import { showNotification } from '@mantine/notifications';
import { useMutation } from '@tanstack/react-query';
import { apiClient, getEndpoints } from '../../../api/common';
import { createMockableHook } from '../../../utils/createMockableHook';

interface RequestData {
	aiPersonaId: string;
	file: File;
}

interface ResponseData {
	avatar_url?: string;
}

export function useAIPersonaImageInternal() {
	const mutationFn = async ({ file, aiPersonaId }: RequestData) => {
		const url = getEndpoints(['ai']).byPath([
			'personas',
			aiPersonaId,
			'upload',
		]);
		const formData = new FormData();
		formData.append('file', file);
		const { data } = await apiClient.post<ResponseData>(url, formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});
		return data;
	};

	return useMutation<ResponseData, Error, RequestData>({
		mutationFn,
		onSuccess() {},
		onError() {
			showNotification({
				autoClose: false,
				title: 'Error',
				message: 'An error occurred when uploading the AI persona image.',
				color: 'red',
			});
		},
	});
}

export const [useAIPersonaImage, MockUseAIPersonaImage] = createMockableHook(
	useAIPersonaImageInternal
);
