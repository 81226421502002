import { Group, Stack, Table } from '@mantine/core';
import { IconEmoji } from '@repo/common/components/IconEmoji/IconEmoji';
import { useNavigateHandler } from '@repo/common/hooks/useNavigate';
import { Text } from '@repo/foundations';
import type { TableComponentProps } from './MetricWidgetTable';

export function MetricGroupTable({ infoColumns, values }: TableComponentProps) {
	const navigateHandler = useNavigateHandler();
	const rows = values.map((group, index) => (
		<tr
			key={index}
			style={{
				cursor: 'pointer',
			}}
			onClick={(e) =>
				navigateHandler(e)(
					`/${'team_id' in group ? `teams/${group.team_id}` : `group/${group.user_group_id}`}/`
				)
			}
		>
			<td>
				<Group h={52} noWrap>
					<IconEmoji value={String(group.icon)} size={32} />
					<Stack spacing={0}>
						<Text
							color="text/primary/default"
							size="sm"
							weight="semibold"
							lineClamp={1}
						>
							{group.name}
						</Text>
					</Stack>
				</Group>
			</td>
			{infoColumns.map((col) => (
				<td key={col.fieldName}>{group[col.fieldName]}</td>
			))}
		</tr>
	));

	return (
		<Table highlightOnHover w="100%">
			<thead>
				<tr>
					<th>
						<Text color="text/secondary/default" fz="xs" fw={500}>
							Group
						</Text>
					</th>
					{infoColumns.map((col) => (
						<th key={col.fieldName}>
							<Text color="text/secondary/default" fz="xs" fw={500}>
								{col.label}
							</Text>
						</th>
					))}
				</tr>
			</thead>
			<tbody>{rows}</tbody>
		</Table>
	);
}
