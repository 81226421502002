import { Box } from '@mantine/core';
import { isString } from 'lodash-es';

import { RichEditor } from '../../../../../../../components/RichEditor';
import { WidgetType } from '../../../../../../../interfaces';
import { useHomepageTeam } from '../../../../../hooks/useHomepageTeam';
import type { IWidgetItemProps } from '../../../types';

function TextBlockWidget({
	widget,
	onChange,
}: IWidgetItemProps & {
	onChange: (value?: string, disableInvalidate?: boolean) => void;
}) {
	const { isTeamViewerUser } = useHomepageTeam();

	let placeholder =
		'Jot down a quick note or add a link to an important resource.';

	if (widget.type === WidgetType.TEXT_BLOCK) {
		placeholder =
			'Use this text block to provide helpful context, link to relevant external links, or anything else';
	}

	const handleChange = (value?: string) => {
		if (!isString(value)) {
			return;
		}

		if (value === widget.content) {
			return;
		}

		onChange(value, true);
	};

	return (
		<Box px="sm">
			<RichEditor
				initialValue={widget.content}
				readOnly={isTeamViewerUser}
				placeholder={placeholder}
				onChangeCallback={handleChange}
				onChangeCallbackDebounceWait={500}
				limited
			/>
		</Box>
	);
}

export default TextBlockWidget;
