import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { api } from '../../../../network';
import { authHeaders } from '../../common';

interface WorkspaceEnv {
	tunnel_url: string;
	slack_enabled: boolean;
	reverse_tunnel_enabled: boolean;
}

export const query = {
	queryKey: ['workspace', 'env'],
	queryFn: async () =>
		(
			await axios.get(`${api()}workspace/workspace_env`, {
				...authHeaders(),
			})
		).data,
};

export function useWorkspaceEnv() {
	return useQuery<WorkspaceEnv>(query.queryKey, query.queryFn, {});
}
