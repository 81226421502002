import { Center, createStyles } from '@mantine/core';
import { useNavigateHandler } from '@repo/common/hooks/useNavigate';
import { Button, Icon, Text } from '@repo/foundations';
import { WidgetType } from '../../../../../../../interfaces';
import { useHomepageTeam } from '../../../../../hooks/useHomepageTeam';
import { WIDGET_CONTENT_HEIGHT } from '../../../constants';
import type { CollectionWidgetTypes } from '../../../types';
import { getCollectionEmptyInfo } from '../../../utils';
import type { ICollectionsWidgetProps } from './CollectionsWidget';

export type IEmptyCollectionsWidgetProps = {
	widgetType: CollectionWidgetTypes;
} & Pick<ICollectionsWidgetProps, 'onWidgetTypeChange'>;

const useStyles = createStyles((theme) => ({
	emptyState: {
		height: WIDGET_CONTENT_HEIGHT,
		flexDirection: 'column',
		gap: theme.spacing.md,
	},
}));

function EmptyCollectionsWidget({
	widgetType,
	onWidgetTypeChange,
}: IEmptyCollectionsWidgetProps) {
	const { classes } = useStyles();
	const navigateHandler = useNavigateHandler();
	const { buttonLabel, message } = getCollectionEmptyInfo(widgetType);
	const { isTeamViewerUser } = useHomepageTeam();

	const handleClick = (event: React.MouseEvent) => {
		if (widgetType === WidgetType.POPULAR_COLLECTIONS) {
			navigateHandler(event)('/collections');
			return;
		}

		onWidgetTypeChange(WidgetType.POPULAR_COLLECTIONS);
	};

	return (
		<Center className={classes.emptyState}>
			<Icon name="inbox" size="lg" color="icon/primary/default" />
			<Text px="sm" size="sm" align="center" color="text/secondary/default">
				{message}
			</Text>
			<Button disabled={isTeamViewerUser} onClick={handleClick}>
				{buttonLabel}
			</Button>
		</Center>
	);
}

export default EmptyCollectionsWidget;
