import { Center, ThemeIcon, useMantineTheme } from '@mantine/core';
import type { ColorNames } from '@repo/theme/utils';
import { BaseProgressIndicator } from './BaseProgressIndicator';

export type ProgressIndicatorVariants =
	| 'primary'
	| 'warning'
	| 'critical'
	| 'success'
	| 'info';
export interface ProgressIndicatorProps {
	variant: ProgressIndicatorVariants;
	// A number between 0 and 100
	value: number;
	bg?: ColorNames;
}

const VARIANT_TO_COLOR_MAP: Record<ProgressIndicatorVariants, ColorNames> = {
	primary: 'fill/brand/default',
	warning: 'fill/warning/default',
	critical: 'fill/critical/default',
	success: 'fill/success/default',
	info: 'fill/info/default',
};

export function ProgressIndicator({
	value,
	variant,
	bg = 'border/inverse/default',
}: ProgressIndicatorProps) {
	const theme = useMantineTheme();
	const colorName = VARIANT_TO_COLOR_MAP[variant];

	if (value >= 100) {
		return (
			<Center w={20} h={20}>
				<ThemeIcon size={16} radius={16} bg={theme.other.getColor(colorName)}>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="16"
						height="16"
						viewBox="0 0 16 16"
						fill="none"
					>
						<path
							d="M5.33337 8L7.33337 10L11.3334 6"
							stroke="white"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</ThemeIcon>
			</Center>
		);
	}

	return (
		<Center w={20} h={20}>
			<BaseProgressIndicator
				size={16}
				value={value}
				backgroundLineColor={bg}
				lineColor={colorName}
			/>
		</Center>
	);
}
