import {
	Divider,
	Group,
	Modal,
	rem,
	Stack,
	TextInput,
	useMantineTheme,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { Button, IconButton, Text } from '@repo/foundations';
import { useFormik } from 'formik';
import { cloneElement, useState } from 'react';
import * as Yup from 'yup';
import { captureError } from '../../web-tracing';

const MAX_LENGTH = 80;
interface AddGlossarySynonymProps {
	onAdd: (synonym: string) => Promise<void>;
	children?: JSX.Element;
}

export function AddGlossarySynonym({
	onAdd,
	children,
}: AddGlossarySynonymProps) {
	const theme = useMantineTheme();
	const [isLoading, setIsLoading] = useState(false);

	const [opened, { toggle, close }] = useDisclosure(false, {
		onClose: () => {
			setIsLoading(false);
			// eslint-disable-next-line no-use-before-define
			formik.resetForm();
		},
	});

	const formik = useFormik({
		initialValues: {
			synonym: '',
		},
		validationSchema: Yup.object().shape({
			synonym: Yup.string()
				.required('Synonym is required')
				.max(
					MAX_LENGTH,
					`Synonym cannot be longer than ${MAX_LENGTH} characters`
				),
		}),
		validateOnBlur: false,
		validateOnChange: false,
		onSubmit: async (values) => {
			try {
				setIsLoading(true);
				await onAdd(values.synonym);
				close();
			} catch (e) {
				captureError(e);
				formik.setFieldError('synonym', 'An error has occurred.');
			} finally {
				setIsLoading(false);
			}
		},
	});

	return (
		<>
			{children ? (
				cloneElement(children, {
					onClick: toggle,
				})
			) : (
				<IconButton
					iconName="plus"
					size="sm"
					variant="tertiary"
					onClick={toggle}
					tooltip="Add synonym"
				/>
			)}
			<Modal opened={opened} onClose={close} title="Add synonym to term">
				<form onSubmit={formik.handleSubmit}>
					<Stack spacing="md">
						<TextInput
							autoFocus
							name="synonym"
							value={formik.values.synonym}
							error={formik.errors.synonym}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							label="Synonym"
							readOnly={isLoading}
							errorProps={{ px: 'xs', pb: '3xs' }}
							data-autofocus
							maxLength={MAX_LENGTH}
							rightSection={
								<Text size="sm" color="text/secondary/default">
									{formik.values.synonym.length}/{MAX_LENGTH}
								</Text>
							}
							rightSectionWidth={45}
							rightSectionProps={{
								style: {
									top: rem(theme.spacing['2xs']),
									right: rem(theme.spacing['2xs']),
									bottom: rem(theme.spacing['2xs']),
									width: 'auto',
									justifyContent: 'flex-end',
								},
							}}
						/>
						<Divider />
						<Group position="right" spacing="md">
							<Button variant="default" onClick={close} disabled={isLoading}>
								Cancel
							</Button>
							<Button
								variant="primary"
								type="submit"
								disabled={isLoading}
								loading={isLoading}
							>
								Add synonym
							</Button>
						</Group>
					</Stack>
				</form>
			</Modal>
		</>
	);
}
