import type {
	DataQualityScore,
	DataQualitySuggestion,
} from '@repo/api-codegen';
import { pluralize } from '@repo/common/utils';
import { getScoreClassification } from '@repo/common/utils/dataQuality';
import { capitalize } from 'lodash-es';
import { sentenceCase } from '../../utils/stringUtils';
import {
	DATA_QUALITY_COMPLETED_ITEMS,
	DATA_QUALITY_COMPLETED_ITEMS_REPORT_LEVEL,
	DATA_QUALITY_MAX_SCORE,
	DATA_QUALITY_TOP_KEYS,
} from './constants';
import type {
	DataQualityBreakdownItem,
	DataQualityBreakdownSubItem,
} from './types';

function getTopLevelDataQualityBreakdownItem(
	key: DataQualitySuggestion['data_quality_top_key'],
	dataQualityScore: DataQualityScore
): DataQualityBreakdownItem {
	const score = dataQualityScore[key];
	const maxScore = DATA_QUALITY_MAX_SCORE[key];
	const percentage = (score / maxScore) * 100;

	return {
		title: capitalize(key),
		key: key,
		score,
		maxScore,
		classification: getScoreClassification(percentage),
		percentage,
		subItems: [],
	};
}

function appendCompletedItems(
	topKey: DataQualitySuggestion['data_quality_top_key'],
	dataQualityScore: DataQualityScore,
	item: DataQualityBreakdownItem,
	page: 'resource' | 'report'
) {
	const COMPLETED_ITEMS =
		page === 'resource'
			? DATA_QUALITY_COMPLETED_ITEMS
			: DATA_QUALITY_COMPLETED_ITEMS_REPORT_LEVEL;

	const subItems = COMPLETED_ITEMS[topKey].filter(
		({ key, maxScore }) => dataQualityScore[key] === maxScore
	);

	subItems.forEach((subItem) => {
		const subScore = dataQualityScore[subItem.key as keyof DataQualityScore];
		const percentage = (subScore / subItem.maxScore) * 100;

		item.subItems.push({
			title: subItem.title,
			classification: getScoreClassification(percentage),
			percentage,
			data_quality_key: subItem.key,
			data_quality_top_key: topKey,
			isCompleted: true,
			score: subScore,
			max_score: subItem.maxScore,
		});
	});
}

export function parseDataQualitySuggestions(
	dataQualityScore: DataQualityScore,
	suggestions: DataQualitySuggestion[],
	page: 'resource' | 'report'
): DataQualityBreakdownItem[] {
	const suggestionsMap: Record<string, DataQualityBreakdownItem> = {};

	suggestions.forEach((suggestion) => {
		if (!suggestionsMap[suggestion.data_quality_top_key]) {
			suggestionsMap[suggestion.data_quality_top_key] =
				getTopLevelDataQualityBreakdownItem(
					suggestion.data_quality_top_key,
					dataQualityScore
				);
		}

		const percentage = (suggestion.score / suggestion.max_score) * 100;

		suggestionsMap[suggestion.data_quality_top_key].subItems.push({
			...suggestion,
			classification: getScoreClassification(percentage),
			percentage,
		});
	});

	DATA_QUALITY_TOP_KEYS.forEach((key) => {
		if (!suggestionsMap[key]) {
			suggestionsMap[key] = getTopLevelDataQualityBreakdownItem(
				key,
				dataQualityScore
			);
		}

		appendCompletedItems(key, dataQualityScore, suggestionsMap[key], page);
	});

	return Object.values(suggestionsMap);
}

export function getTestsAndMonitorsNoun(item: DataQualityBreakdownSubItem) {
	if (item.tests.length > 0 && item.monitors.length > 0) {
		return `${pluralize('test', item.tests.length)} and ${pluralize('monitor', item.monitors.length)}`;
	} else if (item.tests.length > 0) {
		return `${pluralize('test', item.tests.length)}`;
	} else {
		return `${pluralize('monitor', item.monitors.length)}`;
	}
}

export function getQualityKeyLabel(key: keyof DataQualityScore) {
	if (key === 'total') {
		return 'Overall score';
	}
	return sentenceCase(key);
}
