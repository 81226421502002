import type { MantineNumberSize } from '@mantine/core';
import { Box, useMantineTheme } from '@mantine/core';
import { useId } from '@mantine/hooks';
import * as Plot from '@observablehq/plot';
import { svg } from 'htl';
import { isNil, max } from 'lodash-es';
import { transparentize } from 'polished';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ChartTooltip } from '../../components/Chart/components/ChartTooltip';
import { pointerX } from './TooltipMark/pointer';
import { cleanUpTooltips, tooltip } from './TooltipMark/tooltipMark';
import { getPointValueFromChannel } from './utils';

export interface LineChartChannel<T, X = Date, Y = number> {
	label: string;
	color: string;
	x: keyof T | ((d: T) => X);
	y: keyof T | ((d: T) => Y);
}

export interface LineChartAxis<P> {
	formatter?: (point: P) => string;
	tooltipFormatter?: (point: P) => string;
	maxTicks?: number;
	visible?: boolean;
	grid?: boolean;
}

export interface LineChartProps<T, X = Date, Y = number> {
	channels: Array<LineChartChannel<T, X, Y>>;
	data: Array<T>;
	labelX: string;
	width?: MantineNumberSize;
	height?: MantineNumberSize;
	xAxis?: LineChartAxis<X>;
	yAxis?: LineChartAxis<Y>;
	tooltipRenderer?: (dataIndex: number) => React.ReactNode;
	chartProps?: Pick<Plot.PlotOptions, 'marginLeft'>;
}

export function LineChart<T, X, Y>({
	channels,
	data,
	labelX,
	width,
	height,
	xAxis,
	yAxis,
	tooltipRenderer,
	chartProps,
}: LineChartProps<T, X, Y>) {
	const theme = useMantineTheme();
	const containerRef = useRef<HTMLDivElement>(null);
	const [chartWidth, setChartWidth] = useState<number | undefined>();
	const uuid = useId();

	const renderTooltip = useCallback(
		({
			x,
			y,
			dataIndex,
		}: {
			x: number;

			y: number;
			// eslint-disable-next-line react/no-unused-prop-types
			dataIndex: number | null;
		}) => {
			if (!dataIndex || !data[dataIndex] || !tooltipRenderer) {
				return null;
			}

			return (
				<ChartTooltip x={x} y={y} w="auto" miw={260}>
					<Box>{tooltipRenderer(dataIndex)}</Box>
				</ChartTooltip>
			);
		},
		[data, tooltipRenderer]
	);

	useEffect(() => {
		if (containerRef.current) {
			setChartWidth(containerRef.current.clientWidth);
		}
	}, [containerRef.current?.clientWidth]);

	useEffect(() => {
		const renderArea = channels.length === 1;

		const maxY = renderArea
			? max(
					data.map((item) =>
						max(
							channels.map((channel) =>
								getPointValueFromChannel(channel.y, item)
							)
						)
					)
				)
			: undefined;

		const plot = Plot.plot({
			width: chartWidth,
			height: typeof height === 'number' ? height : undefined,
			marginTop: 10,
			marginBottom: isNil(xAxis?.visible) || xAxis.visible ? 40 : 3,
			marginLeft: 80,
			marginRight: 20,
			x: {
				ticks: isNil(xAxis?.visible) || xAxis.visible ? xAxis?.maxTicks : 0,
				label: null,
				nice: true,
				grid: xAxis?.grid,
				tickFormat: xAxis?.formatter,
			},
			y: {
				ticks: isNil(yAxis?.visible) || yAxis.visible ? yAxis?.maxTicks : 0,
				label: null,
				grid: yAxis?.grid,
				tickFormat: yAxis?.formatter,
			},
			marks: [
				...(isNil(xAxis?.visible) || xAxis.visible
					? [
							Plot.axisX({
								anchor: 'bottom',
								label: null,
								ticks: xAxis?.maxTicks,
								tickSize: 0,
								fontSize: 12,
								color: theme.other.getColor('text/secondary/default'),
								tickPadding: 12,
								textAnchor: 'middle',
								tickFormat: xAxis?.formatter,
							}),
						]
					: []),
				...(isNil(yAxis?.visible) || yAxis.visible
					? [
							Plot.axisY({
								label: null,
								ticks: yAxis?.maxTicks,
								tickSize: 0,
								fontSize: 12,
								color: theme.other.getColor('text/secondary/default'),
								tickPadding: 16,
								tickFormat: yAxis?.formatter,
							}),
						]
					: []),

				...channels.flatMap((channel) => [
					Plot.line(data, {
						x: channel.x as Plot.ChannelValueSpec,
						y: channel.y as Plot.ChannelValueSpec,
						stroke: channel.color,
						curve: 'monotone-x',
					}),
				]),
				...(renderArea
					? ([
							Plot.areaY(data, {
								x: channels[0].x as Plot.ChannelValueSpec,
								y: channels[0].y as Plot.ChannelValueSpec,
								fill: `url(#gradient-${uuid})`,
								curve: 'monotone-x',
							}),
							function GradientPlot(_index, { y }) {
								return svg`<defs>
                  <linearGradient id="gradient-${uuid}" gradientUnits="userSpaceOnUse"
                    x1=0 x2=0 y1=${y?.(0)} y2=${y?.(maxY)}>
                      <stop offset=0% stop-color=${transparentize(1, channels[0].color)} />
                      <stop offset=100% stop-color=${transparentize(0.5, channels[0].color)} />`;
							},
						] as Plot.Markish[])
					: []),
				Plot.ruleX(
					data,
					pointerX({
						x: channels[0].x as Plot.ChannelValueSpec,
						stroke: theme.colors.gray[3],
					})
				),
				...(tooltipRenderer
					? [
							tooltip(
								data,
								pointerX({
									x: channels[0].x as Plot.ChannelValueSpec,
									tooltipRenderer: renderTooltip,
								})
							),
						]
					: []),
			],
			...chartProps,
		});
		containerRef.current?.appendChild(plot);
		return () => {
			plot.remove();
			cleanUpTooltips();
		};
	}, [
		chartWidth,
		height,
		theme,
		data,
		channels,
		labelX,
		xAxis,
		yAxis,
		tooltipRenderer,
		renderTooltip,
		uuid,
		chartProps,
	]);

	return <Box w={width} h={height} ref={containerRef} />;
}
