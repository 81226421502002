import type { MantineNumberSize } from '@mantine/core';
import { Box, createStyles, useMantineTheme } from '@mantine/core';
import { useResizeObserver } from '@mantine/hooks';
import * as Plot from '@observablehq/plot';
import type { MetricType } from '@repo/api-codegen';
import dayjs from 'dayjs';
import { every, isNil, max, min } from 'lodash-es';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import type { IncidentStatus, MeasurementChartData } from '../../../api';
import { pointer, pointerX } from '../../../utils/charts/TooltipMark/pointer';
import {
	cleanUpTooltips,
	tooltip,
} from '../../../utils/charts/TooltipMark/tooltipMark';
import { uuidRegex } from '../../../utils/shared.utils.ts';
import {
	formatValueCompact,
	shouldCapLowerThreshold,
	shouldCapUpperThreshold100,
} from '../utils';
import { useIncidentDrawer } from './IncidentDrawerContext';
import MonitorMeasurementTooltip from './MonitorMeasurementTooltip.tsx';

export interface MonitorTimeseriesChartProps {
	metricType: MetricType;
	data: MeasurementChartData[];
	width?: MantineNumberSize;
	height?: MantineNumberSize;
	configuredUpperThreshold?: number;
	configuredLowerThreshold?: number;
	chartProps?: Pick<Plot.PlotOptions, 'marginLeft'>;
}

const useStyles = createStyles(() => ({
	wrapper: {
		overflow: 'visible !important',
	},
}));

interface IncidentData {
	value: number;
	timestamp: Date;
	start_timestamp: Date;
	end_timestamp: Date;
	incident_id?: string;
	incident_status?: null | IncidentStatus;
	measurementCount: number;
	measurements: MeasurementChartData[];
}

function MonitorTimeseriesChart({
	data: _data,
	width,
	height,
	metricType,
	configuredLowerThreshold,
	configuredUpperThreshold,
	chartProps,
}: MonitorTimeseriesChartProps) {
	const navigate = useNavigate();
	const { opened, openIncident } = useIncidentDrawer();
	const { classes } = useStyles();

	const floorDomainAtZero = useMemo(() => {
		const allValuesAboveZero = every(
			_data,
			(item) => typeof item.value === 'number' && item.value >= 0
		);
		return shouldCapLowerThreshold(metricType) || allValuesAboveZero;
	}, [_data, metricType]);

	const capUpperThreshold = useMemo(
		() => shouldCapUpperThreshold100(metricType),
		[metricType]
	);

	const data = useMemo(
		() =>
			_data.map((item) => ({
				...item,
				timestamp: new Date(item.truncated_time),
				lower_threshold:
					floorDomainAtZero && item.lower_threshold && item.lower_threshold < 0
						? 0
						: item.lower_threshold,
				upper_threshold:
					capUpperThreshold &&
					item.upper_threshold &&
					item.upper_threshold > 100
						? 100
						: item.upper_threshold,
			})),
		[_data, floorDomainAtZero]
	);

	const yAxisDomain = useMemo(() => {
		const upperValues = data.map((d) =>
			isFinite(d.upper_threshold)
				? Math.max(d.upper_threshold, d.value)
				: d.value
		);
		const lowerValues = data.map((d) =>
			isFinite(d.lower_threshold)
				? Math.min(d.lower_threshold, d.value)
				: d.value
		);

		const maxValue = max(upperValues) ?? 0;
		const minValue = min(lowerValues) ?? 0;

		if (maxValue === minValue && maxValue === 0) {
			return [0, 5];
		}

		const orderOfMagnitude = Math.floor(
			Math.log10(Math.max(Math.abs(minValue), Math.abs(maxValue)))
		);

		let domainStart =
			Math.floor(minValue / Math.pow(10, orderOfMagnitude)) *
			Math.pow(10, orderOfMagnitude);
		let domainEnd =
			Math.ceil(maxValue / Math.pow(10, orderOfMagnitude)) *
			Math.pow(10, orderOfMagnitude);
		if (domainStart === domainEnd) {
			domainStart = 0;
			domainEnd =
				Math.ceil(maxValue / Math.pow(10, orderOfMagnitude + 1)) *
				Math.pow(10, orderOfMagnitude + 1);
		}

		if (
			(metricType === 'null_percentage' ||
				metricType === 'unique_percentage') &&
			domainEnd > 100
		) {
			domainEnd = 100;
		}

		return [domainStart, domainEnd];
	}, [data]);

	const singleValueFlag = data.length === 1 && !data[0].incident_exists;

	const incidentData = useMemo(
		() => data.filter((item) => item.incident_exists),
		[data]
	);

	const incidentList = useMemo(() => {
		const list: IncidentData[] = [];
		let currentIncident = null;

		for (const measurement of data) {
			if (measurement.incident_exists && measurement.incident_id) {
				if (
					!currentIncident ||
					currentIncident.incident_id !== measurement.incident_id
				) {
					// Start a new incident
					if (currentIncident) {
						const middleIndex = Math.floor(
							currentIncident.measurements.length / 2
						);
						const middleMeasurement = currentIncident.measurements[middleIndex];
						list.push({
							...currentIncident,
							timestamp: middleMeasurement.timestamp,
							value: middleMeasurement.value,
							measurementCount: currentIncident.measurements.length,
						});
					}
					currentIncident = {
						incident_id: measurement.incident_id,
						measurements: [measurement],
						start_timestamp: measurement.timestamp,
						end_timestamp: measurement.timestamp,
						incident_status: measurement.incident_status,
					};
				} else {
					// Continue the current incident
					currentIncident.measurements.push(measurement);
					currentIncident.end_timestamp = measurement.timestamp;
					currentIncident.incident_status = measurement.incident_status;
				}
			} else {
				// No incident, add the current incident to the list if exists
				if (currentIncident) {
					const middleIndex = Math.floor(
						currentIncident.measurements.length / 2
					);
					const middleMeasurement = currentIncident.measurements[middleIndex];
					list.push({
						...currentIncident,
						timestamp: middleMeasurement.timestamp,
						value: middleMeasurement.value,
						measurementCount: currentIncident.measurements.length,
					});
					currentIncident = null;
				}
			}
		}

		// Add the last incident if it exists
		if (currentIncident) {
			const middleIndex = Math.floor(currentIncident.measurements.length / 2);
			const middleMeasurement = currentIncident.measurements[middleIndex];
			list.push({
				...currentIncident,
				timestamp: middleMeasurement.timestamp,
				value: middleMeasurement.value,
				measurementCount: currentIncident.measurements.length,
			});
		}

		return list;
	}, [data]);

	const hasAutoThresholds =
		isNil(configuredUpperThreshold) && isNil(configuredLowerThreshold);
	const hasLowerThresholdOnly =
		isNil(configuredUpperThreshold) && !isNil(configuredLowerThreshold);
	const hasUpperThresholdOnly =
		!isNil(configuredUpperThreshold) && isNil(configuredLowerThreshold);
	const hasBothManualThresholds =
		!isNil(configuredUpperThreshold) && !isNil(configuredLowerThreshold);

	const theme = useMantineTheme();
	const [chartWidth, setChartWidth] = useState<number | undefined>();

	const [ref, rect] = useResizeObserver();
	useEffect(() => {
		setChartWidth(rect.width);
	}, [rect]);

	const dateFormat = useMemo(() => {
		if (data.length === 0) return 'MMM D';

		const timestamps = data.map((d) => d.timestamp);
		const minTimestamp = new Date(
			Math.min(...timestamps.map((t) => t.getTime()))
		);
		const maxTimestamp = new Date(
			Math.max(...timestamps.map((t) => t.getTime()))
		);
		const hoursDifference =
			(maxTimestamp.getTime() - minTimestamp.getTime()) / (1000 * 60 * 60);

		if (hoursDifference <= 24) {
			return 'MMM D hA';
		}

		// Check if the dates cross 2 years
		const allSameYear = timestamps.every(
			(timestamp) => timestamp.getFullYear() === minTimestamp.getFullYear()
		);
		if (!allSameYear) {
			return 'MMM D YY';
		}

		return 'MMM D';
	}, [data]);

	const renderTooltip = useCallback(
		({
			x,
			y,
			dataIndex,
		}: {
			// eslint-disable-next-line react/no-unused-prop-types
			x: number;
			// eslint-disable-next-line react/no-unused-prop-types
			y: number;
			// eslint-disable-next-line react/no-unused-prop-types
			dataIndex: number | null;
		}) => {
			if (!dataIndex) {
				return null;
			}
			if (!data[dataIndex]) {
				return null;
			}

			return (
				<MonitorMeasurementTooltip
					value={data[dataIndex]}
					x={x}
					y={y}
					metricType={metricType}
				/>
			);
		},
		[data, metricType]
	);

	useEffect(() => {
		// Base Layer - axis/incidents etc
		let marks = [
			// ruleX doesn't work with the eventListener for some reason. `plot.value` just returns null.
			// Keep crosshairX here, and disable the text the axis by settings opacity to 0
			Plot.crosshairX(
				data,
				pointerX({
					x: 'timestamp',
					color: theme.other.getColor('border/secondary/default'),
					textStrokeOpacity: 0,
					textFillOpacity: 0,
				})
			),
			Plot.gridY({
				stroke: theme.other.getColor('border/secondary/default'),
				strokeOpacity: 1,
			}),
			Plot.axisX({
				anchor: 'bottom',
				tickFormat: (t: Date) => dayjs.utc(t).format(dateFormat),
				label: null,
				tickSize: 0,
				fontSize: 12,
				color: theme.other.getColor('text/secondary/default'),
				tickPadding: 16,
				ticks: 5,
				textAnchor: 'middle',
			}),
			Plot.axisY({
				tickFormat: (t: number) => formatValueCompact(metricType, t),
				label: null,
				tickSize: 0,
				fontSize: 12,
				color: theme.other.getColor('text/secondary/default'),
				tickPadding: 6,
				ticks: 5,
			}),
			Plot.line(data, {
				x: 'timestamp',
				y: 'value',
				stroke: theme.other.getColor('fill/success/default'),
			}),
			tooltip(
				data,
				pointerX({
					x: 'timestamp',
					y: 'value',
					tooltipRenderer: renderTooltip,
				})
			),

			// Rectangle highlights for incidents
			Plot.rect(incidentList, {
				y1: yAxisDomain[0],
				y2: yAxisDomain[1],
				x1: (datapoint) => datapoint.start_timestamp,
				x2: (datapoint) => datapoint.end_timestamp,
				fill: (dataPoint: MeasurementChartData) => {
					if (dataPoint.incident_status === 'resolved') {
						return 'transparent';
					}
					if (dataPoint.incident_status === 'acknowledged') {
						return theme.other.getColor('icon/caution/default');
					}
					return theme.other.getColor('icon/critical/default');
				},
				fillOpacity: 0.2,
				insetLeft: -6,
				insetRight: -6,
			}),
		];

		incidentList.forEach((incident) => {
			if (incident.measurementCount > 0) {
				marks = marks.concat([
					Plot.line(incident.measurements, {
						x: 'timestamp',
						y: 'value',
						stroke: (dataPoint: MeasurementChartData) => {
							if (dataPoint.incident_status === 'resolved') {
								return theme.other.getColor('icon/success/default');
							}
							if (dataPoint.incident_status === 'acknowledged') {
								return theme.other.getColor('icon/caution/default');
							}
							return theme.other.getColor('icon/critical/default');
						},
					}),
				]);
			}
		});

		marks = marks.concat([
			Plot.dot(
				incidentList,
				pointer({
					x: 'timestamp',
					y: 'value',
					r: 11,
					stroke: (dataPoint: MeasurementChartData) => {
						if (dataPoint.incident_status === 'resolved') {
							return theme.other.getColor('fill/success-secondary/default');
						}
						if (dataPoint.incident_status === 'acknowledged') {
							return theme.other.getColor('fill/caution-secondary/default');
						}
						return theme.other.getColor('fill/critical-secondary/default');
					},
					fill: (dataPoint: MeasurementChartData) => {
						if (dataPoint.incident_status === 'resolved') {
							return theme.other.getColor('icon/success/default');
						}
						if (dataPoint.incident_status === 'acknowledged') {
							return theme.other.getColor('icon/caution/default');
						}
						return theme.other.getColor('icon/critical/default');
					},
					strokeWidth: 6,
				})
			),
			Plot.dot(incidentList, {
				x: 'timestamp',
				y: 'value',
				r: 9,
				stroke: (dataPoint: MeasurementChartData) => {
					if (dataPoint.incident_status === 'resolved') {
						return theme.other.getColor('fill/success-secondary/default');
					}
					if (dataPoint.incident_status === 'acknowledged') {
						return theme.other.getColor('fill/caution-secondary/default');
					}
					return theme.other.getColor('fill/critical-secondary/default');
				},
				fill: (dataPoint: MeasurementChartData) => {
					if (dataPoint.incident_status === 'resolved') {
						return theme.other.getColor('icon/success/default');
					}
					if (dataPoint.incident_status === 'acknowledged') {
						return theme.other.getColor('icon/caution/default');
					}
					return theme.other.getColor('icon/critical/default');
				},
				strokeWidth: 3,
			}),
			Plot.text(incidentList, {
				x: 'timestamp',
				y: 'value',
				text: () => '!',
				fontSize: 13,
				fontWeight: 600,
				fill: 'white',
				pointerEvents: 'none',
			}),
		]);

		if (singleValueFlag) {
			marks = marks.concat([
				Plot.dot(data, {
					x: 'timestamp',
					y: 'value',
					stroke: theme.other.getColor('fill/success/default'),
					fill: theme.other.getColor('fill/success/default'),
					r: 3,
				}),
			]);
		}

		if (hasAutoThresholds) {
			marks = marks.concat([
				Plot.areaY(data, {
					x: 'timestamp',
					y1: 'lower_threshold',
					y2: 'upper_threshold',
					fill: theme.other.getColor('fill/success/default'),
					fillOpacity: 0.15,
				}),
				Plot.line(data, {
					x: 'timestamp',
					y: 'lower_threshold',
					stroke: theme.other.getColor('fill/success/default'),
					strokeDasharray: '5,5',
					strokeOpacity: 0.5,
				}),
				Plot.line(data, {
					x: 'timestamp',
					y: 'upper_threshold',
					stroke: theme.other.getColor('fill/success/default'),
					strokeDasharray: '5,5',
					strokeOpacity: 0.5,
				}),
			]);
		}

		if (hasBothManualThresholds) {
			marks = marks.concat([
				Plot.areaY(data, {
					x: 'timestamp',
					y1: configuredUpperThreshold,
					y2: configuredLowerThreshold,
					fill: theme.other.getColor('fill/success/default'),
					fillOpacity: 0.15,
				}),
				Plot.line(data, {
					x: 'timestamp',
					y: configuredUpperThreshold,
					stroke: theme.other.getColor('fill/success/default'),
					strokeDasharray: '5,5',
					strokeOpacity: 0.5,
				}),
				Plot.line(data, {
					x: 'timestamp',
					y: configuredLowerThreshold,
					stroke: theme.other.getColor('fill/success/default'),
					strokeDasharray: '5,5',
					strokeOpacity: 0.5,
				}),
			]);
		}

		if (hasLowerThresholdOnly) {
			marks = marks.concat([
				Plot.line(data, {
					x: 'timestamp',
					y: configuredLowerThreshold,
					stroke: theme.other.getColor('fill/success/default'),
					strokeDasharray: '5,5',
					strokeOpacity: 0.5,
				}),
			]);
		}

		if (hasUpperThresholdOnly) {
			marks = marks.concat([
				Plot.line(data, {
					x: 'timestamp',
					y: configuredUpperThreshold,
					stroke: theme.other.getColor('fill/success/default'),
					strokeDasharray: '5,5',
					strokeOpacity: 0.5,
				}),
				Plot.areaY(data, {
					x: 'timestamp',
					y1: 0,
					y2: configuredUpperThreshold,
					fill: theme.other.getColor('fill/success/default'),
					fillOpacity: 0.15,
				}),
			]);
		}

		const plot = Plot.plot({
			width: chartWidth,
			height: typeof height === 'number' ? height : undefined,
			marginTop: 10,
			marginBottom: 30,
			marginLeft: 70,
			marginRight: 20,
			x: {
				nice: true,
			},
			y: {
				domain: yAxisDomain,
			},
			color: {},
			marks: marks,
			...chartProps,
		});

		plot.addEventListener('click', () => {
			if (
				plot.value &&
				plot.value.incident_id &&
				uuidRegex.test(plot.value.incident_id)
			) {
				if (opened) {
					navigate(`/incident/${plot.value.incident_id}`);
				} else {
					openIncident(plot.value.incident_id);
				}
			}
		});

		ref.current?.appendChild(plot);
		return () => {
			plot.remove();
			cleanUpTooltips();
		};
	}, [
		chartWidth,
		data,
		incidentData,
		theme.other,
		configuredUpperThreshold,
		configuredLowerThreshold,
		renderTooltip,
		hasAutoThresholds,
		hasBothManualThresholds,
		hasLowerThresholdOnly,
		hasUpperThresholdOnly,
		height,
		ref,
		metricType,
		navigate,
		opened,
		openIncident,
		incidentList,
		yAxisDomain,
		singleValueFlag,
		dateFormat,
		chartProps,
	]);
	return <Box w={width} h={height} className={classes.wrapper} ref={ref} />;
}

export default memo(MonitorTimeseriesChart);
