import { createStyles } from '@mantine/core';

// Styling configurations for the component
export const useStyles = createStyles((theme) => ({
	navlink: {
		cursor: 'pointer',
	},
	markdown: {
		borderRight: `1px solid ${theme.colors.gray[2]}`,
		height: '100%',
		minHeight: '75vh',
		width: '80%',
	},
	sidebar: {
		minWidth: '20%',
		borderRadius: theme.radius.xs,
	},
	container: {
		justifyContent: 'space-between',
		alignItems: 'flex-start',
		flexWrap: 'nowrap',
	},
}));
