import type { LiteUserOut } from '@repo/api-codegen';
import type { SelectablePropertyItem } from '@repo/common/components/SingleSelector/types';
import { IconUsers } from '@tabler/icons-react';
import { uniqBy } from 'lodash-es';
import type { UserGroup } from '../../lib/models';

import type { DjangoValueType } from '../../pages/TemplatePage/types';
import type { SidebarEntityKeys } from './types';

export const getOwnerOptions = (
	users: LiteUserOut[],
	onOwnerClick: (value: any) => void,
	hasGroup = true
) =>
	uniqBy(users, (user) => user.id).map((owner) => {
		const item = {
			label: owner.display_name,
			value: owner.id,
			icon: owner.profile_picture,
			navigateTo: `/user/${owner.id}`,
			onClick: onOwnerClick,
			strikeThrough: owner.disabled,
			hidden: owner.disabled,
		} as SelectablePropertyItem;

		if (hasGroup) {
			item.group = 'users';
		}

		return item;
	});

export const getGroupOptions = (
	groups: UserGroup[],
	onGroupClick: (value: any) => void,
	hasGroup = true
) =>
	uniqBy(groups, (group) => group.id).map((group) => {
		const item = {
			label: group.name,
			value: group.id,
			defaultIcon: IconUsers,
			navigateTo: `/group/${group.id}`,
			onClick: onGroupClick,
		} as SelectablePropertyItem;

		if (hasGroup) {
			item.group = 'groups';
		}

		return item;
	});

export const getOwnerAndGroupSelectorOptions = (
	users: LiteUserOut[],
	groups: UserGroup[],
	onOwnerClick: (value: any) => void,
	onGroupClick: (value: any) => void
) => {
	const ownerOptions = getOwnerOptions(users, onOwnerClick);
	const groupOptions = getGroupOptions(groups, onGroupClick);

	return [...ownerOptions, ...groupOptions];
};

export const getAssigneeOptions = (
	users: LiteUserOut[],
	onClick: (value: any) => void
) =>
	uniqBy(users, (user) => user.id).map((owner) => {
		const item = {
			label: owner.display_name,
			value: owner.id,
			icon: owner.profile_picture,
			navigateTo: `/user/${owner.id}`,
			onClick: onClick,
			strikeThrough: owner.disabled,
			hidden: owner.disabled,
			group: 'users',
		} as SelectablePropertyItem;
		return item;
	});
export const getAssigneeGroupOptions = (
	groups: UserGroup[],
	onClick: (value: any) => void
) =>
	uniqBy(groups, (group) => group.id).map((group) => {
		const item = {
			label: group.name,
			value: group.id,
			defaultIcon: IconUsers,
			navigateTo: `/group/${group.id}`,
			onClick: onClick,
			group: 'groups',
		} as SelectablePropertyItem;
		return item;
	});
export const getAssigneeAndGroupSelectorOptions = (
	users: LiteUserOut[],
	groups: UserGroup[],
	onClick: (
		key: SidebarEntityKeys
	) => (value: DjangoValueType, saveRemotely?: boolean) => void
) => {
	const ownerOptions = getAssigneeOptions(users, (value: DjangoValueType) => {
		onClick('assigned_to_group')(null, false);
		onClick('assigned_to')(value, true);
	});
	const groupOptions = getAssigneeGroupOptions(
		groups,
		(value: DjangoValueType) => {
			onClick('assigned_to_group')(value, true);
			onClick('assigned_to')(null, false);
		}
	);
	return [...ownerOptions, ...groupOptions];
};
