import { createStyles, Group, Menu, Stack } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import type { APIKeyOut } from '@repo/api-codegen';
import {
	queryKeyFn,
	useApiDeleteApiKey,
	useApiUpdateApiKey,
} from '@repo/api-codegen';
import { Icon, IconButton, Text, Title } from '@repo/foundations';
import moment from 'moment-timezone';
import { useCallback } from 'react';
import { queryClient } from '../../../api';
import { formatDateTime } from '../../../utils/time.ts';
import { openConfirmModal } from '../../ModalManager';
import { EditAPIKeyNameModal } from './EditAPIKeyNameModal.tsx';

interface APITableRowProps {
	apiKey: APIKeyOut;
	isEditorOrAdminUser: boolean;
}

const useStyles = createStyles((theme) => ({
	text: {
		display: 'block',
	},
}));

export function APITableRow({ apiKey, isEditorOrAdminUser }: APITableRowProps) {
	const [isEditKeyModalOpened, { open, close }] = useDisclosure(false);

	const { mutate: updateKey } = useApiUpdateApiKey({});
	const { mutate: deleteKey } = useApiDeleteApiKey({
		onSuccess: () => {
			queryClient.invalidateQueries(
				queryKeyFn({
					path: '/auth/api_keys/',
					operationId: 'apiListApiKeys',
					variables: {},
				})
			);
		},
	});

	const handleDeleteKey = useCallback(() => {
		openConfirmModal({
			title: 'Delete API key',
			children: <Text>Are you sure you want to delete this API key?</Text>,
			confirmProps: {
				variant: 'primary',
				tone: 'critical',
			},
			labels: { confirm: 'Delete', cancel: 'Cancel' },
			onConfirm: () =>
				deleteKey({
					pathParams: { apiKeyId: apiKey.id },
				}),
		});
	}, [apiKey.id, deleteKey]);

	return (
		<>
			<tr key={apiKey.id}>
				<td>
					<Stack spacing={0}>
						<Title size="sm">{apiKey.description || 'Untitled'}</Title>
						<Text
							size="xs"
							color="text/secondary/default"
						>{`Created ${moment(apiKey.created_at).format('MMM D, YYYY')} by ${apiKey.user.display_name}`}</Text>
					</Stack>
				</td>
				<td>
					<Text size="sm">{formatDateTime(apiKey.last_used_at)}</Text>
				</td>
				<td>
					<Text size="sm">{apiKey.last_used_ip}</Text>
				</td>
				<td>
					<Text size="sm">{apiKey.times_used}</Text>
				</td>
				<td>
					<Group position="right">
						<Menu>
							<Menu.Target>
								<IconButton variant="tertiary" iconName="dots" />
							</Menu.Target>
							<Menu.Dropdown>
								<Menu.Item icon={<Icon name="pencil" />} onClick={open}>
									Rename
								</Menu.Item>
								<Menu.Item
									icon={<Icon name="trash" color="text/critical/default" />}
									onClick={handleDeleteKey}
									disabled={!isEditorOrAdminUser}
								>
									<Text size="sm" color="text/critical/default">
										Delete
									</Text>
								</Menu.Item>
							</Menu.Dropdown>
						</Menu>
					</Group>
				</td>
			</tr>
			<EditAPIKeyNameModal
				apiKey={apiKey}
				opened={isEditKeyModalOpened}
				onClose={close}
			/>
		</>
	);
}
