import { Box } from '@mantine/core';
import { Title } from '@repo/foundations';
import { useEffect } from 'react';
import { useLoaderData, useNavigate } from 'react-router';

export function RootHomeRedirect() {
	const navigate = useNavigate();

	// Ensure that the user is logged in before redirecting to home.
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const _ = useLoaderData();

	useEffect(() => {
		navigate('/home');
	}, [navigate]);

	return (
		<Box p={20}>
			<Title order={1} size="xl" mt={52}>
				Redirecting home...
			</Title>
		</Box>
	);
}
