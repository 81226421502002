import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useAuthUser, useCollection, useUpdateCollection } from '../../api';

import IconEmojiSelector from '@repo/common/components/IconEmojiSelector';
import { EntityType } from '@repo/common/enums/entityType';
import CollectionsEntityTabs from '../../components/CollectionsEntityTabs';
import { CollectionsEntityTabsStore } from '../../components/CollectionsEntityTabs/store';
import { getEntityPageSidebarProps } from '../../components/EntityDrawer/utils';
import EntityPageLayout from '../../components/EntityPageLayout';
import ResourceSelectorPopover from '../../components/ResourceSelectorPopover/ResourceSelectorPopover.tsx';
import { trackEvent } from '../../utils/analytics';
import { useParamsIdSuffixUuid } from '../../utils/hook/utils';
import type { DjangoValueType } from '../TemplatePage/types';

const CollectionPageInternal = observer(
	({ id, tab }: { id: string; tab?: string }) => {
		const { user, workspace } = useAuthUser();

		const [store, setStore] = useState<CollectionsEntityTabsStore>();

		const { data: collectionEntity } = useCollection({
			id,
			options: {
				useErrorBoundary: true,
				onSuccess: () => {
					trackEvent('collection_entity/open', {}, user, workspace);
				},
				suspense: true,
			},
		});

		const { mutateAsync } = useUpdateCollection({
			disableInvalidation: true,
			options: {
				onSuccess: () => {
					trackEvent('collection/properties/update', {}, user, workspace!);
				},
			},
		});

		useEffect(() => {
			// We set a new store here to reset the state of the tabs. This is important
			// to avoid flickering, when switching between collections.
			if (collectionEntity) {
				const newStore = new CollectionsEntityTabsStore(collectionEntity, tab);
				setStore(newStore);
			}
		}, [collectionEntity, id, tab]);

		// Debouncing this is not a good idea, because it overwrites changes, does not
		// queue them.
		const updateCollectionEntity = useCallback(
			async (key: string, value: DjangoValueType) => {
				await mutateAsync({
					data: {
						id,
						[key]: value,
					},
				});
			},
			[id, mutateAsync]
		);

		const handleIconChange = useCallback(
			(value: string) => {
				updateCollectionEntity('icon', value);
			},
			[updateCollectionEntity]
		);

		const customActions = useMemo(
			() =>
				store ? (
					<ResourceSelectorPopover
						key="add-to-collection"
						targetButtonIconName="plus"
						targetButtonLabel="Add resource"
						collectionId={store.collection?.id ?? ''}
						types={[
							'table',
							'dashboard',
							'job',
							'metric',
							'dictionary_term',
							'question',
							'document',
							'collection',
						]}
						updateEntity={store.addToCollection}
					/>
				) : undefined,
			[store, store?.addToCollection, store?.collection?.id]
		);

		if (!store || !collectionEntity) {
			return null;
		}

		return (
			<EntityPageLayout
				key={collectionEntity.id}
				name="Collection"
				entity={collectionEntity as never}
				icon={
					<IconEmojiSelector
						value={collectionEntity.icon ?? 'iconName:folder iconColor:#4a4a4a'}
						onChange={handleIconChange}
					/>
				}
				updateEntity={updateCollectionEntity}
				withDescription
				withPinnedToggle
				withCustomActions={customActions}
				customActionsPosition={'right'}
				{...getEntityPageSidebarProps(EntityType.collection)}
			>
				<CollectionsEntityTabs store={store} />
			</EntityPageLayout>
		);
	}
);

export default function CollectionPage() {
	const id = useParamsIdSuffixUuid();
	const { tab } = useParams();

	return <CollectionPageInternal id={id} tab={tab} />;
}
