import { Box } from '@mantine/core';
import { useId } from '@mantine/hooks';
import MultiSelector from '@repo/common/components/MultiSelector/MultiSelector';
import RichTooltip from '@repo/common/components/RichTooltip/RichTooltip';
import type { ButtonVariants, IconNames } from '@repo/foundations';
import type { SecodaThemeShades } from '@repo/theme/types';
import React, { useCallback, useMemo } from 'react';
import { useAuthUser, useTagGroupList, useTagList } from '../../../api';
import { NEW_CUSTOM_PROPERTY_COLUMN_PREFIX } from '../../../constants';
import { extractInheritedTags } from '../../../utils/extractInheritedTags';
import { closeAllModals, openModal } from '../../ModalManager';
import IntegrationTooltipSelectorItem from '../../RichTooltip/IntegrationTooltipSelectorItem';
import { TagOptionsModal } from '../../Settings/TagOptionsModal';
import { TagModal } from '../../Settings/TagSettings/Modal/TagModal';

interface TagSelectorProps {
	onChange?: (value: string[]) => void;
	initialValue?: string[];
	placeholder?: string;
	placeholderIconName?: IconNames;
	forceVariant?: ButtonVariants;
	readOnly?: boolean;
	hideOnEmpty?: boolean;
	maintainTags?: boolean;
	entityIntegrationId?: string;
	entityIntegrationName?: string;
	field?: string;
	label?: string;
}

export function TagSelector({
	onChange,
	initialValue,
	placeholder = 'Tags',
	hideOnEmpty = false,
	placeholderIconName,
	forceVariant,
	readOnly = false,
	maintainTags,
	entityIntegrationId,
	entityIntegrationName,
	field,
	label,
}: TagSelectorProps) {
	const uuid = useId();
	const { user, isAdminUser } = useAuthUser();

	const [selected, setSelected] = React.useState<string[]>(initialValue ?? []);

	const { data: tags } = useTagList({
		filters: field?.startsWith(NEW_CUSTOM_PROPERTY_COLUMN_PREFIX)
			? { custom_property_id: field.split('.')[1] }
			: undefined,
	});
	const { data: tagGroups } = useTagGroupList({
		options: {
			select: ({ results }) => results,
		},
	});

	const memoizedTags = useMemo(
		() =>
			tags?.results
				.filter((tag) => (maintainTags ? initialValue?.includes(tag.id) : true))
				.map((tag) => ({
					label: tag.name,
					value: tag.id,
					color: tag.color as SecodaThemeShades,
					tagIntegration: tag.integration,
					maintainProp: maintainTags,
					entityIntegrationId: entityIntegrationId,
					itemTypeName: 'tag',
					description: tag.description,
					group:
						tagGroups?.find((tg) => tg.id === tag.tag_group_id)?.name ??
						'default',
				})),
		[tags?.results, entityIntegrationId, initialValue, maintainTags, tagGroups]
	);

	const allTags = useMemo(
		() => (tags?.results || []).map((t) => t.name),
		[tags?.results]
	);

	const inheritedTags = extractInheritedTags(memoizedTags, initialValue);

	const handleChange = useCallback(
		(value: (string | boolean)[]) => {
			setSelected(value as string[]);
			onChange?.(value as string[]);
		},
		[onChange]
	);

	const handleCreate = useCallback(
		(value: string) => {
			if (field?.includes('properties2.')) {
				openModal({
					title: 'Create option',
					children: (
						<TagOptionsModal
							key={`tag-modal-${uuid}`}
							customPropertyId={field?.split('.')[1]}
							propertyLabel={'option'}
						/>
					),
				});
				return;
			}

			openModal({
				title: 'Create tag',
				children: (
					<TagModal
						key={`tag-modal-${uuid}`}
						initialTitle={value}
						allTags={allTags}
						onClose={closeAllModals}
					/>
				),
			});
		},
		[field, uuid, allTags]
	);

	let variant: ButtonVariants =
		selected && selected.length > 0 ? 'default' : 'tertiary';

	if (forceVariant) {
		variant = forceVariant;
	}

	return (
		<Box maw={256}>
			{maintainTags && selected.length === 0 ? (
				<RichTooltip
					title={`No tags were set in ${entityIntegrationName}`}
					body={'To maintain in Secoda, go to integration settings.'}
					linkLabel={
						isAdminUser ? `${entityIntegrationName} settings` : undefined
					}
					linkUrl={
						isAdminUser
							? `/integrations/${entityIntegrationId}/preferences`
							: undefined
					}
				>
					<MultiSelector
						hideOnEmpty={hideOnEmpty}
						placeholder={placeholder}
						placeholderIconName={placeholderIconName}
						variant={variant}
						iconType="tag"
						isViewerUser={false}
						options={memoizedTags ?? []}
						onChange={handleChange}
						initialSelected={selected}
						inheritedValues={maintainTags ? inheritedTags : undefined}
						property={label ?? 'tags'}
						permittedId={user.id}
						isMenuItemBadge
						readOnly={readOnly}
						selectorItem={IntegrationTooltipSelectorItem}
						onCreate={handleCreate}
						withDescriptionTooltip
					/>
				</RichTooltip>
			) : (
				<MultiSelector
					hideOnEmpty={hideOnEmpty}
					placeholder={placeholder}
					placeholderIconName={placeholderIconName}
					variant={variant}
					iconType="tag"
					isViewerUser={false}
					options={memoizedTags ?? []}
					onChange={handleChange}
					initialSelected={selected}
					inheritedValues={maintainTags ? inheritedTags : undefined}
					property={label ?? 'tags'}
					permittedId={user.id}
					isMenuItemBadge
					readOnly={readOnly}
					selectorItem={IntegrationTooltipSelectorItem}
					onCreate={handleCreate}
					withDescriptionTooltip
				/>
			)}
		</Box>
	);
}

export default TagSelector;
