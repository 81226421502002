import { Anchor, Stack } from '@mantine/core';
import { useNavigate } from '@repo/common/hooks/useNavigate';
import { Button, Text } from '@repo/foundations';

function FreeTrialEndingContent() {
	const navigate = useNavigate();

	const handleBookDemo = () => {
		window.open(
			'https://secoda.co/contact-sales',
			'_blank',
			'noopener,noreferrer'
		);
	};

	const handleNavigateToPricing = () => {
		navigate('/plans');
	};

	return (
		<Stack spacing="lg">
			<Text>
				If you have any questions,{' '}
				<Anchor onClick={handleBookDemo}>book a demo with us.</Anchor>
			</Text>
			<Button w="fit-content" onClick={handleNavigateToPricing}>
				Choose your plan
			</Button>
		</Stack>
	);
}

export default FreeTrialEndingContent;
