import { ActionIcon, Tooltip, useMantineTheme } from '@mantine/core';
import { IconCircleCheck, IconExclamationCircle } from '@tabler/icons-react';
import { isNil, size, some } from 'lodash-es';
import type { MouseEvent } from 'react';
import type { ILineageTableTest } from '../../../../api';
import { lineageStore } from '../../store';
import { isTestRunSuccessful } from './utils';

interface ITestBadgeProps {
	tests: ILineageTableTest[];
}

function TestBadge({ tests }: ITestBadgeProps) {
	const theme = useMantineTheme();

	const isAllNull = !some(tests, (test) => test.latest_run !== null);

	if (isNil(tests) || size(tests) === 0 || isAllNull) {
		return null;
	}
	const isSuccess = !some(
		tests,
		(test) =>
			!isTestRunSuccessful(test.latest_run?.status) && !isNil(test.latest_run)
	);

	const Icon = isSuccess ? IconCircleCheck : IconExclamationCircle;

	const handleViewTestResults = (event: MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();
		lineageStore.setTestsModalOpen(true, tests);
	};

	return (
		<Tooltip label="View tests" withArrow>
			<ActionIcon onClick={handleViewTestResults} size="sm">
				<Icon
					size={theme.other.iconSize.sm}
					color={theme.other.getColor(
						isSuccess ? 'icon/success/default' : 'icon/critical/default'
					)}
				/>
			</ActionIcon>
		</Tooltip>
	);
}

export default TestBadge;
