export function getFontColorFromBackgroundColor(
	backgroundColor: string
): string {
	let hex = backgroundColor;

	if (backgroundColor.startsWith('#')) {
		hex = backgroundColor.slice(1);
	}

	if (hex.length !== 6) {
		throw new Error('Invalid HEX color.');
	}

	const r = parseInt(hex.slice(0, 2), 16);
	const g = parseInt(hex.slice(2, 4), 16);
	const b = parseInt(hex.slice(4, 6), 16);

	// Based on : https://stackoverflow.com/a/946734
	const isLight = r * 0.299 + g * 0.587 + b * 0.114 > 186;

	return isLight ? '#232221' : '#FFFFFF';
}

export function rgbToHex(rgba: string, forceRemoveAlpha = false) {
	return (
		'#' +
		rgba
			.replace(/^rgba?\(|\s+|\)$/g, '') // Get's rgba / rgb string values
			.split(',') // splits them at ","
			.filter((string: any, index: number) => !forceRemoveAlpha || index !== 3)
			.map((string: string) => parseFloat(string)) // Converts them to numbers
			.map((number: number, index: number) =>
				index === 3 ? Math.round(number * 255) : number
			) // Converts alpha to 255 number
			.map((number: { toString: (arg0: number) => string }) =>
				number.toString(16)
			) // Converts numbers to hex
			.map((string: string | number[]) =>
				string.length === 1 ? '0' + string : string
			) // Adds 0 when length of one number is 1
			.join('')
	); // Puts the array to togehter to a string
}
