import { Box, Group, Stack, Table, useMantineTheme } from '@mantine/core';
import { useElementSize } from '@mantine/hooks';
import { useNavigateHandler } from '@repo/common/hooks/useNavigate';
import { Text } from '@repo/foundations';
import type { MouseEvent } from 'react';
import type { ISecodaEntity } from '../../../../api';
import { useSecodaEntityList } from '../../../../api';
import { SecodaEntityIcon } from '../../../../components/SecodaEntity';
import { buildResourceUrl } from '../../../../utils/navigationUtils';
import { snakeCaseToTitleCase } from '../../../../utils/shared.utils';
import type { TableComponentProps } from './MetricWidgetTable';

function StatusBadge({ label, color }: { label: string; color: string }) {
	const theme = useMantineTheme();

	return (
		<Text
			sx={{
				backgroundColor: theme.colors[color][1],
				width: 'fit-content',
				borderRadius: 10,
			}}
			lineClamp={1}
			px={6}
			color={
				color === 'green' ? 'text/success/default' : 'text/caution/default'
			}
			fz="sm"
			weight="regular"
		>
			{label}
		</Text>
	);
}

export function MetricResourceTable({
	values,
	infoColumns,
}: TableComponentProps) {
	const navigateHandler = useNavigateHandler();

	const handleNavigateToEntity = (event: MouseEvent, entity: ISecodaEntity) => {
		const url = buildResourceUrl(entity);
		navigateHandler(event)(url);
	};

	const { data } = useSecodaEntityList({
		filters: {
			id__in: values.map((resource) => resource.id).join(','),
		},
		options: {
			suspense: false,
		},
	});

	const { ref, width } = useElementSize();

	const includeDocumentedStatus = width >= 520;

	function getSecodaEntityById(id: string) {
		return data?.results.find((e) => e.id === id);
	}

	const rows = values.map((resource) => {
		const entity = getSecodaEntityById(resource.id as string);
		return (
			<tr
				style={{
					cursor: 'pointer',
				}}
				key={resource.id}
				onClick={(e) => entity && handleNavigateToEntity(e, entity)}
			>
				<td>
					<Group noWrap h={52}>
						<Box w={32} h={32}>
							{entity && <SecodaEntityIcon entity={entity} size={32} />}
						</Box>
						<Stack spacing={0}>
							<Text
								color="text/primary/default"
								size="sm"
								fw="semibold"
								maw={250}
								lineClamp={1}
							>
								{resource.title}
							</Text>
							<Text color="text/secondary/default" size="xs">
								{snakeCaseToTitleCase(resource.entity_type as string)}
							</Text>
						</Stack>
					</Group>
				</td>
				{includeDocumentedStatus && (
					<td>
						{entity &&
							(!entity.definition && !entity.description ? (
								<StatusBadge label="Undocumented" color="yellow" />
							) : (
								<StatusBadge label="Documented" color="green" />
							))}
					</td>
				)}

				{infoColumns.map((col) => (
					<td key={col.fieldName}>{resource[col.fieldName]}</td>
				))}
			</tr>
		);
	});

	return (
		<Table ref={ref} highlightOnHover>
			<thead>
				<tr>
					<th>
						<Text color="text/secondary/default" fz="xs" fw="semibold">
							Resource
						</Text>
					</th>
					{includeDocumentedStatus && (
						<th>
							<Text color="text/secondary/default" fz="xs" fw="semibold">
								Documentation
							</Text>
						</th>
					)}
					{infoColumns.map((col) => (
						<th key={col.fieldName}>
							<Text color="text/secondary/default" fz="xs" fw="semibold">
								{col.label}
							</Text>
						</th>
					))}
				</tr>
			</thead>
			<tbody>{rows}</tbody>
		</Table>
	);
}
