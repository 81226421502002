import { makeAutoObservable, runInAction, toJS } from 'mobx';
import { createContext, useContext } from 'react';

import {
	DEFAULT_FILTER_OPTIONS,
	FILTER_OPTIONS_DIVIDER,
} from '@repo/common/components/Filter/constants';
import { FilterDropdownType } from '@repo/common/components/Filter/types';
import type { FilterOption, FilterOptionType, FilterValue } from '../../Filter';
import { FILTER_OPTIONS_CONFIG } from '../../Filter/constants';
import { getApiCatalogFilterFromFilterValues } from '../../Filter/utils';
import type { ApiCatalogFilterWithType, GovernanceFilter } from './types';

export interface AddedFilterResult {
	value: FilterValue;
	changeFilter: (value: Partial<FilterValue>) => void;
	clearFilter: () => void;
}

export class GovernanceFilterStore {
	filterOptions: (FilterOption | typeof FILTER_OPTIONS_DIVIDER)[];

	values: GovernanceFilter[];

	catalogFilters: ApiCatalogFilterWithType[] = [];

	constructor(
		filterOptions: (
			| FilterOptionType
			| typeof FILTER_OPTIONS_DIVIDER
		)[] = DEFAULT_FILTER_OPTIONS,
		initialValues: GovernanceFilter[] = []
	) {
		makeAutoObservable(this);

		this.filterOptions = filterOptions.map((option) => {
			if (option === FILTER_OPTIONS_DIVIDER) {
				return option;
			}
			return FILTER_OPTIONS_CONFIG[option];
		});

		this.values = initialValues;
		this.refreshCachedCatalogFilter();

		this.prefetchPromises();
	}

	async refreshCachedCatalogFilter() {
		const allFilters = toJS(this.values);

		if (allFilters.length === 0) {
			runInAction(() => {
				this.catalogFilters = [];
			});
		}

		const result: ApiCatalogFilterWithType[] = [];

		for (const filter of allFilters) {
			// eslint-disable-next-line no-await-in-loop
			const item = await getApiCatalogFilterFromFilterValues(
				filter.filterValues,
				'and'
			);
			if ((item?.operands?.length ?? 0) > 0) {
				result.push({
					...item!,
					type: filter.type,
				});
			}
		}

		runInAction(() => {
			this.catalogFilters = result;
		});
	}

	reset() {
		this.values = [];
		this.refreshCachedCatalogFilter();
	}

	prefetchPromises = () => {
		this.filterOptions.forEach((option) => {
			if (
				option === FILTER_OPTIONS_DIVIDER ||
				option.filterDropdownConfig.dropdownType !== FilterDropdownType.List
			) {
				return;
			}

			const { getItems } = option.filterDropdownConfig;

			if (typeof getItems !== 'function') {
				return;
			}

			Promise.resolve(getItems());
		});
	};

	onAddValue =
		(filterIndex: number) =>
		(value: FilterValue): AddedFilterResult => {
			const filterValueIdx = runInAction(() => {
				const newValues = [...this.values];
				newValues[filterIndex].filterValues.push(value);
				this.values = newValues;

				return this.values[filterIndex].filterValues.length - 1;
			});

			this.refreshCachedCatalogFilter();

			return {
				value: this.values[filterIndex].filterValues[filterValueIdx],
				changeFilter: this.onChangeValue(filterIndex)(filterValueIdx),
				clearFilter: this.onClearValue(filterIndex)(filterValueIdx),
			};
		};

	onChangeValue =
		(filterIndex: number) =>
		(filterValueIdx: number) =>
		(value: Partial<FilterValue>) => {
			if (Array.isArray(value.value) && value.value.length === 0) {
				this.onClearValue(filterIndex)(filterValueIdx)();
				return;
			}

			runInAction(() => {
				const tempArr = [...this.values];

				tempArr[filterIndex].filterValues[filterValueIdx] = {
					...tempArr[filterIndex].filterValues[filterValueIdx],
					...value,
				};

				this.values = tempArr;
			});

			this.refreshCachedCatalogFilter();
		};

	onClearValue = (filterIndex: number) => (idx: number) => () => {
		runInAction(() => {
			const tempArr = [...this.values];
			tempArr[filterIndex].filterValues.splice(idx, 1);
			this.values = tempArr;
		});

		this.refreshCachedCatalogFilter();
	};

	onAddFilter = () => {
		runInAction(() => {
			this.values.push({
				filterValues: [],
				type: 'included',
			});
		});

		this.refreshCachedCatalogFilter();
	};

	onRemoveFilter = (filterIndex: number) => () => {
		runInAction(() => {
			const tempArr = [...this.values];
			tempArr.splice(filterIndex, 1);
			this.values = [...tempArr];
		});

		this.refreshCachedCatalogFilter();
	};

	onToggleType = (filterIndex: number) => () => {
		const { type } = this.values[filterIndex];

		runInAction(() => {
			const tempArr = [...this.values];
			tempArr[filterIndex].type = type === 'excluded' ? 'included' : 'excluded';
			this.values = tempArr;
		});

		this.refreshCachedCatalogFilter();
	};
}

export const GovernanceFilterStoreContext =
	createContext<GovernanceFilterStore | null>(null);

export function useGovernanceFilterStore() {
	const store = useContext(GovernanceFilterStoreContext);

	if (!store) {
		throw new Error(
			'useGovernanceFilterStore must be used within a GovernanceFilterStoreContext'
		);
	}

	return store;
}
