import type { MantineThemeOverride } from '@mantine/core';

export const Image: MantineThemeOverride['components'] = {
	Image: {
		styles: () => ({
			image: {
				objectFit: 'contain',
			},
		}),
	},
};
