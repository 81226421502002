import { Box, createStyles, Group, Skeleton, Stack } from '@mantine/core';
import { IconSkeleton, ParagraphSkeleton } from '@repo/foundations';

export const useStyles = createStyles((theme) => ({
	wrapper: {
		borderRadius: theme.other.borderRadius.md,
		border: `1px solid ${theme.other.getColor('border/secondary/default')}`,
		overflow: 'hidden',
	},
	table: {
		padding: `${theme.spacing.sm} ${theme.spacing.md}`,
		borderTop: `1px solid ${theme.other.getColor('border/secondary/default')}`,
	},
}));

export function TableV2Skeleton() {
	const { classes } = useStyles();

	return (
		<Stack spacing="sm">
			<Group spacing="md" noWrap>
				<Skeleton width="100%" height={28} />
				<Group noWrap spacing="xs">
					<IconSkeleton size={28} />
					<IconSkeleton size={28} />
				</Group>
			</Group>

			<Skeleton width={92} height={24} />

			<Box className={classes.wrapper}>
				<Stack spacing={0}>
					<Skeleton width="100%" height={36} radius={0} />
					<Box className={classes.table}>
						<ParagraphSkeleton lines={5} />
					</Box>
				</Stack>
			</Box>
		</Stack>
	);
}
