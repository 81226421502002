import { Breadcrumbs, BreadcrumbsSkeleton } from '@repo/foundations';
import type { ISecodaEntity } from '../../api';
import { useEntityBreadcrumbs } from '../../hooks/useEntityBreadcrumbs';

interface EntityPageBreadcrumbsProps {
	entity: ISecodaEntity;
}

function EntityPageBreadcrumbs({ entity }: EntityPageBreadcrumbsProps) {
	const { isLoading, breadcrumbs } = useEntityBreadcrumbs(entity, {
		includeTeam: true,
		includeCurrentPage: true,
	});

	if (isLoading) {
		return <BreadcrumbsSkeleton />;
	}

	return (
		<Breadcrumbs
			data-testid="entity-page-layout-breadcrumbs"
			crumbs={breadcrumbs}
		/>
	);
}

export default EntityPageBreadcrumbs;
