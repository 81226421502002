import {
	Anchor,
	Box,
	createStyles,
	Group,
	useMantineTheme,
} from '@mantine/core';
import { Button, Icon, Text } from '@repo/foundations';
import { Link } from 'react-router-dom';
import { useWorkspace } from '../../api';
import { FREE_PLAN } from '../../data/plans';
import { usePlan } from '../../hooks/usePlans';
import { SeatsSettings } from './SeatsSettings';

const useStyles = createStyles(() => ({
	anchor: {
		display: 'flex',
		alignItems: 'center',
	},
}));

export function BillingSettings() {
	const { workspace } = useWorkspace();
	const { classes } = useStyles();
	const { daysLeftInTrial, trial, plan } = usePlan();
	const theme = useMantineTheme();

	const showPlanSelector = trial || plan?.id === FREE_PLAN.id;

	const planText = `You are currently on ${
		!trial
			? `the ${plan?.title} plan.`
			: `a free trial with ${daysLeftInTrial} days remaining.`
	}`;

	return (
		<Box>
			<Group spacing={8}>
				<Text size="sm" mb={theme.spacing.lg}>
					{planText}
				</Text>
				{showPlanSelector && (
					<Link to="/plans">
						<Anchor ml={8} size="sm" className={classes.anchor}>
							Select plan
							<Icon name="externalLink" />
						</Anchor>
					</Link>
				)}
			</Group>
			<SeatsSettings />
			{workspace.customer_id && workspace.customer_id.startsWith('cus_') && (
				<Box>
					<Button
						mt="2rem"
						onClick={() => {
							//  This path matching `billing/telemetry` is processed by a cf worker. It is intercepted by cf.
							window.open(
								`https://app.secoda.co/billing/telemetry/?customer_id=${workspace.customer_id}`,
								'_blank',
								'noopener,noreferrer'
							);
						}}
					>
						Billing settings
					</Button>
				</Box>
			)}
		</Box>
	);
}
