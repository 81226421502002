import { Menu } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { Icon } from '@repo/foundations';

export function CopyLinkAction() {
	const copyLocation = () => {
		navigator.clipboard.writeText(window.location.href);
		showNotification({
			message: 'Link copied',
			color: 'green',
		});
	};

	return (
		<Menu.Item icon={<Icon name="link" />} onClick={copyLocation}>
			Copy link
		</Menu.Item>
	);
}
