import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
	searchControls: {
		justifyContent: 'space-between',
		flexWrap: 'nowrap',
		display: 'flex',
		width: '100%',
	},
	padding: {
		paddingLeft: theme.spacing['2xs'],
		paddingRight: theme.spacing['2xs'],
		paddingTop: theme.spacing.xs,
		paddingBottom: theme.spacing.xs,
	},
}));
