import {
	Alert,
	Card,
	Center,
	createStyles,
	Group,
	rem,
	Stack,
} from '@mantine/core';
import type { ColorNames } from '@repo/theme/utils';
import { useMemo } from 'react';
import { Button, type ButtonProps, IconButton } from '../Buttons';
import { Icon, type IconNames } from '../Icon';
import { Text } from '../Text';
import { Title } from '../Title';

export const allBannerTones = [
	'caution',
	'critical',
	'info',
	'neutral',
	'success',
	'warning',
] as const;

export type BannerTones = (typeof allBannerTones)[number];

const BannerStyleMap: Record<BannerTones, BannerStyleProperties> = {
	caution: {
		icon: 'alertTriangle',
		fillColor: 'fill/caution/default',
		textOnFillColor: 'text/caution-on-fill/default',
		surfaceColor: 'surface/caution/default',
		textColor: 'text/caution/default',
	},
	critical: {
		icon: 'alertCircle',
		fillColor: 'fill/critical/default',
		textOnFillColor: 'text/critical-on-fill/default',
		surfaceColor: 'surface/critical/default',
		textColor: 'text/critical/default',
	},
	info: {
		icon: 'infoCircle',
		fillColor: 'fill/info-secondary/default',
		textOnFillColor: 'text/info-on-fill/default',
		surfaceColor: 'surface/info/default',
		textColor: 'text/info/default',
	},
	neutral: {
		icon: 'infoCircle',
		fillColor: 'surface/secondary/default',
		textOnFillColor: 'text/primary/default',
		surfaceColor: 'surface/secondary/default',
		textColor: 'text/primary/default',
	},
	success: {
		icon: 'check',
		fillColor: 'fill/success/default',
		textOnFillColor: 'text/success-on-fill/default',
		surfaceColor: 'surface/success/default',
		textColor: 'text/success/default',
	},
	warning: {
		icon: 'alertTriangle',
		fillColor: 'fill/warning/default',
		textOnFillColor: 'text/warning-on-fill/default',
		surfaceColor: 'surface/warning/default',
		textColor: 'text/warning/default',
	},
};

interface BannerStyleProperties {
	icon: IconNames;
	fillColor: ColorNames;
	textOnFillColor: ColorNames;
	surfaceColor: ColorNames;
	textColor: ColorNames;
}

interface BannerProps {
	message: string | React.ReactNode;
	tone?: BannerTones;
	title?: string;
	header?: boolean;
	isInsideCard?: boolean;
	onDismiss?: () => void;
	actions?: ButtonProps[];
}

interface BannerStyleProps {
	tone?: BannerTones;
	header: boolean;
}

const useStyles = createStyles((theme, { tone, header }: BannerStyleProps) => {
	const { fillColor, textOnFillColor, surfaceColor } =
		BannerStyleMap[tone ?? 'neutral'];

	return {
		cardWrapper: {
			width: '100%',
			borderRadius: theme.radius.md,
			boxShadow:
				'0px 0px 0px 1px rgba(0, 0, 0, 0.08) inset, 0px 4px 4px -1px rgba(0, 0, 0, 0.02)',
		},
		cardHeader: {
			display: 'flex',
			flexWrap: 'nowrap',
			justifyContent: 'space-between',
			height: `calc(${rem(theme.other.space[7])} + 2 * ${theme.spacing.xs})`, // height of the IconButton + y-axis padding
			padding: `${theme.spacing.xs} ${theme.spacing.xs} ${theme.spacing.xs} ${theme.spacing.md}`,
			gap: theme.spacing.xs,
			backgroundColor: theme.other.getColor(fillColor),
		},
		cardHeaderTitle: {
			flexWrap: 'nowrap',
			flexGrow: 1,
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
			gap: theme.spacing.xs,
		},
		closeIcon: {
			color: header ? theme.other.getColor(textOnFillColor) : 'inherit',
		},
		cardBody: {
			padding: header
				? `${theme.spacing.sm} ${theme.spacing.md} ${theme.spacing.md} ${theme.spacing.md}`
				: theme.spacing.sm,
		},
		iconWrapper: {
			width: theme.other.space[7],
			height: theme.other.space[7],
			padding: theme.spacing['3xs'],
			backgroundColor: theme.other.getColor(fillColor),
			borderRadius: theme.radius.sm,
		},
		alertRoot: {
			width: '100%',
			border: 'none',
			margin: 0,
			borderRadius: theme.radius.sm,
			backgroundColor: theme.other.getColor(surfaceColor),
		},
	};
});

function Banner({
	tone = 'neutral',
	message,
	title,
	header = false,
	isInsideCard = false,
	onDismiss,
	actions = [],
}: BannerProps) {
	const { classes } = useStyles({ tone, header });

	const renderActions = useMemo(
		() =>
			actions.length > 0 && (
				<Group spacing="sm">
					{actions.slice(0, 2).map((props, index) => (
						<Button key={index} {...props} />
					))}
				</Group>
			),
		[actions]
	);

	const renderCloseButton = useMemo(
		() =>
			onDismiss && (
				<IconButton
					classNames={{ icon: classes.closeIcon }}
					variant="tertiary"
					iconName="x"
					onClick={() => onDismiss()}
				/>
			),
		[onDismiss, classes.closeIcon]
	);

	const renderBanner = useMemo(
		() => (
			<Card className={classes.cardWrapper}>
				<Card.Section className={classes.cardBody}>
					<Group spacing="xs" position="apart" noWrap>
						<Group spacing="xs" noWrap>
							<Center className={classes.iconWrapper}>
								<Icon
									name={BannerStyleMap[tone].icon}
									color={BannerStyleMap[tone].textOnFillColor}
								/>
							</Center>
							<Text size="sm">{message}</Text>
						</Group>
						<Group spacing="sm" noWrap>
							{renderActions}
							{renderCloseButton}
						</Group>
					</Group>
				</Card.Section>
			</Card>
		),
		[
			tone,
			message,
			classes.cardWrapper,
			classes.cardBody,
			classes.iconWrapper,
			renderActions,
			renderCloseButton,
		]
	);

	if (!tone) {
		return renderBanner;
	}

	if (isInsideCard) {
		return (
			<Alert classNames={{ root: classes.alertRoot }}>
				<Group
					align={actions.length > 0 ? 'flex-start' : 'center'}
					position="apart"
					spacing="xs"
					noWrap
				>
					<Group align="flex-start" spacing="xs" noWrap>
						<Icon
							name={BannerStyleMap[tone].icon}
							color={BannerStyleMap[tone].textColor}
						/>
						<Stack spacing="xs">
							<Text size="sm" color={BannerStyleMap[tone].textColor}>
								{message}
							</Text>
							{renderActions}
						</Stack>
					</Group>
					{renderCloseButton}
				</Group>
			</Alert>
		);
	}

	if (header) {
		return (
			<Card className={classes.cardWrapper}>
				<Card.Section className={classes.cardHeader}>
					<Group className={classes.cardHeaderTitle}>
						<Icon
							name={BannerStyleMap[tone].icon}
							color={BannerStyleMap[tone].textOnFillColor}
						/>
						<Title
							size="sm"
							color={BannerStyleMap[tone].textOnFillColor}
							truncate
						>
							{title}
						</Title>
					</Group>
					{renderCloseButton}
				</Card.Section>
				<Card.Section className={classes.cardBody}>
					<Stack spacing="xs">
						<Text size="sm">{message}</Text>
						{renderActions}
					</Stack>
				</Card.Section>
			</Card>
		);
	}

	return renderBanner;
}

export { Banner };
