import type { IColumn } from '../../../../api';

import type { ICommandListItem } from '../../../../components/Spotlight/components/CommandPalette/constants';
import { useActions as useCatalogActions } from '../../../TeamCatalogPage/TeamCatalogPage.hooks';

export const useActions = () => {
	const actions = useCatalogActions() as unknown as ICommandListItem<IColumn>[];

	return actions;
};
