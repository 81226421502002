import type { Fragment, NodeType } from 'prosemirror-model';
import { liftListItem, wrapInList } from 'prosemirror-schema-list';
import type { EditorState } from 'prosemirror-state';
import { findParentNode } from 'prosemirror-utils';
import chainTransactions from '../lib/chainTransactions';
import isList from '../queries/isList';
import type { Dispatch } from '../types';
import clearNodes from './clearNodes';

function canConvertFragment(fragment: Fragment, itemType: NodeType) {
	for (let i = 0; i < fragment.childCount; i += 1) {
		const child = fragment.child(i);

		if (!itemType.validContent(child.content)) {
			return false;
		}
	}

	return true;
}

export default function toggleList(listType: NodeType, itemType: NodeType) {
	return (state: EditorState, dispatch?: Dispatch) => {
		const { schema, selection } = state;
		const { $from, $to } = selection;
		const range = $from.blockRange($to);
		const { tr } = state;

		if (!range) {
			return false;
		}

		const parentList = findParentNode((node) => isList(node, schema))(
			selection
		);

		if (range.depth >= 1 && parentList && range.depth - parentList.depth <= 1) {
			if (parentList.node.type === listType) {
				return liftListItem(itemType)(state, dispatch);
			}

			const validContent = listType.validContent(parentList.node.content);
			const canConvert = canConvertFragment(parentList.node.content, itemType);

			if (isList(parentList.node, schema) && (validContent || canConvert)) {
				let transaction = tr;

				if (!validContent && canConvert) {
					let newContent = parentList.node.content;
					for (let i = 0; i < parentList.node.content.childCount; i += 1) {
						const child = parentList.node.content.child(i);
						newContent = newContent.replaceChild(
							i,
							itemType.create(child.attrs, child.content, child.marks)
						);
					}

					transaction = transaction.replaceWith(
						parentList.pos,
						parentList.pos + parentList.node.nodeSize,
						newContent
					);
				}

				transaction = transaction.setNodeMarkup(parentList.pos, listType);

				dispatch?.(transaction);
				return false;
			}
		}

		const canWrapInList = wrapInList(listType)(state);

		if (canWrapInList) {
			return wrapInList(listType)(state, dispatch);
		}

		return chainTransactions(clearNodes(), wrapInList(listType))(
			state,
			dispatch
		);
	};
}
