import { createStyles } from '@mantine/core';

export const useStyle = createStyles((theme) => ({
	tab: {
		padding: theme.other.space[2],
	},
	tabsList: {
		paddingLeft: theme.other.space[4],
		paddingRight: theme.other.space[4],
		borderBottom: `${theme.other.borderWidth.sm}px solid ${theme.other.getColor('border/secondary/default')}`,
	},
}));
