import { useMantineTheme } from '@mantine/core';

const SPARKLE1 = {
	from: 'M12.6667 14C13.0204 14 13.3595 14.1405 13.6096 14.3905C13.8596 14.6406 14.0001 14.9797 14.0001 15.3333C14.0001 14.9797 14.1406 14.6406 14.3906 14.3905C14.6407 14.1405 14.9798 14 15.3334 14C14.9798 14 14.6407 13.8595 14.3906 13.6095C14.1406 13.3594 14.0001 13.0203 14.0001 12.6667C14.0001 13.0203 13.8596 13.3594 13.6096 13.6095C13.3595 13.8595 13.0204 14 12.6667 14Z',
	to: 'M13 14C13.2652 14 13.5196 14.1054 13.7071 14.2929C13.8946 14.4804 14 14.7348 14 15C14 14.7348 14.1054 14.4804 14.2929 14.2929C14.4804 14.1054 14.7348 14 15 14C14.7348 14 14.4804 13.8946 14.2929 13.7071C14.1054 13.5196 14 13.2652 14 13C14 13.2652 13.8946 13.5196 13.7071 13.7071C13.5196 13.8946 13.2652 14 13 14Z',
};

const SPARKLE2 = {
	from: 'M9.17157 11.1716C8.42143 11.9217 8 12.9391 8 14C8 12.9391 7.57857 11.9217 6.82843 11.1716C6.07828 10.4214 5.06087 10 4 10C5.06087 10 6.07828 9.57857 6.82843 8.82843C7.57857 8.07828 8 7.06087 8 6C8 7.06087 8.42143 8.07828 9.17157 8.82843C9.92172 9.57857 10.9391 10 12 10C10.9391 10 9.92172 10.4214 9.17157 11.1716Z',
	to: 'M8.58579 10.5858C8.21071 10.9609 8 11.4696 8 12C8 11.4696 7.78929 10.9609 7.41421 10.5858C7.03914 10.2107 6.53043 10 6 10C6.53043 10 7.03914 9.78929 7.41421 9.41421C7.78929 9.03914 8 8.53043 8 8C8 8.53043 8.21071 9.03914 8.58579 9.41421C8.96086 9.78929 9.46957 10 10 10C9.46957 10 8.96086 10.2107 8.58579 10.5858Z',
};

const SPARKLE3 = {
	from: 'M13.6096 6.39053C13.3595 6.14048 13.0204 6.00001 12.6667 6.00001C13.0204 6.00001 13.3595 5.85953 13.6096 5.60948C13.8596 5.35943 14.0001 5.02029 14.0001 4.66667C14.0001 5.02029 14.1406 5.35943 14.3906 5.60948C14.6407 5.85953 14.9798 6.00001 15.3334 6.00001C14.9798 6.00001 14.6407 6.14048 14.3906 6.39053C14.1406 6.64058 14.0001 6.97972 14.0001 7.33334C14.0001 6.97972 13.8596 6.64058 13.6096 6.39053Z',
	to: 'M13.1213 6.87868C12.5587 6.31607 11.7956 6 11 6C11.7956 6 12.5587 5.68393 13.1213 5.12132C13.6839 4.55871 14 3.79565 14 3C14 3.79565 14.3161 4.55871 14.8787 5.12132C15.4413 5.68393 16.2044 6 17 6C16.2044 6 15.4413 6.31607 14.8787 6.87868C14.3161 7.44129 14 8.20435 14 9C14 8.20435 13.6839 7.44129 13.1213 6.87868Z',
};

export function AIIconAnimated({
	className,
	speed = '1s',
}: {
	className?: string;
	speed?: string;
}) {
	const theme = useMantineTheme();

	return (
		<svg
			id="shape"
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			<path
				d={SPARKLE1.from}
				stroke={theme.white}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			>
				<animate
					id="animation1"
					begin="0s; animation1.end"
					attributeName="d"
					dur={speed}
					values={`${SPARKLE1.from};${SPARKLE1.to};${SPARKLE1.from}`}
				/>
			</path>
			<path
				d={SPARKLE2.from}
				stroke={theme.white}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			>
				<animate
					begin="0s; animation1.end"
					attributeName="d"
					dur={speed}
					values={`${SPARKLE2.from};${SPARKLE2.to};${SPARKLE2.from}`}
				/>
			</path>
			<path
				d={SPARKLE3.from}
				stroke={theme.white}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			>
				<animate
					begin="0s; animation1.end"
					attributeName="d"
					dur={speed}
					values={`${SPARKLE3.from};${SPARKLE3.to};${SPARKLE3.from}`}
				/>
			</path>
		</svg>
	);
}
