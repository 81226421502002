import { TextInput } from '@mantine/core';
import { usePublicEnv } from '../../api/hooks/utils/usePublicEnv';

export function IntegrationAWSAccountID() {
	const { data } = usePublicEnv();

	return (
		<TextInput
			disabled
			value={data?.AWS_ACCOUNT_ID}
			description="On the AWS cross account role copy this value to the Account ID field"
			label="AWS Account ID"
		/>
	);
}
