import { baseQueryHooksFactory } from '../../factories';
import type { INotification } from '../../types/models/notification';
import { notificationQueryKeyFactory } from './constants';
import { useDeleteAllNotifications } from './useDeleteAllNotifications';
import { useMarkNotificationAsRead } from './useMarkNotificationAsRead';
import { useUnreadNotificationCount } from './useUnreadNotificationCount';

const {
	useNotification,
	useNotificationInfiniteList,
	useNotificationList,
	useDeleteNotification,
} = baseQueryHooksFactory<INotification, 'notification'>(
	'notification',
	notificationQueryKeyFactory
);

export {
	useDeleteAllNotifications,
	useDeleteNotification,
	useMarkNotificationAsRead,
	useNotification,
	useNotificationInfiniteList,
	useNotificationList,
	useUnreadNotificationCount,
};
