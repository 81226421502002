import { colors } from '@repo/theme/primitives';

interface InfoCircleFilledIconProps {
	size?: number | string;
	color?: string;
}

export function InfoCircleFilledIcon({
	size,
	color,
}: InfoCircleFilledIconProps) {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M12 2C17.523 2 22 6.477 22 12C22.0021 14.6255 20.9715 17.1464 19.1309 19.0186C17.2902 20.8908 14.7872 21.9641 12.162 22.0066C9.53689 22.0491 7.00034 21.0576 5.1 19.246C3.19966 17.4344 2.08799 14.9482 2.005 12.324L2 12L2.004 11.72C2.152 6.327 6.57 2 12 2ZM12 11H11L10.883 11.007C10.64 11.0359 10.4159 11.153 10.2534 11.336C10.0909 11.519 10.0011 11.7552 10.0011 12C10.0011 12.2448 10.0909 12.481 10.2534 12.664C10.4159 12.847 10.64 12.9641 10.883 12.993L11 13V16L11.007 16.117C11.0333 16.3402 11.1341 16.5481 11.293 16.707C11.4519 16.8659 11.6598 16.9667 11.883 16.993L12 17H13L13.117 16.993C13.3402 16.9667 13.5481 16.8659 13.707 16.707C13.8659 16.5481 13.9667 16.3402 13.993 16.117L14 16L13.993 15.883C13.9691 15.679 13.8829 15.4873 13.7462 15.3339C13.6095 15.1806 13.4289 15.0731 13.229 15.026L13.117 15.006L13 15V12L12.993 11.883C12.9667 11.6598 12.8659 11.4519 12.707 11.293C12.5481 11.1341 12.3402 11.0333 12.117 11.007L12 11ZM12.01 8L11.883 8.007C11.64 8.03591 11.4159 8.15296 11.2534 8.33597C11.0909 8.51897 11.0011 8.75524 11.0011 9C11.0011 9.24476 11.0909 9.48103 11.2534 9.66403C11.4159 9.84704 11.64 9.96409 11.883 9.993L12 10L12.127 9.993C12.37 9.96409 12.5941 9.84704 12.7566 9.66403C12.9191 9.48103 13.0089 9.24476 13.0089 9C13.0089 8.75524 12.9191 8.51897 12.7566 8.33597C12.5941 8.15296 12.37 8.03591 12.127 8.007L12.01 8Z"
				fill={color || colors.gray[8]}
			/>
		</svg>
	);
}

interface MessageFilledProps {
	size?: number | string;
	color?: string;
}

export function MessageFilledIcon({ size, color }: MessageFilledProps) {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M18 4C18.7956 4 19.5587 4.31607 20.1213 4.87868C20.6839 5.44129 21 6.20435 21 7V15C21 15.7956 20.6839 16.5587 20.1213 17.1213C19.5587 17.6839 18.7956 18 18 18H13L8 21V18H6C5.20435 18 4.44129 17.6839 3.87868 17.1213C3.31607 16.5587 3 15.7956 3 15V7C3 6.20435 3.31607 5.44129 3.87868 4.87868C4.44129 4.31607 5.20435 4 6 4H18Z"
				fill={color || colors.gray[8]}
				stroke={color || colors.gray[8]}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M8.00098 9H16.001M8.00098 13H14.001"
				stroke="#EBEBEB"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

interface SparklesFilledIconProps {
	size?: number | string;
	color?: string;
}

export function SparklesFilledIcon({ size, color }: SparklesFilledIconProps) {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M16 18C16.5304 18 17.0391 18.2107 17.4142 18.5858C17.7893 18.9609 18 19.4696 18 20C18 19.4696 18.2107 18.9609 18.5858 18.5858C18.9609 18.2107 19.4696 18 20 18C19.4696 18 18.9609 17.7893 18.5858 17.4142C18.2107 17.0391 18 16.5304 18 16C18 16.5304 17.7893 17.0391 17.4142 17.4142C17.0391 17.7893 16.5304 18 16 18ZM16 6C16.5304 6 17.0391 6.21071 17.4142 6.58579C17.7893 6.96086 18 7.46957 18 8C18 7.46957 18.2107 6.96086 18.5858 6.58579C18.9609 6.21071 19.4696 6 20 6C19.4696 6 18.9609 5.78929 18.5858 5.41421C18.2107 5.03914 18 4.53043 18 4C18 4.53043 17.7893 5.03914 17.4142 5.41421C17.0391 5.78929 16.5304 6 16 6ZM9 18C9 16.4087 9.63214 14.8826 10.7574 13.7574C11.8826 12.6321 13.4087 12 15 12C13.4087 12 11.8826 11.3679 10.7574 10.2426C9.63214 9.11742 9 7.5913 9 6C9 7.5913 8.36786 9.11742 7.24264 10.2426C6.11742 11.3679 4.5913 12 3 12C4.5913 12 6.11742 12.6321 7.24264 13.7574C8.36786 14.8826 9 16.4087 9 18Z"
				fill={color || colors.gray[8]}
				stroke={color || colors.gray[8]}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
