import {
	Checkbox,
	Divider,
	Group,
	Stack,
	useMantineTheme,
} from '@mantine/core';
import { EntityType } from '@repo/common/enums/entityType';
import { Button, Select, Text, TextInput } from '@repo/foundations';
import { isEmpty, isNil } from 'lodash-es';
import { useState } from 'react';
import { useAuthUser } from '../../api';

export function PropertyForm({
	fields,
	onCancel,
	onConfirm,
	existingLabels,
	initialValues = { label: '', type: '', visibility: [] },
}: {
	fields: Array<'label' | 'type' | 'visibility'>;
	onCancel: () => void;
	onConfirm: (label: string, type: string, visibility: EntityType[]) => void;
	existingLabels: string[];
	initialValues?: {
		label: string;
		type: string;
		visibility: EntityType[];
	};
}) {
	const theme = useMantineTheme();
	const { workspace } = useAuthUser();
	const [label, setLabel] = useState(initialValues.label);
	const [type, setType] = useState(initialValues.type);
	const [visibility, setVisibility] = useState<EntityType[]>(
		initialValues.visibility
	);
	const [errors, setErrors] = useState<Record<string, string>>({});

	const handleConfirm = () => {
		const newErrors: Record<string, string> = {};

		if (existingLabels.includes(label) && label !== initialValues.label) {
			newErrors.label = 'A column with this name already exists';
		} else if (fields.includes('label') && !/^[a-zA-Z\s]+$/.test(label)) {
			newErrors.label = 'Label must be alphabetical characters';
		} else if (fields.includes('label') && isEmpty(label)) {
			newErrors.label = 'Label is required';
		} else if (fields.includes('type') && isEmpty(type)) {
			newErrors.type = 'A type selection is required';
		}

		if (Object.keys(newErrors).length === 0) {
			onConfirm(label, type, visibility);
		} else {
			setErrors(newErrors);
		}
	};

	return (
		<Stack>
			{fields.includes('label') && (
				<TextInput
					label="Label"
					value={label}
					onChange={(event) => {
						setLabel(event.currentTarget.value);
						setErrors({ ...errors, label: '' });
					}}
					error={errors.label}
				/>
			)}
			{fields.includes('type') && (
				<Select
					label="Type"
					data={[
						{ value: 'string', label: 'Text' },
						{ value: 'number', label: 'Number' },
						{ value: 'boolean', label: 'Checkbox' },
						{ value: 'date', label: 'Date' },
						{ value: 'select', label: 'Dropdown' },
						{ value: 'resource', label: 'Resource' },
						{ value: 'user', label: 'User' },
					]}
					value={type}
					disabled={!isEmpty(initialValues.type) && !isNil(initialValues.type)}
					onChange={(value) => {
						setType(value || '');
						setErrors({ ...errors, type: '' });
					}}
					error={errors.type}
				/>
			)}
			{fields.includes('visibility') && (
				<Stack spacing={'sm'}>
					<Text size="sm" weight="semibold">
						Visibility
					</Text>
					<Stack spacing={theme.other.space[3]}>
						<Checkbox
							label="Catalog"
							checked={visibility.includes(EntityType.all)}
							onChange={(event) => {
								if (event.currentTarget.checked) {
									setVisibility([...visibility, EntityType.all]);
								} else {
									setVisibility(visibility.filter((v) => v !== EntityType.all));
								}
							}}
						/>
						<Checkbox
							label="Tables"
							checked={visibility.includes(EntityType.table)}
							onChange={(event) => {
								if (event.currentTarget.checked) {
									setVisibility([...visibility, EntityType.table]);
								} else {
									setVisibility(
										visibility.filter((v) => v !== EntityType.table)
									);
								}
							}}
						/>
						<Checkbox
							label="Columns"
							checked={visibility.includes(EntityType.column)}
							onChange={(event) => {
								if (event.currentTarget.checked) {
									setVisibility([...visibility, EntityType.column]);
								} else {
									setVisibility(
										visibility.filter((v) => v !== EntityType.column)
									);
								}
							}}
						/>
						<Checkbox
							label="Collections"
							checked={visibility.includes(EntityType.collection)}
							onChange={(event) => {
								if (event.currentTarget.checked) {
									setVisibility([...visibility, EntityType.collection]);
								} else {
									setVisibility(
										visibility.filter((v) => v !== EntityType.collection)
									);
								}
							}}
						/>
						<Checkbox
							label="Glossary"
							checked={visibility.includes(EntityType.glossary)}
							onChange={(event) => {
								if (event.currentTarget.checked) {
									setVisibility([...visibility, EntityType.glossary]);
								} else {
									setVisibility(
										visibility.filter((v) => v !== EntityType.glossary)
									);
								}
							}}
						/>
						<Checkbox
							label="Documents"
							checked={visibility.includes(EntityType.document)}
							onChange={(event) => {
								if (event.currentTarget.checked) {
									setVisibility([...visibility, EntityType.document]);
								} else {
									setVisibility(
										visibility.filter((v) => v !== EntityType.document)
									);
								}
							}}
						/>
						<Checkbox
							label="Questions"
							checked={visibility.includes(EntityType.question)}
							onChange={(event) => {
								if (event.currentTarget.checked) {
									setVisibility([...visibility, EntityType.question]);
								} else {
									setVisibility(
										visibility.filter((v) => v !== EntityType.question)
									);
								}
							}}
						/>
					</Stack>
				</Stack>
			)}

			<Divider
				mx={`-${theme.spacing.md}`}
				px={`${theme.spacing.md}`}
				mt={'sm'}
			/>
			<Group position="right">
				<Button variant="default" onClick={onCancel}>
					Cancel
				</Button>
				<Button variant="primary" onClick={handleConfirm}>
					Save
				</Button>
			</Group>
		</Stack>
	);
}
