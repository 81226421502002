import { Stack } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { Button } from '@repo/foundations';
import { useCallback } from 'react';
import { useAuthUser } from '../../api';
import type { SecodaEntity } from '../../lib/models';
import { trackEvent } from '../../utils/analytics';

import AnnouncementModal from './AnnouncementModal';

export interface IAnnouncementProps {
	entity?: SecodaEntity;
}

function Announcement({ entity }: IAnnouncementProps) {
	const [opened, { close, toggle }] = useDisclosure(false);
	const { user, workspace } = useAuthUser();

	const clickCreateAnnouncement = useCallback(() => {
		toggle();
		trackEvent('inbox/new_announcement_toggle/click', {}, user, workspace);
	}, [toggle, user, workspace]);

	return (
		<Stack>
			<Button
				onClick={clickCreateAnnouncement}
				data-testid="announcement-button"
			>
				Create announcement
			</Button>
			<AnnouncementModal entity={entity} opened={opened} close={close} />
		</Stack>
	);
}

export default Announcement;
