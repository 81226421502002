import { Checkbox, Group, Menu } from '@mantine/core';
import { Icon } from '@repo/foundations';
import { filter, includes, isEmpty, isNil } from 'lodash-es';
import { useMemo } from 'react';
import { setParamsInUrl } from '../../../../utils/url';
import type { EventType } from '../types';

interface IInboxFilterItemProps {
	event: EventType;
	selected: string | null;
	setSelected: (events: string) => void;
}

function InboxFilterItem({
	event,
	selected,
	setSelected,
}: IInboxFilterItemProps) {
	const selectedArr = useMemo(
		() => (isEmpty(selected) || isNil(selected) ? [] : selected.split(',')),
		[selected]
	);

	const isSelected = useMemo(
		() => includes(selectedArr, event.value),
		[event, selectedArr]
	);

	const handleOnClick = () => {
		let newSelected = selectedArr;
		if (isSelected) {
			newSelected = filter(newSelected, (value) => value !== event.value);
			setSelected(newSelected.join(','));
		} else {
			newSelected.push(event.value);
			setSelected(newSelected.join(','));
		}

		setParamsInUrl('events', newSelected.join(','));
	};

	return (
		<Menu.Item icon={<Checkbox checked={isSelected} />} onClick={handleOnClick}>
			<Group spacing="xs">
				<Icon name={event.iconName} />
				{event.label}
			</Group>
		</Menu.Item>
	);
}

export default InboxFilterItem;
