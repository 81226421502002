import { useCallback, useState } from 'react';
import {
	type IDatabuilderJob,
	integrationsQueryKeyFactory,
	queryClient,
	useAuthUser,
	useCreateDatabuilderJob,
} from '../../../api';
import {
	forceFetchJobs,
	usePollDatabuilderJobList,
} from '../../../api/hooks/databuilderJob/usePollDatabuilderJob';
import { resourceCatalogQueryKeyFactory } from '../../../api/hooks/resourceCatalog/constants';
import { DatabuilderJobType } from '../../../interfaces';
import { trackEvent } from '../../../utils/analytics';

interface UseIntegrationSyncProps {
	id: string;
	type?: 'PULL' | 'PUSH';
}

const useIntegrationExtraction = ({
	id,
	type = 'PULL',
}: UseIntegrationSyncProps) => {
	const { user, workspace } = useAuthUser();

	const {
		databuilderJobs: extractionJobs,
		isLoading,
		polling,
		setExtractionPolling,
	} = usePollDatabuilderJobList({
		integrationId: id,
		type,
	});

	const { mutate: createJob } = useCreateDatabuilderJob({
		options: {
			onSuccess: forceFetchJobs,
		},
	});

	const [showExtractionDetail, setShowExtractionDetail] = useState(false);
	const [extractionDetailId, setExtractionDetailId] = useState<string>('');

	const run = useCallback(async () => {
		if (!polling) {
			try {
				setExtractionPolling(true);
				await createJob({
					data: { integration_id: id, type: DatabuilderJobType.METADATA },
				});
				trackEvent('integrations/run_extraction/click', {}, user, workspace!);
			} catch (error) {
				// eslint-disable-next-line no-console
				console.error(error);
				setExtractionPolling(false);
			}
		}
	}, [createJob, id, polling, setExtractionPolling, user, workspace]);

	const handleRunExtraction = useCallback(() => {
		// Reset the Catalog Cache.
		queryClient.invalidateQueries(resourceCatalogQueryKeyFactory.allLists());
		run();
		queryClient.invalidateQueries(integrationsQueryKeyFactory.allLists());
	}, [run]);

	const handleSetActiveExtraction = useCallback((job: IDatabuilderJob) => {
		setExtractionDetailId(job.id);
		setShowExtractionDetail(true);
	}, []);

	return {
		extractionJobs,
		isLoading,
		polling,
		setExtractionPolling,
		handleRunExtraction,
		handleSetActiveExtraction,
		extractionDetailId,
		setExtractionDetailId,
		showExtractionDetail,
		setShowExtractionDetail,
	};
};

export default useIntegrationExtraction;
