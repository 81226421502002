import { Group } from '@mantine/core';
import { Icon, Text } from '@repo/foundations';
import { isEmpty, noop } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import type { ITemplate } from '../../../api';
import { openConfirmModal } from '../../ModalManager';
import { ENTITY_TYPES_MODAL_CONFIG } from '../constants';
import { entityModalStore } from '../store';
import { TeamSelector } from './TeamSelector';
import { TemplateSelector } from './TemplateSelector';

function MetadataHeaderInternal() {
	const handleTeamChange = useCallback((value: string) => {
		entityModalStore.teamId = value;
	}, []);

	const handleTemplateChange = useCallback((value: ITemplate) => {
		const set = () => {
			entityModalStore.setTemplate(value);
		};

		if (
			!isEmpty(entityModalStore.description) &&
			entityModalStore.template?.id !== value?.id
		) {
			openConfirmModal({
				title: 'Template overwrite',
				children: (
					<Text size="sm">
						If you change the template, the description will be overwritten with
						the template contents. Please copy your description before changing
						the template.
					</Text>
				),
				labels: { confirm: 'Confirm', cancel: 'Cancel' },
				confirmProps: { variant: 'primary' },
				onCancel: noop,
				onConfirm: set,
			});
		} else {
			set();
		}
	}, []);

	const withTemplate =
		entityModalStore.type &&
		ENTITY_TYPES_MODAL_CONFIG[entityModalStore.type].withTemplate;

	return (
		<Group spacing="xs">
			<TeamSelector onChange={handleTeamChange} editorOnly={true} />
			{withTemplate && (
				<>
					<Icon name="chevronRight" />
					<TemplateSelector
						onChange={handleTemplateChange}
						entityType={entityModalStore.type!}
						initialValue={entityModalStore.template?.id}
					/>
				</>
			)}
		</Group>
	);
}

export const MetadataHeader = observer(MetadataHeaderInternal);
