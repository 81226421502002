import { Group } from '@mantine/core';
import type { ComponentPropsWithoutRef, ReactNode } from 'react';
import { forwardRef } from 'react';
import { Text } from '../Text';

const ItemComponent = forwardRef<
	HTMLDivElement,
	ComponentPropsWithoutRef<'div'> & {
		label: ReactNode;
		icon: ReactNode;
		'data-disabled'?: boolean;
		'data-hovered'?: boolean;
		'data-selected'?: boolean;
	}
>((props, ref) => {
	const { label, icon, id, className, onMouseEnter, onMouseDown } = props;

	return (
		<div
			data-testid={`select-item-${label}`}
			ref={ref}
			key={id}
			className={className}
			onMouseDown={onMouseDown}
			onMouseEnter={onMouseEnter}
			data-disabled={props['data-disabled']}
			data-hovered={props['data-hovered']}
			data-selected={props['data-selected']}
		>
			<Group noWrap spacing={0}>
				{icon}
				<Text ml={4} size="sm">
					{label}
				</Text>
			</Group>
		</div>
	);
});

export default ItemComponent;
