import { Box, Group, Stack } from '@mantine/core';
import {
	IconSkeleton,
	ParagraphSkeleton,
	TextSkeleton,
	TitleSkeleton,
} from '@repo/foundations';
import { useParams } from 'react-router';
import { DOCUMENTATION_WIDTH } from '../../components/Documentation/constants';
import { EntityPageSidebarSkeleton } from '../../components/EntityPageLayout/EntityPageSidebarSkeleton';
import {
	PageLayoutContent,
	PageLayoutContentWrapper,
	PageLayoutOuterWrapper,
	PageLayoutWrapper,
} from '../../components/PageLayout';
import { RightSidebarWrapper } from '../../components/RightSidebar';
import {
	NavBarBreadcrumbWrapper,
	NavBarSidesheetControlsWrapper,
	NavBarWrapper,
} from '../../components/SecodaAppShell/SecodaNavBar';
import { useFullWidthToggle } from '../../hooks/useFullWidthToggle';

export function GlossaryTermPageSkeleton() {
	const { id } = useParams();
	const { shouldSetFullWidth } = useFullWidthToggle(id);

	return (
		<PageLayoutOuterWrapper>
			<PageLayoutWrapper name="glossary-term-page-skeleton">
				<PageLayoutContentWrapper name="glossary-term-page-skeleton">
					<NavBarWrapper>
						<NavBarBreadcrumbWrapper>
							<Box px="xs">
								<TextSkeleton width={64} height={8} />
							</Box>
						</NavBarBreadcrumbWrapper>
						<NavBarSidesheetControlsWrapper>
							<Group spacing="xs">
								<IconSkeleton size={28} />
								<IconSkeleton size={28} />
								<IconSkeleton size={28} />
								<IconSkeleton size={28} />
							</Group>
						</NavBarSidesheetControlsWrapper>
					</NavBarWrapper>
					<PageLayoutContent>
						<Stack
							spacing="md"
							w={shouldSetFullWidth ? '100%' : DOCUMENTATION_WIDTH}
							mx="auto"
						>
							<Stack spacing="sm" pt="3xl">
								<IconSkeleton size={28} />
								<TitleSkeleton size="xl" text="Entity name" />
							</Stack>
							<ParagraphSkeleton lines={3} />
							<Stack spacing="xs" pt="xl">
								<Group spacing="md" h={28}>
									<TextSkeleton width={96} height={8} />
									<TextSkeleton width={96} height={8} />
								</Group>
								<Group spacing="md" h={28}>
									<TextSkeleton width={96} height={8} />
									<TextSkeleton width={96} height={8} />
								</Group>
							</Stack>
						</Stack>
					</PageLayoutContent>
				</PageLayoutContentWrapper>
				<RightSidebarWrapper>
					<EntityPageSidebarSkeleton />
				</RightSidebarWrapper>
			</PageLayoutWrapper>
		</PageLayoutOuterWrapper>
	);
}
