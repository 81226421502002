import type { CSSObject } from '@mantine/core';
import {
	Box,
	Group,
	Menu,
	Skeleton,
	Stack,
	Tooltip,
	createStyles,
} from '@mantine/core';
import { useOs } from '@mantine/hooks';
import type {
	FilterValue,
	FilterValueType,
} from '@repo/common/components/Filter/types.ts';
import { FilterOperator } from '@repo/common/components/Filter/types.ts';
import { Icon, IconButton, Text } from '@repo/foundations';
import type { DataTableProps } from '@repo/mantine-datatable';
import { DataTable } from '@repo/mantine-datatable';
import { observer } from 'mobx-react-lite';
import { unparse } from 'papaparse';
import { Suspense, useCallback, useContext, useMemo, useRef } from 'react';
import type { Measurement, Monitor } from '../../../api';
import { useMeasurementInfiniteList } from '../../../api';
import { ErrorBoundary } from '../../../components/ErrorBoundary';
import {
	FilterOptionType,
	SearchFilterV2StoreContext,
} from '../../../components/Filter/index.ts';
import {
	DEFAULT_MAX_RECORD_SIZE,
	DEFAULT_PAGINATION_SIZE,
} from '../../../components/TableV2/constants.ts';
import {
	BOTTOM_PADDING,
	FOOTER_HEIGHT,
	HEADER_HEIGHT,
	ROW_HEIGHT,
	rowSx,
	useTableStyles,
} from '../../../components/TableV2/TableV2.styles.ts';
import { TableV2Loader } from '../../../components/TableV2/TableV2.tsx';
import { TableV2Header } from '../../../components/TableV2/TableV2Header.tsx';
import { saveBlob } from '../../../lib/models/misc.ts';
import { useIncidentDrawer } from './IncidentDrawerContext';
import MeasurementsFilterBar from './MeasurementsFilterBar.tsx';
import { useColumns } from './V2MeasurementsTable.hooks.tsx';

interface V2MeasurementsTableProps {
	monitor: Monitor;
	hideTitle?: boolean;
}

const useStyles = createStyles((theme) => ({
	root: {
		height: '100%',
		minHeight: `${theme.other.space[120]}px`,
		flexGrow: 1,
	},
	historyTitle: {
		color: theme.other.getColor('text/primary/default'),
		fontWeight:
			`${theme.other.typography.weight.bold} !important` as CSSObject['fontWeight'],
	},
}));

const DataTableComponent = DataTable as <T>(
	props: Omit<DataTableProps<T>, 'page'> &
		Partial<{
			withStickyColumnBorder?: boolean;
			page: number & Partial<{ paginationSize: 'sm' | 'md' | 'lg' }>;
			nested: boolean;
			endReached?: () => void;
			nextPageFetching?: boolean;
			maxHeight?: number;
		}>
) => JSX.Element;

function V2MeasurementsTable({ monitor, hideTitle }: V2MeasurementsTableProps) {
	// Styles
	const { classes } = useStyles();
	const { classes: tableClasses } = useTableStyles({
		hideCheckbox: true,
		noBorder: true,
	});

	// Incident drawer
	const { openIncident } = useIncidentDrawer();
	const handleOpenIncident = useCallback(
		(record: Measurement) => openIncident(record.incident || null),
		[openIncident]
	);

	// Columns
	const columns = useColumns(monitor);
	const columnHeaders = useMemo(
		() =>
			columns.map((column) => ({
				...column,
				defaultToggle: true,
				title: (
					<TableV2Header
						column={column}
						withFilters={false}
						onColumnVisibilityChange={() => {}}
						onSort={undefined}
						onResizeColumn={() => {}}
					/>
				),
			})),
		[columns]
	);

	const store = useContext(SearchFilterV2StoreContext);
	const { values: filters } = store;

	// Parse filters to API call
	const parsedFilters = useMemo(() => {
		// eslint-disable-next-line no-underscore-dangle
		const _filters: Record<string, FilterValue['value']> = {
			monitor: monitor.id,
		};
		// This logic assume that there's only one filter of each type
		// Has incident
		const hasIncidentFilter = filters.find(
			(f) => f.filterType === FilterOptionType.HAS_INCIDENT
		);
		if (hasIncidentFilter) {
			_filters['incident'] =
				(hasIncidentFilter?.operator === FilterOperator.Is &&
					hasIncidentFilter?.value === true) ||
				(hasIncidentFilter?.operator === FilterOperator.IsNot &&
					hasIncidentFilter?.value === false);
		}

		// Time range
		const createdAtFilter = filters.find(
			(f) => f.filterType === FilterOptionType.RUN_DATE
		);
		switch (createdAtFilter?.operator) {
			case FilterOperator.IsOnOrAfter:
				_filters['created_at__gte'] = createdAtFilter.value;
				break;
			case FilterOperator.IsOnOrBefore:
				_filters['created_at__lte'] = createdAtFilter.value;
				break;
			case FilterOperator.IsBetween:
				_filters['created_at__gte'] = (
					createdAtFilter.value as FilterValueType[]
				)[0];
				_filters['created_at__lte'] = (
					createdAtFilter.value as FilterValueType[]
				)[1];
				break;
			default:
				break;
		}

		return _filters as Record<string, string>;
	}, [filters, monitor.id]);

	// Data fetching
	const paginationListData = useMeasurementInfiniteList({
		filters: parsedFilters,
	});
	const { data: measurements } = paginationListData;
	const handleEndReached = useCallback(() => {
		if (paginationListData && paginationListData.hasNextPage) {
			paginationListData.fetchNextPage();
		}
	}, [paginationListData]);

	// UI
	const tableRef = useRef<HTMLTableSectionElement>(null);
	const distanceFromTop = tableRef?.current?.getBoundingClientRect()?.top ?? 0;
	let tableMaxHeight = HEADER_HEIGHT + 7 * ROW_HEIGHT + FOOTER_HEIGHT;

	const os = useOs();
	if (!!tableMaxHeight && os === 'windows') {
		// We need to add a 20px gutter to the max-height on Windows to account for the scrollbars taking over the element's space
		// in this issue we see that the horizontal scrollbar is forcing the vertical scrollbar to appear, which is causing the scrollbar to hide part of the elements
		// on Mac this doesn't happen because Mac uses overlay scrollbars, while Windows uses classic scrollbars
		// Reference: https://developer.mozilla.org/en-US/docs/Web/CSS/scrollbar-gutter
		// Note: scrollbar-gutter doesn't work either because all of these elements have fixed height. We need to revisit this whole structure to fix this properly.
		// This will cause some unwanted extra space at the bottom for some windows users if the browser decides to use overlay scrollbars. This is less of a problem than the current issue.
		tableMaxHeight += 20;
	}
	let tableHeight: number | string | undefined = undefined;
	if (!tableHeight) {
		// If the table is on the bottom-half of the screen, set height to auto.
		if (distanceFromTop > window.innerHeight / 2) {
			tableHeight = tableMaxHeight;
		} else {
			tableHeight = `calc(100vh - ${distanceFromTop + BOTTOM_PADDING}px)`;
		}
	}

	// Misc
	const noRecordsIcon = useMemo(() => <Icon size="lg" name="search" />, []);

	// CSV export
	const handleCsvExport = useCallback(() => {
		const csv = unparse(measurements?.pages || []);
		saveBlob(new Blob([csv], { type: 'text/csv' }), `${monitor.name}.csv`);
	}, [measurements, monitor.name]);

	return (
		<Stack className={classes.root}>
			{!hideTitle && <Text className={classes.historyTitle}>History</Text>}
			<Stack spacing="sm" h="100%">
				<Group position="apart">
					<MeasurementsFilterBar />
					<Group>
						<Menu position="bottom-end">
							<Menu.Target>
								<Tooltip label="Additional actions">
									<IconButton
										data-testid="dots-button"
										iconName="dots"
										variant="tertiary"
									/>
								</Tooltip>
							</Menu.Target>
							<Menu.Dropdown>
								<Menu.Item
									icon={<Icon name="download" />}
									onClick={handleCsvExport}
								>
									Export page as CSV
								</Menu.Item>
							</Menu.Dropdown>
						</Menu>
					</Group>
				</Group>
				<ErrorBoundary>
					<Suspense fallback={<Skeleton h="70vh" w="100%" />}>
						<Box ref={tableRef}>
							<DataTableComponent<Measurement>
								withStickyColumnBorder
								noHeader={false}
								borderRadius="md"
								endReached={handleEndReached}
								nextPageFetching={paginationListData?.isFetchingNextPage}
								maxHeight={tableMaxHeight}
								height={tableHeight}
								fetching={paginationListData.isFetching}
								loadingText={'Loading...'}
								noRecordsIcon={noRecordsIcon}
								noRecordsText={'No measurements found'}
								classNames={tableClasses}
								onRowClick={handleOpenIncident}
								page={1}
								paginationSize="md"
								records={measurements?.pages || []}
								recordsPerPage={DEFAULT_PAGINATION_SIZE}
								columns={columnHeaders}
								totalRecords={
									(measurements as { count: number } | undefined)?.count || 0
								}
								withBorder
								rowSx={rowSx}
								paginationText={({ from, to, totalRecords }) => {
									const totalRecordsString =
										totalRecords >= DEFAULT_MAX_RECORD_SIZE
											? `${DEFAULT_MAX_RECORD_SIZE}+`
											: totalRecords.toLocaleString();
									return `Showing ${to.toLocaleString()} of ${totalRecordsString} measurements`;
								}}
								customLoader={<TableV2Loader />}
							/>
						</Box>
					</Suspense>
				</ErrorBoundary>
			</Stack>
		</Stack>
	);
}

export default observer(V2MeasurementsTable);
