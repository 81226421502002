import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
	icon: {
		maxWidth: theme.other.iconSize.md + theme.other.space[2] * 2,
		display: 'flex',
	},
	content: {
		paddingLeft: 0,
		paddingX: theme.other.space[2],
	},
	line: {
		paddingTop: theme.spacing.sm,
		width: theme.other.space[0.25],
		height: `calc(100% - ${theme.other.space[7]}px)`,
		minHeight: theme.other.space[5],
		backgroundColor: theme.other.getColor('border/secondary/default'),
	},
}));
