export class GitbookMarkdownParser {
	markdown: string;

	constructor(markdown: string) {
		this.markdown = markdown;
	}

	parseGitbookMarkdown(): string {
		this.parseHints();
		this.removeTemplates();
		this.removeGitlabShortcuts();
		this.removeGitlabDescriptors();
		this.parseRelativeLinks();
		this.parseFigures();
		this.parseSingleImageTags();
		this.parseEmbeds();
		this.parseCodeTags();
		this.parsePTags();
		this.parseDetailsAndSummary();

		return this.markdown; // Return the parsed markdown
	}

	parseHints() {
		const HINT_TEMPLATE_REGEX = /{% hint.+?%}\n((\n|.)*?)\n{% endhint %}/g;
		this.markdown = this.markdown.replaceAll(
			HINT_TEMPLATE_REGEX,
			(match, firstGroup) => firstGroup.replaceAll(/^/gm, '>')
		);
	}

	removeTemplates() {
		const REMOVE_TEMPLATE_REGEX = /{% ([^%\s]+)(.+?)%}\n(\n|.)*?{% end\1 %}/g;
		this.markdown = this.markdown.replaceAll(REMOVE_TEMPLATE_REGEX, '');
	}

	removeGitlabShortcuts() {
		const GITLAB_SHORTCUT_REGEX = /<a href="#h_.*<\/a>/g;
		this.markdown = this.markdown.replaceAll(GITLAB_SHORTCUT_REGEX, '');
	}

	removeGitlabDescriptors() {
		const GITLAB_DESCRIPTOR_REGEX = /^---(\n|.)*---/g;
		this.markdown = this.markdown.replaceAll(GITLAB_DESCRIPTOR_REGEX, '');
	}

	parseRelativeLinks() {
		const RELATIVE_LINK = /(\.\.\/)+\.gitbook\/assets/g;
		this.markdown = this.markdown.replaceAll(
			RELATIVE_LINK,
			'https://raw.githubusercontent.com/secoda/gitbook/master/.gitbook/assets'
		);
	}

	parseFigures() {
		const FIGURE_REGEX =
			/<figure><img src="(.+?)" alt=""[^>]*>(<figcaption>(.*?)<\/figcaption>)?<\/figure>/g;
		this.markdown = this.markdown.replace(
			FIGURE_REGEX,
			(_, firstGroup, secondGroup, thirdGroup) =>
				`![${thirdGroup}](${firstGroup.replaceAll(/ /g, '%20')})`
		);
	}

	// handles edge case of <img/> without <figure> parent
	parseSingleImageTags() {
		const IMAGE_TAG_REGEX = /<img src="(.+?)" alt="".*>/g;
		this.markdown = this.markdown.replace(
			IMAGE_TAG_REGEX,
			(_, firstGroup) => `![](${firstGroup.replaceAll(/ /g, '%20')})`
		);
	}

	parseEmbeds() {
		const EMBED_REGEX = /{% embed url="(.*?)" %}/g;
		this.markdown = this.markdown.replaceAll(
			EMBED_REGEX,
			(_, firstGroup) =>
				`[iframe:embed](${firstGroup}&embedWidth=450&embedHeight=220)`
		);
	}

	parsePTags() {
		const P_REGEX = /<p>(.*?)<\/p>/gs;
		this.markdown = this.markdown.replaceAll(
			P_REGEX,
			(_, content) => `${content.trim()}`
		);
	}
	parseCodeTags() {
		const CODE_REGEX = /<code>(.*?)<\/code>/gs;
		this.markdown = this.markdown.replaceAll(
			CODE_REGEX,
			(_, content) => `\`${content}\``
		);
	}

	parseDetailsAndSummary() {
		const DETAILS_REGEX = /<details[^>]*>([\s\S]*?)<\/details>/gi;
		const SUMMARY_REGEX = /<summary[^>]*>([\s\S]*?)<\/summary>/gi;

		this.markdown = this.markdown.replaceAll(
			DETAILS_REGEX,
			(match, content) => `${content}`
		);

		this.markdown = this.markdown.replaceAll(
			SUMMARY_REGEX,
			(match, content) => `### ${content}`
		);
	}
}
