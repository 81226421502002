import type { Heading } from './getHeadings';

export interface TreeNode {
	heading: Heading;
	children: TreeNode[];
}

export function headingsToTree(headings: Heading[]): TreeNode[] {
	const rootNodes: TreeNode[] = [];
	const stack: TreeNode[] = [];

	for (const heading of headings) {
		const node: TreeNode = { heading, children: [] };

		while (
			stack.length > 0 &&
			stack[stack.length - 1].heading.level >= heading.level
		) {
			stack.pop();
		}

		if (stack.length === 0) {
			rootNodes.push(node);
		} else {
			stack[stack.length - 1].children.push(node);
		}

		stack.push(node);
	}

	return rootNodes;
}
