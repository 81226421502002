import { cloneDeep } from 'lodash-es';
import { BaseModel } from './base';

export class ResourceRelation extends BaseModel {
	// @ts-expect-error TS(2564): Property 'id' has no initializer and is not defini... Remove this comment to see the full error message
	id: string;

	// @ts-expect-error TS(2564): Property 'to_entity' has no initializer and is not... Remove this comment to see the full error message
	to_entity: string;

	// @ts-expect-error TS(2564): Property 'from_entity' has no initializer and is n... Remove this comment to see the full error message
	from_entity: string;

	constructor(obj: ResourceRelation) {
		super(obj);
		Object.keys(obj).forEach((key) => {
			(this as any)[key] = cloneDeep((obj as any)[key]);
		});
	}

	static get fields() {
		return BaseModel.fields.concat(['from_entity', 'to_entity']);
	}

	static get namespace() {
		return ['lineage', 'relations'];
	}
}
