import {
	concat,
	filter,
	forEach,
	includes,
	isEmpty,
	isNil,
	map,
} from 'lodash-es';
import { NOTIFICATION_EVENT_TYPES } from './InboxList/constants';
import type { EventType } from './InboxList/types';

export const getEventsFilter = (events: string, isInclusive: boolean) => {
	const eventFilters = concat(map(NOTIFICATION_EVENT_TYPES, 'values')).join(
		','
	);

	if (isEmpty(events) || isNil(events)) {
		return eventFilters;
	}

	if (!isInclusive) {
		const eventsFilterArr = eventFilters.split(',');
		const exclusiveFilters: string[] = [];

		forEach(eventsFilterArr, (event) => {
			if (!includes(events.split(','), event)) {
				exclusiveFilters.push(event);
			}
		});

		return exclusiveFilters.join(',');
	}

	const eventsArr = events.split(',');

	const eventTypeCategories = filter(
		NOTIFICATION_EVENT_TYPES,
		(type: EventType) => includes(eventsArr, type.value)
	);

	return concat(map(eventTypeCategories, 'values')).join(',');
};

export const getInboxFiltersFromUrl = (params: URLSearchParams) => ({
	searchTerm: params.get('searchTerm') || '',
	page: parseInt(params.get('page') || '1', 10),
	events: params.get('events') || '',
	isInclusive:
		isEmpty(params.get('inclusive')) ||
		isNil(params.get('inclusive') ? true : params.get('inclusive') === 'true'),
	selectedId: params.get('id') || '',
});

export const getFilters = (
	events: string,
	searchTerm: string,
	isInclusive: boolean,
	showRead: boolean
) => {
	const eventFilters = getEventsFilter(events, isInclusive);

	return {
		events: eventFilters,
		search_term: searchTerm,
		read: showRead,
	};
};

export const getNotificationType = (event: string) => {
	let type = 'notifications';

	const foundEventType = NOTIFICATION_EVENT_TYPES.find((task) =>
		includes(task.values, event)
	);

	if (foundEventType && foundEventType.isTask) {
		type = 'tasks';
	}

	return type;
};
