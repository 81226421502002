import { Skeleton } from '@mantine/core';
import type { IconSizes } from '../Icon';
import { IconSizeMap } from '../Icon/Icon';

interface IconSkeletonProps {
	size?: IconSizes | number;
}

export function IconSkeleton({ size = 'md' }: IconSkeletonProps) {
	return (
		<Skeleton
			width={typeof size === 'number' ? size : IconSizeMap[size]}
			height={typeof size === 'number' ? size : IconSizeMap[size]}
		/>
	);
}
