import { Stack } from '@mantine/core';
import { Title } from '@repo/foundations';
import { width } from '@repo/theme/primitives';
import { isEmpty } from 'lodash-es';
import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { useIntegrationList } from '../../api';
import { EmptyState, type ButtonDetails } from '../../components/EmptyState';
import NewIntegrationModal from '../../components/Integration/AddIntegrationModal';
import { useStyles as useNewIntegrationModalStyles } from '../../components/Integration/AddIntegrationModal/AddIntegrationModal.styles';
import { NEW_INTEGRATION_MODAL_ID } from '../../components/Integration/AddIntegrationModal/constants';
import { FullWidthLoadingSpinner } from '../../components/LoadingSpinner';
import { openModal } from '../../components/ModalManager';
import { TableV2 } from '../../components/TableV2';
import type { ExtendedDataTableColumn } from '../../components/TableV2/types';
import { useStyles } from '../TeamCatalogPage/TeamCatalogPage.styles';
import { useActions, useColumns } from './IntegrationsListPage.hooks';

const COLUMN_VISIBILITY = {
	catalogServerType: 'integrations',
	catalogType: 'integration',
} as const;

const QUICK_ACTIONS = ['actions::run', 'actions::delete'] as const;

const PAGINATION_LIST_OPTIONS = {
	suspense: true,
};

function IntegrationsListPage() {
	const { data: integrations, isLoading } = useIntegrationList({
		page: 1,
		options: {
			select: ({ results }) => results,
		},
	});

	const { classes } = useStyles();
	const { classes: addIntegrationModalClasses } =
		useNewIntegrationModalStyles();

	const columns = useColumns();
	const actions = useActions();

	const navigate = useNavigate();

	const handleClick = useCallback(
		(id: string) => {
			navigate(`/integrations/${id}`);
		},
		[navigate]
	);

	const handleConnectIntegration = useCallback(() => {
		openModal({
			modalId: NEW_INTEGRATION_MODAL_ID,
			title: <Title size="md">Connect Integration</Title>,
			children: <NewIntegrationModal />,
			size: width.xxl,
			radius: 'lg',
			classNames: {
				header: addIntegrationModalClasses.header,
				body: addIntegrationModalClasses.body,
			},
		});
	}, [addIntegrationModalClasses.body, addIntegrationModalClasses.header]);

	const onCellClick = useCallback(
		(cell) => {
			if (
				(cell.column as ExtendedDataTableColumn<unknown>).navigate !== false
			) {
				handleClick(cell.record.id);
			}
		},
		[handleClick]
	);

	if (isLoading) {
		return <FullWidthLoadingSpinner />;
	}

	if (!isLoading && isEmpty(integrations)) {
		const buttons: ButtonDetails[] = [
			{
				name: 'Learn more',
				action: () => {
					window.open('https://docs.secoda.co/integrations', '_blank');
				},
				isPrimary: false,
				size: 'md',
				leftIconName: 'externalLink',
			},
			{
				name: 'Connect integration',
				action: handleConnectIntegration,
				isPrimary: true,
				size: 'md',
			},
		];

		return (
			<EmptyState
				title="Connect your first integration"
				description="Set up two or more integrations to seamlessly interact, govern and view lineage with your data."
				illustrationName="integrations"
				includeGoBack={false}
				size="lg"
				buttons={buttons}
				stateHeight="80vh"
			/>
		);
	}

	return (
		<Stack className={classes.wrapper}>
			<TableV2
				columnVisibility={COLUMN_VISIBILITY}
				pluralTypeString="integrations"
				columns={columns}
				usePaginationList={useIntegrationList}
				usePaginationListOptions={PAGINATION_LIST_OPTIONS}
				withActions={actions}
				withCheckbox={true}
				withQuickActions={QUICK_ACTIONS}
				withCsvExport
				withInteractiveHeader
				withSearch
				withFilters={false}
				defaultSort={null}
				onCellClick={onCellClick}
			/>
		</Stack>
	);
}

export default IntegrationsListPage;
