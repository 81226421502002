import { baseQueryHooksFactory } from '../../factories';
import type { IDatabuilderJob } from '../../types/models/databuilderJob';
import { databuilderJobQueryKeyFactory } from './constants';

const {
	useDatabuilderJob,
	useCreateDatabuilderJob,
	useDatabuilderJobList,
	useUpdateDatabuilderJob,
} = baseQueryHooksFactory<IDatabuilderJob, 'databuilderJob'>(
	'databuilderJob',
	databuilderJobQueryKeyFactory
);

export {
	useCreateDatabuilderJob,
	useDatabuilderJob,
	useDatabuilderJobList,
	useUpdateDatabuilderJob,
};
