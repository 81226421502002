import { Avatar, useMantineTheme } from '@mantine/core';
import { AIAvatar } from '@repo/common/components/AI/Avatar';
import { pickColorType } from '@repo/common/utils';
import type { IconSizes } from '@repo/foundations';
import { space } from '@repo/theme/primitives';
import { User } from '../../lib/models';
import type { AvatarUser } from './common';
import { TooltipAvatar } from './common';
import { colorGroupToFillMap } from './helpers';

type UserAvatarSize = 'xxs' | 'xs' | 'sm' | 'md' | 'lg';

const userAvatarSize: Record<UserAvatarSize, number> = {
	xxs: space[4],
	xs: space[5],
	sm: space[6],
	md: space[7],
	lg: space[8],
} as const;

export interface UserAvatarProps {
	size: number | UserAvatarSize | IconSizes;
	user: AvatarUser;
	tooltip?: string;
	enableLink?: boolean;
}

export function UserAvatar({
	user,
	tooltip,
	size,
	enableLink = false,
}: UserAvatarProps) {
	const theme = useMantineTheme();

	// Default xs is 16
	const convertedSize =
		typeof size === 'string'
			? (userAvatarSize[size as keyof typeof userAvatarSize] ?? 16)
			: size;
	if (`${user?.first_name} ${user?.last_name}` === 'Secoda AI') {
		return <AIAvatar size={size as IconSizes} speed="none" />;
	}

	const colorGroup = pickColorType(user?.display_name, user?.disabled);
	const { fillStart, fillEnd, textColor } = colorGroupToFillMap(colorGroup);
	const initials = User.getUserInitialsFromName(user?.display_name) || '';

	return (
		<Avatar
			variant="filled"
			size={convertedSize}
			h={convertedSize}
			w={convertedSize}
			mih={convertedSize}
			miw={convertedSize}
			sx={{
				backgroundImage: theme.fn.gradient({
					from: fillStart,
					to: fillEnd,
					deg: 180,
				}),
				color: textColor,
			}}
			src={user?.profile_picture}
			alt={user?.display_name}
			component={TooltipAvatar}
			user={user}
			tooltip={tooltip}
			enableLink={enableLink}
		>
			<div style={{ fontSize: convertedSize / 2 }}>{initials}</div>
		</Avatar>
	);
}

export default UserAvatar;
