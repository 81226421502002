import { Box, createStyles, Group, Stack } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { Icon, Text, Title } from '@repo/foundations';

interface PreferenceSectionProps {
	header: string;
	subheader?: string;
	children: React.ReactNode;
}

const useStyles = createStyles((theme) => ({
	collapsedWrapper: {
		cursor: 'pointer',
		padding: theme.spacing.md,
		borderRadius: theme.radius.md,
		'&:hover': {
			backgroundColor: theme.other.getColor('surface/primary/hover'),
		},
	},
}));

export function PreferenceSection({
	header,
	subheader,
	children,
}: PreferenceSectionProps) {
	const [opened, { toggle }] = useDisclosure(false);
	const { classes, theme } = useStyles();

	return (
		<Stack spacing="sm">
			<Group
				className={classes.collapsedWrapper}
				position="apart"
				noWrap
				onClick={toggle}
			>
				<Stack spacing={theme.spacing['4xs']}>
					<Title size="md">{header}</Title>
					{subheader && <Text size="sm">{subheader}</Text>}
				</Stack>
				<Icon name={opened ? 'chevronUp' : 'chevronDown'} />
			</Group>
			{opened && <Box px="md">{children}</Box>}
		</Stack>
	);
}
