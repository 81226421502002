import { useQuery } from '@tanstack/react-query';
import { apiClient, getEndpoints } from '../../common';

export const INTERNAL_QUERY_ACTIONS_NAMESPACE = [
	'admin',
	'internal_query_actions',
];

export function useIsSuperAdmin() {
	const queryKey = [...INTERNAL_QUERY_ACTIONS_NAMESPACE, 'is_superadmin'];
	const queryFn = async () => {
		const url = getEndpoints(INTERNAL_QUERY_ACTIONS_NAMESPACE).byPath([
			'is_superadmin',
		]);
		const { data } = await apiClient.get<boolean>(url);
		return data;
	};

	return useQuery(queryKey, queryFn, {
		onError: () => false,
	});
}
