import { Divider, Global, Skeleton, Stack } from '@mantine/core';
import { Text } from '@repo/foundations';
import moment from 'moment';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';
import type { IQuestion } from '../../../api';
import {
	useDeleteQuestion,
	useQuestionReplyList,
	useUpdateQuestion,
} from '../../../api';
import { useQuestionPermissions } from '../../../api/hooks/question/permissions';
import { openDeleteConfirmModal } from '../../../components/ModalManager';
import { QuestionReply } from './Reply';
import { QuestionNewReply } from './ReplyNew';
import { ReplyWrapper } from './ReplyWrapper';

function dividerText(count: number, hasAcceptedAnswer = false) {
	if (count === 0) {
		return 'No replies yet';
	}
	if (count === 1) {
		return `1 ${hasAcceptedAnswer ? 'other reply' : 'reply'}`;
	}
	return `${count} ${hasAcceptedAnswer ? 'other replies' : 'replies'}`;
}

export interface QuestionProps {
	question: IQuestion;
}

export function Question({ question }: QuestionProps) {
	const navigate = useNavigate();

	const { data: repliesData, isLoading } = useQuestionReplyList({
		filters: { question_id: question.id },
		options: { suspense: false },
	});

	const acceptedReply = useMemo(
		() => repliesData?.results?.find((f) => f.accepted_answer),
		[repliesData]
	);
	const otherReplies = useMemo(
		() =>
			(repliesData?.results ?? [])
				.filter((f) => !f.accepted_answer)
				.sort((a, b) => moment(a.created_at).diff(moment(b.created_at))),
		[repliesData]
	);

	const { canReply } = useQuestionPermissions(question, 'question');

	const { mutateAsync: updateQuestion } = useUpdateQuestion({});

	const { mutateAsync: deleteQuestion } = useDeleteQuestion({
		options: {
			onSuccess: () => {
				navigate('/questions/');
			},
		},
	});

	const handleUpdate = useCallback(
		(values: Partial<IQuestion>) =>
			updateQuestion({ data: { id: question.id, ...values } }),
		[updateQuestion, question]
	);

	const handleDelete = useCallback(() => {
		openDeleteConfirmModal({
			title: 'Please confirm deletion',
			description: 'Are you sure you want to delete this question?',
			onConfirm: () => deleteQuestion({ id: question.id }),
		});
	}, [deleteQuestion, question]);

	if (isLoading) {
		return <Skeleton />;
	}

	return (
		<>
			<Global
				styles={() => ({
					'.heading-actions, .block-menu-trigger': {
						display: 'none !important',
					},
				})}
			/>
			<Stack spacing="md" mb="3xl">
				<ReplyWrapper
					entity={question}
					handleDelete={handleDelete}
					type="question"
					handleUpdate={handleUpdate}
				/>
				{acceptedReply && (
					<QuestionReply questionReply={acceptedReply} accepted />
				)}
				{otherReplies?.length > 0 && (
					<Stack my="lg" spacing="sm">
						<Divider />
						<Text size="xs" color="text/secondary/default">
							{dividerText(otherReplies.length, !!acceptedReply)}
						</Text>
					</Stack>
				)}
				{otherReplies.map((questionReply) => (
					<QuestionReply key={questionReply.id} questionReply={questionReply} />
				))}
				{canReply && <QuestionNewReply questionId={question.id} />}
			</Stack>
		</>
	);
}
