export type SSOTypes = 'google' | 'microsoft' | 'saml' | null;

export const SSO_NAMES = {
	google: 'Google',
	microsoft: 'Microsoft',
	saml: 'SAML',
	none: 'None',
	generic: 'Generic',
	okta: 'Okta',
};

export const getSSOName = (sso: SSOTypes) => {
	if (!sso) {
		return '';
	}
	return SSO_NAMES[sso];
};
