import { showNotification } from '@mantine/notifications';
import { EntityType } from '@repo/common/enums/entityType';
import { pluralize } from '@repo/common/utils';
import type { IconNames } from '@repo/foundations';
import { Icon } from '@repo/foundations';
import { useCallback, useMemo } from 'react';
import type { DeleteRequestParams, IBaseModel } from '../../api';
import { queryClient } from '../../api';
import type { QueryKeyFactory } from '../../api/factories';
import type { DeleteModelHook } from '../../api/factories/types';
import DeleteWithWarningModal from '../../pages/IntegrationsPage/DeleteWithWarningModal';
import { closeAllModals, openModal } from '../ModalManager';
import type { ICommandListItem } from '../Spotlight/components/CommandPalette/constants';

export function useDeleteManyAction<T extends IBaseModel>({
	name,
	queryKeyFactory,
	useDelete,
}: {
	name: string;
	queryKeyFactory: QueryKeyFactory;
	useDelete: DeleteModelHook<T, DeleteRequestParams, unknown, unknown>;
}): { action: ICommandListItem<T> } {
	const { mutateAsync: deleteModel } = useDelete({});

	const deleteFn = useCallback(
		async (selected: T[]) => {
			const results = await Promise.allSettled(
				selected.map((el) => deleteModel({ id: el.id }))
			);
			const success = results.filter((result) => result.status === 'fulfilled');
			const failed = results.filter((result) => result.status === 'rejected');

			if (failed.length > 0) {
				showNotification({
					title: `Error deleting ${failed.length} ${pluralize(name)}`,
					message: 'If the issues persists, please contact support',
					color: 'red',
					icon: <Icon name="alertCircle" />,
				});
			} else {
				showNotification({
					title: `${success.length} ${pluralize(name)}  deleted`,
					message: `The ${pluralize(name)} have been deleted successfully.`,
					color: 'green',
					icon: <Icon name="check" />,
				});
			}

			queryClient.invalidateQueries(queryKeyFactory.allLists());
			closeAllModals();
		},
		[deleteModel, name, queryKeyFactory]
	);

	const handleDelete = useCallback(
		async (selected: T[], clearSelected: VoidFunction) => {
			openModal({
				variant: 'default',
				title: `Delete ${pluralize(name)}`,
				children: (
					<DeleteWithWarningModal
						text="This can't be undone."
						onClose={() => closeAllModals()}
						onConfirm={async () => {
							await deleteFn(selected);
							clearSelected();
						}}
					/>
				),
				classNames: {
					body: '',
				},
			});
		},
		[deleteFn, name]
	);

	const action = useMemo(
		() => ({
			id: 'actions::delete',
			title: 'Delete',
			name: 'Delete',
			iconName: 'trash' as IconNames,
			hotkey: '/dq',
			type: EntityType.all,
			team: undefined,
			category: 'actions',
			show: true,
			onClick: handleDelete,
		}),
		[handleDelete]
	);

	return { action };
}
