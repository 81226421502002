import { ActionIcon, Group, Space, UnstyledButton } from '@mantine/core';
import { Text } from '@repo/foundations';
import { IconX } from '@tabler/icons-react';
import { observer } from 'mobx-react-lite';
import { queryClient } from '../../api';
import {
	slackhooksQueryKeyFactory,
	useDeleteSlackHook,
} from '../../api/hooks/workspace/useSlackHooks';
import type { ISlackHook } from '../../lib/models';

interface IEntityNotificationHookEntryProps {
	hook: ISlackHook;
}

function EntityNotificationHookEntry(props: IEntityNotificationHookEntryProps) {
	const { hook } = props;

	const { mutateAsync: deleteSlackHook } = useDeleteSlackHook({});

	return (
		<>
			<UnstyledButton>
				<Group>
					<Group>
						<div>
							<Text size="sm">#{hook.slack_conversation_name}</Text>
							<Text size="xs" color="text/secondary/default">
								Subscribed to updates
							</Text>
						</div>
						<ActionIcon
							onClick={async () => {
								await deleteSlackHook({ id: hook.id });
								queryClient.invalidateQueries(slackhooksQueryKeyFactory.all());
							}}
						>
							<IconX />
						</ActionIcon>
					</Group>
				</Group>
			</UnstyledButton>
			<Space h="xs" />
		</>
	);
}

export default observer(EntityNotificationHookEntry);
