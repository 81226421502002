import type { WithOnlyIdRequired } from '../../../lib/typescript';
import type { IWidget } from '../../types';
import { useUpdateBaseModel } from '../base';
import { WIDGETS_NAMESPACE } from './constants';

interface IUpdateWidgetParams {
	data: WithOnlyIdRequired<IWidget>;
	invalidateReport?: boolean;
	reportId?: string;
}

export function useUpdateWidget() {
	return useUpdateBaseModel<IWidget, IUpdateWidgetParams>({
		namespace: WIDGETS_NAMESPACE,
		disableOptimisticUpdate: true,
		disableInvalidation: true,
	});
}
