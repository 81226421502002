import { Center, Flex, Stack } from '@mantine/core';
import type { DataQualityScore } from '@repo/api-codegen';
import type { ForwardedRef, ReactNode } from 'react';
import { forwardRef } from 'react';
import { DATA_QUALITY_TOP_KEYS } from '../constants';
import { DataQualityDetailsGroup } from './DataQualityDetailsCardGroup';
import { DataQualityDetailsGroupLoading } from './DataQualityDetailsCardGroupLoading';

interface DataQualityDetailsCardBodyProps {
	direction?: 'horizontal' | 'vertical';
	ring: ReactNode;
	data?: DataQualityScore;
	scoreType?: 'points' | 'percentage';
}

export const DataQualityDetailsCardBody = forwardRef(
	(
		{
			ring,
			data,
			direction = 'vertical',
			scoreType = 'points',
		}: DataQualityDetailsCardBodyProps,
		ref: ForwardedRef<HTMLDivElement>
	) => (
		<Flex
			direction={direction === 'vertical' ? 'column' : 'row'}
			gap="md"
			p="sm"
			h={296}
			align="center"
			justify={direction === 'vertical' ? 'space-between' : 'space-evenly'}
			ref={ref}
		>
			<Center w={direction === 'vertical' ? undefined : '50%'}>{ring}</Center>
			<Stack
				spacing={direction === 'vertical' ? 'xs' : 'md'}
				w={direction === 'vertical' ? '100%' : '50%'}
			>
				{DATA_QUALITY_TOP_KEYS.map((dataQualityKey) =>
					data ? (
						<DataQualityDetailsGroup
							key={dataQualityKey}
							score={data[dataQualityKey]}
							scoreType={scoreType}
							dataQualityKey={dataQualityKey}
						/>
					) : (
						<DataQualityDetailsGroupLoading
							key={dataQualityKey}
							dataQualityKey={dataQualityKey}
						/>
					)
				)}
			</Stack>
		</Flex>
	)
);
