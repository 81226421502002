import { TableV2 } from '../../../../components/TableV2';
import { useActions, useColumns, useQueryExecutionsList } from './hooks';

interface QueryHistoryProps {
	queryId: string;
	lookBackDays: number;
}

function QueryHistory({ queryId, lookBackDays }: QueryHistoryProps) {
	const actions = useActions();
	const columns = useColumns();

	const queryExecutionsList = useQueryExecutionsList(queryId, lookBackDays);

	return (
		<TableV2
			withInteractiveHeader
			withActions={actions}
			pluralTypeString="executions"
			columns={columns}
			// @ts-ignore
			usePaginationList={queryExecutionsList}
		/>
	);
}

export default QueryHistory;
