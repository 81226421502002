import type { SelectItem } from '@mantine/core';
import { Stack } from '@mantine/core';
import { Icon, MultiSelect, Title } from '@repo/foundations';
import { observer } from 'mobx-react-lite';
import { useCallback, useMemo, useState } from 'react';
import type { IUser } from '../../../../api';
import { useExtendedUserList } from '../../../../api/hooks/user/useExtendedUserList';
import { getDisplayName } from '../../../../utils/userUtils';
import { UserAvatar } from '../../../UserAvatar';
import { useAddMonitorStoreContext } from '../context';

type UserItem = SelectItem & {
	group: 'Users';
	user: IUser;
};

type EmailItem = SelectItem & {
	group: 'Emails';
};

type UserOrEmailItem = UserItem | EmailItem;

function isUserItem(item: UserOrEmailItem): item is UserItem {
	return item.group === 'Users';
}

function ManageStack() {
	const store = useAddMonitorStoreContext();
	const { activeUsers } = useExtendedUserList();

	// Owner selector
	const ownersData = useMemo(
		() =>
			activeUsers?.map((u) => ({
				value: u.id,
				label: getDisplayName(u),
				user: u,
			})) || [],
		[activeUsers]
	);

	// Subscriber selector allow for adding custom emails
	const subscribersData = useMemo(() => {
		const userItems =
			activeUsers?.map((u) => ({
				value: `user:${u.id}`,
				label: getDisplayName(u),
				group: 'Users',
				user: u,
			})) || [];
		const emailItems = store.tempEmailSubscribers.map((e) => ({
			value: `email:${e}`,
			label: e,
			group: 'Emails',
		}));
		return [...userItems, ...emailItems] as UserOrEmailItem[];
	}, [activeUsers, store.tempEmailSubscribers]);

	const [subscribersValue, setSubscribersValue] = useState<string[]>([]);
	const [subscriberError, setSubscriberError] = useState<string>('');

	const handleSetSubscribersValue = (value: string[]) => {
		setSubscribersValue(value);
		setSubscriberError('');
		store.setEmailSubscribers(
			value
				.filter((v) => v.startsWith('email:'))
				.map((v) => v.slice('email:'.length))
		);
		store.setSubscribers(
			value
				.filter((v) => v.startsWith('user:'))
				.map((v) => v.slice('user:'.length))
		);
	};

	const handleAddEmail = useCallback(
		(email: string) => {
			// simple heuristic to check email syntax
			if (/\S+@\S+\.\S+/.test(email)) {
				store.setTempEmailSubscribers([...store.tempEmailSubscribers, email]);
				store.setEmailSubscribers([...store.emailSubscribers, email]);
				setSubscribersValue([...subscribersValue, `email:${email}`]);
				setSubscriberError('');
				return {
					value: `email:${email}`,
					label: email,
					group: 'Emails',
				};
			} else {
				setSubscriberError(`"${email}" is not a valid email`);
				return undefined;
			}
		},
		[store, subscribersValue]
	);

	return (
		<Stack p={0} spacing="lg">
			<Title size="md">Manage</Title>
			<MultiSelect
				label="Owners"
				placeholder="Select owners"
				data={ownersData}
				value={store.owners}
				setValue={store.setOwners}
				renderIcon={(item) => <UserAvatar user={item.user} size="xs" />}
				optional={false}
				error={store.ownersError}
				dropdownPosition="flip"
			/>
			<MultiSelect<UserOrEmailItem>
				label="Subscribers"
				placeholder="Select subscribers"
				data={subscribersData}
				value={subscribersValue}
				setValue={handleSetSubscribersValue}
				renderIcon={(item: UserOrEmailItem) =>
					isUserItem(item) ? (
						<UserAvatar user={item.user} size="xs" />
					) : (
						<Icon name="user" />
					)
				}
				optional
				creatable
				getCreateLabel={(query) =>
					subscribersData?.filter((value) => value.label?.includes(query))
						.length > 0
						? ''
						: `Add "${query}" to subscribers`
				}
				onCreate={handleAddEmail}
				onClick={() => setSubscriberError('')}
				error={subscriberError}
				dropdownPosition="flip"
			/>
		</Stack>
	);
}

export default observer(ManageStack);
