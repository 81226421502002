import { ActionIcon, Box, Center, Group, createStyles } from '@mantine/core';
import { Icon } from '@repo/foundations';
import { useDebounceFn } from 'ahooks';
import dayjs from 'dayjs';
import { isEmpty } from 'lib0/object';
import { every, includes } from 'lodash-es';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { IMetric } from '../../api';
import {
	useAuthUser,
	useCreateMetric,
	useIntegrationList,
	useWorkspace,
} from '../../api';
import { useMetricDownload } from '../../api/hooks/metric/useMetricDownload';
import { useMetricExecution } from '../../api/hooks/metric/useMetricExecution';
import type { DjangoValueType } from '../../pages/TemplatePage/types';
import { router } from '../../router';
import { trackEvent } from '../../utils/analytics';
import {
	arrayToObjects,
	metricOptions,
	parseValueToNumber,
} from '../../utils/metrics';
import { buildResourceUrl } from '../../utils/navigationUtils';
import { parseUnknownDateTime } from '../../utils/time';
import { hashCode } from '../../utils/utils';
import { MetricJobProgress } from '../BackgroundJobProgress/MetricJobProgress';
import { Chart } from '../Chart';
import { EmptyState } from '../EmptyState';
import ErrorDrawer from '../ErrorDrawer/ErrorDrawer';
import { DotsAction } from './Actions/DotsAction';
import { ErrorAction } from './Actions/ErrorAction';
import { IntegrationSelectAction } from './Actions/IntegrationSelectAction';
import { PrimarySelectAction } from './Actions/PrimarySelectAction';
import { RunAction } from './Actions/RunAction';
import { TimeSelectAction } from './Actions/TimeSelectAction';
import SqlEditor from './SqlEditor/SqlEditor';

interface SqlChartsProps {
	onChange: (entries: Record<string, DjangoValueType>) => Promise<void>;
	metric: IMetric;
	canEdit?: boolean;
	withQuery?: boolean;
	withChart?: boolean;
	withInlineIntegrationSelector?: boolean;
	isSelected?: boolean;
}

const useStyles = createStyles(
	(theme, { isSelected }: { isSelected?: boolean }) => ({
		editor: {
			borderRadius: 4,
		},
		playIcon: {
			borderRadius: '50%',
		},
		overlayWrapper: {
			paddingTop: theme.spacing.md,
			paddingBottom: theme.spacing.md,

			paddingLeft: theme.spacing.lg,
			paddingRight: theme.spacing.lg,

			position: 'relative',
			border: `1px solid ${isSelected ? '#0366d6' : theme.other.getColor('border/inverse/active')}`,
			borderRadius: theme.radius.lg,
		},
		queryWrapper: {
			marginTop: theme.spacing.lg,
			backgroundColor: theme.colors.gray[0],
		},
		tableWrapper: { height: 400 },
	})
);

export default function SqlCharts({
	metric,
	onChange,
	canEdit = true,
	withQuery = true,
	withChart = true,
	withInlineIntegrationSelector = true,
	isSelected,
}: SqlChartsProps) {
	const MIN_HEIGHT = 250;

	const [showErrorDrawer, setShowErrorDrawer] = useState(false);

	const { isViewerOrGuestUser, user } = useAuthUser();
	const { workspace } = useWorkspace();
	const { classes, theme, cx } = useStyles({ isSelected });

	const { run: onChangeQueryDebounce, flush: flushOnChangeDebounce } =
		useDebounceFn((value: string) => onChange({ sql: value }), { wait: 350 });

	const trackEventPrefix = every([withChart, withQuery])
		? 'metric'
		: 'metric/embedded';

	const { mutateAsync: createMetric } = useCreateMetric({});
	const { download } = useMetricDownload(metric, trackEventPrefix);
	const { execute, executionStatus, isExecuting } = useMetricExecution(
		metric,
		trackEventPrefix
	);

	// We only want to show the query empty state if the user hasn't selected an
	// integration. If we aren't showing the chart, we do not want to show the
	// query empty state, since QueryBlocks shouldn't require an integration.
	const showQueryEmptyState = !metric.integration && withChart;

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const results = metric.results ?? [];
	const dimension = metric.dimension ?? '';
	const primary = metric.primary ?? '';
	const time = metric.time ?? '';

	const chartKey = hashCode(
		`${hashCode(JSON.stringify(results))}${primary}${time}${dimension}${
			metric.integration
		}`
	);

	const timeMetricData = useMemo(
		() =>
			arrayToObjects(results)
				?.filter((t) => parseValueToNumber(t[primary])[1])
				.map((t) => ({
					time: parseUnknownDateTime(t[time])?.toDate(),
					series: t[dimension] ?? primary,
					[primary]: parseValueToNumber(t[primary])[0],
				})) ?? [],
		[results, dimension, primary, time]
	);

	useEffect(() => {
		if (isViewerOrGuestUser || !canEdit) {
			return;
		}

		const { metricCols, timeCols, dimensionCols } = metricOptions(results);

		let update: Record<string, DjangoValueType> = {};

		if (isEmpty(primary) && metricCols.length > 0) {
			update = { ...update, primary: metricCols[0].name };
		}
		if (isEmpty(time) && timeCols.length > 0) {
			update = { ...update, time: timeCols[0].name };
		}

		// We only want to set the dimension if the user hasn't already configured the `dimension` field.
		if (isEmpty(dimension) && dimensionCols.length > 0) {
			update = { ...update, dimension: dimensionCols[0].name };
		}

		if (!isEmpty(update)) {
			onChange(update);
		}
	}, [
		time,
		primary,
		onChange,
		results,
		dimension,
		isViewerOrGuestUser,
		canEdit,
	]);

	const handleDownload = useCallback(() => {
		trackEvent(
			`${trackEventPrefix}/download`,
			{
				id: metric.id,
			},
			user,
			workspace
		);
		download();
	}, [download, metric, trackEventPrefix, user, workspace]);

	const handleExecute = useCallback(async () => {
		setShowErrorDrawer(false);
		// make sure pending changes are flushed to API before executing
		await flushOnChangeDebounce();
		await execute();
	}, [execute, flushOnChangeDebounce]);

	const handleNavigate = useCallback(() => {
		trackEvent(
			`${trackEventPrefix}/navigate`,
			{
				id: metric.id,
			},
			user,
			workspace
		);
		// NOTE: We can't use `navigate` here because this component exists
		// outside of the router. Instead, we have to use `router.navigate`.
		router.navigate(buildResourceUrl(metric));
	}, [metric, trackEventPrefix, user, workspace]);

	const handleCreateStandaloneMetric = useCallback(async () => {
		trackEvent(
			`${trackEventPrefix}/convert`,
			{
				id: metric.id,
			},
			user,
			workspace
		);
		const newMetric = await createMetric({
			data: {
				sql: metric.sql,
				integration: metric.integration,
			},
		});
		// NOTE: We can't use `navigate` here because this component exists
		// outside of the router. Instead, we have to use `router.navigate`.
		router.navigate(buildResourceUrl(newMetric));
	}, [createMetric, metric, trackEventPrefix, user, workspace]);

	const { data: integrations } = useIntegrationList({});

	const integrationType = integrations?.results?.find(
		(i) => i.id === metric.integration
	)?.type;

	if (!metric) {
		return null;
	}

	const { metricCols, timeCols } = metricOptions(results);

	const showMetricEmptyState =
		!metric.primary ||
		!metric.time ||
		results.length === 0 ||
		!includes(
			metricCols.map(({ name }) => name),
			metric.primary
		) ||
		!includes(
			timeCols.map(({ name }) => name),
			metric.time
		);

	const curryOnChange = (key: string) => (value: DjangoValueType) => {
		onChange({ [key]: value });
	};

	return (
		<>
			{withChart && (
				<Box className={classes.overlayWrapper}>
					{/* We only want to show the navigate action if we are embedding the chart somewhere,
							indicated by the lack of the `withQuery` prop. */}
					{!withQuery && (
						<ActionIcon
							pos="absolute"
							right="1.25rem"
							top="1.25rem"
							style={{
								zIndex: 100,
							}}
							onClick={handleNavigate}
						>
							<Icon name="maximize" />
						</ActionIcon>
					)}
					{showMetricEmptyState && (
						<Center
							data-testid="preview-metric-chart-empty-state"
							mih={MIN_HEIGHT}
							p={theme.spacing.xl}
						>
							<EmptyState
								key={chartKey}
								iconName="chartHistogram"
								title="Preview metric"
								description="Configure the primary and time fields after the Metric query has been executed"
								includeGoBack={false}
								withActions={
									results.length > 0 ? (
										<Group
											key={chartKey}
											noWrap={false}
											w={500}
											align="center"
											sx={{ justifyContent: 'center', flexWrap: 'wrap' }}
										>
											<TimeSelectAction
												metric={metric}
												onChange={curryOnChange('time')}
											/>
											<PrimarySelectAction
												metric={metric}
												onChange={curryOnChange('primary')}
											/>
										</Group>
									) : null
								}
								size="sm"
							/>
						</Center>
					)}

					{!showMetricEmptyState && (
						<Chart
							dimension={dimension}
							key={chartKey}
							primary={primary}
							results={timeMetricData}
							numericFormat={metric.numeric_format}
						/>
					)}
				</Box>
			)}
			{withQuery && (
				<Box className={cx(classes.overlayWrapper, classes.queryWrapper)}>
					{showQueryEmptyState && (
						<Center
							data-testid="preview-metric-query-empty-state"
							mih={MIN_HEIGHT}
							p={theme.spacing.xl}
						>
							<EmptyState
								iconName="code"
								title="New query"
								description="Select an integration to begin using the SQL editor"
								includeGoBack={false}
								withActions={
									<IntegrationSelectAction
										metric={metric}
										onChange={curryOnChange('integration')}
									/>
								}
								size="sm"
							/>
						</Center>
					)}
					{!showQueryEmptyState && (
						// NOTE: It may be tempting to add scheduled executions here, but
						// this has a serious limitation: we do not know when Query Blocks
						// have been removed from a document. This means that orphaned
						// queryblocks (i.e. deleted from a document) are not deleted from
						// Secoda, and will continue to execute on a schedule. This is a
						// serious problem, and we should not add scheduled executions until
						// we have a solution.
						<SqlEditor
							// Re-render the editor when the integration type changes to
							// ensure the editor is re-initialized with the new value, and the
							// autocomplete is updated.
							key={metric.integration}
							autoCompleteIntegrationId={metric.integration}
							autoCompleteIntegrationType={integrationType}
							lineNumbers
							results={results}
							readOnly={!canEdit}
							defaultValue={metric.sql}
							onChange={onChangeQueryDebounce}
							onExecute={handleExecute}
							withActions={
								<Group spacing={theme.spacing.xs} data-testid="metric-actions">
									{withInlineIntegrationSelector && canEdit && (
										<IntegrationSelectAction
											disabled={isExecuting}
											key={chartKey}
											metric={metric}
											onChange={curryOnChange('integration')}
										/>
									)}
									<ErrorAction
										disabled={executionStatus?.status !== 'failed'}
										handleShow={() => setShowErrorDrawer(true)}
									/>
									<MetricJobProgress
										isExecuting={isExecuting}
										logs={executionStatus?.logs}
									/>
									{canEdit && (
										<RunAction
											disabled={isExecuting}
											handleExecute={handleExecute}
										/>
									)}
									<DotsAction
										lastUpdated={
											metric.last_run && moment(metric.last_run).fromNow()
										}
										handleCreateStandaloneMetric={
											!withChart && metric.hidden && metric.integration
												? handleCreateStandaloneMetric
												: undefined
										}
										handleDownloadMetric={handleDownload}
									/>
								</Group>
							}
						/>
					)}
				</Box>
			)}
			{executionStatus?.status === 'failed' && (
				<ErrorDrawer
					title={/\(.*errors.(.*)\)/.exec(executionStatus?.logs)?.[1]}
					errorMessage={executionStatus?.logs}
					open={showErrorDrawer}
					onClose={() => setShowErrorDrawer(false)}
					errorAt={dayjs().toISOString()}
				/>
			)}
		</>
	);
}
