import { useMutation, useQuery } from '@tanstack/react-query';
import { apiClient, getEndpoints } from '../../common';
import { queryClient } from '../../index';
import type { IInternalIntegrationStatus } from '../../types/models/internalIntegrationStatus';

const INTERNAL_INTEGRATION_STATUS_NAMESPACE = [
	'admin',
	'internal_integration_status',
];

const GITHUB_NAMESPACE = ['admin', 'github'];

export const invalidateInternalIntegrationStatusQueries = () =>
	queryClient.invalidateQueries(INTERNAL_INTEGRATION_STATUS_NAMESPACE);

const fetchStatuses = async (
	params: {
		latest_k?: number;
		integration_types?: Array<string>;
	} = {}
) => {
	const url = getEndpoints(INTERNAL_INTEGRATION_STATUS_NAMESPACE).byPath([
		'status',
	]);
	const { data } = await apiClient.get<Array<IInternalIntegrationStatus>>(url, {
		params,
	});
	return data;
};

export function useInternalIntegrationStatuses(params = {}) {
	const queryKey = [...INTERNAL_INTEGRATION_STATUS_NAMESPACE, 'status', params];
	return useQuery(queryKey, () => fetchStatuses(params));
}

export function useRemoveDanglingJobs() {
	return useMutation({
		mutationFn: async ({ integrationID }: { integrationID: string }) => {
			const url = getEndpoints(INTERNAL_INTEGRATION_STATUS_NAMESPACE).byPath([
				'remove_dangling_jobs',
				integrationID,
			]);

			return apiClient.patch(url);
		},
		onSettled: invalidateInternalIntegrationStatusQueries,
	});
}

export function useRemoveReservedJob() {
	return useMutation({
		mutationFn: async ({ databuilderJobID }: { databuilderJobID: string }) => {
			const url = getEndpoints(INTERNAL_INTEGRATION_STATUS_NAMESPACE).byPath([
				'remove_reserved_job',
				databuilderJobID,
			]);

			return apiClient.patch(url);
		},
		onSettled: invalidateInternalIntegrationStatusQueries,
	});
}

export function useRetryJob() {
	return useMutation({
		mutationFn: async ({ databuilderJobID }: { databuilderJobID: string }) => {
			const url = getEndpoints(INTERNAL_INTEGRATION_STATUS_NAMESPACE).byPath([
				'remove_reserved_job',
				databuilderJobID,
			]);

			return apiClient.patch(url);
		},
		onSettled: invalidateInternalIntegrationStatusQueries,
	});
}
