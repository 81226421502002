import { Skeleton, Stack } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { Icon, ListBox, Text } from '@repo/foundations';
import { useAIEmbedded, useUpdateQuestion } from '../../../../api';
import { sanitizePromptMessage } from '../../../AIAssistant/utils';

interface RelatedResourceAIChatItemProps {
	promptId: string;
	entityId: string;
}

export function RelatedResourceAIChatItem({
	promptId,
	entityId,
}: RelatedResourceAIChatItemProps) {
	const { data: promptData, isLoading } = useAIEmbedded({ id: promptId });
	const userData = promptData?.user;

	const { mutateAsync } = useUpdateQuestion({
		options: {
			onSuccess: () => {
				showNotification({
					title: 'AI link added',
					message: 'AI chat linked successfully',
					color: 'green',
				});
			},
		},
	});

	const handleClick = async () => {
		await mutateAsync({
			data: {
				id: entityId,
				ai_prompt: promptId,
			},
		});
	};

	if (isLoading) {
		return (
			<ListBox.Item icon={<Icon name="sparkles" />}>
				<Stack spacing={0}>
					<Text size="sm" lineClamp={1}>
						<Skeleton width={150} height={16} />
					</Text>
					<Text size="xs" color="text/secondary/default" lineClamp={1}>
						<Skeleton width={100} height={12} />
					</Text>
				</Stack>
			</ListBox.Item>
		);
	}

	return (
		<ListBox.Item icon={<Icon name="sparkles" />} onClick={handleClick}>
			<Stack spacing={0}>
				<Text size="sm" lineClamp={1}>
					{sanitizePromptMessage(promptData?.prompt || 'Untitled AI Chat')}
				</Text>
				<Text size="xs" color="text/secondary/default" lineClamp={1}>
					{`AI Chat from ${userData?.display_name || userData?.email || 'Unknown User'}`}
				</Text>
			</Stack>
		</ListBox.Item>
	);
}
