import type { MantineTheme } from '@mantine/core';
import { createStyles, Group, Stack, Tooltip } from '@mantine/core';
import type { ISecodaEntity } from '@repo/common/models/secodaEntity';
import { Badge, Text } from '@repo/foundations';
import { typography } from '@repo/theme/primitives';
import {
	IconDatabase,
	IconFolder,
	IconSchema,
	IconTable,
} from '@tabler/icons-react';
import { isNil, size, startCase, truncate } from 'lodash-es';
import EntityProperty from '../../../pages/SearchPage/SearchResults/SearchResultCardView/EntityProperty';
import { ENTITY_TYPE_VARIANT_MAP } from '../Legacy/constants';

interface INodeMenuItemProps {
	entity: Pick<
		ISecodaEntity,
		| 'entity_type'
		| 'native_type'
		| 'icon'
		| 'integration'
		| 'id'
		| 'title'
		| 'title_cased'
		| 'search_metadata'
	>;
}

const useStyles = createStyles((theme: MantineTheme) => ({
	group: {
		flexGrow: 1,
	},
	title: {
		fontSize: theme.fontSizes.sm,
		fontWeight: typography.weight.semibold,
	},
}));

function NodeMenuItem({ entity }: INodeMenuItemProps) {
	const { classes } = useStyles();

	return (
		<Group className={classes.group} position="apart">
			<Stack spacing={0}>
				<Tooltip
					label={entity.title_cased ?? entity.title ?? ''}
					disabled={size(entity.title) <= 40}
					withinPortal
				>
					<Text className={classes.title}>
						{truncate(entity.title_cased ?? entity.title ?? '', {
							length: 40,
						})}
					</Text>
				</Tooltip>
				<Group spacing={12}>
					<EntityProperty
						icon={IconDatabase}
						iconSize={12}
						label={entity?.search_metadata?.database}
						labelFontSize={12}
						labelTruncateLength={14}
						isHidden={isNil(entity?.search_metadata?.database)}
					/>
					<EntityProperty
						icon={IconSchema}
						iconSize={12}
						label={entity?.search_metadata?.schema}
						labelFontSize={12}
						labelTruncateLength={14}
						isHidden={isNil(entity?.search_metadata?.schema)}
					/>
					<EntityProperty
						icon={IconTable}
						iconSize={12}
						label={entity?.search_metadata?.table}
						labelFontSize={12}
						labelTruncateLength={14}
						isHidden={isNil(entity?.search_metadata?.table)}
					/>
					<EntityProperty
						icon={IconFolder}
						iconSize={12}
						label={entity?.search_metadata?.group}
						labelFontSize={12}
						isHidden={isNil(entity?.search_metadata?.group)}
					/>
				</Group>
			</Stack>
			<Badge
				variant={
					entity.entity_type in ENTITY_TYPE_VARIANT_MAP
						? ENTITY_TYPE_VARIANT_MAP[entity.entity_type]
						: 'info'
				}
			>
				{startCase(entity.native_type || entity.entity_type)}
			</Badge>
		</Group>
	);
}

export default NodeMenuItem;
