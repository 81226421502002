import type { ReactNode } from 'react';
import { useRowExpansionStatus } from './hooks';
import type { DataTableRowExpansionCollapseProps } from './types';

type DataTableRowExpansionProps = {
	open: boolean;
	colSpan: number;
	content: () => ReactNode;
	collapseProps?: DataTableRowExpansionCollapseProps;
};

export default function DataTableRowExpansion({
	open,
	colSpan,
	content,
	collapseProps,
}: DataTableRowExpansionProps) {
	const { expanded, visible } = useRowExpansionStatus(
		open,
		collapseProps?.transitionDuration
	);
	return <>{visible ? content() : null}</>;
}
