import { useDisclosure } from '@mantine/hooks';
import { IconButton } from '@repo/foundations';
import { useCallback } from 'react';

export interface IPinnedToggleProps {
	isPinned: boolean;
	onChange: (isPinned: boolean) => void;
}

function PinnedToggle({ isPinned, onChange }: IPinnedToggleProps) {
	const [fillIcon, { toggle }] = useDisclosure(isPinned);
	const handleClick = useCallback(() => {
		onChange(!fillIcon);
		toggle();
	}, [fillIcon, toggle, onChange]);

	return (
		<IconButton
			tooltip="Pin Resource"
			onClick={handleClick}
			iconName="pinned"
			fillIcon={fillIcon}
			variant="tertiary"
		/>
	);
}

export default PinnedToggle;
