import { Menu, TextInput, createStyles } from '@mantine/core';
import { useDebouncedValue, useDidUpdate } from '@mantine/hooks';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';

const useStyles = createStyles(() => ({
	textInput: {
		width: '100%',
	},
	menuDropdown: {
		minHeight: 'fit-content',
		width: '100%',
	},
}));

interface AutomationCardTextProps {
	value?: string | boolean;
	targetButton: JSX.Element;
	onChange: (value: string, label?: string) => void;
}

function AutomationCardText({
	value,
	targetButton,
	onChange,
}: AutomationCardTextProps) {
	const { classes } = useStyles();

	if (typeof value === 'boolean') {
		throw new Error('Invalid value has been provided');
	}

	const [text, setText] = useState<string>(value ?? '');
	const [debouncedText] = useDebouncedValue(text ?? '', 250);
	const [opened, setOpened] = useState(false);

	useEffect(() => {
		setText(value ?? '');
	}, [value]);

	const open = () => {
		setOpened(true);
	};

	const close = () => setOpened(false);

	useDidUpdate(() => {
		onChange(debouncedText, debouncedText);
	}, [debouncedText]);

	const handleUpdateValue = (val: string) => {
		setText(val);
	};

	return (
		<Menu
			classNames={{
				dropdown: classes.menuDropdown,
			}}
			opened={opened}
			onClose={close}
			onOpen={open}
			width={300}
			position="bottom-start"
			withinPortal
		>
			<Menu.Target>{targetButton}</Menu.Target>
			<Menu.Dropdown>
				<TextInput
					className={classes.textInput}
					placeholder="Enter the value..."
					onChange={(e) => handleUpdateValue(e.currentTarget.value)}
					value={text}
					onKeyDown={(event) => {
						if (event.key === 'Enter' || event.key === 'Escape') {
							close();
						}
					}}
				/>
			</Menu.Dropdown>
		</Menu>
	);
}

export default observer(AutomationCardText);
