import { Box, createStyles } from '@mantine/core';

const useStyles = createStyles((theme) => ({
	separator: {
		width: '1px',
		height: theme.spacing.md,
		background: theme.other.getColor('border/primary/default'),
	},
}));

export default function Separator() {
	const { classes } = useStyles();
	return <Box className={classes.separator} />;
}
