import {
	Box,
	createStyles,
	Group,
	Menu,
	TextInput,
	UnstyledButton,
} from '@mantine/core';
import { useInputState } from '@mantine/hooks';
import { Icon, Text } from '@repo/foundations';
import { useUpdateEffect } from 'ahooks';
import { capitalize } from 'lodash-es';
import type { CustomPropertyType } from './CustomProperty.types';
import ModifyCustomProperty from './ModifyCustomProperty';

export interface IPropertyTitleProps {
	value: string;
	readOnly: boolean;
	hidden: boolean;
	onChange: (value: string) => void;
	type: CustomPropertyType;
	onTypeChange: (type: CustomPropertyType) => void;
	onRemove: VoidFunction;
}

const useStyles = createStyles((theme) => ({
	propertyTitle: {
		width: theme.other.space[28],
	},
}));

function PropertyTitle({
	value: initialValue,
	readOnly,
	onChange,
	hidden = false,
	type,
	onTypeChange,
	onRemove,
}: IPropertyTitleProps) {
	const { classes } = useStyles();
	const [value, handleChange] = useInputState(initialValue);

	useUpdateEffect(() => {
		onChange(value);
	}, [value]);

	if (hidden) {
		return null;
	}

	if (readOnly) {
		return (
			<Text size="sm" color="text/secondary/default" lineClamp={1}>
				{value}
			</Text>
		);
	}

	return (
		<Menu position="bottom">
			<Menu.Target>
				<UnstyledButton
					data-testid={`custom-property-title-${value}-tfme0wGb`}
					title={value}
					className={classes.propertyTitle}
				>
					<Text size="sm" color="text/secondary/default" lineClamp={1}>
						{value}
					</Text>
				</UnstyledButton>
			</Menu.Target>
			<Menu.Dropdown>
				<Box px="xs" pt="sm" pb="3xs">
					<TextInput
						data-testid={`custom-property-title-input-${value}-3o847lkj`}
						size="sm"
						value={value}
						onChange={handleChange}
						disabled={readOnly}
					/>
				</Box>
				<ModifyCustomProperty
					target={
						<UnstyledButton w="100%">
							<Menu.Item icon={<Icon name="adjustmentsHorizontal" />}>
								<Group noWrap position="apart">
									Type
									<Group spacing="3xs">
										{capitalize(type)}
										<Icon name="chevronRight" />
									</Group>
								</Group>
							</Menu.Item>
						</UnstyledButton>
					}
					value={type}
					onChange={onTypeChange}
				/>

				<Menu.Divider />
				<Menu.Item icon={<Icon name="trash" />} onClick={onRemove}>
					Delete property
				</Menu.Item>
			</Menu.Dropdown>
		</Menu>
	);
}

export default PropertyTitle;
