import { createStyles, Space, Tabs } from '@mantine/core';
import { Button } from '@repo/foundations';
import { IconBrandUnsplash } from '@tabler/icons-react';
import type { IDefaultColorPickerPanelProps } from './components/DefaultColorPickerPanel';
import DefaultColorPickerPanel from './components/DefaultColorPickerPanel';
import UnsplashCoverPickerPanel from './components/UnsplashCoverPickerPanel';
import UploadPanel from './components/UploadPanel';

export interface ICoverImagePickerProps extends IDefaultColorPickerPanelProps {
	onRemove: VoidFunction;
}

const useStyles = createStyles({
	removeButton: {
		alignSelf: 'center',
	},
	spacer: {
		flexGrow: 1,
	},
});

function CoverImagePicker({
	onBackgroundImageChange,
	onRemove,
}: ICoverImagePickerProps) {
	const { classes, theme } = useStyles();

	return (
		<Tabs defaultValue="defaults">
			<Tabs.List>
				<Tabs.Tab value="defaults">Defaults</Tabs.Tab>
				<Tabs.Tab value="upload">Upload</Tabs.Tab>
				<Tabs.Tab
					icon={<IconBrandUnsplash fill={theme.colors.gray[6]} stroke={0} />}
					value="unsplash"
				>
					Unsplash
				</Tabs.Tab>
				<Space className={classes.spacer} />
				<Button className={classes.removeButton} onClick={onRemove}>
					Remove
				</Button>
			</Tabs.List>

			<DefaultColorPickerPanel
				onBackgroundImageChange={onBackgroundImageChange}
			/>
			<UploadPanel onBackgroundImageChange={onBackgroundImageChange} />
			<UnsplashCoverPickerPanel
				onBackgroundImageChange={onBackgroundImageChange}
			/>
		</Tabs>
	);
}

export default CoverImagePicker;
