import { useQuery } from '@tanstack/react-query';
import { apiClient, getEndpoints } from '../../common';
import type { UseQueryOptionsArgs } from '../../factories/types';
import type { ISecodaEntity } from '../../types';
import { monitorsQueryKeyFactory } from './constants';

interface UseMonitorTargetArgs {
	monitorId: string;
	options: UseQueryOptionsArgs;
}
export const useMonitorTarget = ({
	monitorId,
	options,
}: UseMonitorTargetArgs) => {
	const queryKey = monitorsQueryKeyFactory.byArgs('target', {
		monitorId: monitorId,
	});
	const queryFn = async () => {
		const url = getEndpoints(monitorsQueryKeyFactory.namespace).root();

		const { data: responseData } = await apiClient.get(
			`${url}${monitorId}/target/`
		);
		return responseData as ISecodaEntity;
	};
	return useQuery(queryKey, queryFn, options);
};
