import type { IconNames } from '@repo/foundations';
import { createQueryKeys } from '../../factories';
import type { IReport } from '../../types';

export const REPORTS_NAMESPACE = ['report', 'reports'];

export const reportsQueryKeyFactory = createQueryKeys(REPORTS_NAMESPACE);

export type ReportTemplateConfig = {
	value: string;
	label: string;
	reportType: IReport['type'];
	integrationTypes?: string[];
	icon?: IconNames | string;
};

export const REPORT_TEMPLATES: ReportTemplateConfig[] = [
	{
		value: 'secoda',
		label: 'Secoda Report',
		reportType: 'analytics',
		integrationTypes: ['secoda'],
	},
];
