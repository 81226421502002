import type { PopoverBaseProps } from '@mantine/core';
import { Box, createStyles } from '@mantine/core';
import RichTooltip from '@repo/common/components/RichTooltip/RichTooltip';
import { Text } from '@repo/foundations';
import { useCallback, useState } from 'react';
import { useAuthUser, useIntegrationPrefetched } from '../../api';

function calculatePosition(e: React.MouseEvent) {
	const mouseX = e.clientX;
	const targetDims = (e.target as HTMLElement).getBoundingClientRect();

	if (mouseX < targetDims.width / 3 + targetDims.left) {
		return 'bottom-start';
	} else if (mouseX > (targetDims.width / 3) * 2 + targetDims.left) {
		return 'bottom-end';
	} else {
		return 'bottom';
	}
}

export interface DescriptionEditorTooltipProps {
	integrationId: string;
	initialValue?: string;
	persistDescription: boolean;
}

const useStyles = createStyles((theme) => ({
	editorWrapper: {
		width: '100%',
		position: 'relative',
		paddingRight: theme.spacing.sm,
		marginRight: theme.spacing.sm,
	},
}));

function DescriptionEditorTooltip({
	integrationId,
	initialValue,
	persistDescription,
}: DescriptionEditorTooltipProps) {
	const { data: integration } = useIntegrationPrefetched({ id: integrationId });
	const { isAdminUser } = useAuthUser();
	const { classes } = useStyles();

	const [showTooltip, setShowTooltip] = useState(false);
	const [calculatedPos, setCalculatedPos] =
		useState<PopoverBaseProps['position']>('bottom-start');

	const onClick = useCallback((e: React.MouseEvent) => {
		setCalculatedPos(calculatePosition(e));
		setShowTooltip(true);
	}, []);

	return (
		<Box onMouseLeave={() => setShowTooltip(false)}>
			{persistDescription ? (
				<RichTooltip
					title={
						initialValue
							? `Description comes from ${integration?.name}`
							: `No description set in ${integration?.name}`
					}
					body={
						isAdminUser && (
							<Text size="sm" color={'text/secondary/default'}>
								To maintain in Secoda, go to integration settings.
							</Text>
						)
					}
					linkLabel={isAdminUser ? `${integration?.name} settings` : undefined}
					linkUrl={
						isAdminUser
							? `/integrations/${integration?.id}/preferences`
							: undefined
					}
					hidden={!integrationId || !showTooltip}
					position={calculatedPos}
				>
					<Box className={classes.editorWrapper} onClick={onClick}>
						<Text>{initialValue ? initialValue : 'No description'}</Text>
					</Box>
				</RichTooltip>
			) : (
				<Box className={classes.editorWrapper} onClick={onClick}>
					<Text>{initialValue ? initialValue : 'No description'}</Text>
				</Box>
			)}
		</Box>
	);
}

export default DescriptionEditorTooltip;
