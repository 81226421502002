import type { Icon as TablerIcon } from '@tabler/icons-react';
import {
	IconAlignJustified,
	IconDiscountCheck,
	IconFileDescription,
	IconFolders,
	IconLetterCase,
	IconLine,
	IconShieldLockFilled,
	IconTag,
	IconUser,
} from '@tabler/icons-react';
import type { IFilterOptionSelectorItem } from './FilterOptionSelector';
import type { MetadataMapKey } from './types';

export const METADATA_PROPS = [
	'description',
	'definition',
	'verified',
	'pii',
	'tags',
	'collections',
	'owners',
] as const;

export const FILTER_OPTIONS = [
	'exactName',
	'sameName',
	'lineageDownstream',
] as const;

export const METADATA_MAP: Record<
	MetadataMapKey,
	{
		icon: TablerIcon;
		label: string;
	}
> = {
	description: {
		icon: IconAlignJustified,
		label: 'Description',
	},
	definition: {
		icon: IconFileDescription,
		label: 'Documentation',
	},
	verified: {
		icon: IconDiscountCheck,
		label: 'Verification',
	},
	pii: {
		icon: IconShieldLockFilled,
		label: 'Governance',
	},
	tags: {
		icon: IconTag,
		label: 'Tags',
	},
	collections: {
		icon: IconFolders,
		label: 'Collections',
	},
	owners: {
		icon: IconUser,
		label: 'Owners',
	},
};

export const FILTER_OPTIONS_MAP: IFilterOptionSelectorItem[] = [
	{
		value: 'exactName',
		label: 'Related by: Exact name',
		icon: IconLetterCase,
	},
	{
		value: 'sameName',
		label: 'Related by: Similar name',
		icon: IconLetterCase,
	},
	{
		value: 'lineageDownstream',
		label: 'Related by: Downstream lineage',
		icon: IconLine,
	},
];
