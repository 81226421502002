import { Group } from '@mantine/core';
import CollapsableStack from '@repo/common/components/CollapsableStack';
import { Text } from '@repo/foundations';
import { isEmpty } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import type { IGlossaryTerm } from '../../../../api';
import { useAuthUser, useUpdateSecodaEntity } from '../../../../api';
import { isViewerOfEntity } from '../../../../utils/authorization/roles';
import { AddGlossarySynonym } from '../../../InlineGlossarySynonyms/AddGlossarySynonym';
import { GlossarySynonymPill } from '../../../InlineGlossarySynonyms/GlossarySynonymPill';

export interface ISynonymsStackProps {
	entity: IGlossaryTerm;
}

export function SynonymsStack({ entity }: ISynonymsStackProps) {
	const { user } = useAuthUser();
	const viewerOfEntity = isViewerOfEntity(user, entity);
	const editorOfEntity = !viewerOfEntity;

	const { mutateAsync: updateGlossaryTerm } = useUpdateSecodaEntity({});

	const glossaryTermSynonyms = useMemo(
		() => entity.synonyms ?? [],
		[entity.synonyms]
	);

	const handleOnAdd = useCallback(
		async (synonym: string) => {
			const synonyms = [...glossaryTermSynonyms, synonym];
			await updateGlossaryTerm({
				data: {
					id: entity.id,
					synonyms,
				},
			});
		},
		[entity.id, glossaryTermSynonyms, updateGlossaryTerm]
	);

	const handleOnRemove = useCallback(
		(synonym: string) => async () => {
			const synonyms = glossaryTermSynonyms.filter((s) => s !== synonym);
			await updateGlossaryTerm({
				data: {
					id: entity.id,
					synonyms,
				},
			});
		},
		[entity.id, glossaryTermSynonyms, updateGlossaryTerm]
	);

	return (
		<CollapsableStack
			groupName="Synonyms"
			collapsedText={String(entity?.synonyms?.length ?? '')}
			actions={editorOfEntity && <AddGlossarySynonym onAdd={handleOnAdd} />}
		>
			{isEmpty(glossaryTermSynonyms) && (
				<Text size="sm" color="text/secondary/default">
					Add synonym
				</Text>
			)}
			<Group spacing="xs">
				{glossaryTermSynonyms?.map((synonym, idx) => (
					<GlossarySynonymPill
						// eslint-disable-next-line react/no-array-index-key
						key={`${synonym}-${idx}`}
						synonym={synonym}
						onRemove={handleOnRemove(synonym)}
					/>
				))}
			</Group>
		</CollapsableStack>
	);
}
