import type { SelectItem } from '@mantine/core';
import { IconWrapper } from '@repo/common/components/IconWrapper';
import type { MultiSelectProps } from '@repo/foundations';
import { MultiSelect } from '@repo/foundations';
import { useUserGroupList } from '../../../api';
import type { Group } from '../../../interfaces/Group';

type GroupItem = SelectItem & {
	item: Group;
};

export function MultiGroupSelector({
	value,
	setValue,
	...rest
}: {
	value: string[];
	setValue: (xs: string[]) => void;
} & Omit<
	MultiSelectProps<GroupItem>,
	'data' | 'value' | 'setValue' | 'renderLabel' | 'renderIcon'
>) {
	const { data: groups } = useUserGroupList({});

	const items: GroupItem[] =
		groups?.results.map((group: Group) => ({
			value: group.id,
			label: group.name,
			item: group,
		})) ?? [];

	return (
		<MultiSelect
			{...rest}
			value={value}
			setValue={setValue}
			data={items}
			renderLabel={(item) => item.item.name}
			renderIcon={(item) => <IconWrapper>{item.item.icon}</IconWrapper>}
		/>
	);
}
