import { Box, Group, Skeleton, Stack } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { Icon, IconButton, Text } from '@repo/foundations';
import { useNavigate } from 'react-router';
import type { IQuestion } from '../../../../api';
import { useAIEmbedded, useUpdateQuestion } from '../../../../api';
import { sanitizePromptMessage } from '../../../AIAssistant/utils';
import { ResourceLinkType } from '../../../Link/useResourceLink';
import { ResourcePreview } from '../../../ResourcePreview';
import { useStyles } from '../EntityDisplay/EntityDisplay';

interface IRelatedAIChatProps {
	entity: IQuestion;
	isEditorOfEntity: boolean;
}

export function RelatedAIChat({
	entity,
	isEditorOfEntity,
}: IRelatedAIChatProps) {
	const { classes } = useStyles();
	const navigate = useNavigate();

	const { data: promptData } = useAIEmbedded({ id: entity.ai_prompt || '' });

	const { mutateAsync } = useUpdateQuestion({
		options: {
			onSuccess: () => {
				showNotification({
					title: 'AI link removed',
					message: 'Attached AI link removed successfully',
				});
			},
			onError: () => {
				showNotification({
					message: 'Failed to remove AI link',
				});
			},
		},
	});

	if (!entity.ai_prompt) {
		return null;
	}

	if (!promptData) {
		return (
			<Group spacing="xs" noWrap>
				<Group
					spacing="xs"
					position="apart"
					noWrap
					className={classes.itemWrapper}
					style={{ flex: 1 }}
				>
					<Group spacing="xs" align="flex-start" noWrap>
						<Box className={classes.iconWrapper}>
							<Icon name="sparkles" color="text/primary/default" />
						</Box>
						<Stack spacing={0}>
							<Skeleton height={16} width={160} />
							<Skeleton height={14} width={200} mt={4} />
						</Stack>
					</Group>
				</Group>
			</Group>
		);
	}

	const handleClick = () => {
		navigate(`/ai/${promptData.id}`);
	};

	const previewData = {
		id: promptData.id,
		prompt: sanitizePromptMessage(
			promptData.prompt || 'Attached link to Secoda AI'
		),
		entity_type: ResourceLinkType.AIChat as const,
		user: promptData.user || undefined,
	};

	return (
		<ResourcePreview
			id={promptData.id}
			data={previewData}
			resourceType={ResourceLinkType.AIChat}
			hasError={false}
			isLoading={false}
			position="left-start"
			width={300}
			withinPortal
		>
			<Group spacing="xs" className={classes.itemWrapper} noWrap>
				<Group
					spacing="xs"
					position="apart"
					noWrap
					className={classes.entityLink}
					onClick={handleClick}
					style={{ flex: 1 }}
				>
					<Group spacing="xs" align="flex-start" noWrap>
						<Box className={classes.iconWrapper}>
							<Icon name="sparkles" color="text/primary/default" />
						</Box>
						<Stack spacing={0}>
							<Text size="sm" lineClamp={1} className={classes.label}>
								{entity.title ?? 'Untitled'}
							</Text>
							<Text size="xs" color="text/secondary/default" lineClamp={1}>
								Secoda AI Chat from{' '}
								{promptData.user?.display_name ||
									promptData.user?.email ||
									'Unknown User'}
							</Text>
						</Stack>
					</Group>
				</Group>
				{isEditorOfEntity && (
					<Box className={classes.buttonWrapper}>
						<IconButton
							iconName="x"
							variant="tertiary"
							onClick={async (e) => {
								e.stopPropagation();
								e.preventDefault();
								await mutateAsync({
									data: { id: entity.id, ai_prompt: '' },
								});
							}}
						/>
					</Box>
				)}
			</Group>
		</ResourcePreview>
	);
}
