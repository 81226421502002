import { baseQueryHooksFactory } from '../../factories';
import type { ISecodaEntity } from '../../types';
import { automationPreviewQueryKeyFactory } from './constants';

const { useAutomationPreviewList } = baseQueryHooksFactory<
	ISecodaEntity,
	'automationPreview'
>('automationPreview', automationPreviewQueryKeyFactory);

export { useAutomationPreviewList };
