import type { MantineColor } from '@mantine/styles';
import type { MetricType, MonitorStatus } from '@repo/api-codegen';
import type { SelectablePropertyItem } from '@repo/common/components/SingleSelector/types';
import type { IconNames } from '@repo/foundations';
import type { SecodaThemeShades } from '@repo/theme/types';
import type { ColorNames } from '@repo/theme/utils';

export const MONITOR_ICON_MAPPING: Record<
	MonitorStatus,
	{
		iconName: IconNames;
		color: ColorNames;
		name: string;
		badgeColor?: MantineColor;
	}
> = {
	normal: {
		iconName: 'circleCheck',
		color: 'icon/success/default',
		name: 'Normal',
		badgeColor: 'green',
	},
	error: {
		iconName: 'alertTriangle',
		color: 'icon/critical/default',
		name: 'Error',
		badgeColor: 'red',
	},
	inactive: {
		iconName: 'circle',
		color: 'icon/secondary/default',
		name: 'Inactive',
		badgeColor: 'gray',
	},
	incident: {
		iconName: 'alertTriangle',
		color: 'icon/warning/default',
		name: 'Incident',
		badgeColor: 'yellow',
	},
	pending: {
		iconName: 'clock',
		color: 'icon/info/default',
		name: 'Pending',
		badgeColor: 'blue',
	},
	learning: {
		iconName: 'clock',
		color: 'icon/ai/default',
		name: 'Learning',
		badgeColor: 'purple',
	},
};

export const MEASUREMENT_TIME_RANGE_OPTIONS = [
	{
		value: '7',
		label: '7 days',
	},
	{
		value: '14',
		label: '14 days',
	},
	{
		value: '30',
		label: '30 days',
	},
];

export const SCHEDULE_OPTIONS: SelectablePropertyItem[] = [
	{
		label: 'Hourly',
		value: '1',
		iconName: 'clock',
	},
	{
		label: 'Every 3 hours',
		value: '3',
		iconName: 'clock',
	},
	{
		label: 'Every 6 hours',
		value: '6',
		iconName: 'clock',
	},
	{
		label: 'Every 12 hours',
		value: '12',
		iconName: 'clock',
	},
	{
		label: 'Daily',
		value: '24',
		iconName: 'clock',
	},
];

export const THRESHOLD_OPTIONS = [
	{
		label: 'Automatic',
		value: 'automatic',
		iconName: 'sparkles' as IconNames,
	},
	{
		label: 'Manual',
		value: 'manual',
		iconName: 'adjustmentsHorizontal' as IconNames,
	},
];

export const MINIMUM_MENU_OPTIONS = [
	{
		label: 'No minimum',
		value: 'null',
	},
	{
		label: 'Below',
		value: 'below',
	},
];

export const MAXIMUM_MENU_OPTIONS = [
	{
		label: 'No maximum',
		value: 'null',
	},
	{
		label: 'Above',
		value: 'above',
	},
];

export const METRIC_TYPE_INFORMATION: Record<
	MetricType,
	{
		label: string;
		iconName: IconNames;
		description: string;
		fill: SecodaThemeShades;
		value: MetricType;
		group: 'table' | 'column' | 'custom' | 'analytics' | 'job';
		canSchedule: boolean;
		isGrouped: boolean;
	}
> = {
	row_count: {
		value: 'row_count',
		label: 'Row count',
		description: 'Tracks the number of rows over time.',
		iconName: 'layoutRows',
		fill: 'icon/emphasis/default',
		group: 'table',
		canSchedule: true,
		isGrouped: false,
	},
	cardinality: {
		value: 'cardinality',
		label: 'Cardinality',
		description: 'Tracks the number of distinct values in a column.',
		iconName: 'columns2',
		fill: 'icon/emphasis/default',
		group: 'column',
		canSchedule: true,
		isGrouped: false,
	},
	freshness: {
		value: 'freshness',
		label: 'Freshness',
		description: 'Tracks the time elapsed since last update.',
		iconName: 'clockPlay',
		fill: 'icon/emphasis/default',
		group: 'table',
		canSchedule: true,
		isGrouped: false,
	},
	max: {
		value: 'max',
		label: 'Maximum',
		description: 'Tracks the maximum of all values in a column.',
		iconName: 'hash',
		fill: 'icon/emphasis/default',
		group: 'column',
		canSchedule: true,
		isGrouped: false,
	},
	mean: {
		value: 'mean',
		label: 'Mean',
		description: 'Tracks the average of all values in a column.',
		iconName: 'hash',
		fill: 'icon/emphasis/default',
		group: 'column',
		canSchedule: true,
		isGrouped: false,
	},
	min: {
		value: 'min',
		label: 'Minimum',
		description: 'Tracks the minimum of all values in a column.',
		iconName: 'hash',
		fill: 'icon/emphasis/default',
		group: 'column',
		canSchedule: true,
		isGrouped: false,
	},
	null_percentage: {
		value: 'null_percentage',
		label: 'Null percentage',
		description: 'Tracks the percentage of values in a column that are null.',
		iconName: 'columns2',
		fill: 'icon/emphasis/default',
		group: 'column',
		canSchedule: true,
		isGrouped: false,
	},
	unique_percentage: {
		value: 'unique_percentage',
		label: 'Unique percentage',
		description: 'Tracks the percentage of values in a column that are unique.',
		iconName: 'columns2',
		fill: 'icon/emphasis/default',
		group: 'column',
		canSchedule: true,
		isGrouped: false,
	},
	custom_sql: {
		value: 'custom_sql',
		label: 'Custom SQL',
		description: 'Define a monitor using custom SQL.',
		iconName: 'code',
		fill: 'icon/emphasis/default',
		group: 'custom',
		canSchedule: true,
		isGrouped: false,
	},
	analytics: {
		label: 'Analytics',
		description: 'Tracks the specific integration analytics',
		iconName: 'chartBar',
		fill: 'icon/emphasis/default',
		value: 'analytics',
		group: 'analytics',
		canSchedule: false,
		isGrouped: false,
	},
	job_success_rate: {
		value: 'job_success_rate',
		label: 'Job Success Rate',
		description: 'Tracks the percentage of successful job executions.',
		iconName: 'circleCheck',
		fill: 'icon/emphasis/default',
		group: 'job',
		canSchedule: false,
		isGrouped: true,
	},
	job_error_rate: {
		value: 'job_error_rate',
		label: 'Job Error Rate',
		description: 'Tracks the percentage of failed job executions.',
		iconName: 'hash',
		fill: 'icon/emphasis/default',
		group: 'job',
		canSchedule: false,
		isGrouped: true,
	},
	job_duration: {
		value: 'job_duration',
		label: 'Job Duration',
		description: 'Measures the time taken for job completion.',
		iconName: 'clockPlay',
		fill: 'icon/emphasis/default',
		group: 'job',
		canSchedule: false,
		isGrouped: false,
	},
};
