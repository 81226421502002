import { useSortable } from '@dnd-kit/sortable';
import type { MantineColor } from '@mantine/core';
import { createStyles, Group, ScrollArea, Stack } from '@mantine/core';
import { Text, Title } from '@repo/foundations';
import { typography } from '@repo/theme/primitives';
import { motion } from 'framer-motion';
import { useMemo } from 'react';
import type { IWidget } from '../../../../../../../api';
import { openConfirmModal } from '../../../../../../../components/ModalManager';
import type { WidgetSize } from '../../../../../../../interfaces';
import { WidgetType } from '../../../../../../../interfaces';
import { useHomepageTeam } from '../../../../../hooks/useHomepageTeam';
import type {
	HomepageWidgetType,
	WidgetRemoveFn,
	WidgetUpdateFn,
} from '../../../../../types';
import { WIDGET_HEIGHT } from '../../../constants';
import {
	getAnimation,
	getTransition,
	getWidgetColors,
	getWidgetSpan,
	getWidgetTitle,
	isTextWidget,
} from '../../../utils';
import WidgetMenu from './WidgetMenu';

interface IStyleParams {
	height?: number;
	color?: string;
}

type IWidgetWrapperProps = {
	widget: IWidget;
	children: React.ReactNode;
	withMenu?: boolean;
	onUpdateWidget: WidgetUpdateFn;
	onRemoveWidget: WidgetRemoveFn;
	filter?: React.ReactNode;
	right?: React.ReactNode;
};

const useStyles = createStyles(
	(theme, { color, height = WIDGET_HEIGHT }: IStyleParams) => {
		const widgetColors = getWidgetColors(color, theme);

		return {
			wrapper: {
				display: 'flex',
				height,
				width: '100%',
				borderRadius: theme.spacing.sm,
				...widgetColors,
			},
			content: {
				width: '100%',
			},
			header: {
				gap: 0,
				padding: theme.spacing.sm,
				paddingBottom: 0,
			},
			title: {
				cursor: 'grab',
				flexGrow: 1,
				height: '100%',
				display: 'inline-flex',
				alignItems: 'center',
				padding: 8,
				fontSize: typography.text.sm,
				fontWeight: typography.weight.bold,
			},
			titleIcon: {
				paddingTop: 2,
			},
			grabbing: {
				cursor: 'grabbing',
			},
			innerContent: {
				width: '100%',
				height: '100%',
				padding: theme.spacing.sm,
				paddingTop: 0,
				flexGrow: 1,
			},
			cursorDefault: {
				cursor: 'default',
			},
		};
	}
);

function WidgetWrapper({
	widget,
	children,
	withMenu = false,
	onUpdateWidget,
	onRemoveWidget,
	filter = null,
	right,
}: IWidgetWrapperProps) {
	const { isTeamViewerUser } = useHomepageTeam();

	const widgetHeight =
		widget.type === WidgetType.INTEGRATIONS && widget.size === 'full'
			? 180
			: undefined;

	const { cx, classes, theme } = useStyles({
		color: widget.color,
		height: widgetHeight,
	});

	const { id, size, type, title, color } = widget;

	const {
		attributes,
		setNodeRef,
		setActivatorNodeRef,
		listeners,
		transform,
		isDragging,
	} = useSortable({
		id,
		transition: null,
		disabled: isTeamViewerUser,
	});

	const style = {
		gridColumn: getWidgetSpan(size),
		boxShadow: isDragging
			? theme.shadows.lg
			: '0px 8px 16px 0px #0000000A , 0px 0px 0px 1px #23222114 inset',
	};

	const handleSizeChange = (widgetSize: WidgetSize) => () => {
		onUpdateWidget(widget.id, 'size')(widgetSize);
	};

	let handleColorChange: ((color: MantineColor) => void) | undefined;

	if (
		isTextWidget(widget.type) ||
		widget.type === WidgetType.DICTIONARY_TERM_CHART
	) {
		handleColorChange = (updatedColor: string) => {
			onUpdateWidget(widget.id, 'color')(updatedColor);
		};
	}

	const handleRemoveWidget = () =>
		openConfirmModal({
			title: (
				<Title order={4}>Are you sure you want to remove this widget?</Title>
			),
			children: (
				<Text size="md">
					This will removing the{' '}
					{getWidgetTitle(widget.type as HomepageWidgetType)} widget from the
					home page. This cannot be undone.
				</Text>
			),
			labels: {
				cancel: 'Cancel',
				confirm: 'Remove widget',
			},
			confirmProps: { variant: 'primary', tone: 'critical' },
			onConfirm: onRemoveWidget(widget.id),
		});

	const widgetTitle = getWidgetTitle(type as HomepageWidgetType, title);

	const memoizedHeader = useMemo(
		() => (
			<Group className={classes.header} position="apart" noWrap>
				<Text
					className={cx(classes.title, {
						[classes.grabbing]: isDragging,
						[classes.cursorDefault]: isTeamViewerUser,
					})}
					ref={setActivatorNodeRef}
					{...listeners}
				>
					<Group align="inherit" spacing={0} noWrap>
						{widgetTitle}
					</Group>
				</Text>
				<Group spacing={8}>
					{filter}
					{withMenu && (
						<WidgetMenu
							widgetType={widget.type}
							size={size}
							color={color}
							onSizeChange={handleSizeChange}
							onRemoveWidget={handleRemoveWidget}
							onColorChange={handleColorChange}
							onDictionaryTermChange={onUpdateWidget(widget.id, [
								'linked_entity',
								'title',
							])}
						/>
					)}
					{right}
				</Group>
			</Group>
		),
		[
			classes.header,
			classes.title,
			cx,
			isDragging,
			isTeamViewerUser,
			listeners,
			setActivatorNodeRef,
			widgetTitle,
			filter,
			withMenu,
			widget.type,
			size,
			color,
			handleSizeChange,
			handleRemoveWidget,
			handleColorChange,
			onUpdateWidget,
			right,
		]
	);

	const memoizedContent = useMemo(
		() => <ScrollArea className={classes.innerContent}>{children}</ScrollArea>,
		[classes.innerContent, children]
	);

	return (
		<motion.div
			className={classes.wrapper}
			ref={setNodeRef}
			layoutId={String(id)}
			style={style}
			animate={{
				...getAnimation(transform, isDragging),
			}}
			transition={getTransition(isDragging)}
			data-testid={`${type}-widget-wrapper-d4dfac1ae`}
			{...attributes}
		>
			<Stack className={classes.content} spacing={0}>
				{memoizedHeader}
				{memoizedContent}
			</Stack>
		</motion.div>
	);
}

export default WidgetWrapper;
