import { Box, createStyles, Group, Stack, Stepper } from '@mantine/core';
import { Button, Icon, IconButton } from '@repo/foundations';
import { isNil } from 'lodash-es';
import { TOP_NAVBAR_HEIGHT } from '../../SecodaAppShell/constants';
import { IntegrationTitle } from '../components/IntegrationTitle';
import IntegrationGuide from './IntegrationGuide';
import {
	ConnectionStep,
	MappingStep,
	SelectStep,
	SyncStep,
} from './StepComponents';
import useCreateIntegrationConnection, {
	IntegrationSteps,
} from './useCreateIntegrationConnection';

const useStyles = createStyles((theme) => ({
	header: {
		width: '100%',
		padding: `${theme.spacing.sm} ${theme.spacing['3xl']}`,
		height: `${TOP_NAVBAR_HEIGHT}px`,
		borderBottom: `1px solid ${theme.other.getColor('border/secondary/default')}`,
	},
	content: {
		height: `calc(100vh - ${TOP_NAVBAR_HEIGHT + 1}px)`, // modalHeader border-bottom is 1px
		overflow: 'auto',
	},
	mainContent: {
		minWidth: '486px',
		flex: 1,
	},
	mainContentWrapper: {
		padding: `0 ${theme.spacing.xl} ${theme.spacing['4xl']} ${theme.spacing.xl}`,
		width: '486px',
		margin: '0 auto',
	},
	root: {
		// display: 'flex',
		// flexDirection: 'column',
		// alignItems: 'center',
		// justifyContent: 'space-between',
		// width: '100%',
		// height: '100%',
	},
	steps: {
		padding: `${theme.spacing.lg} 0`,
		// flexShrink: 1,
		// width: `calc(${theme.other.width.md}px + (2 * ${theme.spacing['3xl']}))`,
		// padding: `${theme.spacing.lg} ${theme.spacing['3xl']}`, // The horizontal padding is to hide the box shadow of the content
		// backgroundColor: theme.other.getColor('surface/primary/default'),
		// zIndex: 10,
	},
	step: {
		'.mantine-Stepper-stepLabel': {
			fontSize: theme.other.typography.text.sm,
			fontWeight: theme.other.typography.weight.semibold,
			color: theme.other.getColor('text/primary/default'),
		},
		'&:not([data-completed])': {
			'.mantine-Stepper-stepLabel': {
				color: theme.other.getColor('text/secondary/default'),
			},
		},
	},
	stepperContent: {
		// flexGrow: 1,
		// width: theme.other.width.lg,
		// paddingTop: theme.spacing.lg,
		// marginTop: theme.spacing['5xl'],
	},
	stepIcon: {
		border: 'none',
		'&[data-completed=true]': {
			backgroundColor: theme.other.getColor('surface/success/default'),
			svg: { stroke: theme.other.getColor('icon/success/default') },
		},
		'&[data-progress=true]': {
			backgroundColor: theme.other.getColor('fill/brand/default'),
			fontSize: theme.other.typography.text.sm,
			fontWeight: theme.other.typography.weight.semibold,
			color: theme.other.getColor('text/brand-on-fill/default'),
			svg: {
				stroke: theme.other.getColor('icon/primary/default'),
			},
		},
	},
	stepBody: {
		// marginLeft: theme.spacing.xs,
	},
	separator: {
		backgroundColor: theme.other.getColor('border/secondary/default'),
		height: 1,
	},
	separatorActive: {
		backgroundColor: theme.other.getColor('border/secondary/default'),
		height: 1,
	},
}));

function CreateIntegrationConnection() {
	const {
		integrationSpec,
		createdIntegration,
		setCreatedIntegration,
		currentStep,
		prevStep,
		nextStep,
		handleClose,
		stepNames,
	} = useCreateIntegrationConnection();

	const { classes, theme, cx } = useStyles();

	const hasDocumentation =
		integrationSpec.type === 'builtin' &&
		integrationSpec.value.documentationMarkdown &&
		currentStep === 0;

	if (isNil(integrationSpec.value)) {
		return null;
	}

	return (
		<Stack spacing={0}>
			<Group noWrap position="apart" className={classes.header}>
				<Button
					variant="tertiary"
					leftIconName="chevronLeft"
					style={{
						visibility: currentStep === 0 ? 'hidden' : 'visible',
					}}
					onClick={prevStep}
					disabled={currentStep === 0}
				>
					Back
				</Button>
				<IntegrationTitle
					integrationSpec={integrationSpec}
					title={
						integrationSpec.type === 'builtin'
							? `Connecting ${integrationSpec.value.name}`
							: `Connecting ${integrationSpec.value.versions[0]?.name}`
					}
				/>
				<IconButton variant="tertiary" iconName="x" onClick={handleClose} />
			</Group>
			<Group noWrap position="apart" spacing={0}>
				<Box className={cx(classes.content, classes.mainContent)}>
					<Box className={classes.mainContentWrapper}>
						<Stepper
							classNames={{
								root: classes.root,
								steps: classes.steps,
								step: classes.step,
								stepIcon: classes.stepIcon,
								stepBody: classes.stepBody,
								separator: classes.separator,
								separatorActive: classes.separatorActive,
								content: classes.stepperContent,
							}}
							active={currentStep}
							completedIcon={<Icon name="check" />}
							iconSize={theme.other.iconSize.lg}
							contentPadding="xl"
						>
							{stepNames.map((step) => (
								<Stepper.Step key={step} label={step}>
									<Stack spacing="2xl">
										{step === IntegrationSteps.CONNECTION && (
											<ConnectionStep
												integrationSpec={integrationSpec}
												integration={createdIntegration}
												setIntegration={setCreatedIntegration}
												nextStep={nextStep}
											/>
										)}
										{step === IntegrationSteps.IMPORT && createdIntegration && (
											<SelectStep
												integration={createdIntegration}
												integrationSpec={integrationSpec}
												nextStep={nextStep}
											/>
										)}
										{step === IntegrationSteps.MAPPING &&
											createdIntegration && (
												<MappingStep
													integration={createdIntegration}
													nextStep={nextStep}
												/>
											)}
										{step === IntegrationSteps.SYNC && createdIntegration && (
											<SyncStep
												integrationSpec={integrationSpec}
												integration={createdIntegration}
											/>
										)}
									</Stack>
								</Stepper.Step>
							))}
						</Stepper>
					</Box>
				</Box>
				{hasDocumentation && (
					<Box className={classes.content}>
						<IntegrationGuide
							type={integrationSpec.value.type}
							markdownURL={integrationSpec.value.documentationMarkdown}
						/>
					</Box>
				)}
			</Group>
		</Stack>
	);
}

export default CreateIntegrationConnection;
