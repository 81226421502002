import { baseQueryHooksFactory } from '../../factories';
import type { IIntegrationSshKey } from '../../types/models/integrationSSHKey';
import { integrationSSHKeyQueryKeyFactory } from './constants';

const {
	useIntegrationSshKeyList,
	useCreateIntegrationSshKey,
	useUpdateIntegrationSshKey,
} = baseQueryHooksFactory<IIntegrationSshKey, 'integrationSshKey'>(
	'integrationSshKey',
	integrationSSHKeyQueryKeyFactory
);

export {
	useCreateIntegrationSshKey,
	useIntegrationSshKeyList,
	useUpdateIntegrationSshKey,
};
