import { IconCalendar, IconFilter } from '@tabler/icons-react';

import { isEmpty } from 'lodash-es';
import type { IMetricWidget } from '../../../../api';
import { ScrollableDropdownFilter } from '../../../../components/ScrollableDropdownFilter/ScrollableDropdownFilter';
import { snakeCaseToTitleCase } from '../../../../utils/shared.utils';

interface IIntegrationFiltersProps {
	metricWidget: IMetricWidget;
	onFilterChange: (key: string, value: string | null) => void;
	filterMap: Record<string, string>;
	includeLookbackFilter?: boolean;
}

function getIntegrationFilters({
	metricWidget,
	onFilterChange,
	filterMap,
	includeLookbackFilter = true,
}: IIntegrationFiltersProps) {
	const filterOptions = metricWidget.metric_metadata?.filter_options || {};

	return (
		<>
			{includeLookbackFilter && (
				<ScrollableDropdownFilter
					key="lookback"
					icon={IconCalendar}
					options={[
						{
							value: 'weekly',
							label: 'Last week',
						},
						{
							value: 'monthly',
							label: 'Last month',
						},
					]}
					onChange={(value) => onFilterChange('lookback', value)}
					value={
						metricWidget.metric_metadata?.user_filter?.lookback ||
						metricWidget.metric_metadata?.fixed_filter?.lookback ||
						'7'
					}
				/>
			)}
			{Object.keys(filterOptions).map((filterName) => (
				<ScrollableDropdownFilter
					key={filterName}
					icon={IconFilter}
					options={[
						{
							value: null,
							label: `All ${snakeCaseToTitleCase(filterName)}`,
						},
						...filterOptions[filterName]
							.filter((option) => !isEmpty(option))
							.map((option) => ({
								value: option,
								label: option,
							})),
					]}
					onChange={(value) => onFilterChange(filterName, value)}
					value={filterMap[filterName] || null}
				/>
			))}
		</>
	);
}

export default getIntegrationFilters;
