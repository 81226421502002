import { Box, createStyles } from '@mantine/core';
import { TextInput } from '@repo/foundations';
import type {
	EditorDictionary,
	EmbedDescriptor,
	MenuItem,
} from '@repo/secoda-editor';
import { ToastType } from '@repo/secoda-editor';
import type { ComponentProps } from 'react';
import { useState } from 'react';

const useStyles = createStyles((theme) => ({
	wrapper: {
		margin: theme.spacing.xs,
	},
}));

interface LinkInputProps extends ComponentProps<typeof TextInput> {
	embedItem: EmbedDescriptor;
	dictionary: EditorDictionary;
	onShowToast?: (message: string, id: string) => void;
	onInsertBlock: (item: MenuItem) => void;
	onClose: () => void;
}

export function LinkInput({
	embedItem,
	dictionary,
	onShowToast,
	onClose,
	onInsertBlock,
	...props
}: LinkInputProps) {
	const { classes } = useStyles();
	const [text, setText] = useState('');

	function handleLinkInputKeydown(
		event: React.KeyboardEvent<HTMLInputElement>
	) {
		if (event.key === 'Enter') {
			event.preventDefault();
			event.stopPropagation();

			const matches = embedItem.matcher(text);

			if (!matches) {
				onShowToast?.(dictionary.embedInvalidLink, ToastType.Error);
				return;
			}

			onInsertBlock({
				name: 'embed',
				attrs: {
					href: text,
				},
			});
		}

		if (event.key === 'Escape') {
			onClose();
		}
	}

	function handleLinkInputPaste(event: React.ClipboardEvent<HTMLInputElement>) {
		const href = event.clipboardData.getData('text/plain');
		const matches = embedItem.matcher(href);

		if (matches) {
			event.preventDefault();
			event.stopPropagation();

			onInsertBlock({
				name: 'embed',
				attrs: {
					href,
				},
			});
		}
	}

	return (
		<Box className={classes.wrapper}>
			<TextInput
				{...props}
				value={text}
				onChange={(event) => setText(event.currentTarget.value)}
				onKeyDown={handleLinkInputKeydown}
				onPaste={handleLinkInputPaste}
				w={300}
			/>
		</Box>
	);
}
