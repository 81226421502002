import moment from 'moment';
import { cache } from '../../lib/stores/CacheStore';

/**
 * The minimum length of the input value required to trigger a snapshot update.
 * If the value is shorter than this, no snapshot will be taken.
 */
export const MIN_LENGTH_FOR_SNAPSHOT = 4;
export const MIN_MINUTES_BETWEEN_SNAPSHOTS = 2;

/**
 * Debounces and creates a local definition snapshot in the cache.
 * @param entityId - The unique identifier of the entity to associate the snapshot with.
 * @param value - The text value to create a snapshot for.
 */
export const debounceLocalDefinitionSnapshot = async (
	entityId: string,
	value?: string
) => {
	// Check if a value exists and if its length is greater than the minimum length for a snapshot.
	if (value && value.length > MIN_LENGTH_FOR_SNAPSHOT) {
		// Create an object to store snapshots of the definition.
		// Each snapshot is associated with a timestamp in ISO format.
		const snapshots: Record<string, string> = {};
		snapshots[moment().toISOString().split('.')[0]] = value;

		try {
			const storedSnapshots: Record<string, string> =
				await cache.definitions.get(entityId);
			delete storedSnapshots.id;
			// Check if the current text equals the latest snapshot:
			const latestSnapshot = Object.keys(storedSnapshots).pop();
			if (latestSnapshot) {
				// If it has been less than 2 minutes since the last snapshot,
				// then we do not need to store the snapshot.
				const lastSnapshotTime = moment(moment.utc(latestSnapshot));
				const now = moment();
				if (
					now.diff(lastSnapshotTime, 'minutes') < MIN_MINUTES_BETWEEN_SNAPSHOTS
				) {
					return;
				}

				if (storedSnapshots[latestSnapshot] === value) {
					// If the latest snapshot is the same as the current text,
					// then we do not need to store the snapshot.
					return;
				}
			}
			await cache.definitions.update(entityId, {
				...(storedSnapshots ?? {}),
				...(snapshots ?? {}),
			});
		} catch {
			// If the entityId does not exist in the cache, then we do not need to do anything.
			await cache.definitions.add({
				id: entityId,
				...(snapshots ?? {}),
			});
		}
	}
};

export const PARENT_DOC_ID = 'documentation';
