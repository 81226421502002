import { Title } from '@repo/foundations';
import { NavBarWrapper } from '.';

function ProfilePageNavBar() {
	return (
		<NavBarWrapper>
			<Title order={1} size="sm" data-testid="profile-page-nav-bar-title">
				Profile
			</Title>
		</NavBarWrapper>
	);
}

export default ProfilePageNavBar;
