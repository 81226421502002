import type { LiteQueryOut } from '@repo/api-codegen';
import { makeAutoObservable } from 'mobx';
import type { IQuery } from '../../../api';
import type { Lineage } from '../../../lib/models';
import type { EntityTabsStore } from '../../TableEntityPage/TableEntityTabs/TableEntityTabsStore';

export class EventEntityTabsStore implements EntityTabsStore {
	discussionCount: number | undefined = undefined;

	lineageCount: number | undefined = undefined;

	lineageUpstreamResults?: Lineage[] = undefined;

	lineageDownstreamResults?: Lineage[] = undefined;

	propertiesCount: number | undefined = undefined;

	// Only populated on some SecodaEntity elements.
	creationQuery: IQuery | LiteQueryOut | undefined = undefined;

	get tables() {
		return this.lineageUpstreamResults ?? [];
	}

	get charts() {
		return this.lineageDownstreamResults ?? [];
	}

	get tablesCount() {
		return this.lineageUpstreamResults?.length;
	}

	get chartsCount() {
		return this.lineageDownstreamResults?.length;
	}

	get isLineageLoading() {
		return this.lineageCount === undefined;
	}

	get isTablesLoading() {
		return this.tablesCount === undefined;
	}

	get isChartsLoading() {
		return this.chartsCount === undefined;
	}

	get isPropertiesLoading() {
		return this.propertiesCount === undefined;
	}

	get isDiscussionLoading() {
		return this.discussionCount === undefined;
	}

	reset = () => {
		this.lineageUpstreamResults = undefined;
		this.lineageCount = undefined;
		this.propertiesCount = undefined;
		this.lineageDownstreamResults = undefined;
		this.creationQuery = undefined;
		this.discussionCount = undefined;
	};

	setCreationQuery = (query: IQuery | LiteQueryOut) => {
		this.creationQuery = query;
	};

	setLineageCount = (count: number) => {
		this.lineageCount = count;
	};

	setPropertiesCount = (count: number) => {
		this.propertiesCount = count;
	};

	setDiscussionCount = (count: number) => {
		this.discussionCount = count;
	};

	setLineageUpstreamResults = (results: Lineage[]) => {
		this.lineageUpstreamResults = results;
	};

	setLineageDownstreamResults = (results: Lineage[]) => {
		this.lineageDownstreamResults = results;
	};

	constructor() {
		makeAutoObservable(this);
	}
}
