export const space = {
	0: 0,
	0.125: 0.5,
	0.25: 1,
	0.375: 1.5,
	0.5: 2,
	1: 4,
	1.5: 6,
	2: 8,
	3: 12,
	4: 16,
	5: 20,
	6: 24,
	7: 28,
	8: 32,
	9: 36,
	10: 40,
	12: 48,
	16: 64,
	20: 80,
	24: 96,
	28: 112,
	32: 128,
	40: 160,
	60: 240,
	80: 320,
	90: 360,
	100: 400,
	120: 480,
	160: 640,
	200: 800,
	240: 960,
	280: 1120,
	320: 1280,
} as const;
