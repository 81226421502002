import {
	ActionIcon,
	Anchor,
	Box,
	createStyles,
	Group,
	Modal,
	Stack,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { Prism } from '@mantine/prism';

import { useState } from 'react';

import CollapsableStack from '@repo/common/components/CollapsableStack';
import { Banner, Button, Icon, IconButton, Text } from '@repo/foundations';
import type { Monitor, MonitorMetricConfig } from '../../../api';
import { useUpdateMonitor } from '../../../api';
import { openDeleteConfirmModal } from '../../../components/ModalManager';
import SqlEditor from '../../../components/Sql/SqlEditor/SqlEditor';
import { supportsCustomQuery, supportsWhereClause } from '../utils';

interface ICustomQueryEditorProps {
	monitor: Monitor;
}

const useStyles = createStyles((theme) => ({
	prismRoot: {
		backgroundColor: theme.colors.gray[1],
		borderRadius: 8,
		padding: 5,
	},
	prismScrollArea: {
		backgroundColor: 'transparent',
		marginRight: 5,
	},
	prismCode: {
		backgroundColor: 'transparent !important',
		minHeight: 90,
		'text-wrap': 'balance',
	},
	editIcon: {
		position: 'absolute',
		right: 10,
		top: 10,
		boxShadow: ' 0px 0px 6px rgba(0, 0, 0, 0.15)',
		zIndex: 1, // Float this icon on top of the prism editor
	},
	editWrapper: {
		position: 'relative',
	},
	sqlEditor: {
		border: 'thin solid',
		borderColor: theme.other.getColor('border/secondary/default'),
		padding: 0,
		borderRadius: 10,
		minHeight: 140,
	},
	modalFooter: {
		borderTop: 'thin solid',
		borderColor: theme.other.getColor('border/secondary/default'),
	},
	warning: {
		padding: '10px 15px',
		backgroundColor: theme.other.getColor('fill/caution/default'),
	},
	modalTitle: {
		backgroundColor: theme.colors.gray[1],
	},
	modalBody: {
		marginTop: 20,
	},
	warningMessage: {
		color: theme.other.getColor('text/caution/default'),
	},
}));

function CustomQueryEditor({ monitor }: ICustomQueryEditorProps) {
	const canEdit = monitor.permissions?.edit || false;

	const isCustomSql = supportsCustomQuery(monitor.metric_type);
	const isWhereClause = !isCustomSql;
	const initialQuery = isCustomSql
		? (monitor.metric_config?.query_select ?? '')
		: (monitor.metric_config?.query_where ?? '');

	const [sqlEditorContent, setSqlEditorContent] =
		useState<string>(initialQuery);
	const { mutateAsync: updateMonitor } = useUpdateMonitor({});

	const { classes } = useStyles();

	const [opened, { open, close }] = useDisclosure();

	if (
		!(
			supportsCustomQuery(monitor.metric_type) ||
			supportsWhereClause(monitor.metric_type)
		)
	) {
		return null;
	}

	const handleUpdateMonitorConfig = async (
		key: keyof MonitorMetricConfig,
		value: string
	) => {
		if (!monitor.id) {
			return;
		}

		await updateMonitor({
			data: {
				id: monitor.id,
				metric_type: monitor.metric_type,
				metric_config: {
					[key]: value,
				},
			},
		});
	};

	const saveSqlEditorContent = () => {
		if (isCustomSql) {
			handleUpdateMonitorConfig('query_select', sqlEditorContent);
		} else {
			handleUpdateMonitorConfig('query_where', sqlEditorContent);
		}
		close();
	};

	const removeSqlEditorContent = () => {
		setSqlEditorContent('');
		if (isCustomSql) {
			handleUpdateMonitorConfig('query_select', '');
		} else {
			handleUpdateMonitorConfig('query_where', '');
		}
	};

	const handleRemoveSqlEditorContent = () => {
		const text = isCustomSql
			? 'Removing the SQL Query will reset monitor thresholds for re-learning, and automatically resolve all current incidents.'
			: 'Removing the WHERE Clause will reset monitor thresholds for re-learning, and automatically resolve all current incidents.';

		openDeleteConfirmModal({
			title: 'Remove Query',
			description: text,
			onConfirm: removeSqlEditorContent,
		});
	};

	const handleSqlEditorChange = (value: string) => {
		if (value === undefined) {
			return;
		}
		setSqlEditorContent(value);
	};

	const warningMessage = isCustomSql
		? 'Modifying the SQL Query will reset monitor thresholds for re-learning, and automatically resolve all current incidents.'
		: 'Modifying the WHERE Clause will reset monitor thresholds for re-learning, and automatically resolve all current incidents.';

	return (
		<CollapsableStack
			groupName={isWhereClause ? 'WHERE Clause' : 'Query'}
			defaultOpened={isCustomSql}
			actions={
				isWhereClause &&
				canEdit &&
				initialQuery === '' && (
					<ActionIcon onClick={open}>
						<Icon name="plus" />
					</ActionIcon>
				)
			}
		>
			<Stack spacing="sm">
				<Text size="xs" color="text/secondary/default">
					{isCustomSql
						? "Input a SQL query for execution. The value from the first column of the initial row will represent the monitor's metric."
						: 'Use a WHERE clause to specify conditions such as age > 30 AND country = "US" for precise monitoring.'}
				</Text>
				{(isCustomSql || initialQuery.length > 0) && (
					<Box className={classes.editWrapper}>
						<Group spacing="3xs" className={classes.editIcon}>
							<IconButton
								onClick={open}
								iconName="pencil"
								disabled={!canEdit}
							/>
							{isWhereClause && (
								<IconButton
									disabled={!canEdit}
									onClick={handleRemoveSqlEditorContent}
									iconName="trash"
								/>
							)}
						</Group>
						<Group pl="xs" grow>
							<Prism
								classNames={{
									root: classes.prismRoot,
									code: classes.prismCode,
									scrollArea: classes.prismScrollArea,
								}}
								language="sql"
								noCopy
							>
								{initialQuery}
							</Prism>
						</Group>
					</Box>
				)}

				<Modal.Root opened={opened} onClose={close} size="lg">
					<Modal.Overlay />
					<Modal.Content>
						<Modal.Header className={classes.modalTitle}>
							<Modal.Title>
								{isCustomSql ? 'Edit Query' : 'Edit WHERE Clause'}
							</Modal.Title>
							<Modal.CloseButton />
						</Modal.Header>
						<Modal.Body className={classes.modalBody}>
							<Stack spacing={8}>
								<Banner tone="warning" message={warningMessage} isInsideCard />
								<Text fw={500}>SQL</Text>
								<SqlEditor
									className={classes.sqlEditor}
									lineNumbers={false}
									readOnly={false}
									defaultValue={sqlEditorContent}
									onChange={handleSqlEditorChange}
								/>

								<Text size="xs" color="text/secondary/default" pl="xs">
									{isCustomSql
										? "Input a SQL query for execution. The value from the first column of the initial row will represent the monitor's metric."
										: 'Use a WHERE clause to specify conditions such as age > 30 AND country = "US" for precise monitoring.'}
								</Text>
							</Stack>
						</Modal.Body>
						<Group className={classes.modalFooter} position="apart" p="md">
							<Anchor
								size="xs"
								href="https://docs.secoda.co/features/monitoring"
								target="_blank"
								rel="noopener noreferrer"
							>
								Learn more
							</Anchor>
							<Group position="right">
								<Button onClick={close}>Cancel</Button>
								<Button
									variant="primary"
									onClick={saveSqlEditorContent}
									disabled={sqlEditorContent.length === 0 || !canEdit}
								>
									{isCustomSql ? 'Update Query' : 'Update WHERE Clause'}
								</Button>
							</Group>
						</Group>
					</Modal.Content>
				</Modal.Root>
			</Stack>
		</CollapsableStack>
	);
}

export default CustomQueryEditor;
