// NOTE: ideally we avoid use of the Mantine `Button` component in favour of
// mantine's `UnstyledButton` or our `Button` in `repo/foundations`.
// eslint-disable-next-line no-restricted-imports
import { Box, Button, Group, UnstyledButton } from '@mantine/core';
import type { ForwardedRef, ReactNode } from 'react';
import { forwardRef } from 'react';
import type {
	FilterMenuTargetBorderStyle,
	FilterMenuTargetVariant,
} from './FilterMenuTarget.styles';
import useStyles from './FilterMenuTarget.styles';

export interface IFilterMenuTargetProps {
	variant?: FilterMenuTargetVariant;
	leftIcon?: ReactNode;
	rightIcon?: ReactNode;
	children?: ReactNode;
	borderStyle?: FilterMenuTargetBorderStyle;
	onClick?: () => void;
	position?: 'default' | 'align';
}

export const FilterMenuTarget = forwardRef(
	(
		{
			variant = 'primary',
			leftIcon,
			rightIcon,
			children,
			borderStyle = 'solid',
			onClick,
			position = 'default',
		}: IFilterMenuTargetProps,
		ref: ForwardedRef<HTMLButtonElement>
	) => {
		const { classes } = useStyles({
			variant,
			borderStyle,
			hasRightIcon: !!rightIcon,
		});

		if (position === 'align') {
			return (
				<UnstyledButton ref={ref} data-testid="filter-button" onClick={onClick}>
					<Group className={classes.root} position="apart" noWrap>
						<Group spacing={4} noWrap>
							<Box className={classes.leftIcon}>{leftIcon}</Box>
							<Box className={classes.label}>{children}</Box>
						</Group>
						<Box className={classes.rightIcon}>{rightIcon}</Box>
					</Group>
				</UnstyledButton>
			);
		}
		return (
			<Button
				ref={ref}
				data-testid="filter-button"
				classNames={{
					root: classes.root,
					icon: classes.icon,
					leftIcon: classes.leftIcon,
					rightIcon: classes.rightIcon,
					centerLoader: classes.centerLoader,
					inner: classes.inner,
					label: classes.label,
				}}
				size="xs"
				leftIcon={leftIcon}
				rightIcon={rightIcon}
				onClick={onClick}
			>
				{children}
			</Button>
		);
	}
);

FilterMenuTarget.displayName = 'FilterMenuTarget';
