import { createStyles, Stack } from '@mantine/core';
import { ARCHIVED_TABLE_DATA } from './constants';
import { TrashEntityTable } from './TrashEntityTable';

const useTrashStyles = createStyles((theme) => ({
	wrapper: {
		display: 'flex',
		flexGrow: 1,
		maxHeight: '94vh',
		padding: theme.spacing['2xl'],
		paddingTop: 0,
		paddingBottom: theme.spacing.md,
	},
}));

function TrashPage() {
	const { classes } = useTrashStyles();

	return (
		<Stack className={classes.wrapper}>
			<TrashEntityTable
				onRestoreMessage={ARCHIVED_TABLE_DATA.restoreMessage}
				restoreData={ARCHIVED_TABLE_DATA.restoreData}
			/>
		</Stack>
	);
}

export default TrashPage;
