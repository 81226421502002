import { Divider } from '@mantine/core';
import { Banner, Select, Text } from '@repo/foundations';
import { concat } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import type { ITunnel } from '../../api';
import { useTunnelList } from '../../api';

interface IIntegrationTunnelSelectProps {
	onChange: (value: string) => void;
	formik: any;
}

export const IntegrationTunnelSelect = observer(
	({ formik, onChange }: IIntegrationTunnelSelectProps) => {
		const { data: tunnels } = useTunnelList({
			filters: {
				reverse: false,
				no_status: true,
			},
		});

		const { data: tunnelsReverse } = useTunnelList({
			filters: {
				reverse: true,
				no_status: true,
			},
		});

		const combined = useMemo(
			() =>
				concat(
					tunnels?.results?.map((t: ITunnel) => ({
						label: `${t.username}@${t.host}:${t.port}`,
						value: t.id,
					})) ?? [],
					tunnelsReverse?.results?.map((t: ITunnel) => ({
						label: `${t.port} (reverse)`,
						value: t.id,
					})) ?? [],
					{
						label: 'No tunnel',
						value: 'no_tunnel',
					}
				),
			[tunnels?.results, tunnelsReverse?.results]
		);

		const selectedTunnel = useMemo(() => {
			if (!formik.values.ssh_tunnel || formik.values.ssh_tunnel === 'no_tunnel')
				return null;
			return tunnels?.results?.find(
				(t: ITunnel) => t.id === formik.values.ssh_tunnel
			);
		}, [formik.values.ssh_tunnel, tunnels?.results]);

		return (
			<>
				<Divider my={'md'} />
				<Select
					name="tunnel"
					placeholder="No tunnel"
					label="SSH Tunnel (Optional)"
					value={formik.values.ssh_tunnel}
					help={
						<Text size="xs" variant="text">
							<Link to="/tunnels" target="_blank">
								Manage tunnels
							</Link>
						</Text>
					}
					onChange={onChange}
					onBlur={formik.handleBlur}
					error={formik.errors.ssh_tunnel}
					data={combined}
				/>
				{selectedTunnel && !selectedTunnel.reverse && (
					<Banner
						tone="info"
						title="Forward tunnel selected"
						message="Secoda will avoid scheduling multiple integrations to run simultaneously on the same forward tunnel, we recommend using a reverse tunnel for better concurrent execution support."
					/>
				)}
			</>
		);
	}
);
