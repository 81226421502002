import { Menu } from '@mantine/core';
import { Icon } from '@repo/foundations';
import type { ISecodaEntity } from '../../../../api';
import { useAuthUser } from '../../../../api';
import type { SecodaEntity } from '../../../../lib/models';
import { trackEvent } from '../../../../utils/analytics';

interface ActivityLogActionProps {
	entity: SecodaEntity | ISecodaEntity;
	openLogDrawer: VoidFunction;
}

export function ActivityLogAction({
	entity,
	openLogDrawer,
}: ActivityLogActionProps) {
	const { user, workspace } = useAuthUser();

	const handleClickActivityLog = () => {
		openLogDrawer();
		trackEvent(`${entity.entity_type}/activity/click`, {}, user, workspace);
	};

	return (
		<Menu.Item icon={<Icon name="history" />} onClick={handleClickActivityLog}>
			View activity log
		</Menu.Item>
	);
}
