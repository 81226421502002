import { Box } from '@mantine/core';
import type { ForwardedRef, PropsWithChildren } from 'react';
import { forwardRef } from 'react';
import { useListBoxStyles } from './ListBox.styles';

export interface ListBoxItemProps
	extends Omit<React.HTMLProps<HTMLButtonElement>, 'ref'> {
	icon?: React.ReactNode;
	rightSection?: React.ReactNode;
}

export const ListBoxItem = forwardRef(
	(
		{
			children,
			icon,
			rightSection,
			...boxProps
		}: PropsWithChildren<ListBoxItemProps>,
		ref: ForwardedRef<HTMLButtonElement>
	) => {
		const { classes, cx } = useListBoxStyles();
		const { className, ...rest } = boxProps;

		return (
			<Box
				{...rest}
				ref={ref}
				component="button"
				type="button"
				role="option"
				className={cx(classes.item, className)}
			>
				{icon && <div className={classes.itemIcon}>{icon}</div>}
				{children && <div className={classes.itemLabel}>{children}</div>}
				{rightSection && (
					<div className={classes.itemRightSection}>{rightSection}</div>
				)}
			</Box>
		);
	}
);
ListBoxItem.displayName = 'ListBoxItem';
