import { useDisclosure } from '@mantine/hooks';
import { IconButton } from '@repo/foundations';
import { includes } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import type { ISecodaEntity } from '../../api';
import { useAuthUser, useUpdateSecodaEntity } from '../../api';
import type { SecodaEntity } from '../../lib/models';

export interface IBookmarkToggleProps {
	entity: SecodaEntity | ISecodaEntity;
}

function BookmarkToggle({ entity }: IBookmarkToggleProps) {
	const { mutateAsync: updateSecodaEntity } = useUpdateSecodaEntity({});
	const { user } = useAuthUser();

	const [isBookmarked, { toggle }] = useDisclosure(
		includes(entity.bookmarked_by, user.id)
	);

	const onClick = () => {
		let { bookmarked_by } = entity;
		if (isBookmarked) {
			bookmarked_by = entity.bookmarked_by.filter((b) => b !== user.id);
			toggle();
		} else {
			bookmarked_by.push(user.id);
			toggle();
		}
		updateSecodaEntity({
			data: {
				id: entity.id,
				bookmarked_by,
			},
		});
	};

	return (
		<IconButton
			tooltip={`${isBookmarked ? 'Remove' : 'Add'} bookmark`}
			onClick={onClick}
			fillIcon={isBookmarked}
			iconName="bookmark"
			variant="tertiary"
		/>
	);
}

export default observer(BookmarkToggle);
