import type { RequiredPick } from '../../../lib/typescript';
import type { IWorkspace } from '../../types';
import { useCreateBaseModel } from '../base';
import { WORKSPACE_NAMESPACE } from './constants';

interface ICreateWorkspaceParams {
	data: RequiredPick<IWorkspace, 'name'> & Partial<IWorkspace>;
}

export function useCreateWorkspace() {
	return useCreateBaseModel<IWorkspace, ICreateWorkspaceParams>({
		namespace: WORKSPACE_NAMESPACE,
	});
}
