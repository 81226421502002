import { Box, createStyles, Flex } from '@mantine/core';
import { observer } from 'mobx-react-lite';
import type { IEvent, IEventProperty } from '../../../api';
import { useEventPropertyInfiniteList } from '../../../api';
import { TableV2 } from '../../../components/TableV2';
import { useColumns } from '../../TeamCatalogPage/TeamCatalogPage.hooks';
import type { EventEntityTabsStore } from '../EventEntityTabs/EventEntityTabsStore';

interface IEventPropertiesTabProps {
	event: IEvent;
	store: EventEntityTabsStore;
}

const useStyles = createStyles({
	gridWrapper: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
		width: '100%',
		minHeight: 350,
	},
	optionsWrapper: {
		marginBottom: 12,
	},
	tableWrapper: {
		height: '100%',
	},
});

function EventPropertiesTab({ event, store }: IEventPropertiesTabProps) {
	const { classes } = useStyles();

	const columns = useColumns();

	return (
		<Flex className={classes.gridWrapper}>
			<Box className={classes.tableWrapper}>
				<TableV2<IEventProperty>
					onTotalRowCountChange={store.setPropertiesCount}
					pluralTypeString="properties"
					withCheckbox={false}
					withInteractiveHeader
					withSearch
					withFilters={false}
					defaultSort={null}
					withServerCsvExport
					withCsvExport
					withInfiniteScroll
					usePaginationList={useEventPropertyInfiniteList}
					defaultRequiredSearchParams={{
						parent_id: event.id,
					}}
					columns={columns.filter((column) =>
						['title', 'type', 'description', 'tags', 'owners'].includes(
							column.accessor
						)
					)}
				/>
			</Box>
		</Flex>
	);
}

export default observer(EventPropertiesTab);
