import { Image, useMantineTheme } from '@mantine/core';

interface WorkspaceLogoProps {
	logo: string;
	size?: number;
}

export function WorkspaceLogo({ logo, size = 36 }: WorkspaceLogoProps) {
	const theme = useMantineTheme();
	return (
		<Image
			src={logo}
			mih={size}
			miw={size}
			height={size}
			width={size}
			radius={theme.other.space[1]}
			fit="contain"
		/>
	);
}
