/**
 * Creates a factory function that allows you to create react-query compliant query keys for the given model
 * Based on the recommended approach from the react-query contributor
 * https://tkdodo.eu/blog/effective-react-query-keys#use-query-key-factories
 *
 * @param namespace API path for the entity's viewset
 * @returns Factory function that allows you to create react-query compliant query keys for the given model
 */
export const createQueryKeys = <TTypes extends string = string>(
	namespace: string[]
) => ({
	namespace,
	all: () => [...namespace] as const,
	allLists: () => [...namespace, 'list'] as const,
	list: (page = 1, filters: Record<string, unknown> = {}) =>
		[...namespace, 'list', page, filters] as const,
	byId: (id: string) => [...namespace, 'detail', id] as const,
	byArgs: (type: TTypes, ...args: (string | Record<string, unknown>)[]) =>
		[...namespace, type, ...args] as const,
});

export type QueryKeyFactory<TTypes extends string = string> = ReturnType<
	typeof createQueryKeys<TTypes>
>;
