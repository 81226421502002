import { Icon } from '@repo/foundations';
import { uuidv4 } from 'lib0/random';
import { makeAutoObservable } from 'mobx';
import { AutomationTriggerType, type Automation } from '../../api';
import type { AutomationCardType } from '../../components/Automation/AutomationCard/constants';
import { DefaultAutomationCardType } from '../../components/Automation/AutomationCard/constants';
import { AutomationActionCardType } from '../../components/Automation/constants';

export interface AutomationCards {
	id: string;
	titleIcon: JSX.Element;
	type: AutomationCardType;
	searchKey?: string; // Required to handle filters properly
	isSecondFilterResourceCard?: boolean;
	isNthFilterResourceCard?: boolean;
}

export class AutomationPageStore {
	automation?: Automation;

	cards: AutomationCards[] = [];

	hasBeenSetup = false;

	lastFilterIndex = 0;

	constructor() {
		makeAutoObservable(this);
	}

	setupCards() {
		if (this.hasBeenSetup) {
			return;
		}

		this.hasBeenSetup = true;

		const filterSetExists =
			this.automation?.filter_config &&
			this.automation?.filter_config.filter_sets &&
			this.automation?.filter_config.filter_sets.length > 0;

		const actionUpdateEntitiesExist =
			this.automation?.action_target &&
			this.automation?.action_update_entities &&
			this.automation?.action_update_entities.length > 0;

		// Create trigger card
		if (this.automation?.trigger_type) {
			let setKey;

			// Add set key if trigger type is table dropped or schema change
			if (
				this.automation?.trigger_type === AutomationTriggerType.TABLE_DROP ||
				this.automation?.trigger_type === AutomationTriggerType.SCHEMA_CHANGE
			) {
				const set = this.automation?.filter_config?.filter_sets[0];
				setKey = filterSetExists ? set?.key : uuidv4();
			}

			this.addCard({
				id: uuidv4(),
				titleIcon: <Icon name="playerPlay" />,
				type: DefaultAutomationCardType.TRIGGER,
				searchKey: setKey,
			});
		}

		if (filterSetExists) {
			this.automation?.filter_config?.filter_sets.forEach((set, index) => {
				// Do not create filter resource card if automation trigger type is table dropped or schema change
				// The filters will be on the trigger card in these cases
				if (
					(this.automation?.trigger_type === AutomationTriggerType.TABLE_DROP ||
						this.automation?.trigger_type ===
							AutomationTriggerType.SCHEMA_CHANGE) &&
					index === 0
				) {
					return;
				}

				this.addCard({
					id: uuidv4(),
					titleIcon: <Icon name="filter" />,
					type: AutomationActionCardType.FILTER_RESOURCES,
					searchKey: set.key,
					isNthFilterResourceCard: index > 0,
					isSecondFilterResourceCard: index === 1,
				});
			});

			// Add the filter count card if there are any filters on any filter set
			if (
				this.automation?.filter_config?.filter_sets.some(
					(set) => set.filters.length > 0
				)
			) {
				this.addFilterCountCard();
			}
		}

		if (actionUpdateEntitiesExist) {
			this.addCard({
				id: uuidv4(),
				titleIcon: <Icon name="pencil" />,
				type: AutomationActionCardType.EDIT_RESOURCES,
			});
		}

		// Create announcement card
		if (this.automation?.action_send_announcement) {
			this.addCard({
				id: uuidv4(),
				titleIcon: <Icon name="speakerphone" />,
				type: AutomationActionCardType.SEND_ANNOUNCEMENT,
			});
		}

		// Create email card
		if (this.automation?.action_send_email) {
			this.addCard({
				id: uuidv4(),
				titleIcon: <Icon name="mail" />,
				type: AutomationActionCardType.SEND_EMAIL,
			});
		}

		// Create slack card
		if (this.automation?.action_send_slack_message) {
			this.addCard({
				id: uuidv4(),
				titleIcon: <Icon name="brandSlack" />,
				type: AutomationActionCardType.SEND_SLACK_MESSAGE,
			});
		}

		if (this.automation?.action_propagate_metadata) {
			this.addCard({
				id: uuidv4(),
				titleIcon: <Icon name="arrowsSplit" />,
				type: AutomationActionCardType.PROPAGATE_METADATA,
			});
		}
	}

	addCard(card: AutomationCards) {
		this.cards = [...this.cards, card];

		this.setLastFilterResourceCardIndex();
	}

	addFilterResourceCard() {
		const filterResourceCardCount = this.cards.filter(
			(card) => card.type === AutomationActionCardType.FILTER_RESOURCES
		).length;

		// Add a new filter resource card card after the last filter card
		const newCards = [
			...this.cards.slice(0, this.lastFilterIndex + 1),
			{
				id: uuidv4(),
				titleIcon: <Icon name="filter" />,
				type: AutomationActionCardType.FILTER_RESOURCES,
				searchKey: uuidv4(),
				isSecondFilterResourceCard: filterResourceCardCount === 1,
				isNthFilterResourceCard: filterResourceCardCount > 0,
			},
			...this.cards.slice(this.lastFilterIndex + 1),
		];

		// Update the last filter index and set the cards
		this.cards = newCards;
		this.lastFilterIndex += 1;
	}

	addFilterCountCard() {
		if (
			this.cards.some(
				(card) => card.type === AutomationActionCardType.FILTER_COUNT
			)
		) {
			return;
		}

		// Add a new filter count card after the last filter card
		const newCards = [
			...this.cards.slice(0, this.lastFilterIndex + 1),
			{
				id: uuidv4(),
				titleIcon: <Icon name="infoCircle" />,
				type: AutomationActionCardType.FILTER_COUNT,
				searchKey: uuidv4(),
			},
			...this.cards.slice(this.lastFilterIndex + 1),
		];

		this.cards = newCards;
	}

	removeFilterCountCard() {
		this.cards = this.cards.filter(
			(card) => card.type !== AutomationActionCardType.FILTER_COUNT
		);
	}

	setLastFilterResourceCardIndex() {
		this.lastFilterIndex = 0;

		this.cards.forEach((card, index) => {
			if (
				card.type === AutomationActionCardType.FILTER_RESOURCES &&
				index > this.lastFilterIndex
			) {
				this.lastFilterIndex = index;
			}
		});
	}

	updateKey(newKey: string) {
		const index = this.cards.findIndex(
			(card) =>
				card.searchKey === undefined &&
				card.type === AutomationActionCardType.FILTER_RESOURCES
		);

		if (index === -1) {
			return;
		}

		this.cards[index].searchKey = newKey;
	}

	removeCard(type: AutomationCardType, cardId: string) {
		this.cards = this.cards.filter((card) => card.id !== cardId);

		if (type === AutomationActionCardType.FILTER_RESOURCES) {
			this.setLastFilterResourceCardIndex();

			if (
				this.cards.every(
					(card) => card.type !== AutomationActionCardType.FILTER_RESOURCES
				)
			) {
				this.removeFilterCountCard();
			}
		}
	}

	resetCards() {
		this.cards = [];
		this.hasBeenSetup = false;
		this.lastFilterIndex = 0;
	}
}
