export const compactNumber = (num: number, decimals = 0) => {
	const formatter = new Intl.NumberFormat('en-US', {
		notation: 'compact',
		minimumFractionDigits: 0,
		maximumFractionDigits: decimals || 0,
	});

	return formatter.format(num);
};

export const formatNumber = (num: number) => {
	const formatter = new Intl.NumberFormat('en-US', {
		notation: 'standard',
	});

	return formatter.format(num);
};

export const formatCurrency = (amount: number) => {
	if (Math.abs(amount) < 0.009 && amount !== 0) {
		return '0.00 USD';
	}

	return new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
	}).format(amount);
};

export const isBetween = (
	number: number,
	min: number,
	max: number,
	inclusiveLower = true,
	inclusiveUpper = true
): boolean => {
	if (inclusiveLower && inclusiveUpper) {
		return number >= min && number <= max;
	}
	if (inclusiveLower) {
		return number >= min && number < max;
	}
	if (inclusiveUpper) {
		return number > min && number <= max;
	}
	return number > min && number < max;
};
