import { Space, Stack } from '@mantine/core';
import { Text, Title } from '@repo/foundations';
import type { IWidgetSample } from '../../../../../api';
import { useHomepageTeam } from '../../../hooks/useHomepageTeam';
import AddWidgetCard from './cards/AddWidgetCard';

interface IWidgetSelectorProps {
	widgetSamples: IWidgetSample[];
	onAddWidget: (widgetSample: IWidgetSample) => void;
	subtitle?: string;
}

function WidgetSelector({
	widgetSamples,
	onAddWidget,
	subtitle,
}: IWidgetSelectorProps) {
	const { isTeamsPage } = useHomepageTeam();

	const handleAddWidget = (widgetSample: IWidgetSample) => () => {
		onAddWidget(widgetSample);
	};

	return (
		<Stack px="lg" spacing="xs">
			<Stack spacing={4}>
				<Title order={6}>Add widgets</Title>
				<Text size="sm" color="text/secondary/default">
					{subtitle}
				</Text>
			</Stack>
			{widgetSamples.map((sample) => (
				<AddWidgetCard
					key={`${sample.type} - ${JSON.stringify(sample.metric_metadata)}`}
					widgetSample={sample}
					onClick={handleAddWidget(sample)}
					isTeamsPage={isTeamsPage}
				/>
			))}
			<Space h={24} />
		</Stack>
	);
}

export default WidgetSelector;
