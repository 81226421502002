import { EmptyState } from '../EmptyState';

function Error500(): JSX.Element {
	return (
		<EmptyState
			title="Server is returning an error"
			description="Please report this path to customer support."
			includeGoBack
			iconName="serverBolt"
			size="lg"
		/>
	);
}

export default Error500;
