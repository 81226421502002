import type { MultiSelectValueProps } from '@mantine/core';
import { createStyles, Group, UnstyledButton } from '@mantine/core';
import { iconSize } from '@repo/theme/primitives';
import { IconX } from '@tabler/icons-react';
import type { ReactNode } from 'react';
import { Text } from '../Text';

const useValueComponentStyles = createStyles((theme) => ({
	container: {
		borderRadius: 1000,
		backgroundColor: theme.other.getColor('surface/primary/active'),
		flexWrap: 'nowrap',
		padding: theme.other.space[0.25],
		height: theme.other.space[8] - theme.other.space[0.25] * 4,
	},
	removeIcon: {
		cursor: 'pointer',
		textAlign: 'center',
		// Need to set a slight padding to vertically center the iconX
		paddingTop: 2,
		height: iconSize['md'],
		width: iconSize['md'],
		borderRadius: theme.other.borderRadius.xs,
		'&:hover': {
			backgroundColor: theme.other.getColor('fill/secondary/hover'),
		},
	},
	displayIcon: {
		paddingLeft: theme.spacing['2xs'],
		paddingRight: theme.spacing['3xs'],
	},
}));

export default function ValueComponent(
	props: {
		label: ReactNode;
		icon?: ReactNode;
	} & MultiSelectValueProps
) {
	const { theme, classes } = useValueComponentStyles();

	const { label, icon, onRemove } = props;

	return (
		<Group spacing={0} className={classes.container}>
			<div className={classes.displayIcon}>{icon}</div>
			<Text size="sm">{label}</Text>
			<UnstyledButton onClick={onRemove} className={classes.removeIcon}>
				<IconX color={theme.other.getColor('icon/primary/default')} size={12} />
			</UnstyledButton>
		</Group>
	);
}
