import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
	container: {
		width: '100%',
		flex: 1,
	},
	breadcrumbHidden: {
		position: 'absolute',
		visibility: 'hidden',
		whiteSpace: 'nowrap',
	},
	root: {
		width: 'fit-content',
	},
	separator: {
		marginLeft: theme.other.space[0.25],
		marginRight: theme.other.space[0.25],
	},
	button: {
		fontSize: theme.other.typography.text.sm,
		lineHeight: theme.other.typography.lineHeight.text.sm,
	},
	buttonInner: {
		justifyContent: 'left',
	},
}));
