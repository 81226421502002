import { Box } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useWorkspaceList } from '../../api';
import { AuthStepWorkspaceCreate } from './AuthStepWorkspaceCreate';
import { AuthStepWorkspaceJoin } from './AuthStepWorkspaceJoin';

export function AuthStepWorkspace() {
	const [tab, setTab] = useState<'join' | 'create'>('join');

	const { isLoading, data: workspaces } = useWorkspaceList({
		page: 1,
		filters: {
			disabled: false,
			scheduled_for_deletion: false,
		},
		options: {
			select: (data) => data.results,
			suspense: false,
			refetchOnMount: true,
			refetchOnWindowFocus: true,
			refetchOnReconnect: true,
		},
	});

	useEffect(() => {
		if (!isLoading) {
			if ((workspaces?.length ?? 0) > 0) {
				setTab('join');
			} else {
				setTab('create');
			}
		}
	}, [isLoading, workspaces?.length]);

	return (
		<Box>
			{!isLoading && (
				<>
					{tab === 'join' && (
						<AuthStepWorkspaceJoin
							workspaces={workspaces ?? []}
							toggleTab={setTab}
						/>
					)}
					{tab === 'create' && <AuthStepWorkspaceCreate toggleTab={setTab} />}
				</>
			)}
		</Box>
	);
}
