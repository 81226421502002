import { uuidv4 } from 'lib0/random';
import type { AutomationAction } from '../../api';
import {
	AutomationActionType,
	AutomationField,
	AutomationFilterOperator,
	AutomationTarget,
} from '../../api';
import {
	AutomationCondition,
	type AutomationFilter,
} from '../../api/types/models/automation/automationFilters';
import {
	AutomationPropagateMetadataField,
	AutomationPropagateMetadataRelationship,
	type AutomationActionPropagateMetadata,
} from '../../api/types/models/automation/automationPropagateMetadata';
import type {
	AutomationActionCardOptions,
	createAutomationType,
} from './constants';
import {
	AutomationActionCardType,
	AutomationActionMenuValue,
	automationActionCardOptions,
} from './constants';

interface CreateAutomationProps {
	title?: string;
	icon?: string;
	filters: AutomationFilter[];
	actions?: AutomationAction[];
	filterOperator: AutomationFilterOperator;
	actionTarget?: AutomationTarget;
	propagateMetadata?: AutomationActionPropagateMetadata;
}

export const AUTOMATION_TEMPLATES: Record<
	createAutomationType,
	CreateAutomationProps
> = {
	SCRATCH: {
		filters: [],
		actions: [],
		filterOperator: AutomationFilterOperator.AND,
		actionTarget: AutomationTarget.SELF,
	},
	PII_TO_CHILD: {
		title: 'Propagate table PII tag to columns',
		icon: '🛡️',
		filters: [
			{
				condition: AutomationCondition.IS,
				key: uuidv4(),
				field: AutomationField.NATIVE_TYPE,
				value: 'table',
				display: 'Table',
			},
			{
				condition: AutomationCondition.IS,
				key: uuidv4(),
				field: AutomationField.SCHEMA,
				value: '',
				display: '',
			},
			{
				condition: AutomationCondition.IS,
				key: uuidv4(),
				field: AutomationField.PII,
				value: true,
				display: 'PII',
			},
		],
		actions: [
			{
				type: AutomationActionType.PII_TRUE,
				key: uuidv4(),
				value: '',
				display: '',
			},
		],
		filterOperator: AutomationFilterOperator.AND,
		actionTarget: AutomationTarget.CHILD,
	},
	SCHEMA_TO_COLLECTION: {
		title: 'Add tables to a collection',
		icon: '🗂️',
		filters: [
			{
				condition: AutomationCondition.IS,
				key: uuidv4(),
				field: AutomationField.NATIVE_TYPE,
				value: 'table',
				display: 'Table',
			},
			{
				condition: AutomationCondition.IS,
				key: uuidv4(),
				field: AutomationField.SCHEMA,
				value: '',
				display: '',
			},
		],
		actions: [
			{
				type: AutomationActionType.COLLECTIONS_ADD,
				key: uuidv4(),
				value: '',
				display: '',
			},
		],
		filterOperator: AutomationFilterOperator.AND,
		actionTarget: AutomationTarget.SELF,
	},
	OWNERSHIP_ASSIGNMENT: {
		title: 'Assign ownership to schema tables',
		icon: '👤',
		filters: [
			{
				condition: AutomationCondition.IS,
				key: uuidv4(),
				field: AutomationField.INTEGRATION_ID,
				value: '',
				display: '',
			},
			{
				condition: AutomationCondition.IS,
				key: uuidv4(),
				field: AutomationField.SCHEMA,
				value: '',
				display: '',
			},
			{
				condition: AutomationCondition.IS,
				key: uuidv4(),
				field: AutomationField.NATIVE_TYPE,
				value: 'table',
				display: 'Table',
			},
			{
				condition: AutomationCondition.IS_NOT_SET,
				key: uuidv4(),
				field: AutomationField.OWNERS,
				value: '',
				display: '',
			},
		],
		actions: [
			{
				type: AutomationActionType.OWNERS_ADD,
				key: uuidv4(),
				value: '',
				display: '',
			},
		],
		filterOperator: AutomationFilterOperator.AND,
		actionTarget: AutomationTarget.SELF,
	},
	ADD_SUBSCRIBERS: {
		title: 'Subscribe to tables in a schema',
		icon: '🔔',
		filters: [
			{
				condition: AutomationCondition.IS,
				key: uuidv4(),
				field: AutomationField.INTEGRATION_ID,
				value: '',
				display: '',
			},
			{
				condition: AutomationCondition.IS,
				key: uuidv4(),
				field: AutomationField.SCHEMA,
				value: '',
				display: '',
			},
			{
				condition: AutomationCondition.IS,
				key: uuidv4(),
				field: AutomationField.NATIVE_TYPE,
				value: 'table',
				display: 'Table',
			},
		],
		actions: [
			{
				type: AutomationActionType.SUBSCRIBERS_ADD,
				key: uuidv4(),
				value: '',
				display: '',
			},
		],
		filterOperator: AutomationFilterOperator.AND,
		actionTarget: AutomationTarget.SELF,
	},
	VERIFY_COMPLETE_RESOURCES: {
		title: 'Verify documented resources',
		icon: '✅',
		filters: [
			{
				condition: AutomationCondition.IS_SET,
				key: uuidv4(),
				field: AutomationField.DESCRIPTION,
				value: '',
				display: '',
			},
			{
				condition: AutomationCondition.IS_SET,
				key: uuidv4(),
				field: AutomationField.OWNERS,
				value: '',
				display: '',
			},
		],
		actions: [
			{
				type: AutomationActionType.VERIFIED_TRUE,
				key: uuidv4(),
				value: '',
				display: '',
			},
		],
		filterOperator: AutomationFilterOperator.AND,
		actionTarget: AutomationTarget.SELF,
	},
	ADD_SCHEMA_TO_TEAM: {
		title: 'Add tables from a schema to a team',
		icon: '👥',
		filters: [
			{
				condition: AutomationCondition.IS,
				key: uuidv4(),
				field: AutomationField.INTEGRATION_ID,
				value: '',
				display: '',
			},
			{
				condition: AutomationCondition.IS,
				key: uuidv4(),
				field: AutomationField.SCHEMA,
				value: '',
				display: '',
			},
			{
				condition: AutomationCondition.IS,
				key: uuidv4(),
				field: AutomationField.NATIVE_TYPE,
				value: 'table',
				display: 'Table',
			},
		],
		actions: [
			{
				type: AutomationActionType.TEAMS_ADD,
				key: uuidv4(),
				value: '',
				display: '',
			},
		],
		filterOperator: AutomationFilterOperator.AND,
		actionTarget: AutomationTarget.SELF,
	},
	PROPAGATE_METADATA_TO_UPSTREAM: {
		title: 'Propagate metadata to upstream resources',
		icon: '⤴️',
		filters: [
			{
				condition: AutomationCondition.IS,
				key: uuidv4(),
				field: AutomationField.NATIVE_TYPE,
				value: 'table',
				display: 'Table',
			},
		],
		filterOperator: AutomationFilterOperator.AND,
		propagateMetadata: {
			to: AutomationPropagateMetadataRelationship.LINEAGE_UP,
			fields: [
				AutomationPropagateMetadataField.DESCRIPTION,
				AutomationPropagateMetadataField.TAGS,
				AutomationPropagateMetadataField.OWNERS,
			],
		},
	},
};

export const convertAutomationActionMenuValueToCardOptions = (
	actionValue: AutomationActionMenuValue
): AutomationActionCardOptions[] => {
	if (actionValue === AutomationActionMenuValue.FILTER_RESOURCES) {
		return automationActionCardOptions.filter(
			(option) => option.type === AutomationActionCardType.FILTER_RESOURCES
		);
	}

	if (actionValue === AutomationActionMenuValue.EDIT_RESOURCES) {
		return automationActionCardOptions.filter(
			(option) => option.type === AutomationActionCardType.EDIT_RESOURCES
		);
	}

	if (actionValue === AutomationActionMenuValue.SEND_ANNOUNCEMENT) {
		return automationActionCardOptions.filter(
			(option) => option.type === AutomationActionCardType.SEND_ANNOUNCEMENT
		);
	}

	if (actionValue === AutomationActionMenuValue.SEND_EMAIL) {
		return automationActionCardOptions.filter(
			(option) => option.type === AutomationActionCardType.SEND_EMAIL
		);
	}

	if (actionValue === AutomationActionMenuValue.SEND_SLACK_MESSAGE) {
		return automationActionCardOptions.filter(
			(option) => option.type === AutomationActionCardType.SEND_SLACK_MESSAGE
		);
	}

	if (actionValue === AutomationActionMenuValue.PROPAGATE_METADATA) {
		return automationActionCardOptions.filter(
			(option) => option.type === AutomationActionCardType.PROPAGATE_METADATA
		);
	}

	throw new Error(
		'Automation action menu value cannot be converted to automation action card type'
	);
};

export const getFieldFromActionType = (
	actionType: AutomationActionType
): AutomationField => {
	switch (actionType) {
		case AutomationActionType.OWNERS_ADD:
		case AutomationActionType.OWNERS_REMOVE:
		case AutomationActionType.OWNERS_REPLACE:
		case AutomationActionType.OWNERS_CLEAR:
			return AutomationField.OWNERS;

		case AutomationActionType.SUBSCRIBERS_ADD:
		case AutomationActionType.SUBSCRIBERS_REMOVE:
		case AutomationActionType.SUBSCRIBERS_REPLACE:
		case AutomationActionType.SUBSCRIBERS_CLEAR:
			return AutomationField.SUBSCRIBERS;

		case AutomationActionType.TAGS_ADD:
		case AutomationActionType.TAGS_REMOVE:
		case AutomationActionType.TAGS_REPLACE:
		case AutomationActionType.TAGS_CLEAR:
			return AutomationField.TAGS;

		case AutomationActionType.DEFINITION_SET:
		case AutomationActionType.DEFINITION_CLEAR:
			return AutomationField.DEFINITION;

		case AutomationActionType.DESCRIPTION_SET:
		case AutomationActionType.DESCRIPTION_CLEAR:
			return AutomationField.DESCRIPTION;

		case AutomationActionType.PII_TRUE:
		case AutomationActionType.PII_FALSE:
			return AutomationField.PII;

		case AutomationActionType.VERIFIED_TRUE:
		case AutomationActionType.VERIFIED_FALSE:
			return AutomationField.VERIFIED;

		case AutomationActionType.PUBLISHED_TRUE:
		case AutomationActionType.PUBLISHED_FALSE:
			return AutomationField.PUBLISHED;

		case AutomationActionType.TEAMS_ADD:
		case AutomationActionType.TEAMS_REMOVE:
		case AutomationActionType.TEAMS_REPLACE:
		case AutomationActionType.TEAMS_CLEAR:
		case AutomationActionType.TEAMS_ADD_ALL:
			return AutomationField.TEAMS;

		case AutomationActionType.COLLECTIONS_ADD:
		case AutomationActionType.COLLECTIONS_REMOVE:
		case AutomationActionType.COLLECTIONS_REPLACE:
		case AutomationActionType.COLLECTIONS_CLEAR:
			return AutomationField.COLLECTIONS;

		default:
			throw new Error(`Unknown action type: ${actionType}`);
	}
};

export enum AUTOMATION_JOB_STATUS {
	RUNNING = 'running',
	SUCCESS = 'success',
	ERROR = 'error',
	REVERTED = 'reverted',
}
