import type { MarkdownSerializerState } from '@repo/secoda-editor/lib/markdown/serializer';
import type Token from 'markdown-it/lib/token';
import { toggleMark } from 'prosemirror-commands';
import { InputRule } from 'prosemirror-inputrules';
import type {
	MarkSpec,
	Node,
	Mark as ProsemirrorMark,
} from 'prosemirror-model';
import type { EditorState } from 'prosemirror-state';
import { Plugin } from 'prosemirror-state';
import { isModKey } from '../../../../../utils/keyboard';
import { isHash } from '../../../../../utils/url';
import type { CommandFactory } from '../lib/Extension';
import type { Dispatch } from '../types';
import type { MarkOptions } from './Mark';
import Mark from './Mark';

const LINK_INPUT_REGEX = /\[([^[]+)]\((\S+)\)$/;

function isPlainURL(
	link: ProsemirrorMark,
	parent: Node,
	index: number,
	side: -1 | 1
) {
	if (link.attrs.title || !/^\w+:/.test(link.attrs.href)) {
		return false;
	}

	const content = parent.child(index + (side < 0 ? -1 : 0));
	if (
		!content.isText ||
		content.text !== link.attrs.href ||
		content.marks[content.marks.length - 1] !== link
	) {
		return false;
	}

	if (index === (side < 0 ? 1 : parent.childCount - 1)) {
		return true;
	}

	const next = parent.child(index + (side < 0 ? -2 : 1));
	return !link.isInSet(next.marks);
}

export default class Link extends Mark {
	get name() {
		return 'link';
	}

	get schema(): MarkSpec {
		return {
			attrs: {
				href: {
					default: '',
				},
				title: {
					default: '',
				},
			},
			inclusive: false,
			parseDOM: [
				{
					tag: 'a[href]',
					getAttrs: (dom: string | HTMLElement) => ({
						href: (dom as HTMLElement).getAttribute('href'),
						title: (dom as HTMLElement).getAttribute('title'),
					}),
				},
			],
			toDOM: (node) => [
				'a',
				{
					...node.attrs,
					rel: 'noopener noreferrer nofollow',
				},
				0,
			],
		};
	}

	inputRules({ type }: MarkOptions) {
		return [
			new InputRule(LINK_INPUT_REGEX, (state, match, start, end) => {
				const [okay, alt, href] = match;
				const { tr } = state;

				if (okay) {
					tr.replaceWith(start, end, this.editorState.schema.text(alt)).addMark(
						start,
						start + alt.length,
						type.create({ href })
					);
				}

				return tr;
			}),
		];
	}

	commands({ type }: MarkOptions): CommandFactory {
		return ({ href } = { href: '' }) => toggleMark(type, { href });
	}

	keys({ type }: MarkOptions) {
		return {
			'Mod-j': (state: EditorState, dispatch?: Dispatch) => {
				if (state.selection.empty) {
					this.options.onKeyboardShortcut();
					return true;
				}

				return toggleMark(type, { href: '' })(state, dispatch);
			},
		};
	}

	get plugins() {
		const plugin: Plugin = new Plugin({
			props: {
				handleDOMEvents: {
					mousemove: (view, event) => {
						if (!(event.target instanceof HTMLAnchorElement)) {
							return false;
						}

						if (event.target.matches('.component-attachment *')) {
							return false;
						}

						if (view.editable && isModKey(event)) {
							event.target.style.cursor = 'pointer';
						} else {
							event.target.style.removeProperty('cursor');
						}

						return false;
					},
					mousedown: (view, event) => {
						if (!(event.target instanceof HTMLAnchorElement)) {
							return false;
						}

						if (event.target.matches('.component-attachment *')) {
							return false;
						}

						const isReadOnly = !view.editable;
						const isNotInFocus = view.editable && !view.hasFocus();
						const isModKeyClick = isModKey(event);

						if (isReadOnly || isNotInFocus || isModKeyClick) {
							const href =
								event.target.href ||
								(event.target.parentNode instanceof HTMLAnchorElement
									? event.target.parentNode.href
									: '');
							const isSamePageHash = isHash(href);

							if (isSamePageHash) {
								try {
									const parsed = new URL(href);
									if (parsed.hash) {
										event.stopPropagation();
										event.preventDefault();
										this.options.scrollToAnchor(parsed.hash);
										return true;
									}
								} catch (e) {
									// Failed to parse href as url
								}
							}

							event.stopPropagation();
							event.preventDefault();
							this.options.onClickLink(href, event, isNotInFocus);

							return true;
						}

						return false;
					},
				},
			},
		});

		return [plugin];
	}

	toMarkdown() {
		return {
			open(
				_state: MarkdownSerializerState,
				mark: ProsemirrorMark,
				parent: Node,
				index: number
			) {
				return isPlainURL(mark, parent, index, 1) ? '<' : '[';
			},
			close(
				state: MarkdownSerializerState,
				mark: ProsemirrorMark,
				parent: Node,
				index: number
			) {
				return isPlainURL(mark, parent, index, -1)
					? '>'
					: `](${state.esc(mark.attrs.href, false)}${
							mark.attrs.title ? ` ${state.quote(mark.attrs.title)}` : ''
						})`;
			},
		};
	}

	parseMarkdown() {
		return {
			mark: 'link',
			getAttrs: (tok: Token) => ({
				href: tok.attrGet('href'),
				title: tok.attrGet('title') || null,
			}),
		};
	}
}
