import { Stack, useMantineTheme } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { UserRole } from '@repo/common/enums/UserRole';
import { Text } from '@repo/foundations';
import { lowerCase } from 'lodash-es';
import { queryClient, useUpdateUser, usersQueryKeyFactory } from '../../../api';
import { useLimits } from '../../../api/hooks/workspace/useLimits';
import type { User } from '../../../lib/models';
import { openConfirmModal } from '../../ModalManager';
import { PricingDetails } from '../../PriceIncreaseModals/PricingDetails';

export const useMembersTabSelectRole = () => {
	const theme = useMantineTheme();

	const { usedEditorOrAdminSeats, usedViewerSeats } = useLimits();

	const { mutateAsync } = useUpdateUser({
		options: {
			onSuccess: () => {
				showNotification({
					title: 'Role updated',
					message: 'Role updated successfully',
				});
				queryClient.invalidateQueries(usersQueryKeyFactory.list());
			},
			onError: () => {
				showNotification({
					message: 'Failed to update role',
				});
			},
		},
	});

	const hasWritePerms = [UserRole.EDITOR, UserRole.ADMIN];

	const selectRole = async (user: User, role: UserRole) => {
		if (
			(hasWritePerms.includes(role) && !hasWritePerms.includes(user.role)) ||
			(hasWritePerms.includes(user.role) && !hasWritePerms.includes(role))
		) {
			openConfirmModal({
				sx: {
					paddingTop: `${theme.spacing.md} !important`,
					paddingBottom: `${theme.spacing.md} !important`,
				},
				title: (
					<Text
						size="md"
						weight="bold"
						data-testid="update-user-role-editor-warning-modal-BAV737"
					>
						Updating user role to {lowerCase(role)}
					</Text>
				),
				children: (
					<Stack>
						{hasWritePerms.includes(role) &&
							!hasWritePerms.includes(user.role) && (
								<Text size="sm">
									This user will have write permissions in all teams they are a
									member. Do you want to proceed?
								</Text>
							)}
						{!hasWritePerms.includes(role) &&
							hasWritePerms.includes(user.role) && (
								<Text size="sm">
									This user will lose write permissions in all teams they are a
									member. Do you want to proceed?
								</Text>
							)}
						{hasWritePerms.includes(role) && (
							<PricingDetails
								type="editors"
								nominalCurrentCount={usedEditorOrAdminSeats}
								nominalChange={1}
								hidden={false}
							/>
						)}
						{!hasWritePerms.includes(role) && (
							<PricingDetails
								type="viewers"
								nominalCurrentCount={usedViewerSeats}
								nominalChange={1}
								hidden={false}
							/>
						)}
					</Stack>
				),
				labels: {
					confirm: 'Confirm',
					cancel: 'Cancel',
				},
				cancelProps: {
					size: 'md',
				},
				confirmProps: {
					size: 'md',
					variant: 'primary',
				},
				onConfirm: async () => {
					await mutateAsync({
						data: { id: user.id, role },
					});
				},
			});
			return;
		}

		if (user.role !== role) {
			await mutateAsync({
				data: { id: user.id, role },
			});
		}
	};

	return {
		selectRole,
	};
};
