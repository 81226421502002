import { Checkbox, createStyles, Group, Stack, Textarea } from '@mantine/core';
import { useForm } from '@mantine/form';
import { Button, Text } from '@repo/foundations';
import type { AIFeedbackValues, SentimentType } from './constants';
import { CONFIG } from './constants';

const useStyles = createStyles((theme) => ({
	checkboxLabel: {
		fontWeight: theme.other.typography.weight.regular,
	},
	textarea: {
		borderRadius: theme.radius.sm,
		borderColor: theme.other.getColor('border/input/default'),
		backgroundColor: theme.other.getColor('surface/input/default'),
	},
	footer: {
		padding: `${theme.spacing.md} 0`,
		justifyContent: 'flex-end',
		borderTop: `1px solid ${theme.other.getColor('border/secondary/default')}`,
	},
}));

export interface AIFeedbackModalContentProps {
	sentiment: SentimentType;
	onCancel: VoidFunction;
	onSubmit: (values: AIFeedbackValues) => void;
	isAnswerOptionsRequired?: boolean;
}

export function AIFeedbackModalContent({
	sentiment,
	onCancel,
	onSubmit,
	isAnswerOptionsRequired = false,
}: AIFeedbackModalContentProps) {
	const { classes } = useStyles();

	const form = useForm({
		initialValues: {
			sentiment,
			answerOptions: [] as string[],
			details: '',
		},
		validate: {
			answerOptions: (value: string[], values) =>
				isAnswerOptionsRequired && value.length === 0 && !values.details,
		},
	});

	return (
		<form onSubmit={form.onSubmit(onSubmit)}>
			<Stack spacing="md">
				<Checkbox.Group
					defaultValue={[]}
					label={CONFIG[sentiment].question}
					value={form.values.answerOptions}
					onChange={(v) => form.setFieldValue('answerOptions', v)}
				>
					<Stack spacing="sm">
						{CONFIG[sentiment].answerOptions.map((option) => (
							<Checkbox
								classNames={{ label: classes.checkboxLabel }}
								key={option}
								label={option}
								value={option}
							/>
						))}
					</Stack>
				</Checkbox.Group>
				<Stack spacing="3xs">
					<Text weight="semibold" size="sm">
						Add more details
					</Text>
					<Textarea
						classNames={{ input: classes.textarea }}
						radius="sm"
						autosize
						minRows={4}
						{...form.getInputProps('details')}
					/>
				</Stack>
				<Group className={classes.footer}>
					<Button variant="default" onClick={onCancel}>
						Cancel
					</Button>
					<Button type="submit" variant="primary" disabled={!form.isValid()}>
						Send feedback
					</Button>
				</Group>
			</Stack>
		</form>
	);
}
