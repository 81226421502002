export enum AutomationActionType {
	// Owners
	OWNERS_ADD = 'owners_add',
	OWNERS_REMOVE = 'owners_remove',
	OWNERS_REPLACE = 'owners_replace',
	OWNERS_CLEAR = 'owners_clear',

	// Subscribers
	SUBSCRIBERS_ADD = 'subscribers_add',
	SUBSCRIBERS_REMOVE = 'subscribers_remove',
	SUBSCRIBERS_REPLACE = 'subscribers_replace',
	SUBSCRIBERS_CLEAR = 'subscribers_clear',

	// Tags
	TAGS_ADD = 'tags_add',
	TAGS_REMOVE = 'tags_remove',
	TAGS_REPLACE = 'tags_replace',
	TAGS_CLEAR = 'tags_clear',

	// Description
	DEFINITION_SET = 'definition_set',
	DEFINITION_CLEAR = 'definition_clear',

	// Description
	DESCRIPTION_SET = 'description_set',
	DESCRIPTION_CLEAR = 'description_clear',

	// Governance - PII
	PII_TRUE = 'pii_true',
	PII_FALSE = 'pii_false',

	// Governance - Verified
	VERIFIED_TRUE = 'verified_true',
	VERIFIED_FALSE = 'verified_false',

	// Status = Published
	PUBLISHED_TRUE = 'published_true',
	PUBLISHED_FALSE = 'published_false',

	// Teams
	TEAMS_ADD = 'teams_add',
	TEAMS_REMOVE = 'teams_remove',
	TEAMS_REPLACE = 'teams_replace',
	TEAMS_CLEAR = 'teams_clear',
	TEAMS_ADD_ALL = 'teams_add_all',

	// Collections
	COLLECTIONS_ADD = 'collections_add',
	COLLECTIONS_REMOVE = 'collections_remove',
	COLLECTIONS_REPLACE = 'collections_replace',
	COLLECTIONS_CLEAR = 'collections_clear',
}

// TODO: remove interface when v2 goes live
export interface AutomationAction {
	key: string;
	type: AutomationActionType;
	value: string;
	display: string;
}

// Same as AutomationAction but making a new interface to
// make it easier to remove all v1 references in 1 go
export interface AutomationActionUpdateEntity {
	key: string;
	type: AutomationActionType;
	value: string;
	display: string;
}
