import { cloneDeep } from 'lodash-es';
import { BaseModel } from './base';

export class APIKey extends BaseModel {
	// @ts-expect-error TS(2564): Property 'id' has no initializer and is not defini... Remove this comment to see the full error message
	id: string;

	// @ts-expect-error TS(2564): Property 'created_at' has no initializer and is no... Remove this comment to see the full error message
	created_at: string;

	key?: string;

	user?: string;

	description?: string;

	constructor(obj: APIKey) {
		super(obj);
		Object.keys(obj).forEach((key) => {
			(this as any)[key] = cloneDeep((obj as any)[key]);
		});
	}

	static get fields() {
		return BaseModel.fields.concat(['key', 'scope', 'expires_at', 'user']);
	}

	static get namespace() {
		return ['auth', 'api_keys'];
	}
}
