import type Token from 'markdown-it/lib/token';
import type { MarkdownSerializerState } from 'prosemirror-markdown';
import type {
	Attrs,
	MarkSpec,
	Mark as ProsemirrorMark,
} from 'prosemirror-model';
import type { Command, EditorState } from 'prosemirror-state';
import { Plugin } from 'prosemirror-state';
import addCommentMark from '../commands/addCommentMark';
import collapseSelection from '../commands/collapseSelection';
import chainTransactions from '../lib/chainTransactions';
import commentRule from '../rules/comment';
import type { Dispatch } from '../types';
import { removeStartingBulletPoints } from '../utils/removeStartingBulletPoints';
import { removeTrailingWhitespaces } from '../utils/removeTrailingWhitespaces';
import type { MarkOptions } from './Mark';
import Mark from './Mark';

// Placeholder id that allows for consistent UI display while the comment is being created.
export const COMMENT_PLACEHOLDER_ID = 'placeholder';

export default class Comment extends Mark {
	get name(): string {
		return 'comment';
	}

	get schema(): MarkSpec {
		return {
			attrs: {
				commentID: {
					default: '',
				},
			},
			inclusive: false,
			parseDOM: [
				{
					tag: 'span',
					getAttrs: (dom: HTMLElement | string) => {
						if (typeof dom === 'string') {
							return {};
						}
						return {
							commentID: dom.getAttribute('commentID'),
						} as Attrs;
					},
				},
			],
			toDOM: (mark) => [
				'span',
				{ commentID: `${mark.attrs.commentID}`, ...mark.attrs },
				0,
			],
			excludes: '', // Allow overlapping comment marks (https://prosemirror.net/docs/ref/#model.MarkSpec.excludes)
		};
	}

	get rulePlugins() {
		return [commentRule];
	}

	keys({ type }: MarkOptions): Record<string, Command> {
		return {
			'Mod-Alt-m': (state: EditorState, dispatch?: Dispatch) => {
				chainTransactions(
					addCommentMark(type, {
						commentID: COMMENT_PLACEHOLDER_ID,
					}),
					collapseSelection()
				)(state, dispatch);
				if (this.options.onCreatePlaceholderComment) {
					let selectedText = '';
					if (this.options.reference?.serializer) {
						selectedText = this.options.reference.serializer.serialize(
							state.selection.content().content
						);
					}

					// Filter out trailing empty lines
					selectedText = removeTrailingWhitespaces(selectedText);

					// Filter out initial bullets
					const lines = selectedText.split('\n');
					if (lines.length > 0) {
						lines[0] = removeStartingBulletPoints(lines[0]);
					}

					// Patch back the text
					selectedText = removeTrailingWhitespaces(lines.join('\n'));

					// Create placeholder
					this.options.onCreatePlaceholderComment(lines.join('\n'));
				}
				return true;
			},
		};
	}

	commands({ type }: MarkOptions) {
		return () => (state: EditorState, dispatch: Dispatch) => {
			chainTransactions(
				addCommentMark(type, {
					commentID: COMMENT_PLACEHOLDER_ID,
				}),
				collapseSelection()
			)(state, dispatch);
			if (this.options.onCreatePlaceholderComment) {
				let selectedText = '';
				if (this.options.reference?.serializer) {
					selectedText = this.options.reference.serializer.serialize(
						state.selection.content().content
					);
				}

				// Filter out trailing empty lines
				selectedText = removeTrailingWhitespaces(selectedText);

				// Filter out initial bullets
				const lines = selectedText.split('\n');
				if (lines.length > 0) {
					lines[0] = removeStartingBulletPoints(lines[0]);
				}

				// Patch back the text
				selectedText = removeTrailingWhitespaces(lines.join('\n'));

				// Create placeholder
				this.options.onCreatePlaceholderComment(selectedText);
			}
			return true;
		};
	}

	toMarkdown() {
		return {
			open: '{[{',
			close(state: MarkdownSerializerState, mark: ProsemirrorMark) {
				return `}]}(${mark.attrs.commentID})`;
			},
			mixable: true,
		};
	}

	get plugins(): Plugin[] {
		return [
			new Plugin({
				props: {
					handleDOMEvents: {
						// TODO[tan]: Maybe add keydown to handle when cursor moves. There's not an efficient way to do this.
						click: (view, event: MouseEvent) => {
							if (!this.options.onClickComment) {
								return false;
							}

							// Verify that the click is on a comment
							if (
								event.target &&
								event.target instanceof HTMLElement &&
								event.target.matches('span[commentID]')
							) {
								event.stopPropagation();
								event.preventDefault();
								this.options.onClickComment(
									event.target.getAttribute('commentID')
								);
								return true;
							}

							// Verify that the click is a child within a comment span
							// (`code` for example is a child of `span[commentID]` but is the target of the click)
							if (
								event.target &&
								event.target instanceof HTMLElement &&
								event.target.closest('span[commentID]')
							) {
								event.stopPropagation();
								event.preventDefault();
								this.options.onClickComment(
									event.target
										.closest('span[commentID]')
										?.getAttribute('commentID')
								);
								return true;
							}

							return false;
						},
					},
				},
			}),
		];
	}

	parseMarkdown() {
		return {
			mark: 'comment',
			getAttrs: (tok: Token) => ({
				commentID: tok.attrGet('commentID'),
			}),
		};
	}
}
