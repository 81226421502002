import { Box, createStyles, getDefaultZIndex } from '@mantine/core';
import { CellSelection, selectedRect } from 'prosemirror-tables';
import type { EditorView } from 'prosemirror-view';
import React from 'react';
import { Portal } from 'react-portal';
import useComponentSize from '../hooks/useComponentSize';
import useMediaQuery from '../hooks/useMediaQuery';
import useViewportHeight from '../hooks/useViewportHeight';

const useStyles = createStyles(
	(
		theme,
		{
			active,
			offset,
			top,
			left,
		}: {
			active?: boolean;
			offset: number;
			top: number;
			left: number;
		}
	) => ({
		wrapper: {
			willChange: 'opacity, transform',
			padding: theme.spacing['3xs'],
			gap: theme.spacing['3xs'],
			position: 'absolute',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			zIndex: getDefaultZIndex('max'),
			backgroundColor: theme.other.getColor('surface/primary/default'),
			border: `${theme.other.borderWidth.xs}px solid ${theme.other.getColor('border/primary/default')}`,
			borderRadius: theme.other.borderRadius.md,
			boxShadow: theme.shadows.md,
			transition:
				'opacity 150ms cubic-bezier(0.175, 0.885, 0.32, 1.275), transform 150ms cubic-bezier(0.175, 0.885, 0.32, 1.275)',
			transitionDelay: '150ms',
			lineHeight: 0,
			pointerEvents: 'none',
			whiteSpace: 'nowrap',

			opacity: active ? 1 : 0,
			transform: active ? 'translateY(-6px) scale(1)' : 'scale(0.95)',
			top: `${top}px`,
			left: `${left}px`,

			'@media print': {
				display: 'none',
			},

			'@media (hover: none) and (pointer: coarse)': {
				transition: 'opacity 150ms cubic-bezier(0.175, 0.885, 0.32, 1.275)',
				transform: 'scale(1)',
				borderRadius: 0,
				width: '100vw',
				position: 'fixed',
			},
		},
	})
);

const SSR = typeof window === 'undefined';

type IFloatingToolbarProps = {
	active?: boolean;
	// eslint-disable-next-line react/no-unused-prop-types
	view: EditorView;
	children: React.ReactNode;
	forwardedRef: React.RefObject<HTMLDivElement> | null | undefined;
};

const defaultPosition = {
	left: -1000,
	top: 0,
	offset: 0,
	visible: false,
};

// @ts-expect-error TS(7031): Binding element 'menuRef' implicitly has an 'any' ... Remove this comment to see the full error message
function usePosition({ menuRef, isSelectingText, props }) {
	const { view, active } = props;
	const { selection } = view.state;
	const { width: menuWidth, height: menuHeight } = useComponentSize(menuRef);
	const viewportHeight = useViewportHeight();
	const isTouchDevice = useMediaQuery('(hover: none) and (pointer: coarse)');

	if (!active || !menuWidth || !menuHeight || SSR || isSelectingText) {
		return defaultPosition;
	}

	// If we're on a mobile device then stick the floating toolbar to the bottom
	// of the screen above the virtual keyboard.
	if (isTouchDevice && viewportHeight) {
		return {
			left: 0,
			right: 0,
			top: viewportHeight - menuHeight,
			offset: 0,
			visible: true,
		};
	}

	// Based on the start and end of the selection calculate the position at
	// the center top
	let fromPos;
	let toPos;
	try {
		fromPos = view.coordsAtPos(selection.from);
		toPos = view.coordsAtPos(selection.to, -1);
	} catch (err) {
		// eslint-disable-next-line no-console
		console.warn(err);
		return defaultPosition;
	}

	// Ensure that start < end for the menu to be positioned correctly
	const selectionBounds = {
		top: Math.min(fromPos.top, toPos.top),
		bottom: Math.max(fromPos.bottom, toPos.bottom),
		left: Math.min(fromPos.left, toPos.left),
		right: Math.max(fromPos.right, toPos.right),
	};

	// tables are an oddity, and need their own positioning logic
	const isColSelection =
		selection instanceof CellSelection && selection.isColSelection();
	const isRowSelection =
		selection instanceof CellSelection && selection.isRowSelection();

	if (isColSelection && isRowSelection) {
		const rect = selectedRect(view.state);
		const table = view.domAtPos(rect.tableStart);
		const bounds = (table.node as HTMLElement).getBoundingClientRect();
		selectionBounds.top = bounds.top - 16;
		selectionBounds.left = bounds.left - 10;
		selectionBounds.right = bounds.left - 10;
	} else if (isColSelection) {
		const rect = selectedRect(view.state);
		const table = view.domAtPos(rect.tableStart);
		const element = (table.node as HTMLElement).querySelector(
			`tr > *:nth-child(${rect.left + 1})`
		);
		if (element instanceof HTMLElement) {
			const bounds = element.getBoundingClientRect();
			selectionBounds.top = bounds.top - 16;
			selectionBounds.left = bounds.left;
			selectionBounds.right = bounds.right;
		}
	} else if (isRowSelection) {
		const rect = selectedRect(view.state);
		const table = view.domAtPos(rect.tableStart);
		const element = (table.node as HTMLElement).querySelector(
			`tr:nth-child(${rect.top + 1}) > *`
		);
		if (element instanceof HTMLElement) {
			const bounds = element.getBoundingClientRect();
			selectionBounds.top = bounds.top;
			selectionBounds.left = bounds.left - 10;
			selectionBounds.right = bounds.left - 10;
		}
	}

	const isImageSelection =
		selection.node && selection.node.type.name === 'image';
	// Images need their own positioning to get the toolbar in the center
	if (isImageSelection) {
		const element = view.nodeDOM(selection.from);

		// Images are wrapped which impacts positioning - need to traverse through
		// p > span > div.image
		const imageElement = element.getElementsByTagName('img')[0];
		const { left, top, width } = imageElement.getBoundingClientRect();

		return {
			left: Math.round(left + width / 2 + window.scrollX - menuWidth / 2),
			top: Math.round(top + window.scrollY - menuHeight),
			offset: 0,
			visible: true,
		};
	}
	// Calcluate the horizontal center of the selection
	const halfSelection =
		Math.abs(selectionBounds.right - selectionBounds.left) / 2;
	const centerOfSelection = selectionBounds.left + halfSelection;

	// Position the menu so that it is centered over the selection except in
	// the cases where it would extend off the edge of the screen. In these
	// instances leave a margin
	const margin = 12;
	const left = Math.min(
		window.innerWidth - menuWidth - margin,
		Math.max(margin, centerOfSelection - menuWidth / 2)
	);
	const top = Math.min(
		window.innerHeight - menuHeight - margin,
		Math.max(margin, selectionBounds.top - menuHeight)
	);

	// If the menu has been offset to not extend offscreen then we should adjust
	// the position of the triangle underneath to correctly point to the center
	// of the selection still
	const offset = left - (centerOfSelection - menuWidth / 2);
	return {
		left: Math.round(left + window.scrollX),
		top: Math.round(top + window.scrollY),
		offset: Math.round(offset),
		visible: true,
	};
}

function FloatingToolbar(props: IFloatingToolbarProps) {
	const menuRef = props.forwardedRef || React.createRef<HTMLDivElement>();
	const [isSelectingText, setSelectingText] = React.useState(false);

	const position = usePosition({
		menuRef,
		isSelectingText,
		props,
	});

	const { classes } = useStyles({
		active: !!props.active && position.visible,
		offset: position.offset,
		top: position.top,
		left: position.left,
	});

	React.useEffect(() => {
		const handleMouseDown = () => {
			if (!props.active) {
				setSelectingText(true);
			}
		};

		const handleMouseUp = () => {
			setSelectingText(false);
		};

		window.addEventListener('mousedown', handleMouseDown);
		window.addEventListener('mouseup', handleMouseUp);

		return () => {
			window.removeEventListener('mousedown', handleMouseDown);
			window.removeEventListener('mouseup', handleMouseUp);
		};
	}, [props.active]);

	// Only render children when state is updated to visible
	// to prevent gaining input focus before calculatePosition runs
	return (
		<Portal>
			<Box ref={menuRef} className={classes.wrapper}>
				{position.visible && props.children}
			</Box>
		</Portal>
	);
}

export default React.forwardRef((props: any, ref: any) => (
	<FloatingToolbar forwardedRef={ref} {...props} />
));
