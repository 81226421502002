import { Box, Divider } from '@mantine/core';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useAuthUser } from '../../api';
import { useUpdateWorkspace } from '../../api/hooks/workspace/useUpdateWorkspace';
import { Section, SettingsSwitch } from './WorkspaceSettings';

function AppearanceSettings() {
	const { workspace, isAdminUser } = useAuthUser();
	const { mutateAsync } = useUpdateWorkspace(workspace.id);

	const disabled = !isAdminUser;

	const handleChange =
		(key: 'default_full_width_pages' | 'hide_dictionary_term_query_section') =>
		(e: React.ChangeEvent<HTMLInputElement>) => {
			mutateAsync({
				data: { id: workspace.id, [key]: e.currentTarget.checked },
			});
			runInAction(() => {
				workspace![key] = e.currentTarget.checked;
			});
		};

	return (
		<Box>
			<Section title="Resources">
				<SettingsSwitch
					onChange={handleChange('default_full_width_pages')}
					checked={Boolean(workspace?.default_full_width_pages)}
					title="Full page width"
					description="Set resource pages to full width by default."
				/>
				<Divider />
			</Section>
			<Section title="Guests">
				<SettingsSwitch
					disabled={disabled}
					checked={!workspace?.hide_feedback_for_viewers}
					onChange={(e) => {
						mutateAsync({
							data: {
								id: workspace.id,
								hide_feedback_for_viewers: !e.target.checked,
							},
						});
					}}
					title="Show help button"
					description="The help button will be shown for viewers and guests."
				/>
			</Section>
		</Box>
	);
}

export default observer(AppearanceSettings);
