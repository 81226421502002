import type { MantineNumberSize } from '@mantine/core';
import { Skeleton, rem, useMantineTheme } from '@mantine/core';

interface AvatarSkeletonProps {
	size?: MantineNumberSize;
}

export function AvatarSkeleton({ size }: AvatarSkeletonProps) {
	const theme = useMantineTheme();

	let height = theme.fontSizes.lg;

	if (size === 'xxs') {
		height = rem(16);
	} else if (size === 'xs') {
		height = rem(20);
	} else if (size === 'sm') {
		height = rem(24);
	} else if (size === 'md') {
		height = rem(28);
	} else if (size === 'lg') {
		height = rem(32);
	} else if (size === 'xl') {
		height = rem(40);
	}

	return <Skeleton width={height} height={height} circle />;
}
