import { isNil } from 'lodash-es';
import { DataQualityClassification } from '../enums/dataQualityClassification';

export function formatScore(score: number) {
	/* Score should be rounded down for consistency of formatting */
	return Math.floor(score);
}

export function getScoreClassification(
	score: number | undefined
): DataQualityClassification {
	if (isNil(score) || score < 0 || score > 100) {
		return DataQualityClassification.UNAVAILABLE;
	}

	const rounded = formatScore(score || 0);
	if (rounded < 34) {
		return DataQualityClassification.POOR;
	}

	if (rounded < 67) {
		return DataQualityClassification.MODERATE;
	}

	return DataQualityClassification.GOOD;
}
