import SkeletonWidgetRow from './SkeletonWidgetRow';

const PERSON_IMAGES = [
	'user-1.webp',
	'user-2.webp',
	'user-3.webp',
	'user-4.webp',
] as const;

type PersonImageType = (typeof PERSON_IMAGES)[number];

interface IPersonsCardProps {
	persons: [PersonImageType, PersonImageType];
}

function PersonsCard({ persons }: IPersonsCardProps) {
	return (
		<>
			{persons.map((person) => (
				<SkeletonWidgetRow
					key={person}
					src={`/images/icons/persons/${person}`}
				/>
			))}
		</>
	);
}

export default PersonsCard;
