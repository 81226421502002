import type { Filter } from '@repo/api-codegen';
import { DataQualityClassification } from '../enums/dataQualityClassification';

export function getDataQualityClassificationCatalogFilter(
	classification: DataQualityClassification,
	field: string
): Filter | null {
	switch (classification) {
		case DataQualityClassification.GOOD: {
			return {
				operands: [],
				field,
				operator: 'gte',
				value: 67,
			};
		}
		case DataQualityClassification.MODERATE: {
			return {
				operator: 'and',
				operands: [
					{
						operands: [],
						field,
						operator: 'gte',
						value: 34,
					},
					{
						operands: [],
						field,
						operator: 'lt',
						value: 67,
					},
				],
			};
		}
		case DataQualityClassification.POOR: {
			return {
				operands: [],
				field,
				operator: 'lt',
				value: 34,
			};
		}
		default: {
			return null;
		}
	}
}
