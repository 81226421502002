import type { MantineTheme } from '@mantine/core';
import { createStyles } from '@mantine/core';

export default createStyles(
	(theme: MantineTheme, { active }: { active: boolean }) => ({
		slide: {
			flexGrow: 0,
			flexShrink: 0,
			flexBasis: 'auto',
			height: 36,
			marginTop: 4,
			'& .mantine-Button-label': {
				color: active ? theme.colors.gray[9] : theme.colors.gray[6],
			},
		},
		indicator: {
			position: 'relative',
			height: 2,
			top: 6,
			zIndex: 1000,
			paddingRight: 24,
			borderBottomWidth: active ? 2 : 'unset',
			borderBottomStyle: active ? 'solid' : 'unset',
			borderBottomColor: active ? theme.colors.gray[9] : 'unset',
		},
	})
);
