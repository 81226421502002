import type { GroupProps } from '@mantine/core';
import { Group } from '@mantine/core';
import type { PropsWithChildren } from 'react';
import { forwardRef } from 'react';

interface IIconWrapperProps extends GroupProps {
	size?: number;
}

export const IconWrapper = forwardRef<
	HTMLDivElement,
	PropsWithChildren<IIconWrapperProps>
>(({ children, size = 20, ...rest }, ref) => (
	<Group
		ref={ref}
		position="center"
		align="center"
		w={size}
		h={size}
		{...rest}
		noWrap
	>
		{children}
	</Group>
));

IconWrapper.displayName = 'IconWrapper';
