import type { EditorView } from 'prosemirror-view';
import { v4 } from '../../../../../utils/uuid/v4';

export type Options = {
	integrationId?: string;
};

export default function insertQueryBlock(
	view: EditorView,
	pos: number,
	attrs: Options
) {
	const { schema, tr } = view.state;

	const $pos = tr.doc.resolve(pos);
	view.dispatch(
		view.state.tr.replaceWith(
			$pos.pos,
			$pos.pos + ($pos.nodeAfter?.nodeSize || 0),
			schema.nodes.query_block.create({
				...attrs,
				id: v4(),
			})
		)
	);
}
