import { Group } from '@mantine/core';
import { OPERATORS_CONFIG } from '@repo/common/components/Filter/constants';
import { observer } from 'mobx-react-lite';
import { Filter, FilterOptionType } from '../../../Filter';
import { FILTER_OPTIONS_CONFIG } from '../../../Filter/constants';
import { ImpactAnalysisSearch } from './ImpactAnalysisSearch';
import { impactAnalysisStore } from './store';

export const ImpactAnalysisFilters = observer(() => (
	<Group position="apart" noWrap>
		{!impactAnalysisStore.showSearch && (
			<Group spacing="xs" noWrap>
				<Filter
					filterOption={FILTER_OPTIONS_CONFIG[FilterOptionType.DIRECTION]}
					onChange={(value) =>
						impactAnalysisStore.setValue(FilterOptionType.DIRECTION, value)
					}
					value={impactAnalysisStore.filterValues[FilterOptionType.DIRECTION]}
					showDetailedLabel
					operatorConfig={
						OPERATORS_CONFIG[
							FILTER_OPTIONS_CONFIG[FilterOptionType.DIRECTION]
								.filterDropdownConfig.dropdownType
						]
					}
				/>
				<Filter
					filterOption={FILTER_OPTIONS_CONFIG[FilterOptionType.NATIVE_TYPE]}
					onChange={(value) =>
						impactAnalysisStore.setValue(FilterOptionType.NATIVE_TYPE, value)
					}
					value={impactAnalysisStore.filterValues[FilterOptionType.NATIVE_TYPE]}
					showDetailedLabel
					operatorConfig={
						OPERATORS_CONFIG[
							FILTER_OPTIONS_CONFIG[FilterOptionType.NATIVE_TYPE]
								.filterDropdownConfig.dropdownType
						]
					}
					onClear={() =>
						impactAnalysisStore.setValue(FilterOptionType.NATIVE_TYPE, {
							value: [],
						})
					}
				/>
				<Filter
					filterOption={
						FILTER_OPTIONS_CONFIG[FilterOptionType.INTEGRATION_TYPE]
					}
					onChange={(value) =>
						impactAnalysisStore.setValue(
							FilterOptionType.INTEGRATION_TYPE,
							value
						)
					}
					value={
						impactAnalysisStore.filterValues[FilterOptionType.INTEGRATION_TYPE]
					}
					showDetailedLabel
					operatorConfig={
						OPERATORS_CONFIG[
							FILTER_OPTIONS_CONFIG[FilterOptionType.INTEGRATION_TYPE]
								.filterDropdownConfig.dropdownType
						]
					}
					onClear={() =>
						impactAnalysisStore.setValue(FilterOptionType.INTEGRATION_TYPE, {
							value: [],
						})
					}
				/>
			</Group>
		)}
		<ImpactAnalysisSearch
			searchTerm={impactAnalysisStore.searchTerm}
			setSearchTerm={impactAnalysisStore.setSearchTerm}
			hidden={!impactAnalysisStore.showSearch}
		/>
	</Group>
));
