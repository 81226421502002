import type { ProviderConfig } from 'launchdarkly-react-client-sdk';
import { useMemo } from 'react';
import { useAuthUser } from '../../api';

// we don't want to bootstrap LaunchDarkly in CI to avoid unnecessary costs in MAU
// we also do this check in the backend
const isCI = import.meta.env.CI || false;

/**
 * This hook bootstraps the LaunchDarkly context variables with the user and workspace information.
 */
export function useSetupFeatureFlags(): ProviderConfig {
	const { workspace } = useAuthUser();

	return useMemo(
		() => ({
			context:
				!isCI && workspace?.id
					? {
							// Make sure to never add PII attributes to LaunchDarkly's context
							// below these keys should match the keys in the API:
							// https://github.com/secoda/secoda/blob/c28bd446467ae78467dd7ec66d79017124fdf16e/api/feature_flags.py
							kind: 'multi',
							workspace: {
								key: workspace.id,
								name: workspace.name,
								customerId: workspace.customer_id,
								planId: workspace.customer_plan_id,
							},
						}
					: undefined,
			clientSideID: '6552442215751a124fca2ce8', // we only use the production env in LaunchDarkly
			deferInitialization: true, // prevents initializing an anonymous user context
		}),
		[workspace]
	);
}
