import type { SecodaEditorComponentProps } from '@repo/secoda-editor';
import type { MarkdownSerializerState } from '@repo/secoda-editor/lib/markdown/serializer';
import type Token from 'markdown-it/lib/token';
import type {
	Attrs,
	NodeSpec,
	Node as ProsemirrorNode,
} from 'prosemirror-model';
import toggleWrap from '../commands/toggleWrap';
import { AttachmentContainer } from '../components/AttachmentContainer';
import attachmentsRule from '../rules/attachments';
import { sanitizeUrl } from '../utils/urls';
import type { NodeOptions } from './Node';
import ReactNode from './ReactNode';

export default class Attachment extends ReactNode {
	get name() {
		return 'attachment';
	}

	get rulePlugins() {
		return [attachmentsRule];
	}

	get schema(): NodeSpec {
		return {
			attrs: {
				id: {
					default: null,
				},
				href: {
					default: null,
				},
				title: {},
				size: {
					default: 0,
				},
			},
			group: 'block',
			defining: true,
			atom: true,
			parseDOM: [
				{
					priority: 100,
					tag: 'a.attachment',
					getAttrs: (dom: HTMLElement | string) =>
						typeof dom === 'string'
							? null
							: {
									id: dom.id,
									title: dom.innerText,
									href: dom.getAttribute('href'),
									size: parseInt(dom.dataset.size || '0', 10),
								},
				},
			],
			toDOM: (node) => [
				'a',
				{
					class: 'attachment',
					id: node.attrs.id,
					href: sanitizeUrl(node.attrs.href),
					download: node.attrs.title,
					'data-size': node.attrs.size,
				},
				node.attrs.title,
			],
			toPlainText: (node: ProsemirrorNode) => node.attrs.title,
		};
	}

	component({ isSelected, node }: SecodaEditorComponentProps) {
		return (
			<AttachmentContainer
				title={node.attrs.title}
				href={node.attrs.href}
				isSelected={isSelected}
			/>
		);
	}

	commands({ type }: NodeOptions) {
		return (attrs?: Attrs) => toggleWrap(type, attrs);
	}

	toMarkdown(state: MarkdownSerializerState, node: ProsemirrorNode) {
		state.write(
			`\n\n[${node.attrs.title} ${node.attrs.size}](${node.attrs.href})\n\n`
		);
		state.closeBlock(node);
	}

	parseMarkdown() {
		return {
			node: 'attachment',
			getAttrs: (tok: Token) => ({
				href: tok.attrGet('href'),
				title: tok.attrGet('title'),
				size: tok.attrGet('size'),
			}),
		};
	}
}
