import { createQueryKeys } from '../../factories';

export const WORKSPACE_NAMESPACE = ['workspace'];

export const WORKSPACE_QUERY_KEY_TYPES: string[] = [
	'columnState',
	'featureFlags',
];

type OneOfQueryKeyTypes = (typeof WORKSPACE_QUERY_KEY_TYPES)[number];

export const workspaceQueryKeyFactory =
	createQueryKeys<OneOfQueryKeyTypes>(WORKSPACE_NAMESPACE);
