import { Box, Collapse, createStyles, Group } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import type { ReactNode } from 'react';
import { Icon, Title } from '../../../../../../../../packages/foundations';

interface AccordionCardProps {
	title: string;
	children: ReactNode;
}

interface AccordionCardStylesProps {
	opened: boolean;
}

const useStyles = createStyles(
	(theme, { opened }: AccordionCardStylesProps) => ({
		wrapper: {
			borderWidth: 1,
			borderStyle: 'solid',
			borderColor: theme.other.getColor('border/secondary/default'),
			borderRadius: theme.radius.sm,
			boxShadow:
				'0px 1px 2px 0px rgba(0, 0, 0, 0.04), 0px 4px 12px 0px rgba(0, 0, 0, 0.04)',
		},
		header: {
			cursor: 'pointer',
			padding: `${theme.spacing.sm} ${theme.spacing.md}`,
		},
		chevronIcon: {
			transition: 'transform 0.3s ease',
			transform: opened ? 'rotate(90deg)' : 'rotate(0deg)',
		},
		content: {
			padding: `${theme.spacing.xs} ${theme.spacing.md} ${theme.spacing.sm} ${theme.spacing.md}`,
		},
	})
);

export function AccordionCard({ title, children }: AccordionCardProps) {
	const [opened, { toggle }] = useDisclosure(false);

	const { classes } = useStyles({ opened });

	return (
		<Box className={classes.wrapper}>
			<Group
				className={classes.header}
				position="apart"
				noWrap
				onClick={toggle}
			>
				<Title size="sm">{title}</Title>
				<Icon className={classes.chevronIcon} name="chevronRight" />
			</Group>
			<Collapse in={opened}>
				<Box className={classes.content}>{children}</Box>
			</Collapse>
		</Box>
	);
}
