import { Box, Center, createStyles, Stack } from '@mantine/core';
import { useApiGetUrlPreview } from '@repo/api-codegen';
import { Button } from '@repo/foundations';
import { useRef } from 'react';
import type { ISecodaEntity } from '../../api';
import { EmptyState } from '../EmptyState';
import { FullWidthLoadingSpinner } from '../LoadingSpinner';
import { openModal } from '../ModalManager';
import PowerBiPreview from './PowerBiPreview';
import { PREVIEW_MAP } from './UrlPreview.constants';

const PREVIEW_BOTTOM_MARGIN = '80px';

interface IPreviewProps {
	integration: string;
	entity: ISecodaEntity;
}

const useStyles = createStyles((theme) => ({
	wrapper: {
		width: '100%',
		height: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	messageWrapper: {
		textAlign: 'center',
	},
	loading: {
		my: 20,
	},
	preview: {
		height: '100%',
		position: 'relative',
	},
	noPreview: {
		pt: 0,
	},
	noPreviewTitle: {
		size: 800,
		mb: 4,
	},
	previewBtn: {
		position: 'absolute',
		bottom: theme.other.space[5],
		left: `calc(50% - ${theme.other.space[20]}px)`,
		width: theme.other.space[40],
	},
	previewContainer: {
		height: `calc(100vh - ${PREVIEW_BOTTOM_MARGIN})`,
	},
}));

export default function UrlPreview({ integration, entity }: IPreviewProps) {
	const { classes } = useStyles();
	const previewRef = useRef<HTMLDivElement>(null);
	const { data: previewUrl = '', isLoading } = useApiGetUrlPreview(
		{
			pathParams: { entityId: entity.id },
		},
		{
			select: (res) => res.result,
		}
	);

	const handleOpenPreviewModal = () => {
		openModal({
			variant: 'default',
			title: 'Preview',
			fullScreen: true,
			children: (
				<Box className={classes.previewContainer} ref={previewRef}>
					{integration === 'powerbi' ? (
						<PowerBiPreview previewUrl={previewUrl} previewRef={previewRef} />
					) : (
						PREVIEW_MAP[integration](previewUrl)
					)}
				</Box>
			),
		});
	};

	if (isLoading) {
		return (
			<Box className={classes.loading}>
				<FullWidthLoadingSpinner />
			</Box>
		);
	}

	if (integration === 'powerbi' && previewUrl) {
		return (
			<Box className={classes.preview}>
				<Box className={classes.preview} ref={previewRef}>
					<PowerBiPreview previewUrl={previewUrl} previewRef={previewRef} />
				</Box>
				<Button
					variant="primary"
					className={classes.previewBtn}
					leftIconName="maximize"
					onClick={handleOpenPreviewModal}
				>
					Expand preview
				</Button>
			</Box>
		);
	}

	if (integration && integration in PREVIEW_MAP && previewUrl) {
		return (
			<Box className={classes.preview}>
				{PREVIEW_MAP[integration](previewUrl)}
				<Button
					variant="primary"
					className={classes.previewBtn}
					leftIconName="maximize"
					onClick={handleOpenPreviewModal}
				>
					Expand preview
				</Button>
			</Box>
		);
	}
	return (
		<Center h="100%" w="100%">
			<Stack className={classes.wrapper}>
				<EmptyState
					stateHeight="80vh"
					iconName="listDetails"
					title="No preview"
					description="There is no preview available for this resource."
					size="lg"
					includeGoBack={false}
				/>
			</Stack>
		</Center>
	);
}
