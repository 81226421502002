import { get } from 'lodash-es';

export function getPointValueFromChannel<T, P>(
	channel: keyof T | ((d: T) => P),
	item: T
) {
	return typeof channel === 'function'
		? channel(item)
		: (get(item, channel) as P);
}
