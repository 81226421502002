import { Container, Group, createStyles } from '@mantine/core';
import { useListCustomProperties } from '@repo/api-codegen';
import { Text } from '@repo/foundations';
import { uuidv4 } from 'lib0/random';
import { isNil, lowerCase } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import { useMemo, useState } from 'react';
import type {
	Automation,
	AutomationFilter,
	AutomationFilterSet,
} from '../../../../../api';
import {
	AutomationCondition,
	AutomationDateOptions,
	AutomationField,
	AutomationTriggerType,
} from '../../../../../api';
import { useAutomationStore } from '../../../../../pages/AutomationPage/context';
import { isValidEnumValue } from '../../../../../utils/enumUtils';
import AutomationCardDeleteButton from '../../AutomationCardDeleteButton';
import AutomationCardValueInput from '../../AutomationCardValueInput';
import { AutomationValueType, getAutomationValueType } from '../../constants';
import AutomationActionCardFilterConditionButton from './AutomationActionCardFilterConditionButton';
import AutomationActionCardFieldButton from './AutomationActionCardFilterFieldButton';
import { useAutomationActionCardFilterStore } from './context';
import { isCustomPropertyField } from './utils';

const useStyles = createStyles((theme) => ({
	group: {
		gap: theme.spacing.xs,
	},
	topGroup: {
		width: 'auto',
		flexWrap: 'nowrap',
		alignItems: 'flex-start',
		gap: theme.spacing.xs,
		margin: 0,
		padding: 0,
		overflow: 'hidden',
	},
	bottomGroup: {
		alignItems: 'flex-end',
		gap: theme.spacing.xs,
		height: '100%',
		lineHeight: '28px',
	},
	container: {
		display: 'flex',
		justifyContent: 'space-between',
		gap: theme.spacing['0.5'],
		margin: 0,
		padding: 0,
		flex: '1 0 0',
		width: '100%',
		maxWidth: 'none',
	},
	text: {
		lineHeight: '28px',
	},
}));

interface AutomationActionCardFilterRowProps {
	handleAutomationUpdate: (automation: Partial<Automation>) => Promise<void>;
	filterSetSearchKey?: string;
	filterSearchKey: string;
	field?: AutomationField;
	condition?: AutomationCondition;
	display?: string;
	value?: string | boolean;
	isLastElement: boolean;
}

function AutomationActionCardFilterRow({
	handleAutomationUpdate,
	filterSetSearchKey,
	filterSearchKey,
	field,
	condition,
	display,
	value,
	isLastElement,
}: AutomationActionCardFilterRowProps) {
	const { classes } = useStyles();
	const store = useAutomationActionCardFilterStore();
	const automationStore = useAutomationStore();
	const { automation } = automationStore;
	const { data: customProperties } = useListCustomProperties({});
	const [dateRange, setDateRange] = useState<AutomationDateOptions | null>(
		null
	);

	let initialValueType: AutomationValueType = getAutomationValueType(field);

	const [valueType, setValueType] =
		useState<AutomationValueType>(initialValueType);

	const shouldDisplayValue = useMemo(() => {
		// If condition is IS_SET or IS_NOT_SET, then value is not needed
		if (
			condition &&
			[AutomationCondition.IS_SET, AutomationCondition.IS_NOT_SET].includes(
				condition
			)
		) {
			return false;
		}

		// If condition is LTE or GTE and field is not EXTERNAL_USAGE or INTERNAL_USAGE,
		// then value is not needed because date input will be shown instead
		if (
			condition &&
			[AutomationCondition.GTE, AutomationCondition.LTE].includes(condition) &&
			field &&
			![
				AutomationField.EXTERNAL_USAGE,
				AutomationField.INTERNAL_USAGE,
			].includes(field) &&
			!isCustomPropertyField(field)
		) {
			return false;
		}

		if (isCustomPropertyField(field)) {
			const [_, searchId, __] = field?.split('.') ?? [];
			const customProperty = customProperties?.find(
				(property) => property.id === searchId
			);

			if (customProperty?.value_type === 'date') {
				setDateRange(AutomationDateOptions.CUSTOM);
				return false;
			}
		}

		return true;
	}, [condition, field, customProperties]);

	const requiresDateInput = valueType === AutomationValueType.DATE_INPUT;

	const filterConjunction = lowerCase(store.filterOperator);

	const handleFilterUpdate = async () => {
		let filterSets: AutomationFilterSet[];

		// Update DB for all filters that have all field, values, and displays
		// Need to check is isNil because empty strings are supported
		const validStoreFilters = store.filters
			.filter(
				(filter) =>
					!isNil(filter.field) &&
					!isNil(filter.searchKey) &&
					!isNil(filter.value) &&
					!isNil(filter.condition)
			)
			.map((filter) => ({
				key: filter.searchKey,
				field: filter.field,
				value: filter.value,
				condition: filter.condition,
				display: filter.display,
			})) as AutomationFilter[];

		const newFilterSetKey = uuidv4();

		if (
			automation?.filter_config?.filter_sets &&
			automation?.filter_config.filter_sets.length > 0
		) {
			/**
			 * if filter sets already exist in the db, there are two scenario to consider:
			 * 1. The filter set was created when the user created the automation from v1 OR
			 * 2. The filter set was created with automation v2
			 *
			 * If the filter set has a length of 1 and there is no key on the filter set object,
			 * it is from v1, otherwise assume it is from v2
			 */

			const existingFilterSet = filterSetSearchKey
				? automation.filter_config.filter_sets.filter(
						(set) => set.key === filterSetSearchKey
					)
				: [];

			// handle the case for v1 automation by setting the key on the filterSet and updating the operator and filters
			if (
				existingFilterSet.length === 0 &&
				automation.filter_config.filter_sets.length === 1 &&
				!automation.filter_config.filter_sets[0].key
			) {
				filterSets = [
					{
						operator: store.filterOperator,
						key: filterSetSearchKey ?? newFilterSetKey,
						filters: validStoreFilters,
					},
				];
			} else if (existingFilterSet.length === 0) {
				// handle the case for v2 automation where a new filter set has been created
				filterSets = [
					...automation.filter_config.filter_sets,
					{
						operator: store.filterOperator,
						key: filterSetSearchKey ?? newFilterSetKey,
						filters: validStoreFilters,
					},
				];
			} else {
				// handle the case for v2 automation where an existing filter set had their filters updated
				filterSets = [
					...automation.filter_config.filter_sets.map((set) => {
						if (set.key === filterSetSearchKey) {
							return {
								...set,
								filters: validStoreFilters,
							};
						}

						return set;
					}),
				];
			}

			// throw an error if filter set cannot be determined
			if (filterSets.length === 0) {
				throw new Error(
					`Invalid state for filter set update for automation: ${automation.id}`
				);
			}
		} else {
			/**
			 * If there are no filter sets on the db, that means this is the first
			 * filter set the user is adding.
			 * Create the a new filterSet arr with one object inside
			 */
			filterSets = [
				{
					operator: store.filterOperator,
					key: filterSetSearchKey ?? newFilterSetKey,
					filters: validStoreFilters,
				},
			];
		}

		if (filterSets.every((set) => set.filters.length === 0)) {
			automationStore.removeFilterCountCard();
		} else if (automation?.trigger_type === AutomationTriggerType.SCHEDULE) {
			automationStore.addFilterCountCard();
		}

		await handleAutomationUpdate?.({
			filter_config: {
				...automation?.filter_config,
				filter_sets: filterSets,
				operator: automation?.filter_config?.operator ?? store.filterOperator,
			},
		});

		// This should only occur when an automation was created in v1
		if (!filterSetSearchKey) {
			automationStore.updateKey(newFilterSetKey);
		}
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleValueChange = (val: any, label?: string) => {
		store.setFilterValue({
			key: filterSearchKey,
			value: val,
			display: label,
		});

		handleFilterUpdate();
	};

	const handleDateValueChange = (val: any, label?: string) => {
		if (isValidEnumValue(AutomationDateOptions, val)) {
			setDateRange(val);
		}

		if (val !== AutomationDateOptions.CUSTOM) {
			store.setFilterValue({
				key: filterSearchKey,
				value: val,
				display: label,
			});

			handleFilterUpdate();
			// Reset date range to null so if user clicks on button again, it will show the dropdown
			setDateRange(null);
		}
	};

	const handleDeleteAction = () => {
		store.deleteFilter(filterSearchKey);

		handleFilterUpdate();
	};

	return (
		<Container className={classes.container}>
			<Group key={filterSearchKey} className={classes.group}>
				<Group className={classes.topGroup}>
					<AutomationActionCardFieldButton
						filterSearchKey={filterSearchKey}
						field={field}
						setValueType={setValueType}
					/>
					<AutomationActionCardFilterConditionButton
						field={field}
						filterSearchKey={filterSearchKey}
						condition={condition}
						handleFilterUpdate={handleFilterUpdate}
					/>
					{shouldDisplayValue && (
						<AutomationCardValueInput
							valueType={valueType}
							handleValueChange={handleValueChange}
							display={display}
							value={value}
							field={field}
						/>
					)}
					{requiresDateInput && dateRange !== AutomationDateOptions.CUSTOM && (
						<AutomationCardValueInput
							valueType={AutomationValueType.DROPDOWN}
							handleValueChange={handleDateValueChange}
							display={display}
							value={value}
							field={field}
						/>
					)}
					{requiresDateInput && dateRange === AutomationDateOptions.CUSTOM && (
						<AutomationCardValueInput
							valueType={AutomationValueType.DATE_INPUT}
							handleValueChange={handleDateValueChange}
							display={display}
							value={value}
							field={field}
							defaultOpened={true}
						/>
					)}
				</Group>
				<Group className={classes.bottomGroup}>
					{!isLastElement && (
						<Text className={classes.text}>{filterConjunction}</Text>
					)}
				</Group>
			</Group>
			<AutomationCardDeleteButton handleDelete={handleDeleteAction} />
		</Container>
	);
}

export default observer(AutomationActionCardFilterRow);
