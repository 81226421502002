export const DEFAULT_TAG_COLORS = [
	'#cccccc',
	'#2f2db9',
	'#e60c00',
	'#e68600',
	'#e6b800',
	'#8fc620',
	'#30b651',
	'#2da4b9',
	'#0066cc',
	'#8c25c1',
	'#be2898',
	'#e6002c',
];

export function getRandomTagColor() {
	return DEFAULT_TAG_COLORS[
		Math.floor(Math.random() * DEFAULT_TAG_COLORS.length)
	];
}
