import { ActionIcon, Box, Divider, Group, Menu, Skeleton } from '@mantine/core';
import { useDebouncedValue, useId } from '@mantine/hooks';
import { Button, Icon, Text } from '@repo/foundations';
import { observer } from 'mobx-react-lite';
import { Fragment, useMemo, useState } from 'react';
import { isTagGroup, useTagGroupList, useTagList } from '../../../api';
import { closeAllModals, openModal } from '../../ModalManager';
import SearchBox from '../../SearchBox/SearchBox';
import { SettingsTableEmpty } from '../Empty';
import TagCard from './Card/TagCard';
import TagGroupCard from './Card/TagGroupCard';
import { TagGroupModal } from './Modal/TagGroupModal';
import { TagModal } from './Modal/TagModal';
import { useStyles } from './TagSettings.styles';

export const TagSettings = observer(() => {
	const uuid = useId();

	const { classes } = useStyles();

	const { data: tags, isLoading: isLoadingTags } = useTagList({
		filters: {
			visible: 'all',
		},
		options: {
			select: (data) => data.results,
			refetchOnMount: 'always',
		},
	});
	const { data: tagGroups, isLoading: isLoadingTagGroups } = useTagGroupList({
		options: {
			select: (data) => data.results,
			refetchOnMount: 'always',
		},
	});
	const isLoading = isLoadingTags || isLoadingTagGroups;

	// Filter logic
	const [searchTerm, setSearchTerm] = useState<string>('');
	const [debouncedSearchTerm] = useDebouncedValue(searchTerm, 200);

	const [filter, setFilter] = useState<'all' | 'custom' | 'integration'>('all');
	const [sortBy, setSortBy] = useState<'asc' | 'dsc'>('asc');

	const rows = useMemo(() => {
		if (!tags && !tagGroups) return [];
		// Get tags that are un-grouped and all tag groups.
		// eslint-disable-next-line @typescript-eslint/no-shadow
		const rows = [
			...(tags?.filter((tag) => !tag.tag_group_id) || []),
			...(tagGroups || []),
		];

		const filteredRows = rows
			?.filter((row) =>
				row.name.toLowerCase().includes(debouncedSearchTerm.toLowerCase())
			)
			?.filter((row) => {
				if (isTagGroup(row)) return true;
				if (filter === 'custom') return !row.integration;
				if (filter === 'integration') return !!row.integration;
				return true;
			});

		return filteredRows?.sort((a, b) => {
			if (sortBy === 'asc') return a.name.localeCompare(b.name);
			return b.name.localeCompare(a.name);
		});
	}, [debouncedSearchTerm, filter, sortBy, tagGroups, tags]);

	const noTagExist = !tags || tags.length === 0;

	const allTags = useMemo(() => (tags || []).map((tag) => tag.name), [tags]);
	const allGroups = useMemo(
		() => (tagGroups || []).map((group) => group.name),
		[tagGroups]
	);

	const handleOpenTagModal = () => {
		openModal({
			title: 'Create tag',
			children: (
				<TagModal
					key={`tag-modal-${uuid}`}
					allTags={allTags}
					onClose={closeAllModals}
				/>
			),
		});
	};

	const handleOpenGroupModal = () => {
		openModal({
			title: 'Create tag group',
			children: (
				<TagGroupModal
					key={`tag-group-modal-${uuid}`}
					allGroups={allGroups}
					onClose={closeAllModals}
				/>
			),
		});
	};

	return (
		<Box>
			<Group w="100%" spacing="xs" noWrap>
				<SearchBox
					defaultSearchTerm={searchTerm}
					onSearch={setSearchTerm}
					onCancelSearch={() => setSearchTerm('')}
					placeholder="Search tags"
					disabled={noTagExist}
				/>
				<Menu>
					<Menu.Target>
						<ActionIcon className={classes.actionIcon} disabled={noTagExist}>
							<Icon
								name="filter"
								color={
									noTagExist ? 'icon/secondary/default' : 'icon/primary/default'
								}
							/>
						</ActionIcon>
					</Menu.Target>
					<Menu.Dropdown>
						<Menu.Label pl="xs">
							<Text weight="bold" color="text/secondary/default" size="sm">
								Filter by
							</Text>
						</Menu.Label>
						<Menu.Item
							rightSection={filter === 'all' && <Icon name="check" />}
							onClick={() => setFilter('all')}
						>
							All
						</Menu.Item>
						<Menu.Item
							rightSection={filter === 'custom' && <Icon name="check" />}
							onClick={() => setFilter('custom')}
						>
							Custom tags
						</Menu.Item>
						<Menu.Item
							rightSection={filter === 'integration' && <Icon name="check" />}
							onClick={() => setFilter('integration')}
						>
							Integration tags
						</Menu.Item>
						<Menu.Divider />
						<Menu.Label pl="xs">
							<Text weight="bold" color="text/secondary/default" size="sm">
								Sort by
							</Text>
						</Menu.Label>
						<Menu.Item
							icon={<Icon name="sortAZ" />}
							onClick={() => setSortBy('asc')}
							rightSection={sortBy === 'asc' && <Icon name="check" />}
						>
							Ascending
						</Menu.Item>
						<Menu.Item
							icon={<Icon name="sortZA" />}
							onClick={() => setSortBy('dsc')}
							rightSection={sortBy === 'dsc' && <Icon name="check" />}
						>
							Descending
						</Menu.Item>
					</Menu.Dropdown>
				</Menu>
				<Button size="lg" onClick={handleOpenGroupModal}>
					Create group
				</Button>
				<Button variant="primary" size="lg" onClick={handleOpenTagModal}>
					Create tag
				</Button>
			</Group>
			<Skeleton visible={isLoading} mt="xl">
				{rows.length ? (
					<Box className={classes.list}>
						{rows?.map((row, idx) => (
							<Fragment key={row.id}>
								{isTagGroup(row) ? (
									<TagGroupCard
										tagGroup={row}
										allGroups={allGroups}
										allTags={allTags}
									/>
								) : (
									<TagCard tag={row} allTags={allTags} />
								)}
								{idx !== rows.length - 1 && <Divider />}
							</Fragment>
						))}
					</Box>
				) : (
					<SettingsTableEmpty
						title="No tags found"
						description='Create a tag by selecting "New tag".'
					/>
				)}
			</Skeleton>
		</Box>
	);
});
