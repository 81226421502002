import { createStyles, Input, Stack } from '@mantine/core';
import { Banner } from '@repo/foundations';
import SqlEditor, {
	BOTTOM_WRAPPER_PADDING,
} from '../../../Sql/SqlEditor/SqlEditor';

const useStyles = createStyles((theme) => ({
	root: {
		padding: 0,
		marginBottom: -BOTTOM_WRAPPER_PADDING,
	},
	editor: {
		top: 0,
		border: 'thin solid',
		borderWidth: 0.5,
		borderColor: theme.other.getColor('border/input/default'),
		borderRadius: theme.radius.sm,
		padding: theme.spacing['3xs'],
		paddingTop: theme.spacing.md,
		backgroundColor: theme.colors.gray[0],
		outlineColor: 'none',
	},
}));

interface CustomSQLSelectorProps {
	label: string;
	onChange: (value: string) => void;
	error: string;
	description: string;
}

function CustomSQLSelector({
	label,
	onChange,
	error,
	description,
}: CustomSQLSelectorProps) {
	const { classes } = useStyles();

	return (
		<Stack spacing="3xs" pb="xs">
			<Input.Label>{label}</Input.Label>
			<SqlEditor
				classNames={{
					root: classes.root,
					editor: classes.editor,
				}}
				onChange={onChange}
				lineNumbers
			/>
			{error && <Banner isInsideCard tone="critical" message={error} />}
			<Input.Description> {description}</Input.Description>
		</Stack>
	);
}

export default CustomSQLSelector;
