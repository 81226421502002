import { DataQualityClassification } from '@repo/common/enums/dataQualityClassification';
import { getScoreClassification } from '@repo/common/utils/dataQuality';
import {
	ProgressIndicator,
	type ProgressIndicatorVariants,
} from '../ProgressIndicator/ProgressIndicator';

const ICON_CONFIG: Record<
	DataQualityClassification,
	{ variant: ProgressIndicatorVariants }
> = {
	[DataQualityClassification.GOOD]: {
		variant: 'info',
	},
	[DataQualityClassification.MODERATE]: {
		variant: 'warning',
	},
	[DataQualityClassification.POOR]: {
		variant: 'critical',
	},
	[DataQualityClassification.UNAVAILABLE]: {
		variant: 'primary',
	},
};

interface DataQualityProgressIconProps {
	score: number;
}

export function DataQualityProgressIcon({
	score,
}: DataQualityProgressIconProps) {
	const classification = getScoreClassification(score);

	return (
		<ProgressIndicator
			value={score}
			variant={ICON_CONFIG[classification].variant}
			bg="fill/tertiary/default"
		/>
	);
}
