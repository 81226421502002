import { isNil } from 'lodash-es';
import type { IMetric, MetricNumericFormat } from '../api';
import { parseUnknownDateTime } from './time';

type ColumnType = 'string' | 'number' | 'date' | 'unknown';

export function guessColumnTypes(
	data: any[][],
	rowsToCheck: number = 7,
	rowsToCheckStart: number = 1
): ColumnType[] {
	const columnTypes: ColumnType[] = [];

	const isDate = (str: string) => !isNil(parseUnknownDateTime(str));

	if (data.length === 0) {
		return [];
	}

	for (let col = 0; col < data[0].length; col += 1) {
		// Skip the first row, since it may contain the column names.
		for (
			let row = rowsToCheckStart;
			row < Math.min(data.length, rowsToCheck + 1);
			row += 1
		) {
			let type: ColumnType = 'unknown';
			const cell = data[row][col];

			if (isNil(cell) || cell === 'N/A' || cell === 'None' || cell === '') {
				columnTypes[col] = columnTypes[col] ?? 'unknown';
				continue;
			}

			if (typeof cell === 'string') {
				if (/^[0-9]+(\.[0-9]+)?$/.test(cell) && !isNaN(parseFloat(cell))) {
					if (Number.isInteger(parseFloat(cell))) {
						type = 'number';
					} else {
						type = 'number';
					}
				} else if (isDate(cell)) {
					type = 'date';
				} else {
					type = 'string';
				}
			} else if (typeof cell === 'number') {
				type = 'number';
			}

			if (isNil(columnTypes[col])) {
				columnTypes[col] = type;
			} else if (columnTypes[col] === type) {
				continue;
			} else {
				columnTypes[col] = 'unknown';
				break;
			}
		}
	}

	return columnTypes;
}

export type ObjectRow<T extends string> = Record<T, any>;

export function arrayToObjects<T extends string>(
	inputArray: any[][]
): ObjectRow<T>[] {
	if (inputArray.length < 2) {
		return [] as ObjectRow<T>[];
	}

	const keys = inputArray[0];
	const objectsArray: ObjectRow<T>[] = [];

	for (let i = 1; i < inputArray.length; i += 1) {
		const currentRow = inputArray[i];
		const object: Record<string, any> = {};

		if (currentRow.length !== keys.length) {
			throw new Error('All rows must have the same length as the keys.');
		}

		for (let j = 0; j < keys.length; j += 1) {
			object[keys[j]] = currentRow[j];
		}

		objectsArray.push(object as ObjectRow<T>);
	}

	return objectsArray;
}

export const metricOptions = (results: any[][]) => {
	const types = guessColumnTypes(results);
	const colNames = results?.[0] ?? [];
	const typeObject = colNames?.map((name, i) => ({ name, type: types[i] }));

	const timeCols = typeObject.filter((t) => t.type === 'date');
	const metricCols = typeObject.filter((t) => t.type === 'number');
	const dimensionCols = typeObject.filter((t) => t.type === 'string');

	return {
		timeCols,
		metricCols,
		dimensionCols,
	};
};

/**
 * Parse a string to a float, if possible.
 * @param value
 * @returns [number | string, boolean] - The parsed value, and whether or not it was parsed.
 */
export const parseValueToNumber = (value: string | number) => {
	let test = value;

	if (isNil(test)) {
		return [test, false];
	}

	if (typeof test === 'number') {
		return [test, true];
	}

	if (typeof test === 'string') {
		// Handle `1,234.56` and `1.234,56` formats.
		test = test.replace(/,/g, '');
	}

	const parsed = parseFloat(test);
	return [isNaN(parsed) ? test : parsed, !isNaN(parsed)];
};

export function formatMetricNumber(
	num: number,
	style: MetricNumericFormat,
	minimumFractionDigits: number = 2,
	maximumFractionDigits: number = 2,
	currency: string = 'USD'
) {
	let formatterOptions: Intl.NumberFormatOptions = {};

	switch (style) {
		case 'percent':
			formatterOptions = {
				style: 'percent',
				minimumFractionDigits,
				maximumFractionDigits,
			};
			break;
		case 'currency':
			formatterOptions = {
				style: 'currency',
				currency: currency,
				// we should not define the props below so they won't interfer with the currency standard formatting from ISO 4217
				// minimumFractionDigits,
				// maximumFractionDigits,
			};
			break;
		case 'decimal':
			formatterOptions = {
				style: 'decimal',
				minimumFractionDigits,
				maximumFractionDigits,
			};
			break;
		case 'number':
		default:
			formatterOptions = {
				style: 'decimal',
				minimumFractionDigits,
				maximumFractionDigits,
			};
			break;
	}

	const formatter = new Intl.NumberFormat(undefined, formatterOptions);
	return formatter.format(num);
}

// This function makes sure we have valid columns to display the metric results
export function getValidColumns(
	metric?: IMetric,
	selectedXAxis?: string,
	selectedYAxis?: string,
	selectedDimension?: string
): { xAxis?: string; yAxis?: string; dimension?: string } {
	const columns = metric?.results?.[0] ?? [];

	let xAxis: string | undefined = undefined;
	let yAxis: string | undefined = undefined;
	let dimension: string | undefined = undefined;

	const hasSelectedDimension = !!selectedDimension;

	if (selectedXAxis && columns.includes(selectedXAxis)) {
		xAxis = metric?.time;
	} else if (metric?.time && columns.includes(metric?.time ?? '')) {
		xAxis = metric?.time;
	}

	if (selectedYAxis && columns.includes(selectedYAxis)) {
		yAxis = selectedYAxis;
	} else if (metric?.primary && columns.includes(metric?.primary ?? '')) {
		yAxis = metric?.primary;
	}

	if (hasSelectedDimension && columns.includes(selectedDimension)) {
		dimension = selectedDimension;
	} else if (
		hasSelectedDimension &&
		metric?.dimension &&
		columns.includes(metric?.dimension ?? '')
	) {
		dimension = metric?.dimension;
	}

	if (!xAxis || !yAxis || (hasSelectedDimension && !dimension)) {
		// some columns are missing
		const options = metricOptions(metric?.results ?? []);
		if (!xAxis) {
			xAxis = options.timeCols[0]?.name;
		}
		if (!yAxis) {
			yAxis = options.metricCols[0]?.name;
		}
		if (hasSelectedDimension && !dimension) {
			dimension = options.dimensionCols[0]?.name;
		}
	}

	return {
		xAxis,
		yAxis,
		dimension,
	};
}
