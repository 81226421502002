import * as React from 'react';
import type { EmbedProps as Props } from '.';
import Frame from './components/Frame';

const URL_REGEX = new RegExp('^https://app.hex.tech/(.*)/app/(.*)/(.*)$');

export default class Hex extends React.Component<Props> {
	static ENABLED = [URL_REGEX];

	render() {
		return (
			<Frame
				{...this.props}
				src={`${this.props.attrs.href}`}
				title="Hex Embed"
			/>
		);
	}
}
