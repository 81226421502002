import { Tooltip } from '@mantine/core';
import { EntityType } from '@repo/common/enums/entityType';
import { Text } from '@repo/foundations';
import { truncate } from 'lodash-es';

interface ILineageListItemDetailsProps {
	entityType: EntityType;
	metadata: Record<string, string>;
	length?: number;
}

function LineageListItemDetails({
	entityType,
	metadata,
	length = 30,
}: ILineageListItemDetailsProps) {
	let details = '';

	if (entityType === EntityType.table) {
		details = `${metadata.database} | ${metadata.schema}`;
	} else if ([EntityType.dashboard, EntityType.job].includes(entityType)) {
		details = metadata.group;
	} else if (entityType === EntityType.chart) {
		details = metadata?.dashboard || '';
	} else if (entityType === EntityType.event) {
		details = metadata?.category || '';
	} else if (entityType === EntityType.test) {
		if (!metadata.suite_title) {
			return null;
		}
		details = metadata.suite_title;
	} else {
		return null;
	}

	if (details?.length <= length) {
		return (
			<Text size="xs" color="text/secondary/default">
				{details}
			</Text>
		);
	}

	return (
		<Tooltip label={details} position="top-start" withinPortal>
			<Text size="xs" color="text/secondary/default">
				{truncate(details, { length })}
			</Text>
		</Tooltip>
	);
}

export default LineageListItemDetails;
