import { Flex, Image } from '@mantine/core';
import { Badge, Text } from '@repo/foundations';
import { space } from '@repo/theme/primitives';
import type { IntegrationSpec } from '../../interfaces/IntegrationSpec';

export function IntegrationImage(props: { spec: IntegrationSpec }) {
	const { spec } = props;

	const src =
		spec.type === 'builtin' ? spec.value.src : spec.value.versions[0].icon_url;

	const name =
		spec.type === 'builtin' ? spec.value.name : spec.value.versions[0].name;

	return (
		<Flex align="center" gap={space[5]} mb={space[4]}>
			<Image src={src} height={space[8]} width={space[8]} />
			<Flex display="flex" direction="column">
				<Text>{name}</Text>
			</Flex>
			{spec.type === 'builtin' && spec.value.isBeta && (
				<Badge size="xs" variant="default">
					Beta
				</Badge>
			)}
		</Flex>
	);
}
