import { ActionIcon, Alert, Box, Code, createStyles } from '@mantine/core';
import { useClipboard } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import { Icon, Text } from '@repo/foundations';
import axios from 'axios';
import React from 'react';
import { api } from '../../../network';
import { captureError } from '../../web-tracing';

const useStyles = createStyles((theme) => ({
	container: {
		position: 'relative',
		marginTop: theme.spacing.sm,
		minHeight: '36px',
	},
	title: {
		display: 'flex',
		alignItems: 'center',
		gap: 5,
		marginBottom: theme.spacing.xs,
	},
	copyButton: {
		position: 'absolute',
		right: theme.other.space[5],
		top: theme.other.space[1],
		cursor: 'pointer',
	},
}));

export function DbtDisclaimer() {
	return (
		<Alert
			variant="light"
			title="dbt requirements"
			icon={<Icon name="alertCircleFilled" />}
			color="yellow"
		>
			Please add one of the following integrations before dbt: Snowflake,
			Redshift, BigQuery, Postgres, MySQL, MS SQL, Oracle, or Databricks. The
			dbt integration only adds additional metadata to your existing tables in
			Secoda.
		</Alert>
	);
}

export function GithubDisclaimer() {
	return (
		<Alert
			variant="light"
			title="Github requirements"
			icon={<Icon name="alertCircleFilled" />}
			color="yellow"
		>
			Please add a dbt integration before Github. The Github integration
			requires dbt metadata and Secoda lineage of the dbt models.
		</Alert>
	);
}

export function DemoIntegration() {
	return (
		<Alert
			title="Demo integration"
			icon={<Icon name="alertCircleFilled" />}
			color="yellow"
		>
			Please note that this integration is for demo purposes only. Instead of
			modifying the credentials on this page, you can create a new integration
			and connect to your own data source.
		</Alert>
	);
}

interface IConnectionErrorProps {
	error: string;
}

export function ConnectionError({ error }: IConnectionErrorProps) {
	return (
		<Alert
			title="Connection error"
			icon={<Icon name="alertCircleFilled" />}
			color="red"
		>
			{error}
		</Alert>
	);
}

const useServerIP = () => {
	const [ips, setIps] = React.useState<string[] | null>(null);

	React.useEffect(() => {
		axios
			.get(`${api()}healthcheck/ip/`)
			.then(({ data }) => setIps([`${data.ip.trim()}/32`]))
			.catch((error) => {
				captureError(error);
				// Default to AWS US production IPs
				setIps(['35.175.75.15/32', '3.122.13.89/32', '13.251.200.242/32']);
			});
	}, []);

	return ips;
};

export function Whitelist() {
	const { classes } = useStyles();
	const ips = useServerIP() || [];
	const clipboard = useClipboard();

	const handleCopyClick = () => {
		showNotification({
			title: 'Copied to clipboard',
			message: 'IP addresses copied to clipboard',
			color: 'green',
		});
		clipboard.copy(ips.join('\n'));
	};

	return (
		<Box my={24}>
			<Box className={classes.title}>
				<Icon name="shieldCheck" />
				<Text weight="semibold" size="sm">
					Whitelist
				</Text>
			</Box>
			<Text size="xs">
				Allow inbound traffic from these IPs in your firewall for Secoda to
				connect to your data source. If you are using a reverse tunnel, you do
				not need to whitelist inbound IPs.
			</Text>
			<Code block className={classes.container}>
				<ActionIcon
					onClick={handleCopyClick}
					className={classes.copyButton}
					p={0}
					h={28}
					w={28}
				>
					<Icon name="clipboard" />
				</ActionIcon>
				{ips.map((ip, index) => (
					<React.Fragment key={ip}>
						{ip}
						{index !== ips.length - 1 && <br />}
					</React.Fragment>
				))}
			</Code>
		</Box>
	);
}
