import { Tooltip, UnstyledButton, createStyles } from '@mantine/core';
import { useNavigate } from '@repo/common/hooks/useNavigate';
import type React from 'react';
import { useCallback } from 'react';
import { useAuthUser } from '../../../api';
import { UserAvatar } from '../../UserAvatar';

const useStyles = createStyles((theme) => ({
	userAvatarButton: {
		display: 'flex',
		flex: '0 0 auto',
		alignItems: 'center',
		justifyContent: 'center',
		height: theme.other.space[7],
		width: theme.other.space[7],
		borderRadius: theme.other.borderRadius.sm,
		':hover': {
			backgroundColor: theme.other.getColor('fill/transparent/hover'),
		},
		':active': {
			backgroundColor: theme.other.getColor('fill/transparent/active'),
		},
	},
}));

export function SideBarUserMenu() {
	const { user } = useAuthUser();

	const navigate = useNavigate();
	const { classes } = useStyles();

	const handleProfileClick = useCallback(
		(e: React.MouseEvent<HTMLButtonElement>) => {
			e.stopPropagation();
			navigate(`/user/${user.id}`);
		},
		[navigate, user.id]
	);

	return (
		<Tooltip label="View profile">
			<UnstyledButton
				className={classes.userAvatarButton}
				onClick={handleProfileClick}
				data-testid="sidebar-user-menu-target-button-9d1fa2bd"
			>
				<UserAvatar user={user} size="xs" />
			</UnstyledButton>
		</Tooltip>
	);
}
