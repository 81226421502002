import { Box, Tooltip } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { Button, type ButtonSizes } from '@repo/foundations';
import axios from 'axios';
import { useAuthUser } from '../../api';

export function UpgradeButton(props: {
	tooltip?: string;
	feature?: string;
	size?: ButtonSizes;
}) {
	const { user } = useAuthUser();
	const { tooltip, feature, size = 'sm' } = props;

	const handleUpgradeClick = () => {
		showNotification({
			title: `${feature} upgrade selected`,
			message: 'A Secoda team member will be in contact shortly',
			autoClose: false,
		});
		const text = `${user.email} has clicked the "Upgrade" button for the ${feature} feature.`;
		axios.post(
			`https://webhook-slack-worker.secoda.workers.dev/?channel=${'C01QUB1T8NQ'}&text=${text}`
		);
	};

	const innerRender = (
		<Box style={{ width: 'max-content' }}>
			<Button size={size} onClick={handleUpgradeClick} variant="primary">
				Upgrade
			</Button>
		</Box>
	);

	if (tooltip) {
		return (
			<Tooltip label={tooltip} openDelay={500}>
				{innerRender}
			</Tooltip>
		);
	}
	return innerRender;
}
