import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { useCallback, useState } from 'react';
import { metricsQueryKeyFactory } from '.';
import type { ExecutionStatus } from '../../../components/Sql/types';
import { createMockableHook } from '../../../utils/createMockableHook';
import { useFeatureFlags } from '../../../utils/featureFlags';
import { authHeaders, getEndpoints } from '../../common';

function useMetricRefreshInternal(metricId: string) {
	const { queryBlockScheduling } = useFeatureFlags();
	const [hasRefreshed, setHasRefreshed] = useState(false);

	const { mutateAsync } = useMutation({
		mutationFn: async () => {
			const apiUrl = getEndpoints(metricsQueryKeyFactory.namespace).byPath([
				metricId,
				'refresh',
			]);
			return await axios.post<ExecutionStatus>(apiUrl, {}, authHeaders());
		},
	});

	return useCallback(async () => {
		if (hasRefreshed || !queryBlockScheduling) {
			return null;
		}

		setHasRefreshed(true);

		try {
			return await mutateAsync();
		} catch {
			// ignore error
		}

		return null;
	}, [hasRefreshed, mutateAsync, queryBlockScheduling]);
}

export const [useMetricRefresh, MockUseMetricRefresh] = createMockableHook(
	useMetricRefreshInternal
);
