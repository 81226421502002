import { createStyles } from '@mantine/core';
import {
	NODE_HEADER_HEIGHT,
	NODE_PADDING,
	NODE_ROW_HEIGHT,
	NODE_WIDTH,
} from '../../constants';

export const useStyles = createStyles((theme) => ({
	wrapper: {
		cursor: 'default',
		position: 'relative',
		width: NODE_WIDTH,
		height: '100%',
		padding: NODE_PADDING,
		gap: theme.spacing.xs,
		borderTop: `1px solid ${theme.other.getColor('border/secondary/default')}`,
	},
	accordion: {
		cursor: 'pointer',
		height: NODE_ROW_HEIGHT,
		borderRadius: theme.radius.sm,
		paddingLeft: theme.spacing.xs,
		paddingRight: theme.spacing.xs,
		gap: theme.spacing['3xs'],
		'&:hover': {
			backgroundColor: theme.other.getColor('surface/secondary/hover'),
		},
		'&:active': {
			backgroundColor: theme.other.getColor('surface/secondary/active'),
		},
	},
	scrollableChildren: {
		overflowY: 'auto',
		gap: 0,
	},
	handle: {
		width: '0px !important',
		height: '0px !important',
		backgroundColor: 'transparent !important',
		borderColor: 'transparent !important',
		top: `${
			NODE_HEADER_HEIGHT + NODE_PADDING + NODE_ROW_HEIGHT / 2
		}px !important`,
	},
}));
