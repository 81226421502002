import type {
	EditorDictionary,
	EmbedDescriptor,
	MenuItem,
} from '@repo/secoda-editor';
import { capitalize } from 'lodash-es';
import { findParentNode } from 'prosemirror-utils';
import type { EditorView } from 'prosemirror-view';
import { useCallback, useRef, useState } from 'react';
import type { ISecodaEntity } from '../../../../../../api';
import { captureError } from '../../../../../../web-tracing';
import insertChartBlock from '../../commands/insertChartBlock';
import insertFiles from '../../commands/insertFiles';
import insertQueryBlock from '../../commands/insertQueryBlock';
import type { CommandFactory } from '../../lib/Extension';
import { getEventFiles } from '../../utils/files';
import type { CreateGraphModalResult } from '../ChartBlock/CreateGraphModal';

export interface UseCommandMenuProps {
	view: EditorView;
	commands: Record<string, CommandFactory>;
	dictionary: EditorDictionary;
	onClearSearch: () => void;
	onClose: () => void;
	onLinkToolbarOpen?: () => void;
	onPlaceholderToolbarOpen?: () => void;
	uploadFile?: (file: File) => Promise<string>;
	onFileUploadStart?: () => void;
	onFileUploadStop?: () => void;
	onShowToast?: (message: string, id: string) => void;
	onTrackEvent?: (
		eventName: string,
		properties?: Record<string, string>
	) => void;
}

export function useCommandMenu({
	view,
	commands,
	dictionary,
	onClearSearch,
	onClose,
	onLinkToolbarOpen,
	onPlaceholderToolbarOpen,
	uploadFile,
	onFileUploadStart,
	onFileUploadStop,
	onShowToast,
	onTrackEvent,
}: UseCommandMenuProps) {
	const [embedItem, setEmbedItem] = useState<EmbedDescriptor | null>();
	const [insertChartBlockItem, setInsertChartBlockItem] = useState(false);
	const [insertQueryBlockItem, setInsertQueryBlockItem] = useState(false);

	const fileUploadInputRef = useRef<HTMLInputElement>(null);

	const insertBlock = useCallback(
		(item: MenuItem) => {
			onClearSearch();

			let command = item.name ? commands[item.name] : undefined;

			if (command) {
				command(item.attrs);
			} else {
				const commandName = `create${capitalize(item.name)}`;
				command = commands[commandName];
				if (command) {
					command(item.attrs);
				} else {
					captureError(new Error(`Command not found for ${commandName}`));
				}
			}

			onClose();
		},
		[commands, onClearSearch, onClose]
	);

	function triggerFilePick(accept: string) {
		if (fileUploadInputRef.current) {
			if (accept) {
				fileUploadInputRef.current.accept = accept;
			}
			fileUploadInputRef.current.click();
		}
	}

	async function handleFilePicked(event: React.ChangeEvent<HTMLInputElement>) {
		// Re-focus the editor as it loses focus when file picker is opened on iOS
		view.focus();

		const files = getEventFiles(event);
		const parent = findParentNode((node) => !!node)(view.state.selection);

		onClearSearch();

		if (!uploadFile) {
			throw new Error('uploadFile prop is required to replace files');
		}

		if (parent) {
			await insertFiles(view, event, parent.pos, files, {
				uploadFile,
				onFileUploadStart,
				onFileUploadStop,
				onShowToast,
				dictionary: dictionary,
				isAttachment: fileUploadInputRef.current?.accept === '*',
			});
		}

		if (fileUploadInputRef.current) {
			fileUploadInputRef.current.value = '';
		}

		onClose();
	}

	const handleItemClick = useCallback(
		(item: MenuItem) => {
			onTrackEvent?.('editor/insert-item', {
				itemName: item.name ?? 'unknown',
				itemTitle: item.title ?? '',
				location: window.location.pathname,
			});

			switch (item.name) {
				case 'image':
					triggerFilePick('image/*');
					break;
				case 'attachment':
					triggerFilePick('*');
					break;
				case 'chart_block':
					setInsertChartBlockItem(true);
					break;
				case 'query_block':
					setInsertQueryBlockItem(true);
					break;
				case 'embed':
					setEmbedItem(item as EmbedDescriptor);
					break;
				case 'link': {
					onClearSearch();
					onClose();
					onLinkToolbarOpen?.();
					break;
				}
				case 'placeholder': {
					onClearSearch();
					onClose();
					onPlaceholderToolbarOpen?.();
					break;
				}
				default:
					insertBlock(item);
					onClose();
					break;
			}
		},
		[
			insertBlock,
			onClearSearch,
			onClose,
			onLinkToolbarOpen,
			onPlaceholderToolbarOpen,
			onTrackEvent,
		]
	);

	const handleChartBlockPicked = useCallback(
		(option: ISecodaEntity) => {
			const { state } = view;

			const parent = findParentNode((node) => !!node)(state.selection);

			if (parent) {
				insertChartBlock(view, parent.pos, {
					id: option.id,
				});
			}

			onClose();
		},
		[onClose, view]
	);

	const handleChartBlockV2Picked = useCallback(
		async (result: CreateGraphModalResult) => {
			const { state } = view;

			const parent = findParentNode((node) => !!node)(state.selection);

			if (parent) {
				insertChartBlock(view, parent.pos, {
					id: result.metric.id,
					xAxis: result.xAxis,
					yAxis: result.yAxis,
					dimension: result.dimension,
					numericFormat: result.numericFormat,
				});
			}

			onClose();
		},
		[onClose, view]
	);

	const handleQueryBlockPicked = useCallback(
		(integrationId: string) => {
			const { state } = view;

			const parent = findParentNode((node) => !!node)(state.selection);

			if (parent) {
				insertQueryBlock(view, parent.pos, {
					integrationId,
				});
			}

			onClose();
		},
		[onClose, view]
	);

	return {
		fileUploadInputRef,
		handleFilePicked,
		handleItemClick,
		embedItem,
		insertChartBlockItem,
		insertQueryBlockItem,
		insertBlock,
		handleChartBlockPicked,
		handleQueryBlockPicked,
		handleChartBlockV2Picked,
	};
}
