import { Group, Stack } from '@mantine/core';

import { Button, Text, Title } from '@repo/foundations';
import { observer } from 'mobx-react-lite';
import { GovernanceFilterCard } from './GovernanceFilterCard';
import { GovernanceFilterStoreContext } from './store';
import type { UseGovernanceFiltersProps } from './useGovernanceFilters';
import { useGovernanceFilters } from './useGovernanceFilters';

interface GovernanceFiltersProps extends UseGovernanceFiltersProps {
	title?: string;
	description?: string;
	emptyState?: React.ReactNode;
}

export const GovernanceFilters = observer(
	({
		emptyState,
		title,
		description,
		includedFilters,
		excludedFilters,
		onIncludedFiltersChange,
		onExcludedFiltersChange,
		filterOptions,
	}: GovernanceFiltersProps) => {
		const store = useGovernanceFilters({
			includedFilters,
			excludedFilters,
			onIncludedFiltersChange,
			onExcludedFiltersChange,
			filterOptions,
		});

		return (
			<GovernanceFilterStoreContext.Provider value={store}>
				<Stack spacing="lg">
					<Group position="apart">
						<Stack spacing="4xs">
							<Title order={3} weight="semibold" size="md">
								{title ?? 'Resource filter rules'}
							</Title>
							{description && (
								<Text size="sm" color="text/secondary/default">
									{description}
								</Text>
							)}
						</Stack>
						<Button onClick={store.onAddFilter}>Create rule</Button>
					</Group>

					{store.values.length === 0 && emptyState}
					{store.values.length > 0 && (
						<Stack spacing="md">
							{store.values.map((filter, idx) => (
								<GovernanceFilterCard
									// eslint-disable-next-line react/no-array-index-key
									key={`filter-card-${idx}`}
									filter={filter}
									filterIndex={idx}
								/>
							))}
						</Stack>
					)}
				</Stack>
			</GovernanceFilterStoreContext.Provider>
		);
	}
);
