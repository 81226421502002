import { ActionIcon, createStyles, Flex, UnstyledButton } from '@mantine/core';
import { rem } from '@mantine/styles';
import { Icon, Text } from '@repo/foundations';
import { openSpotlight } from '../../Spotlight';

interface ISearchButtonProps {
	collapsed: boolean;
}

const useStyles = createStyles((theme) => ({
	wrapper: {
		height: theme.other.space[7],
		paddingLeft: theme.other.space[1],
		paddingRight: theme.other.space[1],
		borderRadius: theme.other.borderRadius.sm,
		boxShadow:
			'0px -1px 0px 0px #CCC inset, -1px 0px 0px 0px #DDD inset, 1px 0px 0px 0px #DDD inset, 0px 1px 0px 0px #DDD inset',
		backgroundColor: theme.other.getColor('fill/primary/default'),
		'&:hover': {
			borderColor: theme.other.getColor('border/input/hover'),
			backgroundColor: theme.other.getColor('fill/primary/hover'),
		},
		'&:active': {
			borderColor: theme.other.getColor('border/input/active'),
			backgroundColor: theme.other.getColor('fill/primary/active'),
		},
		transitionProperty: 'box-shadow',
		transitionTimingFunction: 'ease-in-out',
		transitionDuration: '100ms',
	},
	kbd: {
		backgroundColor: theme.other.getColor('fill/secondary/default'),
		padding: `${rem(theme.other.space[0.5])} ${rem(theme.other.space[1])}`,
		borderRadius: theme.other.space[1],
		fontSize: theme.other.typography.text.xs,
		fontWeight: theme.other.typography.weight.regular,
		lineHeight: theme.other.typography.text.md,
		color: theme.other.getColor('text/secondary/default'),
	},
	searchActionIcon: {
		backgroundColor: theme.other.getColor('fill/primary/default'),
		borderColor: theme.other.getColor('border/primary/default'),
		'&:hover': {
			boxShadow: theme.shadows.sm,
		},
	},
}));

export function SearchButton({ collapsed }: ISearchButtonProps) {
	const { classes } = useStyles();

	const handleSearchClick = () => {
		openSpotlight({ type: 'search' });
	};

	if (collapsed) {
		return (
			<ActionIcon
				className={classes.searchActionIcon}
				variant="outline"
				radius="xl"
				size="sm"
				onClick={handleSearchClick}
			>
				<Icon name="search" color="icon/primary/default" />
			</ActionIcon>
		);
	}

	return (
		<UnstyledButton className={classes.wrapper} onClick={handleSearchClick}>
			<Flex align="center" justify="space-between">
				<Flex align="center">
					<Icon name="search" color="icon/primary/default" />
					<Text
						size="sm"
						weight="semibold"
						color="text/primary/default"
						pl="2xs"
					>
						Search
					</Text>
				</Flex>
				<Text className={classes.kbd}>/</Text>
			</Flex>
		</UnstyledButton>
	);
}
