import {
	Center,
	createStyles,
	Divider,
	ScrollArea,
	Skeleton,
	Stack,
} from '@mantine/core';
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { useAuthUser } from '../../api';
import type { DjangoValueType } from '../../pages/TemplatePage/types';
import { trackEvent } from '../../utils/analytics';
import { buildResourceUrl } from '../../utils/navigationUtils';
import type { ButtonDetails } from '../EmptyState';

import { EmptyState } from '../EmptyState';
import EntitySidebarContent from '../EntityPageLayout/EntitySidebarContent';
import type { SidebarEntityKeys } from '../EntityPageLayout/types';
import LoadingTable from '../LoadingTable';
import EntityDrawerActionBar from './components/EntityDrawerActionBar';
import EntityDrawerHeader from './components/EntityDrawerHeader';
import store from './store';
import { getEntityPageSidebarProps } from './utils';

const useStyles = createStyles((theme) => ({
	asideWrapper: {
		width: '100%',
		flexGrow: 1,
		border: 'none',
		padding: 0,
		overflow: 'inherit',
	},
	sidebarWrapper: {
		overflow: 'hidden',
		padding: theme.spacing.md,
		paddingTop: 0,
	},
}));

function EntityDrawerContent() {
	const { classes } = useStyles();
	const { user, workspace } = useAuthUser();

	const update = useCallback(
		(
			key: SidebarEntityKeys,
			value: DjangoValueType,
			saveRemotely?: boolean
		) => {
			trackEvent(
				`${store.entity.entity_type}/${key}/update`,
				{ id: store.entity.id },
				user!,
				workspace
			);
			store.updateEntity(key, value, saveRemotely);
		},
		[user, workspace]
	);

	if (store.loading) {
		return (
			<Stack spacing="xl" w="100%" p="md">
				<Skeleton height={40} width="70%" radius="xs" />
				<LoadingTable />
			</Stack>
		);
	}

	if (store.isError || store.entity === undefined) {
		const buttons: ButtonDetails[] = [
			{
				isPrimary: false,
				name: 'Go back',
				action: store.closeEntityDrawer,
				size: 'md',
			},
		];

		return (
			<Center w="100%">
				<EmptyState
					iconName="lock"
					title="Access Denied"
					description="You are not authorized to view this resource or it does not exist. Contact your workspace admin to get access. If you think this is a mistake contact customer support"
					buttons={buttons}
					includeGoBack={false}
					size="sm"
				/>
			</Center>
		);
	}

	const resourceUrl = buildResourceUrl(store.entity);

	return (
		<Stack w="100%" spacing={0}>
			<EntityDrawerActionBar to={resourceUrl} />
			<Stack className={classes.sidebarWrapper}>
				<EntityDrawerHeader entityType={store.entity.entity_type} />
				<Divider />
				<ScrollArea>
					<Stack className={classes.asideWrapper} spacing={0}>
						<EntitySidebarContent
							entity={store.entity}
							updateEntity={update}
							{...getEntityPageSidebarProps(store.entity.entity_type)}
						/>
					</Stack>
				</ScrollArea>
			</Stack>
		</Stack>
	);
}

export default observer(EntityDrawerContent);
