import { ActionIcon, createStyles, Menu } from '@mantine/core';
import { Icon, Switch } from '@repo/foundations';
import { observer } from 'mobx-react-lite';
import { inboxPageStore } from '../../store';

interface IInboxViewMenuProps {
	hidden: boolean;
}

const useStyles = createStyles({
	dropdown: {
		minHeight: 'fit-content',
	},
});

function InboxViewMenu({ hidden }: IInboxViewMenuProps) {
	const { classes } = useStyles();

	if (hidden) {
		return null;
	}

	return (
		<Menu
			classNames={{ dropdown: classes.dropdown }}
			width={256}
			position="bottom-start"
			closeOnItemClick={false}
			withinPortal
		>
			<Menu.Target>
				<ActionIcon
					size="sm"
					p={4}
					data-testid="inbox-view-menu-button-iXFR9mOBH"
				>
					<Icon name="adjustmentsHorizontal" color="icon/primary/default" />
				</ActionIcon>
			</Menu.Target>
			<Menu.Dropdown>
				<Menu.Item
					onClick={inboxPageStore.toggleShowRead}
					icon={<Icon name="inbox" />}
					rightSection={
						<Switch
							size="sm"
							checked={inboxPageStore.showRead}
							onChange={inboxPageStore.toggleShowRead}
						/>
					}
					data-testid="inbox-view-menu-show-read-switch-jHDjYXvm5q"
				>
					Show read
				</Menu.Item>
			</Menu.Dropdown>
		</Menu>
	);
}

export default observer(InboxViewMenu);
