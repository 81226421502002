import { useMsal } from '@azure/msal-react';
import { useHash } from '@mantine/hooks';
import { noop } from 'lodash-es';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { callbackGoogle, callbackMicrosoft } from './AuthCallback.helpers';

export function AuthCallback() {
	const failureCallback = noop;

	const navigate = useNavigate();

	// Microsoft
	const { accounts } = useMsal();

	// Google
	const [hash] = useHash();
	const params = new URLSearchParams(hash.replace('#', ''));
	const accessToken = params.get('access_token');
	const csrfState = params.get('state');

	// Google
	useEffect(() => {
		if (accessToken && csrfState) {
			callbackGoogle(navigate, accessToken, csrfState, failureCallback);
		}
	}, [accessToken, csrfState, failureCallback, navigate]);

	// Microsoft
	useEffect(() => {
		// The provider handles the access token here.
		// We just need to ensure the provider got us a code
		// with the `accounts` check.
		if (accounts && accounts.length > 0) {
			callbackMicrosoft(navigate, failureCallback);
		}
	}, [accounts, failureCallback, navigate]);

	return null;
}

export default AuthCallback;
