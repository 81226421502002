import { Box, createStyles, SimpleGrid, Stack } from '@mantine/core';
import { useMarketplaceIntegrationSpecList } from '../../api/hooks/marketplace';

import { useNavigate } from '@repo/common/hooks/useNavigate';
import { EmptyState } from '../../components/EmptyState';
import { MarketplaceIntegrationSpecCard } from '../../components/IntegrationSpecCard/MarketplaceIntegrationSpecCard';
import { FullWidthLoadingSpinner } from '../../components/LoadingSpinner';

const useStyles = createStyles((theme) => ({
	grid: {
		rowGap: theme.spacing.xs,
	},
	cardWrapper: {
		paddingBottom: theme.spacing.xs,
	},
}));

export function CreateTab() {
	const { classes } = useStyles();
	const navigate = useNavigate();

	const { data: specs, isLoading } = useMarketplaceIntegrationSpecList({
		options: {
			select: (data) => data.results,
		},
	});

	const createMarketplaceIntegration = () => {
		navigate('/marketplace-integration/new');
	};

	if (!specs || isLoading) {
		return <FullWidthLoadingSpinner />;
	}

	if (!specs.length) {
		return (
			<EmptyState
				stateHeight="60vh"
				illustrationName="integrations"
				title="Create your own integration"
				description="Create your own integration with Secoda’s API and submit it to the marketplace."
				size="lg"
				includeGoBack={false}
				buttons={[
					{
						name: 'Learn more',
						action: () => {
							window.open(
								'https://docs.secoda.co/integrations/custom-integrations-and-marketplace',
								'_blank'
							);
						},
						isPrimary: false,
						size: 'md',
						leftIconName: 'externalLink',
					},
					{
						name: 'Create integration',
						action: createMarketplaceIntegration,
						isPrimary: true,
						size: 'md',
					},
				]}
			/>
		);
	}

	return (
		<Stack>
			<SimpleGrid cols={2} className={classes.grid}>
				{specs.map((spec) => (
					<Box key={spec.id} className={classes.cardWrapper}>
						<MarketplaceIntegrationSpecCard
							spec={spec}
							onClick={() => {
								navigate(`/marketplace-integration/${spec.id}`);
							}}
							showCommunityBadge={false}
							withMenu
						/>
					</Box>
				))}
			</SimpleGrid>
		</Stack>
	);
}
