import { Stack } from '@mantine/core';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router';
import type { ICollection } from '../../api';
import { useAuthUser } from '../../api';
import { useTeamEmptyState } from '../../api/hooks/team/emptyStates';
import type { ButtonDetails } from '../../components/EmptyState';
import { EmptyState } from '../../components/EmptyState';
import { entityModalStore } from '../../components/EntityModal/store';

import type { Filter } from '@repo/api-codegen';
import { EntityType } from '@repo/common/enums/entityType';
import type {
	FetchModelInfiniteListHook,
	FetchModelList,
} from '../../api/factories/types';
import {
	fetchCatalogList,
	useCatalogInfiniteList,
} from '../../api/hooks/resourceCatalog';
import { TableV2 } from '../../components/TableV2';
import type { OnCellClickHandlerParams } from '../../components/TableV2/types';
import { useEntityBreadcrumbTeam } from '../../hooks/useEntityBreadcrumbs';
import { useHomepageTeam } from '../HomePage/hooks/useHomepageTeam';
import { useStyles } from '../TeamCatalogPage/TeamCatalogPage.styles';
import { useActions, useColumns } from './TeamCollectionsPage.hooks';

const COLUMN_VISIBILITY = {
	catalogType: EntityType.document,
	catalogServerType: EntityType.document,
} as const;

const DEFAULT_CATALOG_FILTERS = {
	operands: [
		{
			operator: 'not',
			field: 'collections',
			operands: [],
		},
	],
} as { readonly operands: readonly Filter[] };

const QUICK_ACTIONS = [
	'actions::collections',
	'actions::pii',
	'actions::verified',
	'actions::sidebar',
	'actions::ai',
	'actions::delete',
] as const;

const DEFAULT_SEARCH_PARAMS_NESTING = {
	entity_type: EntityType.collection,
	calculate_children_count: true,
} as const;

const PAGINATION_LIST_OPTIONS = {
	suspense: true,
};

function TeamCollectionsPage() {
	const { teamId } = useParams();
	const { classes } = useStyles();

	const { user, isAdminUser, isViewerOrGuestUser } = useAuthUser();
	const { isTeamEditorUser } = useHomepageTeam();

	const columns = useColumns();
	const actions = useActions();

	const navigate = useNavigate();

	const { setTeamBreadcrumb } = useEntityBreadcrumbTeam();

	useEffect(() => {
		// If the user opens a entity page from this context we want the team
		// breadcrumb to match the team of this page.
		if (teamId) {
			setTeamBreadcrumb(teamId);
		}
	}, [setTeamBreadcrumb, teamId]);

	const handleNewCollection = useCallback(async () => {
		await entityModalStore.quickCreateEntity(
			EntityType.collection,
			'Untitled',
			[teamId ?? ''],
			{
				owners: [user.id],
			},
			navigate
		);
	}, [teamId, user.id, navigate]);

	const emptyState = useTeamEmptyState(teamId as string, 'collection', {
		enabled: !isViewerOrGuestUser,
	});

	const buttons: ButtonDetails[] = [
		{
			name: 'Learn more',
			action: () => {
				window.open('https://docs.secoda.co/features/collections-1', '_blank');
			},
			isPrimary: false,
			size: 'md',
			leftIconName: 'externalLink',
		},
		...(isTeamEditorUser || isAdminUser
			? [
					{
						name: 'Create collection',
						action: handleNewCollection,
						isPrimary: true,
						size: 'md',
					} as ButtonDetails,
				]
			: []),
	];

	const handleCellClick = useCallback(
		({ column, record }: OnCellClickHandlerParams<ICollection>) => {
			if (column.navigate !== false) {
				navigate(`/collections/${record.id}`);
			}
		},
		[navigate]
	);

	const defaultSearchParams = useMemo(
		() => ({
			team_id: teamId,
			entity_type: EntityType.collection,
			calculate_children_count: true,
		}),
		[teamId]
	);

	if (!isViewerOrGuestUser) {
		if (emptyState?.is_empty) {
			return (
				<EmptyState
					illustrationName="collections"
					title="Welcome to Collections"
					description="Curate relevant data and resources in collections for easy sharing and discoverability across your team"
					includeGoBack={false}
					buttons={buttons}
					stateHeight="80vh"
					size="lg"
				/>
			);
		}
	}

	return (
		<Stack className={classes.wrapper}>
			<TableV2<ICollection>
				withCustomProperties={EntityType.collection}
				withQuickActions={QUICK_ACTIONS}
				nestingFilter="collections"
				withCsvExport
				withCheckbox
				withInteractiveHeader
				withSearch
				columns={columns}
				withActions={actions}
				onCellClick={handleCellClick}
				withInfiniteScroll
				fetchPaginationList={
					fetchCatalogList as unknown as FetchModelList<ICollection>
				}
				usePaginationList={
					useCatalogInfiniteList as FetchModelInfiniteListHook<ICollection>
				}
				columnVisibility={COLUMN_VISIBILITY}
				defaultRequiredSearchParams={defaultSearchParams}
				defaultRequiredSearchParamsNesting={DEFAULT_SEARCH_PARAMS_NESTING}
				defaultRequiredCatalogFilters={DEFAULT_CATALOG_FILTERS}
				usePaginationListOptions={PAGINATION_LIST_OPTIONS}
			/>
		</Stack>
	);
}

export default observer(TeamCollectionsPage);
