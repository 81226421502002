/* eslint-disable no-underscore-dangle */
/* eslint-disable default-param-last */
import makeRules from '@repo/secoda-editor/lib/markdown/rules';
import { MarkdownSerializer } from '@repo/secoda-editor/lib/markdown/serializer';
import type { Options, PluginSimple } from 'markdown-it';
import { keymap } from 'prosemirror-keymap';
import type { ParseSpec } from 'prosemirror-markdown';
import { MarkdownParser } from 'prosemirror-markdown';
import type { Attrs, MarkSpec, NodeSpec, Schema } from 'prosemirror-model';
import type { Plugin } from 'prosemirror-state';
import type { EditorView } from 'prosemirror-view';
import type { RichMarkdownEditor as Editor } from '..';
import type Mark from '../marks/Mark';
import type Node from '../nodes/Node';
import type Extension from './Extension';
import type { CommandFactory, ExtensionOptions } from './Extension';

export default class ExtensionManager {
	allExtensions: Array<Node | Mark | Extension> = [];
	extensions: Array<Node | Mark | Extension> = [];
	disabledExtensions: Array<Node | Mark | Extension> = [];
	disableInputExtensions: Array<string> = [];
	paragraphExtension: Node | null = null;

	constructor(
		allExtensions: Array<Extension | Node | Mark> = [],
		disabledExtensions: Array<string> = [],
		disableInputExtensions: Array<string> = [],
		editor?: Editor
	) {
		this.allExtensions = allExtensions;
		this.disableInputExtensions = disableInputExtensions;

		this.allExtensions.forEach((extension) => {
			if (disabledExtensions.includes(extension.name)) {
				this.disabledExtensions.push(extension);
				return;
			}

			if (editor) {
				extension.bindEditor(editor);
			}

			this.extensions.push(extension);
		});

		this.paragraphExtension = this.extensions.find(
			(ext) => ext.name === 'paragraph'
		) as Node;

		if (!this.paragraphExtension) {
			throw new Error(
				'The paragraph extension is required and cannot be disabled'
			);
		}
	}

	get nodes(): Record<string, NodeSpec> {
		const schemaNodes = this.extensions
			.filter((extension) => extension.type === 'node')
			.reduce(
				(nodes, node) => ({
					...nodes,
					[node.name]: (node as Node).schema,
				}),
				{} as Record<string, NodeSpec>
			);

		// make sure all disabled nodes will be replaced by paragraph
		// this ensures that if any markdown document with disabled nodes can still be rendered
		// disabled nodes will be replaced by paragraph with inner text of the node
		// eg. a code block will be rendered as plain string instead of corrupting the document
		this.disabledExtensions
			.filter((extension) => extension.type === 'node')
			.forEach((extension) => {
				schemaNodes[extension.name] = this.paragraphExtension!.schema;
			});

		return schemaNodes;
	}

	serializer() {
		const nodes: Record<string, unknown> = this.extensions
			.filter((extension) => extension.type === 'node')
			.reduce(
				(extensions, extension) => ({
					...extensions,
					[extension.name]: (extension as Node).toMarkdown,
				}),
				{}
			);

		const marks: Record<string, unknown> = this.extensions
			.filter((extension) => extension.type === 'mark')
			.reduce(
				(extensions, extension) => ({
					...extensions,
					[extension.name]: (extension as Mark).toMarkdown,
				}),
				{}
			);

		this.disabledExtensions.forEach((extension) => {
			if (extension.type === 'node') {
				nodes[extension.name] = this.paragraphExtension!.toMarkdown;
			}
		});

		return new MarkdownSerializer(nodes, marks);
	}

	parser({
		schema,
		rules,
		plugins,
	}: {
		schema: Schema;
		rules?: Partial<Options & { disable?: string | string[] }>;
		plugins?: any;
	}): MarkdownParser {
		const tokens: Record<string, ParseSpec> = this.extensions
			.filter(
				(extension) => extension.type === 'mark' || extension.type === 'node'
			)
			.reduce((nodes, extension: Node | Mark | Extension) => {
				if (!('parseMarkdown' in extension)) {
					return nodes;
				}

				const md = extension.parseMarkdown();
				if (!md) {
					return nodes;
				}

				return {
					...nodes,
					[extension.markdownToken || extension.name]: md,
				};
			}, {});

		// we still want to parse the markdown for disabled nodes and marks
		// but we replace their schema by a paragraph
		this.disabledExtensions.forEach((extension) => {
			if (!('parseMarkdown' in extension)) {
				return;
			}

			const md = extension.parseMarkdown();
			if (!md) {
				return;
			}

			tokens[extension.markdownToken || extension.name] = md;
		});

		return new MarkdownParser(
			schema,
			makeRules({
				options: rules,
				plugins,
				disabledExtensions:
					this.disabledExtensions?.map((ext) => ext.name) ?? [],
			}),
			tokens
		);
	}

	get marks(): Record<string, MarkSpec> {
		const schemaMarks = this.extensions
			.filter((extension) => extension.type === 'mark')
			.reduce(
				(marks, mark) => ({
					...marks,
					[mark.name]: (mark as Mark).schema,
				}),
				{} as Record<string, MarkSpec>
			);

		// make sure all disabled marks will be replaced by plain text wrapped in an span
		// this ensures that any markdown document with disabled marks can still be rendered
		this.disabledExtensions
			.filter((extension) => extension.type === 'mark')
			.forEach((extension) => {
				schemaMarks[extension.name] = { toDOM: () => ['span'] };
			});

		return schemaMarks;
	}

	get plugins(): Array<Plugin> {
		return this.extensions
			.filter((extension) => 'plugins' in extension)
			.reduce(
				(allPlugins: Array<Plugin>, { plugins }) => [...allPlugins, ...plugins],
				[]
			);
	}

	get rulePlugins(): Array<PluginSimple> {
		// we enable all markdown rules in the editor, even for disabled nodes and marks
		// this will force the editor to parse the markdown document instead of throwing an error
		return this.allExtensions
			.filter((extension) => 'rulePlugins' in extension)
			.reduce(
				(allRulePlugins, { rulePlugins }) => [
					...allRulePlugins,
					...rulePlugins,
				],
				[] as Array<PluginSimple>
			);
	}

	keymaps({ schema }: ExtensionOptions<undefined>) {
		const keymaps = this.extensions
			.filter((extension) => extension.keys)
			.map((extension) =>
				['node', 'mark'].includes(extension.type)
					? (extension as Node | Mark).keys({
							// @ts-expect-error FIX-ME
							type: schema[`${extension.type}s`][extension.name],
							schema,
						})
					: (extension as Extension).keys({ schema })
			);

		return keymaps.map(keymap);
	}

	inputRules({ schema }: { schema: Schema }) {
		const extensionInputRules = this.extensions
			.filter((extension) => ['extension'].includes(extension.type))
			.filter((extension) => extension.inputRules)
			.filter(
				(extension) => !this.disableInputExtensions.includes(extension.name)
			)
			.map((extension) => (extension as Extension).inputRules({ schema }));

		const nodeMarkInputRules = this.extensions
			.filter((extension) => ['node', 'mark'].includes(extension.type))
			.filter((extension) => extension.inputRules)
			.filter(
				(extension) => !this.disableInputExtensions.includes(extension.name)
			)
			.map((extension) =>
				(extension as Node | Mark).inputRules({
					// @ts-expect-error FIX-ME
					type: schema[`${extension.type}s`][extension.name],
					schema,
				})
			);

		return [...extensionInputRules, ...nodeMarkInputRules].reduce(
			(allInputRules, inputRules) => [...allInputRules, ...inputRules],
			[]
		);
	}

	commands({ schema, view }: { schema: Schema; view: EditorView }) {
		return this.extensions
			.filter((extension) => extension.commands)
			.reduce((allCommands, extension) => {
				const { name, type } = extension;
				const commands = {};

				const value = extension.commands({
					// @ts-expect-error FIX-ME
					schema,
					...(['node', 'mark'].includes(type)
						? {
								// @ts-expect-error FIX-ME
								type: schema[`${type}s`][name],
							}
						: {}),
				});

				const apply = (callback: CommandFactory, attrs: Attrs) => {
					// do not allow viewers to edit, unless it's to add comments
					if (!view.editable && extension.name !== 'comment') {
						return false;
					}
					if (extension.focusAfterExecution) {
						view.focus();
					}

					return callback(attrs)(view.state, view.dispatch, view);
				};

				const handle = (_name: string, _value: CommandFactory) => {
					if (Array.isArray(_value)) {
						// @ts-expect-error FIX-ME
						commands[_name] = (attrs: Attrs) =>
							_value.forEach((callback) => apply(callback, attrs));
					} else if (typeof _value === 'function') {
						// @ts-expect-error FIX-ME
						commands[_name] = (attrs: Attrs) => apply(_value, attrs);
					}
				};

				if (typeof value === 'object') {
					Object.entries(value).forEach(([commandName, commandValue]) => {
						handle(commandName, commandValue);
					});
				} else {
					handle(name, value);
				}

				return {
					...allCommands,
					...commands,
				};
			}, {});
	}
}
