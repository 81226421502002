import { Group, Stack, TextInput } from '@mantine/core';
import { useInputState } from '@mantine/hooks';
import { Button, Text } from '@repo/foundations';

interface IDeleteWithWarningModal {
	text: string;
	onClose: () => void;
	onConfirm: () => Promise<void>;
}

function DeleteWithWarningModal({
	text,
	onClose,
	onConfirm,
}: IDeleteWithWarningModal) {
	const [confirmText, setConfirmText] = useInputState('');

	return (
		<Stack spacing="2xl" pt="md">
			<Stack spacing="sm">
				<Text>{text}</Text>
				<TextInput
					data-testid="confirm-text-input"
					placeholder="I agree"
					label={
						<Text>
							Type <b>&ldquo;I agree&rdquo;</b> to confirm deletion.
						</Text>
					}
					value={confirmText}
					onChange={setConfirmText}
					data-autofocus
				/>
			</Stack>
			<Group spacing="sm" position="right">
				<Button onClick={onClose}>Cancel</Button>
				<Button
					type="submit"
					disabled={confirmText.toLowerCase() !== 'i agree'}
					variant="primary"
					tone="critical"
					onClick={onConfirm}
				>
					Confirm
				</Button>
			</Group>
		</Stack>
	);
}

export default DeleteWithWarningModal;
