import type { EntityType } from '@repo/common/enums/entityType';
import { iconSize, space } from '@repo/theme/primitives';

export const TREE_INDENT = space[3];
export type DocumentTreeNode = {
	id: string;
	name: string;
	entityType: EntityType;
	hasChildren: boolean;
	fetchedChildren: boolean;
	children: DocumentTreeNode[];
	isOpen: boolean;
	isLoading: boolean;
};

export const TOP_NAVBAR_HEIGHT = 52;

export const NAV_SIDEBAR_LOCAL_STORAGE_PROPERTY_PREFIX = 'navigationSidebar';
export const NAV_SIDEBAR_TRIGGER_WIDTH = space[32];
export const COLLAPSED_NAV_SIDEBAR_WIDTH =
	iconSize.md + 2 * space[2] + 2 * space[3];
export const EXPANDED_NAV_MIN_SIDEBAR_WIDTH = space[60];
export const EXPANDED_NAV_MAX_SIDEBAR_WIDTH = space[120];

// The name of this constant doesn't match the value in local storage
// becuase changing it would break what users have set for themselves for
// the sidebar
export const RIGHT_SIDEBAR_LOCAL_STORAGE_PROPERTY_PREFIX = 'entitySidebar';
export const RIGHT_SIDEBAR_WIDTH = space[90];
export const RIGHT_SIDEBAR_MIN_WIDTH = space[90];
export const RIGHT_SIDEBAR_MAX_WIDTH = space[120];
export const SIDEBAR_COLOR = 'transparent';
export const RIGHT_SIDEBAR_TRANSITION_DURATION = '250ms';
export const RIGHT_SIDEBAR_TRANSITION_TIMING_FUNCTION = 'ease-in-out';
