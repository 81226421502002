// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import window from 'global';

export function api() {
	if (import.meta.env.VITE_API_ENDPOINT) {
		return import.meta.env.VITE_API_ENDPOINT;
	}
	return `${window.location.origin}/api/v1/`;
}

export function apiWithoutTrailSlash() {
	const res = api();
	return res.endsWith('/') ? res.slice(0, -1) : res;
}
