import { SimpleGrid, Stack, Tabs, useMantineTheme } from '@mantine/core';
import { Text } from '@repo/foundations';
import { map, pick } from 'lodash-es';
import {
	COVER_COLORS,
	COVER_COLORS_FONT_COLOR_MAPPING,
	COVER_IMAGE_FONT_COLOR_MAPPING,
	COVER_IMAGE_MAPPING,
} from '../constants';
import ColorPaletteChip from './ColorPaletteChip';

export interface IDefaultColorPickerPanelProps {
	onBackgroundImageChange: (backgroundImage: string, fontColor: string) => void;
}

function DefaultColorPickerPanel({
	onBackgroundImageChange,
}: IDefaultColorPickerPanelProps) {
	const theme = useMantineTheme();

	const handleBackgroundImageChange =
		(backgroundImage: string, fontColor: string) => () => {
			onBackgroundImageChange(backgroundImage, fontColor);
		};

	return (
		<Tabs.Panel value="defaults">
			<Stack p="md">
				<Stack spacing="xs">
					<Text transform="uppercase" color="text/secondary/default" size="sm">
						Colors
					</Text>
					<SimpleGrid cols={4} spacing="xs">
						{map(pick(theme.colors, COVER_COLORS), (colors, name) => (
							<ColorPaletteChip
								key={name}
								alt={name}
								backgroundImage={colors[4]}
								onClick={handleBackgroundImageChange(
									colors[4],
									COVER_COLORS_FONT_COLOR_MAPPING[name]
								)}
							/>
						))}
					</SimpleGrid>
				</Stack>
				<Stack spacing="xs">
					<Text transform="uppercase" color="text/secondary/default" size="sm">
						Textures
					</Text>
					<SimpleGrid cols={4} spacing="xs">
						{map(COVER_IMAGE_MAPPING, (image, name) => (
							<ColorPaletteChip
								key={name}
								alt={name}
								thumbnail={`/images/home-bg-covers/${name}`}
								backgroundImage={image}
								onClick={handleBackgroundImageChange(
									image,
									COVER_IMAGE_FONT_COLOR_MAPPING[name]
								)}
							/>
						))}
					</SimpleGrid>
				</Stack>
			</Stack>
		</Tabs.Panel>
	);
}

export default DefaultColorPickerPanel;
