import { useState } from 'react';
import type { Automation } from '../../../../../../api';
import { useAutomationStore } from '../../../../../../pages/AutomationPage/context';
import type { AutomationActionCardOnChangeProps } from '../AutomationActionCardNotificationContent';
import AutomationActionCardNotificationContent from '../AutomationActionCardNotificationContent';

interface AutomationActionCardAnnouncementContentProps {
	handleAutomationUpdate: (automation: Partial<Automation>) => Promise<void>;
}

function AutomationActionCardAnnouncementContent({
	handleAutomationUpdate,
}: AutomationActionCardAnnouncementContentProps) {
	const { automation } = useAutomationStore();

	const [checked, setChecked] = useState<boolean>(
		automation?.action_send_announcement?.send_summary ?? true
	);
	const [subject, setSubject] = useState<string | undefined>(
		automation?.action_send_announcement?.subject
	);
	const [body, setBody] = useState<string | undefined>(
		automation?.action_send_announcement?.body
	);

	const handleOnCheckboxChange = async (newChecked: boolean) => {
		setChecked(newChecked);
		await handleAutomationUpdate({
			action_send_announcement: {
				...automation?.action_send_announcement,
				send_summary: newChecked,
			},
		});
	};

	const onChange = async ({
		subject: newSubject,
		body: newBody,
	}: AutomationActionCardOnChangeProps) => {
		setSubject(newSubject);
		setBody(newBody);

		await handleAutomationUpdate({
			action_send_announcement: {
				...automation?.action_send_announcement,
				subject: newSubject,
				body: newBody,
				send_summary: checked,
			},
		});
	};

	return (
		<AutomationActionCardNotificationContent
			checked={checked}
			initialSubject={subject}
			initialBody={body}
			onCheckboxChange={handleOnCheckboxChange}
			onChange={onChange}
			includeSubject
		/>
	);
}

export default AutomationActionCardAnnouncementContent;
