import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
	actionIcon: {
		'&:disabled': {
			background: 'transparent',
		},
		marginRight: theme.spacing.md,
	},
	list: {
		borderRadius: theme.radius.lg,
		border: `1px solid ${theme.other.getColor('border/primary/default')}`,
	},
}));
