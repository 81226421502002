import type { MantineThemeOverride } from '@mantine/core';

export const Divider: MantineThemeOverride['components'] = {
	Divider: {
		styles: (theme) => ({
			label: { color: theme.other.getColor('border/secondary/default') },
		}),
		defaultProps: (theme) => ({
			color: theme.other.getColor('border/primary/default'),
			labelProps: { color: theme.other.getColor('border/secondary/default') },
		}),
	},
};
