import { showNotification } from '@mantine/notifications';
import { api } from '../../../network';
import { cache } from '../../lib/stores/CacheStore.helpers';
import { resetGrafanaSessionMetadata } from '../../web-tracing';

export const clearCache = async () => {
	try {
		// Clear the model store cache.
		await cache.destroy();
	} catch (e) {
		// eslint-disable-next-line no-console
		console.error('Error clearing cache', e);
	}
};

export const clearTokens = () => {
	// The impersonator token is used to impersonate other users, remove if it exists.
	localStorage.clear();
};

export const isImpersonating = () =>
	document.cookie.includes('is_impersonating');

export const handleLogout = async () => {
	await clearCache();
	clearTokens();
	localStorage.clear();
	resetGrafanaSessionMetadata();

	showNotification({
		withCloseButton: false,
		title: 'Logging out',
		message: 'You will be redirected in a few moments',
	});

	if (isImpersonating()) {
		// Wait some time for the stores to clear.
		setTimeout(
			() =>
				window.open(
					`${api()}auth/utilities/terminate_impersonation_session/`,
					'_self'
				),
			3000
		);
	} else {
		// Wait some time for the stores to clear.
		setTimeout(() => window.open(`${api()}auth/u/logout/`, '_self'), 3000);
	}
};

/*
 * General utils for managing cookies in Typescript.
 */
export function setCookie(name: string, val: string) {
	const date = new Date();
	const value = val;

	// Set it expire in 7 days
	date.setTime(date.getTime() + 7 * 24 * 60 * 60 * 1000);

	// Set it
	document.cookie = `${name}=${value}; expires=${date.toUTCString()}; path=/`;
}

export function getCookie(name: string) {
	const value = `; ${document.cookie}`;
	const parts = value.split(`; ${name}=`);

	if (parts.length === 2) {
		return parts.pop()?.split(';').shift();
	}
	return null;
}

export function deleteCookie(name: string) {
	const date = new Date();

	// Set it expire in -1 days
	date.setTime(date.getTime() + -1 * 24 * 60 * 60 * 1000);

	// Set it
	document.cookie = `${name}=; expires=${date.toUTCString()}; path=/`;
}
