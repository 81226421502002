import { showNotification } from '@mantine/notifications';
import { useMutation } from '@tanstack/react-query';
import { useCallback } from 'react';
import { AIFeedbackModalContent } from '../../../components/AIAssistant/AIFeedbackModal/AIFeedbackModalContent';
import type { AIFeedbackValues } from '../../../components/AIAssistant/AIFeedbackModal/constants';
import { SentimentType } from '../../../components/AIAssistant/AIFeedbackModal/constants';
import { closeAllModals, openModal } from '../../../components/ModalManager';
import { trackEvent } from '../../../utils/analytics';
import { apiClient, getEndpoints } from '../../common';
import { useAuthUser } from '../authUser';
import { useWorkspace } from '../workspace';
import { aiEmbeddedQueryKeyFactory } from './constants';

interface IUseAISessionArgs {
	id: string;
	onNegativeFeedbackSubmitted?: () => void;
}

function getTrackEventProperties(
	promptId: string,
	feedback: 'helpful' | 'unhelpful',
	values: AIFeedbackValues
): Record<string, string> {
	return {
		promptId,
		feedback,
		answerOptions: values.answerOptions.join(', '),
		details: values.details,
	};
}

interface RequestData {
	answer_options: string;
	details: string;
	sentiment: string;
}

export function useAIFeedback({
	id,
	onNegativeFeedbackSubmitted,
}: IUseAISessionArgs) {
	const { user } = useAuthUser();
	const { workspace } = useWorkspace();

	const { mutateAsync: postFeedback } = useMutation<
		unknown,
		unknown,
		RequestData,
		unknown
	>({
		mutationFn: async (data: RequestData) => {
			const url = getEndpoints(aiEmbeddedQueryKeyFactory.namespace).byPath([
				id,
				'feedback',
			]);
			await apiClient.post(url, data);
		},
	});

	const submitFeedback = useCallback(
		(feedback: 'helpful' | 'unhelpful', values: AIFeedbackValues) => {
			trackEvent(
				`ai/feedback/${feedback === 'helpful' ? 'like' : 'dislike'}`,
				getTrackEventProperties(id, feedback, values),
				user,
				workspace
			);
			postFeedback({
				sentiment: values.sentiment,
				answer_options: values.answerOptions.join(', '),
				details: values.details,
			});
			closeAllModals();
			showNotification({
				title: 'Success',
				message: 'Your feedback has been recorded.',
				color: 'green',
			});
			if (feedback === 'unhelpful') {
				onNegativeFeedbackSubmitted?.();
			}
		},
		[id, postFeedback, user, workspace]
	);

	const showPositiveFeedbackModal = useCallback(() => {
		openModal({
			title: 'Share your feedback',
			children: (
				<AIFeedbackModalContent
					sentiment={SentimentType.Positive}
					onCancel={() => closeAllModals()}
					onSubmit={(values: AIFeedbackValues) =>
						submitFeedback('helpful', values)
					}
				/>
			),
		});
	}, [submitFeedback]);

	const showNegativeFeedbackModal = useCallback(() => {
		openModal({
			title: 'Share your feedback',
			children: (
				<AIFeedbackModalContent
					sentiment={SentimentType.Negative}
					onCancel={() => closeAllModals()}
					onSubmit={(values: AIFeedbackValues) =>
						submitFeedback('unhelpful', values)
					}
					isAnswerOptionsRequired
				/>
			),
		});
	}, [submitFeedback]);

	return {
		showPositiveFeedbackModal,
		showNegativeFeedbackModal,
	};
}
