import type { DOMOutputSpec, Node as ProsemirrorNode } from 'prosemirror-model';

import type { SecodaEditorComponentProps } from '@repo/secoda-editor';
import type { MarkdownSerializerState } from '@repo/secoda-editor/lib/markdown/serializer';
import { Plugin, type EditorState } from 'prosemirror-state';
import { findBlockNodes } from 'prosemirror-utils';
import { Decoration, DecorationSet } from 'prosemirror-view';
import type { ReactElement } from 'react';
import { TableOfContentsTree } from '../components/TableOfContentsTree';
import { getHeadings } from '../lib/getHeadings';
import type { TreeNode } from '../lib/headingsToTree';
import { headingsToTree } from '../lib/headingsToTree';
import tableOfContents from '../rules/tableOfContents';
import type { Dispatch } from '../types';
import type { NodeOptions } from './Node';
import ReactNode from './ReactNode';

export default class TableOfContents extends ReactNode {
	private plugin: Plugin | null = null;

	get name() {
		return 'toc';
	}

	get markdownToken() {
		return 'toc';
	}

	get schema() {
		return {
			attrs: {},
			group: 'block',
			atom: true,
			draggable: true,
			parseDOM: [
				{
					tag: 'div.toc',
				},
			],
			toDOM: (node: ProsemirrorNode): DOMOutputSpec => [
				'div',
				{
					...node.attrs,
					class: 'toc',
				},
				'table of contents',
			],
		};
	}

	toMarkdown(state: MarkdownSerializerState) {
		state.ensureNewLine();
		state.write('\n\n[toc]\n\n');
		state.ensureNewLine();
	}

	parseMarkdown() {
		return {
			block: 'toc',
		};
	}

	get rulePlugins() {
		return [tableOfContents];
	}

	commands({ type }: NodeOptions) {
		return (attrs: Record<string, unknown> | undefined) =>
			(state: EditorState, dispatch: Dispatch) => {
				dispatch(state.tr.insert(state.selection.from, type.create(attrs)));
				return true;
			};
	}

	get plugins() {
		function getDecorations(doc: ProsemirrorNode) {
			const headingsTree = headingsToTree(getHeadings(doc));
			const decorations = findBlockNodes(doc)
				.filter((node) => node.node.type.name === 'toc')
				.map((node) =>
					Decoration.node(node.pos, node.pos + node.node.nodeSize, {}, {
						headings: headingsTree,
					} as never)
				);

			return DecorationSet.create(doc, decorations);
		}

		this.plugin = new Plugin({
			state: {
				init: (config, state) => getDecorations(state.doc),
				apply: (tr, oldState) =>
					tr.docChanged ? getDecorations(tr.doc) : oldState,
			},
			props: {
				decorations: (state) => this.plugin?.getState(state),
			},
		});

		return [this.plugin];
	}

	component = ({
		view,
		isSelected,
	}: SecodaEditorComponentProps): ReactElement => {
		let items: TreeNode[] = [];

		if (this.plugin && view) {
			// get tree from plugin state
			items =
				this.plugin.getState(view.state)?.local?.[0]?.type?.spec?.headings ??
				[];
		}

		return (
			<TableOfContentsTree
				items={items}
				isSelected={isSelected}
				scrollToAnchor={this.options.scrollToAnchor}
			/>
		);
	};
}
