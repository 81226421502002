import { Center, createStyles, Image, Menu } from '@mantine/core';
import { Button, Text } from '@repo/foundations';
import { useHomepageTeam } from '../../../../../hooks/useHomepageTeam';
import { WIDGET_CONTENT_HEIGHT } from '../../../constants';
import type { IDictionaryTermSelectorDropdownProps } from './DictionaryTermSelectorDropdown';
import DictionaryTermSelectorDropdown from './DictionaryTermSelectorDropdown';

interface IStyleParams {
	color?: string;
}

interface IEmptyChartWidgetProps
	extends IStyleParams,
		IDictionaryTermSelectorDropdownProps {}

const useStyles = createStyles((theme, { color }: IStyleParams) => ({
	emptyState: {
		height: WIDGET_CONTENT_HEIGHT,
		display: 'flex',
		flexDirection: 'column',
		gap: theme.spacing.md,
	},
	image: {
		filter: color === 'dark' ? 'brightness(0.5) grayscale(1)' : 'none',
	},
	text: {
		color: color === 'dark' ? theme.colors.gray[5] : theme.colors.gray[6],
	},
}));

function EmptyChartWidget({
	color,
	onDictionaryTermChange,
}: IEmptyChartWidgetProps) {
	const { classes } = useStyles({ color });
	const { isTeamViewerUser } = useHomepageTeam();

	return (
		<Center className={classes.emptyState}>
			<Image
				className={classes.image}
				width={74}
				src="/images/empty-state/chart-widget.svg"
			/>
			<Text className={classes.text} align="center" size="xs">
				Choose a dictionary term to display a chart
			</Text>
			<Menu
				disabled={isTeamViewerUser}
				position="right"
				withinPortal
				width={256}
			>
				<Menu.Target>
					<Button disabled={isTeamViewerUser}>Choose dictionary term</Button>
				</Menu.Target>
				<DictionaryTermSelectorDropdown
					onDictionaryTermChange={onDictionaryTermChange}
				/>
			</Menu>
		</Center>
	);
}

export default EmptyChartWidget;
