import { baseQueryHooksFactory } from '../../factories';
import type { IAgentScratchPad } from '../../types';
import { agentScratchpadQueryKeyFactory } from './constants';

const { useAgentScratchpad } = baseQueryHooksFactory<
	IAgentScratchPad,
	'agentScratchpad'
>('agentScratchpad', agentScratchpadQueryKeyFactory);

export { useAgentScratchpad };
