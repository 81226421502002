import type { ButtonProps } from '@repo/foundations';
import { Button } from '@repo/foundations';
import { useCallback, useState } from 'react';
import type { IMetric } from '../../../../../../api';
import { useMetricDownload } from '../../../../../../api/hooks/metric/useMetricDownload';
import { captureError } from '../../../../../../web-tracing';

interface ExportCsvFileButtonProps extends Omit<ButtonProps, 'children'> {
	metric: IMetric;
}

export function ExportCsvFileButton({
	metric,
	disabled,
	...buttonProps
}: ExportCsvFileButtonProps) {
	const [isLoading, setIsLoading] = useState(false);
	const { download } = useMetricDownload(metric);

	const handleDownload = useCallback(async () => {
		setIsLoading(true);
		try {
			await download();
		} catch (e) {
			captureError(e);
		} finally {
			setIsLoading(false);
		}
	}, [download]);

	return (
		<Button
			{...buttonProps}
			size="sm"
			variant="tertiary"
			leftIconName="download"
			onClick={handleDownload}
			disabled={isLoading || disabled}
			rightIconName={isLoading ? 'loader' : undefined}
			rightIconAnimation="spin"
		>
			Export CSV file
		</Button>
	);
}
