// Cloned and modified from @tanstack/react-query-devtools/src/devtools.tsx
import { Portal } from '@mantine/core';
import { useLocalStorage } from '@mantine/hooks';
import { ReactQueryDevtoolsPanel } from '@tanstack/react-query-devtools';
import type { SidePanelStyleOptions } from '@tanstack/react-query-devtools/build/lib/utils';
import { useEffect, useRef, useState } from 'react';
import type { DevPanelItemProps } from './DevPanel';

const minPanelSize = 70;
const defaultPanelSize = 500;

const defaultTheme = {
	background: '#0b1521',
	backgroundAlt: '#132337',
	foreground: 'white',
	gray: '#3f4e60',
	grayAlt: '#222e3e',
	inputBackgroundColor: '#fff',
	inputTextColor: '#000',
	success: '#00ab52',
	danger: '#ff0085',
	active: '#006bff',
	paused: '#8c49eb',
	warning: '#ffb200',
} as const;

type Side = 'left' | 'right' | 'top' | 'bottom';
const sides: Record<Side, Side> = {
	top: 'bottom',
	bottom: 'top',
	left: 'right',
	right: 'left',
};

function getSidedProp<T extends string>(prop: T, side: Side) {
	return `${prop}${
		side.charAt(0).toUpperCase() + side.slice(1)
	}` as `${T}${Capitalize<Side>}`;
}

function getOppositeSide(side: Side): Side {
	return sides[side];
}

function isVerticalSide(side: Side) {
	return ['left', 'right'].includes(side);
}

function getSidePanelStyle({
	position = 'bottom',
	height,
	width,
	devtoolsTheme,
	isOpen,
	isResizing,
	panelStyle,
}: SidePanelStyleOptions): React.CSSProperties {
	const oppositeSide = getOppositeSide(position);
	const borderSide = getSidedProp('border', oppositeSide);
	const isVertical = isVerticalSide(position);

	return {
		...panelStyle,
		direction: 'ltr',
		position: 'fixed',
		[position]: 0,
		[borderSide]: `1px solid ${devtoolsTheme.gray}`,
		transformOrigin: oppositeSide,
		boxShadow: '0 0 20px rgba(0,0,0,.3)',
		zIndex: 99999,
		// visibility will be toggled after transitions, but set initial state here
		visibility: isOpen ? 'visible' : 'hidden',
		...(isResizing
			? {
					transition: 'none',
				}
			: { transition: 'all .2s ease' }),
		...(isOpen
			? {
					opacity: 1,
					pointerEvents: 'all',
					transform: isVertical
						? 'translateX(0) scale(1)'
						: 'translateY(0) scale(1)',
				}
			: {
					opacity: 0,
					pointerEvents: 'none',
					transform: isVertical
						? 'translateX(15px) scale(1.02)'
						: 'translateY(15px) scale(1.02)',
				}),
		...(isVertical
			? {
					top: 0,
					height: '100vh',
					maxWidth: '90%',
					width:
						typeof width === 'number' && width >= minPanelSize
							? width
							: defaultPanelSize,
				}
			: {
					left: 0,
					width: '100%',
					maxHeight: '90%',
					height:
						typeof height === 'number' && height >= minPanelSize
							? height
							: defaultPanelSize,
				}),
	};
}

export function CustomReactQueryDevtools({ close }: DevPanelItemProps) {
	const rootRef = useRef<HTMLDivElement>(null);
	const panelRef = useRef<HTMLDivElement>(null);

	const [panelPosition = 'bottom', setPanelPosition] = useLocalStorage<Side>({
		key: 'reactQueryDevtoolsPanelPosition',
		defaultValue: 'bottom',
	});

	const [devtoolsHeight, setDevtoolsHeight] = useLocalStorage<number>({
		key: 'reactQueryDevtoolsHeight',
		defaultValue: defaultPanelSize,
	});
	const [devtoolsWidth, setDevtoolsWidth] = useLocalStorage<number>({
		key: 'reactQueryDevtoolsWidth',
		defaultValue: defaultPanelSize,
	});

	const [isOpen, setIsOpen] = useState(true);
	const [isResizing, setIsResizing] = useState(false);

	const handleClose = () => {
		setIsOpen(false);
		close();
	};

	const handleDragStart = (
		panelElement: HTMLDivElement | null,
		startEvent: React.MouseEvent<HTMLDivElement, MouseEvent>
	) => {
		if (!panelElement) return;
		if (startEvent.button !== 0) return; // Only allow left click for drag
		const isVertical = isVerticalSide(panelPosition);
		setIsResizing(true);

		const { height, width } = panelElement.getBoundingClientRect();
		const startX = startEvent.clientX;
		const startY = startEvent.clientY;
		let newSize = 0;

		const run = (moveEvent: MouseEvent) => {
			// prevent mouse selecting stuff with mouse drag
			moveEvent.preventDefault();

			// calculate the correct size based on mouse position and current panel position
			// hint: it is different formula for the opposite sides
			if (isVertical) {
				newSize =
					width +
					(panelPosition === 'right'
						? startX - moveEvent.clientX
						: moveEvent.clientX - startX);
				setDevtoolsWidth(newSize);
			} else {
				newSize =
					height +
					(panelPosition === 'bottom'
						? startY - moveEvent.clientY
						: moveEvent.clientY - startY);
				setDevtoolsHeight(newSize);
			}

			if (newSize < minPanelSize) {
				setIsOpen(false);
			} else {
				setIsOpen(true);
			}
		};

		const unsub = () => {
			if (isResizing) {
				setIsResizing(false);
			}

			document.removeEventListener('mousemove', run, false);
			document.removeEventListener('mouseUp', unsub, false);
		};

		document.addEventListener('mousemove', run, false);
		document.addEventListener('mouseup', unsub, false);
	};

	useEffect(() => {
		if (isOpen && rootRef.current?.parentElement) {
			const { parentElement } = rootRef.current;
			// const styleProp = getSidedProp('padding', panelPosition);
			const styleProp = 'paddingTop';
			const isVertical = isVerticalSide(panelPosition);

			const previousPaddings = (({
				padding,
				paddingTop,
				paddingBottom,
				paddingLeft,
				paddingRight,
			}) => ({
				padding,
				paddingTop,
				paddingBottom,
				paddingLeft,
				paddingRight,
			}))(parentElement.style);

			const run = () => {
				// reset the padding
				parentElement.style.padding = '0px';
				parentElement.style.paddingTop = '0px';
				parentElement.style.paddingBottom = '0px';
				parentElement.style.paddingLeft = '0px';
				parentElement.style.paddingRight = '0px';
				// set the new padding based on the new panel position

				parentElement.style[styleProp] = `${
					isVertical ? devtoolsWidth : devtoolsHeight
				}px`;
			};

			run();

			if (typeof window !== 'undefined') {
				window.addEventListener('resize', run);

				return () => {
					window.removeEventListener('resize', run);
					Object.entries(previousPaddings).forEach(
						([property, previousValue]) => {
							parentElement.style[property as keyof typeof previousPaddings] =
								previousValue;
						}
					);
				};
			}
		}
	}, [isOpen, panelPosition, devtoolsHeight, devtoolsWidth]);

	const style = getSidePanelStyle({
		position: panelPosition,
		devtoolsTheme: defaultTheme,
		isOpen,
		height: devtoolsHeight,
		width: devtoolsWidth,
		isResizing,
	});

	return (
		<Portal>
			<aside ref={rootRef}>
				<ReactQueryDevtoolsPanel
					ref={panelRef}
					position={panelPosition}
					onPositionChange={setPanelPosition}
					showCloseButton
					style={style}
					isOpen={isOpen}
					setIsOpen={handleClose}
					onDragStart={(e) => handleDragStart(panelRef.current, e)}
				/>
			</aside>
		</Portal>
	);
}
