import type { MantineThemeOverride } from '@mantine/core';

export const DatePicker: MantineThemeOverride['components'] = {
	DatePicker: {
		styles: (theme) => ({
			day: {
				'&[data-in-range]': {
					backgroundColor: theme.other.getColor('fill/primary/selected'),
					'&:hover': {
						backgroundColor: theme.other.getColor('fill/primary/hover'),
					},
				},
				'&[data-selected]': {
					backgroundColor: theme.other.getColor('fill/brand/default'),
					color: theme.other.getColor('text/inverse/default'),

					'&:hover': {
						backgroundColor: theme.other.getColor('fill/brand/hover'),
					},
				},
				'&[data-weekend]': {
					color: theme.other.getColor('text/primary/default'),

					'&[data-selected]': {
						color: theme.other.getColor('text/inverse/default'),
					},
				},
			},
		}),
	},
};
