// NOTE: ideally we use an Anchor in this context rather than a Mantine `Button`.
// eslint-disable-next-line no-restricted-imports
import { Button, Group, Stack, TextInput } from '@mantine/core';
import type { UseFormReturnType } from '@mantine/form';
import { randomId } from '@mantine/hooks';
import { Icon, IconButton, Text, Title } from '@repo/foundations';
import { useSpecVersionFormStyles } from './MarketplaceIntegrationSpecVersionForm.helpers';
import type { MarketplaceIntegrationSpecVersionFormValues } from './MarketplaceIntegrationSpecVersionForm.hook';

export function AllowedEndpointsSection({
	form,
	disabled,
}: {
	form: UseFormReturnType<MarketplaceIntegrationSpecVersionFormValues>;
	disabled: boolean;
}) {
	const { classes } = useSpecVersionFormStyles();
	return (
		<>
			<Stack spacing="xs">
				<Title size="lg">Add allowed endpoints</Title>
				<Text size="sm" color="text/secondary/default">
					{`Please list out all of the endpoints called in your code. Secoda will use this list to verify your HTTP requests.
						You can use wildcard or placeholder. Here are some valid examples:
						`}
					<br />
					• https://api.cluvio.com
					<br />
					• http://*.bloomberg.com/api/v1
					<br />• {'https://{host}'} - where &quot;host&quot; is provided in the
					connection form
				</Text>
			</Stack>
			<Stack spacing="md">
				{form.values.allowed_endpoints.map((endpoint, index) => (
					<Stack spacing="xs" key={endpoint.key}>
						<Text weight="semibold">URL</Text>
						<Group align="start">
							<TextInput
								disabled={disabled}
								sx={{
									flexGrow: 1,
								}}
								classNames={{
									input: classes.input,
									root: classes.inputRoot,
								}}
								name={`allowed_endpoints.${index}.value`}
								{...form.getInputProps(`allowed_endpoints.${index}.value`)}
							/>
							{!disabled && (
								<IconButton
									onClick={() =>
										form.removeListItem('allowed_endpoints', index)
									}
									variant="tertiary"
									iconName="trash"
								/>
							)}
						</Group>
					</Stack>
				))}
				{!disabled && (
					<Group>
						<Button
							p="xs"
							pl={0}
							variant="anchor"
							color="blue"
							leftIcon={<Icon name="plus" color="text/emphasis/default" />}
							onClick={() => {
								form.insertListItem('allowed_endpoints', {
									key: randomId(),
									value: '',
								});
							}}
						>
							<Text size="sm" color="text/emphasis/default" weight="bold">
								Add endpoint
							</Text>
						</Button>
					</Group>
				)}
			</Stack>
		</>
	);
}
