import type { MarkdownSerializerState } from '@repo/secoda-editor/lib/markdown/serializer';
import { chainCommands } from 'prosemirror-commands';
import type {
	Attrs,
	NodeSpec,
	Node as ProsemirrorNode,
} from 'prosemirror-model';
import {
	addColumnAfter,
	addRowAfter,
	columnResizing,
	deleteColumn,
	deleteRow,
	deleteTable,
	goToNextCell,
	tableEditing,
	toggleHeaderCell,
	toggleHeaderColumn,
	toggleHeaderRow,
} from 'prosemirror-tables';
import {
	addColumnBefore,
	addRowAndMoveSelection,
	addRowBefore,
	createTable,
	setColumnAttr,
} from '../commands/table';
import tablesRule from '../rules/tables';
import Node from './Node';
import { TableView } from './TableView';

export default class Table extends Node {
	get name() {
		return 'table';
	}

	get schema(): NodeSpec {
		return {
			content: 'tr+',
			tableRole: 'table',
			isolating: true,
			group: 'block',
			parseDOM: [{ tag: 'table' }],
			toDOM() {
				return [
					'div',
					{ class: 'scrollable-wrapper' },
					['div', { class: 'scrollable' }, ['table', {}, ['tbody', 0]]],
				];
			},
		};
	}

	get rulePlugins() {
		return [tablesRule];
	}

	commands() {
		return {
			createTable: (attrs?: Attrs) =>
				createTable({
					rowsCount: attrs?.rowsCount ?? 0,
					colsCount: attrs?.colsCount ?? 0,
				}),
			setColumnAttr: (attrs?: Attrs) =>
				setColumnAttr({ index: attrs?.index, alignment: attrs?.alignment }),
			addColumnBefore: (attrs?: Attrs) =>
				addColumnBefore({ index: attrs?.index }),
			addColumnAfter: () => addColumnAfter,
			deleteColumn: () => deleteColumn,
			addRowBefore: (attrs?: Attrs) => addRowBefore({ index: attrs?.index }),
			addRowAfter: () => addRowAfter,
			deleteRow: () => deleteRow,
			deleteTable: () => deleteTable,
			toggleHeaderColumn: () => toggleHeaderColumn,
			toggleHeaderRow: () => toggleHeaderRow,
			toggleHeaderCell: () => toggleHeaderCell,
		};
	}

	keys() {
		return {
			Tab: chainCommands(goToNextCell(1), addRowAndMoveSelection()),
			'Shift-Tab': goToNextCell(-1),
			Enter: addRowAndMoveSelection(),
		};
	}

	toMarkdown(state: MarkdownSerializerState, node: ProsemirrorNode) {
		state.renderTable(node);
		state.closeBlock(node);
	}

	parseMarkdown() {
		return { block: 'table' };
	}

	get plugins() {
		return [
			columnResizing({
				View: TableView,
				lastColumnResizable: false,
				cellMinWidth: 100,
			}),
			// tableEditing must be last because it subscribe to a more broad set of events and will prevent other plugins from handling them
			tableEditing(),
		];
	}
}
