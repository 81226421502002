import { Group, createStyles } from '@mantine/core';
import { IconButton } from '@repo/foundations';
import { colors } from '@repo/theme/primitives';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { type Automation } from '../../../../../../api';

import type { AutomationReceiver } from '../../../../../../api/types/models/automation/automationReceiver';
import { useAutomationStore } from '../../../../../../pages/AutomationPage/context';
import { useAnnouncementGlobalRecipientsList } from '../../../../../Announcement/AnnouncementGlobalRecipients.helpers';
import { AutomationActionCardType } from '../../../../constants';
import type { MultiSelectedItem } from '../../../AutomationCardButton';
import AutomationCardButton from '../../../AutomationCardButton';
import { AutomationValueType } from '../../../constants';

const useStyles = createStyles((theme) => ({
	root: {
		justifyContent: 'space-between',
		width: '100%',
	},
	group: {
		alignSelf: 'stretch',
		background: colors.gray[1],
		width: '100%',
		gap: theme.spacing['2xs'],
	},
}));

interface AutomationActionCardAnnouncementHeaderTextProps {
	cardId: string;
	handleAutomationUpdate: (automation: Partial<Automation>) => Promise<void>;
}

function AutomationActionCardAnnouncementHeaderText({
	cardId,
	handleAutomationUpdate,
}: AutomationActionCardAnnouncementHeaderTextProps) {
	const { classes } = useStyles();
	const { options } = useAnnouncementGlobalRecipientsList();
	const buttonOptions = useMemo(() => {
		const filteredOptions = options
			.filter((option) => option.type !== 'channel')
			.map((option) => ({
				label: option.label,
				value: option.value,
				type: option.type,
				group: option.group,
			}));

		filteredOptions.push(
			{
				label: 'Resource owners',
				value: 'owners',
				type: 'owners',
				group: 'Resource recipients',
			},
			{
				label: 'Resource subscribers',
				value: 'subscribers',
				type: 'subscribers',
				group: 'Resource recipients',
			}
		);

		return filteredOptions;
	}, [options]);

	const automationStore = useAutomationStore();
	const { automation } = automationStore;

	const selectedValues = useMemo(() => {
		const values: string[] = [];

		automation?.action_send_announcement?.users?.forEach((user) => {
			values.push(user.id);
		});

		automation?.action_send_announcement?.user_groups?.forEach((group) => {
			values.push(group.id);
		});

		if (automation?.action_send_announcement?.owners) {
			values.push('owners');
		}

		if (automation?.action_send_announcement?.subscribers) {
			values.push('subscribers');
		}

		return values;
	}, [automation]);

	const handleOnClick = async (items: MultiSelectedItem[]) => {
		const users: AutomationReceiver[] = [];
		const userGroups: AutomationReceiver[] = [];

		let owners: boolean | undefined = undefined;
		let subscribers: boolean | undefined = undefined;

		items.forEach((item) => {
			if (item.type === 'user' && typeof item.value === 'string') {
				users.push({
					id: item.value,
					display: item.label,
				});
			} else if (item.type === 'group' && typeof item.value === 'string') {
				userGroups.push({
					id: item.value,
					display: item.label,
				});
			} else if (item.type === 'owners') {
				owners = true;
			} else if (item.type === 'subscribers') {
				subscribers = true;
			}
		});

		await handleAutomationUpdate({
			action_send_announcement: {
				...automation?.action_send_announcement,
				user_groups: userGroups,
				users,
				owners,
				subscribers,
			},
		});
	};

	const handleDelete = async () => {
		await handleAutomationUpdate?.({
			action_send_announcement: null,
		});

		automationStore.removeCard(
			AutomationActionCardType.SEND_ANNOUNCEMENT,
			cardId
		);
	};

	return (
		<Group className={classes.root} noWrap>
			<Group className={classes.group} noWrap>
				Send announcement to
				<AutomationCardButton
					buttonLabel="recipients"
					buttonOptions={buttonOptions}
					type={AutomationValueType.MULTI_SELECT_DROPDOWN}
					handleMultiSelectorChange={handleOnClick}
					property="recipients"
					placeholder="Select recipients"
					selectedValues={selectedValues}
				/>
			</Group>
			<IconButton variant="tertiary" onClick={handleDelete} iconName="x" />
		</Group>
	);
}

export default observer(AutomationActionCardAnnouncementHeaderText);
