import {
	Box,
	Group,
	// eslint-disable-next-line no-restricted-imports
	Breadcrumbs as MantineBreadcrumbs,
	Menu,
	Stack,
} from '@mantine/core';
import { useElementSize } from '@mantine/hooks';
import { useLayoutEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../Buttons';
import { Text } from '../Text';
import { useStyles } from './Breadcrumbs.styles';

export interface BreadcrumbCrumb {
	title: string;
	href: string;
	icon?: string;
	onClick?: () => void;
}

interface BreadcrumbProps {
	crumb: BreadcrumbCrumb;
	onClick?: () => void;
}

function Breadcrumb({ crumb, onClick }: BreadcrumbProps) {
	const { classes } = useStyles();
	return (
		<Button
			size="md"
			className={classes.button}
			classNames={{ inner: classes.buttonInner }}
			component={Link}
			to={crumb.href}
			onClick={onClick}
			variant="tertiary"
		>
			{crumb.icon ? (
				<Group spacing="xs" noWrap>
					<span>{crumb.icon}</span>
					<span>{crumb.title}</span>
				</Group>
			) : (
				<>{crumb.title}</>
			)}
		</Button>
	);
}

interface TruncatedBreadcrumbsProps {
	crumbs: Array<BreadcrumbCrumb>;
	onBreadcrumbClick?: () => void;
}

function TruncatedBreadcrumbs({
	crumbs,
	onBreadcrumbClick,
}: TruncatedBreadcrumbsProps) {
	const { classes } = useStyles();

	return (
		<Menu position="bottom">
			<Menu.Target>
				<Button size="md" className={classes.button} variant="tertiary">
					...
				</Button>
			</Menu.Target>
			<Menu.Dropdown>
				<Stack spacing={0}>
					{crumbs.map((crumb, index) => (
						<Breadcrumb
							key={`${crumb.title}-${index}`}
							crumb={crumb}
							onClick={onBreadcrumbClick}
						/>
					))}
				</Stack>
			</Menu.Dropdown>
		</Menu>
	);
}

interface BreadcrumbsProps {
	crumbs: Array<BreadcrumbCrumb>;
	'data-testid'?: string;
	onBreadcrumbClick?: () => void;
}

export function Breadcrumbs({
	crumbs,
	'data-testid': dataTestID,
	onBreadcrumbClick,
}: BreadcrumbsProps) {
	const { classes } = useStyles();

	if (crumbs.length === 0) {
		return null;
	}

	const breadCrumbLabels = crumbs.map((curr) => curr.title).join('|||');
	const breadCrumbLinks = crumbs.map((curr) => curr.href).join('|||');

	const { ref: breadcrumbRef, width: breadcrumbWidth } = useElementSize();
	const { ref: containerRef, width: containerWidth } = useElementSize();
	const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

	useLayoutEffect(() => {
		// DOM updates can be out of sync and containerWidth can be 0 for a frame, causing collapsed state
		if (breadcrumbWidth === 0 || containerWidth === 0) {
			return;
		}
		if (breadcrumbWidth > containerWidth) {
			setIsCollapsed(true);
		} else {
			setIsCollapsed(false);
		}
	}, [breadcrumbWidth, containerWidth]);

	return (
		<Box className={classes.container} ref={containerRef}>
			{/* Render a hidden breadcrumb that is not collapsed and use this to measure if width is overflowing.
			Can't use the visible breadcrumb because it will cause infinite re-renders when size changes */}
			<MantineBreadcrumbs
				ref={breadcrumbRef}
				classNames={{
					root: classes.root,
					separator: classes.separator,
				}}
				className={classes.breadcrumbHidden}
			>
				{crumbs.map((crumb, index) => (
					<Breadcrumb key={`${crumb.title}-hidden-${index}`} crumb={crumb} />
				))}
			</MantineBreadcrumbs>
			<MantineBreadcrumbs
				data-testid={dataTestID}
				data-breadcrumblabels={breadCrumbLabels}
				data-breadcrumblinks={breadCrumbLinks}
				classNames={{
					root: classes.root,
					separator: classes.separator,
				}}
			>
				{isCollapsed && crumbs.length > 2 ? (
					<Group spacing={0} noWrap>
						<Breadcrumb
							key={`${crumbs[0].title}-0`}
							crumb={crumbs[0]}
							onClick={onBreadcrumbClick}
						/>
						<Text size={'sm'} color={'border/input/default'}>
							/
						</Text>
						<TruncatedBreadcrumbs
							key="truncated"
							crumbs={crumbs.slice(1, -1)}
							onBreadcrumbClick={onBreadcrumbClick}
						/>
						<Text size={'sm'} color={'border/input/default'}>
							/
						</Text>
						<Breadcrumb
							key={`${crumbs[crumbs.length - 1].title}-${crumbs.length - 1}`}
							crumb={crumbs[crumbs.length - 1]}
							onClick={onBreadcrumbClick}
						/>
					</Group>
				) : (
					crumbs.map((crumb, index) => (
						<Breadcrumb
							key={`${crumb.title}-${index}`}
							crumb={crumb}
							onClick={onBreadcrumbClick}
						/>
					))
				)}
			</MantineBreadcrumbs>
		</Box>
	);
}
