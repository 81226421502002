import { Box, Divider, Group, Stack, TextInput } from '@mantine/core';
import MultiSelector from '@repo/common/components/MultiSelector/MultiSelector';
import { Button, Text } from '@repo/foundations';
import type { SecodaThemeShades } from '@repo/theme/types';
import { includes } from 'lodash-es';
import { useCallback, useMemo, useState } from 'react';
import type { ITagGroup } from '../../../../api';
import {
	invalidateTagList,
	useAuthUser,
	useCreateTagGroup,
	useTagList,
	useUpdateTagGroup,
} from '../../../../api';
import { useModalStyles } from './styles';

interface TagGroupModalProps {
	onClose: VoidFunction;
	tagGroup?: ITagGroup;
	allGroups: Array<string>;
}

export function TagGroupModal({
	onClose,
	tagGroup,
	allGroups,
}: TagGroupModalProps) {
	const { classes } = useModalStyles();

	const [groupName, setGroupName] = useState<string>(tagGroup?.name ?? '');
	const [selected, setSelected] = useState<string[]>(
		tagGroup?.tags?.map((tag) => tag.id) ?? []
	);

	const { data: tags } = useTagList({
		options: {
			select: (data) => data.results,
		},
	});
	const { user } = useAuthUser();

	const options = useMemo(
		() =>
			tags?.map((tag) => ({
				label: tag.name,
				value: tag.id,
				color: tag.color as SecodaThemeShades,
			})),
		[tags]
	);

	const isCreateNew =
		tagGroup === undefined || tagGroup === null || !tagGroup.id;

	const groupIsUnique = useMemo(
		() => !includes(allGroups, groupName),
		[groupName, allGroups]
	);
	const canSave = groupName !== '' && groupIsUnique;
	const canUpdate =
		groupName !== '' &&
		groupName !== tagGroup?.name &&
		tagGroup?.tags?.map((tag) => tag.id) !== selected &&
		groupIsUnique;

	const { mutateAsync: createTagGroup } = useCreateTagGroup({
		options: {
			onSuccess: () => invalidateTagList(),
		},
	});

	const { mutateAsync: updateTagGroup } = useUpdateTagGroup({
		options: {
			onSuccess: () => invalidateTagList(),
		},
		disableOptimisticUpdate: true,
		disableInvalidation: true,
	});

	const handleClose = useCallback(() => {
		setGroupName(tagGroup?.name ?? '');
		setSelected(tagGroup?.tags?.map((tag) => tag.id) ?? []);
		onClose();
	}, [onClose, tagGroup?.name, tagGroup?.tags]);

	const handleAction = useCallback(async () => {
		if (isCreateNew) {
			await createTagGroup({
				data: {
					name: groupName,
					tags: tags?.filter((tag) => selected.includes(tag.id)),
				},
			});
			handleClose();
		} else {
			await updateTagGroup({
				data: {
					id: tagGroup.id,
					name: groupName,
					tags: tags?.filter((tag) => selected.includes(tag.id)),
				},
			});
			handleClose();
		}
	}, [
		createTagGroup,
		groupName,
		handleClose,
		isCreateNew,
		selected,
		tagGroup?.id,
		tags,
		updateTagGroup,
	]);

	const handleChange = useCallback((value: (string | boolean)[]) => {
		setSelected(value as string[]);
	}, []);

	return (
		<Box>
			<Stack spacing="sm">
				<Text weight="semibold" size="sm">
					Group Name*
				</Text>
				<TextInput
					value={groupName}
					onChange={(e) => setGroupName(e.target.value)}
					placeholder="Group name"
					className={classes.textInput}
					error={
						groupIsUnique || groupName === tagGroup?.name
							? null
							: 'A tag group with this name already exists.'
					}
				/>
				<Text weight="semibold" size="sm">
					Tags&nbsp;
					<Text size="sm" color="text/secondary/default" span>
						(Optional)
					</Text>
				</Text>
				<MultiSelector
					placeholder="No tags selected"
					iconType="tag"
					isViewerUser={false}
					options={options ?? []}
					onChange={handleChange}
					initialSelected={selected}
					property="tags"
					isMenuItemBadge
					permittedId={user.id}
				/>
			</Stack>
			<Divider my="md" />
			<Group position="right" spacing="sm">
				<Button onClick={handleClose}>Cancel</Button>
				<Button
					disabled={isCreateNew ? !canSave : !canUpdate}
					variant="primary"
					onClick={handleAction}
				>
					{isCreateNew ? 'Save' : 'Update'}
				</Button>
			</Group>
		</Box>
	);
}
