import type { WithOptional } from '../../../lib/typescript';
import { createMockableHook } from '../../../utils/createMockableHook';
import type { IBaseModelQueryArgs, ISecodaEntity } from '../../types';
import { useBaseModel } from '../base';
import {
	SECODA_ENTITIES_NAMESPACE,
	secodaEntitiesQueryKeyFactory,
} from './constants';

type ISecodaEntityQueryArgs<
	TApiResponseData extends ISecodaEntity,
	TData,
	TError,
> = WithOptional<
	IBaseModelQueryArgs<TApiResponseData, TData, TError>,
	'queryKey' | 'namespace'
>;

/**
 * A react-query query hook that can be used to fetch a Secoda entity.
 * By default, it will return the entity's data with the viewer's delta applied
 * based on the current user's role.
 *
 * @param id ID of the entity
 * @param queryKey Query key for caching the entity
 * @param namespace Namespace for the entity
 * @param options Options for the react-query query hook
 * @returns Returns a react-query query hook that can be used to fetch a Secoda entity
 */
function useSecodaEntityInternal<
	TApiResponseData extends ISecodaEntity,
	TData = TApiResponseData,
	TError = unknown,
>({
	id,
	queryKey,
	namespace = SECODA_ENTITIES_NAMESPACE,
	options,
}: ISecodaEntityQueryArgs<TApiResponseData, TData, TError>) {
	const entityQueryKey = queryKey ?? secodaEntitiesQueryKeyFactory.byId(id);

	return useBaseModel<TApiResponseData, TData, TError>({
		id,
		queryKey: entityQueryKey,
		namespace,
		options,
	});
}

export const [useSecodaEntity, MockUseSecodaEntity] = createMockableHook(
	useSecodaEntityInternal
);

export default useSecodaEntity;
