import { Group, Table, ThemeIcon, useMantineTheme } from '@mantine/core';
import { useNavigateHandler } from '@repo/common/hooks/useNavigate';
import { Icon, Text } from '@repo/foundations';
import { useExtendedUserList } from '../../../../api/hooks/user/useExtendedUserList';
import { sanitizePromptMessage } from '../../../../components/AIAssistant/utils';
import type { TableComponentProps } from './MetricWidgetTable';
import { UserCell } from './UserCell';

function MetricAIQueryTable({
	values,
	withUser,
}: TableComponentProps & { withUser: boolean }) {
	const theme = useMantineTheme();
	const { users } = useExtendedUserList();
	const findUserById = (id: string) => users.find((u) => u.id === id);

	const navigateHandler = useNavigateHandler();

	return (
		<Table highlightOnHover width={600}>
			<thead>
				<tr>
					<th style={{ width: '60%' }}>
						<Text color="text/secondary/default" weight="semibold" size="xs">
							Prompt
						</Text>
					</th>
					{withUser && (
						<th style={{ width: '40%' }}>
							<Text color="text/secondary/default" weight="semibold" size="xs">
								User
							</Text>
						</th>
					)}
				</tr>
			</thead>
			<tbody>
				{values.map((prompt) => (
					<tr
						style={{
							cursor: 'pointer',
						}}
						key={prompt.id}
						onClick={(e) => navigateHandler(e)(`/ai/${prompt.id}`)}
					>
						<td>
							<Group noWrap>
								<ThemeIcon color={theme.colors.gray[2]} size={32} radius={32}>
									<Icon name="sparkles" color="icon/primary/default" />
								</ThemeIcon>
								<Text
									lineClamp={1}
									color="text/primary/default"
									size="sm"
									weight="semibold"
								>
									{sanitizePromptMessage(String(prompt.prompt))}
								</Text>
							</Group>
						</td>
						{withUser && (
							<td>
								{findUserById(String(prompt.user)) && (
									<UserCell user={findUserById(String(prompt.user))} />
								)}
							</td>
						)}
					</tr>
				))}
			</tbody>
		</Table>
	);
}

export function MetricAIQueryTableWithUsers({ values }: TableComponentProps) {
	return <MetricAIQueryTable values={values} infoColumns={[]} withUser />;
}

export function MetricAIQueryTableWithoutUsers({
	values,
}: TableComponentProps) {
	return (
		<MetricAIQueryTable values={values} infoColumns={[]} withUser={false} />
	);
}
