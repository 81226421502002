import { filter } from 'lodash-es';
import { useMemo } from 'react';

import type { ILineage } from '../../../api';
import { FilterOptionType } from '../../../components/Filter';
import {
	CapitalizeTextRender,
	IntegrationRender,
	ParentRenderer,
} from '../../../components/TableV2/render';
import { useGenericColumns } from '../../../components/TableV2/SecodaEntity.hooks';
import type { ExtendedDataTableColumn } from '../../../components/TableV2/types';

export function useColumns(): ExtendedDataTableColumn<ILineage>[] {
	const genericColumns = useGenericColumns(
		{}
	) as ExtendedDataTableColumn<ILineage>[];

	const columns = useMemo(
		() => [
			...filter(genericColumns, (column) =>
				['title'].includes(column.accessor)
			),
			{
				accessor: 'integration_id',
				esAccessor: 'integration_id',
				title: 'Integration',
				render: (record: ILineage) => (
					<IntegrationRender record={{ integration: record.integration_id! }} />
				),
				width: 150,
				filterOptionType: FilterOptionType.INTEGRATION,
			},
			{
				accessor: 'native_type',
				title: 'Type',
				render: (record: ILineage) => (
					<CapitalizeTextRender
						record={record}
						field="native_type"
						field2="entity_type"
					/>
				),
				width: 150,
				filterOptionType: FilterOptionType.NATIVE_TYPE,
			},
			{
				accessor: 'parent_id',
				esAccessor: 'parent_id',
				title: 'Parent',
				render: (record: ILineage) => (
					<ParentRenderer record={{ search_metadata: record.metadata }} />
				),
				width: 150,
				filterOptionType: FilterOptionType.PARENT_ID,
			},
			{
				title: 'Depth',
				accessor: 'depth',
				width: 100,
			},
			...filter(genericColumns, (column) =>
				['owners'].includes(column.accessor)
			),
		],
		[genericColumns]
	);

	return columns;
}
