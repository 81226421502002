import { useQuery } from '@tanstack/react-query';
import type { AxiosResponse } from 'axios';
import { apiClient, getEndpoints } from '../../common';
import {
	NOTIFICATION_UNREAD_COUNT_NAMESPACE,
	notificationUnreadCountQueryKeyFactory,
} from './constants';

export function useUnreadNotificationCount() {
	const queryKey = notificationUnreadCountQueryKeyFactory.all();
	const queryFn = () => {
		const url = getEndpoints(NOTIFICATION_UNREAD_COUNT_NAMESPACE).root();
		return apiClient.get(url, {});
	};

	return useQuery(queryKey, queryFn, {
		select: (
			response: AxiosResponse<{
				count: number;
			}>
		) => response.data.count,
		refetchIntervalInBackground: false,
		refetchInterval: 1000 * 60 * 2, // 2 minutes.
	});
}
