import { Box, createStyles, Group, Tabs } from '@mantine/core';
import { randomId } from '@mantine/hooks';
import { Icon } from '@repo/foundations';
import { isEmpty, isNil } from 'lodash-es';
import { useLayoutEffect, useState } from 'react';
import { Navigate } from 'react-router';
import { useIsSuperAdmin } from '../../../api/hooks/internalQueryActions';
import { FullWidthLoadingSpinner } from '../../../components/LoadingSpinner';
import ActionPage from './ActionPage';
import ActionsList from './ActionsList';
import type { ACTION_TYPE, ActionsPageTab } from './types';

const useStyles = createStyles((theme) => ({
	tab: {
		padding: `${theme.spacing.xs} ${theme.spacing['2xs']} ${theme.spacing.xs} ${theme.spacing.xs}`,
	},
	tabLabelContainer: {
		alignItems: 'center',
	},
}));

function QueryActionsPage() {
	const [tabs, setTabs] = useState<ActionsPageTab[]>([
		{ key: randomId(), label: 'Query Actions' },
	]);
	const [currentTab, setCurrentTab] = useState<string | null>(randomId());

	const { classes, theme } = useStyles();

	useLayoutEffect(() => {
		const storedTabs = localStorage.getItem(
			'secoda-internal-query-actions-page-tabs'
		);
		if (!isNil(storedTabs)) {
			setTabs(JSON.parse(storedTabs));
		}
	}, []);

	useLayoutEffect(() => {
		if (tabs.length === 1 && isNil(currentTab)) {
			setCurrentTab(tabs[0].key);
		} else {
			const isCurrentTabValid = tabs.some((tab) => tab.key === currentTab);
			if (!isCurrentTabValid) {
				setCurrentTab(tabs[tabs.length - 1].key);
			}
			localStorage.setItem(
				'secoda-internal-query-actions-page-tabs',
				JSON.stringify(tabs)
			);
		}
	}, [tabs]);

	const { data: isSuperAdmin, isLoading: isSuperAdminLoading } =
		useIsSuperAdmin();

	const updateTab = (key: string, partial: Partial<ActionsPageTab>) => {
		setTabs((prevTabs) =>
			prevTabs.map((tab) => {
				if (tab.key === key) {
					return { ...tab, ...partial };
				}
				return tab;
			})
		);
	};

	const handleSetActionType = (key: string, actionType: ACTION_TYPE) => {
		updateTab(key, {
			label: actionType,
			actionType,
		});
	};

	const handleTabNameChange = (key: string, newLabel: string) => {
		updateTab(key, {
			label: newLabel,
		});
	};

	const handleNewTab = () => {
		const newKey = randomId();

		setTabs((prevTabs) => [
			...prevTabs,
			{
				key: newKey,
				label: 'Query Actions',
			},
		]);

		setCurrentTab(newKey);
	};

	const handleTabChange = (newTabKey: string) => {
		if (newTabKey === 'new-tab') {
			handleNewTab();
		} else {
			setCurrentTab(newTabKey);
		}
	};

	const handleTabRemove = (key: string) => {
		if (tabs.length === 1) {
			return;
		}
		localStorage.removeItem(`secoda-internal-query-action-page-${key}`);
		setTabs((prevTabs) => prevTabs.filter((tab) => tab.key !== key));
	};

	if (isSuperAdminLoading) {
		return <FullWidthLoadingSpinner />;
	}

	if (!isSuperAdmin) {
		return <Navigate to="/" />;
	}

	return (
		<Tabs
			classNames={{
				tab: classes.tab,
			}}
			value={currentTab}
			onTabChange={handleTabChange}
		>
			<Tabs.List>
				{tabs?.map(({ key, label, actionType }) => (
					<Tabs.Tab key={key} value={key}>
						<Group
							className={classes.tabLabelContainer}
							spacing={theme.spacing['3xs']}
						>
							{!isEmpty(label) ? label : actionType}
							<Box onClick={() => handleTabRemove(key)}>
								<Icon name="x" color="icon/secondary/default" />
							</Box>
						</Group>
					</Tabs.Tab>
				))}
				<Tabs.Tab value="new-tab" icon={<Icon name="plus" />}>
					New tab
				</Tabs.Tab>
			</Tabs.List>
			<Tabs.Panel value={currentTab ?? ''}>
				{tabs?.map(({ key, actionType }) => (
					<Tabs.Panel key={key} value={key}>
						{isNil(actionType) ? (
							<ActionsList
								onSelectAction={(type: ACTION_TYPE) =>
									handleSetActionType(key, type)
								}
							/>
						) : (
							<ActionPage
								tabKey={key}
								type={actionType}
								onTabNameChange={(newName: string) =>
									handleTabNameChange(key, newName)
								}
							/>
						)}
					</Tabs.Panel>
				))}
			</Tabs.Panel>
		</Tabs>
	);
}

export default QueryActionsPage;
