import type { BoxProps } from '@mantine/core';
import { Stack } from '@mantine/core';
import { ListBox } from '@repo/foundations';
import { range } from 'lodash-es';
import type { ReactNode } from 'react';
import { useMemo, useState } from 'react';
import { SelectorItem } from './SelectorItem';
import { SelectorItemEmpty } from './SelectorItemEmpty';
import { SelectorItemError } from './SelectorItemError';
import { SelectorItemSkeleton } from './SelectorItemSkeleton';
import type { SelectorItemType, SelectorValue } from './types';
import { SelectType } from './types';
import { useSelectorItems } from './useSelectorItems';

const DIVIDER = 'divider';

export interface SelectorDropdownProps extends BoxProps {
	getItems: (searchTerm: string) => Promise<SelectorItemType[]>;
	onChange: (value: SelectorValue[], close: boolean) => void;
	searchPlaceholder?: string;
	selectType: SelectType;
	renderItem?: (item: SelectorItemType) => ReactNode;
	initialValue: SelectorValue[];
	withDivider?: boolean;
	disableSearch?: boolean;
}

export function SelectorDropdown({
	getItems,
	onChange,
	searchPlaceholder = 'Search...',
	selectType,
	renderItem,
	initialValue,
	withDivider = true,
	disableSearch = false,
	...boxProps
}: SelectorDropdownProps) {
	const { isLoading, error, items, searchTerm, handleSearchChange } =
		useSelectorItems({ getItems });
	const [value, setValue] = useState<SelectorValue[]>(initialValue ?? []);

	const itemsToRender = useMemo(() => {
		if (!withDivider || selectType === SelectType.Single) {
			return items;
		}

		const selected = items.filter((i) => value.includes(i.value));
		const unselected = items.filter((i) => !value.includes(i.value));

		const result: Array<SelectorItemType | typeof DIVIDER> = [...selected];

		if (selected.length > 0 && unselected.length) {
			result.push(DIVIDER);
		}

		result.push(...unselected);

		return result;
	}, [items, value]);

	const handleChange = (item: SelectorItemType, close: boolean) => {
		let newValue: SelectorValue[] = [];
		if (selectType === SelectType.Multiple) {
			newValue = value.includes(item.value)
				? value.filter((i) => i !== item.value)
				: [...value, item.value];
			setValue(newValue);
		} else {
			newValue = [item.value];
			setValue(newValue);
		}
		onChange(newValue, close);
	};

	const isEmptyResults =
		items.length === 0 && !!searchTerm && !isLoading && !error;

	return (
		<ListBox.ItemsDropdown
			{...boxProps}
			usePortal
			search={
				disableSearch
					? undefined
					: {
							onChange: handleSearchChange,
							value: searchTerm,
							placeholder: searchPlaceholder,
						}
			}
			items={itemsToRender}
			renderItem={(item, getProps, index) => {
				if (item === DIVIDER) {
					return <ListBox.Divider key={`multi-select-divider-${index}`} />;
				}

				return (
					<SelectorItem
						item={item}
						getProps={getProps}
						key={`multi-select-item-${index}`}
						onClick={(close) => handleChange(item, close)}
						selectType={selectType}
						renderItem={renderItem}
						selected={value.includes(item.value)}
					/>
				);
			}}
		>
			{isLoading && (
				<Stack spacing="2xs">
					{range(3).map((idx) => (
						<SelectorItemSkeleton key={`multi-select-item-skeleton-${idx}`} />
					))}
				</Stack>
			)}
			{error && <SelectorItemError>An error has ocurred</SelectorItemError>}
			{isEmptyResults && (
				<SelectorItemEmpty>No results found</SelectorItemEmpty>
			)}
		</ListBox.ItemsDropdown>
	);
}
