import { Box, Group, Stack, Tooltip } from '@mantine/core';
import { Switch } from '@repo/foundations';
import { EDUCATION_MODALS } from '../EducationModal/constants';
import { useEducationModalDismiss } from '../EducationModal/useEducationModalDismiss';
import { DefaultDevDropdown } from './DefaultDevDropdown';
import type { DevPanelItemProps } from './DevPanel';

function EducationModalSwitch({
	modalKey,
}: {
	modalKey: keyof typeof EDUCATION_MODALS;
}) {
	const { title } = EDUCATION_MODALS[modalKey];

	const { setAsSeen, setAsUnseen, dismissedModals } =
		useEducationModalDismiss();

	const previouslyShown = !!dismissedModals?.[modalKey];

	return (
		<Group>
			<Tooltip label={previouslyShown ? 'Show it again' : 'Hide it'}>
				<Box>
					<Switch
						label={title}
						labelPosition="left"
						checked={previouslyShown}
						onChange={(event) =>
							!!event.currentTarget.value
								? setAsUnseen(modalKey)
								: setAsSeen(modalKey)
						}
					/>
				</Box>
			</Tooltip>
		</Group>
	);
}

export function EducationModalToggles({ close }: DevPanelItemProps) {
	return (
		<DefaultDevDropdown label="Education Modals" close={close}>
			<Stack>
				{Object.keys(EDUCATION_MODALS).map((modalKey) => (
					<EducationModalSwitch
						key={modalKey}
						modalKey={modalKey as keyof typeof EDUCATION_MODALS}
					/>
				))}
			</Stack>
		</DefaultDevDropdown>
	);
}
