import { cloneDeep } from 'lodash-es';
import { SecodaEntity } from './entity';

export class Document extends SecodaEntity {
	static CLASS_NAME = 'Document';

	// @ts-expect-error TS(2564): Property 'id' has no initializer and is not defini... Remove this comment to see the full error message
	id: string;

	// @ts-expect-error TS(2564): Property 'definition' has no initializer and is no... Remove this comment to see the full error message
	definition: string;

	constructor(obj: Document) {
		super(obj);
		Object.keys(obj).forEach((key) => {
			(this as any)[key] = cloneDeep((obj as any)[key]);
		});
	}

	static get namespace() {
		return ['document'];
	}
}
