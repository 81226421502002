import { Plugin } from 'prosemirror-state';
import { Decoration, DecorationSet } from 'prosemirror-view';
import Extension from '../lib/Extension';

export default class Placeholder extends Extension {
	get name() {
		return 'empty-placeholder';
	}

	get defaultOptions() {
		return {
			emptyNodeClass: 'placeholder',
			placeholder: '',
		};
	}

	get plugins() {
		return [
			new Plugin({
				view: () => ({
					update: (view) => {
						const { doc } = view.state;

						const completelyEmpty =
							doc.textContent === '' &&
							doc.childCount <= 1 &&
							doc.content.size <= 2;

						view.dom.classList.toggle('doc-empty', completelyEmpty);
					},
				}),
				props: {
					decorations: (state) => {
						const { doc } = state;
						const decorations: Decoration[] = [];
						const completelyEmpty =
							doc.textContent === '' &&
							doc.childCount <= 1 &&
							doc.content.size <= 2;

						doc.descendants((node, pos) => {
							if (!completelyEmpty) {
								return;
							}
							if (pos !== 0 || node.type.name !== 'paragraph') {
								return;
							}

							const decoration = Decoration.node(pos, pos + node.nodeSize, {
								class: this.options.emptyNodeClass,
								'data-empty-text': this.options.placeholder,
							});
							decorations.push(decoration);
						});

						return DecorationSet.create(doc, decorations);
					},
				},
			}),
		];
	}
}
