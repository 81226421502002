import { noop } from 'lodash-es';
import { useEffect, useState } from 'react';

export function useStreamingContent(
	active: boolean,
	content: string,
	callback: VoidFunction = noop
) {
	const [displayedContent, setDisplayedContent] = useState('');

	useEffect(() => {
		let timer: ReturnType<typeof setInterval>;

		if (active) {
			timer = setInterval(() => {
				if (displayedContent.length < content.length) {
					setDisplayedContent(content.slice(0, displayedContent.length + 1));
				} else {
					clearInterval(timer);
				}

				callback();
			}, 1);
		} else {
			setDisplayedContent(content);
			callback();
		}
		return () => {
			clearInterval(timer);
		};
	}, [active, callback, content, displayedContent]);

	return displayedContent;
}
