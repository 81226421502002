import { useState } from 'react';
import { useParams } from 'react-router';

import { useAuthUser, useEvent, useUpdateEvent } from '../../api';
import { getEntityPageSidebarProps } from '../../components/EntityDrawer/utils';
import EntityLogo from '../../components/EntityLogo/EntityLogo';
import EntityPageLayout from '../../components/EntityPageLayout';

import { EntityType } from '@repo/common/enums/entityType';
import { trackEvent } from '../../utils/analytics';
import { useParamsIdSuffixUuid } from '../../utils/hook/utils';
import type { DjangoValueType } from '../TemplatePage/types';
import EventEntityTabs from './EventEntityTabs';
import { EventEntityTabsStore } from './EventEntityTabs/EventEntityTabsStore';

export interface IEventPageProps {
	id?: string;
}

function EventPage({ id: propsId }: IEventPageProps) {
	const { tab } = useParams();
	const paramsId = useParamsIdSuffixUuid();
	const [eventEntityTabsStore] = useState(new EventEntityTabsStore());
	const { user, workspace } = useAuthUser();

	const id = propsId || paramsId;

	const { data: event } = useEvent({
		id,
		options: {
			onSettled: () => {
				trackEvent('event/open', {}, user, workspace);
			},
		},
	});

	const { mutateAsync } = useUpdateEvent({});

	const updateEvent = async (
		key: string,
		value: DjangoValueType,
		saveRemotely = true
	) => {
		if (saveRemotely) {
			mutateAsync({
				data: {
					id: event!.id,
					[key]: value,
				},
			});
			trackEvent('event/properties/update', {}, user, workspace!);
		}
	};

	if (!event) {
		return null;
	}

	return (
		<EntityPageLayout
			name="Event"
			isReadOnlyTitle
			entity={event}
			icon={<EntityLogo size={32} entity={event} />}
			updateEntity={updateEvent}
			withDescription
			{...getEntityPageSidebarProps(EntityType.event)}
		>
			<EventEntityTabs
				eventEntityTabsStore={eventEntityTabsStore}
				event={event}
				tab={tab}
			/>
		</EntityPageLayout>
	);
}

export default EventPage;
