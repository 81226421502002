import { useQuery } from '@tanstack/react-query';
import { cache } from '../../../lib/stores/CacheStore';
import type { WithOptional } from '../../../lib/typescript';
import type { IBaseModelQueryArgs, ISecodaEntity } from '../../types';
import { useBaseModel } from '../base';
import { secodaEntitiesQueryKeyFactory } from './constants';

export interface NewSnapshotFormat {
	definition: string;
	user: string | null;
}

interface IDefinitionSnapshot extends ISecodaEntity {
	definition_snapshots: Record<string, string | NewSnapshotFormat>;
	definition_snapshots_last_saved: string;
}

type IIDefinitionSnapshotQueryArgs<
	TApiResponseData extends IDefinitionSnapshot,
	TData,
	TError,
> = WithOptional<
	IBaseModelQueryArgs<TApiResponseData, TData, TError>,
	'queryKey' | 'namespace'
>;

/**
 * @param id ID of the entity
 * @param options Options for the react-query query hook
 * @returns Returns a react-query query hook that can be used to fetch a Secoda entity
 */
export function useRemoteSnapshots<
	TApiResponseData extends IDefinitionSnapshot,
	TData = TApiResponseData,
	TError = unknown,
>({
	id,
	options,
}: IIDefinitionSnapshotQueryArgs<TApiResponseData, TData, TError>) {
	const entityQueryKey = secodaEntitiesQueryKeyFactory.byArgs('snapshots', id);

	return useBaseModel<TApiResponseData, TData, TError>({
		id,
		queryKey: entityQueryKey,
		namespace: ['resource', 'snapshots'],
		options: {
			suspense: false,
			cacheTime: 1000,
			...options,
		},
	});
}

export function useLocalSnapshots({ id }: { id: string }) {
	return useQuery<{ data: Record<string, string> }>(
		['snapshots', id],
		async () => {
			const value = (await cache.definitions.get(id)) ?? {};
			// `id` is an artificat of `dexie`.
			delete value.id;
			return value;
		},
		{
			suspense: false,
			cacheTime: 1000,
		}
	);
}
