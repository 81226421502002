import type { Sx } from '@mantine/styles';
import { Icon } from '@repo/foundations';

interface TagIconProps {
	color: string;
	sx?: Sx;
}

function TagIcon({ color, sx }: TagIconProps) {
	return (
		<Icon
			sx={sx}
			iconPadding={0}
			iconWidth={14}
			name="circle"
			style={{ color, fill: color, width: 14 }}
		/>
	);
}

export default TagIcon;
