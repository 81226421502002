import { ActionIcon, Loader, Tooltip } from '@mantine/core';
import { Icon } from '@repo/foundations';
import { useCallback, useState } from 'react';

export interface DeleteGlossarySynonymProps {
	onRemove: () => Promise<void>;
	className?: string;
}

export function DeleteGlossarySynonym({
	onRemove,
	className,
}: DeleteGlossarySynonymProps) {
	const [isLoading, setIsLoading] = useState(false);

	const handleOnRemove = useCallback(async () => {
		setIsLoading(true);
		await onRemove();
		setIsLoading(false);
	}, [onRemove]);

	return (
		<Tooltip label="Remove synonym">
			<ActionIcon className={className} size="xs" onClick={handleOnRemove}>
				{isLoading ? (
					<Loader size="xs" />
				) : (
					<Icon name="smallX" color="icon/primary/default" />
				)}
			</ActionIcon>
		</Tooltip>
	);
}
