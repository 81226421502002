import { Stack, createStyles } from '@mantine/core';
import { observer } from 'mobx-react-lite';
import type { Automation } from '../../../api';
import { isValidEnumValue } from '../../../utils/enumUtils';
import { AutomationActionCardType } from '../constants';
import AutomationActionCardContent from './AutomationActionCard/AutomationActionCardContent';
import AutomationTriggerCardContent from './AutomationTriggerCard/AutomationTriggerCardContent';
import type { AutomationCardType } from './constants';
import { DefaultAutomationCardType } from './constants';

const useStyles = createStyles((theme) => ({
	stack: {
		padding: theme.spacing.md,
		gap: theme.spacing.md,
		margin: '0',
		maxWidth: 'none',
	},
}));

interface AutomationCardContentProps {
	type: AutomationCardType;
	searchKey?: string;
	icon?: JSX.Element;
	handleAutomationUpdate: (automation: Partial<Automation>) => Promise<void>;
}

function AutomationCardContent({
	type,
	searchKey,
	icon,
	handleAutomationUpdate,
}: AutomationCardContentProps) {
	const { classes } = useStyles();

	return (
		<Stack className={classes.stack}>
			{type === DefaultAutomationCardType.TRIGGER && (
				<AutomationTriggerCardContent
					searchKey={searchKey}
					handleAutomationUpdate={handleAutomationUpdate}
				/>
			)}
			{isValidEnumValue(AutomationActionCardType, type) &&
				[
					AutomationActionCardType.FILTER_RESOURCES,
					AutomationActionCardType.FILTER_COUNT,
					AutomationActionCardType.EDIT_RESOURCES,
					AutomationActionCardType.SEND_ANNOUNCEMENT,
					AutomationActionCardType.SEND_EMAIL,
					AutomationActionCardType.SEND_SLACK_MESSAGE,
					AutomationActionCardType.PROPAGATE_METADATA,
				].includes(type) && (
					<AutomationActionCardContent
						type={type}
						icon={icon}
						searchKey={searchKey}
						handleAutomationUpdate={handleAutomationUpdate}
					/>
				)}
		</Stack>
	);
}

export default observer(AutomationCardContent);
