import { Title } from '@repo/foundations';
import { NavBarWrapper } from '.';

function SettingsPageNavBar() {
	return (
		<NavBarWrapper>
			<Title order={1} size="sm" data-testid="settings-page-nav-bar-title">
				Settings
			</Title>
		</NavBarWrapper>
	);
}

export default SettingsPageNavBar;
