import Fuse from 'fuse.js';
import { map, orderBy } from 'lodash-es';

/**
 * Fuzzy search filter for finding items in a list
 * of objects based on a search term and chosen
 * keys.
 *
 * @param searchTerm Search term to filter by
 * @param items List of items to filter
 * @param keys Keys to search by
 * @param options Fuse options
 */
export const fuzzySearchFilter = <T>(
	searchTerm: string,
	items: T[],
	keys: Array<Fuse.FuseOptionKey<T>> = [],
	options: Fuse.IFuseOptions<T> = { threshold: 0.6, includeScore: true }
): T[] => {
	if (searchTerm === '') {
		return items;
	}

	const fuse = new Fuse(items, { keys, ...options });
	return map(orderBy(fuse.search(searchTerm), ['score'], ['asc']), 'item');
};
