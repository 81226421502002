import { Breadcrumbs, BreadcrumbsSkeleton } from '@repo/foundations';
import type { ISecodaEntity } from '../../../api';
import { useEntityBreadcrumbs } from '../../../hooks/useEntityBreadcrumbs';

interface EntityDrawerBreadcrumbsProps {
	entity: ISecodaEntity;
	onClick: () => void;
}

function EntityDrawerBreadcrumbs({
	entity,
	onClick,
}: EntityDrawerBreadcrumbsProps) {
	const { isLoading, breadcrumbs } = useEntityBreadcrumbs(entity, {
		includeTeam: true,
	});

	if (isLoading) {
		return <BreadcrumbsSkeleton />;
	}

	return (
		<Breadcrumbs
			data-testid="entity-drawer-breadcrumbs"
			crumbs={breadcrumbs}
			onBreadcrumbClick={onClick}
		/>
	);
}

export default EntityDrawerBreadcrumbs;
