import { Container, Skeleton, Stack } from '@mantine/core';
import { isEmpty, isNil } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router';

import { useDataQualityAccess } from '../../api';
import { appShellStore } from '../../components/SecodaAppShell/store';
import { TeamSettingsModal } from '../../components/TeamSettingsModal/TeamSettingsModal';
import { useEntityBreadcrumbTeam } from '../../hooks/useEntityBreadcrumbs';
import { WidgetType } from '../../interfaces';

import EditHomeScreenDrawer from './components/EditHomeScreenDrawer';
import { TeamHomepageEditorOnboarding } from './components/Onboarding';
import TeamsHomepageHeader from './components/TeamsHomepageHeader';
import WidgetContainer, {
	EmptyWidgetContainer,
} from './components/WidgetContainer';
import {
	HOMEPAGE_MAX_WIDTH,
	HOMEPAGE_PADDING,
	TEAM_HOMEPAGE_ALLOWED_WIDGETS_TO_ADD,
} from './constants';
import { useHomepageTeam } from './hooks/useHomepageTeam';
import { useReportManagement } from './hooks/useReportManagement';

function TeamHomePage() {
	const { teamId } = useParams();
	const dqsEnabled = useDataQualityAccess();

	if (!teamId) {
		throw new Error('Cannot render TeamHomePage outside a team page');
	}

	const {
		report,
		widgets,
		updateBackgroundImage,
		reorderWidgets,
		updateWidget,
		removeWidget,
		addWidget,
		isFetching,
	} = useReportManagement({ type: 'teams', teamId });

	const { isTeamViewerUser, isTeamsPage, team, updateTeam } = useHomepageTeam();

	const { setTeamBreadcrumb } = useEntityBreadcrumbTeam();

	useEffect(() => {
		// If the user opens a entity page from this context we want the team
		// breadcrumb to match the team of this page.
		setTeamBreadcrumb(teamId);
	}, [teamId]);

	const {
		navBarUI: {
			teamHomePage: { drawerOpen, settingsModalOpen },
		},
		teamHomePageDrawerHandler: { open: openDrawer, close: closeDrawer },
		teamHomePageSettingsModalHandler: {
			open: openSettingsModal,
			close: closeSettingsModal,
		},
	} = appShellStore;

	if (!isTeamsPage) {
		return null;
	}

	const widgetSamples = [...TEAM_HOMEPAGE_ALLOWED_WIDGETS_TO_ADD];
	if (dqsEnabled) {
		widgetSamples.push({ type: WidgetType.DATA_QUALITY_SCORE });
	}

	return (
		<Stack>
			<Helmet>
				<title>{team.name} Home</title>
			</Helmet>
			<TeamsHomepageHeader
				team={team}
				image={report?.background_image}
				icon={team.icon || ''}
				onIconChange={updateTeam('icon')}
			/>
			<Container
				data-testid="team-homepage-container-1X1nJVC"
				px={HOMEPAGE_PADDING}
				size={HOMEPAGE_MAX_WIDTH + 2 * HOMEPAGE_PADDING}
				miw={HOMEPAGE_MAX_WIDTH + 2 * HOMEPAGE_PADDING}
			>
				<Stack>
					{!isTeamViewerUser && !isNil(team) && !team.onboarding_completed && (
						<TeamHomepageEditorOnboarding
							onEditHomeScreen={openDrawer}
							onOpenTeamSettings={openSettingsModal}
						/>
					)}
					{isFetching && <Skeleton />}
					{isEmpty(widgets) && !isFetching ? (
						<EmptyWidgetContainer onCustomizeButtonClick={openDrawer} />
					) : (
						<WidgetContainer
							widgets={widgets}
							onReorderWidgets={reorderWidgets}
							onUpdateWidget={updateWidget}
							onRemoveWidget={removeWidget}
						/>
					)}
				</Stack>
			</Container>
			{isTeamsPage && (
				<TeamSettingsModal
					opened={settingsModalOpen}
					onClose={closeSettingsModal}
					team={team}
				/>
			)}
			<EditHomeScreenDrawer
				widgetSamples={widgetSamples}
				opened={drawerOpen}
				onClose={closeDrawer}
				backgroundImage={report?.background_image}
				onAddWidget={addWidget}
				onBackgroundImageChange={updateBackgroundImage}
			/>
		</Stack>
	);
}

export default observer(TeamHomePage);
