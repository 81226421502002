import { Box, useMantineTheme } from '@mantine/core';
import { isNil } from 'lodash-es';
import { useMemo } from 'react';
import type { IUser, IUserGroup } from '../../../api';
import { OwnerSelector } from '../../EntityModal/Metadata/OwnerSelector';

export function MemberRender<T extends IUserGroup>({
	record,
	onChangeUserOwners,
}: {
	record: T;
	onChangeUserOwners?: (id: string) => (value: string[]) => void;
}) {
	const theme = useMantineTheme();

	const ownerIds = useMemo(() => {
		const userIds =
			record.users
				?.map((user) => (typeof user === 'object' ? (user as IUser).id : user))
				.filter((owner) => !isNil(owner)) ?? [];

		return [...userIds];
	}, [record.users]);

	return (
		<Box ml={`-${theme.spacing['2xs']}`}>
			<OwnerSelector
				property="member"
				propertyLabel="Members"
				placeholder={'Add members'}
				forceVariant="tertiary"
				hideOnEmpty
				initialValue={ownerIds}
				key={ownerIds?.join('-')}
				onChangeUserOwners={onChangeUserOwners?.(record.id)}
			/>
		</Box>
	);
}
