import {
	baseQueryHooksFactory,
	createQueryKeys,
	prefetchFunctionsFactory,
} from '../../factories';
import type { ITemplate } from '../../types';

export const TEMPLATES_NAMESPACE = ['template', 'templates'];

export const templatesQueryKeyFactory = createQueryKeys(TEMPLATES_NAMESPACE);

const { prefetchTemplate, prefetchTemplateList } = prefetchFunctionsFactory(
	'template',
	templatesQueryKeyFactory
);

const {
	useTemplate,
	useTemplateInfiniteList,
	useTemplateList,
	useCreateTemplate,
	useDeleteTemplate,
	useUpdateTemplate,
	fetchTemplateList,
} = baseQueryHooksFactory<ITemplate, 'template'>(
	'template',
	templatesQueryKeyFactory
);

export {
	fetchTemplateList,
	prefetchTemplate,
	prefetchTemplateList,
	useCreateTemplate,
	useDeleteTemplate,
	useTemplate,
	useTemplateInfiniteList,
	useTemplateList,
	useUpdateTemplate,
};
