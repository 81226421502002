import { Center, Group, Stack } from '@mantine/core';
import { Dropzone } from '@mantine/dropzone';
import type { UseFormReturnType } from '@mantine/form';
import type { IMarketplaceIntegrationSpecVersion } from '@repo/common/models/marketplace';
import { Button, Text, Title } from '@repo/foundations';
import { IconTrash } from '@tabler/icons-react';
import { useSpecVersionFormStyles } from './MarketplaceIntegrationSpecVersionForm.helpers';
import type { MarketplaceIntegrationSpecVersionFormValues } from './MarketplaceIntegrationSpecVersionForm.hook';

export function CodeSection({
	form,
	disabled,
	existingVersion,
	onPreviewCode,
	onRemovePythonFile,
	onDropPythonFile,
	inSteps,
}: {
	form: UseFormReturnType<MarketplaceIntegrationSpecVersionFormValues>;
	disabled: boolean;
	existingVersion?: IMarketplaceIntegrationSpecVersion;
	onPreviewCode: VoidFunction;
	onRemovePythonFile: VoidFunction;
	onDropPythonFile: (files: File[]) => void;
	inSteps: boolean;
}) {
	const { classes } = useSpecVersionFormStyles();
	return (
		<>
			<Stack spacing="xs">
				<Title size="lg">
					{inSteps ? 'Next, add your python code' : 'Python code'}
				</Title>
				<Text size="sm" color="text/secondary/default">
					We will run your code to extract metadata and resources.
				</Text>
			</Stack>
			<Stack spacing="3xs">
				<Text weight="semibold" size="sm">
					Python file
				</Text>
				{disabled || form.values.code_file ? (
					<Group
						px="xs"
						py="sm"
						className={classes.pydropzone}
						position="apart"
					>
						<Text size="sm" weight="bold">
							{typeof form.values.code_file === 'object'
								? form.values.code_file?.name
								: existingVersion?.code_file_name}
						</Text>
						<Group spacing="xs">
							<Button size="sm" onClick={onPreviewCode}>
								View
							</Button>
							{!disabled && (
								<Button size="sm" onClick={onRemovePythonFile}>
									<IconTrash size={14} />
								</Button>
							)}
						</Group>
					</Group>
				) : (
					<Dropzone onDrop={onDropPythonFile} padding="xl" maxFiles={1}>
						<Center>
							<Stack spacing="xs">
								<Button>Add file</Button>
								<Text size="xs" color="text/secondary/default">
									Accept .py
								</Text>
							</Stack>
						</Center>
					</Dropzone>
				)}

				{form.errors.code_file}
			</Stack>
		</>
	);
}
