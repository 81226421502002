import type { TablerIconsProps } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { isNil } from 'lodash-es';
import React from 'react';
import type { IncidentStatus, Measurement } from '../../api';
import {
	ACKNOWLEDGE_ENABLED_STATES,
	INCIDENT_STATUS_MAPPING,
	RESOLVE_ENABLED_STATES,
} from './constants';

export const getIncidentStatusIcon = (
	status?: IncidentStatus,
	props: TablerIconsProps = {}
) => {
	const { icon, fill, color } = INCIDENT_STATUS_MAPPING[status || 'expired'];

	return React.createElement(icon, {
		size: 24,
		fill,
		color,
		...props,
	});
};

export const isAcknowledgeEnabled = (status?: IncidentStatus) => {
	if (!status) return false;

	return ACKNOWLEDGE_ENABLED_STATES.includes(status);
};

export const isResolveEnabled = (status?: IncidentStatus) => {
	if (!status) return false;

	return RESOLVE_ENABLED_STATES.includes(status);
};

export const getIncidentStatus = (
	metricType: string,
	lastMeasurement?: Measurement
) => {
	if (isNil(lastMeasurement)) {
		return '';
	}

	const {
		value,
		created_at: createdAt,
		upper_threshold: upperThreshold,
		lower_threshold: lowerThreshold,
	} = lastMeasurement;

	const incidentDate = dayjs(createdAt).utc().format('h:mm A [on] MMM D YYYY');
	const messagePrefix = `At ${incidentDate}, the ${metricType.toLocaleLowerCase()} value was ${Number(
		value
	).toFixed(2)} which`;

	if (isNil(upperThreshold) && isNil(lowerThreshold)) {
		return `${messagePrefix} exceeded the monitor threshold.`;
	}

	if (Number(value) > Number(upperThreshold)) {
		return `${messagePrefix} was higher than expected.`;
	}

	if (Number(value) < Number(lowerThreshold)) {
		return `${messagePrefix} was lower than expected.`;
	}

	return '';
};
