import { Center, createStyles } from '@mantine/core';
import { useNavigateHandler } from '@repo/common/hooks/useNavigate';
import { Button, Icon, Text } from '@repo/foundations';
import { WidgetType } from '../../../../../../../interfaces';
import { useHomepageTeam } from '../../../../../hooks/useHomepageTeam';
import { WIDGET_CONTENT_HEIGHT } from '../../../constants';
import type { ResourceWidgetTypes } from '../../../types';
import { getResourceEmptyInfo } from '../../../utils';
import type { IResourcesWidgetProps } from './ResourcesWidget';

export type IEmptyResourcesWidgetProps = {
	widgetType: ResourceWidgetTypes;
} & Pick<IResourcesWidgetProps, 'onWidgetTypeChange'>;

const useStyles = createStyles((theme) => ({
	emptyState: {
		height: WIDGET_CONTENT_HEIGHT,
		flexDirection: 'column',
		gap: theme.spacing.md,
	},
}));

function EmptyResourcesWidget({
	widgetType,
	onWidgetTypeChange,
}: IEmptyResourcesWidgetProps) {
	const { classes } = useStyles();
	const navigateHandler = useNavigateHandler();
	const { buttonLabel, message } = getResourceEmptyInfo(widgetType);
	const { isTeamViewerUser } = useHomepageTeam();

	const handleClick = (event: React.MouseEvent) => {
		if (
			[
				WidgetType.POPULAR_RESOURCES,
				WidgetType.RECENTLY_EDITED_RESOURCES,
				WidgetType.RECENTLY_ACCESSED_RESOURCES,
			].includes(widgetType)
		) {
			navigateHandler(event)('/catalog');
			return;
		}

		if (onWidgetTypeChange) {
			onWidgetTypeChange(WidgetType.RECENTLY_ACCESSED_RESOURCES);
		}
	};

	return (
		<Center className={classes.emptyState}>
			<Icon name="inbox" size="lg" />
			<Text align="center" color="text/secondary/default">
				{message}
			</Text>
			<Button disabled={isTeamViewerUser} onClick={handleClick}>
				{buttonLabel}
			</Button>
		</Center>
	);
}

export default EmptyResourcesWidget;
