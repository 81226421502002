import { Group, createStyles } from '@mantine/core';
import { colors } from '@repo/theme/primitives';
import { uuidv4 } from 'lib0/random';
import { useEffect, useMemo } from 'react';
import type { Automation } from '../../../../../api';
import { AutomationFilterOperator } from '../../../../../api';
import { useAutomationStore } from '../../../../../pages/AutomationPage/context';
import { isValidEnumValue } from '../../../../../utils/enumUtils';
import { AUTOMATION_FILTER_OPERATORS } from '../../AutomationActionCard/Filter/constants';
import { useAutomationActionCardFilterStore } from '../../AutomationActionCard/Filter/context';
import { getFilterSetForOperatorUpdate } from '../../AutomationActionCard/Filter/utils';
import AutomationCardButton from '../../AutomationCardButton';
import { AUTOMATION_TRIGGER_OPTIONS } from '../constants';

const useStyles = createStyles((theme) => ({
	group: {
		display: 'flex',
		alignSelf: 'stretch',
		background: colors.gray[1],
		width: '100%',
		gap: theme.spacing['2xs'],
	},
}));

interface AutomationTriggerCardTableDropHeaderTextProps {
	triggerLabel: string;
	filterSetSearchKey?: string;
	triggerChangeOperator: (option: string | boolean) => Promise<void>;
	handleAutomationUpdate: (automation: Partial<Automation>) => Promise<void>;
}

function AutomationTriggerCardTableDropHeaderText({
	triggerLabel,
	filterSetSearchKey,
	triggerChangeOperator,
	handleAutomationUpdate,
}: AutomationTriggerCardTableDropHeaderTextProps) {
	const { classes } = useStyles();
	const filterStore = useAutomationActionCardFilterStore();
	const automationStore = useAutomationStore();
	const { automation } = automationStore;

	useEffect(() => {
		if (automation?.filter_config) {
			const existingFilterSet = automation.filter_config.filter_sets.filter(
				(set) => set.key === filterSetSearchKey
			);

			if (existingFilterSet.length > 0) {
				filterStore.filterOperator = existingFilterSet[0].operator;
			}
		}
	}, [filterStore, filterSetSearchKey, automation?.filter_config]);

	const filterSet = useMemo(() => {
		if (filterSetSearchKey) {
			return (
				automation?.filter_config?.filter_sets.filter(
					(set) => set.key === filterSetSearchKey
				) ?? []
			);
		}

		if (!filterSetSearchKey && automation?.filter_config?.filter_sets) {
			return automation.filter_config.filter_sets;
		}

		return [];
	}, [automation, filterSetSearchKey]);

	const filterOperatorLabel = useMemo(() => {
		if (filterSet.length > 0) {
			return AUTOMATION_FILTER_OPERATORS.find(
				(operator) => operator.value === filterSet[0].operator
			)?.label;
		}

		return undefined;
	}, [filterSet]);

	const handleFilterOperatorOnClick = async (option: string | boolean) => {
		if (
			typeof option === 'string' &&
			isValidEnumValue(AutomationFilterOperator, option) &&
			automation
		) {
			const newFilterSetKey = uuidv4();
			const newFilterSets = getFilterSetForOperatorUpdate(
				option,
				automation,
				newFilterSetKey,
				filterSetSearchKey
			);

			await handleAutomationUpdate({
				filter_config: {
					...automation.filter_config,
					filter_sets: newFilterSets,
					operator:
						automation.filter_config?.operator ?? AutomationFilterOperator.AND,
				},
			});
			filterStore.filterOperator = option;

			// This should only occur when an automation was created in v1
			if (!filterSetSearchKey) {
				automationStore.updateKey(newFilterSetKey);
			}

			return;
		}

		throw new Error('Invalid automation operator has been provided');
	};

	return (
		<Group className={classes.group} noWrap>
			When
			<AutomationCardButton
				buttonLabel={triggerLabel}
				buttonOptions={AUTOMATION_TRIGGER_OPTIONS}
				onClick={triggerChangeOperator}
			/>
			that match
			<AutomationCardButton
				buttonLabel={
					filterOperatorLabel ?? AUTOMATION_FILTER_OPERATORS[0].label
				}
				buttonOptions={AUTOMATION_FILTER_OPERATORS}
				onClick={handleFilterOperatorOnClick}
			/>
			of the following filters
		</Group>
	);
}

export default AutomationTriggerCardTableDropHeaderText;
