// eslint-disable-next-line no-restricted-imports
import { Text, Tooltip } from '@mantine/core';
import type { typography } from '@repo/theme/primitives';
import { truncate } from 'lodash-es';

interface ITruncateTextProps {
	text: string;
	length: number;
	size: keyof typeof typography.title | keyof typeof typography.text;
	weight?: keyof typeof typography.weight;
	color?: string;
}

function TextComponent({
	text,
	length,
	size = 'md',
	weight,
	color,
}: ITruncateTextProps) {
	return (
		<Text size={size} weight={weight} color={color}>
			{truncate(text, { length })}
		</Text>
	);
}

export function TruncateText(props: ITruncateTextProps) {
	const { text, length } = props;

	if (text.length > length) {
		return (
			<Tooltip label={text} position="bottom-start">
				<TextComponent {...props} />
			</Tooltip>
		);
	}

	return <TextComponent {...props} />;
}
