import { Group, Stack } from '@mantine/core';
import { Handle, type NodeProps, Position } from 'reactflow';
import {
	EdgeHandle,
	type EntityNodeData,
	LineageDirectionEnum,
} from '../types';
import EntityChildren from './EntityChildren';
import { useStyles } from './EntityNode.styles';
import EntityNodeHeader from './EntityNodeHeader';
import LineageIndicator from './LineageIndicator';

function EntityNode({ data, selected }: NodeProps<EntityNodeData>) {
	const {
		id,
		numOfChildren,
		count,
		fetched,
		collapsed,
		connectable,
		direction,
		isChildrenOpen,
		isHighlighted,
	} = data;

	const { classes } = useStyles({
		selected,
		isHighlighted,
		isSourceConnectable: connectable.source,
		isTargetConnectable: connectable.target,
	});

	const isConnectable = connectable.source || connectable.target;

	return (
		<Group className={classes.wrapper}>
			<LineageIndicator
				id={id}
				count={count}
				fetched={fetched}
				collapsed={collapsed}
				direction={direction}
				indicatorDirection={LineageDirectionEnum.UPSTREAM}
			/>
			<Stack className={classes.contentWrapper}>
				<Handle
					className={classes.handle}
					id={EdgeHandle.MAIN_TARGET}
					type="target"
					position={Position.Left}
					isConnectable={isConnectable}
				/>
				<EntityNodeHeader data={data} selected={selected} />
				<EntityChildren
					id={id}
					numOfChildren={numOfChildren}
					isOpen={isChildrenOpen}
				/>
				<Handle
					className={classes.handle}
					id={EdgeHandle.MAIN_SOURCE}
					type="source"
					position={Position.Right}
					isConnectable={isConnectable}
				/>
			</Stack>
			<LineageIndicator
				id={id}
				count={count}
				fetched={fetched}
				collapsed={collapsed}
				direction={direction}
				indicatorDirection={LineageDirectionEnum.DOWNSTREAM}
			/>
		</Group>
	);
}

export default EntityNode;
