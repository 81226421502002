import { useQuery } from '@tanstack/react-query';
import { find, isNil, map } from 'lodash-es';
import { apiClient } from '../../common';
import type { ILookerProject } from '../../types/models/lookerProject';
import { useIntegrationSshKeyList } from '../integration/useIntegrationSshKey';
import { lookerProjectsQueryKeyFactory } from './constant';

interface IUseLookerProjectsProps {
	integrationID: string;
}

export const useLookerProjectList = ({
	integrationID,
}: IUseLookerProjectsProps) => {
	const { data: sshKeys } = useIntegrationSshKeyList({
		filters: {
			entity__integration__id: integrationID,
		},
		options: {
			select: ({ results }) => results,
		},
	});

	return useQuery<ILookerProject[], Error>(
		lookerProjectsQueryKeyFactory.list(1, {
			integration_id: integrationID,
		}),
		async () => {
			const { data } = await apiClient.get<ILookerProject[]>(
				'/databuilder/looker/projects',
				{
					params: {
						integration_id: integrationID,
					},
				}
			);

			return map(data, (project) => {
				const sshkey = find(sshKeys, { entity: project.entity });
				return {
					...project,
					ssh_key_id: sshkey?.id,
					public_key: sshkey?.public_key,
					is_used: sshkey?.is_used,
				};
			});
		},
		{
			enabled: !isNil(sshKeys),
		}
	);
};
