import { useMantineTheme } from '@mantine/core';
import { Text } from '@repo/foundations';
import { typography } from '@repo/theme/primitives';
import { isNil } from 'lodash-es';
import type { INotification } from '../../../../api/types/models/notification';
import { NotificationTitle } from '../../NotificationTitle';

interface IInboxViewTitleProps {
	selected?: INotification;
}

function InboxViewTitle({ selected }: IInboxViewTitleProps) {
	const theme = useMantineTheme();

	if (isNil(selected)) {
		return null;
	}

	return (
		<Text
			style={{
				fontSize: 32,
				fontWeight: typography.weight.regular,
				lineHeight: '36px',
				color: theme.colors.gray[9],
			}}
			data-testid="inbox-view-title-f2b106f16"
		>
			<NotificationTitle notification={selected} />
		</Text>
	);
}

export default InboxViewTitle;
