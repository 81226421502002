import { createStyles, Group, Stack } from '@mantine/core';
import { type ReactNode } from 'react';

const useStyles = createStyles((theme) => ({
	outerWrapper: {
		flexGrow: 1,
	},
	wrapper: {
		width: '100%',
		height: '100%',
		alignItems: 'flex-start',
		overflow: 'hidden',
	},
	contentWrapper: {
		width: '100%',
		height: '100vh',
		overflow: 'auto',
		scrollbarColor: `${theme.other.getColor(
			'fill/tertiary/default'
		)} transparent`,
		scrollbarWidth: 'thin',
	},
	content: {
		// Use `margin` instead of `padding` to avoid reducing the width of the content.
		marginLeft: 'auto',
		marginRight: 'auto',
		marginBottom: theme.spacing['2xl'],
		width: `calc(100% - (2 * ${theme.spacing['2xl']}))`,
		flexGrow: 1,
	},
}));

interface PageLayoutOuterWrapperProps {
	children?: ReactNode;
}

export function PageLayoutOuterWrapper({
	children,
}: PageLayoutOuterWrapperProps) {
	const { classes } = useStyles();

	return (
		<Stack className={classes.outerWrapper} spacing={0}>
			{children}
		</Stack>
	);
}

interface PageLayoutWrapperProps {
	children?: ReactNode;
	name: string;
}

export function PageLayoutWrapper({ children, name }: PageLayoutWrapperProps) {
	const { classes } = useStyles();

	return (
		<Group
			className={classes.wrapper}
			spacing={0}
			position="apart"
			noWrap
			data-testid={`${name}-layout--wrapper`}
		>
			{children}
		</Group>
	);
}

interface PageLayoutContentWrapperProps {
	children?: ReactNode;
	name: string;
}

export function PageLayoutContentWrapper({
	children,
	name,
}: PageLayoutContentWrapperProps) {
	const { classes } = useStyles();

	return (
		<Stack
			className={classes.contentWrapper}
			align="flex-start"
			spacing={0}
			data-testid={`${name}-layout--content-wrapper`}
		>
			{children}
		</Stack>
	);
}

interface PageLayoutContentProps {
	children?: ReactNode;
	className?: string;
}

export function PageLayoutContent({
	children,
	className,
}: PageLayoutContentProps) {
	const { cx, classes } = useStyles();

	return (
		<Stack className={cx(classes.content, className)} spacing={0}>
			{children}
		</Stack>
	);
}
