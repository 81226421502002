import { Avatar, Box, createStyles, Group, Tooltip } from '@mantine/core';
import type { SelectablePropertyItem } from '@repo/common/components/SingleSelector/types';
import { EntityType } from '@repo/common/enums/entityType';
import { useNavigate } from '@repo/common/hooks/useNavigate';
import { pickColor } from '@repo/common/utils';
import { Badge, IconButton, Text } from '@repo/foundations';
import { isNil, map, slice, upperFirst } from 'lodash-es';
import type { ReactNode } from 'react';
import IntegrationLogo from '../../../IntegrationLogo';
import { usePropertyStyles } from '../styles';
import type { StaticPropertyType } from './types';
import { isSelectableProperty, isSelectablePropertyArray } from './utils';

interface IStaticPropertyProps {
	type?: StaticPropertyType;
	label: string;
	value?: string | SelectablePropertyItem | SelectablePropertyItem[];
	tooltip?: string;
	forceInline?: boolean;
	wideLabel?: boolean;
	infoTooltip?: string;
	disableUserNavigation?: boolean;
	custom?: ReactNode;
}

const useStyles = createStyles(() => ({
	avatarRoot: {
		cursor: 'pointer',
		outlineWidth: 2,
		outlineStyle: 'solid',
		outlineColor: 'white',
	},
	sourceLogo: {
		background: 'transparent !important',
	},
}));

export function StaticProperty({
	type,
	label,
	value,
	tooltip,
	forceInline,
	wideLabel,
	infoTooltip,
	disableUserNavigation,
	custom,
}: IStaticPropertyProps) {
	const { classes, cx, theme } = useStyles();
	const { classes: propertyClasses } = usePropertyStyles();

	const navigate = useNavigate();

	let valueCol = null;

	const handleNavigateToUser = (userId: string) => {
		if (!disableUserNavigation) {
			// Navigate to user page
			navigate(`/user/${userId}`);
		}
	};

	const handleNavigateToTable = (tableId: string) => {
		// Navigate to table page
		navigate(`/table/${tableId}`);
	};

	if (type === 'text') {
		valueCol = (
			<Tooltip label={tooltip} withArrow withinPortal disabled={isNil(tooltip)}>
				<Box>
					<Text ml={0} size="sm" truncate>
						{value as ReactNode}
					</Text>
				</Box>
			</Tooltip>
		);
	}

	if (type === 'users' && isSelectablePropertyArray(value)) {
		valueCol = (
			<Avatar.Group spacing="xs">
				{map(slice(value, 0, 3), (item) => (
					<Tooltip key={`${item.value}`} label={item.label} withArrow>
						<Avatar
							classNames={{ root: classes.avatarRoot }}
							src={item.icon as string}
							size="xs"
							sx={{
								backgroundColor: pickColor(item.label),
							}}
							onClick={() => {
								handleNavigateToUser(item.value as string);
							}}
						>
							{(item.icon as ReactNode) || upperFirst(item.label?.slice(0, 1))}
						</Avatar>
					</Tooltip>
				))}
			</Avatar.Group>
		);
	}

	if (type === 'source') {
		valueCol = (
			<Tooltip label={tooltip} withArrow disabled={isNil(tooltip)}>
				<Group spacing={4}>
					<IntegrationLogo
						size={16}
						entityType={EntityType.integration}
						integrationType={value?.toString().toLowerCase()}
					/>
					<Text ml={0} size="sm">
						{value as ReactNode}
					</Text>
				</Group>
			</Tooltip>
		);
	}

	if (type === 'tables' && isSelectablePropertyArray(value)) {
		valueCol = (
			<Avatar.Group spacing="xs" ml={8}>
				{map(slice(value, 0, 3), (item) => (
					<Tooltip key={`${item.value}`} label={item.label} withArrow>
						<Avatar
							classNames={{ root: classes.avatarRoot }}
							size="xs"
							onClick={() => {
								handleNavigateToTable(item.value as string);
							}}
							className={classes.sourceLogo}
						>
							{item.icon as ReactNode}
						</Avatar>
					</Tooltip>
				))}
			</Avatar.Group>
		);
	}

	if (type === 'badge' && isSelectableProperty(value)) {
		valueCol = (
			<Badge
				my={0}
				color={value.color}
				backgroundColor={value.fill}
				leftSection={value.icon as ReactNode}
				radius="sm"
			>
				{value.label}
			</Badge>
		);
	}

	if (custom) {
		valueCol = custom;
	}

	return (
		<Group spacing={8} noWrap>
			<Group
				className={cx(propertyClasses.leftCol, {
					[propertyClasses.leftColForceInline]: Boolean(forceInline),
					[propertyClasses.leftColWide]: Boolean(wideLabel),
				})}
				noWrap
			>
				<Group spacing="xs" noWrap>
					<Text size="sm" color="text/secondary/default" lineClamp={1}>
						{label}
					</Text>
					{infoTooltip && (
						<IconButton
							iconName="infoCircle"
							tooltip={infoTooltip}
							variant="tertiary"
							tooltipProps={{
								withinPortal: true,
								w: theme.other.space[90],
								multiline: true,
							}}
						/>
					)}
				</Group>
			</Group>
			<Group className={propertyClasses.rightCol}>{valueCol}</Group>
		</Group>
	);
}
