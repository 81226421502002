import {
	Box,
	Center,
	createStyles,
	Group,
	HoverCard,
	Image,
	Stack,
} from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import type { LineageJob, LineageJobMetadata } from '@repo/api-codegen';
import { useNavigate } from '@repo/common/hooks';
import { Badge, Text, Title } from '@repo/foundations';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { useCallback } from 'react';
import { BaseEdge, EdgeLabelRenderer } from 'reactflow';
import type { IIntegration } from '../../../api/index.ts';
import { iconSrcFromIntegration } from '../../../utils/integrationLogo.tsx';
import { isTestRunSuccessful } from '../EntityNode/EntityNodeHeader/utils.ts';

dayjs.extend(advancedFormat);

interface EntityEdgeWithJobProps {
	id: string;
	path: string;
	labelX: number;
	labelY: number;
	markerEnd?: string;
	style: {
		stroke: string;
		strokeWidth: number;
	};
	isHovered: boolean;
	metadata: LineageJobMetadata;
}

type EntityEdgeWithJobStylesParams = Pick<
	EntityEdgeWithJobProps,
	'labelX' | 'labelY'
> & {
	isHovered: boolean;
};

const useStyles = createStyles(
	(theme, { labelX, labelY, isHovered }: EntityEdgeWithJobStylesParams) => ({
		container: {
			position: 'absolute',
			transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
			pointerEvents: 'all',
			zIndex: isHovered ? 1 : 0,
		},
		jobIndicator: {
			width: theme.other.space[1.5],
			height: theme.other.space[1.5],
			borderRadius: theme.other.space[0.5],
			backgroundColor: theme.other.getColor('border/tertiary/default'),
			position: 'relative',
		},
		icon: {
			backgroundColor: theme.other.getColor('fill/primary/default'),
			width: theme.other.space[7],
			height: theme.other.space[7],
			borderWidth: theme.other.space[0.125],
			borderStyle: 'solid',
			borderColor: theme.other.getColor('border/primary/default'),
			borderRadius: theme.radius.sm,
			padding: `${theme.other.space[1.5]}px 0`,
			position: 'relative',
		},
		dropdown: {
			padding: theme.spacing['2xs'],
			borderWidth: theme.other.space[0.125],
			borderStyle: 'solid',
			borderColor: theme.other.getColor('border/primary/default'),
			borderRadius: theme.radius.md,
			boxShadow:
				'0px 4px 8px 0px rgba(0, 0, 0, 0.04), 0px 2px 6px 0px rgba(0, 0, 0, 0.04), 0px 1px 1px 0px rgba(0, 0, 0, 0.04)',
			overflow: 'clip',
		},
		dropdownWrapper: {
			cursor: 'pointer',
			justifyContent: 'space-between',
			flexWrap: 'nowrap',
			padding: `${theme.spacing['2xs']} ${theme.spacing.xs}`,
			backgroundColor: theme.other.getColor('surface/primary/default'),
			borderRadius: theme.radius.sm,
			'&:hover': {
				backgroundColor: theme.other.getColor('surface/primary/hover'),
			},
			'&:active': {
				backgroundColor: theme.other.getColor('surface/primary/active'),
			},
		},
	})
);

export function EntityEdgeWithJob({
	id,
	path,
	labelX,
	labelY,
	markerEnd,
	style,
	isHovered,
	metadata,
}: EntityEdgeWithJobProps) {
	const navigate = useNavigate();

	const [debouncedIsHovered] = useDebouncedValue(isHovered, 100);
	const { classes, cx } = useStyles({
		labelX,
		labelY,
		isHovered: debouncedIsHovered,
	});

	const isSuccess = useCallback(
		(job: LineageJob) =>
			job.last_status && isTestRunSuccessful(job.last_status),
		[]
	);

	const handleClickJobName = useCallback(
		(job: LineageJob) => {
			navigate(`/job/${job.id}`);
		},
		[navigate]
	);

	return (
		<>
			<BaseEdge id={id} path={path} markerEnd={markerEnd} style={style} />
			<EdgeLabelRenderer>
				<Box className={cx(classes.container, 'nodrag', 'nopan')}>
					{debouncedIsHovered ? (
						<HoverCard
							classNames={{
								dropdown: classes.dropdown,
							}}
							width={360}
						>
							<HoverCard.Target>
								<Center className={classes.icon}>
									<Image
										width={16}
										height={16}
										src={iconSrcFromIntegration({
											id: metadata.jobs[0].integration_id,
											type: metadata.jobs[0].integration_type,
										} as IIntegration)}
									/>
								</Center>
							</HoverCard.Target>
							<HoverCard.Dropdown>
								<Stack spacing={0}>
									{metadata.jobs.map((job) => {
										const lastRun = job.last_run_date
											? dayjs(job.last_run_date).format(
													'MMM D, YYYY [at] h:mmA'
												)
											: null;

										const subtext = lastRun
											? `Job · Last run ${lastRun}`
											: 'Job';

										return (
											<Stack key={job.id} spacing={0}>
												<Group
													className={classes.dropdownWrapper}
													position="apart"
													noWrap
												>
													<Group spacing="sm" noWrap>
														<Image
															width={16}
															height={16}
															src={iconSrcFromIntegration({
																id: job.integration_id,
																type: job.integration_type,
															} as IIntegration)}
														/>
														<Stack spacing={0}>
															<Title
																size="sm"
																onClick={() => handleClickJobName(job)}
															>
																{job.name}
															</Title>
															<Text size="xs" color="text/secondary/default">
																{subtext}
															</Text>
														</Stack>
													</Group>
													<Badge
														variant={isSuccess(job) ? 'success' : 'critical'}
													>
														{job.last_status}
													</Badge>
												</Group>
											</Stack>
										);
									})}
								</Stack>
							</HoverCard.Dropdown>
						</HoverCard>
					) : (
						<Box className={classes.jobIndicator} />
					)}
				</Box>
			</EdgeLabelRenderer>
		</>
	);
}
