import { ThemeIcon } from '@mantine/core';
import { Icon } from '@repo/foundations';
import type { IInternalDatabuilderJobStatus } from '../../../api/types/models/internalIntegrationStatus';

export function InternalJobStatusIcon({
	jobStatus,
}: {
	jobStatus: IInternalDatabuilderJobStatus;
}) {
	if (jobStatus.status === 'succeeded') {
		return (
			<ThemeIcon color="green">
				<Icon name="check" />
			</ThemeIcon>
		);
	}
	if (jobStatus.status === 'failed') {
		return (
			<ThemeIcon color="red">
				<Icon name="exclamationMark" />
			</ThemeIcon>
		);
	}
	if (jobStatus.status === 'running') {
		return (
			<ThemeIcon color="blue">
				<Icon name="run" />
			</ThemeIcon>
		);
	}

	if (jobStatus.status === 'dangling') {
		return (
			<ThemeIcon color="yellow">
				<Icon name="exclamationMarkOff" />
			</ThemeIcon>
		);
	}

	if (jobStatus.status === 'reserved') {
		return (
			<ThemeIcon color="purple">
				<Icon name="refresh" />
			</ThemeIcon>
		);
	}

	// Terminated
	return (
		<ThemeIcon color="gray">
			<Icon name="exclamationMarkOff" />
		</ThemeIcon>
	);
}
