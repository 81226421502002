import { Box, createStyles } from '@mantine/core';
import type React from 'react';

interface IInlineEmojiWrapperStyleProps {
	highlight: boolean;
}

const useStyles = createStyles(
	(theme, { highlight }: IInlineEmojiWrapperStyleProps) => ({
		root: {
			backgroundColor: highlight ? theme.colors.primary[0] : theme.white,
			border: highlight
				? `1px solid ${theme.colors.primary[3]}`
				: `1px solid ${theme.colors.gray[3]}`,
			gap: `calc(${theme.spacing.xs} / 2)`,
			display: 'inline-flex',
			cursor: 'pointer',
			alignItems: 'center',
			justifyContent: 'center',
			borderRadius: `calc(${theme.radius.sm} * 4)`,
			position: 'relative',
			paddingTop: `calc(${theme.spacing.xs} / 4)`,
			paddingBottom: `calc(${theme.spacing.xs} / 4)`,
			paddingRight: theme.spacing.xs,
			paddingLeft: theme.spacing.xs,
		},
	})
);

interface IInlineEmojiWrapperProps {
	children: React.ReactNode | React.ReactNode[];
	highlight: boolean;
	onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export function InlineEmojiWrapper({
	children,
	highlight,
	onClick,
}: IInlineEmojiWrapperProps) {
	const { classes } = useStyles({ highlight });
	return (
		<Box onClick={onClick} className={classes.root}>
			{children}
		</Box>
	);
}
