/* eslint-disable no-nested-ternary */
// eslint-disable-next-line no-restricted-imports
import { Box, Divider, Group, Skeleton, Stack, Text } from '@mantine/core';
import { Button } from '@repo/foundations';
import { Fragment } from 'react';
import { useTagList } from '../../api';
import { closeAllModals, openModal } from '../ModalManager';
import TagCard from './TagSettings/Card/TagCard';
import { TagModal } from './TagSettings/Modal/TagModal';
import { useStyles } from './TagSettings/TagSettings.styles';

interface TagOptionsModalProps {
	customPropertyId: string | undefined;
	propertyLabel: string;
}

export function TagOptionsModal({
	customPropertyId,
	propertyLabel,
}: TagOptionsModalProps) {
	const { classes, theme } = useStyles();

	const { data: tags, isLoading } = useTagList({
		filters: {
			custom_property: customPropertyId,
		},
		options: {
			select: (data) => data.results,
		},
	});

	const handleOpenTagModal = () => {
		openModal({
			title: 'Create option',
			children: (
				<TagModal
					customPropertyId={customPropertyId}
					key={`option-modal-${propertyLabel}`}
					allTags={tags?.map((tag) => tag.name) || []}
					onClose={closeAllModals}
				/>
			),
		});
	};

	return (
		<Stack>
			<Text size="xs" mb={0} color="text/secondary/default">
				Manage options for this custom property
			</Text>
			<Box className={classes.list}>
				{isLoading ? (
					<Stack p="xs" spacing="xs">
						<Skeleton height={20} radius="sm" />
						<Skeleton height={20} radius="sm" />
						<Skeleton height={20} radius="sm" />
					</Stack>
				) : tags?.length ? (
					tags.map((tag, idx) => (
						<Fragment key={tag.id}>
							<TagCard
								propertyLabel={'option'}
								customPropertyId={customPropertyId}
								key={tag.id}
								tag={tag}
								// eslint-disable-next-line @typescript-eslint/no-shadow
								allTags={tags?.map((tag) => tag.id) || []}
							/>
							{idx !== tags.length - 1 && <Divider />}
						</Fragment>
					))
				) : (
					<Text color="dimmed" align="center" py="md">
						No options added yet. Click the button below to add your first
						option.
					</Text>
				)}
			</Box>
			<Box>
				<Button onClick={handleOpenTagModal}>Add option</Button>
			</Box>
			<Divider my={0} py={0} px={'md'} mx={`-${theme.spacing.md}`} />
			<Group position="right">
				<Button variant="primary" onClick={() => closeAllModals()}>
					Close
				</Button>
			</Group>
		</Stack>
	);
}
