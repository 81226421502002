import { Stack } from '@mantine/core';
import { EntityType } from '@repo/common/enums/entityType';
import { Button } from '@repo/foundations';
import { observer } from 'mobx-react-lite';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';
import type { IQuestion, ISecodaEntity } from '../../api';
import {
	useAuthUser,
	useIntegrationList,
	useQuestionInfiniteList,
} from '../../api';
import type { FetchModelInfiniteListHook } from '../../api/factories/types';
import type { EntityTabsStore } from '../../pages/TableEntityPage/TableEntityTabs/TableEntityTabsStore';
import { useColumns } from '../../pages/TeamQuestionsPage/TeamQuestionsPage.hooks';
import { entityModalStore } from '../EntityModal/store';
import { TableV2 } from '../TableV2';
import type { OnCellClickHandlerParams } from '../TableV2/types';
import { getDiscussionTeamIds } from './discussionTeams';

export interface IEntityDiscussionsProps {
	entity: ISecodaEntity;
	store?: EntityTabsStore;
}

const COLUMN_VISIBILITY = {
	catalogType: EntityType.question,
	catalogServerType: EntityType.question,
} as const;

function EntityDiscussions({ entity, store }: IEntityDiscussionsProps) {
	const { user } = useAuthUser();
	const integrations = useIntegrationList({});
	const navigate = useNavigate();

	const columns = useColumns();

	const handleQuestionClick = useCallback(
		({ column, record }: OnCellClickHandlerParams<IQuestion>) => {
			if (!column.navigate) {
				return;
			}
			navigate(`/questions/${record.id}`);
		},
		[navigate]
	);

	const handleButtonClick = useCallback(() => {
		entityModalStore.open({
			type: EntityType.question,
			relatedResource: entity,
			teamIds: getDiscussionTeamIds(
				entity as ISecodaEntity,
				integrations.data?.results || []
			),
			metadata: {
				owners: [user.id],
			},
		});
	}, [entity, integrations.data?.results, user.id]);

	const defaultSearchParams = useMemo(
		() => ({
			entity_id: entity.id,
		}),
		[entity.id]
	);

	return (
		<Stack>
			<TableV2<IQuestion>
				pluralTypeString="questions"
				onTotalRowCountChange={(count) => {
					store?.setDiscussionCount?.(count);
				}}
				withCsvExport
				withInfiniteScroll
				usePaginationList={
					useQuestionInfiniteList as FetchModelInfiniteListHook<IQuestion>
				}
				onCellClick={handleQuestionClick}
				columns={columns}
				withSearch={false}
				withFilters={false}
				columnVisibility={COLUMN_VISIBILITY}
				defaultRequiredSearchParams={defaultSearchParams}
				withAdditionalButtons={
					<Button
						variant="primary"
						leftIconName="messageCircleQuestion"
						onClick={handleButtonClick}
						data-testid="entity-discussions-create-question-button"
					>
						Create a question
					</Button>
				}
			/>
		</Stack>
	);
}

export default observer(EntityDiscussions);
