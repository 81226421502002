import { Menu } from '@mantine/core';
import { Icon } from '@repo/foundations';
import { useCallback } from 'react';
import type { ISecodaEntity } from '../../../../api';
import type { SecodaEntity } from '../../../../lib/models';
import { downloadEntityFile } from '../helper';

interface ExportActionProps {
	entity: SecodaEntity | ISecodaEntity;
}

export function ExportAction({ entity }: ExportActionProps) {
	const handleJSONExportClick = useCallback(() => {
		downloadEntityFile(entity, 'json');
	}, [entity]);

	const handleCSVExportClick = useCallback(() => {
		downloadEntityFile(entity, 'csv');
	}, [entity]);

	const handlePrintClick = useCallback(() => {
		try {
			// @ts-expect-error - document.execCommand is deprecated, used in Safari
			if (!document.execCommand('print', false, null)) {
				window.print();
			}
		} catch {
			window.print();
		}
	}, []);

	return (
		<>
			<Menu.Item icon={<Icon name="csv" />} onClick={handleCSVExportClick}>
				Export CSV
			</Menu.Item>
			<Menu.Item icon={<Icon name="json" />} onClick={handleJSONExportClick}>
				Export JSON
			</Menu.Item>
			<Menu.Item icon={<Icon name="pdf" />} onClick={handlePrintClick}>
				Export PDF
			</Menu.Item>
		</>
	);
}
