import type { SelectablePropertyItem } from '@repo/common/components/SingleSelector/types';
import { AutomationActionType, AutomationTarget } from '../../../../../api';

export const AUTOMATION_ACTION_TARGETS: SelectablePropertyItem[] = [
	{
		label: 'Resources',
		value: AutomationTarget.SELF,
		iconName: 'wand',
	},
	{
		label: 'Parent',
		value: AutomationTarget.PARENT,
		iconName: 'wand',
	},
	{
		label: 'Children',
		value: AutomationTarget.CHILD,
		iconName: 'wand',
	},
];

export const AUTOMATION_ACTION_TARGETS_DISPLAY_MAP: {
	[key in AutomationTarget]: string;
} = {
	[AutomationTarget.SELF]: 'Resources',
	[AutomationTarget.PARENT]: 'Parent',
	[AutomationTarget.CHILD]: 'Children',
};

export const AUTOMATION_ACTION_TYPE_OPTIONS: SelectablePropertyItem[] = [
	{
		label: 'Add owner',
		value: AutomationActionType.OWNERS_ADD,
	},
	{
		label: 'Remove owner',
		value: AutomationActionType.OWNERS_REMOVE,
	},
	{
		label: 'Replace owner',
		value: AutomationActionType.OWNERS_REPLACE,
	},
	{
		label: 'Remove all owners',
		value: AutomationActionType.OWNERS_CLEAR,
	},
	{
		label: 'Add tag',
		value: AutomationActionType.TAGS_ADD,
	},
	{
		label: 'Remove tag',
		value: AutomationActionType.TAGS_REMOVE,
	},
	{
		label: 'Replace tag',
		value: AutomationActionType.TAGS_REPLACE,
	},
	{
		label: 'Remove all tags',
		value: AutomationActionType.TAGS_CLEAR,
	},
	{
		label: 'Set documentation',
		value: AutomationActionType.DEFINITION_SET,
	},
	{
		label: 'Clear documentation',
		value: AutomationActionType.DEFINITION_CLEAR,
	},
	{
		label: 'Set description',
		value: AutomationActionType.DESCRIPTION_SET,
	},
	{
		label: 'Clear description',
		value: AutomationActionType.DESCRIPTION_CLEAR,
	},
	{
		label: 'Mark as PII',
		value: AutomationActionType.PII_TRUE,
	},
	{
		label: 'Remove PII',
		value: AutomationActionType.PII_FALSE,
	},
	{
		label: 'Mark as verified',
		value: AutomationActionType.VERIFIED_TRUE,
	},
	{
		label: 'Remove verified',
		value: AutomationActionType.VERIFIED_FALSE,
	},
	{
		label: 'Mark as published',
		value: AutomationActionType.PUBLISHED_TRUE,
	},
	{
		label: 'Mark as draft',
		value: AutomationActionType.PUBLISHED_FALSE,
	},
	{
		label: 'Add team',
		value: AutomationActionType.TEAMS_ADD,
	},
	{
		label: 'Remove team',
		value: AutomationActionType.TEAMS_REMOVE,
	},
	{
		label: 'Replace team',
		value: AutomationActionType.TEAMS_REPLACE,
	},
	{
		label: 'Remove all teams',
		value: AutomationActionType.TEAMS_CLEAR,
	},
	{
		label: 'Add all teams',
		value: AutomationActionType.TEAMS_ADD_ALL,
	},
	{
		label: 'Add collection',
		value: AutomationActionType.COLLECTIONS_ADD,
	},
	{
		label: 'Remove collection',
		value: AutomationActionType.COLLECTIONS_REMOVE,
	},
	{
		label: 'Replace collection',
		value: AutomationActionType.COLLECTIONS_REPLACE,
	},
	{
		label: 'Remove all collections',
		value: AutomationActionType.COLLECTIONS_CLEAR,
	},
	{
		label: 'Add subscriber',
		value: AutomationActionType.SUBSCRIBERS_ADD,
	},
	{
		label: 'Remove subscriber',
		value: AutomationActionType.SUBSCRIBERS_REMOVE,
	},
	{
		label: 'Replace subscriber',
		value: AutomationActionType.SUBSCRIBERS_REPLACE,
	},
	{
		label: 'Remove all subscribers',
		value: AutomationActionType.SUBSCRIBERS_CLEAR,
	},
];
