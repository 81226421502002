import { Accordion, createStyles } from '@mantine/core';
import type { PropsWithChildren } from 'react';

const useStyles = createStyles((theme) => ({
	accordion: {
		borderTop: `1px solid ${theme.other.getColor('border/secondary/default')}`,

		'.mantine-Accordion-item:last-child:not([data-active]) .mantine-Accordion-control':
			{
				borderBottomLeftRadius: theme.radius.md,
				borderBottomRightRadius: theme.radius.md,
			},

		'.mantine-Accordion-item:last-child .mantine-Accordion-control': {
			borderBottom: 0,
		},

		'.mantine-Accordion-item .mantine-Accordion-control:disabled': {
			opacity: 1,
		},
	},
	item: {
		width: '100%',
		border: 0,
	},
	label: {
		paddingTop: theme.spacing.sm,
		paddingBottom: theme.spacing.sm,
	},
	control: {
		paddingLeft: theme.spacing.md,
		paddingRight: theme.spacing.md,
		borderBottom: `1px solid ${theme.other.getColor('border/secondary/default')}`,
		justifyContent: 'space-between',
		gap: theme.spacing.xs,
		backgroundColor: theme.other.getColor('surface/secondary/default'),

		'&:hover, &:focus': {
			backgroundColor: theme.other.getColor('surface/secondary/hover'),
		},

		'&:active': {
			backgroundColor: theme.other.getColor('surface/secondary/active'),
		},
	},
	chevron: {
		margin: 0,
		color: theme.other.getColor('icon/secondary/default'),

		'&[data-rotate]': {
			color: theme.other.getColor('icon/primary/default'),
		},
	},
	content: {
		padding: theme.spacing.xs,
	},
}));

interface DataQualityBreakdownBodyProps {}

export function DataQualityBreakdownBody({
	children,
}: PropsWithChildren<DataQualityBreakdownBodyProps>) {
	const { classes } = useStyles();

	return (
		<Accordion
			className={classes.accordion}
			classNames={{
				item: classes.item,
				label: classes.label,
				control: classes.control,
				chevron: classes.chevron,
				content: classes.content,
			}}
		>
			{children}
		</Accordion>
	);
}
