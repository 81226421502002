import { filter } from 'lodash-es';
import { useMemo } from 'react';
import type { ISecodaEntity } from '../../api';
import type { ExtendedDataTableColumn } from '../../components/TableV2/types';

import { resourceCatalogQueryKeyFactory } from '../../api/hooks/resourceCatalog/constants';
import {
	useGenericActions,
	useGenericColumns,
} from '../../components/TableV2/SecodaEntity.hooks';
import { useParentAction } from '../TableEntityPage/Actions/hooks';

export const useColumns = (): ExtendedDataTableColumn<ISecodaEntity>[] => {
	const genericColumns = useGenericColumns({});

	const columns: ExtendedDataTableColumn<ISecodaEntity>[] = useMemo(
		() => [
			...filter(genericColumns, (column) => column.accessor !== 'description'),
		],
		[genericColumns]
	);

	return columns;
};

export const useActions = () => {
	const genericActions = useGenericActions();
	const parentAction = useParentAction(resourceCatalogQueryKeyFactory.all());
	const actions = useMemo(
		() => [
			parentAction,
			...filter(genericActions, (action) => action.id !== 'actions::ai'),
		],
		[genericActions, parentAction]
	);
	return actions;
};
