import { createStyles, Drawer } from '@mantine/core';
import { observer } from 'mobx-react-lite';
import EntityDrawerContent from './EntityDrawerContent';
import store from './store';

const useStyles = createStyles({
	drawerBody: {
		width: '100%',
		height: '100%',
		overflowY: 'auto',
		display: 'flex',
		padding: 0,
	},
});

function EntityDrawer() {
	const { classes } = useStyles();

	return (
		<Drawer
			classNames={{
				body: classes.drawerBody,
			}}
			position="right"
			opened={store.isOpen}
			onClose={store.closeEntityDrawer}
			size={480}
			withinPortal
			withCloseButton={false}
		>
			<EntityDrawerContent />
		</Drawer>
	);
}

export default observer(EntityDrawer);
