import type { IWidget } from '../../types';
import { useDeleteBaseModel } from '../base';
import { WIDGETS_NAMESPACE } from './constants';

interface IDeleteWidgetParams {
	id: string;
	reportId?: string;
}

export function useDeleteWidget() {
	return useDeleteBaseModel<IWidget, IDeleteWidgetParams>({
		namespace: WIDGETS_NAMESPACE,
	});
}
