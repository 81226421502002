import { useState } from 'react';
import type { Automation } from '../../../../../../api';
import { useAutomationStore } from '../../../../../../pages/AutomationPage/context';
import type { AutomationActionCardOnChangeProps } from '../AutomationActionCardNotificationContent';
import AutomationActionCardNotificationContent from '../AutomationActionCardNotificationContent';

interface AutomationActionCardSlackContentProps {
	handleAutomationUpdate: (automation: Partial<Automation>) => Promise<void>;
}

function AutomationActionCardSlackContent({
	handleAutomationUpdate,
}: AutomationActionCardSlackContentProps) {
	const { automation } = useAutomationStore();

	const [checked, setChecked] = useState<boolean>(
		automation?.action_send_slack_message?.send_summary ?? true
	);
	const [body, setBody] = useState<string | undefined>(
		automation?.action_send_slack_message?.body
	);

	const handleOnCheckboxChange = async (newChecked: boolean) => {
		setChecked(newChecked);

		await handleAutomationUpdate({
			action_send_slack_message: {
				...automation?.action_send_slack_message,
				send_summary: newChecked,
			},
		});
	};

	const onChange = async ({
		body: newBody,
	}: AutomationActionCardOnChangeProps) => {
		setBody(newBody);
		await handleAutomationUpdate({
			action_send_slack_message: {
				...automation?.action_send_slack_message,
				body: newBody,
				send_summary: checked,
			},
		});
	};

	return (
		<AutomationActionCardNotificationContent
			checked={checked}
			initialBody={body}
			onCheckboxChange={handleOnCheckboxChange}
			onChange={onChange}
			includeSubject={false}
		/>
	);
}

export default AutomationActionCardSlackContent;
