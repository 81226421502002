import { Box, Card, Group, Stack } from '@mantine/core';
import { EntityType } from '@repo/common/enums/entityType';
import { Badge, Text } from '@repo/foundations';
import type { BuiltinIntegrationSpec } from '../../interfaces/IntegrationSpec';
import IntegrationLogo from '../IntegrationLogo';

import { useSpecCardStyles } from './IntegrationSpecCard.helpers';

export function BuiltinIntegrationSpecCard({
	spec,
	onClick,
}: {
	spec: BuiltinIntegrationSpec;
	onClick: VoidFunction;
}) {
	const { classes } = useSpecCardStyles();
	const { name, type, isBeta, description } = spec;

	return (
		<Card variant="clickable" className={classes.card} onClick={onClick}>
			<Stack h="100%" spacing="sm">
				<Box className={classes.logoWrapper}>
					<IntegrationLogo
						size={16}
						entityType={EntityType.integration}
						integrationType={type}
					/>
				</Box>
				<Stack spacing="xs">
					<Group spacing="xs" noWrap>
						<Text size="sm" weight="bold">
							{name}
						</Text>
						{isBeta && (
							<Badge size="xs" m={0}>
								Beta
							</Badge>
						)}
					</Group>
					<Text size="xs">{description}</Text>
				</Stack>
				<Stack sx={{ flexGrow: 1 }} justify="end">
					<Text size="xs" color="text/secondary/default">
						Created by Secoda
					</Text>
				</Stack>
			</Stack>
		</Card>
	);
}
