import type { EdgeProps } from 'reactflow';
import { BaseEdge, EdgeText } from 'reactflow';

export function SelfConnectingEdge({
	label,
	labelStyle,
	markerEnd,
	sourceX,
	sourceY,
	targetX,
	targetY,
	...rest
}: EdgeProps) {
	const radiusX = (sourceX - targetX) * 0.6;
	const radiusY = 50;
	const edgePath = `M ${sourceX - 5} ${sourceY} A ${radiusX} ${radiusY} 0 1 0 ${
		targetX + 2
	} ${targetY}`;

	return (
		<>
			<BaseEdge path={edgePath} markerEnd={markerEnd} {...rest} />
			<EdgeText
				x={sourceX - 75}
				y={sourceY - 80}
				label={label}
				labelStyle={{ ...(labelStyle || {}), fill: 'black' }}
				labelShowBg
				labelBgStyle={{ fill: 'white' }}
				labelBgPadding={[2, 4]}
				labelBgBorderRadius={2}
			/>
		</>
	);
}
