import { Divider, Group, Stack } from '@mantine/core';
import { Icon } from '@repo/foundations';
import { isNil } from 'lodash-es';
import { useMemo } from 'react';
import type { INotification } from '../../../../../api/types/models/notification';
import { useStyles } from '../styles';

import Columns from './Columns';

interface ITableSchemaChangeContentProps {
	selected: INotification;
}

function SchemaTablesDroppedContent({
	selected,
}: ITableSchemaChangeContentProps) {
	const { classes } = useStyles();

	const changes = useMemo(
		() =>
			(selected.metadata as Record<string, Record<string, string | string[]>>)
				.changes,
		[selected.metadata.changes]
	);

	if (isNil(selected.metadata.changes)) {
		return null;
	}

	return (
		<Stack spacing="lg" data-testid="inbox-view-table-dropped-uaapBF2">
			<Divider />
			<Group spacing="xs" align="flex-start">
				<Group className={classes.leftCol} spacing="xs">
					<Icon name="columns" />
					Dropped Tables
				</Group>
				<Columns columns={changes.deleted_tables as string[]} />
			</Group>
		</Stack>
	);
}

export default SchemaTablesDroppedContent;
