export type SelectorValue = string;

export interface SelectorItemType {
	label: string;
	value: SelectorValue;
	icon?: React.ReactNode;
}

export enum SelectType {
	Multiple = 'multiple',
	Single = 'single',
}
