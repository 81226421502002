import { makeAutoObservable } from 'mobx';

import { invalidateNotificationListQuery } from '../../api/hooks/notification/utils';
import { deleteParamsFromUrl } from '../../utils/url';

export class InboxPageStore {
	showRead = true;

	showSearch = false;

	filterDropdownHeaderMenuVisible = false;

	toggleShowRead = () => {
		this.showRead = !this.showRead;
		deleteParamsFromUrl('id');
		invalidateNotificationListQuery();
	};

	setShowSearch = (showSearch: boolean) => {
		this.showSearch = showSearch;
	};

	setFilterDropdownHeaderMenuVisible = (
		filterDropdownHeaderMenuVisible: boolean
	) => {
		this.filterDropdownHeaderMenuVisible = filterDropdownHeaderMenuVisible;
	};

	constructor() {
		makeAutoObservable(this);
	}
}

export const inboxPageStore = new InboxPageStore();
