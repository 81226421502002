import { useCallback } from 'react';

import { Group, Image, Tooltip, useMantineTheme } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { Prism } from '@mantine/prism';
import type { MarketplaceIntegrationSpecVersionOut } from '@repo/api-codegen';
import { Button, Text } from '@repo/foundations';
import { BoldTextRender } from '../../components/TableV2/render';

import type { IMarketplaceIntegrationSpecVersion } from '@repo/common/models/marketplace';
import {
	useChangeSubmissionStateMarketplaceIntegrationSpecVersion,
	useMarketplaceIntegrationSpec,
	useMarketplaceIntegrationSpecVersionCode,
} from '../../api/hooks/marketplace';
import { openConfirmModal } from '../../components/ModalManager';
import { ScrollableModal } from '../../components/ScrollableModal/ScrollableModal';

export function CodeRender({
	record,
}: {
	record: MarketplaceIntegrationSpecVersionOut;
}) {
	const [
		codePreviewModalOpened,
		{ open: openCodePreviewModal, close: closeCodePreviewModal },
	] = useDisclosure(false);

	const { data: codeContent } = useMarketplaceIntegrationSpecVersionCode(
		record.spec_id,
		record.id
	);

	return (
		<>
			<ScrollableModal
				size={720}
				title="Preview"
				modalProps={{
					keepMounted: false,
				}}
				opened={codePreviewModalOpened}
				onClose={closeCodePreviewModal}
			>
				<Prism language="python">{codeContent || ''}</Prism>
			</ScrollableModal>
			<Button
				leftIconName="code"
				onClick={() => {
					openCodePreviewModal();
				}}
			>
				Preview code
			</Button>
		</>
	);
}

export function VersionActionRender({
	record,
}: {
	record: MarketplaceIntegrationSpecVersionOut;
}) {
	const theme = useMantineTheme();

	const { data: spec } = useMarketplaceIntegrationSpec({
		id: record.spec_id,
	});

	const { mutateAsync: apiVersionSubmission, isLoading } =
		useChangeSubmissionStateMarketplaceIntegrationSpecVersion(
			record as unknown as IMarketplaceIntegrationSpecVersion
		);

	const hasSubmittedVersion = spec?.versions.some(
		(v) => v.publish_status === 'SUBMITTED'
	);

	const withdrawSubmission = useCallback(async () => {
		openConfirmModal({
			sx: {
				paddingTop: `${theme.spacing.md} !important`,
				paddingBottom: `${theme.spacing.md} !important`,
			},
			title: (
				<Text size="md" fw={600}>
					Do you want to withdraw this submission?
				</Text>
			),
			children: (
				<Text size="sm">
					Once withdrawn, you will be able to make changes to this version.
				</Text>
			),
			labels: { confirm: 'Withdraw', cancel: 'Cancel' },
			cancelProps: {
				size: 'md',
			},
			confirmProps: {
				size: 'md',
				variant: 'primary',
			},

			onConfirm: async () => {
				await apiVersionSubmission('withdraw');
			},
		});
	}, [apiVersionSubmission, theme.spacing.md]);

	const submitForReview = useCallback(async () => {
		openConfirmModal({
			sx: {
				paddingTop: `${theme.spacing.md} !important`,
				paddingBottom: `${theme.spacing.md} !important`,
			},
			title: (
				<Text size="md" fw={600}>
					Do you want to submit this version for review?
				</Text>
			),
			children: (
				<Text size="sm">
					Once submitted, you will not be able to make any changes.
				</Text>
			),
			labels: { confirm: 'Submit', cancel: 'Cancel' },
			cancelProps: {
				size: 'md',
			},
			confirmProps: {
				size: 'md',
				variant: 'primary',
			},

			onConfirm: async () => {
				await apiVersionSubmission('submit');
			},
		});
	}, [apiVersionSubmission, theme.spacing.md]);

	if (
		record.publish_status === 'APPROVED' ||
		record.publish_status === 'REJECTED'
	) {
		return <Button>Review feedback</Button>;
	}

	if (record.publish_status === 'DRAFT') {
		if (hasSubmittedVersion) {
			return (
				<Tooltip label="You can only submit 1 version">
					<Button
						disabled
						loading={isLoading}
						onClick={(event) => event.preventDefault()}
					>
						Submit for review
					</Button>
				</Tooltip>
			);
		}
		return (
			<Button variant="primary" onClick={submitForReview} loading={isLoading}>
				Submit for review
			</Button>
		);
	}

	return (
		<Button onClick={withdrawSubmission} loading={isLoading}>
			Withdraw submission
		</Button>
	);
}

export function TitleWithImageRender({
	record,
}: {
	record: MarketplaceIntegrationSpecVersionOut;
}) {
	return (
		<Group noWrap>
			<Image src={record.icon_url} radius="sm" width={28} height={28} />
			<BoldTextRender<MarketplaceIntegrationSpecVersionOut>
				record={record}
				field="name"
			/>
		</Group>
	);
}
