import { Box, Divider, Group, TextInput, useMantineTheme } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { Button, Text, Title } from '@repo/foundations';
import { useDebounceFn } from 'ahooks';
import axios from 'axios';
import { useFormik } from 'formik';
import { size } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import {
	queryClient,
	useAuthUser,
	usersQueryKeyFactory,
	useUpdateUser,
} from '../../api';
import { useExtendedUserList } from '../../api/hooks/user/useExtendedUserList';
import { thisUserQueryKey } from '../../api/hooks/user/useThisUser';
import { useUserProfileImage } from '../../api/hooks/user/useUserProfileImage';
import { ImageUpload } from '../ImageUpload/ImageUpload';
import { openModal } from '../ModalManager';
import { Section } from './WorkspaceSettings';

interface IProfileInitialValues {
	first_name: string;
	last_name: string;
	profile_picture?: File;
}

export const ProfileSettings = observer(() => {
	const { activeUsers } = useExtendedUserList();
	const theme = useMantineTheme();

	const { mutateAsync: updateProfileImage } = useUserProfileImage();
	const { mutateAsync } = useUpdateUser({
		options: {
			onSuccess: () => {
				queryClient.invalidateQueries(usersQueryKeyFactory.byId(user.id));
				queryClient.invalidateQueries(thisUserQueryKey);
			},
		},
	});

	const { user, workspace } = useAuthUser();

	const handleNotification = useCallback((updatedVal: string) => {
		showNotification({
			title: `${updatedVal} updated`,
			message: 'Your profile has been updated.',
			color: 'green',
			autoClose: 1500,
		});
	}, []);

	const { run: debounceFn } = useDebounceFn(
		(values: IProfileInitialValues) => {
			mutateAsync({
				data: {
					id: user.id,
					first_name: values.first_name,
					last_name: values.last_name,
				},
			}).then(async () => {
				if (
					user.first_name !== values.first_name ||
					user.last_name !== values.last_name
				) {
					handleNotification('Profile updated');
				} else if (values.profile_picture) {
					await updateProfileImage({
						file: values.profile_picture,
					});
				}
			});
		},
		{
			wait: 700,
		}
	);

	const formik = useFormik<IProfileInitialValues>({
		initialValues: {
			first_name: user!.first_name,
			last_name: user!.last_name,
		},
		enableReinitialize: true,
		onSubmit: async (values) => {
			debounceFn(values);
		},
	});

	const handleLeaveWorkspaceModal = useCallback(() => {
		const handleLeave = async () => {
			await axios.post(`${workspace.apiPath}leave_workspace/`, {});
			window.location.reload();
		};

		openModal({
			title: 'Are you sure you want to leave this workspace?',
			children: (
				<Button
					data-testid="leave-workspace-modal"
					variant="primary"
					tone="critical"
					onClick={handleLeave}
				>
					Leave workspace
				</Button>
			),
		});
	}, [workspace]);

	const onUpload = useCallback(
		(file: File) => {
			formik.setFieldValue('profile_picture', file);
			formik.handleSubmit();
		},
		[formik]
	);

	return (
		<Box>
			<form
				onChange={formik.handleSubmit}
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: '10px',
					margin: '35px 0',
				}}
			>
				<ImageUpload
					callback={onUpload}
					label="Profile image"
					renderAvatar
					placeholder={
						user.profile_picture ??
						'/images/auth-image/profile-placeholder.webp'
					}
				/>
				<TextInput
					onChange={formik.handleChange}
					value={formik.values.first_name}
					mt="10px"
					label="First name"
					name="first_name"
				/>
				<TextInput
					onChange={formik.handleChange}
					value={formik.values.last_name}
					label="Last name"
					name="last_name"
				/>
			</form>
			<Divider pb={theme.spacing.xl} />
			{size(activeUsers) > 1 && (
				<Section title="Workspace">
					<>
						<Title mt={24} my={16} order={3} weight="semibold" size="md">
							Leave workspace
						</Title>
						<Text color="text/secondary/default" size="sm" mb={16}>
							Leaving this workspace will remove you from this workspace all
							together. You will not be able to access this workspace again. You
							can delete this workspace if you are the only member.
						</Text>
						<Group>
							<Button
								data-testid="leave-workspace-inline"
								onClick={handleLeaveWorkspaceModal}
								tone="critical"
							>
								Leave workspace
							</Button>
						</Group>
					</>
				</Section>
			)}
		</Box>
	);
});
