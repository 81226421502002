import { Box, Flex, useMantineTheme } from '@mantine/core';
import { useNavigate } from '@repo/common/hooks/useNavigate';
import { Icon, Text } from '@repo/foundations';
import { range } from 'lodash-es';
import moment from 'moment';
import React from 'react';
import { useCollection } from '../../api';

export function InlineIconText(props: { children: React.ReactNode }) {
	const { children } = props;
	return (
		<Flex gap={2} align="center">
			{children}
		</Flex>
	);
}

export function Schema(props: { schema: string }) {
	const { schema } = props;

	return (
		<InlineIconText>
			<Icon name="schema" />
			<Text
				size="xs"
				dangerouslySetInnerHTML={{ __html: schema }} // Search highlighting sometimes sends an annotated schema back with html
			/>
		</InlineIconText>
	);
}

export function Database(props: { database: string }) {
	const { database } = props;

	return (
		<InlineIconText>
			<Icon name="database" />
			<Text color="text/secondary/default" size="xs">
				{`${database}`}
			</Text>
		</InlineIconText>
	);
}

export function Group(props: { group: string }) {
	const { group } = props;

	return (
		<InlineIconText>
			<Icon name="chartBar" />
			<Text color="text/secondary/default" size="xs">
				{`${group}`}
			</Text>
		</InlineIconText>
	);
}

export function Role(props: { role: string }) {
	const { role } = props;

	return (
		<InlineIconText>
			<Icon name="user" />
			<Text color="text/secondary/default" size="xs">
				{`${role}`}
			</Text>
		</InlineIconText>
	);
}

export function Email(props: { email: string }) {
	const { email } = props;
	return (
		<InlineIconText>
			<Icon name="mail" />
			<Text color="text/secondary/default" size="xs">
				{`${email}`}
			</Text>
		</InlineIconText>
	);
}

export function Time(props: { prefix: string; timestamp: string }) {
	const { timestamp, prefix } = props;

	return (
		<InlineIconText>
			<Icon name="clock" />
			<Text color="text/secondary/default" size="xs">
				{`${prefix} ${moment(timestamp).fromNow()}`}
			</Text>
		</InlineIconText>
	);
}

function GreenCircle() {
	const theme = useMantineTheme();

	return (
		<Box
			style={{
				width: theme.other.space[2],
				height: theme.other.space[2],
				borderRadius: theme.other.space[2],
				backgroundColor: theme.other.getColor('icon/primary/default'),
			}}
		/>
	);
}

function GrayCircle() {
	const theme = useMantineTheme();
	return (
		<Box
			style={{
				width: theme.other.space[2],
				height: theme.other.space[2],
				borderRadius: theme.other.space[2],
				backgroundColor: theme.other.getColor('icon/primary/disabled'),
			}}
		/>
	);
}

// eslint-disable-next-line prefer-arrow-callback
export const Popularity = React.memo(function Popularity(props: {
	level: number;
}) {
	const { level } = props;
	const theme = useMantineTheme();

	return (
		<Flex gap={theme.other.space[0.5]} align="center">
			{range(4).map((v) =>
				v + 1 > level ? <GrayCircle key={v} /> : <GreenCircle key={v} />
			)}
		</Flex>
	);
});

export function SearchListItemIcon(props: {
	icon: JSX.Element;
	badge?: JSX.Element;
}) {
	const { icon, badge } = props;
	return (
		<Box style={{ position: 'relative' }}>
			<Box>{icon}</Box>
			{badge && <Box>{badge}</Box>}
		</Box>
	);
}

export function SearchListItem({ collectionId }: { collectionId: string }) {
	const { data: collection } = useCollection({ id: collectionId });
	const navigate = useNavigate();

	if (!collection) {
		return null;
	}

	return (
		<Box
			mx="5px"
			onClick={(e) => {
				e.preventDefault();
				e.stopPropagation();
				navigate(`/collections/${collectionId}`);
			}}
			key={collectionId}
			style={{
				cursor: 'pointer',
			}}
		>
			#{collection?.title}
		</Box>
	);
}

interface SearchListItemTemplateProps {
	title: JSX.Element;
	type: string;
	collections: string[];
	informationBar: JSX.Element;
	icon: JSX.Element;
	body: JSX.Element;
	handleClick?: () => void;
}

export function SearchListItemTemplate(props: SearchListItemTemplateProps) {
	const { handleClick, title, type, collections, informationBar, icon, body } =
		props;

	return (
		<Flex
			onClick={handleClick}
			direction="row"
			justify="flex-start"
			align="space-around"
			gap={6}
		>
			<Box mt={1} style={{ minWidth: '30px' }}>
				{icon}
			</Box>
			<Flex direction="column">
				<Box mb={0.5}>{title}</Box>
				<Box mb={1}>
					<Flex>
						<Text color="text/secondary/default" size="xs" ta="left">
							{type}
						</Text>
						{collections?.map((collectionId) => (
							<SearchListItem key={collectionId} collectionId={collectionId} />
						))}
					</Flex>
				</Box>
				<Box mb={1.5}>{informationBar}</Box>
				<Box>
					<Text color="text/secondary/default" size="xs" lineClamp={2}>
						{body}
					</Text>
				</Box>
			</Flex>
		</Flex>
	);
}
