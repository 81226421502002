import { Box, Stack, createStyles } from '@mantine/core';
import type { PropsWithChildren, ReactNode } from 'react';

const useStyles = createStyles((theme) => ({
	wrapper: {
		position: 'relative',
	},
	detail: {
		gap: theme.spacing['3xs'],
		alignItems: 'center',
		justifyContent: 'center',
		position: 'absolute',
		width: '100%',
		height: '100%',
		top: 0,
		left: 0,
		pointerEvents: 'none', // so users can hover the chart and interact with it (the detail div is on top of it and would prevent it otherwise)

		'& > *': {
			// so users can hover/copy the text inside the detail div
			pointerEvents: 'initial',
		},
	},
}));

interface QualityScoreRingBodyProps {
	progressIndicator: ReactNode;
}

export function QualityScoreRingBody({
	progressIndicator,
	children,
}: PropsWithChildren<QualityScoreRingBodyProps>) {
	const { classes } = useStyles();

	return (
		<Box className={classes.wrapper}>
			{progressIndicator}
			<Stack className={classes.detail}>{children}</Stack>
		</Box>
	);
}
