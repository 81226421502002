import { forEach } from 'lodash-es';
import { useCallback } from 'react';
import { matchPath, useNavigate } from 'react-router';

// Utility function to create new path based on pattern and params
function createNewPath(
	pattern: string,
	params: Record<string, string | undefined>
) {
	let newPath = pattern;
	forEach(params, (value, key) => {
		newPath = newPath.replace(`:${key}`, String(value));
	});

	return newPath;
}

export function useTabNavigate() {
	const navigate = useNavigate();

	const navigateToTab = useCallback(
		(tab: string) => {
			const pagePattern = '/:pagePrefix/:id';
			const pagePatternWithTab = `${pagePattern}/:tab`;
			const currentPath = window.location.pathname;
			const match =
				matchPath(pagePattern, currentPath) ??
				matchPath(pagePatternWithTab, currentPath);

			if (!match) {
				return;
			}

			const newPath = createNewPath(pagePatternWithTab, {
				...match.params,
				tab,
			});

			navigate(newPath);
		},
		[navigate]
	);

	return navigateToTab;
}

export function useListPageTabNavigate() {
	const navigate = useNavigate();

	const navigateToTab = useCallback(
		(tab: string) => {
			const pagePattern = '/:pagePrefix';
			const pagePatternWithTab = `${pagePattern}/:tab`;
			const currentPath = window.location.pathname;
			const match =
				matchPath(pagePattern, currentPath) ??
				matchPath(pagePatternWithTab, currentPath);

			if (!match) {
				return;
			}

			const newPath = createNewPath(pagePatternWithTab, {
				...match.params,
				tab,
			});

			navigate(newPath);
		},
		[navigate]
	);

	return navigateToTab;
}
