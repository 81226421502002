import type { TextProps as MantineTextProps } from '@mantine/core';
// eslint-disable-next-line no-restricted-imports
import { Text as MantineText, useMantineTheme } from '@mantine/core';
import { typography } from '@repo/theme/primitives';
import type { ColorNames } from '@repo/theme/utils';
import type { AriaRole, ForwardedRef } from 'react';
import { forwardRef, memo, useMemo } from 'react';

interface SecodaTextProps {
	children?: React.ReactNode;
	dangerouslySetInnerHTML?: {
		__html: string;
	};
	size?: keyof typeof typography.title | keyof typeof typography.text;
	weight?: keyof typeof typography.weight;
	color?: ColorNames;
	nonThemeColor?: string;
	role?: AriaRole;
	tabIndex?: number;
}

export type TextProps = SecodaTextProps &
	Omit<MantineTextProps, keyof SecodaTextProps | 'weight'>;

export const Text = memo(
	forwardRef(
		(
			{
				children,
				size = 'md',
				weight = 'regular',
				color,
				nonThemeColor,
				...others
			}: TextProps,
			ref: ForwardedRef<HTMLDivElement>
		) => {
			const theme = useMantineTheme();

			const textColor = useMemo(
				() =>
					color
						? theme.other.getColor(color)
						: nonThemeColor || theme.other.getColor('text/primary/default'),
				[color, nonThemeColor, theme]
			);

			const fontWeight = useMemo(() => typography.weight[weight], [weight]);

			return (
				<MantineText
					ref={ref}
					size={size}
					fw={fontWeight}
					color={textColor}
					{...others}
				>
					{children}
				</MantineText>
			);
		}
	)
);
Text.displayName = 'Text';
