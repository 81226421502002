import { Skeleton, useMantineTheme } from '@mantine/core';

function LoadingTable() {
	const theme = useMantineTheme();

	return (
		<Skeleton
			m={theme.spacing.xl}
			height={theme.other.space[160]}
			width="100%"
		/>
	);
}

export default LoadingTable;
