import type { SelectablePropertyItem } from '@repo/common/components/SingleSelector/types';
import type { IconNames } from '@repo/foundations';
import { ScheduleCadence } from '../../api/types/models/schedule';

export const SCHEDULE_CADENCE_OPTIONS: SelectablePropertyItem[] = [
	{
		label: ScheduleCadence.HOURLY,
		value: ScheduleCadence.HOURLY,
		iconName: 'repeat',
	},
	{
		label: ScheduleCadence.DAILY,
		value: ScheduleCadence.DAILY,
		iconName: 'repeat',
	},
	{
		label: ScheduleCadence.WEEKLY,
		value: ScheduleCadence.WEEKLY,
		iconName: 'repeat',
	},
];

export const SCHEDULE_HOURLY_OPTIONS: Array<{
	label: string;
	value: string;
	iconName: IconNames;
}> = [
	{ label: 'Every hour', value: '1', iconName: 'clock' },
	{ label: 'Every 3 hours', value: '3', iconName: 'clock' },
	{ label: 'Every 6 hours', value: '6', iconName: 'clock' },
	{ label: 'Every 12 hours', value: '12', iconName: 'clock' },
];
