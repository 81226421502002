import { Group, Tooltip } from '@mantine/core';
import { Text } from '@repo/foundations';
import type { ILineage } from '../../../../api';
import { getMetadata } from './utils';

interface IImpactAnalysisCardMetadataProps {
	entity: ILineage;
}

export function ImpactAnalysisCardMetadata({
	entity,
}: IImpactAnalysisCardMetadataProps) {
	const { label, metadata } = getMetadata(entity);

	return (
		<Group>
			<Tooltip label={metadata}>
				<Text size="sm" color="text/secondary/default" truncate>
					{metadata ? `${label} · ${metadata}` : label}
				</Text>
			</Tooltip>
		</Group>
	);
}
