import {
	createStyles,
	LoadingOverlay,
	Stack,
	useMantineTheme,
} from '@mantine/core';
import { integrationList } from '@repo/common/constants/integration/integrations';
import { useNavigate } from '@repo/common/hooks/useNavigate';
import {
	integrationsQueryKeyFactory,
	useAuthUser,
	useCreateIntegration,
} from '../../api';
import { useMarketplaceIntegrationSpecList } from '../../api/hooks/marketplace';
import { useWorkspaceEnv } from '../../api/hooks/workspace/useWorkspaceEnv';
import { IntegrationSpecCard } from '../../components/IntegrationSpecCard/IntegrationSpecCard';
import { toTitleCase } from '../../components/SearchListItem/helpers';
import WidgetSelector from '../../components/WidgetSelector';
import type { WidgetData } from '../../components/WidgetSelector/types';
import { usePlan } from '../../hooks/usePlans';
import type { IntegrationSpec } from '../../interfaces/IntegrationSpec';
import { IS_LOCAL_OR_DEVELOPMENT } from '../../utils/envs';

const useStyles = createStyles((theme) => ({
	selectorViewWrapper: {
		paddingBottom: 0,
	},
	widgetItemsWrapper: {
		columnGap: theme.spacing.lg,
		rowGap: theme.spacing.md,
	},
	widgetWrapper: {
		paddingBottom: 0,
	},
}));

export function BrowseTab() {
	const theme = useMantineTheme();

	const { classes } = useStyles();
	const { isPayingCustomer } = usePlan();

	const { data: workspaceEnv } = useWorkspaceEnv();

	const navigate = useNavigate();
	const { isLoading, mutateAsync: apiCreateIntegration } = useCreateIntegration(
		{
			invalidationKeys: [integrationsQueryKeyFactory.allLists()],
		}
	);
	const { workspace } = useAuthUser();
	const marketplaceEnabled =
		IS_LOCAL_OR_DEVELOPMENT || Boolean(workspace.marketplace_enabled);

	const { data: marketplaceIntegrationSpecs } =
		useMarketplaceIntegrationSpecList({
			filters: {
				browsing: true,
			},
			options: {
				select: (res) => res.results,
			},
		});

	const builtinIntegrationSpecsData = integrationList
		.filter(
			(integration) =>
				!integration.hidden &&
				(workspaceEnv?.slack_enabled || integration.type !== 'slack')
		)
		.map((integration) => ({
			group: integration.group,
			title: integration.name,
			data: { type: 'builtin', value: integration } as IntegrationSpec,
		}));

	const marketplaceIntegrationSpecsData = (marketplaceIntegrationSpecs || [])
		.filter((spec) => spec.versions.length > 0)
		.map((spec) => ({
			group: toTitleCase(spec.versions[0].category.toLowerCase()).replace(
				'_',
				' '
			),
			title: spec.versions[0].name,
			data: { type: 'marketplace', value: spec } as IntegrationSpec,
			key: spec.id,
		}));

	const data = [
		...builtinIntegrationSpecsData,
		...(marketplaceEnabled ? marketplaceIntegrationSpecsData : []),
	];

	const createCustomIntegration = async () => {
		if (!IS_LOCAL_OR_DEVELOPMENT && !isPayingCustomer) {
			// Open https://feedback.secoda.co/integrations in new tab.
			window.open('https://feedback.secoda.co/integrations', '_blank');
			return;
		}

		const integration = await apiCreateIntegration({
			data: {
				name: 'Untitled',
				type: 'custom',
				credentials: {},
			},
		});
		navigate(`/integrations/${integration.id}/`);
	};

	const handleIntegrationSelect = async (
		widgetData: WidgetData<IntegrationSpec>
	) => {
		if (widgetData.data.type === 'builtin') {
			if (widgetData.data.value.type === 'custom') {
				await createCustomIntegration();
				return;
			}
			navigate(`/integrations/new?type=${widgetData.data.value.type}`);
		} else {
			navigate(
				`/integrations/new?selectedMarketplaceSpec=${widgetData.data.value.id}`
			);
		}
	};

	return (
		<Stack spacing={0}>
			<LoadingOverlay
				visible={isLoading}
				overlayBlur={2}
				loaderProps={{ color: theme.other.neutralColors.black }}
			/>

			<WidgetSelector<IntegrationSpec>
				classNames={{ selectorViewWrapper: classes.selectorViewWrapper }}
				data={data}
				onWidgetSelect={handleIntegrationSelect}
				widgetGroupProps={{
					classNames: {
						widgetItemsWrapper: classes.widgetItemsWrapper,
					},
				}}
				scrollAreaHeight={`calc(100vh - ${theme.other.calculateSpace(54)})`}
				renderer={IntegrationSpecCard}
				cols={3}
			/>
		</Stack>
	);
}
