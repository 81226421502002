import { Group } from '@mantine/core';
import { Title } from '@repo/foundations';
import { observer } from 'mobx-react-lite';
import {
	NavBarBreadcrumbWrapper,
	NavBarRightSideWrapper,
	NavBarWrapper,
} from '.';
import { useAuthUser } from '../../../api';
import AddMonitorButton from '../../AddMonitor/AddMonitorButton';

function MonitorListPageNavBar() {
	const { workspace } = useAuthUser();

	if (!workspace?.monitoring_enabled) {
		return null;
	}

	return (
		<NavBarWrapper>
			<NavBarBreadcrumbWrapper>
				<Group>
					<Title order={1} size="sm" data-testid="monitors-page-nav-bar-title">
						Monitors
					</Title>
				</Group>
			</NavBarBreadcrumbWrapper>
			<NavBarRightSideWrapper>
				<AddMonitorButton />
			</NavBarRightSideWrapper>
		</NavBarWrapper>
	);
}

export default observer(MonitorListPageNavBar);
