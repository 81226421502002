import type { MarkdownSerializerState } from '@repo/secoda-editor/lib/markdown/serializer';
import type { Node as ProsemirrorNode } from 'prosemirror-model';
import Node from './Node';

export default class Text extends Node {
	get name() {
		return 'text';
	}

	get schema() {
		return {
			group: 'inline',
		};
	}

	toMarkdown(state: MarkdownSerializerState, node: ProsemirrorNode) {
		state.text(node.text ?? '', true);
	}
}
