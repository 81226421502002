import { Stack } from '@mantine/core';
import type { DataQualityDatapoint } from '@repo/api-codegen';
import { Text } from '@repo/foundations';
import dayjs from 'dayjs';
import { ChartTooltip } from '../../Chart/components/ChartTooltip';
import { DataQualityDetailsCard } from '../DataQualityDetailsCard';
import { DataQualityDetailsGroup } from '../DataQualityDetailsCard/DataQualityDetailsCardGroup';
import type { QualityFilterKey } from '../types';

export interface TimeseriesTooltipProps {
	data: DataQualityDatapoint;
	dataQualityKey: QualityFilterKey;
	x: number;
	y: number;
}

export function TimeseriesTooltip({
	data,
	dataQualityKey,
	x,
	y,
}: TimeseriesTooltipProps) {
	return (
		<ChartTooltip x={x} y={y}>
			<Stack spacing={0}>
				<Text size="sm" color="text/secondary/default" px="sm" pt="sm">
					{dayjs.utc(data.timestamp).format('MMM D, YYYY')}
				</Text>
				{dataQualityKey === 'total' ? (
					<DataQualityDetailsCard
						data={data}
						direction="vertical"
						scoreType="percentage"
					/>
				) : (
					<Stack p="md">
						<DataQualityDetailsGroup
							score={data[dataQualityKey]}
							scoreType="percentage"
							dataQualityKey={dataQualityKey}
						/>
					</Stack>
				)}
			</Stack>
		</ChartTooltip>
	);
}
