import { Container, Group, createStyles } from '@mantine/core';
import { Text } from '@repo/foundations';
import { isNil } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import { useMemo, useState } from 'react';
import type { Automation, AutomationAction } from '../../../../../api';
import { AutomationActionType } from '../../../../../api';
import { isValidEnumValue } from '../../../../../utils/enumUtils';
import { getFieldFromActionType } from '../../../utils';
import AutomationCardDeleteButton from '../../AutomationCardDeleteButton';
import AutomationCardValueInput from '../../AutomationCardValueInput';
import type { AutomationValueType } from '../../constants';
import { getAutomationValueType } from '../../constants';
import { shouldExcludeValuePromptForActionType } from '../utils';
import AutomationActionCardEditTypeButton from './AutomationActionCardEditTypeButton';
import { useAutomationActionCardEditStore } from './context';

interface AutomationActionCardEditPropertyProps {
	searchKey: string;
	type?: AutomationActionType;
	display?: string;
	value?: string | boolean;
	isLastElement: boolean;
	handleAutomationUpdate: (automation: Partial<Automation>) => Promise<void>;
}

const useStyles = createStyles((theme) => ({
	container: {
		display: 'flex',
		justifyContent: 'space-between',
		gap: theme.spacing.xs,
		margin: 0,
		padding: 0,
		flex: '1 0 0',
		width: '100%',
		maxWidth: 'none',
	},
	innerContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		gap: theme.spacing.xs,
	},
	group: {
		gap: theme.spacing.xs,
	},
	topGroup: {
		width: 'auto',
		flexWrap: 'nowrap',
		alignItems: 'flex-start',
		gap: theme.spacing.xs,
		margin: 0,
		padding: 0,
		overflow: 'hidden',
	},
	bottomGroup: {
		alignItems: 'flex-end',
		gap: theme.spacing.xs,
		height: '100%',
		lineHeight: '28px',
	},
	text: {
		lineHeight: '28px',
	},
}));

function AutomationActionCardEditProperty({
	searchKey,
	type,
	display,
	value,
	isLastElement,
	handleAutomationUpdate,
}: AutomationActionCardEditPropertyProps) {
	const { classes } = useStyles();
	const store = useAutomationActionCardEditStore();

	const field = useMemo(
		() => (type ? getFieldFromActionType(type) : undefined),
		[type]
	);

	const shouldDisplayValue = useMemo(
		() => type && !shouldExcludeValuePromptForActionType(type),
		[type]
	);

	const initialValueType = getAutomationValueType(field);

	const [valueType, setValueType] =
		useState<AutomationValueType>(initialValueType);

	// Update DB for all actions that have all field, values, and displays
	// Need to check is isNil because empty strings are supported
	const handleActionUpdate = async () => {
		await handleAutomationUpdate({
			actions: store.actions
				.filter(
					(action) =>
						!isNil(action.type) &&
						!isNil(action.searchKey) &&
						!isNil(action.value)
				)
				.map((action) => ({
					key: action.searchKey,
					type: action.type,
					value: action.value,
					display: action.display,
				})) as AutomationAction[],
		});
	};

	// OnClick functions
	const handleDeleteAction = () => {
		store.deleteAction(searchKey);

		handleActionUpdate();
	};

	const handleValueChange = (val: string | boolean, label?: string) => {
		if (typeof val === 'string') {
			store.setActionValue(searchKey, val, label);

			handleActionUpdate();
		}
	};

	const handleActionTypeChange = (option: string | boolean) => {
		if (isValidEnumValue(AutomationActionType, option)) {
			store.setActionType(searchKey, option);

			const newField = getFieldFromActionType(option);
			setValueType(getAutomationValueType(newField));

			// Need to set the value to an empty string if the action type
			// is to clear or to set pii or verified
			if (shouldExcludeValuePromptForActionType(option)) {
				handleValueChange('', '');
			}
		}
	};

	return (
		<Container className={classes.container}>
			<Group className={classes.group}>
				<Group className={classes.topGroup}>
					<AutomationActionCardEditTypeButton
						type={type}
						handleActionTypeChange={handleActionTypeChange}
					/>
					{shouldDisplayValue && (
						<AutomationCardValueInput
							valueType={valueType}
							display={display}
							value={value}
							field={field}
							handleValueChange={handleValueChange}
						/>
					)}
				</Group>
				<Group className={classes.bottomGroup}>
					{!isLastElement && <Text className={classes.text}>and</Text>}
				</Group>
			</Group>
			<AutomationCardDeleteButton handleDelete={handleDeleteAction} />
		</Container>
	);
}

export default observer(AutomationActionCardEditProperty);
