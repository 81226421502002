import { Group, createStyles } from '@mantine/core';
import { colors } from '@repo/theme/primitives';
import type { Automation } from '../../../api';
import { isValidEnumValue } from '../../../utils/enumUtils';
import { AutomationActionCardType } from '../constants';
import AutomationActionCardHeaderText from './AutomationActionCard/AutomationActionCardHeaderText';
import AutomationTriggerCardHeaderText from './AutomationTriggerCard/AutomationTriggerCardHeaderText';
import type { AutomationCardType } from './constants';
import { DefaultAutomationCardType } from './constants';

const useStyles = createStyles((theme) => ({
	group: {
		padding: theme.spacing.md,
		gap: theme.spacing.sm,
		background: colors.gray[1],
		width: '100%',
		borderRadius: `${theme.radius.md} ${theme.radius.md} 0px 0px`,
		maxWidth: '100%',
	},
}));

interface AutomationCardHeaderProps {
	icon?: JSX.Element;
	cardId: string;
	type: AutomationCardType;
	searchKey?: string;
	isNthFilterResourceCard?: boolean;
	isSecondFilterResourceCard?: boolean;
	handleAutomationUpdate: (automation: Partial<Automation>) => Promise<void>;
}

function AutomationCardHeader({
	icon,
	cardId,
	type,
	searchKey,
	isNthFilterResourceCard,
	isSecondFilterResourceCard,
	handleAutomationUpdate,
}: AutomationCardHeaderProps) {
	const { classes } = useStyles();

	return (
		<Group className={classes.group} noWrap>
			{icon}
			{type === DefaultAutomationCardType.TRIGGER && (
				<AutomationTriggerCardHeaderText
					searchKey={searchKey}
					handleAutomationUpdate={handleAutomationUpdate}
				/>
			)}
			{isValidEnumValue(AutomationActionCardType, type) &&
				[
					AutomationActionCardType.FILTER_RESOURCES,
					AutomationActionCardType.EDIT_RESOURCES,
					AutomationActionCardType.SEND_ANNOUNCEMENT,
					AutomationActionCardType.SEND_EMAIL,
					AutomationActionCardType.SEND_SLACK_MESSAGE,
					AutomationActionCardType.PROPAGATE_METADATA,
				].includes(type) && (
					<AutomationActionCardHeaderText
						cardId={cardId}
						type={type}
						searchKey={searchKey}
						handleAutomationUpdate={handleAutomationUpdate}
						isNthFilterResourceCard={isNthFilterResourceCard}
						isSecondFilterResourceCard={isSecondFilterResourceCard}
					/>
				)}
		</Group>
	);
}

export default AutomationCardHeader;
