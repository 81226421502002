import { useSortable } from '@dnd-kit/sortable';
import type { MantineTheme } from '@mantine/core';
import { Box, createStyles, Group, Stack } from '@mantine/core';
import { motion } from 'framer-motion';
import { type WidgetSize } from '../../../interfaces';
import { getAnimation, getTransition, getWidgetSpan } from '../utils/utils';

const useStyles = createStyles(
	(
		theme: MantineTheme,
		params: {
			size: WidgetSize;
			isDragging: boolean;
		}
	) => ({
		container: {
			minHeight: 364,
			minWidth: 364,
			borderRadius: theme.radius.md,
			borderWidth: 1,
			borderStyle: 'solid',
			borderColor: theme.colors.gray[2],
			gridColumn: getWidgetSpan(params.size),
			backgroundColor: theme.other.getColor('fill/primary/default'),
			boxShadow: params.isDragging ? theme.shadows.lg : undefined,
			cursor: 'default!important',
		},
		header: {
			cursor: params.isDragging ? 'grabbing' : 'grab',
			flex: '1 1 auto',
			minWidth: 0,
			maxWidth: '100%',
		},
		titleStack: {
			minWidth: 0,
			maxWidth: '100%',
			flex: '1 1 auto',
		},
	})
);

interface WidgetWrapperProps {
	metricId: string;
	size: WidgetSize;
	left: React.ReactNode;
	right: React.ReactNode;
	content: React.ReactNode;
}

export function WidgetWrapper({
	metricId,
	size,
	left,
	right,
	content,
}: WidgetWrapperProps) {
	const {
		attributes,
		setActivatorNodeRef,
		setNodeRef,
		transform,
		isDragging,
		listeners,
	} = useSortable({
		id: metricId,
		transition: null,
	});

	const { classes } = useStyles({
		size: size,
		isDragging,
	});

	return (
		<motion.div
			className={classes.container}
			ref={setNodeRef}
			layoutId={metricId}
			{...{
				...attributes,
				animate: getAnimation(transform, isDragging),
				transition: getTransition(isDragging),
			}}
		>
			<Group noWrap position="apart" pt={8} className={classes.header}>
				<Stack
					pl={20}
					spacing={4}
					className={classes.titleStack}
					ref={setActivatorNodeRef}
					{...listeners}
				>
					{left}
				</Stack>
				<Group h={36} pr={14} noWrap spacing={0}>
					{right}
				</Group>
			</Group>
			<Box px={20} py={16}>
				{content}
			</Box>
		</motion.div>
	);
}
