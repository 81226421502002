import { isIntegrationMetricWidget } from '../../../api';
import { WidgetType } from '../../../interfaces';
import type { ISampleOrActualMetricWidget } from '../constants';
import { MetricIntegrationBarChart } from './MetricIntegrationBarChart';
import { MetricIntegrationLineChart } from './MetricIntegrationLineChart';
import { MetricWidgetSingleValue } from './MetricWidgetSingleValue';
import { MetricWorkspaceLineChart } from './MetricWorkspaceLineChart';
import { MonitorChart } from './MonitorChart/MonitorChart';
import {
	MetricAIQueryTableWithoutUsers,
	MetricAIQueryTableWithUsers,
} from './Table/MetricAIQueryTable';
import { MetricGroupTable } from './Table/MetricGroupTable';
import { MetricResourceTable } from './Table/MetricResourceTable';
import { MetricSearchTermTable } from './Table/MetricSearchTermTable';
import { MetricUserTable } from './Table/MetricUserTable';
import { MetricWidgetTable } from './Table/MetricWidgetTable';

export type IMetricWidgetContentProps = ISampleOrActualMetricWidget;

export function MetricWidgetContent(props: IMetricWidgetContentProps) {
	const { metricWidget } = props;
	if (!metricWidget.metric_metadata) return null;

	if (metricWidget.type === WidgetType.METRIC_LINE_CHART) {
		if (!isIntegrationMetricWidget(metricWidget)) {
			return <MetricWorkspaceLineChart {...props} />;
		}
		return <MetricIntegrationLineChart {...props} />;
	}

	if (metricWidget.type === WidgetType.METRIC_BAR_CHART) {
		return <MetricIntegrationBarChart {...props} />;
	}

	if (metricWidget.type === WidgetType.METRIC_SINGLE_VALUE) {
		return <MetricWidgetSingleValue {...props} />;
	}

	if (metricWidget.type === WidgetType.MONITOR) {
		return <MonitorChart {...props} />;
	}

	if (metricWidget.type === WidgetType.METRIC_USER_TABLE) {
		let label = 'Actions';
		if (
			metricWidget.metric_metadata.metric_name === 'TOP_USERS' ||
			metricWidget.metric_metadata.metric_name === 'LEAST_ACTIVE_EDITORS'
		) {
			if (metricWidget.metric_metadata.fixed_filter?.type === 'view') {
				label = 'Views';
			} else {
				label = 'Edits';
			}
		} else if (
			metricWidget.metric_metadata.metric_name === 'TOP_USER_SEARCHES'
		) {
			label = 'Searches';
		} else if (
			metricWidget.metric_metadata.metric_name === 'TOP_RESOURCE_OWNERS'
		) {
			label = 'Resources';
		}

		return (
			<MetricWidgetTable
				TableComponent={MetricUserTable}
				infoColumns={[
					{
						label,
						fieldName: 'event_count',
					},
				]}
				{...props}
			/>
		);
	}
	if (metricWidget.type === WidgetType.METRIC_GROUP_TABLE) {
		let label = 'Edits';
		if (metricWidget.metric_metadata.fixed_filter?.type === 'view') {
			label = 'Views';
		}
		return (
			<MetricWidgetTable
				TableComponent={MetricGroupTable}
				infoColumns={[
					{
						label,
						fieldName: 'event_count',
					},
				]}
				{...props}
			/>
		);
	}
	if (metricWidget.type === WidgetType.METRIC_RESOURCE_TABLE) {
		return (
			<MetricWidgetTable
				infoColumns={[
					{
						label: 'Views',
						fieldName: 'views',
					},
				]}
				TableComponent={MetricResourceTable}
				{...props}
			/>
		);
	}
	if (metricWidget.type === WidgetType.METRIC_SEARCH_TERM_TABLE) {
		return (
			<MetricWidgetTable
				infoColumns={[
					{
						label: 'Searches',
						fieldName: 'count',
					},
				]}
				TableComponent={MetricSearchTermTable}
				{...props}
			/>
		);
	}
	if (metricWidget.type === WidgetType.AI_CHAT_HISTORY) {
		if (props.source === 'sample') {
			return (
				<MetricWidgetTable
					infoColumns={[]}
					TableComponent={MetricAIQueryTableWithoutUsers}
					{...props}
				/>
			);
		}
		return (
			<MetricWidgetTable
				infoColumns={[]}
				TableComponent={MetricAIQueryTableWithUsers}
				{...props}
			/>
		);
	}

	return null;
}
