import { Group } from '@mantine/core';
import { DataQualityClassification } from '@repo/common/enums/dataQualityClassification';
import {
	formatScore,
	getScoreClassification,
} from '@repo/common/utils/dataQuality';
import { Text } from '@repo/foundations';
import type { ColorNames } from '@repo/theme/utils';
import { memo } from 'react';
import { BaseProgressIndicator } from '../../ProgressIndicator/BaseProgressIndicator';
import { DataQualityBadge } from '../DataQualityBadge';
import { QualityScoreRingBody } from './QualityScoreRingBody';
import { LABEL_SIZES, SIZE_CONFIG, type SizeOptions } from './constants';

const CHART_COLORS: Record<
	DataQualityClassification,
	{ lineColor: ColorNames; hoverLineColor: ColorNames }
> = {
	[DataQualityClassification.GOOD]: {
		lineColor: 'fill/info/default',
		hoverLineColor: 'fill/info-secondary/default',
	},
	[DataQualityClassification.MODERATE]: {
		lineColor: 'fill/warning/default',
		hoverLineColor: 'fill/warning-secondary/default',
	},
	[DataQualityClassification.POOR]: {
		lineColor: 'fill/critical/default',
		hoverLineColor: 'fill/critical-secondary/default',
	},
	[DataQualityClassification.UNAVAILABLE]: {
		lineColor: 'fill/secondary/default',
		hoverLineColor: 'fill/secondary/hover',
	},
} as const;

export interface QualityScoreRingProps {
	score: number;
	size?: SizeOptions;
	displayBadge?: boolean;
}

export const QualityScoreRing = memo(
	({ score, size = 'md', displayBadge = true }: QualityScoreRingProps) => {
		const classification = getScoreClassification(score);

		return (
			<QualityScoreRingBody
				progressIndicator={
					<BaseProgressIndicator
						size={SIZE_CONFIG[size]}
						value={score}
						backgroundLineColor="fill/tertiary/default"
						animate
						borderWidth={8}
						lineColor={CHART_COLORS[classification].lineColor}
					/>
				}
			>
				{classification !== DataQualityClassification.UNAVAILABLE && (
					<Group spacing={0}>
						<Text size={LABEL_SIZES[size].score} fw={600}>
							{formatScore(score)}
						</Text>
						<Text size={LABEL_SIZES[size].percent} fw={600}>
							%
						</Text>
					</Group>
				)}
				{displayBadge && <DataQualityBadge classification={classification} />}
			</QualityScoreRingBody>
		);
	}
);
QualityScoreRing.displayName = 'QualityScoreRing';
