import {
	Group,
	Image,
	Modal,
	Stack,
	getDefaultZIndex,
	useMantineTheme,
} from '@mantine/core';
import { Button, IconButton, Title } from '@repo/foundations';
import dayjs from 'dayjs';
import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { EDUCATION_MODALS } from './constants';
import { useEducationModalDismiss } from './useEducationModalDismiss';

export function EducationModal({
	modalKey,
	forceShow = false,
}: {
	modalKey: keyof typeof EDUCATION_MODALS;
	forceShow?: boolean;
}) {
	const {
		title,
		imageUrl,
		learnMoreLink,
		children,
		primaryAction,
		width,
		expiresAt,
	} = EDUCATION_MODALS[modalKey];

	const theme = useMantineTheme();

	const { setAsSeen, dismissedModals } = useEducationModalDismiss();

	const previouslyShown = !!dismissedModals?.[modalKey];
	const isExpired = dayjs().isAfter(expiresAt);

	const canShowModal = (!previouslyShown && !isExpired) || forceShow;

	const handleClose = useCallback(() => {
		setAsSeen(modalKey);
	}, [modalKey, setAsSeen]);

	return (
		<Modal
			padding={0}
			opened={canShowModal}
			onClose={handleClose}
			closeButtonProps={{
				name: 'Close modal',
			}}
			closeOnClickOutside={true}
			withCloseButton={false}
			centered
			size={width}
			radius="lg"
			style={{ position: 'relative' }}
		>
			<IconButton
				iconName="x"
				onClick={handleClose}
				variant="tertiary"
				style={{
					position: 'absolute',
					right: 0,
					top: 0,
					zIndex: getDefaultZIndex('modal'),
				}}
			/>
			<Image src={imageUrl} alt={title} />
			<Stack p="xl" spacing="xs">
				<Title order={1}>{title}</Title>
				{children}
			</Stack>
			<Group
				p="md"
				sx={{
					borderTop: `1px solid ${theme.other.getColor('border/primary/default')}`,
					justifyContent: 'flex-end',
				}}
			>
				<Group spacing="xs" noWrap>
					{learnMoreLink && (
						<Link target="_blank" to={learnMoreLink}>
							<Button leftIconName="externalLink">Learn more</Button>
						</Link>
					)}

					{typeof primaryAction === 'string' ? (
						<Button
							data-testid="education-continue-button"
							onClick={handleClose}
							variant="primary"
						>
							{primaryAction}
						</Button>
					) : (
						primaryAction(handleClose)
					)}
				</Group>
			</Group>
		</Modal>
	);
}
