import { integrationList } from '@repo/common/constants/integration/integrations';
import { EntityType } from '@repo/common/enums/entityType';
import type { IIntegration } from '../../../api';
import { useIntegrationList } from '../../../api';
import IntegrationLogo from '../../IntegrationLogo';

export const useQueryableIntegrationOptions = () => {
	const integrations = useIntegrationList({});

	return (
		(integrations?.data?.results ?? [])
			.filter((integration) =>
				integrationList.find(
					(localIntegration) =>
						localIntegration.type === integration.type &&
						localIntegration.queryBlockSupport
				)
			)
			.map((integration: IIntegration) => ({
				icon: (
					<IntegrationLogo
						entityType={EntityType.integration}
						size={16}
						integrationType={integration.type}
						integrationId={integration.id}
					/>
				),
				value: integration.id,
				label: integration.name,
			})) || []
	);
};
