import { useMemo } from 'react';
import type { IColumn } from '../../../../api';
import { FilterOptionType } from '../../../../components/Filter';
import type { ICommandListItem } from '../../../../components/Spotlight/components/CommandPalette/constants';
import { useGenericColumns } from '../../../../components/TableV2/SecodaEntity.hooks';
import {
	CapitalizeTextRender,
	ColumnProfileRender,
} from '../../../../components/TableV2/render';
import type {
	ExtendedDataTableColumn,
	Render,
} from '../../../../components/TableV2/types';
import { useActions as useCatalogActions } from '../../../TeamCatalogPage/TeamCatalogPage.hooks';

export function useColumns(): ExtendedDataTableColumn<IColumn>[] {
	const genericColumns = useGenericColumns({});

	const columns = useMemo(
		() => [
			{
				accessor: 'type',
				title: 'Type',
				width: 160,
				render: (record: IColumn) => (
					<CapitalizeTextRender<IColumn>
						record={record}
						field={'type'}
						field2={'native_type'}
					/>
				),
				filterOptionType: FilterOptionType.NATIVE_TYPE,
			},
			...genericColumns,
			{
				accessor: 'properties._profile.ntiles',
				title: 'Distribution',
				sortable: false,
				filtering: false,
				explicit: true,
				width: 160,
				render: ColumnProfileRender as unknown as Render<IColumn>,
			},
		],
		[genericColumns]
	);

	return columns;
}
export const useActions = () => {
	const actions = useCatalogActions() as unknown as ICommandListItem<IColumn>[];

	return actions;
};
