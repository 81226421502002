import { Box, Divider, Group, Stack, Textarea, TextInput } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { Button, Text } from '@repo/foundations';
import { includes } from 'lodash-es';
import { useMemo, useState } from 'react';
import {
	invalidateTagList,
	useCreateTag,
	useUpdateTag,
	type ITag,
} from '../../../../api';
import { ColorSelector, getRandomTagColor } from '../../../ColorSelector';
import { useModalStyles } from './styles';

interface TagModalProps {
	onClose: VoidFunction;
	initialTitle?: string;
	tag?: ITag;
	allTags: Array<string>;
	customPropertyId?: string;
}

export function TagModal({
	onClose,
	initialTitle,
	tag,
	allTags,
	customPropertyId,
}: TagModalProps) {
	const { classes } = useModalStyles();

	const isCreateNew = tag === undefined || tag === null || !tag.id;

	const [tagColor, setTagColor] = useState<string>(
		tag?.color ?? getRandomTagColor()
	);
	const [tagName, setTagName] = useState<string>(
		tag?.name ?? initialTitle ?? ''
	);
	const [tagDescription, setTagDescription] = useState<string>(
		tag?.description ?? ''
	);

	const tagIsUnique = useMemo(
		() => tag?.name === tagName || !includes(allTags, tagName),
		[allTags, tagName, tag]
	);
	const canSave = tagName !== '' && tagIsUnique;
	const canUpdate =
		tagName !== '' &&
		(tagName !== tag?.name ||
			tagColor !== tag?.color ||
			tagDescription !== tag?.description) &&
		tagIsUnique;

	const { mutateAsync: createTag } = useCreateTag({
		options: {
			onSuccess: () => {
				showNotification({
					title: 'Tag created',
					message: `Tag "${tagName}" has been created successfully`,
					color: 'green',
				});
				invalidateTagList();
			},
			onError: () => {
				showNotification({
					title: 'An error occurred',
					message:
						'An error occurred that caused a failure while creating the tag',
					color: 'red',
				});
			},
		},
	});

	const { mutateAsync: updateTag } = useUpdateTag({
		options: {
			onSuccess: () => {
				showNotification({
					title: 'Tag updated',
					message: `Tag "${tagName}" has been updated successfully`,
					color: 'green',
				});
				invalidateTagList();
			},
			onError: () => {
				showNotification({
					title: 'An error occurred',
					message:
						'An error occurred that caused a failure while updating the tag',
					color: 'red',
				});
			},
		},
		disableOptimisticUpdate: true,
		disableInvalidation: true,
	});

	const handleAction = async () => {
		if (isCreateNew) {
			await createTag({
				data: {
					custom_property_id: customPropertyId,
					name: tagName,
					description: tagDescription,
					color: tagColor,
				},
			});
			onClose();
		} else {
			await updateTag({
				data: {
					id: tag.id,
					name: tagName,
					description: tagDescription,
					color: tagColor,
				},
			});
			onClose();
		}
	};

	return (
		<Box>
			<Stack spacing="sm">
				<Text weight="semibold" size="sm">
					Color and name
				</Text>
				<Group noWrap spacing="sm">
					<ColorSelector initialColor={tagColor} onChange={setTagColor} />
					<TextInput
						value={tagName}
						onChange={(e) => setTagName(e.target.value)}
						className={classes.textInput}
						error={tagIsUnique ? null : 'A tag with this name already exists.'}
					/>
				</Group>
				<Text weight="semibold" size="sm">
					Description&nbsp;
					<Text size="sm" color="text/secondary/default" span>
						(Optional)
					</Text>
				</Text>
				<Textarea
					value={tagDescription}
					onChange={(e) => setTagDescription(e.target.value)}
					minRows={3}
					maxRows={3}
				/>
			</Stack>
			<Divider my="md" />
			<Group position="right" spacing="sm">
				<Button onClick={onClose}>Cancel</Button>
				<Button
					disabled={isCreateNew ? !canSave : !canUpdate}
					variant="primary"
					onClick={handleAction}
				>
					{isCreateNew ? 'Save' : 'Update'}
				</Button>
			</Group>
		</Box>
	);
}
