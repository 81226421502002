import * as React from 'react';
import type { EmbedProps as Props } from '.';
import Frame from './components/Frame';

export default class DBDiagram extends React.Component<Props> {
	static ENABLED = [/https:\/\/dbdiagram.io\/(embed|d)\/(\w+)$/];

	render() {
		const { matches } = this.props.attrs;
		const shareId = matches[2];

		return (
			<Frame
				{...this.props}
				src={`https://dbdiagram.io/embed/${shareId}`}
				title={`DBDiagram (${shareId})`}
				width="100%"
				height="400px"
			/>
		);
	}
}
