import { Anchor, Box, useMantineTheme } from '@mantine/core';
import type { GroupedPermissionsResponse } from '@repo/api-codegen';
import { Text } from '@repo/foundations';
import { buildResourceUrl } from '../../utils/navigationUtils';

export function EntityPermissionSourceRow({
	group,
}: {
	group: GroupedPermissionsResponse;
}) {
	const theme = useMantineTheme();

	const contentText = () => {
		if (group.source === 'custom') {
			return <>Custom</>;
		}

		if (group.source === 'team' && group.source_team) {
			return (
				<>
					From {group.source_team.icon} {group.source_team.name}
				</>
			);
		}
		if (group.source === 'entity' && group.source_entity) {
			return (
				<>
					From {group.source_entity.icon || ''}{' '}
					<Anchor
						href={buildResourceUrl(group.source_entity)}
						color="dimmed"
						size="xs"
					>
						{group.source_entity.title}
					</Anchor>
				</>
			);
		}
		return null;
	};

	return (
		<Box pt={8} pb={4}>
			<Text size="xs" weight="semibold" color="text/secondary/default">
				{contentText()}
			</Text>
		</Box>
	);
}
