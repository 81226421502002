interface PromiseCache {
	cacheKey: string;
	promise?: Promise<void>;
	inputs: Array<any>;
	error?: any;
	response?: any;
}

const promiseCaches: Map<string, Map<string, PromiseCache>> = new Map();

export const clearUsePromiseCacheKey = (key: string) => {
	promiseCaches.delete(key);
};

// DEPRECATED: LETS MOVE TO REACT QUERY
// The cacheKey must be unique for each type of promise.
export const usePromise = (
	cacheKey: string,
	promise: (...inputs: any) => any,
	inputs: Array<any> = [],
	lifespan = 0
) => {
	const cache = promiseCaches.get(cacheKey);

	// TODO: The serialization should be done deterministically.
	// I.e. we should be sorting the inputs before serializing them.
	const serializedInputs = JSON.stringify(inputs);
	if (cache && cache?.has(serializedInputs)) {
		const promiseCache = cache.get(serializedInputs)!;
		if (Object.prototype.hasOwnProperty.call(promiseCache, 'error')) {
			throw promiseCache.error;
		}
		// If a response was successful,
		if (Object.prototype.hasOwnProperty.call(promiseCache, 'response')) {
			return promiseCache.response;
		}
		throw promiseCache.promise;
	}

	// The request is new or has changed.
	const promiseCache: PromiseCache = {
		cacheKey,
		promise:
			// Make the promise request.
			promise(...inputs)
				.then((response: unknown) => {
					promiseCache.response = response;
				})
				.catch((e: any) => {
					promiseCache.error = e;
				})
				.then(() => {
					if (lifespan > 0) {
						setTimeout(() => {
							promiseCaches.get(cacheKey)?.delete(serializedInputs);
						}, lifespan);
					}
				}),
		inputs,
	};

	const _map = new Map();
	_map.set(serializedInputs, promiseCache);
	promiseCaches.set(cacheKey, _map);
	throw promiseCache.promise;
};
