import { Stack } from '@mantine/core';
import { Button } from '@repo/foundations';
import PlansGrid from './PlansGrid';
import PlansPageHeader from './PlansPageHeader';

function PlansPage() {
	const handleNavigateToPricingPage = () => {
		window.open('https://secoda.co/pricing', '_blank');
	};

	return (
		<Stack spacing="xl" align="center" px="2xl" pb="2xl">
			<PlansPageHeader />
			<PlansGrid />
			<Button
				rightIconName="arrowUpRight"
				onClick={handleNavigateToPricingPage}
			>
				See all features
			</Button>
		</Stack>
	);
}

export default PlansPage;
