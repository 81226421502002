import { IconButton } from '@repo/foundations';
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { RightSidebarMode, rightSidebarStore } from '../RightSidebar';

export const CommentsSidebarToggle = observer(() => {
	const { collapsed, mode, setCollapsed, setMode } = rightSidebarStore;

	const toggleEntitySideBar = useCallback(() => {
		if (collapsed) {
			setCollapsed(false);
		} else if (mode === RightSidebarMode.COMMENT) {
			// If the mode is already set to this we instead want to close sidebar.
			setCollapsed(true);
		}
		setMode('comment');
	}, [mode, collapsed]);

	const isOpen = !collapsed && mode === RightSidebarMode.COMMENT;

	return (
		<IconButton
			tooltip={`${isOpen ? 'Hide' : 'Show'} comments`}
			iconName={isOpen ? 'messageFilled' : 'message'}
			variant="tertiary"
			onClick={toggleEntitySideBar}
			highlight={isOpen}
		/>
	);
});
