export enum AutomationPropagateMetadataRelationship {
	PARENT = 'parent',
	CHILDREN = 'children',
	EXACT_NAME = 'exact_name',
	LINEAGE_UP = 'lineage_up',
	LINEAGE_DOWN = 'lineage_down',
}

export enum AutomationPropagateMetadataField {
	DESCRIPTION = 'description',
	DOCUMENTATION = 'documentation',
	VERIFICATION = 'verification',
	GOVERNANCE = 'governance',
	TAGS = 'tags',
	COLLECTIONS = 'collections',
	OWNERS = 'owners',
}

export interface AutomationActionPropagateMetadata {
	to?: AutomationPropagateMetadataRelationship;
	fields?: AutomationPropagateMetadataField[];
	overwrite_metadata?: boolean;
	levels?: number;
}
