import type { ActionIconProps } from '@mantine/core';
import { ActionIcon, createStyles, Tooltip } from '@mantine/core';
import type { FloatingPosition } from '@mantine/core/lib/Floating';
import type { MantineNumberSize } from '@mantine/styles';
import { Icon, type IconNames } from '@repo/foundations';
import type { FocusEventHandler } from 'react';

interface IControlIconProps {
	label: string;
	iconName: IconNames;
	onClick: () => void;
	position?: FloatingPosition;
	dataTestId?: string;
}

const useStyles = createStyles((theme) => ({
	actionIcon: {
		backgroundColor: theme.other.getColor('fill/primary/default'),
		'&:hover': {
			backgroundColor: theme.other.getColor('fill/primary/hover'),
		},
		'&:active': {
			backgroundColor: theme.other.getColor('fill/primary/active'),
		},
		'&:focus': {
			backgroundColor: theme.other.getColor('fill/primary/active'),
		},
	},
}));

function ControlIcon({
	label,
	iconName,
	onClick,
	position = 'right',
	dataTestId,
}: IControlIconProps) {
	const { classes } = useStyles();

	const handleFocus: FocusEventHandler<HTMLButtonElement> = (event) => {
		event.target.blur();
	};

	const actionIconProps: {
		variant: ActionIconProps['variant'];
		size: MantineNumberSize;
		onClick: () => void;
		onFocus: FocusEventHandler<HTMLButtonElement>;
		'data-testid'?: string;
	} = {
		variant: 'default',
		size: 'sm',
		onClick,
		onFocus: handleFocus,
	};

	if (dataTestId) {
		actionIconProps['data-testid'] = dataTestId;
	}

	return (
		<Tooltip label={label} position={position}>
			<ActionIcon className={classes.actionIcon} {...actionIconProps}>
				<Icon name={iconName} />
			</ActionIcon>
		</Tooltip>
	);
}

export default ControlIcon;
