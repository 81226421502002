import axios from 'axios';
import { api } from '../../../network';
import { REDIRECT_FROM_AUTH_PATH_KEY } from '../../pages/RootPage/RootAuthRedirect.tsx';
import {
	loginRequest,
	msalInstance,
} from '../../utils/authentication/azure/authConfig';

// This will either retrieve a verification token or send a verification email.
const tryAuthenticationToken = (
	navigate: (arg0: string) => void,
	data: Record<string, string>
) => {
	if (data.id) {
		localStorage.setItem('id', data.id);
		if (data.workspace_id) {
			const to = localStorage.getItem(REDIRECT_FROM_AUTH_PATH_KEY) ?? '/';
			localStorage.removeItem(REDIRECT_FROM_AUTH_PATH_KEY);
			navigate(to);
		} else {
			navigate('/onboarding/profile');
		}
	}
	return false;
};

export const callbackGoogle = (
	navigate: (arg0: string) => void,
	accessToken: string,
	csrfState: string,
	failureCallback: () => void
) => {
	axios
		.post(`${api()}auth/social/google/`, {
			access_token: accessToken,
			csrf_state: csrfState,
		})
		.then((response) => {
			const { data } = response;
			if (data.redirect_to) {
				window.open(data.redirect_to, '_self');
				return;
			}
			tryAuthenticationToken(navigate, response.data);
		})
		.catch(() => {
			failureCallback();
		});
};

export const callbackMicrosoft = async (
	navigate: (arg0: string) => void,
	failureCallback: VoidFunction
) => {
	const request = {
		...loginRequest,
		// We are "technically" signed in with Microsoft.
		// but we need to still sign in to Secoda.
		account: msalInstance.getAllAccounts()[0],
	};
	const codeExchange = await msalInstance.acquireTokenSilent(request);
	if (codeExchange !== null) {
		// Acquire token silent success.
		const { accessToken } = codeExchange;
		// Call your API with token.
		axios
			.post(`${api()}auth/social/microsoft/`, { access_token: accessToken })
			.then((response) => {
				tryAuthenticationToken(navigate, response.data);
			})
			.catch(() => {
				failureCallback();
			});
	}
};
