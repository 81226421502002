import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
	header: {
		backgroundColor: theme.other.getColor('surface/secondary/default'),
		padding: `${theme.spacing.sm} ${theme.spacing.sm} ${theme.spacing.sm} ${theme.spacing.lg}`,
	},
	body: {
		padding: 0,
	},
	widgetItemsWrapper: {
		columnGap: theme.spacing.lg,
		rowGap: theme.spacing.md,
	},
	widgetWrapper: {
		paddingBottom: 0,
	},
}));
