import { Stack } from '@mantine/core';
import { FilterBar } from '../../../../pages/SearchPage/v2/FilterBar/FilterBar';
import { SearchFiltersV2Provider } from '../../../Filter/provider';
import { ApiFilterPreview } from './ApiFilterPreview';

export function ApiFiltersGenerator() {
	return (
		<SearchFiltersV2Provider>
			<Stack>
				<FilterBar showSort={false} py={0} />
				<ApiFilterPreview />
			</Stack>
		</SearchFiltersV2Provider>
	);
}
