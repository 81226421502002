import { isNil } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';

function animateElasticCubicInOut(elapsed: number) {
	let k = elapsed * 2;

	if (k < 1) {
		return 0.5 * k * k * k;
	}

	k -= 2;

	return 0.5 * (k * k * k + 2);
}

class NumberAnimation {
	frame: number = 0;

	initialValue: number;

	toValue?: number;

	value: number;

	duration: number;

	startTime?: DOMHighResTimeStamp;

	callback?: (value: number) => void;

	constructor(initialValue: number, duration: number) {
		this.initialValue = initialValue;
		this.value = initialValue;
		this.duration = duration;
	}

	play(toValue: number, callback: (value: number) => void) {
		this.initialValue = this.value;
		this.toValue = toValue;
		this.startTime = undefined;
		this.callback = callback;
		this.frame = requestAnimationFrame(this.tick.bind(this));
	}

	tick(now: DOMHighResTimeStamp) {
		if (isNil(this.toValue)) {
			this.cancel();
			return;
		}

		if (!this.startTime) {
			this.startTime = now;
		}

		const elapsedTime = now - this.startTime;
		const elapsedRate = elapsedTime / this.duration;

		if (elapsedRate >= 1) {
			this.value = this.toValue;
			this.callback?.(this.value);
			return;
		}

		const step =
			(this.toValue - this.initialValue) *
			animateElasticCubicInOut(elapsedRate);
		this.value = this.initialValue + step;

		this.callback?.(this.value);

		this.frame = requestAnimationFrame(this.tick.bind(this));
	}

	cancel() {
		if (this.frame) {
			cancelAnimationFrame(this.frame);
		}
	}
}

interface UseAnimatedValueProps {
	value: number;
	animate?: boolean;
	duration?: number;
}

export function useAnimatedValue({
	value,
	animate = true,
	duration = 1000,
}: UseAnimatedValueProps) {
	const [animatedValue, setAnimatedValue] = useState(animate ? 0 : value);
	const animation = useMemo(() => new NumberAnimation(0, duration), [duration]);

	useEffect(() => {
		if (!animate) {
			setAnimatedValue(value);
			animation.value = value;
			return () => {};
		}

		animation.play(value, setAnimatedValue);

		return () => animation.cancel();
	}, [animate, value, animation]);

	return {
		value: animatedValue,
	};
}
