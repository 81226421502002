import type { CSSObject } from '@mantine/core';
import { createStyles } from '@mantine/core';
import {
	NODE_HEADER_PADDING_LEFT,
	NODE_HEADER_PADDING_Y,
	NODE_PADDING,
	NODE_WIDTH,
} from '../../constants';

export const useStyles = createStyles((theme) => ({
	wrapper: {
		cursor: 'pointer',
		flexWrap: 'nowrap',
		alignItems: 'flex-start',
		justifyContent: 'space-between',
		width: NODE_WIDTH,
		padding: `${NODE_HEADER_PADDING_Y}px ${NODE_PADDING}px ${NODE_HEADER_PADDING_Y}px ${NODE_HEADER_PADDING_LEFT}px`,
		gap: 0,
	},
	contentWrapper: {
		flexWrap: 'nowrap',
		flexGrow: '1 !important' as CSSObject['flexGrow'],
		gap: theme.spacing.xs,
		overflow: 'hidden',
	},
	content: {
		flexWrap: 'nowrap',
		flexGrow: '1 !important' as CSSObject['flexGrow'],
		justifyContent: 'space-between',
		gap: 0,
	},
	textWrapper: {
		width: theme.other.space[80],
		gap: 0,
	},
	metadataWrapper: {
		flexWrap: 'nowrap',
		gap: theme.spacing['3xs'],
	},
	actionIconsWrapper: {
		flexWrap: 'nowrap',
		flexDirection: 'row-reverse',
		gap: 0,
	},
}));
