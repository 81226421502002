import { Box, createStyles, Divider, Group, Modal } from '@mantine/core';
import { Button, Text } from '@repo/foundations';
import { width } from '@repo/theme/primitives';
import { noop } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import type { IMetricWidgetSample, IWidgetSample } from '../../../../api';
import WidgetSelector from '../../../../components/WidgetSelector';
import type { WidgetData } from '../../../../components/WidgetSelector/types';
import { useHomepageTeam } from '../../../HomePage/hooks/useHomepageTeam';
import { formatMetricWidgetTitle } from '../../utils/utils';
import { useWidgetsToAdd } from './utils';
import { WidgetRenderer } from './WidgetRenderer';

export interface IAddWidgetModalProps {
	addWidget: (widget: IWidgetSample) => void;
	opened: boolean;
	onClose: VoidFunction;
}

const useStyles = createStyles((theme) => ({
	modalBody: {
		padding: 0,
	},
	widgetSelectorRoot: {
		margin: 0,
	},
	groupTitle: {
		fontSize: theme.fontSizes.sm,
		fontWeight: theme.other.typography.weight.bold,
		color: theme.other.getColor('text/secondary/default'),
		paddingLeft: theme.spacing.sm,
		paddingBottom: 0,
	},
	widgetSelectorColumn: {
		paddingLeft: theme.spacing.md,
	},
}));

function AddWidgetModal({ addWidget, opened, onClose }: IAddWidgetModalProps) {
	const { theme, classes } = useStyles();
	const { isTeamsPage } = useHomepageTeam();

	const widgetSamples = useWidgetsToAdd();

	const widgetData = widgetSamples.map(
		(widgetSample) =>
			({
				group: widgetSample.group,
				groupIconName: widgetSample.groupIconName,
				title: formatMetricWidgetTitle(widgetSample),
				data: widgetSample,
				renderer: ({ data }) => (
					<WidgetRenderer
						key={`${widgetSample.metric_metadata.metric_name}`}
						widgetSample={data.data}
						addWidget={addWidget}
						isTeamsPage={isTeamsPage}
					/>
				),
			}) as WidgetData<IMetricWidgetSample>
	);

	const header = (
		<Box w="100%">
			<Group position="apart" p="xs">
				<Text weight="bold" size="md">
					Add Widget
				</Text>
				<Button onClick={onClose} variant="primary">
					Done
				</Button>
			</Group>
			<Divider ml="-0.5rem" mr="-0.5rem" pb="0.5rem" />
		</Box>
	);

	return (
		<Modal
			size={width.xxl}
			opened={opened}
			onClose={onClose}
			withCloseButton={false}
			classNames={{
				body: classes.modalBody,
			}}
			overlayProps={{
				opacity: 0.1,
			}}
			radius={theme.radius.lg}
		>
			<WidgetSelector
				header={header}
				type="filter"
				data={widgetData}
				cols={2}
				onWidgetSelect={noop}
				scrollAreaHeight="600px"
				widgetGroupProps={{
					classNames: {
						groupTitle: classes.groupTitle,
					},
				}}
				classNames={{
					root: classes.widgetSelectorRoot,
				}}
			/>
		</Modal>
	);
}

export default observer(AddWidgetModal);
