import { Box, useMantineTheme } from '@mantine/core';
import { TextSkeleton } from '@repo/foundations';

export function FilterItemSkeleton() {
	const theme = useMantineTheme();

	return (
		<Box
			style={{
				margin: `0 ${theme.spacing['2xs']}`,
				width: `calc(100% - (2 * ${theme.spacing['2xs']}))`,
			}}
		>
			<TextSkeleton />
		</Box>
	);
}
