import type { Plan } from '../interfaces/Plan';
import { isProductionEnvironment } from '../utils/authorization/roles';

export const perEditorPlans: string[] = [
	// Testing
	'price_1Je88TK6Kr02pHOooj4i1NvD',
	'price_1JdxykK6Kr02pHOoiPGfLzwS',
	'price_1Jdy1hK6Kr02pHOoEkxGJMT5',
	'price_1Jdy1FK6Kr02pHOoAxZWLGwh',
	'price_1JdxwYK6Kr02pHOoXRX4wqKY',
	'price_1JrlOVK6Kr02pHOoC3SZ5gKi',
	'price_1JrlOBK6Kr02pHOoc0XIrPBl',
	'price_1Or0pcK6Kr02pHOoaJStPPPx',
	'price_1Or0pLK6Kr02pHOoa7PHwbE5',
	// Production
	'price_1JeBHiK6Kr02pHOojtuWTW5h',
	'price_1JeBH4K6Kr02pHOoE9BN3Qjt',
	'price_1JeBJvK6Kr02pHOo1wdpbw31',
	'price_1JeBJaK6Kr02pHOoPyXU0jiP',
	'price_1Jrn00K6Kr02pHOoJGce8MFx',
	'price_1Jrn0eK6Kr02pHOonQNAAq7c',
	'price_1Or0NVK6Kr02pHOolNvmFdtP',
	'price_1Or0NOK6Kr02pHOoBcHzjorG',
];

export const FREE_PLAN: Plan = {
	id: 'FREE',
	legacy: false,
	integrations: 2,
	viewers: 20,
	editors: 1,
	title: 'Free',
	price: 0,
	annual: {
		price: 0,
		id: 'free',
	},
	monthly: {
		price: 0,
		id: 'free',
	},
	git_support: false,
	integration_scheduler_support: false,
	api_support: false,
	export_support: false,
	confluence_support: false,
	slack_support: false,
	analytics_support: false,
	pii_support: false,
	group_support: false,
	public_support: false,
	disable_analytics_support: false,
	custom_invite_support: false,
	audit_log_support: false,
	all_plans: ['free'],
};

// @ts-expect-error TS(2741): Property 'price' is missing in type '{ id: string;... Remove this comment to see the full error message
export const CI_PLAN: Plan = {
	id: 'CI',
	legacy: true,
	integrations: 2,
	viewers: 2,
	editors: 2,
	title: 'CI',
	annual: {
		price: 40,
		id: 'ci',
	},
	monthly: {
		price: 50,
		id: 'ci',
	},
	group_support: true, // Giving all features because we are restricting them only in the new plans.
	slack_support: true, // Giving all features because we are restricting them only in the new plans.
	analytics_support: true, // Giving all features because we are restricting them only in the new plans.
	pii_support: true, // Giving all features because we are restricting them only in the new plans.
	api_support: true, // Giving all features because we are restricting them only in the new plans.
	export_support: true, // Giving all features because we are restricting them only in the new plans.
	confluence_support: false,
	git_support: true, // Giving all features because we are restricting them only in the new plans.
	integration_scheduler_support: true, // Giving all features because we are restricting them only in the new plans.
	public_support: true,
	disable_analytics_support: false,
	custom_invite_support: true,
	all_plans: ['ci'],
};

// @ts-expect-error TS(2741): Property 'price' is missing in type '{ id: string;... Remove this comment to see the full error message
export const STARTER_PLAN: Plan = {
	id: 'STARTER',
	legacy: true,
	integrations: 1000,
	viewers: 50,
	editors: 15,
	title: 'Team',
	annual: {
		price: 40,
		id: isProductionEnvironment()
			? 'price_1Jrn0eK6Kr02pHOonQNAAq7c'
			: 'price_1JrlOVK6Kr02pHOoC3SZ5gKi',
	},
	monthly: {
		price: 50,
		id: isProductionEnvironment()
			? 'price_1Jrn00K6Kr02pHOoJGce8MFx'
			: 'price_1JrlOBK6Kr02pHOoc0XIrPBl',
	},
	group_support: true, // Giving all features because we are restricting them only in the new plans.
	slack_support: true, // Giving all features because we are restricting them only in the new plans.
	analytics_support: true, // Giving all features because we are restricting them only in the new plans.
	pii_support: true, // Giving all features because we are restricting them only in the new plans.
	api_support: true, // Giving all features because we are restricting them only in the new plans.
	export_support: true, // Giving all features because we are restricting them only in the new plans.
	confluence_support: false,
	git_support: true, // Giving all features because we are restricting them only in the new plans.
	integration_scheduler_support: true, // Giving all features because we are restricting them only in the new plans.
	public_support: true,
	disable_analytics_support: false,
	custom_invite_support: true,
	all_plans: [
		'starter',
		'price_1JrlOBK6Kr02pHOoc0XIrPBl',
		'price_1JrlOVK6Kr02pHOoC3SZ5gKi',
		'price_1JdxykK6Kr02pHOoiPGfLzwS',
		'price_1JdxwYK6Kr02pHOoXRX4wqKY',
		'price_1JUBxgK6Kr02pHOoWyNO5NHE',
		'price_1JUBxfK6Kr02pHOo925o6m9Y',
		'price_1IQ20rK6Kr02pHOoVRhn7w4j',
		'price_1IQ20rK6Kr02pHOoVRhn7w4j',
		'price_1JeBJvK6Kr02pHOo1wdpbw31',
		'price_1JeBJaK6Kr02pHOoPyXU0jiP',
		'price_1Je88TK6Kr02pHOooj4i1NvD',
		'price_1IQBvsK6Kr02pHOonWDrNL7w',
		'price_1Jrn00K6Kr02pHOoJGce8MFx',
		'price_1Jrn0eK6Kr02pHOonQNAAq7c',
		'price_1JNJDXK6Kr02pHOoumqFJYNr',
	],
};

export const GROWTH_PLAN: Plan = {
	id: 'GROWTH',
	legacy: true,
	integrations: 3,
	viewers: 10000,
	editors: 1000,
	title: 'Growth',
	price: 40,
	annual: {
		price: 40,
		id: isProductionEnvironment()
			? 'price_1KdcgdK6Kr02pHOoZPWBAKPf'
			: 'price_1KdcruK6Kr02pHOoDkzcHQhc',
	},
	monthly: {
		price: 50,
		id: isProductionEnvironment()
			? 'price_1KdcgdK6Kr02pHOoZPWBAKPf'
			: 'price_1KdcruK6Kr02pHOoDkzcHQhc',
	},
	group_support: true,
	slack_support: true,
	analytics_support: true,
	pii_support: true,
	git_support: false,
	integration_scheduler_support: true,
	api_support: false,
	export_support: true,
	confluence_support: false,
	public_support: false,
	custom_invite_support: false,
	audit_log_support: false,
	all_plans: [
		'growth',
		'price_1KdcgdK6Kr02pHOoZPWBAKPf',
		'price_1KdcruK6Kr02pHOoDkzcHQhc',
		'price_1NsVfGK6Kr02pHOoG6lVMUOo',
		'price_1NsVJeK6Kr02pHOo1nkbE39Q',
		'price_1NsVHeK6Kr02pHOoUROTJb9Q',
	],
	disable_analytics_support: false,
};

export const BUSINESS_PLAN: Plan = {
	id: 'BUSINESS',
	legacy: true,
	integrations: 4,
	viewers: 10000,
	editors: 10000,
	title: 'Business',
	price: 10000,
	annual: {
		price: 10000,
		id: isProductionEnvironment()
			? 'price_1KdchZK6Kr02pHOozncYeGZ6'
			: 'price_1KdcsAK6Kr02pHOoYieLyzi6',
	},
	monthly: {
		price: 10000,
		id: isProductionEnvironment()
			? 'price_1KdchZK6Kr02pHOozncYeGZ6'
			: 'price_1KdcsAK6Kr02pHOoYieLyzi6',
	},
	group_support: true,
	slack_support: true,
	analytics_support: true,
	pii_support: true,
	api_support: true,
	export_support: true,
	confluence_support: false,
	git_support: false,
	integration_scheduler_support: true,
	public_support: false,
	disable_analytics_support: false,
	custom_invite_support: false,
	audit_log_support: false,
	all_plans: [
		'business',
		'price_1KdchZK6Kr02pHOozncYeGZ6',
		'price_1KdcsAK6Kr02pHOoYieLyzi6',
	],
};

// @ts-expect-error TS(2741): Property 'price' is missing in type '{ id: string;... Remove this comment to see the full error message
export const PREMIUM_PLAN: Plan = {
	id: 'PREMIUM',
	legacy: true,
	integrations: 1000,
	viewers: 10000,
	editors: 1000,
	title: 'Premium',
	annual: {
		price: 79,
		id: isProductionEnvironment()
			? 'price_1JeBHiK6Kr02pHOojtuWTW5h'
			: 'price_1Jdy1hK6Kr02pHOoEkxGJMT5',
	},
	monthly: {
		price: 99,
		id: isProductionEnvironment()
			? 'price_1JeBH4K6Kr02pHOoE9BN3Qjt'
			: 'price_1Jdy1FK6Kr02pHOoAxZWLGwh',
	},
	group_support: true, // Giving all features because we are restricting them only in the new plans.
	slack_support: true, // Giving all features because we are restricting them only in the new plans.
	analytics_support: true, // Giving all features because we are restricting them only in the new plans.
	pii_support: true, // Giving all features because we are restricting them only in the new plans.
	api_support: true, // Giving all features because we are restricting them only in the new plans.
	export_support: true, // Giving all features because we are restricting them only in the new plans.
	confluence_support: false,
	git_support: true, // Giving all features because we are restricting them only in the new plans.
	integration_scheduler_support: true, // Giving all features because we are restricting them only in the new plans.
	public_support: true,
	disable_analytics_support: false,
	custom_invite_support: true,
	all_plans: [
		'premium',
		'price_1Jdy1hK6Kr02pHOoEkxGJMT5',
		'price_1Jdy1FK6Kr02pHOoAxZWLGwh',
		'price_1JUC06K6Kr02pHOoMP7duDgh',
		'price_1JUC06K6Kr02pHOo6FSX7UCO',
		'price_1JeBHiK6Kr02pHOojtuWTW5h',
		'price_1JeBH4K6Kr02pHOoE9BN3Qjt',
	],
};

const oldPlans: Plan[] = [
	FREE_PLAN,
	STARTER_PLAN,
	GROWTH_PLAN,
	BUSINESS_PLAN,
	PREMIUM_PLAN,
];

// The new plans. These are the ones currently advertised on the website.
// We should slowly deprecate (remove) the old plans as customers cycle off of them.

export const BASIC_PLAN: Plan = {
	id: 'BASIC',
	legacy: false,
	integrations: 4,
	viewers: 10000,
	editors: 1000,
	title: 'Basic',
	price: 40,
	annual: {
		price: 40,
		id: isProductionEnvironment()
			? 'price_1Or0NVK6Kr02pHOolNvmFdtP'
			: 'price_1Or0pcK6Kr02pHOoaJStPPPx',
	},
	monthly: {
		price: 50,
		id: isProductionEnvironment()
			? 'price_1Or0NVK6Kr02pHOolNvmFdtP'
			: 'price_1Or0pcK6Kr02pHOoaJStPPPx',
	},
	group_support: true,
	slack_support: true,
	analytics_support: true,
	pii_support: true,
	git_support: false,
	integration_scheduler_support: true,
	api_support: true,
	export_support: true,
	confluence_support: false,
	public_support: false,
	custom_invite_support: false,
	audit_log_support: false,
	all_plans: [
		'basic',
		'price_1Or0pcK6Kr02pHOoaJStPPPx',
		'price_1Or0NVK6Kr02pHOolNvmFdtP',
	],
	disable_analytics_support: false,
};

export const CORE_PLAN: Plan = {
	id: 'CORE',
	legacy: true,
	integrations: 10000,
	viewers: 10000,
	editors: 10000,
	title: 'Core',
	price: 10000,
	annual: {
		price: 10000,
		id: isProductionEnvironment()
			? 'price_1Or0NOK6Kr02pHOoBcHzjorG'
			: 'price_1Or0pLK6Kr02pHOoa7PHwbE5',
	},
	monthly: {
		price: 10000,
		id: isProductionEnvironment()
			? 'price_1Or0NOK6Kr02pHOoBcHzjorG'
			: 'price_1Or0pLK6Kr02pHOoa7PHwbE5',
	},
	group_support: true,
	slack_support: true,
	analytics_support: true,
	pii_support: true,
	api_support: true,
	export_support: true,
	confluence_support: false,
	git_support: false,
	integration_scheduler_support: true,
	public_support: false,
	disable_analytics_support: false,
	custom_invite_support: false,
	audit_log_support: false,
	all_plans: [
		'core',
		'price_1Or0NOK6Kr02pHOoBcHzjorG',
		'price_1Or0pLK6Kr02pHOoa7PHwbE5',
	],
};

export const ENTERPRISE_PLAN: Plan = {
	id: 'ENTERPRISE',
	legacy: false,
	integrations: 10000,
	viewers: 10000,
	guests: 10000,
	editors: 10000,
	title: 'Enterprise',
	price: 10000,
	annual: {
		price: 999,
		id: isProductionEnvironment()
			? 'price_1IQBvkK6Kr02pHOoN4jWIU5o'
			: 'price_1IQ21ZK6Kr02pHOoxeZq5beF',
	},
	monthly: {
		price: 1299,
		id: isProductionEnvironment()
			? 'price_1IQBvkK6Kr02pHOoCwBlm0xf'
			: 'price_1IQ21ZK6Kr02pHOoVW5gcxCc',
	},
	group_support: true,
	slack_support: true,
	analytics_support: true,
	pii_support: true,
	api_support: true,
	export_support: true,
	confluence_support: true,
	git_support: true,
	integration_scheduler_support: true,
	public_support: true,
	disable_analytics_support: true,
	custom_invite_support: true,
	audit_log_support: true, // Allow workspace level audit logs.
	all_plans: [
		'enterprise',
		'price_1IQ21ZK6Kr02pHOoxeZq5beF',
		'price_1IQ21ZK6Kr02pHOoVW5gcxCc',
		'price_1JRhqVK6Kr02pHOowv4MFhNi',
		'price_1KfvoNK6Kr02pHOoUokGJlp7',
		'price_1KfvnyK6Kr02pHOo9HvLrX8w',
		'price_1KHHHmK6Kr02pHOoTw6B0Zs3',
		'price_1KJ1RgK6Kr02pHOogFyV7Gnq',
		'price_1KCTnJK6Kr02pHOofr2L3zZi',
		'price_1KCRHrK6Kr02pHOovGJ3Dl2e',
		'price_1K9vnWK6Kr02pHOo4bL0J6Gd',
		'price_1JZdq5K6Kr02pHOoZYyGdcrI',
		'price_1IQBvkK6Kr02pHOoCwBlm0xf',
		'price_1JNJEcK6Kr02pHOoZoyhIZb3',
		'price_1KQwFbK6Kr02pHOoVIUM2RlZ',
		'price_1J1DqGK6Kr02pHOo8FUvB2JA',
		'price_1IQBvkK6Kr02pHOoN4jWIU5o',
	],
};

export const TRIAL_PLAN: Plan = {
	id: 'TRIAL',
	legacy: false,
	integrations: 10000,
	viewers: 10000,
	guests: 10000,
	editors: 10000,
	title: 'Trial',
	price: 10000,
	annual: {
		price: 999,
		id: 'trial',
	},
	monthly: {
		price: 1299,
		id: 'trial',
	},
	group_support: true,
	slack_support: true,
	analytics_support: true,
	pii_support: true,
	api_support: true,
	export_support: true,
	confluence_support: true,
	git_support: true,
	integration_scheduler_support: true,
	public_support: true,
	disable_analytics_support: true,
	custom_invite_support: true,
	audit_log_support: true,
	all_plans: ['trial'],
};

export const workspacePlans: Plan[] = [
	...oldPlans,
	CI_PLAN,
	BASIC_PLAN,
	CORE_PLAN,
	ENTERPRISE_PLAN,
];
