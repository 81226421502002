import { Space } from '@mantine/core';
import SkeletonWidgetRow from './SkeletonWidgetRow';

function TextBlockCard() {
	return (
		<>
			<SkeletonWidgetRow />
			<SkeletonWidgetRow />
			<Space h="sm" />
		</>
	);
}

export default TextBlockCard;
