import { useContext } from 'react';
import { ModalsContext } from './context';

export function useModals() {
	const ctx = useContext(ModalsContext);

	if (!ctx) {
		throw new Error(
			'useModals hook was called outside of context, wrap your app with ModalManagerProvider component'
		);
	}

	return ctx;
}
