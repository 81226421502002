import { Group, Stack } from '@mantine/core';
import {
	useCreatePersona,
	useDeletePersona,
	useGetPersonas,
} from '@repo/api-codegen';
import { Button } from '@repo/foundations';
import { AIPersonaCard } from './AIPersonaCard';

export function AIPersonas() {
	const { data: personas, refetch } = useGetPersonas({});
	const { mutateAsync: createPersona } = useCreatePersona();
	const { mutateAsync: deletePersona } = useDeletePersona();

	const handleCreatePersona = async () => {
		await createPersona({});
		await refetch();
	};

	const handleDeletePersona = async (id: string) => {
		await deletePersona({
			pathParams: {
				personaId: id || '',
			},
		});
		await refetch();
	};

	return (
		<Stack>
			<Group position="right">
				<Button onClick={handleCreatePersona}>Create Agent</Button>
			</Group>
			{personas?.map((persona) => (
				<AIPersonaCard
					key={persona.id}
					id={persona.id}
					name={persona.name}
					description={persona.description}
					avatarURL={persona.avatar_url}
					customInstructions={persona.custom_instructions}
					onDelete={handleDeletePersona}
					teams={persona.teams}
				/>
			))}
		</Stack>
	);
}
