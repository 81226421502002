import type { ESQueryExecutionModel } from '@repo/api-codegen';
import { useApiGetQueryExecutions } from '@repo/api-codegen';
import { useMemo } from 'react';
import {
	CostRender,
	LastUpdatedRender,
	ReadOnlyOwnerRender,
	RuntimeRender,
} from '../../../../components/TableV2/render';
import type { ExtendedDataTableColumn } from '../../../../components/TableV2/types';

export const useQueryExecutionsList = (queryId: string, lookBackDays: number) =>
	function ({ page }: { page?: number | undefined }) {
		return useApiGetQueryExecutions({
			pathParams: {
				queryId,
			},
			queryParams: {
				page: page || 1,
				page_size: 50,
				lookback_days: lookBackDays,
			},
		});
	};

export const useColumns =
	(): ExtendedDataTableColumn<ESQueryExecutionModel>[] =>
		useMemo(
			() => [
				{
					accessor: 'started_at',
					title: 'Date',
					width: 100,
					render: (record) => (
						<LastUpdatedRender record={record} field={'started_at'} />
					),
				},
				{
					accessor: 'user',
					title: 'User',
					width: 200,
					render: (record) => <ReadOnlyOwnerRender record={record} />,
				},
				{
					accessor: 'runtime',
					title: 'Runtime',
					width: 100,
					render: (record) => <RuntimeRender record={record} />,
				},
				{
					accessor: 'estimated_cost',
					title: 'Cost',
					width: 200,
					render: (record) => <CostRender record={record} />,
				},
			],
			[]
		);

export const useActions = () => [];
