import { Stack, createStyles } from '@mantine/core';
import type { MantineNumberSize } from '@mantine/styles';
import { Text } from '@repo/foundations';
import { isEmpty, map } from 'lodash-es';
import type { IAction } from '../types';
import DefaultActionItem from './DefaultActionItem';

export interface IActionsListProps {
	groupHeading?: string;
	actions: IAction[];
	spacing?: MantineNumberSize;
	selected?: string;
	setHovered: (actionID: string) => void;
}

const useStyles = createStyles((theme) => ({
	groupHeading: {
		fontWeight: theme.other.typography.weight.semibold,
		fontSize: theme.other.typography.text.sm,
		padding: `0 ${theme.spacing.xs} ${theme.spacing.xs} ${theme.spacing.xs}`,
		color: theme.other.getColor('text/secondary/default'),
	},
}));

function ActionsList({
	groupHeading = '',
	actions,
	selected,
	setHovered,
	spacing = 0,
}: IActionsListProps) {
	const { classes } = useStyles();

	const handleHover = (actionID: string) => () => setHovered(actionID);
	if (actions.length === 0 && groupHeading === '') return null;

	return (
		<Stack spacing={spacing} pb="xs">
			{!isEmpty(groupHeading) && (
				<Text
					data-testid={`search-action-list-heading-${groupHeading}`}
					className={classes.groupHeading}
				>
					{groupHeading}
				</Text>
			)}
			{map(actions, (action) => {
				const isSelected = action.id === selected;
				const ActionItem = action?.component ?? DefaultActionItem;

				return (
					<ActionItem
						key={`${action.id}-${isSelected}`} // To re-render on selection change
						action={action}
						selected={isSelected}
						onHover={handleHover(action.id)}
					/>
				);
			})}
		</Stack>
	);
}

export default ActionsList;
