import { useDisclosure } from '@mantine/hooks';
import { IconButton } from '@repo/foundations';
import { useCallback } from 'react';
import type { ISecodaEntity } from '../../api';
import { useAuthUser, useWorkspace } from '../../api';
import type { SecodaEntity } from '../../lib/models';
import { trackEvent } from '../../utils/analytics';
import { isViewerOfEntity } from '../../utils/authorization/roles';
import { ScrollableModal } from '../ScrollableModal/ScrollableModal';
import { Content } from './Content';
import { Footer } from './Footer';

export function EntityPermissions({
	model,
}: {
	model: SecodaEntity | ISecodaEntity;
}) {
	const { user } = useAuthUser();
	const readOnly = isViewerOfEntity(user, model);

	const [opened, { close, toggle }] = useDisclosure(false);

	const { workspace } = useWorkspace();

	const trackClick = useCallback(() => {
		trackEvent(
			`${model.entity_type}/share_settings/click`,
			{
				resource_id: model.id,
			},
			user,
			workspace
		);
	}, [model.entity_type, model.id, user, workspace]);

	const handleClick = () => {
		trackClick();
		toggle();
	};

	return (
		<>
			<IconButton
				iconName="share"
				onClick={handleClick}
				variant="tertiary"
				tooltip="Share and set permissions"
			/>
			<ScrollableModal
				headerProps={{ pl: 'lg' }}
				opened={opened}
				title={`Permissions of ${model.title}`}
				onClose={close}
				withHeaderDivider={false}
				size={480}
				footer={<Footer model={model} readOnly={readOnly} />}
				withDoneFooter={false}
				modalProps={{
					top: 150,
				}}
			>
				<Content model={model} readOnly={readOnly} />
			</ScrollableModal>
		</>
	);
}
