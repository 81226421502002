export const DEFAULT_RESULT_LIMIT = 5;
export const DEFAULT_RECENT_SEARCHES_LIMIT = 3;

// Different rendering states made global for easier reading
export const SearchPaletteState = {
	DEFAULT: 'default',
	LOADING: 'loading',
	RESULTS: 'results',
	NO_RESULTS: 'no-results',
	NO_RESULTS_FILTERS: 'no-results-with-filters',
} as const;

export type ISearchPaletteState =
	(typeof SearchPaletteState)[keyof typeof SearchPaletteState];
