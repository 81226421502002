import type { CollapseProps, DefaultProps, Selectors } from '@mantine/core';
import {
	Box,
	Collapse,
	createStyles,
	Divider,
	Group,
	Stack,
	UnstyledButton,
} from '@mantine/core';
import { Icon, Text, Title } from '@repo/foundations';
import type React from 'react';
import { useLocalStorageBooleanState } from '../../hooks/useLocalStorageBooleanState';

const useStyles = createStyles((theme) => ({
	root: {},
	collapsableWrapper: {
		padding: `0 ${theme.spacing.xl}`,
	},
	button: {
		padding: `${theme.spacing['3xs']} ${theme.spacing.xs}`,
		marginLeft: `-${theme.spacing.xs}`,
		borderRadius: theme.radius.md,
		'&:hover': {
			backgroundColor: theme.other.getColor('surface/primary/hover'),
		},
	},
	actions: {
		flexShrink: 0,
	},
	collapsedTextWrapper: {
		background: theme.other.getColor('surface/secondary/default'),
		paddingLeft: theme.other.space[1],
		paddingRight: theme.other.space[1],
		marginLeft: `${theme.spacing.sm}`,
		borderRadius: theme.radius.xs,
	},
	title: {
		fontSize: theme.other.typography.title.sm,
	},
}));

type CollapsableStackStylesNames = Selectors<typeof useStyles>;

export interface ICollapsableStackProps
	extends DefaultProps<CollapsableStackStylesNames> {
	groupName: string;
	defaultOpened?: boolean;
	children: React.ReactNode;
	collapseProps?: Omit<CollapseProps, 'in' | 'children'>;
	withDivider?: boolean;
	actions?: React.ReactNode;
	collapsedText?: string;
	alwaysShowCollapsedText?: boolean;
}

function CollapsableStack({
	groupName,
	children,
	defaultOpened = true,
	classNames,
	className,
	styles,
	unstyled,
	collapseProps = {},
	withDivider = true,
	actions,
	collapsedText = '',
	alwaysShowCollapsedText = false,
	...others
}: ICollapsableStackProps) {
	const { classes, cx } = useStyles(undefined, {
		name: 'CollapsableStack',
		classNames,
		styles,
		unstyled,
	});

	const [open, { toggle }] = useLocalStorageBooleanState(
		groupName,
		defaultOpened
	);

	const ToggleIcon = open
		? () => <Icon name="chevronDown" color="icon/primary/default" />
		: () => <Icon name="chevronRight" color="icon/primary/default" />;

	const collapsedTextVisible =
		collapsedText && (!open || alwaysShowCollapsedText);

	return (
		<Stack className={cx(classes.root, className)} {...others}>
			<Stack className={cx(classes.collapsableWrapper, className)}>
				<Group position="apart" noWrap>
					<UnstyledButton className={classes.button} onClick={toggle}>
						<Group spacing="4xs" noWrap>
							<Title
								weight="bold"
								className={cx(classes.title, classNames?.title)}
							>
								{groupName}
							</Title>
							{collapsedTextVisible && (
								<Box className={classes.collapsedTextWrapper}>
									<Text className={cx(classes.title, classNames?.title)}>
										{collapsedText || ''}
									</Text>
								</Box>
							)}
							<ToggleIcon />
						</Group>
					</UnstyledButton>
					{actions && <Box className={classes.actions}>{actions}</Box>}
				</Group>
				<Collapse in={Boolean(open)} {...collapseProps}>
					{children}
				</Collapse>
			</Stack>
			{withDivider && <Divider />}
		</Stack>
	);
}

export default CollapsableStack;
