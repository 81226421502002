import { Group, createStyles } from '@mantine/core';
import { Text } from '@repo/foundations';
import type { IEmbeddedPromptResponseStep } from '../../../api';

export interface MessageAILoadingProps {
	steps?: IEmbeddedPromptResponseStep[];
}

const useStyles = createStyles((theme) => ({
	root: {
		width: '100%',
		overflow: 'hidden',
	},
	wrapper: {
		position: 'relative',
		height: theme.other.space[6],
	},
	text: {
		position: 'absolute',
		whiteSpace: 'nowrap',
		top: '0px',
		left: '0px',
		animationFillMode: 'forwards',
		animationDuration: '1s',

		'@keyframes flipUp': {
			from: {
				transform: 'translateY(100%)',
			},
			to: {
				transform: 'translateY(0)',
			},
		},

		'@keyframes flipOut': {
			from: {
				transform: 'translateY(0)',
			},
			to: {
				transform: 'translateY(-100%)',
			},
		},

		'&.last-message': {
			animationName: 'flipUp',
		},

		'&.previous-message': {
			animationName: 'flipOut',
		},

		':after': {
			overflow: 'hidden',
			display: 'inline-block',
			verticalAlign: 'bottom',
			animation: 'ellipsis 2s infinite',
			content: "'\\2026'",
			width: '0px',

			'@keyframes ellipsis': {
				from: {
					width: '0px',
				},
				to: {
					width: '15px',
				},
			},
		},
	},
}));

export function MessageAILoading({ steps = [] }: MessageAILoadingProps) {
	const { classes, cx } = useStyles();

	const stepTitles = steps?.map((step) => step.title) ?? [];
	const messages = ['Computing answer', ...stepTitles];

	return (
		<Group spacing="3xs" className={classes.root}>
			<div className={classes.wrapper}>
				{messages.map((message, idx) => {
					let className = 'skip-animation';

					if (messages.length > 1) {
						className = 'previous-message';

						if (idx === messages.length - 1) {
							className = 'last-message';
						}
					}

					return (
						<Text
							// eslint-disable-next-line react/no-array-index-key
							key={idx}
							size="md"
							color="text/secondary/default"
							className={cx(classes.text, className)}
						>
							{message.endsWith('.')
								? message.substring(0, message.length - 1)
								: message}
						</Text>
					);
				})}
			</div>
		</Group>
	);
}
