import { showNotification } from '@mantine/notifications';
import { useApiArchivedRestoreEntities } from '@repo/api-codegen';
import { EntityType } from '@repo/common/enums/entityType';
import { IconAlertCircle, IconCheck } from '@tabler/icons-react';
import { isString, size } from 'lodash-es';
import { useCallback } from 'react';
import type { ISecodaEntity } from '../../api';
import {
	queryClient,
	secodaEntitiesQueryKeyFactory,
	useAuthUser,
} from '../../api';
import type { FetchModelInfiniteListHook } from '../../api/factories/types';
import { catalogQueryKeyFactory } from '../../api/hooks/catalog/constants.ts';
import { resourceCatalogQueryKeyFactory } from '../../api/hooks/resourceCatalog/constants';
import type { SuccessMessage } from '../../api/hooks/secodaEntity/useBulkUpdateSecodaEntities';
import entityDrawerStore from '../../components/EntityDrawer/store';
import { TableV2 } from '../../components/TableV2';
import { archivedEntitiesInfiniteList, useColumns } from './TrashPage.hooks';

export interface ITrashEntityTableProps {
	onRestoreMessage: SuccessMessage;
	restoreData: Record<string, string | boolean | null>;
}

const QUICK_ACTIONS = ['actions::restore'] as const;

export function TrashEntityTable({
	onRestoreMessage = { title: '', message: '' },
	restoreData = {},
}: ITrashEntityTableProps) {
	const columns = useColumns();
	const { isEditorOrAdminUser } = useAuthUser();

	const { mutateAsync: updateBulkSecodaEntity } = useApiArchivedRestoreEntities(
		{
			onError: (err) => {
				const message = err?.detail;
				showNotification({
					title: 'Error updating resources',
					message: isString(message)
						? message
						: 'An error occurred while updating the resources',
					color: 'red',
					icon: <IconAlertCircle size={16} />,
				});
			},
			onSuccess: () => {
				queryClient.invalidateQueries(
					resourceCatalogQueryKeyFactory.allLists()
				);
				queryClient.invalidateQueries(secodaEntitiesQueryKeyFactory.allLists());
				queryClient.invalidateQueries(catalogQueryKeyFactory.allLists());

				showNotification({
					title: onRestoreMessage.title,
					message: onRestoreMessage.message,
					color: 'green',
					icon: <IconCheck size={16} />,
				});
			},
		}
	);

	const handleRestore = useCallback(
		async (selectedEntities: ISecodaEntity[], clearSelected: VoidFunction) => {
			if (selectedEntities.length === 0) {
				return;
			}
			await updateBulkSecodaEntity({
				body: selectedEntities.map((i) => i.id),
			});

			clearSelected();
		},
		[updateBulkSecodaEntity]
	);

	const handleNavigate = useCallback(
		async ({ record }: { record: ISecodaEntity }) => {
			await entityDrawerStore.openEntityDrawerById(
				isEditorOrAdminUser,
				record.id,
				record.entity_type,
				undefined,
				{ archived: true }
			);
		},
		[isEditorOrAdminUser]
	);

	return (
		<TableV2<ISecodaEntity>
			pluralTypeString="resources"
			withQuickActions={QUICK_ACTIONS}
			withInfiniteScroll={false} // Codegen API does not work with infinite scrolling...
			withFilters={false}
			withCheckbox
			defaultSort={{
				field: 'title',
				order: 'asc',
			}}
			withSearch
			onCellClick={handleNavigate}
			withActions={[
				{
					id: 'actions::restore',
					title: 'Restore',
					name: 'Restore',
					iconName: 'arrowsSplit' as const,
					hotkey: '/sp',
					type: EntityType.all,
					team: undefined,
					category: 'actions',
					show: (selected: ISecodaEntity[]) => size(selected) >= 1,
					onClick: handleRestore,
				},
			]}
			// Only include read-only columns.
			columns={columns.filter((column) =>
				['title', 'type', 'parent', 'updated_at'].includes(column.accessor)
			)}
			defaultRequiredSearchParams={{
				archived: true,
			}}
			usePaginationList={
				archivedEntitiesInfiniteList as FetchModelInfiniteListHook<ISecodaEntity>
			}
			usePaginationListOptions={{
				retry: false,
			}}
		/>
	);
}
