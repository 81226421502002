import { useMutation } from '@tanstack/react-query';
import { apiClient, getEndpoints } from '../../common';
import type { Catalog } from '../../types';
import { catalogQueryKeyFactory } from './constants';

type RequestData = Pick<Catalog, 'id' | 'view'>;

export function useCatalogToggleView() {
	return useMutation({
		mutationFn: async ({ id, view }: RequestData) => {
			const url = getEndpoints(catalogQueryKeyFactory.namespace).byPath([
				id,
				'toggle_view',
			]);

			const { data } = await apiClient.patch<Catalog>(url, {
				view,
			});

			return data;
		},
	});
}
