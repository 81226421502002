import {
	Box,
	Group,
	Menu,
	Skeleton,
	Stack,
	Textarea,
	createStyles,
} from '@mantine/core';
import { useNavigate } from '@repo/common/hooks/useNavigate';
import { Button } from '@repo/foundations';
import { typography } from '@repo/theme/primitives';
import { isNil } from 'lodash-es';
import type { ChangeEvent } from 'react';
import { useAuthUser } from '../../../api';

interface IQueryNavProps {
	entityId?: string;
	entityTitle?: string;
	entityType?: string;

	lookBackDays: number;
	setLookBackDays: (days: number) => void;

	queryDescription: string | undefined | null;
	onUpdateQueryDescription: (description: string) => void;

	displayLookback?: boolean;
}

const useStyles = createStyles((theme) => ({
	actions: {
		paddingTop: theme.spacing['xs'],
		paddingLeft: theme.spacing['xl'],
		paddingRight: theme.spacing['2xl'],
	},
	horizontalDivider: {
		fontWeight: 600,
		color: theme.colors.gray[5],
	},
	wrapper: {
		display: 'flex',
		width: '100%',
		cursor: 'pointer',
	},
	textAreaRoot: {
		display: 'flex',
		flexGrow: '1 !important' as any,
	},
	textAreaWrapper: {
		width: '100%',
	},
	textAreaInput: {
		fontSize: typography.text['xl'],
		fontWeight: typography.weight.bold,

		'&:focus': {
			boxShadow: 'none',
			borderColor: 'transparent',
			backgroundColor: 'transparent',
		},

		'&:disabled': {
			opacity: 1,
			backgroundColor: theme.other.getColor('fill/transparent/default'),
			color: theme.other.getColor('text/primary/default'),
			cursor: 'text',
		},
	},
	content: {
		marginLeft: theme.spacing['2xl'],
		marginRight: theme.spacing['2xl'],
		marginBottom: theme.spacing['lg'],
		width: `calc(100% - (2 * ${theme.spacing['2xl']}))`,
		flexGrow: 1,
	},
}));

function QueryNav({
	entityId,
	entityType,
	entityTitle,
	lookBackDays,
	setLookBackDays,
	queryDescription,
	onUpdateQueryDescription,
	displayLookback = true,
}: IQueryNavProps) {
	const { classes } = useStyles();

	const navigate = useNavigate();

	const { isEditorOrAdminUser } = useAuthUser();

	const handleBack = () => {
		navigate(`/${entityType}/${entityId}/queries`);
	};

	const handleQueriesPage = () => {
		navigate('/queries/');
	};

	const setLookbackDaysCallback = (days: number) => () => {
		setLookBackDays(days);
	};

	const handleUpdateQueryDescription = (
		event: ChangeEvent<HTMLTextAreaElement>
	) => {
		onUpdateQueryDescription(event.currentTarget.value || '');
	};

	return (
		<Stack spacing={'xs'}>
			<Group position="apart" className={classes.actions}>
				<Group spacing={'xs'}>
					<Button variant="tertiary" onClick={handleQueriesPage}>
						{'Queries'}
					</Button>
					{!!entityTitle && (
						<>
							<Box className={classes.horizontalDivider}>/</Box>
							<Button variant="tertiary" onClick={handleBack}>
								{entityTitle}
							</Button>
						</>
					)}
				</Group>
				{displayLookback && (
					<Menu>
						<Menu.Target>
							<Button leftIconName="calendar" variant="primary">
								Last {lookBackDays} days
							</Button>
						</Menu.Target>
						<Menu.Dropdown>
							<Menu.Item onClick={setLookbackDaysCallback(1)}>Today</Menu.Item>
							<Menu.Item onClick={setLookbackDaysCallback(7)}>7 days</Menu.Item>
							<Menu.Item onClick={setLookbackDaysCallback(30)}>
								30 days
							</Menu.Item>
						</Menu.Dropdown>
					</Menu>
				)}
			</Group>
			<Stack className={classes.content} spacing="0">
				{!isNil(queryDescription) && (
					<Textarea
						value={queryDescription}
						onChange={handleUpdateQueryDescription}
						placeholder={'Add description...'}
						disabled={!isEditorOrAdminUser}
						variant="unstyled"
						autosize
						minRows={2}
						size="md"
					/>
				)}
				{isNil(queryDescription) && <Skeleton height={24} radius="lg" />}
			</Stack>
		</Stack>
	);
}

export default QueryNav;
