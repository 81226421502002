import { createStyles, getStylesRef } from '@mantine/core';
import { NODE_HEADER_HEIGHT } from '../../constants';
import { LineageDirectionEnum } from '../../types';

interface IEntityNodeStylesProps {
	direction?: LineageDirectionEnum;
}

export const useStyles = createStyles(
	(theme, { direction }: IEntityNodeStylesProps) => {
		// Prepare static styles and minimize dynamic calculations
		const horizontalPosition = {
			left:
				direction === LineageDirectionEnum.UPSTREAM
					? `-${theme.other.space[16]}px`
					: undefined,
			right:
				direction === LineageDirectionEnum.DOWNSTREAM
					? `-${theme.other.space[16]}px`
					: undefined,
		};

		return {
			buttonRoot: {
				position: 'absolute',
				...horizontalPosition,
				top: NODE_HEADER_HEIGHT / 4,
				minWidth: theme.other.space[12],
				height: theme.other.space[8],
				boxShadow: theme.shadows.md,
				'&:hover': {
					[`& .${getStylesRef('lineage-indicator-label')}`]: {
						visibility: 'visible',
						width: 'auto',
					},
					[`& .${getStylesRef('lineage-indicator-right-icon')}`]: {
						visibility: 'hidden',
						width: 0,
					},
				},
			},
			buttonRightIcon: {
				ref: getStylesRef('lineage-indicator-right-icon'),
				marginLeft: 0,
			},
			buttonLabel: {
				ref: getStylesRef('lineage-indicator-label'),
				visibility: 'hidden',
				width: 0,
				color: theme.other.getColor('text/secondary/default'),
			},
		};
	}
);
