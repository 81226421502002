import { createStyles, Group, Stack, UnstyledButton } from '@mantine/core';
import { Icon, Text } from '@repo/foundations';
import { SCHEDULE_OPTIONS } from './constants';

const useStyles = createStyles((theme) => ({
	dropdown: {
		borderRadius: theme.radius.md,
		border: `${theme.other.borderWidth.xs} solid ${theme.other.getColor('border/primary/default')}`,
		background: theme.other.getColor('surface/primary/default'),
		minWidth: theme.other.width['2xs'],
	},
	button: {
		padding: theme.spacing['2xs'],
		borderRadius: theme.radius.sm,

		'&:hover, &:active': {
			background: theme.other.getColor('surface/primary/hover'),
		},
	},
	buttonSelected: {
		background: theme.other.getColor('surface/primary/selected'),
	},
}));

interface SchedulingDropdownProps {
	value: number | null;
	onChange: (value: number | null) => void;
}

export function SchedulingDropdown({
	value,
	onChange,
}: SchedulingDropdownProps) {
	const { classes, cx } = useStyles();

	return (
		<Stack spacing={0} p="2xs" className={classes.dropdown}>
			{SCHEDULE_OPTIONS.map((option) => (
				<UnstyledButton
					key={option.value ?? ''}
					className={cx(
						classes.button,
						option.value === value && classes.buttonSelected
					)}
					onClick={() => onChange(option.value)}
				>
					<Group position="apart" spacing="xs" px="2xs">
						<Text color="text/primary/default" size="sm">
							{option.label}
						</Text>
						{option.value === value && (
							<Icon name="check" color="icon/primary/default" />
						)}
					</Group>
				</UnstyledButton>
			))}
		</Stack>
	);
}
