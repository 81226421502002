import type { TeamOut } from '@repo/api-codegen';
import { EntityType } from '@repo/common/enums/entityType';
import { pluralize } from '@repo/common/utils';
import type { BreadcrumbCrumb } from '@repo/foundations';
import {
	capitalize,
	find,
	includes,
	isNil,
	lowerCase,
	map,
	sortBy,
	startCase,
} from 'lodash-es';
import type { IIntegration, INestedBreadcrumbs } from '../api';
import {
	isCatalogEntity,
	isCollection,
	isDatabase,
	isDictionaryTerm,
	isDocument,
	isGlossary,
	isMetric,
	isQuestion,
	isSchema,
} from '../lib/utils/entity';
import { getEntityTypeDisplayInfo } from './entityDisplayUtils';
import { buildResourceUrl } from './navigationUtils';
import { getNavigationUrl } from './url';

export interface BreadcrumbEntity {
	id: string;
	entity_type: EntityType;
	title?: string;
	title_cased?: string;
	teams?: Array<string>;
	integration?: string;
}

/**
 * Retrieves the team id associated with the given entity.
 * If there are multiple teams associated with the entity, it picks the preferred one.
 * If there are multiple teams associated with the entity, and no preferred team, it picks the first one.
 * If there are no teams associated with the entity, it returns the ID of a
 * pseudo-random team that the user is a part of.
 * @param entity The entity object.
 * @param preferredTeamId The team that we would prefer to return.
 * @param userTeams The array of teams the user is a member of.
 * @param workspaceIntegrations The array of workspace integrations.
 * @returns The ID of the associated team, or the ID of the default team if no specific team is found.
 */
function getEntityTeamId(
	entity: BreadcrumbEntity,
	preferredTeamId: string | null,
	userTeams: Array<TeamOut>,
	workspaceIntegrations: Array<IIntegration>
) {
	const userTeamIds = map(userTeams, 'id');
	// Retrieve the teams associated with the entity
	const entityTeams = entity.teams || [];
	// Retrieve the teams associated with the entity's integration (if any)
	const entityIntegrationTeams =
		find(
			workspaceIntegrations,
			(integration) => entity.integration === integration.id
		)?.teams || [];

	const preferredEntityTeamId = find(
		[...entityTeams, ...entityIntegrationTeams],
		(team) => includes(userTeamIds, team) && team === preferredTeamId
	);

	if (preferredEntityTeamId) {
		return preferredEntityTeamId;
	}

	const validEntityTeamId = find(
		[...entityTeams, ...entityIntegrationTeams],
		(team) => includes(userTeamIds, team)
	);

	const defaultTeamId = find(userTeams, (team) => team.is_default_team)?.id;
	return validEntityTeamId || defaultTeamId;
}

/**
 * Retrieves the team associated with the given team id.
 */
function getEntityTeam(
	entity: BreadcrumbEntity,
	preferredTeamId: string | null,
	userTeams: Array<TeamOut>,
	workspaceIntegrations: Array<IIntegration>
) {
	const teamId = getEntityTeamId(
		entity,
		preferredTeamId,
		userTeams || [],
		workspaceIntegrations || []
	);

	const team = find(userTeams, (team) => team.id === teamId);
	return team;
}

/**
 * Returns a function that generates a breadcrumb link based on the provided entity, and workspace teams.
 * @param entity The entity object.
 * @param userTeams The array of teams the user is a member of.
 * @param workspaceIntegrations The array of workspace integrations.
 * @returns A function that generates the breadcrumb link.
 */
export const getBreadcrumbLink =
	(
		entity: BreadcrumbEntity,
		preferredTeamId: string | null,
		native_type: string,
		userTeams: Array<TeamOut> = [],
		workspaceIntegrations: Array<IIntegration> = []
	) =>
	(
		data: string | null | undefined,
		entityType?: EntityType | 'integration'
	) => {
		const value = data?.toLowerCase();
		const teamId = getEntityTeamId(
			entity,
			preferredTeamId,
			userTeams,
			workspaceIntegrations
		);

		if (isCatalogEntity(entity)) {
			if (entityType === undefined) {
				if (native_type) {
					const filtersArray = [
						{
							value: [native_type],
							operator: 'is',
							filterType: 'native_type',
						},
					];
					const filtersString = JSON.stringify(filtersArray);
					const encodedFilters = encodeURIComponent(filtersString);
					const navigationUrl = `/teams/${teamId}/catalog?filters=${encodedFilters}`;
					return getNavigationUrl(navigationUrl);
				}
				return getNavigationUrl(`/teams/${teamId}/catalog`);
			}

			if (entityType === 'integration') {
				return getNavigationUrl(`/teams/${teamId}/catalog`, {
					filters: {
						integration: [value],
					},
				});
			}

			if (entityType === EntityType.table) {
				return getNavigationUrl(`/table/${value}`);
			}

			if (entityType === EntityType.column) {
				return getNavigationUrl(`/column/${value}`);
			}

			// schema has a specific filter type
			if (entityType === EntityType.schema) {
				return getNavigationUrl(`/schema/${value}`);
			}

			if (entityType === EntityType.database) {
				return getNavigationUrl(`/database/${value}`);
			}

			// other types of filters fallback to parent
			return getNavigationUrl(`/teams/${teamId}/catalog`, {
				filters: {
					parent: [value],
				},
			});
		}

		if (isCollection(entity)) {
			return `/teams/${teamId}/collections`;
		}

		if (isMetric(entity)) {
			return `/teams/${teamId}/metrics`;
		}

		if (isDictionaryTerm(entity)) {
			return `/teams/${teamId}/dictionary`;
		}

		if (isGlossary(entity)) {
			return `/teams/${teamId}/glossary`;
		}

		if (isQuestion(entity)) {
			return `/teams/${teamId}/questions`;
		}

		if (isDocument(entity)) {
			return `/teams/${teamId}/docs`;
		}

		if (isSchema(entity)) {
			return `/teams/${teamId}/catalog?filters=%5B%7B"value"%3A%5B"schema"%5D%2C"operator"%3A"is"%2C"filterType"%3A"native_type"%7D%5D`;
		}

		if (isDatabase(entity)) {
			return `/teams/${teamId}/catalog?filters=%5B%7B"value"%3A%5B"database"%5D%2C"operator"%3A"is"%2C"filterType"%3A"native_type"%7D%5D`;
		}

		// Return an empty string if the entity type is not recognized
		return '';
	};

/**
 * Generates nestedBreadcrumbs for catalog entities.
 * @param type - Entity type.
 * @param getBreadcrumbFn - Breadcrumb function.
 * @param integration - Integration
 * @param nestedBreadcrumbs - Nested breadcrumb objects.
 * @returns Array of breadcrumb objects with titles and hrefs.
 */
export const getCatalogEntityBreadcrumbs = (
	entity_type: EntityType,
	native_type: string,
	getBreadcrumbFn: ReturnType<typeof getBreadcrumbLink>,
	integration?: IIntegration,
	nestedBreadcrumbs: Array<INestedBreadcrumbs> = []
): Array<BreadcrumbCrumb> => {
	let { label } = getEntityTypeDisplayInfo(entity_type);
	if (native_type) {
		label = pluralize(capitalize(lowerCase(startCase(native_type))));
	}

	return [
		{ title: label, href: getBreadcrumbFn(null) },
		{
			title: integration?.name ?? 'Integration',
			href: getBreadcrumbFn(integration?.id, 'integration'),
		},
		...map(nestedBreadcrumbs, (item) => ({
			title: item.title,
			href: getBreadcrumbFn(item.id, item.entity_type),
		})),
	];
};

/**
 * Generates nestedBreadcrumbs for schemas.
 * @param getBreadcrumbFn - Breadcrumb function.
 * @returns Array of breadcrumb objects with titles and hrefs.
 */
export const getDatabaseBreadcrumbs = (
	getBreadcrumbFn: ReturnType<typeof getBreadcrumbLink>
) => [{ title: 'Databases', href: getBreadcrumbFn(null) }];

/**
 * Generates nestedBreadcrumbs for schemas.
 * @param getBreadcrumbFn - Breadcrumb function.
 * @returns Array of breadcrumb objects with titles and hrefs.
 */
export const getSchemaBreadcrumbs = (
	getBreadcrumbFn: ReturnType<typeof getBreadcrumbLink>
) => [{ title: 'Schemas', href: getBreadcrumbFn(null) }];

/**
 * Generates nestedBreadcrumbs for collections.
 * @param getBreadcrumbFn - Breadcrumb function.
 * @returns Array of breadcrumb objects with titles and hrefs.
 */
export const getCollectionBreadcrumbs = (
	getBreadcrumbFn: ReturnType<typeof getBreadcrumbLink>
): Array<BreadcrumbCrumb> => [
	{ title: 'Collections', href: getBreadcrumbFn(null) },
];

/**
 * Generates nestedBreadcrumbs for questions.
 * @param getBreadcrumbFn - Breadcrumb function.
 * @returns Array of breadcrumb objects with titles and hrefs.
 */
export const getQuestionBreadcrumbs = (
	getBreadcrumbFn: ReturnType<typeof getBreadcrumbLink>
): Array<BreadcrumbCrumb> => [
	{ title: 'Questions', href: getBreadcrumbFn(null) },
];

/**
 * Generates nestedBreadcrumbs for metrics.
 * @param getBreadcrumbFn - Breadcrumb function.
 * @param nestedBreadcrumbs - Nested breadcrumb objects. Defaults to empty array.
 * @returns Array of breadcrumb objects with titles and hrefs.
 */
export const getMetricBreadcrumbs = (
	getBreadcrumbFn: ReturnType<typeof getBreadcrumbLink>,
	nestedBreadcrumbs: Array<INestedBreadcrumbs> = []
): Array<BreadcrumbCrumb> => [
	{
		title: 'Metrics',
		href: getBreadcrumbFn(null),
	},
	...map(nestedBreadcrumbs, (b) => ({
		title: b.title,
		href: `/metrics/${b.id}`,
	})),
];

/**
 * Generates nestedBreadcrumbs for dictionary terms.
 * @param getBreadcrumbFn - Breadcrumb function.
 * @param nestedBreadcrumbs - Nested breadcrumb objects. Defaults to empty array.
 * @returns Array of breadcrumb objects with titles and hrefs.
 */
export const getDictionaryTermBreadcrumbs = (
	getBreadcrumbFn: ReturnType<typeof getBreadcrumbLink>,
	nestedBreadcrumbs: Array<INestedBreadcrumbs> = []
): Array<BreadcrumbCrumb> => [
	{
		title: 'Dictionary',
		href: getBreadcrumbFn(null),
	},
	...map(nestedBreadcrumbs, (b) => ({
		title: b.title,
		href: `/dictionary/${b.id}`,
	})),
];

/**
 * Generates nestedBreadcrumbs for glossary.
 * @param getBreadcrumbFn - Breadcrumb function.
 * @param nestedBreadcrumbs - Nested breadcrumb objects. Defaults to empty array.
 * @returns Array of breadcrumb objects with titles and hrefs.
 */
export const getGlossaryBreadcrumbs = (
	getBreadcrumbFn: ReturnType<typeof getBreadcrumbLink>,
	nestedBreadcrumbs: Array<INestedBreadcrumbs> = []
): Array<BreadcrumbCrumb> => [
	{
		title: 'Glossary',
		href: getBreadcrumbFn(null),
	},
	...map(nestedBreadcrumbs, (b) => ({
		title: b.title,
		href: `/glossary/${b.id}`,
	})),
];

/**
 * Generates nestedBreadcrumbs for documents.
 * @param getBreadcrumbFn - Breadcrumb function.
 * @param nestedBreadcrumbs - Nested breadcrumb objects. Defaults to empty array.
 * @returns Array of breadcrumb objects with titles and hrefs.
 */
export const getDocumentBreadcrumbs = (
	getBreadcrumbFn: ReturnType<typeof getBreadcrumbLink>,
	nestedBreadcrumbs: Array<INestedBreadcrumbs> = []
): Array<BreadcrumbCrumb> => [
	{
		title: 'Documents',
		href: getBreadcrumbFn(null),
	},
	...map(nestedBreadcrumbs, (b) => ({
		title: b.title,
		href: `/docs/${b.id}`,
	})),
];

interface IgetTeamBreadcrumbs {
	entity: BreadcrumbEntity;
	preferredTeamId: string | null;
	teams?: Array<TeamOut>;
	integrations?: Array<IIntegration>;
}

function getTeamBreadcrumbs({
	entity,
	preferredTeamId,
	teams,
	integrations,
}: IgetTeamBreadcrumbs): Array<BreadcrumbCrumb> {
	const team = getEntityTeam(
		entity,
		preferredTeamId,
		teams || [],
		integrations || []
	);

	if (team) {
		return [
			{
				icon: team.icon || undefined,
				title: team.name,
				href: `/teams/${team.id}/`,
			},
		];
	}
	return [];
}

interface IgetCurrentPageBreadcrumbs {
	entity: BreadcrumbEntity;
}

function getCurrentPageBreadcrumbs({
	entity,
}: IgetCurrentPageBreadcrumbs): Array<BreadcrumbCrumb> {
	if (entity && entity.title && entity.id) {
		const entityUrl = buildResourceUrl(entity);
		return [
			{
				title: entity.title_cased ?? entity.title ?? '',
				href: entityUrl,
			},
		];
	}
	return [];
}

interface IgetBreadcrumbForEntityArgs {
	entity: BreadcrumbEntity;
	preferredTeamId: string | null;
	nativeType: string;
	teams?: Array<TeamOut>;
	nestedBreadcrumbs?: Array<INestedBreadcrumbs>;
	integrations?: Array<IIntegration>;
	includeTeam?: boolean;
	includeCurrentPage?: boolean;
}

export function getBreadcrumbForEntity({
	entity,
	preferredTeamId,
	nativeType,
	teams,
	nestedBreadcrumbs,
	integrations,
	includeTeam = false,
	includeCurrentPage = false,
}: IgetBreadcrumbForEntityArgs) {
	const getBreadcrumbFn = getBreadcrumbLink(
		entity,
		preferredTeamId,
		nativeType,
		teams,
		integrations
	);
	const integration = find(integrations, { id: entity.integration });

	const breadCrumbs = sortBy(nestedBreadcrumbs, ({ level }) => -level);

	const teamBreadcrumbs = includeTeam
		? getTeamBreadcrumbs({ entity, preferredTeamId, teams, integrations })
		: [];

	const currentPageBreadcrumbs = includeCurrentPage
		? getCurrentPageBreadcrumbs({ entity })
		: [];

	if (isCatalogEntity(entity)) {
		return teamBreadcrumbs
			.concat(
				getCatalogEntityBreadcrumbs(
					entity.entity_type,
					nativeType,
					getBreadcrumbFn,
					integration,
					breadCrumbs
				)
			)
			.concat(currentPageBreadcrumbs);
	}

	if (isCollection(entity)) {
		return teamBreadcrumbs
			.concat(getCollectionBreadcrumbs(getBreadcrumbFn))
			.concat(currentPageBreadcrumbs);
	}

	if (isQuestion(entity)) {
		return teamBreadcrumbs
			.concat(getQuestionBreadcrumbs(getBreadcrumbFn))
			.concat(currentPageBreadcrumbs);
	}

	if (isMetric(entity) && !isNil(breadCrumbs)) {
		return teamBreadcrumbs
			.concat(getMetricBreadcrumbs(getBreadcrumbFn, breadCrumbs))
			.concat(currentPageBreadcrumbs);
	}

	if (isDictionaryTerm(entity) && !isNil(breadCrumbs)) {
		return teamBreadcrumbs
			.concat(getDictionaryTermBreadcrumbs(getBreadcrumbFn, breadCrumbs))
			.concat(currentPageBreadcrumbs);
	}

	if (isGlossary(entity) && !isNil(breadCrumbs)) {
		return teamBreadcrumbs
			.concat(getGlossaryBreadcrumbs(getBreadcrumbFn, breadCrumbs))
			.concat(currentPageBreadcrumbs);
	}

	if (isDocument(entity) && !isNil(breadCrumbs)) {
		return teamBreadcrumbs
			.concat(getDocumentBreadcrumbs(getBreadcrumbFn, breadCrumbs))
			.concat(currentPageBreadcrumbs);
	}

	// eslint-disable-next-line no-console
	console.warn('[breadcrumbs] No breadcrumbs for type');

	return teamBreadcrumbs;
}
