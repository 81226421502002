import { useRef } from 'react';

export function useRefs<T>() {
	const refs = useRef<Record<string, T>>({});

	const setRefFromKey = (key: string) => (element: T) => {
		refs.current[key] = element;
	};

	return { refs: refs.current, setRefFromKey };
}
