import {
	Box,
	createStyles,
	Group,
	Input,
	Loader,
	Modal,
	Stack,
	TextInput,
} from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { fetchApiCreateAnnouncement } from '@repo/api-codegen';
import { Button, Title } from '@repo/foundations';
import { debounce, uniq } from 'lodash-es';
import { Suspense, useCallback, useState } from 'react';
import type { ISecodaEntity } from '../../api';
import { useAuthUser } from '../../api';
import type { SecodaEntity } from '../../lib/models';
import { trackEvent } from '../../utils/analytics';

import { ErrorBoundary } from '../ErrorBoundary';
import { RichEditor } from '../RichEditor';
import {
	AnnouncementEntityRecipientsEntity,
	AnnouncementEntityRecipientsGlobal,
} from './AnnouncementEntityRecipients';
import { announcementTemplate } from './helpers';
import type { AnnouncementFormKeys, IAnnouncementFieldProps } from './types';
import { AnnouncementDefaultFormData } from './types';

const useStyles = createStyles((theme) => ({
	editorWrapper: {
		padding: theme.spacing.sm,
		borderRadius: theme.radius.xs,
		border: `1px solid ${theme.colors.gray[4]}`,
	},
	formWrapper: { gap: 25 },
}));

function SubjectInput({ onChange }: IAnnouncementFieldProps) {
	return (
		<TextInput
			required
			size="md"
			label="Subject"
			onChange={(event) => onChange('subject')(event.target.value ?? '')}
		/>
	);
}

function BodyInput({ onChange, initialValue }: IAnnouncementFieldProps) {
	const { classes } = useStyles();

	return (
		<Input.Wrapper label="Body">
			<Box className={classes.editorWrapper}>
				<RichEditor
					initialValue={initialValue}
					onChangeCallback={(value?: string) =>
						onChange('definition')(value ?? '')
					}
					readOnly={false}
				/>
			</Box>
		</Input.Wrapper>
	);
}

type IAnnouncementModalProps = {
	entity?: SecodaEntity | ISecodaEntity;
	opened: boolean;
	close: () => void;
};

function AnnouncementModal({ entity, opened, close }: IAnnouncementModalProps) {
	const { classes } = useStyles();

	const { user, workspace } = useAuthUser();
	const [formData, setFormData] = useState(AnnouncementDefaultFormData);

	const debounceChange = debounce(
		(key: AnnouncementFormKeys, value: string | string[]) => {
			setFormData((prev) => ({ ...prev, [key]: value }));
		},
		300
	);

	const handleChange = useCallback(
		(key: AnnouncementFormKeys) => (value: string | string[]) => {
			if (formData[key] === value) return;

			// Only debounce certain properties that can't be edited simultaneously.
			// don't debounce definition - the Editor already debounces it
			if (['subject'].includes(key)) {
				debounceChange(key, value);
			} else {
				setFormData((prev) => ({ ...prev, [key]: value }));
			}
		},
		[debounceChange, formData]
	);

	const handleSubmit = useCallback(async () => {
		const values = formData;
		await fetchApiCreateAnnouncement({
			body: {
				title: values.subject,
				definition: values.definition,
				receivers: values.recipients,
				receiver_slack_channel_ids: uniq(values.receiver_slack_channel_ids),
			},
		});
		showNotification({ message: 'Announcement Sent' });
		trackEvent('inbox/send_announcement/click', {}, user, workspace);
		close();
	}, [formData, close]);

	return (
		<Modal
			size="xl"
			opened={opened}
			onClose={close}
			title={<Title order={4}>New announcement</Title>}
		>
			<Stack className={classes.formWrapper}>
				<SubjectInput onChange={handleChange} />
				<BodyInput
					onChange={handleChange}
					initialValue={entity ? announcementTemplate(entity) : undefined}
				/>
				<ErrorBoundary>
					<Suspense fallback={<Loader />}>
						{entity && (
							<AnnouncementEntityRecipientsEntity
								onChange={handleChange}
								entity={entity}
							/>
						)}
						{!entity && (
							<AnnouncementEntityRecipientsGlobal onChange={handleChange} />
						)}
					</Suspense>
				</ErrorBoundary>
			</Stack>
			<Group position="right" mt={12}>
				<Button onClick={close}>Cancel</Button>
				<Button variant="primary" onClick={handleSubmit}>
					Send
				</Button>
			</Group>
		</Modal>
	);
}

export default AnnouncementModal;
