import { Flex, Stack, TextInput, useMantineTheme } from '@mantine/core';
import { Button, PlainButton, Title } from '@repo/foundations';
import { isEmpty } from 'lodash-es';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router';
import { AuthCard } from './AuthCard';
import { AuthLayoutBase } from './AuthLayouts/AuthLayoutBase';
import { AuthStepStartContext } from './AuthStepStartContext';
import { AuthTermsAndPolicy } from './AuthTermsAndPolicy';

const REGEX_DOMAIN = /^[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

export function AuthStepSamlSso() {
	const theme = useMantineTheme();
	const navigate = useNavigate();
	const [value, setValue] = useState<string>('');
	const [error, setError] = useState<string | null>(null);

	const context = useContext(AuthStepStartContext);
	if (!context) {
		throw new Error(
			'AuthStepSamlSso must be used within a AuthStepStartContextProvider'
		);
	}
	const { startSAMLAuth } = context;

	const handleClick = () => {
		// If it's an email address, extract the domain.
		let domain = value.includes('@') ? value.split('@')[1] : value;
		domain = domain.trim().toLowerCase();

		if (domain && !isEmpty(domain) && domain.match(REGEX_DOMAIN)) {
			startSAMLAuth(domain);
		} else {
			setError('Please enter a valid domain');
		}
	};

	return (
		<AuthLayoutBase>
			<AuthCard>
				<Flex direction="column" gap="xl">
					<Title order={1}>Continue with SAML SSO</Title>
					<Stack spacing="sm">
						<TextInput
							onKeyDown={(e) => {
								if (e.key === 'Enter') {
									handleClick();
								}
							}}
							onChange={(e) => setValue(e.currentTarget.value)}
							value={value}
							error={error}
							type="text"
							label="Work email or domain name"
							styles={{ input: { borderRadius: theme.other.space[2] } }}
						/>
						<Button onClick={handleClick} size="md">
							Sign in with SAML SSO
						</Button>
					</Stack>
					<AuthTermsAndPolicy />
				</Flex>
			</AuthCard>

			<PlainButton
				onClick={() => {
					navigate('/auth');
				}}
				pt="lg"
				size="md"
			>
				Back to login
			</PlainButton>
		</AuthLayoutBase>
	);
}
