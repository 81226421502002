import {
	ActionIcon,
	CloseButton,
	createStyles,
	FocusTrap,
	Group,
	TextInput,
} from '@mantine/core';
import { Icon } from '@repo/foundations';
import type { ChangeEvent } from 'react';
import { useCallback } from 'react';
import { impactAnalysisStore } from './store';

interface IInboxSearchProps {
	searchTerm: string;
	setSearchTerm: (searchTerm: string) => void;
	hidden: boolean;
}

const useStyles = createStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	input: {
		height: '1.75rem',
		borderRadius: theme.radius.sm,
	},
	closeButton: {
		cursor: 'pointer',
		color: theme.other.getColor('icon/secondary/default'),
	},
}));

export function ImpactAnalysisSearch({
	searchTerm,
	setSearchTerm,
	hidden,
}: IInboxSearchProps) {
	const { classes } = useStyles();

	const handleTermChange = (event: ChangeEvent<HTMLInputElement>) => {
		setSearchTerm(event.target.value);
	};

	const handleOpen = useCallback(() => {
		impactAnalysisStore.setShowSearch(true);
	}, []);

	const handleClose = () => {
		setSearchTerm('');
		impactAnalysisStore.setShowSearch(false);
	};

	if (hidden) {
		return (
			<ActionIcon size="sm" p={4} onClick={handleOpen}>
				<Icon name="search" />
			</ActionIcon>
		);
	}

	return (
		<Group spacing="xs" w="100%">
			<FocusTrap active>
				<TextInput
					classNames={{ root: classes.root, input: classes.input }}
					placeholder="Search..."
					value={searchTerm}
					onChange={handleTermChange}
					data-autofocus
				/>
			</FocusTrap>
			<CloseButton size="sm" p={4} onClick={handleClose} />
		</Group>
	);
}
