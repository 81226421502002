import { Menu, Tooltip } from '@mantine/core';
import { useNavigate } from '@repo/common/hooks/useNavigate/index.ts';
import { Breadcrumbs, Button, Icon, Switch, Title } from '@repo/foundations';
import { width } from '@repo/theme/primitives';
import { filter } from 'lodash-es';
import type { ChangeEvent } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import {
	NavBarBreadcrumbWrapper,
	NavBarRightSideWrapper,
	NavBarWrapper,
} from '.';
import {
	queryClient,
	useAuthUser,
	useCreateDatabuilderJob,
	useIntegration,
	useIntegrationSpec,
	useUpdateDatabuilderJob,
	useUpdateIntegration,
} from '../../../api';
import {
	forceFetchJobs,
	usePollDatabuilderJobList,
} from '../../../api/hooks/databuilderJob/usePollDatabuilderJob';
import {
	incidentsQueryKeyFactory,
	monitorsQueryKeyFactory,
} from '../../../api/hooks/monitoring/constants.ts';
import { useLimits } from '../../../api/hooks/workspace/useLimits';
import { usePlan } from '../../../hooks/usePlans';
import { DatabuilderJobType } from '../../../interfaces';
import { trackEvent } from '../../../utils/analytics';
import NewIntegrationModal from '../../Integration/AddIntegrationModal';
import { useStyles as useNewIntegrationModalStyles } from '../../Integration/AddIntegrationModal/AddIntegrationModal.styles';
import { NEW_INTEGRATION_MODAL_ID } from '../../Integration/AddIntegrationModal/constants';
import {
	closeAllModals,
	openConfirmModal,
	openModal,
} from '../../ModalManager';

function IntegrationPageNavBar() {
	const { classes: newIntegrationModalClasses } =
		useNewIntegrationModalStyles();

	const location = useLocation();
	const navigate = useNavigate();

	const { isEditorOrAdminUser, user, workspace } = useAuthUser();
	const { integrationSeats, usedIntegrationSeats, trialIntegrationsLimit } =
		useLimits();
	const { id } = useParams();
	const { trial } = usePlan();

	const { data: integration } = useIntegration({
		id: id || '',
		options: {
			enabled: Boolean(id),
		},
	});
	const { mutateAsync: updateIntegration } = useUpdateIntegration({});
	const integrationSpec = useIntegrationSpec(id || '');

	const { mutate: runSync } = useCreateDatabuilderJob({
		options: {
			onSuccess: () => forceFetchJobs(),
		},
	});
	const { mutate: updateJob } = useUpdateDatabuilderJob({
		options: {
			onSuccess: () => forceFetchJobs(),
		},
	});
	const {
		polling: pullPolling,
		databuilderJobs: pullJobs,
		setExtractionPolling: setPullExtractionPolling,
	} = usePollDatabuilderJobList({
		integrationId: id || '',
		type: 'PULL',
		enabled: Boolean(id),
	});

	const {
		polling: pushPolling,
		databuilderJobs: pushJobs,
		setExtractionPolling: setPushExtractionPolling,
	} = usePollDatabuilderJobList({
		integrationId: id || '',
		type: 'PUSH',
		enabled: Boolean(id),
	});

	const requireUpgradeForCustomer = usedIntegrationSeats >= integrationSeats;
	const requireUpgradeForTrial =
		trial &&
		trialIntegrationsLimit &&
		usedIntegrationSeats >= trialIntegrationsLimit;
	const requireUpgrade = requireUpgradeForCustomer || requireUpgradeForTrial;

	const isIntegrationListPage = location.pathname === '/integrations';

	const handleNavigate = () => {
		openModal({
			modalId: NEW_INTEGRATION_MODAL_ID,
			title: <Title size="md">Connect Integration</Title>,
			children: <NewIntegrationModal />,
			size: width.xxl,
			radius: 'lg',
			classNames: {
				header: newIntegrationModalClasses.header,
				body: newIntegrationModalClasses.body,
			},
		});
	};

	const handleUpgrade = () => {
		// Trials sometimes have a hard limit on the number of integrations.
		if (trial && trialIntegrationsLimit) {
			return;
		}

		openConfirmModal({
			title: 'Upgrade',
			children:
				'Your account will be charged for 1 additional Integration seat.',
			labels: {
				cancel: 'Cancel',
				confirm: 'Upgrade',
			},
			onConfirm: () => {
				handleNavigate();
			},
			onCancel: () => {
				closeAllModals();
			},
		});
	};

	const toggleEnabled = (event: ChangeEvent<HTMLInputElement>) => {
		updateIntegration({
			data: {
				id: integration!.id,
				enabled: event.target.checked,
			},
		});
	};

	const handlePullSync = async () => {
		if (integration && !pullPolling) {
			runSync({
				data: {
					integration_id: id,
					type: DatabuilderJobType.METADATA,
				},
			});
			setPullExtractionPolling(true);
			trackEvent('integrations/run_extraction/click', {}, user, workspace!);

			// ENG-10149 Invalidate the queries for monitors and incidents
			await queryClient.invalidateQueries(monitorsQueryKeyFactory.allLists());
			await queryClient.invalidateQueries(incidentsQueryKeyFactory.allLists());
		}
	};

	const handlePushSync = async () => {
		if (integration && !pushPolling) {
			runSync({
				data: {
					integration_id: id,
					type: DatabuilderJobType.PUSH,
				},
			});
			setPushExtractionPolling(true);
			trackEvent(
				'integration/sync/push',
				{
					integration_type: integration.type,
				},
				user,
				workspace!
			);
		}
	};

	const handleCancelSync = () => {
		const idsToCancel = filter(
			[...(pullJobs ?? []), ...(pushJobs ?? [])],
			(job) =>
				!job.failed && !job.completed && !job.terminated && !job.timed_out
		).map((job) => job.id);

		idsToCancel.forEach((id) => {
			updateJob({
				data: {
					id: id,
					terminated: true,
				},
			});
		});
	};

	if (isIntegrationListPage) {
		return (
			<NavBarWrapper>
				<NavBarBreadcrumbWrapper>
					<Title
						order={1}
						size="sm"
						data-testid="integrations-page-nav-bar-title"
					>
						Integrations
					</Title>
				</NavBarBreadcrumbWrapper>
				<NavBarRightSideWrapper>
					{requireUpgrade && (
						<Tooltip label="Max number of integrations added">
							<Button leftIconName="bolt" onClick={handleUpgrade}>
								Upgrade
							</Button>
						</Tooltip>
					)}
					{isEditorOrAdminUser && !requireUpgrade && (
						<Button
							variant="primary"
							onClick={handleNavigate}
							data-testid="new-integrations-button"
						>
							Connect integration
						</Button>
					)}
				</NavBarRightSideWrapper>
			</NavBarWrapper>
		);
	}

	const isPolling = pullPolling || pushPolling;

	const breadcrumbs = integration
		? [
				{ title: 'Integrations', href: '/integrations' },
				{ title: integration.name, href: `/integrations/${integration.id}` },
			]
		: [{ title: 'Integrations', href: '/integrations' }];

	return (
		<NavBarWrapper>
			<NavBarBreadcrumbWrapper>
				<Breadcrumbs crumbs={breadcrumbs} />
			</NavBarBreadcrumbWrapper>
			{integration &&
				!['slack', 'ms_teams', 'pagerduty'].includes(integration.type) && (
					<NavBarRightSideWrapper>
						{integration && (
							<Switch
								label="Enabled"
								labelPosition="left"
								checked={integration.enabled}
								onChange={toggleEnabled}
							/>
						)}
						<Menu>
							<Menu.Target>
								<Button
									variant="primary"
									leftIconName={isPolling ? 'refresh' : undefined}
									leftIconAnimation="spinCounterClockwise"
									rightIconName="chevronDown"
								>
									{isPolling ? 'Syncing' : 'Run sync'}
								</Button>
							</Menu.Target>
							<Menu.Dropdown>
								{isPolling && (
									<Menu.Item
										icon={<Icon name="playerStop" />}
										onClick={handleCancelSync}
									>
										Cancel sync
									</Menu.Item>
								)}
								{!isPolling && !integrationSpec?.value.disablePullMetadata && (
									<Menu.Item
										icon={<Icon name="arrowDown" />}
										onClick={handlePullSync}
									>
										Pull metadata
									</Menu.Item>
								)}
								{!isPolling && integrationSpec?.value.supportPushMetadata && (
									<Menu.Item
										icon={<Icon name="arrowUp" />}
										onClick={handlePushSync}
									>
										Push metadata
									</Menu.Item>
								)}
							</Menu.Dropdown>
						</Menu>
					</NavBarRightSideWrapper>
				)}
		</NavBarWrapper>
	);
}

export default IntegrationPageNavBar;
