import { Group, Skeleton } from '@mantine/core';
import type { DataQualitySuggestion } from '@repo/api-codegen';
import { Text } from '@repo/foundations';
import { sentenceCase } from '../../../utils/stringUtils';
import { DataQualityProgressIcon } from '../DataQualityProgressIcon';

interface DataQualityDetailsGroupLoadingProps {
	dataQualityKey: DataQualitySuggestion['data_quality_top_key'];
}

export function DataQualityDetailsGroupLoading({
	dataQualityKey,
}: DataQualityDetailsGroupLoadingProps) {
	return (
		<Group position="apart" spacing={0}>
			<Group spacing="xs">
				<DataQualityProgressIcon score={-1} />
				<Text size="sm" fw={500}>
					{sentenceCase(String(dataQualityKey))}
				</Text>
			</Group>
			<Skeleton width={40} height={16} />
		</Group>
	);
}
