import {
	Container,
	Divider,
	Group,
	Radio,
	Stack,
	Textarea,
	useMantineTheme,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useNavigate } from '@repo/common/hooks/useNavigate';
import type { IMarketplaceIntegrationSpecVersion } from '@repo/common/models/marketplace';
import { Button, Switch, Text, Title } from '@repo/foundations';
import { useParams } from 'react-router-dom';
import {
	useMarketplaceIntegrationSpecVersion,
	useReviewMarketplaceVersion,
} from '../../api/hooks/marketplace';
import { FullWidthLoadingSpinner } from '../../components/LoadingSpinner';
import { MarketplaceIntegrationSpecVersionForm } from '../../components/MarketplaceIntegrationSpecVersionForm/MarketplaceIntegrationSpecVersionForm';
import { useMarketplaceIntegrationSpecVersionForm } from '../../components/MarketplaceIntegrationSpecVersionForm/MarketplaceIntegrationSpecVersionForm.hook';

function Content({ version }: { version: IMarketplaceIntegrationSpecVersion }) {
	const theme = useMantineTheme();
	const versionForm = useMarketplaceIntegrationSpecVersionForm(version, true);

	const navigate = useNavigate();

	const feedbackForm = useForm({
		initialValues: {
			comment: version.reviewer_comment || '',
			action: null,
			is_trusted: version.is_trusted,
		},
		validate: {},
	});

	const { mutateAsync: apiReviewMarketplaceVersion } =
		useReviewMarketplaceVersion();

	const submitReview = async (values: {
		comment: string;
		action: 'approve' | 'reject' | null;
		is_trusted: boolean;
	}) => {
		await apiReviewMarketplaceVersion({
			specId: version.spec_id,
			versionId: version.id,
			action: values.action,
			comment: values.comment,
			isTrusted: values.is_trusted,
		});

		navigate('/internal/marketplace');
	};

	return (
		<Container p="3xl">
			<Stack spacing="xl">
				<MarketplaceIntegrationSpecVersionForm
					form={versionForm}
					existingVersion={version}
					disabled
				/>
				<form onSubmit={feedbackForm.onSubmit(submitReview)}>
					<Stack>
						<Divider />
						<Title size="lg">Trusted</Title>
						<Text size="sm" color="text/secondary/default">
							Turn on if the container for this integration should have full
							internet access. If turned off, all of its HTTP-only requests will
							be routed through an egress server.{' '}
						</Text>
						<Switch
							{...feedbackForm.getInputProps('is_trusted', {
								type: 'checkbox',
							})}
						/>

						{version.publish_status === 'SUBMITTED' && (
							<>
								<Divider />
								<Title size="lg">Feedback</Title>
								<Stack>
									<Radio.Group
										label="Action"
										name="action"
										withAsterisk
										required
										{...feedbackForm.getInputProps('action')}
									>
										<Radio value="approve" label="Approve" />
										<Radio
											sx={{
												marginTop: theme.spacing.xs,
											}}
											value="reject"
											label="Reject"
										/>
									</Radio.Group>

									<Textarea
										label="Comment"
										name="comment"
										{...feedbackForm.getInputProps('comment')}
									/>
								</Stack>
							</>
						)}
						<Group>
							<Button variant="primary" type="submit">
								Submit
							</Button>
						</Group>
					</Stack>
				</form>
			</Stack>
		</Container>
	);
}

export default function InternalMarketplaceReviewPage() {
	const { versionId, specId } = useParams();

	const { data: version } = useMarketplaceIntegrationSpecVersion(
		specId!,
		versionId!
	);

	if (!version) {
		return <FullWidthLoadingSpinner />;
	}

	return <Content version={version} />;
}
