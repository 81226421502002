import { space } from '@repo/theme/primitives';
import type { LineageGraphModals } from './types';
import { LineageGraphModalType } from './types';

export const DEFAULT_POSITION = { x: 0, y: 0 };

export const DAGRE_NODE_SEP = space[12];
export const DAGRE_EDGE_SEP = space[12];
export const DAGRE_RANK_SEP = space[28];

export const NODE_PADDING = space[2];
export const NODE_WIDTH = space[120];
export const NODE_GAP = space[2];

export const NODE_HEADER_PADDING_LEFT = space[4];
export const NODE_HEADER_PADDING_Y = space[3];
export const NODE_HEADER_INTEGRATION_ICON_SIZE = 1.75 * 16;
export const NODE_HEADER_HEIGHT = 3.75 * 16;

export const NODE_ROW_HEIGHT = space[8];

export const MAX_NUM_OF_CHILDREN = 5;

export const NODE_CHILDREN_MAX_HEIGHT = NODE_ROW_HEIGHT * MAX_NUM_OF_CHILDREN; // search input height + gap + columns height

export const EXPORT_LINEAGE_SIZE = {
	width: 1024,
	height: 768,
};

export const INITIAL_LINEAGE_GRAPH_MODALS: LineageGraphModals = {
	[LineageGraphModalType.IMPACT_ANALYSIS]: { opened: false },
	[LineageGraphModalType.TESTS]: { opened: false, tests: [] },
	[LineageGraphModalType.CREATION_QUERY]: { opened: false, query: undefined },
};

export const LINEAGE_GRAPH_FILTER_KEY = 'lineage-graph-filter';
