import { Anchor } from '@mantine/core';
import { Text } from '@repo/foundations';

export function AuthTermsAndPolicy() {
	return (
		<Text size="sm" align="center">
			By continuing, you agree to the{' '}
			<Anchor
				target="_blank"
				href="https://docs.secoda.co/policies/terms-of-use"
			>
				Terms of Use
			</Anchor>{' '}
			and{' '}
			<Anchor
				target="_blank"
				href="https://docs.secoda.co/policies/privacy-policy"
			>
				Privacy Policy
			</Anchor>
			.
		</Text>
	);
}
