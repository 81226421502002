import { Card, Checkbox, Divider, Group, Stack } from '@mantine/core';
import { Text, Title } from '@repo/foundations';
import { capitalize, isEmpty, map, uniq } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { useAuthUser } from '../../../api';
import { useUpdateWorkspace } from '../../../api/hooks/workspace/useUpdateWorkspace';
import type {
	ISecodaSlackChannel,
	WorkspaceNotificationPreference,
} from '../../../lib/models';
import { notifications } from '../../Settings/constants';

interface IntegrationSlackNotificationsProps {
	existingChannel?: ISecodaSlackChannel;
}

function IntegrationSlackNotifications({
	existingChannel,
}: IntegrationSlackNotificationsProps) {
	const { workspace } = useAuthUser();
	const { mutateAsync } = useUpdateWorkspace(workspace.id);

	const isNotificationChannel =
		existingChannel?.channel_type === 'notification' &&
		!isEmpty(existingChannel.notification_settings);

	const sections = useMemo(
		() =>
			uniq(
				map(
					notifications.filter((settings) => settings.category !== 'Digest'),
					'category'
				)
			),
		[]
	);

	const onCheck =
		(attribute: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
			const { checked } = e.target;
			mutateAsync({
				data: {
					id: workspace.id,
					notification_preferences: {
						...workspace.notification_preferences,
						[attribute]: checked,
					},
				},
			});
		};

	const notificationSettings = Array.from(sections).map((section) => (
		<Stack key={section} spacing={4}>
			<Title order={6}>{section}</Title>
			{notifications
				// Exclude notify_mentions from workspace slack settings.
				.filter(
					(preference) =>
						preference.category === section &&
						preference.attribute !== 'notify_mentions'
				)
				.map((preference) => (
					<Group key={preference.category} position="apart" spacing="xl">
						<Text size="md">{capitalize(preference.description)}</Text>
						<Checkbox
							onChange={onCheck(preference.attribute)}
							width="40px"
							checked={
								// FIXME: Cannot edit existing notification channel's settings
								isNotificationChannel
									? existingChannel.notification_settings?.[
											preference.attribute as keyof WorkspaceNotificationPreference
										]
									: workspace.notification_preferences?.[
											preference.attribute as keyof WorkspaceNotificationPreference
										]
							}
						/>
					</Group>
				))}
		</Stack>
	));

	return (
		<Stack>
			<Card withBorder radius="xs" p="xl" mb={32}>
				<Stack spacing="md">
					<Stack>
						<Group>
							<Text size="lg">
								{existingChannel ? 'Channel' : 'Global'} notification settings
							</Text>
						</Group>
						<Divider />
						<Stack spacing="lg">{notificationSettings}</Stack>
					</Stack>
				</Stack>
			</Card>
		</Stack>
	);
}

export default observer(IntegrationSlackNotifications);
