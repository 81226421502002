import { cloneDeep } from 'lodash-es';
import type { Workspace } from '.';
import { BaseModel } from './base';

export class UserAccountManager extends BaseModel {
	id = '';

	email = '';

	first_name = '';

	last_name = '';

	profile_picture = '';

	active_user = '';

	// @ts-expect-error TS(2564): Property 'workspaces' has no initializer and is no... Remove this comment to see the full error message
	workspaces: Workspace[];

	is_superuser = false;

	constructor(obj: UserAccountManager) {
		super(obj);
		Object.keys(obj).forEach((key) => {
			(this as any)[key] = cloneDeep((obj as any)[key]);
		});
	}

	static get fields() {
		return BaseModel.fields.concat([
			'email',
			'first_name',
			'last_name',
			'profile_picture',
			'active_user',
			'workspaces',
			'is_superuser',
		]);
	}

	static get namespace() {
		return ['auth', 'accounts'];
	}
}
