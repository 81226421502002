import { Paper, useMantineTheme } from '@mantine/core';
import { observer } from 'mobx-react-lite';
import { type Automation } from '../../../api';
import { AutomationActionCardType } from '../constants';
import { AutomationActionCardEditStoreProvider } from './AutomationActionCard/Edit/context';
import { AutomationActionCardFilterStoreProvider } from './AutomationActionCard/Filter/context';
import { AutomationActionCardPropagateMetadataStoreProvider } from './AutomationActionCard/PropagateMetadata/context';
import AutomationCardContent from './AutomationCardContent';
import AutomationCardHeader from './AutomationCardHeader';
import { type AutomationCardType } from './constants';

export interface AutomationCardProps {
	titleIcon?: JSX.Element;
	cardId: string;
	type: AutomationCardType;
	searchKey?: string;
	isSecondFilterResourceCard?: boolean;
	isNthFilterResourceCard?: boolean;
	handleAutomationUpdate: (automation: Partial<Automation>) => Promise<void>;
}

function AutomationCard({
	titleIcon,
	cardId,
	type,
	searchKey,
	isSecondFilterResourceCard,
	isNthFilterResourceCard,
	handleAutomationUpdate,
}: AutomationCardProps) {
	const theme = useMantineTheme();

	return (
		<AutomationActionCardFilterStoreProvider>
			<AutomationActionCardEditStoreProvider>
				<AutomationActionCardPropagateMetadataStoreProvider>
					<Paper
						shadow="box-shadow"
						withBorder
						radius={theme.radius.md}
						w="100%"
					>
						{type !== AutomationActionCardType.FILTER_COUNT && (
							<AutomationCardHeader
								cardId={cardId}
								icon={titleIcon}
								type={type}
								searchKey={searchKey}
								isSecondFilterResourceCard={isSecondFilterResourceCard}
								isNthFilterResourceCard={isNthFilterResourceCard}
								handleAutomationUpdate={handleAutomationUpdate}
							/>
						)}
						<AutomationCardContent
							icon={titleIcon}
							type={type}
							searchKey={searchKey}
							handleAutomationUpdate={handleAutomationUpdate}
						/>
					</Paper>
				</AutomationActionCardPropagateMetadataStoreProvider>
			</AutomationActionCardEditStoreProvider>
		</AutomationActionCardFilterStoreProvider>
	);
}

export default observer(AutomationCard);
