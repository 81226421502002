import { useGetSlackChannels } from '@repo/api-codegen';
import type { ApiCatalogSort } from '../../../api';

export const useSecodaChannelList = () =>
	function (props: {
		page?: number | undefined;
		filters?: { search_term?: string; sort?: ApiCatalogSort };
	}) {
		const { page, filters } = props;

		return useGetSlackChannels({
			queryParams: {
				page: page || 1,
				search_term: filters?.search_term,
				page_size: 50,
				sort_by: JSON.stringify(filters?.sort) || JSON.stringify({}),
			},
		});
	};
