import { Box, createStyles, Stack } from '@mantine/core';
import { memo } from 'react';
import type { MonitorJobResource } from '../../../../../../packages/api-codegen';
import { useApiGetMonitorJobResources } from '../../../../../../packages/api-codegen';
import { DataTable } from '../../../../../../packages/mantine-datatable';
import type { Monitor } from '../../../api';
import { TableV2Loader } from '../../../components/TableV2';
import {
	FOOTER_HEIGHT,
	HEADER_HEIGHT,
	ROW_HEIGHT,
	rowSx,
	useTableStyles,
} from '../../../components/TableV2/TableV2.styles';
import {
	DEFAULT_MAX_RECORD_SIZE,
	DEFAULT_PAGINATION_SIZE,
} from '../../../components/TableV2/constants';
import { useColumns } from './MonitorJobTable.hooks';

const useStyles = createStyles(() => ({
	wrapper: {
		height: '100%',
		flexGrow: 1,
	},
}));
interface IMonitorJobTableProps {
	monitor: Monitor;
}
function MonitorJobTable({ monitor }: IMonitorJobTableProps) {
	const { classes } = useStyles();
	const columns = useColumns();
	const { data, isFetching } = useApiGetMonitorJobResources({
		queryParams: {
			monitor_id: monitor.id,
		},
	});
	const { classes: tableClasses } = useTableStyles({
		hideCheckbox: false,
		noBorder: true,
	});
	if (!data) {
		return null;
	}
	return (
		<Stack className={classes.wrapper}>
			<Box>
				<DataTable<MonitorJobResource>
					// Styling
					withStickyColumnBorder
					noHeader={false}
					borderRadius="md"
					withBorder
					rowSx={rowSx}
					maxHeight={HEADER_HEIGHT + 7 * ROW_HEIGHT + FOOTER_HEIGHT}
					height={HEADER_HEIGHT + 7 * ROW_HEIGHT + FOOTER_HEIGHT}
					paginationSize="md"
					classNames={tableClasses}
					// Misc rendering
					fetching={isFetching}
					loadingText={'Loading...'}
					records={data}
					columns={columns}
					// Pagination
					page={1}
					recordsPerPage={DEFAULT_PAGINATION_SIZE}
					totalRecords={data.length}
					onPageChange={() => {}}
					paginationText={({ to, totalRecords }) => {
						const totalRecordsString =
							totalRecords >= DEFAULT_MAX_RECORD_SIZE
								? `${DEFAULT_MAX_RECORD_SIZE}+`
								: totalRecords.toLocaleString();
						return `Showing ${to.toLocaleString()} of ${totalRecordsString} records`;
					}}
					customLoader={<TableV2Loader />}
				/>
			</Box>
		</Stack>
	);
}
export default memo(MonitorJobTable);
