/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */

import {
	Anchor,
	Box,
	Checkbox,
	FileInput,
	Flex,
	Group,
	Select,
	Stack,
	Textarea,
	TextInput,
} from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { integrationSchemas } from '@repo/common/constants/integration/integrations.schemas';
import { isTestableIntegration } from '@repo/common/constants/integration/integrations.utils';
import type { IMarketplaceIntegrationSpecVersion } from '@repo/common/models/marketplace';
import { Button, SegmentedControl, Text } from '@repo/foundations';
import { useFormik } from 'formik';
import { capitalize, isNil, mapValues, omit, omitBy } from 'lodash-es';
import { memo, Suspense, useMemo, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import type { AnySchema } from 'yup';
import * as Yup from 'yup';
import { api } from '../../../network';
import type { IIntegration } from '../../api';
import { useAuthUser, useIntegrationList, useWorkspace } from '../../api';
import type {
	IntegrationSpec,
	SlackIntegrationSpec,
} from '../../interfaces/IntegrationSpec';
import { Integration } from '../../lib/models';
import { trackEvent } from '../../utils/analytics';
import {
	gracefulBase64Decode,
	snakeCaseToTitleCase,
	titleFromIdentifier,
} from '../../utils/shared.utils';
import { uploadFileToS3 } from '../../utils/upload.utils';
import { v4 } from '../../utils/uuid/v4';
import { SmallLoadingSpinner } from '../LoadingSpinner';
import { MultiTeamsSelector } from '../MultiTeamsSelector/MultiTeamsSelector';
import { IntegrationAWSAccountID } from './IntegrationAWSAccountID';
import {
	ConnectionError,
	DbtDisclaimer,
	DemoIntegration,
	GithubDisclaimer,
	Whitelist,
} from './IntegrationDisclaimers';
import { IntegrationHelp } from './IntegrationHelp';
import { IntegrationImage } from './IntegrationImage';
import { IntegrationTunnelSelect } from './IntegrationTunnelSelect';

export function SlackOAuthButton(props: {
	spec: SlackIntegrationSpec;
	integration?: Integration | IIntegration;
}) {
	const { spec, integration } = props;

	const { workspace } = useWorkspace();

	function encodeUtf8ToHex(str: string): string {
		const encoder = new TextEncoder();
		const uint8Array = encoder.encode(str);
		return Array.from(uint8Array, (byte) =>
			byte.toString(16).padStart(2, '0')
		).join('');
	}

	const handleConnect = () => {
		const { oauth } = spec;

		// We pass in the callback encoded in the state parameter,
		// so the central server can redirect back to the correct page.
		// We can't pass this in the `redirect_uri` parameter, because
		// we want to reuse the same slack app for different domains, and
		// the `redirect_uri` parameter must be whitelisted in the slack app.
		const state = encodeUtf8ToHex(
			JSON.stringify({
				nonce: v4(),
				workspace_id: workspace.id,
				origin: window.location.origin,
				redirect_uri: oauth.redirect_uri,
			})
		);

		const url = new URL(oauth.base_url);
		url.searchParams.append('state', state);
		url.searchParams.append('scope', oauth.scope ?? '');
		url.searchParams.append('client_id', oauth.client_id ?? '');
		url.searchParams.append('redirect_uri', oauth.redirect_uri ?? '');
		url.searchParams.append('response_type', oauth.response_type ?? '');
		if (oauth.access_type) {
			url.searchParams.append('access_type', oauth.access_type);
		}
		window.open(url.toString(), '_self');
	};
	return (
		<Button type="button" size="md" onClick={handleConnect}>
			{integration?.credentials
				? `Re-authorize ${integration?.name ?? 'slack'}`
				: 'Connect'}
		</Button>
	);
}

const calculateForm = (
	spec: IntegrationSpec,
	selectedSpecVersion?: IMarketplaceIntegrationSpecVersion
) => {
	if (spec.type === 'builtin') {
		return integrationSchemas[spec.value.type] || Yup.object().shape({});
	}

	const selectedVersion = selectedSpecVersion || spec.value.versions[0];

	return Yup.object().shape(
		mapValues(selectedVersion.form_spec, (field) => {
			let yupField = Yup.string().meta({
				htmlType: field.type,
				description: field.placeholder,
			});

			if (field.required) {
				yupField = yupField.required('Required');
			}

			return yupField;
		})
	);
};

export const IntegrationForm = memo(
	(props: {
		spec: IntegrationSpec;
		integration?: Integration | IIntegration;
		teamId?: string | null;
		compact?: boolean;
		onFinish?: (result: Integration) => void;
		onBack?: () => void;
	}) => {
		const { user, workspace } = useAuthUser();

		const {
			spec,
			teamId,
			compact,
			onBack,
			integration: existingIntegration,
			onFinish,
		} = props;

		const initialSetup = !existingIntegration;

		const { data: integrations } = useIntegrationList({});

		const [connectStatus, setConnectStatus] = useState<
			'init' | 'connected' | 'error'
		>('init');
		const [error, setError] = useState<string | undefined>();

		const [integration, setIntegration] = useState<
			Integration | IIntegration | undefined
		>(existingIntegration);

		const [selectedSpecVersion, setSelectedSpecVersion] = useState(
			spec.type === 'marketplace'
				? spec.value.versions.find(
						(v) =>
							v.id === integration?.marketplace_integration_spec_version?.id
					) || spec.value.versions[0]
				: undefined
		);

		const [alwaysUpdateLatestVersion, setAlwaysUpdateLatestVersion] =
			useState<boolean>(
				integration?.marketplace_auto_update_latest_version || true
			);

		const form = useMemo(
			() => calculateForm(spec, selectedSpecVersion),
			[spec, selectedSpecVersion]
		);

		// This is used to create a segmented control if there are multiple ways to connect an integration.
		const groups: Record<string, Record<string, AnySchema>> = Object.keys(
			form?.fields ?? {}
		).reduce((prv, cur) => {
			// _group is a special field indicating which group is chosen
			if (cur === '_group') return prv;

			const field = form.fields[cur];
			if (field.spec.meta?.group) {
				// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
				prv[field.spec.meta?.group] = {
					// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
					...(prv[field.spec.meta?.group] || {}),
					[cur]: field,
				};
			} else if (field.spec.meta?.groups) {
				// @ts-expect-error TS(7006): Parameter 'group' implicitly has an 'any' type.
				field.spec.meta?.groups.forEach((group) => {
					// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
					prv[group] = {
						// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
						...(prv[group] || {}),
						[cur]: field,
					};
				});
			} else {
				// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
				prv[0] = { ...(prv[0] || {}), [cur]: field }; // Default to the first group.
			}
			return prv;
		}, {});

		// Make sure to include all groups in groupSettings
		if (spec.type === 'builtin' && spec.value.groupSettings) {
			for (const groupName in spec.value.groupSettings) {
				if (!groups[groupName]) {
					groups[groupName] = {};
				}
			}
		}

		const slackOauth = spec.type === 'builtin' && spec.value.oauth;

		// Add oauth to the group if applicable.
		// Only applicable to Slack integration
		if (slackOauth && spec.value.oauth) {
			groups.slack = {};
		}

		const initialValues = {
			...(integration?.credentials || {}),
			teams: integration?.teams || (teamId ? [teamId] : []),
			// We check multiple places for the `ssh_tunnel` value,
			// for backwards-compat. reasons:
			// 1. The integration credentials
			// 2. The integration itself
			ssh_tunnel:
				integration?.credentials?.ssh_tunnel ??
				integration?.ssh_tunnel ??
				'no_tunnel',
		};

		const defaultValues = form.cast({});
		const defaultNonEmptyValues = omitBy(defaultValues, isNil);

		const [selectedGroup, setSelectedGroup] = useState(
			// eslint-disable-next-line no-underscore-dangle
			initialValues._group ||
				// eslint-disable-next-line no-underscore-dangle
				defaultNonEmptyValues._group ||
				Object.keys(groups)[0]
		);

		const disableDbt =
			spec.type === 'builtin' &&
			['dbt', 'dbt_core'].includes(spec.value.type) &&
			integrations?.results?.filter(
				(i) =>
					[
						'bigquery',
						'redshift',
						'snowflake',
						'mssql',
						'mysql',
						'oracle',
						'postgres',
						'databricks',
					].includes(i.type) && !i.name.toLowerCase().includes('demo')
			).length === 0;

		const disableGithub =
			spec.type === 'builtin' &&
			spec.value.type === 'github' &&
			integrations?.results?.filter(
				(i) =>
					['dbt', 'dbt_core'].includes(i.type) &&
					!i.name.toLowerCase().includes('demo')
			).length === 0;

		const isDemo =
			(spec.type === 'builtin' && spec.value.is_demo) ||
			integration?.credentials?.is_demo;

		const createOrUpdateIntegration = async (values: Record<string, any>) => {
			const { teams } = values;

			// Clear the tunnel values if "No tunnel" is selected
			if (values.ssh_tunnel === 'no_tunnel') {
				delete values.ssh_tunnel;
				if (integration) {
					delete integration.ssh_tunnel;
				}
			}

			// If the integration is being setup for the first time
			if (!integration) {
				const params =
					spec.type === 'builtin'
						? {
								type: spec.value.type,
								name: spec.value.name,
							}
						: {
								type: 'marketplace',
								name: selectedSpecVersion!.name,
								marketplace_integration_spec_version_id:
									selectedSpecVersion!.id,
								marketplace_auto_update_latest_version:
									alwaysUpdateLatestVersion,
							};
				const integrationObj = await Integration.create({
					...params,
					teams,
					credentials: omit(values, ['teams']),
				});

				// Keep this event for historic activation tracking
				trackEvent(
					'integration/connection/update',
					{
						label: params.name,
						type: params.type,
					},
					user,
					workspace!
				);

				// @ts-expect-error TS(2345)
				const result = new Integration({ ...integrationObj });

				setIntegration(result);
				return result;
			}

			// @ts-expect-error TS(2345)
			const result: Integration = new Integration({
				...integration,
				credentials: {
					...omit(values, ['teams']),
					// TODO: This component needs to be refactored and we need to decide
					// on the consistent way to handle tunnels.
					ssh_tunnel: values?.ssh_tunnel,
					tunnel: values?.ssh_tunnel,
				},
				teams: values.teams,
				ssh_tunnel: values?.ssh_tunnel,
			});

			const updateFields = ['credentials', 'ssh_tunnel'];

			if (spec.type === 'marketplace') {
				result.marketplace_integration_spec_version_id =
					selectedSpecVersion!.id;
				result.marketplace_auto_update_latest_version =
					alwaysUpdateLatestVersion;

				updateFields.push(
					'marketplace_integration_spec_version_id',
					'marketplace_auto_update_latest_version'
				);
			}

			if (initialSetup && values.teams && values.teams.length) {
				updateFields.push('teams');
			}
			await result.save(updateFields);

			setIntegration(result);
			return result;
		};

		const oauthIntegration = (result: Integration) => {
			const baseUrl = api();
			// Use local server tunnel URL for debugging
			// baseUrl =
			// 	'https://e7ad-2607-fea8-fca0-8146-2464-b746-8c6d-7239.ngrok.io/';

			const authUrl = `${baseUrl}oauth/to_oauth/${result.id}/`;
			window.open(authUrl, '_self');
		};

		const testConnection = async (result: Integration) => {
			try {
				const success = await result.isConnected();

				if (!success) {
					setConnectStatus('error');

					showNotification({
						message: 'Cannot establish connection',
						color: 'red',
					});
					setError('Connection error');
					return;
				}

				setConnectStatus('connected');
				showNotification({
					message: 'Connection established',
					color: 'green',
				});

				setError(undefined);
			} catch (e: any) {
				setConnectStatus('error');
				setError(
					`Encountered the following error: \n${JSON.stringify(
						e?.message ?? e
					)}`
				);

				throw e;
			}
		};

		const formik = useFormik({
			initialValues: {
				...(initialSetup
					? {
							teams: [],
						}
					: {}),
				...defaultNonEmptyValues,
				// Initial values goes second to ensure we are overwriting default values.
				...initialValues,
			},
			validationSchema: initialSetup
				? form.concat(
						Yup.object().shape({
							teams: Yup.array(Yup.string()),
						})
					)
				: form,
			validateOnBlur: false,
			validateOnChange: false,
			onSubmit: async (values) => {
				try {
					const result = await createOrUpdateIntegration(values);
					if (!initialSetup) {
						showNotification({
							message: 'Integration updated',
							color: 'green',
						});
					}

					if (
						spec.type === 'builtin' &&
						spec.value.groupSettings?.[selectedGroup]?.isOAuth
					) {
						oauthIntegration(result);
						return;
					}

					if (isTestableIntegration(spec, selectedGroup)) {
						await testConnection(result);
					}

					onFinish?.(result);
				} catch (e: any) {
					setError(
						`Encountered the following error: \n${JSON.stringify(
							e?.message ?? e
						)}`
					);
				}
			},
		});

		const submitLabel = () => {
			if (
				spec.type === 'builtin' &&
				spec.value.groupSettings?.[selectedGroup]?.isOAuth
			) {
				return 'Connect with OAuth';
			}

			if (!isTestableIntegration(spec, selectedGroup)) {
				return 'Submit';
			}

			if (connectStatus === 'init') {
				return 'Test connection';
			}

			if (connectStatus === 'connected') {
				return 'Connected';
			}

			return 'Retry connection';
		};

		const [toggleState, setToggleState] = useState<Record<string, boolean>>({
			ssl: integration?.credentials?.ssl ?? false,
			set_google_authorization_header:
				integration?.credentials?.set_google_authorization_header ?? false,
			legacy_authentication:
				integration?.credentials?.legacy_authentication ?? false,
		});

		return (
			<Box sx={{ display: 'flex', width: '100%' }}>
				<Box sx={{ width: '100%' }}>
					{initialSetup && !compact && <IntegrationImage spec={spec} />}
					{disableDbt && !isDemo && <DbtDisclaimer />}
					{disableGithub && !isDemo && <GithubDisclaimer />}
					{isDemo && <DemoIntegration />}
					{/* Only display a segmented control if there is more than 1 group. */}
					{Object.keys(groups).length > 1 && (
						<Flex w="100%" justify="center" mb="35px">
							<SegmentedControl
								data={Object.keys(groups).map((g) => ({
									label: g,
									value: g,
								}))}
								value={selectedGroup}
								onChange={(value) => {
									// eslint-disable-next-line no-underscore-dangle
									if (formik.values._group) {
										formik.setFieldValue('_group', value);
									}
									setSelectedGroup(value as string);
								}}
							/>
						</Flex>
					)}

					{spec.type === 'marketplace' && (
						<Stack spacing="xs">
							<Select
								label="Version"
								required
								data={spec.value.versions.map((v) => ({
									value: v.id,
									label: `Version ${v.version_number}`,
								}))}
								value={selectedSpecVersion?.id}
								onChange={(value) => {
									setSelectedSpecVersion(
										spec.value.versions.find((v) => v.id === value)
									);
								}}
							/>
							<Checkbox
								checked={alwaysUpdateLatestVersion}
								onChange={(event) =>
									setAlwaysUpdateLatestVersion(event.currentTarget.checked)
								}
								mb="xl"
								label="Auto update to the latest version"
							/>
						</Stack>
					)}

					{Object.keys(groups).map((groupName: string) => {
						const group = groups[groupName];

						return (
							<form
								key={groupName}
								style={{
									display: selectedGroup === groupName ? 'block' : 'none',
								}}
								onSubmit={formik.handleSubmit}
							>
								{Object.keys(group).includes('external_id') && (
									<Box mb={4}>
										<IntegrationAWSAccountID />
									</Box>
								)}
								{group &&
									Object.keys(group)
										.filter((f) => f !== 'ssh_tunnel' && f !== 'oauth') // Filter out the tunnel, we will add a custom field for it below.
										.map((k: string) => {
											const field = group[k];
											if (field.spec.meta?.toggleController) {
												const toggle = field.spec.meta?.toggleController;

												return (
													<Checkbox
														sx={{ marginBottom: 15 }}
														checked={toggleState[toggle]}
														onChange={(event) => {
															setToggleState((prevState) => ({
																...prevState,
																[toggle]: event.target.checked,
															}));
															formik.setFieldValue(
																field.spec.meta?.toggleController,
																event.target.checked
															);
														}}
														key={toggle}
														label={field.spec.meta?.label}
													/>
												);
											}

											if (
												field.spec.meta?.toggleGroup &&
												!toggleState?.[field.spec.meta?.toggleGroup]
											) {
												return null;
											}

											if (field.spec.meta?.htmlType === 'file') {
												return (
													<FileInput
														key={k}
														name={k}
														w={250}
														multiple
														label={
															k.includes('_')
																? capitalize(k?.replaceAll('_', ' '))
																: capitalize(k)
														}
														description={field.spec.meta?.description}
														// eslint-disable-next-line @typescript-eslint/ban-ts-comment
														// @ts-ignore poorly typed component
														placeholder={formik.values[k] ?? 'Select the file'}
														defaultValue={
															formik.values[k] ?? field.spec.default
														}
														error={error}
														onChange={async (files) => {
															if (files.length > 0) {
																if (
																	integration?.type === 'great_expectations'
																) {
																	const reader = new FileReader();
																	reader.onload = async (e) => {
																		formik.setFieldValue(k, e.target?.result);
																	};
																	reader.readAsText(files[0]);
																} else {
																	const fileType =
																		field.spec.meta?.secodaFileType;

																	const fileKey = await uploadFileToS3(
																		files[0],
																		fileType
																	);

																	if (fileKey !== '') {
																		formik.setFieldValue(k, fileKey);
																	}
																}
															}
														}}
													/>
												);
											}

											// Right now, only certificates are used in the textarea.
											// And they need to be base64 encoded, so do not add other fields
											// with textarea type, unless they are expected to be base64 encoded.
											if (field.spec.meta?.htmlType === 'textarea') {
												return (
													<Textarea
														minRows={7}
														key={k}
														name={k}
														label={
															field.spec.meta?.label ?? titleFromIdentifier(k)
														}
														placeholder={field.spec.meta?.placeholder}
														onChange={(event) =>
															formik.setFieldValue(k, btoa(event.target.value))
														}
														onBlur={formik.handleBlur}
														onDrop={(event) => {
															event.preventDefault();
															const file = event.dataTransfer.files[0];
															const reader = new FileReader();
															reader.onload = async (e) => {
																formik.setFieldValue(
																	k,
																	btoa(e.target?.result as string)
																);
															};
															reader.readAsText(file);
														}}
														value={gracefulBase64Decode(formik.values[k] ?? '')}
														description={field.spec.meta?.description}
														defaultValue={gracefulBase64Decode(
															formik.values[k] ?? field.spec.default
														)}
														error={error}
													/>
												);
											}

											return (
												<TextInput
													autoComplete="off"
													type={field.spec.meta?.htmlType || 'text'}
													required={field.spec?.presence === 'required'}
													description={field.spec.meta?.description}
													key={k}
													name={k}
													defaultValue={field.spec.default}
													label={
														field.spec.meta?.label ?? snakeCaseToTitleCase(k)
													}
													error={formik.errors[k]}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values[k]}
													mb={24}
													disabled={field.spec.meta?.disabled || isDemo}
												/>
											);
										})}

								{initialSetup && !compact && (
									<MultiTeamsSelector
										mb={24}
										label="Teams"
										error={formik.errors.teams as string}
										value={formik.values.teams}
										setValue={(value) => formik.setFieldValue('teams', value)}
									/>
								)}

								{group?.ssh_tunnel && (
									<>
										<Suspense fallback={<SmallLoadingSpinner />}>
											<IntegrationTunnelSelect
												formik={formik}
												onChange={(value) =>
													formik.handleChange('ssh_tunnel')(
														value ?? 'no_tunnel'
													)
												}
											/>
										</Suspense>

										<Text size="sm">
											Go to the{' '}
											<RouterLink to="/tunnels">
												<Anchor>tunnels</Anchor>
											</RouterLink>{' '}
											page to create or modify tunnels.
										</Text>
									</>
								)}
								{/* Only applicable to Slack integration */}
								{slackOauth && (
									<Group spacing="3xs" mb="24px">
										<Text size="sm">OAuth Authorization</Text>
										<Text size="sm">{slackOauth.description}</Text>
										<SlackOAuthButton
											spec={spec.value as SlackIntegrationSpec}
											integration={integration}
										/>
									</Group>
								)}

								<Stack display="flex" spacing="10px">
									<Group position="right">
										{compact && (
											<Button
												variant="default"
												size="md"
												onClick={() => {
													onBack?.();
												}}
											>
												Back
											</Button>
										)}
										{!slackOauth && (
											<Button
												loading={formik.isSubmitting}
												variant="primary"
												size="md"
												type="submit"
												disabled={disableDbt || disableGithub || isDemo}
											>
												{submitLabel()}
											</Button>
										)}
									</Group>
								</Stack>
								{error && <ConnectionError error={error} />}
								{!isDemo && !compact && <Whitelist />}
								{!compact && <IntegrationHelp spec={spec} />}
							</form>
						);
					})}
				</Box>
			</Box>
		);
	}
);

IntegrationForm.displayName = 'IntegrationForm';
