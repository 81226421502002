/*
We can never repeat these keys or else customers won't be shown the modal with new content.
Please add keys to the used list below to make sure we never repeat them in the future:
Previously used keys:
- education-modal-of-table-v2
- education-modal-of-data-quality-score
*/

import type { MantineNumberSize } from '@mantine/core';
import { Button, Text } from '@repo/foundations';
import type { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { useReportList } from '../../api';
import { SmallLoadingSpinner } from '../LoadingSpinner';

interface EducationModalCopy {
	title: string;
	children: ReactNode;
	imageUrl: string;
	primaryAction: (handleClose: VoidFunction) => ReactNode | string;
	learnMoreLink?: string;
	width?: MantineNumberSize;
	expiresAt?: string;
}

function DataQualityScorePrimaryButton({
	handleClose,
}: {
	handleClose: VoidFunction;
}) {
	const { data: reportData } = useReportList({
		page: 1,
		filters: {
			type: 'data_quality',
		},
	});

	if (!reportData) {
		return <SmallLoadingSpinner />;
	}

	return (
		<Link
			to={`/analytics/${reportData?.results?.[0]?.id}`}
			onClick={handleClose}
		>
			<Button data-testid="education-continue-button" variant="primary">
				View quality score
			</Button>
		</Link>
	);
}

export const EDUCATION_MODALS = {
	'education-modal-of-data-quality-score': {
		title: 'Introducing Quality Score',
		learnMoreLink: 'https://docs.secoda.co/features/data-quality-score',
		imageUrl:
			'https://imagedelivery.net/28-eDrK8lEif6_ED0iMQeg/71729d48-53f9-4df9-2ccc-8e509b74d500/public',
		children: (
			<Text color="text/secondary/default">
				Evaluate and improve data quality with actionable insights across
				Stewardship, Usability, Reliability, and Accuracy, enhancing
				transparency and trust in your data.
			</Text>
		),
		primaryAction: (handleClose) => (
			<DataQualityScorePrimaryButton handleClose={handleClose} />
		),
		width: 480,
		expiresAt: '2024-11-01',
	} as EducationModalCopy,
	'education-modal-of-glossary-terms': {
		title: 'Metrics and Dictionary have merged',
		learnMoreLink: 'https://docs.secoda.co/features/glossary',
		imageUrl:
			'https://secoda-public-media-assets.s3.us-east-1.amazonaws.com/glossary-education-modal.png',
		children: (
			<Text color="text/secondary/default">
				You can now find your metrics and dictionary terms in the new Glossary
				page. Create terms with queries, synonyms, definitions, and more in one
				unified place.
			</Text>
		),
		primaryAction: (handleClose) => (
			<Button
				data-testid="education-continue-button"
				variant="primary"
				onClick={handleClose}
			>
				Continue
			</Button>
		),
		width: 538,
		expiresAt: '2025-01-01',
	} as EducationModalCopy,
} as const;
