import { lift, wrapIn } from 'prosemirror-commands';
import type { Attrs, NodeType } from 'prosemirror-model';
import type { EditorState } from 'prosemirror-state';
import isNodeActive from '../queries/isNodeActive';
import type { Dispatch } from '../types';

export default function toggleWrap(type: NodeType, attrs?: Attrs) {
	return (state: EditorState, dispatch?: Dispatch) => {
		const isActive = isNodeActive(type)(state);

		if (isActive) {
			return lift(state, dispatch);
		}

		return wrapIn(type, attrs)(state, dispatch);
	};
}
