import type { CSSObject } from '@mantine/core';
import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
	card: {
		backgroundColor: theme.other.getColor('surface/primary/default'),
		padding: theme.spacing.md,
		borderRadius: theme.radius.md,
		boxShadow:
			'0px 1px 0px 0px rgba(0, 0, 0, 0.05), 0px 1px 0px 0px rgba(0, 0, 0, 0.08) inset, 0px -1px 0px 0px rgba(0, 0, 0, 0.12) inset, -1px 0px 0px 0px rgba(0, 0, 0, 0.08) inset, 1px 0px 0px 0px rgba(0, 0, 0, 0.08) inset;',
	},
	inputRoot: {
		flexGrow: '1 !important' as CSSObject['flexGrow'],
	},
	input: {
		backgroundColor: theme.other.getColor('surface/secondary/default'),
		borderWidth: 0,
		borderRadius: theme.radius.sm,
		'&:hover': {
			backgroundColor: theme.other.getColor('surface/secondary/hover'),
		},
		'&:focus': {
			borderWidth: 1,
			borderStyle: 'solid',
			borderColor: theme.other.neutralColors.white,
			boxShadow: `0px 0px 0px 2px ${theme.colors.blue[5]}`,
		},
		'&:active': {
			borderWidth: 1,
			borderStyle: 'solid',
			borderColor: theme.other.neutralColors.white,
			boxShadow: `0px 0px 0px 2px ${theme.colors.blue[5]}`,
		},
	},
}));
