import { Anchor, Box, createStyles, Stack } from '@mantine/core';
import { Icon, Text } from '@repo/foundations';
import { useIntercom } from 'react-use-intercom';
import type { IntegrationSpec } from '../../interfaces/IntegrationSpec';
import { formatIntegrationType } from '../../utils/stringUtils';

interface IIntegrationHelpProps {
	spec: IntegrationSpec;
}

const useStyles = createStyles((theme) => ({
	title: {
		display: 'flex',
		alignItems: 'center',
		gap: 5,
		marginBottom: theme.spacing.xs,
	},
	body: { display: 'flex', gap: 3 },
}));

export function IntegrationHelp({ spec }: IIntegrationHelpProps) {
	const { classes } = useStyles();
	const { boot, show } = useIntercom();

	const openIntercom = async () => {
		await boot();
		show();
	};

	if (spec.type === 'marketplace') {
		// TODO(nhanthai): implement this
		return null;
	}

	return (
		<Box my={24}>
			<Box className={classes.title}>
				<Icon name="lifebuoy" />
				<Text weight="semibold" size="sm">
					Need help?
				</Text>
			</Box>
			<Stack className={classes.body}>
				<Anchor
					target="_blank"
					href={spec.value.helpArticle}
					rel="noreferrer"
					size="sm"
				>
					{formatIntegrationType(spec.value.type)} docs
				</Anchor>
				<Anchor href="#intercom" size="sm" onClick={openIntercom}>
					Contact support
				</Anchor>
			</Stack>
		</Box>
	);
}
