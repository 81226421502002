import type TurndownService from 'turndown';

/**
 * A turndown plugin for converting a confluence task list to markdown.
 *
 * @param turndownService The TurndownService instance.
 */
export default function confluenceTaskList(turndownService: TurndownService) {
	turndownService.addRule('confluenceTaskList', {
		filter(node) {
			return (
				node.nodeName === 'LI' &&
				node.parentNode?.nodeName === 'UL' &&
				// @ts-expect-error TS(2339): Property 'className' does not exist on type 'Paren... Remove this comment to see the full error message
				node.parentNode?.className.includes('inline-task-list')
			);
		},
		replacement(content, node) {
			return `${
				// @ts-expect-error TS(2339): Property 'className' does not exist on type 'Node'... Remove this comment to see the full error message
				node.className === 'checked' ? '- [x]' : '- [ ]'
			} ${content} \n`;
		},
	});
}
