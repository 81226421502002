import Dexie from 'dexie';

class Cache extends Dexie {
	// @ts-expect-error TS(2564): Property 'resources' has no initializer and i... Remove this comment to see the full error message
	secoda_entities: Dexie.Table<any, any>;

	// @ts-expect-error TS(2564): Property 'catalog_tables' has no initializer and i... Remove this comment to see the full error message
	catalog_tables: Dexie.Table<any, any>;

	// @ts-expect-error TS(2564): Property 'catalog_columns' has no initializer and ... Remove this comment to see the full error message
	catalog_columns: Dexie.Table<any, any>;

	// @ts-expect-error TS(2564): Property 'catalog_dashboards' has no initializer a... Remove this comment to see the full error message
	catalog_dashboards: Dexie.Table<any, any>;

	// @ts-expect-error TS(2564): Property 'catalog_charts' has no initializer and i... Remove this comment to see the full error message
	catalog_charts: Dexie.Table<any, any>;

	// @ts-expect-error TS(2564): Property 'catalog_jobs' has no initializer and is ... Remove this comment to see the full error message
	catalog_jobs: Dexie.Table<any, any>;

	// @ts-expect-error TS(2564): Property 'catalog_events' has no initializer and i... Remove this comment to see the full error message
	catalog_events: Dexie.Table<any, any>;

	// @ts-expect-error TS(2564): Property 'catalog_event_properties' has no initial... Remove this comment to see the full error message
	catalog_event_properties: Dexie.Table<any, any>;

	// @ts-expect-error TS(2564): Property 'dictionary_terms' has no initializer and... Remove this comment to see the full error message
	dictionary_terms: Dexie.Table<any, any>;

	// @ts-expect-error TS(2564): Property 'dictionary_terms' has no initializer and... Remove this comment to see the full error message
	metrics: Dexie.Table<any, any>;

	// @ts-expect-error TS(2564): Property 'documents' has no initializer and is not... Remove this comment to see the full error message
	documents: Dexie.Table<any, any>;

	// @ts-expect-error TS(2564): Property 'questions' has no initializer and is not... Remove this comment to see the full error message
	questions: Dexie.Table<any, any>;

	// @ts-expect-error TS(2564): Property 'collections' has no initializer and is n... Remove this comment to see the full error message
	collections: Dexie.Table<any, any>;

	// @ts-expect-error TS(2564): Property 'invitation_links' has no initializer and... Remove this comment to see the full error message
	invitation_links: Dexie.Table<any, any>;

	// @ts-expect-error TS(2564): Property 'ssh_tunnels' has no initializer and is n... Remove this comment to see the full error message
	ssh_tunnels: Dexie.Table<any, any>;

	// @ts-expect-error TS(2564): Property 'git_repositories' has no initializer and... Remove this comment to see the full error message
	git_repositories: Dexie.Table<any, any>;

	// @ts-expect-error TS(2564): Property 'integrations' has no initializer and is ... Remove this comment to see the full error message
	integrations: Dexie.Table<any, any>;

	// @ts-expect-error TS(2564): Property 'users' has no initializer and is not def... Remove this comment to see the full error message
	users: Dexie.Table<any, any>;

	// @ts-expect-error TS(2564): Property 'serviceAccounts' has no initializer and ... Remove this comment to see the full error message
	serviceAccounts: Dexie.Table<any, any>;

	// @ts-expect-error TS(2564): Property 'accounts' has no initializer and is not ... Remove this comment to see the full error message
	accounts: Dexie.Table<any, any>;

	// @ts-expect-error TS(2564): Property 'tags' has no initializer and is not defi... Remove this comment to see the full error message
	tags: Dexie.Table<any, any>;

	// @ts-expect-error TS(2564): Property 'groups' has no initializer and is not de... Remove this comment to see the full error message
	groups: Dexie.Table<any, any>;

	// @ts-expect-error TS(2564): Property 'notifications' has no initializer and is... Remove this comment to see the full error message
	notifications: Dexie.Table<any, any>;

	// @ts-expect-error TS(2564): Property 'slack' has no initializer and is not def... Remove this comment to see the full error message
	slack: Dexie.Table<any, any>;

	// @ts-expect-error TS(2564): Property 'slack_entities_notification_hooks' has n... Remove this comment to see the full error message
	slack_entities_notification_hooks: Dexie.Table<any, any>;

	// @ts-expect-error TS(2564): Property 'channels' has no initializer and is not ... Remove this comment to see the full error message
	channels: Dexie.Table<any, any>;

	// @ts-expect-error TS(2564): Property 'widgets' has no initializer and is not d... Remove this comment to see the full error message
	widgets: Dexie.Table<any, any>;

	// @ts-expect-error TS(2564): Property 'reports' has no initializer and is not d... Remove this comment to see the full error message
	reports: Dexie.Table<any, any>;

	// To store snapshots of definitions.
	definitions!: Dexie.Table<any, any>;

	// Because SQL formatting is CPU intensive, we only perform it on the frontend, and cache it,
	// so that we don't have to do it again and again.
	formatted_sql!: Dexie.Table<any, any>;

	constructor() {
		super('secoda_cache');
		Cache.intialize(this);
	}

	static intialize(db: Dexie) {
		return db.version(40).stores({
			formatted_sql: 'id',
			secoda_entities: 'id',
			catalog_tables: 'id',
			catalog_columns: 'id',
			catalog_dashboards: 'id',
			catalog_charts: 'id',
			catalog_jobs: 'id',
			catalog_events: 'id',
			catalog_event_properties: 'id',

			invitation_links: 'id',
			git_repositories: 'id',
			ssh_tunnels: 'id',
			tags: 'id',

			metrics: 'id',
			dictionary_terms: 'id',
			documents: 'id',
			questions: 'id',
			collections: 'id',

			channels: 'id',

			integrations: 'id',
			users: 'id',
			accounts: 'id',
			serviceAccounts: 'id',
			groups: 'id',
			tree: 'id',
			taggable: 'id',
			notifications: 'id',
			slack: 'id',
			slack_entities_notification_hooks: 'id',

			widgets: 'id',
			reports: 'id',

			// To store snapshots of definitions.
			definitions: 'id',
		});
	}

	destroy = async (_delete = false) => {
		if (_delete) {
			await this.delete();
			return Cache.intialize(this);
		}
		return Promise.all(this.tables.map((table) => table.clear()));
	};
}

export const cache = new Cache();
