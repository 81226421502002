import type { EntityType } from '@repo/common/enums/entityType';
import { Icon, SplitButton } from '@repo/foundations';
import type { MouseEvent, ReactNode, Ref } from 'react';
import { forwardRef, useCallback } from 'react';

import { useAuthUser, type ITemplate } from '../../api';
import { useCurrentTeamId } from '../../utils/hook/useCurrentTeamId.ts';
import { entityModalStore } from '../EntityModal/store.tsx';
import { TemplatesButton } from './TemplatesButton';

interface TemplatesButtonGroupProps {
	type: EntityType;
	withForm?: boolean;
	withSetDefault?: boolean;
	handleNew: (ev: MouseEvent<HTMLButtonElement>) => void;
	leftButtonTooltip?: ReactNode;
	label: string;
}

function TemplatesButtonGroupInternal(
	{
		type,
		withForm,
		withSetDefault,
		handleNew,
		leftButtonTooltip,
		label,
	}: TemplatesButtonGroupProps,
	ref: Ref<HTMLButtonElement>
) {
	const { user } = useAuthUser();
	const teamId = useCurrentTeamId();
	const handleUseTemplate = useCallback(
		async (template: ITemplate) => {
			entityModalStore.open({
				type,
				teamId,
				template,
				metadata: {
					owners: [user.id],
				},
			});
		},
		[teamId, type, user]
	);

	return (
		<SplitButton
			onClick={handleNew}
			variant="primary"
			label={label}
			leftButtonTooltip={leftButtonTooltip}
			ref={ref}
		>
			<TemplatesButton
				withSetDefault={withSetDefault}
				withForm={withForm}
				type={type}
				handleUseTemplate={handleUseTemplate}
			>
				<Icon name="chevronDown" />
			</TemplatesButton>
		</SplitButton>
	);
}

export const TemplatesButtonGroup = forwardRef(TemplatesButtonGroupInternal);
