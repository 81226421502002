import { createStyles, Group, Stack } from '@mantine/core';
import type { FilterOption } from '@repo/common/components/Filter/types.ts';
import type { ReactNode } from 'react';
import { useMemo } from 'react';
import type { FilterValue } from '../../../../Filter';
import { AddFilter, Filter, FilterOptionType } from '../../../../Filter';
import { FILTER_OPTIONS_CONFIG } from '../../../../Filter/constants.tsx';

interface IntegrationResourceFiltersProps {
	filters: string[];
	handleUpdate: (newFilters: string[]) => void;
	description?: ReactNode;
	filterOption: FilterOption;
}

const useStyles = createStyles((theme) => ({
	card: {
		padding: theme.spacing.md,
		border: `1px solid ${theme.other.getColor('border/secondary/default')}`,
		borderRadius: theme.radius.md,
	},
}));

export function IntegrationResourceFilters({
	filters,
	handleUpdate,
	description,
	filterOption,
}: IntegrationResourceFiltersProps) {
	const { classes } = useStyles();

	const filterValues = useMemo(
		() =>
			filters.map((value: string) => {
				const filterValue: FilterValue = {
					value,
					operator: filterOption.filterDropdownConfig.defaultOperator,
					filterType: filterOption.type,
				};
				return filterValue;
			}),
		[filters]
	);

	const handleOnChange =
		(previousValue: FilterValue) => (value: Partial<FilterValue>) => {
			handleUpdate(
				filters.map((filter) => {
					if (filter === previousValue.value) {
						return value.value as string;
					}
					return filter;
				})
			);
		};

	const handleOnClear = (value: FilterValue) => () => {
		handleUpdate(filters.filter((filter) => filter !== value.value));
	};

	const handleOnAddFilter = (value: FilterValue) => {
		handleUpdate([...filters, value.value as string]);

		return {
			value: value,
			changeFilter: handleOnChange(value),
			clearFilter: handleOnClear(value),
		};
	};

	return (
		<Stack spacing="lg">
			<Stack spacing="md" className={classes.card}>
				{description && (
					<Group position="apart" spacing={0}>
						{description}
					</Group>
				)}
				<Group spacing="xs">
					{filterValues?.map((value, idx) => (
						<Filter
							/* eslint-disable-next-line react/no-array-index-key */
							key={`filter-${idx}}`}
							filterOption={filterOption}
							value={value}
							onChange={handleOnChange(value)}
							onClear={handleOnClear(value)}
							showDetailedLabel
							operatorConfig={{
								getOperators: () => [
									filterOption.filterDropdownConfig.defaultOperator,
								],
								getLabel: (operator) => operator.toString(),
							}}
						/>
					))}
					<AddFilter
						options={[FILTER_OPTIONS_CONFIG[FilterOptionType.TITLE]]}
						onAddFilter={handleOnAddFilter}
					/>
				</Group>
			</Stack>
		</Stack>
	);
}
