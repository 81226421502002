import { useLocalStorage } from '@mantine/hooks';

const ACTIVE_AI_PERSONA_KEY = 'activeAIPersonaId';

export function useActiveAiPersona() {
	const [activeAiPersonaId, setActiveAiPersonaId] = useLocalStorage({
		key: ACTIVE_AI_PERSONA_KEY,
		defaultValue: '',
	});

	return {
		activeAiPersonaId,
		setActiveAiPersonaId,
	};
}
