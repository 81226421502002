import { cloneDeep } from 'lodash-es';
import { makeAutoObservable } from 'mobx';
import type { ConnectionStatus } from './MultiplayerEditor.types';

const USER_PRESENCE_INTERVAL = 5000;

type DocumentPresence = Map<
	string,
	{
		isEditing: boolean;
		userId: string;
	}
>;

class MultiplayerStore {
	data: Map<string, DocumentPresence> = new Map();

	observingUserId: string | undefined;

	timeouts: Map<string, ReturnType<typeof setTimeout>> = new Map();

	multiplayerStatus: ConnectionStatus = 'disconnected';

	multiplayerUserHistory: string[] = [];

	entityId: string | undefined;

	setMultiplayerUserHistory = (multiplayerUserHistory: string[]): void => {
		this.multiplayerUserHistory = cloneDeep(multiplayerUserHistory);
	};

	setEntityId = (entityId: string | undefined): void => {
		this.entityId = entityId;
	};

	setObservingUser = (userId: string | undefined): void => {
		this.observingUserId = userId;
	};

	init(documentId: string, userIds: string[], editingIds: string[]) {
		this.data.set(documentId, new Map());
		userIds.forEach((userId) => {
			if (userId !== this.observingUserId) {
				this.touch(documentId, userId, editingIds.includes(userId));
			}
		});
	}

	leave(documentId: string, userId: string) {
		const existing = this.data.get(documentId);

		if (existing) {
			existing.delete(userId);
		}
	}

	update(documentId: string, userId: string, isEditing: boolean) {
		const existing = this.data.get(documentId) || new Map();
		existing.set(userId, {
			isEditing,
			userId,
		});
		this.data.set(documentId, existing);
	}

	// Called when a user presence message is received – user.presence websocket message.
	// While in edit mode a message is sent every USER_PRESENCE_INTERVAL, if
	// the other clients don't receive within USER_PRESENCE_INTERVAL*2 then a
	// timeout is triggered causing the users presence to default back to not
	// editing state as a safety measure.
	touch(documentId: string, userId: string, isEditing: boolean) {
		const id = `${documentId}-${userId}`;
		let timeout = this.timeouts.get(id);

		// Don't update if the user is the one observing.
		if (userId === this.observingUserId) {
			return;
		}

		if (timeout) {
			clearTimeout(timeout);
			this.timeouts.delete(id);
		}

		this.update(documentId, userId, isEditing);

		if (isEditing) {
			timeout = setTimeout(() => {
				this.update(documentId, userId, false);
			}, USER_PRESENCE_INTERVAL * 2);
			this.timeouts.set(id, timeout);
		}
	}

	get(documentId: string): DocumentPresence | null | undefined {
		return this.data.get(documentId);
	}

	clear() {
		this.data.clear();
	}

	setMultiplayerStatus = (status: ConnectionStatus): void => {
		this.multiplayerStatus = status;
	};

	constructor() {
		makeAutoObservable(this);
	}
}

export const multiplayerStore = new MultiplayerStore();
