// @ts-expect-error TS(7016): Could not find a declaration file for module 'mamm... Remove this comment to see the full error message
import mammoth from 'mammoth/mammoth.browser';
import turndownService from './turndown';

export async function docxToMarkdown(
	content: ArrayBuffer | string
): Promise<string> {
	if (content instanceof ArrayBuffer) {
		const { value: html } = await mammoth.convertToHtml({
			arrayBuffer: content,
		});
		return turndownService.turndown(html);
	}

	throw new Error('docxToMarkdown: content must be a ArrayBuffer');
}
