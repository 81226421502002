import { Skeleton } from '@mantine/core';
import { Suspense } from 'react';
import type { MetricContainerProps } from './MetricContainer';
import { MetricContainer } from './MetricContainer';

export interface QueryBlockContainerProps
	extends Pick<
		MetricContainerProps,
		'onDuplicate' | 'onCopyLink' | 'onAddGraph'
	> {
	id: string;
	integrationId?: string;
	readOnly: boolean;
	onDelete: VoidFunction;
	isSelected?: boolean;
}

export function QueryBlockContainer({
	id,
	integrationId,
	readOnly,
	onDelete,
	isSelected,
	onDuplicate,
	onCopyLink,
	onAddGraph,
}: QueryBlockContainerProps) {
	return (
		<Suspense fallback={<Skeleton height={300} />}>
			<MetricContainer
				withQuery
				withChart={false}
				id={id}
				integrationId={integrationId}
				readOnly={readOnly}
				isSelected={isSelected}
				onDelete={onDelete}
				onDuplicate={onDuplicate}
				onCopyLink={onCopyLink}
				onAddGraph={onAddGraph}
			/>
		</Suspense>
	);
}
