import { Anchor, Flex, Stack, Textarea, useMantineTheme } from '@mantine/core';
import { useClipboard } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import { Button, Text } from '@repo/foundations';
import ed25519 from 'ed25519-keygen/ssh';
import { randomBytes } from 'ed25519-keygen/utils';
import {
	queryClient,
	tunnelsQueryKeyFactory,
	useCreateTunnel,
} from '../../api';
import { useFeatureAccess } from '../../api/hooks/workspace/useFeatureAccess';
import { useWorkspaceEnv } from '../../api/hooks/workspace/useWorkspaceEnv';
import { EmptyState } from '../../components/EmptyState';
import { openModal } from '../../components/ModalManager';
import { UpgradeButton } from '../../components/Settings/UpgradeButton';
import TunnelsTable from './TunnelsTable';

const TEMPLATE = `version: "3"
services:
  agent:
    image: secoda/agent:latest
    restart: always
    environment:
      - SSH_HOST={HOST}
      - SSH_HOST_PORT={HOST_PORT}
      - SSH_LISTEN_PORT={LISTEN_PORT}
      - SSH_KEY_BASE64={KEY}`;

function TunnelsPage() {
	const clipboard = useClipboard();
	const theme = useMantineTheme();

	const { mutateAsync: createTunnel } = useCreateTunnel({});

	const { data } = useWorkspaceEnv();

	const { tunnelAccess } = useFeatureAccess();

	const handleNewTunnelReverse = async () => {
		const sseed = randomBytes(32);
		const skeys = await ed25519(sseed, 'ssh@secoda.co');

		const tunnel = await createTunnel({
			data: {
				public_key: skeys.publicKey,
				reverse: true,
			},
		});

		queryClient.invalidateQueries(
			tunnelsQueryKeyFactory.list(1, { reverse: true })
		);

		const value = TEMPLATE.replaceAll('{HOST}', data?.tunnel_url ?? '')
			.replaceAll('{HOST_PORT}', tunnel.port?.toString())
			.replaceAll('{LISTEN_PORT}', tunnel?.port_socks?.toString())
			.replaceAll('{KEY}', btoa(skeys.privateKey));

		const handleClick = () => {
			clipboard.copy(value);
			showNotification({
				title: 'Configuration copied',
				color: 'green',
				message:
					'The docker compose configuration has been copied to your clipboard',
			});
		};

		openModal({
			variant: 'default',
			title: 'Reverse tunnel generated',
			children: (
				<Stack spacing={theme.spacing.md} mt={theme.spacing.md}>
					<Stack spacing={theme.spacing.xs}>
						<Text size="sm" weight="semibold">
							Docker Compose
							<br />
							<Text size="xs">
								Learn more{' '}
								<Anchor
									size="xs"
									target="_blank"
									href="https://docs.secoda.co/integrations/connecting-via-tunnels/connecting-via-secoda-agent-reverse-ssh-tunnel"
								>
									here
								</Anchor>
							</Text>
						</Text>
						<Textarea minRows={6} value={value} />
					</Stack>
					<Flex>
						<Button onClick={handleClick}>Copy configuration</Button>
					</Flex>
				</Stack>
			),
		});
	};

	if (!tunnelAccess) {
		return (
			<EmptyState
				illustrationName="upgrade"
				title="Upgrade to access Tunnels"
				description="Connect integrations to Secoda using SSH tunnels instead of a direction connection."
				includeGoBack={false}
				stateHeight="80vh"
				size="lg"
				withActions={
					<UpgradeButton
						tooltip="Upgrade to access Tunnels"
						feature="Tunnels"
						size="md"
					/>
				}
			/>
		);
	}

	return (
		<Stack spacing={theme.spacing.xl} p={theme.other.space[8]}>
			<TunnelsTable
				prefix="Standard"
				description="Connect through SSH to your bastion server and select this tunnel when creating a new integration. For 5+ integrations using the same tunnel host, use a reverse tunnel to avoid session limits. Learn more at https://docs.secoda.co/integrations/connecting-via-tunnels/connecting-via-ssh-tunnel"
				args={{ filters: { reverse: false, archived: false } }}
			/>
			{data?.reverse_tunnel_enabled && (
				<TunnelsTable
					columns={['port']}
					prefix="Reverse"
					description="Connect to services in a private network without opening ports. Create a reverse tunnel on this page and run the Secoda reverse tunnel agent on your server. Learn more at https://docs.secoda.co/integrations/connecting-via-tunnels/connecting-via-secoda-agent-reverse-ssh-tunnel"
					onCreate={handleNewTunnelReverse}
					args={{ filters: { reverse: true, archived: false } }}
				/>
			)}
		</Stack>
	);
}

export default TunnelsPage;
