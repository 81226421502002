import { observer } from 'mobx-react-lite';
import { CommentStoreProvider } from '../../components/Comment/context';
import { useParamsIdSuffixUuid } from '../../utils/hook/utils';
import type { IDocumentPageProps } from './DocumentPage';
import DocumentPage from './DocumentPage';

function DocumentPageWithCommentStore({ id: propsId }: IDocumentPageProps) {
	const paramsId = useParamsIdSuffixUuid();
	const id = propsId || paramsId;

	return (
		<CommentStoreProvider>
			<DocumentPage id={id} />
		</CommentStoreProvider>
	);
}

export default observer(DocumentPageWithCommentStore);
