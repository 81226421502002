import type { MantineColor } from '@mantine/core';
import { createStyles, Group, Menu, ThemeIcon } from '@mantine/core';
import { Icon, Text } from '@repo/foundations';
import { capitalize, map, omit } from 'lodash-es';
import type { IWidget } from '../../../../../../../api';

export interface IWidgetColorMenuProps {
	color: IWidget['color'];
	onColorChange: (color: MantineColor) => void;
}

const useStyles = createStyles({
	colorIcon: {
		boxShadow: '0px 0px 0px 1px #23222114 inset',
	},
});

function WidgetColorMenu({
	color: widgetColor,
	onColorChange,
}: IWidgetColorMenuProps) {
	const { classes, theme } = useStyles();

	const handleColorChange = (color: MantineColor) => () => {
		onColorChange(color);
	};

	// We should use the `color=` prop instead of `c=` here, but this cycles through
	// color[8] for every color, and there isn't a good enough match for all of them
	const getIcon = (bg: string, color: string) => (
		<ThemeIcon className={classes.colorIcon} size="sm" color={bg}>
			<Text size="sm" weight="bold" c={color}>
				A
			</Text>
		</ThemeIcon>
	);

	return (
		<Menu position="right" trigger="hover" withinPortal>
			<Menu.Target>
				<Menu.Item icon={<Icon name="paint" />}>
					<Group position="apart">
						Color
						<Icon name="chevronRight" />
					</Group>
				</Menu.Item>
			</Menu.Target>
			<Menu.Dropdown>
				<Menu.Item
					icon={getIcon('white', 'black')}
					onClick={handleColorChange('white')}
					rightSection={widgetColor === 'white' && <Icon name="check" />}
				>
					Default
				</Menu.Item>
				{map(
					omit(theme.colors, ['brand', 'primary', 'dark', 'gray']),
					(colors, color) => (
						<Menu.Item
							key={color}
							icon={getIcon(colors[0], colors[8])}
							onClick={handleColorChange(color)}
							rightSection={widgetColor === color && <Icon name="check" />}
						>
							{capitalize(color)}
						</Menu.Item>
					)
				)}
			</Menu.Dropdown>
		</Menu>
	);
}

export default WidgetColorMenu;
