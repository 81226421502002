import {
	baseQueryHooksFactory,
	createQueryKeys,
	prefetchFunctionsFactory,
} from '../../factories';
import type { ITunnel } from '../../types';

const TUNNELS_NAMESPACE = ['tunnel', 'tunnels'];

export const tunnelsQueryKeyFactory = createQueryKeys(TUNNELS_NAMESPACE);

const { prefetchTunnel, prefetchTunnelList } = prefetchFunctionsFactory(
	'tunnel',
	tunnelsQueryKeyFactory
);

const {
	useTunnel,
	useTunnelInfiniteList,
	useTunnelList,
	useCreateTunnel,
	// To delete, set `archived` to `true`.
	useUpdateTunnel,
	fetchTunnelList,
	updateTunnel,
} = baseQueryHooksFactory<ITunnel, 'tunnel'>('tunnel', tunnelsQueryKeyFactory);

export {
	fetchTunnelList,
	prefetchTunnel,
	prefetchTunnelList,
	updateTunnel,
	useCreateTunnel,
	useTunnel,
	useTunnelInfiniteList,
	useTunnelList,
	useUpdateTunnel,
};
