import type { ESQueryModel, LiteQueryOut } from '@repo/api-codegen';
import {
	useApiGetCreationQuery,
	useApiGetEntityQueries,
} from '@repo/api-codegen';
import { useNavigate } from '@repo/common/hooks/useNavigate';
import { useCallback } from 'react';
import type { IBaseModel, ISecodaEntity } from '../../api';
import type { EntityTabsStore } from '../../pages/TableEntityPage/TableEntityTabs/TableEntityTabsStore';
import { TableV2 } from '../TableV2';
import { useActions, useColumns } from './hooks';

export interface IQueryListProps {
	entity: ISecodaEntity;
	store: EntityTabsStore;
}

export function QueryList({ entity, store }: IQueryListProps) {
	const columns = useColumns();
	const actions = useActions();
	const navigate = useNavigate();

	useApiGetCreationQuery(
		{
			pathParams: {
				entityId: entity.id,
			},
		},
		{
			onSuccess: (data: LiteQueryOut) => {
				store.setCreationQuery?.(data);
			},
		}
	);

	const handleCellClick = useCallback(
		(cell) => {
			navigate(`/query/${cell.record.id}`);
		},
		[navigate]
	);

	return (
		<TableV2<ESQueryModel & IBaseModel>
			pluralTypeString="queries"
			onTotalRowCountChange={store.setQueryCount}
			columns={columns}
			// @ts-ignore
			usePaginationList={useApiGetEntityQueries}
			defaultRequiredOptions={{
				pathParams: {
					entityId: entity.id,
				},
			}}
			withSearch
			withCheckbox={false}
			withFilters={false}
			withActions={actions}
			withInteractiveHeader
			defaultSort={null}
			onCellClick={handleCellClick}
		/>
	);
}
