import type { MarketplaceIntegrationSpecVersionOut } from '@repo/api-codegen';
import { useMemo } from 'react';

import {
	LastUpdatedRender,
	ReadOnlyBadgeRender,
	TextRender,
} from '../../components/TableV2/render';

import type { ExtendedDataTableColumn } from '../../components/TableV2/types';
import {
	CodeRender,
	TitleWithImageRender,
	VersionActionRender,
} from './MarketplaceIntegrationSpecDetailPage.render';

export const useColumns =
	(): ExtendedDataTableColumn<MarketplaceIntegrationSpecVersionOut>[] => {
		const columns: ExtendedDataTableColumn<MarketplaceIntegrationSpecVersionOut>[] =
			useMemo(
				() => [
					{
						title: 'Name',
						accessor: 'name',
						render: (record) => <TitleWithImageRender record={record} />,
					},
					{
						title: 'Version',
						accessor: 'version_number',
						render: (record) => (
							<TextRender<MarketplaceIntegrationSpecVersionOut>
								record={record}
								field={'version_number'}
							/>
						),
					},
					{
						title: 'Description',
						accessor: 'description',
						render: (record) => (
							<TextRender<MarketplaceIntegrationSpecVersionOut>
								record={record}
								field={'description'}
							/>
						),
					},
					{
						title: 'Status',
						accessor: 'publish_status',
						render: (record) => (
							<ReadOnlyBadgeRender
								record={record}
								field={'publish_status'}
								options={[
									{ option: 'draft', color: 'gray', label: 'Draft' },
									{ option: 'submitted', color: 'yellow', label: 'In review' },
									{
										color: 'yellow',
										label: 'In review',
										option: 'in_review',
									},
									{ option: 'approved', color: 'green', label: 'Approved' },
									{ option: 'published', color: 'green', label: 'Published' },
									{ option: 'rejected', color: 'red', label: 'Rejected' },
								]}
								nilOption={{
									color: 'gray',
									label: 'Draft',
									option: 'disabled',
								}}
							/>
						),
					},
					{
						title: 'Code',
						accessor: 'code',
						render: (record) => <CodeRender record={record} />,
						navigate: false,
					},

					{
						title: 'Action',
						accessor: 'action',
						render: (record) => <VersionActionRender record={record} />,
						navigate: false,
					},
					{
						title: 'Created at',
						accessor: 'created_at',
						render: (record) => (
							<LastUpdatedRender<MarketplaceIntegrationSpecVersionOut>
								record={record}
								field={'created_at'}
							/>
						),
					},
				],
				[]
			);

		return columns;
	};
