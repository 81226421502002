import type { DefaultProps, MantineColor, Selectors } from '@mantine/core';
import { ActionIcon, Box, createStyles, Group, Tabs } from '@mantine/core';
import { Icon } from '@repo/foundations';
import useEmblaCarousel from 'embla-carousel-react';
import React, { useCallback, useEffect } from 'react';

export interface TabItem {
	label: React.ReactNode;
	value: string;
	icon?: React.ReactNode;
	hidden?: boolean;
	disabled?: boolean;
}

type TabsListStylesNames = Selectors<typeof useStyles>;

export interface TabsListProps extends DefaultProps<TabsListStylesNames> {
	tabs: TabItem[];
	color?: MantineColor;
}

const useStyles = createStyles((theme) => ({
	wrapper: {
		position: 'relative',
		'&::after': {
			display: 'flex',
			content: '""',
			width: '100%',
			borderBottom: `thin solid ${theme.other.getColor(
				'border/primary/default'
			)}`,
			position: 'absolute',
			bottom: -1,
		},
	},
	container: {
		flexWrap: 'nowrap',
	},
	viewport: {
		overflowX: 'hidden',
		height: theme.other.space[10] - 2, // 2px for border bottom,
	},
	tabLabel: {},
	slide: {
		'&[data-active]': {
			fontWeight: theme.other.typography.weight.semibold,
		},
		'&:not([data-active])': {
			fontWeight: theme.other.typography.weight.semibold,
			color: theme.other.getColor('text/secondary/default'),
		},
		'&:hover': {
			backgroundColor: 'transparent',
		},
		padding: theme.spacing.xs,
		marginLeft: theme.spacing.xs,
		marginRight: theme.spacing.xs,
		paddingLeft: 0,
		paddingRight: 0,
		'&:first-of-type': {
			marginLeft: 0,
		},
		'.mantine-Tabs-tabLabel': {
			lineHeight: theme.other.typography.lineHeight.text.sm,
		},
	},
	icon: {
		border: `thin solid ${theme.other.getColor('border/primary/default')}`,
		borderRadius: theme.radius.xl,
	},
	iconWrapper: {
		position: 'absolute',
		zIndex: 1,
		height: '90%',
		display: 'flex',
		alignItems: 'center',
		width: theme.other.space[16],
	},
	prevIcon: {
		left: 0,
		background: theme.fn.linearGradient(
			90,
			theme.white,
			theme.white,
			theme.fn.rgba(theme.white, 0)
		),
	},
	nextIcon: {
		top: 0,
		right: 0,
		background: theme.fn.linearGradient(
			90,
			theme.fn.rgba(theme.white, 0),
			theme.white,
			theme.white
		),
		display: 'flex',
		justifyContent: 'flex-end',
	},
}));

function TabsList({
	tabs,
	className,
	classNames,
	unstyled,
	styles,
	color = 'dark',
	...others
}: TabsListProps) {
	const { classes, cx } = useStyles(undefined, {
		name: 'TabsList',
		classNames,
		unstyled,
		styles,
	});

	const [emblaRef, emblaApi] = useEmblaCarousel({
		align: 'start',
		slidesToScroll: 2,
		containScroll: 'trimSnaps',
	});

	const [showPrev, setShowPrev] = React.useState(false);
	const [showNext, setShowNext] = React.useState(false);

	const scrollPrev = useCallback(() => {
		if (!emblaApi) {
			return;
		}
		emblaApi.scrollPrev();
	}, [emblaApi]);

	const scrollNext = useCallback(() => {
		if (!emblaApi) {
			return;
		}
		emblaApi.scrollNext();
	}, [emblaApi]);

	const recalculateArrows = useCallback(() => {
		if (!emblaApi) {
			return;
		}

		setShowPrev(emblaApi.canScrollPrev());
		setShowNext(emblaApi.canScrollNext());
	}, [emblaApi]);

	useEffect(() => {
		if (!emblaApi) {
			return () => undefined;
		}

		function onResize() {
			recalculateArrows();
		}

		emblaApi.on('select', recalculateArrows);
		emblaApi.on('resize', onResize);

		return () => {
			emblaApi.off('select', recalculateArrows);
			emblaApi.off('resize', onResize);
		};
	}, [emblaApi, recalculateArrows]);

	useEffect(() => {
		if (!emblaApi) {
			return;
		}

		emblaApi.reInit();
		recalculateArrows();
	}, [emblaApi, recalculateArrows, tabs]);

	return (
		<Box className={cx(classes.wrapper, className)} {...others}>
			{showPrev && (
				<Box className={cx(classes.iconWrapper, classes.prevIcon)}>
					<ActionIcon
						className={cx(classes.icon)}
						size="sm"
						onClick={scrollPrev}
					>
						<Icon name="chevronLeft" />
					</ActionIcon>
				</Box>
			)}
			<Box ref={emblaRef} className={cx(classes.viewport, 'embla__viewport')}>
				<Group
					spacing="none"
					className={cx(classes.container, 'embla__container')}
				>
					{tabs.map((tab) => {
						if (tab?.hidden) {
							return null;
						}

						return (
							<Tabs.Tab
								key={tab.value}
								data-testid={`tab-${tab.value?.toLowerCase()}`}
								className={cx(classes.slide, 'embla__slide')}
								value={tab.value}
								icon={tab.icon}
								color={color}
								disabled={tab?.disabled}
							>
								<Box className={classes.tabLabel}>{tab.label}</Box>
							</Tabs.Tab>
						);
					})}
				</Group>
			</Box>
			{showNext && (
				<Box className={cx(classes.iconWrapper, classes.nextIcon)}>
					<ActionIcon className={classes.icon} size="sm" onClick={scrollNext}>
						<Icon name="chevronRight" />
					</ActionIcon>
				</Box>
			)}
		</Box>
	);
}

export default TabsList;
