import type { IHomepageOnboardingSteps } from './types';

export const HOMEPAGE_ONBOARDING_STEPS = [
	'Connect an integration',
	'Explore your workspace',
	'Invite your team members',
] as const;

export const TEAM_HOMEPAGE_ONBOARDING_STEPS = [
	'Add new widgets to this page',
	'Add a cover image',
	'Click and drag widgets to reorder them',
	'Invite members to this team',
] as const;

export const HOMEPAGE_ONBOARDING_STEP_LINKS: Partial<
	Record<IHomepageOnboardingSteps, string>
> = {
	'Connect an integration': '/integrations',
	'Explore your workspace': '/search',
	'Invite your team members': '/settings/members',
};
