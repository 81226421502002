import type { IIntegration, ISecodaEntity } from '../../api';

const getDiscussionTeamIds = (
	entity: ISecodaEntity,
	integrations: IIntegration[]
): string[] => {
	const teams: string[] = [];

	// Check if the resource has explicit teams defined
	if (entity.teams && entity.teams.length > 0) {
		// If so, we need to add them to the question
		teams.push(...entity.teams);
	}

	// If the integration has teams, we need to add them to the question
	const integration = integrations?.find((i) => i.id === entity.integration);

	// If the integration has teams, we need to add them to the question
	if (integration?.teams) {
		teams.push(...integration.teams);
	}

	// Return a de-duplicated list of teams since we may have added teams
	// from both the resource and the integration
	return [...new Set(teams)];
};

export { getDiscussionTeamIds };
