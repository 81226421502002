import type { MeasurementChartData, TimeRange } from '../../../../api';
import { useMeasurementsChartData, useMonitor } from '../../../../api';
import MonitorTimeseriesChart from '../../../MonitorPage/v2/MonitorTimeseriesChart';
import { METRIC_WIDGET_CONTENT_HEIGHT } from '../../constants';
import { LOOKBACK_KEY } from '../../utils/utils';
import { MetricLoadingOrEmpty } from '../MetricLoadingOrEmpty';
import type { IMetricWorkspaceLineChartProps } from '../MetricWorkspaceLineChart';

const BASE_DATE = new Date();
const DAY_IN_MS = 24 * 60 * 60 * 1000;

const MOCK_DATA: MeasurementChartData[] = [
	{
		upper_threshold: 182.81788672521867,
		lower_threshold: 118.50652119737396,
		truncated_time: new Date(BASE_DATE.getTime() - 6 * DAY_IN_MS).toISOString(),
		value: 154.90107547422951,
		incident_exists: false,
		incident_status: null,
	} as MeasurementChartData,
	{
		upper_threshold: 186.74945377988053,
		lower_threshold: 124.43250363274757,
		truncated_time: new Date(BASE_DATE.getTime() - 5 * DAY_IN_MS).toISOString(),
		value: 42.73498890790867,
		incident_exists: true,
		incident_id: 'foobar',
		incident_status: 'resolved',
	} as MeasurementChartData,
	{
		upper_threshold: 175.17506023728455,
		lower_threshold: 118.39388602460821,
		truncated_time: new Date(BASE_DATE.getTime() - 4 * DAY_IN_MS).toISOString(),
		value: 149.91931496453014,
		incident_exists: false,
		incident_status: null,
	} as MeasurementChartData,
	{
		upper_threshold: 177.1314469972407,
		lower_threshold: 122.14632035263836,
		truncated_time: new Date(BASE_DATE.getTime() - 3 * DAY_IN_MS).toISOString(),
		value: 145.37030061850632,
		incident_exists: false,
		incident_status: null,
	} as MeasurementChartData,
	{
		upper_threshold: 170.6520439898447,
		lower_threshold: 118.44071251532304,
		truncated_time: new Date(BASE_DATE.getTime() - 2 * DAY_IN_MS).toISOString(),
		value: 146.09455289115806,
		incident_exists: false,
		incident_status: null,
	} as MeasurementChartData,
	{
		upper_threshold: 170.03427006192283,
		lower_threshold: 122.17929426684555,
		truncated_time: new Date(BASE_DATE.getTime() - 1 * DAY_IN_MS).toISOString(),
		value: 200.21795400592188,
		incident_exists: true,
		incident_id: 'foobar2',
		incident_status: 'acknowledged',
	} as MeasurementChartData,
	{
		upper_threshold: 173.70612555881752,
		lower_threshold: 127.00419393086318,
		truncated_time: BASE_DATE.toISOString(),
		value: 151.34281812103922,
		incident_exists: false,
		incident_status: null,
	} as MeasurementChartData,
	{
		upper_threshold: 244.14212209923977,
		lower_threshold: 40.582330333756005,
		truncated_time: new Date(BASE_DATE.getTime() + 1 * DAY_IN_MS).toISOString(),
		value: 0.0,
		incident_exists: true,
		incident_first_seen_at: new Date(
			BASE_DATE.getTime() - 1 * DAY_IN_MS
		).toISOString(),
		incident_id: '84c2c23c-ae9c-481c-8ce0-ddbaa84a9557',
		incident_status: 'active',
	},
	{
		upper_threshold: 270.30096156511,
		lower_threshold: -284.3832673828769,
		truncated_time: new Date(BASE_DATE.getTime() + 3 * DAY_IN_MS).toISOString(),
		value: 152.9415860430662,
		incident_exists: false,
		incident_status: null,
	} as MeasurementChartData,
];

export function MonitorChart({
	metricWidget,
	source,
}: IMetricWorkspaceLineChartProps) {
	const { data: monitor } = useMonitor({
		id: String(metricWidget.metric_metadata.monitor_id),
		options: {
			enabled: Boolean(metricWidget.metric_metadata.monitor_id),
		},
	});

	const { data: measurements, isFetching: isLoading } =
		useMeasurementsChartData({
			monitorId: metricWidget.metric_metadata.monitor_id,
			timeRange: (metricWidget.metric_metadata?.user_filter?.[LOOKBACK_KEY] ||
				'7') as TimeRange,
		});

	if (isLoading) {
		return <MetricLoadingOrEmpty isLoading={isLoading} source={source} />;
	}

	if (!monitor) {
		return (
			<MonitorTimeseriesChart
				width="100%"
				height={METRIC_WIDGET_CONTENT_HEIGHT[source]}
				data={MOCK_DATA}
				configuredLowerThreshold={undefined}
				configuredUpperThreshold={undefined}
				metricType={'row_count'}
				chartProps={{
					marginLeft: source === 'widget' ? 60 : 40,
				}}
			/>
		);
	}

	return (
		<MonitorTimeseriesChart
			width="100%"
			height={METRIC_WIDGET_CONTENT_HEIGHT[source]}
			data={measurements || []}
			configuredLowerThreshold={monitor.condition_manual_min ?? undefined}
			configuredUpperThreshold={monitor.condition_manual_max ?? undefined}
			metricType={monitor.metric_type}
			chartProps={{
				marginLeft: source === 'widget' ? 60 : 40,
			}}
		/>
	);
}
