import { Carousel } from '@mantine/carousel';
import { Button, type IconNames } from '@repo/foundations';
import { forwardRef } from 'react';
import useStyles from './Tab.styles';

export interface ITabProps {
	active?: boolean;
	iconName?: IconNames;
	children: React.ReactNode;
	onClick?: () => void;
}

export const Tab = forwardRef<HTMLDivElement, ITabProps>(
	({ active = false, iconName, children, onClick }, ref) => {
		const { classes } = useStyles({ active });

		return (
			<Carousel.Slide ref={ref} className={classes.slide}>
				<Button
					data-testid="tab-button"
					variant="tertiary"
					leftIconName={iconName}
					onClick={onClick}
				>
					{children}
				</Button>
				<div className={classes.indicator} />
			</Carousel.Slide>
		);
	}
);

Tab.displayName = 'Tab';
