import type { ClassNames } from '@mantine/core';
import { createStyles, Group, Stack, Table } from '@mantine/core';
import { useInputState } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import type { GroupItem, GroupSetting } from '@repo/api-codegen';
import {
	useApiGetIntegrationGroupItems,
	useApiGetIntegrationGroupSettings,
	useApiGroupItemsRefreshSupport,
	useApiRefreshIntegrationGroupItems,
	useApiUpdateIntegrationGroupSettings,
} from '@repo/api-codegen';
import { Button, Icon } from '@repo/foundations';
import { useCallback, useEffect, useMemo } from 'react';
import type { IIntegration } from '../../../../api';
import type { IntegrationSpec } from '../../../../interfaces/IntegrationSpec.ts';
import { LoadingSpinner } from '../../../LoadingSpinner';
import SearchBox from '../../../SearchBox/SearchBox.tsx';
import {
	filterGroupItems,
	getAllDescendants,
} from '../../IntegrationSelectionPage/StepComponents/ImportStep/utils.ts';
import {
	SelectIntegrationGroupsPanelTableHeaders,
	SelectIntegrationGroupsPanelTableRow,
	VisibilityState,
} from './SelectIntegrationGroupsPanelTableRow.tsx';

interface SelectPanelProps {
	integration: IIntegration;
	spec: IntegrationSpec;
	classes?: ClassNames<string>;
	refreshOnLoad?: boolean;
}

const useStyles = createStyles((theme) => ({
	table: {
		borderCollapse: 'separate',
		border: `1px solid ${theme.other.getColor('border/primary/default')}`,
		borderRadius: theme.radius.md,
		overflow: 'hidden',
		width: '100%',
		height: '100%',
		tableLayout: 'fixed',
		'thead tr th': {
			padding: theme.spacing.xs,
			fontSize: theme.other.typography.text.sm,
			fontWeight: theme.other.typography.weight.semibold,
			color: theme.other.getColor('text/secondary/default'),
			backgroundColor: theme.other.getColor('surface/secondary/default'),
			borderBottomColor: theme.other.getColor('border/secondary/default'),
		},
		'thead tr th:first-of-type': {
			paddingLeft: theme.spacing.md,
		},
		'tbody tr td': {
			padding: theme.spacing.xs,
			fontSize: theme.other.typography.text.xs,
			borderTopColor: theme.other.getColor('border/secondary/default'),
		},
		'tbody tr td:first-of-type': {
			paddingLeft: theme.spacing.md,
			fontWeight: theme.other.typography.weight.bold,
		},
		'tbody tr': {
			cursor: 'pointer',
		},
	},
}));

export function SelectIntegrationGroupsPanel({
	integration,
	spec,
	refreshOnLoad = false,
}: SelectPanelProps) {
	const { classes } = useStyles();

	// ============================
	// Search/Filtering State
	// ============================
	const [searchTerm, setSearchTerm] = useInputState('');

	// ============================
	// API Calls
	// ============================
	const {
		data: groupItems,
		isLoading: isLoadingGroupItems,
		refetch: refetchIntegrationGroupItems,
	} = useApiGetIntegrationGroupItems(
		{
			pathParams: {
				integrationId: integration.id,
			},
		},
		{
			enabled: !refreshOnLoad,
			select: (data) => filterGroupItems(data, searchTerm),
		}
	);

	const { data: supportRefresh, isLoading: isLoadingRefreshSupport } =
		useApiGroupItemsRefreshSupport({
			queryParams: {
				integration_type: integration.type,
			},
		});

	const {
		data: groupSettingsState,
		isLoading: isLoadingGroupSettings,
		refetch: refetchIntegrationGroupSettings,
	} = useApiGetIntegrationGroupSettings(
		{
			pathParams: {
				integrationId: integration.id,
			},
		},
		{ enabled: !refreshOnLoad }
	);

	const {
		mutateAsync: apiRefreshIntegrationGroupItems,
		isLoading: isRefreshing,
	} = useApiRefreshIntegrationGroupItems({
		onSuccess: () => {
			refetchIntegrationGroupItems();
			refetchIntegrationGroupSettings();
		},
	});

	const { mutateAsync: apiUpdateIntegrationGroupSettings } =
		useApiUpdateIntegrationGroupSettings({
			onSuccess: () => refetchIntegrationGroupSettings(),
		});

	// ============================
	// Initial refresh on page load
	// ============================

	useEffect(() => {
		if (refreshOnLoad)
			apiRefreshIntegrationGroupItems({
				pathParams: {
					integrationId: integration.id,
				},
			});
	}, [apiRefreshIntegrationGroupItems, refreshOnLoad]);

	// ============================
	// Update the group settings on the backend
	// ============================

	const persistGroupSettings = useCallback(
		async (newState: Record<string, GroupSetting>) => {
			try {
				await apiUpdateIntegrationGroupSettings({
					body: {
						group_settings: newState,
					},
					pathParams: {
						integrationId: integration.id,
					},
				});
			} catch {
				showNotification({
					message: 'Unable to update group settings',
					color: 'red',
					icon: <Icon name="x" />,
				});
			}
		},
		[integration]
	);

	// ============================
	// Method Handlers
	// ============================
	const refreshIntegrationGroupItems = useCallback(async () => {
		try {
			await apiRefreshIntegrationGroupItems({
				pathParams: {
					integrationId: integration.id,
				},
			});
		} catch {
			showNotification({
				title: 'Error refreshing groups',
				message: 'Please contact support if the issue persists.',
				color: 'red',
				icon: <Icon name="x" />,
			});
		}
	}, [apiRefreshIntegrationGroupItems]);

	// ============================
	// Group Visibility Handler
	// ============================
	const handleVisibilityChange = useCallback(
		async (item: GroupItem, visible: boolean) => {
			const descendants = getAllDescendants(item);
			const newState = {
				...groupSettingsState,
				...descendants.reduce<Record<string, GroupSetting>>(
					(acc, descendant) => {
						acc[descendant.databuilder_id] = {
							...groupSettingsState![descendant.databuilder_id],
							visible,
						};
						return acc;
					},
					{}
				),
			};

			await persistGroupSettings(newState);
		},
		[groupSettingsState, persistGroupSettings]
	);

	// ============================
	// Team Visibility Handlers
	// ============================

	const updateCustomMappingTeams = useCallback(
		async (item: GroupItem, teamIds: string[]) => {
			// TODO make the update cascade here??
			const newState: Record<string, GroupSetting> = {
				...groupSettingsState,
				[item.databuilder_id]: {
					...groupSettingsState![item.databuilder_id],
					mapping_teams: teamIds,
					custom_mapping: teamIds.length > 0,
				},
			};

			await persistGroupSettings(newState);
		},
		[groupSettingsState, persistGroupSettings]
	);

	// ============================
	// Group Item (+ children) visibility state
	// ============================
	const isVisible = useCallback(
		(node: GroupItem) => {
			if (spec.type === 'builtin' && spec.value.schemaSupport) {
				const descendants = getAllDescendants(node).filter(
					(d) => d.databuilder_id !== node.databuilder_id
				);

				// If there are no descendants, check the visibility of the current node
				if (descendants.length === 0) {
					return (groupSettingsState?.[node.databuilder_id]?.visible ?? true)
						? VisibilityState.VISIBLE
						: VisibilityState.HIDDEN;
				}

				const allVisible = descendants.every(
					(descendant) =>
						groupSettingsState?.[descendant.databuilder_id]?.visible ?? true
				);
				const someVisible = descendants.some(
					(descendant) =>
						groupSettingsState?.[descendant.databuilder_id]?.visible ?? true
				);

				if (allVisible) {
					return VisibilityState.VISIBLE;
				}
				if (someVisible) {
					return VisibilityState.INDETERMINATE;
				}
				return VisibilityState.HIDDEN;
			}

			if (
				groupSettingsState &&
				node.databuilder_id in groupSettingsState &&
				groupSettingsState[node.databuilder_id]
			) {
				return groupSettingsState[node.databuilder_id].visible
					? VisibilityState.VISIBLE
					: VisibilityState.HIDDEN;
			}

			return VisibilityState.VISIBLE;
		},
		[groupSettingsState, groupItems]
	);

	// Search Handling
	const handleSearch = useCallback(
		(newSearchTerm: string) => {
			setSearchTerm(newSearchTerm);
		},
		[setSearchTerm]
	);

	// Collect all the loading variables into one place
	const isLoading = useMemo(
		() =>
			isLoadingGroupItems ||
			isLoadingRefreshSupport ||
			isLoadingGroupSettings ||
			isRefreshing,
		[
			isLoadingGroupItems,
			isLoadingRefreshSupport,
			isLoadingGroupSettings,
			isRefreshing,
		]
	);

	return (
		<>
			<Group spacing="sm" noWrap>
				<SearchBox
					variant="tertiary"
					placeholder="Search"
					onSearch={handleSearch}
				/>
				{supportRefresh && (
					<Button
						variant="default"
						size="md"
						leftIconName="refresh"
						loading={isRefreshing}
						disabled={isRefreshing}
						onClick={refreshIntegrationGroupItems}
					>
						Refresh
					</Button>
				)}
			</Group>
			<Stack mah={'100%'} pos="relative" style={{ overflowY: 'auto' }}>
				<Table className={classes.table}>
					<thead>
						<SelectIntegrationGroupsPanelTableHeaders />
					</thead>
					<tbody>
						{isLoading && (
							<tr>
								<td colSpan={2}>
									<LoadingSpinner />
								</td>
							</tr>
						)}
						{!isLoading &&
							groupSettingsState &&
							groupItems?.map((item) =>
								groupSettingsState[item.databuilder_id] ? (
									<SelectIntegrationGroupsPanelTableRow
										key={item.databuilder_id}
										item={item}
										groupSettings={groupSettingsState}
										isVisible={isVisible}
										handleVisibilityChange={handleVisibilityChange}
										updateCustomMappingTeams={updateCustomMappingTeams}
										customMappingTeamOptions={integration.teams}
									/>
								) : (
									<></>
								)
							)}
					</tbody>
				</Table>
			</Stack>
		</>
	);
}
