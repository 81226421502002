import { baseQueryHooksFactory } from '../../factories';
import type { IEmbeddedPrompt } from '../../types';
import { aiEmbeddedQueryKeyFactory } from './constants';

const {
	useAIEmbedded,
	useAIEmbeddedInfiniteList,
	useAIEmbeddedList,
	useDeleteAIEmbedded,
	useUpdateAIEmbedded,
} = baseQueryHooksFactory<IEmbeddedPrompt, 'aIEmbedded'>(
	'aIEmbedded',
	aiEmbeddedQueryKeyFactory
);

export {
	useAIEmbedded,
	useAIEmbeddedInfiniteList,
	useAIEmbeddedList,
	useDeleteAIEmbedded,
	useUpdateAIEmbedded,
};
