import type { CSSObject, MantineTheme } from '@mantine/core';
import { createStyles } from '@mantine/core';

export default createStyles(
	(
		theme: MantineTheme,
		{
			borderBottomWidth,
			showLeftControl,
			showRightControl,
		}: {
			borderBottomWidth: number;
			showLeftControl: boolean;
			showRightControl: boolean;
		}
	) => {
		const controlOverlay: CSSObject = {
			top: `calc(${theme.spacing.md} * -1)`,
			position: 'absolute',
			content: '""',
			display: 'block',
			width: `calc(${theme.spacing.lg} * 2)`,
			height: '100%',
		};

		return {
			root: {
				borderBottomWidth,
				borderBottomStyle: 'solid',
				borderBottomColor: theme.colors.gray[3],
			},
			controls: {
				padding: 0,
				height: '100%',
				alignItems: 'flex-start',

				'::before': {
					...controlOverlay,
					left: 0,
					background: showLeftControl
						? 'linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 75%, rgba(255,255,255,0) 100%)'
						: 'transparent',
				},

				'::after': {
					...controlOverlay,
					right: 0,
					background: showRightControl
						? 'linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 25%, rgba(255,255,255,1) 100%)'
						: 'transparent',
					zIndex: -1,
				},
			},
			control: {
				'&:nth-of-type(1)': {
					visibility: showLeftControl ? 'visible' : 'hidden',
				},
				'&:nth-of-type(2)': {
					visibility: showRightControl ? 'visible' : 'hidden',
				},
			},
		};
	}
);
