import {
	Group,
	Modal,
	SimpleGrid,
	Skeleton,
	TextInput,
	Tooltip,
} from '@mantine/core';
import {
	useGetLinearTickets,
	useLinkLinearTicket,
	useRemoveLinearTicket,
} from '@repo/api-codegen';
import { integrationList } from '@repo/common/constants/integration/integrations';
import { IconButton, Text } from '@repo/foundations';
import { useState } from 'react';
import type { LinkTicketModalProps } from './types';

export function LinkTicketModal({
	curIntegration,
	ticketTitles,
	setTicketTitles,
	opened,
	onClose,
}: LinkTicketModalProps) {
	const name = integrationList.find(
		(i) =>
			i.type?.toLowerCase() === curIntegration?.integration_type.toLowerCase()
	)?.name;
	const integrationId = curIntegration?.integration_id ?? '';
	const { mutate: addComment, isLoading: isLinkLoading } =
		useLinkLinearTicket();
	const { mutate: removeLinearTicket, isLoading: isRemoveLoading } =
		useRemoveLinearTicket();
	const [searchTerm, setSearchTerm] = useState('');

	const handleClose = () => {
		setSearchTerm('');
		onClose();
	};
	const handleCreateComment = (identifier: string) => {
		if (!curIntegration || !name) {
			return;
		}
		const failedJobs =
			curIntegration?.latest_k_jobs
				.filter((job) => job.status === 'failed')
				.reverse()[0] ?? null;
		const params = {
			name,
			issue_id: identifier,
			status: curIntegration,
			first_fail: failedJobs,
		};
		addComment({ body: params });
		const prevTickets = { ...ticketTitles };
		prevTickets[integrationId] = [...prevTickets[integrationId], identifier];
		setTicketTitles(prevTickets);
	};

	const handleRemoveLinearTicket = (identifier: string) => {
		if (!curIntegration) {
			return;
		}
		const params = {
			issue_id: identifier,
			integration_id: integrationId,
			tickets: ticketTitles[integrationId],
		};
		removeLinearTicket({ body: params });
		const prevTickets = { ...ticketTitles };
		prevTickets[integrationId] = prevTickets[integrationId].filter(
			(ticket) => ticket !== identifier
		);
		setTicketTitles(prevTickets);
	};

	const { data: tickets, isLoading: isGetLoading } = useGetLinearTickets(
		{ pathParams: { title: `${name} failed` } },
		{
			enabled: !!curIntegration?.integration_name && !!name,
			refetchOnMount: 'always',
		}
	);

	const linkedTickets = ticketTitles[integrationId] || [];

	const isLoading = isGetLoading || isLinkLoading || isRemoveLoading;
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchTerm(e.target.value);
	};
	return (
		<Modal.Root opened={opened} onClose={handleClose}>
			<Modal.Overlay />
			<Modal.Content>
				<Modal.Header>
					<Modal.Title>
						<Text size="md" weight="bold">
							Integration Failure Tickets
						</Text>
					</Modal.Title>
					<Modal.CloseButton />
				</Modal.Header>
				<Modal.Body>
					<Skeleton visible={isLoading}>
						{tickets && tickets.length > 0 ? (
							<SimpleGrid cols={1}>
								<TextInput
									placeholder="Search tickets..."
									onChange={handleChange}
									styles={{
										input: {
											'&:focus': {
												boxShadow: 'none',
											},
										},
									}}
								/>
								{tickets
									.filter(({ title }) =>
										title.toLowerCase().includes(searchTerm.toLowerCase())
									)
									.sort(
										(a, b) =>
											new Date(b.created_at).getTime() -
											new Date(a.created_at).getTime()
									)
									.map(({ identifier, title }) => (
										<Group key={identifier} position="apart">
											<a
												href={`https://linear.app/secoda/issue/${identifier}`}
												target="_blank"
												rel="noopener noreferrer"
												style={{
													wordWrap: 'break-word',
													flex: 1,
													marginRight: '10px',
												}}
											>{`[${identifier}] ${title}`}</a>
											<Group>
												{linkedTickets.includes(identifier) ? (
													<>
														<Text>Linked</Text>
														<Tooltip
															label="Unlink from this ticket"
															withArrow
															withinPortal
														>
															<IconButton
																iconName="unlink"
																onClick={() =>
																	handleRemoveLinearTicket(identifier)
																}
															/>
														</Tooltip>
													</>
												) : (
													<Tooltip
														label="Link to this ticket"
														withArrow
														withinPortal
													>
														<IconButton
															iconName="plus"
															onClick={() => {
																handleCreateComment(identifier);
															}}
														/>
													</Tooltip>
												)}
											</Group>
										</Group>
									))}
							</SimpleGrid>
						) : (
							<Text>
								No integration failure tickets tagged with{' '}
								{curIntegration?.integration_name} failed
							</Text>
						)}
					</Skeleton>
				</Modal.Body>
			</Modal.Content>
		</Modal.Root>
	);
}
