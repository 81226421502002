import { baseQueryHooksFactory } from '../../factories';
import type { AutomationJob } from '../../types/models/automation/automationJob';
import { automationJobQueryKeyFactory } from './constants';

const {
	useAutomationJobList,
	updateAutomationJob,
	fetchAutomationJobList,
	useAutomationJob,
} = baseQueryHooksFactory<AutomationJob, 'automationJob'>(
	'automationJob',
	automationJobQueryKeyFactory
);

export {
	fetchAutomationJobList,
	updateAutomationJob,
	useAutomationJob,
	useAutomationJobList,
};
