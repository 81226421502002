import { Box, Divider, Group, Stack } from '@mantine/core';
import { TextSkeleton, TitleSkeleton } from '@repo/foundations';

interface CollapsableStackSkeletonProps {
	numLines?: number;
}

export function CollapsableStackSkeleton({
	numLines = 3,
}: CollapsableStackSkeletonProps) {
	return (
		<Box mb="xl">
			<Stack px="xl" pb="xl" spacing="md">
				<TitleSkeleton width={120} height={28} />
				<Stack spacing="xs">
					{Array.from({ length: numLines }).map((_, index) => (
						<Group spacing="md" key={index} h={28}>
							<TextSkeleton width={96} height={8} />
							<TextSkeleton width={96} height={8} />
						</Group>
					))}
				</Stack>
			</Stack>
			<Divider />
		</Box>
	);
}
