import type { AuthError } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
// eslint-disable-next-line no-restricted-imports
import { Button, Stack } from '@mantine/core';
import type { IEmbedConfiguration } from 'powerbi-client';
import { factories, models, service } from 'powerbi-client';
import { useEffect, useState } from 'react';
import IntegrationLogo from '../IntegrationLogo';

const powerbi = new service.Service(
	factories.hpmFactory,
	factories.wpmpFactory,
	factories.routerFactory
);

interface PowerBiPreviewProps {
	previewUrl: string;
	previewRef?: React.RefObject<HTMLDivElement | null>;
}

export default function PowerBiPreview({
	previewUrl,
	previewRef,
}: PowerBiPreviewProps) {
	const { instance, accounts, inProgress } = useMsal();
	const [accessToken, setAccessToken] = useState<string>('');
	const [errorMessage, setError] = useState<string[]>([]);
	const reportRef = previewRef ? previewRef : undefined;
	let isDashboard = previewUrl.includes('dashboardEmbed');

	useEffect(() => {
		const authenticate = async () => {
			const isAuthenticated = accounts.length > 0;

			const scopeBase = [
				'https://analysis.windows.net/powerbi/api/Dashboard.Read.All',
				'https://analysis.windows.net/powerbi/api/Report.Read.All',
			];

			const tokenRequest = {
				scopes: scopeBase,
				account: accounts[0],
			};

			if (isAuthenticated && !accessToken) {
				try {
					const response = await instance.acquireTokenSilent(tokenRequest);
					setAccessToken(response.accessToken);
				} catch (error) {
					const authError = error as AuthError;
					if (
						authError.errorCode === 'consent_required' ||
						authError.errorCode === 'interaction_required' ||
						authError.errorCode === 'login_required'
					) {
						instance.acquireTokenPopup(tokenRequest);
					} else {
						setError([
							'Problem fetching access token: ' + authError.toString(),
						]);
					}
				}
			}
		};

		authenticate();
	}, [instance, accounts, inProgress, accessToken, isDashboard]);

	useEffect(() => {
		if (accessToken && previewUrl && reportRef?.current) {
			const urlPrefix = isDashboard
				? 'https://app.powerbi.com/dashboardEmbed?dashboardId='
				: 'https://app.powerbi.com/reportEmbed?reportId=';
			const entityId = previewUrl.substring(
				urlPrefix.length,
				urlPrefix.length + 36 // GUID length is 36 chars
			);

			const embedConfiguration: IEmbedConfiguration = {
				type: isDashboard ? 'dashboard' : 'report',
				tokenType: models.TokenType.Aad,
				accessToken,
				embedUrl: previewUrl,
				id: entityId,
				pageView: 'fitToWidth',
			};

			powerbi.embed(reportRef.current, embedConfiguration);
		}
	}, [accessToken, isDashboard, previewUrl, reportRef]);

	const loginScopeBase = [
		'https://analysis.windows.net/powerbi/api/Dashboard.Read.All',
		'https://analysis.windows.net/powerbi/api/Report.Read.All',
	];

	const loginRequest = {
		scopes: loginScopeBase,
		account: accounts[0],
	};

	if (useIsAuthenticated() && previewUrl) {
		return <div />;
	} else {
		return (
			<Stack
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					height: '100%',
				}}
			>
				<IntegrationLogo integrationType={'powerbi'} size={60} />
				<Button
					variant="primary"
					onClick={() => instance.loginPopup(loginRequest)}
					size={'xl'}
				>
					Sign in
				</Button>
			</Stack>
		);
	}
}
