import { createContext, useContext, useMemo } from 'react';
import { AutomationActionCardFilterStore } from './store';

const AutomationActionCardFilterStoreContext =
	createContext<AutomationActionCardFilterStore | null>(null);

export interface AutomationActionCardFilterStoreProviderProps {
	children: React.ReactNode;
}

export function AutomationActionCardFilterStoreProvider({
	children,
}: AutomationActionCardFilterStoreProviderProps) {
	const store = useMemo(() => new AutomationActionCardFilterStore(), []);

	return (
		<AutomationActionCardFilterStoreContext.Provider value={store}>
			{children}
		</AutomationActionCardFilterStoreContext.Provider>
	);
}

export function useAutomationActionCardFilterStore() {
	const ctx = useContext(AutomationActionCardFilterStoreContext);
	if (ctx === null) {
		throw new Error(
			'useFormContext was called outside of FormProvider context for AutomationActionCardFilterStore'
		);
	}

	return ctx;
}
