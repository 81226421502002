import { useMutation, useQueryClient } from '@tanstack/react-query';
import { apiClient, getEndpoints } from '../../common';
import type { ITunnelConnectionStatus } from '../../types';
import { tunnelsQueryKeyFactory } from './index';

export const useTestTunnelConnection = () => {
	const queryClient = useQueryClient();

	return useMutation(
		async (tunnelId: string): Promise<ITunnelConnectionStatus> => {
			const url = getEndpoints(tunnelsQueryKeyFactory.namespace).byPath([
				tunnelId,
				'test_connection',
			]);

			const { data } = await apiClient.post<ITunnelConnectionStatus>(url);

			return data;
		},
		{
			onSuccess: () => {
				// Optionally, invalidate and refetch any relevant data.
				queryClient.invalidateQueries(tunnelsQueryKeyFactory.list());
			},
		}
	);
};
