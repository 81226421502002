/* eslint-disable react/display-name */
import { apiQueryKey } from '@repo/api-codegen';
import { EntityType } from '@repo/common/enums/entityType';
import { memo, useCallback, useState } from 'react';
import {
	queryClient,
	useAuthUser,
	useSecodaEntity,
	useUpdateSecodaEntity,
} from '../../api';
import { getEntityPageSidebarProps } from '../../components/EntityDrawer/utils';
import EntityLogo from '../../components/EntityLogo/EntityLogo';
import EntityPageLayout from '../../components/EntityPageLayout';
import { trackEvent } from '../../utils/analytics';
import { useParamsIdSuffixUuid } from '../../utils/hook/utils';
import type { DjangoValueType } from '../TemplatePage/types';
import TableEntityTabs from './TableEntityTabs';
import { TableEntityTabsStore } from './TableEntityTabs/TableEntityTabsStore';

const InnerTableEntityPage = memo(
	({ id, store }: { id: string; store: TableEntityTabsStore }) => {
		const { user, workspace } = useAuthUser();

		const { data: tableEntity } = useSecodaEntity({
			id,
			options: {
				useErrorBoundary: true,
				suspense: true,
				onSettled: () => {
					trackEvent('table_entity/open', {}, user, workspace);
				},
			},
		});

		const { mutateAsync } = useUpdateSecodaEntity({
			options: {
				onSuccess: () => queryClient.invalidateQueries(apiQueryKey('quality')),
			},
		});

		const updateTableEntity = useCallback(
			async (key: string, value: DjangoValueType, saveRemotely = true) => {
				if (saveRemotely) {
					mutateAsync({
						data: {
							id: tableEntity!.id,
							[key]: value,
						},
					});
					trackEvent('table/properties/update', {}, user, workspace!);
				}
			},
			[mutateAsync, tableEntity, user, workspace]
		);

		if (!tableEntity) {
			return null;
		}

		return (
			<EntityPageLayout
				key={tableEntity.id}
				entityTabsStore={store}
				name="Table"
				isReadOnlyTitle
				entity={tableEntity}
				icon={<EntityLogo entity={tableEntity} size={32} />}
				updateEntity={updateTableEntity}
				withDescription
				withEntityByteSize
				withEntityRowCount
				{...getEntityPageSidebarProps(EntityType.table)}
			>
				<TableEntityTabs
					key={tableEntity.id}
					table={tableEntity}
					tableEntityTabsStore={store}
				/>
			</EntityPageLayout>
		);
	}
);

// by memoizing the inner component, we can avoid re-rendering the entire page on tab changes and only re-render the tab panel
export default function TableEntityPage() {
	const [tableEntityTabsStore] = useState(new TableEntityTabsStore());
	const id = useParamsIdSuffixUuid();

	return <InnerTableEntityPage id={id} store={tableEntityTabsStore} />;
}
