import type { MarkdownSerializerState } from '@repo/secoda-editor/lib/markdown/serializer';
import { wrappingInputRule } from 'prosemirror-inputrules';
import type { ParseSpec } from 'prosemirror-markdown';
import type { NodeSpec, Node as ProsemirrorNode } from 'prosemirror-model';
import toggleList from '../commands/toggleList';
import type { NodeOptions } from './Node';
import Node from './Node';

export default class CheckboxList extends Node {
	get name() {
		return 'checkbox_list';
	}

	get schema(): NodeSpec {
		return {
			content: 'checkbox_item+',
			group: 'block',
			parseDOM: [
				{
					tag: `[class="${this.name}"]`,
				},
			],
			toDOM: () => ['ul', { class: this.name }, 0],
		};
	}

	keys({ type, schema }: NodeOptions) {
		return {
			'Shift-Ctrl-7': toggleList(type, schema.nodes.checkbox_item),
		};
	}

	commands({ type, schema }: NodeOptions) {
		return () => toggleList(type, schema.nodes.checkbox_item);
	}

	inputRules({ type }: NodeOptions) {
		return [wrappingInputRule(/^-?\s*(\[ \])\s$/i, type)];
	}

	toMarkdown(state: MarkdownSerializerState, node: ProsemirrorNode) {
		state.renderList(node, '  ', () => '- ');
	}

	parseMarkdown(): ParseSpec {
		return { block: 'checkbox_list' };
	}
}
