import { cloneDeep } from 'lodash-es';
import { BaseModel } from './base';

export class SSHTunnel extends BaseModel {
	// @ts-expect-error TS(2564): Property 'query' has no initializer and is not def... Remove this comment to see the full error message
	query: string;

	// @ts-expect-error TS(2564): Property 'username' has no initializer and is not ... Remove this comment to see the full error message
	username: string;

	// @ts-expect-error TS(2564): Property 'host' has no initializer and is not defi... Remove this comment to see the full error message
	host: string;

	// @ts-expect-error TS(2564): Property 'port' has no initializer and is not defi... Remove this comment to see the full error message
	port: string;

	constructor(obj: SSHTunnel) {
		super(obj);
		Object.keys(obj).forEach((key) => {
			(this as any)[key] = cloneDeep((obj as any)[key]);
		});
	}

	static get fields() {
		return BaseModel.fields.concat([
			'username',
			'host',
			'port',
			'private_key',
			'workspace_id',
		]);
	}

	static get namespace() {
		return ['tunnel', 'tunnels'];
	}
}
