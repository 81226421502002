import { Container, Group, createStyles } from '@mantine/core';
import type { SelectablePropertyItem } from '@repo/common/components/SingleSelector/types';
import { capitalize, isNil } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import type { Automation } from '../../../../../api';
import { AutomationPropagateMetadataField } from '../../../../../api/types/models/automation/automationPropagateMetadata';
import { useAutomationStore } from '../../../../../pages/AutomationPage/context';
import { isValidEnumValue } from '../../../../../utils/enumUtils';
import AutomationCardButton from '../../AutomationCardButton';
import AutomationCardDeleteButton from '../../AutomationCardDeleteButton';
import { useAutomationActionCardPropagateMetadataStore } from './context';

const useStyles = createStyles((theme) => ({
	group: {
		display: 'flex',
		gap: theme.spacing['2xs'],
	},
	container: {
		display: 'flex',
		justifyContent: 'space-between',
		gap: theme.spacing['0.5'],
		margin: 0,
		padding: 0,
		flex: '1 0 0',
		width: '100%',
		maxWidth: 'none',
	},
}));

interface AutomationActionCardPropagateMetadataFilterRowProps {
	value?: AutomationPropagateMetadataField;
	id: string;
	handleAutomationUpdate: (automation: Partial<Automation>) => Promise<void>;
	buttonOptions: SelectablePropertyItem[];
}

function AutomationActionCardPropagateMetadataFilterRow({
	value,
	id,
	handleAutomationUpdate,
	buttonOptions,
}: AutomationActionCardPropagateMetadataFilterRowProps) {
	const { classes } = useStyles();
	const store = useAutomationActionCardPropagateMetadataStore();
	const { automation } = useAutomationStore();
	const label = value ? capitalize(value) : 'Property';

	const handleFieldUpdate = async () => {
		// need to typecast using as here because TS isn't smart enough
		// to tell that filtering by !isNil removes nulls and undefined
		// therefore a type error occurs below
		const fieldValues = store.fields
			.map((field) => field.val)
			.filter((field) => !isNil(field)) as AutomationPropagateMetadataField[];

		await handleAutomationUpdate?.({
			action_propagate_metadata: {
				...automation?.action_propagate_metadata,
				fields: fieldValues,
			},
		});
	};

	const handleValueChange = (val: string | boolean) => {
		if (
			typeof val === 'string' &&
			isValidEnumValue(AutomationPropagateMetadataField, val)
		) {
			store.setField(id, val);

			handleFieldUpdate();
		} else {
			throw new Error('Invalid value was provided for propagate metadata');
		}
	};

	const handleDeleteAction = () => {
		store.deleteField(id);

		handleFieldUpdate();
	};

	return (
		<Container className={classes.container}>
			<Group className={classes.group}>
				<AutomationCardButton
					addFilter
					buttonLabel={label}
					buttonOptions={buttonOptions}
					placeholder="Property"
					onClick={handleValueChange}
				/>
			</Group>
			<AutomationCardDeleteButton handleDelete={handleDeleteAction} />
		</Container>
	);
}

export default observer(AutomationActionCardPropagateMetadataFilterRow);
