import { isNil } from 'lodash-es';

const getIdFromURL = (url: string): string | undefined | null => {
	const uuidRegex =
		/([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/;
	const match = url.match(uuidRegex);

	if (isNil(match?.[1])) {
		return null;
	}

	return match?.[1];
};

export { getIdFromURL };
