import { Tabs } from '@mantine/core';
import TabsList from '@repo/common/components/TabsList';
import { useState } from 'react';
import type { IIntegration } from '../../../../../api';
import type { IntegrationSpec } from '../../../../../interfaces/IntegrationSpec';
import { ScheduleInput } from './ScheduleInput';

interface SchedulePanelProps {
	integration: IIntegration;
	spec: IntegrationSpec;
}

export function SchedulePanel({ integration, spec }: SchedulePanelProps) {
	const [activeTab, setActiveTab] = useState<string | null>('PULL');

	if (spec.value.supportPushMetadata) {
		return (
			<Tabs
				color="dark"
				defaultValue="pull"
				value={activeTab}
				onTabChange={setActiveTab}
			>
				<TabsList
					tabs={[
						{ value: 'PULL', label: 'Pull' },
						{ value: 'PUSH', label: 'Push' },
					]}
				/>

				<Tabs.Panel value="PULL" pt="2xl">
					<ScheduleInput integration={integration} type="PULL" />
				</Tabs.Panel>

				<Tabs.Panel value="PUSH" pt="2xl">
					<ScheduleInput integration={integration} type="PUSH" />
				</Tabs.Panel>
			</Tabs>
		);
	}

	return <ScheduleInput integration={integration} type="PULL" />;
}
