import type { Filter } from '@repo/api-codegen';
import {
	DEFAULT_FILTER_OPTIONS,
	DEFAULT_FILTER_OPTIONS_WITH_DQS,
} from '@repo/common/components/Filter/constants';
import { reaction } from 'mobx';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDataQualityAccess } from '../../../api';
import type { FilterOptionType } from '../../Filter';
import { getFilterValueFromApiCatalogFilter } from '../../Filter/utils';
import { GovernanceFilterStore } from './store';
import type { ApiCatalogFilterWithType, GovernanceFilter } from './types';

export interface UseGovernanceFiltersProps {
	includedFilters?: Filter[];
	excludedFilters?: Filter[];
	onIncludedFiltersChange?: (included: Filter[]) => Promise<void>;
	onExcludedFiltersChange?: (excluded: Filter[]) => Promise<void>;
	filterOptions?: FilterOptionType[];
}

export function useGovernanceFilters({
	includedFilters,
	excludedFilters,
	onIncludedFiltersChange,
	onExcludedFiltersChange,
	filterOptions,
}: UseGovernanceFiltersProps) {
	const dqsEnabled = useDataQualityAccess();

	const filters = useMemo(() => {
		const included: GovernanceFilter[] = (includedFilters ?? []).map(
			(filter) => ({
				filterValues: getFilterValueFromApiCatalogFilter(filter),
				type: 'included',
			})
		);

		const excluded: GovernanceFilter[] = (excludedFilters ?? []).map(
			(filter) => ({
				filterValues: getFilterValueFromApiCatalogFilter(filter),
				type: 'excluded',
			})
		);

		return [...included, ...excluded];
	}, [includedFilters, excludedFilters]);

	const handleFilterUpdate = useCallback(
		(filtersToSave: ApiCatalogFilterWithType[]) => {
			const includedFiltersToSave = filtersToSave.filter(
				(f) => f.type === 'included'
			);
			onIncludedFiltersChange?.(includedFiltersToSave);

			const excludedFiltersToSave = filtersToSave.filter(
				(f) => f.type === 'excluded'
			);
			onExcludedFiltersChange?.(excludedFiltersToSave);
		},
		[onExcludedFiltersChange, onIncludedFiltersChange]
	);

	const storeFilterOptions = useMemo(() => {
		if (filterOptions) {
			return filterOptions;
		}
		if (dqsEnabled) {
			return DEFAULT_FILTER_OPTIONS_WITH_DQS;
		}
		return DEFAULT_FILTER_OPTIONS;
	}, [filterOptions, dqsEnabled]);

	const [store] = useState(
		new GovernanceFilterStore(storeFilterOptions, filters)
	);

	useEffect(
		() =>
			reaction(
				() => store.catalogFilters,
				() => {
					handleFilterUpdate(store.catalogFilters);
				}
			),
		[handleFilterUpdate, store]
	);

	return store;
}
