/**
 * Converts an ArrayBuffer to a string.
 * @param {ArrayBuffer} buffer - The ArrayBuffer to convert.
 * @returns {string} - The string representation of the ArrayBuffer.
 */
export function arrayBufferToString(buffer: ArrayBuffer): string {
	const decoder = new TextDecoder('utf-8');
	return decoder.decode(buffer);
}

/**
 * Reads a file input event as an ArrayBuffer.
 * @param {File} file - The File object representing the input file.
 * @returns {Promise<ArrayBuffer>} - A promise that resolves with the ArrayBuffer.
 */
export async function readFileInputEventAsArrayBuffer(
	file: File
): Promise<ArrayBuffer> {
	return new Promise((resolve) => {
		const reader = new FileReader();

		reader.onload = (loadEvent: ProgressEvent<FileReader>) => {
			const arrayBuffer = loadEvent.target?.result as ArrayBuffer;
			resolve(arrayBuffer);
		};

		reader.readAsArrayBuffer(file);
	});
}

/**
 * Reads a file input event as text.
 * @param {File} file - The File object representing the input file.
 * @returns {Promise<string>} - A promise that resolves with the text content of the file.
 */
export async function readFileInputEventAsText(file: File): Promise<string> {
	return file.text();
}

/**
 * Unescapes a string.
 */
export function unescape(text: string) {
	return text
		.replace(/\\([\\`*{}[\]()#+\-.!_>])/g, '$1')
		.replace(/\n\\\n/g, '\n\n');
}
