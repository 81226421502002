import { Box, Center, Flex, Group, Stack, createStyles } from '@mantine/core';
import type { DataQualityScore, Filter } from '@repo/api-codegen';
import {
	useRetrieveAggregateQuality,
	useRetrieveQualitySuggestions,
	useRetrieveTimeseries,
} from '@repo/api-codegen';
import { Text } from '@repo/foundations';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { DataQualityBenchmarkTooltip } from '../../../../components/DataQualityScore/DataQualityBenchmarkTooltip/DataQualityBenchmarkTooltip';
import { DataQualityBreakdown } from '../../../../components/DataQualityScore/DataQualityBreakdown/DataQualityBreakdown';
import { QualityScoreRing } from '../../../../components/DataQualityScore/QualityScoreRing/QualityScoreRing';

import { HoverCard } from '@repo/common/components/HoverCard';
import { useAuthUser } from '../../../../api';
import { useFeatureAccess } from '../../../../api/hooks/workspace/useFeatureAccess';
import { DATA_QUALITY_MAX_SCORE } from '../../../../components/DataQualityScore/constants';
import { DataQualityBreakdownLoading } from '../../../../components/DataQualityScore/DataQualityBreakdown';
import { DataQualityTimeseriesChart } from '../../../../components/DataQualityScore/DataQualityTimeseriesChart/DataQualityTimeseriesChart';
import { DataQualityTimeseriesChartLoading } from '../../../../components/DataQualityScore/DataQualityTimeseriesChart/DataQualityTimeseriesChartLoading';
import { QualityScoreRingLoading } from '../../../../components/DataQualityScore/QualityScoreRing';
import type { QualityFilterKey } from '../../../../components/DataQualityScore/types';
import { parseDataQualitySuggestions } from '../../../../components/DataQualityScore/utils';
import { EmptyState } from '../../../../components/EmptyState';
import { IntegrationSelector } from '../../../../components/EntityModal/Metadata/IntegrationSelector';
import { appShellStore } from '../../../../components/SecodaAppShell/store';
import { UpgradeButton } from '../../../../components/Settings/UpgradeButton';
import { trackEvent } from '../../../../utils/analytics';
import { DataQualityFilterMenu } from '../../components/FilterMenu/DataQualityFilterMenu';
import { TeamAndGroupSelector } from '../../components/TeamAndGroupSelector/TeamAndGroupSelector';

const useStyles = createStyles((theme) => ({
	tooltipTarget: {
		borderBottom: `dotted 1px ${theme.other.getColor('border/secondary/default')}`,
	},
	ringCard: {
		display: 'flex',
		flexDirection: 'column',
		border: `solid 1px ${theme.other.getColor('border/secondary/default')}`,
		borderRadius: theme.radius.md,
	},
	cardTitle: {
		paddingTop: theme.spacing.md,
		paddingLeft: theme.spacing.md,
		paddingRight: theme.spacing.md,
		paddingBottom: 0,
	},
	ring: {
		height: 280,
	},
	timeseriesCard: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		border: `solid 1px ${theme.other.getColor('border/secondary/default')}`,
		borderRadius: theme.radius.md,
	},
	timeseries: {
		padding: `${theme.spacing.md} ${theme.spacing.md}`,
	},
	breakdownCard: {
		display: 'flex',
		flexDirection: 'column',
		flex: 1,
		border: `solid 1px ${theme.other.getColor('border/secondary/default')}`,
		borderRadius: theme.radius.md,
	},
	breakdownTitle: {
		borderBottom: 0,
		paddingBottom: theme.spacing.md,
	},
}));

export function DataQualityReport() {
	const { classes, cx } = useStyles();
	const { user, workspace } = useAuthUser();

	const [teamId, setTeamId] = useState<string>('');
	const [groupId, setGroupId] = useState<string>('');
	const [integrationId, setIntegrationId] = useState<string>('');
	const { dqsAccess } = useFeatureAccess();

	useEffect(() => {
		trackEvent('dqs/report/open', {}, user, workspace);
	}, []);

	const [dataQualityKey, setDataQualityKey] =
		useState<QualityFilterKey>('total');

	const setQualityForDownload = (quality: DataQualityScore) => {
		appShellStore.navBarUI.analyticsPage.downloadDataQualityScore.dataQualityScore =
			quality;
		return quality;
	};

	const { data: quality, isLoading } = useRetrieveAggregateQuality(
		{
			queryParams: {
				team_id: teamId,
				filters: JSON.stringify({
					operator: 'and',
					operands: [
						...(integrationId
							? [
									{
										operator: 'exact',
										field: 'integration_id',
										value: integrationId,
										operands: [],
									},
								]
							: []),
						...(groupId
							? [
									{
										operator: 'exact',
										field: 'owner_groups',
										value: groupId,
										operands: [],
									},
								]
							: []),
					],
				} as Filter),
			},
		},
		{
			select: setQualityForDownload,
		}
	);

	const { data: timeseries, isLoading: isLoadingTimeseries } =
		useRetrieveTimeseries({
			queryParams: {
				team_id: teamId,
				integration_id: integrationId,
				group_id: groupId,
				from_date: dayjs()
					.utc()
					.subtract(30, 'days')
					.format('YYYY-MM-DD HH:mm:ss'),
				to_date: dayjs().utc().format('YYYY-MM-DD HH:mm:ss'),
			},
		});

	const { data: suggestions, isLoading: isLoadingSuggestions } =
		useRetrieveQualitySuggestions(
			{
				queryParams: {
					team_id: teamId,
					filters: JSON.stringify({
						operator: 'and',
						operands: [
							...(integrationId
								? [
										{
											operator: 'exact',
											field: 'integration_id',
											value: integrationId,
											operands: [],
										},
									]
								: []),
							...(groupId
								? [
										{
											operator: 'exact',
											field: 'owner_groups',
											value: groupId,
											operands: [],
										},
									]
								: []),
						],
					} as Filter),
				},
			},
			{
				enabled: !!quality,
				select: (response) =>
					parseDataQualitySuggestions(quality!, response, 'resource'),
			}
		);

	if (!dqsAccess) {
		return (
			<EmptyState
				title="Upgrade to access Quality Score"
				description="You can use Quality Score to understand and improve your data quality."
				illustrationName="upgrade"
				includeGoBack={false}
				stateHeight="50vh"
				size="lg"
				withActions={<UpgradeButton feature="DQS" size="md" />}
			/>
		);
	}

	if (!workspace.quality_enabled) {
		return (
			<EmptyState
				illustrationName="upgrade"
				title="Quality Score is disabled"
				description="Quality Score is not enabled for this workspace. Please contact your administrator to turn on access."
				includeGoBack={false}
				stateHeight="80vh"
				size="lg"
			/>
		);
	}

	return (
		<Center>
			<Stack spacing="md" w="100%" maw={848} pb="md">
				<Group spacing="xs">
					<DataQualityFilterMenu
						dataQualityKey={dataQualityKey}
						setDataQualityKey={setDataQualityKey}
					/>
					<TeamAndGroupSelector
						onGroupSelected={setGroupId}
						onTeamSelected={setTeamId}
					/>
					<IntegrationSelector
						onChange={setIntegrationId}
						initialValue={integrationId}
						allowEmptyOption
					/>
				</Group>
				<Group spacing="md" noWrap>
					<Box w={320}>
						<Stack spacing={0} className={classes.ringCard}>
							<Group spacing={0} className={classes.cardTitle}>
								<HoverCard
									target={
										<Text size="sm" fw={600} className={classes.tooltipTarget}>
											Quality score
										</Text>
									}
								>
									<DataQualityBenchmarkTooltip />
								</HoverCard>
							</Group>
							<Group align="center" position="center" className={classes.ring}>
								{isLoading && <QualityScoreRingLoading size="lg" />}
								{!isLoading && quality && (
									<QualityScoreRing
										score={
											(quality[dataQualityKey] /
												DATA_QUALITY_MAX_SCORE[dataQualityKey]) *
											100
										}
										size="lg"
									/>
								)}
							</Group>
						</Stack>
					</Box>
					<Flex style={{ flex: 1 }}>
						<Stack spacing={0} className={classes.timeseriesCard}>
							<Group spacing={0} className={cx(classes.cardTitle)}>
								<Text size="sm" fw={600}>
									Quality score over time
								</Text>
							</Group>
							<Box className={classes.timeseries}>
								{isLoadingTimeseries && (
									<DataQualityTimeseriesChartLoading
										width="100%"
										height={249}
									/>
								)}
								{!isLoadingTimeseries && timeseries && (
									<DataQualityTimeseriesChart
										width="100%"
										height={249}
										timeseries={timeseries}
										liveValue={quality}
										dataQualityKey={dataQualityKey}
									/>
								)}
							</Box>
						</Stack>
					</Flex>
				</Group>
				<Stack spacing={0} className={classes.breakdownCard}>
					<Group
						spacing={0}
						className={cx(classes.cardTitle, classes.breakdownTitle)}
					>
						<Stack spacing={0}>
							<Text size="sm" fw={600}>
								Suggestions
							</Text>
							<Text size="sm" color="text/secondary/default">
								Use these suggestions to improve the quality score of your
								workspace.
							</Text>
						</Stack>
					</Group>
					{isLoadingSuggestions && <DataQualityBreakdownLoading />}
					{suggestions && (
						<DataQualityBreakdown items={suggestions} onReportPage />
					)}
				</Stack>
			</Stack>
		</Center>
	);
}
