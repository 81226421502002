import { observer } from 'mobx-react-lite';

import type { AutomationActionType } from '../../../../../api';
import AutomationCardButton from '../../AutomationCardButton';
import { getActionTypeDisplayValue } from '../utils';
import { AUTOMATION_ACTION_TYPE_OPTIONS } from './constants';

interface AutomationActionCardEditTypeButtonProps {
	type?: AutomationActionType;
	handleActionTypeChange: (type: string | boolean) => void;
}

function AutomationActionCardEditTypeButton({
	type,
	handleActionTypeChange,
}: AutomationActionCardEditTypeButtonProps) {
	const label = type ? getActionTypeDisplayValue(type) : 'Action';

	return (
		<AutomationCardButton
			addFilter
			buttonLabel={label}
			buttonOptions={AUTOMATION_ACTION_TYPE_OPTIONS}
			onClick={handleActionTypeChange}
			defaultOpened={!type}
			property="action"
		/>
	);
}

export default observer(AutomationActionCardEditTypeButton);
