import type { QueryKey, UseMutationOptions } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import { apiClient, getEndpoints } from '../../common';
import { bulkGenerateAiMetadataQueryKeyFactory } from './constants';

interface IBulkGenerateAiMetadataResponse {
	jobId: string;
}

interface IBulkGenerateAiMetadataRequest {
	field: 'description' | 'definition';
	entity_ids: string[];
	bulk_generate?: boolean;
}

interface RequestData {
	data: IBulkGenerateAiMetadataRequest;
	signal?: AbortSignal;
}
interface IuseBulkGenerateAiMetadataArgs {
	invalidationKeys?: QueryKey[];
	options?: UseMutationOptions<
		IBulkGenerateAiMetadataResponse,
		unknown,
		RequestData,
		unknown
	>;
}

async function createbulkGenerateAiMetadataJob(
	data: IBulkGenerateAiMetadataRequest,
	signal?: AbortSignal
) {
	const url = getEndpoints(
		bulkGenerateAiMetadataQueryKeyFactory.namespace
	).root();

	const body = {
		...data,
		bulk_generate: data?.bulk_generate ?? false,
	};

	const response = await apiClient.post<{ job_id: string }>(url, body, {
		signal,
	});

	const { job_id: jobId } = response.data;

	return { jobId };
}

export function useBulkGenerateAiMetadata({
	options = {},
}: IuseBulkGenerateAiMetadataArgs = {}) {
	const mutationFn = ({ data, signal }: RequestData) =>
		createbulkGenerateAiMetadataJob(data, signal);

	return useMutation({
		mutationFn,
		...options,
	});
}
