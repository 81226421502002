import type { GroupItem } from '@repo/api-codegen';
import { sortBy } from 'lodash-es';

export function filterGroupItems(
	groupItems: GroupItem[],
	searchTerm: string
): GroupItem[] {
	const lowercasedSearchTerm = searchTerm.toLowerCase();
	const filteredItems = groupItems
		.filter((groupItem) => {
			const includesSearchTerm = groupItem.title
				.toLowerCase()
				.includes(lowercasedSearchTerm);

			if (includesSearchTerm) return true;

			if (!groupItem.children || groupItem.children.length === 0)
				return includesSearchTerm;

			const children = filterGroupItems(groupItem.children, searchTerm);
			return children.length > 0;
		})
		.map((groupItem) => {
			const includesSearchTerm = groupItem.title
				.toLowerCase()
				.includes(lowercasedSearchTerm);

			return includesSearchTerm
				? groupItem
				: {
						...groupItem,
						children: filterGroupItems(groupItem.children, searchTerm),
					};
		});

	return sortBy(filteredItems, ['native_type', 'title']);
}

export function getAllDescendants(groupItem: GroupItem): GroupItem[] {
	return [groupItem, ...(groupItem.children || []).flatMap(getAllDescendants)];
}
