import { Box, Flex, Stack, UnstyledButton, createStyles } from '@mantine/core';
import { Button, IconButton, Text } from '@repo/foundations';
import {
	IconFileAnalytics,
	IconFolders,
	IconPlugConnected,
	IconSearch,
	IconSparkles,
} from '@tabler/icons-react';
import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuthUser } from '../../../../api/hooks';
import { ProgressIndicator } from '../../../../components/ProgressIndicator';
import { appShellStore } from '../../../../components/SecodaAppShell/store';
import { useUserOnboarding } from '../../../../hooks/useUserOnboarding';
import { trackEvent } from '../../../../utils/analytics';

const useOnboardingActionStyles = createStyles((theme) => ({
	container: {
		padding: theme.spacing.sm,
		gap: theme.spacing.sm,
		display: 'flex',
		alignItems: 'center',
		borderWidth: '1px',
		borderStyle: 'solid',
		borderRadius: theme.other.space[3],
		cursor: 'pointer',
		borderColor: theme.other.getColor('border/primary/default'),
		backgroundColor: theme.other.getColor('surface/primary/default'),
		':hover': {
			backgroundColor: theme.other.getColor('surface/primary/hover'),
		},
		':active': {
			backgroundColor: theme.other.getColor('surface/primary/active'),
		},
	},
	selected: {
		borderWidth: '1.25px',
		borderStyle: 'solid',
		borderColor: theme.other.getColor('border/emphasis-secondary/default'),
		backgroundColor: theme.other.getColor('surface/emphasis/default'),
		':hover': {
			backgroundColor: theme.other.getColor('surface/emphasis/hover'),
		},
		':active': {
			backgroundColor: theme.other.getColor('surface/emphasis/active'),
		},
	},
}));

interface OnboardingActionProps {
	isSelected: boolean;
	isCompleted: boolean;
	cta: string;
	onClick: () => void;
}

function OnboardingAction({
	cta,
	isSelected,
	isCompleted,
	onClick,
}: OnboardingActionProps) {
	const { classes, cx } = useOnboardingActionStyles();
	return (
		<UnstyledButton
			onClick={onClick}
			unstyled
			className={
				isSelected ? cx(classes.container, classes.selected) : classes.container
			}
		>
			<ProgressIndicator value={isCompleted ? 100 : 0} variant="primary" />
			<Text size="xs" weight="bold">
				{cta}
			</Text>
		</UnstyledButton>
	);
}

const useOnboardingPanelStyles = createStyles((theme) => ({
	container: {
		width: '100%',
		paddingTop: theme.spacing.lg,
		paddingLeft: theme.spacing.lg,
		paddingRight: theme.spacing.lg,
		gap: theme.spacing.sm,
		overflow: 'hidden',
		display: 'flex',
		alignItems: 'start',
		borderWidth: '1px',
		borderStyle: 'solid',
		borderRadius: theme.other.space[3],
		borderColor: theme.other.getColor('border/primary/default'),
		backgroundColor: theme.other.getColor('surface/primary/default'),
	},
	decorContainer: {
		position: 'relative',
	},

	blueContainer: {
		position: 'absolute',
		top: 0,
		width: theme.other.space[120],
		height: theme.other.space[24],
		overflow: 'hidden',
		background: theme.colors.blue[0],
		border: '2px solid rgba(174, 215, 255, 0.50)',
		borderRadius: `${theme.other.space[4]}px 0 0 0`,
	},
	blueSearchBar: {
		background: 'rgba(255, 255, 255, 0.80)',
		display: 'flex',
		position: 'relative',
		top: '17px',
		left: '19px',
		alignItems: 'center',
		justifyContent: 'space-between',
		borderRadius: theme.other.space[6],
		height: theme.other.space[7],
		width: theme.other.space[100],
	},
	blueSearch: {
		marginLeft: theme.other.space[2],
		display: 'flex',
		justifyContent: 'center',
		backgroundColor: theme.other.getColor('surface/primary/default'),
	},
	blueSearchIcon: {
		color: theme.colors.blue[6],
	},
	blueSlash: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		fontSize: theme.other.typography.text.xs,
		height: 18,
		width: 13,
		background: theme.colors.blue[0],
		color: theme.colors.blue[4],
		borderRadius: theme.other.space[1],
		padding: `${theme.other.space[0.25]} ${theme.other.space[1]}`,
		marginRight: theme.other.space[2],
	},
	blueBox1: {
		background: 'rgba(255, 255, 255, 0.60)',
		borderRadius: theme.other.space[6],
		height: theme.other.space[5],
		width: '113px',
		position: 'absolute',
		top: '55px',
		left: '19px',
	},
	blueBox2: {
		background: 'rgba(255, 255, 255, 0.60)',
		borderRadius: theme.other.space[6],
		height: theme.other.space[5],
		width: '113px',
		position: 'absolute',
		top: '55px',
		left: '141px',
	},
	blueBox3: {
		background: 'rgba(255, 255, 255, 0.60)',
		borderRadius: theme.other.space[6],
		height: theme.other.space[5],
		width: '32px',
		position: 'absolute',
		top: '55px',
		left: '263px',
	},

	pinkContainer: {
		position: 'absolute',
		top: 0,
		width: theme.other.space[120],
		height: theme.other.space[24],
		overflow: 'hidden',
		background: 'rgba(249, 232, 255, 0.75)',
		border: '2px solid rgba(246, 190, 231, 0.75)',
		borderRadius: `${theme.other.space[4]}px 0 0 0`,
	},
	pinkSparkleBar: {
		background: 'rgba(255, 255, 255, 0.80)',
		display: 'flex',
		position: 'relative',
		top: '17px',
		left: '19px',
		alignItems: 'center',
		justifyContent: 'space-between',
		borderRadius: theme.other.space[6],
		height: theme.other.space[7],
		width: '420px',
	},
	pinkSparkleIcon: {
		marginLeft: theme.other.space[2],
		color: theme.colors.grape[4],
	},
	pinkBox1: {
		background: 'rgba(255, 255, 255, 0.60)',
		borderRadius: theme.other.space[6],
		height: theme.other.space[5],
		width: '420px',
		position: 'absolute',
		top: '55px',
		left: '19px',
	},
	pinkBox2: {
		background: 'rgba(255, 255, 255, 0.60)',
		borderRadius: theme.other.space[6],
		height: theme.other.space[5],
		width: '420px',
		position: 'absolute',
		top: '79px',
		left: '19px',
	},

	orangeContainer: {
		position: 'absolute',
		top: 0,
		width: theme.other.space[120],
		height: theme.other.space[24],
		overflow: 'hidden',
		background: 'rgba(255, 231, 226, 0.75)',
		border: '2px solid rgba(255, 190, 181, 0.75)',
		borderRadius: `${theme.other.space[4]}px 0 0 0`,
	},
	orangeBox1: {
		background: 'rgba(255, 255, 255, 0.80)',
		borderRadius: theme.other.space[3],
		height: theme.other.space[24],
		width: '120px',
		position: 'absolute',
		top: '20px',
		left: '19px',
	},
	orangeBox2: {
		background: 'rgba(255, 255, 255, 0.80)',
		borderRadius: theme.other.space[3],
		height: theme.other.space[24],
		width: '120px',
		position: 'absolute',
		top: '20px',
		left: '157px',
	},
	orangeBox3: {
		background: 'rgba(255, 255, 255, 0.80)',
		borderRadius: theme.other.space[3],
		height: theme.other.space[24],
		width: '120px',
		position: 'absolute',
		top: '20px',
		left: '295px',
	},
	orangeIcon: {
		position: 'absolute',
		top: '7px',
		left: '9px',
		color: theme.colors.sunset[3],
	},
	orangeBar1: {
		background: 'rgba(255, 231, 226, 0.50)',
		borderRadius: theme.other.space[6],
		height: theme.other.space[3],
		width: '77px',
		position: 'absolute',
		top: '10px',
		right: '13px',
	},
	orangeBar2: {
		background: 'rgba(255, 231, 226, 0.50)',
		borderRadius: theme.other.space[6],
		height: theme.other.space[3],
		width: '96px',
		position: 'absolute',
		top: '30px',
		right: '13px',
	},
	orangeBar3: {
		background: 'rgba(255, 231, 226, 0.50)',
		borderRadius: theme.other.space[6],
		height: theme.other.space[3],
		width: '96px',
		position: 'absolute',
		top: '50px',
		right: '13px',
	},
}));

function SearchOnboardingPanel() {
	const { classes } = useOnboardingPanelStyles();

	return (
		<div className={classes.container}>
			<Flex direction="column" w="100%" gap="xl">
				<Flex justify="space-between" w="100%">
					<Text size="xs" weight="semibold" w="250px">
						Access search from your homepage, navigation or select &quot;/&quot;
						anywhere, anytime.
					</Text>
					<Button variant="primary" component={Link} to="/search">
						Try Search
					</Button>
				</Flex>

				<Flex className={classes.decorContainer}>
					<div className={classes.blueContainer}>
						<div className={classes.blueSearchBar}>
							<div className={classes.blueSearch}>
								<IconSearch
									size={13}
									stroke={3}
									className={classes.blueSearchIcon}
								/>
							</div>
							<div className={classes.blueSlash}>/</div>
						</div>
						<div className={classes.blueBox1} />
						<div className={classes.blueBox2} />
						<div className={classes.blueBox3} />
					</div>
				</Flex>
			</Flex>
		</div>
	);
}

function QuestionOnboardingPanel() {
	const { classes } = useOnboardingPanelStyles();

	return (
		<div className={classes.container}>
			<Flex direction="column" w="100%" gap="xl">
				<Flex justify="space-between" w="100%">
					<Text size="xs" weight="semibold" w="250px">
						Get the functionality of ChatGPT combined with the context of your
						metadata.
					</Text>
					<Button variant="primary" component={Link} to="/ai/new">
						Ask AI
					</Button>
				</Flex>

				<Flex className={classes.decorContainer}>
					<div className={classes.pinkContainer}>
						<div className={classes.pinkSparkleBar}>
							<IconSparkles
								size={16}
								stroke={2.5}
								className={classes.pinkSparkleIcon}
							/>
						</div>
						<div className={classes.pinkBox1} />
						<div className={classes.pinkBox2} />
					</div>
				</Flex>
			</Flex>
		</div>
	);
}

function HomescreenOnboardingPanel() {
	const { classes } = useOnboardingPanelStyles();
	const {
		homePageDrawerHandler: { open },
	} = appShellStore;

	return (
		<div className={classes.container}>
			<Flex direction="column" w="100%" gap="xl">
				<Flex justify="space-between" w="100%">
					<Text size="xs" weight="semibold" w="250px">
						Add widgets to access pinned resources, search history, and more.
					</Text>
					<Button variant="primary" onClick={() => open()}>
						Customize home
					</Button>
				</Flex>

				<Flex className={classes.decorContainer}>
					<div className={classes.orangeContainer}>
						<div className={classes.orangeBox1}>
							<IconFolders
								size={16}
								stroke={2.5}
								className={classes.orangeIcon}
							/>
							<div className={classes.orangeBar1} />
							<div className={classes.orangeBar2} />
							<div className={classes.orangeBar3} />
						</div>

						<div className={classes.orangeBox2}>
							<IconFileAnalytics
								size={16}
								stroke={2.5}
								className={classes.orangeIcon}
							/>
							<div className={classes.orangeBar1} />
							<div className={classes.orangeBar2} />
							<div className={classes.orangeBar3} />
						</div>

						<div className={classes.orangeBox3}>
							<IconPlugConnected
								size={16}
								stroke={2.5}
								className={classes.orangeIcon}
							/>
							<div className={classes.orangeBar1} />
							<div className={classes.orangeBar2} />
							<div className={classes.orangeBar3} />
						</div>
					</div>
				</Flex>
			</Flex>
		</div>
	);
}

const useViewerOnboardingStyles = createStyles((theme) => ({
	container: {
		paddingTop: theme.spacing.md,
		backgroundColor: theme.other.getColor('surface/secondary/default'),
		borderWidth: '1px',
		borderRadius: theme.other.space[3],
		borderColor: theme.other.getColor('border/primary/default'),
		boxShadow:
			'1px 0px 0px 0px rgba(0, 0, 0, 0.08) inset, -1px 0px 0px 0px rgba(0, 0, 0, 0.08) inset, 0px -1px 0px 0px rgba(0, 0, 0, 0.12) inset, 0px 1px 0px 0px rgba(0, 0, 0, 0.08) inset, 0px 1px 0px 0px rgba(0, 0, 0, 0.05)',
	},
}));

type ViewerOnboardingAction = 'search' | 'question' | 'homescreen';

interface ViewerOnboardingProps {
	showAI: boolean;
	initialSelected?: ViewerOnboardingAction;
}

function ViewerOnboarding({ showAI, initialSelected }: ViewerOnboardingProps) {
	const { dismissViewerOnboardingAll, viewerOnboardingStepCompletion } =
		useUserOnboarding();

	const [selected, setSelected] = useState<ViewerOnboardingAction>(
		initialSelected || 'search'
	);
	const { user, workspace } = useAuthUser();
	const { classes, theme } = useViewerOnboardingStyles();

	const handleActionClick = useCallback(
		(newSelected: ViewerOnboardingAction) => {
			setSelected(newSelected);
			trackEvent(
				'viewer_onboarding/action/click',
				{ action: newSelected },
				user,
				workspace
			);
		},
		[user, workspace]
	);

	if (viewerOnboardingStepCompletion.dismissAll) {
		return null;
	}

	return (
		<Stack className={classes.container} spacing="none">
			<Flex direction="row" justify="space-between" pl="xl" pr="sm">
				<Text data-testid="quick-start-guide" weight="bold">
					Quick start guide
				</Text>
				<IconButton
					data-testid="dismiss-onboarding-icon-button"
					iconName="x"
					variant="tertiary"
					aria-label="Dismiss onboarding"
					onClick={dismissViewerOnboardingAll}
				/>
			</Flex>
			<Flex p="xl" gap="xl">
				<Stack w="282px" spacing="sm">
					<OnboardingAction
						cta="Search your company data"
						isSelected={selected === 'search'}
						isCompleted={viewerOnboardingStepCompletion.search}
						onClick={() => handleActionClick('search')}
					/>
					{showAI && (
						<OnboardingAction
							cta="Ask a question"
							isSelected={selected === 'question'}
							isCompleted={viewerOnboardingStepCompletion.question}
							onClick={() => handleActionClick('question')}
						/>
					)}
					<OnboardingAction
						cta="Customize your homepage"
						isSelected={selected === 'homescreen'}
						isCompleted={viewerOnboardingStepCompletion.homescreen}
						onClick={() => handleActionClick('homescreen')}
					/>

					{!showAI && <Box h={theme.other.space[10]} />}
				</Stack>
				<Flex w="452px">
					{selected === 'search' && <SearchOnboardingPanel />}
					{selected === 'question' && <QuestionOnboardingPanel />}
					{selected === 'homescreen' && <HomescreenOnboardingPanel />}
				</Flex>
			</Flex>
		</Stack>
	);
}

export default ViewerOnboarding;
