import type { SelectItem } from '@mantine/core';

const SLACK_RESPONSE_PREFERENCE = ['always', 'relevant', 'mention'] as const;

type SlackDataRequestResponsePreference =
	| (typeof SLACK_RESPONSE_PREFERENCE)[number]
	| null;

const DEFAULT_THREAD_RESPONSE_LEVEL = 'relevant';
const DEFAULT_CHANNEL_RESPONSE_LEVEL = 'always';

const CHANNEL_RESPONSE_PREFERENCE_OPTIONS: SelectItem[] = [
	{ value: 'always', label: 'Respond to every message' },
	{ value: 'mention', label: 'Respond when Secoda bot is mentioned' },
];

const THREAD_RESPONSE_PREFERENCE_OPTIONS: SelectItem[] = [
	...CHANNEL_RESPONSE_PREFERENCE_OPTIONS,
	{ value: 'relevant', label: 'Respond when a data question is asked' },
];

export {
	CHANNEL_RESPONSE_PREFERENCE_OPTIONS,
	DEFAULT_CHANNEL_RESPONSE_LEVEL,
	DEFAULT_THREAD_RESPONSE_LEVEL,
	SlackDataRequestResponsePreference,
	THREAD_RESPONSE_PREFERENCE_OPTIONS,
};
