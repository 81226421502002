import { createStyles, Menu } from '@mantine/core';
import type { DataQualityScore } from '@repo/api-codegen';
import { Button, Icon, Text } from '@repo/foundations';
import { DATA_QUALITY_TOP_KEYS } from '../../../../components/DataQualityScore/constants';
import type { QualityFilterKey } from '../../../../components/DataQualityScore/types';
import { getQualityKeyLabel } from '../../../../components/DataQualityScore/utils';
const useStyles = createStyles(() => ({
	dropdown: {
		zIndex: 100,
	},
}));

interface IDataQualityFilterMenuProps {
	dataQualityKey: keyof DataQualityScore;
	setDataQualityKey: (value: QualityFilterKey) => void;
}

export function DataQualityFilterMenu({
	dataQualityKey,
	setDataQualityKey,
}: IDataQualityFilterMenuProps) {
	const { classes } = useStyles();

	const options: QualityFilterKey[] = ['total', ...DATA_QUALITY_TOP_KEYS];

	return (
		<Menu position="bottom">
			<Menu.Target>
				<Button>
					<Text size="sm">{getQualityKeyLabel(dataQualityKey)}</Text>
				</Button>
			</Menu.Target>
			<Menu.Dropdown className={classes.dropdown}>
				{options.map((option) => (
					<Menu.Item
						key={option}
						onClick={() => setDataQualityKey(option)}
						rightSection={
							option === dataQualityKey ? <Icon name="check" /> : null
						}
					>
						{getQualityKeyLabel(option)}
					</Menu.Item>
				))}
			</Menu.Dropdown>
		</Menu>
	);
}
