import type { EntityPermissionOrMembershipOut } from '@repo/api-codegen';

export type SidebarSettings = {
	home?: boolean;
	catalog?: boolean;
	collections?: boolean;
	dictionary?: boolean;
	documents?: boolean;
	questions?: boolean;
	metrics?: boolean;
	glossary?: boolean;
};

// True by default
export const getSideBarSettingValue = (
	settings: SidebarSettings,
	key: keyof SidebarSettings
) => (key in settings ? (settings[key] as boolean) : true);

export const membershipText = (membership: EntityPermissionOrMembershipOut) => {
	const value = membership.write ? 'write' : 'read';

	if (value === 'read') {
		return 'Can read';
	}

	if (membership.user) {
		return 'Can edit';
	}
	return 'Editors can edit';
};
