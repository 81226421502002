import { useMutation } from '@tanstack/react-query';
import { apiClient, getEndpoints } from '../../common';
import { reportsQueryKeyFactory } from '../report';

interface IReorderWidgetParams {
	reportId: string;
	template: string;
}

export function usePopulateReportWithTemplate() {
	const mutationFn = async ({ reportId, template }: IReorderWidgetParams) => {
		const baseUrl = getEndpoints(reportsQueryKeyFactory.namespace).byId(
			reportId
		);

		const url = `${baseUrl}populate`;

		await apiClient.post(url, { template: template });
	};

	return useMutation(mutationFn);
}
