import { baseQueryHooksFactory } from '../../factories';
import type { INestedBreadcrumbs } from '../../types/models/nestedBreadcrumbs';
import { nestedBreadcrumbQueryKeyFactory } from './constants';

const { useNestedBreadcrumbsList } = baseQueryHooksFactory<
	INestedBreadcrumbs,
	'nestedBreadcrumbs'
>('nestedBreadcrumbs', nestedBreadcrumbQueryKeyFactory);

export { useNestedBreadcrumbsList };
