import { observer } from 'mobx-react-lite';
import { CommentStoreProvider } from '../../components/Comment/context';
import { useParamsIdSuffixUuid } from '../../utils/hook/utils';
import GlossaryTermPage from './GlossaryTermPage';

function GlossaryTermPageWithCommentStore() {
	const id = useParamsIdSuffixUuid();

	return (
		<CommentStoreProvider>
			<GlossaryTermPage id={id} />
		</CommentStoreProvider>
	);
}

export default observer(GlossaryTermPageWithCommentStore);
