import { useHotkeys } from '@mantine/hooks';
import { openSpotlight } from './events';

export function useSpotlightShortcuts() {
	useHotkeys([
		['/', () => openSpotlight({ type: 'search' })],
		[
			'mod+k',
			() => {
				openSpotlight({ type: 'command' });
			},
		],
	]);
}
