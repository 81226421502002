import { Center, createStyles } from '@mantine/core';
import { Icon, Text, type IconSizes } from '@repo/foundations';
import { IconSizeMap } from '@repo/foundations/components/Icon/Icon';
import {
	isEmoji,
	isTablerIcon,
	stringToIconEmojiSelectorValue,
} from '../IconEmojiSelector/IconEmojiSelector';

export interface IconEmojiProps {
	value: string;
	size?: IconSizes | number;
	padding?: number;
}

const iconSize = (size: IconSizes | number) =>
	typeof size === 'number' ? size : IconSizeMap[size];

const useStyles = createStyles(
	(
		theme,
		{
			size,
			selectedIconColor,
		}: { size: IconSizes | number; selectedIconColor: string }
	) => ({
		icon: {
			color: selectedIconColor,
			width: iconSize(size),
			height: iconSize(size),
		},
	})
);

export function IconEmoji({ value, size = 'md', padding }: IconEmojiProps) {
	const icon = stringToIconEmojiSelectorValue(value);

	const { classes } = useStyles({
		size,
		selectedIconColor: isTablerIcon(icon)
			? icon.iconColor
			: 'icon/primary/default',
	});

	return isEmoji(icon) ? (
		<Center p={2} w={iconSize(size)} h={iconSize(size)}>
			<Text
				sx={{ fontSize: iconSize(size) - 6, lineHeight: `${iconSize(size)}px` }}
			>
				{icon.emoji}
			</Text>
		</Center>
	) : (
		<Icon
			name={icon.iconName}
			iconWidth={iconSize(size)}
			iconHeight={iconSize(size)}
			className={classes.icon}
			iconPadding={padding}
		/>
	);
}
