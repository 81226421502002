import { useCallback } from 'react';
import { Navigate, useNavigate } from 'react-router';
import { useAuthUser } from '../../api';
import { useAutomationList } from '../../api/hooks/automation';
import { TableV2 } from '../../components/TableV2';
import type { ExtendedDataTableColumn } from '../../components/TableV2/types';
import { useActions, useColumns } from './AutomationList.hooks';

const COLUMN_VISIBILITY = {
	catalogServerType: 'automations',
	catalogType: 'automation',
} as const;

const QUICK_ACTIONS = ['actions::run'] as const;

function AutomationList() {
	const { workspace } = useAuthUser();

	const columns = useColumns();
	const actions = useActions();

	const navigate = useNavigate();

	const handleClick = useCallback(
		(cell) => {
			if (
				(cell.column as ExtendedDataTableColumn<unknown>).navigate !== false
			) {
				navigate(`/automation/${cell.record.id}`);
			}
		},
		[navigate]
	);

	if (!workspace.automation_enabled) {
		return <Navigate to="/" />;
	}

	return (
		<TableV2
			pluralTypeString="automations"
			columns={columns}
			columnVisibility={COLUMN_VISIBILITY}
			usePaginationList={useAutomationList}
			withActions={actions}
			withCheckbox={true}
			withQuickActions={QUICK_ACTIONS}
			withCsvExport
			withInteractiveHeader
			withSearch
			withFilters={false}
			defaultSort={null}
			onCellClick={handleClick}
		/>
	);
}

export default AutomationList;
