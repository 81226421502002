import { makeAutoObservable } from 'mobx';
import type { Automation } from '../../../../api';
import { AutomationTriggerType } from '../../../../api';
import { AUTOMATION_TRIGGER_OPTIONS } from './constants';

export class AutomationTriggerCardStore {
	selectedTrigger: AutomationTriggerType | undefined;

	constructor(automation: Automation) {
		makeAutoObservable(this);

		this.selectedTrigger =
			automation.trigger_type ?? AutomationTriggerType.SCHEDULE;
	}

	setSelectedTrigger(trigger: AutomationTriggerType) {
		this.selectedTrigger = trigger;
	}

	getTriggerLabel() {
		const label = AUTOMATION_TRIGGER_OPTIONS.find(
			(triggerOption) => triggerOption.value === this.selectedTrigger
		)?.label;

		if (!label) {
			return AUTOMATION_TRIGGER_OPTIONS[0].label;
		}

		return label;
	}
}
