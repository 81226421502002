import { ActionIcon, Box, Flex } from '@mantine/core';
import { Icon, Text } from '@repo/foundations';
import type React from 'react';
import { useCallback, useMemo, useState } from 'react';
import { useParamsIdSuffixUuid } from '../../../utils/hook/utils';
import { SmallLoadingSpinner } from '../../LoadingSpinner';
import { TREE_INDENT } from '../constants';
import { LoadMore } from './LoadMore';
import { useTreeNodeStyle } from './TreeNode.styles';

type State<T> =
	| { state: 'init' }
	| { state: 'loading' }
	| {
			state: 'loaded';
			open: boolean;
			children: T[];
			nextPage?: number | null;
			isLoadingMore?: boolean;
	  };

export type TreeNodeBase = {
	hasChildren: boolean;
	id: string;
};

export interface TreeNodeProps<T extends TreeNodeBase> {
	node: T;
	level: number;
	getIcon: (node: T, size: number) => React.ReactNode;
	getLabel: (node: T) => React.ReactNode;
	onLoadChildren: (
		node: T,
		page?: number
	) => Promise<T[] | { results: T[]; nextPage?: number | null }>;
	onClick: (e: React.MouseEvent, node: T) => void;
}

// DEPRECATED: use EntityTreeNode with useInfiniteList hooks instead of fetching.
// Direct fetching does not benefit from optimistic react-query updates.
export function TreeNode<T extends TreeNodeBase>({
	node,
	level,
	onLoadChildren,
	getIcon,
	getLabel,
	onClick,
}: TreeNodeProps<T>) {
	const paramsId = useParamsIdSuffixUuid();
	const [state, setState] = useState<State<T>>({ state: 'init' });

	const isSelected = useMemo(() => {
		if ('entity' in node) {
			return paramsId === node.id;
		}
		return false;
	}, [node, paramsId]);

	const { classes, theme } = useTreeNodeStyle({
		open: state.state === 'loaded' && state.open,
		selected: isSelected,
	});

	const toggle = useCallback(
		async (e: React.MouseEvent) => {
			e.stopPropagation();
			if (node.hasChildren) {
				if (state.state === 'init') {
					setState({ state: 'loading' });
					const result = await onLoadChildren(node);
					if (Array.isArray(result)) {
						setState({
							state: 'loaded',
							open: true,
							children: result,
						});
					} else {
						setState({
							state: 'loaded',
							open: true,
							children: result.results,
							nextPage: result.nextPage,
						});
					}
				} else if (state.state === 'loaded') {
					setState((prev) => ({
						...prev,
						open: !(prev as State<T> & { open: boolean }).open,
					}));
				}
			}
		},
		[node, state, onLoadChildren]
	);

	const onLoadMore = useCallback(async () => {
		if (state.state === 'loaded' && state.nextPage) {
			setState({ ...state, isLoadingMore: true });
			const result = await onLoadChildren(node, state.nextPage);

			if (Array.isArray(result)) {
				setState({
					state: 'loaded',
					open: true,
					children: result,
					isLoadingMore: false,
				});
			} else {
				setState((prev) => ({
					state: 'loaded',
					open: true,
					children: [
						...(prev as State<T> & { children: T[] }).children,
						...result.results,
					],
					nextPage: result.nextPage,
					isLoadingMore: false,
				}));
			}
		}
	}, [node, state, onLoadChildren]);

	const shouldRenderToggle =
		node.hasChildren &&
		(state.state === 'init' ||
			(state.state === 'loaded' && state.children.length > 0));
	const shouldRenderChildTreeNodes = state.state === 'loaded' && state.open;
	const shouldRenderLoadMore =
		state.state === 'loaded' && state.open && state.nextPage;

	const icon = getIcon(node, 16);

	return (
		<>
			<Box
				role="button"
				className={classes.button}
				pl={(level + 1) * TREE_INDENT - theme.other.space[1]}
				onClick={(e) => onClick(e, node)}
				data-testid={`tree-node-${node.id}`}
			>
				<Flex direction="row" align="center" justify="space-between" w="100%">
					<Flex align="center" style={{ flex: '0 0 auto' }}>
						<Box className={classes.toggleContainer}>
							{shouldRenderToggle && (
								<ActionIcon
									onClick={toggle}
									data-testid={`tree-node-${node.id}-collapsable`}
									className={classes.chevronButton}
								>
									<Icon name="chevronRight" className={classes.chevron} />
								</ActionIcon>
							)}
							{node.hasChildren && state.state === 'loading' && (
								<SmallLoadingSpinner size="xs" />
							)}
						</Box>
						<Flex align="center">
							<Flex
								mx={theme.other.space[1]}
								w={theme.other.space[5]}
								justify="center"
							>
								{icon}
							</Flex>
							<Text
								size="sm"
								weight="semibold"
								color="text/primary/default"
								lineClamp={1}
								style={{
									textOverflow: 'ellipsis',
								}}
							>
								{getLabel(node)}
							</Text>
						</Flex>
					</Flex>
				</Flex>
			</Box>

			{shouldRenderChildTreeNodes && (
				<>
					{state.children.map((childNode) => (
						<TreeNode
							key={childNode.id}
							node={childNode}
							onLoadChildren={onLoadChildren}
							level={level + 1}
							getIcon={getIcon}
							onClick={onClick}
							getLabel={getLabel}
						/>
					))}
				</>
			)}

			{shouldRenderLoadMore && (
				<LoadMore
					level={level + 1}
					onClick={onLoadMore}
					isLoading={state.isLoadingMore || false}
				/>
			)}
		</>
	);
}
