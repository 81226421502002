import { Carousel as MantineCarousel } from '@mantine/carousel';
import { SimpleGrid, Skeleton, UnstyledButton } from '@mantine/core';
import MarkdownRenderer from '@repo/common/components/MarkdownRenderer';
import { Text } from '@repo/foundations';
import range from 'lodash-es/range';
import { useAIPromptSuggestions } from '../../../api';
import { DelayedRender } from '../../DelayedRender/DelayedRender';
import { Carousel } from '../../Navigation';
import { useAIAssistantContext } from '../context';
import { AIAssistantMode } from '../types';
import { useStyles } from './AIChatEmpty.styles';

interface IPromptSuggestionsGridProps {
	onPromptClick: (prompt: string) => void;
}

export function PromptSuggestions({
	onPromptClick,
}: IPromptSuggestionsGridProps) {
	const { classes, theme } = useStyles();

	const { mode } = useAIAssistantContext();
	const { data: suggestions, isLoading } = useAIPromptSuggestions();

	if (mode === AIAssistantMode.SIDEBAR) {
		return (
			<Carousel
				classNames={{
					container: classes.carouselContainer,
					slide: classes.carouselSlide,
				}}
				height={theme.other.space[7]}
				gap={theme.spacing.sm}
				withControls={false}
				borderBottomWidth={0}
			>
				{suggestions?.map((suggestion) => (
					<MantineCarousel.Slide key={suggestion}>
						<UnstyledButton
							className={classes.carouselSlideButton}
							onClick={() => onPromptClick(suggestion)}
						>
							<Text size="xs" weight="semibold">
								<MarkdownRenderer inline>{suggestion}</MarkdownRenderer>
							</Text>
						</UnstyledButton>
					</MantineCarousel.Slide>
				))}
			</Carousel>
		);
	}

	return (
		<SimpleGrid
			spacing="xs"
			cols={2}
			breakpoints={[{ maxWidth: 'sm', cols: 1 }]}
		>
			{isLoading ? (
				<DelayedRender>
					{range(4).map((index) => (
						<Skeleton key={index} height={60} width="100%" />
					))}
				</DelayedRender>
			) : (
				suggestions?.map((suggestion) => (
					<UnstyledButton
						key={suggestion}
						className={classes.promptSample}
						onClick={() => onPromptClick(suggestion)}
					>
						<Text size="sm" color="text/secondary/default">
							<MarkdownRenderer inline>{suggestion}</MarkdownRenderer>
						</Text>
					</UnstyledButton>
				))
			)}
		</SimpleGrid>
	);
}
