import { toggleMark } from 'prosemirror-commands';
import type { MarkSpec } from 'prosemirror-model';
import markInputRule from '../lib/markInputRule';
import type { MarkOptions } from './Mark';
import Mark from './Mark';

export default class Strikethrough extends Mark {
	get name() {
		return 'strikethrough';
	}

	get schema(): MarkSpec {
		return {
			parseDOM: [
				{
					tag: 's',
				},
				{
					tag: 'del',
				},
				{
					tag: 'strike',
				},
			],
			toDOM: () => ['del', 0],
		};
	}

	keys({ type }: MarkOptions) {
		return {
			'Mod-d': toggleMark(type),
		};
	}

	inputRules({ type }: MarkOptions) {
		return [markInputRule(/~([^~]+)~$/, type)];
	}

	toMarkdown() {
		return {
			open: '~~',
			close: '~~',
			mixable: true,
			expelEnclosingWhitespace: true,
		};
	}

	get markdownToken() {
		return 's';
	}

	parseMarkdown() {
		return { mark: 'strikethrough' };
	}
}
