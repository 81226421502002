import { createStyles, SimpleGrid } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import axios from 'axios';
import { useAuthUser } from '../../api';
import { GROWTH_PLAN, STARTER_PLAN } from '../../data/plans';
import { usePlan } from '../../hooks/usePlans';
import { PLAN_FEATURES } from './constants';
import PlansCard from './PlansCard';

const useStyles = createStyles(() => ({
	buttonRoot: {
		width: 'fit-content',
	},
}));

export default function PlansGrid() {
	const { theme } = useStyles();
	const { user } = useAuthUser();
	const { plan } = usePlan();

	const handleContactUsClick = (planTitle: string) => {
		window.open('https://secoda.co/upgrade', '_blank', 'noopener,noreferrer');
		showNotification({
			title: `${planTitle} plan selected`,
			message: 'A Secoda team member will be in contact shortly',
			autoClose: false,
		});
		const text = `${user.email} has clicked the "Contact us" button for the ${planTitle} plan.`;
		axios.post(
			`https://webhook-slack-worker.secoda.workers.dev/?channel=${'C01QUB1T8NQ'}&text=${text}`
		);
	};

	const handleStarterClick = () => {
		handleContactUsClick('BASIC');
	};

	const handleGrowthClick = () => {
		handleContactUsClick('CORE');
	};

	const handleEnterpriseClick = () => {
		handleContactUsClick('ENTERPRISE');
	};

	return (
		<SimpleGrid cols={4} spacing="sm" maw={theme.other.width.xxxxl}>
			<PlansCard
				title="basic"
				color="text/primary/default"
				bgColor={theme.other.getColor('surface/tertiary/default')}
				description="For small and growing data teams to get started."
				ctaButtonLabel="Contact us"
				ctaOnClick={handleStarterClick}
				features={PLAN_FEATURES.basic}
				ctaButtonFilled
				disabled={plan?.id === STARTER_PLAN.id}
			/>
			<PlansCard
				title="core"
				color="text/primary/default"
				bgColor={theme.other.getColor('surface/tertiary/default')}
				description="Ideal for teams looking to scale and need more automation."
				ctaButtonLabel="Contact us"
				ctaOnClick={handleGrowthClick}
				features={PLAN_FEATURES.core}
				ctaButtonFilled
				disabled={plan?.id === GROWTH_PLAN.id}
			/>
			<PlansCard
				title="premium"
				color="text/primary/default"
				bgColor="linear-gradient(to bottom, #FBC3B5, #F1C0D9, #CCD9F0)"
				description="Manage and measure your data teams impact."
				ctaButtonLabel="Contact us"
				ctaOnClick={handleGrowthClick}
				features={PLAN_FEATURES.premium}
				ctaButtonFilled
				disabled={plan?.id === GROWTH_PLAN.id}
			/>
			<PlansCard
				title="enterprise"
				color="text/inverse/default"
				bgColor={theme.other.getColor('surface/inverse/default')}
				description="Built for bigger teams with security or deployment needs."
				ctaButtonLabel="Contact us"
				ctaOnClick={handleEnterpriseClick}
				features={PLAN_FEATURES.enterprise}
				ctaButtonFilled
			/>
		</SimpleGrid>
	);
}
