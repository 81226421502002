import { Icon } from '@repo/foundations';
import type { EditorDictionary, MenuItem } from '@repo/secoda-editor';
import type { EditorState } from 'prosemirror-state';
import { ALT_KEY, MOD_KEY } from '../utils/keyboard';

export default function formattingMenuItems(
	state: EditorState,
	dictionary: EditorDictionary,
	hasCommentFunction: boolean | undefined
): MenuItem[] {
	const { schema } = state;
	return [
		{
			name: 'comment',
			tooltip: dictionary.comment,
			icon: () => <Icon name="message" color="icon/primary/default" />,
			visible: 'comment' in schema.marks && hasCommentFunction,
			shortcutKeys: [MOD_KEY, ALT_KEY, 'M'],
		},
	];
}
