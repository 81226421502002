import { Box, Group } from '@mantine/core';
import { Text } from '@repo/foundations';
import { SmallLoadingSpinner } from '../../../components/LoadingSpinner';
import type { WidgetSource } from '../constants';
import { METRIC_WIDGET_CONTENT_HEIGHT } from '../constants';

export type IMetricLoadingOrEmptyProps = {
	source: WidgetSource;
	isEmpty?: boolean;
	isLoading?: boolean;
};

export function MetricLoadingOrEmpty({
	source,
	isEmpty,
	isLoading,
}: IMetricLoadingOrEmptyProps) {
	const height = METRIC_WIDGET_CONTENT_HEIGHT[source];

	if (isLoading) {
		return (
			<Box h={height}>
				<SmallLoadingSpinner />
			</Box>
		);
	}

	if (isEmpty) {
		return (
			<Group position="center" align="center" h={height}>
				<Text
					color="text/secondary/default"
					size={source === 'preview' ? 'xs' : 'sm'}
				>
					No data available
				</Text>
			</Group>
		);
	}

	return null;
}
