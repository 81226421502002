import type { MantineThemeOverride } from '@mantine/core';

export const Select: MantineThemeOverride['components'] = {
	Select: {
		styles: (theme) => ({
			description: {
				fontSize: theme.other.typography.text.sm,
				lineHeight: theme.other.typography.lineHeight.text.sm,
				color: theme.other.getColor('text/secondary/default'),
			},
			input: {
				backgroundColor: theme.other.getColor('surface/input/default'),
			},
			item: {
				'&[data-selected=true]': {
					backgroundColor: theme.other.getColor('surface/primary/active'),
					color: theme.other.getColor('text/primary/default'),
					'&:hover': {
						backgroundColor: theme.other.getColor('surface/primary/hover'),
						color: theme.other.getColor('text/primary/default'),
					},
				},
			},
		}),
	},
};
