import { Box } from '@mantine/core';
import { memo } from 'react';
import { useFeatureAccess } from '../../../api/hooks/workspace/useFeatureAccess';
import { EmptyState } from '../../EmptyState';
import { UpgradeButton } from '../UpgradeButton';
import { GroupTable } from './GroupsTable';

function InternalGroups() {
	const { groupAccess } = useFeatureAccess();

	if (!groupAccess) {
		return (
			<EmptyState
				title="Upgrade to access Groups"
				description="You can use Groups to organize your members in Secoda."
				illustrationName="upgrade"
				includeGoBack={false}
				stateHeight="50vh"
				size="lg"
				withActions={<UpgradeButton feature="Groups" size="md" />}
			/>
		);
	}

	return (
		<Box my={'md'}>
			<GroupTable />
		</Box>
	);
}

export const Groups = memo(InternalGroups);
