import { Box, Group } from '@mantine/core';
import {
	apiQueryKey,
	useApiBulkCreateEntityPermissions,
	useApiGetEntityGroupedPermissions,
} from '@repo/api-codegen';
import { IconWrapper } from '@repo/common/components/IconWrapper';
import { Button, MultiSelect, Text } from '@repo/foundations';
import { useMemo, useState } from 'react';
import { useUserGroupList } from '../../api';
import { useExtendedUserList } from '../../api/hooks/user/useExtendedUserList';
import queryClient from '../../api/queryClient';
import type { User, UserGroup } from '../../lib/models';
import { getDisplayName } from '../../utils/userUtils';
import { UserAvatar } from '../UserAvatar';

type UserOrGroupItem = { value: string; group: string } & (
	| {
			group: 'Select a person';
			user: User;
	  }
	| {
			group: 'Select a group';
			userGroup: UserGroup;
	  }
);
const prepareData = (selectedValues: string[]) =>
	selectedValues.map((value) => {
		const [key, id] = value.split(':');
		if (key === 'user_id') {
			return {
				user_id: id,
			};
		}
		return {
			group_id: id,
		};
	});

export function InviteEntityPermissionForm({
	entityId,
	searchValue,
	setSearchValue,
	placeholder,
}: {
	entityId: string;
	searchValue: string;
	setSearchValue: (value: string) => void;
	placeholder: string;
}) {
	const [value, setValue] = useState<string[]>([]);

	const { data: groupedPermissions } = useApiGetEntityGroupedPermissions({
		pathParams: {
			entityId,
		},
	});
	const {
		mutateAsync: bulkCreateEntityPermissions,
		isLoading: isBulkCreatingEntityPermissions,
	} = useApiBulkCreateEntityPermissions();

	const existingEntityPermissions = groupedPermissions?.flatMap(
		(group) => group.permissions
	);

	const invite = async () => {
		await bulkCreateEntityPermissions({
			body: prepareData(value),
			pathParams: {
				entityId,
			},
		});
		await queryClient.invalidateQueries(
			apiQueryKey('resource/all/{entity_id}/permissions_v2', {
				entity_id: entityId,
			})
		);

		setValue([]);
	};

	const { activeUsers: usersData } = useExtendedUserList();

	const { data: groupData } = useUserGroupList({});

	const data: UserOrGroupItem[] = useMemo(() => {
		const users =
			usersData?.filter(
				(user) =>
					!existingEntityPermissions?.find(
						(permission) => permission.user?.id === user.id
					)
			) ?? [];

		const userGroups =
			groupData?.results.filter(
				(userGroup) =>
					!existingEntityPermissions?.find(
						(permission) => permission.group?.id === userGroup.id
					)
			) ?? [];

		return [
			...users.map((user) => ({
				label: getDisplayName(user),
				value: `user_id:${user.id}`,
				group: 'Select a person' as const,
				user: user as unknown as User,
			})),
			...userGroups.map((userGroup) => ({
				label: userGroup.name,
				value: `user_group_id:${userGroup.id}`,
				group: 'Select a group' as const,
				userGroup: userGroup as unknown as UserGroup,
			})),
		];
	}, [existingEntityPermissions, groupData?.results, usersData]);

	const filteredValue = value.filter((v) =>
		data.find((item) => item.value === v)
	);

	return (
		<Group noWrap>
			<Box
				sx={{
					flexGrow: 1,
					flexShrink: 1,
					paddingTop: 3, // input state getting slightly cutoff
				}}
			>
				<MultiSelect
					searchValue={searchValue}
					onSearchChange={setSearchValue}
					data={data}
					placeholder={placeholder}
					value={filteredValue}
					setValue={setValue}
					renderIcon={(item) =>
						item.group === 'Select a person' ? (
							<UserAvatar user={item.user} size="sm" />
						) : (
							<IconWrapper>
								<Text size="sm">{item.userGroup.icon}</Text>
							</IconWrapper>
						)
					}
					renderLabel={(item) =>
						item.group === 'Select a person'
							? getDisplayName(item.user)
							: item.userGroup.name
					}
				/>
			</Box>
			<Button
				size="md"
				disabled={filteredValue.length === 0}
				loading={isBulkCreatingEntityPermissions}
				onClick={invite}
			>
				Add
			</Button>
		</Group>
	);
}
