import { useQuery } from '@tanstack/react-query';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { filterOncePerDay } from '../../../pages/AnalyticsPage/utils/utils';
import { createMockableHook } from '../../../utils/createMockableHook';
import { apiClient, getEndpoints } from '../../common';
import type { IMetricWidget, IMetricWidgetSample } from '../../types';
import type { IAnalyticsMetric } from '../../types/models/analyticsMetric';

export const METRIC_NAMESPACE = ['admin', 'usage'];

function addPrefixToKeys<T>(
	prefix: string,
	obj: Record<string, T>
): Record<string, T> {
	const newObj: Record<string, T> = {};

	Object.keys(obj).forEach((key) => {
		newObj[prefix + key] = obj[key];
	});

	return newObj;
}

export function useMetric(
	name: string,
	filter: Record<string, string>,
	since: Dayjs | null = null
) {
	const queryKey = [...METRIC_NAMESPACE, name, filter, since];
	const queryFn = async () => {
		const url = getEndpoints(METRIC_NAMESPACE).byPath([name]);
		const { data } = await apiClient.get<Array<IAnalyticsMetric>>(url, {
			params: {
				...addPrefixToKeys('filter__', filter),
				...(since ? { since: since.format() } : {}),
			},
		});
		return filterOncePerDay(data);
	};

	return useQuery(queryKey, queryFn);
}

export function useMetricLatest(name: string, filter: Record<string, string>) {
	const queryKey = [...METRIC_NAMESPACE, name, 'latest', filter];
	const queryFn = async () => {
		const url = getEndpoints(METRIC_NAMESPACE).byPath([name, 'latest']);
		const { data } = await apiClient.get<IAnalyticsMetric>(url, {
			params: {
				...addPrefixToKeys('filter__', filter),
			},
		});
		return data;
	};

	return useQuery(queryKey, queryFn, { retry: false });
}

export function useMetricLatestLive(args: {
	name: string;
	filter: Record<string, string>;
	workspaceId?: string;
	after?: Dayjs;
	enabled?: boolean;
}) {
	const { name, enabled, filter, workspaceId, after } = args;
	const queryKey = [
		...METRIC_NAMESPACE,
		name,
		'latest_live',
		filter,
		workspaceId,
		after,
	];
	const queryFn = async () => {
		const url = getEndpoints(METRIC_NAMESPACE).byPath([name, 'latest_live']);
		const params = {
			...addPrefixToKeys('filter__', filter),
		};

		if (workspaceId) {
			params.workspace_id = workspaceId;
		}

		if (after) {
			params.after = after.format();
		}

		const { data } = await apiClient.get<
			{ resolved: false } | { resolved: true; data: IAnalyticsMetric }
		>(url, {
			params,
		});
		return data;
	};

	return useQuery(queryKey, queryFn, {
		enabled,
		refetchInterval: (res) => {
			if (!res) {
				return 10_000;
			}

			if (res.resolved) {
				return false;
			}

			return 10_000;
		},
	});
}

export function useMetricFilters(
	name: string,
	options: { enabled?: boolean } = {}
) {
	const queryKey = [...METRIC_NAMESPACE, name, 'filters'];

	const queryFn = async () => {
		const url = getEndpoints(METRIC_NAMESPACE).byPath([name, 'filters']);
		const { data } = await apiClient.get<Array<Record<string, string>>>(url);
		return data;
	};
	return useQuery(queryKey, queryFn, options);
}

function useMetricFromWidgetInternal(
	metricWidget: IMetricWidget | IMetricWidgetSample
) {
	const filter =
		metricWidget.metric_metadata.fixed_filter ||
		metricWidget.metric_metadata.user_filter;

	const since = dayjs().endOf('day').subtract(90, 'days');

	return useMetric(metricWidget.metric_metadata.metric_name, filter, since);
}

export const [useMetricFromWidget, MockUseMetricFromWidgetProvider] =
	createMockableHook(useMetricFromWidgetInternal);

export function useMetricLatestFromWidget(
	metricWidget: IMetricWidget | IMetricWidgetSample
) {
	const fixedFilter = metricWidget.metric_metadata.fixed_filter || {};

	const filter = {
		...fixedFilter,
		...metricWidget.metric_metadata.user_filter,
	};

	return useMetricLatest(metricWidget.metric_metadata.metric_name, filter);
}
