import { showNotification } from '@mantine/notifications';
import type { IconNames } from '@repo/foundations';
import { Icon } from '@repo/foundations';
import { capitalize, filter } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import type {
	DefaultContext,
	ISecodaEntity,
	Monitor,
	UpdateRequestParams,
} from '../../api';
import { useDeleteMonitor, useRunMonitors, useUpdateMonitor } from '../../api';
import type { ExtendedDataTableColumn } from '../../components/TableV2/types';

import { useGenericColumns } from '../../components/TableV2/SecodaEntity.hooks';

import { SELECTABLE_PROPERTY_OPTIONS } from '@repo/common/components/SelectableProperty/constants';
import { EntityType } from '@repo/common/enums/entityType';
import type { UpdateModelHook } from '../../api/factories/types';
import { monitorsQueryKeyFactory } from '../../api/hooks/monitoring/constants';
import type { ICommandListItem } from '../../components/Spotlight/components/CommandPalette/constants';
import { useDeleteManyAction } from '../../components/TableV2/BaseModel.hooks';
import {
	BadgeRender,
	BoldTextRender,
	MonitorResourceRender,
	RelativeTimeRender,
} from '../../components/TableV2/render';
import { formatValue } from '../MonitorPage/utils';

export function useColumns<T extends Monitor>(): ExtendedDataTableColumn<T>[] {
	const genericColumns = useGenericColumns({
		useUpdate: useUpdateMonitor as unknown as UpdateModelHook<
			ISecodaEntity,
			UpdateRequestParams<ISecodaEntity>,
			DefaultContext<ISecodaEntity>,
			unknown
		>,
	}) as ExtendedDataTableColumn<T>[];

	const columns: ExtendedDataTableColumn<T>[] = useMemo(
		() => [
			{
				accessor: 'name',
				title: 'Name',
				render: (record) => <BoldTextRender record={record} field={'name'} />,
			},
			{
				accessor: 'target',
				title: 'Resource',
				render: (record) => <MonitorResourceRender record={record} />,
			},
			{
				accessor: 'metric_type',
				title: 'Metric type',
				render: (record: Monitor) =>
					capitalize(record.metric_type?.replace('_', ' ')),
			},
			{
				accessor: 'last_measurement_value',
				title: 'Last value',
				render: (record: Monitor) =>
					record.last_measurement_value
						? formatValue(
								record.metric_type,
								parseFloat(record.last_measurement_value)
							)
						: null,
			},
			{
				accessor: 'status',
				title: 'Status',
				render: (record) => (
					<BadgeRender
						record={record}
						field={'status'}
						badgeOptions={Object.entries(
							SELECTABLE_PROPERTY_OPTIONS.monitorStatus
						).map(([key, value]) => ({
							color: value.color || 'fill/transparent-secondary/default',
							label: value.label,
							option: value.value,
							textColor: value.textColor,
							fontSize: 'xs',
						}))}
						nilOption={{
							color: 'fill/transparent-secondary/default',
							label: 'Pending',
							option: 'pending',
							textColor: 'text/primary/default',
							fontSize: 'xs',
						}}
					/>
				),
			},
			{
				accessor: 'last_attempted_at',
				title: 'Last run',
				render: (record: Monitor) => (
					<RelativeTimeRender record={record} field={'last_attempted_at'} />
				),
			},

			...filter(genericColumns, (column) =>
				['updated_at'].includes(column.accessor)
			),
		],
		[genericColumns]
	);

	return columns;
}

export const useActions = (): ICommandListItem<Monitor>[] => {
	const { runMonitors } = useRunMonitors();
	const { action: deleteManyAction } = useDeleteManyAction({
		name: 'monitor',
		queryKeyFactory: monitorsQueryKeyFactory,
		useDelete: useDeleteMonitor,
	});

	const handleRunMonitors = useCallback(
		async (entities: Monitor[]) => {
			let monitorIds =
				entities
					?.filter(({ is_enabled: isEnabled }) => isEnabled)
					.map(({ id }) => id) ?? [];

			showNotification({
				title: 'Monitors running',
				message: 'All the selected monitors have been triggered',
				color: 'green',
				icon: <Icon name="check" />,
			});

			await runMonitors(monitorIds);
		},
		[runMonitors]
	);

	const actions = useMemo(
		() => [
			{
				id: 'actions::run',
				title: 'Run monitor',
				name: 'Run',
				iconName: 'refresh' as IconNames,
				hotkey: '/mp',
				type: EntityType.all,
				team: undefined,
				category: 'actions',
				show: true,
				onClick: handleRunMonitors,
			},
			deleteManyAction,
		],
		[deleteManyAction, handleRunMonitors]
	);

	return actions;
};
