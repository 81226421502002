import {
	CloseButton,
	createStyles,
	Group,
	Stack,
	TextInput,
	UnstyledButton,
} from '@mantine/core';
import { useInputState } from '@mantine/hooks';
import type { MetricType } from '@repo/api-codegen';
import { fuzzySearchFilter } from '@repo/common/utils/fuse';
import { Icon, Text } from '@repo/foundations';
import { groupBy, map, omit } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { METRIC_TYPE_INFORMATION } from '../../pages/MonitorPage/constants';
import { useAddMonitorStoreContext } from './context';

const useStyles = createStyles((theme) => ({
	root: {
		backgroundColor: theme.other.getColor('surface/tertiary/default'),
		height: '100%',
	},
	button: {
		borderRadius: theme.radius.sm,
		padding: `${theme.spacing.xs} ${theme.spacing.xs}`,
		fontSize: theme.fontSizes.sm,
		fontWeight: theme.other.typography.weight.semibold,
		'&:hover': {
			backgroundColor: theme.other.getColor('fill/secondary/hover'),
		},
		'&[data-active=true]': {
			backgroundColor: theme.other.getColor('fill/secondary/active'),
		},
	},
}));

function MonitorTypeSelector() {
	const { classes } = useStyles();
	const [searchTerm, setSearchTerm] = useInputState('');
	const store = useAddMonitorStoreContext();

	const groups = useMemo(() => {
		const filteredMetricTypes = fuzzySearchFilter(
			searchTerm,
			Object.values(omit(METRIC_TYPE_INFORMATION, 'analytics')),
			['label', 'group'],
			{ threshold: 0.2 }
		);

		return groupBy(filteredMetricTypes, 'group');
	}, [searchTerm]);

	const handleChange = (value: MetricType) => () =>
		store.setFormFields('metricType', value);

	const clearSearch = () => {
		setSearchTerm('');
	};

	return (
		<Stack px="xs" py="sm" className={classes.root}>
			<TextInput
				data-autofocus
				size="sm"
				px="xs"
				placeholder="Search..."
				icon={<Icon name="search" />}
				value={searchTerm}
				onChange={setSearchTerm}
				rightSection={
					searchTerm !== '' && <CloseButton onClick={clearSearch} />
				}
			/>
			{map(groups, (groupItems, group) => (
				<Stack key={group} spacing="xs">
					<Text px="xs" size="sm" tt="capitalize">
						{group}
					</Text>
					<Stack spacing="none">
						{groupItems.map(({ iconName, label, value: metricType }) => {
							const active = store.getFormValue('metricType') === metricType;

							return (
								<UnstyledButton
									className={classes.button}
									key={label}
									data-active={active}
									onClick={handleChange(metricType)}
								>
									<Group spacing="xs" noWrap>
										<Icon name={iconName} />
										<Text size="sm">{label}</Text>
									</Group>
								</UnstyledButton>
							);
						})}
					</Stack>
				</Stack>
			))}
		</Stack>
	);
}

export default observer(MonitorTypeSelector);
