import { Group } from '@mantine/core';
import type { DataQualitySuggestion } from '@repo/api-codegen';
import { Button } from '@repo/foundations';
import { Link } from 'react-router-dom';

interface FixMonitorActionProps {
	suggestion: DataQualitySuggestion;
}

export function FixMonitorAction({ suggestion }: FixMonitorActionProps) {
	return (
		<Group spacing="sm">
			{suggestion.monitors?.map((monitor) => (
				<Button
					key={monitor.id}
					component={Link}
					to={`/monitor/${monitor.id}`}
					variant="primary"
				>
					Fix monitor {monitor.title}
				</Button>
			))}
			{suggestion.tests
				?.filter((test) => !!test.url)
				?.map((test) => (
					<Button
						key={test.id}
						component={Link}
						to={test.url!}
						variant="primary"
						target="_blank"
					>
						Fix test {test.title}
					</Button>
				))}
		</Group>
	);
}
