import { Group } from '@mantine/core';
import { Button } from '@repo/foundations';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router';
import { useAuthUser } from '../../../../api';
import { trackEvent } from '../../../../utils/analytics';

import { entityModalStore } from '../../store';

function GlossaryTermFooter() {
	const { user, workspace } = useAuthUser();
	const navigate = useNavigate();

	const handleOnClick = () => {
		trackEvent('glossary/create', {}, user, workspace!);
		entityModalStore.createGlossaryTerm(navigate);
	};

	return (
		<Group position="right">
			<Button
				onClick={handleOnClick}
				loading={entityModalStore.loading}
				variant="primary"
			>
				Create term
			</Button>
		</Group>
	);
}

export default observer(GlossaryTermFooter);
