import type { MantineThemeOverride } from '@mantine/core';

export const Card: MantineThemeOverride['components'] = {
	Card: {
		variants: {
			clickable: (theme) => ({
				root: {
					cursor: 'pointer',
					'&:hover': {
						backgroundColor: theme.other.getColor('surface/primary/hover'),
						boxShadow: theme.shadows.sm,
					},
					'&:active': {
						backgroundColor: theme.other.getColor('surface/primary/active'),
					},
				},
			}),
		},
	},
};
