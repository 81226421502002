export const notifications: Array<{
	attribute: string;
	description: string;
	category: string;
	allowedTypes?: Array<'app' | 'email' | 'slack'>;
}> = [
	// General
	{
		attribute: 'notify_announcement',
		description: 'On Announcement',
		category: 'General',
	},
	{
		attribute: 'notify_mentions',
		description: 'On Mentioned',
		category: 'General',
	},
	// Questions
	{
		attribute: 'notify_question_new',
		description: 'New Question Asked',
		category: 'Questions',
	},
	{
		attribute: 'notify_question_owner_assigned',
		description: 'New Question Owner Added',
		category: 'Questions',
	},
	{
		attribute: 'notify_question_reply',
		description: 'New Question Reply',
		category: 'Questions',
	},
	{
		attribute: 'notify_question_reply_edited',
		description: 'Question Reply Edited',
		category: 'Questions',
	},
	{
		attribute: 'notify_question_reply_removed',
		description: 'Question Reply Removed',
		category: 'Questions',
	},
	{
		attribute: 'notify_question_answer',
		description: 'New Question Answer Accepted',
		category: 'Questions',
	},
	// Resources
	{
		attribute: 'notify_resource_description_updated',
		description: 'Resource Description Updated',
		category: 'Resources',
	},
	{
		attribute: 'notify_resource_owner_assigned',
		description: 'Resource Owner Assigned',
		category: 'Resources',
	},
	{
		attribute: 'notify_resource_published',
		description: 'Resource Published',
		category: 'Resources',
	},
	{
		attribute: 'notify_resource_unpublished',
		description: 'Resource Changed to Draft',
		category: 'Resources',
	},
	{
		attribute: 'notify_resource_definition_updated',
		description: 'Resource Documentation Updated',
		category: 'Resources',
	},
	{
		attribute: 'notify_resource_tag_added',
		description: 'Tag Added to Resource',
		category: 'Resources',
	},
	{
		attribute: 'notify_resource_tag_removed',
		description: 'Tag Removed From Resource',
		category: 'Resources',
	},
	{
		attribute: 'notify_resource_collection_added',
		description: 'Resource Added to Collection',
		category: 'Resources',
	},
	{
		attribute: 'notify_resource_collection_removed',
		description: 'Resource Removed From Collection',
		category: 'Resources',
	},
	{
		attribute: 'notify_change_request',
		description: 'Resource Change Request Submitted',
		category: 'Resources',
	},
	{
		attribute: 'notify_applied_change_request',
		description: 'Resource Change Request Applied',
		category: 'Resources',
	},
	{
		attribute: 'notify_resource_archived',
		description: 'Resource Deleted',
		category: 'Resources',
	},
	// Comments
	{
		attribute: 'notify_comment_new_thread',
		description: 'New Comment Thread In Subscribed Document',
		category: 'Comments',
	},
	{
		attribute: 'notify_comment_reply',
		description: 'New Comment Reply In Thread',
		category: 'Comments',
	},
	{
		attribute: 'notify_comment_mention',
		description: 'Mentioned In Comment',
		category: 'Comments',
	},
	{
		attribute: 'notify_comment_reaction',
		description: 'New reactions to your comments',
		category: 'Comments',
	},
	{
		attribute: 'notify_comment_resolved',
		description: 'Comment Thread Resolved',
		category: 'Comments',
	},
	// Digest
	{
		attribute: 'secoda_weekly',
		description: 'Secoda Weekly',
		category: 'Digest',
		allowedTypes: ['email'],
	},
	{
		attribute: 'secoda_monthly',
		description: 'Secoda Monthly',
		category: 'Digest',
		allowedTypes: ['email'],
	},
	// Extractions
	{
		attribute: 'notify_extraction_job_failed',
		description: 'Metadata Extraction Failed',
		category: 'Extractions',
	},
	{
		attribute: 'notify_extraction_job_completed',
		description: 'Metadata Extraction Succeeded',
		category: 'Extractions',
	},
	{
		attribute: 'notify_table_schema_changed',
		description: 'Table Schema Changed',
		category: 'Extractions',
	},
	{
		attribute: 'notify_tables_dropped',
		description: 'Tables Dropped',
		category: 'Extractions',
	},
	// Metadata push
	{
		attribute: 'notify_metadata_push_job_failed',
		description: 'Metadata Metadata Push Failed',
		category: 'Metadata push',
	},
	{
		attribute: 'notify_metadata_push_job_completed',
		description: 'Metadata Metadata Push Succeeded',
		category: 'Metadata push',
	},
	// Teams
	{
		attribute: 'notify_joined_team',
		description: 'Members Joined Team',
		category: 'Teams',
	},
	// Monitoring
	{
		attribute: 'notify_monitor_incident_created',
		description: 'New Incident Created',
		category: 'Monitoring',
	},
	{
		attribute: 'notify_monitor_incident_resolved',
		description: 'Incident Resolved',
		category: 'Monitoring',
	},
];
