/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Group, Skeleton, Tooltip, createStyles } from '@mantine/core';
import { Text, Title } from '@repo/foundations';
import { observer } from 'mobx-react-lite';
import { useLimits } from '../../api/hooks/workspace/useLimits';

const useStyles = createStyles(() => ({
	card: {
		padding: '16px',
		paddingLeft: '24px',
		paddingRight: '24px',
		display: 'flex',
		flexDirection: 'column',
		alignContent: 'flex-start',
		justifyContent: 'space-between',
		borderRadius: '10px',
		backgroundColor: '#F9FAFC',
		flex: 1,
	},
	wrapper: {
		display: 'flex',
		width: '100%',
		gap: '20px',
	},
	subtitle: {
		paddingTop: '8px',
	},
}));

function Card(props: {
	title: string;
	subtitle: string | JSX.Element;
	info?: string | JSX.Element;
	dataTestId?: string;
}) {
	const { title, subtitle, info, dataTestId } = props;
	const { classes, theme } = useStyles();

	return (
		<Tooltip label={info}>
			<Box className={classes.card}>
				<Group spacing={0}>
					<Title color="text/secondary/default" size="md">
						{title}
					</Title>
				</Group>
				<Title
					color="text/primary/default"
					className={classes.subtitle}
					size="md"
					data-testid={dataTestId}
				>
					{subtitle}
				</Title>
			</Box>
		</Tooltip>
	);
}

export const SeatsSettings = observer(() => {
	const { classes } = useStyles();
	const {
		usedEditorOrAdminSeats,
		usedIntegrationSeats,
		usedViewerSeats,
		editorOrAdminSeats,
		viewerSeats,
		integrationSeats,
		isLoading,
	} = useLimits();

	const editorSubtitle =
		editorOrAdminSeats >= 1000 ? (
			<span>Unlimited</span>
		) : (
			`${usedEditorOrAdminSeats}/${editorOrAdminSeats}`
		);
	const viewerSubtitle =
		viewerSeats >= 1000 ? (
			<span>Unlimited</span>
		) : (
			`${usedViewerSeats}/${viewerSeats}`
		);
	const integrationSubtitle =
		integrationSeats >= 1000 ? (
			<span>Unlimited</span>
		) : (
			`${usedIntegrationSeats}/${integrationSeats}`
		);

	return (
		<Skeleton visible={isLoading}>
			<Box className={classes.wrapper}>
				<Card
					dataTestId="editor-seats-card"
					title="Editor seats"
					subtitle={editorSubtitle}
					info={<Text size="xs">Includes editors &#38; admins</Text>}
				/>
				<Card
					dataTestId="viewer-seats-card"
					title="Viewer seats"
					subtitle={viewerSubtitle}
					info={<Text size="xs">Includes viewers &#38; guests</Text>}
				/>
				<Card
					dataTestId="integration-seats-card"
					title="Integrations"
					subtitle={integrationSubtitle}
					info={<Text size="xs">Unique connected integrations</Text>}
				/>
			</Box>
		</Skeleton>
	);
});
