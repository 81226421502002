import { useExtendedUserList } from '../../api/hooks/user/useExtendedUserList';
import { useSlackChannels } from '../../api/hooks/workspace/useSlackChannels';
import type { ISlackChannel, User, UserGroup } from '../../lib/models';
import { selectChannelMap, selectGroupMap, selectUserMap } from './helpers';

type GroupType =
	| 'Workspace admins'
	| 'Workspace editors'
	| 'Workspace viewers'
	| 'Other groups'
	| 'Other slack channels'
	| 'Resource recipients';

export const useAnnouncementGlobalRecipientsList = () => {
	const { activeUsers: usersData, userGroups: groupsData } =
		useExtendedUserList();
	const { data: channelsData } = useSlackChannels({});

	const users = (usersData ?? []) as unknown as User[];
	const groups = (groupsData ?? []) as unknown as UserGroup[];
	const channels = (channelsData ?? []) as unknown as ISlackChannel[];

	const optionsAdmins = users
		.filter((u) => u.role === 'admin')
		.map(selectUserMap<GroupType>('Workspace admins'));

	const optionsEditors = users
		.filter((u) => u.role === 'editor')
		.map(selectUserMap<GroupType>('Workspace editors'));

	const optionsViewers = users
		?.filter((u) => u.role === 'viewer')
		.map(selectUserMap<GroupType>('Workspace viewers'));

	const optionsGroups = groups?.map(selectGroupMap<GroupType>('Other groups'));

	const optionsChannels = channels?.map(
		selectChannelMap<GroupType>('Other slack channels')
	);

	return {
		channels,
		users,
		groups,
		options: [
			...optionsAdmins,
			...optionsEditors,
			...optionsViewers,
			...optionsGroups,
			...optionsChannels,
		],
	};
};
