import { Box, Divider, Skeleton, Stack } from '@mantine/core';
import { useStyles } from './InboxViewBody.styles';

function InboxViewBodySkeleton() {
	const { classes } = useStyles();

	return (
		<Box className={classes.scrollbarContainer} mt={52}>
			<Box className={classes.container}>
				<Stack className={classes.content} spacing="lg">
					<Skeleton height={36} radius="sm" />
					<Divider />
					<Stack spacing="xs">
						<Skeleton height={24} radius="sm" />
						<Skeleton height={24} radius="sm" />
						<Skeleton height={24} radius="sm" />
						<Skeleton height={24} radius="sm" />
					</Stack>
				</Stack>
			</Box>
		</Box>
	);
}

export default InboxViewBodySkeleton;
