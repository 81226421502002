// eslint-disable-next-line no-restricted-imports
import { Group, Text } from '@mantine/core';
import type {
	ApiRestoreEntityFromActivityLogError,
	ApiRestoreEntityFromActivityLogVariables,
} from '@repo/api-codegen';
import { IconButton, type IconNames } from '@repo/foundations';
import type { UseMutateFunction } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import type { IActivity } from '../../api/types/models/notification';
import type { Workspace } from '../../lib/models';
import { buildResourceUrl } from '../../utils/navigationUtils';

// The boolean value marks whether the resource title should be included
// in the notification title.
const suffixes: [string, string, IconNames, string?][] = [
	['collection_removed', 'removed collection', 'folder', 'from'],
	['collection_added', 'added collection', 'folder', 'to'],
	['description_updated', 'updated description', 'cursorText', 'of'],
	['owner_assigned', 'assigned owner', 'user', 'to'],
	['job_completed', 'completed job', 'check', undefined],
	['job_failed', 'failed job', 'handStop', undefined],
	['tag_added', 'tag added', 'folder', 'to'],
	['tag_removed', 'tag removed', 'folder', 'from'],
	['definition_updated', 'updated documentation', 'cursorText', 'of'],
	['applied_change_request', 'applied change request', 'check', 'to'],
];

const events: [string, string, IconNames, string?][] = [
	['mentions', 'mentioned', 'at', 'in'],
	['question_answer', 'answered question', 'clipboardCheck', ''],
	['question_new', 'asked question', 'messagePlus', ''],
	['question_owner_assigned', 'assigned owner', 'user', 'to'],
	['question_reply', 'replied to question', 'messagePlus', ''],
	['question_reply_edited', 'edited a reply to question', 'edit', ''],
	['question_reply_removed', 'removed a reply to question', 'trash', ''],
	['comment_new_thread', 'added a comment', 'message', 'to'],
];

const notificationEvent = (notification: IActivity) => {
	for (const s in suffixes) {
		if (notification.event?.endsWith(suffixes[s][0])) {
			return suffixes[s];
		}
	}

	for (const e in events) {
		if (notification.event === events[e][0]) {
			return events[e];
		}
	}

	return undefined;
};

export const notificationEventToIconName = (notification: IActivity) => {
	const iconName = notificationEvent(notification)?.[2];
	// If we don't find a match, just return a default icon.
	return iconName ?? 'check';
};

export const notificationEventToTitle = (
	notification: IActivity,
	workspace: Workspace,
	restoreEntityFromActivityLog: UseMutateFunction<
		boolean,
		ApiRestoreEntityFromActivityLogError,
		ApiRestoreEntityFromActivityLogVariables,
		unknown
	>,
	withResourceLink = false,
	withPlainResourceText = false,
	textTransform: (text: string) => string = (text) => text
) => {
	if (notification.metadata.no_subject_verb) {
		return `${notification.subject}`;
	}
	const event = notificationEvent(notification);
	const title = event?.[1];

	let suffix;
	if (event?.[3] && notification.resource_title) {
		suffix = (
			<>
				{/* Don't add 'to' when we don't want the link */}
				{withResourceLink && event?.[3]}
				{withResourceLink && (
					<Text style={{ display: 'inline' }} variant="link">
						<Link
							to={buildResourceUrl({
								id: notification.resource_id,
								entity_type: notification.resource_type,
							})}
						>
							{' '}
							{notification.resource_title}
						</Link>
					</Text>
				)}
				{withPlainResourceText && notification.resource_title}
			</>
		);
	}

	const handleRestore = async () => {
		if (!notification.entity_snapshot_key) return;

		restoreEntityFromActivityLog(
			{
				queryParams: {
					entity_snapshot_key: notification.entity_snapshot_key,
				},
			},
			{
				onSuccess: () => {
					window.location.reload();
				},
			}
		);
	};

	const showRestoreButton =
		workspace?.activity_log_restore_enabled && notification.entity_snapshot_key;

	// If we don't find a match, just return the event name.
	return (
		<Group spacing="xs" noWrap>
			{textTransform(title ?? notification.event?.replace('_', ' '))} {suffix}
			{showRestoreButton && (
				<IconButton
					iconName="restore"
					size="sm"
					tooltip="Restore"
					tooltipProps={{ position: 'top' }}
					onClick={handleRestore}
				/>
			)}
		</Group>
	);
};
