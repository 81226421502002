import { createStyles, Modal } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useMemoizedFn } from 'ahooks';
import { isEmpty } from 'lodash-es';
import { useCallback, useState } from 'react';
import type { IBaseModel } from '../../api';
import type { BulkActionsCommandPaletteProps } from './components/BulkActionsCommandPalette/BulkActionsCommandPalette';
import { BulkActionsCommandPalette } from './components/BulkActionsCommandPalette/BulkActionsCommandPalette';
import CommandPalette from './components/CommandPalette';
import SearchPalette from './components/SearchPalette';
import { SPOTLIGHT_WIDTH } from './constants';
import { useSpotlightEvents } from './events';
import type { SpotlightType } from './types';

const useStyles = createStyles({
	header: { display: 'none' },
	modal: { padding: '0 !important' },
	inner: { paddingTop: '80px' },
});

function Spotlight() {
	const { classes } = useStyles();

	const [opened, { open, toggle, close }] = useDisclosure();
	const [paletteType, setPaletteType] = useState<SpotlightType>('search');
	const [paletteProps, setPaletteProps] = useState<
		BulkActionsCommandPaletteProps<IBaseModel>
	>({});

	const createSpotlightEvent = useMemoizedFn(
		(event: VoidFunction) => (type: SpotlightType) => {
			setPaletteType(type);
			event();
		}
	);

	const handleOpen = useCallback(
		({
			type,
			props,
		}: {
			type: SpotlightType;
			props?: BulkActionsCommandPaletteProps<IBaseModel>;
		}) => {
			setPaletteType(type);
			setPaletteProps(props || {});
			open();
		},
		[open]
	);

	useSpotlightEvents({
		open: handleOpen,
		close: createSpotlightEvent(close),
		toggle: createSpotlightEvent(toggle),
	});

	return (
		<Modal
			size={SPOTLIGHT_WIDTH}
			opened={opened}
			onClose={close}
			classNames={{
				header: classes.header,
				body: classes.modal,
				inner: classes.inner,
			}}
			closeOnEscape
			transitionProps={{
				duration: 0,
			}}
			withCloseButton={false}
			radius={12}
			overlayProps={{
				opacity: 0.5,
				blur: 0,
				zIndex: 200,
			}}
			trapFocus
		>
			{paletteType === 'search' && <SearchPalette />}
			{paletteType === 'command' && <CommandPalette />}
			{paletteType === 'bulkActions' && !isEmpty(paletteProps) && (
				<BulkActionsCommandPalette {...paletteProps} />
			)}
		</Modal>
	);
}

export default Spotlight;
