import { createStyles, Tooltip } from '@mantine/core';
import type { SuggestMonitorEntityOut } from '@repo/api-codegen';
import { getBreadCrumbsFromSearchMetadata } from '@repo/common/utils/breadcrumb';
import { Badge, Text } from '@repo/foundations';
import { isEmpty } from 'lodash-es';
import { useCallback, useRef } from 'react';
import { ROW_HEIGHT } from '../../../TableV2/TableV2.styles';
import type { ExtendedDataTableColumn } from '../../../TableV2/types';

const useStyles = createStyles((theme, { level = 0 }: { level?: number }) => ({
	ellipsis: {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
	},
	titleGroup: {
		flexWrap: 'nowrap',
		height: ROW_HEIGHT,
		marginLeft: level * 20,
	},
}));

function TitleRender({ record }: { record: SuggestMonitorEntityOut }) {
	const { classes } = useStyles({});

	return (
		<Text
			size="sm"
			role="link"
			weight="bold"
			className={classes.ellipsis}
			color={
				record.monitor_created
					? 'text/secondary/default'
					: 'text/primary/default'
			}
			sx={{
				lineHeight: `${ROW_HEIGHT}px`,
			}}
		>
			{isEmpty(record.title) ? 'Untitled' : record.title}
		</Text>
	);
}

function ParentRenderer({ record }: { record: SuggestMonitorEntityOut }) {
	const { search_metadata: searchMetadata } = record;
	const breadcrumbs = getBreadCrumbsFromSearchMetadata(searchMetadata);

	const { classes, theme } = useStyles({});

	const textRef = useRef<HTMLDivElement>(null);

	const isEllipsisHidden = useCallback(
		(e: HTMLDivElement | null) => (e ? e.offsetWidth >= e.scrollWidth : false),
		[]
	);

	return (
		<Tooltip
			maw={theme.other.space[90]}
			sx={{
				overflowWrap: 'break-word',
				whiteSpace: 'normal',
			}}
			withinPortal
			hidden={isEllipsisHidden(textRef.current)}
			label={breadcrumbs.join(' / ')}
		>
			<Text
				size="sm"
				ref={textRef}
				className={classes.ellipsis}
				color={
					record.monitor_created
						? 'text/secondary/default'
						: 'text/primary/default'
				}
			>
				{breadcrumbs.join(' / ')}
			</Text>
		</Tooltip>
	);
}

export const COLUMNS: ExtendedDataTableColumn<SuggestMonitorEntityOut>[] = [
	{
		accessor: 'title',
		title: 'Title',
		render: (record) => <TitleRender record={record} />,
		width: 200,
	},
	{
		accessor: 'parent',
		title: 'Parent',
		render: (record) => <ParentRenderer record={record} />,
		width: 160,
	},
	{
		accessor: 'created',
		title: '',
		render: (record) =>
			record.monitor_created && (
				<Badge variant="success">
					<Text size="xs" color="text/secondary/default">
						Created
					</Text>
				</Badge>
			),
		width: 80,
	},
];
