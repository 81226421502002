import { showNotification } from '@mantine/notifications';
import { Button, Icon } from '@repo/foundations';
import { observer } from 'mobx-react-lite';
import type { Monitor } from '../../../api';
import { useRunMonitors } from '../../../api';
import { BackgroundJobProgress } from '../../BackgroundJobProgress/BackgroundJobProgress';

export interface IRunMonitorsProps {
	size?: 'sm' | 'md' | 'lg';
	monitor: Monitor;
	onSuccess?: VoidFunction;
	disabled?: boolean;
	variant?: 'default' | 'primary';
}

function RunMonitors({
	size = 'md',
	monitor,
	onSuccess,
	disabled = false,
	variant = 'default',
}: IRunMonitorsProps) {
	const { backgroundJob, resetBackgroundJob, runMonitors } = useRunMonitors();
	const buttonLabel = 'Run monitor';

	const handleRunMonitors = async () => {
		await runMonitors([monitor.id]);
	};

	const handleBackgroundJobComplete = () => {
		resetBackgroundJob();
		if (onSuccess) {
			onSuccess();
		}

		showNotification({
			title: 'Monitors ran successfully',
			message: 'All the selected monitors ran successfully',
			color: 'green',
			icon: <Icon name="check" />,
		});
	};

	return (
		<>
			{backgroundJob && (
				<BackgroundJobProgress
					key={backgroundJob.id}
					job={backgroundJob}
					onCompleted={handleBackgroundJobComplete}
				/>
			)}
			<Button
				disabled={disabled}
				size={size}
				variant={variant}
				onClick={handleRunMonitors}
			>
				{buttonLabel}
			</Button>
		</>
	);
}

export default observer(RunMonitors);
