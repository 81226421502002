import { Popover, TextInput } from '@mantine/core';
import { useDebouncedValue, useInputState } from '@mantine/hooks';
import { Icon } from '@repo/foundations';
import FlexSearch from 'flexsearch';
import { map, size, trim } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useState } from 'react';
import { lineageStore } from '../store';
import type { LineageSearchResultItem } from '../types';
import { useStyles } from './LineageSearch.styles';
import LineageSearchResult from './LineageSearchResult';
import { getLineageSearchItems } from './utils';

function LineageSearch() {
	const [searchTerm, setSearchTerm] = useInputState('');
	const [debouncedSearchTerm] = useDebouncedValue(searchTerm, 500);
	const [searchResults, setSearchResults] = useState<LineageSearchResultItem[]>(
		[]
	);

	const searchItemsMap = useMemo(
		() =>
			getLineageSearchItems(lineageStore.nodes, lineageStore.nodeChildrenMap),
		[lineageStore.nodes, lineageStore.nodeChildrenMap]
	);
	const searchItems = useMemo(
		() => Array.from(searchItemsMap.values()),
		[searchItemsMap]
	);

	const index = useMemo(() => {
		const idx = new FlexSearch.Index({
			preset: 'memory',
			tokenize: 'forward',
		});
		searchItems.forEach((item) => {
			idx.add(item.id, item.title);
		});
		return idx;
	}, [searchItems]);

	useEffect(() => {
		if (debouncedSearchTerm.trim()) {
			const results = index.search(debouncedSearchTerm, {
				limit: 10,
			}) as string[];
			const matchedItems = results.map((id) =>
				searchItemsMap.get(id)
			) as LineageSearchResultItem[];
			setSearchResults(matchedItems);
		} else {
			setSearchResults([]);
		}
	}, [debouncedSearchTerm, index, searchItems, searchItemsMap]);

	const { classes } = useStyles();

	const opened = size(trim(searchTerm)) > 0 && size(searchResults) > 0;

	const handleClearSearch = () => {
		setSearchTerm('');
	};

	return (
		<Popover
			classNames={{
				dropdown: classes.dropdown,
			}}
			opened={opened}
			position="bottom-start"
		>
			<Popover.Target>
				<TextInput
					classNames={{ root: classes.inputRoot, input: classes.input }}
					icon={<Icon name="search" color="icon/secondary/default" />}
					placeholder="Search lineage"
					value={searchTerm}
					onChange={setSearchTerm}
					data-testid="lineage-search-input-2673fd928"
				/>
			</Popover.Target>
			<Popover.Dropdown>
				{map(searchResults, (item) => (
					<LineageSearchResult
						key={item.id}
						item={item}
						clearSearch={handleClearSearch}
					/>
				))}
			</Popover.Dropdown>
		</Popover>
	);
}

export default observer(LineageSearch);
