import {
	baseQueryHooksFactory,
	prefetchFunctionsFactory,
} from '../../factories';
import type { Incident, Measurement, Monitor } from '../../types';
import {
	incidentsQueryKeyFactory,
	measurementsQueryKeyFactory,
	monitorsQueryKeyFactory,
} from './constants';

const {
	useCreateIncident,
	useDeleteIncident,
	useIncidentInfiniteList,
	useIncidentList,
	useUpdateIncident,
	useIncident,
	fetchIncidentList,
} = baseQueryHooksFactory<Incident, 'incident'>(
	'incident',
	incidentsQueryKeyFactory
);

const {
	useCreateMonitor,
	useDeleteMonitor,
	useMonitorInfiniteList,
	useMonitorList,
	useUpdateMonitor,
	useMonitor,
	fetchMonitorList,
	updateMonitor,
} = baseQueryHooksFactory<Monitor, 'monitor'>(
	'monitor',
	monitorsQueryKeyFactory
);

const {
	useCreateMeasurement,
	useDeleteMeasurement,
	useMeasurementInfiniteList,
	useMeasurementList,
	useUpdateMeasurement,
	useMeasurement,
	fetchMeasurementList,
} = baseQueryHooksFactory<Measurement, 'measurement'>(
	'measurement',
	measurementsQueryKeyFactory
);

const { prefetchMonitor, prefetchMonitorList } =
	prefetchFunctionsFactory<'monitor'>('monitor', monitorsQueryKeyFactory);

export {
	fetchIncidentList,
	fetchMeasurementList,
	fetchMonitorList,
	prefetchMonitor,
	prefetchMonitorList,
	updateMonitor,
	useCreateIncident,
	useCreateMeasurement,
	useCreateMonitor,
	useDeleteIncident,
	useDeleteMeasurement,
	useDeleteMonitor,
	useIncident,
	useIncidentInfiniteList,
	useIncidentList,
	useMeasurement,
	useMeasurementInfiniteList,
	useMeasurementList,
	useMonitor,
	useMonitorInfiniteList,
	useMonitorList,
	useUpdateIncident,
	useUpdateMeasurement,
	useUpdateMonitor,
};

export * from './useAddMonitorItems';
export * from './useIncidentMeasurement';
export * from './useIncidentStatusUpdate';
export * from './useMeasurementsChartData';
export * from './useRunMonitors';
