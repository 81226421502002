import { Group } from '@mantine/core';
import { Button, Text } from '@repo/foundations';
import { useCallback } from 'react';
import type { TopLevelOperatorType } from './types';

interface TopLevelOperatorToggleProps {
	value: TopLevelOperatorType;
	onChange: (value: TopLevelOperatorType) => void;
}

export function TopLevelOperatorToggle({
	value,
	onChange,
}: TopLevelOperatorToggleProps) {
	const onClick = useCallback(() => {
		onChange(value === 'and' ? 'or' : 'and');
	}, [onChange, value]);

	return (
		<Group spacing={0} noWrap align="baseline">
			<Text color="text/secondary/default" size="xs">
				Match
			</Text>
			<Button onClick={onClick} variant="tertiary" size="sm">
				{value === 'and' ? 'All' : 'Any'} filters
			</Button>
		</Group>
	);
}
