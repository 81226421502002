interface CustomFilterIconProps {
	size?: number | string;
	color?: string;
}

function CustomFilterIcon({ size, color }: CustomFilterIconProps) {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M2 6H22M5 12H19M8 18H16"
				stroke={color ?? 'black'}
				strokeWidth="2"
				strokeLinecap="round"
			/>
		</svg>
	);
}

export default CustomFilterIcon;
