import { createStyles } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { Icon } from '@repo/foundations';
import type { QueryKey } from '@tanstack/react-query';
import { useDebounceFn } from 'ahooks';
import { useCallback } from 'react';
import { queryClient } from '../../api';
import { BackgroundJob } from '../../lib/models';
import { closeModal, openModal } from '../ModalManager';
import BackgroundJob2 from './BackgroundJob2';

const useStyles = createStyles({
	inner: {
		top: 'auto',
		justifyContent: 'flex-end',
	},
});

export function useBackgroundJob2(
	invalidationKeys?: QueryKey,
	successMessage?: string,
	hasFile: boolean = false
) {
	const { classes } = useStyles();

	const { run: debounceCompletion } = useDebounceFn(
		() => {
			if (invalidationKeys) {
				queryClient.invalidateQueries(invalidationKeys);
			}
			closeModal('background-job');
			if (!hasFile) {
				// We do not want ot display a message when there's a file to download.
				showNotification({
					title: successMessage ?? 'Background job completed',
					message: 'The background job has completed successfully',
					color: 'green',
					icon: <Icon name="check" />,
				});
			}
		},
		{ wait: 1000 }
	);

	const startJob = useCallback(
		async (jobId: string, fileName?: string) => {
			try {
				const job = new BackgroundJob({ id: jobId });
				await job.sync();
				openModal({
					modalId: 'background-job',
					size: 'sm',
					classNames: classes,
					withCloseButton: false,
					withinPortal: true,
					withOverlay: false,
					children: (
						<BackgroundJob2
							job={job}
							onCompleted={debounceCompletion}
							fileName={fileName}
						/>
					),
				});
			} catch (e) {
				showNotification({
					title: 'Could not process background job',
					message: 'Please contact customer support if the issue continues',
					color: 'red',
				});
			}
		},
		[classes, debounceCompletion]
	);

	return [startJob] as const;
}
