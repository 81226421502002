import { Stack } from '@mantine/core';
import {
	PageLayoutContent,
	PageLayoutContentWrapper,
	PageLayoutOuterWrapper,
	PageLayoutWrapper,
} from '../PageLayout';
import { TableV2Skeleton } from '../TableV2/TableV2Skeleton';

export function EntityListPageSkeleton() {
	return (
		<PageLayoutOuterWrapper>
			<PageLayoutWrapper name="entity-page-skeleton">
				<PageLayoutContentWrapper name="entity-page-skeleton">
					<PageLayoutContent>
						<Stack spacing="md">
							<TableV2Skeleton />
						</Stack>
					</PageLayoutContent>
				</PageLayoutContentWrapper>
			</PageLayoutWrapper>
		</PageLayoutOuterWrapper>
	);
}
