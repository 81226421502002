import type { AutomationPageProps } from './AutomationPage';
import AutomationPage from './AutomationPage';
import { AutomationPageStoreProvider } from './context';

function AutomationPageWrapper({ id: propsId }: AutomationPageProps) {
	return (
		<AutomationPageStoreProvider>
			<AutomationPage id={propsId} />
		</AutomationPageStoreProvider>
	);
}

export default AutomationPageWrapper;
