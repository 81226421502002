import { Stack, useMantineTheme } from '@mantine/core';
import { Text } from '@repo/foundations';

type IEmptySidebarProps = {
	searchTerm: string;
	filter: 'all' | 'resolved' | 'open';
};

function EmptySidebar({ searchTerm, filter }: IEmptySidebarProps) {
	const theme = useMantineTheme();
	let mainText = 'No comments yet';
	let sideText = 'Comments will appear here.';

	if (searchTerm) {
		mainText = 'No results found';
		sideText = 'Try searching for something else.';
	}

	if (filter === 'resolved') {
		mainText = 'No resolved comments yet';
		sideText = 'Resolved comments will appear here.';
	} else if (filter === 'open') {
		mainText = 'No open comments yet';
		sideText = 'Open comments will appear here.';
	}

	return (
		<Stack spacing="xs" align="center" p={theme.spacing.md}>
			<Text weight="bold" size="sm">
				{mainText}
			</Text>
			<Text size="sm">{sideText}</Text>
		</Stack>
	);
}

export default EmptySidebar;
