import { createStyles, Stack } from '@mantine/core';
import { memo } from 'react';
import { AutomationHistoryTable } from '../../components/Automation/AutomationHistoryTable';

export interface AutomationRunHistoryTabProps {
	automationId?: string;
}

const useStyles = createStyles((theme) => ({
	root: {
		height: '100%',
		minHeight: 450,
		flexGrow: 1,
		marginTop: theme.spacing.md,
		width: '100%',
	},
}));

function AutomationRunHistoryTab({
	automationId,
}: AutomationRunHistoryTabProps) {
	const { classes } = useStyles();

	if (!automationId) {
		return null;
	}

	return (
		<Stack className={classes.root}>
			<AutomationHistoryTable automationId={automationId} />
		</Stack>
	);
}

export default memo(AutomationRunHistoryTab);
