import { ActionIcon, Menu, useMantineTheme } from '@mantine/core';

import { Icon, Text } from '@repo/foundations';

export function DotsAction({
	handleDownloadMetric,
	handleDeleteMetric,
	handleCreateStandaloneMetric,
	lastUpdated,
}: {
	handleDownloadMetric?: () => void;
	handleCreateStandaloneMetric?: () => void;
	handleDeleteMetric?: () => void;
	lastUpdated?: string;
}) {
	const theme = useMantineTheme();
	return (
		<Menu shadow="md" width={200}>
			<Menu.Target>
				<ActionIcon data-testid="embed-query-dots">
					<Icon name="dots" />
				</ActionIcon>
			</Menu.Target>
			<Menu.Dropdown>
				{lastUpdated && (
					<>
						<Text
							py={theme.spacing.xs}
							px={theme.spacing.sm}
							size="xs"
							color="text/secondary/default"
						>
							Last updated {lastUpdated}
						</Text>
						<Menu.Divider />
					</>
				)}
				{handleCreateStandaloneMetric && (
					<Menu.Item
						data-testid="embed-query-create-metric"
						onClick={handleCreateStandaloneMetric}
						icon={<Icon name="chartHistogram" />}
					>
						Create metric
					</Menu.Item>
				)}
				{handleDownloadMetric && (
					<Menu.Item
						onClick={handleDownloadMetric}
						icon={<Icon name="download" />}
					>
						Export results as CSV
					</Menu.Item>
				)}
				{handleDeleteMetric && (
					<>
						<Menu.Divider />

						<Menu.Item
							onClick={handleDeleteMetric}
							icon={<Icon name="trash" />}
						>
							Remove
						</Menu.Item>
					</>
				)}
			</Menu.Dropdown>
		</Menu>
	);
}
