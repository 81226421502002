import type { EventType } from '@repo/api-codegen';

export const VISIBLE_AUDIT_ITEM_EVENT_TYPE: EventType[] = [
	'incident.new',
	'incident.resolved',
	'incident.acknowledged',
	'incident.expired',
	'incident.normal',
	'incident.assigned_owner',
];
