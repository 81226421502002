import { Stack } from '@mantine/core';
import { Switch, Title } from '@repo/foundations';
import { useState } from 'react';
import { useMetricLatestLive } from '../../../api/hooks/metric/useMetric';
import type { IInternalIntegrationStatus } from '../../../api/types/models/internalIntegrationStatus';

import { LineageSummaryContent } from './LineageSummaryContent';

export function LineageSummary({
	integrationStatuses,
	workspaceId,
}: {
	workspaceId: string;
	integrationStatuses: IInternalIntegrationStatus[];
}) {
	const [show, setShow] = useState<boolean>(false);

	const { data } = useMetricLatestLive({
		enabled: show,
		name: 'LINEAGE_SUMMARY',
		workspaceId,
		filter: {},
	});

	return (
		<Stack>
			<Title size="md">Show lineage summary</Title>
			<Switch
				checked={show}
				onChange={(e) => setShow(e.currentTarget.checked)}
			/>
			{show && (
				<LineageSummaryContent
					workspaceId={workspaceId}
					integrationStatuses={integrationStatuses}
					result={data}
				/>
			)}
		</Stack>
	);
}
