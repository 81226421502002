import { Icon } from '@repo/foundations';
import type { EditorDictionary, MenuItem } from '@repo/secoda-editor';
import type { EditorState } from 'prosemirror-state';
import isNodeActive from '../queries/isNodeActive';

export default function tableColMenuItems(
	state: EditorState,
	index: number,
	rtl: boolean,
	dictionary: EditorDictionary
): MenuItem[] {
	const { schema } = state;

	return [
		{
			name: 'toggleHeaderColumn',
			tooltip: dictionary.toggleHeader,
			icon: () => <Icon name="tableColumn" color="icon/primary/default" />,
			visible: index === 0,
		},
		{
			name: 'setColumnAttr',
			tooltip: dictionary.alignLeft,
			icon: () => <Icon name="alignLeft" color="icon/primary/default" />,
			attrs: { index, alignment: 'left' },
			active: isNodeActive(schema.nodes.th, {
				colspan: 1,
				rowspan: 1,
				alignment: 'left',
			}),
		},
		{
			name: 'setColumnAttr',
			tooltip: dictionary.alignCenter,
			icon: () => <Icon name="alignCenter" color="icon/primary/default" />,
			attrs: { index, alignment: 'center' },
			active: isNodeActive(schema.nodes.th, {
				colspan: 1,
				rowspan: 1,
				alignment: 'center',
			}),
		},
		{
			name: 'setColumnAttr',
			tooltip: dictionary.alignRight,
			icon: () => <Icon name="alignRight" color="icon/primary/default" />,
			attrs: { index, alignment: 'right' },
			active: isNodeActive(schema.nodes.th, {
				colspan: 1,
				rowspan: 1,
				alignment: 'right',
			}),
		},
		{
			name: 'separator',
		},
		{
			name: rtl ? 'addColumnAfter' : 'addColumnBefore',
			tooltip: rtl ? dictionary.addColumnAfter : dictionary.addColumnBefore,
			icon: () => <Icon name="columnInsertLeft" color="icon/primary/default" />,
			active: () => false,
			attrs: { index },
		},
		{
			name: rtl ? 'addColumnBefore' : 'addColumnAfter',
			tooltip: rtl ? dictionary.addColumnBefore : dictionary.addColumnAfter,
			icon: () => (
				<Icon name="columnInsertRight" color="icon/primary/default" />
			),
			active: () => false,
			attrs: { index },
		},
		{
			name: 'separator',
		},
		{
			name: 'deleteColumn',
			tooltip: dictionary.deleteColumn,
			icon: () => <Icon name="trash" color="icon/primary/default" />,
			active: () => false,
		},
	];
}
