import { Group, Loader } from '@mantine/core';
import type { DataQualityScore } from '@repo/api-codegen';
import TabsList from '@repo/common/components/TabsList';
import { getScoreClassification } from '@repo/common/utils/dataQuality';
import { observer } from 'mobx-react-lite';
import { memo, useMemo } from 'react';
import type { IIntegration } from '../../api';
import { useAuthUser, useIntegrationSupport } from '../../api';
import { useFeatureAccess } from '../../api/hooks/workspace/useFeatureAccess';
import { DataQualityBadge } from '../../components/DataQualityScore/DataQualityBadge';
import { withParanthesis } from '../../utils/shared.utils';
import type { TableEntityTabsStore } from './TableEntityTabs/TableEntityTabsStore';

// eslint-disable-next-line react-refresh/only-export-components
const TabLoader = memo(() => <Loader size={12} />);
TabLoader.displayName = 'TabLoader';

export interface TableEntityTabsListProps {
	store: TableEntityTabsStore;
	dataQuality: DataQualityScore | null;
	showPreviewTab: boolean;
	integration: IIntegration | null;
}

// eslint-disable-next-line react-refresh/only-export-components
function TableEntityTabsList({
	store,
	dataQuality,
	showPreviewTab,
	integration,
}: TableEntityTabsListProps) {
	const { workspace } = useAuthUser();
	const { monitoringAccess } = useFeatureAccess();
	const { data: integrationSupport } = useIntegrationSupport();

	const integrationKeys = useMemo(
		() => (integrationSupport ? Object.keys(integrationSupport) : []),
		[integrationSupport]
	);

	const testsActive = (store?.testCount ?? 0) > 0;
	const queriesActive = (store?.queryCount ?? 0) > 0;

	const isMonitoringEnabled =
		Boolean(workspace?.monitoring_enabled) &&
		Boolean(monitoringAccess) &&
		integrationKeys.includes(integration?.type ?? '');
	const incidentsActive = (store?.incidentsCount ?? 0) > 0;

	const discussionsCount = withParanthesis(store.discussionCount);

	const tabsList = useMemo(
		() => [
			{
				value: 'columns',
				icon: store.isColumnLoading && <TabLoader />,
				label: 'Columns',
			},
			{
				value: 'lineage',
				label: 'Lineage',
			},
			{
				value: 'monitors',
				label: 'Monitors',
				hidden: !isMonitoringEnabled,
			},
			{
				value: 'incidents',
				label: 'Incidents',
				hidden: !isMonitoringEnabled || !incidentsActive,
			},
			{
				value: 'tests',
				disabled: store.isTestLoading,
				icon: store.isTestLoading && <TabLoader />,
				label: 'Tests',
				hidden: !store.isTestLoading && !testsActive,
			},
			{
				value: 'queries',
				label: 'Queries',
				hidden: !store.isQueryLoading && !queriesActive,
			},
			{
				value: 'preview',
				hidden: !showPreviewTab,
				label: 'Preview',
			},
			{
				value: 'discussions',
				icon: store.isDiscussionLoading && <TabLoader />,
				label: `Questions ${discussionsCount}`,
			},
			{
				value: 'documentation',
				label: 'Documentation',
			},
			...(dataQuality !== null
				? [
						{
							value: 'quality',
							label: (
								<Group spacing="xs" noWrap>
									<span>Quality</span>
									<DataQualityBadge
										classification={getScoreClassification(dataQuality?.total)}
										my={0}
									/>
								</Group>
							),
						},
					]
				: []),
		],
		[
			store.isColumnLoading,
			store.isLineageLoading,
			store.isTestLoading,
			store.isQueryLoading,
			store.isDiscussionLoading,
			isMonitoringEnabled,
			incidentsActive,
			testsActive,
			queriesActive,
			showPreviewTab,
			discussionsCount,
			dataQuality,
		]
	);

	if (!store) {
		return null;
	}

	return <TabsList tabs={tabsList} />;
}

// eslint-disable-next-line react-refresh/only-export-components
export default observer(TableEntityTabsList);
