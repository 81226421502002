import { Box, createStyles } from '@mantine/core';
import { observer } from 'mobx-react-lite';
import { useNavigate, useParams } from 'react-router';
import { useAuthUser, useUser } from '../../api';
import ProfileHeader from './components/ProfileHeader';
import ProfileTabs from './components/ProfileTabs';

const useStyles = createStyles(() => ({
	wrapper: {
		padding: 42,
	},
}));

function ProfilePage() {
	const styles = useStyles();
	const { id } = useParams();

	const { user: currentUser, isGuestUser } = useAuthUser();

	const { data: user, isLoading } = useUser({
		id: id!,
		options: { enabled: !!id },
	});

	const navigate = useNavigate();
	if (isGuestUser && currentUser?.id !== id) {
		navigate('/403');
	}

	if (isLoading || !user) {
		return null;
	}

	return (
		<Box className={styles.classes.wrapper}>
			<ProfileHeader user={user} />
			<ProfileTabs userId={user.id} />
		</Box>
	);
}

export default observer(ProfilePage);
