import { Code, createStyles, Modal, Stack } from '@mantine/core';
import { Title } from '@repo/foundations';
import type { IEmbeddedPromptResponseStep } from '../../../api';
import { RichEditor } from '../../RichEditor';
import type { AIStepData } from '../utils';
import { extractStepData } from '../utils';

const useStyles = createStyles((theme) => ({
	wrapper: {
		gap: theme.spacing.xs,
		paddingTop: theme.spacing.md,
	},
	sql: {
		backgroundColor: theme.other.getColor('surface/secondary/default'),

		'.code-block': {
			margin: 0,

			pre: {
				margin: 0,
			},
		},
	},
	code: {
		whiteSpace: 'break-spaces',
		padding: theme.spacing.md,
		backgroundColor: theme.other.getColor('surface/secondary/default'),
	},
	richEditor: {
		padding: theme.spacing.md,
		backgroundColor: theme.other.getColor('surface/secondary/default'),
	},
	modalContent: {
		borderRadius: theme.radius.lg,
	},
	modalHeader: {
		backgroundColor: theme.other.getColor('surface/secondary/default'),
	},
	modalTitle: {
		fontWeight: 'bold',
	},
}));

interface AIStepModalProps {
	step: IEmbeddedPromptResponseStep;
	onClose: VoidFunction;
}

function ContentGeneral(data: AIStepData) {
	const { classes } = useStyles();

	const { functionCall, definition } = data;

	return (
		<Stack className={classes.wrapper}>
			<Title order={3}>Function Call</Title>
			<Code className={classes.code} block>
				{functionCall}
			</Code>
			<Title order={3}>Result</Title>
			<div className={classes.richEditor}>
				<RichEditor initialValue={definition ?? ''} readOnly />
			</div>
		</Stack>
	);
}

function ContentSQL(data: AIStepData) {
	const { classes } = useStyles();

	const { functionCall, definition } = data;

	const sql = `\`\`\`sql\n${functionCall}\n\`\`\``;

	return (
		<Stack className={classes.wrapper}>
			<Title order={3}>SQL Query</Title>
			<div className={classes.sql}>
				<RichEditor
					initialValue={sql}
					readOnly
					disableExtensions={['trailing_node']}
				/>
			</div>
			<Title order={3}>Result</Title>
			<div className={classes.richEditor}>
				<RichEditor initialValue={definition ?? ''} readOnly />
			</div>
		</Stack>
	);
}

export function AIStepModal({ step, onClose }: AIStepModalProps) {
	const { classes } = useStyles();

	const data = extractStepData(step);

	return (
		<Modal
			classNames={{
				title: classes.modalTitle,
				content: classes.modalContent,
				header: classes.modalHeader,
			}}
			opened
			size="xl"
			title={data.title}
			onClose={onClose}
		>
			{data.format === 'General' && <ContentGeneral {...data} />}
			{data.format === 'SQL' && <ContentSQL {...data} />}
		</Modal>
	);
}
