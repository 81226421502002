import { Box, Stack } from '@mantine/core';
import { EmptyState } from '@repo/common/components';
import { useAIAssistantContext } from '../context';
import { AIAssistantMode } from '../types';
import { useStyles } from './AIChatEmpty.styles';
import { PromptSuggestions } from './PromptSuggestions';

type AIChatEmptyProps = {
	onPromptChange: (prompt: string) => void;
};

function AIChatEmpty({ onPromptChange }: AIChatEmptyProps) {
	const { classes } = useStyles();

	const { mode } = useAIAssistantContext();

	const handlePromptClick = (prompt: string) => {
		onPromptChange(prompt);
	};

	return (
		<Stack className={classes.root}>
			<Box className={classes.emptyStateContainer}>
				<EmptyState
					className={classes.emptyState}
					illustrationName="ai"
					title="Hi, I'm Secoda AI"
					description="Ask me anything about your company data."
					includeGoBack={false}
					size="lg"
					descWidth="100%"
					width="100%"
				/>
			</Box>
			{mode !== AIAssistantMode.SIDEBAR && (
				<PromptSuggestions onPromptClick={handlePromptClick} />
			)}
		</Stack>
	);
}

export default AIChatEmpty;
