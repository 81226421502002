import { FloatingFocusManager } from '@floating-ui/react';
import type { BoxProps } from '@mantine/core';
import { Box } from '@mantine/core';
import { isNil } from 'lodash-es';
import type { PropsWithChildren } from 'react';
import { useCallback, useEffect } from 'react';
import { useListBoxContext } from './context';
import { useListBoxStyles } from './ListBox.styles';
import type { ListBoxItemProps } from './ListBoxItem';
import { ListBoxItem } from './ListBoxItem';
import { ListBoxPortal } from './ListBoxPortal';
import { ListBoxSearch } from './ListBoxSearch';

interface ListBoxItemsDropdownProps<T = ListBoxItemProps> extends BoxProps {
	search?: React.ComponentProps<typeof ListBoxSearch>;
	renderItem?: (
		item: T,
		getItemProps: (
			userProps: Omit<React.HTMLProps<HTMLElement>, 'ref'>
		) => Omit<React.HTMLProps<HTMLElement>, 'ref'>,
		index: number
	) => React.ReactElement;
	items?: Array<T>;
	usePortal?: boolean;
}

export function ListBoxItemsDropdown<T = ListBoxItemProps>({
	children,
	search,
	renderItem,
	items,
	usePortal,
	...boxProps
}: PropsWithChildren<ListBoxItemsDropdownProps<T>>) {
	const { classes, cx, theme } = useListBoxStyles();
	const { className, ...rest } = boxProps;

	const {
		opened,
		disabled,
		floatingContext,
		dropdownId,
		refs,
		floatingStyles,
		targetId,
		getFloatingProps,
		onKeyDown,
		activeIndex,
		getItemProps,
		setActiveIndex,
		listRef,
	} = useListBoxContext();

	const searchOnKeyDown = useCallback(
		(e: React.KeyboardEvent<HTMLInputElement>) => {
			search?.onKeyDown?.(e);

			if (e.key === 'Enter' && !isNil(activeIndex)) {
				e.preventDefault();
				e.stopPropagation();
				listRef.current?.[activeIndex]?.click();
			}
		},
		[search, activeIndex, listRef]
	);

	useEffect(() => {
		setActiveIndex(0);
	}, [items]);

	if (!opened || disabled) {
		return null;
	}

	return (
		<ListBoxPortal enabled={usePortal}>
			<FloatingFocusManager
				context={floatingContext}
				modal={false}
				initialFocus={-1}
			>
				<Box
					{...rest}
					id={dropdownId}
					className={cx(classes.dropdown, className)}
					ref={refs.setFloating}
					style={{
						...floatingStyles,
						paddingTop: search ? undefined : theme.spacing['2xs'],
					}}
					aria-labelledby={targetId}
					{...getFloatingProps({
						onKeyDown,
					})}
				>
					{search && <ListBoxSearch {...search} onKeyDown={searchOnKeyDown} />}
					<Box style={{ overflow: 'auto', flex: 1 }}>
						{items?.map((option, index) =>
							renderItem ? (
								renderItem(
									option,
									({ onClick, ...userProps } = {}) => ({
										...userProps,
										tabIndex: activeIndex === index ? 0 : -1,
										'data-hovered': activeIndex === index ? true : undefined,
										'data-index': index,
										...getItemProps({
											ref: (el: HTMLButtonElement) => {
												listRef.current[index] = el;
											},
											onClick,
										}),
									}),
									index
								)
							) : (
								<ListBoxItem
									key={index}
									{...{
										...option,
										tabIndex: activeIndex === index ? 0 : -1,
										'data-hovered': activeIndex === index ? true : undefined,
										'data-index': index,
										...getItemProps({
											ref: (el: HTMLButtonElement) => {
												listRef.current[index] = el;
											},
											onClick:
												!!option &&
												typeof option === 'object' &&
												'onClick' in option
													? (option.onClick as never)
													: undefined,
										}),
									}}
								/>
							)
						)}
						{children}
					</Box>
				</Box>
			</FloatingFocusManager>
		</ListBoxPortal>
	);
}
