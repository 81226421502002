import { Group } from '@mantine/core';
import { IconSkeleton, TitleSkeleton } from '@repo/foundations';

export function EntityPageTitleSkeleton() {
	return (
		<Group spacing="xs">
			<IconSkeleton size={28} />
			<TitleSkeleton size="xl" text="Entity name" />
		</Group>
	);
}
