import { Title } from '@repo/foundations';
import {
	NavBarBreadcrumbWrapper,
	NavBarRightSideWrapper,
	NavBarWrapper,
} from '.';
import { useAuthUser } from '../../../api';
import Announcement from '../../Announcement';

function InboxPageNavBar() {
	const { isEditorOrAdminUser } = useAuthUser();
	return (
		<NavBarWrapper>
			<NavBarBreadcrumbWrapper>
				<Title order={1} size="sm" data-testid="inbox-page-nav-bar-title">
					Inbox
				</Title>
			</NavBarBreadcrumbWrapper>
			<NavBarRightSideWrapper>
				{isEditorOrAdminUser && <Announcement />}
			</NavBarRightSideWrapper>
		</NavBarWrapper>
	);
}

export default InboxPageNavBar;
