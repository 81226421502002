import type {
	AIChatPreview,
	EntityPreview,
	MonitorPreview,
	UserPreview,
} from './ResourcePreview.types';

export const isUserPreview = (
	data: Record<string, unknown>
): data is UserPreview => 'display_name' in data;

export const isMonitorPreview = (
	data: Record<string, unknown>
): data is MonitorPreview => 'status' in data;

export const isAIChatPreview = (
	data: Record<string, unknown>
): data is AIChatPreview => 'prompt' in data;

export const isEntityPreview = (
	data: Record<string, unknown>
): data is EntityPreview => 'display_metadata' in data;
