import { observer } from 'mobx-react-lite';
import type { Automation } from '../../../../api';

import { AutomationActionCardType } from '../../constants';
import AutomationActionCardEditHeaderText from './Edit/AutomationActionCardEditHeaderText';
import AutomationActionCardFilterHeaderText from './Filter/AutomationActionCardFilterHeaderText';
import AutomationActionCardAnnouncementHeaderText from './Notification/announcement/AutomationActionCardAnnouncementHeaderText';
import AutomationActionCardEmailHeaderText from './Notification/email/AutomationActionCardEmailHeaderText';
import AutomationActionCardSlackHeaderText from './Notification/slack/AutomationActionCardSlackHeaderText';
import AutomationActionCardPropagateMetadataHeaderText from './PropagateMetadata/AutomationActionCardPropagateMetadataHeaderText';

interface AutomationActionCardHeaderTextProps {
	type: AutomationActionCardType;
	cardId: string;
	searchKey?: string;
	isSecondFilterResourceCard?: boolean;
	isNthFilterResourceCard?: boolean;
	handleAutomationUpdate: (automation: Partial<Automation>) => Promise<void>;
}

function AutomationActionCardHeaderText({
	type,
	cardId,
	searchKey,
	isSecondFilterResourceCard,
	isNthFilterResourceCard,
	handleAutomationUpdate,
}: AutomationActionCardHeaderTextProps) {
	if (type === AutomationActionCardType.FILTER_RESOURCES) {
		return (
			<AutomationActionCardFilterHeaderText
				cardId={cardId}
				filterSetSearchKey={searchKey}
				isSecondFilterResourceCard={isSecondFilterResourceCard}
				isNthFilterResourceCard={isNthFilterResourceCard}
				handleAutomationUpdate={handleAutomationUpdate}
			/>
		);
	}

	if (type === AutomationActionCardType.EDIT_RESOURCES) {
		return (
			<AutomationActionCardEditHeaderText
				cardId={cardId}
				handleAutomationUpdate={handleAutomationUpdate}
			/>
		);
	}

	if (type === AutomationActionCardType.SEND_ANNOUNCEMENT) {
		return (
			<AutomationActionCardAnnouncementHeaderText
				cardId={cardId}
				handleAutomationUpdate={handleAutomationUpdate}
			/>
		);
	}

	if (type === AutomationActionCardType.SEND_EMAIL) {
		return (
			<AutomationActionCardEmailHeaderText
				cardId={cardId}
				handleAutomationUpdate={handleAutomationUpdate}
			/>
		);
	}

	if (type === AutomationActionCardType.SEND_SLACK_MESSAGE) {
		return (
			<AutomationActionCardSlackHeaderText
				cardId={cardId}
				handleAutomationUpdate={handleAutomationUpdate}
			/>
		);
	}

	if (type === AutomationActionCardType.PROPAGATE_METADATA) {
		return (
			<AutomationActionCardPropagateMetadataHeaderText
				cardId={cardId}
				handleAutomationUpdate={handleAutomationUpdate}
			/>
		);
	}

	throw new Error('Invalid automation action type has been provided');
}

export default observer(AutomationActionCardHeaderText);
