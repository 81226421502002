import { Group } from '@mantine/core';
import { Selector, SelectorTarget } from '@repo/common/components';
import { SelectType } from '@repo/common/components/Selectors/types';
import { truncateWithEllipsis } from '@repo/common/utils';
import { Icon, Text } from '@repo/foundations';
import { useCallback } from 'react';
import { type IMetric } from '../../../api';
import type { Metric } from '../../../lib/models';
import { useQueryableIntegrationOptions } from './IntegrationSelectAction.hooks';

export function IntegrationSelectAction({
	onChange,
	metric,
	disabled = false,
}: {
	onChange: (value: string) => void;
	metric: IMetric | Metric;
	disabled?: boolean;
}) {
	const data = useQueryableIntegrationOptions();

	const selected = data.find((item) => item.value === metric.integration);
	const placeholder = truncateWithEllipsis(
		selected ? selected.label : 'Select integration',
		19
	);

	const getItems = useCallback(
		(searchTerm: string) =>
			Promise.resolve(
				data.filter((item) => !searchTerm || item.label.includes(searchTerm))
			),
		[data]
	);

	const handleOnChange = useCallback(
		(value: string[]) => {
			onChange(value?.[0] ?? '');
		},
		[onChange]
	);

	return (
		<Selector
			initialValue={[metric.integration]}
			getItems={getItems}
			onChange={handleOnChange}
			selectType={SelectType.Single}
			disabled={disabled}
			disableSearch
		>
			<SelectorTarget>
				<Group spacing="2xs" miw={80}>
					{selected?.icon ?? <Icon name="database" />}
					<Text size="sm">{placeholder}</Text>
				</Group>
			</SelectorTarget>
		</Selector>
	);
}
