import { indexOf } from 'lodash-es';
import { memo } from 'react';
import { DATA_QUALITY_TOP_KEYS } from '../constants';
import type { DataQualityBreakdownItem } from '../types';
import { DataQualityBreakdownBody } from './DataQualityBreakdownBody';
import { DataQualityBreakdownItem as DataQualityBreakdownItemComp } from './DataQualityBreakdownItem';

export interface DataQualityBreakdownProps {
	items: DataQualityBreakdownItem[];
	onReportPage?: boolean;
}

export const DataQualityBreakdown = memo(
	({ items, onReportPage = false }: DataQualityBreakdownProps) => (
		<DataQualityBreakdownBody>
			{items
				.sort(
					(a, b) =>
						indexOf(DATA_QUALITY_TOP_KEYS, a.key) -
						indexOf(DATA_QUALITY_TOP_KEYS, b.key)
				)
				.map((item) => (
					<DataQualityBreakdownItemComp
						key={item.key}
						item={item}
						onReportPage={onReportPage}
					/>
				))}
		</DataQualityBreakdownBody>
	)
);
DataQualityBreakdown.displayName = 'DataQualityBreakdown';
