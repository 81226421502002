import { Box, Collapse, Group, Menu, Stack, createStyles } from '@mantine/core';
import { useHover } from '@mantine/hooks';
import { useLocalStorageBooleanState } from '@repo/common/hooks/useLocalStorageBooleanState';
import { Icon, IconButton, Text, Title } from '@repo/foundations';
import type { ITagGroup } from '../../../../api';
import { invalidateTagList, useDeleteTagGroup } from '../../../../api';
import {
	closeAllModals,
	openDeleteConfirmModal,
	openModal,
} from '../../../ModalManager';
import { TagGroupModal } from '../Modal/TagGroupModal';
import TagCard from './TagCard';

interface ITagGroupProps {
	tagGroup: ITagGroup;
	allTags: Array<string>;
	allGroups: Array<string>;
}

const useStyles = createStyles((theme) => ({
	container: {
		padding: theme.spacing.sm,
		height: theme.other.space[10],
	},
	innerTag: {
		paddingLeft: theme.other.space[6],
	},
}));

function TagGroupCard({ tagGroup, allGroups, allTags }: ITagGroupProps) {
	const { classes } = useStyles();
	const { hovered, ref } = useHover();

	const [stackOpened, { toggle: toggleStack }] = useLocalStorageBooleanState(
		tagGroup.id,
		false
	);

	const ToggleIcon = stackOpened
		? () => <Icon name="chevronDown" color="icon/primary/default" />
		: () => (
				<Icon
					name="chevronRight"
					color={
						tagGroup.tags?.length === 0
							? 'icon/primary/disabled'
							: 'icon/primary/default'
					}
				/>
			);

	const { mutateAsync: deleteTagGroup } = useDeleteTagGroup({
		options: {
			onSuccess: invalidateTagList,
		},
	});

	const openDeleteModal = () =>
		openDeleteConfirmModal({
			title: `Delete ${tagGroup.name}`,
			description:
				"This grouping will be removed from all tags. This action can't be undone.",
			onConfirm: () => {
				deleteTagGroup({
					id: tagGroup.id,
				});
			},
		});

	const handleClickKebabMenu = (e: React.MouseEvent) => {
		e.stopPropagation();
		e.preventDefault();
	};

	const handleClickEdit = (e: React.MouseEvent) => {
		e.stopPropagation();
		e.preventDefault();
		openModal({
			title: 'Edit tag group',
			children: (
				<TagGroupModal
					tagGroup={tagGroup}
					allGroups={allGroups}
					onClose={closeAllModals}
				/>
			),
		});
	};

	const handleClickDelete = (e: React.MouseEvent) => {
		e.stopPropagation();
		e.preventDefault();
		openDeleteModal();
	};

	return (
		<>
			<Group
				className={classes.container}
				ref={ref}
				position="apart"
				noWrap
				onClick={() => (tagGroup.tags?.length === 0 ? null : toggleStack())}
			>
				<Group spacing="xs" noWrap>
					<ToggleIcon />
					<Title size="sm" lineClamp={1}>
						{tagGroup.name}&nbsp;
						<Text span color="text/secondary/default" size="sm">
							({tagGroup.tags?.length || 0})
						</Text>
					</Title>
				</Group>
				<Group spacing={0} noWrap>
					{hovered && (
						<IconButton
							onClick={handleClickEdit}
							iconName="pencil"
							variant="tertiary"
						/>
					)}
					<Menu>
						<Menu.Target>
							<Box onClick={handleClickKebabMenu}>
								{hovered && <IconButton iconName="dots" variant="tertiary" />}
							</Box>
						</Menu.Target>
						<Menu.Dropdown>
							<Menu.Item
								icon={<Icon name="pencil" />}
								onClick={handleClickEdit}
							>
								Edit group
							</Menu.Item>
							<Menu.Item
								icon={<Icon name="trash" />}
								onClick={handleClickDelete}
							>
								Delete group
							</Menu.Item>
						</Menu.Dropdown>
					</Menu>
				</Group>
			</Group>
			<Collapse in={Boolean(stackOpened)}>
				<Stack spacing={0} className={classes.innerTag}>
					{tagGroup.tags?.map((tag) => (
						<TagCard key={tag.id} tag={tag} allTags={allTags} />
					))}
				</Stack>
			</Collapse>
		</>
	);
}

export default TagGroupCard;
