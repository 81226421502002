import type { MantineTheme } from '@mantine/core';
import { Avatar, createStyles } from '@mantine/core';
import { type ButtonVariants, Text } from '@repo/foundations';
import type { TablerIconsProps } from '@tabler/icons-react';
import { isNil, map, omitBy, size, slice, upperFirst } from 'lodash-es';
import type { ReactNode } from 'react';
import { pickColorType, pluralize, singularize } from '../../utils';
import GroupIcon from '../GroupIcon/GroupIcon';
import { IconEmoji } from '../IconEmoji/IconEmoji';
import type { ItemIconType } from '../ItemIcon';
import type { SelectablePropertyType } from '../SelectableProperty/types';
import type { SelectablePropertyItem } from '../SingleSelector/types';
import TagIcon from '../TagIcon/TagIcon';
import { colorGroupToFillMap } from '../UserAvatar/helpers';

interface IMultiTargetButtonProps {
	selected: SelectablePropertyItem[];
	property: SelectablePropertyType;
	propertyLabel?: string;
	iconType: ItemIconType;
	variant?: ButtonVariants;
	onTargetClick?: () => void;
}

const useStyles = createStyles((theme: MantineTheme) => ({
	root: {
		outlineWidth: 2,
		outlineStyle: 'solid',
		outlineColor: 'white',
	},
	avatarLabel: {
		fontSize: '0.6rem',
		lineHeight: theme.fontSizes.xs,
	},
	emoji: {
		fontSize: theme.spacing.sm,
		lineHeight: theme.spacing.sm,
	},
}));

function MultiTargetButton({
	selected,
	property,
	propertyLabel,
	iconType,
	variant = 'default',
	onTargetClick,
}: IMultiTargetButtonProps) {
	const { classes, theme } = useStyles();
	const iconHeight =
		iconType === 'tag' ? theme.other.space[3] : theme.other.space[4];

	return (
		<Avatar.Group
			ml={iconType !== 'tag' ? 2 : 0}
			pl={iconType === 'none' ? 0 : undefined}
			spacing="xs"
			my="auto"
			onClick={onTargetClick}
		>
			{iconType !== 'none' &&
				map(slice(selected, 0, 3), (item) => {
					const sx: Record<string, string | null | undefined> = {
						backgroundColor: theme.other.getColor('surface/primary/active'),
					};
					const isGroup =
						item.navigateTo && item.navigateTo.includes('/group/');

					if (iconType === 'avatar') {
						const colorGroup = isGroup
							? 'lightGray'
							: pickColorType(item.label);
						const { fillStart, fillEnd, textColor } =
							colorGroupToFillMap(colorGroup);
						sx.backgroundImage = theme.fn.gradient({
							from: fillStart,
							to: fillEnd,
							deg: 180,
						});
						sx.color = textColor;
					} else if (iconType === 'tabler') {
						sx.backgroundColor = item.color as string;
					} else if (iconType === 'tag') {
						sx.backgroundColor = item.color as string;
					}

					let icon: ReactNode;
					if (iconType === 'avatar') {
						if (isGroup) {
							icon = <GroupIcon item={item} size="xs" />;
						} else {
							icon =
								(item.icon as ReactNode) || upperFirst(item.label?.slice(0, 1));
						}
					} else if (iconType === 'tag') {
						icon = <TagIcon color={item.color as string} />;
					} else if (iconType === 'tabler') {
						const Icon = item.icon as (props: TablerIconsProps) => JSX.Element;
						icon = (
							<Icon
								height={`${iconHeight}px`}
								width={`${iconHeight}px`}
								size={iconHeight}
								color={sx?.color ?? item.color}
							/>
						);
					} else if (typeof item.icon === 'string') {
						icon = (
							<IconEmoji
								value={item.icon as string}
								size={iconHeight}
								padding={0}
							/>
						);
					} else {
						icon = (
							<Text className={classes.emoji}>{item.icon as ReactNode}</Text>
						);
					}

					return (
						<Avatar
							key={`${item.value}`}
							classNames={{
								root: classes.root,
								placeholder: classes.avatarLabel,
							}}
							src={iconType === 'avatar' ? (item.icon as string) : undefined}
							miw={`${iconHeight}px`}
							mih={`${iconHeight}px`}
							h={`${iconHeight}px`}
							w={`${iconHeight}px`}
							size="sm"
							sx={omitBy(sx, isNil)}
						>
							{icon}
						</Avatar>
					);
				})}
			<Text
				ml={iconType !== 'none' ? 4 : 0}
				size="sm"
				style={{ lineHeight: `${iconHeight}px` }}
				color={
					variant === 'primary'
						? 'text/brand-on-fill/default'
						: 'text/primary/default'
				}
			>
				{`${size(selected)} ${
					size(selected) === 1
						? singularize(propertyLabel ?? property)
						: pluralize(propertyLabel ?? property)
				}`}
			</Text>
		</Avatar.Group>
	);
}

export default MultiTargetButton;
