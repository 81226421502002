import type {
	IBaseModelInfiniteListQueryArgs,
	ISecodaEntity,
} from '../../types';
import { useBaseModelInfiniteList } from '../base';
import {
	SECODA_ENTITIES_NAMESPACE,
	secodaEntitiesQueryKeyFactory,
} from './constants';

function useSecodaEntityInfiniteList<
	TApiResponseData extends ISecodaEntity,
	TError = unknown,
>({
	queryKey,
	namespace = SECODA_ENTITIES_NAMESPACE,
	options = {},
	filters = {},
}: IBaseModelInfiniteListQueryArgs<TApiResponseData, TError>) {
	const entityQueryKey = queryKey ?? secodaEntitiesQueryKeyFactory.allLists();

	return useBaseModelInfiniteList<TApiResponseData, TError>({
		queryKey: entityQueryKey,
		namespace,
		options,
		filters,
	});
}

export default useSecodaEntityInfiniteList;
