import type { EntityType } from '@repo/common/enums/entityType';
import { queryClient, searchQueryKeyFactory } from '../../api';
import { createQueryKeys } from '../../api/factories';
import { getSearchFilters, search } from '../../lib/models';

export const SEARCH_QUERY_CLIENT_OPTIONS = {
	staleTime: 15 * 1000 * 60, // 15 minutes
	cacheTime: 15 * 1000 * 60, // 15 minutes
};

export const SEARCH_FILTERS_NAMESPACE = ['search', 'filters'];
export const SEARCH_FILTERS_QUERY_KEY = createQueryKeys(
	SEARCH_FILTERS_NAMESPACE
);

export const getSearchResults = async (term: string, page = 1) => {
	const filters = {
		search_term: term,
		page,
	};

	const response = await queryClient.fetchQuery({
		queryKey: searchQueryKeyFactory.list(page, filters),
		queryFn: async () => {
			const { data } = await search(filters);
			return data;
		},
	});

	return {
		results: response.results,
		totalPages: response.total_pages,
	};
};

export const getEntityFilters = (): Promise<
	Record<
		'databases' | 'groups' | 'schemas' | 'native_types',
		{
			label: string;
			value: string;
			entityType?: EntityType;
		}[]
	>
> =>
	queryClient
		.fetchQuery({
			queryKey: SEARCH_FILTERS_QUERY_KEY.list(1, {}),
			queryFn: () => getSearchFilters(),
			...SEARCH_QUERY_CLIENT_OPTIONS,
		})
		.catch(() => ({
			databases: [],
			schemas: [],
			groups: [],
			native_types: [],
		}));
