import dayjs from 'dayjs';
import type { IInternalDatabuilderJobStatus } from '../../../api/types/models/internalIntegrationStatus';

function isWithinLookback(
	job: IInternalDatabuilderJobStatus,
	lastNumDays: number
) {
	const jobDate = dayjs(job.created_at);
	const minutesDifference = dayjs().diff(jobDate, 'minute');
	return minutesDifference <= lastNumDays * 24 * 60;
}

export function isActiveFailing(
	jobs: IInternalDatabuilderJobStatus[],
	lastNumDays: number
) {
	const filteredJobs = jobs
		.filter((job) => job.status === 'failed' || job.status === 'succeeded')
		.reverse();
	return (
		filteredJobs.length > 0 &&
		filteredJobs[0].status === 'failed' &&
		isWithinLookback(filteredJobs[0], lastNumDays)
	);
}

export function isFailingWithNewError(
	jobs: IInternalDatabuilderJobStatus[],
	lastNumDays: number
) {
	const filteredJobs = jobs
		.filter((job) => job.status === 'failed' || job.status === 'succeeded')
		.reverse();
	if (
		filteredJobs.length < 2 ||
		filteredJobs[0].status !== 'failed' ||
		filteredJobs[1].status !== 'failed'
	) {
		return false;
	}
	const log1 = filteredJobs[0].exception_log?.split('\n')[0];
	const log2 = filteredJobs[1].exception_log?.split('\n')[0];
	return log1 !== log2 && isWithinLookback(filteredJobs[0], lastNumDays);
}

export function isRecentlyResolved(
	jobs: IInternalDatabuilderJobStatus[],
	lastNumDays: number
) {
	const filteredJobs = jobs
		.filter((job) => job.status === 'failed' || job.status === 'succeeded')
		.reverse();
	return (
		filteredJobs.length > 1 &&
		filteredJobs[0].status === 'succeeded' &&
		filteredJobs[1].status === 'failed' &&
		isWithinLookback(filteredJobs[0], lastNumDays)
	);
}
