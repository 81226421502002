import { Divider, Group, Stack, createStyles } from '@mantine/core';
import { Button, Switch } from '@repo/foundations';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useState } from 'react';
import type { Automation } from '../../../../../api';
import { AutomationPropagateMetadataField } from '../../../../../api/types/models/automation/automationPropagateMetadata';
import { useAutomationStore } from '../../../../../pages/AutomationPage/context';
import { isValidEnumValue } from '../../../../../utils/enumUtils';
import AutomationActionCardPropagateMetadataRow from './AutomationActionCardPropagateMetadataRow';
import { AUTOMATION_PROPAGATE_METADATA_PROPERTIES } from './constants';
import { useAutomationActionCardPropagateMetadataStore } from './context';

const useStyles = createStyles((theme) => ({
	group: {
		display: 'flex',
		gap: theme.spacing['2xs'],
	},
	button: {
		display: 'flex',
		width: 'fit-content',
	},
	switchGroup: {
		justifyContent: 'space-between',
	},
}));

interface AutomationActionCardPropagateMetadataContentProps {
	handleAutomationUpdate: (automation: Partial<Automation>) => Promise<void>;
}

function AutomationActionCardPropagateMetadataContent({
	handleAutomationUpdate,
}: AutomationActionCardPropagateMetadataContentProps) {
	const { classes } = useStyles();
	const store = useAutomationActionCardPropagateMetadataStore();
	const { automation } = useAutomationStore();

	const handleAddProperty = () => {
		store.addField(undefined);
	};

	const [checked, setChecked] = useState<boolean>(
		automation?.action_propagate_metadata?.overwrite_metadata ?? false
	);

	const handleOnCheckboxChange = async (newChecked: boolean) => {
		setChecked(newChecked);

		await handleAutomationUpdate({
			action_propagate_metadata: {
				...automation?.action_propagate_metadata,
				overwrite_metadata: newChecked,
			},
		});
	};

	useEffect(() => {
		store.resetFields();
		automation?.action_propagate_metadata?.fields?.forEach((field) => {
			store.addField(field);
		});
	}, [automation, store]);

	const buttonOptions = useMemo(
		() =>
			AUTOMATION_PROPAGATE_METADATA_PROPERTIES.filter((prop) => {
				if (isValidEnumValue(AutomationPropagateMetadataField, prop.value)) {
					return !store.fields.map((field) => field.val).includes(prop.value);
				}

				return false;
			}),
		[store.fields]
	);

	return (
		<Stack>
			{store.fields.map((field) => (
				<AutomationActionCardPropagateMetadataRow
					key={field.id}
					value={field.val}
					id={field.id}
					handleAutomationUpdate={handleAutomationUpdate}
					buttonOptions={buttonOptions}
				/>
			))}
			{buttonOptions.length > 0 && (
				<Group className={classes.group}>
					<Button
						onClick={handleAddProperty}
						leftIconName="plus"
						className={classes.button}
					>
						Add property
					</Button>
				</Group>
			)}
			<Divider />
			<Group className={classes.switchGroup}>
				Override existing properties
				<Switch
					checked={checked}
					onChange={(event) =>
						handleOnCheckboxChange(event.currentTarget.checked)
					}
				/>
			</Group>
		</Stack>
	);
}

export default observer(AutomationActionCardPropagateMetadataContent);
