import { createStyles, Drawer, Stack } from '@mantine/core';
import type { ITest, ITestRun } from '../../../api';
import { useTestRunList } from '../../../api/hooks/table';
import { TableV2 } from '../../../components/TableV2';
import {
	LastUpdatedRender,
	ReadOnlyBadgeRender,
} from '../../../components/TableV2/render';
import TestActions from './TestActions';

interface ITestDrawerProps {
	test: ITest;
	opened: boolean;
	onClose: () => void;
}

const useStyles = createStyles((theme) => ({
	drawerBody: {
		width: '100%',
		height: '100%',
		overflowY: 'auto',
		display: 'flex',
		padding: 0,
	},
	asideWrapper: {
		width: '100%',
		flexGrow: 1,
		border: 'none',
		padding: 0,
		overflow: 'inherit',
	},
	sidebarWrapper: {
		overflow: 'hidden',
		padding: theme.spacing.md,
		paddingTop: 0,
	},
}));

function TestDrawer({ test, opened, onClose }: ITestDrawerProps) {
	const { classes } = useStyles();

	return (
		<Drawer
			classNames={{
				body: classes.drawerBody,
			}}
			position="right"
			opened={opened}
			onClose={onClose}
			withCloseButton={false}
			size={480}
		>
			<Stack w="100%" spacing={0}>
				<TestActions title={test.title} test={test} onClose={onClose} />
				<Stack spacing={24} className={classes.sidebarWrapper}>
					<TableV2<ITestRun>
						columns={[
							{
								accessor: 'status',
								title: 'Status',
								width: 200,
								render: (record) => (
									<ReadOnlyBadgeRender
										record={record}
										field={'status'}
										options={[
											{
												color: 'green',
												label: 'Success',
												option: 'pass',
											},
											{
												color: 'green',
												label: 'Success',
												option: 'success',
											},
											{
												color: 'red',
												label: 'Fail',
												option: 'fail',
											},
											{
												color: 'red',
												label: 'Error',
												option: 'error',
											},
										]}
										nilOption={{
											color: 'gray',
											label: 'Unknown',
											option: '',
										}}
									/>
								),
							},
							{
								accessor: 'run_created_at',
								title: 'Started at',
								width: 200,
								render: (record) => (
									<LastUpdatedRender record={record} field={'run_created_at'} />
								),
							},
							{
								accessor: 'run_finished_at',
								title: 'Run finished at',
								width: 200,
								render: (record) => (
									<LastUpdatedRender
										record={record}
										field={'run_finished_at'}
									/>
								),
							},
						]}
						withCheckbox={false}
						withInteractiveHeader={false}
						withFilters={false}
						usePaginationList={useTestRunList}
						defaultRequiredSearchParams={{
							parent_id: test.id,
						}}
					/>
				</Stack>
			</Stack>
		</Drawer>
	);
}

export default TestDrawer;
