import { Box, Menu, useMantineTheme } from '@mantine/core';
import { Icon } from '@repo/foundations';
import type React from 'react';

interface TemplateDotsButton<TItem> {
	displayName: string;
	item: TItem;
	onSetAsDefault?: (item: TItem) => void;
	defaultDisplayText?: string;
	onUseItem?: (item: TItem) => void;
	onDuplicateItem?: (item: TItem) => void;
	onDeleteItem?: (item: TItem) => void;
	onEditItem?: (item: TItem) => void;
	visible?: boolean;
}

export function TemplateDotsButton<TItem>({
	displayName,
	item,
	onSetAsDefault,
	defaultDisplayText,
	onUseItem,
	onDuplicateItem,
	onDeleteItem,
	onEditItem,
	visible,
}: TemplateDotsButton<TItem>) {
	const theme = useMantineTheme();

	if (!visible) {
		return null;
	}

	const stopPropagation = (
		e: React.MouseEvent,
		handler?: (item: TItem) => void
	) => {
		e.preventDefault();
		e.stopPropagation();
		if (handler) {
			handler(item);
		}
	};

	const handleSetAsDefault = (e: React.MouseEvent) => {
		stopPropagation(e, onSetAsDefault);
	};

	const handleUseItem = (e: React.MouseEvent) => {
		stopPropagation(e, onUseItem);
	};

	const handleDuplicateItem = (e: React.MouseEvent) => {
		stopPropagation(e, onDuplicateItem);
	};

	const handleDeleteItem = (e: React.MouseEvent) => {
		stopPropagation(e, onDeleteItem);
	};

	const handleEditItem = (e: React.MouseEvent) => {
		stopPropagation(e, onEditItem);
	};

	const handlePopoverClick = (e: React.MouseEvent) => {
		stopPropagation(e);
	};

	return (
		<Menu position="bottom-start">
			<Menu.Target>
				<Box pl={theme.spacing.sm} onClick={handlePopoverClick}>
					<Icon name="dots" />
				</Box>
			</Menu.Target>
			<Menu.Dropdown>
				{onDuplicateItem && (
					<Menu.Item onClick={handleDuplicateItem} icon={<Icon name="copy" />}>
						Duplicate {displayName}
					</Menu.Item>
				)}
				{onUseItem && (
					<Menu.Item onClick={handleUseItem} icon={<Icon name="plus" />}>
						Use {displayName}
					</Menu.Item>
				)}
				<Menu.Item onClick={handleEditItem} icon={<Icon name="pencil" />}>
					Edit {displayName}
				</Menu.Item>
				{onSetAsDefault && (
					<Menu.Item onClick={handleSetAsDefault} icon={<Icon name="flag" />}>
						{defaultDisplayText || 'Toggle default'}
					</Menu.Item>
				)}
				<Menu.Item onClick={handleDeleteItem} icon={<Icon name="trash" />}>
					Delete {displayName}
				</Menu.Item>
			</Menu.Dropdown>
		</Menu>
	);
}
