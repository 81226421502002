import { createStyles, Stack } from '@mantine/core';
import type { IconNames } from '@repo/foundations';
import { observer } from 'mobx-react-lite';
import { ActionIconGroup } from '../../ActionIconGroup';
import { lineageStore } from '../store';
import ControlIcon from './ControlIcon';

const useStyles = createStyles((theme) => ({
	wrapper: {
		position: 'absolute',
		width: 'fit-content',
		right: theme.spacing.md,
		bottom: theme.spacing.lg,
		gap: theme.spacing.xs,
		zIndex: 5,
	},
}));

const ZOOM_CONTROL_ICONS = [
	{
		label: 'Zoom In',
		iconName: 'plus' as IconNames,
		onClick: () => {
			lineageStore.reactFlowInstance?.zoomIn();
		},
	},
	{
		label: 'Zoom Out',
		iconName: 'minus' as IconNames,
		onClick: () => {
			lineageStore.reactFlowInstance?.zoomOut();
		},
	},
];

// The <Panel /> component from `reactflow` does not seem to work out of the box.
// The <Stack /> component from `@mantine/core` is used instead.
export const Controls = observer(() => {
	const { classes } = useStyles();

	const handleFitView = () => {
		lineageStore.reactFlowInstance?.fitView();
	};

	return (
		<Stack className={classes.wrapper}>
			<ControlIcon
				label="Fit View"
				iconName="focusCentered"
				onClick={handleFitView}
			/>
			<ActionIconGroup icons={ZOOM_CONTROL_ICONS} />
		</Stack>
	);
});
