import { useUserActivity } from '../../api/hooks/notification/useResourceActivity';
import Timeline from './Timeline';

export interface IActityLogTimelineProps {
	userId: string;
}

function UserActivityLogTimeline({ userId }: IActityLogTimelineProps) {
	const { data: notifications } = useUserActivity(userId);

	if (!notifications) {
		return null;
	}

	return <Timeline notifications={notifications} />;
}

export default UserActivityLogTimeline;
