import cuid from 'cuid';

export const REFERRER_LOCAL_STORAGE_KEY = 'auth-referrer';

export const EMAIL_VERIFICATION_LOCAL_STORAGE_KEY =
	'auth-verification-attempt-email';

export const ANONYMOUS_USER_ID_LOCAL_STORAGE_KEY = 'anonymous-user-id';

export const anonymousId = () => {
	const id = localStorage.getItem(ANONYMOUS_USER_ID_LOCAL_STORAGE_KEY);

	if (id) {
		return id;
	}

	const newId = window.analytics?.user?.().anonymousId?.() ?? cuid();
	localStorage.setItem(ANONYMOUS_USER_ID_LOCAL_STORAGE_KEY, newId);

	return newId;
};

// We use the localStorage referrer value
// to avoid the referrer being lost when the user
// is redirected to SSO.
export const referrer = () =>
	localStorage.getItem(REFERRER_LOCAL_STORAGE_KEY) ?? document.referrer ?? '';
