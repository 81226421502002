import type { IUseListQueryHookArgs } from '../../types';
import type { ISearchLog } from '../../types/models/search';
import { useBaseModelList } from '../base';
import { SEARCH_LOGS_NAMESPACE, searchLogsQueryKeyFactory } from './constants';

export function useSearchLogs<T = ISearchLog[]>({
	page,
	filters,
	options = {},
}: IUseListQueryHookArgs<ISearchLog, T>) {
	return useBaseModelList<ISearchLog, T>({
		queryKey: searchLogsQueryKeyFactory.list(page, filters),
		namespace: SEARCH_LOGS_NAMESPACE,
		page,
		filters,
		options,
	});
}
