import { createQueryKeys } from '../../factories';

export const TABLES_NAMESPACE = ['table', 'tables'];
export const TABLES_QUERY_KEY = createQueryKeys(TABLES_NAMESPACE);

export const TESTS_NAMESPACE = ['table', 'tests'];
export const testsQueryKeyFactory = createQueryKeys(TESTS_NAMESPACE);

export const TEST_RUNS_NAMESPACE = ['table', 'test_runs'];
export const testRunsQueryKeyFactory = createQueryKeys(TEST_RUNS_NAMESPACE);

export const COLUMNS_NAMESPACE = ['table', 'columns'];
export const columnsQueryKeyFactory = createQueryKeys(COLUMNS_NAMESPACE);
