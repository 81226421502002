import { showNotification } from '@mantine/notifications';
import { Text } from '@repo/foundations';
import { useEffect, useState } from 'react';
import { useIntegration, useUpdateIntegration } from '../../../api';
import { MultiTeamsSelector } from '../../MultiTeamsSelector/MultiTeamsSelector.tsx';

interface IntegrationSlackAIPreferencesProps {
	integrationId: string;
}

export function IntegrationSlackSearchPreferences({
	integrationId,
}: IntegrationSlackAIPreferencesProps): JSX.Element {
	// Fetch the integration data by its id
	const { data: integration, isLoading } = useIntegration({
		id: integrationId,
	});
	const [teams, setTeams] = useState<string[]>([]);

	useEffect(() => {
		const teams = integration?.credentials?.default_search_teams;
		if (teams && typeof teams === 'string') {
			try {
				setTeams(JSON.parse(teams));
			} catch (e) {}
		}
	}, [integration]);

	// Initialize the mutation function for updating the integration
	const { mutate: updateIntegration, isLoading: isUpdating } =
		useUpdateIntegration({
			disableOptimisticUpdate: true,
			disableInvalidation: true,
		});

	const update = (default_search_teams: string[]) => {
		updateIntegration(
			{
				data: {
					id: integrationId,
					credentials: {
						...integration?.credentials,
						default_search_teams: JSON.stringify(default_search_teams),
					},
				},
			},
			{
				onSuccess: () => {
					setTeams(default_search_teams);
					showNotification({
						title: 'Success',
						message: `Successfully updated settings`,
						color: 'green',
					});
				},
			}
		);
	};

	return (
		<form>
			<Text size="md" weight="bold" mb={6}>
				Default Search Teams
			</Text>
			<Text mb={6}>
				Search the following teams when a Slack user does not have an account in
				Secoda
			</Text>
			<MultiTeamsSelector
				disabled={isUpdating || isLoading}
				value={teams}
				setValue={update}
				placeholder={'All teams'}
			/>
		</form>
	);
}
