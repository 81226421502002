import type { CSSObject } from '@mantine/core';
import { createStyles } from '@mantine/core';
import { NODE_HEADER_HEIGHT, NODE_WIDTH } from '../constants';

interface IEntityNodeStylesProps {
	selected: boolean;
	isHighlighted: boolean;
	isSourceConnectable: boolean;
	isTargetConnectable: boolean;
}

export const useStyles = createStyles(
	(
		theme,
		{
			selected,
			isSourceConnectable,
			isTargetConnectable,
		}: IEntityNodeStylesProps
	) => {
		let sourceWidth = '0 !important';
		let sourceHeight = '0 !important';
		let sourceZIndex = 1;

		let targetWidth = '0 !important';
		let targetHeight = '0 !important';
		let targetZIndex = 1;

		if (isSourceConnectable) {
			sourceWidth = `${NODE_WIDTH}px !important`;
			sourceHeight = `${NODE_HEADER_HEIGHT}px !important`;
			sourceZIndex = 2;
		} else if (isTargetConnectable) {
			targetWidth = `${NODE_WIDTH}px !important`;
			targetHeight = `${NODE_HEADER_HEIGHT}px !important`;
			targetZIndex = 2;
		}

		return {
			wrapper: {
				backgroundColor: theme.other.getColor('surface/primary/default'),
				flexWrap: 'nowrap',
				justifyContent: 'space-between',
				width: NODE_WIDTH,
				height: 'fit-content',
				boxShadow: selected
					? `0px 0px 0px 2px ${theme.other.getColor('border/emphasis/default')}`
					: '1px 0px 0px 0px rgba(0, 0, 0, 0.08) inset, -1px 0px 0px 0px rgba(0, 0, 0, 0.08) inset, 0px -1px 0px 0px rgba(0, 0, 0, 0.12) inset, 0px 1px 0px 0px rgba(0, 0, 0, 0.08) inset, 0px 1px 0px 0px rgba(0, 0, 0, 0.05) inset',
				borderRadius: theme.radius.md,
				overflow: 'hidden',
				gap: 0,
			},
			contentWrapper: {
				flexGrow: '1 !important' as CSSObject['flexGrow'],
				gap: 0,
			},
			handle: {
				width: '0 !important',
				height: '0 !important',
				backgroundColor: 'transparent !important',
				borderColor: 'transparent !important',
				top: `${NODE_HEADER_HEIGHT / 2}px !important`,
				borderRadius: '0 !important',
				'&:first-of-type': {
					width: targetWidth,
					height: targetHeight,
					zIndex: targetZIndex,
				},
				'&:last-of-type': {
					width: sourceWidth,
					height: sourceHeight,
					zIndex: sourceZIndex,
				},
			},
		};
	}
);
