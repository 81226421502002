import { Box, createStyles, Flex } from '@mantine/core';
import { Button, type IconNames } from '@repo/foundations';

interface IBottomPanelProps {
	label: string;
	iconName: IconNames;
	onClick: () => void;
}

const useStyles = createStyles((theme) => ({
	wrapper: {
		position: 'absolute',
		justifyContent: 'center',
		width: '100%',

		bottom: theme.spacing['2xl'],
		gap: theme.spacing['3xs'],
		zIndex: 5,
	},
	content: {
		backgroundColor: theme.other.getColor('surface/tertiary/default'),
		width: 'fit-content',
		padding: theme.spacing.xs,
		borderRadius: theme.radius.xl,
	},
}));

function BottomPanelButton({ label, iconName, onClick }: IBottomPanelProps) {
	const { classes } = useStyles();

	return (
		<Flex className={classes.wrapper}>
			<Box className={classes.content}>
				<Button variant="tertiary" leftIconName={iconName} onClick={onClick}>
					{label}
				</Button>
			</Box>
		</Flex>
	);
}

export default BottomPanelButton;
