import { Box, createStyles, Divider, ScrollArea, Stack } from '@mantine/core';
import { Button, Title } from '@repo/foundations';
import { keys, map } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import type { ReactNode } from 'react';
import type { IEmbeddedPrompt } from '../../api';
import {
	useAIEmbeddedList,
	useAuthUser,
	useDeleteAllAIEmbedded,
} from '../../api';
import { openDeleteConfirmModal } from '../../components/ModalManager';
import { trackEvent } from '../../utils/analytics';
import { RightSidebarWrapper } from '../RightSidebar';
import ChatHistoryItem from './ChatHistoryItem';
import { useAIAssistantContext } from './context';
import { AIAssistantMode } from './types';
import { getTimePeriod } from './utils';

const useStyles = createStyles((theme) => ({
	wrapper: {
		height: '100%',
		justifyContent: 'space-between',
		padding: `0 ${theme.spacing.sm} ${theme.spacing.xl} ${theme.spacing.sm}`,
	},
	groupedChatsWrapper: {
		height: '100%',
		gap: theme.spacing.sm,
	},
	button: {
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
		gap: theme.spacing.xs,
		borderRadius: theme.radius.sm,
		backgroundColor: theme.other.getColor('surface/primary/default'),
		'&:hover': {
			backgroundColor: theme.other.getColor('surface/primary/hover'),
		},
		'&:active': {
			backgroundColor: theme.other.getColor('surface/primary/active'),
		},
	},
	buttonInner: {
		justifyContent: 'flex-start',
	},
	scrollAreaViewport: {
		'>div': {
			display: 'block !important',
		},
	},
}));

interface IChatHistoryContainerProps {
	children: ReactNode;
}

function ChatHistoryContainer({ children }: IChatHistoryContainerProps) {
	const { mode } = useAIAssistantContext();

	if (mode !== AIAssistantMode.SIDEBAR) {
		return <RightSidebarWrapper>{children}</RightSidebarWrapper>;
	}

	// eslint-disable-next-line react/jsx-no-useless-fragment
	return <>{children}</>;
}

export const ChatHistory = observer(() => {
	const { classes } = useStyles();

	const { mode } = useAIAssistantContext();

	const { user, workspace } = useAuthUser();
	const { data } = useAIEmbeddedList({
		options: {
			select: (res) => res.results ?? [],
		},
	});

	const groupedChats = (data ?? []).reduce(
		(
			acc: {
				[key: string]: IEmbeddedPrompt[];
			},
			chat
		) => {
			const period = getTimePeriod(chat.created_at);
			if (!acc[period]) {
				acc[period] = [];
			}
			acc[period].push(chat);
			return acc;
		},
		{}
	);

	const { mutate: deleteAll } = useDeleteAllAIEmbedded();

	const handleConfirmDeleteAll = () => {
		trackEvent(
			'ai/history/delete_all',
			{
				mode,
			},
			user,
			workspace
		);
		deleteAll();
	};

	const handleDeleteAll = () => {
		openDeleteConfirmModal({
			title: 'Are you sure you want to clear all conversations?',
			onConfirm: handleConfirmDeleteAll,
			confirmLabel: 'Clear conversations',
		});
	};

	return (
		<ChatHistoryContainer>
			<Stack className={classes.wrapper}>
				<ScrollArea
					classNames={{
						viewport: classes.scrollAreaViewport,
					}}
					type="scroll"
				>
					<Stack className={classes.groupedChatsWrapper}>
						{map(keys(groupedChats), (period) => (
							<Box key={period}>
								<Title
									key={`${period}-title`}
									size="xs"
									color="text/secondary/default"
								>
									{period}
								</Title>
								<Stack spacing={0} key={`${period}-list`}>
									{map(groupedChats[period], (chat) => (
										<ChatHistoryItem key={chat.id} chat={chat} />
									))}
								</Stack>
							</Box>
						))}
					</Stack>
				</ScrollArea>
				{mode !== AIAssistantMode.SIDEBAR && (
					<Stack>
						<Divider />
						<Button
							classNames={{
								inner: classes.buttonInner,
							}}
							variant="tertiary"
							size="lg"
							leftIconName="trash"
							onClick={handleDeleteAll}
						>
							Clear all conversations
						</Button>
					</Stack>
				)}
			</Stack>
		</ChatHistoryContainer>
	);
});
