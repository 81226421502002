import type {
	DataQualityScore,
	DataQualitySuggestion,
} from '@repo/api-codegen';

const OWNERSHIP = 10.0;
const TAGGED = 10.0;
const QUESTIONS = 5.0;
const STEWARDSHIP = OWNERSHIP + TAGGED + QUESTIONS;

const DESC = 10.0;
const COLUMN_DESC = 10.0;
const SCHEMA_DESC = 5.0;
const USABILITY = DESC + COLUMN_DESC + SCHEMA_DESC;

const SLA_SET = 10.0;
const SLA_ACHIEVED = 10.0;
const RELIABILITY = SLA_SET + SLA_ACHIEVED;

const NULLNESS_TEST = 5.0;
const NULLNESS_PASSING = 5.0;
const UNIQUENESS_TEST = 5.0;
const UNIQUENESS_PASSING = 5.0;
const OTHER_TEST_PASSING = 10.0;
const ACCURACY =
	NULLNESS_TEST +
	NULLNESS_PASSING +
	UNIQUENESS_TEST +
	UNIQUENESS_PASSING +
	OTHER_TEST_PASSING;

const TOTAL = STEWARDSHIP + USABILITY + RELIABILITY + ACCURACY;

export const DATA_QUALITY_MAX_SCORE: {
	[key in keyof DataQualityScore]: number;
} = {
	ownership: OWNERSHIP,
	tagged: TAGGED,
	questions: QUESTIONS,
	stewardship: STEWARDSHIP,
	desc: DESC,
	column_desc: COLUMN_DESC,
	schema_desc: SCHEMA_DESC,
	usability: USABILITY,
	sla_set: SLA_SET,
	sla_achieved: SLA_ACHIEVED,
	reliability: RELIABILITY,
	nullness_test: NULLNESS_TEST,
	nullness_passing: NULLNESS_PASSING,
	uniqueness_test: UNIQUENESS_TEST,
	uniqueness_passing: UNIQUENESS_PASSING,
	other_test_passing: OTHER_TEST_PASSING,
	accuracy: ACCURACY,
	total: TOTAL,
};

export const DATA_QUALITY_COMPLETED_ITEMS: Record<
	DataQualitySuggestion['data_quality_top_key'],
	Array<{
		key: DataQualitySuggestion['data_quality_key'];
		title: string;
		maxScore: number;
	}>
> = {
	accuracy: [
		{
			key: 'nullness_test',
			title: 'Resource have nullness tests/monitors',
			maxScore: NULLNESS_TEST,
		},
		{
			key: 'nullness_passing',
			title: 'No failing nullness tests/monitors',
			maxScore: NULLNESS_PASSING,
		},
		{
			key: 'uniqueness_test',
			title: 'Resource have uniqueness tests/monitors',
			maxScore: UNIQUENESS_TEST,
		},
		{
			key: 'uniqueness_passing',
			title: 'No failing uniqueness tests/monitors',
			maxScore: UNIQUENESS_PASSING,
		},
		{
			key: 'other_test_passing',
			title: 'No other failing tests',
			maxScore: OTHER_TEST_PASSING,
		},
	],
	reliability: [
		{
			key: 'sla_set',
			title: 'Resource have freshness tests/monitors',
			maxScore: SLA_SET,
		},
		{
			key: 'sla_achieved',
			title: 'No failing freshness tests/monitors',
			maxScore: SLA_ACHIEVED,
		},
	],
	stewardship: [
		{ key: 'tagged', title: 'Table have tags', maxScore: TAGGED },
		{ key: 'ownership', title: 'Table have owners', maxScore: OWNERSHIP },
		{ key: 'questions', title: 'All questions answered', maxScore: QUESTIONS },
	],
	usability: [
		{ key: 'desc', title: 'Table is documented', maxScore: DESC },
		{
			key: 'column_desc',
			title: 'Table columns are documented',
			maxScore: COLUMN_DESC,
		},
		{
			key: 'schema_desc',
			title: 'Table schema is documented',
			maxScore: SCHEMA_DESC,
		},
	],
};

export const DATA_QUALITY_COMPLETED_ITEMS_REPORT_LEVEL: Record<
	DataQualitySuggestion['data_quality_top_key'],
	Array<{
		key: DataQualitySuggestion['data_quality_key'];
		title: string;
		maxScore: number;
	}>
> = {
	accuracy: [
		{
			key: 'nullness_test',
			title: 'Resource have nullness tests/monitors',
			maxScore: NULLNESS_TEST,
		},
		{
			key: 'nullness_passing',
			title: 'No failing nullness tests/monitors',
			maxScore: NULLNESS_PASSING,
		},
		{
			key: 'uniqueness_test',
			title: 'Resource have uniqueness tests/monitors',
			maxScore: UNIQUENESS_TEST,
		},
		{
			key: 'uniqueness_passing',
			title: 'No failing uniqueness tests/monitors',
			maxScore: UNIQUENESS_PASSING,
		},
		{
			key: 'other_test_passing',
			title: 'No other failing tests',
			maxScore: OTHER_TEST_PASSING,
		},
	],
	reliability: [
		{
			key: 'sla_set',
			title: 'Resource have freshness tests/monitors',
			maxScore: SLA_SET,
		},
		{
			key: 'sla_achieved',
			title: 'No failing freshness tests/monitors',
			maxScore: SLA_ACHIEVED,
		},
	],
	stewardship: [
		{ key: 'tagged', title: 'Resources have tags', maxScore: TAGGED },
		{ key: 'ownership', title: 'Resources have owners', maxScore: OWNERSHIP },
		{ key: 'questions', title: 'All questions answered', maxScore: QUESTIONS },
	],
	usability: [
		{ key: 'desc', title: 'Tables are documented', maxScore: DESC },
		{
			key: 'column_desc',
			title: 'Columns are documented',
			maxScore: COLUMN_DESC,
		},
		{
			key: 'schema_desc',
			title: 'Schemas are documented',
			maxScore: SCHEMA_DESC,
		},
	],
};

export const DATA_QUALITY_TOP_KEYS: DataQualitySuggestion['data_quality_top_key'][] =
	['stewardship', 'usability', 'reliability', 'accuracy'];
