import type { MantineTheme } from '@mantine/core';
import {
	ActionIcon,
	Box,
	createStyles,
	Flex,
	Indicator,
	Loader,
} from '@mantine/core';
import { useNavigateHandler } from '@repo/common/hooks/useNavigate';
import { Badge, Icon, Text } from '@repo/foundations';
import { observer } from 'mobx-react-lite';
import type { MouseEvent, ReactNode } from 'react';
import { Suspense, useCallback, useEffect, useState } from 'react';
import { compactNumber } from '../../../utils/numberUtils';
import SidebarTooltipWrapper from './SidebarTooltipWrapper';
import { sideBarStore } from './store';

export interface Link {
	id: string;
	label: string | JSX.Element;
	icon: JSX.Element;
	href: string[];
	matchExact?: boolean;
	show: boolean;
	collapsable?: boolean;
	collapsedComponent?: ReactNode;
	showCount?: boolean;
	count?: number;
	target?: string;
	underTeam?: boolean;
}

interface SideBarLinkProps {
	link: Link;
	selected: boolean;
}

interface SideBarLinkStyleProps {
	selected: boolean;
	collapsableOpened: boolean;
}

const useStyles = createStyles(
	(
		theme: MantineTheme,
		{ selected, collapsableOpened }: SideBarLinkStyleProps
	) => ({
		indicator: {
			paddingTop: theme.other.space[0.25],
		},
		sidebarItem: {
			height: theme.other.space[7],
			width: '100%',

			cursor: 'pointer',
			borderRadius: theme.other.borderRadius.sm,
			color: theme.other.getColor('text/primary/default'),
			backgroundColor: selected
				? theme.other.getColor('fill/transparent/selected')
				: 'transparent',
			':hover': {
				backgroundColor: selected
					? theme.other.getColor('fill/transparent/selected')
					: theme.other.getColor('fill/transparent/hover'),
			},
			':active': {
				backgroundColor: theme.other.getColor('fill/transparent/active'),
			},
		},
		chevron: {
			transform: collapsableOpened ? 'rotate(90deg)' : 'none',
			transition: 'transform 200ms ease',
		},
		chevronButton: {
			padding: 0,
			width: theme.other.space[7],
			height: theme.other.space[7],
			color: theme.other.getColor('icon/secondary/default'),
			':hover': {
				color: theme.other.getColor('icon/primary/default'),
				backgroundColor: theme.other.getColor('fill/transparent/hover'),
			},
		},
		linkText: {
			color: theme.other.getColor('text/primary/default'),
			whiteSpace: 'nowrap',
		},
		badgeRoot: {
			margin: 0,
			marginRight: theme.other.space[1],
			borderRadius: theme.other.borderRadius.sm,
		},
	})
);

function SideBarLink({ link, selected }: SideBarLinkProps) {
	const [collapsableOpened, setCollapsableOpened] = useState(false);
	const { label, icon, href } = link;

	useEffect(() => {
		if (sideBarStore.collapsed) {
			setCollapsableOpened(false);
		}
	}, [setCollapsableOpened]);

	const { classes, theme } = useStyles({
		selected,
		collapsableOpened,
	});

	const navigateHandler = useNavigateHandler();

	const handleNavigate = (event: MouseEvent) => {
		if (link.collapsable) {
			if (selected && !sideBarStore.collapsed) {
				setCollapsableOpened(!collapsableOpened);
			}
		}

		navigateHandler(event)(href[0]);
	};

	const handledCollapsable = useCallback(
		(event: MouseEvent) => {
			event.stopPropagation();
			setCollapsableOpened(!collapsableOpened);
		},
		[collapsableOpened]
	);

	const CollapsedComponent = link.collapsedComponent;
	const showCollapsableComponent =
		CollapsedComponent && !sideBarStore.collapsed;

	const linkWidth = sideBarStore.collapsed ? 36 : '100%';

	const shouldShowCountBadge: boolean =
		(!sideBarStore.collapsed && link.showCount && (link?.count || 0) > 0) ||
		false;

	const shouldShowChevron: boolean =
		(!sideBarStore.collapsed && link.underTeam && (link?.count || 0) > 0) ||
		false;

	return (
		<Box w={linkWidth}>
			<Indicator
				size={10}
				offset={10}
				className={classes.indicator}
				disabled={!sideBarStore.collapsed || !link.showCount || !link.count}
				color={theme.other.getColor('icon/primary/default')}
			>
				<Flex
					className={classes.sidebarItem}
					role="button"
					onClick={handleNavigate}
				>
					<SidebarTooltipWrapper
						label={label}
						showTooltip={!!sideBarStore.collapsed}
					>
						<Flex
							w="100%"
							justify={!sideBarStore.collapsed ? 'space-between' : 'center'}
							align="center"
						>
							<Flex justify="center" align="center">
								{shouldShowChevron && (
									<ActionIcon
										onClick={handledCollapsable}
										data-testid={`sidebar-link-${label}-collapsable`}
										className={classes.chevronButton}
									>
										<Icon name="chevronRight" className={classes.chevron} />
									</ActionIcon>
								)}
								{!sideBarStore.collapsed && link.underTeam && !link.count && (
									<Box w={theme.other.space[7]} />
								)}
								{!sideBarStore.collapsed && !link.underTeam && (
									<Box w={theme.other.space[1]} />
								)}
								<Flex align="center">
									<Box
										pr={
											!sideBarStore.collapsed
												? `${theme.other.space[1.5]}px`
												: 0
										}
									>
										{icon}
									</Box>

									{!sideBarStore.collapsed && (
										<Text
											size="sm"
											weight="semibold"
											className={classes.linkText}
										>
											{label}
										</Text>
									)}
								</Flex>
							</Flex>
							{shouldShowCountBadge && (
								<Badge
									className={classes.badgeRoot}
									backgroundColor="fill/transparent-secondary/default"
									color="text/primary/default"
									size="xs"
								>
									{compactNumber(link?.count || 0, 1)}
								</Badge>
							)}
						</Flex>
					</SidebarTooltipWrapper>
				</Flex>
				{showCollapsableComponent && (
					<Box
						sx={{
							display: collapsableOpened ? 'block' : 'none',
						}}
					>
						<Suspense fallback={<Loader my={8} w="100%" size="xs" />}>
							{CollapsedComponent}
						</Suspense>
					</Box>
				)}
			</Indicator>
		</Box>
	);
}

export default observer(SideBarLink);
