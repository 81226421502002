import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
	formWrapper: {
		gap: theme.spacing['2xs'],
		'.mantine-Checkbox-label': {
			fontSize: theme.other.typography.text.sm,
			fontWeight: theme.other.typography.weight.semibold,
		},
	},
}));
