import { Box } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { Button, Icon, Switch } from '@repo/foundations';
import { observer } from 'mobx-react-lite';
import type React from 'react';
import { useAuthUser } from '../../api';
import { useUpdateWorkspace } from '../../api/hooks/workspace/useUpdateWorkspace';
import { publishAll } from '../../lib/models';
import { Setting } from './Setting';

function PublishSettingsComponent() {
	const { isAdminUser, workspace } = useAuthUser();
	const { mutateAsync } = useUpdateWorkspace(workspace.id);

	const handlePublishAllClick = async () => {
		// Check if the user has clicked Publish All in the last 1 hour
		const lastPublishAll = localStorage.getItem('publishAll');
		if (lastPublishAll) {
			const lastPublishAllDate = new Date(lastPublishAll);
			const oneHourAgo = new Date();
			oneHourAgo.setHours(oneHourAgo.getHours() - 1);
			if (lastPublishAllDate > oneHourAgo) {
				showNotification({
					title: 'Publish all resources process already started',
					message: 'Please wait one hour for the current process to complete.',
					color: 'blue',
					icon: <Icon name="alertCircle" />,
				});
				return;
			}
		}

		try {
			await publishAll();
			showNotification({
				title: 'Published all resources process started',
				message: 'This can take an hour to complete.',
				color: 'green',
				icon: <Icon name="check" />,
			});
			// Set localStorage to the time Publish All was clicked
			localStorage.setItem('publishAll', new Date().toISOString());
		} catch (error) {
			showNotification({
				title: 'Error publishing all resources',
				message: 'If the issues persists, please contact support',
				color: 'red',
				icon: <Icon name="alertCircle" />,
			});
		}
	};

	const handleDefaultPublishingCheck = (
		e: React.ChangeEvent<HTMLInputElement>
	) => {
		mutateAsync({
			data: { id: workspace.id, default_publishing_enabled: e.target.checked },
		});
	};

	return (
		<Box>
			<Setting
				title="Publish all resources"
				description="Published data resources are available to all viewers in a workspace."
			>
				<Box style={{ position: 'relative' }}>
					<Button onClick={handlePublishAllClick} disabled={!isAdminUser}>
						Publish
					</Button>
				</Box>
			</Setting>
			<Setting
				title="Publish all resources by default"
				description="All new resources will be published by default."
			>
				<Box style={{ position: 'relative' }}>
					<Switch
						onChange={handleDefaultPublishingCheck}
						checked={!!workspace?.default_publishing_enabled}
						disabled={!isAdminUser}
					/>
				</Box>
			</Setting>
		</Box>
	);
}

export const PublishSettings = observer(PublishSettingsComponent);
