import type { MantineThemeOverride } from '@mantine/core';

export const MultiSelect: MantineThemeOverride['components'] = {
	MultiSelect: {
		styles: (theme) => ({
			value: {
				marginTop: theme.spacing.xs,
				marginBottom: theme.spacing.xs,
			},
			description: {
				fontSize: theme.other.typography.text.sm,
				lineHeight: theme.other.typography.lineHeight.text.sm,
				color: theme.other.getColor('text/secondary/default'),
			},
		}),
	},
};
