import {
	fromPairs,
	get,
	isArray,
	isPlainObject,
	join,
	map,
	omit,
	toString,
} from 'lodash-es';
import type { ISecodaEntity } from '../../../api';
import type { SecodaEntity } from '../../../lib/models';
import { saveBlob } from '../../../lib/models';
import type { CustomProperty } from '../../CustomPropertyEditor/CustomProperty.types';

export const parseCustomProperties = (properties: CustomProperty[]) => {
	const keys: Record<string, number> = {};
	const getKey = (name: string) => {
		if (name in keys) {
			keys[name] += 1;
			return `${name}_${keys[name]}`;
		}

		keys[name] = 0;
		return name;
	};

	return fromPairs(
		map(properties, ({ name, value }) => [
			`custom_property_${getKey(name)}`,
			value,
		])
	);
};

export const convertArrayToExportCSV = (data?: string[][]) => {
	if (!data || !data.length) {
		return '';
	}

	const header = data[0].join(',');
	const values = join(
		map(data.slice(1), (row) => join(row, ',')),
		'\n'
	);

	return `${header}\n${values}`;
};

export const convertToExportCSV = (data: Record<string, unknown>) => {
	const header = Object.keys(data).join(',');
	const values = join(
		map(data, (value) => {
			if (isArray(value)) {
				return `"${join(value, ';')}"`;
			}

			if (isPlainObject(value)) {
				return `"${JSON.stringify(value)}"`;
			}

			return `"${toString(value)}"`;
		}),
		','
	);

	return `${header}\n${values}`;
};

export const convertToExportObject = (data: SecodaEntity | ISecodaEntity) => {
	const sanitizedData: Record<string, unknown> = omit(
		data,
		'stale',
		'archived',
		'multiplayers',
		'multiplayer_last_modified_by',
		'multiplayer_last_modified',
		'properties',
		'search_metadata',
		'creation_query',
		'chart_config',
		'results',
		'_id',
		'paramExpanded',
		'onExpandClick'
	);

	const creationQuery = get(data, 'creation_query.definition', '');

	if (creationQuery) {
		sanitizedData.creation_query = creationQuery;
	}

	return {
		...sanitizedData,
	};
};

const getEntityBlob = (
	data: SecodaEntity | ISecodaEntity,
	type: 'csv' | 'json'
) => {
	const jsonData = convertToExportObject(data);

	if (type === 'csv') {
		const csv = convertToExportCSV(jsonData);
		const blob = new Blob([csv], { type: 'text/csv' });
		return blob;
	}

	const json = JSON.stringify(jsonData, null, 2);
	const blob = new Blob([json], { type: 'application/json' });
	return blob;
};

export const downloadEntityFile = (
	entity: SecodaEntity | ISecodaEntity,
	type: 'csv' | 'json' = 'json'
) => {
	saveBlob(getEntityBlob(entity, type), `${entity.id}.${type}`);
};
