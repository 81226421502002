import type { IMarketplaceIntegrationSpecVersion } from '@repo/common/models/marketplace';
import axios from 'axios';
import { cloneDeep } from 'lodash-es';
import { authHeaders } from '../../api/common';
import { BaseModel } from './base';

export class Integration extends BaseModel {
	static CLASS_NAME = 'Integration';

	// @ts-expect-error TS(2564): Property 'id' has no initializer and is not defini... Remove this comment to see the full error message
	id: string;

	// @ts-expect-error TS(2564): Property 'name' has no initializer and is not defi... Remove this comment to see the full error message
	name: string;

	// @ts-expect-error TS(2564): Property 'type' has no initializer and is not defi... Remove this comment to see the full error message
	type: string;

	// @ts-expect-error TS(2564): Property 'connected' has no initializer and is not... Remove this comment to see the full error message
	connected: boolean;

	credentials: any;

	ssh_tunnel?: string;

	extracted_at: any;

	// @ts-expect-error TS(2564): Property 'use_native_descriptions' has no initiali... Remove this comment to see the full error message
	use_native_descriptions: boolean;

	// @ts-expect-error TS(2564): Property 'import_tags_preference' has no initializ... Remove this comment to see the full error message
	import_tags_preference: boolean;

	// @ts-expect-error TS(2564): Property 'import_owners_preference' has no initializ... Remove this comment to see the full error message
	import_owners_preference: boolean;

	// @ts-expect-error TS(2564): Property 'scheduled_extractions_cron' has no initi... Remove this comment to see the full error message
	scheduled_extractions_cron: string;

	// @ts-expect-error TS(2564): Property 'scheduled_extractions_last_run' has no i... Remove this comment to see the full error message
	scheduled_extractions_last_run: string;

	// @ts-expect-error TS(2564): Property 'scheduled_extractions_next_run' has no i... Remove this comment to see the full error message
	scheduled_extractions_next_run: string;

	// @ts-expect-error TS(2564): Property 'scheduled_push_metadata_cron' has no ... Remove this comment to see the full error message
	scheduled_push_metadata_cron: string;

	// @ts-expect-error TS(2564): Property 'scheduled_push_metadata_last_run' has no i... Remove this comment to see the full error message
	scheduled_push_metadata_last_run: string;

	// @ts-expect-error TS(2564): Property 'scheduled_push_metadata_next_run' has no i... Remove this comment to see the full error message
	scheduled_push_metadata_next_run: string;

	// @ts-expect-error TS(2564): Property 'execute_access' has no initializer and i... Remove this comment to see the full error message
	execute_access: string[];

	// @ts-expect-error TS(2564): Property 'preview_access_users' has no initializer... Remove this comment to see the full error message
	preview_access_users: string[];

	// @ts-expect-error TS(2564): Property 'preview_access_groups' has no initialize... Remove this comment to see the full error message
	preview_access_groups: string[];

	// @ts-expect-error TS(2564): Property 'preview_access_roles' has no initializer... Remove this comment to see the full error message
	preview_access_roles: string[];

	// @ts-expect-error TS(2564): Property 'properties' has no initializer and is no... Remove this comment to see the full error message
	properties: Record<string, any>;

	// @ts-expect-error TS(2564)
	teams: string[];

	is_demo: boolean | undefined;

	supportPushMetadata: unknown;

	marketplace_integration_spec_version_id?: string;

	marketplace_integration_spec_version?: IMarketplaceIntegrationSpecVersion;

	marketplace_auto_update_latest_version?: boolean;

	groupSettings: any = {};

	constructor(obj: Integration) {
		super(obj);
		Object.keys(obj).forEach((key) => {
			(this as any)[key] = cloneDeep((obj as any)[key]);
		});
	}

	static get fields() {
		return BaseModel.fields.concat([
			'name',
			'type',
			'credentials',
			'connected',
			'ssh_tunnel',
			'extracted_at',
			'use_native_descriptions',
			'import_tags_preference',
			'import_owners_preference',
			'scheduled_extractions_cron',
			'scheduled_extractions_last_run',
			'scheduled_extractions_next_run',
			'scheduled_push_metadata_cron',
			'scheduled_push_metadata_last_run',
			'scheduled_push_metadata_next_run',
			'execute_access',
			'properties',
			'preview_access_users',
			'preview_access_groups',
			'preview_access_roles',
			'teams',
			'marketplace_integration_spec_version',
			'marketplace_auto_update_latest_version',
		]);
	}

	static get namespace() {
		return ['integration', 'integrations'];
	}

	redacted() {
		const redacted = cloneDeep(this);
		redacted.credentials = 'REDACTED';
		return redacted;
	}

	async isConnected() {
		const res = await axios.post(
			`${this.apiPath}test_connection/`,
			{},
			authHeaders()
		);

		if (res.data.connected) {
			return true;
		}

		// Punctuation not required because formatted error already has a period
		const errorMessage = `${res.data.formattedError} ${res.data.error}`;

		throw new Error(errorMessage);
	}

	async refreshSchemas() {
		return axios.post(`${this.apiPath}refresh_schemas/`, {}, authHeaders());
	}
}
