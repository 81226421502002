import { makeAutoObservable } from 'mobx';
import { DATA_QUALITY_MAX_SCORE } from '../DataQualityScore/constants';

export class AppShellStore {
	navBarUI = {
		homePage: {
			drawerOpen: false,
		},
		teamsPage: {
			includeArchive: false,
		},
		teamHomePage: {
			drawerOpen: false,
			settingsModalOpen: false,
		},
		analyticsPage: {
			addWidgetModalOpen: false,
			showAddWidgetButton: true,
			downloadDataQualityScore: {
				downloadButtonVisible: false,
				dataQualityScore: DATA_QUALITY_MAX_SCORE,
			},
			reportId: '',
			reportTitle: '',
		},
	};

	constructor() {
		makeAutoObservable(this);
	}

	get homePageDrawerHandler() {
		return {
			open: () => {
				this.navBarUI.homePage.drawerOpen = true;
			},
			close: () => {
				this.navBarUI.homePage.drawerOpen = false;
			},
		};
	}

	get teamsPageIncludeArchiveHandler() {
		return {
			toggle: () => {
				this.navBarUI.teamsPage.includeArchive =
					!this.navBarUI.teamsPage.includeArchive;
			},
		};
	}

	get teamHomePageDrawerHandler() {
		return {
			open: () => {
				this.navBarUI.teamHomePage.drawerOpen = true;
			},
			close: () => {
				this.navBarUI.teamHomePage.drawerOpen = false;
			},
		};
	}

	get teamHomePageSettingsModalHandler() {
		return {
			open: () => {
				this.navBarUI.teamHomePage.settingsModalOpen = true;
			},
			close: () => {
				this.navBarUI.teamHomePage.settingsModalOpen = false;
			},
		};
	}

	get analyticsPageAddWidgetModalHandler() {
		return {
			open: () => {
				this.navBarUI.analyticsPage.addWidgetModalOpen = true;
			},
			close: () => {
				this.navBarUI.analyticsPage.addWidgetModalOpen = false;
			},
		};
	}

	get analyticsPageReportId() {
		return this.navBarUI.analyticsPage.reportId;
	}
}

export const appShellStore = new AppShellStore();
