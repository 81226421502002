import type { MantineColor } from '@mantine/core';
import { ThemeIcon } from '@mantine/core';
import type { Icon as TablerIcon, TablerIconsProps } from '@tabler/icons-react';
import { map, range } from 'lodash-es';
import { createElement } from 'react';
import SkeletonWidgetRow from './SkeletonWidgetRow';

interface IListCardProps {
	colors: [MantineColor, MantineColor];
	icons: [TablerIcon, TablerIcon];
	iconProps?: TablerIconsProps;
}

function ListCard({ colors, icons, iconProps = { size: 18 } }: IListCardProps) {
	return (
		<>
			{map(range(2), (i) => (
				<SkeletonWidgetRow
					key={i}
					icon={
						<ThemeIcon color={colors[i]} radius={24}>
							{createElement(icons[i], iconProps)}
						</ThemeIcon>
					}
				/>
			))}
		</>
	);
}

export default ListCard;
