import { createStyles, Group } from '@mantine/core';
import type {
	CustomProperty,
	CustomPropertyKeys,
} from './CustomProperty.types';
import { PropertyEditor } from './PropertyEditor';
import PropertyTitle from './PropertyTitle';

const useStyles = createStyles((theme) => ({
	leftGroup: {
		flexBasis: theme.other.space[32],
		padding: `${theme.spacing['3xs']} 0`,
	},
	rightGroup: {
		flexGrow: 1,
		alignSelf: 'stretch',
	},
}));

interface ICustomPropertyItemProps {
	property: CustomProperty;
	onRemove: VoidFunction;
	readOnly: boolean;
	onChange: (prop: CustomPropertyKeys) => (value: string | string[]) => void;
	viewerUser: boolean;
}

// DEPRECATED: This component is deprecated and should not be used in new code.
// Use the new custom properties instead.
function CustomPropertyItem({
	onChange,
	onRemove,
	property,
	readOnly,
	viewerUser,
}: ICustomPropertyItemProps) {
	const { classes } = useStyles();

	return (
		<Group
			data-testid={`custom-property-${property.name}`}
			spacing="xs"
			noWrap
			align="start"
		>
			<Group className={classes.leftGroup}>
				<PropertyTitle
					hidden={false}
					value={property.name}
					type={property.type}
					onTypeChange={onChange('type')}
					onChange={onChange('name')}
					onRemove={onRemove}
					readOnly={readOnly}
				/>
			</Group>
			<Group className={classes.rightGroup}>
				<PropertyEditor
					readOnly={readOnly}
					property={property}
					handleChange={onChange}
					isViewerUser={viewerUser}
				/>
			</Group>
		</Group>
	);
}

export default CustomPropertyItem;
