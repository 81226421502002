import { QueryCache, QueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { captureError } from '../web-tracing';

const DEFAULT_RETRY_LIMIT = 3;
const NO_RETRY_STATUS_CODES = [401, 403, 404];

export default new QueryClient({
	defaultOptions: {
		queries: {
			// Keep this set to false. We typically want to NOT suspend on queries
			// but this can be overridden on a per-query basis.
			suspense: false,

			// This might cause users to loose their progress if stale out queries are refetch on window focus, causing the application to re-render.
			refetchOnWindowFocus: false,

			cacheTime: 1000 * 60 * 5, // 5 minutes
			staleTime: 1000 * 60 * 5, // 5 minutes
			retry: (failureCount: number, error: unknown) => {
				if (error instanceof AxiosError && !!error.response?.status) {
					if (NO_RETRY_STATUS_CODES.includes(error.response.status)) {
						return false;
					}
				}

				return failureCount < DEFAULT_RETRY_LIMIT;
			},
		},
	},
	queryCache: new QueryCache({
		onError(error) {
			captureError(error);
		},
	}),
});
