import { createUseExternalEvents } from '@mantine/utils';

export type CatalogEvents = {
	updateTableRow: (data: any) => void;
	deselectAllRows: VoidFunction;
};

export const [useCatalogEvents, createEvent] =
	createUseExternalEvents<CatalogEvents>('catalog-view');

export const updateTableRow = createEvent('updateTableRow');
export const deselectAllRows = createEvent('deselectAllRows');
