import { createStyles, Group, Menu, Stack } from '@mantine/core';
import { pluralize } from '@repo/common/utils';
import { Icon } from '@repo/foundations';
import { find, isEmpty, isNil, map, size, sortBy } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import { useMemo, useState } from 'react';
import type { INotification } from '../../../../api/types/models/notification';
import {
	FilterMenuDropdownHeader,
	FilterMenuTarget,
} from '../../../../components/FilterMenu';
import { clearAllParamsFromUrl, setParamsInUrl } from '../../../../utils/url';
import { inboxPageStore } from '../../store';
import { NOTIFICATION_EVENT_TYPES } from '../constants';
import type { EventType } from '../types';
import InboxActionMenu from './InboxActionMenu/InboxActionMenu';
import InboxFilterItem from './InboxFilterItem';
import InboxSearch from './InboxSearch';
import InboxViewMenu from './InboxViewMenu';

interface IInboxListHeaderProps {
	searchTerm: string;
	setSearchTerm: (searchTerm: string) => void;
	events: string | null;
	setEvents: (events: string) => void;
	isInclusive: boolean;
	setIsInclusive: (value: boolean) => void;
	selected: INotification | undefined;
}

const useStyles = createStyles(
	(theme, { showSearch }: { showSearch: boolean }) => ({
		container: {
			width: '100%',
			padding: '12px 24px',
		},
		dropdown: {
			maxHeight: 500,
			overflowY: 'auto',
		},
		actionsContainer: {
			width: showSearch ? '100%' : 'auto',
		},
	})
);

function InboxListHeader({
	searchTerm,
	setSearchTerm,
	events,
	setEvents,
	isInclusive,
	setIsInclusive,
	selected,
}: IInboxListHeaderProps) {
	const [opened, setOpened] = useState(false);

	const { classes } = useStyles({
		showSearch: inboxPageStore.showSearch,
	});

	const selectedArr = useMemo(() => {
		if (isEmpty(events) || isNil(events)) {
			return [];
		}

		return events.split(',');
	}, [events]);

	const { label, leftIcon } = useMemo(() => {
		if (isEmpty(selectedArr)) {
			return {
				label: 'All',
				leftIcon: <Icon name="layoutGrid" />,
			};
		}
		if (size(selectedArr) === 1) {
			const type = find(
				NOTIFICATION_EVENT_TYPES,
				(event: EventType) => event.value === selectedArr[0]
			);

			return {
				label: type?.label,
				leftIcon: type?.iconName && <Icon name={type?.iconName} />,
			};
		}
		return {
			label: pluralize('filter', size(selectedArr), true),
			leftIcon: <Icon name="layoutGrid" />,
		};
	}, [selectedArr]);

	const handleFilterChange = (o: boolean) => {
		if (!inboxPageStore.filterDropdownHeaderMenuVisible) {
			setOpened(o);
		}
	};

	const onMenuChange = (o: boolean) => {
		inboxPageStore.setFilterDropdownHeaderMenuVisible(o);
	};

	const onInclusiveClick = () => {
		setIsInclusive(true);
		setParamsInUrl('inclusive', true);
	};

	const onExclusiveClick = () => {
		setIsInclusive(false);
		setParamsInUrl('inclusive', false);
	};

	const onDangerClick = () => {
		clearAllParamsFromUrl();
		setEvents('');
		setOpened(false);
	};

	return (
		<Group className={classes.container} position="apart">
			{!inboxPageStore.showSearch && (
				<Menu
					width={256}
					position="bottom-start"
					opened={opened}
					onChange={handleFilterChange}
					closeOnItemClick={false}
					withinPortal
				>
					<Menu.Target>
						<FilterMenuTarget
							variant={isEmpty(selectedArr) ? 'secondary' : 'primary'}
							leftIcon={leftIcon}
							rightIcon={<Icon name="chevronDown" />}
						>
							{label}
						</FilterMenuTarget>
					</Menu.Target>
					<Menu.Dropdown>
						<Stack spacing={0}>
							<FilterMenuDropdownHeader
								label="Notifications"
								dangerLabel="Clear filters"
								isInclusive={isInclusive}
								onMenuChange={onMenuChange}
								onInclusiveClick={onInclusiveClick}
								onExclusiveClick={onExclusiveClick}
								onDangerClick={onDangerClick}
							/>
							{map(sortBy(NOTIFICATION_EVENT_TYPES, 'label'), (event) => (
								<InboxFilterItem
									key={event.value}
									event={event}
									selected={events}
									setSelected={setEvents}
								/>
							))}
						</Stack>
					</Menu.Dropdown>
				</Menu>
			)}
			<Group className={classes.actionsContainer} spacing="xs">
				<InboxActionMenu
					selected={selected}
					hidden={inboxPageStore.showSearch}
				/>
				<InboxViewMenu hidden={inboxPageStore.showSearch} />
				<InboxSearch
					searchTerm={searchTerm}
					setSearchTerm={setSearchTerm}
					hidden={!inboxPageStore.showSearch}
				/>
			</Group>
		</Group>
	);
}

export default observer(InboxListHeader);
