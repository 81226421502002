import { createStyles, Group, Menu, Tooltip } from '@mantine/core';
import { KeyboardShortcut } from '@repo/common/components/KeyboardShortcut/KeyboardShortcut';
import { Icon as IconComponent, Text } from '@repo/foundations';
import type { MenuItem } from '@repo/secoda-editor';
import type { EditorView } from 'prosemirror-view';
import { useMemo, useState } from 'react';
import ToolbarButton from './ToolbarButton';

const useStyles = createStyles((theme) => ({
	menuItem: {
		pointerEvents: 'all',

		'&[data-selected]': {
			backgroundColor: theme.other.getColor('surface/primary/selected'),
		},
	},
}));

interface ToolbarButtonMenuProps {
	item: MenuItem;
	onClick: (option: MenuItem) => void;
	view: EditorView;
}

export function ToolbarButtonMenu({
	item,
	view,
	onClick,
}: ToolbarButtonMenuProps) {
	const { classes } = useStyles();
	const [opened, setOpened] = useState(false);

	const Icon = item.icon!;
	const { state } = view;

	const selectedOption = useMemo(
		() =>
			item.menuOptions?.find((option) =>
				option.active ? option.active(state) : false
			),
		[item, state]
	);
	const SelectedOptionIcon = selectedOption?.icon;

	return (
		<Menu
			opened={opened}
			onChange={setOpened}
			classNames={{ item: classes.menuItem }}
		>
			<Menu.Target>
				<Tooltip label={item.tooltip} position="top">
					<ToolbarButton>
						<Group spacing={0}>
							{SelectedOptionIcon ? <SelectedOptionIcon /> : <Icon />}
							<IconComponent
								name={opened ? 'chevronUp' : 'chevronDown'}
								color="icon/primary/default"
							/>
						</Group>
					</ToolbarButton>
				</Tooltip>
			</Menu.Target>
			<Menu.Dropdown>
				{item.menuOptions?.map((option, optionIndex) => {
					if (!option.icon) {
						return null;
					}

					const OptionIcon = option.icon;
					const isActive = option.active ? option.active(state) : false;

					return (
						<Menu.Item
							key={optionIndex}
							data-testid={`selection-toolbar-${option.name}-c1099ed`}
							onClick={() => onClick(option)}
							data-selected={isActive ? true : undefined}
							rightSection={
								option.shortcutKeys && (
									<KeyboardShortcut keys={option.shortcutKeys} />
								)
							}
						>
							<Group spacing="2xs">
								<OptionIcon />
								<Text size="sm">{option.tooltip}</Text>
							</Group>
						</Menu.Item>
					);
				})}
			</Menu.Dropdown>
		</Menu>
	);
}
