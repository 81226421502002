/* eslint-disable react-hooks/exhaustive-deps */

import type { Embla } from '@mantine/carousel';
import { Carousel as MantineCarousel } from '@mantine/carousel';
import { type ClassNames, type MantineNumberSize } from '@mantine/core';
import { debounce } from 'lodash-es';
import type { CSSProperties, ReactNode } from 'react';
import { forwardRef, useEffect, useState } from 'react';
import useStyles from './Carousel.styles';

export interface ICarouselProps {
	height: CSSProperties['height'];
	gap: MantineNumberSize;
	borderBottomWidth?: number;
	children: ReactNode;
	className?: string;
	classNames?: ClassNames<string>;
	showLeftControl?: boolean;
	showRightControl?: boolean;
	setShowLeftControl?: (show: boolean) => void;
	setShowRightControl?: (show: boolean) => void;
	setEmblaInstance?: (embla: Embla) => void;
	withControls?: boolean;
}

// eslint-disable-next-line react/display-name
export const Carousel = forwardRef<HTMLDivElement, ICarouselProps>(
	(
		{
			height,
			gap,
			borderBottomWidth = 1,
			children,
			className,
			classNames,
			showLeftControl,
			showRightControl,
			setShowLeftControl,
			setShowRightControl,
			setEmblaInstance,
			withControls = true,
		},
		ref
	) => {
		const [showLC, setShowLC] = useState(false);
		const [showRC, setShowRC] = useState(false);

		const [embla, setEmbla] = useState<Embla | null>(null);

		useEffect(() => {
			if (embla) {
				if (setEmblaInstance) {
					setEmblaInstance(embla);
				}

				embla.on('scroll', determineControlVisibility);
				embla.containerNode().parentNode?.addEventListener('wheel', (event) => {
					event.preventDefault();
					scroll(event);
				});

				determineControlVisibility();
			}
			return () => {
				if (embla) {
					embla.off('scroll', determineControlVisibility);
					embla
						.containerNode()
						.parentNode?.removeEventListener('wheel', (event) => {
							event.preventDefault();
							scroll(event);
						});
				}
			};
		}, [embla]);

		const { classes, theme } = useStyles({
			borderBottomWidth,
			showLeftControl: showLeftControl || showLC,
			showRightControl: showRightControl || showRC,
		});

		const scroll = debounce((event) => {
			if (embla) {
				const engine = embla.internalEngine();

				if (engine) {
					engine.scrollBody.useSpeed(5);
					if (event.deltaX > 0) {
						engine.scrollTo.distance(-10, false);
					} else if (event.deltaX < 0) {
						engine.scrollTo.distance(10, false);
					}
				}
			}
		}, 5);

		const determineControlVisibility = debounce(() => {
			if (embla) {
				if (setShowRightControl) {
					setShowRightControl(!!embla.canScrollNext());
				} else {
					setShowRC(!!embla.canScrollNext());
				}

				if (setShowLeftControl) {
					setShowLeftControl(!!embla.canScrollPrev());
				} else {
					setShowLC(!!embla.canScrollPrev());
				}
			}
		}, 5);

		return (
			<MantineCarousel
				ref={ref}
				className={className}
				classNames={{
					root: classes.root,
					controls: classes.controls,
					control: classes.control,
					...classNames,
				}}
				height={height}
				align="start"
				slideGap={gap}
				controlSize={theme.other.space[6]}
				withControls={withControls}
				dragFree
				containScroll="trimSnaps"
				getEmblaApi={setEmbla}
			>
				{children}
			</MantineCarousel>
		);
	}
);
