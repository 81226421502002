import { observer } from 'mobx-react-lite';
import IncidentList from '../../MonitorListPage/IncidentList';

interface IIncidentsTabProps {
	tableId: string;
	onTotalRowCountChange: (count: number) => void;
}

function IncidentsTab({ tableId, onTotalRowCountChange }: IIncidentsTabProps) {
	return (
		<IncidentList
			onTotalRowCountChange={onTotalRowCountChange}
			defaultRequiredSearchParams={{
				target_id: tableId,
			}}
		/>
	);
}

export default observer(IncidentsTab);
