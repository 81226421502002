import { Box, createStyles, Loader } from '@mantine/core';
import axios from 'axios';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { api } from '../../../network';
import { clearCache, clearTokens } from '../../utils/cache/utils';

const useStyles = createStyles(() => ({
	page: {
		marginTop: 50,
		display: 'flex',
		justifyContent: 'center',
		position: 'relative',
	},
	card: {
		width: 500,
		marginTop: 50,
	},
}));

export function AuthImpersonate() {
	const styles = useStyles();

	const [params] = useSearchParams();
	const email = params.get('email');

	useEffect(() => {
		if (email && (email?.length ?? 0 > 2)) {
			axios
				.get(
					`${api()}auth/utilities/account/?email=${encodeURIComponent(email)}`
				)
				.then(async (response) => {
					clearTokens();
					await clearCache();
					localStorage.setItem('email', response.data.email);
					localStorage.setItem(
						'login_flow_type',
						response.data.login_flow_type
					);
					window.open('/', '_self');
				});
		}
	}, [email]);

	return <Box className={styles.classes.page}>{true && <Loader />}</Box>;
}

export default AuthImpersonate;
