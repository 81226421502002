import type { EditorState } from 'prosemirror-state';
import isMarkActive from './isMarkActive';

export default function isInCode(state: EditorState): boolean {
	if (state.schema.nodes.code_block) {
		const { $head } = state.selection;
		for (let d = $head.depth; d > 0; d -= 1) {
			if ($head.node(d).type === state.schema.nodes.code_block) {
				return true;
			}
		}
	}

	return isMarkActive(state.schema.marks.code_inline)(state);
}
