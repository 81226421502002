import { ActionIcon } from '@mantine/core';
import { Icon } from '@repo/foundations';
import { useCallback } from 'react';
import { useDeleteNotification } from '../../../../api/hooks/notification';
import { invalidateNotificationListQuery } from '../../../../api/hooks/notification/utils';
import type { INotification } from '../../../../api/types/models/notification';
import { deleteParamsFromUrl } from '../../../../utils/url';

interface INotificationActionsProps {
	selected: INotification;
}

function NotificationActions({ selected }: INotificationActionsProps) {
	const { mutate: deleteNotification } = useDeleteNotification({
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		options: {
			onSuccess: () => {
				deleteParamsFromUrl('id');
				invalidateNotificationListQuery();
			},
		},
	});

	const handleDelete = useCallback(() => {
		deleteNotification({
			id: selected.id,
		});
	}, [deleteNotification, selected.id]);

	return (
		<ActionIcon size="sm" p={4} onClick={handleDelete}>
			<Icon name="trash" />
		</ActionIcon>
	);
}

export default NotificationActions;
