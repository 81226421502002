import { Flex, Loader } from '@mantine/core';
import type { MantineNumberSize } from '@mantine/styles';
import { space } from '@repo/theme/primitives';

export interface FullWidthLoadingSpinnerProps {
	size?: MantineNumberSize;
	// Remaning props
	[k: string]: unknown;
}

export function FullWidthLoadingSpinner({
	size,
	...otherProps
}: FullWidthLoadingSpinnerProps) {
	return (
		<Flex
			mt={8}
			h="100%"
			w="100%"
			align="center"
			justify="center"
			{...otherProps}
		>
			<Loader size={size || space[12]} />
		</Flex>
	);
}
