import type { Node } from 'prosemirror-model';
import { useMetricList } from '../../../../../api';
import { getQueryBlocks } from '../lib/getQueryBlocks';
import type { CreateGraphModalProps } from './ChartBlock/CreateGraphModal';
import { CreateGraphModal } from './ChartBlock/CreateGraphModal';

export interface CreateGraphModalWrapperProps
	extends Pick<CreateGraphModalProps, 'onClose' | 'onSave'> {
	doc: Node;
}

export function CreateGraphModalWrapper({
	onClose,
	onSave,
	doc,
}: CreateGraphModalWrapperProps) {
	const queryBlocks = getQueryBlocks(doc);
	const metricsIds = queryBlocks.map((qb) => qb.node.attrs.id);

	const { data } = useMetricList({
		filters: {
			id__in: metricsIds.join(','),
			hidden: true,
		},
	});

	return (
		<CreateGraphModal
			metrics={data?.results ?? []}
			onClose={onClose}
			onSave={onSave}
		/>
	);
}
