import { apiClient, getEndpoints } from '../../common';
import queryClient from '../../queryClient';
import { automationJobQueryKeyFactory } from '../automationJob/constants';

export const fetchLatestAutomationJobStatus = ({
	automationId,
}: {
	automationId: string;
}): Promise<string | null> =>
	queryClient.fetchQuery(
		automationJobQueryKeyFactory.byArgs(automationId),
		async () => {
			const url = getEndpoints(automationJobQueryKeyFactory.namespace).byPath([
				automationId,
				'latest_status',
			]);

			const { data } = await apiClient.get(url);

			return data;
		}
	);
