import { EntityType } from '@repo/common/enums/entityType';
import type { ExtendedDataTableColumn } from '../../components/TableV2/types';
import { useColumns as useIncidentColumns } from '../../pages/MonitorListPage/IncidentList.hooks';
import { useColumns as useMonitorColumns } from '../../pages/MonitorListPage/MonitorList.hooks';
import { useColumns as useCatalogColumns } from '../../pages/TeamCatalogPage/TeamCatalogPage.hooks';
import { useColumns as useCollectionColumns } from '../../pages/TeamCollectionsPage/TeamCollectionsPage.hooks';
import { useColumns as useDocumentColumns } from '../../pages/TeamDocumentsPage/TeamDocumentsPage.hooks';
import { useColumns as useGlossaryTermColumns } from '../../pages/TeamGlossaryTermsPage/TeamGlossaryTermsPage.hooks';
import { useColumns as useQuestionColumns } from '../../pages/TeamQuestionsPage/TeamQuestionsPage.hooks';

export const CATALOG_COLUMN_MAPPING: Record<
	string,
	{
		tableV2ColumnsHook?: () => ExtendedDataTableColumn<never>[];
	}
> = {
	catalog: {
		tableV2ColumnsHook: useCatalogColumns,
	},
	monitorTables: {
		tableV2ColumnsHook: useMonitorColumns,
	},
	secoda_entity: {
		tableV2ColumnsHook: useCatalogColumns,
	},
	[EntityType.table]: {
		tableV2ColumnsHook: useCatalogColumns,
	},
	[EntityType.column]: {
		tableV2ColumnsHook: useCatalogColumns,
	},
	[EntityType.dashboard]: {
		tableV2ColumnsHook: useCatalogColumns,
	},
	[EntityType.chart]: {
		tableV2ColumnsHook: useCatalogColumns,
	},
	[EntityType.job]: {
		tableV2ColumnsHook: useCatalogColumns,
	},
	[EntityType.event]: {
		tableV2ColumnsHook: useCatalogColumns,
	},
	[EntityType.event_property]: {
		tableV2ColumnsHook: useCatalogColumns,
	},
	[EntityType.glossary]: {
		tableV2ColumnsHook: useGlossaryTermColumns,
	},
	[EntityType.document]: {
		tableV2ColumnsHook: useDocumentColumns,
	},
	[EntityType.question]: {
		tableV2ColumnsHook: useQuestionColumns,
	},
	[EntityType.collection]: {
		tableV2ColumnsHook: useCollectionColumns,
	},
	[EntityType.user]: {},
	[EntityType.schema]: {
		tableV2ColumnsHook: useCatalogColumns,
	},
	[EntityType.database]: {
		tableV2ColumnsHook: useCatalogColumns,
	},
	entities: {
		tableV2ColumnsHook: useCatalogColumns,
	},
	monitors: {
		tableV2ColumnsHook: useMonitorColumns,
	},
	incidents: {
		tableV2ColumnsHook: useIncidentColumns,
	},
	measurements: {},
	downstreamResources: {},
	integrations: {},
	tunnels: {},
	reports: {},
	automations: {},
	automation_jobs: {},
	automation_history: {},
	[EntityType.template]: {},
	[EntityType.test_run]: {},
};
