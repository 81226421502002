import { baseQueryHooksFactory, createQueryKeys } from '../../factories';
import type { ITest } from '../../types';

const TEST_NAMESPACE = ['table', 'tests'];

export const testsQueryKeyFactory = createQueryKeys(TEST_NAMESPACE);

const { useTestList } = baseQueryHooksFactory<ITest, 'test'>(
	'test',
	testsQueryKeyFactory
);

export { useTestList };
