import { Group, createStyles } from '@mantine/core';
import { IconButton } from '@repo/foundations';
import { colors } from '@repo/theme/primitives';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { type Automation } from '../../../../../../api';

import type { AutomationReceiver } from '../../../../../../api/types/models/automation/automationReceiver';
import { useAutomationStore } from '../../../../../../pages/AutomationPage/context';
import { useAnnouncementGlobalRecipientsList } from '../../../../../Announcement/AnnouncementGlobalRecipients.helpers';
import { AutomationActionCardType } from '../../../../constants';
import type { MultiSelectedItem } from '../../../AutomationCardButton';
import AutomationCardButton from '../../../AutomationCardButton';
import { AutomationValueType } from '../../../constants';

const useStyles = createStyles((theme) => ({
	root: {
		justifyContent: 'space-between',
		width: '100%',
	},
	group: {
		alignSelf: 'stretch',
		background: colors.gray[1],
		width: '100%',
		gap: theme.spacing['2xs'],
	},
}));

interface AutomationActionCardEmailHeaderTextProps {
	handleAutomationUpdate: (automation: Partial<Automation>) => Promise<void>;
	cardId: string;
}

function AutomationActionCardEmailHeaderText({
	handleAutomationUpdate,
	cardId,
}: AutomationActionCardEmailHeaderTextProps) {
	const { classes } = useStyles();
	const { options } = useAnnouncementGlobalRecipientsList();
	const buttonOptions = options.filter((option) => option.type !== 'channel');
	const automationStore = useAutomationStore();
	const { automation } = useAutomationStore();

	const selectedValues = useMemo(() => {
		const values: string[] = [];

		automation?.action_send_email?.users?.forEach((user) => {
			values.push(user.id);
		});

		automation?.action_send_email?.user_groups?.forEach((group) => {
			values.push(group.id);
		});

		return values;
	}, [automation]);

	const handleOnClick = async (items: MultiSelectedItem[]) => {
		const users: AutomationReceiver[] = [];
		const userGroups: AutomationReceiver[] = [];

		items.forEach((item) => {
			if (item.type === 'user' && typeof item.value === 'string') {
				users.push({
					id: item.value,
					display: item.label,
				});
			} else if (item.type === 'group' && typeof item.value === 'string') {
				userGroups.push({
					id: item.value,
					display: item.label,
				});
			}
		});

		await handleAutomationUpdate({
			action_send_email: {
				...automation?.action_send_email,
				user_groups: userGroups,
				users,
			},
		});
	};

	const handleDelete = async () => {
		await handleAutomationUpdate?.({
			action_send_email: null,
		});

		automationStore.removeCard(AutomationActionCardType.SEND_EMAIL, cardId);
	};

	return (
		<Group className={classes.root} noWrap>
			<Group className={classes.group} noWrap>
				Send email to
				<AutomationCardButton
					buttonLabel="recipients"
					buttonOptions={buttonOptions}
					type={AutomationValueType.MULTI_SELECT_DROPDOWN}
					handleMultiSelectorChange={handleOnClick}
					property="recipients"
					placeholder="Select recipients"
					selectedValues={selectedValues}
				/>
			</Group>
			<IconButton variant="tertiary" onClick={handleDelete} iconName="x" />
		</Group>
	);
}

export default observer(AutomationActionCardEmailHeaderText);
