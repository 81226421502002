import { Skeleton } from '@mantine/core';
import { DataQualityProgressIcon } from '../DataQualityProgressIcon';
import { DataQualityBreakdownItemBody } from './DataQualityBreakdownItemBody';

interface DataQualityBreakdownItemLoadingProps {
	value: string;
}

export function DataQualityBreakdownItemLoading({
	value,
}: DataQualityBreakdownItemLoadingProps) {
	return (
		<DataQualityBreakdownItemBody
			value={value}
			disabled
			title={
				<>
					<DataQualityProgressIcon score={-1} />
					<Skeleton width={100} height={18} />
				</>
			}
			badge={<Skeleton width={70} height={20} />}
		/>
	);
}
