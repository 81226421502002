import { Group, Menu, Stack, createStyles } from '@mantine/core';
import { Button, IconButton, Text } from '@repo/foundations';
import produce from 'immer';
import { isIntegrationMetricWidget, type IMetricWidget } from '../../../../api';

import { useMetricFilters } from '../../../../api/hooks/metric/useMetric';
import { WidgetType } from '../../../../interfaces';
import {
	getNumActivatedFilter,
	mergeWorkspaceAnalyticsFilters,
} from '../../utils/utils';
import IntegrationFilters from './IntegrationFilters';
import MonitorFilters from './MonitorFilters';
import WorkspaceFilters from './WorkspaceFilters';

const useStyles = createStyles(() => ({
	dropdown: {
		zIndex: 100,
	},
}));

export type IFilterMenuProps = {
	metricWidget: IMetricWidget;
	updateWidgetMetricMetadata: (
		value: IMetricWidget['metric_metadata'],
		disableInvalidateReport?: boolean
	) => void;
};

export function FilterMenu({
	metricWidget,
	updateWidgetMetricMetadata,
}: IFilterMenuProps) {
	const { classes, theme } = useStyles();
	const { data: availableFilters } = useMetricFilters(
		metricWidget.metric_metadata?.metric_name
	);

	if (!metricWidget.metric_metadata) return null;

	const handleWorkspaceFilterChange = (key: string, value: string | null) => {
		updateWidgetMetricMetadata(
			{
				...metricWidget.metric_metadata,
				user_filter: mergeWorkspaceAnalyticsFilters(
					metricWidget.metric_metadata.user_filter,
					availableFilters,
					key,
					value
				),
			},
			true
		);
	};

	const handleIntegrationFilterChange = (key: string, value: string | null) => {
		const newUserFilters = produce(
			metricWidget.metric_metadata.user_filter,
			(draft) => {
				if (value) {
					return {
						...draft,
						[key]: value,
					};
				}
				delete draft[key];
				return draft;
			}
		);
		updateWidgetMetricMetadata(
			{
				...metricWidget.metric_metadata,
				user_filter: newUserFilters,
			},
			true
		);
	};

	function clearFilter() {
		updateWidgetMetricMetadata(
			{
				...metricWidget.metric_metadata,
				user_filter: {},
			},
			true
		);
	}

	let filters = null;
	if (metricWidget.type === WidgetType.MONITOR) {
		filters = (
			<MonitorFilters
				metricWidget={metricWidget}
				onFilterChange={handleIntegrationFilterChange}
			/>
		);
	} else if (isIntegrationMetricWidget(metricWidget)) {
		filters = (
			<IntegrationFilters
				metricWidget={metricWidget}
				onFilterChange={handleIntegrationFilterChange}
				filterMap={metricWidget.metric_metadata.user_filter}
			/>
		);
	} else {
		filters = (
			<WorkspaceFilters
				metricWidget={metricWidget}
				onFilterChange={handleWorkspaceFilterChange}
			/>
		);

		if (
			!availableFilters ||
			(availableFilters.length === 1 &&
				JSON.stringify(availableFilters[0]) === '{}')
		) {
			return null;
		}
	}

	const numActivatedFilter = getNumActivatedFilter(metricWidget);
	return (
		<Menu position="bottom">
			<Menu.Target>
				<IconButton
					tooltip="Filter"
					iconName="filter"
					variant="tertiary"
					size="sm"
					style={
						numActivatedFilter > 0
							? {
									backgroundColor: theme.colors.gray[4],
								}
							: undefined
					}
				/>
			</Menu.Target>
			<Menu.Dropdown className={classes.dropdown}>
				<Stack spacing="xs" w={256} px={8} py={12}>
					<Group position="apart" pl={4}>
						<Text size="sm" weight="bold">
							Filter by
						</Text>
						<Button size="sm" variant="tertiary" onClick={() => clearFilter()}>
							Clear all
						</Button>
					</Group>
					{filters}
				</Stack>
			</Menu.Dropdown>
		</Menu>
	);
}
