/* eslint-disable react/destructuring-assignment */
import * as React from 'react';
import type { EmbedProps as Props } from '.';
import Frame from './components/Frame';
import Image from './components/Image';

const URL_REGEX = new RegExp(
	'^https?://(datastudio|lookerstudio).google.com/(embed|u/0)/reporting/(.*)/page/(.*)(/edit)?$'
);

export default class LookerStudio extends React.Component<Props> {
	static ENABLED = [URL_REGEX];

	render() {
		return (
			<Frame
				{...this.props}
				src={this.props.attrs.href.replace('u/0', 'embed').replace('/edit', '')}
				icon={
					<Image
						src="/images/looker_studio.webp"
						alt="Looker Studio Icon"
						width={16}
						height={16}
					/>
				}
				canonicalUrl={this.props.attrs.href}
				title="Looker Studio"
				border
			/>
		);
	}
}
