import {
	Breadcrumbs,
	BreadcrumbsSkeleton,
	IconButton,
} from '@repo/foundations';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import {
	NavBarBreadcrumbWrapper,
	NavBarRightSideWrapper,
	NavBarWrapper,
} from '.';
import { usePlan } from '../../../hooks/usePlans';
import AddWidgetButton from '../../../pages/AnalyticsPage/components/Buttons/AddWidgetButton';
import { ExportReportCSVButton } from '../../../pages/AnalyticsPage/components/Buttons/ExportReportCSVButton';
import { DataQualityShareModal } from '../../DataQualityScore/DataQualityShareModal/DataQualityShareModal';
import { appShellStore } from '../store';

function AnalyticsPageNavBar() {
	const { plan } = usePlan();
	const [modalOpen, setModalOpen] = useState(false);

	const {
		navBarUI: {
			analyticsPage: {
				showAddWidgetButton,
				downloadDataQualityScore: { downloadButtonVisible, dataQualityScore },
				reportTitle,
				reportId,
			},
		},
	} = appShellStore;

	if (!plan?.analytics_support) {
		return null;
	}

	return (
		<>
			<NavBarWrapper>
				<NavBarBreadcrumbWrapper>
					{reportTitle === '' || reportId === '' ? (
						<BreadcrumbsSkeleton />
					) : (
						<Breadcrumbs
							crumbs={[
								{
									title: 'Analytics',
									href: '/analytics',
								},
								{
									title: reportTitle,
									href: `/analytics/${reportId}`,
								},
							]}
						/>
					)}
				</NavBarBreadcrumbWrapper>

				<NavBarRightSideWrapper>
					{showAddWidgetButton && (
						<>
							<ExportReportCSVButton />
							<AddWidgetButton />
						</>
					)}
					{downloadButtonVisible && (
						<IconButton
							variant="tertiary"
							iconName="upload"
							onClick={() => setModalOpen(true)}
						/>
					)}
				</NavBarRightSideWrapper>
			</NavBarWrapper>
			<DataQualityShareModal
				initialOpened={modalOpen}
				dataQualityScore={dataQualityScore}
				onClose={() => setModalOpen(false)}
			/>
		</>
	);
}

export default observer(AnalyticsPageNavBar);
