import axios from 'axios';

const CF_WORKER_URL = {
	PUBLIC: 'https://app.secoda.co/image/public/',
	PRIVATE: 'https://app.secoda.co/image/private/',
};

const CF_IMAGE_ENDPOINT = 'https://imagedelivery.net/';

export const uploadImage = async (file: string | Blob, url: string) => {
	try {
		const response = await axios.post(url);
		const {
			result: { uploadURL },
		} = response.data;

		const formData = new FormData();
		formData.append('file', file);

		const result = await axios.post(uploadURL, formData);

		return result.data.result.variants?.[0];
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error('Error uploading image:', error);
		throw error;
	}
};

export const proxyImageThroughLocalAPI = (variant: string) => {
	const path = variant.replace(CF_IMAGE_ENDPOINT, '');
	return `/api/v1/image/${path}/`;
};

export const uploadImagePrivate = async (file: string | Blob) => {
	try {
		const variant = await uploadImage(file, CF_WORKER_URL.PRIVATE);
		return proxyImageThroughLocalAPI(variant);
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error('Error uploading private image:', error);
		throw error;
	}
};

export const uploadImagePublic = async (file: string | Blob) => {
	try {
		const variant = await uploadImage(file, CF_WORKER_URL.PUBLIC);
		return variant;
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error('Error uploading public image:', error);
		throw error;
	}
};
