import { Group } from '@mantine/core';
import { Title } from '@repo/foundations';
import {
	NavBarBreadcrumbWrapper,
	NavBarRightSideWrapper,
	NavBarWrapper,
} from '.';
import AddAutomationButton from '../../Automation/AddAutomationButton';

function AutomationListPageNavbar() {
	return (
		<NavBarWrapper>
			<NavBarBreadcrumbWrapper>
				<Group>
					<Title
						order={1}
						size="sm"
						data-testid="automation-page-nav-bar-title"
					>
						Automations
					</Title>
				</Group>
			</NavBarBreadcrumbWrapper>
			<NavBarRightSideWrapper>
				<AddAutomationButton />
			</NavBarRightSideWrapper>
		</NavBarWrapper>
	);
}

export default AutomationListPageNavbar;
