export function getMarkdownAsPlainText(content: string, lines?: number) {
	const output = content
		// Remove Table of Contents mark
		.replace('[toc]', '')
		// Remove HTML tags
		.replace(/<[^>]*>/g, '')
		// Remove setext-style headers
		.replace(/^[=-]{2,}\s*$/gm, '')
		// Remove footnotes
		.replace(/\[\^.+?\](: .*?$)?/g, '')
		.replace(/\s{0,2}\[.*?\]: .*?$/g, '')
		// Remove images
		.replace(/!\[.*?\][[(].*?[\])]/g, '')
		// Remove inline links
		.replace(/\[(.*?)\][[(].*?[\])]/g, '$1')
		// Remove blockquotes
		.replace(/^\s{0,3}>\s?/gm, '')
		// Remove reference-style links?
		.replace(/^\s{1,2}\[(.*?)\]: (\S+)( ".*?")?\s*$/g, '')
		// Remove atx-style headers
		.replace(/^#{1,6}\s*([^#]*)\s*(#{1,6})?/gm, '$1')
		// Remove emphasis (bold, italic)
		.replace(/\*\*(.*?)\*\*/g, '$1')
		.replace(/\*(.*?)\*/g, '$1')
		.replace(/__(.*?)__/g, '$1')
		.replace(/_(.*?)_/g, '$1')
		// Remove code blocks
		.replace(/```[\s\S]*?```/g, '')
		.replace(/`(.+?)`/g, '$1')
		// Remove horizontal rules
		.replace(/\n-{5,}\s*/g, '\n')
		// Remove list items
		.replace(/^\s*[-+]\s+/gm, '')
		.replace(/^\s*\d+\.\s+/gm, '')
		// Remove extra lines
		.replace(/\n{2,}/g, '\n\n')
		.trim();

	const linesWithoutEmptyLines = output
		.split('\n')
		.filter((line) => !!line.trim());
	const clampedLines = lines
		? linesWithoutEmptyLines.slice(0, lines)
		: linesWithoutEmptyLines;

	return clampedLines.join('\n');
}
