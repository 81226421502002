import { ColorSwatch, Group, Stack } from '@mantine/core';
import { Text } from '@repo/foundations';

export interface MetricWidgetTooltipData {
	title: string;
	items: Array<{ label: string; value: string; color?: string }>;
}

export function MetricWidgetTooltip({ title, items }: MetricWidgetTooltipData) {
	return (
		<Stack spacing="sm" p="sm">
			<Text size="sm" color="text/secondary/default">
				{title}
			</Text>
			<Stack spacing="xs">
				{items.map((item, index) => (
					// eslint-disable-next-line react/no-array-index-key
					<Group key={index} position="apart" spacing="sm" noWrap>
						<Group spacing="xs">
							{item.color && <ColorSwatch size={6} color={item.color} />}
							<Text size="sm" style={{ whiteSpace: 'nowrap' }}>
								{item.label}
							</Text>
						</Group>
						<Text size="sm" fw={500} style={{ whiteSpace: 'nowrap' }}>
							{item.value}
						</Text>
					</Group>
				))}
			</Stack>
		</Stack>
	);
}
