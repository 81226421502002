import type { FILTER_OPTIONS_DIVIDER } from '@repo/common/components/Filter/constants';
import { useEffect, useMemo } from 'react';
import type { FilterOptionType, FilterValue } from '.';
import { SearchFilterV2Store } from '.';
import { deleteParamsFromUrl, setParamsInUrl } from '../../utils/url';
import { useFilterQueryString } from './useFilterQueryString';
import { parseFilterValuesFromLocalStorage } from './utils';

interface UseFilterStoreWithPersistenceOptions {
	filterOptions: (FilterOptionType | typeof FILTER_OPTIONS_DIVIDER)[];
	// The preferences are stored in the local storage, with this key.
	filterLocalStorageKey?: string;
	// The filters are synced to the URL with this key.
	filtersQueryStringKey?: string;
	// This is used to restrict the filters that are shown in the filter bar.
	// It is used to prevent the user from adding filters that are not supported
	// by the table.
	excludeItems?: { [key in FilterOptionType]?: string[] };
}

export function useFilterStoreWithPersistence({
	filterOptions,
	filterLocalStorageKey,
	filtersQueryStringKey,
	excludeItems,
}: UseFilterStoreWithPersistenceOptions) {
	const getFiltersFromUrl = useFilterQueryString(
		filtersQueryStringKey,
		filterOptions
	);

	const searchFilterV2Store = useMemo(() => {
		function onFilterChanged(values: FilterValue[]) {
			if (!!filterLocalStorageKey) {
				localStorage.setItem(filterLocalStorageKey, JSON.stringify(values));
			}

			if (!!filtersQueryStringKey) {
				if (values?.length > 0) {
					setParamsInUrl(filtersQueryStringKey, JSON.stringify(values));
				} else {
					deleteParamsFromUrl(filtersQueryStringKey);
				}
			}
		}

		// load initial values from url or local storage (in that order of priority)
		let initialValues: FilterValue[] = [];
		if (!!filtersQueryStringKey) {
			initialValues = getFiltersFromUrl();
		} else if (!!filterLocalStorageKey) {
			initialValues = parseFilterValuesFromLocalStorage(filterLocalStorageKey);
		}

		return new SearchFilterV2Store({
			filterOptions,
			initialValues,
			preferencesLocalStorageKey: filterLocalStorageKey,
			urlParamToSyncFilters: filtersQueryStringKey,
			excludeItems,
			onFilterChanged,
		});
	}, [
		filterOptions,
		filterLocalStorageKey,
		filtersQueryStringKey,
		excludeItems,
	]);

	useEffect(() => {
		searchFilterV2Store.prefetchPromises();
	}, [searchFilterV2Store]);

	return {
		searchFilterV2Store,
	};
}
