import type { MantineThemeOverride } from '@mantine/core';
import { isNil, omitBy } from 'lodash-es';

export const Input: MantineThemeOverride['components'] = {
	Input: {
		styles: (theme, params, context) => {
			let radius = theme.radius.sm;
			const borderColor: string =
				theme.colorScheme === 'dark'
					? theme.colors.gray[7]
					: theme.colors.gray[5];
			let size = 36;
			const lineHeight = '20px';

			if (context.size === 'sm' || context.size === 'xs') {
				radius = theme.radius.xs;
				size = 28;
			}

			const root = omitBy(
				{
					radius,
					borderColor,
					size,
					width: size,
					lineHeight,
					padding: theme.spacing.xs,
				},
				isNil
			);
			return {
				root,
				input: {
					minHeight: size,
					borderColor,
					borderRadius: radius,
					':focus': {
						borderColor: theme.colors.primary[5],
						// `33` is the alpha value of the color, 20%.
						boxShadow: `0 0 0 2px ${`${theme.colors.primary[5]}33`}`,
					},
					'::placeholder': {
						color: theme.other.getColor('text/secondary/default'),
					},
				},
			};
		},
	},
};
