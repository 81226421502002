import type { MantineTheme } from '@mantine/core';
import {
	Box,
	CopyButton,
	createStyles,
	Group,
	Textarea,
	TextInput,
	Tooltip,
	UnstyledButton,
} from '@mantine/core';
import { useInputState } from '@mantine/hooks';
import { Badge, Text } from '@repo/foundations';
import { typography } from '@repo/theme/primitives';
import { observer } from 'mobx-react-lite';
import type { ChangeEvent } from 'react';
import type { ISecodaEntity } from '../../../api';
import { useAuthUser } from '../../../api';
import type { SecodaEntity } from '../../../lib/models';
import { getEntityDisplayTitle } from '../../../lib/utils/entity';
import { isViewerOfEntity } from '../../../utils/authorization/roles';

export interface IEntityPageTitleProps {
	placeholder: string;
	icon?: React.ReactNode;
	entity:
		| SecodaEntity
		| ISecodaEntity
		| { title: string; title_cased?: string };
	isReadOnly: boolean;
	onChange: (title: string) => void;
	wrapTitle?: boolean;
	size?: keyof typeof typography.title;
}

const useStyles = createStyles(
	(theme: MantineTheme, size: keyof typeof typography.title) => ({
		textAreaRoot: {
			flex: 1,
		},
		textAreaInput: {
			fontSize: typography.text[size],
			fontWeight: typography.weight.bold,
			lineHeight: '32px',
			padding: 0,
			overflowY: 'hidden',

			'&:focus': {
				boxShadow: 'none',
				borderColor: 'transparent',
				backgroundColor: 'transparent',
			},

			'&:disabled': {
				opacity: 1,
				backgroundColor: theme.other.getColor('fill/transparent/default'),
				color: theme.other.getColor('text/primary/default'),
				cursor: 'text',
			},
		},
		titleButton: {
			maxWidth: '100%',
			flex: '1 1 auto',
			minWidth: 0,
		},
		titleGroup: {
			flex: '1 1 auto',
			minWidth: 0,
		},
	})
);

function EntityPageTitle({
	placeholder = 'Untitled',
	icon,
	entity,
	isReadOnly,
	onChange,
	wrapTitle = false,
	size = 'xxl',
}: IEntityPageTitleProps) {
	const { classes } = useStyles(size);
	const { user, isViewerOrGuestUser } = useAuthUser();
	const readOnlyUser =
		'id' in entity && entity.id
			? isViewerOfEntity(user, entity)
			: isViewerOrGuestUser;

	const [title, setTitle] = useInputState(entity.title ?? '');

	const handleChange = (
		event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		const { value } = event.currentTarget;
		setTitle(value);
		onChange(value);
	};

	const casedTitle = getEntityDisplayTitle(entity);

	const copiedText = `"${casedTitle}" copied to clipboard`;

	return isReadOnly || readOnlyUser ? (
		<CopyButton value={casedTitle} timeout={2000}>
			{({ copied, copy }) => (
				<Tooltip
					label={<Text size="xs">{copied ? copiedText : casedTitle}</Text>}
					openDelay={1000}
					position="top-start"
					offset={-10}
				>
					<Group spacing="sm" align="center" noWrap>
						{icon && <Box>{icon}</Box>}
						<Group
							spacing="sm"
							align="baseline"
							noWrap
							className={classes.titleGroup}
						>
							<UnstyledButton
								variant="subtle"
								onClick={copy}
								className={classes.titleButton}
							>
								<Text
									data-testid="input-title"
									size={size}
									weight="bold"
									truncate
								>
									{casedTitle}
								</Text>
							</UnstyledButton>
							{readOnlyUser && (
								<Badge style={{ flex: 'none' }} variant="default">
									Read Only
								</Badge>
							)}
						</Group>
					</Group>
				</Tooltip>
			)}
		</CopyButton>
	) : (
		<Group spacing="sm" align="flex-start" noWrap>
			{icon && <Box>{icon}</Box>}
			{wrapTitle ? (
				<Textarea
					classNames={{
						root: classes.textAreaRoot,
						input: classes.textAreaInput,
					}}
					data-testid="input-title"
					value={title}
					onChange={handleChange}
					placeholder={placeholder}
					variant="unstyled"
					autosize
					size="md"
				/>
			) : (
				<TextInput
					classNames={{
						root: classes.textAreaRoot,
						input: classes.textAreaInput,
					}}
					data-testid="input-title"
					value={title}
					onChange={handleChange}
					placeholder={placeholder}
					variant="unstyled"
					size="md"
				/>
			)}
		</Group>
	);
}

export default observer(EntityPageTitle);
