import * as React from 'react';
import type { EmbedProps as Props } from '.';
import Frame from './components/Frame';

const IFRAME_REGEX =
	/^https:\/\/(invis\.io\/.*)|(projects\.invisionapp\.com\/share\/.*)$/;
const IMAGE_REGEX =
	/^https:\/\/(opal\.invisionapp\.com\/static-signed\/live-embed\/.*)$/;

export default class InVision extends React.Component<Props> {
	static ENABLED = [IFRAME_REGEX, IMAGE_REGEX];

	render() {
		if (IMAGE_REGEX.test(this.props.attrs.href)) {
			return (
				<img
					className={this.props.isSelected ? 'ProseMirror-selectednode' : ''}
					src={this.props.attrs.href}
					alt="InVision Embed"
					style={{
						maxWidth: '100%',
						maxHeight: '75vh',
					}}
				/>
			);
		}

		return (
			<Frame
				{...this.props}
				src={this.props.attrs.href}
				title="InVision Embed"
			/>
		);
	}
}
