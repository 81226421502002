import { UnstyledButton } from '@mantine/core';
import { Text } from '@repo/foundations';
import { useMemo } from 'react';
import type { IUserGroup } from '../../../api';
import { useUpdateUserGroup } from '../../../api';
import { openModal } from '../../ModalManager';
import type { ExtendedDataTableColumn } from '../../TableV2/types';
import { EmojiIcon, GroupsTitle } from './GroupsTitle';
import { MemberRender } from './MemberRender';

export const useAvatarColumn = (): ExtendedDataTableColumn<IUserGroup> => {
	const { mutateAsync } = useUpdateUserGroup({});

	const column: ExtendedDataTableColumn<IUserGroup> = useMemo(
		() => ({
			accessor: 'members',
			title: 'Members',
			render: (record: IUserGroup) => (
				<MemberRender
					record={record}
					onChangeUserOwners={(id) => (value) =>
						mutateAsync({
							data: {
								id,
								users: value,
							},
						})
					}
				/>
			),
		}),
		[mutateAsync]
	);

	return column;
};

export const useUserGroupTitle = (): ExtendedDataTableColumn<IUserGroup> => {
	const column: ExtendedDataTableColumn<IUserGroup> = useMemo(
		() => ({
			accessor: 'name',
			title: 'Name',
			render: (record: IUserGroup) => (
				<UnstyledButton
					sx={{
						display: 'flex',
						flexWrap: 'nowrap',
						alignItems: 'center',
						gap: '8px',
					}}
					onClick={() =>
						openModal({
							title: 'Edit group',
							children: <GroupsTitle group={record} />,
						})
					}
				>
					<EmojiIcon size="md" icon={record.icon} />
					<Text size="sm" weight="semibold">
						{record.name}
					</Text>
				</UnstyledButton>
			),
		}),
		[]
	);

	return column;
};
