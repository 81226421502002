import { SecodaTheme } from '@repo/theme/mantine/secodaTheme';
// @ts-expect-error TS(2724): '"echarts"' has no exported member named 'EChartOp... Remove this comment to see the full error message
import type { EChartOption } from 'echarts';
import produce from 'immer';
import { isEmpty } from 'lodash-es';
import { INITIAL_CHART_CONFIG } from './constants';
import type { EChartConfig } from './types';

interface IChartConfigOptions {
	isWidget?: boolean;
	darkMode?: boolean;
}

const darkThemeColor = SecodaTheme.colors?.dark?.[7] ?? '#1E1B1A';

export const getChartConfig = (
	initialChartConfig?: EChartConfig,
	data: Array<object> = [],
	options: IChartConfigOptions = {}
) =>
	produce(
		!initialChartConfig || isEmpty(initialChartConfig)
			? INITIAL_CHART_CONFIG
			: initialChartConfig,
		(draft) => {
			draft.dataset = [{ source: data }];

			const { isWidget = false, darkMode = false } = options;

			if (!isWidget) {
				draft.xAxis.nameLocation = 'middle';
				draft.yAxis.nameLocation = 'middle';

				draft.yAxis.nameTextStyle = {
					...draft.yAxis.nameTextStyle,
					padding: [-30, 0, 0, 0],
				};
			}

			if (isWidget) {
				draft.darkMode = darkMode;
				draft.backgroundColor = darkMode ? darkThemeColor : '#FFF';
				draft.toolbox = {
					show: false,
				};

				draft.grid.top = 0;
				draft.grid.right = 0;

				draft.yAxis.axisLabel = {
					width: 32,
					overflow: 'truncate',
				} as EChartOption.XAxis['axisLabel'];

				draft.xAxis.axisLabel = {
					width: 32,
					overflow: 'truncate',
				} as EChartOption.XAxis['axisLabel'];
			}
		}
	);
