import type { ModalProps } from '@mantine/core';
import { createUseExternalEvents } from '@mantine/utils';
import type { OpenConfirmModal } from './context';

export type ModalSettings = Partial<Omit<ModalProps, 'opened'>> & {
	modalId?: string;
};

type ModalsEvents = {
	openModal: (props: ModalSettings) => string;
	closeModal(id: string): void;
	closeAllModals(): void;
	openConfirmModal(payload: OpenConfirmModal): void;
	openDeleteConfirmModal(payload: OpenConfirmModal): void;
};

export const [useModalsEvents, createEvent] =
	createUseExternalEvents<ModalsEvents>('mantine-modals');

export const openConfirmModal = createEvent('openConfirmModal');
export const closeAllModals = createEvent('closeAllModals');
export const closeModal = createEvent('closeModal');
export const openModal = createEvent('openModal');
export const openDeleteConfirmModal = createEvent('openDeleteConfirmModal');
