import { createStyles } from '@mantine/core';

interface TreeNodeStyleProps {
	open?: boolean;
	selected?: boolean;
}

export const useTreeNodeStyle = createStyles(
	(theme, { open, selected }: TreeNodeStyleProps) => ({
		button: {
			position: 'relative',
			display: 'flex',
			alignCenter: 'center',
			width: '100%',
			height: theme.other.space[7],
			borderRadius: theme.other.borderRadius.sm,
			paddingTop: theme.other.space[0.25],
			color: theme.other.getColor('text/primary/default'),
			cursor: 'pointer',
			backgroundColor: selected
				? theme.other.getColor('fill/transparent/selected')
				: 'transparent',
			':hover': {
				backgroundColor: theme.other.getColor('fill/transparent/hover'),
			},
			':active': {
				backgroundColor: theme.other.getColor('fill/transparent/active'),
			},
		},
		loadMoreButton: {
			cursor: 'pointer',
			display: 'flex',
			width: '100%',
			height: theme.other.space[7],
			borderRadius: theme.radius.sm,
			fontWeight: theme.other.typography.weight.semibold,
			color: theme.other.getColor('text/primary/default'),
			':hover': {
				backgroundColor: theme.other.getColor('fill/transparent/hover'),
			},
			':active': {
				backgroundColor: theme.other.getColor('fill/transparent/active'),
			},
		},
		toggleContainer: {
			display: 'flex',
			alignItems: 'center',
			width: theme.other.space[7],
		},
		chevron: {
			transform: open ? 'rotate(90deg)' : 'none',
			transition: 'transform 100ms ease',
		},
		chevronButton: {
			padding: 0,
			width: theme.other.space[7],
			height: theme.other.space[7],
			marginRight: theme.other.space[1],
			':hover': {
				backgroundColor: theme.other.getColor('fill/transparent/hover'),
			},
		},
	})
);
