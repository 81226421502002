import { useUnmount } from 'ahooks';
import type React from 'react';
import { useCallback, useContext, useEffect, useRef } from 'react';
import type { To } from 'react-router';
import {
	UNSAFE_NavigationContext as NavigationContext,
	useNavigate as useReactRouterNavigate,
} from 'react-router';
import { MODIFIER_KEY } from './constants';
import { getHref } from './utils';

/**
 * This hook extends the react-router's useNavigate hook with some limitations and
 * added feature.
 *
 * **WARNING**: Use sparingly, only when you **cannot** use the Link component from react-router
 *
 * _Feature_: Allows capturing `CMD+Click` or `Ctrl+Click` and opens the link in new tab / window
 * 	based on the user's browser preference.
 *
 * _Limitation_: Only supports `to` parameter and does not implement replace or modify state
 * 	functionality similar to useNavigate hook by react-router.
 *
 * @returns navigate function similar to the react-router useNavigate hook
 */
export const useNavigate = () => {
	let reactRouterNavigate;

	// Check if react-router is being used
	// react-router doesn't get used by the chrome extension but
	// it's baked into the multi and single selector so this check is necessary
	try {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		reactRouterNavigate = useReactRouterNavigate();
	} catch (e) {
		return (to: To) => {
			// eslint-disable-next-line no-console
			console.log(`Not using react-router, did not redirect to: ${to}`);
		};
	}

	const isModifierPressed = useRef(false);

	const keyDownHandler = useCallback((event: KeyboardEvent) => {
		if (event.key === MODIFIER_KEY) {
			isModifierPressed.current = true;
		}
	}, []);

	const keyUpHandler = useCallback((event: KeyboardEvent) => {
		if (event.key === MODIFIER_KEY) {
			isModifierPressed.current = false;
		}
	}, []);

	const blurHandler = useCallback(() => {
		isModifierPressed.current = false;
	}, []);

	const removeEventListeners = useCallback(() => {
		document.removeEventListener('keydown', keyDownHandler);
		document.removeEventListener('keyup', keyUpHandler);
	}, [keyDownHandler, keyUpHandler]);

	useUnmount(removeEventListeners);

	// Track modifier key pressed/released
	useEffect(() => {
		document.addEventListener('keydown', keyDownHandler);
		document.addEventListener('keyup', keyUpHandler);
		window.onblur = blurHandler;

		return removeEventListeners;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isModifierPressed.current]);

	const { basename, navigator } = useContext(NavigationContext);

	// Navigate to new tab/window or same tab based on modifier key status
	const navigate = useCallback(
		(to: To) => {
			if (isModifierPressed.current) {
				const href = getHref(basename, navigator, to);
				window.open(href, '_blank', 'noopener');
				return;
			}

			reactRouterNavigate(to);
		},
		[basename, navigator, reactRouterNavigate]
	);

	return navigate;
};

/**
 * This hook extends the react-router's useNavigate hook with some limitations and
 * added feature. It generates a event handler that can be directly added to onClick events
 *
 * _Feature_: Allows capturing `CMD+Click` or `Ctrl+Click` and opens the link in new tab / window
 * 	based on the user's browser preference.
 *
 * _Limitation_: Only supports `to` parameter and does not implement replace or modify state
 * 	functionality similar to useNavigate hook by react-router.
 *
 * @returns navigateHandler function to encapsulate mouse click events
 */
export const useNavigateHandler = () => {
	const reactRouterNavigate = useReactRouterNavigate();

	const { basename, navigator } = useContext(NavigationContext);

	// Navigate to new tab/window or same tab based on modifier key status
	const navigateClickHandler = useCallback(
		(event: React.MouseEvent) => (to: To) => {
			const isModifierPressed = event.getModifierState(MODIFIER_KEY);

			if (isModifierPressed) {
				const href = getHref(basename, navigator, to);
				window.open(href, '_blank', 'noopener');
				return;
			}

			reactRouterNavigate(to);
		},
		[basename, navigator, reactRouterNavigate]
	);

	return navigateClickHandler;
};
