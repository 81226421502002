import type { IconNames } from '@repo/foundations';
import { WidgetSize, WidgetType } from '../../../../interfaces';
import type { HomepageWidgetType } from '../../types';

export const WIDGET_HEIGHT = 360;
export const WIDGET_HEADER_HEIGHT = 53;
export const WIDGET_CONTENT_HEIGHT =
	WIDGET_HEIGHT - WIDGET_HEADER_HEIGHT - 2 * 16;

// Keeping the legacy widget sizes
export const WIDGET_SIZE_MAP: Record<WidgetSize, string> = {
	[WidgetSize.SMALL]: '6',
	[WidgetSize.MEDIUM]: '6',
	[WidgetSize.LARGE]: '12',
	[WidgetSize.FULL]: '12',
};

export const WIDGET_TITLE_MAP: Record<HomepageWidgetType, string> = {
	[WidgetType.HELPFUL_LINKS]: 'Helpful links',
	[WidgetType.INTEGRATIONS]: 'Integrations',
	[WidgetType.TEXT_BLOCK]: '',
	[WidgetType.DICTIONARY_TERM_CHART]: 'Dictionary term chart',
	[WidgetType.INBOX]: 'Inbox',
	[WidgetType.ANNOUNCEMENTS]: 'Latest announcements',
	[WidgetType.PINNED_COLLECTIONS]: 'Collections',
	[WidgetType.POPULAR_COLLECTIONS]: 'Collections',
	[WidgetType.BOOKMARKED_RESOURCES]: 'Resources',
	[WidgetType.POPULAR_RESOURCES]: 'Resources',
	[WidgetType.LOWEST_QUALITY_SCORE]: 'Resources',
	[WidgetType.RECENTLY_EDITED_RESOURCES]: 'Resources',
	[WidgetType.RECENTLY_ACCESSED_RESOURCES]: 'Resources',
	[WidgetType.RECENT_SEARCHES]: 'Searches',
	[WidgetType.QUESTIONS_POPULAR]: 'Questions',
	[WidgetType.QUESTIONS_BOOKMARKED]: 'Questions',
	[WidgetType.QUESTIONS_RECENTLY_EDITED]: 'Questions',
	[WidgetType.QUESTIONS_RECENTLY_ACCESSED]: 'Questions',
	[WidgetType.METRIC_LINE_CHART]: '',
	[WidgetType.METRIC_RESOURCE_TABLE]: '',
	[WidgetType.METRIC_USER_TABLE]: '',
	[WidgetType.METRIC_GROUP_TABLE]: '',
	[WidgetType.METRIC_SEARCH_TERM_TABLE]: '',
	[WidgetType.METRIC_BAR_CHART]: '',
	[WidgetType.METRIC_SINGLE_VALUE]: '',
	[WidgetType.METRIC_PIE_CHART]: '',
	[WidgetType.COLLECTION_RESOURCES]: '',
	[WidgetType.DATA_QUALITY_SCORE]: 'Quality score',
	[WidgetType.MONITOR]: 'Monitor',
	[WidgetType.AI_CHAT_HISTORY]: 'AI chat history',
};

export const RESOURCE_WIDGETS = [
	WidgetType.BOOKMARKED_RESOURCES,
	WidgetType.POPULAR_RESOURCES,
	WidgetType.LOWEST_QUALITY_SCORE,
	WidgetType.RECENTLY_EDITED_RESOURCES,
	WidgetType.RECENTLY_ACCESSED_RESOURCES,
] as const;

export const RESOURCE_WIDGET_FILTERS = [
	{
		value: WidgetType.BOOKMARKED_RESOURCES,
		label: 'Bookmarked',
		iconName: 'bookmark' as IconNames,
	},
	{
		value: WidgetType.POPULAR_RESOURCES,
		label: 'Popular',
		iconName: 'flame' as IconNames,
	},
	{
		value: WidgetType.RECENTLY_ACCESSED_RESOURCES,
		label: 'Recently viewed',
		iconName: 'clock' as IconNames,
	},
	{
		value: WidgetType.RECENTLY_EDITED_RESOURCES,
		label: 'Recently edited',
		iconName: 'clockEdit' as IconNames,
	},
	{
		value: WidgetType.LOWEST_QUALITY_SCORE,
		label: 'Lowest quality score',
		iconName: 'progressDown' as IconNames,
	},
];

export const QUESTIONS_WIDGET_FILTERS = [
	{
		value: WidgetType.QUESTIONS_BOOKMARKED,
		label: 'Bookmarked',
		iconName: 'bookmark' as IconNames,
	},
	{
		value: WidgetType.QUESTIONS_POPULAR,
		label: 'Popular',
		iconName: 'flame' as IconNames,
	},
	{
		value: WidgetType.QUESTIONS_RECENTLY_ACCESSED,
		label: 'Recently viewed',
		iconName: 'clock' as IconNames,
	},
	{
		value: WidgetType.QUESTIONS_RECENTLY_EDITED,
		label: 'Recently edited',
		iconName: 'clockEdit' as IconNames,
	},
];

export const QUESTIONS_WIDGETS = [
	WidgetType.QUESTIONS_BOOKMARKED,
	WidgetType.QUESTIONS_POPULAR,
	WidgetType.QUESTIONS_RECENTLY_EDITED,
	WidgetType.QUESTIONS_RECENTLY_ACCESSED,
] as const;

export const COLLECTION_WIDGETS = [
	WidgetType.PINNED_COLLECTIONS,
	WidgetType.POPULAR_COLLECTIONS,
] as const;

export const COLLECTION_WIDGET_FILTERS = [
	{
		value: WidgetType.PINNED_COLLECTIONS,
		label: 'Pinned',
		iconName: 'pinned' as IconNames,
	},
	{
		value: WidgetType.POPULAR_COLLECTIONS,
		label: 'Popular',
		iconName: 'flame' as IconNames,
	},
];
