import SqlEditor from '../Sql/SqlEditor/SqlEditor';

interface IQueryCodeProps {
	query: string;
	minHeight?: number;
}

export function QueryCode({ query, minHeight = 274 }: IQueryCodeProps) {
	return (
		<SqlEditor
			lineNumbers
			defaultValue={query}
			minHeight={minHeight}
			readOnly
		/>
	);
}
