import {
	// eslint-disable-next-line no-restricted-imports
	Breadcrumbs,
	Checkbox,
	createStyles,
	Group,
	Stack,
} from '@mantine/core';
import { Badge, Text } from '@repo/foundations';
import { compact } from 'lodash-es';
import type React from 'react';
import type { ReactNode } from 'react';
import type { AddMonitorItem, UseAddMonitorItemsArgs } from '../../api';

const useStyles = createStyles((theme) => ({
	checkBoxRoot: {
		borderBottom: 'thin solid',
		borderColor: theme.other.getColor('border/primary/default'),
		padding: `0 ${theme.spacing.sm}`,
	},
	checkBoxBody: {
		alignItems: 'center',
	},
	checkBoxLabelWrapper: {
		width: '100%',
	},
	checkBoxLabel: {
		padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
	},
	separator: {
		marginLeft: theme.spacing['3xs'],
		marginRight: theme.spacing['3xs'],
	},
	label: {
		color: theme.other.getColor('text/secondary/default'),
	},
}));

interface MultipleSelectorItemProps {
	isChecked: boolean;
	item: AddMonitorItem;
	onChange: (checked: boolean) => void;
	type: UseAddMonitorItemsArgs['type'];
}

function MultipleSelectorItem({
	isChecked,
	item,
	onChange,
	type,
}: MultipleSelectorItemProps) {
	const { classes } = useStyles();

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		onChange(event.target.checked);
	};

	let label: ReactNode | null = null;

	if (type === 'tables') {
		const breadcrumbs = ['schema', 'database', 'cluster'].map(
			(key) => item?.[key as keyof AddMonitorItem]
		);

		label = (
			<Stack spacing="xxs">
				<Text weight="semibold" size="sm">
					{item.title}
				</Text>
				<Breadcrumbs classNames={{ separator: classes.separator }}>
					{compact(breadcrumbs).map((breadcrumb) => (
						<Text key={breadcrumb} size="xs" className={classes.label}>
							{breadcrumb}
						</Text>
					))}
				</Breadcrumbs>
			</Stack>
		);
	} else {
		label = (
			<Text weight="semibold" size="sm">
				{item.title}
			</Text>
		);
	}

	return (
		<Checkbox
			classNames={{
				root: classes.checkBoxRoot,
				body: classes.checkBoxBody,
				labelWrapper: classes.checkBoxLabelWrapper,
				label: classes.checkBoxLabel,
			}}
			value={item.id}
			label={
				<Group noWrap position="apart">
					{label}
					{item.monitor && <Badge m={0}>Created</Badge>}
				</Group>
			}
			checked={isChecked}
			onChange={handleChange}
			disabled={Boolean(item.monitor)}
		/>
	);
}

export default MultipleSelectorItem;
