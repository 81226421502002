import {
	ActionIcon,
	Box,
	Group,
	Skeleton,
	useMantineTheme,
} from '@mantine/core';
import {
	queryKeyFn,
	useCreateCustomProperty,
	useDeleteCustomProperty,
	useListCustomProperties,
	useUpdateCustomProperty,
} from '@repo/api-codegen';
import { EmptyState } from '@repo/common/components';
import { Button, Icon, Text } from '@repo/foundations';
import { isEmpty } from 'lib0/object';
import { capitalize, cloneDeep, noop, uniqBy } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import type { CatalogProperty } from '../../api';
import { queryClient, useAuthUser } from '../../api';
import { useUpdateCatalogProperties } from '../../api/hooks/catalog';
import { trackEvent } from '../../utils/analytics';
import MenuList from '../CatalogView/CustomizeColumnsPanel/MenuList';
import { useColumnDefs } from '../CatalogView/hooks/useColumnDefs';
import {
	closeAllModals,
	openDeleteConfirmModal,
	openModal,
} from '../ModalManager';
import { PropertyForm } from './CatalogDefaultsSettings.form';
import type { CustomPropertyCatalogProperty } from './TagOptionsButton';
import { TagOptionsButton } from './TagOptionsButton';
import { Section } from './WorkspaceSettings';

const CATALOG_TYPE = 'custom_property';

function CatalogDefaultsSettings() {
	const theme = useMantineTheme();
	const { user, workspace } = useAuthUser();

	const { mutateAsync: updateCatalogProperties } = useUpdateCatalogProperties();
	const { catalog } = useColumnDefs({
		defaultColumns: [],
		catalogServerType: 'catalog',
		catalogType: CATALOG_TYPE,
	});

	const variables = {};
	const { data: customProperties, isLoading } = useListCustomProperties(
		variables,
		{ suspense: false }
	);

	const queryKey = queryKeyFn({
		path: '/resource/all_v2/custom_properties/',
		operationId: 'listCustomProperties',
		variables,
	});

	const { mutateAsync: deleteCustomProperty } = useDeleteCustomProperty();
	const { mutateAsync: updateCustomProperty } = useUpdateCustomProperty({
		onSuccess: () => {
			queryClient.invalidateQueries(queryKey);
		},
	});
	const { mutateAsync: createCustomProperty } = useCreateCustomProperty({
		onSuccess: () => {
			queryClient.invalidateQueries(queryKey);
		},
	});

	const propertiesToDisplay: CatalogProperty[] = useMemo(() => {
		const customPropertyList =
			customProperties
				?.sort((a, b) => {
					const aIndex =
						catalog?.properties.find((p) => p.value === a.name)?.order ?? 0;
					const bIndex =
						catalog?.properties.find((p) => p.value === b.name)?.order ?? 0;
					return aIndex - bIndex;
				})
				.map((cp, index) => ({
					...cp,
					default: false,
					value: cp.name,
					hidden: false,
					order: index,
				})) || [];

		return customPropertyList;
	}, [catalog?.properties, customProperties]);

	const onColumnReorder = useCallback(
		async (column: string | number, _toIndex: number) => {
			if (!catalog) {
				return null;
			}

			const toIndex = _toIndex - 1;

			const draft = cloneDeep(propertiesToDisplay);

			// Reorder column by name.
			const [removed] = draft.splice(
				draft.findIndex((f) => f.value === column),
				1
			);

			draft.splice(toIndex, 0, removed);

			// Update order of all properties.
			draft.forEach((draftProperty, index) => {
				draftProperty.order = index;
			});

			const result = await updateCatalogProperties({
				...catalog,
				properties: uniqBy(draft, (el) => el.value),
			});

			return result;
		},
		[catalog, propertiesToDisplay, updateCatalogProperties]
	);

	const handleColumnOrderChange = useCallback(
		async (columnName: string, toIndex: number) => {
			const reorderedCatalog = await onColumnReorder(columnName, toIndex + 1);

			if (reorderedCatalog) {
				trackEvent('catalog/customize/reorder', {}, user, workspace);
			}
		},
		[onColumnReorder, user, workspace]
	);

	const existingLabels = useMemo(
		() => propertiesToDisplay.map((column) => column.value),
		[propertiesToDisplay]
	);

	const getSubText = useCallback(
		(columnName: string): React.ReactNode => {
			const property = propertiesToDisplay?.find(
				(cp) => cp.value === columnName
			) as CustomPropertyCatalogProperty;
			if (property) {
				return (
					<Text size="xs" variant="text">
						{property?.entity_types
							?.map(capitalize)
							.map((type: string) =>
								type === 'all' ? 'Catalog' : type.replace(/_/g, ' ')
							)
							.join(', ')}
					</Text>
				);
			}
			return null;
		},
		[propertiesToDisplay]
	);

	const handleDeleteColumn = useCallback(
		(columnName: string) => {
			openDeleteConfirmModal({
				title: 'Delete Property',
				description: 'Are you sure you want to delete this property?',
				confirmLabel: 'Delete',
				onConfirm: () => {
					deleteCustomProperty({
						pathParams: {
							customPropertyId:
								customProperties?.find((cp) => cp.name === columnName)?.id ||
								'',
						},
					})
						.then(() => {
							queryClient.invalidateQueries(queryKey);
						})
						.catch((error) => {
							// eslint-disable-next-line no-console
							console.error('Failed to delete custom property:', error);
						});
				},
			});
		},
		[customProperties, deleteCustomProperty, queryKey]
	);

	return (
		<Box>
			<Section title="Custom properties">
				<Text size="xs">
					These global defaults can be overridden by specific settings on the
					catalog page. If a column below does not apply to a catalog, the
					ordering and visibility setting will not apply.
				</Text>
				<Group>
					<Button
						onClick={() =>
							openModal({
								title: 'Create property',
								children: (
									<PropertyForm
										fields={['label', 'type', 'visibility']}
										onCancel={closeAllModals}
										onConfirm={(label, type, visibility) => {
											createCustomProperty({
												body: {
													name: label,
													value_type: type,
													entity_types: visibility,
												},
											});
											closeAllModals();
										}}
										existingLabels={existingLabels}
									/>
								),
							})
						}
					>
						Create property
					</Button>
				</Group>
				{isLoading && <Skeleton height={100} />}
				{!isLoading && isEmpty(propertiesToDisplay) && (
					<EmptyState
						iconName="tableColumn"
						title="No custom properties"
						description="Create a custom property to get started"
						includeGoBack={false}
						size="sm"
					/>
				)}
				{!isEmpty(propertiesToDisplay) && (
					<Box
						p={'xs'}
						sx={{
							border: `1px solid ${theme.other.getColor('border/secondary/default')}`,
							borderRadius: theme.radius.md,
						}}
					>
						<MenuList
							// eslint-disable-next-line react/no-unstable-nested-components
							getRightContent={(columnName) => (
								<Group className="custom-property-action-buttons" spacing="xs">
									<ActionIcon
										onClick={() => {
											const property = propertiesToDisplay.find(
												(p) => p.value === columnName
											) as CustomPropertyCatalogProperty;
											openModal({
												title: 'Edit Property',
												children: (
													<PropertyForm
														fields={
															!property?.default
																? ['label', 'type', 'visibility']
																: ['visibility']
														}
														onCancel={closeAllModals}
														onConfirm={(label, type, visibility) => {
															updateCustomProperty({
																pathParams: {
																	customPropertyId:
																		customProperties?.find(
																			(cp) => cp.name === columnName
																		)?.id || '',
																},
																body: {
																	name: label,
																	value_type: type,
																	entity_types: visibility,
																},
															});
															closeAllModals();
														}}
														existingLabels={existingLabels.filter(
															(label) => label !== columnName
														)}
														initialValues={{
															label: property?.value || '',
															type: property?.value_type || '',
															visibility: property?.entity_types || [],
														}}
													/>
												),
											});
										}}
										size="xs"
									>
										<Icon name="pencil" />
									</ActionIcon>
									<TagOptionsButton
										property={
											propertiesToDisplay.find(
												(p) => p.value === columnName
											) as CustomPropertyCatalogProperty
										}
										customPropertyId={
											customProperties?.find((cp) => cp.name === columnName)?.id
										}
									/>
									{!propertiesToDisplay.find((p) => p.value === columnName)
										?.default && (
										<ActionIcon
											onClick={() => handleDeleteColumn(columnName)}
											size="xs"
										>
											<Icon name="trash" />
										</ActionIcon>
									)}
								</Group>
							)}
							catalogType={CATALOG_TYPE}
							catalogProperties={propertiesToDisplay}
							onColumnOrderChange={handleColumnOrderChange}
							onVisibilityChange={noop}
							getSubText={getSubText}
						/>
					</Box>
				)}
			</Section>
		</Box>
	);
}

export default CatalogDefaultsSettings;
