import { useLocalStorage } from '@mantine/hooks';
import { useCallback } from 'react';
import { useWorkspace } from '../api';

export function useFullWidthToggle(entityId?: string) {
	const { workspace } = useWorkspace();
	const defaultValue = workspace?.default_full_width_pages ?? false;

	const [shouldSetFullWidth, setValue, removeValue] = useLocalStorage({
		key: `fullWidthToggle-${entityId}`,
		defaultValue,
	});

	const toggle = useCallback(() => {
		const newValue = !shouldSetFullWidth;

		if (newValue === defaultValue) {
			removeValue();
			return;
		}

		setValue(newValue);
	}, [defaultValue, removeValue, setValue, shouldSetFullWidth]);

	return {
		shouldSetFullWidth,
		toggle,
	};
}
