import { colors } from '@repo/theme/primitives';
import type { IIntegrationMetric, IMetricWidgetSample } from '../../../../api';
import { MetricName, useIntegrationMetricList } from '../../../../api';
import { WidgetType } from '../../../../interfaces';
import { DEFAULT_COLORS, LOOKBACK_KEY } from '../../utils/utils';

import { WORKSPACE_ANALYTICS_ALLOWED_WIDGETS_TO_ADD } from './constants';

type IMetricWidgetSampleVariant = Partial<IMetricWidgetSample> &
	Partial<IMetricWidgetSample['metric_metadata']>;

const baseMetricWidgetMetadata = (
	metric: IIntegrationMetric
): Partial<IMetricWidgetSample['metric_metadata']> => ({
	integration_id: metric.integration_id,
	integration_type: metric.integration_type,
	metric_id: metric.id,
	fixed_filter: {
		[LOOKBACK_KEY]: 'weekly',
	},
	user_filter: {},
	filter_options: metric.filter_options,
});

const genDBTTestWidgets = (metric: IIntegrationMetric) => {
	const variants: IMetricWidgetSampleVariant[] = [
		{
			title: 'dbt test execution time',
			group: 'Pipeline health',
			groupIconName: 'heartbeat',
			value: { execution_time: 'avg' },
			suffix: ' seconds',
			type: WidgetType.METRIC_LINE_CHART,
		},
		{
			title: 'dbt test results',
			value: {
				fail: 'sum',
				pass: 'sum',
				error: 'sum',
				skipped: 'sum',
				warn: 'sum',
			},
		},
		{
			title: 'dbt test failures',
			value: { fail: 'sum' },
		},
		{
			title: 'dbt test passes',
			value: { pass: 'sum' },
		},
		{
			title: 'dbt test errors',
			value: { error: 'sum' },
		},
		{
			title: 'dbt test skips',
			value: { skipped: 'sum' },
		},
		{
			title: 'dbt test warnings',
			value: { warn: 'sum' },
		},
	];
	return variants.map(
		(variant) =>
			({
				group: variant.group || 'Data quality',
				groupIconName: variant.groupIconName || 'ribbonHealth',
				type: variant?.type || WidgetType.METRIC_LINE_CHART,
				title: variant.title,

				metric_metadata: {
					...baseMetricWidgetMetadata(metric),
					metric_name: MetricName.DBT_TEST_RESULTS,

					suffix: variant?.suffix || ' tests',
					value: variant.value,
					value_color: {
						execution_time: colors.cyan[4],
						fail: colors.orange[4],
						pass: colors.green[4],
						error: colors.red[4],
						skipped: colors.gray[4],
						warn: colors.yellow[4],
					},
				},
			}) as IMetricWidgetSample
	);
};

const genDBTModelTestWidgets = (metric: IIntegrationMetric) => {
	const variants: IMetricWidgetSampleVariant[] = [
		{
			title: 'dbt model test coverage',
			value: { has_test: 'max' },
		},
		{
			title: 'dbt model test failures',
			value: { test_failed: 'sum' },
		},
	];
	return variants.map(
		(variant) =>
			({
				group: 'Data quality',
				groupIconName: 'ribbonHealth',
				type: variant?.type || WidgetType.METRIC_LINE_CHART,
				title: variant.title,

				metric_metadata: {
					...baseMetricWidgetMetadata(metric),
					metric_name: MetricName.DBT_TABLE_TEST_RESULTS,
					suffix: 'has_test' in (variant.value ?? {}) ? '' : ' models',
					value: variant.value,
					is_percentage: 'has_test' in (variant.value ?? {}) ? true : false,
				},
			}) as IMetricWidgetSample
	);
};

const genSnowflakeCostWidgets = (metric: IIntegrationMetric) => {
	const variants: IMetricWidgetSampleVariant[] = [
		{
			title: 'Snowflake costs',
			value: { cost: 'sum' },
			prefix: '$',
		},
		{
			title: 'Snowflake costs total',
			value: { cost: 'sum' },
			prefix: '$',
			type: WidgetType.METRIC_SINGLE_VALUE,
		},
	];
	return variants.map(
		(variant) =>
			({
				group: 'Pipeline health',
				groupIconName: 'heartbeat',
				type: variant.type || WidgetType.METRIC_LINE_CHART,
				title: variant.title,

				metric_metadata: {
					...baseMetricWidgetMetadata(metric),
					metric_name: MetricName.SNOWFLAKE_COST,
					value: variant.value,
					value_color: {
						cost: DEFAULT_COLORS[0],
					},
					prefix: variant.prefix,
				},
			}) as IMetricWidgetSample
	);
};

export const genSnowflakeCreditPerWarehouseWidgets = (
	metric: IIntegrationMetric
) => {
	const variants: IMetricWidgetSampleVariant[] = [
		{
			title: 'Snowflake warehouse metering history',
			value: { compute_credits: 'sum', cloud_services_credits: 'sum' },
		},
	];
	return variants.map(
		(variant) =>
			({
				group: 'Pipeline health',
				groupIconName: 'heartbeat',
				type: WidgetType.METRIC_LINE_CHART,
				title: variant.title,

				metric_metadata: {
					...baseMetricWidgetMetadata(metric),
					metric_name: MetricName.SNOWFLAKE_CREDITS_PER_WAREHOUSE,
					value: variant.value,
					value_color: {
						compute_credits: DEFAULT_COLORS[0],
						cloud_services_credits: DEFAULT_COLORS[1],
					},
					suffix: ' credits',
				},
			}) as IMetricWidgetSample
	);
};

export const genSnowflakeCreditPerUserWidgets = (
	metric: IIntegrationMetric
) => {
	const variants: IMetricWidgetSampleVariant[] = [
		{
			title: 'Snowflake user query credits',
			value: { credits: 'sum' },
		},
	];
	return variants.map(
		(variant) =>
			({
				group: 'Pipeline health',
				groupIconName: 'heartbeat',
				type: WidgetType.METRIC_LINE_CHART,
				title: variant.title,

				metric_metadata: {
					...baseMetricWidgetMetadata(metric),
					metric_name: MetricName.SNOWFLAKE_CREDITS_PER_USER,
					value: variant.value,
					suffix: ' credits',
				},
			}) as IMetricWidgetSample
	);
};

export const genSnowflakeQueryVolumeWidgets = (metric: IIntegrationMetric) => {
	const variants: IMetricWidgetSampleVariant[] = [
		{
			title: 'Snowflake query volume',
			value: { query_count: 'sum' },
		},
		{
			title: 'Snowflake average daily query volume',
			value: { query_count: 'avg' },
			type: WidgetType.METRIC_SINGLE_VALUE,
		},
	];
	return variants.map(
		(variant) =>
			({
				group: 'Pipeline health',
				groupIconName: 'heartbeat',
				type: variant.type || WidgetType.METRIC_LINE_CHART,
				title: variant.title,

				metric_metadata: {
					...baseMetricWidgetMetadata(metric),
					metric_name: MetricName.SNOWFLAKE_QUERY_VOLUME,
					value: variant.value,
					suffix: ' queries',
				},
			}) as IMetricWidgetSample
	);
};

export const genSnowflakeStorageCreditsWidgets = (
	metric: IIntegrationMetric
) => {
	const variants: IMetricWidgetSampleVariant[] = [
		{
			title: 'Snowflake storage credits usage',
			value: { average_database_bytes: 'sum', average_failsafe_bytes: 'sum' },
		},
	];
	return variants.map(
		(variant) =>
			({
				group: 'Pipeline health',
				groupIconName: 'heartbeat',
				type: variant.type || WidgetType.METRIC_LINE_CHART,
				title: variant.title,

				metric_metadata: {
					...baseMetricWidgetMetadata(metric),
					is_bytes: true,
					metric_name: MetricName.SNOWFLAKE_STORAGE_USAGE,
					value: variant.value,
					value_color: {
						average_database_bytes: DEFAULT_COLORS[0],
						average_failsafe_bytes: DEFAULT_COLORS[1],
					},
				},
			}) as IMetricWidgetSample
	);
};

export const genSnowflakeComputeCreditsWidgets = (
	metric: IIntegrationMetric
) => {
	const variants: IMetricWidgetSampleVariant[] = [
		{
			title: 'Snowflake compute credits usage',
			value: {
				compute_credits: 'sum',
				cloud_services_credits: 'sum',
				adjustment_cloud_services_credits: 'sum',
			},
		},
	];
	return variants.map(
		(variant) =>
			({
				group: 'Pipeline health',
				groupIconName: 'heartbeat',
				type: variant?.type || WidgetType.METRIC_BAR_CHART,
				title: variant.title,

				metric_metadata: {
					...baseMetricWidgetMetadata(metric),
					metric_name: MetricName.SNOWFLAKE_COMPUTE_USAGE,
					value: variant.value,
					value_color: {
						compute_credits: DEFAULT_COLORS[0],
						cloud_services_credits: DEFAULT_COLORS[1],
						adjustment_cloud_services_credits: DEFAULT_COLORS[2],
					},
				},
			}) as IMetricWidgetSample
	);
};

export const useWidgetsToAdd = () => {
	const { data: metrics } = useIntegrationMetricList({
		options: {
			select: (data) => data.results,
		},
	});

	let widgetSamples = [...WORKSPACE_ANALYTICS_ALLOWED_WIDGETS_TO_ADD];

	// TODO: cleanup logic https://github.com/secoda/secoda/pull/2091#discussion_r1388179528
	const dbtTestMetric = metrics?.find(
		(metric) => metric.metric_type === 'dbt_test'
	);

	if (dbtTestMetric) {
		widgetSamples = widgetSamples.concat(genDBTTestWidgets(dbtTestMetric));
	}

	const dbtTableTestMetric = metrics?.find(
		(metric) => metric.metric_type === 'dbt_table_test'
	);

	if (dbtTableTestMetric) {
		widgetSamples = widgetSamples.concat(
			genDBTModelTestWidgets(dbtTableTestMetric)
		);
	}

	const snowflakeCostMetric = metrics?.find(
		(metric) => metric.metric_type === 'snowflake_cost'
	);

	if (snowflakeCostMetric) {
		widgetSamples = widgetSamples.concat(
			genSnowflakeCostWidgets(snowflakeCostMetric)
		);
	}

	const snowflakeCreditsPerWarehouseMetric = metrics?.find(
		(metric) => metric.metric_type === 'snowflake_credits_per_warehouse'
	);

	if (snowflakeCreditsPerWarehouseMetric) {
		widgetSamples = widgetSamples.concat(
			genSnowflakeCreditPerWarehouseWidgets(snowflakeCreditsPerWarehouseMetric)
		);
	}

	const snowflakeCreditsPerUserMetric = metrics?.find(
		(metric) => metric.metric_type === 'snowflake_credits_per_user'
	);

	if (snowflakeCreditsPerUserMetric) {
		widgetSamples = widgetSamples.concat(
			genSnowflakeCreditPerUserWidgets(snowflakeCreditsPerUserMetric)
		);
	}

	const snowflakeQueryVolumeMetric = metrics?.find(
		(metric) => metric.metric_type === 'snowflake_query_volume'
	);

	if (snowflakeQueryVolumeMetric) {
		widgetSamples = widgetSamples.concat(
			genSnowflakeQueryVolumeWidgets(snowflakeQueryVolumeMetric)
		);
	}

	const snowflakeStorageCreditsMetric = metrics?.find(
		(metric) => metric.metric_type === 'snowflake_storage_usage'
	);

	if (snowflakeStorageCreditsMetric) {
		widgetSamples = widgetSamples.concat(
			genSnowflakeStorageCreditsWidgets(snowflakeStorageCreditsMetric)
		);
	}

	const snowflakeComputeCreditsMetric = metrics?.find(
		(metric) => metric.metric_type === 'snowflake_compute_usage'
	);

	if (snowflakeComputeCreditsMetric) {
		widgetSamples = widgetSamples.concat(
			genSnowflakeComputeCreditsWidgets(snowflakeComputeCreditsMetric)
		);
	}

	return widgetSamples;
};
