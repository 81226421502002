import { flattenDeep } from 'lodash-es';
import type { Node } from 'prosemirror-model';
import type { Transaction } from 'prosemirror-state';
import { Plugin, PluginKey } from 'prosemirror-state';
import { findBlockNodes } from 'prosemirror-utils';
import { Decoration, DecorationSet } from 'prosemirror-view';
import type { RefractorElement } from 'refractor';
import { refractor } from 'refractor';

export const LANGUAGES = {
	none: 'None', // Additional entry to disable highlighting
	bash: 'Bash',
	css: 'CSS',
	clike: 'C',
	csharp: 'C#',
	go: 'Go',
	markup: 'HTML',
	objectivec: 'Objective-C',
	java: 'Java',
	javascript: 'JavaScript',
	json: 'JSON',
	perl: 'Perl',
	php: 'PHP',
	powershell: 'Powershell',
	python: 'Python',
	ruby: 'Ruby',
	rust: 'Rust',
	sql: 'SQL',
	typescript: 'TypeScript',
	yaml: 'YAML',
};

type ParsedNode = {
	text: string;
	classes: string[];
};

const cache: Record<number, { node: Node; decorations: Decoration[] }> = {};

function getDecorations({ doc, name }: { doc: Node; name: string }) {
	const decorations: Decoration[] = [];
	const blocks: { node: Node; pos: number }[] = findBlockNodes(doc).filter(
		(item) => item.node.type.name === name
	);

	function parseNodes(
		_nodes: RefractorElement[],
		classNames: string[] = []
	): any {
		let nodes = _nodes;

		// @ts-expect-error TS(2339): Property 'children' does not exist on type 'Refrac... Remove this comment to see the full error message
		if (nodes.children) {
			// @ts-expect-error TS(2339): Property 'children' does not exist on type 'Refrac... Remove this comment to see the full error message
			nodes = nodes.children;
		}

		const result = nodes?.map((node) => {
			if (node.type === 'element') {
				// @ts-expect-error TS(2488): Type 'string | number | true | (string | number)[]... Remove this comment to see the full error message
				const classes = [...classNames, ...(node.properties.className || [])];
				// @ts-expect-error TS(2345): Argument of type '(RefractorElement | Text)[]' is ... Remove this comment to see the full error message
				return parseNodes(node.children, classes);
			}

			return {
				// @ts-expect-error TS(2339): Property 'value' does not exist on type 'Refractor... Remove this comment to see the full error message
				text: node.value,
				classes: classNames,
			};
		});

		return result;
	}

	blocks.forEach((block) => {
		let startPos = block.pos + 1;
		const { language } = block.node.attrs;
		if (!language || language === 'none' || !refractor.registered(language)) {
			return;
		}

		if (!cache[block.pos] || !cache[block.pos].node.eq(block.node)) {
			const nodes = refractor.highlight(block.node.textContent, language);
			// @ts-expect-error TS(2345): Argument of type 'RefractorRoot' is not assignable... Remove this comment to see the full error message
			// eslint-disable-next-line no-underscore-dangle
			const _decorations = (flattenDeep(parseNodes(nodes)) as ParsedNode[])
				?.map((node: ParsedNode) => {
					const from = startPos;
					const to = from + node.text.length;

					startPos = to;

					return {
						...node,
						from,
						to,
					};
				})
				.filter((node) => node.classes && node.classes.length)
				.map((node) =>
					Decoration.inline(node.from, node.to, {
						class: node.classes.join(' '),
					})
				);

			cache[block.pos] = {
				node: block.node,
				decorations: _decorations,
			};
		}
		cache[block.pos].decorations.forEach((decoration) => {
			decorations.push(decoration);
		});
	});

	Object.keys(cache)
		.filter((pos) => !blocks.find((block) => block.pos === Number(pos)))
		.forEach((pos) => {
			delete cache[Number(pos)];
		});

	return DecorationSet.create(doc, decorations);
}

export default function Prism({ name }: { name: string }) {
	let highlighted = false;

	return new Plugin({
		key: new PluginKey('prism'),
		state: {
			// @ts-expect-error TS(2322): Type '(_: Plugin<any>, { doc }: EditorState) => De... Remove this comment to see the full error message
			init: (_: Plugin, { doc }) => DecorationSet.create(doc, []),
			apply: (transaction: Transaction, decorationSet, oldState, state) => {
				const nodeName = state.selection.$head.parent.type.name;
				const previousNodeName = oldState.selection.$head.parent.type.name;
				const codeBlockChanged =
					transaction.docChanged && [nodeName, previousNodeName].includes(name);
				const ySyncEdit = !!transaction.getMeta('y-sync$');

				if (!highlighted || codeBlockChanged || ySyncEdit) {
					highlighted = true;
					return getDecorations({ doc: transaction.doc, name });
				}

				return decorationSet.map(transaction.mapping, transaction.doc);
			},
		},
		view: (view) => {
			if (!highlighted) {
				// We don't highlight code blocks on the first render as part of mounting
				// as it's expensive (relative to the rest of the document). Instead let
				// it render un-highlighted and then trigger a defered render of Prism
				// by updating the plugins metadata
				setTimeout(() => {
					view.dispatch(view.state.tr.setMeta('prism', { loaded: true }));
				}, 10);
			}
			return {};
		},
		props: {
			decorations(state) {
				return this.getState(state);
			},
		},
	});
}
