// @ts-expect-error TS(2724): '"echarts"' has no exported member named 'EChartOp... Remove this comment to see the full error message
import type { EChartOption } from 'echarts';

export enum AxisType {
	'value' = 'value',
	'category' = 'category',
	'time' = 'time',
	'log' = 'log',
}

export interface IAxisConfig {
	type: AxisType;
	label: string;
	column: string;
	padding: number;
}

export interface EChartConfig {
	darkMode?: boolean;
	backgroundColor?: string;
	color?: string[];
	grid: EChartOption.Grid;
	title: Title;
	dataset: EChartOption.Dataset[];
	xAxis: EChartOption.XAxis;
	yAxis: EChartOption.YAxis;
	tooltip: EChartOption.Tooltip;
	legend?: EChartOption.Legend;
	series: Series[];
	toolbox: Toolbox;
}

interface Toolbox {
	show: boolean;
	feature?: object;
}

interface Series {
	type: string;
	areaStyle?: object;
	smooth?: boolean;
	encode: Encode;
}

interface Encode {
	x: string | number;
	y: string | number;
}

interface Title {
	text: string;
}

export enum ChartType {
	'bar' = 'bar',
	'line' = 'line',
	'smooth' = 'smooth',
	'area' = 'area',
	'scatter' = 'scatter',
}
