import { ActionIcon } from '@mantine/core';
import { Icon } from '@repo/foundations';
import { useCallback } from 'react';
import { useAuthUser } from '../../../../../api';
import SearchBox from '../../../../../components/SearchBox/SearchBox';
import { trackEvent } from '../../../../../utils/analytics';
import { setParamsInUrl } from '../../../../../utils/url';
import { inboxPageStore } from '../../../store';

interface IInboxSearchProps {
	searchTerm: string;
	setSearchTerm: (searchTerm: string) => void;
	hidden: boolean;
}

function InboxSearch({ searchTerm, setSearchTerm, hidden }: IInboxSearchProps) {
	const { user, workspace } = useAuthUser();

	const handleTermChange = (term: string) => {
		setSearchTerm(term);
		setParamsInUrl('searchTerm', term);
	};

	const handleOpen = useCallback(() => {
		trackEvent('inbox/search/click', {}, user, workspace!);
		inboxPageStore.setShowSearch(true);
	}, []);

	const handleClose = useCallback(() => {
		setSearchTerm('');
		inboxPageStore.setShowSearch(false);
	}, []);

	if (hidden) {
		return (
			<ActionIcon size="sm" p={4} onClick={handleOpen}>
				<Icon name="search" color="icon/primary/default" />
			</ActionIcon>
		);
	}

	return (
		<SearchBox
			alwaysShowCancel
			defaultSearchTerm={searchTerm}
			onSearch={handleTermChange}
			onCancelSearch={handleClose}
			placeholder="Search your notifications"
		/>
	);
}

export default InboxSearch;
