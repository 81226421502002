import { createContext, useContext, useMemo } from 'react';
import { AutomationActionCardPropagateMetadataStore } from './store';

const AutomationActionCardPropagateMetadataContext =
	createContext<AutomationActionCardPropagateMetadataStore | null>(null);

export interface AutomationActionCardPropagateMetadataStoreProviderProps {
	children: React.ReactNode;
}

export function AutomationActionCardPropagateMetadataStoreProvider({
	children,
}: AutomationActionCardPropagateMetadataStoreProviderProps) {
	const store = useMemo(
		() => new AutomationActionCardPropagateMetadataStore(),
		[]
	);

	return (
		<AutomationActionCardPropagateMetadataContext.Provider value={store}>
			{children}
		</AutomationActionCardPropagateMetadataContext.Provider>
	);
}

export function useAutomationActionCardPropagateMetadataStore() {
	const ctx = useContext(AutomationActionCardPropagateMetadataContext);
	if (ctx === null) {
		throw new Error(
			'useFormContext was called outside of FormProvider context for AutomationActionCardPropagateMetadataStore'
		);
	}

	return ctx;
}
