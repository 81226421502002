import { Box, createStyles, Flex, Group, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { Button } from '@repo/foundations';
import { isEmpty } from 'lodash-es';
import { useCreateWorkspace } from '../../api/hooks/workspace/useCreateWorkspace';
import { useWorkspaceImage } from '../../api/hooks/workspace/useWorkspaceImage';
import { ImageUpload } from '../ImageUpload/ImageUpload';
import { AuthLayoutBase } from './AuthLayouts/AuthLayoutBase';
import { anonymousId } from './AuthStepEmailComponent.constants';

const useStyles = createStyles((theme) => ({
	page: {
		display: 'flex',
		justifyContent: 'center',
		backgroundColor: theme.other.getColor('surface/secondary/default'),
		height: '100vh',
		alignItems: 'center',
	},
	card: {
		width: 500,
		marginTop: 50,
	},
}));

const NAME_REGEX = /^[a-zA-Z0-9].*$/;

interface WorkspaceFormType {
	workspaceName: string;
	workspaceLogo: File | null;
}

interface AuthStepWorkspaceCreateProps {
	toggleTab: (tab: 'join' | 'create') => void;
}

export function AuthStepWorkspaceCreate({
	toggleTab,
}: AuthStepWorkspaceCreateProps) {
	const { classes, theme } = useStyles();
	const { mutateAsync } = useCreateWorkspace();
	const { mutateAsync: uploadImage } = useWorkspaceImage();

	const form = useForm<WorkspaceFormType>({
		initialValues: {
			workspaceName: '',
			workspaceLogo: null,
		},

		validate: {
			workspaceName: (value: string) =>
				!isEmpty(value) && value.match(NAME_REGEX) ? null : 'Invalid name',
			// Always return null for workspaceLogo, as it's optional.
			workspaceLogo: () => null,
		},
	});

	const handleSubmit = async (values: WorkspaceFormType) => {
		const workspace = await mutateAsync({
			data: {
				name: values.workspaceName,
				anonymous_user_id: anonymousId(),
			},
		});
		if (values.workspaceLogo) {
			await uploadImage({
				workspaceId: workspace.id,
				file: values.workspaceLogo,
			});
		}

		window.open('/', '_self');
	};

	const handleFile = (file: File) => {
		form.setFieldValue('workspaceLogo', file);
	};

	return (
		<Box className={classes.page}>
			<AuthLayoutBase
				title="Create a workspace"
				onClickBackButton={() => toggleTab('join')}
				backButtonTooltipText="Join an existing workspace instead"
			>
				<Flex direction="column" gap="2xl">
					<form
						autoComplete="off"
						style={{
							display: 'flex',
							flexDirection: 'column',
							gap: theme.spacing.md,
							marginTop: theme.spacing['2xl'],
						}}
						onSubmit={form.onSubmit(handleSubmit)}
					>
						<Group position="left">
							<ImageUpload
								callback={handleFile}
								label="Company or Organization logo"
								placeholder="/images/auth-image/workspace-placeholder.webp"
							/>
						</Group>
						<TextInput
							label="Workspace name"
							styles={{ input: { borderRadius: theme.other.space[2] } }}
							{...form.getInputProps('workspaceName')}
						/>
						<Group position="center">
							<Button disabled={!form.isValid} type="submit" variant="primary">
								Create workspace
							</Button>
						</Group>
					</form>
				</Flex>
			</AuthLayoutBase>
		</Box>
	);
}
