import { Accordion, Group } from '@mantine/core';
import {
	memo,
	useCallback,
	type PropsWithChildren,
	type ReactNode,
} from 'react';
import { useAuthUser } from '../../../api';
import { trackEvent } from '../../../utils/analytics';

interface DataQualityBreakdownItemBodyProps {
	value: string;
	title: ReactNode;
	badge: ReactNode;
	disabled?: boolean;
}

export const DataQualityBreakdownItemBody = memo(
	({
		value,
		title,
		badge,
		children,
		disabled,
	}: PropsWithChildren<DataQualityBreakdownItemBodyProps>) => {
		const { workspace, user } = useAuthUser();

		const handleOnClick = useCallback(() => {
			trackEvent(
				'dqs/suggestions/expand',
				{
					key: value,
					path: window.location.pathname.split('/')[1],
				},
				user,
				workspace
			);
		}, [value, user, workspace]);

		return (
			<Accordion.Item value={value} onClick={handleOnClick}>
				<Accordion.Control disabled={disabled}>
					<Group position="apart" spacing={0}>
						<Group spacing="xs">{title}</Group>
						{badge}
					</Group>
				</Accordion.Control>
				<Accordion.Panel>{children}</Accordion.Panel>
			</Accordion.Item>
		);
	}
);
DataQualityBreakdownItemBody.displayName = 'DataQualityBreakdownItemBody';
