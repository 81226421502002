import { IconButton } from '@repo/foundations';
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { useLocation } from 'react-router';
import { useAuthUser } from '../../../api';
import { useAiEnabled } from '../../../hooks/useAIEnabled';
import { trackEvent } from '../../../utils/analytics';
import { RightSidebarMode, rightSidebarStore } from '../../RightSidebar';
export const AIAssistantSidebarToggle = observer(() => {
	const { user, workspace } = useAuthUser();
	const { enableAi } = useAiEnabled();

	const location = useLocation();
	const { collapsed, mode, setCollapsed, setMode } = rightSidebarStore;

	const handleToggle = useCallback(() => {
		if (collapsed) {
			trackEvent(
				'ai/sidebar/open',
				{ url: location.pathname },
				user,
				workspace
			);
			setCollapsed(false);
		} else if (mode === RightSidebarMode.AI) {
			// If the mode is already set to this we instead want to close sidebar.
			setCollapsed(true);
		}
		setMode('ai');
	}, [collapsed, mode]);

	if (!enableAi) {
		return null;
	}

	const isOpen = !collapsed && mode === RightSidebarMode.AI;

	return (
		<IconButton
			tooltip={`${isOpen ? 'Hide' : 'Show'} Secoda AI`}
			onClick={handleToggle}
			highlight={isOpen}
			iconName={isOpen ? 'sparklesFilled' : 'sparkles'}
			variant="tertiary"
		/>
	);
});
