import { createStyles, Image, UnstyledButton } from '@mantine/core';
import { noop } from 'lodash-es';
import { isColor } from '../../../utils/stringUtils';

interface IColorPaletteChipProps {
	alt?: string;
	backgroundImage: string;
	thumbnail?: string;
	onClick?: (backgroundImage: string) => void;
}

const useStyles = createStyles({
	colorButton: {
		width: 128,
		height: 64,
		borderRadius: 6,
	},
});

function ColorPaletteChip({
	backgroundImage,
	thumbnail,
	alt = 'Cover image',
	onClick = noop,
}: IColorPaletteChipProps) {
	const { classes } = useStyles();

	const handleClick = () => {
		onClick(backgroundImage);
	};

	if (isColor(backgroundImage)) {
		return (
			<UnstyledButton
				className={classes.colorButton}
				bg={backgroundImage}
				onClick={handleClick}
			/>
		);
	}

	return (
		<UnstyledButton onClick={handleClick}>
			<Image
				radius="xs"
				fit="cover"
				width={128}
				height={64}
				src={thumbnail || backgroundImage}
				alt={alt}
			/>
		</UnstyledButton>
	);
}

export default ColorPaletteChip;
