import { Divider, Group, Stack, createStyles } from '@mantine/core';
import { Button, Switch } from '@repo/foundations';
import { observer } from 'mobx-react-lite';
import { useMemo, useState } from 'react';
import type { Automation } from '../../../../../api';
import { useAuthUser } from '../../../../../api';
import { trackEvent } from '../../../../../utils/analytics';

import { useAutomationStore } from '../../../../../pages/AutomationPage/context';
import AutomationActionCardEditProperty from './AutomationActionCardEditProperty';
import { useAutomationActionCardEditStore } from './context';

interface AutomationActionCardEditContentProps {
	handleAutomationUpdate: (automation: Partial<Automation>) => Promise<void>;
}

const useStyles = createStyles(() => ({
	switchGroup: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	button: {
		width: 'fit-content',
	},
}));

function AutomationActionCardEditContent({
	handleAutomationUpdate,
}: AutomationActionCardEditContentProps) {
	const { classes } = useStyles();
	const { user, workspace } = useAuthUser();
	const store = useAutomationActionCardEditStore();
	const { automation } = useAutomationStore();

	const [checked, setChecked] = useState<boolean>(
		automation?.should_overwrite_metadata ?? false
	);
	const handleOnCheckboxChange = async (newChecked: boolean) => {
		setChecked(newChecked);

		await handleAutomationUpdate({
			should_overwrite_metadata: newChecked,
		});
	};

	const handleAddPropertyOnClick = () => {
		store.addAction({});
		trackEvent('automation/add_action_button/click', {}, user, workspace);
	};

	const actions = useMemo(() => {
		store.resetAction();

		automation?.action_update_entities?.forEach((action) => {
			store.addAction({
				searchKey: action.key,
				type: action.type,
				value: action.value,
				display: action.display,
			});
		});

		return store.actions;
	}, [automation?.action_update_entities, store]);

	return (
		<Stack>
			{actions.map((action, i) => (
				<AutomationActionCardEditProperty
					key={action.searchKey}
					searchKey={action.searchKey}
					type={action.type}
					display={action.display}
					value={action.value}
					isLastElement={i === store.actions.length - 1}
					handleAutomationUpdate={handleAutomationUpdate}
				/>
			))}
			<Button
				onClick={handleAddPropertyOnClick}
				leftIconName="plus"
				className={classes.button}
			>
				Add property
			</Button>
			<Divider />
			<Group className={classes.switchGroup}>
				Override existing properties
				<Switch
					checked={checked}
					onChange={(event) =>
						handleOnCheckboxChange(event.currentTarget.checked)
					}
				/>
			</Group>
		</Stack>
	);
}

export default observer(AutomationActionCardEditContent);
