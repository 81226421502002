import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
	wrapper: {
		display: 'flex',
		flexGrow: 1,
		maxHeight: '94vh',
		padding: theme.spacing['2xl'],
		paddingTop: 0,
		paddingBottom: theme.spacing.md,
	},
	tableWrapper: {
		padding: theme.spacing.none,
	},
	tabsList: {
		marginBottom: theme.spacing.md,
	},
	emptySpace: {
		width: theme.other.width.md,
	},
	borderBottom: {
		borderBottom: '1px solid black',
	},
}));
