import { showNotification } from '@mantine/notifications';
import { UserRole } from '@repo/common/enums/UserRole';
import type { QueryKey } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import { size } from 'lodash-es';
import { createMockableHook } from '../../../utils/createMockableHook';
import { apiClient, getEndpoints } from '../../common';
import queryClient from '../../queryClient';
import { useAuthUser } from '../authUser';
import { WORKSPACE_NAMESPACE } from './constants';
import { useLimits } from './useLimits';

export function useWorkspaceInternal() {
	const { workspace } = useAuthUser();

	return {
		workspace,
	};
}

export const [useWorkspace, MockUseWorkspace] =
	createMockableHook(useWorkspaceInternal);

interface RequestData {
	emails: string[];
	role: UserRole;
	groups: string[];
	teams: string[];
}

export function useInviteUsers(invalidationKey: QueryKey) {
	const { workspace } = useWorkspace();
	const {
		editorOrAdminSeats,
		viewerSeats,
		usedEditorOrAdminSeats,
		usedViewerSeats,
	} = useLimits();

	const mutationFn = async ({ emails, role, groups, teams }: RequestData) => {
		if (
			(role === UserRole.EDITOR || role === UserRole.ADMIN) &&
			usedEditorOrAdminSeats + size(emails) > editorOrAdminSeats
		) {
			showNotification({
				title: 'Error',
				message:
					'You have reached the maximum number of editors for your plan.',
				color: 'red',
			});
			return 'You have reached the maximum number of editors for your plan.';
		}
		if (
			(role === UserRole.VIEWER || role === UserRole.GUEST) &&
			usedViewerSeats + size(emails) > viewerSeats
		) {
			showNotification({
				title: 'Error',
				message:
					'You have reached the maximum number of viewers for your plan.',
				color: 'red',
			});
			return 'You have reached the maximum number of viewers for your plan.';
		}

		const url = getEndpoints(WORKSPACE_NAMESPACE).byPath([
			workspace.id,
			'invite',
		]);
		const { data: responseData } = await apiClient.post<string>(url, {
			emails,
			role,
			groups,
			teams,
		});
		return responseData;
	};

	return useMutation<unknown, unknown, RequestData, unknown>({
		mutationFn,
		onSuccess() {
			queryClient.invalidateQueries(invalidationKey);
			showNotification({
				title: 'Invitations sent',
				message: 'Invitations were sent successfully',
			});
		},
		onError(error: unknown) {
			showNotification({
				autoClose: false,
				title: 'Error',
				message: (error as { response: { data: { message: string } } }).response
					.data.message,
				color: 'red',
			});
		},
	});
}
