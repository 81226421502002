import { Box, Center } from '@mantine/core';
import { Text } from '@repo/foundations';

interface SettingsTableEmptyProps {
	title: string;
	description: string;
	height?: number;
}

export function SettingsTableEmpty({
	title,
	description,
	height = 300,
}: SettingsTableEmptyProps) {
	return (
		<Center h={height}>
			<Box w="100%" ta="center">
				<Text color="text/primary/default" weight="bold">
					{title}
				</Text>
				<Box mt={1}>
					<Text size="sm" color="text/secondary/default">
						{description}
					</Text>
				</Box>
			</Box>
		</Center>
	);
}
