import { createStyles, Group, Stack } from '@mantine/core';
import { Button, Icon, Text, Title } from '@repo/foundations';
import { map, size, startCase, times } from 'lodash-es';
import type {
	PLAN_FEATURE_CATEGORIES,
	PLAN_FEATURE_ITEM,
	PLANS,
} from './constants';
import { getNumOfFeatureItems } from './utils';

interface IPlansCard {
	title: (typeof PLANS)[number];
	color: string;
	bgColor: string;
	description: string;
	ctaButtonLabel: string;
	ctaOnClick: () => void;
	features: {
		[key: (typeof PLAN_FEATURE_CATEGORIES)[number]]: PLAN_FEATURE_ITEM[];
	};
	ctaButtonFilled?: boolean;
	disabled?: boolean;
}

const useStyles = createStyles(
	(theme, { color, bgColor }: { color: string; bgColor: string }) => ({
		wrapper: {
			borderRadius: theme.radius.lg,
			borderWidth: 1,
			borderStyle: 'solid',
			borderColor: theme.other.getColor('border/secondary/default'),
			boxShadow: theme.shadows.sm,
			background: bgColor,
		},
		title: {
			color: theme.other.getColor(color),
			fontWeight: 700,
		},
		item: {
			color: theme.other.getColor(color),
		},
	})
);

function PlansCard({
	title,
	color,
	bgColor,
	description,
	ctaButtonLabel,
	ctaOnClick,
	features,
	ctaButtonFilled,
	disabled = false,
}: IPlansCard) {
	const { classes, theme } = useStyles({ color, bgColor });
	const numOfFeatureCategoryItems = getNumOfFeatureItems();

	return (
		<Stack className={classes.wrapper} spacing="xl" p="xl">
			<Stack spacing="sm">
				<Stack spacing="3xs">
					<Title className={classes.title} order={1}>
						{startCase(title)}
					</Title>
					<Text size="sm" className={classes.item}>
						{description}
					</Text>
				</Stack>
				<Button
					variant={ctaButtonFilled ? 'primary' : 'default'}
					onClick={ctaOnClick}
					disabled={disabled}
				>
					{ctaButtonLabel}
				</Button>
			</Stack>
			{map(features, (items, category) => (
				<Stack key={category} spacing="sm">
					<Text size="sm" weight="bold" className={classes.item}>
						{startCase(category)}
					</Text>
					{map(items, (item) => (
						<Group spacing="xs" noWrap>
							<item.icon
								size={theme.other.iconSize.md}
								className={classes.item}
							/>
							<Text size="sm" lineClamp={1} className={classes.item}>
								{item.label}
							</Text>
						</Group>
					))}
					{times(numOfFeatureCategoryItems[category] - size(items), (index) => (
						<Group spacing="xs" key={`iconLineDashed-${index}`}>
							<Icon name="lineDashed" />
						</Group>
					))}
				</Stack>
			))}
		</Stack>
	);
}

export default PlansCard;
