import {
	Box,
	Group,
	Loader,
	Menu,
	ScrollArea,
	Stack,
	TextInput,
	ThemeIcon,
} from '@mantine/core';
import { useDebouncedState, useInputState } from '@mantine/hooks';
import { Icon, Text } from '@repo/foundations';
import { isEmpty, map, slice } from 'lodash-es';
import { useMemo } from 'react';
import { useDictionaryTermList } from '../../../../../../../api';
import { useHomepageTeam } from '../../../../../hooks/useHomepageTeam';

export interface IDictionaryTermSelectorDropdownProps {
	onDictionaryTermChange: (value: [string, string]) => void;
}

function DictionaryTermSelectorDropdown({
	onDictionaryTermChange,
}: IDictionaryTermSelectorDropdownProps) {
	const { isTeamsPage, team } = useHomepageTeam();
	const [searchTerm, setSearchTerm] = useInputState('');
	const [debouncedSearchTerm] = useDebouncedState(searchTerm, 500);

	const filters = useMemo(() => {
		const filtersToUse: Record<string, string> = {};

		if (isTeamsPage) {
			filtersToUse.teams = team.id;
		}

		if (debouncedSearchTerm) {
			filtersToUse.title__trigram_word_similar = debouncedSearchTerm;
		}

		return filtersToUse;
	}, [debouncedSearchTerm, isTeamsPage, team?.id]);

	const { isLoading, data } = useDictionaryTermList({
		filters: {
			...(filters as Record<string, string>),
			results_available: true,
		},
		options: {
			select: ({ results }) =>
				map(results, ({ title, id }) => ({
					label: title || 'Untitled',
					value: id,
				})),
			suspense: false,
			keepPreviousData: true,
			refetchOnMount: 'always',
		},
	});

	const recommendedTerms = slice(data, 0, 3);
	const otherTerms = slice(data, 3);

	const handleClick = (id: string, title: string) => () => {
		onDictionaryTermChange([id, title]);
	};

	const getMenuItem = ({ label, value }: { label: string; value: string }) => (
		<Menu.Item key={value} onClick={handleClick(value, label)}>
			<Group spacing={8}>
				<ThemeIcon size={32} radius={24}>
					<Icon name="book" />
				</ThemeIcon>
				<Text lineClamp={1}>{label}</Text>
			</Group>
		</Menu.Item>
	);

	return (
		<Menu.Dropdown>
			<Stack spacing={0}>
				<TextInput
					p="sm"
					pb={0}
					placeholder="Search dictionary terms..."
					value={searchTerm}
					onChange={setSearchTerm}
					rightSection={isLoading && <Loader size={12} />}
				/>
				<Box>
					<Menu.Label>Recommended</Menu.Label>
					{map(recommendedTerms, getMenuItem)}
					{!isEmpty(otherTerms) && (
						<>
							<Menu.Divider />
							<Menu.Label>Other dictionary terms</Menu.Label>
							{/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
							{/* @ts-ignore poorly typed component by Mantine */}
							<ScrollArea.Autosize mah={200}>
								{map(otherTerms, getMenuItem)}
							</ScrollArea.Autosize>
						</>
					)}
				</Box>
			</Stack>
		</Menu.Dropdown>
	);
}

export default DictionaryTermSelectorDropdown;
