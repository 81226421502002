import { Grid } from '@mantine/core';
import {
	useApiGetQueryCharts,
	useApiGetQueryMetadata,
	useApiUpdateQueryDescription,
} from '@repo/api-codegen';
import { useDebounceFn } from 'ahooks';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useAuthUser, useWorkspace } from '../../api';
import {
	PageLayoutContent,
	PageLayoutOuterWrapper,
} from '../../components/PageLayout';
import { trackEvent } from '../../utils/analytics';
import { useParamsIdSuffixUuid } from '../../utils/hook/utils';

import QueryGraph from './components/QueryGraph';
import QueryNav from './components/QueryNav';
import QueryTabs from './components/QueryTabs';
import SQLQuery from './components/SQLQuery';

export interface IQueryPageProps {
	id?: string;
}

function QueryPage({ id: propsId }: IQueryPageProps) {
	/*
	 * API related code
	 */

	const paramsId = useParamsIdSuffixUuid();
	const id = propsId || paramsId;
	const [lookBackDays, setLookBackDays] = React.useState(30);

	const { data: metadata } = useApiGetQueryMetadata({
		pathParams: {
			queryId: id,
		},
		queryParams: {
			lookback_days: lookBackDays,
		},
	});

	const { data: charts } = useApiGetQueryCharts({
		pathParams: {
			queryId: id,
		},
		queryParams: {
			lookback_days: lookBackDays,
		},
	});

	const { mutate: updateQueryDescription } = useApiUpdateQueryDescription();
	const [queryDescription, setQueryDescription] = React.useState(
		metadata?.description
	);

	const { run: handleUpdateQueryDescriptionDebounced } = useDebounceFn(
		() => {
			updateQueryDescription({
				pathParams: {
					queryId: id,
				},
				queryParams: {},
				body: {
					description: queryDescription || '',
				},
			});
		},
		{ wait: 500 }
	);

	const handleUpdateQueryDescription = (description: string) => {
		setQueryDescription(description);
		handleUpdateQueryDescriptionDebounced();
	};

	useEffect(() => {
		setQueryDescription(metadata?.description || '');
	}, [metadata?.title, metadata?.description]);

	/*
	 * Analytics related code
	 */

	const { workspace } = useWorkspace();
	const { user } = useAuthUser();

	useEffect(() => {
		trackEvent('query/view', {}, user, workspace);
	}, [user, workspace]);

	return (
		<PageLayoutOuterWrapper>
			<Helmet>
				<title>{`${metadata?.dialect} Query`}</title>
			</Helmet>

			<QueryNav
				entityId={metadata?.destination_resource?.id || ''}
				entityTitle={metadata?.destination_resource?.title || ''}
				entityType={metadata?.destination_resource?.entity_type || ''}
				lookBackDays={lookBackDays}
				setLookBackDays={setLookBackDays}
				queryDescription={queryDescription}
				onUpdateQueryDescription={handleUpdateQueryDescription}
				displayLookback={
					(!!metadata?.total_runs && metadata?.total_runs > 0) ||
					lookBackDays !== 30
				}
			/>

			<PageLayoutContent>
				{!!charts?.length && charts.length > 0 && (
					<Grid>
						<Grid.Col span={4}>
							<QueryGraph
								data={charts}
								field={'total_runs'}
								title={'Total Runs'}
								metric={metadata?.total_runs}
							/>
						</Grid.Col>
						<Grid.Col span={4}>
							<QueryGraph
								data={charts}
								field={'total_runtime'}
								title={'Total Runtime'}
								metric={metadata?.total_runtime}
								units={'ms'}
							/>
						</Grid.Col>
						<Grid.Col span={4}>
							<QueryGraph
								data={charts}
								field={'average_runtime'}
								title={'Average Runtime'}
								metric={metadata?.average_runtime}
								units={'ms'}
							/>
						</Grid.Col>
					</Grid>
				)}
				<SQLQuery query={metadata?.sql} />
				<QueryTabs
					queryMetadata={metadata}
					lookBackDays={lookBackDays}
					queryId={id}
				/>
			</PageLayoutContent>
		</PageLayoutOuterWrapper>
	);
}

export default QueryPage;
