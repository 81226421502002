import { Breadcrumbs } from '@repo/foundations';
import {
	NavBarBreadcrumbWrapper,
	NavBarWrapper,
} from '../../components/SecodaAppShell/SecodaNavBar';

function MarketplaceIntegrationNavbar() {
	return (
		<NavBarWrapper>
			<NavBarBreadcrumbWrapper>
				<Breadcrumbs
					crumbs={[
						{ title: 'Integrations', href: '/integrations' },
						{
							title: 'Integration marketplace',
							href: '/integrations/browse?tab=create',
						},
					]}
				/>
			</NavBarBreadcrumbWrapper>
		</NavBarWrapper>
	);
}

export default MarketplaceIntegrationNavbar;
