import { createStyles } from '@mantine/core';
import type { ColorNames } from '@repo/theme/utils';
import { clamp } from 'lodash-es';
import { useAnimatedValue } from './useAnimatedValue';

/**

returns a string path that represent an arc that we can use as the progress indicator

cx,cy → center of ellipse.
rx,ry → major minor radius.
t1 → start angle, in radian.
delta → angle to sweep, in radian. positive.
phi → rotation on the whole, in radian.

Based off of: http://xahlee.info/js/svg_circle_arc.html
**/
function svgEllipseArc(
	[cx, cy]: [number, number],
	[rx, ry]: [number, number],
	[t1, delta]: [number, number],
	phi: number
) {
	const matrixTimes = (
		[[a, b], [c, d]]: [[number, number], [number, number]],
		[x, y]: [number, number]
	): [number, number] => [a * x + b * y, c * x + d * y];
	const rotateMatrix = (x: number): [[number, number], [number, number]] => {
		const cosx = Math.cos(x);
		const sinx = Math.sin(x);
		return [
			[cosx, -sinx],
			[sinx, cosx],
		];
	};
	const vecAdd = ([a1, a2]: [number, number], [b1, b2]: [number, number]) => [
		a1 + b1,
		a2 + b2,
	];
	const deltaPrime = delta % (2 * Math.PI);

	const rotMatrix = rotateMatrix(phi);
	const [sX, sY] = vecAdd(
		matrixTimes(rotMatrix, [rx * Math.cos(t1), ry * Math.sin(t1)]),
		[cx, cy]
	);

	if (deltaPrime === 0) {
		return ['M', sX, sY].join(' ');
	}

	const [eX, eY] = vecAdd(
		matrixTimes(rotMatrix, [
			rx * Math.cos(t1 + deltaPrime),
			ry * Math.sin(t1 + deltaPrime),
		]),
		[cx, cy]
	);
	const fA = deltaPrime > Math.PI ? 1 : 0;
	const fS = deltaPrime > 0 ? 1 : 0;

	return ['M', sX, sY, 'A', rx, ry, (phi / Math.PI) * 180, fA, fS, eX, eY].join(
		' '
	);
}

interface BaseProgressIndicatorProps {
	/**
	 * Will be used as width/height/radius of the element
	 */
	size: number;
	/**
	 * Number between 0 and 100
	 */
	value: number;
	/**
	 * Color of the background line. Defaults to border/inverse/default
	 */
	backgroundLineColor?: ColorNames;
	/**
	 * Color of the progress line. Defaults to fill/inverse/default
	 */
	lineColor?: ColorNames;
	/**
	 * Color of the progress line when hovered.
	 */
	hoverLineColor?: ColorNames;
	/**
	 * Border width. Defaults to 2px
	 */
	borderWidth?: number;
	/**
	 * Whether to animate the progress indicator. Defaults to true
	 */
	animate?: boolean;
}

const useStyles = createStyles(
	(
		theme,
		{
			lineColor,
			hoverLineColor,
		}: Required<
			Pick<BaseProgressIndicatorProps, 'lineColor' | 'hoverLineColor'>
		>
	) => ({
		path: {
			transition: 'stroke 0.1s linear',
			stroke: theme.other.getColor(lineColor),

			':hover': {
				stroke: theme.other.getColor(hoverLineColor),
			},
		},
	})
);

const IS_SSR = typeof window === 'undefined';

export function BaseProgressIndicator({
	size,
	value,
	backgroundLineColor = 'border/inverse/default',
	lineColor = 'fill/inverse/default',
	hoverLineColor = lineColor,
	borderWidth = 2,
	animate = true,
}: BaseProgressIndicatorProps) {
	const { classes, theme } = useStyles({ lineColor, hoverLineColor });
	const { value: animatedValue } = useAnimatedValue({
		value,
		animate: !IS_SSR && animate,
		duration: 1000,
	});

	const centerPos = size / 2;
	const radius = (size - borderWidth) / 2;

	const clampedValue = clamp(animatedValue, 0, 100);

	// convert value (percentage) to radians
	// 100% = 2 radians = 360deg = full circle
	const scaledValue = clampedValue / 50;

	const backgroundPath = svgEllipseArc(
		[centerPos, centerPos],
		[radius, radius],
		[0, 2 * Math.PI - 0.01],
		1.5 * Math.PI
	);

	const progressPath = svgEllipseArc(
		[centerPos, centerPos],
		[radius, radius],
		[0, scaledValue > 0 ? scaledValue * Math.PI - 0.01 : 0],
		1.5 * Math.PI
	);

	return (
		<svg
			width={size}
			height={size}
			viewBox={`0 0 ${size} ${size}`}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			display="block"
		>
			<path
				d={backgroundPath}
				stroke={theme.other.getColor(backgroundLineColor)}
				strokeWidth={borderWidth}
				strokeLinecap="round"
				strokeLinejoin="bevel"
			/>
			<path
				className={classes.path}
				d={progressPath}
				stroke={theme.other.getColor(lineColor)}
				strokeWidth={borderWidth}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
