import { Flex, Group, Menu, UnstyledButton, createStyles } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import { useSetDefaultSearchView } from '@repo/api-codegen';
import { FilterTargetSimple } from '@repo/common/components/Filter/FilterTarget/FilterTargetSimple';
import type { FilterView } from '@repo/common/components/Filter/types';
import { Icon, IconButton, Text } from '@repo/foundations';
import { includes } from 'lodash-es';
import { useCallback, type ReactNode } from 'react';
import {
	invalidateSearchViews,
	queryClient,
	searchViewQueryKeyFactory,
	useAuthUser,
	useCreateSearchView,
	useDeleteSearchView,
} from '../../../api';
import { useCurrentTeam } from '../../../api/hooks/team/myMemberships';
import { trackEvent } from '../../../utils/analytics';
import { closeAllModals, openConfirmModal } from '../../ModalManager';
import { useFilterViews } from './useFilterViews';

const useStyles = createStyles((theme) => ({
	menuDropdown: {
		paddingTop: theme.spacing['2xs'],
		paddingBottom: theme.spacing['2xs'],
		paddingLeft: 0,
		paddingRight: 0,
	},
	menuDivider: {
		margin: `${theme.spacing['2xs']} 0`,
	},
	menuItem: {
		margin: `0 ${theme.spacing['2xs']}`,
		width: `calc(100% - (2 * ${theme.spacing['2xs']}))`,
		padding: theme.spacing['2xs'],
	},
	menuLabel: {
		paddingLeft: theme.spacing.sm,
		fontWeight: 600,
	},
	submenuDropdown: {
		width: theme.other.space[80],
		maxWidth: theme.other.space[80],
		paddingTop: theme.spacing['2xs'],
		paddingBottom: theme.spacing['2xs'],
		paddingLeft: 0,
		paddingRight: 0,
		marginLeft: 0,
	},
	menuRowButtonContainer: {
		width: '100%',
		padding: `0 ${theme.spacing['2xs']}`,
	},
	menuRowButton: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',

		width: '100%',
		height: theme.other.height.sm,
		padding: `0 ${theme.spacing['2xs']}`,
		borderRadius: theme.radius.sm,

		backgroundColor: theme.other.getColor('fill/primary/default'),
		'&:hover': {
			backgroundColor: theme.other.getColor('fill/primary/hover'),
		},
		'&:active': {
			backgroundColor: theme.other.getColor('fill/primary/selected'),
		},
	},
}));

export interface FilterViewsProps {
	initialOpened?: boolean;
	handleEdit: (view: FilterView | null) => void;
	onChange: (view: FilterView | null) => void;
	value?: FilterView | null;
	renderTarget?: (
		view: FilterView | undefined,
		isMenuOpen: boolean,
		onToggle: () => void
	) => string | ReactNode;
}

interface MenuItemProps {
	view: FilterView;
	handleClick: (view: FilterView | null) => () => void;
	handleEdit: (view: FilterView | null) => void;
	handleDelete: (view: FilterView) => void;
	handleSetDefault?: (view: FilterView) => void;
	handleDuplicate?: (view: FilterView) => void;
	classes: Record<string, string>;
	withDefaultIcon?: boolean;
	isDefault?: boolean;
}

function MenuItem({
	view,
	handleClick,
	handleEdit,
	handleDelete,
	handleSetDefault,
	handleDuplicate,
	isDefault,
	withDefaultIcon,
	classes,
}: MenuItemProps) {
	const [opened, { toggle }] = useDisclosure(false);

	if (view.isOwner) {
		return (
			<Menu
				onChange={toggle}
				opened={opened}
				key={view.value}
				classNames={{
					dropdown: classes.submenuDropdown,
					divider: classes.menuDivider,
					label: classes.menuLabel,
					item: classes.menuItem,
				}}
				offset={{ mainAxis: 2 }}
				position="right-start"
				shadow="md"
				transitionProps={{ transition: 'fade' }}
			>
				<Menu.Target>
					<Flex className={classes.menuRowButtonContainer}>
						<UnstyledButton
							onClick={handleClick(view)}
							className={classes.menuRowButton}
						>
							<Text size="sm">{view.label}</Text>
							<Group spacing={0} align="center">
								{view.teams && withDefaultIcon && (
									<Icon name="flag" color="icon/secondary/default" />
								)}
								<IconButton
									onClick={(e) => {
										e.stopPropagation();
										toggle();
									}}
									iconName="dots"
									variant="tertiary"
								/>
							</Group>
						</UnstyledButton>
					</Flex>
				</Menu.Target>
				<Menu.Dropdown>
					{handleSetDefault && (
						<Menu.Item
							onClick={() => handleSetDefault(view)}
							icon={<Icon name="flag" />}
						>
							{isDefault ? 'Remove as default' : 'Set as default'}
						</Menu.Item>
					)}
					<Menu.Item
						onClick={() => handleEdit(view)}
						icon={<Icon name="edit" />}
					>
						Edit name and visibility
					</Menu.Item>
					{handleDuplicate && (
						<Menu.Item
							onClick={() => handleDuplicate(view)}
							icon={<Icon name="copy" />}
						>
							Duplicate
						</Menu.Item>
					)}
					<Menu.Item
						onClick={() => handleDelete(view)}
						icon={<Icon color="text/critical/default" name="trash" />}
					>
						<Text size="sm" color="text/critical/default">
							Delete
						</Text>
					</Menu.Item>
				</Menu.Dropdown>
			</Menu>
		);
	}

	return (
		<Menu.Item key={view.value} onClick={handleClick(view)}>
			{view.label}
		</Menu.Item>
	);
}

export function FilterViews({
	initialOpened,
	onChange,
	value,
	handleEdit,
	renderTarget,
}: FilterViewsProps) {
	const { classes } = useStyles();
	const [opened, { toggle, close }] = useDisclosure(initialOpened);
	const { views } = useFilterViews();

	const { currentTeam } = useCurrentTeam();
	const currentTeamId = currentTeam?.id;

	const userViews = views.filter(
		(v) => v.isOwner && !includes(v.teams, currentTeamId)
	);
	const teamViews = views.filter((v) => includes(v.teams, currentTeamId));

	const { mutateAsync: setDefaultView } = useSetDefaultSearchView({
		onSuccess: () => {
			queryClient.invalidateQueries(searchViewQueryKeyFactory.allLists());
			showNotification({
				title: 'Success',
				message: 'Default view set',
				color: 'green',
			});
		},
		onError: () => {
			showNotification({
				title: 'Error',
				message: 'Failed to set default view',
				color: 'red',
			});
		},
	});

	const { mutateAsync: deleteView } = useDeleteSearchView({
		options: {
			onSuccess: () => {
				queryClient.invalidateQueries(searchViewQueryKeyFactory.allLists());
				showNotification({
					title: 'Success',
					message: 'View deleted',
					color: 'green',
				});
			},
			onError: () => {
				showNotification({
					title: 'Error',
					message: 'Failed to delete view',
					color: 'red',
				});
			},
		},
	});

	const handleDelete = useCallback(
		(view: FilterView) => {
			openConfirmModal({
				title: 'Delete View',
				labels: {
					confirm: 'Delete',
					cancel: 'Cancel',
				},
				cancelProps: {
					size: 'md',
				},
				confirmProps: {
					size: 'md',
					variant: 'primary',
					tone: 'critical',
				},
				onConfirm: async () => {
					deleteView({ id: view.value });
					closeAllModals();
				},
				children: <Text>Are you sure you want to delete this view?</Text>,
			});
		},
		[deleteView]
	);

	const handleClick = useCallback(
		(view: FilterView | null) => () => {
			onChange(view);
			close();
		},
		[close, onChange]
	);

	const selectedView = views.find((v) => v.value === value?.value);

	const handleSetDefault = useCallback(
		(view: FilterView) => {
			setDefaultView({
				pathParams: { viewId: view.value },
				queryParams: { team_id: currentTeamId ?? '' },
			});
		},
		[currentTeamId, setDefaultView]
	);

	const { user, workspace } = useAuthUser();

	const { mutateAsync: createSearchView } = useCreateSearchView({
		options: {
			onSuccess: () => {
				invalidateSearchViews(1);
				trackEvent('search/views/duplicate', {}, user, workspace);
			},
		},
	});

	const handleDuplicate = useCallback(
		async (view: FilterView) => {
			await createSearchView({
				data: {
					name: `${view.name} (copy)`,
					teams: view.teams,
					selected_filters: view.filters,
					selected_sort_by: view.selected_sort_by,
					selected_sort_direction: view.selected_sort_direction,
					owner_id: user.id,
					default_for_users: [],
					is_default_for_teams: view.is_default_for_teams,
				},
			});
		},
		[createSearchView, user.id]
	);

	return (
		<Menu
			opened={opened}
			closeOnClickOutside
			closeOnEscape
			closeOnItemClick
			onClose={close}
			classNames={{
				dropdown: classes.menuDropdown,
				divider: classes.menuDivider,
				label: classes.menuLabel,
				item: classes.menuItem,
			}}
		>
			<Menu.Target>
				{renderTarget ? (
					renderTarget(selectedView, opened, toggle)
				) : (
					<FilterTargetSimple
						label={selectedView?.label ?? 'Views'}
						onToggle={toggle}
						isMenuOpen={opened}
						hasValue
					/>
				)}
			</Menu.Target>
			<Menu.Dropdown>
				<Menu.Item onClick={handleClick(null)}>All results</Menu.Item>
				{userViews.length > 0 && (
					<>
						<Menu.Divider />
						<Menu.Label>Personal views</Menu.Label>
						{userViews.map((view) => (
							<MenuItem
								key={view.value}
								view={view}
								handleClick={handleClick}
								handleEdit={handleEdit}
								handleDelete={handleDelete}
								withDefaultIcon={false}
								classes={classes}
							/>
						))}
					</>
				)}
				{currentTeam && teamViews.length > 0 && (
					<>
						<Menu.Divider />
						<Menu.Label>
							{currentTeam?.icon} {currentTeam?.name} views
						</Menu.Label>
						{teamViews.map((view) => (
							<MenuItem
								key={view.value}
								view={view}
								handleClick={handleClick}
								handleEdit={handleEdit}
								handleDelete={handleDelete}
								handleDuplicate={handleDuplicate}
								handleSetDefault={handleSetDefault}
								withDefaultIcon={view.is_default_for_teams?.includes(
									currentTeamId ?? ''
								)}
								isDefault={view.is_default_for_teams?.includes(
									currentTeamId ?? ''
								)}
								classes={classes}
							/>
						))}
					</>
				)}
			</Menu.Dropdown>
		</Menu>
	);
}
