import type { Attrs, NodeType } from 'prosemirror-model';
import type { EditorState } from 'prosemirror-state';
import { findParentNode, findSelectedNodeOfType } from 'prosemirror-utils';

const isNodeActive =
	(type: NodeType, attrs: Attrs = {}) =>
	(state: EditorState) => {
		if (!type) {
			return false;
		}

		const node =
			findSelectedNodeOfType(type)(state.selection) ||
			findParentNode((n) => n.type === type)(state.selection);

		if (!Object.keys(attrs).length || !node) {
			return !!node;
		}

		return node.node.hasMarkup(type, { ...node.node.attrs, ...attrs });
	};

export default isNodeActive;
