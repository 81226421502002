/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import type { SecodaEditorState } from '@repo/secoda-editor';
import type { PluginSimple } from 'markdown-it';
import type { InputRule } from 'prosemirror-inputrules';
import type { Attrs, Schema } from 'prosemirror-model';
import type { Command, EditorState, Plugin } from 'prosemirror-state';
import type { EditorView } from 'prosemirror-view';
import type { RichMarkdownEditor as Editor } from '..';
import type { Dispatch } from '../types';

export type CommandFactory = (
	attrs?: Attrs
) => (state: EditorState, dispatch: Dispatch, view: EditorView) => boolean;

export type ExtensionOptions<T> = { type?: T; schema: Schema };

export default class Extension<T = never> {
	options: any;

	// @ts-expect-error FIXME
	editor: Editor;

	constructor(options: Record<string, any> = {}) {
		this.options = {
			...this.defaultOptions,
			...options,
		};
	}

	bindEditor(editor: Editor) {
		this.editor = editor;
	}

	get editorState(): SecodaEditorState {
		return {
			id: this.editor.props.id,
			singleLineEditor: this.editor.props.singleLineEditor ?? false,
			isTemplate: this.editor.props.template ?? false,
			readOnly: this.editor.props.readOnly ?? false,
			disableExtensions: this.editor.props.disableExtensions ?? [],
			schema: this.editor.schema,
			view: this.editor.view,
			uploadFile: this.editor.props.uploadFile,
			onFileUploadStart: this.editor.props.onFileUploadStart,
			onFileUploadStop: this.editor.props.onFileUploadStop,
			onShowToast: this.editor.props.onShowToast,
			embeds: this.editor.props.embeds,
			disableResourceLinking: this.editor.props.disableResourceLinking ?? false,
		};
	}

	get type() {
		return 'extension';
	}

	get name() {
		return 'embed';
	}

	get plugins(): Plugin[] {
		return [];
	}

	get rulePlugins(): PluginSimple[] {
		return [];
	}

	get defaultOptions() {
		return {};
	}

	get focusAfterExecution(): boolean {
		return true;
	}

	keys(_options: ExtensionOptions<T>): Record<string, Command> {
		return {};
	}

	inputRules(_options: ExtensionOptions<T>): InputRule[] {
		return [];
	}

	commands(
		_options: ExtensionOptions<T>
	): Record<string, CommandFactory> | CommandFactory {
		return {};
	}
}
