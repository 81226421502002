import type { IconNames } from '@repo/foundations';

export const QUESTION_STATUS_MAP = {
	ANSWERED: 'Answered',
	IN_REVIEW: 'In Review',
	IN_PROGRESS: 'In Progress',
	UNANSWERED: 'Unanswered',
	CANCELLED: 'Cancelled',
} as const;

export const QUESTION_PRIORITY_MAP = {
	NONE: 'No priority',
	LOW: 'Low',
	MEDIUM: 'Medium',
	HIGH: 'High',
} as const;

export const QUESTION_PRIORITY_ICON_NAMES: Record<string, IconNames> = {
	[QUESTION_PRIORITY_MAP.HIGH]: 'priorityHigh',
	[QUESTION_PRIORITY_MAP.MEDIUM]: 'priorityMedium',
	[QUESTION_PRIORITY_MAP.LOW]: 'priorityLow',
	[QUESTION_PRIORITY_MAP.NONE]: 'priorityNone',
};

export type QuestionStatus = keyof typeof QUESTION_STATUS_MAP;

export type QuestionPriority = keyof typeof QUESTION_PRIORITY_MAP;
