import { Box, createStyles, Divider, Group, Stack } from '@mantine/core';
import { observer } from 'mobx-react-lite';
import { type ReactNode } from 'react';
import {
	RIGHT_SIDEBAR_TRANSITION_DURATION,
	RIGHT_SIDEBAR_TRANSITION_TIMING_FUNCTION,
	TOP_NAVBAR_HEIGHT,
} from '../SecodaAppShell/constants';
import { ResizeRightSidebar } from './ResizeRightSidebar';
import { rightSidebarStore } from './store';

const useStyles = createStyles(
	(theme, { collapsed }: { collapsed: boolean }) => ({
		asideWrapper: {
			width: `calc(${rightSidebarStore.width}px - 1px)`,
			minWidth: `calc(${rightSidebarStore.width}px - 1px)`,
			height: `calc(100vh - ${TOP_NAVBAR_HEIGHT}px)`,
			scrollbarColor: `${theme.other.getColor(
				'fill/tertiary/default'
			)} transparent`,
			scrollbarWidth: 'thin',
			overflowY: 'auto',
			scrollbarGutter: 'stable',
			transitionProperty: 'width',
			transitionTimingFunction: RIGHT_SIDEBAR_TRANSITION_TIMING_FUNCTION,
			transitionDuration: RIGHT_SIDEBAR_TRANSITION_DURATION,
		},
		wrapper: {
			height: '100%',
			width: collapsed ? 0 : rightSidebarStore.width,
			opacity: collapsed ? 0 : 1,
			transform: `translateX(${collapsed ? rightSidebarStore.width : 0}px)`,
			transitionProperty: 'width',
			transitionTimingFunction: RIGHT_SIDEBAR_TRANSITION_TIMING_FUNCTION,
			transitionDuration: RIGHT_SIDEBAR_TRANSITION_DURATION,

			'@media print': {
				display: 'none',
			},
		},
		divider: {
			position: 'absolute',
			height: '100vh',
			transitionDelay: '200ms',
		},
	})
);

export interface RightSidebarWrapperProps {
	classNames?: Record<string, string>;
	children?: ReactNode;
}

export const RightSidebarWrapper = observer(
	({ classNames, children }: RightSidebarWrapperProps) => {
		const collapsed = rightSidebarStore.collapsed || false;

		const { classes, cx } = useStyles({ collapsed });

		return (
			<Group
				className={cx(classes.wrapper, classNames?.wrapper)}
				spacing={0}
				noWrap
				data-testid="page-sidebar-wrapper"
			>
				<Divider className={classes.divider} orientation="vertical" />
				<Stack spacing={0}>
					<Box h={TOP_NAVBAR_HEIGHT} />
					<Stack
						className={cx(classes.asideWrapper, classNames?.asideWrapper)}
						spacing={0}
					>
						{children}
					</Stack>
				</Stack>
				<ResizeRightSidebar />
			</Group>
		);
	}
);
