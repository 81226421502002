import { apiClient, getEndpoints } from '../../common';
import queryClient from '../../queryClient';
import type { IApiListResponse, ISecodaEntity } from '../../types';
import { getDefaultQueryFn } from '../base/useBaseModel';
import { getDefaultUpdateFn } from '../base/useUpdateBaseModel';
import { secodaEntitiesQueryKeyFactory } from './constants';

export const fetchSecodaEntities = ({
	filters,
	page,
}: {
	filters: Record<string, string>;
	page: number;
}) =>
	queryClient.fetchQuery(
		secodaEntitiesQueryKeyFactory.list(page, filters),
		async () => {
			const url = getEndpoints(secodaEntitiesQueryKeyFactory.namespace).root();

			const params = { page, ...filters };

			const { data } = await apiClient.get<IApiListResponse<ISecodaEntity>>(
				url,
				{ params }
			);

			return data;
		}
	);

export const updateSecodaEntity = getDefaultUpdateFn<ISecodaEntity>(
	secodaEntitiesQueryKeyFactory.namespace
);

export const fetchSecodaEntity = (id: string, params: unknown = undefined) =>
	getDefaultQueryFn<ISecodaEntity>(
		id,
		secodaEntitiesQueryKeyFactory.namespace,
		params
	)({
		queryKey: secodaEntitiesQueryKeyFactory.byId(id),
		meta: { id },
	});
