import {
	IS_NOT_SET_FILTER_LABEL,
	IS_SET_FILTER_LABEL,
} from '@repo/common/components/Filter/constants';
import type {
	FilterItem,
	FilterValue,
} from '@repo/common/components/Filter/types';
import { FilterDropdownType } from '@repo/common/components/Filter/types';
import moment from 'moment';
import { FILTER_OPTIONS_CONFIG } from './constants';
import { useFilterItems } from './useFilterItems';
import { getValueAsArray } from './utils';

interface UseFilterLabelProps {
	value: FilterValue;
}

export function useFilterLabel({ value }: UseFilterLabelProps) {
	const config = FILTER_OPTIONS_CONFIG[value.filterType].filterDropdownConfig;
	const isList = config.dropdownType === FilterDropdownType.List;
	const isString = config.dropdownType === FilterDropdownType.String;
	const isDate = config.dropdownType === FilterDropdownType.Date;

	const {
		error: hasListItemsError,
		loading: isLoadingListItems,
		items: listItems,
	} = useFilterItems({
		getItems: isList ? config.getItems : undefined,
		getItemsById: isList ? config.getItemsById : undefined,
		value,
	});

	if (isDate) {
		const isRange = Array.isArray(value.value) && value.value.length === 2;

		return isRange
			? (value.value as string[]).map((v) => moment(v).format('ll')).join(' - ')
			: moment(value.value as string).format('ll');
	}

	const selectedValues: FilterItem[] = [];

	if (value.isNotSetApplied) {
		selectedValues.push(IS_NOT_SET_FILTER_LABEL);
	}

	if (value.isSetApplied) {
		selectedValues.push(IS_SET_FILTER_LABEL);
	}

	if (isList) {
		if (hasListItemsError) {
			return 'Error loading filter';
		}
		if (isLoadingListItems) {
			return 'Loading';
		}

		const values = getValueAsArray(value);

		selectedValues.push(
			...listItems.filter((item) => values.includes(item.value))
		);
	} else if (isString) {
		if (value.value) {
			selectedValues.push({
				label: value.value as string,
				value: value.value as string,
			});
		}
	}

	if (selectedValues.length === 0) {
		return null;
	}

	if (selectedValues.length > 1) {
		return `${selectedValues.length} ${config.multipleCountsSuffix ?? 'items'}`;
	}

	return selectedValues.map((item) => item.label).join(', ');
}
