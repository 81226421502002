import { createStyles, Menu, UnstyledButton } from '@mantine/core';
import { Icon } from '@repo/foundations';
import type { FilterOperator } from './types';

const useStyles = createStyles((theme) => ({
	target: {
		fontSize: theme.fontSizes.xs,
		color: theme.other.getColor('text/secondary/default'),
		backgroundColor: theme.other.getColor('fill/primary/default'),

		'&:hover, &:focus': {
			backgroundColor: theme.other.getColor('fill/primary/hover'),
		},

		'&:active': {
			backgroundColor: theme.other.getColor('fill/primary/active'),
		},
	},
}));

export interface OperatorMenuProps {
	operators: { value: FilterOperator; label: string }[];
	selected: FilterOperator;
	onChange: (operator: FilterOperator) => void;
	label: string;
	className?: string;
}

export function OperatorMenu({
	operators,
	selected,
	onChange,
	label,
	className,
}: OperatorMenuProps) {
	const { classes, cx } = useStyles();

	return (
		<Menu>
			<Menu.Target>
				<UnstyledButton
					data-testid="filter-operator-button"
					className={cx(classes.target, className)}
				>
					{label}
				</UnstyledButton>
			</Menu.Target>
			<Menu.Dropdown>
				{operators.map((operator) => (
					<Menu.Item
						data-testid={`filter-operator-${operator.label.toLowerCase().replace(/ /g, '-')}`}
						key={operator.value}
						value={operator.value}
						onClick={() => onChange(operator.value)}
						rightSection={
							selected === operator.value ? <Icon name="check" /> : null
						}
					>
						{operator.label}
					</Menu.Item>
				))}
			</Menu.Dropdown>
		</Menu>
	);
}
