import { createStyles, Drawer } from '@mantine/core';
import { Text } from '@repo/foundations';
import dayjs from 'dayjs';
import { ErrorLogs } from '../ErrorLogs/ErrorLogs';

interface IErrorDrawerProps {
	title?: string;
	errorAt: string;
	errorMessage: string;
	onClose: () => void;
	open: boolean;
}

const useStyles = createStyles((theme) => ({
	lastSeen: {
		marginTop: theme.spacing.md,
	},
}));

function ErrorDrawer({
	title = 'Query run error',
	errorAt,
	errorMessage,
	open,
	onClose,
}: IErrorDrawerProps) {
	const { classes } = useStyles();
	return (
		<Drawer
			position="right"
			title={
				<Text size="md" weight="bold">
					{title}
				</Text>
			}
			opened={open}
			onClose={onClose}
			padding="lg"
			pt={0}
			size="xl"
		>
			<ErrorLogs
				title={`${dayjs(errorAt).format('MMMM D YYYY h:mm a')}`}
				logs={[errorMessage]}
			/>
		</Drawer>
	);
}

export default ErrorDrawer;
