import { Button } from '@repo/foundations';

export function ErrorAction({
	handleShow,
	disabled,
}: {
	handleShow: () => void;
	disabled: boolean;
}) {
	return (
		<Button
			display={disabled ? 'none' : 'block'}
			variant="tertiary"
			loading={disabled}
			onClick={handleShow}
			size="lg"
		>
			Error
		</Button>
	);
}
