/**
 * Remove white-spaces before and after a given text
 * @param text
 * @returns
 */
export function removeTrailingWhitespaces(text: string) {
	const replaceMarkdown = text.replace('^\\/g', '');

	return replaceMarkdown.trim();
}
