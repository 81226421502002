import { PARENT_DOC_ID } from '../../../components/Documentation/utils';

const DEFAULT_SIZE = 250;

/**
 * Starting from the current span, recurse up the tree until the parent is document is found.
 * Calculate the total offset of the span from the top of the document.
 * @param span - span[comment] HTML element
 */
export const getSpanTopOffset = (span: HTMLElement | null): number => {
	let el: HTMLElement | null = span;
	let top = 0;
	do {
		top += el?.offsetTop || 0;
		el = (el?.offsetParent ?? null) as HTMLElement | null;
	} while (
		// The editor has two parent divs that warrant scope of the ditor. We need to break on both
		// <div id="PARENT_DOC_ID">
		// 	<div contenteditable class="ProseMirror">
		//    <p> sth with comments here </p>
		el &&
		el.id !== PARENT_DOC_ID &&
		!el?.classList?.contains('ProseMirror')
	);
	return top;
};

/*
Assuming a list of objects with different heights and each have an optimal position. This function returns the optimal position map without overlapping.
Also accepts a focusedID that enforces that focusedID have optimal position and shift the rest accordingly.

Algorithm:
Case 1: No focusedID
	+ Assume the sorted index of focusedID is 0.
	+ Base case pos[0] = 0
	+ for i from 0 to n:
			pos[i] = max(pos[i-1] + height[i-1] + padding, pos[i])

Case 2: With focusedID. The second half is still the same as Case 1, but the first half is in reverse because we're pushing the elements up.
	+ Base case pos[focusedID] = naturalOffset[focusedID]
	+ for i from focusedID to 0:
			pos[i] = min(pos[i+1] - height[i] - padding, pos[i])
	+ for i from focusedID to n:
			pos[i] = max(pos[i-1] + height[i-1] + padding, pos[i])

NOTE: This function give default values in case the id doesn't have a height or naturalOffset. An undefined value somewhere in the loop cascades to other values.
*/
export const handleConflicts = (
	ids: string[],
	naturalOffset: { [id: string]: number },
	height: { [id: string]: number },
	padding: number,
	focusedID?: string
) => {
	const sortedIds = ids
		.filter((id) => id in naturalOffset)
		.sort((a, b) => {
			const offsetA = naturalOffset[a];
			const offsetB = naturalOffset[b];

			return offsetA - offsetB;
		});

	// Build a new map of top offsets with the new span positions
	const newOffset: { [id: string]: number } = {};

	// === Get sorted index
	const focusedIndex = focusedID ? sortedIds.indexOf(focusedID) : 0;

	// === Start from the focusedIndex. Iterate index backwards to push elements up.
	sortedIds.slice(0, focusedIndex + 1).reduceRight((prev, id) => {
		if (prev.length === 0) {
			newOffset[id] = naturalOffset[id];
			return [id];
		}

		const prevID = prev[prev.length - 1];

		// Determine the minimum offset to avoid overlap.
		// Add default values in case of missing data so they don't completely break the UI on errors.
		const prevOffset = newOffset[prevID] ?? 0;
		const currentHeight = height[id] ?? DEFAULT_SIZE;

		const minTopToAvoidConflict = prevOffset - currentHeight - padding;
		const offset = naturalOffset[id] ?? 0;

		newOffset[id] = Math.min(minTopToAvoidConflict, offset);

		return [...prev, id];
	}, [] as string[]);

	// === Start from the focusedIndex. Iterate forward to push elements down.
	sortedIds.slice(focusedIndex).reduce((prev, id) => {
		if (prev.length === 0) {
			newOffset[id] = naturalOffset[id];
			return [id];
		}

		const prevID = prev[prev.length - 1];

		// Determine the maximum offset to avoid overlap.
		// Add default values in case of missing data so they don't completely break the UI on errors.
		const prevOffset = newOffset[prevID] ?? 0;
		const prevHeight = height[prevID] ?? DEFAULT_SIZE;

		const maxTopToAvoidConflict = prevOffset + prevHeight + padding;
		const offset = naturalOffset[id] ?? 0;

		newOffset[id] = Math.max(maxTopToAvoidConflict, offset);

		return [...prev, id];
	}, [] as string[]);

	return newOffset;
};
