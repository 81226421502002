import type { Filter } from '@repo/api-codegen';
import { EntityType } from '@repo/common/enums/entityType';
import { useNavigateHandler } from '@repo/common/hooks/useNavigate';
import type { MouseEvent } from 'react';
import { useCallback } from 'react';
import { useCatalogInfiniteList } from '../../../api/hooks/resourceCatalog';
import { useEntityBreadcrumbTeam } from '../../../hooks/useEntityBreadcrumbs';
import { DataTreeLoader } from '../components/DataTreeLoader';
import { EntityTreeNode } from '../components/EntityTreeNode';
import { LoadMore } from '../components/LoadMore';
import type { CommonEntityTreeNode } from '../utils';

interface GlossaryTreeProps {
	teamId: string;
}

export function GlossaryTree({ teamId }: GlossaryTreeProps) {
	const {
		data: result,
		hasNextPage,
		fetchNextPage,
		isFetchingNextPage,
	} = useCatalogInfiniteList({
		filters: {
			teams: teamId,
			filter: {
				operator: 'and',
				operands: [
					{
						operator: 'not',
						field: 'parent_id',
						operands: [],
					},
					{
						field: 'entity_type',
						operator: 'exact',
						value: EntityType.glossary,
					},
				],
			} as Filter,
			sort: {
				field: 'title',
				order: 'asc',
			},
		},
	});

	const nodes: CommonEntityTreeNode[] | undefined = result?.pages.map(
		(term) => ({
			id: term.id,
			entity: term,
			hasChildren: Boolean(term.has_child_of_same_type),
		})
	);

	const { setTeamBreadcrumb } = useEntityBreadcrumbTeam();

	const navigateHandler = useNavigateHandler();

	const handleNavigate = useCallback(
		(event: MouseEvent, node: CommonEntityTreeNode) => {
			setTeamBreadcrumb(teamId);
			navigateHandler(event)(`/glossary/${node.id}`);
		},
		[]
	);

	if (!nodes) {
		return <DataTreeLoader />;
	}

	return (
		<>
			{nodes.map((node) => (
				<EntityTreeNode
					key={node.id}
					node={node}
					onClick={handleNavigate}
					level={0}
					getLabel={(n) => n.entity.title}
				/>
			))}
			{hasNextPage && (
				<LoadMore
					level={0}
					onClick={fetchNextPage}
					isLoading={isFetchingNextPage}
				/>
			)}
		</>
	);
}
