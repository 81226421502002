import dayjs from 'dayjs';
import { debounce } from 'lodash-es';
import { useAuthUser } from '../../api';
import type {
	CustomProperty,
	CustomPropertyKeys,
} from './CustomProperty.types';
import CustomPropertyItem from './CustomPropertyItem';

interface ICustomPropertyEditorProps {
	properties: CustomProperty[];
	readOnly: boolean;
	onAddItem: (index: number, property: CustomProperty) => void;
	onRemoveItem: (index: number) => void;
}

function CustomPropertyEditor({
	properties,
	readOnly,
	onAddItem,
	onRemoveItem,
}: ICustomPropertyEditorProps) {
	const { isViewerOrGuestUser } = useAuthUser();

	if (!properties?.length) {
		return null;
	}

	return (
		<>
			{properties.map((property, i) => {
				const handleDebounce = debounce(
					(key: CustomPropertyKeys, value: string | string[]) => {
						onAddItem(i, {
							...property,
							[key]: value,
							updated_at: dayjs().toISOString(),
						});
					},
					250
				);

				const handleChange =
					(prop: CustomPropertyKeys) => (value: string | string[]) =>
						handleDebounce(prop, value);

				const handleRemove = () => {
					onRemoveItem(i);
				};

				return (
					<CustomPropertyItem
						key={property.id}
						property={property}
						onRemove={handleRemove}
						readOnly={readOnly}
						onChange={handleChange}
						viewerUser={isViewerOrGuestUser}
					/>
				);
			})}
		</>
	);
}

export default CustomPropertyEditor;
