import { Box, Stack, Tabs } from '@mantine/core';
import { FilterOptionType } from '@repo/common/components/Filter/types.ts';
import TabsList, { type TabItem } from '@repo/common/components/TabsList';
import { debounce } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router';
import type { TimeRange } from '../../api';
import {
	useMeasurementsChartData,
	useMonitor,
	useUpdateMonitor,
} from '../../api';
import { useMonitorTarget } from '../../api/hooks/monitoring/useMonitorTarget.ts';
import { AIAssistantSidebar } from '../../components/AIAssistant/AIAssistantSidebar/AIAssistantSidebar.tsx';
import { EntityPageDescription } from '../../components/EntityPageLayout';
import EntityPageTitle from '../../components/EntityPageLayout/EntityPageTitle';
import {
	SearchFilterV2Store,
	SearchFilterV2StoreContext,
} from '../../components/Filter/store.ts';
import {
	PageLayoutContent,
	PageLayoutContentWrapper,
	PageLayoutOuterWrapper,
	PageLayoutWrapper,
} from '../../components/PageLayout';
import {
	RightSidebarMode,
	rightSidebarStore,
	RightSidebarWrapper,
} from '../../components/RightSidebar';
import { useFeatureFlags } from '../../utils/featureFlags';
import MeasurementChart from './components/MeasurementsChart';
import MonitorErrorDrawer from './components/MonitorErrorDrawer';
import MonitorJobTable from './components/MonitorJobTable';
import MeasurementsTable from './containers/MeasurementsTable';
import MonitorConfigurationSidebar from './containers/MonitorConfigurationSidebar';
import MonitorPageNavbar from './containers/MonitorPageNavbar';
import { useMonitorPageStyles } from './MonitorPage.styles.ts';
import { getMonitorMetricTypeInformation, needsJobHistoryTable } from './utils';
import IncidentDrawer from './v2/IncidentDrawer';
import { IncidentDrawerProvider } from './v2/IncidentDrawerContext';
import V2ChartWrapper from './v2/V2ChartWrapper.tsx';
import V2MeasurementsTable from './v2/V2MeasurementsTable.tsx';

interface IMonitorPageProps {
	id?: string;
}

function MonitorPage({ id: propsId }: IMonitorPageProps) {
	const { classes } = useMonitorPageStyles();
	const { id: paramsId } = useParams();
	const id = propsId || paramsId;

	const [timeRange, setTimeRange] = React.useState<TimeRange>('7');
	const [errorDrawerOpen, setErrorDrawerOpen] = useState<boolean>(false);
	const { monitoringV2 } = useFeatureFlags();
	const [activeTab, setActiveTab] = useState<string>('history');

	const toggleErrorDrawer = () => {
		setErrorDrawerOpen((prevState) => !prevState);
	};

	const { data: monitor } = useMonitor({
		id: id as string, // 'id' cannot be undefined here as enabled blocks it
		options: {
			enabled: Boolean(id),
		},
	});

	const { mutateAsync: updateMonitor } = useUpdateMonitor({});

	const { data: targetEntity } = useMonitorTarget({
		monitorId: id as string,
		options: {
			enabled: Boolean(id),
		},
	});

	const { data: measurements } = useMeasurementsChartData({
		monitorId: monitor?.id,
		timeRange,
	});

	const { description, label: chartTitle } = getMonitorMetricTypeInformation(
		monitor?.metric_type,
		monitor?.description
	);

	// Re-structure the monitor name as a Entity for use in EntityPageTitle
	const entityTitle = useMemo(
		() => ({
			title: (monitor?.name ?? '') as string,
			title_cased: (monitor?.name ?? '') as string,
		}),
		[monitor?.name]
	);

	// Debounce handleRename since it's called for each keystroke. We don't want to fire off
	// a save call for each keystroke, but wait 500ms before saving
	const handleRename = debounce(async (value: string) => {
		if (!monitor?.id) {
			return;
		}

		await updateMonitor({
			data: {
				id: monitor.id,
				name: value,
			},
		});
	}, 500);

	async function handleChangeDescription(value: string) {
		if (!monitor?.id) {
			return;
		}

		await updateMonitor({
			data: {
				id: monitor.id,
				description: value,
			},
		});
	}

	const measurementFilterStore = useMemo(
		() =>
			new SearchFilterV2Store({
				filterOptions: [
					FilterOptionType.HAS_INCIDENT,
					FilterOptionType.RUN_DATE,
				],
			}),
		[]
	);

	const handleTabChange = useCallback((newValue: string) => {
		setActiveTab(newValue);
	}, []);

	return (
		<IncidentDrawerProvider>
			<SearchFilterV2StoreContext.Provider value={measurementFilterStore}>
				<PageLayoutOuterWrapper>
					<Helmet>
						<title>{monitor?.name || 'Monitor'}</title>
					</Helmet>
					<PageLayoutWrapper name="monitor">
						<PageLayoutContentWrapper name="monitor">
							<MonitorPageNavbar
								monitor={monitor}
								targetEntity={targetEntity}
							/>
							<PageLayoutContent>
								<Stack className={classes.contentLayout}>
									{monitor && (
										<>
											<EntityPageTitle
												entity={entityTitle}
												onChange={handleRename}
												placeholder="Enter Monitor Name"
												isReadOnly={false}
												icon={undefined}
											/>
											<EntityPageDescription
												entityId={undefined}
												onChange={handleChangeDescription}
												description={description}
											/>
										</>
									)}
									{monitor &&
										(monitoringV2 ? (
											<>
												<V2ChartWrapper monitor={monitor} />
												{needsJobHistoryTable(monitor) ? (
													<Tabs
														value={activeTab}
														onTabChange={(value) =>
															handleTabChange(value as string)
														}
													>
														<TabsList
															tabs={
																[
																	{
																		label: 'History',
																		value: 'history',
																	},
																	{
																		label: 'Monitored jobs',
																		value: 'jobs',
																	},
																] as TabItem[]
															}
														/>
														<Box mt="md">
															{activeTab === 'history' && (
																<V2MeasurementsTable
																	monitor={monitor}
																	hideTitle
																/>
															)}
															{activeTab === 'jobs' && (
																<MonitorJobTable monitor={monitor} />
															)}
														</Box>
													</Tabs>
												) : (
													<V2MeasurementsTable monitor={monitor} />
												)}
											</>
										) : (
											<>
												<MeasurementsTable monitorId={monitor?.id} />
												<MeasurementChart
													key={`${chartTitle}-${timeRange}`}
													timeRange={timeRange}
													onTimeRangeChange={setTimeRange}
													title={chartTitle}
													values={measurements ?? []}
													learningMode={monitor?.status === 'learning'}
												/>
											</>
										))}
								</Stack>
							</PageLayoutContent>
						</PageLayoutContentWrapper>
						<IncidentDrawer targetEntity={targetEntity} />
						<MonitorErrorDrawer
							monitor={monitor}
							open={errorDrawerOpen}
							onClose={toggleErrorDrawer}
						/>
						<RightSidebarWrapper>
							{rightSidebarStore.mode === RightSidebarMode.INFO && (
								<MonitorConfigurationSidebar
									monitorId={monitor?.id}
									targetEntity={targetEntity}
								/>
							)}
							{rightSidebarStore.mode === RightSidebarMode.AI && (
								<AIAssistantSidebar />
							)}
						</RightSidebarWrapper>
					</PageLayoutWrapper>
				</PageLayoutOuterWrapper>
			</SearchFilterV2StoreContext.Provider>
		</IncidentDrawerProvider>
	);
}

export default observer(MonitorPage);
