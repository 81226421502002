import type { MantineThemeOverride } from '@mantine/core';

export const Modal: MantineThemeOverride['components'] = {
	Modal: {
		variants: {
			default: (theme) => ({
				title: {
					fontSize: theme.other.typography.title.md,
					fontWeight: theme.other.typography.weight.bold,
				},
				header: {
					padding: `${theme.spacing.sm} ${theme.spacing.sm} ${theme.spacing.sm} ${theme.spacing.lg}`,
					borderBottom: '1px solid',
					borderColor: theme.other.getColor('border/secondary/default'),
				},
				body: {
					padding: theme.spacing.lg,
				},
			}),
		},
	},
};
