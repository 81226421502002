import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router';
import { usePublicEnv } from '../../api/hooks/utils/usePublicEnv';
import { AIChat, ChatHistory } from '../../components/AIAssistant';
import { AIAssistantProvider } from '../../components/AIAssistant/context';
import { AIAssistantMode } from '../../components/AIAssistant/types';
import { EmptyState } from '../../components/EmptyState';
import { FullWidthLoadingSpinner } from '../../components/LoadingSpinner';
import {
	PageLayoutContentWrapper,
	PageLayoutWrapper,
} from '../../components/PageLayout';
import AIPageNavBar from '../../components/SecodaAppShell/SecodaNavBar/AIPageNavBar';
import { UpgradeButton } from '../../components/Settings/UpgradeButton';
import { useAiEnabled } from '../../hooks/useAIEnabled';

function AIAssistantPage() {
	const { id } = useParams();
	const { enableAi, needsUpgrade, isLoading } = useAiEnabled();
	const { data: publicEnv } = usePublicEnv();

	if (isLoading) {
		return <FullWidthLoadingSpinner />;
	}

	if (needsUpgrade) {
		return (
			<EmptyState
				illustrationName="upgrade"
				title="Upgrade to access Secoda AI"
				description="Use Secoda AI to easily access all your company data and find answers to common questions."
				includeGoBack={false}
				stateHeight="80vh"
				size="lg"
				withActions={
					<UpgradeButton
						feature="Secoda AI"
						tooltip="Upgrade to access Secoda AI"
						size="md"
					/>
				}
			/>
		);
	}

	if (!enableAi) {
		return (
			<EmptyState
				illustrationName="upgrade"
				title="Secoda AI is disabled"
				description="Secoda AI is not enabled for this workspace. Please contact your administrator to turn on access."
				includeGoBack={false}
				stateHeight="80vh"
				size="lg"
			/>
		);
	}

	if (publicEnv?.OPENAI_ENV_CONFIGURED === false) {
		return (
			<EmptyState
				illustrationName="upgrade"
				title="Secoda AI needs to be configured"
				description="Secoda AI requires OpenAI API key to be configured. Please contact your administrator to add the OPENAI_API_KEY environment variable to the on-premise environment."
				includeGoBack={false}
				stateHeight="80vh"
				size="lg"
			/>
		);
	}

	return (
		<AIAssistantProvider mode={AIAssistantMode.PAGE} id={id ?? 'new'}>
			<PageLayoutWrapper name="ai-assistant">
				<PageLayoutContentWrapper name="ai-assistant">
					<AIPageNavBar />
					<AIChat />
				</PageLayoutContentWrapper>
				<ChatHistory />
			</PageLayoutWrapper>
		</AIAssistantProvider>
	);
}

export default observer(AIAssistantPage);
