import { debounce } from 'lodash-es';
import { makeAutoObservable } from 'mobx';
import {
	RIGHT_SIDEBAR_LOCAL_STORAGE_PROPERTY_PREFIX,
	RIGHT_SIDEBAR_WIDTH,
} from '../SecodaAppShell/constants';

export const RightSidebarMode = {
	INFO: 'info',
	AI: 'ai',
	COMMENT: 'comment',
} as const;

type RightSidebarModeKeys = keyof typeof RightSidebarMode;
export type RightSidebarModeValues =
	(typeof RightSidebarMode)[RightSidebarModeKeys];

export class RightSidebarStore {
	collapsed: boolean | null = null;

	mode: RightSidebarModeValues = RightSidebarMode.INFO;

	pageSidebarWidth: number = RIGHT_SIDEBAR_WIDTH;

	get width() {
		return this.collapsed ? 0 : this.pageSidebarWidth;
	}

	setCollapsed = (shouldCollapse: boolean) => {
		if (shouldCollapse) {
			localStorage.setItem(
				`${RIGHT_SIDEBAR_LOCAL_STORAGE_PROPERTY_PREFIX}Collapsed`,
				'true'
			);
		} else {
			localStorage.setItem(
				`${RIGHT_SIDEBAR_LOCAL_STORAGE_PROPERTY_PREFIX}Collapsed`,
				'false'
			);
		}
		this.collapsed = shouldCollapse;
	};

	setMode = (mode: 'info' | 'comment' | 'ai') => {
		localStorage.setItem(
			`${RIGHT_SIDEBAR_LOCAL_STORAGE_PROPERTY_PREFIX}Mode`,
			mode
		);
		this.mode = mode;
	};

	setWidthLocalStorageDebounce = debounce((width: number) => {
		localStorage.setItem(
			`${RIGHT_SIDEBAR_LOCAL_STORAGE_PROPERTY_PREFIX}Width`,
			width.toString()
		);
	}, 500);

	setWidth = (updatedWidth: number) => {
		this.pageSidebarWidth = updatedWidth;
		this.setWidthLocalStorageDebounce(updatedWidth);
	};

	constructor() {
		const localStorageWidth = localStorage.getItem(
			`${RIGHT_SIDEBAR_LOCAL_STORAGE_PROPERTY_PREFIX}Width`
		);

		if (localStorageWidth) {
			this.pageSidebarWidth = parseInt(localStorageWidth, 10);
		}

		const initialCollapsed =
			localStorage.getItem(
				`${RIGHT_SIDEBAR_LOCAL_STORAGE_PROPERTY_PREFIX}Collapsed`
			) === 'true';
		if (initialCollapsed) {
			this.setCollapsed(true);
		}

		const mode = localStorage.getItem(
			`${RIGHT_SIDEBAR_LOCAL_STORAGE_PROPERTY_PREFIX}Mode`
		);
		if (
			mode === RightSidebarMode.INFO ||
			mode === RightSidebarMode.COMMENT ||
			mode === RightSidebarMode.AI
		) {
			this.setMode(mode);
		} else {
			this.setMode('info');
		}

		makeAutoObservable(this);
	}
}

export const rightSidebarStore = new RightSidebarStore();
