import { size } from 'lodash-es';
import { makeAutoObservable } from 'mobx';
import { type IEmbeddedPrompt } from '../../api';
import { clearAllParamsFromUrl } from '../../utils/url';

class SearchPageStore {
	term: string;

	embeddedPrompt: IEmbeddedPrompt | null;

	pollingId: string | null;

	aiSearchTerm: string;

	showAIModal: boolean;

	constructor() {
		makeAutoObservable(this);
		this.term = '';
		this.showAIModal = false;
		this.embeddedPrompt = null;
		this.pollingId = null;
		this.aiSearchTerm = '';
	}

	reset = (all = false) => {
		if (all) {
			clearAllParamsFromUrl();
		}

		this.showAIModal = false;
		this.embeddedPrompt = null;
		this.pollingId = null;
		this.aiSearchTerm = '';
	};

	setTerm = (term: string) => {
		this.term = term;
	};

	toggleAIModal = () => {
		if (
			!this.showAIModal &&
			(size(this.aiSearchTerm) === 0 || this.aiSearchTerm !== this.term)
		) {
			this.aiSearchTerm = this.term;
		}
		this.showAIModal = !this.showAIModal;
	};

	setEmbeddedPrompt = (embeddedPrompt: IEmbeddedPrompt | null) => {
		this.embeddedPrompt = embeddedPrompt;
	};

	setPollingId = (pollingId: string | null) => {
		this.pollingId = pollingId;
	};
}

export const searchPageStore = new SearchPageStore();
