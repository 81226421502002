import { showNotification } from '@mantine/notifications';
import { isNil } from 'lodash-es';
import { useEffect, useState } from 'react';
import type { IEmbeddedPrompt } from '../../types';
import { useAIEmbedded } from './useAIEmbedded';

interface IUseStreamingAIPromptArgs {
	pollingInterval?: number;
	onFinishStreaming?: (prompt: IEmbeddedPrompt) => void;
}

export function useStreamingAIPrompt({
	pollingInterval = 1000,
	onFinishStreaming,
}: IUseStreamingAIPromptArgs = {}) {
	const [pollingId, setPollingId] = useState<string | null>(null);
	const [originalActivePrompt, setActivePrompt] = useState<
		IEmbeddedPrompt | null | undefined
	>(null);

	// We modify the active prompt to replace the domain in the response content.
	const activePrompt = originalActivePrompt;

	useAIEmbedded({
		id: pollingId!,
		options: {
			staleTime: 0,
			cacheTime: 0,
			suspense: false,
			enabled: !isNil(pollingId),
			refetchInterval: (promptData?: IEmbeddedPrompt) => {
				if (promptData?.status === 'failed') {
					setPollingId(null);

					showNotification({
						title: 'Something went wrong',
						message: 'If the issue persists contact customer support',
						color: 'red',
					});

					onFinishStreaming?.(promptData);
					return false;
				}

				if (
					promptData?.status === 'running' ||
					promptData?.status === 'pending'
				) {
					setActivePrompt(promptData);
				}

				if (
					promptData?.status === 'completed' ||
					promptData?.status === 'stopped'
				) {
					setPollingId(null);
					setActivePrompt(promptData);
					onFinishStreaming?.(promptData);
					return false;
				}

				return pollingInterval;
			},
		},
	});

	useEffect(() => () => setPollingId(null), []);

	return {
		activePrompt,
		setActivePrompt,
		pollingId,
		setPollingId,
		isStreaming: !isNil(pollingId),
		hasAIGeneratedText: !isNil(activePrompt),
	};
}
