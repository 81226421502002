import { Notifications } from '@mantine/notifications';
import { SpeedInsights } from '@vercel/speed-insights/react';
import { useEventListener } from 'ahooks';
import 'focus-visible/dist/focus-visible';
import { Suspense, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { RouterProvider } from 'react-router';
import { IntercomProvider } from 'react-use-intercom';
import { DatabuilderJobPollingProvider } from './api/hooks/databuilderJob/context';
import { ErrorBoundary } from './components/ErrorBoundary';
import {
	getWithExpiry,
	setWithExpiry,
} from './components/ErrorBoundary/errorFallbackRenderer';
import { SearchFiltersV2Provider } from './components/Filter/provider';
import GlobalBackgroundJob from './components/GlobalBackgroundJob';
import { FullWidthLoadingSpinner } from './components/LoadingSpinner';
import { Providers } from './providers';
import { router } from './router';
import { CANNY_APP_ID } from './utils/canny/canny';
import { CannyProvider } from './utils/canny/views/CannyProvider';
import { INTERCOM_APP_ID } from './utils/intercom';

export function App() {
	const unhandledRejection = useCallback(async (ev: PromiseRejectionEvent) => {
		if (ev.reason && ev.reason.name === 'DatabaseClosedError') {
			// Sometimes the Dexie cache can get corrupted, so we need to handle this.
			const dbs = await window.indexedDB.databases();
			dbs.forEach((db) => {
				if (db.name) {
					window.indexedDB.deleteDatabase(db.name);
				}
			});
		}
	}, []);

	useEventListener('vite:preloadError', () => {
		if (!getWithExpiry('chunk_failed')) {
			setWithExpiry('chunk_failed', 'true', 10000);
			window.location.reload();
		}
	});

	useEventListener('unhandledrejection', unhandledRejection);

	//  Mantine `Notifications` and `ModalsProvider` cannot be stored in the generic
	//  `Providers`, because it is reused in the outline editor and will duplicate
	//  calls to `modals.` and `notifications.`

	return (
		<Providers>
			<SpeedInsights />
			<SearchFiltersV2Provider>
				<DatabuilderJobPollingProvider>
					<IntercomProvider appId={INTERCOM_APP_ID}>
						<CannyProvider appId={CANNY_APP_ID}>
							<Notifications limit={3} />
							<ErrorBoundary>
								<Suspense fallback={<FullWidthLoadingSpinner />}>
									<Helmet titleTemplate="%s - Secoda" defaultTitle="Secoda" />
									<div id="main">
										<RouterProvider
											router={router}
											fallbackElement={<FullWidthLoadingSpinner />}
										/>
										<GlobalBackgroundJob />
									</div>
								</Suspense>
							</ErrorBoundary>
						</CannyProvider>
					</IntercomProvider>
				</DatabuilderJobPollingProvider>
			</SearchFiltersV2Provider>
		</Providers>
	);
}
