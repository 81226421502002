import { baseQueryHooksFactory } from '../../factories';
import type { Automation } from '../../types/models/automation/automation';
import { automationQueryKeyFactory } from './constants';

const {
	fetchAutomationList,
	useAutomationList,
	useAutomation,
	useCreateAutomation,
	useUpdateAutomation,
	useDeleteAutomation,
	updateAutomation,
} = baseQueryHooksFactory<Automation, 'automation'>(
	'automation',
	automationQueryKeyFactory
);

export {
	fetchAutomationList,
	updateAutomation,
	useAutomation,
	useAutomationList,
	useCreateAutomation,
	useDeleteAutomation,
	useUpdateAutomation,
};
