import type { Placement } from '@floating-ui/react';
import {
	autoUpdate,
	flip,
	FloatingPortal,
	limitShift,
	offset,
	shift,
	useFloating,
} from '@floating-ui/react';
import type { DefaultProps } from '@mantine/core';
import { Box, createStyles, MantineProvider } from '@mantine/core';
import { SecodaTheme } from '@repo/theme/mantine/secodaTheme';
import type { PropsWithChildren } from 'react';
import { useEffect } from 'react';

const useStyles = createStyles((theme) => ({
	wrapper: {
		boxShadow: theme.shadows.md,
		borderRadius: theme.radius.md,
		width: theme.other.width.xs,
		backgroundColor: theme.other.getColor('fill/primary/default'),
		pointerEvents: 'none',
		zIndex: 1000,
	},
}));

interface ChartTooltipProps extends DefaultProps {
	x: number;
	y: number;
	placement?: Placement;
	children: React.ReactElement;
}

function ChartTooltipInternal({
	x,
	y,
	placement = 'bottom-start',
	children,
	...rest
}: ChartTooltipProps) {
	const { classes } = useStyles();

	const { refs, floatingStyles } = useFloating({
		whileElementsMounted: autoUpdate,
		open: true,
		placement,
		middleware: [
			offset(8),
			shift({ limiter: limitShift() }),
			flip({ padding: 5 }),
		],
	});

	useEffect(() => {
		refs.setPositionReference({
			getBoundingClientRect() {
				return {
					width: 0,
					height: 0,
					x: x,
					y: y,
					top: y,
					left: x,
					right: x,
					bottom: y,
				};
			},
		});
	}, [refs, x, y]);

	return (
		<FloatingPortal>
			<Box
				{...rest}
				ref={refs.setFloating}
				style={floatingStyles}
				className={classes.wrapper}
			>
				{children}
			</Box>
		</FloatingPortal>
	);
}

export function ChartTooltip(props: PropsWithChildren<ChartTooltipProps>) {
	return (
		<MantineProvider
			theme={{ ...SecodaTheme, colorScheme: 'light' }}
			withNormalizeCSS
			withGlobalStyles
		>
			<ChartTooltipInternal {...props} />
		</MantineProvider>
	);
}
