import { showNotification } from '@mantine/notifications';
import { Icon } from '@repo/foundations';

export const showCollectionNotification = (
	status: 'warning' | 'error' | 'success',
	title: string
) => {
	if (status === 'warning') {
		showNotification({
			title: 'Warning',
			color: 'orange',
			message: `${title} is already in this collection.`,
			icon: <Icon name="exclamationMark" />,
		});

		return;
	}

	if (status === 'error') {
		showNotification({
			title: 'Error',
			color: 'red',
			message: `Error adding ${title} to collection.`,
			icon: <Icon name="x" />,
		});
		return;
	}

	showNotification({
		title: 'Success',
		message: `Successfully added ${title} to collection.`,
		color: 'green',
	});
};
