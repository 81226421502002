import type { DragEndEvent } from '@dnd-kit/core';
import {
	DndContext,
	KeyboardSensor,
	PointerSensor,
	closestCenter,
	useSensor,
	useSensors,
} from '@dnd-kit/core';
import {
	SortableContext,
	rectSortingStrategy,
	sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';
import { Box, Group, Stack, createStyles } from '@mantine/core';
import { observer } from 'mobx-react-lite';
import { type IMetricWidget } from '../../api';
import { EmptyState } from '../../components/EmptyState';
import { appShellStore } from '../../components/SecodaAppShell/store';
import { useReportManagement } from '../HomePage/hooks/useReportManagement';
import AddWidgetModal from './components/AddWidgetModal/AddWidgetModal';
import AddWidgetButton from './components/Buttons/AddWidgetButton';
import SelectReportTemplateMenu from './components/Buttons/SelectTemplateMenu';
import { MetricWidget } from './components/MetricWidget';

const useStyles = createStyles((theme) => ({
	// Empty state
	root: {
		flexGrow: 1,
		height: '100%',
		paddingBottom: theme.spacing.sm,
	},
	emptyStateContainer: {
		flexGrow: 1,
		display: 'flex',
	},
	emptyState: {
		padding: 0,
		paddingBottom: theme.other.space[16],
	},

	// Text
	wrapper: {
		width: '100%',
		display: 'grid',
		gridTemplateRows: '1fr',
		gridTemplateColumns: 'repeat(12, 1fr)',
		gap: theme.spacing.md,
		padding: theme.spacing.sm,
	},
	emptySpace: {
		width: theme.other.width.md,
	},
	textAreaRoot: {
		display: 'flex',
		flexGrow: '1 !important' as any,
	},
	textAreaWrapper: {
		width: '100%',
	},
	textAreaInput: {
		height: 'unset',
		fontSize: 32,
		fontWeight: theme.other.typography.weight.bold,

		'&:focus': {
			boxShadow: 'none',
			borderColor: 'transparent',
			backgroundColor: 'transparent',
		},

		'&:disabled': {
			opacity: 1,
			backgroundColor: 'transparent',
			color: 'black',
			cursor: 'text',
		},
	},
}));

interface DynamicReportProps {
	id: string;
}

export const DynamicReport = observer(({ id }: DynamicReportProps) => {
	const { classes } = useStyles();

	const {
		analyticsPageAddWidgetModalHandler: { close },
		navBarUI: {
			analyticsPage: { addWidgetModalOpen },
		},
	} = appShellStore;

	// Widgets logic
	const {
		report,
		updateWidget,
		removeWidget,
		reorderWidgets,
		addWidget,
		populateReportWithTemplate,
		widgets,
	} = useReportManagement({
		type: 'analytics',
		id,
	});

	const sensors = useSensors(
		useSensor(PointerSensor),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates,
		})
	);

	const handleDragEnd = async ({ active, over }: DragEndEvent) => {
		if (!over) {
			return;
		}

		const from = widgets.findIndex((info) => info.id === active.id);
		const to = widgets.findIndex((info) => info.id === over.id);

		reorderWidgets({ from, to });
	};

	if (widgets.length === 0) {
		return (
			<>
				<AddWidgetModal
					addWidget={addWidget}
					opened={addWidgetModalOpen}
					onClose={close}
				/>
				<Stack className={classes.root}>
					<Box className={classes.emptyStateContainer}>
						<EmptyState
							illustrationName="reports"
							title="Add widgets to this report"
							description="Get started with a template or by adding widgets from the widget gallery."
							includeGoBack={false}
							size="lg"
							descWidth="100%"
							width="100%"
							withActions={
								<Group>
									<SelectReportTemplateMenu
										reportId={report?.id}
										populateReportWithTemplate={populateReportWithTemplate}
									/>
									<AddWidgetButton />
								</Group>
							}
						/>
					</Box>
				</Stack>
			</>
		);
	}

	return (
		<>
			<AddWidgetModal
				addWidget={addWidget}
				opened={addWidgetModalOpen}
				onClose={close}
			/>
			<Box className={classes.wrapper}>
				<DndContext
					collisionDetection={closestCenter}
					sensors={sensors}
					onDragEnd={handleDragEnd}
				>
					<SortableContext strategy={rectSortingStrategy} items={widgets}>
						{widgets.map((widget) => (
							<MetricWidget
								key={widget.id}
								metricWidget={widget as IMetricWidget}
								updateWidget={updateWidget}
								deleteWidget={removeWidget(widget.id)}
							/>
						))}
					</SortableContext>
				</DndContext>
			</Box>
		</>
	);
});
