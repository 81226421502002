import { Image } from '@mantine/core';
import { TableauEmbed } from '@stoddabr/react-tableau-embed-live';
import { IFrame } from '../IFrame/IFrame';

interface IPreviewMap {
	[supportedPreview: string]: (embedUrl: string) => JSX.Element;
}

export const PREVIEW_MAP: IPreviewMap = {
	tableau: (embedUrl: string) => <TableauEmbed sourceUrl={embedUrl} />,
	mode: (embedUrl: string) => <Image src={embedUrl} />,
	looker: (embedUrl: string) => (
		<IFrame url={embedUrl} title="Looker Dashboard" />
	),
	sigma: (embedUrl: string) => (
		<IFrame url={embedUrl} title="Sigma Dashboard" />
	),
	metabase: (embedUrl: string) => (
		<IFrame url={embedUrl} title="Metabase Dashboard" />
	),
	mixpanel: (embedUrl: string) => (
		<IFrame url={embedUrl} title="Mixpanel Dashboard" />
	),
	retool: (embedUrl: string) => (
		<IFrame url={embedUrl} title="Retool Dashboard" />
	),
	thoughtspot: (embedUrl: string) => (
		<IFrame url={embedUrl} title="ThoughtSpot Dashboard" />
	),
	lightdash: (embedUrl: string) => (
		<IFrame url={embedUrl} title="Lightdash Dashboard" />
	),
	hashboard: (embedUrl: string) => (
		<IFrame url={embedUrl} title="Hashboard Dashboard" />
	),
	looker_studio: (embedUrl: string) => (
		<IFrame url={embedUrl} title="Looker Studio" />
	),
	hex: (embedUrl: string) => <IFrame url={embedUrl} title="Hex Dashboard" />,
};
