import type { PopoverProps } from '@mantine/core';
import { createStyles, Stack } from '@mantine/core';
import { Text } from '@repo/foundations';
import React from 'react';
import { Link } from 'react-router-dom';
import { HoverCard } from '../HoverCard';

interface RichTooltipProps {
	title: string;
	linkLabel?: string;
	linkUrl?: string;
	hidden?: boolean;
	body: React.ReactNode;
	children: React.ReactNode;
	maxWidth?: number;
	position?: PopoverProps['position'];
}

const useStyles = createStyles((theme, maxWidth?: number) => ({
	dropdown: {
		borderRadius: theme.other.borderRadius.sm,
		padding: `${theme.spacing.sm} ${theme.spacing.md}`,
		gap: theme.spacing.sm,
		backgroundColor: theme.other.getColor('surface/primary/default'),
		maxWidth: maxWidth ? maxWidth : theme.other.space[90],
		whiteSpace: 'pre-wrap',
	},
	linkText: {
		textDecoration: 'none',
		color: theme.other.getColor('text/emphasis/default'),
		display: 'flex',
		alignItems: 'center',
	},
	textBox: {
		gap: theme.spacing['3xs'],
		flexWrap: 'wrap',
	},
}));

function RichTooltip({
	title,
	linkLabel,
	linkUrl,
	body,
	children,
	position,
	hidden,
	maxWidth,
	...other
}: RichTooltipProps & Partial<PopoverProps>) {
	const { classes, theme } = useStyles(maxWidth);

	return (
		<HoverCard
			withinPortal={true}
			position={position}
			target={children}
			hidden={hidden}
			{...other}
		>
			<Stack className={classes.dropdown}>
				<Stack className={classes.textBox}>
					<Text mb={theme.other.space[1]} weight="bold" size="sm">
						{title}
					</Text>
					<Text color="text/secondary/default" size="sm" w="100%">
						{body}
					</Text>
				</Stack>
				{linkLabel && linkUrl && (
					<Text weight="semibold" size="sm">
						<Link className={classes.linkText} to={linkUrl}>
							{linkLabel}
						</Link>
					</Text>
				)}
			</Stack>
		</HoverCard>
	);
}

export default RichTooltip;
