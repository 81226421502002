import { useMemo } from 'react';
import type { IJobRun } from '../../../api';
import {
	DurationRender,
	LastUpdatedRender,
	ReadOnlyBadgeRender,
} from '../../../components/TableV2/render';
import type { ExtendedDataTableColumn } from '../../../components/TableV2/types';

export const useColumns = (): ExtendedDataTableColumn<IJobRun>[] => {
	const columns: ExtendedDataTableColumn<IJobRun>[] = useMemo(
		() => [
			{
				title: 'Started at',
				accessor: 'run_created_at',
				render: (record) => (
					<LastUpdatedRender<IJobRun>
						record={record}
						field={'run_created_at'}
					/>
				),
			},
			{
				title: 'Finished at',
				accessor: 'run_finished_at',
				render: (record) => (
					<LastUpdatedRender<IJobRun>
						record={record}
						field={'run_finished_at'}
					/>
				),
			},
			{
				title: 'Status',
				accessor: 'status',
				render: (record) => (
					<ReadOnlyBadgeRender
						record={record}
						field={'status'}
						options={[
							{ option: 'error', color: 'red', label: 'Error' },
							{ option: 'success', color: 'green', label: 'Success' },
							{ option: 'completed', color: 'green', label: 'Completed' },
							{ option: 'succeeded', color: 'green', label: 'Success' }, // Glue saves success status as succeeded
							{ option: 'running', color: 'blue', label: 'Running' },
							{ option: 'canceled', color: 'gray', label: 'Cancelled' },
							{ option: 'failed', color: 'red', label: 'Error' },
							{ option: 'initializing', color: 'blue', label: 'Initializing' },
							{ option: 'rendering', color: 'blue', label: 'Rendering' },
							{ option: 'unknown', color: 'gray', label: 'Unknown' },
							{
								option: 'waitingToRun',
								color: 'gray',
								label: 'Waiting to Run',
							},
						]}
						nilOption={{
							option: 'Unknown error',
							color: 'red',
							label: 'Unknown error',
						}}
					/>
				),
			},
			{
				title: 'Duration',
				accessor: 'duration',
				render: (record) => (
					<DurationRender<IJobRun>
						record={record}
						field1={'run_created_at'}
						field2={'run_finished_at'}
					/>
				),
			},
		],
		[]
	);

	return columns;
};
