import { Center, Image } from '@mantine/core';

function ChartCard() {
	return (
		<Center w="100%">
			<Image src="/images/empty-state/chart.svg" />
		</Center>
	);
}

export default ChartCard;
