import { Text } from '@repo/foundations';
import {
	ListBox,
	type ListBoxProps,
} from '@repo/foundations/components/ListBox';
import { useCallback, useMemo, useState } from 'react';
import { useQueryableIntegrationOptions } from '../../../../Sql/Actions/IntegrationSelectAction.hooks';

interface IntegrationPickerProps {
	onClose: () => void;
	targetRef: ListBoxProps['targetRef'];
	onSelect: (integrationId: string) => void;
}

export function IntegrationPicker({
	onClose,
	targetRef,
	onSelect,
}: IntegrationPickerProps) {
	const [searchTerm, setSearchTerm] = useState('');
	const integrations = useQueryableIntegrationOptions();

	const handleOpenChange = useCallback(
		(open: boolean) => !open && onClose(),
		[onClose]
	);

	const items = useMemo(
		() =>
			(integrations ?? [])
				.filter(
					(integration) =>
						!searchTerm ||
						integration.label.toLowerCase().includes(searchTerm.toLowerCase())
				)
				.map((item) => ({
					icon: item.icon,
					children: <Text size="sm">{item.label ?? 'Untitled'}</Text>,
					onClick: () => {
						onSelect(item.value);
					},
				})) ?? [],
		[integrations, onSelect, searchTerm]
	);

	return (
		<ListBox opened onOpenChange={handleOpenChange} targetRef={targetRef}>
			<ListBox.ItemsDropdown
				items={items}
				search={{
					onChange: setSearchTerm,
					value: searchTerm,
					placeholder: 'Search integrations',
				}}
			/>
		</ListBox>
	);
}
