import { Group, Input, Modal, Stack, createStyles } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import SingleSelector from '@repo/common/components/SingleSelector/SingleSelector';
import type { SelectablePropertyItem } from '@repo/common/components/SingleSelector/types';
import { Button, Icon } from '@repo/foundations';
import produce from 'immer';
import React from 'react';
import { useNavigate } from 'react-router';
import type { IMetricWidget, Monitor } from '../../../api';
import { useCreateMonitor } from '../../../api';
import { BackgroundJobProgress } from '../../../components/BackgroundJobProgress/BackgroundJobProgress';
import { BackgroundJob } from '../../../lib/models';
import { snakeCaseToTitleCase } from '../../../utils/shared.utils';
import { AnalyticsMonitorSample } from './AnalyticsMonitorSample';
import IntegrationFilters from './FilterMenu/IntegrationFilters';

const useStyles = createStyles((theme) => ({
	wrapper: {
		border: '1px solid',
		borderColor: theme.other.getColor('border/input/default'),
		borderRadius: theme.radius.sm,
		paddingRight: theme.spacing.xs,
	},
	disabled: {
		cursor: 'not-allowed',
		opacity: 0.5,
	},
	icon: {
		color: theme.other.getColor('icon/secondary/default'),
		width: theme.other.iconSize.sm,
	},
}));

export type IAddAnalyticsMonitorModalProps = {
	opened: boolean;
	onClose: VoidFunction;
	metricWidget: IMetricWidget;
};

export function AddAnalyticsMonitorModal({
	opened,
	onClose,
	metricWidget,
}: IAddAnalyticsMonitorModalProps) {
	const navigate = useNavigate();
	const { classes } = useStyles();

	const { mutateAsync: createMonitor } = useCreateMonitor({});
	const values = Object.keys(metricWidget?.metric_metadata?.value || {});
	const valueOptions: SelectablePropertyItem[] = values.map(
		(val) =>
			({
				value: val,
				label: snakeCaseToTitleCase(val),
			}) as SelectablePropertyItem
	);
	const [selectedValue, setSelectedValue] = React.useState<string>(values[0]);
	const [selectedFilters, setSelectedFilters] = React.useState<
		Record<string, string>
	>({});
	const handleFilterChange = (key: string, value: string | null) => {
		setSelectedFilters(
			produce(selectedFilters, (draft) => {
				if (value) {
					return {
						...draft,
						[key]: value,
					};
				}
				delete draft[key];
				return draft;
			})
		);
	};

	const filters = IntegrationFilters({
		metricWidget,
		onFilterChange: handleFilterChange,
		filterMap: selectedFilters,
		includeLookbackFilter: false,
	});

	const [backgroundJob, setBackgroundJob] =
		React.useState<BackgroundJob | null>(null);
	const [monitor, setMonitor] = React.useState<Monitor | null>(null);

	const handleBackgroundJobComplete = () => {
		setBackgroundJob(null);
		showNotification({
			title: 'Added monitor',
			message: 'Successfully added monitor',
			icon: <Icon name="check" />,
			color: 'success',
		});
		navigate(`/monitor/${monitor?.id}`);
	};

	const handleAddMonitor = async () => {
		const monitor = await createMonitor({
			data: {
				name: metricWidget.title,
				target_metric: metricWidget.metric_metadata.metric_id,
				integration: metricWidget.metric_metadata.integration_id,
				metric_config: {
					value: {
						[selectedValue]:
							metricWidget?.metric_metadata?.value?.[selectedValue] || '',
					},
					filter: selectedFilters,
				},
				metric_type: 'analytics',
				is_enabled: true,
				condition_auto_sensitivity: 5,
				condition_manual_min: null,
				condition_manual_max: null,
			},
		});

		if (monitor.background_job_id) {
			const backgroundJob = new BackgroundJob({
				id: monitor.background_job_id,
			});
			setBackgroundJob(backgroundJob);
			setMonitor(monitor);
		}
	};

	return (
		<>
			<Modal
				title={`Add monitors on ${metricWidget.title}`}
				opened={opened}
				onClose={onClose}
				variant="default"
			>
				<Stack py="sm" px="xs" pb={0}>
					<Input.Label>Value to monitor</Input.Label>
					<Group className={classes.wrapper} noWrap>
						<SingleSelector
							placeholder="Select value to monitor on"
							variant="default"
							iconType="none"
							isViewerUser={false}
							options={valueOptions}
							onChange={(value) => {
								setSelectedValue(value);
							}}
							initialSelected={selectedValue}
							searchable={false}
							property="resource"
						/>
					</Group>
					<Input.Label>Filters</Input.Label>
					{filters}
					<Input.Label pt="md">Last 30 days preview</Input.Label>
					<AnalyticsMonitorSample
						metricWidget={metricWidget}
						filters={selectedFilters}
						value={{
							[selectedValue]:
								metricWidget.metric_metadata?.value?.[selectedValue] || '',
						}}
						lookbackDays={30}
					/>
					<Group position="right" pb={0}>
						<Button variant="default" onClick={onClose}>
							Cancel
						</Button>
						<Button variant="primary" onClick={handleAddMonitor}>
							Add Monitor
						</Button>
					</Group>
				</Stack>
			</Modal>
			{backgroundJob && (
				<BackgroundJobProgress
					key={backgroundJob.id}
					job={backgroundJob}
					onCompleted={handleBackgroundJobComplete}
				/>
			)}
		</>
	);
}
