import type { IFetchLineageParams, IFetchResourceParams } from './actions';
import {
	fetchLineage,
	fetchLineageCount,
	fetchResource,
	fetchResourceCount,
} from './actions';
import { ACTION_FIELDS } from './constants';
import { ACTION_TYPE } from './types';

export const getInitialFormValues = (type: ACTION_TYPE) => {
	const initialValues: Record<string, string | boolean> = {};
	ACTION_FIELDS[type].forEach((field) => {
		initialValues[field.value] = field.type === 'string' ? '' : false;
	});
	return initialValues;
};

export const onQueryAction = (
	type: ACTION_TYPE,
	params: Record<string, string | boolean>
) => {
	switch (type) {
		case ACTION_TYPE.GET_LINEAGE:
			return fetchLineage(params as IFetchLineageParams);
		case ACTION_TYPE.GET_LINEAGE_COUNT:
			return fetchLineageCount(params as IFetchLineageParams);
		case ACTION_TYPE.GET_RESOURCE:
			return fetchResource(params as IFetchResourceParams);
		case ACTION_TYPE.GET_RESOURCE_COUNT:
			return fetchResourceCount(params as IFetchResourceParams);
		default:
			return '';
	}
};
