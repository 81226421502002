import { arrayBufferToString } from './utils';

export async function fileToMarkdown(
	content: ArrayBuffer | string
): Promise<string> {
	if (content instanceof ArrayBuffer) {
		content = arrayBufferToString(content);
	}
	return content;
}
