import { Container, createStyles } from '@mantine/core';
import { EmptyState } from '../EmptyState';

const useStyles = createStyles(() => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		width: 320,
		gap: 16,
		paddingRight: 0,
		paddingLeft: 0,
	},
}));

function Error404(): JSX.Element {
	const { classes, theme } = useStyles();

	return (
		<Container my={theme.other.space[28]} className={classes.container}>
			<EmptyState
				title="The resource can't be found"
				description="Secoda may be out of sync with the source or the resource may not exist in this workspace"
				iconName="search"
				includeGoBack
				size="lg"
			/>
		</Container>
	);
}

export default Error404;
