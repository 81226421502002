import type { DefaultProps, Selectors } from '@mantine/core';
import { createStyles } from '@mantine/core';
import { Button } from '@repo/foundations';
import { observer } from 'mobx-react-lite';
import { useFeatureAccess } from '../../api/hooks/workspace/useFeatureAccess';
import { closeAllModals, openModal } from '../ModalManager';
import AddAutomationModal from './AddAutomationModal';

const useStyles = createStyles({
	modalContent: {
		overflow: 'hidden',
		padding: 0,
	},
});

type AddAutomationButtonStylesNames = Selectors<typeof useStyles>;

export interface AddAutomationProps
	extends DefaultProps<AddAutomationButtonStylesNames> {}

function AddAutomationButton({ className, ...others }: AddAutomationProps) {
	const { classes } = useStyles(undefined, {
		name: 'addAutomationButton',
	});
	const { automationsAccess } = useFeatureAccess();

	if (!automationsAccess) {
		return null;
	}

	const handleOnClick = async () => {
		openModal({
			variant: 'default',
			title: 'New automation',
			children: <AddAutomationModal onClose={() => closeAllModals()} />,
			lockScroll: true,
			size: 640,
			padding: 0,
			classNames: {
				body: classes.modalContent,
			},
		});
	};

	return (
		<Button
			className={className}
			onClick={handleOnClick}
			variant="primary"
			{...others}
		>
			Create automation
		</Button>
	);
}

export default observer(AddAutomationButton);
