import type { QueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { apiClient, getEndpoints } from '../../common';
import queryClient from '../../queryClient';
import type { NativeType } from '../../types';

export const NATIVE_TYPES_NAMESPACE = ['native_types'];

interface FetchWorkspaceNativeTypesArgs {
	signal?: AbortSignal;
	team_id?: string;
	options?: QueryOptions<NativeType[], unknown, NativeType[]>;
}

export const getWorkspaceNativeTypesQueryFn = async ({
	signal,
	...params
}: FetchWorkspaceNativeTypesArgs) => {
	const url = getEndpoints(NATIVE_TYPES_NAMESPACE).root();

	const { data } = await apiClient.get<NativeType[]>(url, {
		params,
		signal,
	});

	return data;
};

export function fetchWorkspaceNativeTypes({
	signal,
	...args
}: FetchWorkspaceNativeTypesArgs) {
	return queryClient.fetchQuery({
		queryKey: [...NATIVE_TYPES_NAMESPACE, args],
		queryFn: () => getWorkspaceNativeTypesQueryFn({ signal, ...args }),
	});
}

export function useWorkspaceNativeTypes({
	options,
	...args
}: Omit<FetchWorkspaceNativeTypesArgs, 'signal'> = {}) {
	return useQuery({
		queryKey: [...NATIVE_TYPES_NAMESPACE, args],
		queryFn: ({ signal }) =>
			getWorkspaceNativeTypesQueryFn({ signal, ...args }),
		...options,
	});
}
