import { Box, CopyButton, Flex } from '@mantine/core';
import { Button, Switch, Text } from '@repo/foundations';
import ed25519 from 'ed25519-keygen/ssh';
import { randomBytes } from 'ed25519-keygen/utils';
import produce from 'immer';
import { map } from 'lodash-es';
import type { IIntegration } from '../../api';
import { queryClient } from '../../api';
import { useLookerProjectList } from '../../api/hooks/databuilder';
import { lookerProjectsQueryKeyFactory } from '../../api/hooks/databuilder/constant';
import {
	useCreateIntegrationSshKey,
	useUpdateIntegrationSshKey,
} from '../../api/hooks/integration/useIntegrationSshKey';
import type { ILookerProject } from '../../api/types/models/lookerProject';
import { LoadingSpinner } from '../LoadingSpinner';

interface IntegrationLookerGitProps {
	integration: IIntegration;
}

export function IntegrationLookerGit({
	integration,
}: IntegrationLookerGitProps) {
	const { data: projects } = useLookerProjectList({
		integrationID: integration.id,
	});

	const { mutate: createIntegrationSSHKey } = useCreateIntegrationSshKey({});
	const { mutate: updateIntegrationSSHKey } = useUpdateIntegrationSshKey({});

	if (!projects) {
		return <LoadingSpinner />;
	}

	if (projects?.length === 0) {
		return (
			<Text display="block" size="sm">
				No projects found for this resource. Try running Metadata Extraction on
				the History tab and refresh this page.
			</Text>
		);
	}

	const handleSwitchChange = async (
		project: ILookerProject,
		checked: boolean
	) => {
		if (!project.public_key || !project.ssh_key_id) {
			createKey(project);
		} else {
			updateKey(project, checked);
		}
	};

	const createKey = async (project: ILookerProject) => {
		// Generate a new SSH keypair if it doesn't exist
		const seed = randomBytes(32);
		const keys = await ed25519(seed, 'ssh@secoda.co');
		createIntegrationSSHKey(
			{
				data: {
					public_key: btoa(keys.publicKey),
					private_key: btoa(keys.privateKey),
					entity: project.entity,
					url: project.git_remote_url,
					is_used: true,
				},
			},
			{
				onSuccess: (newIntegrationSSHKey) => {
					queryClient.setQueryData<ILookerProject[]>(
						lookerProjectsQueryKeyFactory.list(1, {
							integration_id: integration.id,
						}),
						(oldProjects) =>
							map(oldProjects, (oldProject) => {
								if (oldProject.id === project.id) {
									return produce(oldProject, (draft) => {
										draft.public_key = newIntegrationSSHKey.public_key;
										draft.is_used = true;
										draft.ssh_key_id = newIntegrationSSHKey.id;
									});
								}
								return oldProject;
							})
					);
				},
			}
		);
	};

	const updateKey = async (project: ILookerProject, checked: boolean) => {
		updateIntegrationSSHKey(
			{
				data: {
					id: project.ssh_key_id ?? '',
					is_used: checked,
				},
			},
			{
				onSuccess: () => {
					queryClient.setQueryData<ILookerProject[]>(
						lookerProjectsQueryKeyFactory.list(1, {
							integration_id: integration.id,
						}),
						(oldProjects) =>
							map(oldProjects, (oldProject) => {
								if (oldProject.id === project.id) {
									return produce(oldProject, (draft) => {
										draft.is_used = checked;
									});
								}
								return oldProject;
							})
					);
				},
			}
		);
	};

	return (
		<Box>
			{map(projects, (project) => (
				<Flex key={project.id} align="center" justify="space-between" my="5px">
					<Flex gap="10px">
						<Switch
							checked={project.is_used}
							onChange={(event) =>
								handleSwitchChange(project, event.target.checked)
							}
						/>
						<Text size="sm">{project.name}</Text>
					</Flex>
					{project.public_key && project.is_used && (
						<CopyButton value={atob(project.public_key)}>
							{({ copied, copy }) => (
								<Button color={copied ? 'teal' : 'blue'} onClick={copy}>
									{copied ? 'Copied' : 'Copy public key'}
								</Button>
							)}
						</CopyButton>
					)}
				</Flex>
			))}
		</Box>
	);
}
