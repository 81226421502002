export enum ScheduleCadence {
	HOURLY = 'hourly',
	DAILY = 'daily',
	WEEKLY = 'weekly',
}

export interface ScheduleConfig {
	cadence: ScheduleCadence;
	frequency?: number;
	day?: number;
	hour?: number;
}
