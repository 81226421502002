import type { ISecodaEntity } from '../../api';
import { AIAssistantSidebarToggle } from '../AIAssistant';
import { CommentsSidebarToggle } from '../CommentsSidebarToggle';
import { EntityPageSidebarToggle } from '../EntityPageSidebarToggle';

interface INavBarEntityButtonsProps {
	entity: ISecodaEntity;
	withComment?: boolean;
}

function EntityPageToggles({
	// I believe this prop should continue to exist, even though we're not using it at the moment.
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	entity,
	withComment,
}: INavBarEntityButtonsProps) {
	return (
		<>
			<AIAssistantSidebarToggle />
			{withComment && <CommentsSidebarToggle />}
			<EntityPageSidebarToggle />
		</>
	);
}

export default EntityPageToggles;
