import type { IProseMirrorEditorProps } from '../Editor/outline/src';

export const DISABLE_EXTENSIONS_FOR_CREATION_MODAL_EDITOR: IProseMirrorEditorProps['disableExtensions'] =
	['chart_block', 'query_block'];

export const DISABLE_EXTENSIONS_FOR_LIMITED_EDITOR: IProseMirrorEditorProps['disableExtensions'] =
	[
		'container_notice',
		'chart_block',
		'query_block',
		'table',
		'td',
		'th',
		'tr',
		'holy',
		'page',
		'embed',
		'image',
		'hr',
		'attachment',
		'comment',
		'blockmenu',
		'find-and-replace',
	];
export const DISABLE_INPUT_EXTENSIONS_FOR_LIMITED_EDITOR: IProseMirrorEditorProps['disableInputExtensions'] =
	['code_block', 'code_fence'];
