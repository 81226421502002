import type { Command, EditorState, Transaction } from 'prosemirror-state';
import type { Dispatch } from '../types';

/**
 * Chain multiple commands into a single command and collects state as it goes.
 *
 * @param commands The commands to chain
 * @returns The chained command
 */
export default function chainTransactions(
	...commands: Array<Command | undefined>
) {
	return (state: EditorState, dispatch?: Dispatch): boolean => {
		const dispatcher = (tr: Transaction): void => {
			state = state.apply(tr);
			dispatch?.(tr);
		};
		const last = commands.pop();
		commands.map((command) => command?.(state, dispatcher));
		return last !== undefined && last(state, dispatch);
	};
}
