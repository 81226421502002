import type {
	DefaultProps,
	TitleProps as MantineTitleProps,
	TitleOrder,
} from '@mantine/core';
// eslint-disable-next-line no-restricted-imports
import { Title as MantineTitle, createStyles } from '@mantine/core';
import { typography } from '@repo/theme/primitives';
import type { ColorNames } from '@repo/theme/utils';
import { TITLE_ORDER_TO_SIZE_MAP } from './constants';

interface TitleStylesParams {
	size?: keyof typeof typography.title;
	weight: keyof typeof typography.weight;
	color: ColorNames;
	order?: TitleOrder;
}

interface SecodaTitleProps extends DefaultProps<'', TitleStylesParams> {
	children?: React.ReactNode;
	size?: keyof typeof typography.title;
	weight?: keyof typeof typography.weight;
	color?: ColorNames;
}

export type TitleProps = SecodaTitleProps &
	Omit<MantineTitleProps, keyof SecodaTitleProps | 'lh' | 'weight'>;

const useStyles = createStyles(
	(theme, { size, order, weight, color }: TitleStylesParams) => ({
		root: {
			fontSize: size
				? typography.title[size]
				: typography.title[TITLE_ORDER_TO_SIZE_MAP[order as any]],
			lineHeight: size
				? typography.lineHeight.title[size]
				: typography.lineHeight.title[TITLE_ORDER_TO_SIZE_MAP[order as any]],
			fontWeight: typography.weight[weight],
			color: color
				? theme.other.getColor(color)
				: theme.other.getColor('text/primary/default'),
		},
	})
);

function Title({
	children,
	order,
	size,
	weight = 'bold',
	color = 'text/primary/default',
	classNames,
	styles,
	unstyled,
	className,
	...others
}: TitleProps) {
	const { classes, cx } = useStyles(
		{
			size,
			weight,
			color,
		},
		{ name: 'Title', classNames, unstyled, styles }
	);

	const titleStyles = {
		...styles,
	};

	return (
		<MantineTitle
			order={order}
			size={size}
			styles={titleStyles}
			className={cx(classes.root, className)}
			{...others}
		>
			{children}
		</MantineTitle>
	);
}

export { Title };
