import dayjs from 'dayjs';
import { isEmpty } from 'lodash-es';
import { useMemo } from 'react';
import type { IMetricWidget, IMetricWidgetSample } from '../../../api';
import { useIntegrationMetricMeasurement } from '../../../api';
import { snakeCaseToTitleCase } from '../../../utils/shared.utils';
import { DEFAULT_COLORS, convertValue } from '../utils/utils';
import { MetricLoadingOrEmpty } from './MetricLoadingOrEmpty';
import {
	MetricWidgetLineChart,
	type MetricWidgetLineChartProps,
} from './MetricWidgetLineChart/MetricWidgetLineChart';

export type IAnalyticsMonitorPreviewProps = {
	metricWidget: IMetricWidget;
	filters: Record<string, string>;
	value: Record<string, string>;
	lookbackDays: number;
};

export function AnalyticsMonitorSample({
	metricWidget,
	filters,
	value,
	lookbackDays,
}: IAnalyticsMonitorPreviewProps) {
	const { data: datapoints, isLoading } = useIntegrationMetricMeasurement(
		metricWidget.metric_metadata.metric_id as string,
		filters,
		value,
		lookbackDays
	);

	const isLoadingOrEmpty = isEmpty(datapoints) || isLoading || !datapoints;

	const chartProps = useMemo(() => {
		if (isLoadingOrEmpty) {
			return { channels: [], data: [] };
		}

		const valueNames = Object.keys(metricWidget.metric_metadata?.value || {});
		const channels = valueNames.map((valueName) => ({
			label: snakeCaseToTitleCase(valueName),
			color:
				metricWidget.metric_metadata?.value_color?.[valueName] ||
				DEFAULT_COLORS[0],
			x: 'date',
			y: valueName,
		})) as MetricWidgetLineChartProps<{ date: Date }>['channels'];

		const data = datapoints.map((point) => ({
			date: dayjs(point.timestamp).toDate(),
			...valueNames.reduce(
				(acc, valueName) => ({
					...acc,
					[valueName]: convertValue(point[valueName] as number),
				}),
				{}
			),
		}));

		return { data, channels };
	}, [
		datapoints,
		isLoadingOrEmpty,
		metricWidget.metric_metadata?.value,
		metricWidget.metric_metadata?.value_color,
	]);

	if (isLoadingOrEmpty) {
		return (
			<MetricLoadingOrEmpty
				isLoading={isLoading}
				isEmpty={isEmpty(datapoints)}
				source="sample"
			/>
		);
	}

	return (
		<MetricWidgetLineChart
			source="sample"
			metricWidget={metricWidget as IMetricWidgetSample}
			{...chartProps}
		/>
	);
}
