import { Box, createStyles, Skeleton, Tabs } from '@mantine/core';
import { EntityType } from '@repo/common/enums/entityType';
import { Suspense, useCallback } from 'react';
import { useNavigate } from 'react-router';
import type { IUser, IUserGroup } from '../../../api';
import { useUserList } from '../../../api';
import type { CatalogServerType } from '../../../components/CatalogView/types';
import { ErrorBoundary } from '../../../components/ErrorBoundary';
import { TableV2 } from '../../../components/TableV2';
import { BoldTextRender } from '../../../components/TableV2/render';
import { CatalogTable } from '../../TeamCatalogPage/CatalogTable';

const useStyles = createStyles(() => ({
	tableWrapper: {
		marginTop: 16,
		height: 'calc(100vh - 280px)',
	},
}));

interface IProfileTabsProps {
	userGroup: IUserGroup;
}

function UserGroupProfileTabs({ userGroup }: IProfileTabsProps) {
	const { classes } = useStyles();
	const navigate = useNavigate();

	const handleRowClick = useCallback(
		(row: IUser) => {
			navigate(`/user/${row.id}`);
		},
		[navigate]
	);

	return (
		<Tabs color="dark" defaultValue={'people'}>
			<Tabs.List>
				<Tabs.Tab key={'people'} value={'people'}>
					People
				</Tabs.Tab>
				<Tabs.Tab key={'resources'} value={'resources'}>
					Resources
				</Tabs.Tab>
			</Tabs.List>

			<Tabs.Panel key={'people'} value={'people'} pt="xs">
				<Box className={classes.tableWrapper}>
					<ErrorBoundary>
						<Suspense fallback={<Skeleton h="70vh" w="100%" />}>
							<TableV2<IUser>
								pluralTypeString="users"
								withCheckbox={false}
								withInteractiveHeader={false}
								onRowClick={handleRowClick}
								defaultRequiredSearchParams={{
									owners_groups__in: userGroup.id,
								}}
								columns={[
									{
										accessor: 'display_name',
										title: 'Name',
										width: 200,
										render: (record) => (
											<BoldTextRender record={record} field="display_name" />
										),
									},
									{
										accessor: 'email',
										title: 'Email',
										width: 200,
									},
									{
										accessor: '_role',
										title: 'Role',
										width: 200,
									},
								]}
								usePaginationList={useUserList}
							/>
						</Suspense>
					</ErrorBoundary>
				</Box>
			</Tabs.Panel>
			<Tabs.Panel key={'resources'} value={'resources'} pt="xs">
				<Box className={classes.tableWrapper}>
					<ErrorBoundary>
						<Suspense fallback={<Skeleton h="70vh" w="100%" />}>
							<CatalogTable
								columnVisibility={{
									catalogType: EntityType.all,
									catalogServerType: 'secoda_entity' as CatalogServerType,
								}}
								defaultRequiredCatalogFilters={{
									operands: [
										{
											operands: [],
											field: 'owner_groups',
											operator: 'in',
											value: [userGroup.id],
										},
									],
								}}
							/>
						</Suspense>
					</ErrorBoundary>
				</Box>
			</Tabs.Panel>
		</Tabs>
	);
}

export default UserGroupProfileTabs;
