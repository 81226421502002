import { Container, Divider, Group, Stack } from '@mantine/core';
import { useNavigate } from '@repo/common/hooks/useNavigate';
import type { IMarketplaceIntegrationSpecVersion } from '@repo/common/models/marketplace';
import { Breadcrumbs, Button } from '@repo/foundations';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router';
import {
	useEditMarketplaceIntegrationSpecVersion,
	useMarketplaceIntegrationSpecVersion,
} from '../../api/hooks/marketplace';
import { FullWidthLoadingSpinner } from '../../components/LoadingSpinner';
import { MarketplaceIntegrationSpecVersionForm } from '../../components/MarketplaceIntegrationSpecVersionForm/MarketplaceIntegrationSpecVersionForm';
import { useMarketplaceIntegrationSpecVersionForm } from '../../components/MarketplaceIntegrationSpecVersionForm/MarketplaceIntegrationSpecVersionForm.hook';
import {
	NavBarBreadcrumbWrapper,
	NavBarWrapper,
} from '../../components/SecodaAppShell/SecodaNavBar';

function Content({ version }: { version: IMarketplaceIntegrationSpecVersion }) {
	const navigate = useNavigate();

	const { mutateAsync: apiEditVersion, isLoading: isUpdatingVersion } =
		useEditMarketplaceIntegrationSpecVersion();

	const form = useMarketplaceIntegrationSpecVersionForm(version, true);

	const submit = async () => {
		if (form.validate().hasErrors) {
			return;
		}

		const codeFile =
			typeof form.values.code_file === 'object' ? form.values.code_file : null;

		await apiEditVersion({
			versionId: version.id,
			specId: version.spec_id,
			codeFile,
			detail: {
				name: form.values.name,
				description: form.values.description,
				category: form.values.category,
				icon_url: form.values.icon_url as string,
				allowed_endpoints: form.values.allowed_endpoints.map(
					(endpoint) => endpoint.value
				),
				form_spec: form.values.form_fields.reduce(
					(acc, field) => ({
						...acc,
						[field.name]: {
							placeholder: field.placeholder,
							type: field.sensitive ? 'password' : 'text',
							required: field.required,
						},
					}),
					{}
				),
			},
		});

		navigate(`/marketplace-integration/${version.spec_id}`);
	};

	return (
		<Stack spacing={0}>
			<NavBarWrapper>
				<NavBarBreadcrumbWrapper>
					<Breadcrumbs
						crumbs={[
							{ title: 'Integrations', href: '/integrations' },
							{
								title: 'Integration marketplace',
								href: '/integrations/browse?tab=create',
							},
							{
								title: version.name,
								href: `/marketplace-integration/${version.spec_id}`,
							},
						]}
					/>
				</NavBarBreadcrumbWrapper>
			</NavBarWrapper>

			<Container size={640}>
				<Stack pb="xl" spacing="xl">
					<MarketplaceIntegrationSpecVersionForm
						form={form}
						existingVersion={version}
						disabled={version.publish_status !== 'DRAFT'}
					/>
					{version.publish_status === 'DRAFT' && (
						<>
							<Divider />
							<Group position="right">
								<Button
									onClick={submit}
									variant="primary"
									loading={isUpdatingVersion}
								>
									Save & exit
								</Button>
							</Group>
						</>
					)}
				</Stack>
			</Container>
		</Stack>
	);
}

function MarketplaceIntegrationSpecVersionDetailPage() {
	const { specId, versionId } = useParams<{
		specId: string;
		versionId: string;
	}>();

	const { data: version } = useMarketplaceIntegrationSpecVersion(
		specId as string,
		versionId as string
	);

	if (!version) {
		return <FullWidthLoadingSpinner />;
	}

	return <Content version={version} />;
}

export default observer(MarketplaceIntegrationSpecVersionDetailPage);
