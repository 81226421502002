import { forEach } from 'lodash-es';
import type { PLAN_FEATURE_CATEGORIES } from './constants';
import { PLAN_FEATURES, PLANS } from './constants';

export const getNumOfFeatureItems = () => {
	const maxItems: {
		[category: (typeof PLAN_FEATURE_CATEGORIES)[number]]: number;
	} = {};

	forEach(PLANS, (plan) => {
		forEach(PLAN_FEATURES[plan], (items, category) => {
			if (!maxItems[category] || items.length > maxItems[category]) {
				maxItems[category] = items.length;
			}
		});
	});

	return maxItems;
};
