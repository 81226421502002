/* eslint-disable */

// Source: https://github.com/observablehq/plot/blob/09edfec1f26da02eeeb451f008dd2eb9fb8d8ffc/src/style.js#L451
export function applyFrameAnchor(
	{ frameAnchor },
	{ width, height, marginTop, marginRight, marginBottom, marginLeft }
) {
	return [
		/left$/.test(frameAnchor)
			? marginLeft
			: /right$/.test(frameAnchor)
				? width - marginRight
				: (marginLeft + width - marginRight) / 2,
		/^top/.test(frameAnchor)
			? marginTop
			: /^bottom/.test(frameAnchor)
				? height - marginBottom
				: (marginTop + height - marginBottom) / 2,
	];
}

export function anchorX({ x1: X1, x2: X2, x: X = X1 }, cx) {
	return X1 && X2 ? (i) => (X1[i] + X2[i]) / 2 : X ? (i) => X[i] : () => cx;
}

export function anchorY({ y1: Y1, y2: Y2, y: Y = Y1 }, cy) {
	return Y1 && Y2 ? (i) => (Y1[i] + Y2[i]) / 2 : Y ? (i) => Y[i] : () => cy;
}
