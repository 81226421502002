import { Button } from '@repo/foundations';

export function RunAction({
	handleExecute,
	disabled,
}: {
	handleExecute: () => void;
	disabled: boolean;
}) {
	return (
		<Button
			variant="primary"
			loading={disabled}
			onClick={handleExecute}
			size="lg"
			leftIconName="playerPlayFilled"
		>
			Run
		</Button>
	);
}
