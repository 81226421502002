/* eslint-disable react/display-name */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-key */
/* eslint-disable react/no-unstable-nested-components */
import { Avatar, createStyles, Group, Select, Stack } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { Button, Icon, Text, Title } from '@repo/foundations';
import { observer } from 'mobx-react-lite';
import { forwardRef, useEffect, useMemo, useState } from 'react';
import { parse } from 'yaml';
import { useIntegrationList } from '../../api';
import { Integration } from '../../lib/models';
import { vectorGraphicFromIntegrationType } from '../../utils/integrationLogo';

interface IIntegrationMappingProps {
	integration: Integration;
	initialSetup?: boolean;
	onFinish?: VoidFunction;
}

export interface ItemProps {
	id: string;
	label: string;
	value: string;
	type: string;
}

const useStyles = createStyles({
	integrationStack: {
		maxHeight: 500,
	},
});

function IntegrationMapping({
	integration,
	initialSetup,
	onFinish,
}: IIntegrationMappingProps) {
	const { data: integrations } = useIntegrationList({});

	const [mappings, setMappings] = useState<Record<string, string>>({});

	const { classes } = useStyles();

	useEffect(() => {
		if (!initialSetup && integration.properties?.mappings) {
			const parsedMappings = {};
			// @ts-expect-error TS(7006): Parameter 'mapping' implicitly has an 'any' type.
			integration.properties.mappings.forEach((mapping) => {
				// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
				parsedMappings[mapping.datasource] = mapping.integration_id;
			});

			setMappings(parsedMappings);
		}
	}, [integration.id]);

	if (
		!integration.credentials.configuration_file &&
		!integration.credentials.s3_configuration_file &&
		!integration.credentials.role_configuration_file
	) {
		return null;
	}

	// eslint-disable-next-line react-hooks/rules-of-hooks
	const configuration = useMemo(() => {
		try {
			if ('configuration_file' in integration.credentials) {
				return parse(integration.credentials.configuration_file);
			}
			if ('s3_configuration_file' in integration.credentials) {
				return parse(integration.credentials.s3_configuration_file);
			}
			return parse(integration.credentials.role_configuration_file);
		} catch (e) {
			showNotification({
				id: integration.id,
				title: 'Configuration File Error',
				message:
					'Please check your configuration file for Great Expectations. It is not valid YAML.',
				color: 'red',
				icon: <Icon name="x" />,
			});
		}

		return {};
	}, [
		integration.credentials?.configuration_file,
		integration.credentials?.s3_configuration_file,
		integration.credentials?.role_configuration_file,
	]);

	const datasources =
		'datasources' in configuration
			? Object.keys(configuration.datasources)
			: [];

	const IntegrationMappingItem = forwardRef<HTMLDivElement, ItemProps>(
		({ id, label, value, type, ...others }: ItemProps, ref) => (
			<div ref={ref} {...others}>
				<Group noWrap>
					<Avatar size="xs" src={vectorGraphicFromIntegrationType(type)} />
					<div>
						<Text size="sm">{label}</Text>
						<Text size="xs">{type}</Text>
					</div>
				</Group>
			</div>
		)
	);

	const handleOnMappingSubmit = async () => {
		// @ts-expect-error TS(2345): Argument of type '{ id: string; }' is not assignab... Remove this comment to see the full error message
		const currentIntegration = await new Integration({
			id: integration.id,
		}).sync();
		currentIntegration.properties = {
			mappings: Object.keys(mappings).map((datasource) => ({
				integration_id: mappings[datasource],
				datasource,
			})),
		};

		currentIntegration
			.save(['properties'])
			.then(() => {
				showNotification({
					title: 'Mapped Datasources',
					message: 'Run a sync to map expectations to tables',
					color: 'green',
				});
			})
			.catch(() => {
				showNotification({
					message: 'Could not map datasources',
					color: 'red',
				});
			});

		if (initialSetup) {
			onFinish?.();
		}
	};

	const handleOnItemChange = (id: string, datasource: string) => {
		setMappings({ ...mappings, [datasource]: id });
	};

	return (
		<Stack spacing={0}>
			{initialSetup && (
				<Stack spacing={0} mb={48}>
					<Title order={2}>Mapping</Title>
					<Text color="text/secondary/default">
						Please map each Great Expectations datasource to an existing
						integration
					</Text>
				</Stack>
			)}
			<Group position="apart" mb={12} grow>
				<Title order={5}>Datasource</Title>
				<Title order={5}>Integration</Title>
			</Group>
			<Stack className={classes.integrationStack} mb={24}>
				{datasources.map((datasource) => (
					<Group position="apart" grow>
						<Text size="sm">{datasource}</Text>
						<Select
							placeholder="Select an integration"
							size="xs"
							data={
								integrations?.results
									?.filter((i) => i.type !== 'great_expectations')
									.map((i) => ({
										id: i.id,
										label: i.name,
										value: i.id,
										type: i.type,
									})) || []
							}
							value={mappings[datasource]}
							onChange={(value: string) =>
								handleOnItemChange(value, datasource)
							}
							itemComponent={IntegrationMappingItem}
						/>
					</Group>
				))}
			</Stack>
			<Group position="left">
				<Button size="sm" onClick={handleOnMappingSubmit}>
					Submit
				</Button>
			</Group>
		</Stack>
	);
}

export default observer(IntegrationMapping);
