import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
	root: {
		flexGrow: 1,
		height: '100%',
		paddingBottom: theme.spacing.sm,
	},
	emptyStateContainer: {
		flexGrow: 1,
		display: 'flex',
	},
	emptyState: {
		padding: 0,
		paddingBottom: theme.other.space[16],
	},
	grid: {
		gridAutoRows: '1fr',
	},
	promptSample: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		backgroundColor: theme.other.getColor('fill/secondary/default'),
		padding: `${theme.spacing.sm} ${theme.spacing.md}`,
		borderRadius: theme.radius.md,
		alignItems: 'flex-start',
		wordBreak: 'break-word',
		'&:hover': {
			backgroundColor: theme.other.getColor('fill/secondary/hover'),
		},
		'&:active': {
			backgroundColor: theme.other.getColor('fill/secondary/active'),
		},
	},
	carouselContainer: {
		display: 'flex',
		alignItems: 'center',
	},
	carouselSlide: {
		flexGrow: 0,
		flexShrink: 0,
		flexBasis: 'auto',
	},
	carouselSlideButton: {
		backgroundColor: theme.other.getColor('fill/secondary/default'),
		borderRadius: theme.radius.sm,
		padding: `${theme.spacing['2xs']} ${theme.spacing.sm}`,
		'&:hover': {
			backgroundColor: theme.other.getColor('fill/secondary/hover'),
		},
		'&:active': {
			backgroundColor: theme.other.getColor('fill/secondary/active'),
		},
	},
}));
