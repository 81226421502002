import type { typography } from '@repo/theme/primitives';

export const SIZE_CONFIG = {
	sm: 100,
	md: 128,
	lg: 192,
} as const;

export type SizeOptions = keyof typeof SIZE_CONFIG;

export const LABEL_SIZES: Record<
	SizeOptions,
	{ score: keyof typeof typography.text; percent: keyof typeof typography.text }
> = {
	sm: {
		score: 'lg',
		percent: 'md',
	},
	md: {
		score: 'xl',
		percent: 'lg',
	},
	lg: {
		score: '3xl',
		percent: 'xxl',
	},
} as const;
