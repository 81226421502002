import { Plugin } from 'prosemirror-state';
import Extension from '../lib/Extension';

export default class SpellCheck extends Extension {
	get name() {
		return 'spellcheck';
	}

	get plugins() {
		return [
			new Plugin({
				props: {
					handleDOMEvents: {
						focus: (view) => {
							view.dom.spellcheck = true;
							return false;
						},
						blur: (view) => {
							view.dom.spellcheck = false;
							return false;
						},
					},
				},
			}),
		];
	}
}
