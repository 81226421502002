import { showNotification } from '@mantine/notifications';
import axios from 'axios';
import { api } from '../../../network';
import { authHeaders } from '../../api/common';
import { v4 } from '../../utils/uuid/v4';
import { uploadImagePrivate } from '../ImageUpload/ImageUpload.helpers';

async function putFile(file: File, forAI?: boolean) {
	const id = v4();
	const ai_path_prefix = forAI ? 'ai/' : '';
	const presign = await axios.get(
		`${api()}${ai_path_prefix}file/presign_upload/?path=attachments&unique_file_key=${id}`,
		authHeaders()
	);
	const formFields: Record<string, string> = presign.data.fields;
	const { url } = presign.data;
	const formData = new FormData();

	Object.entries(formFields).forEach(([key, value]) => {
		formData.append(key, value);
	});
	// File field must go last.
	// `https://stackoverflow.com/questions/15234496/upload-directly-to-amazon-s3-using-ajax-returning-error-bucket-post-must-contai`
	formData.append('file', file);
	await axios.post(url, formData);
	return id;
}

export const uploadFile = async (
	file: File,
	isImage: boolean,
	forAI?: boolean
) => {
	if (file.size < 30e6) {
		if (isImage) {
			const result = await uploadImagePrivate(file);
			return result;
		}
		const id = await putFile(file, forAI);
		const ai_path_prefix = forAI ? 'ai/' : '';
		const regex = /[\s,:;/\\]+/g;
		// We must be careful not to remove this endpoint. The regex replaces brackets because it breaks the markdown parser.
		const path = `${ai_path_prefix}file/presign_download/?path=attachments&unique_file_key=${id}&original_name=${file.name.replace(
			regex,
			''
		)}`;
		return `/download?path=${btoa(path)}`;
	}

	showNotification({ message: 'Error! The maximum file size is 10 MB...' });
	throw new Error('maximum file size is 10 MB');
};
