import type { ApiContext } from "./apiContext";
import { apiWithoutTrailSlash } from "../utils";

const defaultBaseURL = apiWithoutTrailSlash();

export type ErrorWrapper<TError> =
	| TError
	| { status: "unknown"; detail: string };

export type ApiFetcherOptions<TBody, THeaders, TQueryParams, TPathParams> = {
	url: string;
	method: string;
	body?: TBody;
	headers?: THeaders;
	queryParams?: TQueryParams;
	pathParams?: TPathParams;
	signal?: AbortSignal;
} & ApiContext["fetcherOptions"];

export async function apiFetch<
	TData,
	TError,
	TBody extends {} | FormData | undefined | null,
	THeaders extends {},
	TQueryParams extends {},
	TPathParams extends {},
>({
	url,
	method,
	body,
	headers,
	pathParams,
	queryParams,
	signal,
	baseURL,
}: ApiFetcherOptions<
	TBody,
	THeaders,
	TQueryParams,
	TPathParams
>): Promise<TData> {
	const requestHeaders: HeadersInit = {
		"Content-Type": "application/json",
		...headers,
	};
	/**
	 * As the fetch API is being used, when multipart/form-data is specified
	 * the Content-Type header must be deleted so that the browser can set
	 * the correct boundary.
	 * https://developer.mozilla.org/en-US/docs/Web/API/FormData/Using_FormData_Objects#sending_files_using_a_formdata_object
	 */
	if (
		requestHeaders["Content-Type"].toLowerCase().includes("multipart/form-data")
	) {
		delete requestHeaders["Content-Type"];
	}

	const response = await window.fetch(
		`${baseURL || defaultBaseURL}${resolveUrl(url, queryParams, pathParams)}`,
		{
			signal,
			method: method.toUpperCase(),
			body: body
				? body instanceof FormData
					? body
					: JSON.stringify(body)
				: undefined,
			headers: requestHeaders,
		},
	);
	if (!response.ok) {
		let error: ErrorWrapper<TError>;
		try {
			error = await response.json();
		} catch (e) {
			error = {
				status: "unknown" as const,
				detail:
					e instanceof Error
						? `Unexpected error (${e.message})`
						: "Unexpected error",
			};
		}

		throw error;
	}

	if (response.headers.get("content-type")?.includes("json")) {
		// The status is ok but in somecase the endpoint returns e.g, (204, None) which
		// translate to empty response. In this case we return null
		try {
			return await response.json();
		} catch (e) {
			return null as TData;
		}
	} else {
		// if it is not a json response, assume it is a blob and cast it to TData
		return (await response.blob()) as unknown as TData;
	}
}

const resolveUrl = (
	url: string,
	queryParams: Record<string, string> = {},
	pathParams: Record<string, string> = {},
) => {
	let query = new URLSearchParams(queryParams).toString();
	if (query) query = `?${query}`;
	return url.replace(/\{\w*\}/g, (key) => pathParams[key.slice(1, -1)]) + query;
};
