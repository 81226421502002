import { createStyles } from '@mantine/core';

export const useSpecCardStyles = createStyles((theme) => ({
	card: {
		height: '100%',
		padding: `${theme.spacing.sm} !important`,
		boxShadow: `0px 0px 0px 1px ${theme.colors.gray[3]} inset`,
		border: 'none',
		transition: '0.2s all',
		'&:hover': {
			backgroundColor: theme.other.getColor('surface/primary/hover'),
			boxShadow: `0px 0px 0px 1px ${theme.colors.gray[3]} inset`,
		},
	},
	logoWrapper: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: theme.other.space[7],
		height: theme.other.space[7],
		padding: theme.spacing['3xs'],
		backgroundColor: theme.other.getColor('fill/secondary/default'),
		borderRadius: theme.radius.sm,
	},
}));
