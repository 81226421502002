import type MarkdownIt from 'markdown-it';
import Token from 'markdown-it/lib/token';
import { isResourceUrl } from '../utils/urls';

function isInline(token: Token) {
	return token.type === 'inline';
}

function isLinkOpen(token: Token) {
	return token.type === 'link_open';
}

export function isResourceLink(token: Token) {
	if (!isLinkOpen(token)) {
		return false;
	}

	const href = token.attrGet('href');

	return isResourceUrl(href);
}

export function resourceLinksRule(md: MarkdownIt) {
	md.core.ruler.after('inline', 'resource_link', (state) => {
		const { tokens } = state;

		for (let i = 0; i < tokens.length - 1; i += 1) {
			// Once we find an inline token look through it's children for links
			if (isInline(tokens[i])) {
				const tokenChildren = tokens[i].children || [];

				for (let j = 0; j < tokenChildren.length - 1; j += 1) {
					const current = tokenChildren[j];
					if (!current) {
						// eslint-disable-next-line no-continue
						continue;
					}

					const resourceLink = isResourceLink(current);

					if (resourceLink) {
						const nodes: Token[] = [];

						const tokenOpen = new Token('resource_link_open', '', 1);
						tokenOpen.attrSet('href', resourceLink);
						nodes.push(tokenOpen);

						const text = new Token('text', '', 0);
						text.content = tokenChildren[j + 1].content;
						nodes.push(text);

						const tokenClose = new Token('resource_link_close', '', -1);
						nodes.push(tokenClose);

						tokenChildren.splice(j, 3, ...nodes);

						j += 2;
					}
				}
			}
		}

		return false;
	});
}
