import * as React from 'react';
import type { EmbedProps as Props } from '.';
import Frame from './components/Frame';

const URL_REGEX = new RegExp(
	'https://airtable.com/(?:embed/)?(app[A-Za-z0-9]+)?/?(shr.*)$'
);

export default class Airtable extends React.Component<Props> {
	static ENABLED = [URL_REGEX];

	render() {
		const { matches } = this.props.attrs;
		const appId = matches[1];
		const shareId = matches[2];
		return (
			<Frame
				{...this.props}
				src={
					appId
						? `https://airtable.com/embed/${appId}/${shareId}`
						: `https://airtable.com/embed/${shareId}`
				}
				title={`Airtable (${shareId})`}
				border
			/>
		);
	}
}
