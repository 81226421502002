import type { IComment } from '../../../api';

/** Fuzzily determines if a thread contains the relevant search term */
export const matchSearchTerm = (
	searchTerm: string,
	comments: IComment[]
): boolean => {
	if (!searchTerm) {
		return true;
	}
	let matched = false;
	comments.forEach((comment) => {
		if (
			comment.quoted_text?.toLowerCase().includes(searchTerm.toLowerCase()) ||
			comment.definition?.toLowerCase().includes(searchTerm.toLowerCase())
		) {
			matched = true;
		}
	});
	return matched;
};
