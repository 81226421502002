import { space } from '@repo/theme/primitives';
import type { IWidgetSample } from '../../api';
import { WidgetType } from '../../interfaces';

export const HOMEPAGE_MAX_WIDTH = space[200];
export const HOMEPAGE_PADDING = space[16];

export const HOMEPAGE_WIDGET_TYPES = [
	WidgetType.INTEGRATIONS,
	WidgetType.TEXT_BLOCK,
	WidgetType.DICTIONARY_TERM_CHART,
	WidgetType.INBOX,
	WidgetType.ANNOUNCEMENTS,
	WidgetType.BOOKMARKED_RESOURCES,
	WidgetType.POPULAR_RESOURCES,
	WidgetType.RECENTLY_EDITED_RESOURCES,
	WidgetType.RECENTLY_ACCESSED_RESOURCES,
	WidgetType.RECENT_SEARCHES,
	WidgetType.DATA_QUALITY_SCORE,
];

export const HOMEPAGE_ALLOWED_WIDGETS_TO_ADD: IWidgetSample[] = [
	...[
		WidgetType.BOOKMARKED_RESOURCES,
		WidgetType.PINNED_COLLECTIONS,
		WidgetType.COLLECTION_RESOURCES,
		// TODO(Carter): Disabled as dictionary term queries are removed from the
		// app. This may be re-added as Metrics.
		// WidgetType.DICTIONARY_TERM_CHART,
		WidgetType.INBOX,
		WidgetType.INTEGRATIONS,
		WidgetType.RECENT_SEARCHES,
		WidgetType.ANNOUNCEMENTS,
		WidgetType.HELPFUL_LINKS,
		WidgetType.QUESTIONS_POPULAR,
	].map((type) => ({ type })),
	{
		type: WidgetType.TEXT_BLOCK,
		color: 'white',
		title: 'Private notepad',
	},
];

export const TEAM_HOMEPAGE_ALLOWED_WIDGETS_TO_ADD: IWidgetSample[] = [
	...[
		WidgetType.BOOKMARKED_RESOURCES,
		WidgetType.PINNED_COLLECTIONS,
		WidgetType.COLLECTION_RESOURCES,
		// TODO(Carter): Disabled as dictionary term queries are removed from the
		// app. This may be re-added as Metrics.
		// WidgetType.DICTIONARY_TERM_CHART,
		WidgetType.INTEGRATIONS,
	].map((type) => ({ type })),
	{
		type: WidgetType.TEXT_BLOCK,
		color: 'white',
		title: 'Notepad',
	},
];
