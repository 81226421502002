import type { Monitor } from '../../../api';
import ErrorDrawer from '../../../components/ErrorDrawer/ErrorDrawer';

interface IMonitorErrorDrawerProps {
	monitor?: Monitor;
	onClose: () => void;
	open: boolean;
}

function MonitorErrorDrawer({
	monitor,
	open,
	onClose,
}: IMonitorErrorDrawerProps) {
	return (
		<ErrorDrawer
			open={open}
			onClose={onClose}
			errorAt={monitor?.last_error_at ?? ''}
			errorMessage={monitor?.last_error_message ?? ''}
		/>
	);
}

export default MonitorErrorDrawer;
