import type { MantineTheme } from '@mantine/core';
import { Box, createStyles, Mark } from '@mantine/core';
import { Prism } from '@mantine/prism';
import { size } from 'lodash-es';
import type { Language } from 'prism-react-renderer';
import 'property-information';
import { forwardRef } from 'react';
import ReactMarkdown from 'react-markdown';
import type {
	CodeProps,
	ComponentPropsWithoutRef,
	ReactMarkdownProps,
	SpecialComponents,
} from 'react-markdown/lib/ast-to-react';
import type { NormalComponents } from 'react-markdown/lib/complex-types';
import { remarkHeadingId } from 'remark-custom-heading-id';
import remarkGfm from 'remark-gfm';
import { sanitizeMarkdownString } from '../../utils';

interface IStyleParams {
	inline?: boolean;
	empty?: boolean;
	addPadding?: boolean;
	fontSize?: string;
}

type MarkProps = ComponentPropsWithoutRef<'del'> & ReactMarkdownProps;

export interface IMarkdownRendererProps
	extends Exclude<IStyleParams, 'addPadding'> {
	children: string;
	className?: string;
	fontSize?: string;
	lineClamp?: number;
	highlight?: boolean;
}

const useStyles = createStyles(
	(
		theme: MantineTheme,
		{ inline = false, empty = false, fontSize = '14px' }: IStyleParams
	) => {
		let color = theme.other.getColor('text/primary/default');
		let backgroundColor = `${theme.colors.gray[1]} !important`;
		let padding = `${theme.spacing.md} !important`;
		const border = 'none';

		if (inline) {
			padding = '0px !important';
		}

		if (empty) {
			color = theme.other.getColor('text/secondary/default');
		}

		return {
			wrapper: {
				fontSize: `${fontSize} !important`,
				color: `${color} !important`,
				'*': {
					margin: inline ? '0px !important' : 'auto',
					color,
				},
			},
			reactMarkdown: {
				pre: {
					border,
					padding,
					backgroundColor,
					':has(> .mantine-Prism-root)': {
						padding: 0,
						backgroundColor: 'transparent !important',
					},
				},
				'.mantine-Prism-code': {
					backgroundColor,
				},
			},
		};
	}
);

const code = ({ className, children, ...props }: CodeProps) => {
	const match = /language-(\w+)/.exec(className || '');
	return match ? (
		<Prism {...props} language={match[1] as Language}>
			{String(children).replace(/\n$/, '')}
		</Prism>
	) : (
		<code {...props} className={className}>
			{children}
		</code>
	);
};

const mark = ({ className, children }: MarkProps) => (
	<Mark className={className}>{String(children)}</Mark>
);

const inLineComponents: Partial<
	Omit<NormalComponents, keyof SpecialComponents> & SpecialComponents
> = {
	h1: 'b',
	h2: 'b',
	h3: 'b',
	h4: 'b',
	h5: 'b',
	h6: 'b',
	code,
};

const nonInLineComponents: Partial<
	Omit<NormalComponents, keyof SpecialComponents> & SpecialComponents
> = {
	code,
};

const MarkdownRenderer = forwardRef<HTMLDivElement, IMarkdownRendererProps>(
	(
		{
			children,
			className,
			inline = false,
			empty = false,
			fontSize = '14px',
			lineClamp = 2,
			highlight = false,
		},
		ref
	) => {
		const { classes, cx } = useStyles({ inline, empty, fontSize });

		// Sometimes there are corruption bugs where the markdown is not a string.
		if (typeof children !== 'string') {
			return null;
		}

		const sanitizedValue = sanitizeMarkdownString(children);

		const valueWithoutLineBreaks = sanitizedValue
			?.split('\n')
			.filter((line) => line.replace('\\', ''));

		let cleanedValue = valueWithoutLineBreaks
			.slice(0, lineClamp)
			.join('\n\n')
			// remove custom component Table of Contents from inline markdown
			.replace('[toc]\n\n', '');

		if (size(valueWithoutLineBreaks) > lineClamp) {
			cleanedValue += '...';
		}

		const value = inline ? cleanedValue : sanitizedValue;

		let components = inline ? inLineComponents : nonInLineComponents;

		if (highlight) {
			components = { ...components, del: mark };
		}

		return (
			<Box ref={ref} className={cx(classes.wrapper, 'markdown-body')}>
				<ReactMarkdown
					className={cx(className, classes.reactMarkdown)}
					components={components}
					remarkPlugins={[remarkGfm, remarkHeadingId]}
					skipHtml
				>
					{value}
				</ReactMarkdown>
			</Box>
		);
	}
);
MarkdownRenderer.displayName = 'MarkdownRenderer';

export default MarkdownRenderer;
