import type { AutomationPropagateMetadataRelationship } from '../../../../../api/types/models/automation/automationPropagateMetadata';
import {
	AUTOMATION_PROPAGATE_METADATA_LEVELS,
	AUTOMATION_PROPAGATE_METADATA_RELATIONSHIP,
} from './constants';

export const getLevelLabel = (
	level: string | undefined
): string | undefined => {
	const index = AUTOMATION_PROPAGATE_METADATA_LEVELS.findIndex(
		(levels) => levels.value === level
	);

	if (index >= 0) {
		const { label } = AUTOMATION_PROPAGATE_METADATA_LEVELS[index];
		return label;
	}

	return undefined;
};

export const getRelationshipLabel = (
	relationship: AutomationPropagateMetadataRelationship | undefined
): string | undefined => {
	const index = AUTOMATION_PROPAGATE_METADATA_RELATIONSHIP.findIndex(
		(levels) => levels.value === relationship
	);

	if (index >= 0) {
		const { label } = AUTOMATION_PROPAGATE_METADATA_RELATIONSHIP[index];
		return label;
	}

	return undefined;
};
