import { ActionIcon, Group, Stack, createStyles } from '@mantine/core';
import { Icon, Text } from '@repo/foundations';
import type { ForwardedRef } from 'react';
import { forwardRef, useState } from 'react';
import type {
	IEmbeddedPrompt,
	IEmbeddedPromptResponseStep,
} from '../../../api';
import { AIStepModal } from './AIStepModal';

const STEPS_TO_IGNORE_MODAL = ['search_resources'];

const useStyles = createStyles((theme) => ({
	wrapper: {
		marginTop: theme.spacing.xs,
		backgroundColor: theme.other.getColor('surface/secondary/default'),
		borderRadius: theme.radius.md,
		padding: `${theme.spacing.sm} ${theme.spacing.md}`,
		gap: 0,
	},
	stepTitle: {
		fontFamily: theme.fontFamilyMonospace,
		fontSize: theme.fontSizes.xs,
	},
	actionIcon: {
		fontWeight: theme.other.typography.weight.bold,
	},
}));

function canShowModal(step: IEmbeddedPromptResponseStep) {
	return !STEPS_TO_IGNORE_MODAL.some(
		(ignore) => step.functionCall?.includes(ignore) ?? false
	);
}

export interface AIStepsProps {
	prompt: IEmbeddedPrompt;
}

function AIStepsInternal(
	{ prompt }: AIStepsProps,
	ref: ForwardedRef<HTMLDivElement>
) {
	const { classes } = useStyles();
	const [stepInModal, setStepInModal] =
		useState<IEmbeddedPromptResponseStep | null>(null);

	const handleOpenModal = (stepToOpen: IEmbeddedPromptResponseStep) => () =>
		setStepInModal(stepToOpen);

	const handleCloseModal = () => setStepInModal(null);

	return (
		<>
			<Stack className={classes.wrapper} ref={ref}>
				{prompt.response?.steps?.map((step, index) => (
					// eslint-disable-next-line react/no-array-index-key
					<Group key={index} spacing="3xs">
						<Text className={classes.stepTitle}>
							{index + 1}. {step.title}
						</Text>
						{canShowModal(step) && (
							<ActionIcon
								className={classes.actionIcon}
								onClick={handleOpenModal(step)}
							>
								<Icon name="code" />
							</ActionIcon>
						)}
					</Group>
				))}
			</Stack>
			{stepInModal && (
				<AIStepModal step={stepInModal} onClose={handleCloseModal} />
			)}
		</>
	);
}

export const AISteps = forwardRef(AIStepsInternal);
