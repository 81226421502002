import { Box, Divider, Group, Progress, Stack } from '@mantine/core';
import type { UseFormReturnType } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import { Prism } from '@mantine/prism';
import type { IMarketplaceIntegrationSpecVersion } from '@repo/common/models/marketplace';
import { Button, Text } from '@repo/foundations';
import { useState } from 'react';
import { useMarketplaceIntegrationSpecVersionCode } from '../../api/hooks/marketplace';
import { uploadImagePublic } from '../ImageUpload/ImageUpload.helpers';
import { ScrollableModal } from '../ScrollableModal/ScrollableModal';
import { AllowedEndpointsSection } from './AllowedEndpointsSection';
import { BasicInformationSection } from './BasicInformationSection';
import { CodeSection } from './CodeSection';
import { FieldsSection } from './FieldsSection';
import type { MarketplaceIntegrationSpecVersionFormValues } from './MarketplaceIntegrationSpecVersionForm.hook';

export function MarketplaceIntegrationSpecVersionForm({
	form,
	existingVersion,
	disabled = false,
	inSteps = false,
	finalStepSubmit,
	finalStepButtonLabel,
}: {
	form: UseFormReturnType<MarketplaceIntegrationSpecVersionFormValues>;
	existingVersion?: IMarketplaceIntegrationSpecVersion;
	disabled?: boolean;
	inSteps?: boolean;
	finalStepButtonLabel?: string;
	finalStepSubmit?: VoidFunction;
}) {
	const [
		codePreviewModalOpened,
		{ open: openCodePreviewModal, close: closeCodePreviewModal },
	] = useDisclosure(false);
	const [codeContent, setCodeContent] = useState<string>('');

	const [isUploadingImage, setIsUploadingImage] = useState(false);
	const { data: existingCodeContent } =
		useMarketplaceIntegrationSpecVersionCode(
			existingVersion?.spec_id || '',
			existingVersion?.id || '',
			{
				enabled: Boolean(existingVersion),
			}
		);

	const [step, setStep] = useState(0);
	const finalStep = 3;

	const onDropImageFile = async (files: File[]) => {
		setIsUploadingImage(true);
		const result = await uploadImagePublic(files[0]);
		form.setFieldValue('icon_url', result);
		setIsUploadingImage(false);
	};

	const onDropPythonFile = async (files: File[]) => {
		const file = files[0];
		if (file.name.endsWith('.py')) {
			form.setFieldValue('code_file', file);
		}
	};

	const onRemovePythonFile = () => {
		form.setFieldValue('code_file', null);
	};

	const onPreviewCode = async () => {
		const file = form.values.code_file;
		if (file && typeof file === 'object') {
			const content = await file.text();
			setCodeContent(content);
			openCodePreviewModal();
		} else {
			setCodeContent(existingCodeContent || '');
			openCodePreviewModal();
		}
	};

	const renderNonSteps = () => (
		<>
			<BasicInformationSection
				form={form}
				disabled={disabled}
				isUploadingImage={isUploadingImage}
				onDropImageFile={onDropImageFile}
				inSteps={inSteps}
			/>
			<Divider />
			<CodeSection
				form={form}
				disabled={disabled}
				existingVersion={existingVersion}
				onDropPythonFile={onDropPythonFile}
				onPreviewCode={onPreviewCode}
				onRemovePythonFile={onRemovePythonFile}
				inSteps={inSteps}
			/>
			<Divider />
			<FieldsSection form={form} disabled={disabled} />
			<Divider />
			<AllowedEndpointsSection form={form} disabled={disabled} />
		</>
	);

	const stepFields: Record<number, string[]> = {
		0: ['icon_url', 'name', 'description', 'category'],
		1: ['code_file'],
		2: ['form_fields'],
		3: ['allowed_endpoints'],
	};

	const nextStep = async () => {
		const validateResult = form.validate();
		if (
			!stepFields[step].some((name) =>
				Object.keys(validateResult.errors).some((key) => key.startsWith(name))
			)
		) {
			setStep(step + 1);
		}
	};

	const prevStep = () => {
		setStep(step - 1);
	};

	const renderSteps = () => (
		<>
			<Progress color="black" value={((step + 1) / (finalStep + 1)) * 100} />
			{step === 0 && (
				<BasicInformationSection
					form={form}
					disabled={disabled}
					isUploadingImage={isUploadingImage}
					onDropImageFile={onDropImageFile}
					inSteps={inSteps}
				/>
			)}
			{step === 1 && (
				<CodeSection
					form={form}
					disabled={disabled}
					existingVersion={existingVersion}
					onDropPythonFile={onDropPythonFile}
					onPreviewCode={onPreviewCode}
					onRemovePythonFile={onRemovePythonFile}
					inSteps={inSteps}
				/>
			)}
			{step === 2 && <FieldsSection form={form} disabled={disabled} />}
			{step === 3 && (
				<AllowedEndpointsSection form={form} disabled={disabled} />
			)}
			<Group position="apart">
				{step !== 0 ? (
					<Button
						onClick={prevStep}
						variant="tertiary"
						leftIconName="chevronLeft"
					>
						<Text size="sm">Back</Text>
					</Button>
				) : (
					<Box />
				)}
				{step !== finalStep ? (
					<Button size="md" onClick={nextStep} variant="primary">
						Next
					</Button>
				) : (
					<Button
						size="md"
						onClick={() => finalStepSubmit?.()}
						variant="primary"
					>
						{finalStepButtonLabel}
					</Button>
				)}
			</Group>
		</>
	);

	return (
		<Stack spacing="xl">
			<ScrollableModal
				size={720}
				title="Preview"
				opened={codePreviewModalOpened}
				onClose={closeCodePreviewModal}
			>
				<Prism language="python">{codeContent}</Prism>
			</ScrollableModal>
			{inSteps ? renderSteps() : renderNonSteps()}
		</Stack>
	);
}
