import { useReducer } from 'react';

/**
 * CAUTION: Do not use this hook until it's absolutely necessary.
 * Please contact @vinothpandian before using this hook.
 *
 * A hook to force the component to rerender
 *
 * @returns A key to force the component to rerender and a function to force the rerender
 */
export const useForceReRender = () => {
	const [key, forceUpdate] = useReducer((x) => x + 1, 0);

	return {
		key,
		forceUpdate,
	};
};
