import { Box, createStyles, Divider, Table } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useApiListApiKeys } from '@repo/api-codegen';
import { Button, Text } from '@repo/foundations';
import { useAuthUser } from '../../../api';
import { useFeatureAccess } from '../../../api/hooks/workspace/useFeatureAccess';
import { usePlan } from '../../../hooks/usePlans';
import { EmptyState } from '../../EmptyState';
import { LoadingSpinner } from '../../LoadingSpinner';
import { SettingsTableEmpty } from '../Empty';
import { Setting } from '../Setting';
import { UpgradeButton } from '../UpgradeButton';
import { ApiFiltersGenerator } from './ApiFiltersGenerator/ApiFiltersGenerator';
import { APITableRow } from './APITableRow';
import { CreateAPIKeyModal } from './CreateAPIKeyModal';

const useStyles = createStyles(() => ({
	table: {
		tableLayout: 'fixed',
	},
}));

export function APISettings() {
	const { classes, theme } = useStyles();

	const [isCreateKeyModalOpened, { open, close }] = useDisclosure(false);
	const { plan } = usePlan();
	const { isEditorOrAdminUser } = useAuthUser();
	// Get feature access
	const { apiAccess } = useFeatureAccess();
	const { data: keys, isLoading } = useApiListApiKeys({});

	if (isLoading) {
		return <LoadingSpinner />;
	}

	if (!apiAccess) {
		return (
			<EmptyState
				title="Upgrade to access the API"
				description="You can use an API to programatically access information in Secoda."
				illustrationName="upgrade"
				includeGoBack={false}
				stateHeight="50vh"
				size="lg"
				withActions={<UpgradeButton feature="API" size="md" />}
			/>
		);
	}

	return (
		<Box>
			<Setting
				title="API keys"
				description="You can use an API key to programatically access Secoda."
			>
				<Box style={{ position: 'relative' }}>
					{plan?.api_support ? (
						<Button
							variant="primary"
							size="md"
							onClick={open}
							disabled={!isEditorOrAdminUser}
						>
							Create key
						</Button>
					) : (
						<UpgradeButton feature="API" />
					)}
				</Box>
			</Setting>
			{keys?.results.length === 0 && (
				<SettingsTableEmpty
					title="No keys found"
					description="Create an API key to get started"
					height={120}
				/>
			)}
			{keys?.results.length !== 0 && (
				<Table className={classes.table} striped>
					<thead>
						<tr>
							<th style={{ width: '50%' }}>
								<Text size="sm">Name</Text>
							</th>
							<th style={{ width: '20%' }}>
								<Text size="sm">Last used</Text>
							</th>
							<th style={{ width: '15%' }}>
								<Text size="sm">Last used IP</Text>
							</th>
							<th style={{ width: '10%' }}>
								<Text size="sm">Usage</Text>
							</th>
							<th aria-label="Revoke token" />
						</tr>
					</thead>
					<tbody>
						{keys?.results.map((key) => (
							<APITableRow
								key={key.id}
								apiKey={key}
								isEditorOrAdminUser={isEditorOrAdminUser}
							/>
						))}
					</tbody>
				</Table>
			)}
			<CreateAPIKeyModal opened={isCreateKeyModalOpened} onClose={close} />
			<Divider mb={theme.spacing.lg} />
			<Setting
				title="API Filters Generator"
				description="Build filters and export to JSON for the Resource API endpoint."
				callToAction={
					<a
						href="https://api.secoda.co/api/schema/redoc/#tag/Resource/paths/~1resource~1catalog/get"
						target="_blank"
					>
						To learn more visit the API documentation.
					</a>
				}
			/>
			<ApiFiltersGenerator />
		</Box>
	);
}
