import { Box, createStyles } from '@mantine/core';
import { TextSkeleton } from '@repo/foundations';

const useStyles = createStyles((theme) => ({
	container: {
		margin: `0 ${theme.spacing['2xs']}`,
		width: `calc(100% - (2 * ${theme.spacing['2xs']}))`,
	},
}));

export function SelectorItemSkeleton() {
	const { classes } = useStyles();

	return (
		<Box className={classes.container}>
			<TextSkeleton />
		</Box>
	);
}
