import { Checkbox, createStyles, Flex, Group } from '@mantine/core';
import type { GroupItem, GroupSetting } from '@repo/api-codegen';
import type { EntityType } from '@repo/common/enums/entityType';
import { Icon } from '@repo/foundations';
import type { ChangeEventHandler } from 'react';
import { useEffect, useMemo, useState } from 'react';
import { getEntityTypeDisplayInfo } from '../../../../utils/entityDisplayUtils';
import { useFeatureFlags } from '../../../../utils/featureFlags';
import { TeamRender } from '../../../TableV2/render.tsx';

export enum VisibilityState {
	VISIBLE = 'visible',
	INDETERMINATE = 'indeterminate',
	HIDDEN = 'hidden',
}

interface SelectPanelTableRowProps {
	item: GroupItem;
	groupSettings: Record<string, GroupSetting>;
	isVisible: (item: GroupItem) => VisibilityState;
	handleVisibilityChange: (item: GroupItem, visible: boolean) => void;
	updateCustomMappingTeams: (item: GroupItem, teamIds: string[]) => void;
	customMappingTeamOptions: string[];
	level?: number;
}

interface SelectPanelTableRowStylesProp {
	level: number;
}

const useStyles = createStyles(
	(theme, { level }: SelectPanelTableRowStylesProp) => ({
		nameCol: {
			paddingLeft: `calc(${theme.spacing['2xl']} * ${level})`,
			width: '100%',
			flexWrap: 'nowrap',
		},
	})
);

export function SelectIntegrationGroupsPanelTableHeaders({}) {
	const { customTeamSchemaMapping } = useFeatureFlags();

	return customTeamSchemaMapping ? (
		<tr>
			<th style={{ width: '60%' }}>Name</th>
			<th style={{ width: '40%' }}>Team Visibility</th>
		</tr>
	) : (
		<tr>
			<th>Name</th>
		</tr>
	);
}

export function SelectIntegrationGroupsPanelTableRow({
	item,
	groupSettings,
	isVisible,
	handleVisibilityChange,
	updateCustomMappingTeams,
	customMappingTeamOptions,
	level = 0,
}: SelectPanelTableRowProps) {
	const { classes, theme } = useStyles({ level });
	const { customTeamSchemaMapping } = useFeatureFlags();

	const hasChildren = item.children?.length > 0;
	const [isExpanded, setIsExpanded] = useState(true);

	const EntityIcon = getEntityTypeDisplayInfo(
		item.entity_type as EntityType
	).icon;

	const toggleVisibility: ChangeEventHandler<HTMLInputElement> = (event) => {
		handleVisibilityChange(item, event.target.checked);
	};

	// ============================
	// Calculates the value team options for the current items children
	// ============================
	const validChildVisibilityTeams = useMemo(() => {
		if (
			!groupSettings[item.databuilder_id] ||
			!groupSettings[item.databuilder_id]?.custom_mapping
		) {
			return customMappingTeamOptions;
		}

		return groupSettings[item.databuilder_id].mapping_teams.filter((t) =>
			customMappingTeamOptions.includes(t)
		);
	}, [customMappingTeamOptions, groupSettings, item]);

	// This has to be a local state so we can update it directly in this component
	// Otherwise we get a weird flickering effect when the selector updates selection
	// but it takes a second to propagate through react-query.
	const [teams, setTeams] = useState<GroupSetting['mapping_teams']>([]);
	useEffect(
		() => setTeams(groupSettings[item.databuilder_id]?.mapping_teams),
		[groupSettings]
	);

	return (
		<>
			<tr key={item.databuilder_id}>
				<td>
					<Group className={classes.nameCol}>
						<Checkbox
							checked={[
								VisibilityState.VISIBLE,
								VisibilityState.INDETERMINATE,
							].includes(isVisible(item))}
							indeterminate={isVisible(item) === VisibilityState.INDETERMINATE}
							onChange={toggleVisibility}
						/>
						<Flex style={{ overflow: 'hidden' }} title={item.title}>
							<Group pr={theme.spacing.xs}>
								<EntityIcon size={theme.other.iconSize.sm} />
							</Group>
							<span
								style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
								title={item.title}
							>
								{item.title}
							</span>
						</Flex>

						{level === 0 && (
							<Group spacing="xs" onClick={() => setIsExpanded(!isExpanded)}>
								<Icon name={isExpanded ? 'chevronDown' : 'chevronRight'} />
							</Group>
						)}
					</Group>
				</td>
				{customTeamSchemaMapping && (
					<td>
						<TeamRender
							record={{ id: item.databuilder_id, teams }}
							onChange={() => (value) => {
								setTeams(value as string[]);
								updateCustomMappingTeams(item, value as string[]);
							}}
							placeholder={'Inherited'}
							optionsFilter={(t) => customMappingTeamOptions.includes(t.id)}
						/>
					</td>
				)}
			</tr>
			{hasChildren &&
				isExpanded &&
				item.children.map((child) => (
					<SelectIntegrationGroupsPanelTableRow
						key={child.databuilder_id}
						item={child}
						groupSettings={groupSettings}
						isVisible={isVisible}
						handleVisibilityChange={handleVisibilityChange}
						updateCustomMappingTeams={updateCustomMappingTeams}
						customMappingTeamOptions={validChildVisibilityTeams}
						level={level + 1}
					/>
				))}
		</>
	);
}
