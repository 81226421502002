import { baseQueryHooksFactory } from '../../factories';
import type { ILineage } from '../../types/models/lineage';
import { lineageQueryKeyFactory } from './constants';

const { useLineageList, useLineageInfiniteList } = baseQueryHooksFactory<
	ILineage,
	'lineage'
>('lineage', lineageQueryKeyFactory);

export { useLineageInfiniteList, useLineageList };
