import { Box, useMantineTheme } from '@mantine/core';
import type {
	AlterEntityPermissionRequest,
	EntityPermissionOrMembershipOut,
} from '@repo/api-codegen';
import { Text } from '@repo/foundations';
import { useEffect, useState } from 'react';
import { CustomSelector } from '../CustomSelector';

const toValue = (membership: EntityPermissionOrMembershipOut) => {
	if (!membership.write) {
		return 'read';
	}
	return 'write';
};

const text = (membership: EntityPermissionOrMembershipOut, value: string) => {
	if (value === 'delete') {
		return 'Remove access';
	}

	if (value === 'read') {
		return 'Can read';
	}

	if (membership.user) {
		return 'Can edit';
	}
	return 'Editors can edit';
};

type IEntityPermissionLevelSelectorProps = {
	entityPermission: EntityPermissionOrMembershipOut;
	alterEntityPermission: (
		request: AlterEntityPermissionRequest
	) => Promise<unknown>;
};

export function EntityPermissionLevelSelector({
	entityPermission,
	alterEntityPermission,
}: IEntityPermissionLevelSelectorProps) {
	const theme = useMantineTheme();
	const [permission, setPermission] = useState<'read' | 'write'>(
		toValue(entityPermission)
	);

	useEffect(() => {
		setPermission(toValue(entityPermission));
	}, [entityPermission]);

	const onSelect = async (value: string) => {
		if (value === permission) return;

		const request: AlterEntityPermissionRequest = {};
		if (entityPermission.user) {
			request.user_id = entityPermission.user.id;
		}
		if (entityPermission.group) {
			request.group_id = entityPermission.group.id;
		}

		if (value === 'delete') {
			request.deleted = true;
		} else {
			setPermission(value as 'read' | 'write');
			request.write = value === 'write';
			request.read = true;
		}

		await alterEntityPermission(request);
	};

	const items = [
		{
			value: 'read',
			disabled: entityPermission.user?.role === 'admin',
		},
		{
			value: 'write',
			disabled: entityPermission.user?.role === 'viewer',
		},
		{
			value: 'delete',
		},
	];

	const color = (item: { value: string; disabled?: boolean }) => {
		if (item.disabled) return 'text/primary/disabled';
		if (item.value === 'delete') return 'text/critical/default';

		return 'text/primary/default';
	};

	return (
		<CustomSelector
			menuProps={{
				width: 'target',
				position: 'bottom-start',
				withinPortal: true,
			}}
			items={items}
			renderItem={(item) => (
				<Box px="xs" py={6}>
					<Text size="sm" color={color(item)}>
						{text(entityPermission, item.value)}
					</Text>
				</Box>
			)}
			value={permission}
			onChange={onSelect}
		/>
	);
}
