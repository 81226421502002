import { createStyles, getStylesRef, TextInput } from '@mantine/core';
import { useDebouncedState } from '@mantine/hooks';
import { useEffect } from 'react';
import { useUpdateMetric, type IMetric } from '../../../../../../api';

const useStyles = createStyles((theme) => ({
	wrapper: {
		background: theme.other.getColor('surface/secondary/default'),
		borderRadius: theme.radius.md,
		border: `solid ${theme.other.borderWidth.sm}px ${theme.other.getColor('border/secondary/default')}`,

		[`&:hover .${getStylesRef('hideIfNotHovered')}, &:focus-within .${getStylesRef('hideIfNotHovered')}`]:
			{
				opacity: 1,
			},
	},
	titleInput: {
		background: 'transparent',
		color: theme.other.getColor('text/primary/default'),
		border: 0,
		fontWeight: theme.other.typography.weight.bold,
		fontSize: theme.fontSizes.sm,
		lineHeight: theme.other.typography.lineHeight.text.sm,
		height: 'unset',
		paddingLeft: theme.spacing['3xs'],
	},
	sqlEditor: {
		padding: `0 ${theme.spacing.md} ${theme.spacing.md}`,
	},
	floatingToggleQuery: {
		position: 'absolute',
		top: 0,
		left: -48,
	},
	floatingToggleResults: {
		position: 'absolute',
		top: 0,
		left: -44,
	},
	hideIfNotHovered: {
		ref: getStylesRef('hideIfNotHovered'),
		opacity: 0,
	},
	expandCollapseButton: {
		opacity: 0,
	},
}));

export interface QueryBlockTitleProps {
	metric: IMetric;
	readOnly?: boolean;
}

export function QueryBlockTitle({ metric, readOnly }: QueryBlockTitleProps) {
	const { classes } = useStyles();
	const [title, setTitle] = useDebouncedState(metric.title, 200);

	const { mutateAsync } = useUpdateMetric({
		disableInvalidation: true,
	});

	useEffect(() => {
		mutateAsync({
			data: { id: metric.id, title },
			optimisticUpdate: true,
		});
	}, [metric.id, mutateAsync, title]);

	return (
		<TextInput
			variant="unstyled"
			classNames={{ input: classes.titleInput }}
			defaultValue={title}
			onChange={(e) => setTitle(e.currentTarget.value)}
			readOnly={readOnly}
		/>
	);
}
