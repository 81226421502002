/* eslint-disable consistent-return */
import { useEffect, useState } from 'react';

export default function useMediaQuery(query: string): boolean {
	const [matches, setMatches] = useState<boolean>(false);

	useEffect(() => {
		if (window.matchMedia) {
			const media = window.matchMedia(query);
			if (media.matches !== matches) {
				setMatches(media.matches);
			}
			const listener = () => {
				setMatches(media.matches);
			};
			media.addListener(listener);
			return () => media.removeListener(listener);
		}
	}, [matches, query]);

	return matches;
}
