import { Group, Menu, Stack } from '@mantine/core';
import { Button, IconButton, Title } from '@repo/foundations';
import { useBoolean } from 'ahooks';

import type { ICoverImagePickerProps } from '../../../../../components/CoverImagePicker';
import CoverImagePicker, {
	ColorPaletteChip,
} from '../../../../../components/CoverImagePicker';

export interface ICoverImageSectionProps
	extends Pick<ICoverImagePickerProps, 'onBackgroundImageChange'> {
	backgroundImage?: string;
}

function CoverImageSection({
	backgroundImage,
	onBackgroundImageChange,
}: ICoverImageSectionProps) {
	const [menuOpened, menuOpenedHandlers] = useBoolean(false);

	const handleRemoveBackgroundImage = () => {
		onBackgroundImageChange('', '#000000');
		menuOpenedHandlers.set(false);
	};

	const handleBackgroundImageChange = (
		image = '#FFFFFF',
		fontColor = '#000000'
	) => {
		onBackgroundImageChange(image, fontColor);
		menuOpenedHandlers.set(false);
	};

	return (
		<Stack px="lg" spacing="xs">
			<Title order={6}>Cover image</Title>
			<Group>
				{backgroundImage && (
					<ColorPaletteChip
						backgroundImage={backgroundImage}
						onClick={menuOpenedHandlers.toggle}
					/>
				)}
				<Menu
					opened={menuOpened}
					onChange={menuOpenedHandlers.set}
					position="bottom-end"
					withinPortal
				>
					<Menu.Target>
						{backgroundImage ? (
							<IconButton size="sm" iconName="pencil" />
						) : (
							<Button>Choose a cover image</Button>
						)}
					</Menu.Target>
					<Menu.Dropdown>
						<CoverImagePicker
							onBackgroundImageChange={handleBackgroundImageChange}
							onRemove={handleRemoveBackgroundImage}
						/>
					</Menu.Dropdown>
				</Menu>
				{backgroundImage && (
					<IconButton
						size="sm"
						iconName="trash"
						onClick={handleRemoveBackgroundImage}
					/>
				)}
			</Group>
		</Stack>
	);
}

export default CoverImageSection;
