import native from './native';
import rng from './rng';
import { unsafeStringify } from './stringify';

export function v4(
	options?: { random?: any; rng?: any },
	buf?: { [x: string]: any },
	offset?: number
) {
	if (native.randomUUID && !buf && !options) {
		return native.randomUUID();
	}

	options = options || {};

	const rnds = options.random || (options.rng || rng)();

	/* eslint-disable no-bitwise */
	// Per 4.4, set bits for version and `clock_seq_hi_and_reserved`
	rnds[6] = (rnds[6] & 0x0f) | 0x40;
	rnds[8] = (rnds[8] & 0x3f) | 0x80;
	/* eslint-enable no-bitwise */

	// Copy bytes to buffer, if provided
	if (buf) {
		offset = offset || 0;

		for (let i = 0; i < 16; i += 1) {
			buf[offset + i] = rnds[i];
		}

		return buf;
	}

	return unsafeStringify(rnds);
}
