import { Group, Stack } from '@mantine/core';
import { useElementSize, useLocalStorage } from '@mantine/hooks';
import { Switch, Text } from '@repo/foundations';
import * as monaco from 'monaco-editor';
import { useEffect, useRef } from 'react';

export function MarkdownDiff({
	oldValue,
	newValue,
}: {
	oldValue: string;
	newValue: string;
}) {
	const hostRef = useRef<HTMLDivElement>(null);
	const { ref: containerRef, width, height } = useElementSize<HTMLDivElement>();
	const editorRef = useRef<monaco.editor.IStandaloneDiffEditor>();
	const [viewType, setViewType] = useLocalStorage({
		key: 'snapshot-diff-view-type',
		defaultValue: 'side-by-side',
	});

	useEffect(() => {
		if (hostRef.current) {
			editorRef.current = monaco.editor.createDiffEditor(hostRef.current, {
				renderSideBySide: viewType !== 'inline',
				automaticLayout: true,
				folding: false,
				lineNumbersMinChars: 3,
				glyphMargin: false,
				contextmenu: false,
				minimap: {
					enabled: false,
				},
				renderLineHighlightOnlyWhenFocus: true,
			});

			editorRef.current.setModel({
				original: monaco.editor.createModel(oldValue, 'markdown'),
				modified: monaco.editor.createModel(newValue, 'markdown'),
			});

			setTimeout(() => {
				editorRef.current?.layout({
					width,
					height,
				});
			}, 1000);
		}

		return () => {
			editorRef.current?.dispose();
			editorRef.current = undefined;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [height, hostRef, newValue, oldValue, width]);

	return (
		<Group style={{ height: '100%' }}>
			<Stack ref={containerRef} style={{ width: '100%', height: '100%' }}>
				<Group spacing="md">
					<Group spacing="xs" align="center">
						<Text size="sm">Side by side</Text>
						<Switch
							checked={viewType === 'inline'}
							onChange={(ev) => {
								editorRef.current?.updateOptions({
									renderSideBySide: !ev.currentTarget.checked,
								});
								setViewType(
									ev.currentTarget.checked ? 'inline' : 'side-by-side'
								);
							}}
						/>
						<Text size="sm">Inline</Text>
					</Group>
				</Group>
				<div
					ref={hostRef}
					style={{
						marginTop: 10,
						minHeight: '90vh',
						width: '100%',
						height: '100%',
					}}
				/>
			</Stack>
		</Group>
	);
}
