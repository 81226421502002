import { CopyButton as MantineCopyButton } from '@mantine/core';

import type { IconNames } from '../Icon';
import { IconButton } from './index';

export const allCopyButtonTypes = ['item', 'link'] as const;

export type CopyButtonTypes = (typeof allCopyButtonTypes)[number];

const CopyButtonIconMap: Record<CopyButtonTypes, IconNames> = {
	item: 'clipboard',
	link: 'link',
};

interface CopyButtonProps {
	value: string;
	type?: CopyButtonTypes;
	timeout?: number;
	id?: string;
	disabled?: boolean;
	color?: string;
}

function CopyButton({
	value,
	type = 'item',
	timeout,
	id,
	disabled = false,
	color = 'black',
}: CopyButtonProps) {
	return (
		<MantineCopyButton value={value} timeout={timeout}>
			{({ copied, copy }) => (
				<IconButton
					id={id}
					variant="tertiary"
					iconName={copied ? 'check' : CopyButtonIconMap[type]}
					onClick={copy}
					disabled={disabled}
					style={{ color: color }}
				/>
			)}
		</MantineCopyButton>
	);
}

export default CopyButton;
