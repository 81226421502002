import { Box, Drawer } from '@mantine/core';
import { Text } from '@repo/foundations';
import { observer } from 'mobx-react-lite';
import type { IDatabuilderJob, IIntegration } from '../../../api';
import { useIntegrationSpec } from '../../../api';
import { DatabuilderJobType } from '../../../interfaces';
import type { Integration } from '../../../lib/models';
import { sentenceCase } from '../../../utils/stringUtils';
import { hashCode } from '../../../utils/utils';
import { ErrorLogs } from '../../ErrorLogs/ErrorLogs';
import { IntegrationHelp } from '../IntegrationHelp';
import { ExtractionStagesTable } from './ExtractionStagesTable';
import { ExtractionSummary } from './ExtractionSummary';

interface ExtractionDetailProps {
	integration: IIntegration | Integration;
	extractionJobs: IDatabuilderJob[];
	extractionDetailId: string;
	isOpen: boolean;
	onClose: () => void;
}

export const ExtractionDetail = observer(
	({
		integration,
		extractionJobs,
		extractionDetailId,
		isOpen,
		onClose,
	}: ExtractionDetailProps) => {
		const job = extractionJobs.find((j) => j.id === extractionDetailId);
		const spec = useIntegrationSpec(integration.id);

		if (!job) {
			return null;
		}

		return (
			<Drawer
				opened={isOpen}
				onClose={onClose}
				position="right"
				title={
					<Text size="md" weight="bold">
						Sync stages
					</Text>
				}
			>
				<Box p={4}>
					<ExtractionStagesTable
						// Only include stages that include a `stage` title.
						stages={job.stages
							?.filter((stage) => stage.stage)
							.map((stage) => ({
								...stage,
								stage: sentenceCase(stage.stage),
							}))}
						isJobTerminated={job.terminated}
					/>
					<Box mb={4} />
					{job.stages
						?.filter((stage) => stage.stage && stage.failed)
						.map((stage) => (
							<Box key={hashCode(stage.id)}>
								<ErrorLogs
									title={stage.logs.split('\n')[0] || 'Runtime Error'}
									logs={stage.logs.split('\n').slice(1)}
								/>
								{spec && <IntegrationHelp spec={spec} />}
							</Box>
						))}
					{!job.failed &&
						(job.type === DatabuilderJobType.METADATA ||
							job.type === DatabuilderJobType.DUMMY) && (
							<ExtractionSummary databuilderJobSummary={job.summary} />
						)}
				</Box>
			</Drawer>
		);
	}
);
