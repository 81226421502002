import type { MantineNumberSize, TextInputProps } from '@mantine/core';
import { createStyles, TextInput } from '@mantine/core';

interface InputProps extends TextInputProps {
	width?: MantineNumberSize;
	padding?: MantineNumberSize;
}

const useStyles = createStyles(
	(
		theme,
		{
			width,
			padding,
		}: { width?: InputProps['width']; padding?: InputProps['padding'] }
	) => ({
		wrapper: {
			flex: width ? undefined : '1 !important',
			width,
		},
		input: {
			height: 'auto',
			minHeight: 'auto',
			lineHeight: 'normal',
			padding: padding ?? `${theme.spacing['2xs']} ${theme.spacing.sm}`,

			'&:focus': {
				boxShadow: 'none',
			},
		},
	})
);

export default function Input({ width, padding, ...rest }: InputProps) {
	const { classes } = useStyles({ width, padding });
	return (
		<TextInput
			{...rest}
			variant="unstyled"
			classNames={{ root: classes.wrapper, input: classes.input }}
		/>
	);
}
