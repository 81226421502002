import { useLocalStorage } from '@mantine/hooks';

interface IUseEntityBreadcrumbTeamReturn {
	setTeamBreadcrumb: (teamId: string) => void;
	teamBreadcrumb: string | null;
}

// Entities can have multiple parents when this happens we want to display the
// most contextually appropriate team in the breadcrumbs for the entity.
export function useEntityBreadcrumbTeam(): IUseEntityBreadcrumbTeamReturn {
	const [teamBreadcrumb, setTeamBreadcrumb] = useLocalStorage<string | null>({
		key: 'breadcrumb-team',
		defaultValue: null,
	});

	return { teamBreadcrumb, setTeamBreadcrumb };
}
