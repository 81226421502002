import { Box, Divider, Flex, Group, Modal, TextInput } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
	useApiGetPiiWordList,
	useApiUpdatePiiWordList,
} from '@repo/api-codegen';
import { Button, IconButton, Text, Title } from '@repo/foundations';
import { useState } from 'react';
import { useWorkspace } from '../../api/hooks/workspace/useWorkspace';

const sanitizeWords = (words: string[]) =>
	words
		.map((str: string) => {
			// Remove non-alphanumeric characters and underscores.
			const sanitizedStr = str.trim().replace(/[^a-zA-Z0-9_-]/g, '');
			return sanitizedStr;
		})
		.filter((str: string) => str.length > 0);

/**
 * A functional component to manage and display a list of PII words.
 */
export function PIIWordsList() {
	const { workspace } = useWorkspace(); // Load workspace details.
	const { data: _piiWords } = useApiGetPiiWordList(
		{},
		{
			select: (data) => data.results,
		}
	);
	const piiWords = [...new Set(_piiWords ?? [])]; // Ensure that piiWords has a uniqueKey and is null

	const { mutateAsync: updatePiiWords } = useApiUpdatePiiWordList();

	const [inputWord, setInputWord] = useState<string>('');

	const [opened, handlers] = useDisclosure();

	// Ensure that workspace is loaded before rendering.
	if (!workspace) {
		return null;
	}

	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (inputWord) {
			updatePiiWords({
				body: {
					words: sanitizeWords([inputWord, ...piiWords]),
				},
			});
			setInputWord('');
		}
	};

	const handleDelete = (word: string) => () => {
		const words = sanitizeWords(piiWords?.filter((w) => w !== word) ?? []);
		updatePiiWords({ body: { words } });
	};

	return (
		<>
			<IconButton iconName="settings" onClick={handlers.open} size="sm" />

			<Modal
				title={
					<>
						<Title order={4}>PII Wordlist</Title>
						<Text size="sm" color="text/secondary/default">
							The column names matching this wordlist will be automatically
							marked as PII.
						</Text>
					</>
				}
				size="xl"
				lockScroll={false}
				opened={opened}
				onClose={handlers.close}
			>
				<Divider mt={12} mb={12} />
				<form onSubmit={handleSubmit}>
					<Flex align="center" justify="space-between" mb={24}>
						<TextInput
							placeholder="Enter a word"
							value={inputWord}
							onChange={(e) => setInputWord(e.target.value)}
							size="sm"
							style={{ flexGrow: 1, marginRight: '1rem' }}
						/>
						<Button type="submit" disabled={!inputWord}>
							Add
						</Button>
					</Flex>
				</form>

				{piiWords?.map((word) => (
					<Box key={word}>
						<Group mb={6}>
							<IconButton size="sm" iconName="x" onClick={handleDelete(word)} />
							<Text>{word}</Text>
						</Group>
					</Box>
				))}
			</Modal>
		</>
	);
}
