export type AIAssistantCurrentPage = {
	id: string;
	type: string;
};

export enum AIAssistantMode {
	PAGE = 'PAGE',
	SIDEBAR = 'SIDEBAR',
	MODAL = 'MODAL',
}
