export const SCHEDULE_OPTIONS: { value: number | null; label: string }[] = [
	{ value: null, label: 'None' },
	{ value: 1, label: 'Hourly' },
	{ value: 24, label: 'Daily' },
	{
		value: 24 * 7,
		label: 'Weekly',
	},
	{
		value: 24 * 30,
		label: 'Monthly',
	},
];
