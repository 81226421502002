import { Box, createStyles, Loader } from '@mantine/core';
import { useEffect } from 'react';
import { cache } from '../../lib/stores/CacheStore.helpers';

const useStyles = createStyles(() => ({
	page: {
		marginTop: 50,
		display: 'flex',
		justifyContent: 'center',
	},
}));

export function AuthSignout() {
	const styles = useStyles();

	useEffect(() => {
		localStorage.clear();

		// Clear the model store cache.
		cache.destroy();

		setTimeout(() => {
			window.open('/', '_self');
		}, 2000);
	}, []);

	return (
		<Box className={styles.classes.page}>
			<Loader />
		</Box>
	);
}

export default AuthSignout;
