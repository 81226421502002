import { IconButton } from '@repo/foundations';

interface AutomationCardDeleteButtonProps {
	handleDelete: () => void;
}

function AutomationCardDeleteButton({
	handleDelete,
}: AutomationCardDeleteButtonProps) {
	return <IconButton onClick={handleDelete} iconName="x" variant="tertiary" />;
}

export default AutomationCardDeleteButton;
