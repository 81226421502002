import { useApiListTeams } from '@repo/api-codegen';
import { type BreadcrumbCrumb } from '@repo/foundations';
import { reverse } from 'lodash-es';
import type {
	IApiListResponse,
	INestedBreadcrumbs,
	ISecodaEntity,
} from '../../api';
import { useIntegrationList } from '../../api';
import { useNestedBreadcrumbsList } from '../../api/hooks/nestedBreadcrumbs';
import { getBreadcrumbForEntity } from '../../utils/breadcrumb';
import { useEntityBreadcrumbTeam } from './useEntityBreadcrumbTeam';

interface IUseEntityBreadcrumbsReturn {
	isLoading: boolean;
	breadcrumbs: Array<BreadcrumbCrumb>;
}

interface IUseEntityBreadcrumbsOptions {
	includeTeam?: boolean;
	includeCurrentPage?: boolean;
}

/**
 * Generates breadcrumbs for the given SecodaEntity.
 */
export function useEntityBreadcrumbs(
	entity: ISecodaEntity,
	options?: IUseEntityBreadcrumbsOptions
): IUseEntityBreadcrumbsReturn {
	const { data: teams } = useApiListTeams(
		{
			queryParams: {
				only_joined: true,
			},
		},
		{
			select: (res) => res.results,
		}
	);

	const { teamBreadcrumb: preferredTeamId } = useEntityBreadcrumbTeam();

	const { data: integrations, isLoading: isIntegrationsListLoading } =
		useIntegrationList({
			options: {
				select: (res) => res.results,
			},
		});

	const { data: nestedBreadcrumbs, isLoading: isNestedBreadCrumbsLoading } =
		useNestedBreadcrumbsList({
			filters: {
				id: entity.id,
			},
			options: {
				select: (res: IApiListResponse<INestedBreadcrumbs>) =>
					reverse(res.results || []),
			},
		});

	if (isNestedBreadCrumbsLoading || isIntegrationsListLoading) {
		return { isLoading: true, breadcrumbs: [] };
	}

	return {
		isLoading: false,
		breadcrumbs: getBreadcrumbForEntity({
			entity,
			preferredTeamId,
			nativeType: entity.native_type ?? '',
			teams,
			nestedBreadcrumbs,
			integrations,
			includeTeam: options && options.includeTeam,
			includeCurrentPage: options && options.includeCurrentPage,
		}),
	};
}
