import {
	Divider,
	Group,
	Stack,
	TextInput,
	Textarea,
	createStyles,
} from '@mantine/core';
import { useDebouncedValue, useDidUpdate } from '@mantine/hooks';
import { Switch } from '@repo/foundations';
import { useState } from 'react';
import { AutomationTriggerType } from '../../../../../api';
import { useAutomationStore } from '../../../../../pages/AutomationPage/context';
import { AutomationActionCardType } from '../../../constants';
import { useAutomationTriggerCardStore } from '../../AutomationTriggerCard/context';
import { doesCardExist } from '../../utils';

const useStyles = createStyles((theme) => ({
	root: {
		gap: theme.spacing.md,
	},
	stack: {
		gap: theme.spacing['3xs'],
	},
	textInput: {
		border: theme.radius.sm,
	},
	textArea: {
		border: theme.radius.sm,
		size: 'md',
	},
	group: {
		display: 'flex',
		justifyContent: 'space-between',
	},
}));

export interface AutomationActionCardOnChangeProps {
	subject?: string;
	body?: string;
}

interface AutomationActionCardNotificationContentProps {
	checked: boolean;
	initialBody?: string;
	initialSubject?: string;
	includeSubject: boolean;
	onCheckboxChange: (newChecked: boolean) => void;
	onChange: (props: AutomationActionCardOnChangeProps) => Promise<void>;
}

function AutomationActionCardNotificationContent({
	checked,
	initialSubject,
	initialBody,
	includeSubject,
	onCheckboxChange,
	onChange,
}: AutomationActionCardNotificationContentProps) {
	const { classes } = useStyles();
	const [subject, setSubject] = useState<string>(initialSubject ?? '');
	const [body, setBody] = useState<string>(initialBody ?? '');

	const [debouncedSubject] = useDebouncedValue(subject, 350);
	const [debouncedBody] = useDebouncedValue(body, 350);

	const { cards } = useAutomationStore();
	const doesEditCardExist = doesCardExist(
		cards,
		AutomationActionCardType.EDIT_RESOURCES
	);
	const { selectedTrigger } = useAutomationTriggerCardStore();
	let summaryLabel = 'Include summary of automation edit';

	if (selectedTrigger === AutomationTriggerType.SCHEMA_CHANGE) {
		summaryLabel = 'Include summary of schema changes';
	}

	useDidUpdate(() => {
		onChange({
			subject: debouncedSubject,
			body: debouncedBody,
		});
	}, [debouncedSubject, debouncedBody]);

	return (
		<Stack className={classes.root}>
			{includeSubject && (
				<Stack className={classes.stack}>
					Subject
					<TextInput
						className={classes.textInput}
						value={subject}
						onChange={(e) => setSubject(e.target.value)}
					/>
				</Stack>
			)}
			<Stack className={classes.stack}>
				Body
				<Textarea
					className={classes.textArea}
					minRows={6}
					value={body}
					onChange={(e) => setBody(e.target.value)}
				/>
			</Stack>
			{doesEditCardExist && (
				<>
					<Divider />
					<Group className={classes.group}>
						{summaryLabel}
						<Switch
							checked={checked}
							onChange={(event) =>
								onCheckboxChange(event.currentTarget.checked)
							}
						/>
					</Group>
				</>
			)}
		</Stack>
	);
}

export default AutomationActionCardNotificationContent;
