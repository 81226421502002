import { type FilterValue } from '@repo/common/components/Filter/types';
import { useCallback } from 'react';
import { useLocation } from 'react-router';
import { useAuthUser } from '../../api';
import { trackEvent } from '../../utils/analytics';

interface UseFilterProps {
	value: FilterValue | null;
	onChange: (value: Partial<FilterValue>) => void;
	close: () => void;
}

export function useFilter({ value, onChange, close }: UseFilterProps) {
	const { user, workspace } = useAuthUser();
	const location = useLocation();

	const handleOnChange = useCallback(
		(newValue: Partial<FilterValue>, shouldClose: boolean) => {
			if (!value) {
				return;
			}

			onChange(newValue);

			if (shouldClose) {
				close();
			}

			const filterValue: FilterValue = {
				...value,
				...newValue,
			};

			trackEvent(
				'filters/select',
				{
					url: location.pathname,
					filter: filterValue.filterType,
					operator: filterValue.operator,
					value: JSON.stringify(filterValue.value),
				},
				user,
				workspace
			);
		},
		[close, location.pathname, onChange, user, value, workspace]
	);

	return {
		handleOnChange,
	};
}
