import { ActionIcon, Group } from '@mantine/core';
import { Icon, IconButton } from '@repo/foundations';
import type { To } from 'react-router';
import { Link } from 'react-router-dom';
import BookmarkToggle from '../../BookmarkToggle/BookmarkToggle';
import {
	NavBarBreadcrumbWrapper,
	NavBarWrapper,
} from '../../SecodaAppShell/SecodaNavBar';
import store from '../store';
import EntityDrawerBreadcrumbs from './EntityDrawerBreadcrumbs';

interface IEntityDrawerActionBarProps {
	to: To;
}

function EntityDrawerActionBar({ to }: IEntityDrawerActionBarProps) {
	return (
		<NavBarWrapper>
			<NavBarBreadcrumbWrapper>
				<EntityDrawerBreadcrumbs
					entity={store.entity}
					onClick={store.closeEntityDrawer}
				/>
			</NavBarBreadcrumbWrapper>
			<Group spacing="3xs" noWrap>
				<BookmarkToggle entity={store.entity} />
				<ActionIcon
					size="xs"
					component={Link}
					to={to}
					onClick={store.closeEntityDrawer}
				>
					<Icon name="arrowsDiagonal" color="icon/primary/default" />
				</ActionIcon>
				<IconButton
					onClick={store.closeEntityDrawer}
					iconName="x"
					variant="tertiary"
				/>
			</Group>
		</NavBarWrapper>
	);
}

export default EntityDrawerActionBar;
