import type { TabsListProps, TabsProps } from '@mantine/core';
import { Box, createStyles, rem } from '@mantine/core';
import { range } from 'lodash-es';
import type { PropsWithChildren } from 'react';
import type { TextProps } from '../Text';
import type { TextSkeletonProps } from './TextSkeleton';
import { TextSkeleton } from './TextSkeleton';

type TabsSkeletonProps = Pick<
	TabsProps,
	'orientation' | 'placement' | 'inverted'
> &
	Pick<TabsListProps, 'position' | 'grow'> &
	Pick<TextProps, 'size'> &
	Pick<TextSkeletonProps, 'height'> & {
		numTabs?: number;
		tabs?: Array<{ label: string }>;
	};

const useStyles = createStyles(
	(theme, { orientation, placement, inverted, grow }: TabsSkeletonProps) => {
		const vertical = orientation === 'vertical';

		return {
			tabsList: {
				display: 'flex',
				flexWrap: 'nowrap',
				overflow: 'hidden',
				flexDirection: vertical ? 'column' : 'row',
				justifyContent: 'flex-start',

				'& [role="tab"]': {
					flex: grow ? 1 : undefined,
				},

				[vertical
					? placement === 'left'
						? 'borderRight'
						: 'borderLeft'
					: inverted
						? 'borderTop'
						: 'borderBottom']: `${rem(2)} solid ${
					theme.colorScheme === 'dark'
						? theme.colors.dark[4]
						: theme.colors.gray[3]
				}`,
			},
			tab: {
				padding: `${theme.spacing.xs} ${theme.spacing.md} ${theme.spacing.xs} 0`,
				marginBottom: theme.spacing['4xs'],
			},
		};
	}
);

export function TabsSkeleton({
	tabs,
	numTabs = 3,
	orientation = 'horizontal',
	placement = 'left',
	inverted = false,
	position = 'left',
	grow = false,
	size = 'sm',
}: PropsWithChildren<TabsSkeletonProps>) {
	const { classes } = useStyles({
		grow,
		inverted,
		orientation,
		placement,
		position,
	});

	return (
		<Box className={classes.tabsList} aria-orientation={orientation}>
			{(tabs ?? []).length > 0
				? tabs!.map((tab, index) => (
						<Box key={tab.label} className={classes.tab}>
							<TextSkeleton
								size={size}
								key={index}
								height={15}
								text={tab.label}
							/>
						</Box>
					))
				: range(numTabs ?? 3).map((tab) => (
						<Box key={tab} className={classes.tab}>
							<TextSkeleton size={size} key={tab} width={64} height={12} />
						</Box>
					))}
		</Box>
	);
}
