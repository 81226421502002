import type { MantineTheme } from '@mantine/core';
import { createStyles } from '@mantine/core';
import { Badge, Text } from '@repo/foundations';
import type { SecodaThemeShades } from '@repo/theme/types';
import { IconWrapper } from '../IconWrapper';
import type { ItemIconType } from '../ItemIcon';
import ItemIcon from '../ItemIcon';
import type { SelectablePropertyItem } from '../SingleSelector/types';

export interface SelectorItemProps {
	isMenuItemBadge: boolean;
	iconType: ItemIconType;
	item: SelectablePropertyItem;
	color: string;
	disabled?: boolean;
}

export interface IntegrationTooltipSelectorItemStyleProps {
	disabled: boolean;
}

const useStyles = createStyles(
	(
		theme: MantineTheme,
		{ disabled }: IntegrationTooltipSelectorItemStyleProps
	) => ({
		badge: {
			backgroundColor: theme.other.getColor(
				'fill/transparent-secondary/default'
			),
		},
		badgeInner: {
			fontSize: theme.fontSizes.sm,
			fontWeight: theme.other.typography.weight.regular,
			color: theme.other.getColor('text/primary/default'),
			lineHeight: theme.other.typography.lineHeight.text.sm,
			opacity: disabled ? '25%' : '100%',
		},
	})
);

function SelectorItemContent({
	isMenuItemBadge,
	iconType,
	item,
	color,
	disabled = false,
}: SelectorItemProps) {
	const { classes } = useStyles({ disabled });

	if (isMenuItemBadge) {
		return (
			<Badge
				classNames={{ root: classes.badge, inner: classes.badgeInner }}
				leftSection={
					<IconWrapper>
						<ItemIcon
							type={iconType}
							item={item}
							color={color as SecodaThemeShades} // Type asserting here as we're internally handling the string colors
						/>
					</IconWrapper>
				}
				my={0}
			>
				<Text size="sm">{item.label}</Text>
			</Badge>
		);
	}

	return (
		<>
			<ItemIcon
				type={iconType}
				item={item}
				color={color as SecodaThemeShades} // Type asserting here as we're internally handling the string colors
			/>
			{item.strikeThrough ? (
				<Text td="line-through" size="sm">
					{item.label}
				</Text>
			) : (
				item.label
			)}
		</>
	);
}

export default SelectorItemContent;
