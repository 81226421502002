import type { ElementType, PropsWithChildren } from 'react';
import { useEffect } from 'react';

import { Global } from '@mantine/core';
import type { ChangeLogOptions } from '../makes/Canny';

type CannyChangelogProps = PropsWithChildren<
	{
		component?: ElementType;
		className?: string;
	} & Partial<Omit<ChangeLogOptions, 'appId'>> & {
			[prop: string]: any;
		}
>;

function CannyChangelog(props: CannyChangelogProps) {
	const {
		align = 'left',
		position = 'bottom',
		labelIds,
		children,
		isLoaded,
		appId,
		canny,
		...rest
	} = props;

	useEffect(() => {
		if (isLoaded && appId) {
			canny.initChangelog({
				appID: appId,
				position: 'right',
				align: 'bottom',
			});

			return () => {
				canny.closeChangelog();
			};
		}
	}, [isLoaded, appId, align, position, labelIds, canny]);

	return (
		<div>
			<Global
				styles={() => ({
					'.Canny_BadgeContainer .Canny_Badge': {
						position: 'absolute',
						top: '-1px',
						right: '-1px',
						borderRadius: '10px',
						backgroundColor: 'rgb(74, 74, 74)',
						padding: '5px',
						border: '1px solid white',
					},
				})}
			/>
			<div {...rest} data-canny-changelog="">
				{children}
			</div>
		</div>
	);
}

export { CannyChangelog };
export type { CannyChangelogProps };
