import { Group, Loader } from '@mantine/core';
import { useMemo } from 'react';
import ReactFlow, {
	Background,
	ReactFlowProvider,
	useEdgesState,
	useNodesState,
} from 'reactflow';
import type { IAnalyticsMetric } from '../../../api/types/models/analyticsMetric';
import type { IInternalIntegrationStatus } from '../../../api/types/models/internalIntegrationStatus';

import 'reactflow/dist/style.css';
import type { LineageSummaryRow } from './LineageSummaryContent.helpers';
import { computeInitialNodesAndEdges } from './LineageSummaryContent.helpers';
import { SelfConnectingEdge } from './SelfConnectingEdge';

const edgeTypes = {
	selfconnecting: SelfConnectingEdge,
};

function LineageSummaryReactFlow({
	integrationStatuses,
	summaryRows,
}: {
	summaryRows: LineageSummaryRow[];
	integrationStatuses: IInternalIntegrationStatus[];
}) {
	const integrationById: Record<string, IInternalIntegrationStatus> = {};
	integrationStatuses.forEach((s) => {
		integrationById[s.integration_id] = s;
	});

	const { nodes: initialNodes, edges: initialEdges } = useMemo(
		() =>
			computeInitialNodesAndEdges({
				integrationStatuses,
				summaryRows,
			}),
		[integrationStatuses, summaryRows]
	);

	const [nodes, , onNodesChange] = useNodesState(initialNodes);
	const [edges, , onEdgesChange] = useEdgesState(initialEdges);

	return (
		<ReactFlow
			nodes={nodes}
			edges={edges}
			edgeTypes={edgeTypes}
			onNodesChange={onNodesChange}
			onEdgesChange={onEdgesChange}
			maxZoom={10}
			minZoom={0.1}
		>
			<Background />
		</ReactFlow>
	);
}

export function LineageSummaryContent({
	integrationStatuses,
	result,
}: {
	workspaceId: string;
	integrationStatuses: IInternalIntegrationStatus[];
	result:
		| { resolved: false }
		| { resolved: true; data: IAnalyticsMetric }
		| undefined;
}) {
	if (!result || !result.resolved) {
		return <Loader />;
	}

	return (
		<Group position="center" w="100%">
			<ReactFlowProvider>
				<Group
					sx={{
						border: '2px solid black',
						width: '80%',
						height: 800,
					}}
				>
					<LineageSummaryReactFlow
						integrationStatuses={integrationStatuses}
						summaryRows={result.data.value as LineageSummaryRow[]}
					/>
				</Group>
			</ReactFlowProvider>
		</Group>
	);
}
