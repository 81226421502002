import { createQueryKeys } from '../../factories';

// Searching for SecodaEntities
export const SEARCH_NAMESPACE = ['search'];
export const searchQueryKeyFactory = createQueryKeys(SEARCH_NAMESPACE);

// Mention search endpoint
export const MENTION_NAMESPACE = ['mention'];
export const mentionQueryKeyFactory = createQueryKeys(MENTION_NAMESPACE);

// Get possible search filters
export const SEARCH_FILTERS_NAMESPACE = ['search', 'filters'];
export const searchFilterQueryKeyFactory = createQueryKeys(
	SEARCH_FILTERS_NAMESPACE
);

// Get previous search logs
export const SEARCH_LOGS_NAMESPACE = ['search', 'logs'];
export const searchLogsQueryKeyFactory = createQueryKeys(SEARCH_LOGS_NAMESPACE);

// Get search views
export const SEARCH_VIEW_NAMESPACE = ['search', 'view'];
export const searchViewQueryKeyFactory = createQueryKeys(SEARCH_VIEW_NAMESPACE);
