import { showNotification } from '@mantine/notifications';
import { Icon } from '@repo/foundations';
import type { MutationFunction } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import { pick } from 'lodash-es';
import { apiClient, getEndpoints } from '../../common';
import type { ISecodaEntity, Namespace } from '../../types';
import {
	optimisticUpdateById,
	optimisticUpdateLists,
} from '../base/useUpdateBaseModel';
import { RESOURCE_CATALOG_NAMESPACE } from '../resourceCatalog/constants.ns';
import { secodaEntitiesQueryKeyFactory } from './constants';

type RequestData = {
	data: {
		source: ISecodaEntity;
		destinations: ISecodaEntity[];
		fields: string[];
		override: boolean;
		override_children: boolean;
	};
	signal?: AbortSignal;
};

export function getDefaultPropagateFn(
	namespace: Namespace
): MutationFunction<string[], RequestData> {
	return async ({ data, signal }) => {
		const baseUrl = getEndpoints(namespace).root();
		const url = `${baseUrl}propagate`;

		const { data: responseData } = await apiClient.post<string[]>(
			url,
			{
				source: data.source.id,
				destinations: data.destinations.map((d) => d.id),
				fields: data.fields,
				override: data.override,
				override_children: data.override_children,
			},
			{
				signal,
			}
		);

		data.destinations.forEach((destination) => {
			const updated = {
				...destination,
				...pick(data.source, data.fields),
			};
			optimisticUpdateLists(updated, [RESOURCE_CATALOG_NAMESPACE]);
			optimisticUpdateById(
				updated,
				secodaEntitiesQueryKeyFactory?.byId(updated.id)
			);
		});

		return responseData;
	};
}

function usePropagateSecodaEntityMetadata() {
	const { namespace } = secodaEntitiesQueryKeyFactory;
	const mutationFn = getDefaultPropagateFn(namespace);

	return useMutation<string[], unknown, RequestData>({
		mutationFn,
		onError: () => {
			showNotification({
				title: 'Error updating resources',
				message: 'If the issues persists, please contact support',
				color: 'red',
				icon: <Icon name="alertCircle" />,
			});
		},
		onSuccess: () => {
			showNotification({
				title: 'Resources updated',
				message: 'The resources have been updated with selected metadata',
				color: 'green',
				icon: <Icon name="check" />,
			});
		},
	});
}

export default usePropagateSecodaEntityMetadata;
