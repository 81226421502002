import { TextInput } from '@mantine/core';

import { useInputState } from '@mantine/hooks';
import { Icon } from '@repo/foundations';
import { useRef } from 'react';

export type IEditableTitleProps = {
	initialValue: string;
	onSave: (newValue: string) => void;
	onExitEditing: () => void;
};

function EditableTitle({
	initialValue,
	onSave,
	onExitEditing,
}: IEditableTitleProps) {
	const [text, setText] = useInputState(initialValue);
	const inputRef = useRef<HTMLInputElement>(null);

	const handleSave = () => {
		onExitEditing();
		if (text !== initialValue && text !== '') {
			onSave(text);
		}
	};

	const handleCancel = () => {
		onExitEditing();
	};

	const handleClick: React.MouseEventHandler = (e) => {
		inputRef.current?.focus();
		e.stopPropagation();
		e.preventDefault();
	};

	return (
		<TextInput
			value={text}
			icon={<Icon name="pencil" />}
			onChange={setText}
			onKeyDown={(event) => {
				if (event.key === 'Enter') {
					handleSave();
				} else if (event.key === 'Escape') {
					handleCancel();
				}
			}}
			onMouseDown={handleClick}
			onBlur={handleCancel}
			ref={inputRef}
			autoFocus
		/>
	);
}

export default EditableTitle;
