import { Container, Divider, Group, Stack } from '@mantine/core';
import { useNavigate } from '@repo/common/hooks/useNavigate';
import type { IMarketplaceIntegrationSpec } from '@repo/common/models/marketplace';
import { Breadcrumbs, Button, Text, Title } from '@repo/foundations';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { useAuthUser } from '../../api';
import {
	useCreateMarketplaceIntegrationSpec,
	useCreateMarketplaceIntegrationSpecVersion,
	useMarketplaceIntegrationSpec,
} from '../../api/hooks/marketplace';
import { FullWidthLoadingSpinner } from '../../components/LoadingSpinner';
import { MarketplaceIntegrationSpecVersionForm } from '../../components/MarketplaceIntegrationSpecVersionForm/MarketplaceIntegrationSpecVersionForm';
import { useMarketplaceIntegrationSpecVersionForm } from '../../components/MarketplaceIntegrationSpecVersionForm/MarketplaceIntegrationSpecVersionForm.hook';
import {
	NavBarBreadcrumbWrapper,
	NavBarWrapper,
} from '../../components/SecodaAppShell/SecodaNavBar';
import { IS_LOCAL_OR_DEVELOPMENT } from '../../utils/envs';

function Content({
	existingSpec,
}: {
	existingSpec?: IMarketplaceIntegrationSpec;
}) {
	const navigate = useNavigate();

	const {
		mutateAsync: apiCreateMarketplaceIntegrationSpec,
		isLoading: isCreatingSpec,
	} = useCreateMarketplaceIntegrationSpec({});

	const { mutateAsync: apiCreateSpecVersion, isLoading: isCreatingVersion } =
		useCreateMarketplaceIntegrationSpecVersion();

	const form = useMarketplaceIntegrationSpecVersionForm(
		existingSpec?.versions?.[0]
	);

	const { workspace } = useAuthUser();

	const submit = async () => {
		if (form.validate().hasErrors) {
			return;
		}

		let spec = existingSpec;
		if (!spec) {
			spec = await apiCreateMarketplaceIntegrationSpec({
				data: {},
			});
		}

		await apiCreateSpecVersion({
			specId: spec.id,
			codeFile: form.values.code_file as File,
			detail: {
				name: form.values.name,
				description: form.values.description,
				category: form.values.category,
				icon_url: form.values.icon_url as string,
				allowed_endpoints: form.values.allowed_endpoints.map(
					(endpoint) => endpoint.value
				),
				form_spec: form.values.form_fields.reduce(
					(acc, field) => ({
						...acc,
						[field.name]: {
							placeholder: field.placeholder,
							type: field.sensitive ? 'password' : 'text',
							required: field.required,
						},
					}),
					{}
				),
			},
		});

		navigate(`/marketplace-integration/${spec.id}`);
	};

	const marketplaceEnabled =
		IS_LOCAL_OR_DEVELOPMENT || Boolean(workspace.marketplace_enabled);

	if (!marketplaceEnabled) {
		return null;
	}

	return (
		<Stack spacing={0}>
			<NavBarWrapper>
				<NavBarBreadcrumbWrapper>
					<Breadcrumbs
						crumbs={[
							{ title: 'Integrations', href: '/integrations' },
							{
								title: 'Integration marketplace',
								href: '/integrations/browse?tab=create',
							},
							{
								title: existingSpec?.versions?.[0].name || '',
								href: `/marketplace-integration/${existingSpec?.id}`,
							},
						]}
					/>
				</NavBarBreadcrumbWrapper>
			</NavBarWrapper>

			<Container size={640}>
				<Stack pb="xl" spacing="xl" w={640}>
					{existingSpec && (
						<>
							<Stack spacing="xs">
								<Title size="xl">Create new version</Title>
								<Text size="md" color="text/secondary/default">
									Create a new version of your integration and submit it to the
									marketplace
								</Text>
							</Stack>
							<Divider />
						</>
					)}

					<MarketplaceIntegrationSpecVersionForm
						form={form}
						inSteps={!existingSpec}
						finalStepButtonLabel="Create integration"
						finalStepSubmit={submit}
					/>
					{!!existingSpec && (
						<>
							<Divider />
							<Group position="right">
								<Button
									onClick={submit}
									variant="primary"
									loading={isCreatingSpec || isCreatingVersion}
								>
									Create new version
								</Button>
							</Group>
						</>
					)}
				</Stack>
			</Container>
		</Stack>
	);
}

function NewMarketplaceIntegrationSpecVersionPage() {
	const { specId } = useParams();

	const { data: spec } = useMarketplaceIntegrationSpec({
		id: specId || '',
		options: {
			enabled: Boolean(specId),
		},
	});

	if (specId && !spec) {
		return <FullWidthLoadingSpinner />;
	}

	return <Content existingSpec={spec} />;
}

export default observer(NewMarketplaceIntegrationSpecVersionPage);
