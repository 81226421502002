import { Group, createStyles } from '@mantine/core';
import { IconButton } from '@repo/foundations';
import { colors } from '@repo/theme/primitives';
import { uuidv4 } from 'lib0/random';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo } from 'react';
import type { Automation } from '../../../../../api';
import { AutomationFilterOperator } from '../../../../../api';
import { useAutomationStore } from '../../../../../pages/AutomationPage/context';
import { isValidEnumValue } from '../../../../../utils/enumUtils';
import { AutomationActionCardType } from '../../../constants';
import AutomationCardButton from '../../AutomationCardButton';
import { doesCardExist } from '../../utils';
import {
	AUTOMATION_FILTER_OPERATORS,
	AUTOMATION_FILTER_SET_OPERATORS,
} from './constants';
import { useAutomationActionCardFilterStore } from './context';
import { getFilterSetForOperatorUpdate } from './utils';

const useStyles = createStyles((theme) => ({
	root: {
		justifyContent: 'space-between',
		width: '100%',
	},
	group: {
		alignSelf: 'stretch',
		background: colors.gray[1],
		width: '100%',
		gap: theme.spacing['2xs'],
	},
}));

interface AutomationActionCardFilterHeaderTextProps {
	filterSetSearchKey?: string;
	isSecondFilterResourceCard?: boolean;
	isNthFilterResourceCard?: boolean;
	cardId: string;
	handleAutomationUpdate: (automation: Partial<Automation>) => Promise<void>;
}

function AutomationActionCardFilterHeaderText({
	filterSetSearchKey,
	isSecondFilterResourceCard,
	isNthFilterResourceCard,
	cardId,
	handleAutomationUpdate,
}: AutomationActionCardFilterHeaderTextProps) {
	const { classes } = useStyles();
	const store = useAutomationActionCardFilterStore();
	const automationStore = useAutomationStore();
	const { automation, cards } = automationStore;

	if (!automation) {
		throw new Error('Automation is not set');
	}

	useEffect(() => {
		if (automation.filter_config) {
			const existingFilterSet = automation.filter_config.filter_sets.filter(
				(set) => set.key === filterSetSearchKey
			);

			if (existingFilterSet.length > 0) {
				store.filterOperator = existingFilterSet[0].operator;
			}
		}
	}, [store, filterSetSearchKey, automation.filter_config]);

	const handleFilterSetOperatorOnClick = async (option: string | boolean) => {
		if (
			typeof option === 'string' &&
			isValidEnumValue(AutomationFilterOperator, option)
		) {
			await handleAutomationUpdate({
				filter_config: {
					...automation.filter_config,
					operator: option,
					filter_sets: automation.filter_config?.filter_sets ?? [],
				},
			});

			return;
		}

		throw new Error('Invalid automation operator has been provided');
	};

	const handleFilterOperatorOnClick = async (option: string | boolean) => {
		if (
			typeof option === 'string' &&
			isValidEnumValue(AutomationFilterOperator, option)
		) {
			const newFilterSetKey = uuidv4();
			const newFilterSets = getFilterSetForOperatorUpdate(
				option,
				automation,
				newFilterSetKey,
				filterSetSearchKey
			);

			await handleAutomationUpdate({
				filter_config: {
					...automation.filter_config,
					filter_sets: newFilterSets,
					operator:
						automation.filter_config?.operator ?? AutomationFilterOperator.AND,
				},
			});
			store.filterOperator = option;

			// This should only occur when an automation was created in v1
			if (!filterSetSearchKey) {
				automationStore.updateKey(newFilterSetKey);
			}

			return;
		}

		throw new Error('Invalid automation operator has been provided');
	};

	const filterSet = useMemo(() => {
		if (filterSetSearchKey) {
			return (
				automation.filter_config?.filter_sets.filter(
					(set) => set.key === filterSetSearchKey
				) ?? []
			);
		}

		if (!filterSetSearchKey && automation.filter_config?.filter_sets) {
			return automation.filter_config.filter_sets;
		}

		return [];
	}, [automation, filterSetSearchKey]);

	const filterSetOperatorLabel =
		AUTOMATION_FILTER_SET_OPERATORS.find(
			(operator) => operator.value === automation.filter_config?.operator
		)?.label ?? AUTOMATION_FILTER_SET_OPERATORS[0].label;

	const filterOperatorLabel = useMemo(() => {
		if (filterSet.length > 0) {
			return AUTOMATION_FILTER_OPERATORS.find(
				(operator) => operator.value === filterSet[0].operator
			)?.label;
		}

		return undefined;
	}, [filterSet]);

	const handleFilterResourceCardDelete = async () => {
		const isExistingKey =
			automation?.filter_config?.filter_sets &&
			automation?.filter_config?.filter_sets.filter(
				(set) => set.key === filterSetSearchKey
			)?.length >= 1;

		/**
		 * If a key does not exist, ignore updating the db as
		 * the user is trying to delete a card that was never saved
		 *
		 * This is possible when a user adds a new filter resource card
		 * but does not add any filters before deleting it
		 */
		if (isExistingKey) {
			const operator = automation.filter_config?.operator;

			if (!operator) {
				throw new Error('Automation operator is not set');
			}

			const existingFilterSetLength =
				automation.filter_config?.filter_sets.length;

			if (
				typeof existingFilterSetLength !== 'number' &&
				!existingFilterSetLength
			) {
				throw new Error('Automation filter sets are not set');
			}

			const newFilterSet = automation?.filter_config?.filter_sets.filter(
				(set) => set.key !== filterSetSearchKey
			);

			await handleAutomationUpdate({
				filter_config:
					existingFilterSetLength > 1
						? {
								operator,
								filter_sets: newFilterSet ?? [],
							}
						: null,
			});
		}

		automationStore.removeCard(
			AutomationActionCardType.FILTER_RESOURCES,
			cardId
		);
	};

	const doesEditCardExist = doesCardExist(
		automationStore.cards,
		AutomationActionCardType.EDIT_RESOURCES
	);
	const doesPropagateMetadataCardExist = doesCardExist(
		automationStore.cards,
		AutomationActionCardType.PROPAGATE_METADATA
	);

	if (isNthFilterResourceCard) {
		return (
			<Group className={classes.root} noWrap>
				<Group className={classes.group} noWrap>
					{isSecondFilterResourceCard ? (
						<AutomationCardButton
							buttonLabel={filterSetOperatorLabel}
							buttonOptions={AUTOMATION_FILTER_SET_OPERATORS}
							onClick={handleFilterSetOperatorOnClick}
						/>
					) : (
						`${filterSetOperatorLabel}${' '}`
					)}
					where
					<AutomationCardButton
						buttonLabel={
							filterOperatorLabel ?? AUTOMATION_FILTER_OPERATORS[0].label
						}
						buttonOptions={AUTOMATION_FILTER_OPERATORS}
						onClick={handleFilterOperatorOnClick}
					/>
					of the following is true
				</Group>
				<IconButton
					variant="tertiary"
					onClick={handleFilterResourceCardDelete}
					iconName="x"
				/>
			</Group>
		);
	}

	return (
		<Group className={classes.root} noWrap>
			<Group className={classes.group} noWrap>
				Filter resources where
				<AutomationCardButton
					buttonLabel={
						filterOperatorLabel ?? AUTOMATION_FILTER_OPERATORS[0].label
					}
					buttonOptions={AUTOMATION_FILTER_OPERATORS}
					onClick={handleFilterOperatorOnClick}
				/>
				of the following is true
			</Group>
			{!doesEditCardExist && !doesPropagateMetadataCardExist && (
				<IconButton
					variant="tertiary"
					onClick={handleFilterResourceCardDelete}
					iconName="x"
				/>
			)}
		</Group>
	);
}

export default observer(AutomationActionCardFilterHeaderText);
