import { Group } from '@mantine/core';
import { toJS } from 'mobx';

import { OPERATORS_CONFIG } from '@repo/common/components/Filter/constants.ts';
import { observer } from 'mobx-react-lite';
import { useContext, useMemo } from 'react';
import { FILTER_OPTIONS_CONFIG } from '../../../components/Filter/constants.tsx';
import {
	AddFilter,
	Filter,
	SearchFilterV2StoreContext,
} from '../../../components/Filter/index.ts';

function MeasurementsFilterBar() {
	const store = useContext(SearchFilterV2StoreContext);
	const {
		onAddValue,
		onChangeValue,
		onClearValue,
		values: filters,
		filterOptions,
	} = store;

	const options = useMemo(
		() =>
			filterOptions.filter(
				(f) =>
					filters.find(
						(filter) => f !== 'divider' && filter.filterType === f.type
					) === undefined
			),
		[filterOptions, filters]
	);

	return (
		<Group spacing="xs">
			{filters.map((value, idx) => (
				<Filter
					key={`filter-${value.filterType}}`}
					filterOption={FILTER_OPTIONS_CONFIG[value.filterType]}
					value={toJS(value)}
					onChange={onChangeValue(idx)}
					onClear={onClearValue(idx)}
					showDetailedLabel
					closeOnItemClick
					operatorConfig={
						OPERATORS_CONFIG[
							FILTER_OPTIONS_CONFIG[value.filterType].filterDropdownConfig
								.dropdownType
						]
					}
				/>
			))}
			{options.length > 0 && (
				<AddFilter options={options} onAddFilter={onAddValue} />
			)}
		</Group>
	);
}

export default observer(MeasurementsFilterBar);
