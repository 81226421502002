import type { MantineThemeOverride } from '@mantine/core';

export const Table: MantineThemeOverride['components'] = {
	Table: {
		styles: (theme) => ({
			root: {
				'& > caption': {
					fontSize: theme.fontSizes.xs,
				},

				'& > thead > tr > th, & > tfoot > tr > th, & > tbody > tr > th': {
					fontWeight: 500,
					fontSize: theme.fontSizes.xs,
					padding: theme.spacing.xs,
				},

				'& > tbody > tr > td': {
					fontSize: theme.fontSizes.xs,
				},

				'& > tbody > tr > td[align=right], & > thead > tr > th[align=right]': {
					textAlign: 'right',
				},

				'& > thead > tr, & > tfoot > tr, & > tbody > tr': {
					'&> th:first-of-type, &> td:first-of-type': {
						padding: `${theme.spacing.xs} ${theme.spacing.xs} ${theme.spacing.xs} ${theme.spacing.md}`,
					},

					'&> th:last-of-type, &> td:last-of-type': {
						padding: `${theme.spacing.xs} ${theme.spacing.md} ${theme.spacing.xs} ${theme.spacing.xs}`,
					},
				},
			},
		}),
	},
};
