import { useLocalStorageState } from 'ahooks';

export function useLocalStorageBooleanState(key: string, defaultValue = false) {
	const [value, setValue] = useLocalStorageState<boolean>(key, {
		defaultValue: (): boolean => {
			const currentValue = localStorage.getItem(key);
			if (currentValue === null) {
				return defaultValue;
			}
			return Boolean(value);
		},
	});

	const handlers = {
		setTrue: () => setValue(true),
		setFalse: () => setValue(false),
		toggle: () => setValue((prev) => !prev),
	};

	return [value, handlers] as const;
}
