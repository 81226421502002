import type { MantineThemeOverride } from '@mantine/core';
import { rem } from '@mantine/core';

export const Tabs: MantineThemeOverride['components'] = {
	Tabs: {
		styles: (theme, params, context) => {
			if (context.variant === 'pills') {
				return {
					tabList: {
						rowGap: theme.other.space[1],
					},
					tab: {
						padding: `${rem(theme.other.space[1])} ${rem(
							theme.other.space[2]
						)}`,
						color: theme.other.getColor('text/primary/default'),
						backgroundColor: theme.other.getColor('fill/transparent/default'),
						'&:hover': {
							backgroundColor: theme.other.getColor('fill/transparent/hover'),
						},
						'&[data-active]': {
							color: theme.other.getColor('text/primary/default'),
							backgroundColor: theme.other.getColor('fill/transparent/active'),
						},
						'&[data-active]:hover': {
							backgroundColor: theme.other.getColor('fill/transparent/active'),
						},
					},
				};
			}

			return {
				tabList: {},
				tab: {},
			};
		},
	},
};
