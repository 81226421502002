import {
	baseQueryHooksFactory,
	createQueryKeys,
	prefetchFunctionsFactory,
} from '../../factories';
import type { IEvent } from '../../types';

export const EVENTS_NAMESPACE = ['event', 'events'];

export const eventsQueryKeyFactory = createQueryKeys(EVENTS_NAMESPACE);

const { prefetchEvent, prefetchEventList } = prefetchFunctionsFactory(
	'event',
	eventsQueryKeyFactory
);

const {
	useEvent,
	useEventInfiniteList,
	useEventList,
	useCreateEvent,
	useDeleteEvent,
	useUpdateEvent,
	fetchEventList,
	updateEvent,
} = baseQueryHooksFactory<IEvent, 'event'>('event', eventsQueryKeyFactory);

export {
	fetchEventList,
	prefetchEvent,
	prefetchEventList,
	updateEvent,
	useCreateEvent,
	useDeleteEvent,
	useEvent,
	useEventInfiniteList,
	useEventList,
	useUpdateEvent,
};
