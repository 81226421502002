import type { MantineTheme } from '@mantine/core';
// NOTE: ideally we avoid use of `@mantine/core/Button` in favour of `@repo/foundations/Button`.
// eslint-disable-next-line no-restricted-imports
import { Avatar, Button, createStyles, Group } from '@mantine/core';
import { Text } from '@repo/foundations';
import { map, size, slice } from 'lodash-es';
import type { ForwardedRef } from 'react';
import { forwardRef } from 'react';

import type { ResourceSelectorItem } from './types';

interface IMultiResourceTargetProps {
	selected: ResourceSelectorItem[];
}

const useStyles = createStyles((theme: MantineTheme) => ({
	buttonRoot: {
		display: 'flex',
		width: '100%',
		height: 'fit-content',
		borderWidth: 0,
		paddingLeft: 8,
		paddingRight: 8,
		paddingTop: 6,
		paddingBottom: 6,
		'&:hover': {
			'.mantine-Avatar-root': {
				outlineColor: theme.colors.gray[1],
			},
		},
		'&:active': {
			'.mantine-Avatar-root': {
				outlineColor: theme.colors.gray[2],
			},
		},
	},
	avatarRoot: {
		backgroundColor: theme.colors.gray[2],
		outlineWidth: 2,
		outlineStyle: 'solid',
		outlineColor: 'white',
	},
}));

const MultiResourceTarget = forwardRef(
	(
		{ selected, ...others }: IMultiResourceTargetProps,
		ref: ForwardedRef<HTMLButtonElement>
	) => {
		const { classes } = useStyles();

		return (
			<Button
				ref={ref}
				classNames={{
					root: classes.buttonRoot,
				}}
				variant="subtle"
				size="md"
				fullWidth
				{...others}
			>
				{size(selected) === 0 && (
					<Text color="text/secondary/default" size="sm">
						Add resources
					</Text>
				)}
				{size(selected) === 1 && (
					<Group spacing={4}>
						{selected[0].icon}
						<Text size="sm">{selected[0].label}</Text>
					</Group>
				)}
				{size(selected) > 1 && (
					<Avatar.Group spacing="xs">
						{map(slice(selected, 0, 3), (resource) => (
							<Avatar
								key={resource.value}
								classNames={{
									root: classes.avatarRoot,
								}}
								size="xs"
							>
								{resource.icon}
							</Avatar>
						))}
						<Text size="sm" ml={4}>
							{`${size(selected)} resources`}
						</Text>
					</Avatar.Group>
				)}
			</Button>
		);
	}
);
MultiResourceTarget.displayName = 'MultiResourceTarget';

export default MultiResourceTarget;
