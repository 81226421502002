import { createStyles } from '@mantine/core';

export const useStyles = createStyles(
	(
		theme,
		{
			isHovered,
			isFirst,
			isLast,
			hasImpactedEntities,
			hasNextPage,
			opened,
		}: {
			isHovered: boolean;
			isFirst: boolean;
			isLast: boolean;
			hasImpactedEntities: boolean;
			hasNextPage: boolean;
			opened: boolean;
		}
	) => {
		let wrapperBorderRadius = {
			borderTopLeftRadius: 'unset',
			borderTopRightRadius: 'unset',
			borderBottomLeftRadius: 'unset',
			borderBottomRightRadius: 'unset',
		};

		if (isFirst) {
			wrapperBorderRadius = {
				...wrapperBorderRadius,
				borderTopLeftRadius: theme.radius.md,
				borderTopRightRadius: theme.radius.md,
			};
			if (!hasImpactedEntities || !opened) {
				wrapperBorderRadius = {
					...wrapperBorderRadius,
					borderBottomLeftRadius: theme.radius.md,
					borderBottomRightRadius: theme.radius.md,
				};
			}
		}

		if (isLast) {
			wrapperBorderRadius = {
				...wrapperBorderRadius,
				borderBottomLeftRadius: hasNextPage ? 'unset' : theme.radius.md,
				borderBottomRightRadius: hasNextPage ? 'unset' : theme.radius.md,
			};
		}

		return {
			wrapper: {
				backgroundColor: isHovered
					? theme.other.getColor('surface/primary/hover')
					: theme.other.getColor('surface/primary/default'),

				...wrapperBorderRadius,
			},
			cardWrapper: {
				width: '100%',
				borderTopLeftRadius: theme.radius.md,
				borderTopRightRadius: theme.radius.md,
				borderBottomLeftRadius: opened ? 0 : theme.radius.md,
				borderBottomRightRadius: opened ? 0 : theme.radius.md,
				'&:hover': {
					backgroundColor: theme.other.getColor('surface/primary/hover'),
				},
			},
			titleWrapper: {
				width: 460,
				flexGrow: 1,
			},
			chevronWrapper: {
				minWidth: theme.other.iconSize.md,
			},
		};
	}
);
