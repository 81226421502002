import type {
	ISecodaEntity,
	MeasurementChartData,
	Monitor,
	TimeRange,
} from '../../api';
import { useMeasurementsChartData, useMonitorList } from '../../api';
import type { SecodaEntity } from '../../lib/models';
import { createMockableHook } from '../../utils/createMockableHook';

interface UseFreshnessProps {
	entity: SecodaEntity | ISecodaEntity;
	timeRange?: TimeRange;
}

interface UseFreshnessResult {
	monitor: Monitor | null;
	data: Array<MeasurementChartData>;
	loading: boolean;
	error: boolean;
}

function useFreshnessInternal({
	entity,
	timeRange = '7',
}: UseFreshnessProps): UseFreshnessResult {
	const {
		data: monitors,
		isLoading: loadingMonitor,
		error: errorMonitor,
	} = useMonitorList({
		filters: {
			target_id: entity.id,
		},
	});

	const freshnessMonitor = monitors?.results?.find(
		(monitor) => monitor.metric_type === 'freshness'
	);

	const {
		data: measurements,
		isLoading: loadingData,
		error: errorData,
	} = useMeasurementsChartData({
		monitorId: freshnessMonitor?.id,
		timeRange,
	});

	return {
		monitor: freshnessMonitor ?? null,
		data: measurements ?? [],
		loading: loadingMonitor || (!!freshnessMonitor?.id && loadingData),
		error: !!errorMonitor || !!errorData,
	};
}

export const [useFreshness, MockUseFreshnessProvider] =
	createMockableHook(useFreshnessInternal);
