import { SimpleGrid, Stack, Tabs, TextInput } from '@mantine/core';
import { useDebouncedValue, useInputState } from '@mantine/hooks';
import { map } from 'lodash-es';
import { useUnsplashImages } from '../../../api';
import ColorPaletteChip from './ColorPaletteChip';
import type { IDefaultColorPickerPanelProps } from './DefaultColorPickerPanel';

type IUnsplashCoverPickerPanelProps = IDefaultColorPickerPanelProps;

function UnsplashCoverPickerPanel({
	onBackgroundImageChange,
}: IUnsplashCoverPickerPanelProps) {
	const [searchTerm, setSearchTerm] = useInputState('');
	const [debouncedSearchTerm] = useDebouncedValue(searchTerm, 500);

	const { data: images } = useUnsplashImages(debouncedSearchTerm);

	const handleBackgroundImageChange =
		(image: string, fontColor: string) => () => {
			onBackgroundImageChange(image, fontColor);
		};

	return (
		<Tabs.Panel value="unsplash">
			<Stack p="md">
				<TextInput
					placeholder="Search for an image..."
					value={searchTerm}
					onChange={setSearchTerm}
				/>
				<SimpleGrid cols={4} spacing="xs">
					{map(images, ({ id, image, thumbnail, fontColor }) => (
						<ColorPaletteChip
							key={id}
							backgroundImage={image}
							thumbnail={thumbnail}
							onClick={handleBackgroundImageChange(image, fontColor)}
						/>
					))}
				</SimpleGrid>
			</Stack>
		</Tabs.Panel>
	);
}

export default UnsplashCoverPickerPanel;
