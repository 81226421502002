import { ActionIcon, Box, createStyles, Group, TextInput } from '@mantine/core';
import { IconButton } from '@repo/foundations';
import type { IconNames } from '@repo/foundations/components/Icon/Icon';
import {
	Icon,
	IconSizeMap,
	TablerIconMap,
} from '@repo/foundations/components/Icon/Icon';
import React, { useState } from 'react';

const DEFAULT_COLOR_OPTIONS = [
	'#4a4a4a',
	'#8a8a8a',
	'#f28c28',
	'#dfba3e',
	'#5caf8a',
	'#53a2b6',
	'#2a64c5',
	'#2f2db2',
	'#8a30c7',
	'#af3794',
	'#d32d36',
];

const ICONS_TO_EXCLUDE = [
	// Custom icons that cannot be colored, so we exclude them
	'priorityLow',
	'priorityMedium',
	'priorityHigh',
	'priorityNone',

	// These icons also have issues with coloring
	'filter',
	'infoCircleFilled',
	'messageFilled',
	'sparklesFilled',

	// This icon displays as an empty box, so we exclude it
	'smallX',
];

const useStyles = createStyles((theme, { color }: { color: string }) => ({
	header: {
		position: 'sticky',
		top: '0',
		zIndex: 100,
		backgroundColor: 'white',
		paddingBottom: theme.spacing.xs,
	},
	chooseColorBar: {
		paddingLeft: '4px',
		paddingRight: '4px',
		paddingTop: theme.spacing.xs,
		paddingBottom: theme.spacing.md,
	},
	colorCircle: {
		borderRadius: theme.radius.xl,
		width: IconSizeMap['md'],
		height: IconSizeMap['md'],
	},
	chosenColorCircle: {
		position: 'absolute',
		cursor: 'pointer',
		width: '12px',
		height: '12px',
		backgroundColor: 'white',
		borderRadius: theme.radius.xl,
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
	},
	switchColorButton: {
		marginLeft: 'auto',
		cursor: 'pointer',
		border: `solid 1px ${theme.other.getColor('border/primary/default')}`,
		borderRadius: theme.radius.xl,
		width: '24px',
		height: '24px',
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'center',
		color: theme.colors.gray[6],
	},
	hexTextInput: {
		width: '75px',
		height: '24px',
		display: 'flex',
		alignItems: 'center',
	},
	mainContainer: {
		maxWidth: '352px',
		minWidth: '352px',
		maxHeight: '435px',
		minHeight: '435px',
		overflow: 'auto',
	},
	input: {
		fontSize: '15px',
		width: 'calc(100% - 3px)',
		margin: '0 auto',
	},
	iconButton: {
		color: color,
		'&:hover': {
			color: color,
		},
	},
}));

interface IconSelectorValue {
	iconName: IconNames;
	iconColor: string;
}

interface IconSelectorProps {
	onSelect: (selectedValue: IconSelectorValue) => void;
	value?: IconSelectorValue;
}

export function IconSelector({ onSelect, value }: IconSelectorProps) {
	const [color, setColor] = useState(
		value?.iconColor ?? DEFAULT_COLOR_OPTIONS[0]
	);
	const [showColorCircle, setShowColorCircle] = useState(true);
	const [search, setSearch] = useState('');
	const { classes } = useStyles({ color });

	const allTablerIcons = Object.keys(TablerIconMap) as IconNames[];

	const filteredIcons = allTablerIcons
		.filter((name) => !ICONS_TO_EXCLUDE.includes(name))
		.filter((name) => name.toLowerCase().includes(search.toLowerCase()));

	const handleColorInputChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		const inputValue = event.target.value.trim();
		setColor(inputValue);
	};

	return (
		<Box className={classes.mainContainer}>
			<Box className={classes.header}>
				<Group spacing="xs" className={classes.chooseColorBar}>
					{showColorCircle ? (
						<>
							{DEFAULT_COLOR_OPTIONS.map((col) => (
								<div key={col} style={{ position: 'relative' }}>
									<ActionIcon
										size={21}
										variant="filled"
										style={{ backgroundColor: col }}
										className={classes.colorCircle}
										onClick={() => setColor(col)}
									/>
									{color === col && (
										<div className={classes.chosenColorCircle} />
									)}
								</div>
							))}
							<div
								className={classes.switchColorButton}
								onClick={() => setShowColorCircle(false)}
							>
								#
							</div>
						</>
					) : (
						<Group spacing="xs" align="center" w="100%">
							<ActionIcon
								size={21}
								variant="filled"
								style={{ backgroundColor: color }}
								className={classes.colorCircle}
							/>
							<TextInput
								value={color}
								onChange={handleColorInputChange}
								placeholder="#"
								size="xs"
								className={classes.hexTextInput}
							/>
							<div
								className={classes.switchColorButton}
								style={{
									background: 'conic-gradient(red, yellow, green, blue, red)',
								}}
								onClick={() => setShowColorCircle(true)}
							/>
						</Group>
					)}
				</Group>
				<TextInput
					autoFocus
					placeholder="Search icons"
					icon={<Icon name="search" color="icon/secondary/default" />}
					value={search}
					mb="sm"
					classNames={{ input: classes.input }}
					onChange={(event) => {
						setSearch(event.currentTarget.value);
					}}
				/>
			</Box>
			<Group spacing={0} position="center">
				{filteredIcons.map((name) => (
					<IconButton
						variant="tertiary"
						key={name}
						iconName={name}
						size="lg"
						className={classes.iconButton}
						onClick={() => onSelect({ iconName: name, iconColor: color })}
					/>
				))}
			</Group>
		</Box>
	);
}
