/* eslint-disable no-plusplus */
import type { QueryKey } from '@tanstack/react-query';

export function isQueryKeyPrefix(array: QueryKey, prefix: QueryKey): boolean {
	if (prefix.length > array.length) {
		return false;
	}

	for (let i = 0; i < prefix.length; i++) {
		if (array[i] !== prefix[i]) {
			return false;
		}
	}

	return true;
}

export function isQueryKeySuffix(array: QueryKey, suffix: QueryKey): boolean {
	if (suffix.length > array.length) {
		return false;
	}

	for (let i = 0; i < suffix.length; i++) {
		if (array[array.length - 1 - i] !== suffix[suffix.length - 1 - i]) {
			return false;
		}
	}

	return true;
}
