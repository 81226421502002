import { EntityType } from '@repo/common/enums/entityType';
import type { BadgeVariant } from '@repo/foundations';

export const ENTITY_TYPE_VARIANT_MAP: Record<string, BadgeVariant> = {
	[EntityType.table]: 'info',
	[EntityType.column]: 'info',
	[EntityType.dashboard]: 'ai',
	[EntityType.chart]: 'success',
	[EntityType.job]: 'warning',
	[EntityType.event]: 'critical',
	[EntityType.collection]: 'info',
	[EntityType.dictionary_term]: 'info',
	[EntityType.metric]: 'info',
	[EntityType.document]: 'default',
	[EntityType.question]: 'warning',
};

export type NodeType = 'entityNode' | 'aggregateNode';
