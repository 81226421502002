import type { DefaultProps, Selectors } from '@mantine/core';
import { createStyles, Grid, Modal, ScrollArea } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { Button, Title } from '@repo/foundations';
import { width } from '@repo/theme/primitives';
import { observer } from 'mobx-react-lite';
import type { ReactNode } from 'react';
import { useFeatureAccess } from '../../api/hooks/workspace/useFeatureAccess';
import { useFeatureFlags } from '../../utils/featureFlags';
import AddMonitorModal from '../AddMonitorV2/AddMonitorModal/AddMonitorModal';
import { useStyles as useAddMonitorStyles } from '../AddMonitorV2/AddMonitorModal/AddMonitorModal.styles';
import { ADD_MONITOR_MODAL_ID } from '../AddMonitorV2/AddMonitorModal/constants';
import { openModal } from '../ModalManager';
import AddMonitorFooter from './AddMonitorFooter';
import AddMonitorForm from './AddMonitorForm';
import AddMonitorHeader from './AddMonitorHeader';
import type { AddMonitorStoreProviderProps } from './context';
import { AddMonitorStoreProvider } from './context';
import MonitorTypeSelector from './MonitorTypeSelector';

const useStyles = createStyles({
	root: {},
	modalContent: {
		overflow: 'hidden',
	},
});

type AddMonitorButtonStylesNames = Selectors<typeof useStyles>;

export interface AddMonitorProps
	extends DefaultProps<AddMonitorButtonStylesNames>,
		Omit<AddMonitorStoreProviderProps, 'children'> {
	withIcon?: boolean;
	customTarget?: ReactNode;
}

function AddMonitorButton({
	initialTable,
	initialIntegration,
	initialMetricType,
	initialSearchTerm,
	withIcon = false,
	className,
	classNames,
	styles,
	unstyled,
	customTarget,
	...others
}: AddMonitorProps) {
	const { classes, cx } = useStyles(undefined, {
		classNames,
		styles,
		unstyled,
		name: 'AddMonitorButton',
	});
	const { classes: addMonitorClasses } = useAddMonitorStyles();

	const { monitoringAccess } = useFeatureAccess();
	const { monitorCreationFlow } = useFeatureFlags();

	const [opened, { open, close }] = useDisclosure(false);

	const handleClose = () => {
		close();
	};

	const handleOpen = () => {
		if (monitorCreationFlow) {
			openModal({
				modalId: ADD_MONITOR_MODAL_ID,
				title: <Title size="md">Select monitor type</Title>,
				children: (
					<AddMonitorModal
						initialTable={initialTable}
						initialIntegration={initialIntegration}
					/>
				),
				size: width.xxl,
				radius: 'lg',
				classNames: {
					header: addMonitorClasses.header,
					body: addMonitorClasses.body,
				},
			});
		} else {
			open();
		}
	};

	if (!monitoringAccess) {
		return null;
	}

	return (
		<AddMonitorStoreProvider
			initialTable={initialTable}
			initialIntegration={initialIntegration}
			initialMetricType={initialMetricType}
			initialSearchTerm={initialSearchTerm}
		>
			{customTarget ?? (
				<Button
					className={cx(classes.root, className)}
					onClick={handleOpen}
					leftIconName={withIcon ? 'activity' : undefined}
					variant="primary"
					{...others}
				>
					Create monitor
				</Button>
			)}
			<Modal.Root opened={opened} onClose={handleClose} size="xl">
				<Modal.Overlay />
				<Modal.Content className={classes.modalContent}>
					<Grid>
						<Grid.Col span={4}>
							<MonitorTypeSelector />
						</Grid.Col>
						<Grid.Col span={8}>
							<AddMonitorHeader />
							<Modal.Body>
								<ScrollArea h="70vh" offsetScrollbars scrollbarSize={8} pr="xs">
									<AddMonitorForm />
								</ScrollArea>
							</Modal.Body>
						</Grid.Col>
					</Grid>
					<AddMonitorFooter onClose={handleClose} />
				</Modal.Content>
			</Modal.Root>
		</AddMonitorStoreProvider>
	);
}

export default observer(AddMonitorButton);
