import { debounce } from 'lodash-es';
import { makeAutoObservable } from 'mobx';
import {
	COLLAPSED_NAV_SIDEBAR_WIDTH,
	EXPANDED_NAV_MIN_SIDEBAR_WIDTH,
	NAV_SIDEBAR_LOCAL_STORAGE_PROPERTY_PREFIX,
} from '../constants';

export class SideBarStore {
	collapsed: boolean | null = null;

	width: number;

	settingsOpen = false;

	showSideBar = true;

	setCollapsed = (shouldCollapse: boolean) => {
		if (shouldCollapse) {
			localStorage.setItem(
				`${NAV_SIDEBAR_LOCAL_STORAGE_PROPERTY_PREFIX}Collapsed`,
				'true'
			);
			this.width = COLLAPSED_NAV_SIDEBAR_WIDTH;
		} else {
			localStorage.setItem(
				`${NAV_SIDEBAR_LOCAL_STORAGE_PROPERTY_PREFIX}Collapsed`,
				'false'
			);
			this.width = EXPANDED_NAV_MIN_SIDEBAR_WIDTH;
		}

		this.collapsed = shouldCollapse;
	};

	setWidthLocalStorageDebounce = debounce((width: number) => {
		localStorage.setItem(
			`${NAV_SIDEBAR_LOCAL_STORAGE_PROPERTY_PREFIX}Width`,
			width.toString()
		);
	}, 500);

	setWidth = (updatedWidth: number) => {
		this.width = updatedWidth;
		this.setWidthLocalStorageDebounce(updatedWidth);
	};

	setSettingsOpen = (settingsOpen: boolean) => {
		this.settingsOpen = settingsOpen;
	};

	toggle = () => {
		this.collapsed = !this.collapsed;
	};

	setShowSideBar = (showSideBar: boolean) => {
		this.showSideBar = showSideBar;
	};

	constructor() {
		const localStorageWidth = localStorage.getItem(
			`${NAV_SIDEBAR_LOCAL_STORAGE_PROPERTY_PREFIX}Width`
		);

		const initialCollapsed =
			localStorage.getItem(
				`${NAV_SIDEBAR_LOCAL_STORAGE_PROPERTY_PREFIX}Collapsed`
			) === 'true';

		this.width = localStorageWidth
			? parseInt(localStorageWidth, 10)
			: EXPANDED_NAV_MIN_SIDEBAR_WIDTH;

		if (initialCollapsed) {
			this.setCollapsed(true);
		}

		makeAutoObservable(this);
	}
}

export const sideBarStore = new SideBarStore();
