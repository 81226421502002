import type { IResourceRelation } from '@repo/common/models/relation';
import {
	baseQueryHooksFactory,
	createQueryKeys,
	prefetchFunctionsFactory,
} from '../../factories';
import { useDeleteResourceRelation } from './useResourceRelationDelete';
import {
	invalidateResourceRelationList,
	useResourceRelationList,
} from './useResourceRelationList';

export const RESOURCE_RELATION_NAMESPACE = ['lineage', 'relations'];

export const resourceRelationsQueryKeyFactory = createQueryKeys(
	RESOURCE_RELATION_NAMESPACE
);

const { prefetchResourceRelation, prefetchResourceRelationList } =
	prefetchFunctionsFactory(
		'resourceRelation',
		resourceRelationsQueryKeyFactory
	);

const {
	useResourceRelation,
	useResourceRelationInfiniteList,
	useCreateResourceRelation,
	useUpdateResourceRelation,
	fetchResourceRelationList,
} = baseQueryHooksFactory<IResourceRelation, 'resourceRelation'>(
	'resourceRelation',
	resourceRelationsQueryKeyFactory
);

export {
	fetchResourceRelationList,
	invalidateResourceRelationList,
	prefetchResourceRelation,
	prefetchResourceRelationList,
	useCreateResourceRelation,
	useDeleteResourceRelation,
	useResourceRelation,
	useResourceRelationInfiniteList,
	useResourceRelationList,
	useUpdateResourceRelation,
};
