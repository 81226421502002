import type {
	QueryFunctionContext,
	QueryKey,
	UseQueryOptions,
} from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { apiClient, getEndpoints } from '../../common';
import { createQueryKeys } from '../../factories';
import type { Namespace } from '../../types';
import { useWorkspace } from '../workspace';

const SAML_NAMESPACE = ['workspace'];
export const SamlsQueryKeyFactory = createQueryKeys(SAML_NAMESPACE);

type SamlType = 'google' | 'microsoft' | 'okta' | 'generic';

interface ISaml {
	ENTITY_ID: string;
	METADATA_AUTO_CONF_URL: string;
	type: SamlType;
}

export function getSamlQueryFn(id: string, namespace: Namespace) {
	const defaultQueryFn = async ({ signal }: QueryFunctionContext<QueryKey>) => {
		const url = `${getEndpoints(namespace).byPath([id, 'saml'])}`;

		const { data } = await apiClient.get<ISaml>(url, { signal });

		let type: SamlType = 'generic';
		if (data.METADATA_AUTO_CONF_URL?.includes('microsoftonline.')) {
			type = 'microsoft';
		} else if (data.METADATA_AUTO_CONF_URL?.includes('okta.')) {
			type = 'okta';
		} else if (data.METADATA_AUTO_CONF_URL?.includes('google.')) {
			type = 'google';
		}
		return { ...data, type };
	};

	return defaultQueryFn;
}

interface IUseSamlArgs {
	id?: string;
	options?: UseQueryOptions<ISaml>;
}

export function useSaml({ options }: IUseSamlArgs = {}) {
	const { workspace } = useWorkspace();
	const queryKey = SamlsQueryKeyFactory.namespace;
	const queryFn = getSamlQueryFn(workspace.id, SamlsQueryKeyFactory.namespace);
	return useQuery<ISaml>(queryKey, queryFn, {
		...options,
		suspense: false,
	});
}
