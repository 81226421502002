export const DARWIN_HOMES_WORKSPACE_ID = '2dec8569-dfcf-4380-b5ca-ef8b8c71ac8a';

export function isCronNotMoreFrequentThanOnceADay(
	cronExpression: string
): boolean {
	const parts: string[] = cronExpression.trim().split(/\s+/);

	// Ensure the cron expression has either 5 or 6 parts
	if (parts.length < 5 || parts.length > 6) {
		throw new Error('Invalid cron expression');
	}

	const minuteField: string = parts.length === 6 ? parts[1] : parts[0];
	const hourField: string = parts.length === 6 ? parts[2] : parts[1];

	// If either the minute or the hour field is '*', the job is more frequent than once a day
	if (minuteField === '*' || hourField === '*') {
		return false;
	}

	// Function to check for ranges or intervals in the field
	const hasMultipleExecutions = (field: string): boolean => /-|\//.test(field);

	// Check if minute or hour fields contain ranges or intervals
	if (hasMultipleExecutions(minuteField) || hasMultipleExecutions(hourField)) {
		return false;
	}

	// The cron expression does not represent a schedule more frequent than once a day
	return true;
}

export function isValidCronExpression(cronExpression: string): boolean {
	// Regex to validate cron expressions
	const cronRegex: RegExp =
		/^(\*\/?\d+|\*|[0-5]?\d) (\*\/?\d+|\*|[0-5]?\d|[01]?\d|2[0-3]) (\*\/?\d+|\*|([01]?\d|2[0-3]|3[01])) (\*\/?\d+|\*|([1-9]|1[0-2])) (\*\/?\d+|\*|([0-6](-[0-6])?|\*[/\-,0-6]*)|([0-7](-[0-7])?|\*[/\-,0-7]*))$/;

	return cronRegex.test(cronExpression);
}

export function isValidCron(
	cronExpression: string,
	workspaceId: string
): boolean {
	// For darwin homes, we want to allow more frequent than once a day
	if (workspaceId === DARWIN_HOMES_WORKSPACE_ID) {
		return isValidCronExpression(cronExpression);
	}

	return (
		isValidCronExpression(cronExpression) &&
		isCronNotMoreFrequentThanOnceADay(cronExpression)
	);
}

export function cronValidationMessage(
	cronExpression: string,
	workspaceId: string
): string {
	if (!cronExpression) {
		return '';
	}

	if (!isValidCronExpression(cronExpression)) {
		return 'Invalid cron expression. Please refer to crontab.guru for help';
	}

	if (
		!isCronNotMoreFrequentThanOnceADay(cronExpression) &&
		workspaceId !== DARWIN_HOMES_WORKSPACE_ID
	) {
		return 'Cron expression must be daily or less frequent';
	}

	return '';
}
