export function MicrosoftIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
	return (
		<svg
			width="14"
			height="14"
			viewBox="0 0 14 14"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path d="M6.65 0H0V6.65H6.65V0Z" fill="#F25022" />
			<path d="M14 0H7.35001V6.65H14V0Z" fill="#7FBA00" />
			<path d="M6.65 7.35H0V14H6.65V7.35Z" fill="#00A4EF" />
			<path d="M14 7.35H7.35001V14H14V7.35Z" fill="#FFB900" />
		</svg>
	);
}

export default MicrosoftIcon;
