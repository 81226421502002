interface TTLCacheItem {
	expires: number;
	value: string;
}

/**
 * Concatenates multiple cache keys into a single string by joining them with a hyphen ("-").
 *
 * @param keys - The cache keys to concatenate.
 * @returns The concatenated cache key.
 */
export const concatTTLCacheKeys = (
	...keys: (string | number | boolean)[]
): string => keys.join('-');

/**
 * Invalidates a TTL cache item by setting its value to an empty string
 * and updating the expiration time to a timestamp in the past.
 *
 * @param key - The key of the cache item to invalidate.
 */
export const invalidateTTLCacheItem = (key: string): void => {
	localStorage.setItem(
		key,
		JSON.stringify({
			value: '',
			expires: Date.now() - 1,
		})
	);
};

/**
 * Retrieves the expiration timestamp of a TTL cache item.
 *
 * @param key - The key of the cache item to retrieve the expiration timestamp for.
 * @returns The expiration timestamp of the cache item if it exists and can be parsed,
 *          otherwise null.
 */
export const getTTLCacheExpiration = (key: string): number | null => {
	try {
		const parsed: TTLCacheItem = JSON.parse(localStorage.getItem(key) || '{}');
		return parsed.expires;
	} catch (e) {
		return null;
	}
};

/**
 * Retrieves the value of a TTL cache item.
 *
 * @param key - The key of the cache item to retrieve.
 * @returns The value of the cache item if it is still valid and exists in the cache,
 *          otherwise an empty string.
 */
export const getTTLCacheItem = (key: string): string => {
	try {
		const parsed: TTLCacheItem = JSON.parse(localStorage.getItem(key) || '{}');
		if (parsed.expires < Date.now() || !parsed.value) {
			return '';
		}
		return parsed.value;
	} catch (e) {
		// If the item is not a valid TTL cache item, invalidate it.
		invalidateTTLCacheItem(key);
		return '';
	}
};

/**
 * Sets the value of a TTL cache item.
 *
 * @param key - The key of the cache item to set.
 * @param value - The value to be set for the cache item.
 * @param ttl - The time-to-live (TTL) duration in milliseconds.
 *              The cache item will expire after this duration has passed.
 */
export const setTTLCacheItem = (
	key: string,
	value: string,
	ttl: number
): void => {
	// If recently expired, ignore the new value.
	// This is a preventative measure for race conditions with invalidation.
	const existingExpiration = getTTLCacheExpiration(key);
	if (
		existingExpiration &&
		Date.now() > existingExpiration &&
		Math.abs(Date.now() - existingExpiration) < 3000
	) {
		return;
	}

	// Check if the value is already in the cache.
	const existingValue = getTTLCacheItem(key);
	if (existingValue === value) {
		return;
	}

	// Set the new value.
	localStorage.setItem(
		key,
		JSON.stringify({
			value,
			expires: Date.now() + ttl,
		})
	);
};
