import { Icon } from '@repo/foundations';
import type { EditorDictionary, MenuItem } from '@repo/secoda-editor';
import type { EditorState } from 'prosemirror-state';
import isNodeActive from '../queries/isNodeActive';

export default function dividerMenuItems(
	state: EditorState,
	dictionary: EditorDictionary
): MenuItem[] {
	const { schema } = state;

	return [
		{
			name: 'hr',
			tooltip: dictionary.pageBreak,
			attrs: { markup: '***' },
			active: isNodeActive(schema.nodes.hr, { markup: '***' }),
			icon: () => <Icon name="pageBreak" color="icon/primary/default" />,
		},
		{
			name: 'hr',
			tooltip: dictionary.hr,
			attrs: { markup: '---' },
			active: isNodeActive(schema.nodes.hr, { markup: '---' }),
			icon: () => (
				<Icon name="separatorHorizontal" color="icon/primary/default" />
			),
		},
	];
}
