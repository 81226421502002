import { Card, createStyles, Group, Stack } from '@mantine/core';
import { Icon, Text } from '@repo/foundations';
import { uuidv4 } from 'lib0/random';
import { useNavigate } from 'react-router';
import type { Automation } from '../../api';
import {
	AutomationFilterOperator,
	AutomationTriggerType,
	useAuthUser,
} from '../../api';
import { useCreateAutomation } from '../../api/hooks/automation';
import { automationQueryKeyFactory } from '../../api/hooks/automation/constants';
import { trackEvent } from '../../utils/analytics';
import type { createAutomationType } from './constants';
import { AUTOMATION_TEMPLATES } from './utils';

interface AddAutomationModalProps {
	onClose: () => void;
}

const useStyles = createStyles((theme) => ({
	group: {
		padding: 0,
	},
	content: {
		padding: theme.spacing.md,
		gap: theme.spacing.md,
	},
	iconContainer: {
		background: theme.other.getColor('surface/tertiary/default'),
		borderRadius: theme.radius.sm,
	},
	card: {
		display: 'flex',
		padding: theme.other.space['4'],
		flexDirection: 'column',
		alignItems: 'flex-start',
		gap: theme.spacing.sm,
		borderRadius: theme.radius.md,
		width: '296px', // Specific width to allow 2 cards per row
	},
	text: {
		color: theme.other.getColor('text/primary/default'),
	},
	title: {
		padding: 0,
	},
	input: {
		color: theme.other.getColor('text/primary/default'),
		fontSize: theme.fontSizes.xl,
		fontWeight: theme.other.typography.weight.semibold,
		lineHeight: theme.spacing.md,
		padding: `0 ${theme.spacing.xs}`,
		'&:focus': {
			outline: 'none',
			borderColor: 'transparent',
			boxShadow: 'none',
		},
	},
}));

function AddAutomationModal({ onClose }: AddAutomationModalProps) {
	const { classes } = useStyles();
	const { mutateAsync: apiCreateAutomation } = useCreateAutomation({
		invalidationKeys: [automationQueryKeyFactory.allLists()],
	});
	const navigate = useNavigate();
	const { user, workspace } = useAuthUser();

	const handleOnClick = async (input: createAutomationType) => {
		const {
			title,
			filters,
			actions,
			actionTarget,
			filterOperator,
			icon,
			propagateMetadata,
		} = AUTOMATION_TEMPLATES[input];

		let automation: Automation;

		if (input !== 'SCRATCH') {
			automation = await apiCreateAutomation({
				data: {
					title,
					is_enabled: false,
					icon,
					trigger_type: AutomationTriggerType.SCHEDULE,
					filter_config: {
						operator: AutomationFilterOperator.AND,
						filter_sets: [
							{
								operator: filterOperator,
								key: uuidv4(),
								filters,
							},
						],
					},
					action_target: actionTarget,
					action_update_entities: actions,
					action_propagate_metadata: propagateMetadata,
				},
			});
		} else {
			automation = await apiCreateAutomation({
				data: {
					title,
					is_enabled: false,
					icon,
					trigger_type: AutomationTriggerType.SCHEDULE,
				},
			});
		}

		onClose();
		trackEvent('automation/create', {}, user, workspace);
		navigate(`/automation/${automation.id}/`);
	};

	return (
		<Group className={classes.group}>
			<Stack className={classes.content}>
				<Card
					variant="clickable"
					className={classes.card}
					withBorder
					role="button"
					onClick={() => handleOnClick('SCRATCH')}
					radius="md"
				>
					<Group className={classes.iconContainer}>
						<Icon name="plus" />
					</Group>
					<Text className={classes.text} weight="bold" size="sm">
						Start from scratch
					</Text>
				</Card>
				<Stack>
					<Text className={classes.text} weight="bold" size="sm">
						Or start with a template
					</Text>
					<Group>
						{Object.entries(AUTOMATION_TEMPLATES)
							.filter(([k]) => k !== 'SCRATCH')
							.map(([key, template]) => (
								<Card
									key={key}
									variant="clickable"
									className={classes.card}
									withBorder
									role="button"
									onClick={() => handleOnClick(key as createAutomationType)}
									radius="md"
								>
									{template.icon}
									<Text className={classes.text} weight="bold" size="sm">
										{template.title}
									</Text>
								</Card>
							))}
					</Group>
				</Stack>
			</Stack>
		</Group>
	);
}

export default AddAutomationModal;
