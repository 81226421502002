import type { FilterValueType } from '@repo/common/components/Filter/types';
import {
	FilterDropdownType,
	FilterOperator,
} from '@repo/common/components/Filter/types';
import { makeAutoObservable } from 'mobx';
import type { FilterValue } from '../../../Filter';
import { FilterOptionType } from '../../../Filter';
import { FILTER_OPTIONS_CONFIG } from '../../../Filter/constants';
import { LineageDirectionEnum } from '../../types';

type FILTER_OPTION_TYPES =
	| FilterOptionType.NATIVE_TYPE
	| FilterOptionType.INTEGRATION_TYPE
	| FilterOptionType.DIRECTION;
const FILTERS_TO_PRELOAD = [
	FilterOptionType.NATIVE_TYPE,
	FilterOptionType.INTEGRATION_TYPE,
];

class ImpactAnalysisStore {
	id: string | undefined = undefined;

	opened = false;

	filterValues: Record<FILTER_OPTION_TYPES, FilterValue> = {
		[FilterOptionType.NATIVE_TYPE]: {
			filterType: FilterOptionType.NATIVE_TYPE,
			value: [],
			operator: FilterOperator.Is,
		},
		[FilterOptionType.INTEGRATION_TYPE]: {
			filterType: FilterOptionType.INTEGRATION_TYPE,
			value: [],
			operator: FilterOperator.Is,
		},
		[FilterOptionType.DIRECTION]: {
			filterType: FilterOptionType.DIRECTION,
			value: LineageDirectionEnum.DOWNSTREAM,
			operator: FilterOperator.Is,
		},
	};

	searchTerm = '';

	showSearch = false;

	constructor() {
		makeAutoObservable(this);
	}

	get queryParams() {
		return {
			direction: this.filterValues[FilterOptionType.DIRECTION].value as string,
			native_types: (
				(this.filterValues[FilterOptionType.NATIVE_TYPE].value ??
					[]) as FilterValueType[]
			).join(','),
			integration_types: (
				(this.filterValues[FilterOptionType.INTEGRATION_TYPE].value ??
					[]) as FilterValueType[]
			).join(','),
			include_columns: true,
		};
	}

	async initialize() {
		FILTERS_TO_PRELOAD.forEach((option) => {
			const filterConfig = FILTER_OPTIONS_CONFIG[option];

			if (
				filterConfig.filterDropdownConfig.dropdownType !==
				FilterDropdownType.List
			) {
				return;
			}

			const { getItems } = filterConfig.filterDropdownConfig;

			if (typeof getItems !== 'function') {
				return;
			}

			Promise.resolve(getItems());
		});
	}

	setId = (id: string) => {
		this.id = id;
	};

	setOpened = (opened: boolean) => {
		this.opened = opened;
	};

	setValue = (
		filterOption: FILTER_OPTION_TYPES,
		value: Partial<FilterValue>
	) => {
		this.filterValues[filterOption] = {
			...this.filterValues[filterOption],
			...value,
		};
	};

	setSearchTerm = (searchTerm: string) => {
		this.searchTerm = searchTerm;
	};

	setShowSearch = (showSearch: boolean) => {
		this.showSearch = showSearch;
	};
}

export const impactAnalysisStore = new ImpactAnalysisStore();
