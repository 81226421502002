import { range } from 'lodash-es';
import { DataQualityBreakdownBody } from './DataQualityBreakdownBody';
import { DataQualityBreakdownItemLoading } from './DataQualityBreakdownItemLoading';

export function DataQualityBreakdownLoading() {
	return (
		<DataQualityBreakdownBody>
			{range(4).map((item) => (
				<DataQualityBreakdownItemLoading key={item} value={`item-${item}`} />
			))}
		</DataQualityBreakdownBody>
	);
}
