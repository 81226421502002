import { Tooltip } from '@mantine/core';
import { useMemo, type PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import type { IUser } from '../../api';

export type AvatarUser = Pick<
	IUser,
	| 'disabled'
	| 'id'
	| 'display_name'
	| 'first_name'
	| 'last_name'
	| 'profile_picture'
>;

interface TooltipAvatarProps {
	user: AvatarUser;
	tooltip?: string;
	enableLink?: boolean;
}

export function TooltipAvatar({
	user,
	tooltip,
	enableLink,
	children,
	...props
}: PropsWithChildren<TooltipAvatarProps>) {
	const label = useMemo(() => {
		const userDisplayName = tooltip ?? user.display_name;
		return user?.disabled
			? `Deactivated User: ${userDisplayName}`
			: userDisplayName;
	}, [user, tooltip]);

	return (
		<Tooltip disabled={tooltip === undefined} position="top" label={label}>
			{enableLink ? (
				<Link to={`/user/${user.id}`} {...props}>
					{children}
				</Link>
			) : (
				<div {...props}>{children}</div>
			)}
		</Tooltip>
	);
}
