import { Box, Stack } from '@mantine/core';
import { useIsJiraIntegrated } from '@repo/api-codegen';
import { space } from '@repo/theme/primitives';
import { map } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import type { Incident, ISecodaEntity } from '../../../../api';
import { useAuthUser, useMonitor, useUpdateIncident } from '../../../../api';
import { useExtendedUserList } from '../../../../api/hooks/user/useExtendedUserList';
import { DateRenderer } from '../../../../components/CatalogView/TableView/DateRenderer';
import {
	SelectableProperty,
	StaticProperty,
} from '../../../../components/EntityPageLayout/EntityPropertySidebar';
import { EntityDisplay } from '../../../../components/EntityPageLayout/SidesheetStacks/EntityDisplay/EntityDisplay';
import { IncidentJiraIssue } from '../../../IncidentPage/v2/JiraIssueWorkflow/IncidentJiraIssue';
import { IncidentStatusBadge } from '../../components/IncidentStatusBadge';
import {
	formatValue,
	metricTypeDescription,
	metricTypeLabel,
} from '../../utils';

interface IncidentSummaryProps {
	incident: Incident;
	targetEntity?: ISecodaEntity;
	withTargetDisplay?: boolean;
	withJiraIssue?: boolean;
}

function IncidentSummary({
	incident,
	targetEntity,

	withTargetDisplay = false,
	withJiraIssue = false,
}: IncidentSummaryProps) {
	const { data: monitor } = useMonitor({ id: incident.monitor });
	const { data: isJiraIntegrated } = useIsJiraIntegrated({});

	const { isViewerOrGuestUser } = useAuthUser();
	const { activeUsers: users } = useExtendedUserList();

	const { mutateAsync: updateIncident } = useUpdateIncident({});
	const handleUpdateIncidentOwner = useCallback(
		(value: unknown) => {
			updateIncident({
				data: {
					id: incident.id,
					owner: value as string,
				},
			});
		},
		[incident.id, updateIncident]
	);

	const owners = useMemo(
		() =>
			map(users, (u) => ({
				label: u.display_name,
				value: u.id,
				icon: u.profile_picture,
				navigateTo: `/user/${u.id}`,
			})),
		[users]
	);

	return (
		<Stack spacing="xs">
			<StaticProperty
				wideLabel={true}
				label="Status"
				custom={<IncidentStatusBadge incidentStatus={incident.status} />}
			/>
			{monitor && (
				<StaticProperty
					infoTooltip={metricTypeDescription(monitor.metric_type)}
					wideLabel={true}
					type="text"
					label={metricTypeLabel(monitor.metric_type)}
					value={formatValue(monitor.metric_type, parseFloat(incident.value))}
				/>
			)}
			<StaticProperty
				wideLabel={true}
				label="Incident first occurred"
				custom={
					<DateRenderer
						format="MMM DD, HH:mm A"
						value={incident.first_seen_at}
					/>
				}
			/>
			<StaticProperty
				wideLabel={true}
				label="Incident last occurred"
				custom={
					<DateRenderer
						format="MMM DD, HH:mm A"
						value={incident.last_seen_at}
					/>
				}
			/>
			<SelectableProperty
				wideLabel={true}
				selected={incident?.owner as string}
				type="single"
				label="Owner"
				value="owner"
				iconType="avatar"
				isViewerUser={isViewerOrGuestUser}
				searchable
				onChange={handleUpdateIncidentOwner}
				options={owners}
			/>
			{monitor && withTargetDisplay && targetEntity && (
				<StaticProperty
					wideLabel
					label="Resource"
					custom={<EntityDisplay entity={targetEntity} />}
				/>
			)}
			{isJiraIntegrated && withJiraIssue && (
				<StaticProperty
					wideLabel
					label="Jira issue"
					custom={
						<Box ml={-space[2]}>
							<IncidentJiraIssue incident={incident} />
						</Box>
					}
				/>
			)}
		</Stack>
	);
}

export default IncidentSummary;
