import { Skeleton } from '@mantine/core';
import { MessageBody } from './MessageBody';

export function MessageGroupSkeleton() {
	return (
		<>
			<MessageBody
				avatar={<Skeleton height={32} circle />}
				author={<Skeleton width={200} height={20} radius="xl" />}
			>
				<Skeleton width="100%" height={40} radius="xl" mt="xs" />
			</MessageBody>
			<MessageBody
				avatar={<Skeleton height={32} circle />}
				author={<Skeleton width={200} height={20} radius="xl" />}
			>
				<Skeleton width="100%" height={40} radius="xl" mt="xs" />
			</MessageBody>
		</>
	);
}
