import type { InternalAxiosRequestConfig } from 'axios';
import axios from 'axios';
import { fromPairs, isObjectLike, map } from 'lodash-es';
import qs from 'qs';
import { api } from '../../../network';

export const authHeaders = () => ({ headers: {} });

export function getBaseUrl() {
	if (import.meta.env.VITE_API_ENDPOINT) {
		return import.meta.env.VITE_API_ENDPOINT;
	}

	return `${window.location.origin}/api/v1/`;
}

export const apiClient = axios.create({
	paramsSerializer: (params) => {
		const queryParams = fromPairs(
			map(params, (value, key) => {
				if (isObjectLike(value)) {
					return [key, JSON.stringify(value)];
				}

				return [key, value];
			})
		);

		return qs.stringify(queryParams, {
			skipNulls: true,
		});
	},
});

const interceptor = (config: InternalAxiosRequestConfig) => {
	// Properly format the request if it's only passed in '/'.
	if (config.url?.startsWith('/')) {
		// eslint-disable-next-line no-param-reassign
		config.url = `${api()}${config.url.slice(1)}`;
	}

	const url = new URL(config.url as string);

	// Django requires a trailing slash on all requests.
	// Add trailing slash if it's not there – do not do this for cloudflare workers.
	if (
		!url.pathname.endsWith('/') &&
		!url.host.includes('workers.dev') &&
		!url.host.includes('upload.imagedelivery.net')
	) {
		url.pathname += '/';
	}

	// eslint-disable-next-line no-param-reassign
	config.url = url.toString();
	return config;
};

axios.interceptors.request.use(interceptor);
apiClient.interceptors.request.use(interceptor);
