import { Group } from '@mantine/core';
import { Text } from '@repo/foundations';

interface DescriptionGroupProps {
	title: string | React.ReactNode;
	description: string | React.ReactNode;
}

function DescriptionGroup({ title, description }: DescriptionGroupProps) {
	return (
		<Group>
			<Text weight="bold" span>
				{title} :{' '}
			</Text>
			<Text span>{description}</Text>
		</Group>
	);
}

export default DescriptionGroup;
