import type { MantineTheme } from '@mantine/core';
import { createStyles } from '@mantine/core';
import { typography } from '@repo/theme/primitives';

export const FILTER_MENU_TARGET_VARIANTS = ['primary', 'secondary'] as const;
export const FILTER_MENU_TARGET_BORDER_STYLES = ['solid', 'dashed'] as const;

export type FilterMenuTargetVariant =
	(typeof FILTER_MENU_TARGET_VARIANTS)[number];
export type FilterMenuTargetBorderStyle =
	(typeof FILTER_MENU_TARGET_BORDER_STYLES)[number];

const getVariantStyles = (
	theme: MantineTheme,
	variant: FilterMenuTargetVariant
) => {
	switch (variant) {
		case 'primary':
			return {
				backgroundColor: theme.other.getColor(
					'fill/emphasis-secondary/default'
				),
				borderWidth: 1,
				borderColor: theme.other.getColor('border/emphasis-secondary/default'),
				color: theme.other.getColor('text/emphasis/default'),
				hover: {
					backgroundColor: theme.other.getColor(
						'fill/emphasis-secondary/hover'
					),
				},
				active: {
					backgroundColor: theme.other.getColor(
						'fill/emphasis-secondary/active'
					),
				},
				leftIcon: {
					color: theme.other.getColor('icon/emphasis/default'),
				},
				rightIcon: {
					color: theme.other.getColor('icon/emphasis/default'),
				},
			};
		case 'secondary':
			return {
				backgroundColor: theme.other.getColor('fill/primary/default'),
				borderWidth: 1,
				borderColor: theme.other.getColor('border/primary/default'),
				color: theme.other.getColor('text/primary/default'),
				hover: {
					backgroundColor: theme.other.getColor('fill/primary/hover'),
				},
				active: {
					backgroundColor: theme.other.getColor('fill/primary/active'),
				},
				leftIcon: {
					color: theme.other.getColor('icon/primary/default'),
				},
				rightIcon: {
					color: theme.other.getColor('icon/primary/default'),
				},
			};
		default:
			return {
				backgroundColor: theme.other.getColor(
					'fill/emphasis-secondary/default'
				),
				borderWidth: 1,
				borderColor: theme.other.getColor('border/emphasis-secondary/default'),
				color: theme.other.getColor('text/emphasis/default'),
				hover: {
					backgroundColor: theme.other.getColor(
						'fill/emphasis-secondary/hover'
					),
				},
				active: {
					backgroundColor: theme.other.getColor(
						'fill/emphasis-secondary/active'
					),
				},
				leftIcon: {
					color: theme.other.getColor('icon/emphasis/default'),
				},
				rightIcon: {
					color: theme.other.getColor('icon/emphasis/default'),
				},
			};
	}
};

export interface IFilterMenuTargetStyleProps {
	variant: FilterMenuTargetVariant;
	borderStyle: FilterMenuTargetBorderStyle;
	hasRightIcon: boolean;
}

export default createStyles(
	(
		theme: MantineTheme,
		{ variant, borderStyle, hasRightIcon }: IFilterMenuTargetStyleProps
	) => {
		const variantStyles = getVariantStyles(theme, variant);
		return {
			root: {
				backgroundColor: variantStyles.backgroundColor,
				borderWidth: variantStyles.borderWidth,
				borderColor: variantStyles.borderColor,
				borderStyle,
				borderRadius: 1000,
				paddingTop: 4,
				paddingBottom: 4,
				paddingLeft: 8,
				paddingRight: hasRightIcon ? 8 : 12,
				'&:hover': {
					backgroundColor: variantStyles.hover.backgroundColor,
				},
				'&:active': {
					backgroundColor: variantStyles.active.backgroundColor,
				},
			},
			icon: {},
			leftIcon: {
				color: variantStyles.leftIcon.color,
				marginRight: 4,
			},
			rightIcon: {
				color: variantStyles.rightIcon.color,
				marginLeft: 4,
			},
			centerLoader: {},
			inner: {},
			label: {
				fontSize: theme.fontSizes.sm,
				fontWeight: typography.weight.semibold,
				color: variantStyles.color,
			},
		};
	}
);
