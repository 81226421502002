import { useLocalStorage } from '@mantine/hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { camelCase } from 'lodash-es';
import { createContext, useCallback, useMemo } from 'react';
import { FEATURE_FLAGS_DEFAULT_VALUES } from './constants';
import type { FeatureFlags } from './types';

type FeatureFlagsContextType = {
	featureFlags: FeatureFlags;
	featureFlagsOverrides: Partial<FeatureFlags>;
	ldFeatureFlags: FeatureFlags;
	setOverride: (flag: keyof FeatureFlags, value: boolean) => void;
	clearOverride: (flag: keyof FeatureFlags) => void;
};

export const FeatureFlagsContext = createContext<FeatureFlagsContextType>({
	featureFlags: {} as FeatureFlags,
	featureFlagsOverrides: {},
	ldFeatureFlags: {} as FeatureFlags,
	setOverride: () => {},
	clearOverride: () => {},
});

export function FeatureFlagsProvider({
	children,
}: {
	children: React.ReactNode;
}) {
	const flags = useFlags();
	const [flagOverrides, setFlagOverrides] = useLocalStorage<
		Partial<FeatureFlags>
	>({
		key: 'feature-flags-override',
		defaultValue: {},
	});

	const featureFlags = useMemo(
		() =>
			Object.keys(FEATURE_FLAGS_DEFAULT_VALUES).reduce((acc, flagName) => {
				const camelCaseFlag = camelCase(flagName) as keyof FeatureFlags;

				return {
					...acc,
					[camelCaseFlag]:
						flagOverrides[camelCaseFlag] ?? flags[camelCaseFlag] ?? false,
				};
			}, {} as FeatureFlags),
		[flagOverrides, flags]
	);

	const setOverride = useCallback(
		(flag: keyof FeatureFlags, value: boolean) => {
			setFlagOverrides((prev) => ({
				...prev,
				[flag]: value,
			}));
		},
		[setFlagOverrides]
	);

	const clearOverride = useCallback(
		(flag: keyof FeatureFlags) => {
			setFlagOverrides((prev) => ({
				...prev,
				[flag]: undefined,
			}));
		},
		[setFlagOverrides]
	);

	const contextValue = useMemo(
		() => ({
			featureFlags,
			setOverride,
			clearOverride,
			featureFlagsOverrides: flagOverrides,
			ldFeatureFlags: flags as FeatureFlags,
		}),
		[featureFlags, flagOverrides, setOverride, clearOverride, flags]
	);

	return (
		<FeatureFlagsContext.Provider value={contextValue}>
			{children}
		</FeatureFlagsContext.Provider>
	);
}
