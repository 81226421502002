import { useMutation } from '@tanstack/react-query';
import { deleteParamsFromUrl } from '../../../utils/url';
import { apiClient, getEndpoints } from '../../common';
import { ACCESS_REQUESTED_NAMESPACE } from './constants';
import { invalidateNotificationListQuery } from './utils';

interface IUseApplyAccessRequestParams {
	id?: string;
}

export function useApplyAccessRequest({ id }: IUseApplyAccessRequestParams) {
	const mutationFn = async () => {
		if (!id) {
			return null;
		}

		const url = `${getEndpoints(ACCESS_REQUESTED_NAMESPACE).byId(id)}apply`;

		return apiClient.post(url);
	};

	return useMutation({
		mutationFn,
		onSuccess: () => {
			deleteParamsFromUrl('id');
			invalidateNotificationListQuery();
		},
	});
}
