import type { SelectablePropertyItem } from '@repo/common/components/SingleSelector/types';

export const isSelectableProperty = (
	value: string | SelectablePropertyItem | SelectablePropertyItem[] | undefined
): value is SelectablePropertyItem =>
	!Array.isArray(value) && typeof value !== 'string';

export const isSelectablePropertyArray = (
	value: string | SelectablePropertyItem | SelectablePropertyItem[] | undefined
): value is SelectablePropertyItem[] => Array.isArray(value);
