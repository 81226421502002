import { Group } from '@mantine/core';
import { UserRole } from '@repo/common/enums/UserRole';
import { Text } from '@repo/foundations';
import { useAuthUser } from '../../../api';
import { usePlan } from '../../../hooks/usePlans';
import { CustomSelector } from '../../CustomSelector';

function RoleSelect(props: {
	role: UserRole;
	disabled?: boolean;
	selectRole: (role: UserRole) => void;
	withDescriptions?: boolean;
}) {
	const { role, disabled, selectRole, withDescriptions } = props;
	const { plan } = usePlan();
	const { user: loggedInUser } = useAuthUser();

	let roles = [...editorPlanRoles, ...viewerPlanRoles];
	// Guests are only available on certain plans.
	if (plan?.guests) {
		roles = [...roles, ...guestPlanRoles];
	}

	const roleOptions = roles.map((r) => ({
		label: r.title,
		value: r.key,
		description: r.description,
		// @ts-ignore
		disabled: !privilegeMap[loggedInUser.role].includes(r.key),
	}));

	return (
		<CustomSelector
			disabled={disabled}
			items={roleOptions}
			menuProps={{
				width: 'target',
			}}
			renderItem={(option) => (
				<Group
					px={12}
					py={6}
					sx={{ flexGrow: 1, flexShrink: 1, flexWrap: 'nowrap' }}
				>
					{!withDescriptions && <Text size="sm">{option.label}</Text>}
					{withDescriptions && (
						<Text size="sm">
							<strong>{option.label}</strong> - {option.description}
						</Text>
					)}
				</Group>
			)}
			value={role}
			onChange={(value) => {
				selectRole(value as UserRole);
			}}
		/>
	);
}

export default RoleSelect;

const editorPlanRoles = [
	{
		title: 'Admin',
		description: 'Full administrative access',
		key: UserRole.ADMIN,
		default: true,
	},
	{
		title: 'Editor',
		description: 'Edit access with limited permissions',
		key: UserRole.EDITOR,
		default: false,
	},
];

const viewerPlanRoles = [
	{
		title: 'Viewer',
		description: 'Read only access and can post questions',
		key: UserRole.VIEWER,
		default: false,
	},
];

const guestPlanRoles = [
	{
		title: 'Guest',
		description: 'Limited access to invited teams',
		key: UserRole.GUEST,
		default: false,
	},
];

const privilegeMap = {
	[UserRole.ADMIN]: [
		UserRole.ADMIN,
		UserRole.EDITOR,
		UserRole.VIEWER,
		UserRole.GUEST,
	],
	[UserRole.EDITOR]: [UserRole.EDITOR, UserRole.VIEWER, UserRole.GUEST],
	[UserRole.VIEWER]: [UserRole.VIEWER],
	[UserRole.GUEST]: [],
};
