const SSR = typeof window === 'undefined';
const isMac = !SSR && window.navigator.platform === 'MacIntel';

export function isModKey(event: KeyboardEvent | MouseEvent): boolean {
	return isMac ? event.metaKey : event.ctrlKey;
}

export function getModKey(): string {
	return isMac ? '⌘' : 'Ctrl';
}
