import type { DataQualityScore } from '@repo/api-codegen';
import type { EntityType } from '@repo/common/enums/entityType';
import type { IFilterSelection } from '@repo/common/interfaces/filterSelection';
import type { UUID } from '../../../lib/types';
import type { IBaseModel } from './baseModel';
import type { DisplayMetadata, ISearchMetadata } from './secodaEntity';

// Mirrors the model of SearchLog in the backend (api/search/models.py)
export interface ISearchLog extends IBaseModel {
	user_id: UUID;
	term: string;
}

export enum LegacyFilterValue {
	NATIVE_TYPE = 'native_type',
	INTEGRATION_NAME = 'integration_name',
	DATABASE = 'database',
	SCHEMA = 'schema',
	TAGS = 'tags',
	PUBLISHED = 'published',
	VERIFIED = 'verified',
	PII = 'pii',
	COLLECTIONS = 'collections',
	OWNERS = 'owners',
	SOURCES = 'sources',
	PARENT_ID = 'parent_id',
	RELATED = 'related',
	SLACK_CHANNELS = 'slack_channels__contains',
	QUESTION_STATUS = 'question_status',
	QUESTION_PRIORITY = 'question_priority',
}

export interface ISearchView extends IBaseModel {
	name: string;
	selected_filters: Partial<Record<LegacyFilterValue, IFilterSelection>>;
	selected_sort_by: string;
	selected_sort_direction: 'asc' | 'desc';
	owner_id: UUID;
	teams: UUID[];
	default_for_users: UUID[];
	is_default_for_teams?: UUID[];
}

export interface SearchResult extends IBaseModel {
	external_updated_at?: string;

	entity_type: EntityType;
	native_type?: string;

	icon?: string;
	title: string;
	title_cased?: string;
	description?: string;
	definition?: string;

	search_metadata?: ISearchMetadata;
	workspace?: string;

	pii: boolean;
	verified: boolean;

	integration: string;
	parent?: string;

	internal_usage: number;
	external_usage: number;

	collections: string[];
	sources: string[];
	tags: string[];

	dqs?: DataQualityScore;
	display_metadata: DisplayMetadata;
}
