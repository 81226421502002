export default function isMarkdown(text: string): boolean {
	// Query-ish
	const queries = text.match(/^;;;/gm);
	if (queries && queries.length > 1) return true;

	// Code-ish
	const fences = text.match(/^```/gm);
	if (fences && fences.length > 1) return true;

	// Link-ish
	if (text.match(/\[[^]+\]\(https?:\/\/\S+\)/gm)) return true;
	if (text.match(/\[[^]+\]\(\/\S+\)/gm)) return true;

	// Heading-ish
	if (text.match(/^#{1,6}\s+\S+/gm)) return true;

	// List-ish
	const listItems = text.match(/^[\d-*].?\s\S+/gm);
	if (listItems && listItems.length > 1) return true;

	return false;
}
