import { FloatingFocusManager } from '@floating-ui/react';
import type { BoxProps } from '@mantine/core';
import { Box } from '@mantine/core';
import type { PropsWithChildren } from 'react';
import { useListBoxContext } from './context';
import { useListBoxStyles } from './ListBox.styles';

interface ListBoxDropdownProps extends BoxProps {}

export function ListBoxDropdown({
	children,
	...boxProps
}: PropsWithChildren<ListBoxDropdownProps>) {
	const { classes, cx } = useListBoxStyles();
	const { className, ...rest } = boxProps;

	const {
		opened,
		disabled,
		floatingContext,
		dropdownId,
		refs,
		floatingStyles,
		targetId,
		getFloatingProps,
		onKeyDown,
	} = useListBoxContext();

	if (!opened || disabled) {
		return null;
	}

	return (
		<FloatingFocusManager context={floatingContext}>
			<Box
				{...rest}
				id={dropdownId}
				className={cx(classes.dropdown, className)}
				ref={refs.setFloating}
				style={floatingStyles}
				aria-labelledby={targetId}
				{...getFloatingProps({ onKeyDown })}
			>
				{children}
			</Box>
		</FloatingFocusManager>
	);
}
