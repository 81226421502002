import {
	baseQueryHooksFactory,
	prefetchFunctionsFactory,
} from '../../factories';

import type { IQuery } from '../../types';
import { secodaQueryQueryKeyFactory } from './constants';

const { prefetchQuery, prefetchQueryList } = prefetchFunctionsFactory(
	'query',
	secodaQueryQueryKeyFactory
);

const {
	useSecodaQuery,
	useSecodaQueryInfiniteList,
	useSecodaQueryList,
	useCreateSecodaQuery,
	useDeleteSecodaQuery,
	useUpdateSecodaQuery,
} = baseQueryHooksFactory<IQuery, 'secodaQuery'>(
	'secodaQuery',
	secodaQueryQueryKeyFactory
);

export {
	prefetchQuery,
	prefetchQueryList,
	useCreateSecodaQuery,
	useDeleteSecodaQuery,
	useSecodaQuery,
	useSecodaQueryInfiniteList,
	useSecodaQueryList,
	useUpdateSecodaQuery,
};
