import { Box, Tooltip, useMantineTheme } from '@mantine/core';
import { Text } from '@repo/foundations';
import { useAuthUser, useUserList } from '../../api';
import { InlineEmojiWrapper } from './InlineEmojiWrapper';

interface IInlineEmojiProps {
	emoji: string;
	users: string[];
	onToggle: ({ native }: { native: string }) => void;
}

export function InlineEmoji({ emoji, users, onToggle }: IInlineEmojiProps) {
	const theme = useMantineTheme();
	const { user: authUser } = useAuthUser();
	const highlight = users.includes(authUser?.id || '');

	const { data } = useUserList({});
	const userNames = users.map((userId) => {
		const user = data?.results?.find((u) => u.id === userId);
		return user?.first_name
			? `${user?.first_name} ${user?.last_name}`
			: user?.email;
	});

	const handleClick = () => {
		onToggle({ native: emoji });
	};

	return (
		<Tooltip label={`Reaction by ${userNames.join(', ')}`}>
			<Box>
				<InlineEmojiWrapper highlight={highlight} onClick={handleClick}>
					<Text size="md">{emoji}</Text>
					<Text
						color={highlight ? 'text/emphasis/hover' : 'text/secondary/default'}
						weight="bold"
						size="xs"
					>
						{users.length}
					</Text>
				</InlineEmojiWrapper>
			</Box>
		</Tooltip>
	);
}
