import { SecodaTheme } from '@repo/theme/mantine/secodaTheme';
import type { EChartConfig } from './types';
import { AxisType, ChartType } from './types';

const primaryColor = SecodaTheme.colors?.primary?.[5] ?? '#000000';

const AXIS_TYPES: Record<AxisType, string> = {
	[AxisType.category]: 'Category',
	[AxisType.log]: 'Logarithmic',
	[AxisType.time]: 'Datetime',
	[AxisType.value]: 'Numeric',
};

export const AXIS_TYPE_OPTIONS = Object.entries(AXIS_TYPES).map(
	([value, label]) => ({
		value,
		label,
	})
);

export const INITIAL_CHART_CONFIG: EChartConfig = {
	color: [primaryColor],
	grid: {
		show: false,
		left: '10%',
		top: 32,
		right: 32,
		bottom: '10%',
	},
	toolbox: {
		show: true,
		feature: {
			dataZoom: { show: true },
			saveAsImage: { show: true },
		},
	},
	title: {
		text: '',
	},
	dataset: [],
	xAxis: {
		name: '',
		nameLocation: 'center',
		nameGap: 32,
		type: 'category',
		axisLabel: {
			interval: 0,
		},
	},
	yAxis: {
		name: '',
		nameLocation: 'end',
		nameGap: 32,
		type: 'category',
	},
	tooltip: {
		show: false,
	},
	legend: {},
	series: [
		{
			type: ChartType.bar,
			encode: {
				x: 0,
				y: 1,
			},
		},
	],
};

export const CHART_OPTIONS = [
	{
		value: ChartType.bar,
		label: 'Bar chart',
	},
	{
		value: ChartType.line,
		label: 'Line chart',
	},
	{
		value: ChartType.smooth,
		label: 'Smooth Line chart',
	},
	{
		value: ChartType.area,
		label: 'Area chart',
	},
	{
		value: ChartType.scatter,
		label: 'Scatter chart',
	},
];
