import { createStyles, Radio, UnstyledButton } from '@mantine/core';
import { Text } from '@repo/foundations';

interface ConnectionTypeRadioProps {
	group: string;
	active: boolean;
	selectGroup: (group: string) => void;
}

const useStyles = createStyles((theme, { active }: { active: boolean }) => ({
	wrapper: {
		display: 'flex',
		alignItems: 'center',
		borderWidth: 1,
		borderStyle: 'solid',
		borderColor: theme.other.getColor('border/primary/default'),
		borderRadius: theme.radius.sm,
		padding: theme.spacing.sm,
		gap: theme.spacing.xs,
		backgroundColor: theme.other.getColor('surface/primary/default'),
		'&:hover': {
			backgroundColor: theme.other.getColor('surface/primary/hover'),
		},
		'&[data-active=true]': {
			backgroundColor: theme.other.getColor('surface/primary/active'),
		},
	},
}));

function ConnectionTypeRadio({
	group,
	active,
	selectGroup,
}: ConnectionTypeRadioProps) {
	const { classes, theme } = useStyles({ active });

	return (
		<UnstyledButton
			className={classes.wrapper}
			onClick={() => selectGroup(group)}
			data-active={active}
		>
			<Radio value={group} />
			<Text size="sm" weight="semibold" lh={`${theme.other.space[6]}px`}>
				{group}
			</Text>
		</UnstyledButton>
	);
}

export default ConnectionTypeRadio;
