import { formatScore } from '@repo/common/utils/dataQuality';
import { Text } from '@repo/foundations';
import { DataQualityBadge } from '../DataQualityBadge';
import { DataQualityProgressIcon } from '../DataQualityProgressIcon';
import type { DataQualityBreakdownItem } from '../types';
import { DataQualityBreakdownChildren } from './DataQualityBreakdownChildren';
import { DataQualityBreakdownItemBody } from './DataQualityBreakdownItemBody';

interface DataQualityBreakdownItemProps {
	item: DataQualityBreakdownItem;
	onReportPage?: boolean;
}

export function DataQualityBreakdownItem({
	item,
	onReportPage = false,
}: DataQualityBreakdownItemProps) {
	return (
		<DataQualityBreakdownItemBody
			value={item.title}
			title={
				<>
					<DataQualityProgressIcon score={item.percentage} />
					<Text size="sm" fw={600}>
						{item.title}
					</Text>
					<Text size="sm" color="text/secondary/default">
						{formatScore(item.score)} of {item.maxScore}
					</Text>
				</>
			}
			badge={<DataQualityBadge classification={item.classification} />}
		>
			<DataQualityBreakdownChildren
				subItems={item.subItems}
				onReportPage={onReportPage}
			/>
		</DataQualityBreakdownItemBody>
	);
}
