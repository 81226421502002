import type { UnstyledButtonProps } from '@mantine/core';
import { UnstyledButton, createStyles } from '@mantine/core';
import { rem } from '@mantine/styles';
import { typography } from '@repo/theme/primitives';
import type { ColorNames } from '@repo/theme/utils';
import { forwardRef, type ReactNode } from 'react';
import { WithConditionalTooltip } from '../Tooltip/WithConditionalTooltip';

export type SplitButtonVariants = 'default' | 'primary' | 'tertiary';

interface SplitButtonProps extends UnstyledButtonProps {
	/** Controls button appearance  */
	variant?: SplitButtonVariants;

	/** Left Button label */
	label: React.ReactNode;

	/** Right Button contents */
	children: React.ReactNode;

	/** Callback for when it's clicked */
	onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;

	leftButtonTooltip?: ReactNode;

	/** Indicate loading state */
	loading?: boolean;
	/** Disabled state */
	disabled?: boolean;
	id?: string;
}

interface SplitButtonStyleProps {
	variant: SplitButtonVariants;
}

const useStyles = createStyles((theme, { variant }: SplitButtonStyleProps) => {
	const height: number = theme.other.space[7];
	let labelColor: ColorNames = 'text/primary/default';
	let dividerColor: ColorNames = 'border/primary/default';
	let fillColor: ColorNames = 'fill/primary/default';
	let iconColor: ColorNames = 'icon/primary/default';
	let hoverFillColor: ColorNames = 'fill/primary/hover';
	let activeFillColor: ColorNames = 'fill/primary/active';
	let highlight = '';
	let highlightOnHover = '';
	let highlightOnActive = '';

	if (variant === 'default') {
		fillColor = 'fill/primary/default';
		labelColor = 'text/primary/default';
		hoverFillColor = 'fill/primary/hover';
		activeFillColor = 'fill/primary/active';
		highlight =
			'0px 1px 0px 0px #DDD inset, 1px 0px 0px 0px #DDD inset, -1px 0px 0px 0px #DDD inset, 0px -1px 0px 0px #CCC inset';
		highlightOnHover =
			'0px 1px 0px 0px #EBEBEB inset, 1px 0px 0px 0px #EBEBEB inset, -1px 0px 0px 0px #EBEBEB inset, 0px -1px 0px 0px #CCC inset';
		highlightOnActive =
			'0px 2px 1px 0px rgba(0, 0, 0, 0.12) inset, 1px 1px 1px 0px rgba(0, 0, 0, 0.12) inset, -1px 0px 1px 0px rgba(0, 0, 0, 0.12) inset';
	} else if (variant === 'primary') {
		fillColor = 'fill/brand/default';
		iconColor = 'text/brand-on-fill/default';
		labelColor = 'text/brand-on-fill/default';
		hoverFillColor = 'fill/brand/hover';
		activeFillColor = 'fill/brand/active';
		highlight =
			'0px 1px 0px 0px #000 inset, 0px -1px 0px 1px #000 inset, -2px 0px 0px 0px rgba(255, 255, 255, 0.20) inset, 2px 0px 0px 0px rgba(255, 255, 255, 0.20) inset, 0px 2px 0px 0px rgba(255, 255, 255, 0.20) inset';
		highlightOnActive = '0px 3px 0px 0px #000 inset';
		dividerColor = 'border/inverse/default';
	} else if (variant === 'tertiary') {
		fillColor = 'fill/transparent/default';
		hoverFillColor = 'fill/transparent/hover';
		activeFillColor = 'fill/transparent/active';
		labelColor = 'text/primary/default';
	}

	return {
		container: {
			display: 'flex',

			height,
			position: 'relative',

			fontSize: typography.text.xs,
			fontWeight: typography.weight.semibold,
			lineHeight: typography.lineHeight.text.xs,

			transition: 'box-shadow 0.075s ease-in-out 0s',
		},
		leftButton: {
			padding: `0 ${theme.spacing.sm}`,
			backgroundColor: theme.other.getColor(fillColor),

			boxShadow: highlight,
			borderTopLeftRadius: theme.other.space[2],
			borderBottomLeftRadius: theme.other.space[2],

			'&:hover': {
				backgroundColor: theme.other.getColor(hoverFillColor),
				boxShadow: highlightOnHover,
			},
			'&:active': {
				backgroundColor: theme.other.getColor(activeFillColor),
				boxShadow: highlightOnActive,
			},
			'&:focus': {
				outline: `solid ${theme.other.getColor('border/emphasis/default')} 2px`,
				outlineOffset: rem(theme.other.space[0.25]),
			},
		},
		label: {
			whiteSpace: 'nowrap',
			height: '100%',
			overflow: 'hidden',
			display: 'flex',
			alignItems: 'center',
			fontSize: typography.text.xs,
			fontWeight: typography.weight.semibold,
			lineHeight: typography.lineHeight.text.xs,
			color: theme.other.getColor(labelColor),
		},
		divider: {
			borderColor: theme.other.getColor(dividerColor),
		},
		rightButton: {
			height,
			width: height,
			backgroundColor: theme.other.getColor(fillColor),
			marginLeft: -2,
			paddingLeft: theme.other.space[1],

			color: theme.other.getColor(iconColor),
			boxShadow: highlight,
			borderTopRightRadius: theme.other.space[2],
			borderBottomRightRadius: theme.other.space[2],

			'&:hover': {
				backgroundColor: theme.other.getColor(hoverFillColor),
				boxShadow: highlightOnHover,
			},
			'&:active': {
				backgroundColor: theme.other.getColor(activeFillColor),
				boxShadow: highlightOnActive,
			},
			'&:focus': {
				outline: `solid ${theme.other.getColor('border/emphasis/default')} 2px`,
				outlineOffset: rem(theme.other.space[0.25]),
			},
		},
	};
});

const SplitButton = forwardRef<HTMLButtonElement, SplitButtonProps>(
	(
		{
			id,
			label,
			children,
			onClick,
			variant = 'default',
			leftButtonTooltip,
			className,
			classNames,
			styles,
			...other
		},
		ref
	) => {
		const { classes, cx } = useStyles(
			{ variant },
			{
				name: 'SplitButton',
				classNames,
				styles,
			}
		);

		return (
			<span className={classes.container} id={id}>
				<WithConditionalTooltip tooltipLabel={leftButtonTooltip}>
					<UnstyledButton
						className={cx(classes.leftButton, className)}
						onClick={onClick}
						{...other}
						ref={ref}
					>
						<span className={classes.label}>{label}</span>
					</UnstyledButton>
				</WithConditionalTooltip>
				<UnstyledButton className={classes.rightButton}>
					{children}
				</UnstyledButton>
			</span>
		);
	}
);
SplitButton.displayName = 'SplitButton';

export default SplitButton;
