import { Center, createStyles, Stack } from '@mantine/core';
import { Button, Text } from '@repo/foundations';
import { useHomepageTeam } from '../../../hooks/useHomepageTeam';
import { WIDGET_HEIGHT } from '../constants';

interface IEmptyWidgetContainerProps {
	onCustomizeButtonClick: VoidFunction;
}

const useStyles = createStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		gap: theme.spacing.md,
		height: WIDGET_HEIGHT,
		boxShadow: '0px 0px 0px 1px #2322210D , 0px 4px 16px 0px #0000000A',
		borderRadius: theme.spacing.sm,
		paddingTop: theme.spacing.md,
	},
}));

function EmptyWidgetContainer({
	onCustomizeButtonClick,
}: IEmptyWidgetContainerProps) {
	const { isTeamViewerUser } = useHomepageTeam();

	const { classes } = useStyles();

	return (
		<Center className={classes.root}>
			<Stack align="center" spacing="xs">
				<Text weight="semibold" size="lg">
					{isTeamViewerUser ? 'No widgets found' : 'Add widgets here'}
				</Text>
				<Text color="text/secondary/default" size="sm">
					{isTeamViewerUser
						? 'Ask your team admin to add widgets to this page.'
						: 'Open the Customize home page panel to get started.'}
				</Text>
			</Stack>
			{!isTeamViewerUser && (
				<Button onClick={onCustomizeButtonClick}>Customize home page</Button>
			)}
		</Center>
	);
}

export default EmptyWidgetContainer;
