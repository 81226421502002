import { Box, createStyles, Group, Modal, Stack } from '@mantine/core';
import { useInputState } from '@mantine/hooks';
import type { APIKeyOut, PagedAPIKeyOut } from '@repo/api-codegen';
import { queryKeyFn, useApiCreateApiKey } from '@repo/api-codegen';
import {
	Button,
	CopyButton,
	IconButton,
	TextInput,
	Title,
} from '@repo/foundations';
import { useState } from 'react';
import { queryClient } from '../../../api';

interface CreateAPIKeyModalProps {
	opened: boolean;
	onClose: () => void;
}

const useStyles = createStyles((theme) => ({
	body: {
		padding: 0,
	},
	header: {
		backgroundColor: theme.other.getColor('surface/secondary/default'),
		borderBottom: `1px solid ${theme.other.getColor('border/secondary/default')}`,
		padding: theme.spacing.sm,
		justifyContent: 'space-between',
		flexWrap: 'nowrap',
	},
	content: {
		padding: theme.spacing.md,
		borderBottom: `1px solid ${theme.other.getColor('border/secondary/default')}`,
	},
	disabledTextInputWrapper: {
		'&:has(input:disabled) .mantine-TextInput-rightSection': {
			display: 'flex',
		},
	},
	disabledTextInputInput: {
		'&:disabled': {
			color: `${theme.other.getColor('text/primary/default')} !important`,
			opacity: 'unset',
		},
	},
	footer: {
		padding: theme.spacing.md,
		justifyContent: 'flex-end',
		gap: theme.spacing.xs,
	},
}));

export function CreateAPIKeyModal({ opened, onClose }: CreateAPIKeyModalProps) {
	const [key, setKey] = useState<APIKeyOut>();
	const [description, setDescription] = useInputState('');

	const { classes } = useStyles();

	const { mutateAsync: createKey, isLoading: isCreateKeyLoading } =
		useApiCreateApiKey({
			onSuccess: (newApiKey) => {
				queryClient.setQueryData<PagedAPIKeyOut>(
					queryKeyFn({
						path: '/auth/api_keys/',
						operationId: 'apiListApiKeys',
						variables: {},
					}),
					(prev) => {
						if (!prev) {
							return;
						}

						return {
							...prev,
							results: [...prev.results, newApiKey],
						};
					}
				);
			},
		});

	const handleCreateKey = async () => {
		const newKey = await createKey({
			body: {
				description,
			},
		});
		setKey(newKey);
	};

	const handleClose = () => {
		setKey(undefined);
		setDescription('');
		onClose();
	};

	return (
		<Modal
			classNames={{
				body: classes.body,
			}}
			size="lg"
			opened={opened}
			onClose={onClose}
			withCloseButton={false}
		>
			<Stack spacing={0}>
				<Group className={classes.header}>
					<Title size="md">Create API key</Title>
					<IconButton variant="tertiary" iconName="x" onClick={handleClose} />
				</Group>
				<Box className={classes.content}>
					{key ? (
						<TextInput
							classNames={{
								wrapper: classes.disabledTextInputWrapper,
								input: classes.disabledTextInputInput,
							}}
							label="API key"
							value={key.key}
							help={
								'For security reasons, you will not be able to view this key again after closing this modal.'
							}
							rightSection={<CopyButton value={key.key} />}
							disabled
						/>
					) : (
						<TextInput
							label={'Name'}
							value={description}
							onChange={setDescription}
						/>
					)}
				</Box>
				<Group className={classes.footer}>
					{!key && (
						<>
							<Button variant="default" onClick={handleClose}>
								Cancel
							</Button>
							<Button
								variant="primary"
								disabled={description === ''}
								loading={isCreateKeyLoading}
								onClick={handleCreateKey}
							>
								Create
							</Button>
						</>
					)}
					{key && (
						<Button variant="primary" onClick={handleClose}>
							Done
						</Button>
					)}
				</Group>
			</Stack>
		</Modal>
	);
}
