import type { EmbedDescriptor, MenuItem } from '@repo/secoda-editor';

const filterExcessSeparators = (
	items: (MenuItem | EmbedDescriptor)[]
): (MenuItem | EmbedDescriptor)[] =>
	// @ts-expect-error TS(2322): Type 'MenuItem | EmbedDescriptor' is not assignabl... Remove this comment to see the full error message
	items.reduce((acc, item, index) => {
		// Separators with titles should always be visible
		if (item.name === 'separator' && item.title) {
			return [...acc, item];
		}

		// Trim separators from start / end
		if (item.name === 'separator' && index === 0) return acc;
		if (item.name === 'separator' && index === items.length - 1) return acc;

		// Trim double separators looking ahead / behind
		const prev = items[index - 1];
		if (prev && prev.name === 'separator' && item.name === 'separator') {
			return acc;
		}

		const next = items[index + 1];
		if (next && next.name === 'separator' && item.name === 'separator') {
			return acc;
		}

		// Otherwise, continue
		return [...acc, item];
	}, []);

export default filterExcessSeparators;
