import { Stack } from '@mantine/core';
import CollapsableStack from '@repo/common/components/CollapsableStack';
import type { SelectablePropertyItem } from '@repo/common/components/SingleSelector/types';
import dayjs from 'dayjs';
import { AutomationTriggerType, type Automation } from '../../../api';
import { StaticProperty } from '../../../components/EntityPageLayout/EntityPropertySidebar';
import { RightSidebarWrapper } from '../../../components/RightSidebar';

export interface AutomationConfigurationSidebarProps {
	automation: Automation;
}

const formatTime = (time: number): string => {
	const durationObject = dayjs.duration(time, 'milliseconds');

	const minutes = durationObject.minutes();
	const hours = durationObject.hours();
	const days = durationObject.days();

	if (days > 0) {
		return `${days} day${days > 1 ? 's' : ''}`;
	}

	if (hours > 0) {
		return `${hours} hour${hours > 1 ? 's' : ''}`;
	}

	return `${minutes} minute${minutes > 1 ? 's' : ''}`;
};

function AutomationConfigurationSidebar({
	automation,
}: AutomationConfigurationSidebarProps) {
	const lastRun = automation.last_run_at
		? dayjs(automation.last_run_at).fromNow()
		: '--';

	const diff = dayjs(automation.next_run_at).diff();

	const nextRun =
		automation.next_run_at && dayjs(automation.next_run_at).isAfter(dayjs())
			? formatTime(diff)
			: '--';

	const statusProperty: SelectablePropertyItem =
		automation.status === 'enabled'
			? {
					label: 'On',
					value: 'enabled',
					fill: 'fill/success-secondary/default',
				}
			: {
					label: 'Off',
					value: 'disabled',
					fill: 'fill/secondary/default',
				};

	return (
		<RightSidebarWrapper>
			<Stack>
				<CollapsableStack groupName="Overview">
					<Stack spacing="xs">
						<StaticProperty
							type="badge"
							label="Status"
							value={statusProperty}
						/>
						{automation.trigger_type === AutomationTriggerType.SCHEDULE && (
							<StaticProperty type="text" label="Last run" value={lastRun} />
						)}
						{automation.trigger_type === AutomationTriggerType.SCHEDULE && (
							<StaticProperty type="text" label="Next run" value={nextRun} />
						)}
					</Stack>
				</CollapsableStack>
			</Stack>
		</RightSidebarWrapper>
	);
}

export default AutomationConfigurationSidebar;
