import { Popover } from '@mantine/core';
import { useCallback, useState } from 'react';

interface PopoverMenuItemProps {
	children: React.ReactNode;
	target?: React.ReactNode;
}

function PopoverMenuItem({ children, target }: PopoverMenuItemProps) {
	const [targetFocused, setTargetFocused] = useState(false);
	const [dropdownFocused, setDropdownFocused] = useState(false);

	const close = useCallback(() => {
		setTargetFocused(false);
		setDropdownFocused(false);
	}, []);

	const handleTargetEnter = useCallback(() => {
		setTargetFocused(true);
	}, []);

	const handleTargetLeave = useCallback(() => {
		setTimeout(() => setTargetFocused(false), 200);
	}, []);

	const handleDropdownEnter = useCallback(() => {
		setDropdownFocused(true);
	}, []);

	const handleDropdownBlur = useCallback(() => {
		setDropdownFocused(false);
	}, []);

	return (
		<Popover
			opened={targetFocused || dropdownFocused}
			onClose={close}
			width={280}
			position="right-start"
			radius={'md'}
			shadow="xl"
		>
			<Popover.Target>
				<div onMouseEnter={handleTargetEnter} onMouseLeave={handleTargetLeave}>
					{target}
				</div>
			</Popover.Target>

			<Popover.Dropdown p={0}>
				<div
					onMouseEnter={handleDropdownEnter}
					onBlurCapture={handleDropdownBlur}
				>
					{children}
				</div>
			</Popover.Dropdown>
		</Popover>
	);
}

export default PopoverMenuItem;
