import { Menu } from '@mantine/core';
import { Icon } from '@repo/foundations';
import type { ISecodaEntity } from '../../../../api';
import type { SecodaEntity } from '../../../../lib/models';
import { openModal } from '../../../ModalManager';
import { DefinitionSnapshots } from '../DefinitionSnapshots';

interface SnapshotsActionProps {
	entity: SecodaEntity | ISecodaEntity;
}

export function SnapshotsAction({ entity }: SnapshotsActionProps) {
	const handleClickSnapshots = () => {
		openModal({
			size: 1200,
			title: entity.title_cased ?? entity.title ?? 'Untitled',
			children: <DefinitionSnapshots entity={entity} />,
		});
	};

	return (
		<Menu.Item
			data-testid="entity-actions-menu-item-snapshots"
			icon={<Icon name="clockPlay" />}
			onClick={handleClickSnapshots}
		>
			View snapshots
		</Menu.Item>
	);
}
