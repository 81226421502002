import { createStyles } from '@mantine/core';
import { DOCUMENTATION_WIDTH } from '../../components/Documentation/constants.ts';

export const useMonitorPageStyles = createStyles(() => ({
	contentLayout: {
		margin: '0 auto',
		width: '100%',
		maxWidth: DOCUMENTATION_WIDTH,
		alignItems: 'left',
	},
}));
