import { createStyles, Menu } from '@mantine/core';
import { Button, Icon, type IconNames, Text } from '@repo/foundations';
import { map, noop } from 'lodash-es';
import { useHomepageTeam } from '../../../../../hooks/useHomepageTeam';

interface IWidgetFilterData {
	value: string;
	label: string;
	iconName: IconNames;
}

interface IWidgetFilterSelectorProps {
	value: string;
	data: IWidgetFilterData[];
	disabled?: boolean;
	onChange?: (value: string) => void;
}

const useStyles = createStyles((theme) => ({
	selectedItem: {
		color: theme.colors.gray[7],
		borderRadius: 1000,
		border: `thin solid ${theme.colors.gray[4]}`,
		padding: '4px 0',
	},
}));

function WidgetFilterSelector({
	value,
	data,
	disabled = false,
	onChange = noop,
}: IWidgetFilterSelectorProps) {
	const { isTeamViewerUser } = useHomepageTeam();
	const { classes } = useStyles();
	const selectedItem = data.find((filter) => filter.value === value);

	if (!selectedItem) {
		throw new Error(`No filter found for value: ${value}`);
	}

	const handleClick = (updatedValue: string) => () => {
		onChange(updatedValue);
	};

	const disableMenu = disabled || isTeamViewerUser;

	return (
		<Menu position="bottom-end" disabled={disableMenu}>
			<Menu.Target>
				<Button
					variant="tertiary"
					className={classes.selectedItem}
					leftIconName={selectedItem.iconName}
					rightIconName={disableMenu ? undefined : 'chevronDown'}
				>
					<Text size="sm" weight="semibold" color="text/secondary/default">
						{selectedItem.label}
					</Text>
				</Button>
			</Menu.Target>
			<Menu.Dropdown>
				{map(data, ({ iconName, label, value: filterValue }) => (
					<Menu.Item
						key={filterValue}
						icon={<Icon name={iconName} />}
						onClick={handleClick(filterValue)}
						rightSection={
							filterValue === value && (
								<Icon name="check" color="icon/primary/default" />
							)
						}
					>
						{label}
					</Menu.Item>
				))}
			</Menu.Dropdown>
		</Menu>
	);
}

export default WidgetFilterSelector;
