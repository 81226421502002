import { Box, Group, Stack } from '@mantine/core';
import {
	IconSkeleton,
	ParagraphSkeleton,
	TabsSkeleton,
	TextSkeleton,
} from '@repo/foundations';
import { observer } from 'mobx-react-lite';
import type { ReactNode } from 'react';
import {
	PageLayoutContent,
	PageLayoutContentWrapper,
	PageLayoutOuterWrapper,
	PageLayoutWrapper,
} from '../PageLayout';
import { RightSidebarWrapper } from '../RightSidebar';
import {
	NavBarBreadcrumbWrapper,
	NavBarSidesheetControlsWrapper,
	NavBarWrapper,
} from '../SecodaAppShell/SecodaNavBar';
import { TableV2Skeleton } from '../TableV2/TableV2Skeleton';
import type { EntityPageLayoutProps } from './EntityPageLayout';
import { EntityPageSidebarSkeleton } from './EntityPageSidebarSkeleton';
import { EntityPageTitleSkeleton } from './EntityPageTitle/EntityPageTitleSkeleton';

export type EntityPageLayoutSkeletonProps = Pick<
	EntityPageLayoutProps,
	'withDescription'
> & {
	withTabs?: boolean;
	withTable?: boolean;
	children?: ReactNode;
};

function EntityPageLayoutSkeleton({
	withDescription,
	withTabs = true,
	withTable = true,
	children,
}: EntityPageLayoutSkeletonProps) {
	return (
		<PageLayoutOuterWrapper>
			<PageLayoutWrapper name="entity-page-skeleton">
				<PageLayoutContentWrapper name="entity-page-skeleton">
					<NavBarWrapper>
						<NavBarBreadcrumbWrapper>
							<Box px="xs">
								<TextSkeleton width={64} height={8} />
							</Box>
						</NavBarBreadcrumbWrapper>
						<NavBarSidesheetControlsWrapper>
							<Group spacing="xs">
								<IconSkeleton size={28} />
								<IconSkeleton size={28} />
								<IconSkeleton size={28} />
								<IconSkeleton size={28} />
							</Group>
						</NavBarSidesheetControlsWrapper>
					</NavBarWrapper>
					<PageLayoutContent>
						<Stack spacing="md">
							<EntityPageTitleSkeleton />
							{withDescription && <ParagraphSkeleton lines={3} />}
							<Stack spacing="md">
								{withTabs && <TabsSkeleton numTabs={4} />}
								{withTable && <TableV2Skeleton />}
								{children}
							</Stack>
						</Stack>
					</PageLayoutContent>
				</PageLayoutContentWrapper>

				<RightSidebarWrapper>
					<EntityPageSidebarSkeleton />
				</RightSidebarWrapper>
			</PageLayoutWrapper>
		</PageLayoutOuterWrapper>
	);
}

export default observer(EntityPageLayoutSkeleton);
