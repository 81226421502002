import type { MenuItem } from '@repo/secoda-editor';
import { capitalize } from 'lodash-es';
import type { EditorView } from 'prosemirror-view';
import { useCallback, useMemo } from 'react';
import type { CommandFactory } from '../../lib/Extension';
import filterExcessSeparators from '../../lib/filterExcessSeparators';

interface UseToolbarSearchProps<T extends MenuItem = MenuItem> {
	view: EditorView;
	commands: Record<string, CommandFactory>;
	trigger?: string;
	searchTerm?: string;
	filterable?: boolean;
	items: T[];
	uploadEnabled?: boolean;
}

export function useToolbarSearch({
	view,
	searchTerm = '',
	trigger = '',
	filterable,
	items,
	commands,
	uploadEnabled = false,
}: UseToolbarSearchProps) {
	const clearSearch = useCallback(() => {
		const { state, dispatch } = view;
		const poss = state.doc.cut(
			state.selection.from - (searchTerm ?? '').length - trigger.length,
			state.selection.from
		);
		const trimTrigger = poss.textContent.startsWith(trigger);

		if (!searchTerm && !trimTrigger) {
			return;
		}

		// clear search input
		dispatch(
			state.tr.insertText(
				'',
				Math.max(
					0,
					state.selection.from -
						(searchTerm ?? '').length -
						(trimTrigger ? trigger.length : 0)
				),
				state.selection.to
			)
		);
	}, [searchTerm, trigger, view]);

	const filteredItems = useMemo(() => {
		if (!filterable) {
			return items;
		}

		const filtered = items?.filter((item) => {
			if (item.name === 'separator') {
				return true;
			}

			// Some extensions may be disabled, remove corresponding menu items
			if (
				item.name &&
				!commands[item.name] &&
				!commands[`create${capitalize(item.name)}`]
			) {
				return false;
			}

			// If no image upload callback has been passed, filter the image block out
			if (!uploadEnabled && ['image', 'attachment'].includes(item.name ?? '')) {
				return false;
			}

			// Some items (defaultHidden) are not visible until a search query exists
			if (!searchTerm) {
				return !item.defaultHidden;
			}

			const n = searchTerm.toLowerCase();

			return (
				(item.title || '').toLowerCase().includes(n) ||
				(item.keywords || '').toLowerCase().includes(n)
			);
		});
		return filterExcessSeparators(filtered);
	}, [commands, filterable, items, searchTerm, uploadEnabled]);

	return {
		clearSearch,
		filteredItems,
	};
}
