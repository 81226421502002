import axios from 'axios';
import { cloneDeep } from 'lodash-es';
import { authHeaders } from '../../api/common';
import { BaseModel } from './base';
import { downloadFile } from './misc';

export class BackgroundJob extends BaseModel {
	// @ts-expect-error TS(2564): Property 'failed' has no initializer and is not de... Remove this comment to see the full error message
	failed: boolean;

	// @ts-expect-error TS(2564): Property 'completed' has no initializer and is not... Remove this comment to see the full error message
	completed: boolean;

	// @ts-expect-error TS(2564): Property 'started' has no initializer and is not d... Remove this comment to see the full error message
	started: boolean;

	logs?: string | null;

	title?: string | null;

	has_artifact?: boolean | null;

	constructor(obj: Partial<BackgroundJob>) {
		super(obj);
		Object.keys(obj).forEach((key) => {
			(this as any)[key] = cloneDeep((obj as any)[key]);
		});
	}

	static get fields() {
		return BaseModel.fields.concat([
			'failed',
			'completed',
			'started',
			'title',
			'logs',
			'has_artifact',
		]);
	}

	static get namespace() {
		return ['background_jobs'];
	}

	async terminate() {
		await axios.post(`${this.apiPath}terminate/`, {}, authHeaders());
	}

	async getArtifact(fileName?: string) {
		return downloadFile(`${this.apiPath}artifact/`, fileName);
	}
}
