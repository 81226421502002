import type { IWidgetSample } from '../../../../api';
import { SideDrawer } from '../../../../components/SideDrawer/SideDrawer';
import { useHomepageTeam } from '../../hooks/useHomepageTeam';
import type { ICoverImageSectionProps } from './components/CoverImageSection';
import CoverImageSection from './components/CoverImageSection';
import WidgetSelector from './components/WidgetSelector';

interface IEditHomeScreenDrawerProps extends ICoverImageSectionProps {
	widgetSamples: IWidgetSample[];
	opened: boolean;
	onClose: VoidFunction;
	onAddWidget: (widgetType: IWidgetSample) => void;
}

function EditHomeScreenDrawer({
	widgetSamples,
	onClose,
	opened,
	backgroundImage,
	onBackgroundImageChange,
	onAddWidget,
}: IEditHomeScreenDrawerProps) {
	const { isTeamsPage } = useHomepageTeam();

	return (
		<SideDrawer opened={opened} onClose={onClose} title="Customize home screen">
			<CoverImageSection
				backgroundImage={backgroundImage}
				onBackgroundImageChange={onBackgroundImageChange}
			/>
			<WidgetSelector
				subtitle={`Click to add the widgets below to add them to your
${isTeamsPage ? ' ' : ' individual'} home page. You can also reorder
and remove them after adding it.
`}
				widgetSamples={widgetSamples}
				onAddWidget={onAddWidget}
			/>
		</SideDrawer>
	);
}

export default EditHomeScreenDrawer;
