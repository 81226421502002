import { Card, Group, Skeleton, Stack } from '@mantine/core';

export function LoadingCard() {
	return (
		<Card withBorder radius="md">
			<Stack h="100%" spacing="sm" pb="sm">
				<Skeleton height={20} width={20} />
				<Stack spacing="xs">
					<Group spacing="xs" noWrap>
						<Skeleton height={20} />
					</Group>
					<Skeleton height={60} />
				</Stack>
			</Stack>
		</Card>
	);
}
