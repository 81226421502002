import { EntityType } from '@repo/common/enums/entityType';
import { makeAutoObservable } from 'mobx';
import type { SecodaEntity } from '../../lib/models';
import type { ITableViewStore } from '../CatalogView/TableView/types';

export class EntityTableStore implements ITableViewStore<SecodaEntity> {
	selectedEntities: SecodaEntity[] = [];

	gridApi?: any;

	columnApi?: any;

	constructor() {
		makeAutoObservable(this);
	}

	get hasSelectedEntities() {
		return this.selectedEntities.length !== 0;
	}

	get selectedEntitiesCount() {
		return this.selectedEntities.length;
	}

	get doesSelectedEntitiesHaveParent() {
		return this.selectedEntities.some((entity) => {
			if (entity.entity_type === EntityType.collection) {
				return entity.collections.length !== 0;
			}

			return entity.parent !== null;
		});
	}

	setGridApi(gridApi: any) {
		this.gridApi = gridApi;
	}

	setColumnApi(columnApi: any) {
		this.columnApi = columnApi;
	}

	setSelectedEntities(entities: SecodaEntity[]) {
		this.selectedEntities = entities;
	}

	reload() {
		this.selectedEntities = [];
		this.gridApi?.refreshServerSide();
	}

	reset() {
		this.gridApi = undefined;
		this.columnApi = undefined;
		this.selectedEntities = [];
	}
}

export const entityTableStore = new EntityTableStore();
