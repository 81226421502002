import { Center, Group, createStyles, rem } from '@mantine/core';
import { usePagination } from '@mantine/hooks';
import { Button } from '@repo/foundations';

export interface IPaginationProps {
	page: number;
	total: number;
	onChange: (page: number) => void;
	siblings?: number;
}

const useStyles = createStyles((theme) => ({
	pagination: {
		marginTop: theme.spacing.sm,
		marginBottom: rem(60),
	},
}));

export function Pagination({
	page,
	total,
	onChange,
	siblings = 4,
}: IPaginationProps) {
	const { classes } = useStyles();

	const pagination = usePagination({
		page,
		total,
		boundaries: 0,
		siblings,
		onChange,
	});

	if (total === 1) {
		return null;
	}

	const handlePageClick = (pageNumber: number) => () => {
		pagination.setPage(pageNumber);
	};

	const disableLastPage = pagination.active === total;
	const disableFirstPage = pagination.active === 1;

	return (
		<Center>
			<Group className={classes.pagination} spacing="xs">
				<Button
					variant="tertiary"
					disabled={disableFirstPage}
					onClick={pagination.previous}
				>
					Previous
				</Button>
				<Group spacing="xs">
					{pagination.range.map((pageNumber) => {
						if (pageNumber === 'dots') {
							return null;
						}

						const isActivePage = pagination.active === pageNumber;

						return (
							<Button
								key={pageNumber}
								variant="tertiary"
								highlight={isActivePage}
								onClick={handlePageClick(pageNumber)}
							>
								{pageNumber}
							</Button>
						);
					})}
				</Group>
				<Button
					variant="tertiary"
					disabled={disableLastPage}
					onClick={pagination.next}
				>
					Next
				</Button>
			</Group>
		</Center>
	);
}
