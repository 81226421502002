import type { Node } from 'prosemirror-model';
import type { Selection } from 'prosemirror-state';
import { NodeSelection } from 'prosemirror-state';

export interface QueryBlock {
	linkId: string;
	pos: number;
	node: Node;
	selection: Selection;
}

export function getQueryBlocks(doc: Node) {
	const nodes: QueryBlock[] = [];

	doc.descendants((node, pos) => {
		if (node.type.name !== 'query_block') {
			return;
		}

		nodes.push({
			linkId: `metric-${node.attrs.id}`,
			node,
			pos,
			selection: new NodeSelection(doc.resolve(pos)),
		});
	});

	return nodes;
}
