import { Table } from '@mantine/core';
import { useNavigateHandler } from '@repo/common/hooks/useNavigate';
import { Text } from '@repo/foundations';
import { useUserList } from '../../../../api';
import type { TableComponentProps } from './MetricWidgetTable';
import { UserCell } from './UserCell';

export function MetricUserTable({ infoColumns, values }: TableComponentProps) {
	const { data } = useUserList({
		filters: {
			email__in: values.map((v) => v.email).join(','),
		},
		options: {
			suspense: false,
		},
	});
	const navigateHandler = useNavigateHandler();

	const getUserByEmail = (email: string) =>
		data?.results.find((user) => user.email === email);

	const rows = values.map((user) => {
		const fullUser = getUserByEmail(user.email as string);

		return (
			<tr
				style={{
					cursor: 'pointer',
				}}
				key={user.email}
				onClick={(e) => fullUser && navigateHandler(e)(`/user/${fullUser.id}`)}
			>
				<td>{fullUser && <UserCell user={fullUser} />}</td>
				{infoColumns.map((col) => (
					<td key={col.fieldName}>{user[col.fieldName]}</td>
				))}
			</tr>
		);
	});

	return (
		<Table highlightOnHover w="100%">
			<thead>
				<tr>
					<th>
						<Text color="text/secondary/default" weight="semibold" size="xs">
							User
						</Text>
					</th>
					{infoColumns.map((col) => (
						<th key={col.fieldName}>
							<Text color="text/secondary/default" weight="semibold" size="xs">
								{col.label}
							</Text>
						</th>
					))}
				</tr>
			</thead>
			<tbody>{rows}</tbody>
		</Table>
	);
}
