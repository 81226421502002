import { Anchor, Group, Stack, ThemeIcon } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import type { TeamOut } from '@repo/api-codegen';
import { apiQueryKey, useApiCreateTeam } from '@repo/api-codegen';
import { Button, Icon, Text } from '@repo/foundations';
import { useState } from 'react';
import { queryClient } from '../../api';
import { TeamType } from '../../interfaces';
import { InviteTeamMemberModal } from '../InviteTeamMemberModal/InviteTeamMemberModal';
import { ScrollableModal } from '../ScrollableModal/ScrollableModal';
import { TeamForm } from '../SecodaAppShell/SideBar/TeamForm';
import type { ITeamForm } from '../SecodaAppShell/SideBar/TeamForm.constants';
import { teamFormSchema } from '../SecodaAppShell/SideBar/TeamForm.constants';

interface NewTeamModalProps {
	opened: boolean;
	onClose: () => void;
	onFinish: (teamId: string) => void;
}

export function NewTeamModal({ opened, onClose, onFinish }: NewTeamModalProps) {
	const [
		inviteTeamMemberModalOpened,
		{ close: closeInviteTeamMemberModal, open: openInviteTeamMemberModal },
	] = useDisclosure(false);

	const [createdTeam, setCreatedTeam] = useState<TeamOut | null>(null);

	const closeAndClearCreatedTeam = () => {
		setCreatedTeam(null);
		onClose();
	};

	const { mutateAsync: apiCreateTeam, isLoading } = useApiCreateTeam({
		onSuccess: () => {
			queryClient.invalidateQueries(apiQueryKey('auth/teams'));
		},
	});

	const form = useForm<ITeamForm>({
		initialValues: {
			icon: '🐬',
			name: '',
			type: TeamType.OPEN,
		},
		validate: yupResolver(teamFormSchema),
		validateInputOnBlur: true,
	});

	const createTeam = async (values: typeof form.values) => {
		const team = await apiCreateTeam({
			body: {
				...values,
				type: values.type as TeamType,
			},
		});

		showNotification({
			title: 'New team created',
			message: `Created new team ${team.name}`,
			color: 'green',
			icon: <Icon name="check" />,
		});

		setCreatedTeam(team);
		onClose();
		openInviteTeamMemberModal();
	};

	const onFinishInviteMembers = (teamId: string) => {
		setCreatedTeam(null);
		closeInviteTeamMemberModal();
		onFinish(teamId);
	};

	return (
		<>
			<ScrollableModal
				opened={opened}
				withHeaderDivider={false}
				withDoneFooter={false}
				onClose={closeAndClearCreatedTeam}
				title="Create a new team"
				modalProps={{
					size: 480,
					top: 150,
				}}
			>
				<form onSubmit={form.onSubmit(createTeam)} autoComplete="off">
					<Stack spacing={24} px={24} pb={16}>
						<TeamForm form={form} />
						<Group position="apart">
							<Group spacing={6}>
								<Anchor
									color="gray.6"
									size="sm"
									weight={500}
									href="https://docs.secoda.co/user-management/teams"
									target="_blank"
								>
									<Group spacing="xs">
										<ThemeIcon
											size={16}
											radius="sm"
											color="gray.6"
											variant="filled"
										>
											<Icon name="questionMark" />
										</ThemeIcon>
										<Text size="xs">Learn about teams</Text>
									</Group>
								</Anchor>
							</Group>
							<Group spacing={12}>
								<Button
									size="md"
									variant="default"
									onClick={closeAndClearCreatedTeam}
								>
									Cancel
								</Button>
								<Button
									size="md"
									type="submit"
									variant="primary"
									disabled={isLoading}
									loading={isLoading}
								>
									Create team
								</Button>
							</Group>
						</Group>
					</Stack>
				</form>
			</ScrollableModal>
			{createdTeam && (
				<InviteTeamMemberModal
					opened={inviteTeamMemberModalOpened}
					onClose={closeInviteTeamMemberModal}
					team={createdTeam}
					onFinish={onFinishInviteMembers}
				/>
			)}
		</>
	);
}
