import { Menu, createStyles } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { observer } from 'mobx-react-lite';
import SecodaDatePicker from '../../DatePicker/SecodaDatePicker';

const useStyles = createStyles(() => ({
	menuDropdown: {
		minHeight: 'fit-content',
		width: '100%',
	},
}));

interface AutomationCardDatePickerProps {
	value?: string | boolean;
	targetButton: JSX.Element;
	onChange: (value: string, label?: string) => void;
	defaultOpened?: boolean;
}

function AutomationCardDatePicker({
	value,
	targetButton,
	onChange,
	defaultOpened = false,
}: AutomationCardDatePickerProps) {
	const { classes } = useStyles();
	const [opened, { open, close }] = useDisclosure(defaultOpened);

	return (
		<Menu
			classNames={{
				dropdown: classes.menuDropdown,
			}}
			opened={opened}
			onClose={close}
			onOpen={open}
			width={300}
			position="bottom-start"
			withinPortal
		>
			<Menu.Target>{targetButton}</Menu.Target>
			<Menu.Dropdown>
				<SecodaDatePicker value={value} close={close} onChange={onChange} />
			</Menu.Dropdown>
		</Menu>
	);
}

export default observer(AutomationCardDatePicker);
