import { Box } from '@mantine/core';
import { observer } from 'mobx-react-lite';
import { PIITable } from './PIITable';
import { Section } from './WorkspaceSettings';

export const DataSettings = observer(() => (
	<Box>
		<Section title="PII">
			<PIITable />
		</Section>
	</Box>
));
