import type { CSSObject } from '@mantine/core';
import {
	createStyles,
	FocusTrap,
	Group,
	Stack,
	TextInput,
} from '@mantine/core';
import { useClickOutside, useDisclosure } from '@mantine/hooks';
import { pluralize } from '@repo/common/utils';
import { Text } from '@repo/foundations';
import { IconX } from '@tabler/icons-react';
import { compact, includes, isArray, isNil, map, size } from 'lodash-es';
import type { ChangeEvent } from 'react';
import { useMemo, useState } from 'react';
import { formatListWithConjunction } from '../../utils/stringUtils';

interface IMultiTextInputProps {
	type: string;
	values: string[];
	setValues: (values: string[]) => void;
	label: string;
	placeholder: string;
	error?: string | string[];
}

const useStyles = createStyles((theme) => ({
	wrapper: {
		minHeight: 42,
		gap: 6,
		borderColor: theme.other.getColor('border/primary/default'),
		borderRadius: theme.radius.xs,
		borderStyle: 'solid',
		borderWidth: 1,
		padding: `6px ${theme.spacing.sm}`,
		cursor: 'text',
	},
	wrapperError: {
		borderColor: theme.other.getColor('border/critical/default'),
		marginBottom: theme.spacing['3xs'],
	},
	badge: {
		borderRadius: 1000,
		backgroundColor: theme.other.getColor('fill/tertiary/default'),
	},
	badgeError: {
		backgroundColor: theme.other.getColor('fill/critical-secondary/default'),
		borderColor: theme.other.getColor('border/critical/default'),
	},
	inputRoot: {
		flexGrow: '1 !important' as CSSObject['flexGrow'],
		height: theme.other.space[5],
		minHeight: theme.other.space[5],
	},
	input: {
		height: theme.other.space[5],
		minHeight: theme.other.space[5],
		'&:focus': {
			border: 'none',
			boxShadow: 'none',
		},
	},
}));

export function MultiTextInput({
	type,
	values,
	setValues,
	label,
	placeholder,
	error,
}: IMultiTextInputProps) {
	const [value, setValue] = useState<string>('');
	const [focusActive, { open: focus, close: unfocus }] = useDisclosure(false);

	const handleClickOutside = () => {
		if (size(value) > 0) {
			setValues([...values, value]);
			setValue('');
		}
		unfocus();
	};

	const ref = useClickOutside(handleClickOutside);

	const { classes, theme, cx } = useStyles();

	const errorMessage = useMemo(() => {
		const compactedError = compact(error);
		if (isArray(error)) {
			if (size(compactedError) === 1) {
				return `${compactedError[0]} is not a valid ${type}`;
			}
			if (size(compactedError) > 1) {
				return `${formatListWithConjunction(
					compactedError
				)} are not valid ${pluralize(type, size(compactedError))}`;
			}
			return undefined;
		}
		return error;
	}, [error, type]);

	const handleFocusOnInput = () => {
		focus();
	};

	const handleOnChange = (newValue: ChangeEvent<HTMLInputElement>) => {
		setValue(newValue.target.value.trim());
	};

	const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (includes(['Enter', 'Tab', 'Space', ' '], event.key)) {
			if (size(value) > 0) {
				setValues([...values, value]);
				setValue('');
			}
		}
	};

	const handleRemoveBadge = (badge: string) => {
		setValues(values.filter((e) => e !== badge));
	};

	return (
		<Stack spacing={0}>
			<Text size="sm" weight="semibold" mb="3xs">
				{label}
			</Text>
			<Group
				className={cx(classes.wrapper, {
					[classes.wrapperError]: !isNil(error),
				})}
				onClick={handleFocusOnInput}
			>
				{map(values, (badge, index) => {
					const isError = isArray(error) && includes(error, badge);
					return (
						<Group
							key={badge}
							noWrap
							spacing={0}
							p={4}
							className={
								isError ? cx(classes.badge, classes.badgeError) : classes.badge
							}
						>
							<Text ml={4} size="sm">
								{badge}
							</Text>
							<Group
								w={20}
								h={16}
								align="center"
								position="center"
								role="button"
								onClick={() => handleRemoveBadge(badge)}
							>
								<IconX
									color={isError ? theme.colors.red[6] : theme.colors.gray[7]}
									size={12}
								/>
							</Group>
						</Group>
					);
				})}
				<FocusTrap active={focusActive}>
					<TextInput
						ref={ref}
						classNames={{
							root: classes.inputRoot,
							input: classes.input,
						}}
						variant="unstyled"
						value={value}
						placeholder={size(values) === 0 ? placeholder : ''}
						onChange={handleOnChange}
						onKeyDown={handleKeyDown}
					/>
				</FocusTrap>
			</Group>
			{!isNil(errorMessage) && (
				<Text size="xs" color="text/critical/default">
					{errorMessage}
				</Text>
			)}
		</Stack>
	);
}
