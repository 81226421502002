import { sortedUniqBy } from 'lodash-es';
import type { Reaction } from '../../api';

export function updateReactionArray(
	reactions: Reaction[],
	emoji: string,
	userId: string
) {
	const existingReaction = reactions.find((r) => r?.emoji === emoji);
	const otherReactions = reactions.filter(
		(reaction) => reaction.emoji !== emoji
	);
	const existingUsers = existingReaction?.users || [];

	let users = [];
	if (existingReaction?.users?.includes(userId)) {
		users = existingUsers.filter((u) => u !== userId);
	} else {
		users = [...existingUsers, userId];
	}

	if (users.length === 0) {
		return otherReactions;
	}

	return sortedUniqBy(otherReactions.concat({ emoji, users }), 'emoji');
}
