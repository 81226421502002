import type { AutomationField } from './automation';

export enum AutomationFilterOperator {
	AND = 'AND',
	OR = 'OR',
}

export enum AutomationCondition {
	IS = 'IS',
	IS_NOT = 'IS_NOT',
	CONTAINS = 'CONTAINS',
	DOES_NOT_CONTAIN = 'DOES_NOT_CONTAIN',
	STARTS_WITH = 'STARTS_WITH',
	ENDS_WITH = 'ENDS_WITH',
	IS_SET = 'IS_SET',
	IS_NOT_SET = 'IS_NOT_SET',
	GTE = 'GTE',
	LTE = 'LTE',
}

export enum AutomationDateOptions {
	ONE_DAY_AGO = 'ONE_DAY_AGO',
	SEVEN_DAYS_AGO = 'SEVEN_DAYS_AGO',
	THIRTY_DAYS_AGO = 'THIRTY_DAYS_AGO',
	SIXTY_DAYS_AGO = 'SIXTY_DAYS_AGO',
	NINETY_DAYS_AGO = 'NINETY_DAYS_AGO',
	CUSTOM = 'CUSTOM',
}

export interface AutomationFilter {
	key: string;
	field: AutomationField | string;
	value: string | boolean;
	display: string;
	condition: AutomationCondition;
}

export interface AutomationFilterSet {
	operator: AutomationFilterOperator;
	key: string;
	filters: AutomationFilter[];
}

export interface AutomationFilterConfig {
	operator: AutomationFilterOperator;
	filter_sets: AutomationFilterSet[];
}
