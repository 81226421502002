import { Checkbox, Divider, Stack } from '@mantine/core';
import { useForm } from '@mantine/form';
import {
	useGetIntegrationExcludedTitles,
	useGetIntegrationWhitelistTitles,
	useSetExcludedExcludedTitles,
	useSetIntegrationWhitelistTitles,
} from '@repo/api-codegen';
import type { FilterOption } from '@repo/common/components/Filter/types.ts';
import {
	FilterDropdownType,
	FilterOperator,
	FilterOptionType,
} from '@repo/common/components/Filter/types.ts';
import type { PreferenceOption } from '@repo/common/constants/integration/integrations.preferences';
import { IntegrationPreferenceType } from '@repo/common/constants/integration/integrations.preferences';
import {
	isPreviewIntegration,
	isQueryBlockIntegration,
	isWarehouseOrDatabaseIntegration,
} from '@repo/common/constants/integration/integrations.utils';
import { Switch, Text } from '@repo/foundations';
import { upperFirst } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import type { IIntegration } from '../../../../../api';
import {
	useUpdateIntegration,
	useUpdateUser,
	useUserGroupList,
	useUserList,
} from '../../../../../api';
import { useExtendedUserList } from '../../../../../api/hooks/user/useExtendedUserList';
import type { IntegrationSpec } from '../../../../../interfaces/IntegrationSpec';
import { getHash } from '../../../../../utils/shared.utils';
import { IntegrationResourceFilters } from './IntegrationResourceFilters.tsx';
import { Popularity } from './Popularity';
import { PreferenceSection } from './PreferenceSection';
import { useStyles } from './PreferencesPanel.styles';
import { QueryPermissions } from './QueryPermissions';

const CUSTOM_TITLE_INCLUSION_FILTER_OPTION: FilterOption = {
	label: 'Title',
	type: FilterOptionType.TITLE,
	field: 'title',
	filterDropdownConfig: {
		dropdownType: FilterDropdownType.String,
		defaultOperator: FilterOperator.Is,
		inputPlaceholder: 'Enter a title',
		hasIsNotSetOption: false,
	},
};

const CUSTOM_TITLE_EXCLUSION_FILTER_OPTION: FilterOption = {
	label: 'Title',
	type: FilterOptionType.TITLE,
	field: 'title',
	filterDropdownConfig: {
		dropdownType: FilterDropdownType.String,
		defaultOperator: FilterOperator.Contains,
		inputPlaceholder: 'Enter a title',
		hasIsNotSetOption: false,
	},
};
interface PreferencesPanelProps {
	integration: IIntegration;
	spec: IntegrationSpec;
	options: PreferenceOption[];
}

export function PreferencesPanel({
	integration,
	spec,
	options,
}: PreferencesPanelProps) {
	const { classes, theme } = useStyles();

	const { mutateAsync: updateIntegration } = useUpdateIntegration({});
	const { mutateAsync: updateUser } = useUpdateUser({});

	const { activeUsers } = useExtendedUserList();
	const { data: userGroups } = useUserGroupList({});
	const { data: serviceAccounts } = useUserList({
		filters: {
			is_service_account: true,
		},
		options: {
			select: ({ results }) => results,
		},
	});

	const isBuiltIn = spec.type === 'builtin';

	const hasPermissions =
		isBuiltIn &&
		(isQueryBlockIntegration(spec.value) || isPreviewIntegration(spec.value));

	const hasUsers = activeUsers && activeUsers.length > 0;
	const hasUserGroups = userGroups && userGroups.results.length > 0;
	const hasServiceAccounts = serviceAccounts && serviceAccounts.length > 0;

	const hasPopularity =
		isBuiltIn && isWarehouseOrDatabaseIntegration(spec.value);

	const metadataManagementPreferences = useMemo(
		() =>
			options.filter(
				(option) =>
					option.type === IntegrationPreferenceType.METADATA_MANAGEMENT
			),
		[options]
	);
	const resourceManagementPreferences = useMemo(
		() =>
			options.filter(
				(option) =>
					option.type === IntegrationPreferenceType.RESOURCE_MANAGEMENT
			),
		[options]
	);

	const form = useForm<Record<string, boolean | undefined>>({
		initialValues: {
			admin_role: integration.preview_access_roles?.includes('admin'),
			editor_role: integration.preview_access_roles?.includes('editor'),
			viewer_role: integration.preview_access_roles?.includes('viewer'),
			...activeUsers
				?.map((user) => ({
					[`${user.id}_user`]: integration.execute_access?.includes(user.id),
				}))
				.reduce((acc, cur) => ({ ...acc, ...cur }), {}),
			...userGroups?.results
				.map((group) => ({
					[`${group.id}_group`]: integration.preview_access_groups?.includes(
						group.id
					),
				}))
				.reduce((acc, cur) => ({ ...acc, ...cur }), {}),
		},
	});

	const handleRoleChange = useCallback(
		(field: string, checked: boolean) => {
			const currentRole = field.replace('_role', '');
			form.setFieldValue(field, checked);
			const updatedRoles = checked
				? [...(integration.preview_access_roles ?? []), currentRole]
				: (integration.preview_access_roles?.filter(
						(role) => role !== currentRole
					) ?? []);

			updateIntegration({
				data: {
					id: integration.id,
					preview_access_roles: updatedRoles,
				},
			});
		},
		[form, integration.preview_access_roles, integration.id, updateIntegration]
	);

	const handleGroupChange = useCallback(
		(field: string, checked: boolean) => {
			const currentGroup = field.replace('_group', '');
			form.setFieldValue(field, checked);
			const updatedGroups = checked
				? [...(integration.preview_access_groups ?? []), currentGroup]
				: (integration.preview_access_groups?.filter(
						(group) => group !== currentGroup
					) ?? []);

			updateIntegration({
				data: {
					id: integration.id,
					preview_access_groups: updatedGroups,
				},
			});
		},
		[form, integration.preview_access_groups, integration.id, updateIntegration]
	);

	const handleUserChange = useCallback(
		(field: string, checked: boolean) => {
			const currentUser = field.replace('_user', '');
			form.setFieldValue(field, checked);
			const updatedUsers = checked
				? [...(integration.execute_access ?? []), currentUser]
				: (integration.execute_access?.filter((user) => user !== currentUser) ??
					[]);

			updateIntegration({
				data: {
					id: integration.id,
					execute_access: updatedUsers,
				},
			});
		},
		[form, integration.execute_access, integration.id, updateIntegration]
	);

	const handleGroupAccessChange = useCallback(
		(groupList: string[]) => {
			// debugger;
			updateIntegration({
				data: {
					id: integration.id,
					execute_access_groups: groupList,
				},
			});
		},
		[form, integration.execute_access_groups, integration.id, updateIntegration]
	);

	const handlePopularityChange = useCallback(
		(emailHash: string, checked: boolean) => {
			form.setFieldValue(emailHash, checked);
			const user = serviceAccounts?.find(
				(u) => getHash(u.email).toString() === emailHash
			);

			if (user) {
				updateUser({
					data: {
						id: user.id,
						service_account_count_popularity: checked,
					},
				});
			}
		},
		[form, serviceAccounts, updateUser]
	);

	const handlePreferenceChange = useCallback(
		(field: string, checked: boolean) => {
			const credentials = {
				...(integration?.credentials ?? {}),
				[field]: checked,
			};
			updateIntegration({
				data: { id: integration.id, credentials },
			});
		},
		[updateIntegration, integration]
	);

	// ==============================
	// Resource Sync Filters
	// ==============================
	const { data: excludeFilters, refetch: refetchExcludedTitles } =
		useGetIntegrationExcludedTitles({
			pathParams: {
				integrationId: integration.id,
			},
		});

	const { mutateAsync: persistExcludedTitles } = useSetExcludedExcludedTitles({
		onSuccess: () => refetchExcludedTitles(),
	});

	const updateExcludedTitles = (newFilters: string[]) => {
		persistExcludedTitles({
			pathParams: {
				integrationId: integration.id,
			},
			body: newFilters,
		});
	};

	const { data: whitelistFilters, refetch: refetchWhitelistTitles } =
		useGetIntegrationWhitelistTitles({
			pathParams: {
				integrationId: integration.id,
			},
		});

	const { mutateAsync: persistWhitelistTitles } =
		useSetIntegrationWhitelistTitles({
			onSuccess: () => refetchWhitelistTitles(),
		});

	const updateWhitelistTitles = (newFilters: string[]) => {
		persistWhitelistTitles({
			pathParams: {
				integrationId: integration.id,
			},
			body: newFilters,
		});
	};

	return (
		<form>
			<Stack spacing={theme.spacing.xl}>
				{hasPermissions && (
					<PreferenceSection
						header="Preview Permissions"
						subheader={`Allow specific roles and groups to see previews for ${upperFirst(integration.type)}.`}
					>
						<Stack className={classes.formWrapper}>
							<Checkbox
								label="Admins"
								checked={form.values.admin_role}
								onChange={(event) => {
									handleRoleChange('admin_role', event.currentTarget.checked);
								}}
							/>
							<Checkbox
								label="Editors"
								checked={form.values.editor_role}
								onChange={(event) => {
									handleRoleChange('editor_role', event.currentTarget.checked);
								}}
							/>
							<Checkbox
								label="Viewers"
								checked={form.values.viewer_role}
								onChange={(event) => {
									handleRoleChange('viewer_role', event.currentTarget.checked);
								}}
							/>
							{hasUserGroups &&
								userGroups.results.map((group) => (
									<Checkbox
										key={group.id}
										label={group.name}
										checked={form.values[`${group.id}_group`]}
										onChange={(event) => {
											handleGroupChange(
												`${group.id}_group`,
												event.currentTarget.checked
											);
										}}
									/>
								))}
						</Stack>
					</PreferenceSection>
				)}
				{hasUsers && (
					<QueryPermissions
						integration={integration}
						activeUsers={activeUsers}
						activeAccessGroups={integration.execute_access_groups ?? []}
						form={form}
						handleUserChange={handleUserChange}
						handleGroupAccessChange={handleGroupAccessChange}
					/>
				)}
				{hasPopularity && hasServiceAccounts && (
					<Popularity
						integration={integration}
						serviceAccounts={serviceAccounts}
						form={form}
						handlePopularityChange={handlePopularityChange}
					/>
				)}
				{metadataManagementPreferences && (
					<>
						<Divider />
						<PreferenceSection
							header="Property management"
							subheader="Select your source of truth for managing resource properties."
						>
							<Stack spacing="xl">
								<Text size="sm">
									<Text size="sm" span weight="bold">
										Note:
									</Text>{' '}
									If toggled on, edits must be made in {integration.name}, as it
									remains the source of truth. Initial sync still imports data
									even if the toggle is off.
								</Text>
								<Stack className={classes.formWrapper}>
									{metadataManagementPreferences.map((option) => (
										<Switch
											key={option.credentialFieldName}
											label={option.title}
											description={option.description}
											onChange={(e) => {
												handlePreferenceChange(
													option.credentialFieldName,
													e.target.checked
												);
											}}
											checked={Boolean(
												integration?.credentials[option.credentialFieldName]
											)}
										/>
									))}
								</Stack>
							</Stack>
						</PreferenceSection>
					</>
				)}
				{resourceManagementPreferences && (
					<>
						<Divider />
						<PreferenceSection
							header="Resource management"
							subheader="Select how queries, schemas and archived resources are managed."
						>
							<Stack spacing="xl">
								<Stack className={classes.formWrapper}>
									{resourceManagementPreferences.map((option) => (
										<Switch
											key={option.credentialFieldName}
											label={option.title}
											description={option.description}
											onChange={(e) => {
												handlePreferenceChange(
													option.credentialFieldName,
													e.target.checked
												);
											}}
											checked={Boolean(
												integration?.credentials[option.credentialFieldName]
											)}
										/>
									))}
								</Stack>
							</Stack>
						</PreferenceSection>
					</>
				)}
				<Divider />
				<PreferenceSection
					header="Filtering"
					subheader="Apply additional filters for whats is synced based on resource names."
				>
					<Stack spacing="sm">
						<IntegrationResourceFilters
							filters={whitelistFilters ?? []}
							description={
								<>
									<Text size="sm">
										<strong>Whitelist </strong>
										resources that match any of the following filters.
									</Text>
									<Text size={'xs'} color={'text/secondary/default'}>
										Resources matched here will be included, even if they match
										an exclusion filter.
									</Text>
								</>
							}
							filterOption={CUSTOM_TITLE_INCLUSION_FILTER_OPTION}
							handleUpdate={updateWhitelistTitles}
						/>
						<IntegrationResourceFilters
							filters={excludeFilters ?? []}
							description={
								<Text size="sm">
									<strong>Exclude </strong>
									resources that match any of the following filters
								</Text>
							}
							filterOption={CUSTOM_TITLE_EXCLUSION_FILTER_OPTION}
							handleUpdate={updateExcludedTitles}
						/>
					</Stack>
				</PreferenceSection>
			</Stack>
		</form>
	);
}
