import { ActionIcon, Group, Menu } from '@mantine/core';
import { useNavigate } from '@repo/common/hooks/useNavigate';
import { Icon, Title } from '@repo/foundations';
import { observer } from 'mobx-react-lite';
import { useAIAssistantContext } from '../context';
import { aiSidebarSessionStore } from './sessionStore';

export const AIAssistantSidebarHeader = observer(() => {
	const store = useAIAssistantContext();

	const navigate = useNavigate();

	const handleNewChat = () => {
		store.setId('new');
		store.setIsHistoryOpen(false);
		aiSidebarSessionStore.clearSessionId();
	};

	const handleViewHistory = () => {
		store.setIsHistoryOpen(true);
		aiSidebarSessionStore.clearSessionId();
	};

	const handleOpenPage = () => {
		navigate(`/ai/${store.id}`);
	};

	const title = store.isHistoryOpen ? 'Chat History' : 'Secoda AI';

	const showNewChatButton =
		(!!store.id && store.id !== 'new') || store.isHistoryOpen;

	return (
		<Group position="apart" p="xl" pt="none">
			<Title size="sm">{title}</Title>
			<Menu keepMounted={false}>
				<Menu.Target>
					<ActionIcon>
						<Icon name="dots" />
					</ActionIcon>
				</Menu.Target>
				<Menu.Dropdown>
					{showNewChatButton && (
						<Menu.Item icon={<Icon name="edit" />} onClick={handleNewChat}>
							New chat
						</Menu.Item>
					)}
					{!store.isHistoryOpen && (
						<Menu.Item
							icon={<Icon name="history" />}
							onClick={handleViewHistory}
						>
							View history
						</Menu.Item>
					)}
					<Menu.Divider />
					<Menu.Item
						icon={<Icon name="circleChevronRight" />}
						onClick={handleOpenPage}
					>
						Open in Secoda AI page
					</Menu.Item>
				</Menu.Dropdown>
			</Menu>
		</Group>
	);
});
