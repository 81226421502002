import { Stack } from '@mantine/core';
import { Text } from '@repo/foundations';
import type { typography } from '@repo/theme/primitives';
import { map, sum } from 'lodash-es';
import { useMeasurementFromWidget } from '../../../api';
import {
	type ISampleOrActualMetricWidget,
	METRIC_WIDGET_CONTENT_HEIGHT,
} from '../constants';
import { formatValueDisplay, isEmptyMetric } from '../utils/utils';
import { MetricLoadingOrEmpty } from './MetricLoadingOrEmpty';

export type IMetricWidgetSingleValueProps = ISampleOrActualMetricWidget;

export function MetricWidgetSingleValue({
	metricWidget,
	source,
}: IMetricWidgetSingleValueProps) {
	const { data: datapoints, isLoading } =
		useMeasurementFromWidget(metricWidget);

	const valueName =
		Object.keys(metricWidget.metric_metadata?.value || {})[0] || undefined;
	if (isEmptyMetric(datapoints) || isLoading || !valueName) {
		return (
			<MetricLoadingOrEmpty
				isLoading={isLoading}
				isEmpty={isEmptyMetric(datapoints) || !valueName}
				source={source}
			/>
		);
	}

	const value = sum(map(datapoints, (point) => point[valueName] as number));
	const {
		value: formattedValue,
		prefix,
		suffix,
	} = formatValueDisplay(value, metricWidget.metric_metadata);

	const textSizeMap: Record<string, keyof typeof typography.text> = {
		widget: '7xl',
		sample: '4xl',
		preview: 'xxl',
	};

	return (
		<Stack
			align="center"
			w="100%"
			mih={METRIC_WIDGET_CONTENT_HEIGHT[source]}
			justify="center"
		>
			<Text size={textSizeMap[source]} weight="semibold">
				{prefix}
				{formattedValue}
			</Text>
			{source !== 'preview' && (
				<Text size={source === 'widget' ? 'lg' : 'md'} weight="semibold">
					{suffix}
				</Text>
			)}
		</Stack>
	);
}
