import { createStyles } from '@mantine/core';
import { isEmpty } from 'lodash-es';
import { Text } from '../Text';

interface TextInputLabelProps {
	label?: string;
	optional?: boolean;
	inputId: string;
}

const useStyles = createStyles((theme) => ({
	optional: { color: theme.other.getColor('text/secondary/default') },
}));

export function TextInputLabel({
	label,
	optional,
	inputId,
}: TextInputLabelProps) {
	const { classes } = useStyles();

	if (isEmpty(label)) {
		return null;
	}

	return (
		<Text size="sm" weight="semibold">
			<label htmlFor={inputId}>{label}</label>
			{optional && (
				<span className={classes.optional} aria-hidden>
					{' (Optional)'}
				</span>
			)}
		</Text>
	);
}
