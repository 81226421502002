import { observer } from 'mobx-react-lite';
import { lineageStore } from '../store';
import BottomPanelButton from './BottomPanelButton';

function BottomPanel() {
	if (lineageStore.isFocused) {
		return (
			<BottomPanelButton
				label="Exit focus"
				iconName="doorExit"
				onClick={lineageStore.exitFocus}
			/>
		);
	}

	if (lineageStore.isFullscreen) {
		return (
			<BottomPanelButton
				label="Exit fullscreen"
				iconName="doorExit"
				onClick={lineageStore.toggleIsFullscreen}
			/>
		);
	}

	if (lineageStore.selectedChild) {
		return (
			<BottomPanelButton
				label="Exit child lineage"
				iconName="doorExit"
				onClick={lineageStore.clearSelectedChild}
			/>
		);
	}

	return null;
}

export default observer(BottomPanel);
