import type { QueryFunctionContext, QueryKey } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { apiClient, getEndpoints } from '../../common';
import type { IBaseModel, IBaseModelQueryArgs, Namespace } from '../../types';

export function getDefaultQueryFn<TApiResponseData extends IBaseModel>(
	id: string,
	namespace: Namespace,
	params: unknown = undefined
) {
	const defaultQueryFn = async ({ signal }: QueryFunctionContext<QueryKey>) => {
		const url = getEndpoints(namespace).byId(id);

		const { data } = await apiClient.get<TApiResponseData>(url, {
			signal,
			params,
		});
		return data;
	};

	return defaultQueryFn;
}

/**
 * Hook for fetching a single base model.
 *
 * Usecase: Fetching & caching a model
 *
 * @param params Params for react-query. ID is passed to the default queryFn if quernFn is not provided.
 * @returns React Query hook for fetching a single model
 */
function useBaseModel<
	TApiResponseData extends IBaseModel,
	TData = TApiResponseData,
	TError = unknown,
>({
	id,
	queryKey,
	namespace,
	queryFn: customQueryFn,
	options = {},
}: IBaseModelQueryArgs<TApiResponseData, TData, TError>) {
	const queryFn =
		customQueryFn || getDefaultQueryFn<TApiResponseData>(id, namespace);
	return useQuery<TApiResponseData, TError, TData>(queryKey, queryFn, {
		suspense: false,
		...options,
	});
}

export default useBaseModel;
