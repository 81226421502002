import type { MantineNumberSize, SkeletonProps } from '@mantine/core';
import { Stack } from '@mantine/core';
import { range } from 'lodash-es';
import type { TextProps } from '../Text';
import { TextSkeleton } from './TextSkeleton';

type ParagraphSkeletonProps = Omit<SkeletonProps, 'width'> &
	Pick<TextProps, 'size'> & {
		width?: MantineNumberSize;
		lines?: number;
	};

export function ParagraphSkeleton({
	width,
	size,
	lines = 3,
}: ParagraphSkeletonProps) {
	return (
		<Stack spacing="sm" w={width}>
			{range(lines).map((line) => (
				<TextSkeleton
					key={line}
					size={size}
					width={line < lines - 1 ? '100%' : '80%'}
					height={8}
				/>
			))}
		</Stack>
	);
}
