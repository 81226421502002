import { observer } from 'mobx-react-lite';
import { AutomationTriggerType, type Automation } from '../../../../api';
import type { ScheduleConfig } from '../../../../api/types/models/schedule';
import { ScheduleCadence } from '../../../../api/types/models/schedule';
import { useAutomationStore } from '../../../../pages/AutomationPage/context';
import HorizontalScheduleSelector from '../../../ScheduleSelector/HorizontalScheduleSelector';
import AutomationActionCardFilterContent from '../AutomationActionCard/Filter/AutomationActionCardFilterContent';
import { useAutomationTriggerCardStore } from './context';

interface AutomationTriggerCardContentProps {
	handleAutomationUpdate: (automation: Partial<Automation>) => Promise<void>;
	searchKey?: string;
}

function AutomationTriggerCardContent({
	handleAutomationUpdate,
	searchKey,
}: AutomationTriggerCardContentProps) {
	const { automation } = useAutomationStore();

	const { selectedTrigger } = useAutomationTriggerCardStore();
	const handleScheduleUpdate = async (schedule: ScheduleConfig) => {
		handleAutomationUpdate({ schedule: schedule });
	};

	if (selectedTrigger === AutomationTriggerType.SCHEDULE) {
		return (
			<HorizontalScheduleSelector
				schedule={
					automation?.schedule || {
						cadence: ScheduleCadence.DAILY,
						frequency: 1,
						hour: 0,
						day: 0,
					}
				}
				onScheduleUpdate={handleScheduleUpdate}
			/>
		);
	}

	if (selectedTrigger === AutomationTriggerType.SCHEMA_CHANGE) {
		return (
			<AutomationActionCardFilterContent
				handleAutomationUpdate={handleAutomationUpdate}
				searchKey={searchKey}
			/>
		);
	}

	throw new Error('The trigger is not implemented yet.');
}

export default observer(AutomationTriggerCardContent);
