import { DOMParser, DOMSerializer, Schema } from 'prosemirror-model';
import { captureError } from '../../../../../web-tracing';

const WORD_ONLINE_SCHEMA = new Schema({
	marks: {
		bold: {
			parseDOM: [
				{ tag: 'b' },
				{ tag: 'strong' },
				{ style: 'font-weight=bold' },
			],
			toDOM: () => ['strong', 0],
		},
		highlight: {
			parseDOM: [{ tag: 'mark' }, { tag: '.TextRun.Highlight' }],
			toDOM: () => ['mark', 0],
		},
		italic: {
			parseDOM: [{ tag: 'i' }, { tag: 'em' }, { style: 'font-style=italic' }],
			toDOM: () => ['em', 0],
		},
		link: {
			parseDOM: [{ tag: 'a' }],
			toDOM: () => ['a', 0],
		},
		strikethrough: {
			parseDOM: [
				{ tag: 's' },
				{ tag: 'del' },
				{ tag: '.TextRun.Strikethrough' },
				{ style: 'text-decoration=line-through' },
			],
			toDOM: () => ['del', 0],
		},
		underline: {
			parseDOM: [
				{ tag: 'u' },
				{ tag: '.TextRun.Underline' },
				{ style: 'text-decoration=underline' },
			],
			toDOM: () => ['u', 0],
		},
	},
	nodes: {
		doc: { content: 'block+' },
		paragraph: {
			content: 'inline*',
			group: 'block',
			parseDOM: [{ tag: 'p', priority: 1 }],
			toDOM: () => ['p', 0],
		}, // default priority is 50. This makes everything else be parsed as a paragraph.
		text: { group: 'inline' },
		bullet_list: {
			content: 'list_item+',
			group: 'block',
			parseDOM: [{ tag: 'ul' }],
			toDOM: () => ['ul', 0],
		},
		br: {
			inline: true,
			group: 'inline',
			parseDOM: [{ tag: 'br' }],
			toDOM: () => ['br'],
		},
		heading: {
			attrs: {
				level: {
					default: 1,
				},
			},
			content: 'inline*',
			group: 'block',
			parseDOM: [
				{
					priority: 100,
					tag: 'p.Paragraph[role=heading]',
					getAttrs: (dom) => {
						if (typeof dom === 'string') {
							return null;
						}

						const level = parseInt(dom.getAttribute('aria-level') ?? '1');

						if (isNaN(level)) {
							return null;
						}

						// editor only supports up to h4
						return { level: Math.min(level, 4) };
					},
				},
			],
			toDOM: (node) => [`h${node.attrs.level}`, 0],
		},
		// TODO: https://linear.app/secoda/issue/ENG-11470/parse-base64-images-from-word-online-into-image-nodes
		// image: { inline: true, content: 'text*', group: 'inline', parseDOM: [{ tag: 'img' }], toDOM: () => ['img'] },
		list_item: {
			content: 'paragraph block*',
			parseDOM: [{ tag: 'li' }],
			toDOM: () => ['li', 0],
		},
		ordered_list: {
			content: 'list_item+',
			group: 'block',
			parseDOM: [{ tag: 'ol' }],
			toDOM: () => ['ol', 0],
		},
		table: {
			content: 'tr+',
			tableRole: 'table',
			group: 'block',
			parseDOM: [{ tag: 'table' }],
			toDOM: () => ['table', 0],
		},
		td: {
			content: 'paragraph+',
			tableRole: 'cell',
			parseDOM: [{ tag: 'td' }],
			toDOM: () => ['td', 0],
		},
		th: {
			content: 'paragraph+',
			tableRole: 'header_cell',
			parseDOM: [{ tag: 'th' }],
			toDOM: () => ['th', 0],
		},
		tr: {
			content: '(th | td)*',
			tableRole: 'row',
			parseDOM: [{ tag: 'tr' }],
			toDOM: () => ['tr', 0],
		},
	},
});

const parser = DOMParser.fromSchema(WORD_ONLINE_SCHEMA);
const serializer = DOMSerializer.fromSchema(WORD_ONLINE_SCHEMA);

function stringToDom(html: string): Document {
	const detachedDoc = document.implementation.createHTMLDocument();
	detachedDoc.body.innerHTML = html;
	return detachedDoc;
}

export function parseWordOnlineDoc(html: string): string | null {
	try {
		const dom = stringToDom(html);
		const parsed = parser.parse(dom);
		const serialized = serializer.serializeFragment(parsed.content);
		const parent = document.createElement('div');
		parent.append(serialized);
		const output = parent.innerHTML;
		return output;
	} catch (e) {
		captureError(e);
		return null;
	}
}
