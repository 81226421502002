import { createStyles, Modal } from '@mantine/core';
import { Prism } from '@mantine/prism';
import type { ILineageTableQuery } from '../../../api';
import { useFmtSql } from '../../../api/hooks/query/useFormatterQuery';

interface ICreationQueryModalProps {
	opened: boolean;
	onClose: () => void;
	query?: ILineageTableQuery;
}

const useStyles = createStyles(() => ({
	queryBlock: {
		overflowY: 'auto',
		'.mantine-Prism-code': {
			fontSize: 10,
		},
	},
}));

export function CreationQueryModal({
	opened,
	onClose,
	query,
}: ICreationQueryModalProps) {
	const { classes, theme } = useStyles();

	const handleModalClose = () => {
		onClose();
	};

	const sql = useFmtSql(query?.sql, query?.dialect);

	return (
		<Modal
			opened={opened}
			size={theme.other.width.lg}
			title="Creation query"
			onClose={handleModalClose}
		>
			<Prism language="sql" className={classes.queryBlock}>
				{sql ?? ''}
			</Prism>
		</Modal>
	);
}
