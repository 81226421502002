import { useApiGetTeam } from '@repo/api-codegen';
import type { ReactElement } from 'react';
import { Navigate } from 'react-router';
import {
	getSideBarSettingValue,
	type SidebarSettings,
} from '../../api/types/models/team';

import { useCurrentTeamId } from '../../utils/hook/useCurrentTeamId';

export interface TeamPageRouteProps {
	children: ReactElement;
	pageKey: keyof SidebarSettings;
}

export function TeamPageRoute({ children, pageKey }: TeamPageRouteProps) {
	const teamId = useCurrentTeamId();
	const { data: team, isLoading } = useApiGetTeam(
		{
			pathParams: {
				teamId: teamId!,
			},
		},
		{
			enabled: !!teamId,
		}
	);

	const canShowPage = team
		? getSideBarSettingValue(team.sidebar_settings, pageKey)
		: false;

	if (isLoading) {
		// Don't block rendering of the page while loading the team setting. It is
		// rare to navigate to a page you don't have access to, so we can show the
		// page while we wait for the team setting.
		return children;
	}

	return canShowPage ? children : <Navigate to="/home" />;
}
