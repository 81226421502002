import type { IconNames } from '@repo/foundations';
import type { IntegrationStatus } from '../../api';

export type IntegrationStatusTones =
	| 'neutral'
	| 'info'
	| 'ai'
	| 'critical'
	| 'attention'
	| 'warning'
	| 'success';

export const INTEGRATION_STATUS_MAPPING: Record<
	IntegrationStatus,
	{
		tone: IntegrationStatusTones;
		label: string;
		iconName?: IconNames;
	}
> = {
	new: {
		tone: 'success',
		label: 'New',
	},
	success: {
		tone: 'success',
		label: 'Completed',
	},
	failed: {
		tone: 'critical',
		label: 'Failed',
	},
	running: {
		tone: 'info',
		label: 'Running',
		iconName: 'refresh',
	},
	queued: {
		tone: 'info',
		label: 'Queued',
	},
	pending: {
		tone: 'info',
		label: 'Pending',
	},
	terminated: {
		tone: 'warning',
		label: 'Terminated',
	},
};
