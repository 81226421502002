import {
	createStyles,
	Divider,
	Group,
	Stack,
	UnstyledButton,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { UserRole } from '@repo/common/enums/UserRole.ts';
import { Button, Icon, Text, Title } from '@repo/foundations';
import { startCase } from 'lodash-es';
import { useIntercom } from 'react-use-intercom';
import {
	useAuthUser,
	useInviteUsers,
	usersQueryKeyFactory,
} from '../../../api';
import { trackEvent } from '../../../utils/analytics';
import { usePromise } from '../../../utils/suspense';
import Flex from '../../Editor/outline/src/components/Flex.tsx';
import { InviteMembersModal } from '../../PriceIncreaseModals';
import { RichEditor } from '../../RichEditor';
import { GitbookMarkdownParser } from '../utils';

interface IntegrationGuideProps {
	markdownURL: string;
	type: string;
}

const useStyles = createStyles((theme) => ({
	wrapper: {
		backgroundColor: theme.other.getColor('fill/primary/hover'),
		paddingBottom: theme.spacing.lg,
		gap: theme.spacing.lg,
		justifyContent: 'space-between',
		overflowY: 'auto',
	},
	content: {
		padding: `0 ${theme.spacing['2xl']}`,
		maxWidth: theme.other.width.lg,
		minWidth: theme.other.width.sm,
		overflowY: 'auto',
		'.rich-text-editor': {
			width: '100%',
			justifyContent: 'unset',
		},
	},
	footer: {
		gap: theme.spacing.lg,
	},
	footerContent: {
		padding: `0 ${theme.spacing['2xl']}`,
		gap: theme.spacing.sm,
	},
	inviteWrapper: {
		backgroundColor: theme.other.getColor('fill/tertiary/default'),
		borderRadius: theme.radius.sm,
		border: `1px solid ${theme.other.getColor('border/secondary/default')}`,
		padding: theme.spacing.lg,
		gap: theme.spacing.md,
	},
	anchor: {
		'&:hover': {
			color: theme.other.getColor('text/emphasis/hover'),
		},
		'&:focus, &:active': {
			color: theme.other.getColor('text/emphasis/active'),
		},
	},
}));

function IntegrationGuide({ markdownURL, type }: IntegrationGuideProps) {
	const { classes } = useStyles();

	const [opened, { open, close }] = useDisclosure(false);

	const { user, workspace } = useAuthUser();

	const { boot, show } = useIntercom();
	const { mutateAsync: inviteUsers } = useInviteUsers(
		usersQueryKeyFactory.list()
	);

	const markdown = usePromise(
		'documentationMarkdown',
		async (url) => {
			// We try/catch here because Github has crashed in the past, and it leads
			// to crashing Secoda.
			try {
				// We use fetch for generic fetching; whereas axios is used for authenticated requests.
				const text = await (await fetch(url)).text();
				const parser = new GitbookMarkdownParser(text);
				return parser.parseGitbookMarkdown();
			} catch (e) {
				return '';
			}
		},
		[markdownURL]
	);

	const handleHelpClick = async () => {
		await boot();
		show();
	};

	return (
		<Stack className={classes.wrapper}>
			<Flex className={classes.content}>
				<RichEditor readOnly initialValue={markdown} />
			</Flex>
			<Stack className={classes.footer}>
				<Divider />
				<Stack className={classes.footerContent}>
					<Stack className={classes.inviteWrapper}>
						<Stack spacing="4xs">
							<Title size="sm">Don’t have credentials to setup?</Title>
							<Text size="sm">
								Invite a team member to Secoda to help set up this integration.
							</Text>
						</Stack>
						<Button leftIconName="plus" w="fit-content" onClick={() => open()}>
							Invite team member
						</Button>
					</Stack>
					<Group spacing="3xs">
						<Icon name="lifebuoy" color="icon/secondary/default" />
						<Text size="sm" weight="semibold">
							Need help from our team?
						</Text>
						<UnstyledButton onClick={handleHelpClick}>
							<Text
								className={classes.anchor}
								size="sm"
								weight="semibold"
								color="text/emphasis/default"
							>
								Reach out via chat
							</Text>
						</UnstyledButton>
					</Group>
				</Stack>
			</Stack>
			{/* TODO: Update Invite Modal [ENG-8277] */}
			<InviteMembersModal
				title={`Invite members to help connect ${startCase(type)}`}
				opened={opened}
				onClose={close}
				onConfirm={async (
					emails: string[],
					role: UserRole,
					groups: string[],
					teams: string[]
				) => {
					await inviteUsers({
						emails,
						role,
						groups,
						teams,
					});

					trackEvent(
						'integrations/invite_user/send',
						{
							integration_type: type,
						},
						user,
						workspace!
					);
				}}
				role={UserRole.ADMIN}
				hideGroupSelector
				hideTeamSelector
			/>
		</Stack>
	);
}

export default IntegrationGuide;
