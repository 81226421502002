import type { EntityStakeholderResponse } from '@repo/api-codegen';
import { uniqBy } from 'lodash-es';
import type { ISecodaEntity } from '../../api';
import { useUserList } from '../../api';
import { useSlackHookList } from '../../api/hooks/workspace/useSlackHooks';
import type {
	ISlackChannel,
	SecodaEntity,
	User,
	UserGroup,
} from '../../lib/models';
import {
	selectChannelMap,
	selectGroupMap,
	selectHookMap,
	selectUserMap,
} from './helpers';

type GroupType =
	| 'Other groups'
	| 'Other users'
	| 'Frequent users'
	| 'Upstream users'
	| 'Downstream users'
	| 'Slack channels'
	| 'Other Slack channels'
	| 'Downstream Slack channels'
	| 'User owners'
	| 'Group owners';

export const useInternalUsers = (entity: SecodaEntity | ISecodaEntity) => {
	const { data: usersData } = useUserList({});

	const internalUsers =
		usersData?.results?.filter((user) =>
			entity.internal_users?.includes(user.id)
		) ?? [];

	return {
		internalUsers,
	};
};

export const useExternalUsers = (entity: SecodaEntity | ISecodaEntity) => {
	const { data: serviceAccountsData } = useUserList({
		filters: {
			is_service_account: true,
		},
	});

	const { data: usersData } = useUserList({
		filters: {
			is_service_account: false,
		},
	});

	const withPopularity =
		serviceAccountsData?.results
			?.filter((sa) => sa.service_account_count_popularity)
			.concat(usersData?.results ?? [])
			?.filter((user) => entity.external_users.includes(user.id)) ?? [];

	const withPopularityAndValidEmail = withPopularity.filter(
		(u: { email: string | string[] }) =>
			!u.email.includes('gserviceaccount.com')
	);

	return {
		withPopularity,
		withPopularityAndValidEmail,
	};
};

/**
 * Return the list of users that can be selected as recipients of an announcement. This may contain duplicates.
 */
export const useEntityUserList = (
	entity: SecodaEntity | ISecodaEntity,
	users: User[],
	groups: UserGroup[],
	stakeholdersResult: EntityStakeholderResponse,
	slackChannels: ISlackChannel[]
) => {
	const { data: hooksData } = useSlackHookList({
		filters: {
			entity: entity.id,
		},
	});

	const { withPopularityAndValidEmail } = useExternalUsers(entity);
	const { internalUsers } = useInternalUsers(entity);

	const upstreamUsers = stakeholdersResult.upstream_owners.map(
		selectUserMap<GroupType>('Upstream users')
	);

	const downstreamUsers = stakeholdersResult.downstream_owners.map(
		selectUserMap<GroupType>('Downstream users')
	);

	const downstreamSlackChannels = uniqBy(
		stakeholdersResult.downstream_slack_channels,
		'slack_conversation_id'
	).map(selectHookMap<GroupType>('Downstream Slack channels'));

	const entityOwners = users
		.filter((user) => entity.owners.includes(user.id))
		.map(selectUserMap<GroupType>('User owners'));

	const entityOwnersGroups = groups
		.filter((group) => entity.owners_groups?.includes(group.id))
		.map(selectGroupMap<GroupType>('Group owners'));

	const externalFrequentUsers = (
		withPopularityAndValidEmail as unknown as User[]
	).map(selectUserMap<GroupType>('Frequent users'));

	const internalFrequentUsers = (internalUsers as unknown as User[])?.map(
		selectUserMap<GroupType>('Frequent users')
	);

	const workspaceUsers = users.map(selectUserMap<GroupType>('Other users'));

	const workspaceGroups = groups.map(selectGroupMap<GroupType>('Other groups'));

	const hooks =
		hooksData?.results.map(selectHookMap<GroupType>('Slack channels')) ?? [];

	const channels = slackChannels.map(
		selectChannelMap<GroupType>('Other Slack channels')
	);

	return [
		...entityOwners,
		...entityOwnersGroups,
		...internalFrequentUsers,
		...externalFrequentUsers,
		...downstreamUsers,
		...upstreamUsers,
		...hooks,
		...channels,
		...downstreamSlackChannels,
		...workspaceUsers,
		...workspaceGroups,
	];
};
