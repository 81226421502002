import { Box, Group, type GroupProps } from '@mantine/core';
import { Button, type ButtonProps } from '@repo/foundations';
import type React from 'react';
import type { ConfirmLabels } from './context';
import { useModals } from './hooks';

export interface ConfirmationModalProps {
	id?: string;
	children?: React.ReactNode;
	onCancel?(): void;
	onConfirm?(): void;
	closeOnConfirm?: boolean;
	closeOnCancel?: boolean;
	cancelProps?: Omit<ButtonProps, 'children'> &
		React.ComponentPropsWithoutRef<'button'>;
	confirmProps?: Omit<ButtonProps, 'children'> &
		React.ComponentPropsWithoutRef<'button'>;
	groupProps?: GroupProps;
	labels?: ConfirmLabels;
}

export function ConfirmationModal({
	id,
	cancelProps,
	confirmProps,
	labels = { cancel: '', confirm: '' },
	closeOnConfirm = true,
	closeOnCancel = true,
	groupProps,
	onCancel,
	onConfirm,
	children,
}: ConfirmationModalProps) {
	const { cancel: cancelLabel, confirm: confirmLabel } = labels;
	const ctx = useModals();

	const handleCancel = (event: React.MouseEvent<HTMLButtonElement>) => {
		typeof cancelProps?.onClick === 'function' && cancelProps?.onClick(event);
		typeof onCancel === 'function' && onCancel();
		closeOnCancel && id && ctx.closeModal(id);
	};

	const handleConfirm = (event: React.MouseEvent<HTMLButtonElement>) => {
		typeof confirmProps?.onClick === 'function' && confirmProps?.onClick(event);
		typeof onConfirm === 'function' && onConfirm();
		closeOnConfirm && id && ctx.closeModal(id);
	};

	return (
		<>
			{children && <Box mb="md">{children}</Box>}

			<Group
				position="right"
				{...groupProps}
				sx={(theme) => ({
					borderTop: `1px solid ${theme.other.getColor('border/secondary/default')}`,
					marginLeft: `-${theme.spacing.lg}`,
					marginRight: `-${theme.spacing.lg}`,
					paddingLeft: theme.spacing.lg,
					paddingRight: theme.spacing.lg,
					paddingTop: theme.spacing.md,
				})}
			>
				{cancelLabel && (
					<Button variant="default" {...cancelProps} onClick={handleCancel}>
						{cancelProps?.children || cancelLabel}
					</Button>
				)}
				{confirmLabel && (
					<Button variant="primary" {...confirmProps} onClick={handleConfirm}>
						{confirmProps?.children || confirmLabel}
					</Button>
				)}
			</Group>
		</>
	);
}
