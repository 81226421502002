import { Divider, Group, Image, Loader, Modal, Stack } from '@mantine/core';
import { Button, Text, TextInput } from '@repo/foundations';
import { useState } from 'react';

import { useLinkJiraIssue } from '@repo/api-codegen';
import jiraSvg from '@repo/common/public/images/logos/integrations/jira.svg';
import { queryClient, questionsQueryKeyFactory } from '../../../../api';
import { incidentsQueryKeyFactory } from '../../../../api/hooks/monitoring/constants';
import { useStyles } from './styles';

type JiraIssueLinkModal = {
	opened: boolean;
	handleClose: () => void;
	entityId: string;
};

export function JiraIssueLinkModal({
	opened,
	handleClose,
	entityId,
}: JiraIssueLinkModal) {
	const { classes } = useStyles();

	const [issueUrl, setIssueUrl] = useState<string>('');
	const [linkError, setLinkError] = useState<string>('');

	const { mutateAsync: issueLink, isLoading: isLinkLoading } = useLinkJiraIssue(
		{
			onSuccess: () => {
				queryClient.invalidateQueries(incidentsQueryKeyFactory.all());
				queryClient.invalidateQueries(questionsQueryKeyFactory.all());
				handleClose();
			},
			onError: (e) => {
				if ('message' in e && e.message === 'Invalid URL') {
					setLinkError('Invalid URL');
				} else {
					throw e;
				}
			},
		}
	);

	const handleLink = () => {
		if (issueUrl) {
			issueLink({
				body: {
					issue_link: issueUrl,
				},
				pathParams: {
					entityId: entityId,
				},
			});
		} else {
			setLinkError('This field is Required');
		}
	};

	return (
		<Modal.Root opened={opened} onClose={handleClose} closeOnEscape>
			<Modal.Overlay />
			<Modal.Content>
				<Modal.Header className={classes.modalHeader}>
					<Modal.Title>
						<Group>
							<Image width={16} height={16} src={jiraSvg} />
							<Text size="md" weight="bold">
								Link an issue from Jira
							</Text>
						</Group>
					</Modal.Title>
					<Modal.CloseButton onClick={handleClose} />
				</Modal.Header>
				<Divider />
				<Modal.Body pt="sm">
					<Stack pt="md">
						<TextInput
							label="Issue link"
							onChange={(e) => {
								setIssueUrl(e.target.value);
								setLinkError('');
							}}
							error={linkError}
						/>
					</Stack>
				</Modal.Body>
				<Divider />
				<Group position="right" spacing="sm" p="md">
					<Button variant="default" onClick={handleClose}>
						Cancel
					</Button>
					{isLinkLoading ? (
						<Loader size="sm" />
					) : (
						<Button variant="primary" onClick={handleLink}>
							Link issue
						</Button>
					)}
				</Group>
			</Modal.Content>
		</Modal.Root>
	);
}
