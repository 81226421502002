import { createQueryKeys } from '../../factories';

export const MONITOR_NAMESPACE = ['monitor', 'monitors'];
export const monitorsQueryKeyFactory = createQueryKeys(MONITOR_NAMESPACE);

export const INCIDENT_NAMESPACE = ['monitor', 'incidents'];
export const incidentsQueryKeyFactory = createQueryKeys(INCIDENT_NAMESPACE);

export const MEASUREMENT_NAMESPACE = ['monitor', 'measurements'];
export const measurementsQueryKeyFactory = createQueryKeys(
	MEASUREMENT_NAMESPACE
);
