import * as yup from 'yup';
import { TeamType } from '../../../interfaces';

export const teamFormSchema = yup.object({
	icon: yup.string().required(),
	name: yup
		.string()
		.required('Must provide name')
		.trim()
		.min(1, 'Must be at least 1 character'),
	type: yup.string().oneOf([TeamType.OPEN, TeamType.CLOSED]).required(),
});

export type ITeamForm = yup.InferType<typeof teamFormSchema>;
