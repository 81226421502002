import { isEmpty, isNil, pickBy } from 'lodash-es';
import { queryClient } from '../../../api';
import { apiClient, getEndpoints } from '../../../api/common';
import { INTERNAL_QUERY_ACTIONS_NAMESPACE } from '../../../api/hooks/internalQueryActions';
import { LINEAGE_QUERY_OPTIONS } from '../../../hooks/useLineage/constants';

export interface IFetchLineageParams {
	from_entity_id?: string;
	to_entity_id?: string;
	from_entity__type?: string;
	to_entity__type?: string;
	from_entity__native_type?: string;
	to_entity__native_type?: string;
	from_entity__integration_id?: string;
	to_entity__integration_id?: string;
	direction?: string;
	is_manual?: boolean;
	archived?: boolean;
	workspace_id?: string;
	integration_id?: string;
}

export interface IFetchResourceParams {
	id?: string;
	title?: string;
	entity_type?: string;
	native_type?: string;
	integration_id?: string;
	integration__type?: string;
	workspace_id?: string;
	stale?: boolean;
	archived?: boolean;
}

const filterParams = (params: object) =>
	pickBy(params, (value) => !isNil(value) && !isEmpty(value));

async function fetchQueryWithType(
	path: string,
	params: IFetchLineageParams | IFetchResourceParams,
	options = LINEAGE_QUERY_OPTIONS
) {
	const queryKey = [...INTERNAL_QUERY_ACTIONS_NAMESPACE, path, params];
	const filters = filterParams(params);

	if (isEmpty(filters)) {
		return Promise.resolve([]);
	}

	const url = getEndpoints(INTERNAL_QUERY_ACTIONS_NAMESPACE).byPath([path]);
	const { data } = await queryClient.fetchQuery({
		queryKey,
		queryFn: () => apiClient.post(url, filters),
		...options,
	});

	return path.includes('count') ? data : data.results;
}

export const fetchLineage = (params: IFetchLineageParams) =>
	fetchQueryWithType('lineage', params);

export const fetchLineageCount = (params: IFetchLineageParams) =>
	fetchQueryWithType('lineage_count', params);

export const fetchResource = (params: IFetchResourceParams) =>
	fetchQueryWithType('resource', params);

export const fetchResourceCount = (params: IFetchResourceParams) =>
	fetchQueryWithType('resource_count', params);
