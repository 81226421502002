import RichTooltip from '@repo/common/components/RichTooltip/RichTooltip';
import { Text } from '@repo/foundations';
import { useAuthUser, useGetGovernanceIncludedEntities } from '../../../../api';
import { StaticProperty } from '../../EntityPropertySidebar';

interface IAIGovernanceInclusionStatusProps {
	entityId: string;
}

export function AIGovernanceInclusionStatus({
	entityId,
}: IAIGovernanceInclusionStatusProps) {
	const { data: isIncluded } = useGetGovernanceIncludedEntities(entityId);
	const { workspace, isAdminUser } = useAuthUser();

	if (!workspace?.ai_search) {
		return null;
	}

	return (
		<StaticProperty
			type="custom"
			label="AI access"
			value={String(isIncluded ? 'Included' : 'Excluded')}
			custom={
				<RichTooltip
					title={''}
					body={
						isIncluded
							? "Secoda's AI assistant will be able to access this resource to answer questions. To manage AI access, please visit AI settings"
							: "Secoda's AI assistant will not be able to access this resource to answer questions. To manage AI access, please visit AI settings"
					}
					children={
						<Text ml={0} size="sm">
							{isIncluded ? 'Included' : 'Excluded'}
						</Text>
					}
					linkLabel={isAdminUser ? 'AI settings' : undefined}
					linkUrl={isAdminUser ? '/settings/ai' : undefined}
					position="left"
				/>
			}
		/>
	);
}
