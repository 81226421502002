import { Divider, useMantineTheme } from '@mantine/core';
import { useAuthUser } from '../../api';
import { useFeatureAccess } from '../../api/hooks/workspace/useFeatureAccess';
import { useUpdateWorkspace } from '../../api/hooks/workspace/useUpdateWorkspace';
import { EmptyState } from '../EmptyState';
import { QualityGovernanceFilters } from './GovernanceFilters/QualityGovernanceFilters';
import { UpgradeButton } from './UpgradeButton';
import { SettingsSwitch } from './WorkspaceSettings';

export function QualityScoreSettings() {
	const { workspace } = useAuthUser();
	const { mutateAsync: updateWorkspace } = useUpdateWorkspace(workspace.id);
	const theme = useMantineTheme();

	const { dqsAccess } = useFeatureAccess();

	if (!dqsAccess) {
		return (
			<EmptyState
				title="Upgrade to access Quality Score"
				description="You can use Quality Score to understand and improve your data quality."
				illustrationName="upgrade"
				includeGoBack={false}
				stateHeight="50vh"
				size="lg"
				withActions={<UpgradeButton feature="DQS" size="md" />}
			/>
		);
	}

	return (
		<div>
			<SettingsSwitch
				onChange={(e) =>
					updateWorkspace({
						data: { id: workspace.id, quality_enabled: e.target.checked },
					})
				}
				checked={workspace?.quality_enabled ?? false}
				title="Use Quality Score"
				description="Turn on Quality Score for all tables."
			/>
			<Divider mb={theme.spacing.lg} />
			<QualityGovernanceFilters />
		</div>
	);
}
