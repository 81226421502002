import { TextSkeleton } from '@repo/foundations';
import { InlineRelatedResourcesBody } from './InlineRelatedResourcesBody';

export function InlineRelatedResourcesLoading() {
	return (
		<InlineRelatedResourcesBody align="center">
			<TextSkeleton size="sm" text="synonyms test" />
			<TextSkeleton size="sm" text="synonyms test" />
			<TextSkeleton size="sm" text="synonyms test" />
		</InlineRelatedResourcesBody>
	);
}
