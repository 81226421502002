import { createStyles } from '@mantine/core';
import type { IconSizes } from '@repo/foundations';
import { IconSparkles } from '@tabler/icons-react';
import { memo } from 'react';
import { AIIconAnimated } from './IconAnimated';

const useBgStyles = createStyles((theme, { size }: { size: number }) => ({
	bg: {
		borderRadius: theme.radius.xl,
		position: 'absolute',
		top: 0,
		left: 0,
		width: size,
		height: size,
	},
}));

function AIAnimatedBg({
	size,
	speed,
}: {
	size: number;
	speed: 'fast' | 'slow' | 'none';
}) {
	const { classes, theme } = useBgStyles({ size });

	const dur = speed === 'fast' ? '1.5s' : '4s';
	const begin = speed === 'none' ? 'indefinite' : '0s';
	const opacity = 0;
	const circleSize = size * 0.8;
	const circleDistanceFromCenter = size * 0.5;

	return (
		<svg
			className={classes.bg}
			width={size}
			height={size}
			viewBox={`0 0 ${size} ${size}`}
			xmlns="http://www.w3.org/2000/svg"
		>
			<defs>
				<filter
					id="nnnoise-filter"
					x="-20%"
					y="-20%"
					width="140%"
					height="140%"
					filterUnits="objectBoundingBox"
					primitiveUnits="userSpaceOnUse"
					colorInterpolationFilters="linearRGB"
				>
					<feTurbulence
						type="fractalNoise"
						baseFrequency="0.9"
						numOctaves="8"
						seed="15"
						stitchTiles="stitch"
						x="0%"
						y="0%"
						width="100%"
						height="100%"
						result="turbulence"
					/>
					<feSpecularLighting
						surfaceScale="1"
						specularConstant="0.75"
						specularExponent="20"
						lightingColor="gray"
						x="0%"
						y="0%"
						width="100%"
						height="100%"
						in="turbulence"
						result="specularLighting"
					>
						<feDistantLight azimuth="3" elevation="100" />
					</feSpecularLighting>
				</filter>

				<radialGradient id="gradient-1">
					<stop
						offset="10%"
						stopColor={theme.other.getColor('icon/ai/default')}
						stopOpacity="1"
					/>
					<stop
						offset="95%"
						stopColor={theme.other.getColor('icon/ai/default')}
						stopOpacity={opacity}
					/>
				</radialGradient>

				<radialGradient id="gradient-2">
					<stop offset="10%" stopColor={theme.colors.cyan[3]} stopOpacity="1" />
					<stop
						offset="95%"
						stopColor={theme.colors.cyan[3]}
						stopOpacity={opacity}
					/>
				</radialGradient>

				<radialGradient id="gradient-3">
					<stop offset="10%" stopColor={theme.colors.pink[1]} stopOpacity="1" />
					<stop
						offset="95%"
						stopColor={theme.colors.pink[1]}
						stopOpacity={opacity}
					/>
				</radialGradient>

				<radialGradient id="gradient-4">
					<stop
						offset="10%"
						stopColor={theme.colors.grape[4]}
						stopOpacity="1"
					/>
					<stop
						offset="95%"
						stopColor={theme.colors.grape[4]}
						stopOpacity={opacity}
					/>
				</radialGradient>
			</defs>

			<circle
				cx={size * 0.8}
				cy={size * 0.5}
				r={circleSize}
				fill="url(#gradient-2)"
			>
				<animateTransform
					attributeName="transform"
					attributeType="XML"
					type="rotate"
					from={`0 ${circleDistanceFromCenter} ${circleDistanceFromCenter}`}
					to={`-360 ${circleDistanceFromCenter} ${circleDistanceFromCenter}`}
					dur={dur}
					repeatCount="indefinite"
					begin={begin}
				/>
			</circle>

			<circle
				cx={size * 0.5}
				cy={size * 0.8}
				r={circleSize}
				fill="url(#gradient-3)"
			>
				<animateTransform
					attributeName="transform"
					attributeType="XML"
					type="rotate"
					from={`0 ${circleDistanceFromCenter} ${circleDistanceFromCenter}`}
					to={`360 ${circleDistanceFromCenter} ${circleDistanceFromCenter}`}
					dur={dur}
					repeatCount="indefinite"
					begin={begin}
				/>
			</circle>

			<circle
				cx={size * 0.2}
				cy={size * 0.5}
				r={circleSize}
				fill="url(#gradient-4)"
			>
				<animateTransform
					attributeName="transform"
					attributeType="XML"
					type="rotate"
					from={`0 ${circleDistanceFromCenter} ${circleDistanceFromCenter}`}
					to={`-360 ${circleDistanceFromCenter} ${circleDistanceFromCenter}`}
					dur={dur}
					repeatCount="indefinite"
					begin={begin}
				/>
			</circle>

			<circle
				cx={size * 0}
				cy={size * 0}
				r={circleSize}
				fill="url(#gradient-1)"
			>
				<animateTransform
					attributeName="transform"
					attributeType="XML"
					type="rotate"
					from={`0 ${circleDistanceFromCenter} ${circleDistanceFromCenter}`}
					to={`360 ${circleDistanceFromCenter} ${circleDistanceFromCenter}`}
					dur={dur}
					repeatCount="indefinite"
					begin={begin}
				/>
			</circle>

			<rect width={size} height={size} filter="url(#nnnoise-filter)" />
		</svg>
	);
}

const useAvatarStyles = createStyles(
	(theme, { avatarSize }: { avatarSize: number }) => ({
		root: {
			position: 'relative',
			width: avatarSize,
			height: avatarSize,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		icon: {
			zIndex: 1,
		},
		animatedIcon: {
			zIndex: 1,
			marginLeft: '-2px',
		},
	})
);

const SIZE_CONFIG = {
	lg: {
		avatarSize: 32,
		iconSize: 20,
	},
	md: {
		avatarSize: 28,
		iconSize: 16,
	},
	illustration: {
		avatarSize: 128,
		iconSize: 80,
	},
} as const;

type AnimationSpeed = 'fast' | 'slow' | 'none';

interface AIAvatarProps {
	size: IconSizes | 'illustration';
	speed: AnimationSpeed;
	iconSpeed?: AnimationSpeed;
}

function AIAvatarInternal({
	size = 'md',
	speed = 'fast',
	iconSpeed = speed,
}: AIAvatarProps) {
	const { avatarSize, iconSize } = SIZE_CONFIG[size];

	const { classes, theme } = useAvatarStyles({ avatarSize });

	return (
		<div className={classes.root}>
			<AIAnimatedBg size={avatarSize} speed={speed} />
			{iconSpeed === 'none' && (
				<IconSparkles
					className={classes.icon}
					color={theme.white}
					size={iconSize}
				/>
			)}
			{iconSpeed !== 'none' && (
				<AIIconAnimated
					className={classes.animatedIcon}
					speed={iconSpeed === 'fast' ? '1s' : '2s'}
				/>
			)}
		</div>
	);
}

export const AIAvatar = memo(AIAvatarInternal);
