import { Input, useMantineTheme } from '@mantine/core';
import type { ChangeEventHandler } from 'react';
import { useEffect, useRef } from 'react';

interface ISelectorSearchProps {
	searchTerm: string;
	setSearchTerm: (term: string) => void;
	placeholder?: string;
}

function SelectorSearch({
	searchTerm,
	setSearchTerm,
	placeholder = 'Search...',
}: ISelectorSearchProps) {
	const ref = useRef<HTMLInputElement>(null);
	const theme = useMantineTheme();

	useEffect(() => {
		const timeout = setTimeout(() => {
			if (ref.current) {
				ref.current.focus();
			}
		}, 5);

		return () => clearTimeout(timeout);
	}, []);
	const handleSearchTermChange: ChangeEventHandler<HTMLInputElement> = (
		event
	) => {
		setSearchTerm(event.currentTarget.value);
	};

	return (
		<Input
			ref={ref}
			height={32}
			mb={theme.spacing['2xs']}
			value={searchTerm}
			placeholder={placeholder}
			onChange={handleSearchTermChange}
		/>
	);
}

export default SelectorSearch;
