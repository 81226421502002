import { Stack } from '@mantine/core';
import type { IIntegration } from '../../../../../api';
import type { IntegrationSpec } from '../../../../../interfaces/IntegrationSpec';
import { SelectIntegrationGroupsPanel } from '../../../components/SelectIntegrationGroupsPanel/SelectIntegrationGroupsPanel.tsx';

interface SelectPanelProps {
	integration: IIntegration;
	spec: IntegrationSpec;
}

export function SelectPanel({ integration, spec }: SelectPanelProps) {
	return (
		<Stack h="100%" style={{ overflowY: 'hidden' }}>
			<SelectIntegrationGroupsPanel integration={integration} spec={spec} />
		</Stack>
	);
}
