import {
	Avatar,
	Center,
	Group,
	Skeleton,
	Stack,
	Tooltip,
	useMantineTheme,
} from '@mantine/core';
import type { TeamMembershipOut, TeamOut } from '@repo/api-codegen';
import { useApiListMemberships } from '@repo/api-codegen';
import { Button, Text } from '@repo/foundations';
import { IconInfoCircle } from '@tabler/icons-react';
import { useCallback, useState } from 'react';
import { membershipText } from '../../api/types/models/team';
import { getDisplayName } from '../../utils/userUtils';
import { EmptyState } from '../EmptyState';
import { toTitleCase } from '../SearchListItem/helpers';
import { UserAvatar } from '../UserAvatar';
import { MembershipSelector } from './MembershipSelector';

export function TeamMembersList({
	team,
	readOnly,
	readOnlyTooltip,
	withoutBorder = false,
	searchTerm,
}: {
	team: TeamOut;
	readOnly?: boolean;
	readOnlyTooltip?: string;
	withoutBorder?: boolean;
	searchTerm: string;
}) {
	const theme = useMantineTheme();
	const [currentPage, setCurrentPage] = useState(1);

	const { data, isLoading } = useApiListMemberships({
		pathParams: {
			teamId: team.id,
		},
		queryParams: { page: currentPage, search_term: searchTerm },
	});

	const handleNextPage = useCallback(() => {
		setCurrentPage((prevPage) =>
			Math.min(prevPage + 1, data?.total_pages ?? 1)
		);
	}, [data?.total_pages]);

	const handlePrevPage = useCallback(() => {
		setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
	}, []);

	if (isLoading) {
		return (
			<Center>
				<Skeleton height={theme.other.space[90]} w={'100%'} />
			</Center>
		);
	}

	if (data?.results?.length === 0) {
		return (
			<EmptyState
				title={'No existing memberships found'}
				description={'Try adjusting your search query'}
				includeGoBack={false}
				size={'sm'}
			/>
		);
	}

	return (
		<Stack spacing={0}>
			{data?.results?.map((membership: TeamMembershipOut) => (
				<Group
					position="apart"
					key={membership.id}
					py={8}
					sx={{
						borderBottom: withoutBorder
							? undefined
							: `1px solid ${theme.colors.gray[3]}`,
						'&:last-child': {
							borderBottom: 'none',
						},
					}}
				>
					{membership.user ? (
						<Group noWrap w={204} spacing={8}>
							<UserAvatar user={membership.user} enableLink size="md" />
							<Stack spacing={0}>
								<Text size="sm" weight="semibold" lineClamp={1}>
									{getDisplayName(membership.user)}
								</Text>
								<Text size="xs" color="text/secondary/default" lineClamp={1}>
									{toTitleCase(membership.user.role)}
								</Text>
							</Stack>
						</Group>
					) : (
						<Group noWrap w={204} spacing={8}>
							<Avatar size="md">{membership.user_group!.icon}</Avatar>
							<Stack spacing={0}>
								<Text size="sm" weight="semibold" lineClamp={1}>
									{membership.user_group!.name}
								</Text>
								<Text size="xs" color="text/secondary/default">
									{membership.user_group?.users?.length} members
								</Text>
							</Stack>
						</Group>
					)}
					<Group position="right">
						{readOnly ? (
							<Group spacing={6}>
								<Text size="sm" color="text/secondary/default">
									{membershipText(membership)}
								</Text>
								{readOnlyTooltip && (
									<Tooltip label={readOnlyTooltip}>
										<IconInfoCircle size={14} color={theme.colors.gray[6]} />
									</Tooltip>
								)}
							</Group>
						) : (
							<MembershipSelector membership={membership} disabled={readOnly} />
						)}
					</Group>
				</Group>
			))}
			{data?.total_pages !== 1 && (
				<Group position="center" mt="md">
					<Button
						size="sm"
						onClick={handlePrevPage}
						disabled={currentPage === 1}
						aria-label="Previous Page"
					>
						Prev
					</Button>
					<Text size="xs">{`Page ${currentPage} of ${data?.total_pages}`}</Text>
					<Button
						size="sm"
						onClick={handleNextPage}
						disabled={currentPage === data?.total_pages}
						aria-label="Next Page"
					>
						Next
					</Button>
				</Group>
			)}
		</Stack>
	);
}
