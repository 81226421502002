import type { IMetricWidget, IMetricWidgetSample } from '../../api';

import { WidgetSize } from '../../interfaces';

export type ISampleOrActualMetricWidget =
	| {
			source: 'widget' | 'preview';
			metricWidget: IMetricWidget;
	  }
	| {
			source: 'sample';
			metricWidget: IMetricWidgetSample;
	  };

export const WIDGET_SIZE_MAP: Record<WidgetSize, string> = {
	[WidgetSize.SMALL]: '4',
	[WidgetSize.MEDIUM]: '6',
	[WidgetSize.LARGE]: '8',
	[WidgetSize.FULL]: '12',
};

export type WidgetSource = 'widget' | 'sample' | 'preview';

export const METRIC_WIDGET_CONTENT_HEIGHT: Record<WidgetSource, number> = {
	widget: 280,
	sample: 200,
	preview: 60,
};

export const PREVIEW_AREA_HEIGHT = 170;

export const WIDGET_PREVIEW_HEIGHT = 100;
