import { createStyles, Group, Stack } from '@mantine/core';
import { Icon, Text } from '@repo/foundations';
import type { PropsWithChildren } from 'react';

const useStyles = createStyles((theme) => ({
	wrapper: {
		borderRadius: theme.radius.sm,
		background: theme.other.getColor('surface/critical/default'),
		gap: theme.spacing['2xs'],
		alignItems: 'flex-start',
		margin: `0 ${theme.spacing.md} ${theme.spacing.md}`,
		padding: theme.spacing.xs,
		flexWrap: 'nowrap',
	},
}));

interface ResultsErrorProps {
	errorMessage: string;
}

export function ResultsError({
	errorMessage,
	children,
}: PropsWithChildren<ResultsErrorProps>) {
	const { classes } = useStyles();

	return (
		<Group className={classes.wrapper}>
			<Icon name="alertCircle" color="text/critical/default" />
			<Stack spacing="xs" align="flex-start">
				<Stack spacing={0}>
					<Text size="sm" color="text/critical/default" weight="bold">
						Query execution error
					</Text>
					<Text size="sm" color="text/critical/default">
						{errorMessage}
					</Text>
				</Stack>
				{children}
			</Stack>
		</Group>
	);
}
