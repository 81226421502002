import { ColorSwatch, Group, useMantineTheme } from '@mantine/core';
import { Text } from '@repo/foundations';
import type { IMetricWidget } from '../../../api';
import { snakeCaseToTitleCase } from '../../../utils/shared.utils';
import { DEFAULT_COLORS } from '../utils/utils';

export function ValueBadge({ metricWidget }: { metricWidget: IMetricWidget }) {
	const theme = useMantineTheme();

	const groupStyle = {
		backgroundColor: theme.colors.gray[2],
		borderRadius: theme.spacing.md,
	};

	return (
		<Group position="right" spacing="sm" mt={2}>
			{Object.keys(metricWidget.metric_metadata.value || {}).map((value) => {
				if (value) {
					return (
						<Group px={6} spacing={6} sx={groupStyle} key={value}>
							<ColorSwatch
								size={6}
								color={
									metricWidget.metric_metadata.value_color?.[value] ||
									DEFAULT_COLORS[0]
								}
							/>
							<Text color="text/primary/default" size="sm">
								{snakeCaseToTitleCase(value)}
							</Text>
						</Group>
					);
				}
				return null;
			})}
		</Group>
	);
}
