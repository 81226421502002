import { useDebouncedValue } from '@mantine/hooks';
import Fuse from 'fuse.js';
import { useMemo } from 'react';

interface UseFuseSearchOptions<T> {
	term: string;
	items: T[];
	keys: Fuse.FuseOptionKey<T>[];
	options?: Fuse.IFuseOptions<T>;
	wait?: number;
	filterFn?: (item: T) => boolean;
}

function useFuseSearch<T>({
	term,
	items,
	keys,
	options = { threshold: 0.6, includeScore: true, ignoreLocation: true },
	wait = 500,
	filterFn,
}: UseFuseSearchOptions<T>): T[] {
	const [debouncedTerm] = useDebouncedValue(term, wait);

	const fuse = useMemo(
		() => new Fuse(items, { ...options, keys }),
		[items, options, keys]
	);

	const results = useMemo(() => {
		if (!debouncedTerm && !filterFn) {
			return items;
		}

		const filteredItems = filterFn ? items.filter(filterFn) : items;

		if (!debouncedTerm) {
			return filteredItems;
		}

		return fuse.search(debouncedTerm).map((result) => result.item);
	}, [debouncedTerm, items, fuse, filterFn]);

	return results;
}

export default useFuseSearch;
