import type { SVGProps } from 'react';

export function SecodaFullTextLogo(
	props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>
) {
	return (
		<svg
			{...props}
			style={{
				minHeight: '24px',
			}}
			width="109"
			height="24"
			viewBox="0 0 109 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M8.39052 8.6727L14.5925 3.46865C16.3942 1.95682 19.0803 2.19183 20.5922 3.99355C21.6297 5.35599 21.8353 6.97944 21.2152 8.38544C21.0004 8.87234 21.0019 9.45313 21.3391 9.86479C21.7849 10.409 22.6155 10.4449 22.9962 9.85334C24.3739 7.71268 24.4642 4.85063 22.4424 2.44107C20.0731 -0.382475 15.8635 -0.750765 13.04 1.61847L8.75773 5.21171C8.29114 4.58083 7.54177 4.17176 6.69685 4.17176C5.28211 4.17176 4.13524 5.31864 4.13524 6.73338C4.13524 8.14812 5.28211 9.29499 6.69685 9.29499C7.28219 9.29499 7.82167 9.09866 8.25305 8.76827C8.30086 8.74114 8.3469 8.70931 8.39052 8.6727Z"
				fill="#F56551"
			/>
			<path
				d="M15.3719 8.39046L20.576 14.5924C22.0878 16.3941 21.8528 19.0803 20.0511 20.5921C18.6886 21.6296 17.0652 21.8352 15.6592 21.2151C15.1723 21.0004 14.5915 21.0018 14.1798 21.339C13.6356 21.7848 13.5997 22.6154 14.1913 22.9961C16.332 24.3738 19.194 24.4641 21.6036 22.4423C24.4271 20.0731 24.7954 15.8635 22.4262 13.0399L18.8329 8.75767C19.4638 8.29108 19.8729 7.54171 19.8729 6.69679C19.8729 5.28205 18.726 4.13518 17.3113 4.13518C15.8965 4.13518 14.7496 5.28205 14.7496 6.69679C14.7496 7.28213 14.946 7.82161 15.2764 8.25299C15.3035 8.3008 15.3353 8.34684 15.3719 8.39046Z"
				fill="#F56551"
			/>
			<path
				d="M15.6095 15.3719L9.40759 20.5759C7.60587 22.0877 4.91971 21.8527 3.40789 20.051C2.37035 18.6886 2.16476 17.0651 2.78487 15.6591C2.99962 15.1722 2.99818 14.5914 2.66096 14.1798C2.21518 13.6356 1.38457 13.5997 1.00386 14.1912C-0.373818 16.3319 -0.464149 19.1939 1.55771 21.6035C3.92694 24.427 8.13653 24.7953 10.9601 22.4261L15.2423 18.8329C15.7089 19.4637 16.4583 19.8728 17.3032 19.8728C18.718 19.8728 19.8648 18.7259 19.8648 17.3112C19.8648 15.8964 18.718 14.7496 17.3032 14.7496C16.7179 14.7496 16.1784 14.9459 15.747 15.2763C15.6992 15.3034 15.6532 15.3353 15.6095 15.3719Z"
				fill="#F56551"
			/>
			<path
				d="M8.61584 15.6095L3.41178 9.40759C1.89996 7.60587 2.13497 4.91971 3.93669 3.40789C5.29913 2.37035 6.92257 2.16476 8.32858 2.78487C8.81548 2.99962 9.39627 2.99818 9.80793 2.66096C10.3521 2.21518 10.388 1.38457 9.79647 1.00386C7.65581 -0.373818 4.79377 -0.464149 2.3842 1.55771C-0.439339 3.92695 -0.807628 8.13653 1.5616 10.9601L5.15485 15.2423C4.52396 15.7089 4.1149 16.4583 4.1149 17.3032C4.1149 18.718 5.26177 19.8648 6.67651 19.8648C8.09125 19.8648 9.23812 18.718 9.23812 17.3032C9.23812 16.7179 9.0418 16.1784 8.7114 15.747C8.68428 15.6992 8.65244 15.6532 8.61584 15.6095Z"
				fill="#F56551"
			/>
			<path
				d="M40.1406 8.04888C40.0609 7.3028 39.7248 6.72188 39.1325 6.30612C38.5459 5.89037 37.7828 5.68249 36.843 5.68249C36.1824 5.68249 35.6157 5.78215 35.143 5.98149C34.6703 6.18083 34.3086 6.45135 34.058 6.79307C33.8074 7.13479 33.6793 7.52492 33.6736 7.96346C33.6736 8.32795 33.7562 8.64404 33.9213 8.91172C34.0922 9.1794 34.3229 9.40721 34.6133 9.59516C34.9038 9.77741 35.2256 9.93118 35.5787 10.0565C35.9318 10.1818 36.2877 10.2871 36.6465 10.3726L38.2868 10.7826C38.9474 10.9364 39.5825 11.1443 40.1919 11.4063C40.807 11.6682 41.3566 11.9986 41.8407 12.3972C42.3304 12.7959 42.7177 13.2772 43.0025 13.841C43.2873 14.4048 43.4296 15.0655 43.4296 15.823C43.4296 16.8481 43.1677 17.7508 42.6437 18.5311C42.1197 19.3056 41.3622 19.9122 40.3713 20.3507C39.386 20.7836 38.1928 21 36.7918 21C35.4306 21 34.2488 20.7893 33.2464 20.3678C32.2498 19.9464 31.4695 19.3313 30.9057 18.5225C30.3475 17.7138 30.0457 16.7285 30.0001 15.5667H33.1183C33.1639 16.1761 33.3518 16.683 33.6821 17.0873C34.0125 17.4917 34.4425 17.7935 34.9721 17.9929C35.5075 18.1922 36.1055 18.2919 36.7661 18.2919C37.4553 18.2919 38.059 18.1894 38.5772 17.9843C39.1012 17.7736 39.5113 17.4832 39.8074 17.113C40.1036 16.7371 40.2545 16.2985 40.2602 15.7973C40.2545 15.3417 40.1207 14.9658 39.8587 14.6697C39.5967 14.3678 39.2294 14.1172 38.7566 13.9179C38.2896 13.7129 37.7429 13.5306 37.1164 13.3711L35.1259 12.8586C33.685 12.4884 32.5459 11.9274 31.7087 11.1756C30.8772 10.4181 30.4614 9.41291 30.4614 8.15994C30.4614 7.12909 30.7405 6.22639 31.2987 5.45183C31.8625 4.67727 32.6285 4.07641 33.5967 3.64926C34.5649 3.21642 35.6613 3 36.8857 3C38.1273 3 39.2151 3.21642 40.1491 3.64926C41.0889 4.07641 41.8264 4.67157 42.3618 5.43474C42.8971 6.19222 43.1734 7.0636 43.1904 8.04888H40.1406Z"
				fill="#232221"
			/>
			<path
				d="M50.7249 20.9915C49.4093 20.9915 48.2731 20.7181 47.3163 20.1713C46.3651 19.6189 45.6333 18.8386 45.1207 17.8306C44.6081 16.8168 44.3519 15.6236 44.3519 14.2511C44.3519 12.9013 44.6081 11.7167 45.1207 10.6972C45.639 9.67205 46.3623 8.8747 47.2906 8.30517C48.219 7.72995 49.3096 7.44234 50.5626 7.44234C51.3713 7.44234 52.1345 7.57333 52.8521 7.83531C53.5754 8.0916 54.2133 8.49027 54.7657 9.03132C55.3239 9.57238 55.7624 10.2615 56.0813 11.0987C56.4003 11.9302 56.5597 12.9212 56.5597 14.0717V15.0199H45.8042V12.9355H53.5953C53.5896 12.3431 53.4615 11.8163 53.2109 11.355C52.9603 10.888 52.61 10.5206 52.1601 10.253C51.7159 9.98529 51.1976 9.85145 50.6053 9.85145C49.9731 9.85145 49.4178 10.0052 48.9394 10.3128C48.461 10.6146 48.088 11.0133 47.8203 11.5088C47.5583 11.9986 47.4245 12.5368 47.4188 13.1234V14.943C47.4188 15.7062 47.5583 16.3612 47.8374 16.9079C48.1164 17.449 48.5066 17.8647 49.0078 18.1552C49.5089 18.44 50.0956 18.5823 50.7676 18.5823C51.2175 18.5823 51.6248 18.5197 51.9893 18.3944C52.3538 18.2634 52.6698 18.0726 52.9375 17.822C53.2052 17.5714 53.4074 17.261 53.5441 16.8908L56.4316 17.2155C56.2493 17.9786 55.9019 18.645 55.3893 19.2145C54.8825 19.7784 54.2332 20.2169 53.4416 20.5301C52.6499 20.8377 51.7444 20.9915 50.7249 20.9915Z"
				fill="#232221"
			/>
			<path
				d="M63.6969 20.9915C62.387 20.9915 61.2622 20.7038 60.3225 20.1286C59.3884 19.5534 58.668 18.7589 58.1611 17.7451C57.6599 16.7257 57.4093 15.5524 57.4093 14.2254C57.4093 12.8927 57.6656 11.7167 58.1782 10.6972C58.6908 9.67205 59.4141 8.8747 60.3481 8.30517C61.2878 7.72995 62.3984 7.44234 63.6799 7.44234C64.7449 7.44234 65.6875 7.63882 66.5076 8.0318C67.3334 8.41908 67.9912 8.96868 68.481 9.68059C68.9708 10.3868 69.2499 11.2126 69.3182 12.158H66.3623C66.2427 11.5259 65.958 10.9991 65.508 10.5776C65.0638 10.1505 64.4687 9.93688 63.7226 9.93688C63.0904 9.93688 62.5351 10.1077 62.0567 10.4495C61.5783 10.7855 61.2052 11.2696 60.9376 11.9018C60.6756 12.5339 60.5446 13.2914 60.5446 14.1742C60.5446 15.0683 60.6756 15.8372 60.9376 16.4808C61.1996 17.1187 61.5669 17.6113 62.0396 17.9587C62.518 18.3004 63.079 18.4713 63.7226 18.4713C64.1782 18.4713 64.5854 18.3859 64.9442 18.215C65.3087 18.0384 65.6134 17.785 65.8583 17.4547C66.1032 17.1243 66.2712 16.7228 66.3623 16.2501H69.3182C69.2442 17.1785 68.9708 18.0014 68.4981 18.719C68.0254 19.4309 67.3818 19.9891 66.5674 20.3934C65.7529 20.7921 64.7961 20.9915 63.6969 20.9915Z"
				fill="#232221"
			/>
			<path
				d="M76.3785 20.9915C75.0971 20.9915 73.9865 20.7095 73.0468 20.1457C72.107 19.5819 71.378 18.7931 70.8598 17.7793C70.3472 16.7655 70.0909 15.5809 70.0909 14.2254C70.0909 12.87 70.3472 11.6825 70.8598 10.663C71.378 9.64357 72.107 8.85192 73.0468 8.28809C73.9865 7.72425 75.0971 7.44234 76.3785 7.44234C77.66 7.44234 78.7705 7.72425 79.7103 8.28809C80.65 8.85192 81.3761 9.64357 81.8887 10.663C82.407 11.6825 82.6661 12.87 82.6661 14.2254C82.6661 15.5809 82.407 16.7655 81.8887 17.7793C81.3761 18.7931 80.65 19.5819 79.7103 20.1457C78.7705 20.7095 77.66 20.9915 76.3785 20.9915ZM76.3956 18.514C77.0904 18.514 77.6714 18.3232 78.1384 17.9416C78.6054 17.5543 78.9528 17.0361 79.1806 16.3868C79.4141 15.7375 79.5309 15.0142 79.5309 14.2169C79.5309 13.4139 79.4141 12.6877 79.1806 12.0384C78.9528 11.3835 78.6054 10.8624 78.1384 10.4751C77.6714 10.0878 77.0904 9.89416 76.3956 9.89416C75.6837 9.89416 75.0914 10.0878 74.6187 10.4751C74.1517 10.8624 73.8014 11.3835 73.5679 12.0384C73.3401 12.6877 73.2262 13.4139 73.2262 14.2169C73.2262 15.0142 73.3401 15.7375 73.5679 16.3868C73.8014 17.0361 74.1517 17.5543 74.6187 17.9416C75.0914 18.3232 75.6837 18.514 76.3956 18.514Z"
				fill="#232221"
			/>
			<path
				d="M88.9576 20.9658C87.9267 20.9658 87.0041 20.701 86.1897 20.1713C85.3752 19.6417 84.7317 18.8728 84.259 17.8647C83.7863 16.8567 83.5499 15.6322 83.5499 14.1913C83.5499 12.7333 83.7891 11.5031 84.2675 10.5007C84.7516 9.49264 85.4037 8.73232 86.2239 8.21974C87.044 7.70147 87.9581 7.44234 88.9661 7.44234C89.735 7.44234 90.3672 7.57333 90.8627 7.83531C91.3582 8.0916 91.7511 8.40199 92.0416 8.76649C92.3321 9.1253 92.557 9.46417 92.7165 9.7831H92.8446V3.2392H95.9457V20.7352H92.9044V18.6678H92.7165C92.557 18.9867 92.3264 19.3256 92.0245 19.6844C91.7227 20.0375 91.324 20.3393 90.8285 20.5899C90.333 20.8405 89.7094 20.9658 88.9576 20.9658ZM89.8204 18.4286C90.4754 18.4286 91.0335 18.252 91.4948 17.8989C91.9562 17.5401 92.3064 17.0418 92.5456 16.4039C92.7848 15.766 92.9044 15.0228 92.9044 14.1742C92.9044 13.3256 92.7848 12.588 92.5456 11.9616C92.3121 11.3351 91.9647 10.8481 91.5034 10.5007C91.0478 10.1533 90.4868 9.97959 89.8204 9.97959C89.1313 9.97959 88.5561 10.159 88.0948 10.5178C87.6334 10.8766 87.286 11.3721 87.0525 12.0043C86.819 12.6364 86.7023 13.3598 86.7023 14.1742C86.7023 14.9943 86.819 15.7262 87.0525 16.3697C87.2917 17.0076 87.642 17.5116 88.1033 17.8818C88.5703 18.2463 89.1427 18.4286 89.8204 18.4286Z"
				fill="#232221"
			/>
			<path
				d="M101.814 21C100.983 21 100.234 20.8519 99.5675 20.5558C98.9069 20.2539 98.3829 19.8097 97.9956 19.2231C97.614 18.6364 97.4232 17.9131 97.4232 17.0532C97.4232 16.3128 97.5599 15.7005 97.8333 15.2164C98.1067 14.7323 98.4797 14.345 98.9524 14.0546C99.4251 13.7641 99.9576 13.5449 100.55 13.3968C101.148 13.243 101.766 13.1319 102.404 13.0636C103.173 12.9839 103.796 12.9127 104.275 12.85C104.753 12.7817 105.1 12.6792 105.317 12.5425C105.539 12.4001 105.65 12.1808 105.65 11.8847V11.8334C105.65 11.1898 105.459 10.6915 105.078 10.3384C104.696 9.98529 104.147 9.80873 103.429 9.80873C102.671 9.80873 102.071 9.9739 101.626 10.3042C101.188 10.6346 100.892 11.0247 100.738 11.4746L97.8504 11.0645C98.0782 10.2672 98.4541 9.60085 98.9781 9.0655C99.502 8.52444 100.143 8.12008 100.9 7.8524C101.658 7.57902 102.495 7.44234 103.412 7.44234C104.044 7.44234 104.673 7.51637 105.3 7.66445C105.926 7.81253 106.499 8.05743 107.017 8.39914C107.535 8.73517 107.951 9.19364 108.264 9.77456C108.583 10.3555 108.743 11.0816 108.743 11.953V20.7352H105.77V18.9326H105.667C105.479 19.2971 105.214 19.6388 104.873 19.9578C104.537 20.271 104.112 20.5244 103.6 20.7181C103.093 20.906 102.498 21 101.814 21ZM102.617 18.7276C103.238 18.7276 103.776 18.6051 104.232 18.3602C104.688 18.1096 105.038 17.7793 105.283 17.3692C105.533 16.9592 105.659 16.5121 105.659 16.028V14.4817C105.562 14.5615 105.397 14.6355 105.163 14.7038C104.935 14.7722 104.679 14.832 104.394 14.8832C104.11 14.9345 103.828 14.9801 103.549 15.0199C103.269 15.0598 103.027 15.094 102.822 15.1224C102.361 15.1851 101.948 15.2876 101.584 15.43C101.219 15.5724 100.932 15.7717 100.721 16.028C100.51 16.2786 100.405 16.6032 100.405 17.0019C100.405 17.5714 100.613 18.0014 101.028 18.2919C101.444 18.5823 101.974 18.7276 102.617 18.7276Z"
				fill="#232221"
			/>
		</svg>
	);
}
