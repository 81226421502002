import { createStyles, Group, Menu, Stack, TextInput } from '@mantine/core';
import { useInputState } from '@mantine/hooks';
import { fuzzySearchFilter } from '@repo/common/utils/fuse';
import { Icon, IconButton } from '@repo/foundations';
import { capitalize } from 'lodash-es';
import { useMemo } from 'react';
import { CUSTOM_PROPERTY_OPTIONS } from './constants';
import type { CustomPropertyType } from './CustomProperty.types';

type IAddCustomPropertyProps = {
	target?: React.ReactNode;
} & (
	| {
			onAddProperty: (type: CustomPropertyType) => void;
			value?: never;
			onChange?: never;
	  }
	| {
			onAddProperty?: never;
			value: CustomPropertyType;
			onChange: (value: CustomPropertyType) => void;
	  }
);

const useStyles = createStyles((theme) => ({
	dropdown: {
		padding: theme.spacing.none,
		borderRadius: theme.radius.md,
	},
	textInput: {
		borderRadius: 0,
		borderTopLeftRadius: theme.radius.md,
		borderTopRightRadius: theme.radius.md,
		border: 'none',
		borderBottom: `1px solid ${theme.other.getColor('border/primary/default')}`,
	},
}));

function ModifyCustomProperty({
	target,
	value,
	onChange,
	onAddProperty,
}: IAddCustomPropertyProps) {
	const { classes } = useStyles();
	const [searchTerm, setSearchTerm] = useInputState('');

	const handleMenuItemClick = (type: CustomPropertyType) => () => {
		// If the type is already selected, then update
		// it to the new type, else create a new property
		if (value) {
			onChange(type);
			return;
		}

		onAddProperty(type);
	};

	const filteredTypes = useMemo(
		() =>
			fuzzySearchFilter(searchTerm, CUSTOM_PROPERTY_OPTIONS, ['type'], {
				threshold: 0.1,
			}),
		[searchTerm]
	);
	return (
		<Menu
			position="bottom-end"
			classNames={{
				dropdown: classes.dropdown,
			}}
		>
			<Menu.Target>
				{target || (
					<IconButton
						data-testid="add-custom-property-032487fh"
						iconName="plus"
						variant="tertiary"
						tooltip="Add property"
					/>
				)}
			</Menu.Target>
			<Menu.Dropdown>
				<TextInput
					classNames={{
						input: classes.textInput,
					}}
					placeholder="Search properties..."
					value={searchTerm}
					onChange={setSearchTerm}
				/>
				<Stack p="3xs" spacing="none">
					{filteredTypes.map(({ type, iconName }) => {
						const isSelected = value === type;

						return (
							<Menu.Item
								px="xs"
								key={type}
								onClick={handleMenuItemClick(type)}
								icon={<Icon name={iconName} />}
							>
								<Group noWrap position="apart">
									{capitalize(type)}
									{isSelected && <Icon name="check" />}
								</Group>
							</Menu.Item>
						);
					})}
				</Stack>
			</Menu.Dropdown>
		</Menu>
	);
}

export default ModifyCustomProperty;
