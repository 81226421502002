export const AnnouncementDefaultFormData = {
	subject: '',
	definition: '',
	recipients: [],
	receiver_slack_channel_ids: [],
};

export type AnnouncementFormKeys = keyof typeof AnnouncementDefaultFormData;

export interface IAnnouncementFieldProps {
	onChange: (key: AnnouncementFormKeys) => (value: string | string[]) => void;
	initialValue?: string;
}
