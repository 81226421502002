export const typography = {
	weight: {
		regular: 400,
		semibold: 500,
		bold: 600,
	},
	title: {
		'7xl': '5rem',
		'4xl': '2.5rem',
		'3xl': '2rem',
		xxl: '1.75rem',
		xl: '1.5rem',
		lg: '1.25rem',
		md: '0.9375rem',
		sm: '0.8125rem',
		xs: '0.75rem',
		xxs: '0.5rem',
	},
	text: {
		'7xl': '5rem',
		'4xl': '2.5rem',
		'3xl': '2rem',
		xxl: '1.75rem',
		xl: '1.5rem',
		lg: '1.25rem',
		md: '0.9375rem',
		sm: '0.8125rem',
		xs: '0.75rem',
		xxs: '0.5rem',
	},
	lineHeight: {
		title: {
			'7xl': '5.25rem',
			'4xl': '2.75rem',
			'3xl': '2.25rem',
			xxl: '2rem',
			xl: '1.75rem',
			lg: '1.5rem',
			md: '1.5rem',
			sm: '1.25rem',
			xs: '1rem',
			xxs: '0.8rem',
		},
		text: {
			'7xl': '5.25rem',
			'4xl': '2.75rem',
			'3xl': '2.25rem',
			xxl: '2rem',
			xl: '1.75rem',
			lg: '1.5rem',
			md: '1.5rem',
			sm: '1.25rem',
			xs: '1rem',
			xxs: '0.8rem',
		},
	},
} as const;
