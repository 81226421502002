import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
	leftCol: {
		width: 175,
		fontSize: theme.fontSizes.sm,
		fontWeight: theme.other.typography.weight.regular,
		lineHeight: '20px',
		color: theme.colors.gray[6],
	},
	rightCol: {
		flex: 1,
		flexWrap: 'nowrap',
	},
	cursorPointer: {
		cursor: 'pointer',
	},
	label: {
		fontSize: theme.fontSizes.sm,
		fontWeight: theme.other.typography.weight.semibold,
		lineHeight: '20px',
		color: theme.colors.gray[9],
	},
	subLabel: {
		fontSize: theme.fontSizes.xs,
		fontWeight: theme.other.typography.weight.regular,
		lineHeight: '16px',
		color: theme.colors.gray[6],
	},
	soleLabel: {
		fontSize: theme.fontSizes.sm,
		fontWeight: theme.other.typography.weight.regular,
		lineHeight: '20px',
		color: theme.colors.gray[9],
	},
	avatar: {
		width: 32,
		height: 32,
		minWidth: 32,
		minHeight: 32,
	},
	colorGroup: {
		width: '100%',
		padding: '6px 8px',
		borderRadius: theme.radius.xs,
	},
	emptyLabel: {
		fontSize: theme.fontSizes.sm,
		fontWeight: theme.other.typography.weight.regular,
		lineHeight: '20px',
		color: theme.colors.gray[5],
	},
}));
