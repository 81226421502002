import { findIndex } from 'lodash-es';

/**
 * Sorts an array of strings by another array of strings.
 *
 * @param arrayToSort - The array to sort.
 * @param comparatorArray - The array to sort by.
 * @param ascending - Whether to sort in ascending order.
 */
export function sortArrayByAnotherArray(
	arrayToSort: string[],
	comparatorArray: string[],
	ascending = true
) {
	return arrayToSort.sort((firstItem, secondItem) => {
		const firstIndex = findIndex(comparatorArray, (item) => item === firstItem);
		const secondIndex = findIndex(
			comparatorArray,
			(item) => item === secondItem
		);

		return (firstIndex - secondIndex) * (ascending ? 1 : -1);
	});
}
