import type { MarkType, Node } from 'prosemirror-model';
import type { EditorState } from 'prosemirror-state';
import type { Dispatch } from '../types';

// Based on prosemirror-transform/mark/removeMark
// and the discussion at https://discuss.prosemirror.net/t/best-method-for-collecting-all-marks-in-a-block/2883

// Node.forEach() is broken in source code. It calls Fragment.forEach() under the hood but the iteration is wrong and is not recursive
// The Fragment.forEach() loop uses `i < this.content.length` which equals `i < undefined` = False because length is not a property of Fragment.
// This function replicate the same behavior but uses `this.content.childCount` instead of `this.content.length`
export function applyRecursivelyOnNode(
	f: (_node: Node, _pos: number) => void,
	node: Node,
	pos: number
) {
	for (let i = 0; i < node.content.childCount; i += 1) {
		const child = node.content.child(i);
		f(child, pos);
		applyRecursivelyOnNode(f, child, pos + 1);
		pos += child.nodeSize;
	}
}

// Find and remove all comment marks with the given commentID
// If replacementCommentID is provided, add a new mark in the same position
export default function removeCommentMark(
	state: EditorState,
	dispatch: Dispatch,
	commentID: string,
	replacementCommentID?: string
) {
	const markType: MarkType = state.schema.marks.comment;

	// Start new transaction
	const { tr } = state;

	// ForEach recursively applies the function to all children of the current node.
	const removeMarksInNode = (node: Node, pos: number) => {
		// Get all marks that match the mark type and have the same commentID
		const markToRemoves = node.marks.filter(
			(mark) => mark.type === markType && mark.attrs.commentID === commentID
		);
		const startPos = pos;
		const endPos = pos + node.nodeSize;

		markToRemoves.forEach((mark) => {
			// Add a step to remove the mark
			tr.removeMark(startPos, endPos, mark);
			// Add a replacement step if there's a replacement commentID (we can't modify attributes directly but must remove and add
			if (replacementCommentID) {
				tr.addMark(
					startPos,
					endPos,
					markType.create({ commentID: replacementCommentID })
				);
			}
		});
	};

	applyRecursivelyOnNode(removeMarksInNode, tr.doc, 0);

	// Dispatch transaction
	dispatch(tr);
}
