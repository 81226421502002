import { createStyles } from '@mantine/core';

export const useStyles = createStyles(
	(
		theme,
		{ hasValue, hasClear }: { hasValue: boolean; hasClear: boolean }
	) => ({
		root: {
			border: `1px ${hasValue ? 'solid' : 'dashed'} ${theme.other.getColor('border/primary/default')}`,
			borderRadius: theme.radius.sm,
			padding: hasClear
				? `0 ${theme.spacing.xs} 0 ${theme.spacing.sm}`
				: `0 0 0 ${theme.spacing['3xs']}`,
			gap: theme.spacing['2xs'],
			backgroundColor: theme.other.getColor('fill/primary/default'),

			button: {
				outline: 'none !important',
			},

			'&:hover': {
				backgroundColor: theme.other.getColor('fill/primary/hover'),
			},

			'&:focus-within': {
				backgroundColor: theme.other.getColor('fill/primary/hover'),
				outline: `2px solid ${theme.colors.blue[5]}`,
				outlineOffset: theme.spacing['4xs'],
			},

			'&:active': {
				backgroundColor: theme.other.getColor('fill/primary/hover'),
			},
		},
		target: {
			padding: `${theme.spacing['5xs']} ${theme.spacing['2xs']}`,
		},
	})
);
