import { useWorkspaceActivity } from '../../api/hooks/notification/useResourceActivity';
import Timeline from './Timeline';

function WorkspaceActivityLog() {
	const {
		data: notifications,
		fetchNextPage,
		hasNextPage,
	} = useWorkspaceActivity();

	if (!notifications?.pages) {
		return null;
	}

	const handleEndReached = () => {
		if (hasNextPage) {
			fetchNextPage();
		}
	};

	return (
		<Timeline
			notifications={notifications?.pages ?? []}
			onEndReached={handleEndReached}
		/>
	);
}

export default WorkspaceActivityLog;
