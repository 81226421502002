import type { UnstyledButtonProps } from '@mantine/core';
import {
	createPolymorphicComponent,
	createStyles,
	Loader,
	UnstyledButton,
} from '@mantine/core';
import { rem } from '@mantine/styles';
import { typography } from '@repo/theme/primitives';
import type { ColorNames } from '@repo/theme/utils';
import { forwardRef, memo, useCallback } from 'react';
import { Icon, type IconNames } from '../Icon';

export type ButtonVariants = 'default' | 'primary' | 'tertiary';
export type ButtonTones = 'default' | 'critical';
export type ButtonSizes = 'sm' | 'md' | 'lg';

export type IconAnimation =
	| 'spin'
	| 'spinCounterClockwise'
	| 'rotate90'
	| 'rotate180'
	| 'rotateCounterClockwise90'
	| 'rotateCounterClockwise180';

export interface ButtonProps
	extends Omit<UnstyledButtonProps, 'unstyled' | 'variant' | 'children'> {
	/** Controls button appearance  */
	variant?: ButtonVariants;
	/** Controls button appearance  */
	tone?: ButtonTones;
	/** Predefined button size */
	size?: ButtonSizes;
	/** Button type attribute */
	type?: 'submit' | 'button' | 'reset';

	/** Adds icon before button label  */
	leftIconName?: IconNames;
	/** Animation for the left icon */
	leftIconAnimation?: IconAnimation;

	/** Adds icon after button label  */
	rightIconName?: IconNames;
	/** Animation for the right icon */
	rightIconAnimation?: IconAnimation;

	/** Makes the icon in the button a specific color. Use `tone` when possible.  */
	iconColor?: ColorNames;

	/** Makes the left icon a filled icon */
	leftIconFilled?: boolean;

	/** Button label */
	children: React.ReactNode;

	/** Callback for when it's clicked */
	onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;

	/** Indicate loading state */
	loading?: boolean;
	/** Disabled state */
	disabled?: boolean;

	/** Highlight the button visually */
	highlight?: boolean;

	id?: string;
}

interface ButtonStyleProps {
	loading: boolean;
	disabled: boolean;
	size: ButtonSizes;
	variant: ButtonVariants;
	tone: ButtonTones;
	iconColor?: ColorNames;
	iconFillColor?: ColorNames;
	hasLeftIcon: boolean;
	hasRightIcon: boolean;
}

const useStyles = createStyles(
	(
		theme,
		{
			size,
			loading,
			disabled,
			variant,
			tone,
			iconColor: iconColorProp,
			hasLeftIcon,
			hasRightIcon,
		}: ButtonStyleProps
	) => {
		let height: number = theme.other.space[7];
		let paddingX: number = theme.other.space[3];
		let paddingY: number = theme.other.space[1.5];
		let labelColor: ColorNames = 'text/primary/default';
		let fillColor: ColorNames = 'fill/primary/default';
		let iconColor: ColorNames = 'icon/primary/default';
		let hoverFillColor: ColorNames = 'fill/primary/hover';
		let hoverLabelColor: ColorNames = 'text/primary/default';
		let activeFillColor: ColorNames = 'fill/primary/active';
		let activeLabelColor: ColorNames = 'text/primary/default';
		let disabledFillColor: ColorNames = 'fill/primary/disabled';
		let disabledTextColor: ColorNames = 'text/primary/disabled';
		let highlight = '';
		let highlightOnHover = '';
		let highlightOnActive = '';

		if (size === 'lg') {
			// eslint-disable-next-line prefer-destructuring
			height = theme.other.space[8];
			paddingY = theme.other.space[1.5];
		} else if (size === 'sm') {
			// eslint-disable-next-line prefer-destructuring
			height = theme.other.space[6];
			// eslint-disable-next-line prefer-destructuring
			paddingX = theme.other.space[2];
			// eslint-disable-next-line prefer-destructuring
			paddingY = theme.other.space[1];
		}
		if (variant === 'default') {
			fillColor = 'fill/primary/default';
			iconColor = 'icon/primary/default';
			labelColor = 'text/primary/default';
			hoverFillColor = 'fill/primary/hover';
			activeFillColor = 'fill/primary/active';
			disabledFillColor = 'fill/primary/disabled';
			disabledTextColor = 'text/primary/disabled';
			highlight =
				'0px 1px 0px 0px #DDD inset, 1px 0px 0px 0px #DDD inset, -1px 0px 0px 0px #DDD inset, 0px -1px 0px 0px #CCC inset';
			highlightOnHover =
				'0px 1px 0px 0px #EBEBEB inset, 1px 0px 0px 0px #EBEBEB inset, -1px 0px 0px 0px #EBEBEB inset, 0px -1px 0px 0px #CCC inset';
			highlightOnActive =
				'0px 2px 1px 0px rgba(0, 0, 0, 0.12) inset, 1px 1px 1px 0px rgba(0, 0, 0, 0.12) inset, -1px 0px 1px 0px rgba(0, 0, 0, 0.12) inset';
			if (tone === 'default') {
				labelColor = 'text/primary/default';
			} else if (tone === 'critical') {
				labelColor = 'text/critical/default';
				hoverLabelColor = 'text/critical/default';
				activeLabelColor = 'text/critical/default';
				iconColor = 'text/critical/default';
			}
			if (loading) {
				fillColor = 'fill/primary/disabled';
			}
		} else if (variant === 'primary') {
			fillColor = 'fill/brand/default';
			iconColor = 'text/brand-on-fill/default';
			labelColor = 'text/brand-on-fill/default';
			hoverLabelColor = 'text/brand-on-fill/hover';
			activeLabelColor = 'text/brand-on-fill/active';
			hoverFillColor = 'fill/brand/hover';
			activeFillColor = 'fill/brand/active';
			disabledFillColor = 'fill/brand/disabled';
			disabledTextColor = 'text/brand-on-fill/disabled';
			highlight =
				'0px 1px 0px 0px #000 inset, 0px -1px 0px 1px #000 inset, -2px 0px 0px 0px rgba(255, 255, 255, 0.20) inset, 2px 0px 0px 0px rgba(255, 255, 255, 0.20) inset, 0px 2px 0px 0px rgba(255, 255, 255, 0.20) inset';
			highlightOnActive = '0px 3px 0px 0px #000 inset';
			if (tone === 'default') {
				labelColor = 'text/brand-on-fill/default';
			} else if (tone === 'critical') {
				fillColor = 'fill/critical/default';
				hoverFillColor = 'fill/critical/hover';
				activeFillColor = 'fill/critical/active';
				labelColor = 'text/critical-on-fill/default';
				hoverLabelColor = 'text/critical-on-fill/hover';
				activeLabelColor = 'text/critical-on-fill/active';
				iconColor = 'text/critical-on-fill/default';
				highlight =
					'0px 1px 0px 0px rgba(255, 255, 255, 0.48) inset, -1px 0px 0px 0px rgba(255, 255, 255, 0.20) inset, 1px 0px 0px 0px rgba(255, 255, 255, 0.20) inset, 0px -1.5px 0px 0px rgba(0, 0, 0, 0.25) inset';
				highlightOnActive = `0px 3px 0px 0px ${theme.other.getColor(
					'fill/critical/active'
				)} inset`;
			}
			if (loading) {
				fillColor = 'fill/brand/disabled';
			}
		} else if (variant === 'tertiary') {
			fillColor = 'fill/transparent/default';
			iconColor = 'icon/primary/default';
			labelColor = 'text/primary/default';
			hoverFillColor = 'fill/transparent/hover';
			activeFillColor = 'fill/transparent/active';
			disabledFillColor = 'fill/transparent/default';
			disabledTextColor = 'text/primary/disabled';
		}

		if (iconColorProp) {
			// If we get a color from a prop we use it directly. Using `tone` is preferable since it will have active and hover states.
			iconColor = iconColorProp;
		}

		return {
			container: {
				display: 'inline-block',
				position: 'relative',

				height,
				padding: `${rem(paddingY)} 0`,

				borderRadius: theme.other.space[2],

				fontSize: size === 'lg' ? typography.text.sm : typography.text.xs,
				fontWeight: typography.weight.semibold,
				lineHeight:
					size === 'lg'
						? typography.lineHeight.text.sm
						: typography.lineHeight.text.xs,

				backgroundColor: theme.other.getColor(fillColor),
				color: theme.other.getColor(labelColor),

				boxShadow: loading || disabled ? 'none' : highlight,
				transition: 'box-shadow 0.075s ease-in-out 0s',

				'&:hover': {
					backgroundColor: theme.other.getColor(hoverFillColor),
					color: theme.other.getColor(hoverLabelColor),
					boxShadow: highlightOnHover,
				},
				'&:active': {
					backgroundColor: theme.other.getColor(activeFillColor),
					color: theme.other.getColor(activeLabelColor),
					boxShadow: highlightOnActive,
					paddingTop:
						variant !== 'tertiary' ? `calc(${paddingY}px + 1px)` : undefined,
					paddingBottom:
						variant !== 'tertiary' ? `calc(${paddingY}px - 1px)` : undefined,
				},
				'&[data-active=true]': {
					backgroundColor: theme.other.getColor(activeFillColor),
					color: theme.other.getColor(activeLabelColor),
					boxShadow: highlightOnActive,
					paddingTop:
						variant !== 'tertiary' ? `calc(${paddingY}px + 1px)` : undefined,
					paddingBottom:
						variant !== 'tertiary' ? `calc(${paddingY}px - 1px)` : undefined,
					'&:hover': {
						backgroundColor: theme.other.getColor(hoverFillColor),
						color: theme.other.getColor(hoverLabelColor),
						boxShadow: highlightOnHover,
						paddingTop: `${paddingY}px`,
						paddingBottom: `${paddingY}px`,
					},
				},
				'&:focus': {
					outline: `solid ${theme.other.getColor(
						'border/emphasis/default'
					)} 2px`,
					outlineOffset: rem(theme.other.space[0.25]),
				},
				'&:disabled': {
					backgroundColor: theme.other.getColor(disabledFillColor),
					color: theme.other.getColor(disabledTextColor),
					cursor: 'not-allowed',
					pointerEvents: 'none',
				},
			},
			icon: {
				color: disabled
					? theme.other.getColor(disabledTextColor)
					: theme.other.getColor(iconColor),
			},
			shine: {
				position: 'absolute',
				width: '100%',
				height: '100%',
				top: 0,
				left: 0,

				background:
					variant === 'primary'
						? 'linear-gradient(180deg, rgba(255, 255, 255, 0.04) 87%, rgba(255, 255, 255, 0.16) 100%)'
						: undefined,
			},
			inner: {
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				gap: theme.other.space[0.5],
				overflow: 'visible',
				opacity: loading ? 0 : 1,

				height: '100%',
				paddingLeft: hasLeftIcon ? `${rem(paddingX - 4)}` : `${rem(paddingX)}`,
				paddingRight: hasRightIcon
					? `${rem(paddingX - 4)}`
					: `${rem(paddingX)}`,

				color: disabled ? theme.other.getColor(disabledTextColor) : 'inherit',
			},
			loader: {
				position: 'absolute',
				left: '50%',
				top: `calc(50% - ${theme.other.space[2]}px)`,
				height: '100%',
				transform: 'translateX(-50%)',
			},
			label: {
				whiteSpace: 'nowrap',
				height: '100%',
				overflow: 'hidden',
				display: 'flex',
				alignItems: 'center',
			},

			// ANIMATIONS FOR ICONS
			iconAnimation: {
				'&.spin': {
					animation: 'spin 2s linear infinite',
				},
				'&.spinCounterClockwise': {
					animation: 'spinCounterClockwise 2s linear infinite',
				},
				'&.rotate90': {
					transform: 'rotate(90deg)',
				},
				'&.rotate180': {
					transform: 'rotate(180deg)',
				},
				'&.rotateCounterClockwise90': {
					transform: 'rotate(-90deg)',
				},
				'&.rotateCounterClockwise180': {
					transform: 'rotate(-180deg)',
				},
				'@keyframes spin': {
					from: {
						transform: 'rotate(0deg)',
					},
					to: {
						transform: 'rotate(360deg)',
					},
				},
				'@keyframes spinCounterClockwise': {
					from: {
						transform: 'rotate(0deg)',
					},
					to: {
						transform: 'rotate(-360deg)',
					},
				},
			},
			leftIconClasses: {
				// The classes will be applied when the animation prop exists
				'&.spin': {
					animation: 'spin 2s linear infinite',
				},
				'&.spinCounterClockwise': {
					animation: 'spinCounterClockwise 2s linear infinite',
				},
				'&.rotate90': {
					transform: 'rotate(90deg)',
				},
				'&.rotate180': {
					transform: 'rotate(180deg)',
				},
				'&.rotateCounterClockwise90': {
					transform: 'rotate(-90deg)',
				},
				'&.rotateCounterClockwise180': {
					transform: 'rotate(-180deg)',
				},
			},
			rightIconClasses: {
				// The classes will be applied when the animation prop exists
				'&.spin': {
					animation: 'spin 2s linear infinite',
				},
				'&.spinCounterClockwise': {
					animation: 'spinCounterClockwise 2s linear infinite',
				},
				'&.rotate90': {
					transform: 'rotate(90deg)',
				},
				'&.rotate180': {
					transform: 'rotate(180deg)',
				},
				'&.rotateCounterClockwise90': {
					transform: 'rotate(-90deg)',
				},
				'&.rotateCounterClockwise180': {
					transform: 'rotate(-180deg)',
				},
			},
		};
	}
);

const Button = createPolymorphicComponent<'button', ButtonProps>(
	memo(
		forwardRef<HTMLButtonElement, ButtonProps>(
			(
				{
					id,
					children,
					onClick,
					variant = 'default',
					tone = 'default',
					leftIconName,
					leftIconAnimation,
					iconColor,
					leftIconFilled,
					rightIconName,
					rightIconAnimation,
					size = 'md',
					loading = false,
					disabled = false,
					highlight = false,
					className,
					classNames,
					styles,
					...other
				},
				ref
			) => {
				const { classes, cx, theme } = useStyles(
					{
						size,
						loading,
						disabled,
						variant,
						tone,
						iconColor,
						hasLeftIcon: !!leftIconName,
						hasRightIcon: !!rightIconName,
					},
					{
						name: 'Button',
						classNames,
						styles,
					}
				);

				const handleClick = useCallback(
					(event: React.MouseEvent<HTMLButtonElement>) => {
						if (onClick) {
							onClick(event);
						}
					},
					[onClick]
				);

				return (
					<UnstyledButton
						id={id}
						className={cx(classes.container, className)}
						disabled={disabled || loading}
						data-active={highlight || undefined}
						onClick={handleClick}
						ref={ref}
						{...other}
					>
						{variant === 'primary' ? (
							<div className={classes.shine} role="none" />
						) : null}
						{loading === true && (
							<span className={classes.loader}>
								<Loader
									size="xs"
									color={theme.other.getColor(
										variant === 'primary'
											? 'text/brand-on-fill/disabled'
											: 'icon/primary/disabled'
									)}
								/>
							</span>
						)}
						<span className={classes.inner}>
							{leftIconName && (
								<Icon
									className={cx(
										classes.icon,
										classes.leftIconClasses,
										leftIconAnimation
									)}
									name={leftIconName}
									color={leftIconFilled ? 'fill/primary/default' : iconColor}
									fillColor={leftIconFilled ? iconColor : undefined}
								/>
							)}

							<span className={classes.label}>{children}</span>
							{rightIconName && (
								<Icon
									name={rightIconName}
									className={cx(
										classes.icon,
										classes.rightIconClasses,
										rightIconAnimation
									)}
								/>
							)}
						</span>
					</UnstyledButton>
				);
			}
		)
	)
);

Button.displayName = 'Button';

export default Button;
