import type { INotification } from '../../api/types/models/notification';
import { hashCode } from '../../utils/utils';

interface INotificationTitleProps {
	notification: INotification;
}

function BoldText({ text }: { text: string }) {
	return <strong>{text}</strong>;
}

export function NotificationTitle({ notification }: INotificationTitleProps) {
	const { sender } = notification.title;

	// Return nothing if no notification
	if (!notification) {
		return null;
	}

	const formattedSubjectArray = [];

	// Find the position of the sender in the subject
	const senderIndex = notification?.subject
		?.toLowerCase()
		.indexOf(sender?.toLowerCase());

	// Return the non-bold subject if no sender is found
	if (!sender || senderIndex === -1) {
		// eslint-disable-next-line react/jsx-no-useless-fragment
		return <>{notification?.subject}</>;
	}

	// Push everything before the sender to the array
	if (senderIndex !== 0) {
		formattedSubjectArray.push({
			context: notification?.subject?.slice(0, senderIndex),
			bold: false,
		});
	}

	// Push the sender to the array
	formattedSubjectArray.push({
		context: sender,
		bold: true,
	});

	// Send the rest of the subject to the array
	if (senderIndex !== notification?.subject?.length - 1) {
		formattedSubjectArray.push({
			context: notification?.subject?.slice(senderIndex + sender?.length),
			bold: false,
		});
	}

	// Now render the subject
	return (
		<>
			{formattedSubjectArray.map((subject) => {
				if (subject.bold) {
					return (
						<BoldText key={hashCode(subject.context)} text={subject.context} />
					);
				}
				return <span key={hashCode(subject.context)}>{subject.context}</span>;
			})}
		</>
	);
}
