import { AutomationActionType } from '../../../../api';
import { AUTOMATION_ACTION_TYPE_OPTIONS } from './Edit/constants';

export const getActionTypeDisplayValue = (
	actionType: AutomationActionType
): string => {
	const label = AUTOMATION_ACTION_TYPE_OPTIONS.find(
		(option) => option.value === actionType
	)?.label;

	if (!label) {
		throw new Error(`Display value not found for: ${actionType}`);
	}

	return label;
};

export const isActionTypeToClear = (
	actionType: AutomationActionType
): boolean => {
	if (
		[
			AutomationActionType.COLLECTIONS_CLEAR,
			AutomationActionType.DEFINITION_CLEAR,
			AutomationActionType.TAGS_CLEAR,
			AutomationActionType.OWNERS_CLEAR,
			AutomationActionType.TEAMS_CLEAR,
			AutomationActionType.TEAMS_ADD_ALL,
			AutomationActionType.DESCRIPTION_CLEAR,
			AutomationActionType.SUBSCRIBERS_CLEAR,
		].includes(actionType)
	) {
		return true;
	}

	return false;
};

export const shouldExcludeValuePromptForActionType = (
	actionType: AutomationActionType
): boolean => {
	// No need for a value when clearing fields
	if (isActionTypeToClear(actionType)) {
		return true;
	}

	// No need for a value when setting PII or Verified
	if (
		[
			AutomationActionType.PII_TRUE,
			AutomationActionType.PII_FALSE,
			AutomationActionType.VERIFIED_TRUE,
			AutomationActionType.VERIFIED_FALSE,
			AutomationActionType.PUBLISHED_TRUE,
			AutomationActionType.PUBLISHED_FALSE,
		].includes(actionType)
	) {
		return true;
	}

	return false;
};
