interface PriorityIconProps {
	color?: string;
}

export function PriorityHighIcon({ color }: PriorityIconProps) {
	return (
		<svg fill={color} width="16" height="16" viewBox="0 0 16 16">
			<rect x="1" y="8" width="3" height="6" rx="1" />
			<rect x="6" y="5" width="3" height="9" rx="1" />
			<rect x="11" y="2" width="3" height="12" rx="1" />
		</svg>
	);
}

export function PriorityMediumIcon({ color }: PriorityIconProps) {
	return (
		<svg fill={color} width="16" height="16" viewBox="0 0 16 16">
			<rect x="1" y="8" width="3" height="6" rx="1" />
			<rect x="6" y="5" width="3" height="9" rx="1" />
			<rect x="11" y="2" width="3" height="12" rx="1" fillOpacity="0.3" />
		</svg>
	);
}

export function PriorityLowIcon({ color }: PriorityIconProps) {
	return (
		<svg fill={color} width="16" height="16" viewBox="0 0 16 16">
			<rect x="1" y="8" width="3" height="6" rx="1" />
			<rect x="6" y="5" width="3" height="9" rx="1" fillOpacity="0.3" />
			<rect x="11" y="2" width="3" height="12" rx="1" fillOpacity="0.3" />
		</svg>
	);
}

export function PriorityNoneIcon({ color }: PriorityIconProps) {
	return (
		<svg fill={color} width="16" height="16" viewBox="0 0 14 14">
			<rect width="2" height="2" rx="1" transform="matrix(-1 0 0 1 5 6)" />
			<rect width="2" height="2" rx="1" transform="matrix(-1 0 0 1 8 6)" />
			<rect width="2" height="2" rx="1" transform="matrix(-1 0 0 1 11 6)" />
		</svg>
	);
}
