import { Button, Title } from '@repo/foundations';
import { isNil } from 'lodash-es';
import {
	NavBarBreadcrumbWrapper,
	NavBarRightSideWrapper,
	NavBarWrapper,
} from '.';
import { useAuthUser } from '../../../api';
import { useHomepageTeam } from '../../../pages/HomePage/hooks/useHomepageTeam';
import { appShellStore } from '../store';

function TeamHomePageNavBar() {
	const { team } = useHomepageTeam();

	const {
		teamHomePageDrawerHandler: { open: openDrawer },
		teamHomePageSettingsModalHandler: { open: openSettingsModal },
	} = appShellStore;

	const { isAdminUser } = useAuthUser();
	const { isTeamViewerUser } = useHomepageTeam();

	if (isNil(team)) {
		return null;
	}

	return (
		<NavBarWrapper>
			<NavBarBreadcrumbWrapper>
				<Title order={1} size="sm" data-testid="team-home-page-nav-bar-title">
					{`${team.name} Home`}
				</Title>
			</NavBarBreadcrumbWrapper>
			<NavBarRightSideWrapper>
				{isAdminUser && (
					<Button
						onClick={openSettingsModal}
						data-testid="team-homepage-settings-button"
					>
						Settings
					</Button>
				)}
				{!isTeamViewerUser && <Button onClick={openDrawer}>Customize</Button>}
			</NavBarRightSideWrapper>
		</NavBarWrapper>
	);
}

export default TeamHomePageNavBar;
