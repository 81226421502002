import { createStyles } from '@mantine/core';
import { Button, Text, WithConditionalTooltip } from '@repo/foundations';
import { observer } from 'mobx-react-lite';
import { sanitizePromptMessage } from '../../AIAssistant/utils';
import { entityModalStore } from '../store';

const useStyles = createStyles((theme) => ({
	text: {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		fontSize: theme.fontSizes.sm,
		width: '152px',
	},
}));

export const AILinkSelector = observer(() => {
	const { classes } = useStyles();

	const handleClick = () => {
		entityModalStore.setWithLinkToAI(true);
	};

	const handleClear = (e: React.MouseEvent) => {
		e.stopPropagation();
		entityModalStore.setWithLinkToAI(false);
	};

	const isSelected = !!entityModalStore.withLinkToAI;

	if (!entityModalStore.metadata?.ai_prompt) {
		return null;
	}

	const sanitizedPromptMessage = sanitizePromptMessage(
		entityModalStore?.metadata?.ai_prompt?.[
			'prompt' as keyof typeof entityModalStore.metadata.ai_prompt
		]
	);

	return (
		<WithConditionalTooltip
			tooltipLabel={
				sanitizedPromptMessage.length > 22 && isSelected
					? sanitizedPromptMessage
					: undefined
			}
			position="top"
			multiline
			width={400}
		>
			<Button
				leftIconName="link"
				variant="tertiary"
				rightIconName={isSelected ? 'x' : undefined}
				onClick={isSelected ? handleClear : handleClick}
			>
				{isSelected ? (
					<Text className={classes.text}>{sanitizedPromptMessage}</Text>
				) : (
					<Text size="sm" color="text/secondary/default">
						Link Secoda AI chat
					</Text>
				)}
			</Button>
		</WithConditionalTooltip>
	);
});

export default AILinkSelector;
