import { Divider, Group, Modal, Stack, TextInput } from '@mantine/core';
import { Button } from '@repo/foundations';
import type { ChangeEvent } from 'react';
import { useCallback, useState } from 'react';
import type { IMetric } from '../../../../../../api/types';

interface QueryBlockRenameModalProps {
	metric: IMetric;
	onChange: (metric: Partial<IMetric>) => Promise<void>;
	onClose: () => void;
}

export function QueryBlockRenameModal({
	metric,
	onChange,
	onClose,
}: QueryBlockRenameModalProps) {
	const [value, setValue] = useState(metric.title);

	const handleChangeTitle = useCallback((e: ChangeEvent<HTMLInputElement>) => {
		setValue(e.currentTarget.value);
	}, []);

	const handleSubmit = useCallback(async () => {
		await onChange({ title: value });
		onClose();
	}, [onChange, onClose, value]);

	return (
		<Modal opened onClose={onClose} title="Rename query">
			<Stack spacing="md">
				<TextInput
					autoFocus
					placeholder="Query name"
					value={value}
					onChange={handleChangeTitle}
					label="Query name"
					data-autofocus
				/>
				<Divider />
				<Group position="right" spacing="md">
					<Button variant="default" onClick={onClose}>
						Cancel
					</Button>
					<Button variant="primary" onClick={handleSubmit}>
						Save
					</Button>
				</Group>
			</Stack>
		</Modal>
	);
}
