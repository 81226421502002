import { Group, Tooltip } from '@mantine/core';
import { Icon } from '@repo/foundations';
import type { EditorDictionary } from '@repo/secoda-editor';
import type { EditorView } from 'prosemirror-view';
import type React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import FloatingToolbar from './FloatingToolbar';
import Input from './Input';
import ToolbarButton from './ToolbarButton';
import Separator from './ToolbarSeparator';

interface PlaceholderToolbarProps {
	isActive: boolean;
	view: EditorView;
	dictionary: EditorDictionary;
	onClose: () => void;
}

export function PlaceholderToolbar({
	dictionary,
	view,
	onClose,
	isActive,
}: PlaceholderToolbarProps) {
	const menuRef = useRef<HTMLDivElement>(null);
	const [value, setValue] = useState('');

	const close = useCallback(() => {
		onClose();
		view.focus();
		setValue('');
	}, [onClose, view]);

	useEffect(() => {
		function handleClickOutside(ev: MouseEvent) {
			if (menuRef.current && !menuRef.current.contains(ev.target as Node)) {
				close();
			}
		}

		window.addEventListener('mousedown', handleClickOutside);

		return () => {
			window.removeEventListener('mousedown', handleClickOutside);
		};
	}, [close, view]);

	const handleKeyDown = (event: React.KeyboardEvent): void => {
		// eslint-disable-next-line default-case
		switch (event.key) {
			case 'Enter': {
				event.preventDefault();

				view.dispatch(
					view.state.tr
						.insertText(
							value,
							view.state.selection.from,
							view.state.selection.to
						)
						.addMark(
							view.state.selection.from,
							view.state.selection.to + value.length,
							view.state.schema.marks.placeholder.create()
						)
				);

				close();
				break;
			}

			case 'Escape': {
				event.preventDefault();
				close();
				break;
			}
		}
	};

	if (!isActive) {
		return null;
	}

	return (
		<FloatingToolbar ref={menuRef} active={isActive} view={view}>
			<Group spacing="3xs">
				<Input
					value={value}
					placeholder={dictionary.placeholderInput}
					onKeyDown={handleKeyDown}
					onChange={(ev) => setValue(ev.currentTarget.value)}
					autoFocus
				/>
				<Separator />
				<Tooltip label={dictionary.close} position="top">
					<ToolbarButton onClick={close}>
						<Icon name="trash" />
					</ToolbarButton>
				</Tooltip>
			</Group>
		</FloatingToolbar>
	);
}
