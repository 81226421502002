import {
	ActionIcon,
	createStyles,
	Divider,
	Group,
	Popover,
	Select,
	Stack,
} from '@mantine/core';
import { Button, Icon, Switch, Text, Title } from '@repo/foundations';
import type { JobStatus } from '../../../api/types/models/internalIntegrationStatus';
import { DAYS_LOOKBACK } from './constants';

interface IIIntegrationStatusPageFiltersProps {
	lastNumDays: number;
	setLastNumDays: (num: number) => void;
	hideSuccessfulJobs: boolean;
	setHideSuccessfulJobs: (hide: boolean) => void;
	showRuntimeChart: boolean;
	setShowRuntimeChart: (show: boolean) => void;
	jobStatus: JobStatus;
	setJobStatus: (status: JobStatus) => void;
	runOnAWSBatchOnly: boolean;
	setRunOnAWSBatchOnly: (run: boolean) => void;
	groupByWorkspace: boolean;
	setGroupByWorkspace: (group: boolean) => void;
}

const useStyles = createStyles((theme) => ({
	selectInput: {
		width: theme.other.space[40],
	},
	clearButtonWrapper: {
		flexDirection: 'row-reverse',
	},
}));

export function IntegrationStatusPageFilters({
	lastNumDays,
	setLastNumDays,
	hideSuccessfulJobs,
	setHideSuccessfulJobs,
	showRuntimeChart,
	setShowRuntimeChart,
	jobStatus,
	setJobStatus,
	runOnAWSBatchOnly,
	setRunOnAWSBatchOnly,
	groupByWorkspace,
	setGroupByWorkspace,
}: IIIntegrationStatusPageFiltersProps) {
	const { classes, theme } = useStyles();
	return (
		<Popover
			width={theme.other.width.sm}
			position="bottom-end"
			shadow="md"
			withinPortal
		>
			<Popover.Target>
				<ActionIcon variant="outline">
					<Icon name="adjustmentsHorizontal" />
				</ActionIcon>
			</Popover.Target>
			<Popover.Dropdown>
				<Stack>
					<Group position="apart" noWrap>
						<Stack spacing={0}>
							<Title size="sm">Job status</Title>
							<Text size="xs">Filter by job status</Text>
						</Stack>
						<Select
							classNames={{ input: classes.selectInput }}
							value={jobStatus}
							onChange={setJobStatus}
							data={[
								{ label: 'All', value: 'all' },
								{ label: 'Dangling', value: 'dangling' },
								{ label: 'Failed', value: 'failed' },
								{ label: 'Reserved', value: 'reserved' },
								{ label: 'Running', value: 'running' },
								{ label: 'Succeeded', value: 'succeeded' },
								{ label: 'Terminated', value: 'terminated' },
								{ label: 'Actively failing', value: 'activeFail' },
								{
									label: 'Failing new error',
									value: 'failingWithNewError',
								},
								{ label: 'Recently resolved', value: 'recentlyResolved' },
								{ label: 'Ticket created', value: 'ticketCreated' },
								{ label: 'In trial', value: 'inTrial' },
							]}
						/>
					</Group>
					<Divider />
					<Group position="apart" noWrap>
						<Stack spacing={0}>
							<Title size="sm">Number of days</Title>
							<Text size="xs">{`Maximum of ${DAYS_LOOKBACK} days`}</Text>
						</Stack>
						<Group spacing="sm">
							<ActionIcon
								variant="outline"
								disabled={lastNumDays === 1}
								onClick={() => setLastNumDays(lastNumDays - 1)}
							>
								<Icon name="minus" />
							</ActionIcon>
							<Text>{lastNumDays}</Text>
							<ActionIcon
								variant="outline"
								disabled={lastNumDays === DAYS_LOOKBACK}
								onClick={() => setLastNumDays(lastNumDays + 1)}
							>
								<Icon name="plus" />
							</ActionIcon>
						</Group>
					</Group>
					<Divider />
					<Group position="apart" noWrap>
						<Stack spacing={0}>
							<Title size="sm">Hide successful jobs</Title>
							<Text size="xs">Pinpoint issues easily</Text>
						</Stack>
						<Switch
							checked={hideSuccessfulJobs}
							onChange={(event) =>
								setHideSuccessfulJobs(event.currentTarget.checked)
							}
						/>
					</Group>
					<Divider />
					<Group position="apart" noWrap>
						<Stack spacing={0}>
							<Title size="sm">AWS Batch only</Title>
							<Text size="xs">Only show jobs run on AWS Batch</Text>
						</Stack>
						<Switch
							checked={runOnAWSBatchOnly}
							onChange={(event) =>
								setRunOnAWSBatchOnly(event.currentTarget.checked)
							}
						/>
					</Group>
					<Divider />
					<Group position="apart" noWrap>
						<Stack spacing={0}>
							<Title size="sm">Show runtime chart</Title>
							<Text size="xs">Observe extraction times</Text>
						</Stack>
						<Switch
							checked={showRuntimeChart}
							onChange={(event) =>
								setShowRuntimeChart(event.currentTarget.checked)
							}
						/>
					</Group>
					<Divider />
					<Group position="apart" noWrap>
						<Stack spacing={0}>
							<Title size="sm">Group by workspace</Title>
							<Text size="xs">Sort the integrations by workspace</Text>
						</Stack>
						<Switch
							checked={groupByWorkspace}
							onChange={(event) =>
								setGroupByWorkspace(event.currentTarget.checked)
							}
						/>
					</Group>
					<Divider />
					<Group className={classes.clearButtonWrapper}>
						<Button
							variant="tertiary"
							onClick={() => {
								setJobStatus('all');
								setLastNumDays(DAYS_LOOKBACK);
								setHideSuccessfulJobs(false);
								setShowRuntimeChart(false);
								setRunOnAWSBatchOnly(false);
								setGroupByWorkspace(false);
							}}
						>
							Clear filters
						</Button>
					</Group>
				</Stack>
			</Popover.Dropdown>
		</Popover>
	);
}
