import { useForm } from '@mantine/form';
import { randomId } from '@mantine/hooks';
import type { IMarketplaceIntegrationSpecVersion } from '@repo/common/models/marketplace';
import { Text } from '@repo/foundations';
import { map } from 'lodash-es';

export type MarketplaceIntegrationSpecVersionFormValues = {
	icon_url: string | null;
	name: string;
	description: string;
	category: string;
	allowed_endpoints: { value: string; key: string }[];
	code_file: File | null | 'existing';
	form_fields: {
		name: string;
		placeholder?: string;
		sensitive: boolean;
		required: boolean;
		key: string;
	}[];
};

export const validateAllowedEndpoint = (endpoint: string): boolean => {
	try {
		const parsedUrl = new URL(endpoint);
		if (parsedUrl.search !== '') {
			return false;
		}

		if (!parsedUrl.protocol.startsWith('http')) {
			return false;
		}

		const hostParts = parsedUrl.host.split('.');
		const pathParts = parsedUrl.pathname.split('/');

		return [...hostParts, ...pathParts].every((p) => {
			if (p === '*') return true;
			if (p.startsWith('{')) {
				return p.match(/^\{([a-zA-Z0-9_]+)\}$/);
			}
			return true;
		});
	} catch (e) {
		return false;
	}
};

export const useMarketplaceIntegrationSpecVersionForm = (
	version?: IMarketplaceIntegrationSpecVersion,
	isUpdate = false
) =>
	useForm<MarketplaceIntegrationSpecVersionFormValues>({
		initialValues: {
			icon_url: version?.icon_url || null,
			name: version?.name || '',
			description: version?.description || '',
			category: version?.category || 'DATA_WAREHOUSES',
			allowed_endpoints: version
				? (version?.allowed_endpoints).map((e) => ({
						key: randomId(),
						value: e,
					}))
				: [
						{
							key: randomId(),
							value: '',
						},
					],
			code_file: version && isUpdate ? 'existing' : null,
			form_fields: version
				? map(version?.form_spec, (field, fieldName) => ({
						name: fieldName,
						required: field.required,
						placeholder: field.placeholder,
						sensitive: field.type === 'password',
						key: randomId(),
					}))
				: [
						{
							name: '',
							placeholder: '',
							sensitive: false,
							required: true,
							key: randomId(),
						},
					],
		},
		validateInputOnChange: true,
		validate: {
			icon_url: (value) =>
				!value ? (
					<Text size="xs" color="text/critical/default">
						Icon is required
					</Text>
				) : null,
			name: (value) => (!value.length ? 'Name is required' : null),
			allowed_endpoints: {
				value: (value) =>
					validateAllowedEndpoint(value) ? null : 'Invalid endpoint',
			},
			code_file: (value) =>
				!value ? (
					<Text size="xs" color="text/critical/default">
						Code file is required
					</Text>
				) : null,
			form_fields: {
				name: (value, values, path) => {
					if (!value) {
						return 'Name is required';
					}

					if (
						values.form_fields.filter((field) => field.name === value).length >
						1
					) {
						return 'Name must be unique';
					}

					return null;
				},
			},
		},
	});
