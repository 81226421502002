import { createStyles } from '@mantine/core';

export const useCommentStyles = createStyles((theme) => ({
	commentAvatar: {
		paddingTop: theme.other.space[2] + theme.other.space[0.5],
		maxWidth: theme.other.iconSize.md + theme.other.space[2] * 2,
		display: 'flex',
	},
	userAvatar: {
		marginTop: -3,
	},
	commentContent: {
		paddingLeft: 0,
		paddingX: theme.other.space[2],
	},
}));
