import { createStyles } from '@mantine/core';

const useStyles = createStyles(() => ({
	span: {
		position: 'absolute',
		height: 1,
		width: 1,
		overflow: 'hidden',
		clip: 'rect(1px, 1px, 1px, 1px)',
	},
}));

export function VisuallyHidden({ children }: { children: React.ReactNode }) {
	const { classes } = useStyles();
	return <span className={classes.span}>{children}</span>;
}
