import { Text } from '@repo/foundations';
import type { SecodaEntity } from '../../lib/models';
import { entityUrl } from '../../utils/navigationUtils';
import { ResourceLink } from '../Link';
import type { DataQualityBreakdownSubItem } from './types';
import { getTestsAndMonitorsNoun } from './utils';

interface DataQualitySuggestionDescriptionProps {
	item: DataQualityBreakdownSubItem;
}

export function DataQualitySuggestionDescription({
	item,
}: DataQualitySuggestionDescriptionProps) {
	const url = entityUrl(item.entity as SecodaEntity);

	if (item.data_quality_key === 'ownership') {
		return (
			<Text size="sm">
				Improve stewardship by adding an owner to <ResourceLink href={url} />
			</Text>
		);
	}

	if (item.data_quality_key === 'tagged') {
		return (
			<Text size="sm">
				Improve stewardship by adding a tag to <ResourceLink href={url} />
			</Text>
		);
	}

	if (item.data_quality_key === 'questions') {
		return (
			<Text size="sm">
				Improve stewardship by answering questions for{' '}
				<ResourceLink href={url} />
			</Text>
		);
	}

	if (item.data_quality_key === 'desc') {
		return (
			<Text size="sm">
				Improve usability by documenting table <ResourceLink href={url} />
			</Text>
		);
	}

	if (item.data_quality_key === 'column_desc') {
		return (
			<Text size="sm">
				Improve usability by documenting column <ResourceLink href={url} />
			</Text>
		);
	}

	if (item.data_quality_key === 'schema_desc') {
		return (
			<Text size="sm">
				Improve usability by documenting schema <ResourceLink href={url} />
			</Text>
		);
	}

	if (item.data_quality_key === 'sla_set') {
		return (
			<Text size="sm">
				Improve reliability by creating a freshness monitor for{' '}
				<ResourceLink href={url} />
			</Text>
		);
	}

	if (item.data_quality_key === 'sla_achieved') {
		return (
			<Text size="sm">
				Improve reliability by having your freshness{' '}
				{getTestsAndMonitorsNoun(item)} status as Normal for{' '}
				<ResourceLink href={url} />
			</Text>
		);
	}

	if (item.data_quality_key === 'nullness_test') {
		return (
			<Text size="sm">
				Improve accuracy by creating a nullness monitor for{' '}
				<ResourceLink href={url} />
			</Text>
		);
	}

	if (item.data_quality_key === 'nullness_passing') {
		return (
			<Text size="sm">
				Improve accuracy by having your nullness {getTestsAndMonitorsNoun(item)}{' '}
				status as Normal for <ResourceLink href={url} />
			</Text>
		);
	}

	if (item.data_quality_key === 'uniqueness_test') {
		return (
			<Text size="sm">
				Improve accuracy by creating a uniqueness monitor for{' '}
				<ResourceLink href={url} />
			</Text>
		);
	}

	if (item.data_quality_key === 'uniqueness_passing') {
		return (
			<Text size="sm">
				Improve accuracy by having your uniqueness{' '}
				{getTestsAndMonitorsNoun(item)} status as Normal for{' '}
				<ResourceLink href={url} />
			</Text>
		);
	}

	return null;
}
