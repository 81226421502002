/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
import type { HocuspocusProvider } from '@hocuspocus/provider';
import { WebSocketStatus } from '@hocuspocus/provider';
import { Box, Skeleton } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { Text } from '@repo/foundations';
import { useDebounceFn } from 'ahooks';
import { noop } from 'lodash-es';
import type { LegacyRef } from 'react';
import {
	forwardRef,
	useEffect,
	useLayoutEffect,
	useMemo,
	useState,
} from 'react';
import * as Y from 'yjs';
import type {
	IProseMirrorEditorProps as EditorProps,
	RichMarkdownEditor,
} from '..';
import { RichMarkdownEditor as Editor } from '..';
import { useAuthUser } from '../../../../../api';
import useIdle from '../hooks/useIdle';
import useIsMounted from '../hooks/useIsMounted';
import usePageVisibility from '../hooks/usePageVisibility';
import MultiplayerExtension from '../lib/MultiplayerExtension';
import { multiplayerStore } from './MultiplayerEditor.store';

/**
 * Props for the MultiplayerEditor component.
 */
type IMultiplayerEditorProps = EditorProps & {
	readOnly?: boolean;
	id: string;
	version: number;
	multiplayerUserHistory: string[];
	proseMirrorRef?: LegacyRef<RichMarkdownEditor>;
};

/**
 * MultiplayerEditor component that provides real-time collaborative editing functionality.
 * @param props - The props for the MultiplayerEditor component.
 * @param ref - A ref object for the MultiplayerEditor component.
 */
function MultiplayerEditor(
	{
		readOnly,
		id,
		version,
		multiplayerUserHistory,
		proseMirrorRef = null,
		...props
	}: IMultiplayerEditorProps,
	ref: LegacyRef<RichMarkdownEditor>
) {
	if (proseMirrorRef) {
		ref = proseMirrorRef;
	}
	const { user: currentUser, color, displayName } = useAuthUser();
	const [remoteProvider, setRemoteProvider] =
		useState<HocuspocusProvider | null>(null);

	const [isRemoteSynced] = useState(false);

	const [ydoc] = useState(() => new Y.Doc());
	const isIdle = useIdle();
	const isVisible = usePageVisibility();
	const isMounted = useIsMounted();

	const { run: disconnectNotification } = useDebounceFn(
		(message?: string) => {
			showNotification({
				title: message ?? 'You have been disconnected',
				message: (
					<Box>
						Changes are not being persisted. Please copy your changes to the
						clipboard and refresh the page.
						<Text color="text/emphasis/hover">
							<a href={window.location.href}>Refresh page</a>
						</Text>
					</Box>
				),
				color: 'red',
				autoClose: false,
			});
		},
		{
			wait: 5000,
		}
	);

	// This is an important safeguard to prevent a remoteProvider being
	// reused across memoized components. We check to make sure the url
	// contains the documentId being worked on.
	if (remoteProvider?.url && !remoteProvider?.url?.includes(id)) {
		remoteProvider.disconnect();
		remoteProvider.disconnectBroadcastChannel();
		remoteProvider.destroy();
		setRemoteProvider(null);
	}

	const collaborateId = `collaborate.${version}.${id}`;

	// Create a memoized user object.
	const user = useMemo(() => {
		const value = {
			id: currentUser.id,
			name: displayName,
			color,
			document_name: collaborateId,
		};

		remoteProvider?.setAwarenessField('user', value);

		return value;
	}, [remoteProvider, currentUser.id, color, displayName]);

	// Provider initialization must be within useLayoutEffect rather than useState
	// or useMemo as both of these are ran twice in React StrictMode resulting in
	// an orphaned websocket connection.
	// see: https://github.com/facebook/react/issues/20090#issuecomment-715926549
	useLayoutEffect(() => {
		if (!id) {
			return;
		}

		multiplayerStore.setMultiplayerUserHistory(multiplayerUserHistory);
		multiplayerStore.setEntityId(id);
	}, [id, ydoc, currentUser.id, isMounted]);

	// Create memoized extensions for the editor.
	const extensions = useMemo(() => {
		if (!id || !remoteProvider) {
			return props.extensions;
		}

		return [
			...(props.extensions || []),
			new MultiplayerExtension({
				user,
				provider: remoteProvider,
				document: ydoc,
			}),
		];
	}, [id, remoteProvider, user, ydoc, props.extensions]);

	// Disconnect the realtime connection while idle. `isIdle` also checks for
	// page visibility and will immediately disconnect when a tab is hidden.
	useEffect(() => {
		if (!remoteProvider) {
			return;
		}

		if (
			isIdle &&
			!isVisible &&
			remoteProvider.status === WebSocketStatus.Connected
		) {
			remoteProvider.disconnect();
		}

		if (
			(!isIdle || isVisible) &&
			remoteProvider.status === WebSocketStatus.Disconnected
		) {
			remoteProvider.connect();
			return () => {
				remoteProvider.disconnect();
				remoteProvider.disconnectBroadcastChannel();
				remoteProvider.destroy();
			};
		}
	}, [id, remoteProvider, isIdle, isVisible]);

	// Certain emoji combinations trigger this error in YJS, while waiting for a fix
	// we must prevent the user from continuing to edit as their changes will not
	// be persisted. See: https://github.com/yjs/yjs/issues/303
	// Show an error message if certain emoji combinations trigger a URIError.
	useEffect(() => {
		function onUnhandledError(event: ErrorEvent) {
			if (event.message.includes('URIError: URI malformed')) {
				disconnectNotification('URIError');
			}
		}

		window.addEventListener('error', onUnhandledError);
		return () => window.removeEventListener('error', onUnhandledError);
	}, [id]);

	// Render a skeleton loading screen while the document is loading or disconnected.
	if (!remoteProvider) {
		return <Skeleton height={768} mt={12} radius="sm" />;
	}

	// While the collaborative document is loading, we render a version of the
	// document from the last text cache in read-only mode if we have it.
	const showCache =
		remoteProvider.status === WebSocketStatus.Connecting ||
		remoteProvider.status === WebSocketStatus.Disconnected ||
		!isRemoteSynced;

	return (
		<Box pos="relative">
			{showCache && (
				<Box>
					<Editor
						{...props}
						dataTestId="rich-text-editor-cache"
						value={undefined}
						defaultValue={undefined}
						extensions={extensions}
						onShowToast={noop}
						readOnly
						ref={ref}
					/>
				</Box>
			)}
			<Editor
				{...props}
				dataTestId="rich-text-editor"
				readOnly={readOnly}
				value={undefined}
				defaultValue={undefined}
				extensions={extensions}
				ref={showCache ? undefined : ref}
				style={
					showCache
						? {
								opacity: 0,
								pointerEvents: 'none',
							}
						: undefined
				}
			/>
		</Box>
	);
}

// @ts-expect-error TS(2304): Cannot find name 'Props'.
export default forwardRef<typeof MultiplayerEditor, Props>(MultiplayerEditor);
