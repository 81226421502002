import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { ISlackChannel } from '../../../lib/models';
import { Slack } from '../../../lib/models';
import { createQueryKeys } from '../../factories';

interface IUseSlackArgs {
	id?: string;
	options?: UseQueryOptions<ISlackChannel[]>;
	refresh?: boolean;
}

const SLACK_NAMESPACE = ['this', 'slack'];
export const slackQueryKeyFactory = createQueryKeys(SLACK_NAMESPACE);

export const slackChannelsQuery = (refresh: boolean | undefined) => ({
	queryKey: slackQueryKeyFactory.list(1, { refresh }),
	queryFn: async () =>
		(await Slack.get_conversations(refresh)) as unknown as ISlackChannel[],
});

export function useSlackChannels({ options, refresh }: IUseSlackArgs = {}) {
	const query = slackChannelsQuery(refresh);
	return useQuery<ISlackChannel[]>(query.queryKey, query.queryFn, {
		suspense: false,
		...options,
	});
}
