import {
	baseQueryHooksFactory,
	createQueryKeys,
	prefetchFunctionsFactory,
} from '../../factories';
import type { IComment, IDocument } from '../../types';

export const DOCUMENTS_NAMESPACE = ['document'];

export const documentsQueryKeyFactory = createQueryKeys(DOCUMENTS_NAMESPACE);

const { prefetchDocument, prefetchDocumentList } = prefetchFunctionsFactory(
	'document',
	documentsQueryKeyFactory
);

const {
	useDocument,
	useDocumentInfiniteList,
	useDocumentList,
	useCreateDocument,
	useDeleteDocument,
	useUpdateDocument,
	fetchDocumentList,
	updateDocument,
	createDocument,
} = baseQueryHooksFactory<IDocument, 'document'>(
	'document',
	documentsQueryKeyFactory
);

export const COMMENTS_NAMESPACE = ['comment'];

export const commentsQueryKeyFactory = createQueryKeys(COMMENTS_NAMESPACE);

const {
	useComment,
	useCommentList,
	useCreateComment,
	useDeleteComment,
	useUpdateComment,
} = baseQueryHooksFactory<IComment, 'comment'>(
	'comment',
	commentsQueryKeyFactory
);

export {
	createDocument,
	fetchDocumentList,
	prefetchDocument,
	prefetchDocumentList,
	updateDocument,
	useComment,
	useCommentList,
	useCreateComment,
	useCreateDocument,
	useDeleteComment,
	useDeleteDocument,
	useDocument,
	useDocumentInfiniteList,
	useDocumentList,
	useUpdateComment,
	useUpdateDocument,
};
