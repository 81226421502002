import { Stack } from '@mantine/core';
import {
	useIncident,
	useMeasurementsChartData,
	useMonitor,
} from '../../../../api';
import type { INotification } from '../../../../api/types/models/notification';
import IncidentContent from '../../../IncidentPage/IncidentContent';
import { getMonitorMetricTypeInformation } from '../../../MonitorPage/utils';

interface IResourceMonitorIncidentContentProps {
	selected: INotification;
}

function ResourceMonitorIncidentContent({
	selected,
}: IResourceMonitorIncidentContentProps) {
	const { data: incident } = useIncident({
		id: selected.resource_id as string,
		options: {
			enabled: Boolean(selected.resource_id),
		},
	});

	const { data: monitor } = useMonitor({
		id: incident?.monitor as string,
		options: {
			enabled: Boolean(incident?.monitor),
		},
	});

	const { data: measurements } = useMeasurementsChartData({
		monitorId: incident?.monitor,
		incidentDate: incident?.created_at,
	});

	const { label: title } = getMonitorMetricTypeInformation(
		monitor?.metric_type
	);

	return (
		<Stack h="100vh" mb="xl">
			<IncidentContent
				incident={incident}
				measurements={measurements}
				title={title}
			/>
		</Stack>
	);
}

export default ResourceMonitorIncidentContent;
