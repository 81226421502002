import {
	baseQueryHooksFactory,
	createQueryKeys,
	prefetchFunctionsFactory,
} from '../../factories';
import type { IDictionaryTerm } from '../../types';

export const DICTIONARY_TERMS_NAMESPACE = ['dictionary', 'terms'];

export const dictionaryTermsQueryKeyFactory = createQueryKeys(
	DICTIONARY_TERMS_NAMESPACE
);

const { prefetchDictionaryTerm, prefetchDictionaryTermList } =
	prefetchFunctionsFactory('dictionaryTerm', dictionaryTermsQueryKeyFactory);

const {
	useDictionaryTerm,
	useDictionaryTermInfiniteList,
	useDictionaryTermList,
	useCreateDictionaryTerm,
	useDeleteDictionaryTerm,
	useUpdateDictionaryTerm,
	fetchDictionaryTermList,
	updateDictionaryTerm,
	createDictionaryTerm,
} = baseQueryHooksFactory<IDictionaryTerm, 'dictionaryTerm'>(
	'dictionaryTerm',
	dictionaryTermsQueryKeyFactory
);

export {
	createDictionaryTerm,
	fetchDictionaryTermList,
	prefetchDictionaryTerm,
	prefetchDictionaryTermList,
	updateDictionaryTerm,
	useCreateDictionaryTerm,
	useDeleteDictionaryTerm,
	useDictionaryTerm,
	useDictionaryTermInfiniteList,
	useDictionaryTermList,
	useUpdateDictionaryTerm,
};
