import { ActionIcon, Group, Stack, Tooltip } from '@mantine/core';
import { Icon, type IconNames } from '@repo/foundations';
import { map } from 'lodash-es';
import type { FocusEventHandler } from 'react';
import { forwardRef } from 'react';
import type { ActionIconGroupVariant } from './ActionIconGroup.styles';
import useStyles from './ActionIconGroup.styles';

interface IActionIconGroupProps {
	className?: string;
	icons: {
		label: string;
		iconName: IconNames;
		onClick: () => void;
	}[];
	orientation?: 'horizontal' | 'vertical';
	variant?: ActionIconGroupVariant;
}

export const ActionIconGroup = forwardRef<
	HTMLDivElement,
	IActionIconGroupProps
>(
	(
		{ className, icons, orientation = 'vertical', variant = 'primary' },
		ref
	) => {
		const { classes, cx } = useStyles({ orientation, variant });
		const Container = orientation === 'vertical' ? Stack : Group;

		const handleFocus: FocusEventHandler<HTMLButtonElement> = (event) => {
			event.target.blur();
		};

		return (
			<Container ref={ref} className={cx(className, classes.container)}>
				{map(icons, ({ label, iconName, onClick }) => (
					<Tooltip key={label} label={label} position="right">
						<ActionIcon
							className={classes.actionIcon}
							variant="default"
							size="sm"
							onClick={onClick}
							onFocus={handleFocus}
						>
							<Icon name={iconName} />
						</ActionIcon>
					</Tooltip>
				))}
			</Container>
		);
	}
);
ActionIconGroup.displayName = 'ActionIconGroup';
