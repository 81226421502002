import { Group } from '@mantine/core';
import { Button, Switch, Text } from '@repo/foundations';
import { lowerCase } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router';

import { entityModalStore } from '../../store';

function CollectionFooter() {
	const navigate = useNavigate();

	const handleClick = () => {
		entityModalStore.createCollection(navigate);
	};

	const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		entityModalStore.setMetadata('pinned', event.currentTarget.checked);
	};

	return (
		<Group position="right">
			<Switch
				checked={!!entityModalStore.metadata.pinned}
				label={
					<Text color="text/secondary/default" weight="semibold" size="sm">
						Pin to home
					</Text>
				}
				labelPosition="left"
				onChange={handleSwitchChange}
			/>
			<Button
				onClick={handleClick}
				loading={entityModalStore.loading}
				variant="primary"
				size="md"
			>
				Create {lowerCase(entityModalStore.type)}
			</Button>
		</Group>
	);
}

export default observer(CollectionFooter);
