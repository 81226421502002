import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { map } from 'lodash-es';
import { getFontColorFromBackgroundColor } from '../../../utils/color';

const UNSPLASH_ACCESS_KEY = 'BwKFoKeAFKnx-QLDo2X3gBdzp_cFuYydA2Nq1k0ylAA';

interface UnsplashResponse {
	total: number;
	results: Array<{
		id: string;
		color?: string;
		urls: {
			raw: string;
		};
	}>;
}

async function searchUnsplash(searchTerm?: string) {
	const searchParams: Record<string, string> = {
		query: searchTerm || 'abstract',
		client_id: UNSPLASH_ACCESS_KEY,
		per_page: '12',
		content_filter: 'high',
		orientation: 'landscape',
	};

	const params = new URLSearchParams(searchParams);
	const unsplashUrl = `https://api.unsplash.com/search/photos?${params.toString()}`;

	const response = await axios.get<UnsplashResponse>(unsplashUrl);

	return map(response.data.results, ({ id, color, urls }) => ({
		id,
		image: `${urls.raw}?auto=format&crop=focal&fit=crop&w=2560&h=400&q=80`,
		thumbnail: `${urls.raw}?auto=format&crop=focal&fit=crop&w=128&q=80`,
		backgroundColor: color || '#000000',
		fontColor: getFontColorFromBackgroundColor(color || '#000000'),
	}));
}

export function useUnsplashImages(searchTerm: string) {
	const queryKey = ['unsplash', searchTerm];
	const queryFn = () => searchUnsplash(searchTerm);
	return useQuery(queryKey, queryFn);
}
