import { NodeSelection, Plugin, TextSelection } from 'prosemirror-state';
import scrollIntoView from 'smooth-scroll-into-view-if-needed';
import { captureError } from '../../../../../web-tracing';
import Extension from '../lib/Extension';

type DOMNode = InstanceType<typeof window.Node>;

const ELEMENTS_WITH_SCROLL_TO_HASH = ['heading', 'query_block'];

export default class ScrollToHeading extends Extension {
	get name() {
		return 'scroll-to-heading';
	}

	get plugins() {
		return [
			new Plugin({
				props: {
					handleScrollToSelection(view) {
						const { state } = view;
						const { selection } = state;

						let dom: DOMNode | null = null;

						if (
							selection instanceof NodeSelection &&
							ELEMENTS_WITH_SCROLL_TO_HASH.includes(selection.node.type.name)
						) {
							dom = view.nodeDOM(selection.$anchor.pos);
						} else if (
							selection instanceof TextSelection &&
							ELEMENTS_WITH_SCROLL_TO_HASH.includes(
								selection.$anchor.parent?.type?.name
							)
						) {
							dom = view.domAtPos(selection.$anchor.pos)?.node;
						}

						if (!dom) {
							return false;
						}

						try {
							// give it a bit of time for the browser to stabilize the layout
							requestAnimationFrame(() => {
								scrollIntoView(dom as HTMLElement, {
									scrollMode: 'if-needed',
									block: 'start',
								});
							});
						} catch (e) {
							// scrollIntoView might throw an error if the node is not in the DOM
							// we shouldn't be scrolling to a node that's not in the DOM
							// the only situation this has happened so far is when vite is hot reloading
							captureError(e);
						}

						return true;
					},
				},
			}),
		];
	}
}
