import { Group } from '@mantine/core';
import { useStyles } from './NavBarWrapper.styles';

interface NavBarWrapperProps {
	children: React.ReactNode;
}

export function NavBarWrapper({ children }: NavBarWrapperProps) {
	const { classes } = useStyles();

	return <Group className={classes.wrapper}>{children}</Group>;
}
