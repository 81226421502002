import type MarkdownIt from 'markdown-it';
import type Token from 'markdown-it/lib/token';

const QUERY_REGEX = /;;;(.*);;;/;

function isQuery(token: Token) {
	return token.content?.match(QUERY_REGEX);
}

export default function queryBlocks(md: MarkdownIt) {
	// Insert a new rule after the "inline" rules are parsed.
	md.core.ruler.after('inline', 'queryblocks', (state) => {
		const { Token } = state;
		const { tokens } = state;

		// Work backwards through the tokens and find text that looks like a query.
		// eslint-disable-next-line no-plusplus
		for (let i = tokens.length - 1; i > 0; i--) {
			const tokenChildren = tokens[i].children || [];
			const matches = tokenChildren.filter(isQuery);

			if (matches.length && matches.length > 0) {
				let token;

				const nodes: Token[] = [];
				const { content } = matches[0];

				token = new Token('query_block_open', '', 1);
				token.attrs = [['id', content]];
				nodes.push(token);

				const text = new Token('text', '', 0);
				text.content = '';
				nodes.push(text);

				token = new Token('query_block_close', '', -1);
				nodes.push(token);
				tokens.splice(i - 1, 3, ...nodes);
			}
		}

		return false;
	});
}
