import { createStyles } from '@mantine/core';
import { Text, Title } from '@repo/foundations';

interface OnboardingViewerContentProps {
	step: number;
	firstName: string;
	workspaceName: string;
}
interface StepCopyProps {
	title: string;
	text: string;
}

enum OnboardingStep {
	Welcome = 'welcome',
	NoMoreRepetitiveQuestions = 'noMoreRepetitiveQuestions',
	GainMoreContext = 'gainMoreContext',
	ChromeExtension = 'chromeExtension',
}

interface StepContent {
	title: (firstName: string) => string;
	text: (workspaceName: string) => string;
}

const onboardingOrder = [
	OnboardingStep.Welcome,
	OnboardingStep.NoMoreRepetitiveQuestions,
	OnboardingStep.GainMoreContext,
	OnboardingStep.ChromeExtension,
];

const useStyle = createStyles(() => ({
	title: {
		whiteSpace: 'pre-line',
	},
}));

function StepCopy({ title, text }: StepCopyProps) {
	const { classes } = useStyle();
	return (
		<>
			<Title order={1} size="xl" className={classes.title}>
				{title}
			</Title>
			<Text>{text}</Text>
		</>
	);
}

const stepData: Record<OnboardingStep, StepContent> = {
	[OnboardingStep.Welcome]: {
		title: (firstName) => `Hi ${firstName},\nwelcome to Secoda`,
		text: (workspaceName) =>
			`Your Google search for ${workspaceName}’s data, improving transparency for your cross-functional team.`,
	},
	[OnboardingStep.NoMoreRepetitiveQuestions]: {
		title: () => 'No more repetitive questions',
		text: () =>
			'Bridge the gap of data insights by asking your data team questions or reviewing past questions. Your data team can provide links to relevant tables, queries, or additional questions for clearer insights.',
	},
	[OnboardingStep.GainMoreContext]: {
		title: () => 'Gain more context about your data',
		text: () =>
			'Understand your data better through Secoda by discovering information like descriptions, owners, related resources, and more.',
	},
	[OnboardingStep.ChromeExtension]: {
		title: () => 'Add Secoda to Chrome',
		text: () =>
			'Get the most out of Secoda with our new Chrome extension. Access and maintain metadata directly in tools like Sigma, Tableau, and Looker. Ask Secoda AI and your data team questions directly from Chrome. Quickly search across all your resources in one place.',
	},
};

function OnboardingViewerContent({
	step,
	firstName,
	workspaceName,
}: OnboardingViewerContentProps) {
	const onboardingStep = onboardingOrder[step];
	const currentStepContent = stepData[onboardingStep];

	if (!currentStepContent) {
		return <Text>Invalid step. Please try again.</Text>;
	}

	const title = currentStepContent.title(firstName);
	const text = currentStepContent.text(workspaceName);

	return <StepCopy title={title} text={text} />;
}

export default OnboardingViewerContent;
