import { isNil } from 'lodash-es';
import { useParams } from 'react-router-dom';

export function useIsTeamsPage() {
	const { teamId } = useParams();

	const isTeamsPage = !isNil(teamId);

	if (!isTeamsPage) {
		return {
			isTeamsPage: false,
			teamId: null,
		};
	}

	return {
		isTeamsPage: true,
		teamId,
	};
}
