import { useEffect, useRef } from 'react';

interface IWrapperProps<T> {
	svgElement: T;
}

export function Wrapper<T extends SVGSVGElement | HTMLElement>({
	svgElement,
}: IWrapperProps<T>) {
	const svg = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (svg.current && svgElement) {
			svg.current.appendChild(svgElement);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return <div ref={svg} />;
}
