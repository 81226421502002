import { useMutation } from '@tanstack/react-query';
import { deleteParamsFromUrl } from '../../../utils/url';
import { apiClient, getEndpoints } from '../../common';
import { NOTIFICATION_CHANGE_REQUEST_NAMESPACE } from './constants';
import { invalidateNotificationListQuery } from './utils';

interface IUseApplyChangeRequestParams {
	id?: string;
}

export function useApplyChangeRequest({ id }: IUseApplyChangeRequestParams) {
	const mutationFn = async () => {
		if (!id) {
			return null;
		}

		const url = `${getEndpoints(NOTIFICATION_CHANGE_REQUEST_NAMESPACE).byId(
			id
		)}apply`;

		return apiClient.post(url);
	};

	return useMutation({
		mutationFn,
		onSuccess: () => {
			deleteParamsFromUrl('id');
			invalidateNotificationListQuery();
		},
	});
}
