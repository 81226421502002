import type {
	FilterDropdownConfigList,
	FilterItem,
	FilterValue,
} from '@repo/common/components/Filter/types';
import { uniqBy } from 'lodash-es';
import { useEffect, useState } from 'react';
import { createMockableHook } from '../../utils/createMockableHook';
import { getValueAsArray } from './utils';

interface UseFilterItemsProps {
	getItems?: FilterDropdownConfigList['getItems'];
	getItemsById?: FilterDropdownConfigList['getItemsById'];
	searchTerm?: string;
	value?: FilterValue;
	excludeItems?: string[];
}

const useFilterItemsInternal = ({
	getItems,
	getItemsById,
	searchTerm = '',
	value,
	excludeItems,
}: UseFilterItemsProps) => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const [items, setItems] = useState<FilterItem[]>([]);

	useEffect(() => {
		if (!getItems) {
			return;
		}

		if (typeof getItems !== 'function') {
			const filteredItems = getItems
				.filter(
					(item) =>
						item.label.toLowerCase().includes(searchTerm.toLocaleLowerCase()) ||
						item.value
							?.toString()
							?.toLowerCase()
							?.includes(searchTerm.toLocaleLowerCase())
				)
				.filter(
					(item) =>
						!excludeItems?.includes(
							typeof item.value === 'string'
								? item.value
								: item.label.toString()
						)
				);

			setItems(filteredItems);
		} else {
			setLoading(true);
			setError(false);

			const getAllItems = async () => {
				let allItems = await getItems(1, searchTerm);

				const selectedValues = value ? getValueAsArray(value) : [];

				if (
					selectedValues?.length > 0 &&
					selectedValues.some(
						(selectedValue) =>
							!allItems.find((item) => item.value === selectedValue)
					)
				) {
					const itemsById = await Promise.resolve(
						getItemsById ? getItemsById(selectedValues as string[]) : []
					);
					allItems = uniqBy([...itemsById, ...allItems], (item) => item.value);
				}

				return allItems.filter(
					(item) =>
						!excludeItems?.includes(
							typeof item.value === 'string'
								? item.value
								: item.label.toString()
						)
				);
			};

			getAllItems()
				.then(setItems)
				.catch(() => setError(true))
				.finally(() => setLoading(false));
		}
	}, [getItems, searchTerm, getItemsById, value, excludeItems]);

	return {
		loading,
		error,
		items,
	};
};

export const [useFilterItems, MockUseFilterItemsProvider] = createMockableHook(
	useFilterItemsInternal
);
