import { Group, UnstyledButton } from '@mantine/core';
import { Icon } from '@repo/foundations';
import type { ForwardedRef, ReactNode } from 'react';
import { forwardRef } from 'react';
import { FilterTargetLabel } from './FilterTargetLabel';
import { useStyles } from './FilterTargetSimple.styles';

interface FilterTargetSimpleProps {
	label: string | ReactNode;
	isMenuOpen?: boolean;
	onClear?: () => void;
	onToggle: () => void;
	hasValue?: boolean;
}

export const FilterTargetSimple = forwardRef(
	(
		{
			isMenuOpen,
			label,
			onClear,
			onToggle,
			hasValue = false,
		}: FilterTargetSimpleProps,
		ref: ForwardedRef<HTMLButtonElement>
	) => {
		const showClear = !!onClear && hasValue;
		const showChevron = !showClear;

		const { classes } = useStyles({ hasValue, hasClear: showClear });

		return (
			<Group data-testid="filter-target-simple" className={classes.root}>
				<UnstyledButton ref={ref} onClick={onToggle} className={classes.target}>
					<FilterTargetLabel
						label={label}
						showChevron={showChevron}
						isMenuOpen={isMenuOpen}
					/>
				</UnstyledButton>
				{showClear && (
					<UnstyledButton onClick={onClear}>
						<Icon name="x" />
					</UnstyledButton>
				)}
			</Group>
		);
	}
);
FilterTargetSimple.displayName = 'FilterTargetSimple';
