import {
	ActionIcon,
	Anchor,
	Box,
	createStyles,
	Group,
	Loader,
	Stack,
	useMantineTheme,
} from '@mantine/core';
import { Icon, Text } from '@repo/foundations';
import { getFileExtensionConfig } from '../utils/fileExtensionConfig';

const useStyles = createStyles(
	(theme, { canDownload }: { canDownload: boolean }) => ({
		wrapper: {
			background: theme.other.getColor('surface/tertiary/default'),
			borderRadius: theme.radius.md,
			width: canDownload ? 320 : 280,
			gap: theme.spacing.sm,
			padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
		},
		icon: {
			borderRadius: theme.radius.sm,
		},
	})
);

interface AttachmentContainerProps {
	title: string;
	href?: string;
	isSelected: boolean;
	onRemove?: () => void;
	canDownload?: boolean;
}

export function AttachmentContainer({
	title,
	href,
	isSelected,
	onRemove,
	canDownload = true,
}: AttachmentContainerProps) {
	const { classes, cx } = useStyles({ canDownload });
	const theme = useMantineTheme();

	const parts = title.split('.');
	const extension = parts.length > 1 ? parts.pop() : '';
	const extensionConfig = getFileExtensionConfig(extension ?? '');

	return (
		<Box sx={{ position: 'relative' }}>
			<Group
				className={cx(
					classes.wrapper,
					isSelected ? 'ProseMirror-selectednode' : ''
				)}
			>
				<Box
					p="3xs"
					className={classes.icon}
					style={{ background: theme.other.getColor(extensionConfig.color) }}
				>
					<Icon color={extensionConfig.text} name={extensionConfig.icon} />
				</Box>
				<Stack spacing={0} style={{ flex: 1 }}>
					<Text weight="bold" size="sm">
						{title}
					</Text>
					<Text transform="uppercase" color="text/secondary/default" size="xs">
						{extension}
					</Text>
				</Stack>
				{href ? (
					canDownload && (
						<Anchor href={href} target="_blank">
							<Icon color="icon/primary/default" name="download" />
						</Anchor>
					)
				) : (
					<Loader size="xs" />
				)}
			</Group>
			{onRemove && (
				<ActionIcon
					size={12}
					variant="outline"
					onClick={onRemove}
					className="close-icon"
					sx={{
						position: 'absolute',
						top: -4,
						right: -4,
						borderRadius: '50%',
						padding: 1,
						width: '16px',
						height: '16px',
						minWidth: '16px',
						minHeight: '16px',
						'& svg': {
							width: '10px',
							height: '10px',
						},
					}}
				>
					<Icon name="x" />
				</ActionIcon>
			)}
		</Box>
	);
}
