import { Avatar, Group } from '@mantine/core';
import { Text } from '@repo/foundations';
import { forwardRef } from 'react';
import { vectorGraphicFromIntegrationType } from '../../../../../utils/integrationLogo';
import type { ItemProps } from '../../../IntegrationMapping';

export const IntegrationSelectItem = forwardRef<HTMLDivElement, ItemProps>(
	({ label, type, ...others }: ItemProps, ref) => (
		<div ref={ref} {...others}>
			<Group noWrap>
				<Avatar size="xs" src={vectorGraphicFromIntegrationType(type)} />
				<div>
					<Text size="sm">{label}</Text>
					<Text size="xs">{type}</Text>
				</div>
			</Group>
		</div>
	)
);
