import {
	createStyles,
	Flex,
	getStylesRef,
	Image,
	UnstyledButton,
} from '@mantine/core';
import { Icon, Text } from '@repo/foundations';
import type { IWorkspace } from '../../api';

interface AuthStepWorkspaceJoinRowProps {
	disabled: boolean;
	workspace: IWorkspace;
	onJoinClick: VoidFunction;
}

const useStyles = createStyles((theme) => ({
	displayOnHover: {
		ref: getStylesRef('displayOnHover'),
		opacity: 0,
		position: 'relative',
		left: '-10px',
		transition: 'opacity 0.3s, left 0.3s',
	},
	wrapper: {
		flexShrink: 0,
		backgroundColor: theme.other.getColor('fill/primary/default'),
		padding: theme.spacing.xs,
		margin: `0 ${theme.spacing.xs}`,
		borderRadius: theme.other.space[3],
		boxShadow: '0px 0px 0px 1px #EBEBEB inset',
		'&:hover, &:focus': {
			backgroundColor: theme.other.getColor('fill/primary/active'),
		},
		[`&:hover .${getStylesRef('displayOnHover')}, &:focus .${getStylesRef(
			'displayOnHover'
		)}`]: {
			opacity: 1,
			left: 0,
		},
	},
}));

export function AuthStepWorkspaceJoinRow({
	disabled,
	workspace,
	onJoinClick,
}: AuthStepWorkspaceJoinRowProps) {
	const { classes } = useStyles();

	return (
		<UnstyledButton
			className={classes.wrapper}
			disabled={disabled}
			onClick={onJoinClick}
		>
			<Flex direction="row" justify="space-between" align="center">
				<Flex direction="row" gap="sm">
					<Image
						src={workspace.logo || '/images/logos/cfsecoda.webp'}
						mih={40}
						miw={40}
						height={40}
						width={40}
						radius="sm"
						fit="contain"
					/>
					<Flex direction="column">
						<Text
							lineClamp={1}
							size="sm"
							weight="bold"
							color="text/primary/default"
						>
							{workspace.name}
						</Text>
						<Text lineClamp={1} size="sm" color="text/secondary/default">
							{workspace.members_count} member
							{workspace.members_count > 1 && 's'}
						</Text>
					</Flex>
				</Flex>
				<Icon
					name="arrowRight"
					color="icon/primary/default"
					className={classes.displayOnHover}
				/>
			</Flex>
		</UnstyledButton>
	);
}
