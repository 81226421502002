import type { MantineColor } from '@mantine/core';
import { createStyles, Group, Image, Skeleton, Stack } from '@mantine/core';

interface IStyleParams {
	color?: MantineColor;
}

export type ISkeletonWidgetRowProps = IStyleParams &
	(
		| {
				src?: string;
				icon?: never;
		  }
		| {
				src?: never;
				icon: JSX.Element;
		  }
	);

const useStyles = createStyles((_, { color }: IStyleParams) => ({
	root: {
		flexGrow: 1,
		width: '100%',
	},
	imageRoot: {
		flexGrow: 0,
	},
	image: {
		borderRadius: '50%',
	},
	skeleton: {
		'&::after': {
			backgroundColor: color,
		},
	},
}));

function SkeletonWidgetRow({ color, src, icon }: ISkeletonWidgetRowProps) {
	const { classes } = useStyles({ color });

	return (
		<Group spacing="sm" className={classes.root}>
			{src && (
				<Image
					classNames={{
						root: classes.imageRoot,
						image: classes.image,
					}}
					src={src}
					width={32}
					height={32}
				/>
			)}
			{icon && <div>{icon}</div>}
			<Stack spacing={4} sx={{ flexGrow: 1 }}>
				<Skeleton
					className={classes.skeleton}
					animate={false}
					width="100%"
					height={8}
				/>
				<Skeleton
					className={classes.skeleton}
					animate={false}
					width="50%"
					height={8}
				/>
			</Stack>
		</Group>
	);
}

export default SkeletonWidgetRow;
