import { FloatingPortal } from '@floating-ui/react';

interface ListBoxPortalProps {
	enabled?: boolean;
	children: JSX.Element;
}

export function ListBoxPortal({ enabled, children }: ListBoxPortalProps) {
	if (!enabled) {
		return children;
	}
	return <FloatingPortal>{children}</FloatingPortal>;
}
