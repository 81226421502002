import { useMutation } from '@tanstack/react-query';
import { apiClient, getEndpoints } from '../../common';
import queryClient from '../../queryClient';
import { aiEmbeddedQueryKeyFactory } from './constants';

interface RequestData {
	id: string;
}

export function useAIEmbeddedTerminate() {
	const mutationFn = async ({ id }: RequestData) => {
		const url = getEndpoints(aiEmbeddedQueryKeyFactory.namespace).byPath([
			id,
			'terminate',
		]);

		await apiClient.post(url);
	};

	return useMutation<unknown, unknown, RequestData, unknown>({
		mutationFn,
		onSettled: async (_data, _error, { id }) => {
			await queryClient.invalidateQueries(aiEmbeddedQueryKeyFactory.byId(id));
		},
	});
}
