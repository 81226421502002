import { FaroErrorBoundary } from '@grafana/faro-react';
import type { PropsWithChildren } from 'react';
import { Component } from 'react';
import { IS_PRODUCTION } from '../../utils/envs';
import { captureError } from '../../web-tracing';
import { errorFallbackRenderer } from './errorFallbackRenderer';

interface ErrorBoundaryProps {
	onError?: (error: Error) => void;
	disableFallback?: boolean;
}

interface ErrorBoundaryState {
	hasError: boolean;
	error: Error | null;
}

class MockErrorBoundary extends Component<
	PropsWithChildren<ErrorBoundaryProps>,
	ErrorBoundaryState
> {
	static getDerivedStateFromError(error: Error) {
		return { hasError: true, error };
	}

	constructor(props: PropsWithChildren<ErrorBoundaryProps>) {
		super(props);

		this.state = {
			hasError: false,
			error: null,
		};
	}

	componentDidCatch(error: Error) {
		const { onError } = this.props;

		// Local testing - just to make sure we're capturing errors with the
		// ProdErrorBoundary too.
		captureError(error);

		if (onError) {
			onError(error);
		}
	}

	render() {
		const { hasError, error } = this.state;
		const { children, disableFallback } = this.props;

		if (hasError && !!error && !disableFallback) {
			return errorFallbackRenderer(error);
		}

		return children;
	}
}

function ProdErrorBoundary({
	children,
	onError,
	disableFallback,
}: PropsWithChildren<ErrorBoundaryProps>) {
	return (
		<FaroErrorBoundary
			fallback={disableFallback ? undefined : errorFallbackRenderer}
			onError={onError}
		>
			{children}
		</FaroErrorBoundary>
	);
}

export function ErrorBoundary(props: PropsWithChildren<ErrorBoundaryProps>) {
	return IS_PRODUCTION ? (
		<ProdErrorBoundary {...props} />
	) : (
		<MockErrorBoundary {...props} />
	);
}
