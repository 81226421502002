import { useMemo } from 'react';
import type { ISecodaEntity } from '../../../../../api';
import { useAutomationPreviewList } from '../../../../../api/hooks/automationPreview/useAutomationPreview';
import { TableV2 } from '../../../../TableV2';
import {
	CapitalizeTextRender,
	IntegrationRender,
	TitleRender,
} from '../../../../TableV2/render';
import type { ExtendedDataTableColumn } from '../../../../TableV2/types';

interface AutomationActionCardFilterPreviewModalProps {
	modalId: string;
	automationId?: string;
}

export function AutomationActionCardFilterPreviewModal({
	modalId,
	automationId,
}: AutomationActionCardFilterPreviewModalProps) {
	const columns: ExtendedDataTableColumn<ISecodaEntity>[] = useMemo(
		() => [
			{
				title: 'Title',
				accessor: 'title',
				render: (record) => <TitleRender modalId={modalId} record={record} />,
				width: 200,
			},
			{
				accessor: 'type',
				esAccessor: 'native_type',
				title: 'Type',
				width: 150,
				render: (record) => (
					<CapitalizeTextRender
						record={record}
						field={'native_type'}
						field2="entity_type"
					/>
				),
			},
			{
				accessor: 'integration',
				esAccessor: 'integration_id',
				title: 'Integration',
				render: (record) => <IntegrationRender record={record} />,
				width: 150,
			},
		],
		[modalId]
	);

	if (!automationId) {
		throw new Error(
			'Something went wrong and automation preview could not be loaded'
		);
	}

	return (
		<TableV2<ISecodaEntity>
			height="70vh"
			columns={columns}
			pluralTypeString="resources"
			usePaginationList={useAutomationPreviewList}
			withFilters={false}
			withInfiniteScroll={false}
			withSearch
			defaultRequiredSearchParams={{
				automation_id: automationId,
				page_size: 50,
			}}
		/>
	);
}
