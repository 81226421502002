import * as Plot from '@observablehq/plot';
import { Wrapper } from '../Chart/components/Wrapper';

type Data = { label: string | number | Date; frequency: number }[];

interface ColumnProfileProps {
	data: Data;
	height?: number;
	width?: number;
	axis?: boolean;
}

const chart = (_data: Data, height: number, width: number, axis: boolean) => {
	let data = _data;

	return Plot.plot({
		marginBottom: axis ? 50 : 0,
		x: { round: true, type: 'band', tickRotate: -30, label: null },
		y: axis ? { grid: true, tickFormat: 's' } : undefined,
		axis: axis ? undefined : false,
		width,
		height,
		marks: [
			Plot.barY(data, {
				x: 'label',
				y: 'frequency',
				tip: axis ? true : false,
			}),
		],
	});
};

function ColumnProfile({
	data,
	height = 28,
	width = 180,
	axis = false,
}: ColumnProfileProps) {
	return <Wrapper vectorEl={chart(data, height, width, axis)} />;
}

export default ColumnProfile;
