import type { ESQueryModel } from '@repo/api-codegen';
import { useMemo } from 'react';
import {
	AverageRunTimeRender,
	ReadOnlyQueryFrequentUsersRender,
	SQLRender,
	TotalRunsRender,
	TotalRunTimeRender,
} from '../TableV2/render';
import type { ExtendedDataTableColumn } from '../TableV2/types.ts';

export const useColumns = (): ExtendedDataTableColumn<ESQueryModel>[] =>
	useMemo(
		() => [
			{
				accessor: 'sql',
				title: 'SQL',
				width: 200,
				render: SQLRender,
				sortable: false,
			},
			{
				accessor: 'frequent_users',
				title: 'Users',
				width: 40,
				render: ReadOnlyQueryFrequentUsersRender,
				sortable: false,
			},
			{
				accessor: 'average_runtime',
				title: 'Average runtime',
				width: 40,
				render: AverageRunTimeRender,
				sortable: false,
				textAlignment: 'right',
			},
			{
				accessor: 'total_runtime',
				title: 'Total runtime',
				width: 40,
				render: TotalRunTimeRender,
				sortable: false,
				textAlignment: 'right',
			},
			{
				accessor: 'total_runs',
				title: 'Total runs',
				width: 40,
				render: TotalRunsRender,
				sortable: false,
				textAlignment: 'right',
			},
		],
		[]
	);

export const useActions = () => [];
