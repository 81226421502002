import { Image, createStyles } from '@mantine/core';
import { Icon, type IconNames } from '../Icon';

const useStyles = createStyles((theme) => ({
	container: {
		width: theme.other.space[7],
		height: theme.other.space[7],
		backgroundColor: theme.colors['black-alpha'][3],
		borderRadius: theme.other.borderRadius.sm,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flex: '0 0 auto',
	},
	emoji: {
		fontSize: theme.other.typography.text.md,
		lineHeight: theme.other.typography.lineHeight.text.md,
	},
	imageContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: theme.other.space[5],
		height: theme.other.space[5],
		padding: theme.other.space[0.5],
	},
}));

interface ResourceIconProps {
	iconName?: IconNames;
	emoji?: string;
	imageSrc?: string;
}

export function ResourceIcon({ iconName, emoji, imageSrc }: ResourceIconProps) {
	const { classes } = useStyles();

	if (iconName) {
		return (
			<div className={classes.container}>
				<Icon name={iconName} />
			</div>
		);
	}

	if (emoji) {
		return (
			<div className={classes.container}>
				<span className={classes.emoji}>{emoji}</span>
			</div>
		);
	}

	if (imageSrc) {
		return (
			<div className={classes.container}>
				<div className={classes.imageContainer}>
					<Image width={16} height={16} src={imageSrc} />
				</div>
			</div>
		);
	}

	// eslint-disable-next-line no-console
	console.warn(
		"Didn't receive a valid prop to show an image in the `ResourceIcon"
	);
	return null;
}
