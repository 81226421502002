import { gfm } from '@joplin/turndown-plugin-gfm';
import TurndownService from 'turndown';
import breaks from './breaks';
import confluenceCodeBlock from './confluence-code-block';
import confluenceTaskList from './confluence-task-list';
import images from './images';

/**
 * Turndown converts HTML to Markdown and is used in the importer code.
 *
 * For options, see: https://github.com/domchristie/turndown#options
 */
const service = new TurndownService({
	hr: '---',
	bulletListMarker: '-',
	headingStyle: 'atx',
	codeBlockStyle: 'fenced',
	blankReplacement: (content, node) => {
		if (node.nodeName === 'P') {
			return '\n\n\\\n';
		}
		return '';
	},
})
	.remove(['script', 'style', 'title', 'head'])
	.use(gfm)
	.use(confluenceTaskList)
	.use(confluenceCodeBlock)
	.use(images)
	.use(breaks)
	.addRule('removeRelativeLinks', {
		filter: ['a'],
		replacement(content, node: any) {
			// Carrousel has asked for relative links to be removed.
			if (!node.outerHTML.includes('http')) {
				return content;
			}
			return `[${content}](${node.href})`;
		},
	})
	.addRule('transformIframe', {
		filter: ['iframe'],
		replacement(_, node: any) {
			if (!node.outerHTML.includes('http')) {
				return '';
			}
			return `[iframe:embed](${encodeURI(node.src)})`;
		},
	});

export default service;
