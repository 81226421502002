import { Text } from '@repo/foundations';
import { InlineRelatedResourcesBody } from './InlineRelatedResourcesBody';

export function InlineRelatedResourcesError() {
	return (
		<InlineRelatedResourcesBody>
			<Text size="sm">Error loading related resources</Text>
		</InlineRelatedResourcesBody>
	);
}
