import { Button } from '@repo/foundations';
import { observer } from 'mobx-react-lite';
import { appShellStore } from '../../../../components/SecodaAppShell/store';

function AddWidgetButton() {
	const {
		analyticsPageAddWidgetModalHandler: { open },
	} = appShellStore;

	return (
		<Button onClick={open} variant="primary">
			Add widgets
		</Button>
	);
}
export default observer(AddWidgetButton);
