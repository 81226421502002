import { withFaroRouterInstrumentation } from '@grafana/faro-react';
import {
	createBrowserRouter,
	createRoutesFromElements,
} from 'react-router-dom';

import { routes } from './routes';

const browserRouter = createBrowserRouter(createRoutesFromElements(routes));

export const router = withFaroRouterInstrumentation(browserRouter);
