import { createStyles } from '@mantine/core';

export const useStyles = createStyles(() => ({
	gridWrapper: {
		width: '100%',
		minHeight: 350,
		flexGrow: 1,
	},
	optionsWrapper: {
		visibility: 'visible',
		marginBottom: 12,
	},
	tableWrapper: {
		height: '100%',
	},
}));
