import type { EditorView } from 'prosemirror-view';
import type { MetricNumericFormat } from '../../../../../api';

export type Options = {
	id: string;
	xAxis?: string;
	yAxis?: string;
	dimension?: string;
	numericFormat?: MetricNumericFormat;
};

export default function insertChartBlock(
	view: EditorView,
	pos: number,
	attrs: Options
) {
	const { schema, tr } = view.state;

	const $pos = tr.doc.resolve(pos);
	view.dispatch(
		view.state.tr.replaceWith(
			$pos.pos,
			$pos.pos + ($pos.nodeAfter?.nodeSize || 0),
			schema.nodes.chart_block.create(attrs)
		)
	);
}
