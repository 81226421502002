import { Avatar, Group, Stack, useMantineTheme } from '@mantine/core';
import type {
	AlterEntityPermissionRequest,
	EntityPermissionOrMembershipOut,
} from '@repo/api-codegen';
import { Text } from '@repo/foundations';
import { membershipText } from '../../api/types/models/team';
import { getDisplayName } from '../../utils/userUtils';
import { toTitleCase } from '../SearchListItem/helpers';
import { UserAvatar } from '../UserAvatar';
import { EntityPermissionLevelSelector } from './EntityPermissionLevelSelector';

export function EntityPermissionRow({
	alterEntityPermission,
	readOnly,
	permission,
}: {
	permission: EntityPermissionOrMembershipOut;
	readOnly: boolean;
	alterEntityPermission: (
		request: AlterEntityPermissionRequest
	) => Promise<unknown>;
}) {
	const theme = useMantineTheme();

	return (
		<Group position="apart" py="xs">
			{permission.user ? (
				<Group noWrap w={204} spacing="xs">
					<UserAvatar user={permission.user} enableLink size="md" />
					<Stack spacing={0}>
						<Text
							size="sm"
							fw={theme.other.typography.weight.semibold}
							lineClamp={1}
						>
							{getDisplayName(permission.user)}
						</Text>
						<Text size="xs" color="text/secondary/default" lineClamp={1}>
							{toTitleCase(permission.user.role)}
						</Text>
					</Stack>
				</Group>
			) : (
				<Group noWrap w={204} spacing="xs">
					<Avatar size="md">{permission.group!.icon}</Avatar>
					<Stack spacing={0}>
						<Text
							size="sm"
							fw={theme.other.typography.weight.semibold}
							lineClamp={1}
						>
							{permission.group!.name}
						</Text>
						<Text size="xs" color="text/secondary/default">
							{permission.group?.users?.length ?? 0} members
						</Text>
					</Stack>
				</Group>
			)}
			<Group position="right">
				{readOnly ? (
					<Text size="sm" color="text/secondary/default">
						{membershipText(permission)}
					</Text>
				) : (
					<EntityPermissionLevelSelector
						entityPermission={permission}
						alterEntityPermission={alterEntityPermission}
					/>
				)}
			</Group>
		</Group>
	);
}
