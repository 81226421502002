import type { MantineTheme, MantineThemeOverride } from '@mantine/core';

export const Slider: MantineThemeOverride['components'] = {
	Slider: {
		styles: (theme: MantineTheme) => ({
			mark: {
				opacity: 0,
			},
			track: {
				height: '0.25rem',
			},
			thumb: {
				backgroundColor: theme.other.neutralColors.black,
			},
			markLabel: {
				color: theme.other.getColor('text/secondary/default'),
			},
		}),
	},
};
