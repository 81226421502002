import { Box, createStyles, getStylesRef, Group, Stack } from '@mantine/core';
import { EntityType } from '@repo/common/enums/entityType';
import { getSummaryAndBreadCrumbs } from '@repo/common/utils/breadcrumb';
import { Text } from '@repo/foundations';
import type { ReactNode } from 'react';
import { useNavigate } from 'react-router';
import type { LiteSecodaEntity } from '../../../../../../../packages/api-codegen';
import type { ISecodaEntity } from '../../../../api';
import { buildResourceUrl } from '../../../../utils/navigationUtils';
import { ResourceLinkType } from '../../../Link/useResourceLink';
import { ResourcePreview } from '../../../ResourcePreview';
import { SecodaEntityIcon } from '../../../SecodaEntity';

export const useStyles = createStyles((theme) => ({
	itemWrapper: {
		[`&:hover .${getStylesRef('buttonWrapper')}`]: {
			display: 'block',
		},
	},
	entityLink: {
		padding: theme.spacing.xs,
		marginLeft: `-${theme.spacing.sm}`,
		marginRight: `-${theme.spacing.xs}`,
		borderRadius: theme.radius.sm,
		'&:hover': {
			backgroundColor: theme.other.getColor('surface/primary/hover'),
		},
		cursor: 'pointer',
	},
	iconWrapper: {
		paddingTop: theme.other.space[1],
	},
	label: {
		overflowWrap: 'anywhere',
	},
	buttonWrapper: {
		ref: getStylesRef('buttonWrapper'),
		display: 'none',
	},
}));
interface IRelatedEntityProps {
	entity: ISecodaEntity | LiteSecodaEntity;
	hoverButton?: ReactNode;
	rightButton?: ReactNode;
}

const validateEntityType = (type: string | null | undefined): EntityType => {
	if (!type || !(type in EntityType)) {
		return EntityType.all; // Default fallback
	}
	return type as EntityType;
};

export function EntityDisplay({
	entity,
	hoverButton,
	rightButton,
}: IRelatedEntityProps) {
	const { classes } = useStyles();
	const navigate = useNavigate();

	const handleClick = () => {
		navigate(
			buildResourceUrl({
				id: entity.id,
				entity_type: validateEntityType(entity.entity_type),
			})
		);
	};

	const breadcrumbs = getSummaryAndBreadCrumbs(
		entity.native_type || entity.entity_type || null,
		entity.search_metadata
	);

	const getIntegrationId = () => {
		if (!('integration' in entity)) return undefined;
		const { integration } = entity;
		if (!integration) return undefined;
		if (typeof integration === 'string') return integration;
		return 'id' in integration ? integration.id : undefined;
	};

	return (
		<ResourcePreview
			data={entity}
			id={entity.id}
			resourceType={ResourceLinkType.Entity}
			isLoading={false}
			hasError={false}
			position="left-start"
			width={300}
			withinPortal
		>
			<Group spacing="xs" className={classes.itemWrapper} noWrap>
				<Group
					spacing="xs"
					position="apart"
					noWrap
					className={classes.entityLink}
					onClick={handleClick}
					style={{ flex: 1 }}
				>
					<Group spacing="xs" align="flex-start" noWrap>
						<Box className={classes.iconWrapper}>
							<SecodaEntityIcon
								inverseIconColor
								entity={{
									id: entity.id,
									icon: entity.icon || undefined,
									entity_type: validateEntityType(entity.entity_type),
									integration: getIntegrationId(),
									title: entity.title || '',
								}}
								inline
								// NOTE: Keep this consistent with Icon.tsx value
								size={20}
							/>
						</Box>
						<Stack spacing={0}>
							<Text size="sm" lineClamp={1} className={classes.label}>
								{entity.title ?? 'Untitled'}
							</Text>
							<Text size="xs" color="text/secondary/default" lineClamp={1}>
								{breadcrumbs}
							</Text>
						</Stack>
					</Group>
					{hoverButton && (
						<Box className={classes.buttonWrapper}>{hoverButton}</Box>
					)}
				</Group>
				{rightButton && (
					<Box className={classes.buttonWrapper}>{rightButton}</Box>
				)}
			</Group>
		</ResourcePreview>
	);
}
