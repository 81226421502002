import { createStyles } from '@mantine/core';
import { typography } from '@repo/theme/primitives';

export const useStyles = createStyles((theme) => ({
	scrollbarContainer: {
		display: 'flex',
		justifyContent: 'center',
		width: '100%',
		overflowY: 'scroll',
	},
	container: {
		padding: '32px 96px',
		width: '100%',
		maxWidth: 848,
	},
	content: {
		width: '100%',
	},
	title: {
		fontSize: 32,
		fontWeight: typography.weight.bold,
		lineHeight: '36px',
		color: theme.colors.gray[9],
	},
}));
