import { Card, createStyles, Stack } from '@mantine/core';
import { Text, Title } from '@repo/foundations';
import type { ACTION_TYPE } from './types';

interface IActionCardProps {
	action: {
		label: string;
		description?: string;
		emoji: string;
		type: ACTION_TYPE;
	};
	onClick: () => void;
}

const useStyles = createStyles((theme) => ({
	card: {
		cursor: 'pointer',
		backgroundColor: theme.other.getColor('surface/primary/default'),
		padding: theme.spacing.sm,
		borderRadius: theme.radius.md,
		boxShadow:
			'0px 1px 0px 0px rgba(0, 0, 0, 0.05), 0px 1px 0px 0px rgba(0, 0, 0, 0.08) inset, 0px -1px 0px 0px rgba(0, 0, 0, 0.12) inset, -1px 0px 0px 0px rgba(0, 0, 0, 0.08) inset, 1px 0px 0px 0px rgba(0, 0, 0, 0.08) inset;',
		'&:hover': {
			backgroundColor: theme.other.getColor('surface/primary/hover'),
		},
		'&:active': {
			backgroundColor: theme.other.getColor('surface/primary/active'),
		},
		'&:focus': {
			backgroundColor: theme.other.getColor('surface/primary/active'),
		},
	},
}));

function ActionCard({ action, onClick }: IActionCardProps) {
	const { classes } = useStyles();

	return (
		<Card className={classes.card} onClick={onClick}>
			<Stack spacing="sm">
				<Title size="xl">{action.emoji}</Title>
				<Stack spacing={0}>
					<Title size="md">{action.label}</Title>
					{action.description && <Text size="sm">{action.description}</Text>}
				</Stack>
			</Stack>
		</Card>
	);
}

export default ActionCard;
