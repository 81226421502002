import { makeAutoObservable } from 'mobx';
import type { AIAssistantMode } from './types';

export class AIAssistantStore {
	id = 'new';

	mode: AIAssistantMode;

	isHistoryOpen = false;

	constructor(mode: AIAssistantMode) {
		makeAutoObservable(this);

		this.mode = mode;
	}

	setId = (id: string) => {
		this.id = id;
	};

	setIsHistoryOpen = (isOpen: boolean) => {
		this.isHistoryOpen = isOpen;
	};
}
