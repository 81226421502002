import { Skeleton } from '@mantine/core';
import { BaseProgressIndicator } from '../../ProgressIndicator/BaseProgressIndicator';
import { QualityScoreRingBody } from './QualityScoreRingBody';
import { SIZE_CONFIG, type SizeOptions } from './constants';

interface QualityScoreRingLoadingProps {
	size?: SizeOptions;
}

export function QualityScoreRingLoading({
	size = 'md',
}: QualityScoreRingLoadingProps) {
	return (
		<QualityScoreRingBody
			progressIndicator={
				<BaseProgressIndicator
					size={SIZE_CONFIG[size]}
					value={0}
					backgroundLineColor="fill/tertiary/default"
					borderWidth={8}
					lineColor="fill/secondary/default"
					hoverLineColor="fill/secondary/hover"
				/>
			}
		>
			<Skeleton width={70} height={36} />
			<Skeleton width={70} height={20} />
		</QualityScoreRingBody>
	);
}
