import { Center, Menu, createStyles } from '@mantine/core';
import { Button } from '@repo/foundations';
import { EmptyState } from '../../../../../../../components/EmptyState';
import { useHomepageTeam } from '../../../../../hooks/useHomepageTeam';
import { WIDGET_CONTENT_HEIGHT } from '../../../constants';
import type { ICollectionSelectorDropdownProps } from './CollectionSelectorDropdown';
import CollectionSelectorDropdown from './CollectionSelectorDropdown';

interface IStyleParams {
	color?: string;
}

interface IEmptyChartWidgetProps
	extends IStyleParams,
		ICollectionSelectorDropdownProps {}

const useStyles = createStyles((theme, { color }: IStyleParams) => ({
	emptyState: {
		height: WIDGET_CONTENT_HEIGHT,
		display: 'flex',
		flexDirection: 'column',
		gap: theme.spacing.md,
	},
}));

function EmptyChartWidget({
	color,
	onCollectionChange,
}: IEmptyChartWidgetProps) {
	const { classes } = useStyles({ color });
	const { isTeamViewerUser } = useHomepageTeam();

	return (
		<Center className={classes.emptyState}>
			<EmptyState
				title=""
				description="Choose a collection to display resources from that collection."
				iconName="folders"
				size="lg"
				includeGoBack={false}
				withActions={
					<Menu
						disabled={isTeamViewerUser}
						withinPortal
						width={256}
						position="bottom-start"
					>
						<Menu.Target>
							<Button disabled={isTeamViewerUser} variant="primary" size="md">
								Choose collection
							</Button>
						</Menu.Target>
						<CollectionSelectorDropdown
							onCollectionChange={onCollectionChange}
						/>
					</Menu>
				}
			/>
		</Center>
	);
}

export default EmptyChartWidget;
