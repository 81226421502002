import { Icon } from '@repo/foundations';
import type { EditorDictionary, MenuItem } from '@repo/secoda-editor';

export default function tableMenuItems(
	dictionary: EditorDictionary
): MenuItem[] {
	return [
		{
			name: 'deleteTable',
			tooltip: dictionary.deleteTable,
			icon: () => <Icon name="trash" color="icon/primary/default" />,
			active: () => false,
		},
	];
}
