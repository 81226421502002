import MarkdownRenderer from '@repo/common/components/MarkdownRenderer';
import { Text } from '@repo/foundations';
import type { IActivity } from '../../api/types/models/notification';

export interface ITimelineDescriptionProps {
	notification: IActivity;
}

function TimelineDescription({ notification }: ITimelineDescriptionProps) {
	if (!notification.sender) {
		return null;
	}

	return (
		<Text color="text/secondary/default" size="sm">
			<MarkdownRenderer inline>
				{notification.short_summary_markdown}
			</MarkdownRenderer>
		</Text>
	);
}

export default TimelineDescription;
