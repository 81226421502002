import Error403 from '../../components/Error/Error403';
import Error404 from '../../components/Error/Error404';
import Error500 from '../../components/Error/Error500';
import type { ErrorType } from './ErrorPage.types';

export interface IErrorPageProps {
	code: ErrorType;
}

function ErrorPage({ code }: IErrorPageProps) {
	if (code === 403) {
		return <Error403 />;
	}
	if (code === 404) {
		return <Error404 />;
	}
	if (code === 500) {
		return <Error500 />;
	}
	return null;
}

export default ErrorPage;
