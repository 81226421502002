import { createContext, useContext, useMemo } from 'react';
import { CommentStore } from './FloatingCommentSidebar/store';

const CommentStoreContext = createContext<CommentStore | null>(null);

export interface CommentStoreProviderProps {
	children: React.ReactNode;
}

export function CommentStoreProvider({ children }: CommentStoreProviderProps) {
	const commentStore = useMemo(() => new CommentStore(), []);
	return (
		<CommentStoreContext.Provider value={commentStore}>
			{children}
		</CommentStoreContext.Provider>
	);
}

export function useCommentStoreContext() {
	const ctx = useContext(CommentStoreContext);
	if (ctx === null) {
		throw new Error(
			'useCommentStoreContext was called outside of CommentStore context'
		);
	}

	return ctx;
}
