import { ActionIcon, createStyles, Group } from '@mantine/core';
import { Icon } from '@repo/foundations';
import { includes, isNil } from 'lodash-es';
import { useMemo } from 'react';
import type { INotification } from '../../../../api/types/models/notification';
import { setParamsInUrl } from '../../../../utils/url';
import { getNotificationType } from '../../utils';
import { moveNotification } from '../utils';
import NotificationActions from './NotificationActions';
import TaskActions from './TaskActions';

interface IInboxViewHeaderProps {
	selected?: INotification;
	setSelectedId: (id: string) => void;
}

const useStyles = createStyles({
	container: {
		width: '100%',
		height: 52,
		padding: '12px 24px',
	},
	dropdown: {
		maxHeight: 500,
		overflowY: 'auto',
	},
});

function InboxViewHeader({ selected, setSelectedId }: IInboxViewHeaderProps) {
	const { classes } = useStyles();

	const type = useMemo(() => {
		if (isNil(selected)) {
			return null;
		}
		return getNotificationType(selected.event);
	}, [selected]);

	if (isNil(selected)) {
		return null;
	}

	const notificationAbove = moveNotification(selected.id, 'up');
	const notificationBelow = moveNotification(selected.id, 'down');

	const handleMoveNotification = (direction: 'up' | 'down') => {
		const notification = moveNotification(selected.id, direction);

		if (!isNil(notification)) {
			setParamsInUrl('id', notification.id);
			setSelectedId(notification.id);
		}
	};

	return (
		<Group className={classes.container} position="apart">
			<Group spacing="xs">
				<ActionIcon
					size="sm"
					p={4}
					disabled={isNil(notificationBelow)}
					onClick={() => handleMoveNotification('down')}
					data-testid="inbox-move-down-button-a779b409e"
				>
					<Icon name="chevronDown" />
				</ActionIcon>
				<ActionIcon
					size="sm"
					p={4}
					disabled={isNil(notificationAbove)}
					onClick={() => handleMoveNotification('up')}
					data-testid="inbox-move-up-button-ec0e6daf5"
				>
					<Icon name="chevronUp" />
				</ActionIcon>
			</Group>
			<Group spacing="xs">
				{type === 'tasks' ||
				includes(['access_request', 'change_request'], selected.event) ? (
					<TaskActions selected={selected} />
				) : (
					<NotificationActions selected={selected} />
				)}
			</Group>
		</Group>
	);
}

export default InboxViewHeader;
