/* eslint-disable react/no-array-index-key */
import { Tooltip } from '@mantine/core';
import { KeyboardShortcut } from '@repo/common/components/KeyboardShortcut/KeyboardShortcut';
import type { MenuItem } from '@repo/secoda-editor';
import type { EditorView } from 'prosemirror-view';
import type { CommandFactory } from '../lib/Extension';
import ToolbarButton from './ToolbarButton';
import { ToolbarButtonMenu } from './ToolbarButtonMenu';
import ToolbarSeparator from './ToolbarSeparator';

interface ToolbarMenuProps {
	commands: Record<string, CommandFactory>;
	view: EditorView;
	items: MenuItem[];
}

export default function ToolbarMenu(props: ToolbarMenuProps) {
	const { view, items, commands } = props;
	const { state } = view;

	return (
		<>
			{items.map((item, index) => {
				if (item.name === 'separator' && item.visible !== false) {
					return <ToolbarSeparator key={index} />;
				}
				if (item.visible === false || !item.icon) {
					return null;
				}

				const Icon = item.icon;
				const isActive = item.active ? item.active(state) : false;
				const isMenu = !!item.menuOptions;

				if (isMenu) {
					return (
						<ToolbarButtonMenu
							key={index}
							item={item}
							view={view}
							onClick={(option) =>
								option.name && commands[option.name](option.attrs)
							}
						/>
					);
				}

				return (
					<Tooltip
						key={index}
						label={
							item.shortcutKeys ? (
								<KeyboardShortcut keys={item.shortcutKeys}>
									{item.tooltip}
								</KeyboardShortcut>
							) : (
								item.tooltip
							)
						}
						position="top"
					>
						<ToolbarButton
							data-testid={`selection-toolbar-${item.name}-c1099ed`}
							onClick={() => item.name && commands[item.name](item.attrs)}
							data-active={isActive ? true : undefined}
						>
							<Icon />
						</ToolbarButton>
					</Tooltip>
				);
			})}
		</>
	);
}
