import { makeAutoObservable } from 'mobx';

class PropagateMetadataModalStore {
	isOpen = false;

	entityId: string | null = null;

	private setIsOpen = (isOpen: boolean) => {
		this.isOpen = isOpen;
	};

	private setEntityId = (entityId: string | null) => {
		this.entityId = entityId;
	};

	closeModal = () => {
		this.setEntityId(null);
		this.setIsOpen(false);
	};

	openModal = (entityId: string) => {
		this.setEntityId(entityId);
		this.setIsOpen(true);
	};

	constructor() {
		makeAutoObservable(this);
	}
}

export const propagateMetadataModalStore = new PropagateMetadataModalStore();
