import { Box, Group, Modal, SimpleGrid, createStyles } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { Switch } from '@repo/foundations';
import { useState } from 'react';
import { RichEditor } from '../RichEditor';
import { DefaultDevDropdown } from './DefaultDevDropdown';
import type { DevPanelItemProps } from './DevPanel';

const LOCAL_STORAGE_KEY = 'documentation-debugger-text';

const useStyles = createStyles((theme) => ({
	wrapper: {
		height: '80vh',
	},
	textarea: {
		width: '100%',
		height: '100%',
		paddingRight: theme.spacing.md,
		marginRight: theme.spacing.md,
		border: 0,
		borderRight: 'solid 1px #000000',
	},
	editorContainer: {
		width: '100%',
		fontSize: theme.fontSizes.sm,
		padding: '1rem 2rem',
	},
}));

export function DocumentationDebugger({ close }: DevPanelItemProps) {
	const { classes } = useStyles();
	const [text, setText] = useState(
		localStorage.getItem(LOCAL_STORAGE_KEY) ?? ''
	);
	const [debouncedText] = useDebouncedValue(text, 300);
	const [biDirection, setBiDirection] = useState(false);

	function handleSetText(newText: string) {
		setText(newText);
		localStorage.setItem(LOCAL_STORAGE_KEY, newText);
	}

	let sanitizedText = debouncedText.trim();
	if (sanitizedText.startsWith('"') && sanitizedText.endsWith('"')) {
		sanitizedText = sanitizedText.slice(1, -1);
	}

	return (
		<DefaultDevDropdown label="Documentation Debugger" close={close}>
			<Modal
				title={
					<Group>
						Documentation Debugger
						<Switch
							label="Bi-directional"
							checked={biDirection}
							onChange={(e) => setBiDirection(e.currentTarget.checked)}
						/>
					</Group>
				}
				opened
				size="100%"
				onClose={close}
				zIndex={9999}
			>
				<SimpleGrid cols={2} className={classes.wrapper} spacing={0}>
					<Box>
						<textarea
							value={text}
							onChange={(e) => handleSetText(e.currentTarget.value)}
							className={classes.textarea}
							placeholder="Paste a markdown string here to preview it in the editor."
						/>
					</Box>
					<Box className={classes.editorContainer}>
						<RichEditor
							initialValue={sanitizedText}
							readOnly={false}
							onChangeCallback={(value?: string) =>
								value && biDirection ? handleSetText(value) : {}
							}
						/>
					</Box>
				</SimpleGrid>
			</Modal>
		</DefaultDevDropdown>
	);
}
