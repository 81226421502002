import type { MarkdownSerializerState } from '@repo/secoda-editor/lib/markdown/serializer';
import { toggleMark } from 'prosemirror-commands';
import type { InputRule } from 'prosemirror-inputrules';
import type { ParseSpec } from 'prosemirror-markdown';
import type {
	MarkSpec,
	MarkType,
	Node as ProsemirrorNode,
	Schema,
} from 'prosemirror-model';
import type { Command } from 'prosemirror-state';
import type { CommandFactory } from '../lib/Extension';
import Extension from '../lib/Extension';

export type MarkOptions = { type: MarkType; schema: Schema };

export default abstract class Mark extends Extension<MarkType> {
	get type() {
		return 'mark';
	}

	get schema(): MarkSpec {
		return {};
	}

	get markdownToken(): string {
		return '';
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	keys(_options: MarkOptions): Record<string, Command> {
		return {};
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	inputRules(_options: MarkOptions): InputRule[] {
		return [];
	}

	toMarkdown(state: MarkdownSerializerState, node: ProsemirrorNode) {
		// eslint-disable-next-line no-console
		console.error('toMarkdown not implemented', state, node);
	}

	parseMarkdown(): ParseSpec | void {
		return undefined;
	}

	commands({ type }: MarkOptions): CommandFactory {
		return () => toggleMark(type);
	}
}
