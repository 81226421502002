import { createStyles, SimpleGrid, Stack } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { useCallback, useEffect } from 'react';
import { useWorkspace } from '../../../../api';
import { useUpdateWorkspace } from '../../../../api/hooks/workspace/useUpdateWorkspace';
import { useOnboarding } from '../../../../hooks/useOnboarding';
import { getWidgetCardShadow } from '../../utils';
import OnboardingHeader from './components/OnboardingHeader';
import OnboardingStep from './components/OnboardingStep';
import { HOMEPAGE_ONBOARDING_STEP_LINKS } from './constants';

const useStyles = createStyles((theme) => ({
	root: {
		borderRadius: theme.spacing.sm,
		boxShadow: getWidgetCardShadow(theme),
	},
}));

function HomepageEditorOnboarding() {
	const { classes } = useStyles();

	const {
		stepsTotal,
		stepsCompleted,
		isConnectIntegrationComplete,
		isExploreWorkspaceComplete,
		isInviteTeamMembersComplete,
	} = useOnboarding();

	const { workspace } = useWorkspace();
	const { mutateAsync } = useUpdateWorkspace(workspace.id);

	const completeOnboarding = useCallback(async () => {
		mutateAsync({
			data: {
				id: workspace.id,
				onboarding_completed: true,
			},
		});
	}, [mutateAsync, workspace.id]);

	useEffect(() => {
		if (stepsTotal === stepsCompleted) {
			completeOnboarding().catch(() => {
				showNotification({
					title: 'Failed to dismiss onboarding',
					message: 'An error occurred while dismissing onboarding',
					color: 'red',
				});
			});
		}
	}, [completeOnboarding, stepsCompleted, stepsTotal]);

	if (workspace.onboarding_completed) {
		return null;
	}

	return (
		<Stack className={classes.root} p="lg">
			<OnboardingHeader
				totalSteps={stepsTotal}
				stepsCompleted={stepsCompleted}
				onDismissOnboarding={completeOnboarding}
			/>
			<SimpleGrid cols={1}>
				<OnboardingStep
					step="Connect an integration"
					link={HOMEPAGE_ONBOARDING_STEP_LINKS['Connect an integration']}
					isComplete={isConnectIntegrationComplete}
				/>
				<OnboardingStep
					step="Explore your workspace"
					link={HOMEPAGE_ONBOARDING_STEP_LINKS['Explore your workspace']}
					isComplete={isExploreWorkspaceComplete}
				/>
				<OnboardingStep
					step="Invite your team members"
					link={HOMEPAGE_ONBOARDING_STEP_LINKS['Invite your team members']}
					isComplete={isInviteTeamMembersComplete}
				/>
			</SimpleGrid>
		</Stack>
	);
}

export default HomepageEditorOnboarding;
