import { Group, createStyles } from '@mantine/core';
import { IconButton } from '@repo/foundations';
import { colors } from '@repo/theme/primitives';
import { observer } from 'mobx-react-lite';
import type { Automation } from '../../../../../api';
import { AutomationTarget } from '../../../../../api';
import { useAutomationStore } from '../../../../../pages/AutomationPage/context';
import { isValidEnumValue } from '../../../../../utils/enumUtils';
import { AutomationActionCardType } from '../../../constants';
import AutomationCardButton from '../../AutomationCardButton';
import { AUTOMATION_ACTION_TARGETS } from './constants';

const useStyles = createStyles((theme) => ({
	root: {
		justifyContent: 'space-between',
		width: '100%',
	},
	group: {
		alignSelf: 'stretch',
		background: colors.gray[1],
		width: '100%',
		gap: theme.spacing['2xs'],
	},
}));

interface AutomationActionCardEditHeaderTextProps {
	cardId: string;
	handleAutomationUpdate: (automation: Partial<Automation>) => Promise<void>;
}

function AutomationActionCardEditHeaderText({
	cardId,
	handleAutomationUpdate,
}: AutomationActionCardEditHeaderTextProps) {
	const { classes } = useStyles();
	const automationStore = useAutomationStore();
	const { automation } = automationStore;
	const handleOperatorOnClick = (option: string | boolean) => {
		if (
			typeof option === 'string' &&
			isValidEnumValue(AutomationTarget, option)
		) {
			handleAutomationUpdate({
				action_target: option,
			});

			return;
		}

		throw new Error('Invalid automation action target has been provided');
	};

	const handleEditResourceCardDelete = async () => {
		// If no edit action is being performed, the notifications
		// should not include a summary of the changes.
		await handleAutomationUpdate?.({
			action_update_entities: [],
		});

		automationStore.removeCard(AutomationActionCardType.EDIT_RESOURCES, cardId);
	};

	const existingTarget = AUTOMATION_ACTION_TARGETS.filter(
		(target) => target.value === automation?.action_target
	);

	if (existingTarget.length === 0) {
		throw new Error('Invalid automation action target has been provided');
	}

	const { label, iconName } = existingTarget[0];

	return (
		<Group className={classes.root} noWrap>
			<Group className={classes.group} noWrap>
				Edit
				<AutomationCardButton
					buttonLabel={label}
					buttonOptions={AUTOMATION_ACTION_TARGETS}
					onClick={handleOperatorOnClick}
					iconType="tabler"
					buttonIconName={iconName}
				/>
			</Group>
			<IconButton
				variant="tertiary"
				onClick={handleEditResourceCardDelete}
				iconName="x"
			/>
		</Group>
	);
}

export default observer(AutomationActionCardEditHeaderText);
