import { Button } from '@repo/foundations';
import { Link } from 'react-router-dom';
import type { ISecodaEntity } from '../../../api';
import { entityUrl } from '../../../utils/navigationUtils';

interface AnswerQuestionActionProps {
	entity: ISecodaEntity;
}

export function AnswerQuestionAction({ entity }: AnswerQuestionActionProps) {
	const url = entityUrl(entity);

	return (
		<Button component={Link} to={`${url}/discussions`} variant="primary">
			Answer questions
		</Button>
	);
}
