import type { DataTableColumnTextAlignment } from '@repo/mantine-datatable';
import { useMemo } from 'react';
import type { Measurement, Monitor } from '../../../api';
import type { ExtendedDataTableColumn } from '../../../components/TableV2/types.ts';

import { DateRenderer } from '../../../components/CatalogView/TableView/DateRenderer';
import { IncidentStatusBadge } from '../components/IncidentStatusBadge.tsx';
import { formatValue, metricTypeLabel } from '../utils.tsx';

export function useColumns(
	monitor: Monitor
): ExtendedDataTableColumn<Measurement>[] {
	const columns = useMemo(
		() => [
			{
				accessor: 'created_at',
				title: 'Time',
				width: 100,
				render: (record: Measurement) =>
					record?.created_at && (
						<DateRenderer
							value={record.created_at}
							format="MMM DD, YYYY [at] hh:mm A"
						/>
					),
			},
			{
				accessor: 'value',
				title: metricTypeLabel(monitor.metric_type),
				width: 200,
				textAlignment: 'right' as DataTableColumnTextAlignment,
				render: (record: Measurement) =>
					formatValue(monitor.metric_type, parseFloat(record.value)),
			},
			{
				accessor: 'incident_status',
				title: 'Incident',
				width: 100,
				render: (record: Measurement) =>
					record.incident_status ? (
						<IncidentStatusBadge incidentStatus={record.incident_status} />
					) : null,
			},
		],
		[monitor.metric_type]
	);

	return columns;
}
