import { ActionIcon, createStyles, Menu } from '@mantine/core';
import { Icon } from '@repo/foundations';
import type { INotification } from '../../../../../api/types/models/notification';
import NotificationActions from './NotificationActions';

interface IInboxActionMenuProps {
	selected: INotification | undefined;
	hidden: boolean;
}

const useStyles = createStyles({
	dropdown: {
		minHeight: 'fit-content',
	},
});

function InboxActionMenu({ selected, hidden }: IInboxActionMenuProps) {
	const { classes } = useStyles();

	if (hidden) {
		return null;
	}

	return (
		<Menu
			classNames={{ dropdown: classes.dropdown }}
			width={256}
			position="bottom-start"
			withinPortal
		>
			<Menu.Target>
				<ActionIcon size="sm" p={4}>
					<Icon name="inbox" color="icon/primary/default" />
				</ActionIcon>
			</Menu.Target>
			<Menu.Dropdown>
				<NotificationActions selected={selected} />
			</Menu.Dropdown>
		</Menu>
	);
}

export default InboxActionMenu;
