import { createStyles } from '@mantine/core';
import { rem } from '@mantine/styles';
import type { ButtonProps } from '@repo/foundations';
import { Button } from '@repo/foundations';
import { forwardRef } from 'react';

const useStyles = createStyles((theme) => ({
	button: {
		pointerEvents: 'all',
	},
	inner: {
		paddingLeft: rem(theme.other.space[1]),
		paddingRight: rem(theme.other.space[1]),
	},
}));

function ToolbarButton(
	{ children, ...rest }: ButtonProps,
	ref: React.Ref<HTMLButtonElement>
) {
	const { classes } = useStyles();
	return (
		<Button
			{...rest}
			ref={ref}
			className={classes.button}
			classNames={{ inner: classes.inner }}
			variant="tertiary"
		>
			{children}
		</Button>
	);
}

export default forwardRef(ToolbarButton);
