import { Group, createStyles } from '@mantine/core';
import type { PropsWithChildren } from 'react';

const useStyles = createStyles(
	(theme, { variant }: { variant: 'light' | 'dark' }) => ({
		span: {
			backgroundColor: theme.other.getColor(
				variant === 'light' ? 'fill/secondary/default' : 'fill/inverse/hover'
			),
			color: theme.other.getColor(
				variant === 'light' ? 'text/secondary/default' : 'text/inverse/default'
			),
			fontSize: theme.fontSizes.xs,
			lineHeight: theme.other.typography.lineHeight.text.xs,
			borderRadius: theme.radius.xs,
			padding: `${theme.spacing['4xs']} ${theme.spacing['3xs']}`,
		},
	})
);

interface KeyboardShortcutProps {
	keys: string[];
	keyAlignment?: 'left' | 'right';
	variant?: 'light' | 'dark';
}

export function KeyboardShortcut({
	keys,
	children,
	keyAlignment = 'right',
	variant = 'light',
}: PropsWithChildren<KeyboardShortcutProps>) {
	const { classes } = useStyles({ variant });

	return (
		<Group spacing="xs" noWrap>
			{keyAlignment === 'right' && children}
			<Group spacing="3xs" noWrap>
				{keys.map((key, index) => (
					// eslint-disable-next-line react/no-array-index-key
					<span key={index} className={classes.span}>
						{key}
					</span>
				))}
			</Group>
			{keyAlignment === 'left' && children}
		</Group>
	);
}
