import { createStyles } from '@mantine/core';
import { TOP_NAVBAR_HEIGHT } from '../constants';

export const useStyles = createStyles((theme) => ({
	wrapper: {
		position: 'sticky',
		top: 0,
		width: '100%',
		height: TOP_NAVBAR_HEIGHT,
		backgroundColor: theme.other.getColor('surface/app/default'),
		zIndex: 2,
		paddingTop: theme.spacing.sm,
		paddingBottom: theme.spacing.sm,
		paddingLeft: theme.spacing.xl,
		paddingRight: theme.spacing.xl,
		justifyContent: 'space-between',
		flexWrap: 'nowrap',
		gap: 0,

		'@media print': {
			display: 'none',
		},
	},
}));
