import {
	Accordion,
	Group,
	Stack,
	createStyles,
	getStylesRef,
} from '@mantine/core';
import { formatScore } from '@repo/common/utils/dataQuality';
import { Text } from '@repo/foundations';
import { orderBy } from 'lodash-es';
import { DataQualityProgressIcon } from '../DataQualityProgressIcon';
import { DataQualitySuggestionActions } from '../DataQualitySuggestionActions/DataQualitySuggestionActions';
import { DataQualitySuggestionDescription } from '../DataQualitySuggestionDescription';
import type {
	DataQualityBreakdownSubItem,
	DataQualityBreakdownSubItemCompleted,
} from '../types';

const useStyles = createStyles((theme) => ({
	accordion: {},
	item: {
		width: '100%',
		border: 0,
		borderRadius: theme.radius.sm,
		marginBottom: theme.spacing['5xs'],

		'&[data-active]': {
			backgroundColor: theme.other.getColor('surface/primary/selected'),

			[`.${getStylesRef('title')}`]: {
				fontWeight: theme.other.typography.weight.bold,
			},
		},

		'&:last-child': {
			marginBottom: 0,
		},
	},
	label: {
		padding: 0,
	},
	control: {
		padding: theme.spacing.xs,
		border: 0,
		borderRadius: theme.radius.sm,
		justifyContent: 'space-between',
		gap: theme.spacing.xs,

		'&:hover': {
			backgroundColor: theme.other.getColor('surface/secondary/hover'),
		},

		'&:active': {
			backgroundColor: theme.other.getColor('surface/secondary/active'),
		},
	},
	chevron: {
		margin: 0,
		color: theme.other.getColor('icon/secondary/default'),

		'&[data-rotate]': {
			color: theme.other.getColor('icon/primary/default'),
		},
	},
	panel: {
		border: 0,
	},
	content: {
		padding: theme.spacing.xs,
		paddingLeft: theme.other.space[9],
	},
	title: {
		ref: getStylesRef('title'),

		color: theme.other.getColor('text/primary/default'),
	},
}));

interface DataQualityBreakdownChildrenProps {
	subItems: Array<
		DataQualityBreakdownSubItem | DataQualityBreakdownSubItemCompleted
	>;
	onReportPage?: boolean;
}

export function DataQualityBreakdownChildren({
	subItems,
	onReportPage = false,
}: DataQualityBreakdownChildrenProps) {
	const { classes } = useStyles();

	// we want to show the most impactful suggestions first
	// so we sort by percentage asc (distance from max_score) and then by max_score desc
	const sortedSubItems = orderBy(
		subItems,
		['percentage', 'max_score'],
		['asc', 'desc']
	);

	return (
		<Accordion
			className={classes.accordion}
			classNames={{
				item: classes.item,
				label: classes.label,
				control: classes.control,
				chevron: classes.chevron,
				content: classes.content,
				panel: classes.panel,
			}}
		>
			{sortedSubItems.map((subItem) =>
				'isCompleted' in subItem ? (
					<Group key={subItem.title} spacing="xs" align="flex-start" p="xs">
						<DataQualityProgressIcon score={subItem.percentage} />
						<Text size="sm" className={classes.title}>
							{subItem.title}
						</Text>
						<Text size="sm" color="text/secondary/default">
							{formatScore(subItem.score)} / {subItem.max_score}
						</Text>
					</Group>
				) : (
					<Accordion.Item
						key={`${subItem.title}-${subItem.entity.id}`}
						value={`${subItem.title}-${subItem.entity.id}`}
					>
						<Accordion.Control>
							<Group key={subItem.title} spacing="xs" align="flex-start" noWrap>
								<DataQualityProgressIcon score={subItem.percentage} />
								<Text size="sm" className={classes.title} truncate>
									{subItem.title}
								</Text>
								{!onReportPage && (
									<Text size="sm" color="text/secondary/default" truncate>
										{formatScore(subItem.score)} / {subItem.max_score}
									</Text>
								)}
							</Group>
						</Accordion.Control>
						<Accordion.Panel>
							<Stack spacing="sm">
								<DataQualitySuggestionDescription item={subItem} />
								<DataQualitySuggestionActions item={subItem} />
							</Stack>
						</Accordion.Panel>
					</Accordion.Item>
				)
			)}
			{sortedSubItems.length === 0 && (
				<Text pl="xl" size="sm">
					No suggestions available.
				</Text>
			)}
		</Accordion>
	);
}
