import type { ItemIconType } from '@repo/common/components/ItemIcon';
import SingleSelector from '@repo/common/components/SingleSelector/SingleSelector';
import type { SelectablePropertyItem } from '@repo/common/components/SingleSelector/types';
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';

interface AutomationCardSingleSelectorProps {
	targetButton: JSX.Element;
	addFilter?: boolean;
	buttonLabel: string;
	buttonOptions: SelectablePropertyItem[];
	onClick: (value: string | boolean, label?: string) => void;
	setHighlight: (highlight: boolean) => void;
	defaultOpened?: boolean;
	property?: string;
	iconType?: ItemIconType;
	placeholder?: string;
}

function AutomationCardSingleSelector({
	targetButton,
	addFilter = false,
	buttonLabel,
	buttonOptions,
	defaultOpened = false,
	onClick,
	property,
	iconType = 'none',
	placeholder,
	setHighlight,
}: AutomationCardSingleSelectorProps) {
	const handleOnClick = useCallback(
		(val: string) => {
			const foundOption = buttonOptions.find((option) => option.value === val);

			if (!foundOption) {
				return;
			}

			onClick(foundOption.value, foundOption.label);
			setHighlight(false);
		},
		[buttonOptions, onClick, setHighlight]
	);

	const initiallySelected = buttonOptions.find(
		(option) => option.label === buttonLabel
	)?.value;

	const handleOnClose = () => {
		setHighlight(false);
	};

	return (
		<SingleSelector
			target={targetButton}
			isViewerUser={false}
			searchable={addFilter}
			options={buttonOptions}
			placeholder={placeholder ?? 'Filter by...'}
			onChange={handleOnClick}
			defaultOpened={defaultOpened}
			property={property ?? ''}
			iconType={iconType}
			initialSelected={initiallySelected}
			onClose={handleOnClose}
		/>
	);
}

export default observer(AutomationCardSingleSelector);
