import { uuidv4 } from 'lib0/random';
import { makeAutoObservable } from 'mobx';
import type { AutomationPropagateMetadataField } from '../../../../../api/types/models/automation/automationPropagateMetadata';

interface PropagateMetadataFields {
	id: string;
	val?: AutomationPropagateMetadataField;
}

export class AutomationActionCardPropagateMetadataStore {
	fields: PropagateMetadataFields[] = [];

	constructor() {
		makeAutoObservable(this);
	}

	addField(newField: AutomationPropagateMetadataField | undefined) {
		this.fields = [
			...this.fields,
			{
				id: uuidv4(),
				val: newField,
			},
		];
	}

	setField(id: string, val: AutomationPropagateMetadataField) {
		const index = this.fields.findIndex((field) => field.id === id);

		if (index === -1) {
			return;
		}

		this.fields[index].val = val;
	}

	deleteField(id: string) {
		this.fields = this.fields.filter((field) => field.id !== id);
	}

	resetFields() {
		this.fields = [];
	}
}
