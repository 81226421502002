import {
	Box,
	Flex,
	Input,
	Select,
	TextInput,
	useMantineTheme,
} from '@mantine/core';
import { useClipboard } from '@mantine/hooks';

import { Badge, Breadcrumbs, Button, Text } from '@repo/foundations';
import ed25519 from 'ed25519-keygen/ssh';
import { randomBytes } from 'ed25519-keygen/utils';
import { Field, Form, Formik } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import {
	queryClient,
	tunnelsQueryKeyFactory,
	type ITunnelConnectionStatus,
} from '../../api';
import { useTestTunnelConnection } from '../../api/hooks/tunnel/useTestTunnelConnection';
import {
	ConnectionError,
	Whitelist,
} from '../../components/Integration/IntegrationDisclaimers';
import { SSHTunnel } from '../../lib/models';
import { usePromise } from '../../utils/suspense';
import { generateKeyPair } from './utils';

function CopyKey(props: { value: string }) {
	const { value } = props;
	const clipboard = useClipboard();

	return (
		<Box>
			<Flex mb={2} align="center" gap="10px">
				<Input miw="100%" value={value} readOnly />
				<Button onClick={() => clipboard.copy(value)}>
					{clipboard.copied ? 'Copied' : 'Copy'}
				</Button>
			</Flex>
			<Text size="xs">
				This key is generated within your browser and is not stored on our
				servers. Before leaving this page, copy this Secoda public key to the
				~/.ssh/authorized_keys file.
			</Text>
		</Box>
	);
}

function validateNumber(value: string) {
	let error;

	if (!value) {
		return 'Fill this field';
	}

	if (!parseInt(value, 10)) {
		error = 'Enter a valid port';
	}
	return error;
}

function validateUsername(value: string) {
	let error;

	if (!value) {
		return 'Fill this field';
	}

	if (!value.match(/^[a-z_][a-z0-9_-]*[$]?$/i)) {
		error = 'Enter a valid username';
	}

	return error;
}

function validateHostname(value: string) {
	/* eslint-disable no-useless-escape */
	const validIpAddressRegex =
		/^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/i;

	const validHostnameRegex =
		/^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\-]*[A-Za-z0-9])$/i;

	const loopbackRegex =
		/^localhost$|^127(?:\.[0-9]+){0,2}\.[0-9]+$|^(?:0*\:)*?:?0*1$/i; // https://stackoverflow.com/questions/8426171/what-regex-will-match-all-loopback-addresses
	/* eslint-enable no-useless-escape */

	let error;

	if (!value) {
		return 'Fill this field';
	}

	if (value.match(loopbackRegex)) {
		error = 'Loopback addresses are not allowed.';
	}

	if (!value.match(validIpAddressRegex) && !value.match(validHostnameRegex)) {
		error = 'Enter a valid ip address or hostname.';
	}

	return error;
}

function TunnelForm(props: { initialValues: any }) {
	const { initialValues } = props;
	const theme = useMantineTheme();
	const [publicKey, setPublicKey] = useState<string>();
	const [tunnelStatus, setTunnelStatus] =
		useState<ITunnelConnectionStatus | null>(null);

	const { mutate: testConnection } = useTestTunnelConnection();

	const handleTest = useCallback(async () => {
		testConnection(initialValues.id, {
			onSuccess: (status: ITunnelConnectionStatus) => {
				setTunnelStatus(status);
			},
		});
	}, [initialValues.id, testConnection]);

	useEffect(() => {
		// Test connection if we have a tunnel.
		if (initialValues.id) {
			handleTest();
		}
	}, [initialValues.id, handleTest]);

	if (publicKey) {
		return (
			<Flex my="100px" w="100%" justify="center">
				<Flex style={{ maxWidth: 750 }}>
					<CopyKey value={publicKey} />
				</Flex>
			</Flex>
		);
	}

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={async (values) => {
				// Check if we are updating an existing tunnel.
				if (values.id) {
					const tunnel = values as SSHTunnel;
					tunnel.host = values.host;
					tunnel.username = values.username;
					tunnel.port = values.port;
					await tunnel.save(['host', 'username', 'port']);
					handleTest();
				} else {
					let copyText = null;
					let input = { ...values };

					if (values.type === 'RSA') {
						const rsaKeys = await generateKeyPair({
							alg: 'RSASSA-PKCS1-v1_5',
							size: 4096,
							hash: 'SHA-512',
							name: 'ssh@secoda.co',
						});
						input = {
							...values,
							private_key: btoa(rsaKeys.privateKey),
						};
						copyText = rsaKeys.publicKey;
					} else {
						const ed25519Keys = await ed25519(randomBytes(32), 'ssh@secoda.co');
						input = { ...values, private_key: btoa(ed25519Keys.privateKey) };
						copyText = ed25519Keys.publicKey;
					}

					await SSHTunnel.create(input);
					setPublicKey(copyText);
				}
				queryClient.invalidateQueries(tunnelsQueryKeyFactory.list());
			}}
		>
			{(formProps) => (
				<Form>
					<Flex w="100%" justify="center">
						<Flex
							direction="column"
							justify="flex-start"
							gap={theme.other.space[5]}
							w="100%"
							my={theme.other.space[6]}
							style={{ maxWidth: 750 }}
						>
							<Flex justify="space-between">
								{tunnelStatus && tunnelStatus.is_open && (
									<Badge variant="success">Connected</Badge>
								)}
							</Flex>
							<Field name="username" validate={validateUsername}>
								{({ field, form }: any) => (
									<TextInput
										label="SSH Username"
										description="Ensure that this SSH user exists on the bastion."
										error={form.errors.username}
										{...field}
										id="username"
										placeholder="secoda"
									/>
								)}
							</Field>

							<Field name="host" validate={validateHostname}>
								{({ field, form }: any) => (
									<TextInput
										label="SSH Hostname"
										description="The IP or public hostname of the bastion."
										error={form.errors.host}
										{...field}
										id="host"
										placeholder=""
									/>
								)}
							</Field>

							<Field name="port" validate={validateNumber}>
								{({ field, form }: any) => (
									<TextInput
										label="SSH Port"
										description="The port the bastion is listening on. The default SSH port is 22."
										error={form.errors.port}
										{...field}
										id="port"
										placeholder="22"
									/>
								)}
							</Field>

							{!initialValues.id && (
								<Field name="type">
									{({ field, form }: any) => (
										<Select
											label="Pick the SSH algorithm"
											defaultValue="ed25519"
											description="ed25519 is the suggested algorithm. RSA is also supported."
											data={['ed25519', 'RSA']}
											error={form.errors.type}
											{...field}
											onChange={(e) => {
												form.setFieldValue('type', e);
											}}
											id="type"
										/>
									)}
								</Field>
							)}
							<Whitelist />
							{tunnelStatus && !tunnelStatus.is_open && (
								<ConnectionError
									error={`${
										tunnelStatus.error || ''
									}. If the issue persists, contact customer support.`}
								/>
							)}
							<Box>
								<Button loading={formProps.isSubmitting} type="submit">
									Submit
								</Button>
							</Box>
						</Flex>
					</Flex>
				</Form>
			)}
		</Formik>
	);
}

function TunnelPage() {
	const theme = useMantineTheme();
	const { id } = useParams();

	const tunnel = usePromise(
		'tunnel',
		async () => {
			if (!id) {
				return undefined;
			}
			// @ts-expect-error TS(2345): Argument of type '{ id: string; }' is not assignab... Remove this comment to see the full error message
			const newTunnel = new SSHTunnel({ id });
			await newTunnel.sync();
			return newTunnel;
		},
		[id]
	);

	return (
		<main>
			<Box px={theme.other.space[8]} py={theme.other.space[1]}>
				<Breadcrumbs
					crumbs={[
						{ title: 'Tunnels', href: '/tunnels' },
						{ title: 'New standard tunnel', href: '/tunnels/new' },
					]}
				/>
				<TunnelForm initialValues={tunnel ?? {}} />
			</Box>
		</main>
	);
}

export default TunnelPage;
