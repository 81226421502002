import { Group } from '@mantine/core';
import { useStyles } from './NavBarRightSideWrapper.styles';

interface NavBarRightSideWrapperProps {
	children: React.ReactNode;
}

export function NavBarRightSideWrapper({
	children,
}: NavBarRightSideWrapperProps) {
	const { classes } = useStyles();
	return <Group className={classes.rightSideWrapper}>{children}</Group>;
}
