import { createStyles } from '@mantine/core';

export const useModalStyles = createStyles((theme) => ({
	modalHeader: {
		background: theme.other.getColor('surface/secondary/default'),
		borderBottom: `1px solid ${theme.other.getColor(
			'border/secondary/default'
		)}`,
		height: theme.other.space[12],
	},
	textInput: {
		padding: 0,
		width: '100%',
		input: {
			borderRadius: '0.5rem',
			borderCollapse: 'separate',
		},
	},
}));
