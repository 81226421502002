import { Container, createStyles } from '@mantine/core';
import { useLocation } from 'react-router-dom';

import { useRequestAccess } from '../../api/hooks/notification/useRequestAccess';
import type { ButtonDetails } from '../EmptyState';

import { EmptyState } from '../EmptyState';
import { getIdFromURL } from './utils';

const useStyles = createStyles(() => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		width: 320,
		gap: 16,
		paddingRight: 0,
		paddingLeft: 0,
	},
	box: {
		padding: 20,
		background: '#F7F7F7',
		borderRadius: 10,
		display: 'flex',
	},
	infoCircleIcon: {
		marginRight: 5,
	},
	helperText: {
		fontSize: 16,
		color: '#717171',
	},
}));

function RequestAccess({ entityId }: { entityId: string }): JSX.Element {
	const { isLoading: isRequestingAccessing, mutate: requestAccess } =
		useRequestAccess({
			entityId,
		});

	const buttons: ButtonDetails[] = [
		{
			action: requestAccess,
			isPrimary: true,
			name: 'Request access',
			size: 'md',
			loading: isRequestingAccessing,
		},
	];

	const { classes } = useStyles();

	return (
		<Container className={classes.container}>
			<EmptyState
				title="No access to this resource"
				description="An admin on your team can grant you access to this resource"
				buttons={buttons}
				includeGoBack
				iconName="lock"
				size="lg"
			/>
		</Container>
	);
}

function Error403(): JSX.Element {
	const location = useLocation();
	const { classes } = useStyles();

	const entityId = getIdFromURL(location.pathname);
	if (entityId) {
		return <RequestAccess entityId={entityId} />;
	}
	return (
		<Container className={classes.container}>
			<EmptyState
				title="No permissions for this resource"
				description="An admin in your workspace can grant you access to this resource"
				iconName="lock"
				size="lg"
				includeGoBack
			/>
		</Container>
	);
}

export default Error403;
