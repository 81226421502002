import {
	Box,
	createStyles,
	Group,
	Space,
	Stack,
	UnstyledButton,
} from '@mantine/core';
import { Icon, Text } from '@repo/foundations';
import { compact, isNil, map, reverse } from 'lodash-es';
import { memo } from 'react';
import { Link } from 'react-router-dom';
import type { IApiListResponse, INestedBreadcrumbs } from '../../../../../api';
import { useIntegrationList } from '../../../../../api';
import { useNestedBreadcrumbsList } from '../../../../../api/hooks/nestedBreadcrumbs';
import { getBreadcrumbForEntity } from '../../../../../utils/breadcrumb';
import { buildResourceUrl } from '../../../../../utils/navigationUtils';
import { SecodaEntityIcon } from '../../../../SecodaEntity';
import type { IActionItemProps } from './DefaultActionItem';

const useStyles = createStyles(
	(theme, { selected }: { selected?: boolean }) => ({
		wrapper: {
			padding: theme.spacing.xs,
			borderRadius: theme.radius.sm,
			backgroundColor: selected
				? theme.other.getColor('fill/transparent/hover')
				: theme.other.getColor('surface/app/default'),
			'&:hover': {
				backgroundColor: theme.other.getColor('fill/transparent/hover'),
			},
			'&:active': {
				backgroundColor: theme.other.getColor('surface/primary/active'),
			},
		},
		icon: {
			flexShrink: 0,
			color: theme.other.getColor('icon/primary/default'),
		},
		rightIcon: {
			color: theme.other.getColor('icon/secondary/default'),
		},
		grow: {
			flexGrow: 1,
		},
		title: {
			color: theme.other.getColor('text/primary/default'),
			fontWeight: theme.other.typography.weight.semibold,
			fontSize: theme.other.typography.text.sm,
			lineHeight: theme.other.typography.lineHeight.text.sm,
		},
		breadcrumb: {
			color: theme.other.getColor('text/secondary/default'),
			fontSize: theme.other.typography.text.xs,
			lineHeight: theme.other.typography.lineHeight.text.xs,
		},
	})
);

function SearchResultItem({ action, selected, onHover }: IActionItemProps) {
	const { classes } = useStyles({ selected });
	const { title, entity, onClick } = action;

	const entityId = entity?.id;

	const { data: integrations } = useIntegrationList({
		options: {
			select: (res) => res.results,
		},
	});

	const { data: nestedBreadcrumbs } = useNestedBreadcrumbsList({
		filters: {
			id: entityId!,
		},
		options: {
			enabled: !isNil(entityId),
			select: (res: IApiListResponse<INestedBreadcrumbs>) =>
				reverse(res.results || []),
		},
	});

	if (!entity) {
		return null;
	}

	const url = buildResourceUrl(entity);
	const breadcrumb = getBreadcrumbForEntity({
		entity,
		preferredTeamId: null,
		nativeType: '',
		teams: [],
		nestedBreadcrumbs,
		integrations,
		includeTeam: false,
	});

	const breadcrumbText = compact(map(breadcrumb, 'title')).join(' / ');

	return (
		<UnstyledButton
			className={classes.wrapper}
			component={Link}
			to={url}
			onClick={onClick}
			onMouseOver={onHover}
		>
			<Group position="apart" noWrap>
				<Group spacing="xs" noWrap align="flex-start">
					<Box className={classes.icon}>
						<SecodaEntityIcon entity={entity} size={20} />
					</Box>
					<Stack spacing={4} className={classes.grow}>
						<Text className={classes.title} lineClamp={1}>
							{title ?? 'Untitled'}
						</Text>
						{breadcrumbText ? (
							<Text className={classes.breadcrumb} lineClamp={1}>
								{breadcrumbText}
							</Text>
						) : (
							<Space h="xs" />
						)}
					</Stack>
				</Group>
				{selected && (
					<Box className={classes.rightIcon}>
						<Icon name="arrowBack" />
					</Box>
				)}
			</Group>
		</UnstyledButton>
	);
}

export default memo(
	SearchResultItem,
	(prev, next) =>
		prev.action.id === next.action.id && prev.selected === next.selected
);
