import type { Filter } from '@repo/api-codegen';
import { integrationList } from '@repo/common/constants/integration/integrations';
import { EntityType } from '@repo/common/enums/entityType';
import { find } from 'lodash-es';
import type React from 'react';
import type { To } from 'react-router-dom';
import type { ApiCatalogSort, IIntegration, ISecodaEntity } from '../../../api';
import { fetchCatalogList } from '../../../api/hooks/resourceCatalog';
import type { Integration } from '../../../lib/models';
import { buildResourceUrl } from '../../../utils/navigationUtils';
import type { TreeNodeBase } from '../components/TreeNode';

export type NewCatalogTreeNode = TreeNodeBase &
	(
		| {
				type: 'integration';
				integration: IIntegration;
				teamId: string;
		  }
		| {
				type: 'entity';
				entity: ISecodaEntity;
				integration?: IIntegration;
				teamId: string;
		  }
	);

export function catalogFilterFromCatalogTreeNode(
	node: NewCatalogTreeNode,
	customTeamSchemaMapping: boolean
): Filter {
	if (node.type === 'integration') {
		const entityTypes = [
			EntityType.database,
			EntityType.schema,
			EntityType.dashboard_group,
			EntityType.category,
		];

		if (node.integration.type !== 'powerbi') {
			entityTypes.push(EntityType.job, EntityType.job_group);
		}

		return {
			operator: 'and',
			operands: [
				{
					operands: [],
					operator: 'not',
					field: 'parent_id',
				},
				{
					operands: [],
					field: 'integration_id',
					operator: 'exact',
					value: node.integration.id,
				},
				{
					operands: [],
					field: 'entity_type',
					operator: 'in',
					value: entityTypes,
				},
			],
		};
	}

	const query: Filter = {
		operator: 'and',
		operands: [
			{
				operands: [],
				field: 'parent_id',
				operator: 'exact',
				value: node.entity.id,
			},
			{
				operator: 'not',
				operands: [
					{
						operands: [],
						field: 'native_type',
						operator: 'exact',
						value: 'embedded_datasource',
					},
				],
			},
		],
	};

	if (customTeamSchemaMapping) {
		query.operands.push({
			operator: 'or',
			operands: [
				{
					operator: 'not',
					operands: [
						{
							operator: 'is_set',
							operands: [],
							field: 'teams',
						},
					],
				},
				{
					operator: 'in',
					field: 'teams',
					value: [node.teamId],
					operands: [],
				},
				// Temporary fix until we have an inlined permission_teams field on es.
				// https://linear.app/secoda/issue/ENG-11192/source-of-truth-for-teams-browsing
				// TODO: remove once eng-11192 is merged in
				{
					operator: 'in',
					field: 'teams',
					value: ['None'],
					operands: [],
				},
				// Temporary fix until we have an inlined permission_teams field on es.
				// https://linear.app/secoda/issue/ENG-11192/source-of-truth-for-teams-browsing
				// TODO: remove once eng-11192 is merged in
				{
					operator: 'not',
					operands: [
						{
							operator: 'in',
							field: 'entity_type',
							operands: [],
							value: [
								EntityType.database,
								EntityType.schema,
								EntityType.job_group,
								EntityType.dashboard_group,
							],
						},
					],
				},
			],
		});
	}

	return query;
}

export function catalogFilterQueryParamsFromCatalogTreeNode(
	node: NewCatalogTreeNode
): Record<string, unknown> {
	if (node.type === 'integration') {
		return {
			integration: [node.integration.id],
		};
	}

	return {
		parent: [node.entity.id],
	};
}

export const SORT_OPTIONS: ApiCatalogSort = {
	field: 'native_type',
	order: 'asc',
	tie_breaker: {
		field: 'title',
		order: 'asc',
	},
};

export const toCatalogTreeNode = ({
	entity,
	teamId,
	integration,
}: {
	entity: ISecodaEntity;
	teamId: string;
	integration?: IIntegration;
}): NewCatalogTreeNode => ({
	type: 'entity',
	entity,
	hasChildren:
		entity.entity_type !== EntityType.job && entity.children_count > 0,
	id: entity.id,
	integration,
	teamId,
});

export async function getCatalogNodeChildrenNew(
	node: NewCatalogTreeNode,
	customTeamSchemaMapping: boolean,
	page: number = 1
): Promise<{ results: NewCatalogTreeNode[]; nextPage?: number | null }> {
	const result = await fetchCatalogList({
		filters: {
			team_id: node.teamId,
			filter: catalogFilterFromCatalogTreeNode(
				node,
				customTeamSchemaMapping
			) as Filter,
			prefetch_children_count: true,
			sort: SORT_OPTIONS,
			page_size: 100,
		},
		page,
	});

	const treeNodes: NewCatalogTreeNode[] = result.results.map((entity) =>
		toCatalogTreeNode({
			entity,
			teamId: node.teamId,
			integration: node.integration,
		})
	);

	return {
		results: treeNodes,
		nextPage: result.meta.next_page,
	};
}

export function handleNavigateCatalogNodeNew(
	event: React.MouseEvent,
	node: NewCatalogTreeNode,
	navigateHandler: (event: React.MouseEvent) => (to: To) => void
) {
	const catalogFilterQueryParams =
		catalogFilterQueryParamsFromCatalogTreeNode(node);
	const catalogPageURL = `/teams/${
		node.teamId
	}/catalog?filters=${encodeURIComponent(
		JSON.stringify(catalogFilterQueryParams)
	)}`;

	if (node.type === 'entity') {
		const resourceURL = buildResourceUrl(node.entity);
		if (resourceURL !== '#') {
			navigateHandler(event)(resourceURL);
			return;
		}
	}

	navigateHandler(event)(catalogPageURL);
}

export const isIntegrationRootNode = (
	integration: Integration | IIntegration
) => {
	const type = find(
		integrationList,
		(i) => i.type === integration.type
	)?.entity_type;

	if (!type) {
		return false;
	}
	if (['dbt_core', 'git'].includes(integration.type)) {
		return false;
	}
	return [
		EntityType.table,
		EntityType.dashboard,
		EntityType.job,
		EntityType.event,
	].includes(type);
};
