import type { UseQueryOptions } from '@tanstack/react-query';
import { useMutation, useQuery } from '@tanstack/react-query';
import { apiClient, getEndpoints } from '../../common';
import type { IIntegratinApiSpec } from '../../types/models/integrationApiSpec';

const INSPECT_API_NAMESPACE = ['admin', 'inspect_api'];

export function useIntegrationApiSpec(
	integrationId: string,
	options?: UseQueryOptions<IIntegratinApiSpec>
) {
	const queryKey = [...INSPECT_API_NAMESPACE, integrationId];
	const queryFn = async () => {
		const url = getEndpoints(INSPECT_API_NAMESPACE).byPath([integrationId]);
		const { data } = await apiClient.get<IIntegratinApiSpec>(url, {});

		return data;
	};

	return useQuery<IIntegratinApiSpec>(queryKey, queryFn, {
		...options,
		retry: false,
	});
}

export function useCallIntegrationApiMethod() {
	const mutationFn = async ({
		integrationId,
		method,
		payload,
		showOriginal,
	}: {
		integrationId: string;
		method: string;
		payload: Record<string, unknown>;
		showOriginal: boolean;
	}) => {
		const url = getEndpoints(INSPECT_API_NAMESPACE).byPath([
			integrationId,
			method,
		]);
		const { data } = await apiClient.post(url, payload, {
			params: {
				show_original: showOriginal,
			},
		});

		return data;
	};

	return useMutation({ mutationFn });
}
