import type { Node } from 'reactflow';
import type {
	EntityNodeChildren,
	EntityNodeData,
	LineageSearchResultItem,
	TemporaryNodeData,
} from '../types';
import { NodeType } from '../types';

export const getLineageSearchItems = (
	nodes: Node<EntityNodeData | TemporaryNodeData>[],
	nodeColumnsMap: Map<string, EntityNodeChildren[]>
): Map<string, LineageSearchResultItem> => {
	const resultMap = new Map<string, LineageSearchResultItem>();

	nodes.forEach((node) => {
		if (node.type === NodeType.ENTITY) {
			const data = node.data as EntityNodeData;

			const mainItem: LineageSearchResultItem = {
				id: data.id,
				nodeId: node.id,
				title: data.title,
				types: data.types,
				integration: {
					id: data.integration.id,
					type: data.integration.type,
				},
				metadata: data.metadata,
				isSelectable: true,
			};

			resultMap.set(mainItem.id, mainItem);

			const nodeColumns = nodeColumnsMap.get(data.id) || [];

			nodeColumns.forEach((column) => {
				const columnItem: LineageSearchResultItem = {
					id: column.id,
					nodeId: node.id,
					title: column.title,
					types: {
						entity: column.types.entity,
						native: column.types.native,
					},
					integration: {
						id: data.integration.id,
						type: data.integration.type,
					},
					metadata: column.metadata,
					isSelectable: true,
				};

				resultMap.set(columnItem.id, columnItem);
			});
		}
	});

	return resultMap;
};
