import { Group, createStyles } from '@mantine/core';
import { IconButton } from '@repo/foundations';
import { colors } from '@repo/theme/primitives';
import { useMemo } from 'react';
import { type Automation } from '../../../../../../api';

import type { AutomationReceiver } from '../../../../../../api/types/models/automation/automationReceiver';
import { useAutomationStore } from '../../../../../../pages/AutomationPage/context';
import { useAnnouncementGlobalRecipientsList } from '../../../../../Announcement/AnnouncementGlobalRecipients.helpers';
import { AutomationActionCardType } from '../../../../constants';
import type { MultiSelectedItem } from '../../../AutomationCardButton';
import AutomationCardButton from '../../../AutomationCardButton';
import { AutomationValueType } from '../../../constants';

const useStyles = createStyles((theme) => ({
	root: {
		justifyContent: 'space-between',
		width: '100%',
	},
	group: {
		alignSelf: 'stretch',
		background: colors.gray[1],
		width: '100%',
		gap: theme.spacing['2xs'],
	},
}));

interface AutomationActionCardSlackHeaderTextProps {
	cardId: string;
	handleAutomationUpdate: (automation: Partial<Automation>) => Promise<void>;
}

function AutomationActionCardSlackHeaderText({
	cardId,
	handleAutomationUpdate,
}: AutomationActionCardSlackHeaderTextProps) {
	const { classes } = useStyles();
	const { options } = useAnnouncementGlobalRecipientsList();
	const buttonOptions = options.filter((option) => option.type === 'channel');
	const automationStore = useAutomationStore();
	const { automation } = automationStore;

	const selectedValues = useMemo(
		() =>
			automation?.action_send_slack_message?.channels?.map(
				(channel) => channel.id
			) ?? [],
		[automation]
	);

	const handleOnClick = async (items: MultiSelectedItem[]) => {
		const channels: AutomationReceiver[] = [];

		items.forEach((item) => {
			if (item.type === 'channel' && typeof item.value === 'string') {
				channels.push({
					id: item.value,
					display: item.label,
				});
			}
		});

		await handleAutomationUpdate({
			action_send_slack_message: {
				...automation?.action_send_slack_message,
				channels,
			},
		});
	};

	const handleDelete = async () => {
		await handleAutomationUpdate?.({
			action_send_slack_message: null,
		});

		automationStore.removeCard(
			AutomationActionCardType.SEND_SLACK_MESSAGE,
			cardId
		);
	};

	return (
		<Group className={classes.root} noWrap>
			<Group className={classes.group} noWrap>
				Send slack messages to
				<AutomationCardButton
					buttonLabel="channels"
					buttonOptions={buttonOptions}
					type={AutomationValueType.MULTI_SELECT_DROPDOWN}
					handleMultiSelectorChange={handleOnClick}
					property="channels"
					placeholder="Select channels"
					selectedValues={selectedValues}
				/>
			</Group>
			<IconButton variant="tertiary" onClick={handleDelete} iconName="x" />
		</Group>
	);
}

export default AutomationActionCardSlackHeaderText;
