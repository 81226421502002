import type { MantineThemeOverride } from '@mantine/core';
import { isNil, omitBy } from 'lodash-es';

// NOTE: Avoid using this `Button` from Mantine.

export const Button: MantineThemeOverride['components'] = {
	Button: {
		defaultProps: () => ({
			loaderProps: {
				size: 'xs',
			},
		}),
		styles: (theme, params, context) => {
			let padding = '6px 12px';
			let radius = theme.radius.sm;
			let fontSize: string | undefined = theme.other.typography.text.sm;

			let lineHeight: string = theme.other.typography.lineHeight.text.sm;
			let height = 32;
			if (context.size === 'sm' || context.size === 'xs') {
				padding = `4px ${theme.spacing.xs}`;
				radius = theme.radius.xs;
				lineHeight = theme.other.typography.lineHeight.text.sm;
				height = 28;
			}

			if (context.size === 'xs') {
				fontSize = theme.fontSizes.xs;
			}

			const root = omitBy(
				{
					padding,
					borderRadius: radius,
					fontSize,
					fontWeight: theme.other.typography.weight.semibold,
					lineHeight: `${lineHeight}px`,
					height: `${height}px`,
				},
				isNil
			);

			return {
				root,
			};
		},
		variants: {
			filled: (theme) => ({
				root: {
					backgroundColor: theme.other.getColor('fill/primary/default'),
					borderColor: theme.other.getColor('border/primary/default'),
					color: theme.other.getColor('text/primary/default'),
					'&:hover': {
						backgroundColor: theme.other.getColor('fill/primary/hover'),
					},
					'&:active': {
						backgroundColor: theme.other.getColor('fill/primary/active'),
					},
				},
			}),
			subtle: (theme) => ({
				root: {
					borderColor: 'transparent',
					color: theme.colors.gray[6],
					'&:hover': {
						backgroundColor:
							theme.colorScheme === 'dark'
								? theme.colors.gray[8]
								: theme.colors.gray[1],
					},
					'&:focus': {
						backgroundColor:
							theme.colorScheme === 'dark'
								? theme.colors.gray[8]
								: theme.colors.gray[2],
					},
				},
			}),
			default: (theme) => ({
				root: {
					borderColor:
						theme.colorScheme === 'dark'
							? theme.colors.gray[8]
							: theme.colors.gray[3],
					backgroundColor:
						theme.colorScheme === 'dark' ? theme.colors.dark[9] : 'white',
					'&:hover': {
						backgroundColor:
							theme.colorScheme === 'dark'
								? theme.colors.gray[8]
								: theme.colors.gray[1],
					},
					'&:focus': {
						backgroundColor:
							theme.colorScheme === 'dark'
								? theme.colors.gray[8]
								: theme.colors.gray[2],
					},
				},
			}),
			primary: (theme) => ({
				root: {
					backgroundColor: theme.other.getColor('fill/inverse/default'),
					color: theme.other.getColor('text/brand-on-fill/default'),
					borderColor: 'transparent',
					'&:hover': {
						backgroundColor: theme.other.getColor('fill/inverse/hover'),
					},
					'&:active': {
						backgroundColor: theme.other.getColor('fill/inverse/active'),
					},
				},
			}),
			critical: (theme) => ({
				root: {
					backgroundColor: theme.other.getColor('fill/critical/default'),
					color: theme.other.getColor('text/critical-on-fill/default'),
					'&:hover': {
						backgroundColor: theme.other.getColor('fill/critical/hover'),
					},
					'&:active': {
						backgroundColor: theme.other.getColor('fill/critical/active'),
					},
				},
			}),
		},
	},
};
