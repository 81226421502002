import { Stack } from '@mantine/core';
import type { ScheduleConfig } from '../../api/types/models/schedule';
import { ScheduleCadence } from '../../api/types/models/schedule';
import { isValidEnumValue } from '../../utils/enumUtils';
import { SelectableProperty } from '../EntityPageLayout/EntityPropertySidebar';
import { DAY_OPTIONS, HOUR_OPTIONS } from './constants';
import { SCHEDULE_CADENCE_OPTIONS, SCHEDULE_HOURLY_OPTIONS } from './schedule';

interface ScheduleSelectorProps {
	schedule: ScheduleConfig;
	onChange: (value: ScheduleConfig) => void;
	disableWeekly: boolean;
	readOnly?: boolean;
}

type DropdownValue = string | number | boolean | string[];

function ScheduleSelector({
	schedule,
	onChange,
	readOnly = false,
	disableWeekly = false,
}: ScheduleSelectorProps) {
	const { cadence, hour, day, frequency } = schedule;

	const shouldDisableWeekly =
		disableWeekly && cadence !== ScheduleCadence.WEEKLY;

	const handleCadenceChange = (value: DropdownValue) => {
		if (typeof value !== 'string') {
			return;
		}
		if (isValidEnumValue(ScheduleCadence, value)) {
			if (shouldDisableWeekly && value === ScheduleCadence.WEEKLY) {
				return;
			}

			onChange({
				...schedule,
				cadence: value,
			});
		}
	};

	const handleFrequencyChange = (value: DropdownValue) => {
		if (typeof value !== 'string') {
			return;
		}
		onChange({
			...schedule,
			frequency: Number(value),
		});
	};

	const handleDayChange = (value: DropdownValue) => {
		if (typeof value !== 'string') {
			return;
		}
		onChange({
			...schedule,
			day: Number(value),
		});
	};

	const handleHourChange = (value: DropdownValue) => {
		if (typeof value !== 'string') {
			return;
		}
		onChange({
			...schedule,
			hour: Number(value),
		});
	};

	const cadenceOptions = SCHEDULE_CADENCE_OPTIONS.filter(
		(option) =>
			!(shouldDisableWeekly && option.label === ScheduleCadence.WEEKLY)
	);

	return (
		<Stack spacing="xs">
			<SelectableProperty
				selected={cadence ?? ''}
				type="single"
				label="Repeats"
				value="repeats"
				iconType="tabler"
				isViewerUser={false}
				options={cadenceOptions}
				onChange={handleCadenceChange}
				readOnly={readOnly}
			/>
			{cadence === ScheduleCadence.HOURLY && (
				<SelectableProperty
					selected={frequency?.toString() ?? ''}
					type="single"
					label="Frequency"
					value="Frequency"
					iconType="tabler"
					isViewerUser={false}
					options={SCHEDULE_HOURLY_OPTIONS}
					onChange={handleFrequencyChange}
					readOnly={readOnly}
				/>
			)}
			{cadence === ScheduleCadence.WEEKLY && (
				<SelectableProperty
					selected={day?.toString() ?? ''}
					type="single"
					label="Day"
					value="Day"
					iconType="tabler"
					isViewerUser={false}
					options={DAY_OPTIONS}
					onChange={handleDayChange}
					readOnly={readOnly}
				/>
			)}
			{[ScheduleCadence.DAILY, ScheduleCadence.WEEKLY].includes(cadence) && (
				<SelectableProperty
					selected={hour?.toString() ?? ''}
					type="single"
					label="Time"
					value="Time"
					iconType="tabler"
					isViewerUser={false}
					options={HOUR_OPTIONS}
					onChange={handleHourChange}
					readOnly={readOnly}
				/>
			)}
		</Stack>
	);
}

export default ScheduleSelector;
