import type { SecodaEditorComponentProps } from '@repo/secoda-editor';
import type { Node as ProsemirrorNode } from 'prosemirror-model';
import type { Decoration, EditorView } from 'prosemirror-view';
import type { FunctionComponent } from 'react';
import type { RichMarkdownEditor as Editor } from '..';
import { NodeViewRenderer } from './NodeViewRenderer';

interface ComponentViewProps {
	component: FunctionComponent<SecodaEditorComponentProps>;
	editor: Editor;
	node: ProsemirrorNode;
	view: EditorView;
	getPos: () => number;
	decorations: Decoration[];
}

export default class ComponentView {
	component: FunctionComponent<SecodaEditorComponentProps>;

	editor: Editor;

	node: ProsemirrorNode;

	view: EditorView;

	getPos: () => number;

	decorations: Decoration[];

	renderer: NodeViewRenderer<SecodaEditorComponentProps>;

	isSelected = false;

	dom: HTMLElement | null;

	constructor({
		component,
		editor,
		node,
		view,
		getPos,
		decorations,
	}: ComponentViewProps) {
		this.component = component;
		this.editor = editor;
		this.getPos = getPos;
		this.decorations = decorations;
		this.node = node;
		this.view = view;
		this.dom = node.type.spec.inline
			? document.createElement('span')
			: document.createElement('div');
		this.renderer = new NodeViewRenderer(this.dom, this.component, this.props);

		this.editor.renderers.add(this.renderer);
	}

	update(node: ProsemirrorNode) {
		if (node.type !== this.node.type) {
			return false;
		}

		this.node = node;
		this.renderer.updateProps(this.props);
		return true;
	}

	selectNode() {
		if (this.view.editable) {
			this.isSelected = true;
			this.renderer.updateProps(this.props);
		}
	}

	deselectNode() {
		if (this.view.editable) {
			this.isSelected = false;
			this.renderer.updateProps(this.props);
		}
	}

	stopEvent() {
		return true;
	}

	destroy() {
		this.editor.renderers.delete(this.renderer);
		this.dom = null;
	}

	ignoreMutation() {
		return true;
	}

	get props() {
		return {
			node: this.node,
			view: this.view,
			isSelected: this.isSelected,
			isEditable: this.view.editable,
			getPos: this.getPos,
		} as SecodaEditorComponentProps;
	}
}
