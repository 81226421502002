import { Group, Stack, Table, useMantineTheme } from '@mantine/core';
import { Button, Text, Title } from '@repo/foundations';
import { capitalize } from 'lodash-es';
import moment from 'moment';
import { useNavigate } from 'react-router';
import type { IApiListResponse, IBaseModelListArgs, ITunnel } from '../../api';
import {
	queryClient,
	tunnelsQueryKeyFactory,
	useAuthUser,
	useTunnelList,
	useUpdateTunnel,
} from '../../api';
import { closeAllModals, openModal } from '../../components/ModalManager';
import DeleteWithWarningModal from '../IntegrationsPage/DeleteWithWarningModal';

function TunnelsTable({
	columns = ['host', 'port', 'username'],
	onCreate,
	description,
	prefix,
	args,
}: {
	columns?: Partial<keyof ITunnel>[];
	onCreate?: () => void;
	description: string;
	prefix: string;
	args: Partial<IBaseModelListArgs<IApiListResponse<ITunnel>>>;
}) {
	const theme = useMantineTheme();
	const navigate = useNavigate();

	const handleNavigateToNewTunnel = () => {
		navigate('/tunnels/new');
	};

	const { isEditorOrAdminUser } = useAuthUser();
	const { data: tunnels } = useTunnelList(args);

	const { mutateAsync: updateTunnel } = useUpdateTunnel({});

	const handleDelete = (id: string) => {
		openModal({
			variant: 'default',
			title: 'Delete tunnel',
			children: (
				<DeleteWithWarningModal
					text="This will permanently remove this tunnel, and remove it from any integrations."
					onConfirm={async () => {
						// We do not delete, but set `archived` to `true`.
						// This is because we need to make sure the reverse tunnel ports are not reused.
						await updateTunnel({
							data: {
								id,
								archived: true,
							},
						});
						closeAllModals();
						queryClient.invalidateQueries(
							tunnelsQueryKeyFactory.list(1, args.filters)
						);
					}}
					onClose={closeAllModals}
				/>
			),
		});
	};

	return (
		<Stack spacing={theme.spacing.xl} p={theme.other.space[8]}>
			<Group
				align="center"
				sx={{ justifyContent: 'space-between' }}
				noWrap
				spacing={theme.spacing.xl}
			>
				<Stack spacing={theme.spacing.xs}>
					<Title weight="semibold" order={6}>
						{prefix} tunnels
					</Title>
					<Text size="xs">{description}</Text>
				</Stack>
				{isEditorOrAdminUser && (
					<Button onClick={onCreate ?? handleNavigateToNewTunnel}>
						Add {prefix?.toLowerCase()} tunnel
					</Button>
				)}
			</Group>
			<Table>
				<thead
					style={{
						backgroundColor: theme.other.getColor('fill/secondary/default'),
					}}
				>
					<tr>
						{columns.map((column) => (
							<th key={column}>
								<Text size="sm" p={theme.spacing.xs}>
									{capitalize(column)}
								</Text>
							</th>
						))}

						<th>
							<Text size="sm" p={theme.spacing.xs}>
								Status
							</Text>
						</th>
						<th>
							<Text size="sm" p={theme.spacing.xs}>
								Created at
							</Text>
						</th>
						<th style={{ borderTopLeftRadius: '4px' }}>
							<Text size="sm" p={theme.spacing.xs}>
								Actions
							</Text>
						</th>
					</tr>
				</thead>
				<tbody>
					{tunnels?.results.map((tunnel: ITunnel) => (
						<tr key={tunnel.id}>
							{columns.map((column) => (
								<td key={column}>
									<Text size="sm" p={theme.spacing.xs}>
										{tunnel?.[column]}
									</Text>
								</td>
							))}
							<td>
								<Text size="sm" p={theme.spacing.xs}>
									{tunnel.status ? 'Connected' : 'Disconnected'}
								</Text>
							</td>
							<td>
								<Text size="sm" p={theme.spacing.xs}>
									{moment(tunnel.created_at).format('YYYY-MM-DD')}
								</Text>
							</td>
							<td>
								<Button
									onClick={() => handleDelete(tunnel.id)}
									variant="tertiary"
								>
									Delete
								</Button>
							</td>
						</tr>
					))}
				</tbody>
			</Table>
		</Stack>
	);
}

export default TunnelsTable;
