import utf8 from 'utf8';
// @ts-expect-error TS(7016): Could not find a declaration file for module 'quot... Remove this comment to see the full error message
import quotedPrintable from 'quoted-printable';
import turndownService from './turndown';
import { arrayBufferToString } from './utils';

export function confluenceToMarkdown(input: ArrayBuffer | string) {
	let value = input;

	if (value instanceof ArrayBuffer) {
		value = arrayBufferToString(value);
	}

	// We're only supporting the ridiculous output from Confluence here, regular
	// Word documents should call into the docxToMarkdown importer.
	// See: https://jira.atlassian.com/browse/CONFSERVER-38237
	if (!value.includes('Content-Type: multipart/related')) {
		throw new Error('Unsupported Word file');
	}

	// Get boundary marker.
	const boundaryMarker = value.match(/boundary="(.+)"/);

	if (!boundaryMarker) {
		throw new Error('Unsupported Word file (No boundary marker)');
	}

	// Get content between multipart boundaries.
	let boundaryReached = 0;
	const lines = value.split('\n').filter((line) => {
		if (line.includes(boundaryMarker[1])) {
			boundaryReached += 1;
			return false;
		}

		if (line.startsWith('Content-')) {
			return false;
		}

		// 1 == definition
		// 2 == content
		// 3 == ending
		if (boundaryReached === 2) {
			return true;
		}

		return false;
	});

	if (!lines.length) {
		throw new Error('Unsupported Word file (No content found)');
	}

	// Mime attachment is "quoted printable" encoded, must be decoded first
	// https://en.wikipedia.org/wiki/Quoted-printable
	value = utf8.decode(quotedPrintable.decode(lines.join('\n')));

	// Remove all table of contents elements.
	value = value.replaceAll(/<div class="toc-macro[\s\S]*?<\/div>/g, '');

	// If we don't remove the title here it becomes printed in the document body by turndown.
	turndownService.remove(['style', 'title']);

	// Now we should have something that looks like HTML.
	const markdown = turndownService.turndown(value);

	return markdown.replace(/<br>/g, '\\n');
}
