import { uuidv4 } from 'lib0/random';
import { makeAutoObservable } from 'mobx';
import type {
	AutomationAction,
	AutomationActionType,
} from '../../../../../api';

interface AutomationCardAction extends Partial<AutomationAction> {
	searchKey: string;
	type?: AutomationActionType;
	value?: string;
	display?: string;
}

export class AutomationActionCardEditStore {
	actions: AutomationCardAction[] = [];

	constructor() {
		makeAutoObservable(this);
	}

	addAction = ({
		searchKey,
		type,
		value,
		display,
	}: Partial<AutomationCardAction>) => {
		this.actions.push({
			searchKey: searchKey ?? uuidv4(),
			type,
			value,
			display,
		});
	};

	setActionType = (key: string, type: AutomationActionType) => {
		const existingAction = this.actions.find(
			(action) => action.searchKey === key
		);

		if (existingAction) {
			existingAction.type = type;

			// Reset value if type is changed
			existingAction.value = undefined;
			existingAction.display = undefined;
		}
	};

	setActionValue = (key: string, value: string, display?: string) => {
		const existingActions = this.actions.find(
			(action) => action.searchKey === key
		);

		if (existingActions) {
			existingActions.value = value;
			existingActions.display = display;
		}
	};

	resetAction = () => {
		this.actions = [];
	};

	deleteAction = (key: string) => {
		const newActions = this.actions.filter(
			(action) => action.searchKey !== key
		);

		this.actions = newActions;
	};
}
