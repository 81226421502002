import {
	Box,
	Divider,
	Flex,
	Group,
	Input,
	Space,
	Stack,
	TextInput,
	useMantineTheme,
} from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { Button, Switch, Text, Title } from '@repo/foundations';
import { useFormik } from 'formik';
import { debounce, isEmpty } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import {
	useCallback,
	useEffect,
	useRef,
	useState,
	type ChangeEventHandler,
	type ReactNode,
} from 'react';
import { useAuthUser } from '../../api';
import { usePublicEnv } from '../../api/hooks/utils/usePublicEnv';
import { useUpdateWorkspace } from '../../api/hooks/workspace/useUpdateWorkspace';
import { useWorkspaceImage } from '../../api/hooks/workspace/useWorkspaceImage';
import { ImageUpload } from '../ImageUpload/ImageUpload';
import { openModal } from '../ModalManager';
import { workspaceSelectorStore } from '../SecodaAppShell/SideBar/WorkspaceSelector/store';
import { UpgradeButton } from './UpgradeButton';

interface IWorkspaceInitialValues {
	name: string;
	allowed_domains: string[];
	logo?: File;
}

export function Section(props: {
	title: string;
	description?: string;
	children?: ReactNode;
}) {
	const { title, description, children } = props;
	return (
		<Stack spacing="lg" mb="45px">
			<Title order={2} size="lg">
				{title}
			</Title>
			{description && <Text size="sm">{description}</Text>}
			{children}
		</Stack>
	);
}

function WorkspaceForm() {
	const { workspace } = useAuthUser();
	const { mutateAsync } = useUpdateWorkspace(workspace.id);
	const { mutateAsync: uploadImage } = useWorkspaceImage();

	const formik = useFormik<IWorkspaceInitialValues>({
		initialValues: {
			name: workspace.name,
			allowed_domains: workspace.allowed_domains,
		},
		onSubmit: async (values) => {
			debounceFn(values);
		},
	});

	const debounceFn = useCallback(
		debounce((values: IWorkspaceInitialValues) => {
			mutateAsync({
				data: {
					id: workspace.id,
					name: values.name,
					allowed_domains: values.allowed_domains,
				},
			}).then(async () => {
				if (values.logo) {
					const response = await uploadImage({
						file: values.logo,
						workspaceId: workspace.id,
					});
					// update Sidebar's logo
					if (workspaceSelectorStore.activeWorkspace) {
						workspaceSelectorStore.activeWorkspace.logo = (
							await response
						)?.logo;
					}
				}
				showNotification({
					title: 'Workspace updated',
					message: 'Your workspace has been updated.',
					color: 'green',
					autoClose: 1500,
				});
			});
		}, 700),
		[workspace.logo]
	);

	const onUpload = useCallback(
		(file: File) => {
			formik.setFieldValue('logo', file);
			formik.handleSubmit();
		},
		[formik]
	);

	useEffect(() => {
		formik.setValues({
			name: workspace.name,
			allowed_domains: workspace.allowed_domains,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [workspace.name]);

	return (
		<Box>
			<form
				onChange={formik.handleSubmit}
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: '10px',
					marginTop: '25px',
				}}
			>
				<Box>
					<ImageUpload
						callback={onUpload}
						label="Workspace logo"
						placeholder={
							isEmpty(workspace.logo)
								? '/images/auth-image/workspace-placeholder.webp'
								: workspace.logo
						}
					/>
				</Box>
				<TextInput
					onChange={formik.handleChange}
					value={formik.values.name}
					mt="10px"
					label="Workspace name"
					name="name"
				/>
			</form>
		</Box>
	);
}

export function SettingsSwitch(props: {
	my?: string;
	title: string;
	description: ReactNode;
	onChange: ChangeEventHandler<HTMLInputElement>;
	checked: boolean;
	disabled?: boolean;
	upgradeRequired?: boolean;
	// @ts-expect-error TS(7008): Member 'footer' implicitly has an 'any' type.
	footer?;
}) {
	const {
		my,
		upgradeRequired,
		title,
		description,
		disabled,
		onChange,
		checked,
		footer,
	} = props;

	return (
		<Flex
			styles={{ minWidth: '700px' }}
			my={my ?? '25px'}
			justify="space-between"
			align="center"
			gap="lg"
		>
			<Flex direction="column" gap="6px">
				<Title order={3} weight="semibold" size="md">
					{title}
				</Title>
				<Text size="sm">{description}</Text>
				{footer}
			</Flex>
			{!upgradeRequired ? (
				<Switch onChange={onChange} checked={checked} disabled={disabled} />
			) : (
				<UpgradeButton feature={title} />
			)}
		</Flex>
	);
}

interface IWorkspaceNameCheckModalProps {
	callback: () => void;
	workspace: { name: string };
}

function WorkspaceNameCheckModal({
	callback,
	workspace,
}: IWorkspaceNameCheckModalProps) {
	const theme = useMantineTheme();

	const ref = useRef<HTMLInputElement>(null);
	const [error, setError] = useState<string | null>(null);

	const handleCallback = () => {
		if (ref.current?.value !== workspace.name) {
			setError('The workspace name does not match');
			return;
		}
		callback();
	};

	return (
		<Box>
			<Text>Enter the current workspace name:</Text>
			<Input error={error} ref={ref} placeholder={workspace.name} />
			<Space my={theme.spacing.md} />
			<Button
				data-testid="delete-workspace-modal"
				variant="primary"
				tone="critical"
				onClick={handleCallback}
			>
				Delete workspace
			</Button>
		</Box>
	);
}

export const WorkspaceSettings = observer(() => {
	const { isAdminUser, workspace } = useAuthUser();
	const { data: publicEnv } = usePublicEnv();

	const { mutateAsync } = useUpdateWorkspace(workspace.id);

	const handleDeleteWorkspaceModal = useCallback(() => {
		const handleDelete = async () => {
			await mutateAsync({
				data: {
					id: workspace.id,
					disabled: true,
					scheduled_for_deletion: true,
				},
			});
			window.location.reload();
		};

		openModal({
			title: 'Are you sure you want to permanently delete this workspace?',
			children: (
				<WorkspaceNameCheckModal
					callback={handleDelete}
					workspace={workspace}
				/>
			),
		});
	}, [mutateAsync, workspace]);

	return (
		<Box>
			<Section title="General">
				<WorkspaceForm />
				<Divider />
			</Section>
			<Section title="Secoda Version">
				<Box my="25px">{`${publicEnv?.TAG} (${publicEnv?.COMMIT_TAG})`}</Box>
				<Divider />
			</Section>
			{isAdminUser && (
				<Section title="Delete workspace">
					<>
						<Text color="text/secondary/default" size="sm" mb={16}>
							This action disables the current workspace and queues it for
							permanent deletion.
						</Text>
						<Group>
							<Button
								data-testid="delete-workspace-inline"
								onClick={handleDeleteWorkspaceModal}
								tone="critical"
							>
								Delete workspace
							</Button>
						</Group>
					</>
					<Divider />
				</Section>
			)}
		</Box>
	);
});
