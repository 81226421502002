import { Group, Loader } from '@mantine/core';
import {
	queryKeyFn,
	useGetExcludedFromQualityGovernance,
	useGetIncludedInQualityGovernance,
	useSetExcludedFromQualityGovernance,
	useSetIncludedInQualityGovernance,
} from '@repo/api-codegen';
import { Text } from '@repo/foundations';
import { queryClient } from '../../../api';
import { GovernanceFilters } from './GovernanceFilters';

export function QualityGovernanceFilters() {
	const { data: includedFilters, isLoading: isLoadingIncluded } =
		useGetIncludedInQualityGovernance({});
	const { mutateAsync: updateIncludedFilters } =
		useSetIncludedInQualityGovernance({
			onSuccess: (_, variables) => {
				// optimistic update
				queryClient.setQueryData(
					queryKeyFn({
						path: '/quality/preferences/governance/included/',
						operationId: 'getIncludedInQualityGovernance',
						variables: {},
					}),
					variables.body
				);

				// invalidate all other quality queries other than governance
				queryClient.invalidateQueries({
					predicate: (query) =>
						query.queryKey?.[0] === 'quality' &&
						!query.queryKey.includes('governance'),
				});
			},
		});
	const { data: excludedFilters, isLoading: isLoadingExcluded } =
		useGetExcludedFromQualityGovernance({});
	const { mutateAsync: updateExcludedFilters } =
		useSetExcludedFromQualityGovernance({
			onSuccess: (_, variables) => {
				// optimistic update
				queryClient.setQueryData(
					queryKeyFn({
						path: '/quality/preferences/governance/excluded/',
						operationId: 'getExcludedFromQualityGovernance',
						variables: {},
					}),
					variables.body
				);

				// invalidate all other quality queries other than governance
				queryClient.invalidateQueries({
					predicate: (query) =>
						query.queryKey?.[0] === 'quality' &&
						!query.queryKey.includes('governance'),
				});
			},
		});

	if (isLoadingIncluded || isLoadingExcluded) {
		return (
			<Group position="center">
				<Loader />
			</Group>
		);
	}

	return (
		<GovernanceFilters
			emptyState={
				<Text size="sm">
					Currently, the Quality Score will be computed to all tables in your
					workspace. To limit the resources, add filters below.
				</Text>
			}
			description="Create rules to include or exclude resources based on specific filters."
			includedFilters={includedFilters}
			excludedFilters={excludedFilters}
			onIncludedFiltersChange={(filters) =>
				updateIncludedFilters({ body: filters })
			}
			onExcludedFiltersChange={(filters) =>
				updateExcludedFilters({ body: filters })
			}
		/>
	);
}
