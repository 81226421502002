import { Divider, Group, Stack } from '@mantine/core';
import { Icon } from '@repo/foundations';
import { isNil } from 'lodash-es';
import type { INotification } from '../../../../../api/types/models/notification';
import { useStyles } from '../styles';

import Description from './Description';

interface IChangeRequestContentProps {
	selected: INotification;
}

function ChangeRequestContent({ selected }: IChangeRequestContentProps) {
	const { classes } = useStyles();

	if (isNil(selected.request) || isNil(selected.request.changes)) {
		return null;
	}

	return (
		<Stack spacing="lg">
			<Divider />
			{!selected.request.applied && (
				<Group spacing="xs" align="flex-start">
					<Group className={classes.leftCol} spacing="xs">
						<Icon name="alignLeft" />
						Current Description
					</Group>
					<Description description={selected.entity.description} />
				</Group>
			)}
			<Group spacing="xs" align="flex-start">
				<Group className={classes.leftCol} spacing="xs">
					<Icon name="alignLeft" />
					Updated Description
				</Group>
				<Description description={selected.request.changes.description} isNew />
			</Group>
		</Stack>
	);
}

export default ChangeRequestContent;
