// NOTE: ideally we use the `@repo/foundations` button in this context
// eslint-disable-next-line no-restricted-imports
import { Button } from '@mantine/core';
import { Icon } from '@repo/foundations';
import { useMemo } from 'react';
import { lineageStore } from '../../store';
import type { LineageBoolean, LineageCount } from '../../types';
import { LineageDirectionEnum } from '../../types';
import { getIndicatorMetadata } from '../../utils';
import { useStyles } from './LineageIndicator.styles';

interface ILineageIndicatorProps {
	id: string;
	count: LineageCount;
	fetched: LineageBoolean;
	collapsed: LineageBoolean;
	direction?: LineageDirectionEnum;
	indicatorDirection: LineageDirectionEnum;
}

function LineageIndicator({
	id,
	count,
	fetched,
	collapsed,
	direction,
	indicatorDirection,
}: ILineageIndicatorProps) {
	const { classes } = useStyles({
		direction,
	});

	const { indicatorCount, isIndicatorShown, isCollapsed } = useMemo(
		() => getIndicatorMetadata(count, fetched, collapsed, direction),
		[count, fetched, collapsed, direction]
	);

	if (!isIndicatorShown || indicatorDirection !== direction) {
		return null;
	}

	const ArrowIcon =
		indicatorDirection === LineageDirectionEnum.UPSTREAM
			? () => <Icon name="arrowLeft" />
			: () => <Icon name="arrowRight" />;

	const handleFetchAndCollapseLineage = () => {
		if (isCollapsed) {
			lineageStore.collapseLineage(id, direction);
		} else {
			lineageStore.fetchLineage(id, direction);
		}
	};

	return (
		<Button
			classNames={{
				root: classes.buttonRoot,
				rightIcon: classes.buttonRightIcon,
				label: classes.buttonLabel,
			}}
			rightIcon={<ArrowIcon />}
			onClick={handleFetchAndCollapseLineage}
		>
			{indicatorCount}
		</Button>
	);
}

export default LineageIndicator;
