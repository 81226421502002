import { Group, Stack, Tabs, useMantineTheme } from '@mantine/core';
import TabsList from '@repo/common/components/TabsList';
import { PlainButton, Text } from '@repo/foundations';
import { useState } from 'react';
import type { IIntegration } from '../../../../../api';
import type { IntegrationSpec } from '../../../../../interfaces/IntegrationSpec';
import {
	formatShortTimeDifference,
	isBeforeRightNow,
} from '../../../../../utils/time';
import { SyncTable } from './SyncTable';

interface SyncPanelProps {
	integration: IIntegration;
	spec: IntegrationSpec;
	navigateToSchedule: () => void;
}

export function SyncPanel({
	integration,
	spec,
	navigateToSchedule,
}: SyncPanelProps) {
	const theme = useMantineTheme();

	const [activeTab, setActiveTab] = useState<string | null>('PULL');

	const renderSubHeader = () => {
		const nextRun =
			activeTab === 'pull'
				? integration.scheduled_extractions_next_run
				: integration.scheduled_push_metadata_next_run;

		if (nextRun && !isBeforeRightNow(nextRun)) {
			return (
				<Group spacing={theme.spacing['2xs']} noWrap>
					<Text size="sm" weight="semibold">
						{`Next ${activeTab === 'pull' ? 'pull' : 'sync'} will run ${formatShortTimeDifference(nextRun, true)}`}
					</Text>
					<PlainButton onClick={navigateToSchedule}>
						Update schedule
					</PlainButton>
				</Group>
			);
		}

		return null;
	};

	const renderContent = () => {
		if (spec.value.disablePullMetadata) {
			return <SyncTable integration={integration} type="PUSH" />;
		} else if (spec.value.supportPushMetadata) {
			return (
				<Tabs
					color="dark"
					defaultValue="pull"
					value={activeTab}
					onTabChange={setActiveTab}
				>
					<TabsList
						tabs={[
							{ value: 'PULL', label: 'Pull' },
							{ value: 'PUSH', label: 'Push' },
						]}
					/>

					<Tabs.Panel value="PULL" pt="2xl">
						<SyncTable integration={integration} type="PULL" />
					</Tabs.Panel>

					<Tabs.Panel value="PUSH" pt="2xl">
						<SyncTable integration={integration} type="PUSH" />
					</Tabs.Panel>
				</Tabs>
			);
		}
		return <SyncTable integration={integration} type="PULL" />;
	};

	return (
		<Stack>
			{renderSubHeader()}
			{renderContent()}
		</Stack>
	);
}
