import { useEffect } from 'react';

function preventNavigation(e: BeforeUnloadEvent) {
	// new way
	e.preventDefault();

	// old way
	// eslint-disable-next-line no-param-reassign
	e.returnValue = 'Are you sure you want to navigate away?';
}

function disable() {
	// try/catch makes sure we can call this any time, even if enable hasn't been called yet
	try {
		window.removeEventListener('beforeunload', preventNavigation);
	} catch {}
}

function enable() {
	// makes sure we never have any dangling event listeners if called twice
	disable();
	window.addEventListener('beforeunload', preventNavigation);
}

export function usePreventNavigation() {
	// makes sure we remove the event listener when the component is unmounted
	useEffect(() => disable, []);

	return { enable, disable };
}
