import type { AxiosResponse } from 'axios';
import axios from 'axios';
import { api } from '../../network';

interface IS3UploadResponse {
	message: string;
	file_key: string;
}

export const uploadFileToS3 = (file: File, prefix: string) => {
	const formData = new FormData();
	formData.append('file_key', file);
	formData.append('prefix', prefix);

	return axios
		.post(`${api()}integration/manifest/`, formData)
		.then((response: AxiosResponse<IS3UploadResponse>) => {
			const { data } = response;
			return data.file_key;
		});
};
