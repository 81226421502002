import type { MantineThemeOverride } from '@mantine/core';
import { DEFAULT_THEME } from '@mantine/core';
import { SecodaTheme } from '@repo/theme/mantine/secodaTheme';

export const primaryTextWeight = 400;
export const primaryTextSize = SecodaTheme.fontSizes?.sm;
export const primaryTextColor = SecodaTheme.colors?.gray?.[9];
export const primaryColor = SecodaTheme.colors?.primary?.[6];
export const primaryContrastColor = SecodaTheme.colors?.yellow?.[6];
export const badgeBackgroundColor = SecodaTheme.colors?.gray?.[1];

export const theme: Partial<MantineThemeOverride> = {
	...SecodaTheme,
	fontFamily:
		"system-ui, -apple-system, BlinkMacSystemFont,     'Segoe UI',     'Ubuntu',     'Roboto', 'Noto Sans', 'Droid Sans',     sans-serif",
	fontSizes: {
		...DEFAULT_THEME.fontSizes,
		md: '0.75rem',
	},
};
