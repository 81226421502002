import { Tooltip } from '@mantine/core';
import { memo } from 'react';

function SidebarTooltipWrapper({
	children,
	label,
	showTooltip,
}: {
	children: JSX.Element;
	label: string | JSX.Element;
	showTooltip: boolean;
}) {
	if (!showTooltip) {
		return children;
	}
	return (
		<Tooltip
			label={label}
			color="dark"
			position="right"
			offset={24}
			withinPortal
		>
			{children}
		</Tooltip>
	);
}

export default memo(SidebarTooltipWrapper);
