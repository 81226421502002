// Process {[{normal text}]}(comment_id)

import type MarkdownIt from 'markdown-it';
import type StateInline from 'markdown-it/lib/rules_inline/state_inline';

// Helper function to find the closing brackets }}} for the current open comment brackets.
// This function assume that the current position is at the open comment brackets.
//
// Returns: position of closing brackets or -1 if not found
// Originally used state.skipToken() but it might not work correctly -> can just do quick search to the next closing brackets.
const parseTextEnd = (state: StateInline, start: number): number => {
	let found;
	let marker;

	// Keep reference of current position before attempt parsing
	const oldMax = state.posMax;
	const oldPos = state.pos;

	// Skip the first 3 characters {[{
	let level = 1;
	state.pos = start + 3;

	while (state.pos < oldMax - 2) {
		marker = state.src.slice(state.pos, state.pos + 3);
		if (marker === '}]}') {
			level -= 1;
			if (level === 0) {
				found = true;
				break;
			}
		}

		// Always move forward one position
		state.pos += 1;

		// Skip till the next '{' or '}'
		while (
			state.pos < oldMax - 2 &&
			state.src.charCodeAt(state.pos) !== 0x7b /* { */ &&
			state.src.charCodeAt(state.pos) !== 0x7d /* } */
		) {
			state.pos += 1;
		}

		if (marker === '{[{') {
			// Found a nested comment. Increase level
			level += 1;
		}
	}

	// If closing brackets not found return -1;
	let textEndPos = -1;
	if (found) {
		textEndPos = state.pos;
	}

	// Restore old state
	state.pos = oldPos;
	return textEndPos;
};

const comment = (md: MarkdownIt) => {
	md.inline.ruler.after(
		'html_inline',
		'comment',
		(state: StateInline, silent: boolean) => {
			// Keep reference of current position before attempt parsing
			const oldPos = state.pos;
			const max = state.posMax;

			let commentID = '';

			// Optimization: Stop early if not start with {
			if (state.src.charCodeAt(oldPos) !== 0x7b /* { */) {
				return false;
			}

			// Optimization: Stop early if not enough length.
			// Need at least 10 characters: ex {[{a}]}(b)
			if (max - oldPos + 1 < 10) {
				return false;
			}

			// Optimization: Check if the current 3 characters are {[{
			if (
				state.src.charCodeAt(oldPos + 1) !== 0x5b /* [ */ ||
				state.src.charCodeAt(oldPos + 2) !== 0x7b /* { */
			) {
				return false;
			}

			const textStartPos = oldPos + 3;
			// Find the end of comment text
			// {[{ }]}
			//     ^^^ these end brackets

			const textEndPos = parseTextEnd(state, state.pos);
			if (textEndPos === -1) {
				return false;
			}

			// Skip the closing brackets
			// {[{ }]}(<comment_id>)
			//        ^ pos
			let pos = textEndPos + 3;
			if (pos > max || state.src.charCodeAt(pos) !== 0x28 /* ( */) {
				return false;
			}

			// {[{ }]}(comment_id)
			//         ^ pos
			pos += 1;

			// {{{ }}}(comment_id)
			//          				 ^ pos + 36 (36 uuid chars)
			// If there's no closing bracket at the right position, then it's not a valid comment
			if (pos + 36 > max || state.src.charCodeAt(pos + 36) !== 0x29 /* ) */) {
				return false;
			}
			commentID = state.src.slice(pos, pos + 36);
			pos += 36;

			// {{{ }}}(comment_id)
			// 								    ^ pos.
			// Skip to after the comment
			pos += 1;

			// Found valid comment
			if (!silent) {
				// Push the starting token
				const openToken = state.push('comment_open', '', 1);
				openToken.attrs = [['commentID', commentID]];

				// Parse inner comment text with other rules
				// Manipulate the max range to only parse the inner comment text.
				state.pos = textStartPos;
				state.posMax = textEndPos;

				state.md.inline.tokenize(state);

				// Push the closing comment token
				state.push('comment_close', '', -1);
			}

			// Reset state positions.
			state.pos = pos;
			state.posMax = max;
			return true;
		}
	);
};

export default comment;
