import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import { apiClient, getEndpoints } from '../../common';
import { createQueryKeys } from '../../factories';

const AI_EDITOR_SUMMARY_NAMESPACE = ['ai', 'editor', 'summary'];

const aiEditorSummaryQueryKeys = createQueryKeys(AI_EDITOR_SUMMARY_NAMESPACE);

interface RequestData {
	content: string;
}

interface ResponseData {
	content: string;
}

export async function postAIEditorSummary({ content }: RequestData) {
	const url = getEndpoints(aiEditorSummaryQueryKeys.namespace).root();

	const result = await apiClient.post<ResponseData>(url, { content });

	return result.data;
}

export function useAIEditorSummary(
	options?: UseMutationOptions<ResponseData, unknown, RequestData, unknown>
) {
	return useMutation<ResponseData, unknown, RequestData, unknown>({
		...options,
		mutationFn: postAIEditorSummary,
	});
}
