import { createStyles, Group } from '@mantine/core';
import { Text } from '@repo/foundations';
import { useCallback, useMemo } from 'react';
import type { IGlossaryTerm } from '../../api';
import { AddGlossarySynonym } from './AddGlossarySynonym';
import { GlossarySynonymPill } from './GlossarySynonymPill';

const useStyles = createStyles(() => ({
	wrapper: {
		flex: '1 !important',
	},
}));

export interface InlineGlossarySynonymsProps {
	glossaryTerm: IGlossaryTerm;
	onChange: (synonyms: string[]) => Promise<void>;
}

export function InlineGlossarySynonyms({
	glossaryTerm,
	onChange,
}: InlineGlossarySynonymsProps) {
	const { classes } = useStyles();

	const glossaryTermSynonyms = useMemo(
		() => glossaryTerm.synonyms ?? [],
		[glossaryTerm.synonyms]
	);

	const handleOnAdd = useCallback(
		async (synonym: string) => {
			const synonyms = [...glossaryTermSynonyms, synonym];
			await onChange(synonyms);
		},
		[glossaryTermSynonyms, onChange]
	);

	const handleOnRemove = useCallback(
		(synonym: string) => async () => {
			const synonyms = glossaryTermSynonyms.filter((s) => s !== synonym);
			await onChange(synonyms);
		},
		[glossaryTermSynonyms, onChange]
	);

	return (
		<Group spacing="xs" noWrap align="flex-start">
			<Text w={145} size="sm" py="4xs">
				Synonyms
			</Text>
			<Group spacing="xs" className={classes.wrapper}>
				{glossaryTermSynonyms.map((synonym, idx) => (
					<GlossarySynonymPill
						// eslint-disable-next-line react/no-array-index-key
						key={`${synonym}-${idx}`}
						synonym={synonym}
						onRemove={handleOnRemove(synonym)}
					/>
				))}
				<AddGlossarySynonym onAdd={handleOnAdd} />
			</Group>
		</Group>
	);
}
