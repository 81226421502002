import { map, omit } from 'lodash-es';
import { unparse } from 'papaparse';
import type { ISecodaEntity } from '../../api';

export function generateCsvFromResults(
	results: Record<string, unknown>[],
	customProperties?: { id: string; name: string }[]
): string {
	const csv = unparse(
		map(results, (el) => {
			const normalized = { ...el } as Record<string, unknown>;

			// Create UUID to name mapping from customProperties
			const propertyNameMap =
				customProperties?.reduce(
					(acc, cp) => {
						// eslint-disable-next-line no-param-reassign
						acc[cp.id] = cp.name;
						return acc;
					},
					{} as Record<string, string>
				) ?? {};

			Object.values(propertyNameMap).forEach((name) => {
				normalized[name] = '';
			});

			// Handle properties2 fields
			Object.keys(normalized).forEach((key) => {
				if (
					key === 'properties2' &&
					(normalized as unknown as ISecodaEntity)[key]
				) {
					try {
						// Parse properties2 JSON
						const properties =
							typeof normalized[key] === 'string'
								? JSON.parse(normalized[key])
								: normalized[key];

						// Delete original properties2 field
						delete normalized[key];

						// Add each property as its own column using the mapped name
						Object.entries(properties).forEach(([uuid, value]) => {
							const columnName = propertyNameMap[uuid] ?? uuid;
							normalized[columnName] =
								Array.isArray(value) || typeof value === 'object'
									? JSON.stringify(value)
									: value;
						});
					} catch (e) {
						// eslint-disable-next-line no-console
						console.warn('Failed to parse properties2 field', e);
					}
				}
			});

			// Remove excluded fields
			return omit(normalized, [
				'display_metadata',
				'properties',
				'properties2',
				'custom_backup',
				'search_metadata',
				'credentials',
				'last_error_message',
				'schedule',
			]);
		})
	);

	return csv;
}
