import { baseQueryHooksFactory } from '../../factories';
import type { ITestRun } from '../../types';
import { testRunsQueryKeyFactory } from './constants';

const { fetchTestRunList, useTestRunList } = baseQueryHooksFactory<
	ITestRun,
	'testRun'
>('testRun', testRunsQueryKeyFactory);

export { fetchTestRunList, useTestRunList };
