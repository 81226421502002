import { useCallback } from 'react';
import type { IQuestionReply } from '../../../api';
import {
	queryClient,
	questionReplyQueryKeyFactory,
	questionsQueryKeyFactory,
	useDeleteQuestionReply,
	useUpdateQuestionReply,
} from '../../../api';
import { ReplyWrapper } from './ReplyWrapper';

export interface IQuestionReplyProps {
	questionReply: IQuestionReply;
	accepted?: boolean;
}

export function QuestionReply({
	questionReply,
	accepted = false,
}: IQuestionReplyProps) {
	const questionId = questionReply.parent!;

	const { mutateAsync: updateQuestionReply } = useUpdateQuestionReply({
		options: {
			onSuccess: async () => {
				queryClient.invalidateQueries(
					questionReplyQueryKeyFactory.list(1, {
						question_id: questionReply.parent,
					})
				);
				queryClient.invalidateQueries(
					questionsQueryKeyFactory.byId(questionId)
				);
			},
		},
	});

	const { mutateAsync: deleteQuestionReply } = useDeleteQuestionReply({
		options: {
			onSuccess: async () => {
				queryClient.invalidateQueries(
					questionReplyQueryKeyFactory.list(1, {
						question_id: questionId,
					})
				);
			},
		},
	});

	const handleUpdate = useCallback(
		(values: Partial<IQuestionReply>) =>
			updateQuestionReply({ data: { id: questionReply.id, ...values } }),
		[questionReply, updateQuestionReply]
	);

	const handleDelete = useCallback(
		() => deleteQuestionReply({ id: questionReply.id }),
		[deleteQuestionReply, questionReply]
	);

	return (
		<ReplyWrapper
			entity={questionReply}
			accepted={accepted}
			handleUpdate={handleUpdate}
			handleDelete={handleDelete}
			type="reply"
		/>
	);
}

export default QuestionReply;
