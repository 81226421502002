import { Box, createStyles } from '@mantine/core';
import { size } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router';
import { useUserGroup } from '../../api';
import { FullWidthLoadingSpinner } from '../../components/LoadingSpinner';
import { UserGroup } from '../../lib/models';
import UserGroupProfileHeader from './components/UserGroupProfileHeader';
import UserGroupProfileTabs from './components/UserGroupProfileTabs';

const useStyles = createStyles(() => ({
	wrapper: {
		padding: 42,
	},
}));

function UserGroupProfilePage() {
	const { id } = useParams();
	const styles = useStyles();

	const { data: group } = useUserGroup({ id: id as string });

	if (!group) {
		return <FullWidthLoadingSpinner />;
	}

	const userGroup = new UserGroup(group);

	const handleIconChange = (emoji: string) => {
		userGroup.icon = emoji;
		userGroup.save(['icon']);
	};

	return (
		<Box className={styles.classes.wrapper}>
			<UserGroupProfileHeader
				onIconChange={handleIconChange}
				icon={userGroup.icon}
				name={userGroup.name}
				usersCount={size(userGroup.users)}
				createdAt={userGroup.created_at}
			/>
			<UserGroupProfileTabs userGroup={group} />
		</Box>
	);
}

export default observer(UserGroupProfilePage);
