import * as FullStory from '@fullstory/browser';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { throttle, uniqBy } from 'lodash-es';
import { api } from '../../network';
import { useAuthUser, useIntegrationList } from '../api';
import { authHeaders } from '../api/common';
import { useLimits } from '../api/hooks/workspace/useLimits';
import { anonymousId } from '../components/Auth/AuthStepEmailComponent.constants';
import { usePlan } from '../hooks/usePlans';
import { type User, type Workspace } from '../lib/models';
import { captureError } from '../web-tracing';
import { coalesceName } from './authorization/roles';
import { isImpersonating } from './cache/utils';
import { IS_PRODUCTION } from './envs';
import { getHash } from './shared.utils';

export const analyticsPermissible = (user?: User, workspace?: Workspace) =>
	!workspace?.disable_analytics && // Ensure they haven't disable analytics in their workspace.
	user && // Ensure they are logged in.
	user.email && // This is an important check to prevent crashes.
	user.email !== 'AnonymousUser' && // Ensure they are authenticated.
	!user.email?.endsWith('mailosaur.net') && // Ensure they are not a canary (testing) user.
	IS_PRODUCTION &&
	!isImpersonating();

export const useConfigureFullStory = () => {
	const { user } = useAuthUser();

	const queryFn = async () => {
		if (analyticsPermissible(user)) {
			FullStory.init({ orgId: '12C2G2' }, () => {
				FullStory.identify(user.email, {
					displayName: coalesceName(user),
					email: user.email,
				});
			});
			return true;
		}
		return false;
	};

	return useQuery<boolean, boolean, void>(['fullstory'], queryFn);
};

export const useConfigureIntercom = () => {
	const { user, workspace } = useAuthUser();
	const { data } = useIntegrationList({});
	const billingDetails = usePlan();
	const limitDetails = useLimits();

	const queryFn = async () => {
		if (analyticsPermissible(user, workspace)) {
			// @ts-expect-error TS(2339): Property 'Intercom' does not exist on type 'Window... Remove this comment to see the full error message
			window.Intercom('boot', {
				app_id: 'dssmg53d',
				email: user.email,
				user_id: getHash(user.email),
				name: user.display_name,
				role: user.role,
				workspace_id: workspace.id,
				is_customer:
					!!workspace.customer_id && workspace.customer_id.startsWith('cus_'),
				days_left_in_trial: billingDetails.daysLeftInTrial,
				trial: `${billingDetails.trial}`,
				plan: `${billingDetails.trial ? 'Trial' : billingDetails.plan?.title}`,
				editor_seats_used: `${limitDetails.usedEditorOrAdminSeats} of ${limitDetails.editorOrAdminSeats}`,
				viewer_seats_used: `${limitDetails.usedViewerSeats} of ${limitDetails.viewerSeats}`,
				number_of_integrations: data?.count,
			});
			return true;
		}
		return false;
	};

	return useQuery<boolean, boolean, void>(['intercom'], queryFn);
};

export const trackEventAnonymous = (
	eventName: string,
	properties: Record<string, string>,
	email?: string
) => {
	if (!email) return;

	try {
		axios.post(`${api()}log/anonymous/event/`, {
			email,
			event_name: eventName,
			properties: properties ?? {},
		});
	} catch (e) {
		// Catch 404 errors to prevent crashes. This may happen when there is no email provided.
		captureError(e);
	}
};

export interface TrackEvent {
	eventName: string;
	properties: Record<string, string>;
}

const eventsStack: TrackEvent[] = [];

/**
 * This function is used to batch events and send them to the server.
 * It is throttled to send events every 15 seconds.
 *
 * @param events - The events to send.
 * @param user - The user to send the events for.
 * @param workspace - The workspace to send the events for.
 * @returns void
 */
const flushEventsStack = throttle((user?: User, workspace?: Workspace) => {
	const events = eventsStack.splice(0, eventsStack.length);

	if (events.length > 0) {
		if (analyticsPermissible(user, workspace)) {
			// Remove duplicate events.
			const uniqueEvents = uniqBy(events, (event) => JSON.stringify(event));

			axios.post(
				`${api()}log/event_batch/`,
				{
					events: uniqueEvents,
					workspace_id: workspace?.id,
				},
				authHeaders()
			);
		} else {
			// eslint-disable-next-line no-console
			console.log('trackBatchEvents not sent:', events);
		}
	}
	// We want to send them at most every 15 seconds, letting us effectively
	// remove duplicates, and batch events.
}, 15000);

export function trackBatchEvents(
	events: TrackEvent[],
	user?: User,
	workspace?: Workspace
) {
	const batchEventsData = events.map((event) => ({
		...event,
		event_name: event.eventName,
		properties: {
			...event.properties,
			anonymous_id: anonymousId(),
		},
	}));

	eventsStack.push(...batchEventsData);
	flushEventsStack(user, workspace);
}

export function trackEvent(
	eventName: string,
	properties: Record<string, string>,
	user?: User,
	workspace?: Workspace
) {
	trackBatchEvents([{ eventName, properties }], user, workspace);
}

export const trackUser = (user: User, workspace?: Workspace) => {
	if (analyticsPermissible(user, workspace)) {
		axios.post(`${api()}log/user/`, {}, authHeaders());
	}
};

export const trackGroup = (user: User, workspace?: Workspace) => {
	if (analyticsPermissible(user, workspace)) {
		axios.post(`${api()}log/group/`, {}, authHeaders());
	}
};

export type ISearchSource = 'search_palette' | 'home_page' | 'search_page';
