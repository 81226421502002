import validate from './validate';

/**
 * Convert array of 16 byte values to UUID string format of the form:
 * XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX
 */
const byteToHex: any[] = [];

// eslint-disable-next-line no-plusplus
for (let i = 0; i < 256; ++i) {
	byteToHex.push((i + 0x100).toString(16).slice(1));
}

// @ts-expect-error TS(7006): Parameter 'arr' implicitly has an 'any' type.
export function unsafeStringify(arr, offset = 0) {
	// Note: Be careful editing this code!  It's been tuned for performance
	// and works in ways you may not expect. See https://github.com/uuidjs/uuid/pull/434
	return `${
		byteToHex[arr[offset + 0]] +
		byteToHex[arr[offset + 1]] +
		byteToHex[arr[offset + 2]] +
		byteToHex[arr[offset + 3]]
	}-${byteToHex[arr[offset + 4]]}${byteToHex[arr[offset + 5]]}-${
		byteToHex[arr[offset + 6]]
	}${byteToHex[arr[offset + 7]]}-${byteToHex[arr[offset + 8]]}${
		byteToHex[arr[offset + 9]]
	}-${byteToHex[arr[offset + 10]]}${byteToHex[arr[offset + 11]]}${
		byteToHex[arr[offset + 12]]
	}${byteToHex[arr[offset + 13]]}${byteToHex[arr[offset + 14]]}${
		byteToHex[arr[offset + 15]]
	}`.toLowerCase();
}

// @ts-expect-error TS(7006): Parameter 'arr' implicitly has an 'any' type.
function stringify(arr, offset = 0) {
	const uuid = unsafeStringify(arr, offset);
	// Consistency check for valid UUID.  If this throws, it's likely due to one
	// of the following:
	// - One or more input array values don't map to a hex octet (leading to
	// "undefined" in the uuid)
	// - Invalid input values for the RFC `version` or `variant` fields
	if (!validate(uuid)) {
		throw TypeError('Stringified UUID is invalid');
	}

	return uuid;
}

export default stringify;
