import { useQuery } from '@tanstack/react-query';
import { omit, pick } from 'lodash-es';
import { apiClient, getEndpoints } from '../../common';
import type { Catalog } from '../../types';
import { catalogQueryKeyFactory } from './constants';

interface UseCatalogPropertiesArgs
	extends Pick<Catalog, 'entity_id' | 'catalog_type'> {}

export function useCatalogProperties(
	filters: UseCatalogPropertiesArgs,
	suspense = false
) {
	// Use only catalog properties for queryKey to sync with useUpdateCatalogProperties
	const queryKeyArgs = pick(filters, ['entity_id', 'catalog_type']);
	const queryKey = catalogQueryKeyFactory.byArgs('properties', queryKeyArgs);

	return useQuery({
		queryKey,
		queryFn: async ({ signal }) => {
			const url = getEndpoints(catalogQueryKeyFactory.namespace).byPath([
				'properties',
			]);
			const { data } = await apiClient.get<Catalog>(url, {
				params: filters,
				signal,
			});

			return data;
		},
		suspense,
		select: (data: Catalog) => omit(data, ['updated_at', 'created_at']),
	});
}
