import { Stack } from '@mantine/core';
import type { DataQualityScore } from '@repo/api-codegen';
import { useRetrieveAggregateQuality } from '@repo/api-codegen';
import { Link } from 'react-router-dom';
import { DataQualityDetailsCard } from '../../../../../../../components/DataQualityScore/DataQualityDetailsCard/DataQualityDetailsCard';
import { WidgetSize } from '../../../../../../../interfaces';
import { useHomepageTeam } from '../../../../../hooks/useHomepageTeam';
import type { IWidgetItemProps } from '../../../types';

export function DataQualityScoreWidget({
	widget,
	href,
}: IWidgetItemProps & { href: string }) {
	const { isTeamsPage, team } = useHomepageTeam();
	const { data } = useRetrieveAggregateQuality(
		{
			queryParams:
				isTeamsPage && team.id
					? {
							team_id: team.id,
						}
					: {},
		},
		{
			enabled: !isTeamsPage || !!team?.id,
		}
	);

	return (
		<Stack>
			<Link to={href} style={{ textDecoration: 'none' }}>
				<DataQualityDetailsCard
					data={
						data ??
						({
							total: -1,
							accuracy: -1,
							reliability: -1,
							stewardship: -1,
							usability: -1,
						} as DataQualityScore)
					}
					direction={
						widget.size === WidgetSize.MEDIUM ? 'vertical' : 'horizontal'
					}
					scoreType="percentage"
				/>
			</Link>
		</Stack>
	);
}
