import type { SpotlightAction } from '@mantine/spotlight';
import { makeAutoObservable } from 'mobx';

class TableV2SpotlightStore {
	actions: SpotlightAction[] | undefined;

	selected: string[] = [];

	setSelected(fn: (arg0: string[]) => string[]) {
		this.selected = fn(this.selected);
	}

	setActions(actions: SpotlightAction[]) {
		this.actions = actions.map((action) => ({
			...action,
			onTrigger: () => {
				const existingTrigger = action.onTrigger;
				if (action.single) {
					this.selected = [action.id!];
				} else {
					this.setSelected((prev: string[]) =>
						prev.includes(action.id!)
							? // We do `startsWith` here to handle the case when the action has `::partial` suffix.
								prev.filter((id) => !id.startsWith(action.id!))
							: [...prev, action.id!]
					);
				}
				existingTrigger?.(action);
			},
		}));
	}

	constructor() {
		makeAutoObservable(this);
	}
}

export const paletteStore = new TableV2SpotlightStore();
