import type { CSSObject } from '@mantine/core';
import { createStyles, TextInput } from '@mantine/core';
import { Icon } from '@repo/foundations';

interface IChildrenSearchProps {
	term: string;
	setTerm: (value: string) => void;
	label: string;
}

const useStyles = createStyles((theme) => ({
	root: {
		flexGrow: '1 !important' as CSSObject['flexGrow'],
	},
	input: {
		height: theme.other.space[5],
		padding: `${theme.spacing['3xs']} ${theme.spacing['3xs']} ${theme.spacing['3xs']} ${theme.spacing.sm}`,
		borderWidth: 0,
		borderRadius: theme.radius.sm,
		backgroundColor: theme.other.getColor('surface/secondary/default'),
		lineHeight: theme.other.typography.lineHeight.text.sm,
		'&:hover': {
			backgroundColor: theme.other.getColor('surface/secondary/hover'),
		},
		'&:focus': {
			borderColor: theme.other.neutralColors.white,
			boxShadow: `0px 0px 0px 2px ${theme.colors.blue[5]}`,
			backgroundColor: theme.other.getColor('surface/secondary/active'),
		},
		'&:active': {
			borderColor: theme.other.neutralColors.white,
			boxShadow: `0px 0px 0px 2px ${theme.colors.blue[5]}`,
			backgroundColor: theme.other.getColor('surface/secondary/active'),
		},
	},
}));

export function ChildrenSearch({ term, setTerm, label }: IChildrenSearchProps) {
	const { classes } = useStyles();

	const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setTerm(event.target.value);
	};

	return (
		<TextInput
			classNames={{
				root: classes.root,
				input: classes.input,
			}}
			placeholder={`Search ${label}...`}
			icon={<Icon name="search" color="icon/secondary/default" />}
			value={term}
			onChange={handleOnChange}
		/>
	);
}
