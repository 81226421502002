// eslint-disable-next-line no-restricted-imports
import { Breadcrumbs, createStyles, Group } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import SingleSelector from '@repo/common/components/SingleSelector/SingleSelector';
import { Text } from '@repo/foundations';
import { capitalize, compact } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import React from 'react';
import type { ISearchMetadata } from '../../api';
import { useMention } from '../../api';
import { SecodaEntityIcon } from '../SecodaEntity';
import { useAddMonitorStoreContext } from './context';
import TableSelectorItem from './TableSelectorItem';

interface TableSelectorProps {
	integrationId: string;
}

const useStyles = createStyles((theme) => ({
	label: {
		color: theme.other.getColor('text/secondary/default'),
	},
	separator: {
		marginLeft: theme.spacing['3xs'],
		marginRight: theme.spacing['3xs'],
	},
}));

function TableSelector({ integrationId }: TableSelectorProps) {
	const { classes } = useStyles();
	const [searchTerm, setSearchTerm] = React.useState('');
	const [debouncedSearchTerm] = useDebouncedValue(searchTerm, 300);

	const { data: tables } = useMention({
		searchTerm: debouncedSearchTerm,
		filters: {
			operator: 'and',
			operands: [
				{
					field: 'integration_id',
					operator: 'exact',
					value: integrationId,
					operands: [],
				},
				{
					field: 'entity_type',
					operator: 'exact',
					value: 'table',
					operands: [],
				},
			],
		},
		options: {
			select: (resp) => resp.results,
		},
	});

	const store = useAddMonitorStoreContext();

	const options =
		tables?.map((i) => {
			const breadcrumbs = ['schema', 'database', 'cluster'].map(
				(key) => i.search_metadata?.[key as keyof ISearchMetadata]
			);

			return {
				label: i.title,
				value: i.id,
				icon: <SecodaEntityIcon entity={i} size={20} />,
				description: (
					<Group spacing="3xs" noWrap>
						<Text size="xs" className={classes.label}>
							{capitalize(i.native_type || i.entity_type)}
						</Text>
						<Text size="xs" className={classes.label}>
							&#8226;
						</Text>
						<Breadcrumbs classNames={{ separator: classes.separator }}>
							{compact(breadcrumbs).map((breadcrumb) => (
								<Text key={breadcrumb} size="xs" className={classes.label}>
									{breadcrumb}
								</Text>
							))}
						</Breadcrumbs>
					</Group>
				),
			};
		}) ?? [];

	const handleChange = (value: string) => {
		if (!tables || tables.length === 0) return;
		const table = tables.find((i) => i.id === value);

		if (!table) {
			return;
		}

		store.setFormFields('table', table.id);
	};

	return (
		<SingleSelector
			variant="default"
			iconType="react-node"
			isViewerUser={false}
			searchable
			options={options}
			placeholder="Table"
			placeholderIconName="table"
			onChange={handleChange}
			initialSelected={store.getFormValue('table') ?? ''}
			property="table"
			readOnly={Boolean(store.initialTable)}
			selectorItem={TableSelectorItem}
			itemSize={64}
			itemsToRender={4}
			onSearchTermChange={setSearchTerm}
		/>
	);
}

export default observer(TableSelector);
