import { Stack } from '@mantine/core';
import { spotlight } from '@mantine/spotlight';
import { useKeyPress } from 'ahooks';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useAuthUser, type IQuestion } from '../../api';
import { useTeamEmptyState } from '../../api/hooks/team/emptyStates';
import { useTemplateList } from '../../api/hooks/templates';
import { EmptyState, type ButtonDetails } from '../../components/EmptyState';
import { entityModalStore } from '../../components/EntityModal/store';

import { EntityType } from '@repo/common/enums/entityType';
import type { FetchModelInfiniteListHook } from '../../api/factories/types';
import { useCatalogInfiniteList } from '../../api/hooks/resourceCatalog';
import { TableV2 } from '../../components/TableV2';
import type { OnCellClickHandlerParams } from '../../components/TableV2/types';
import { useEntityBreadcrumbTeam } from '../../hooks/useEntityBreadcrumbs';
import { useStyles } from '../TeamCatalogPage/TeamCatalogPage.styles';
import { useActions, useColumns } from './TeamQuestionsPage.hooks';

const DEFAULT_SORT = {
	field: 'created_at',
	order: 'desc',
} as const;

const COLUMN_VISIBILITY = {
	catalogType: EntityType.question,
	catalogServerType: EntityType.question,
} as const;

const QUICK_ACTIONS = [
	'actions::assignee',
	'actions::assignee_group',
	'actions::priority',
	'actions::status',
	'actions::sidebar',
] as const;

const PAGINATION_LIST_OPTIONS = {
	suspense: true,
};

function TeamQuestionsPage() {
	const navigate = useNavigate();

	const { classes } = useStyles();
	const { teamId } = useParams();
	const { user } = useAuthUser();

	const emptyState = useTeamEmptyState(teamId as string, 'question');

	const columns = useColumns();
	const actions = useActions();

	const { setTeamBreadcrumb } = useEntityBreadcrumbTeam();

	useEffect(() => {
		// If the user opens a entity page from this context we want the team
		// breadcrumb to match the team of this page.
		if (teamId) {
			setTeamBreadcrumb(teamId);
		}
	}, [setTeamBreadcrumb, teamId]);

	const { data: defaultTemplates } = useTemplateList({
		filters: {
			is_default: true,
		},
		options: {
			// Disable suspense, in case the default template is not found. We do not
			// want to trigger a suspense fallback error.
			suspense: false,
		},
	});

	useKeyPress(['shift.p'], () => {
		spotlight.open();
	});

	const handleCreateQuestionClick = useCallback(async () => {
		entityModalStore.open({
			type: EntityType.question,
			teamId,
			template: defaultTemplates?.results?.[0],
			metadata: {
				owners: [user.id],
			},
		});
	}, [defaultTemplates?.results, teamId, user]);

	const buttons: ButtonDetails[] = useMemo(
		() => [
			{
				name: 'Learn more',
				action: () => {
					window.open(
						'https://docs.secoda.co/features/ask-questions-in-secoda',
						'_blank'
					);
				},
				isPrimary: false,
				size: 'md',
			},
			{
				name: 'Create question',
				action: handleCreateQuestionClick,
				isPrimary: true,
				size: 'md',
			},
		],
		[handleCreateQuestionClick]
	);

	const handleCellClick = useCallback(
		({ column, record }: OnCellClickHandlerParams<IQuestion>) => {
			if (column.navigate !== false) {
				navigate(`/questions/${record.id}`);
			}
		},
		[navigate]
	);

	if (emptyState?.is_empty) {
		return (
			<EmptyState
				illustrationName="questions"
				title="Welcome to Questions"
				description="Foster collaboration and communication around data. Ask questions and share insights to make data-driven decisions"
				includeGoBack={false}
				buttons={buttons}
				stateHeight="80vh"
				size="lg"
			/>
		);
	}

	return (
		<Stack className={classes.wrapper}>
			<TableV2<IQuestion>
				withCustomProperties={EntityType.question}
				pluralTypeString="questions"
				withCsvExport
				withServerCsvExport
				withInfiniteScroll
				withCheckbox
				withInteractiveHeader
				onCellClick={handleCellClick}
				withSearch
				columns={columns}
				withActions={actions}
				withQuickActions={QUICK_ACTIONS}
				usePaginationList={
					useCatalogInfiniteList as FetchModelInfiniteListHook<IQuestion>
				}
				columnVisibility={COLUMN_VISIBILITY}
				defaultRequiredSearchParams={{
					team_id: teamId,
					entity_type: EntityType.question,
				}}
				defaultSort={DEFAULT_SORT}
				usePaginationListOptions={PAGINATION_LIST_OPTIONS}
			/>
		</Stack>
	);
}

export default observer(TeamQuestionsPage);
