import { useMantineTheme } from '@mantine/core';
import type { EdgeProps } from 'reactflow';
import { BaseEdge, getBezierPath } from 'reactflow';

export function ChildEdge({
	id,
	sourceX,
	sourceY,
	targetX,
	targetY,
	sourcePosition,
	targetPosition,
	markerEnd,
}: EdgeProps) {
	const theme = useMantineTheme();

	const bezierPath = getBezierPath({
		sourceX,
		sourceY,
		sourcePosition,
		targetX,
		targetY,
		targetPosition,
	});

	return (
		<BaseEdge
			id={id}
			path={bezierPath[0]}
			markerEnd={markerEnd}
			style={{
				stroke: theme.other.getColor('border/emphasis/default'),
				strokeWidth: 1,
			}}
		/>
	);
}
