import { Box, createStyles } from '@mantine/core';
import { isEqual } from 'lodash-es';
import React, { useCallback } from 'react';
import type { DropResult } from 'react-beautiful-dnd';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import type { Catalog } from '../../../api';
import type { ColumnName } from '../helpers';
import { getColumnDisplayName } from '../helpers';
import { DraggableMenuItem } from './MenuItem';
import { reorder } from './utils';

export interface IMenuListProps {
	rowHeight?: number;
	disableDragging?: boolean;
	catalogType: Catalog['catalog_type'];
	catalogProperties: Catalog['properties'];
	onColumnOrderChange: (columnName: string, toIndex: number) => void;
	onVisibilityChange: (columnName: ColumnName, isVisible: boolean) => void;
	getRightContent?: (columnName: ColumnName) => React.ReactNode;
	getSubText?: (columnName: ColumnName) => React.ReactNode;
}

const getListStyle = (
	rowHeight: number,
	columnCount: number
): React.CSSProperties => ({
	background: 'transparent',
	height: rowHeight * columnCount,
});

const useStyles = createStyles({
	wrapper: {
		'.custom-property-action-buttons': {
			display: 'none',
		},
		'&:hover': {
			'& .custom-property-action-buttons': {
				display: 'flex',
			},
		},
	},
});

function MenuList({
	disableDragging = false,
	catalogType,
	catalogProperties,
	onColumnOrderChange,
	onVisibilityChange,
	getRightContent,
	getSubText,
	rowHeight = 52,
}: IMenuListProps) {
	const { classes } = useStyles();

	const [displayedCatalogProperties, setDisplayedCatalogProperties] =
		React.useState(catalogProperties);

	React.useEffect(() => {
		// This is to reduce the flickering when the columns are reordered
		// as the columns are reordered by react-beautiful-dnd and the API
		setDisplayedCatalogProperties((prevState) => {
			if (!isEqual(catalogProperties, prevState)) {
				return catalogProperties;
			}

			return prevState;
		});
	}, [catalogProperties]);

	const handleDragEnd = useCallback(
		(result: DropResult) => {
			// Dropped outside the list.
			if (!result.destination) {
				return;
			}

			const reorderedColumns = reorder(
				catalogProperties,
				result.source.index,
				result.destination.index
			);
			setDisplayedCatalogProperties(reorderedColumns);

			onColumnOrderChange(
				displayedCatalogProperties[result.source.index]?.value,
				result.destination.index
			);
		},
		[catalogProperties, displayedCatalogProperties, onColumnOrderChange]
	);

	return (
		<DragDropContext onDragEnd={handleDragEnd}>
			<Droppable droppableId="droppable">
				{(provided) => (
					<Box
						{...provided.droppableProps}
						ref={provided.innerRef}
						style={getListStyle(rowHeight, catalogProperties.length)}
					>
						{displayedCatalogProperties.map(
							({ value: columnName, hidden }, index) => {
								const name = getColumnDisplayName(columnName, catalogType);

								const handleVisibilityChange = (isVisible: boolean): void => {
									onVisibilityChange(columnName, isVisible);
								};

								const isVisible = !hidden;

								return (
									<Box className={classes.wrapper} key={columnName}>
										<DraggableMenuItem
											subtext={getSubText ? getSubText(columnName) : undefined}
											height={rowHeight}
											disableDragging={disableDragging}
											name={name}
											columnName={columnName}
											index={index}
											onVisibilityChange={handleVisibilityChange}
											isVisible={isVisible}
											rightContent={
												getRightContent && getRightContent(columnName)
											}
										/>
									</Box>
								);
							}
						)}
						{provided.placeholder}
					</Box>
				)}
			</Droppable>
		</DragDropContext>
	);
}

export default MenuList;
