import { Flex, Stack, createStyles } from '@mantine/core';
import type { MetricType } from '@repo/api-codegen';
import { Text } from '@repo/foundations';
import dayjs from 'dayjs';
import type { MeasurementChartData } from '../../../api';
import { ChartTooltip } from '../../../components/Chart/components/ChartTooltip.tsx';
import { IncidentStatusBadge } from '../components/IncidentStatusBadge.tsx';
import {
	formatValueCompact,
	metricTypeLabel,
	stripTimeUnits,
} from '../utils.tsx';

interface MonitorMeasurementTooltipProps {
	metricType: MetricType;
	value: MeasurementChartData;
	x: number;
	y: number;
}

const useStyles = createStyles(() => ({
	grow: {
		flexGrow: 1,
	},
}));

function MonitorMeasurementTooltip({
	value,
	x,
	y,
	metricType,
}: MonitorMeasurementTooltipProps) {
	const { classes } = useStyles();
	const date = new Date(value.truncated_time);
	const incidentFirstSeenAt = value.incident_first_seen_at
		? new Date(value.incident_first_seen_at)
		: undefined;

	if (!value) {
		return null;
	}

	return (
		<ChartTooltip x={x} y={y} p="sm" w={!!value.incident_id ? 340 : 260}>
			<Stack spacing="xs">
				<Flex>
					<Stack className={classes.grow} spacing={0}>
						<Text size="sm">
							{dayjs(date).format('MMM DD, YYYY [at] h:mm A')}
						</Text>
						{value.incident_exists && (
							<Text size="xs" color={'text/secondary/default'}>
								Incident
							</Text>
						)}
					</Stack>
					{value.incident_id && value.incident_status && (
						<IncidentStatusBadge incidentStatus={value.incident_status} />
					)}
				</Flex>
				<Flex mt="xs">
					<Text size="sm">{metricTypeLabel(metricType)}</Text>
					<Text size="sm" ml="sm" fw={600} className={classes.grow} ta="right">
						{formatValueCompact(metricType, value.value)}
					</Text>
				</Flex>
				{value.upper_threshold > 0 && (
					<Flex>
						<Text size="sm">Threshold</Text>
						<Text
							size="sm"
							ml="sm"
							fw={600}
							className={classes.grow}
							ta="right"
						>
							{stripTimeUnits(
								formatValueCompact(metricType, value.lower_threshold ?? 0)
							)}
							&ndash;
							{formatValueCompact(metricType, value.upper_threshold ?? 0)}
						</Text>
					</Flex>
				)}
				{incidentFirstSeenAt && (
					<Flex>
						<Text size="sm">Incident first occurred</Text>
						<Text
							size="sm"
							ml="xs"
							fw={600}
							className={classes.grow}
							ta="right"
						>
							{dayjs(incidentFirstSeenAt).format('MMM DD, YYYY [at] h:mm A')}
						</Text>
					</Flex>
				)}
			</Stack>
		</ChartTooltip>
	);
}

export default MonitorMeasurementTooltip;
