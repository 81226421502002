import { Group, Stack, Tabs } from '@mantine/core';
import { Button } from '@repo/foundations';
import { observer } from 'mobx-react-lite';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useAuthUser, useMonitorList } from '../../api';

import { useFeatureAccess } from '../../api/hooks/workspace/useFeatureAccess';
import AddMonitorButton from '../../components/AddMonitor/AddMonitorButton';
import { EmptyState } from '../../components/EmptyState';
import { FullWidthLoadingSpinner } from '../../components/LoadingSpinner';
import { UpgradeButton } from '../../components/Settings/UpgradeButton';
import { trackEvent } from '../../utils/analytics';
import { useListPageTabNavigate } from '../../utils/hook/useTabNavigate';
import { useStyles } from '../TeamCatalogPage/TeamCatalogPage.styles';
import IncidentList from './IncidentList';
import MonitorList from './MonitorList';

type MonitorListPageTab = 'monitors' | 'incidents';

function MonitorListPage() {
	const { tab } = useParams();
	const { user, workspace } = useAuthUser();
	const { data: monitors, isLoading } = useMonitorList({});
	const { monitoringAccess, isLoading: isFeatureAccessLoading } =
		useFeatureAccess();

	const { classes } = useStyles();
	const [activeTab, setActiveTab] = useState<MonitorListPageTab>(
		tab === 'incidents' ? 'incidents' : 'monitors'
	);
	const navigateToTab = useListPageTabNavigate();

	const handleTabChange = (value: string): void => {
		if (value === 'monitors' || value === 'incidents') {
			setActiveTab(value);
			navigateToTab(value);
		}
		trackEvent(
			'monitors/tabs/click',
			{
				tab: value,
			},
			user,
			workspace!
		);
	};

	const learnMoreAction = useMemo(
		() => (
			<Group>
				<Button
					component="a"
					href="https://docs.secoda.co/features/monitoring#how-to-create-monitors"
					target="_blank"
					rel="noreferrer noopener"
					size="md"
					leftIconName="externalLink"
				>
					Learn more
				</Button>
				<AddMonitorButton />
			</Group>
		),
		[]
	);

	if (isLoading) {
		return <FullWidthLoadingSpinner />;
	}

	if (!monitoringAccess) {
		return (
			<EmptyState
				illustrationName="upgrade"
				title="Upgrade to access Monitors"
				description="Add monitors to your data, set thresholds, and be alerted as soon as incidents are detected."
				includeGoBack={false}
				stateHeight="80vh"
				size="lg"
				withActions={
					<UpgradeButton
						tooltip="Upgrade to access Monitors"
						feature="Monitors"
						size="md"
					/>
				}
			/>
		);
	}

	if (monitors && monitors.results.length === 0) {
		if (isFeatureAccessLoading) {
			return <FullWidthLoadingSpinner />;
		}

		return (
			<EmptyState
				className={classes.emptySpace}
				illustrationName="monitors"
				title="Keep a pulse on your data"
				description="Add monitors to your data, set thresholds, and be alerted as soon as incidents are detected."
				includeGoBack={false}
				stateHeight="80vh"
				size="lg"
				withActions={learnMoreAction}
			/>
		);
	}

	return (
		<Stack className={classes.wrapper} spacing={0}>
			<Tabs color="gray.9" value={activeTab} onTabChange={handleTabChange}>
				<Tabs.List className={classes.tabsList}>
					<Tabs.Tab value="monitors">Monitors</Tabs.Tab>
					<Tabs.Tab value="incidents">Incidents</Tabs.Tab>
				</Tabs.List>
			</Tabs>
			{activeTab === 'monitors' ? <MonitorList /> : <IncidentList />}
		</Stack>
	);
}

export default observer(MonitorListPage);
