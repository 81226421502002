import { useCallback } from 'react';
import { queryClient } from '../../api';
import { useAuthUser, useUpdateUser } from '../../api/hooks';
import { thisUserQueryKey } from '../../api/hooks/user/useThisUser';

export function useEducationModalDismiss() {
	const { user, workspace, refetch } = useAuthUser();
	const { mutateAsync: updateUser } = useUpdateUser({
		options: {
			onSuccess: () => {
				queryClient.invalidateQueries(thisUserQueryKey);
			},
		},
	});

	const setAsSeen = useCallback(
		async (modalKey: string) => {
			if (!user || !workspace) {
				return;
			}

			if (
				user.education_modals_dismissed &&
				user.education_modals_dismissed[modalKey] === true
			) {
				// If the value is already set don't set it again.
				return;
			}

			await updateUser({
				data: {
					id: user.id,
					education_modals_dismissed: {
						...user.education_modals_dismissed,
						[modalKey]: true,
					},
				},
			});
			await refetch();
		},
		[refetch, updateUser, user, workspace]
	);

	const setAsUnseen = useCallback(
		async (modalKey: string) => {
			if (!user || !workspace) {
				return;
			}

			await updateUser({
				data: {
					id: user.id,
					education_modals_dismissed: {
						...user.education_modals_dismissed,
						[modalKey]: false,
					},
				},
			});
			await refetch();
		},
		[refetch, updateUser, user, workspace]
	);

	return {
		setAsSeen,
		setAsUnseen,
		dismissedModals: user.education_modals_dismissed || {},
	};
}
