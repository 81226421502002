import type { MantineNumberSize, MantineThemeOverride } from '@mantine/core';

export const Text: MantineThemeOverride['components'] = {
	Text: {
		styles: (theme, params, context) => {
			const size: MantineNumberSize = params.fz || context.size;

			let fontSize: string = 'inherit';
			let lineHeight: string = 'inherit';
			if (size === 'xxs') {
				fontSize = theme.other.typography.text.xxs;
				lineHeight = theme.other.typography.lineHeight.text.xxs;
			} else if (size === 'xs') {
				fontSize = theme.other.typography.text.xs;
				lineHeight = theme.other.typography.lineHeight.text.xs;
			} else if (size === 'sm') {
				fontSize = theme.other.typography.text.sm;
				lineHeight = theme.other.typography.lineHeight.text.sm;
			} else if (size === 'md') {
				fontSize = theme.other.typography.text.md;
				lineHeight = theme.other.typography.lineHeight.text.md;
			} else if (size === 'lg') {
				fontSize = theme.other.typography.text.lg;
				lineHeight = theme.other.typography.lineHeight.text.lg;
			} else if (size === 'xl') {
				fontSize = theme.other.typography.text.xl;
				lineHeight = theme.other.typography.lineHeight.text.xl;
			} else if (size === 'xxl') {
				fontSize = theme.other.typography.text.xxl;
				lineHeight = theme.other.typography.lineHeight.text.xxl;
			} else if (size === '3xl') {
				fontSize = theme.other.typography.text['3xl'];
				lineHeight = theme.other.typography.lineHeight.text['3xl'];
			} else if (size === '4xl') {
				fontSize = theme.other.typography.text['4xl'];
				lineHeight = theme.other.typography.lineHeight.text['4xl'];
			} else if (size === '7xl') {
				fontSize = theme.other.typography.text['7xl'];
				lineHeight = theme.other.typography.lineHeight.text['7xl'];
			}

			return {
				root: {
					lineHeight,
					fontSize,
				},
			};
		},
	},
};
