import * as React from 'react';
import type { EmbedProps as Props } from '.';
import Frame from './components/Frame';

export default class Generic extends React.Component<Props> {
	static ENABLED = [new RegExp('(https?)://(.*)$')];

	render() {
		const { matches } = this.props.attrs;
		const http = matches[1];
		const iframe = matches[2];

		return (
			<Frame
				{...this.props}
				src={`${http}://${iframe}`}
				title={`Embed (${iframe})`}
			/>
		);
	}
}
