import { observer } from 'mobx-react-lite';
import type { Automation } from '../../../../api';
import { AutomationActionCardType } from '../../constants';
import type { AutomationCardType } from '../constants';
import AutomationActionCardEditContent from './Edit/AutomationActionCardEditContent';
import AutomationActionCardFilterContent from './Filter/AutomationActionCardFilterContent';
import AutomationActionCardFilterCountContent from './Filter/AutomationActionCardFilterCountContent';
import AutomationActionCardAnnouncementContent from './Notification/announcement/AutomationActionCardAnnouncementContent';
import AutomationActionCardEmailContent from './Notification/email/AutomationActionCardEmailContent';
import AutomationActionCardSlackContent from './Notification/slack/AutomationActionCardSlackContent';
import AutomationActionCardPropagateMetadataContent from './PropagateMetadata/AutomationActionCardPropagateMetadataContent';

interface AutomationCardContentProps {
	type: AutomationCardType;
	searchKey?: string;
	icon?: JSX.Element;
	handleAutomationUpdate: (automation: Partial<Automation>) => Promise<void>;
}

function AutomationActionCardContent({
	type,
	searchKey,
	icon,
	handleAutomationUpdate,
}: AutomationCardContentProps) {
	if (type === AutomationActionCardType.FILTER_RESOURCES) {
		return (
			<AutomationActionCardFilterContent
				handleAutomationUpdate={handleAutomationUpdate}
				searchKey={searchKey}
			/>
		);
	}

	if (type === AutomationActionCardType.FILTER_COUNT) {
		return <AutomationActionCardFilterCountContent icon={icon} />;
	}

	if (type === AutomationActionCardType.EDIT_RESOURCES) {
		return (
			<AutomationActionCardEditContent
				handleAutomationUpdate={handleAutomationUpdate}
			/>
		);
	}

	if (type === AutomationActionCardType.SEND_ANNOUNCEMENT) {
		return (
			<AutomationActionCardAnnouncementContent
				handleAutomationUpdate={handleAutomationUpdate}
			/>
		);
	}

	if (type === AutomationActionCardType.SEND_SLACK_MESSAGE) {
		return (
			<AutomationActionCardSlackContent
				handleAutomationUpdate={handleAutomationUpdate}
			/>
		);
	}

	if (type === AutomationActionCardType.SEND_EMAIL) {
		return (
			<AutomationActionCardEmailContent
				handleAutomationUpdate={handleAutomationUpdate}
			/>
		);
	}

	if (type === AutomationActionCardType.PROPAGATE_METADATA) {
		return (
			<AutomationActionCardPropagateMetadataContent
				handleAutomationUpdate={handleAutomationUpdate}
			/>
		);
	}

	throw new Error('Invalid automation action type has been provided');
}

export default observer(AutomationActionCardContent);
