import { Group, Stack } from '@mantine/core';
import { useApiListTeams } from '@repo/api-codegen';
import { useNavigate } from '@repo/common/hooks/useNavigate';
import { Icon, Text } from '@repo/foundations';
import dayjs from 'dayjs';
import { find, isNil, startCase } from 'lodash-es';
import { useCallback } from 'react';
import type { INotification } from '../../../../api/types/models/notification';
import { UserAvatar } from '../../../../components/UserAvatar';
import { useStyles } from './styles';

interface ITeamContentProps {
	selected: INotification;
}

function TeamContent({ selected }: ITeamContentProps) {
	const { classes, cx } = useStyles();

	const navigate = useNavigate();

	const { data: teams } = useApiListTeams({
		queryParams: {
			include_archived: false,
		},
	});

	const handleNavigateToUser = useCallback(() => {
		navigate(`/user/${selected.sender.id}`);
	}, [navigate, selected.sender.id]);

	const handleNavigateToTeam = useCallback(() => {
		const team = find(teams?.results, {
			name: selected.content.substring(3),
		});
		if (!isNil(team)) {
			navigate(`/teams/${team.id}`);
		}
	}, [navigate, selected.content, teams?.results]);

	return (
		<Stack spacing="lg">
			<Group spacing="xs" align="flex-start">
				<Group className={classes.leftCol} spacing="xs">
					<Icon name="user" />
					User
				</Group>
				<Group
					className={cx(classes.rightCol, classes.cursorPointer)}
					spacing="xs"
					onClick={handleNavigateToUser}
				>
					<UserAvatar user={selected.sender} size="lg" />
					<Stack spacing={0}>
						<Text className={classes.label}>
							{selected.sender.display_name}
						</Text>
						<Text className={classes.subLabel}>{`${startCase(
							selected.sender.role
						)} • ${selected.sender.email}`}</Text>
					</Stack>
				</Group>
			</Group>
			<Group spacing="xs" align="flex-start">
				<Group className={classes.leftCol} spacing="xs">
					<Icon name="users" />
					Team
				</Group>
				<Group
					className={cx(classes.rightCol, classes.cursorPointer)}
					spacing="xs"
					onClick={handleNavigateToTeam}
				>
					<Stack spacing={0}>
						<Text className={classes.label}>{selected.content}</Text>
					</Stack>
				</Group>
			</Group>
			<Group spacing="xs" align="flex-start">
				<Group className={classes.leftCol} spacing="xs">
					<Icon name="clock" />
					Updated time
				</Group>
				<Group className={classes.rightCol} spacing="xs" noWrap>
					<Stack spacing={0}>
						<Text className={classes.soleLabel}>
							{dayjs(selected.created_at).format('MMMM D, YYYY h:mm A')}
						</Text>
					</Stack>
				</Group>
			</Group>
		</Stack>
	);
}

export default TeamContent;
