import type { IJob } from '../../../api';
import { useJobTasksList } from '../../../api';
import { TableV2 } from '../../../components/TableV2';
import { useColumns } from './TaskTable.hooks';

interface ITaskTableProps {
	job: IJob;
}

function TaskTable({ job }: ITaskTableProps) {
	const columns = useColumns();

	return (
		<TableV2
			pluralTypeString="runs"
			columns={columns}
			withInfiniteScroll
			usePaginationList={useJobTasksList}
			defaultRequiredSearchParams={{
				parent_id: job.id,
			}}
			withCheckbox={false}
			withInteractiveHeader={false}
			withFilters={false}
		/>
	);
}

export default TaskTable;
