/* eslint-disable class-methods-use-this */

const AI_ASSISTANT_ID_SESSION_KEY = 'ai_assistant_id';

class SidebarSessionStore {
	getSessionId() {
		return sessionStorage.getItem(AI_ASSISTANT_ID_SESSION_KEY);
	}

	storeSessionId(sessionId: string) {
		sessionStorage.setItem(AI_ASSISTANT_ID_SESSION_KEY, sessionId);
	}

	clearSessionId() {
		sessionStorage.removeItem(AI_ASSISTANT_ID_SESSION_KEY);
	}
}

export const aiSidebarSessionStore = new SidebarSessionStore();
