import { DatePicker } from '@mantine/dates';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';

interface SecodaDatePickerProps {
	value?: string | boolean;
	dateFormat?: string;
	close: () => void;
	onChange: (value: string, label?: string) => void;
}

function SecodaDatePicker({
	value,
	dateFormat,
	close,
	onChange,
}: SecodaDatePickerProps) {
	const handleDateChange = (date: Date) => {
		if (date) {
			onChange(
				date.toISOString(),
				dayjs(date).format(dateFormat ?? 'MMM D, YYYY')
			);
		}
		close();
	};

	if (typeof value === 'boolean') {
		throw new Error('Invalid value has been provided');
	}

	const currentDate =
		dayjs(value).isValid() && value ? new Date(value) : undefined;

	return (
		<DatePicker
			type="default"
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore poorly typed component
			placeholder="Pick a date"
			defaultDate={currentDate ?? new Date()}
			value={currentDate}
			onChange={handleDateChange}
			firstDayOfWeek={0} // Sunday
			weekendDays={[]} // Remove red color from weekends
			getDayProps={() => ({
				sx: (theme) => ({
					'&:focus, &:active': {
						backgroundColor: theme.other.getColor('fill/brand/selected'),
						color: theme.other.getColor('text/brand-on-fill/default'),
						radius: theme.radius.sm,
					},
				}),
			})}
		/>
	);
}

export default observer(SecodaDatePicker);
