import type { MantineThemeOverride } from '@mantine/core';

export const Badge: MantineThemeOverride['components'] = {
	Badge: {
		styles: (theme, params, context) => {
			let colorValues: Partial<Record<'color' | 'backgroundColor', string>> =
				{};
			if (params.color) {
				colorValues = {
					color: theme.fn.themeColor(params.color, 8),
					backgroundColor: theme.fn.themeColor(params.color, 1),
				};
			} else {
				const variant = context.variant || 'info';
				if (variant === 'success') {
					colorValues = {
						color: theme.other.getColor('text/success/default'),
						backgroundColor: theme.other.getColor(
							'fill/success-secondary/default'
						),
					};
				} else if (variant === 'info') {
					colorValues = {
						color: theme.other.getColor('text/info/default'),
						backgroundColor: theme.other.getColor(
							'fill/info-secondary/default'
						),
					};
				} else if (variant === 'default') {
					colorValues = {
						color: theme.other.getColor('text/primary/default'),
						backgroundColor: theme.other.getColor('fill/secondary/default'),
					};
				} else if (variant === 'warning') {
					colorValues = {
						color: theme.other.getColor('text/warning/default'),
						backgroundColor: theme.other.getColor(
							'fill/warning-secondary/default'
						),
					};
				} else if (variant === 'critical') {
					colorValues = {
						color: theme.other.getColor('text/critical/default'),
						backgroundColor: theme.other.getColor(
							'fill/critical-secondary/default'
						),
					};
				} else if (variant === 'inverse') {
					colorValues = {
						color: theme.other.getColor('text/inverse/default'),
						backgroundColor: theme.other.getColor('fill/inverse/default'),
					};
				} else if (variant === 'ai') {
					colorValues = {
						color: theme.other.getColor('text/ai/default'),
						backgroundColor: theme.other.getColor('fill/ai-secondary/default'),
					};
				}
			}

			if (['transparent'].includes(context.variant || '')) {
				colorValues = {};
			}

			let radius = theme.radius.md;

			if (params.radius !== undefined) {
				radius = theme.radius?.[params.radius] ?? params.radius;
			}

			return {
				root: {
					...colorValues,
					marginTop: theme.spacing.xs,
					marginBottom: theme.spacing.xs,
					fontSize: theme.fontSizes.xs,
					borderRadius: radius,
					height: theme.spacing.lg,
					lineHeight: theme.other.typography.lineHeight.text.lg,
					paddingLeft: theme.other.space[2],
					paddingRight: theme.other.space[2],
					fontWeight: theme.other.typography.weight.regular,
					textTransform: 'capitalize',
				},
				inner: {
					display: 'flex',
					flexDirection: 'row',
				},
			};
		},
		variants: {
			transparent: () => ({
				root: {
					backgroundColor: 'transparent',
				},
			}),
		},
	},
};
