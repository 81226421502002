import { Box, useMantineTheme } from '@mantine/core';
import { Text } from '@repo/foundations';

export function FilterItemEmpty({ children }: { children: React.ReactNode }) {
	const theme = useMantineTheme();

	return (
		<Box
			style={{
				margin: `0 ${theme.spacing['2xs']}`,
				width: `calc(100% - (2 * ${theme.spacing['2xs']}))`,
				padding: `${theme.spacing['2xs']}`,
			}}
		>
			<Text size="sm" color="text/primary/default">
				{children}
			</Text>
		</Box>
	);
}
