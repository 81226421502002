import { Divider, Group, Stack, createStyles } from '@mantine/core';
import { Button, Text } from '@repo/foundations';

interface DeleteModal {
	text: string;
	onClose: () => void;
	onConfirm: () => Promise<void>;
}

const useStyles = createStyles((theme) => ({
	content: {
		padding: theme.spacing.md,
	},
	footer: {
		padding: theme.spacing.md,
	},
	divider: {
		margion: 0,
	},
}));

function DeleteModal({ text, onClose, onConfirm }: DeleteModal) {
	const { classes } = useStyles();

	return (
		<Stack spacing="0">
			<Stack spacing="sm" className={classes.content}>
				<Text>{text}</Text>
			</Stack>
			<Divider size="xs" className={classes.divider} />
			<Group spacing="sm" position="right" className={classes.footer}>
				<Button onClick={onClose}>Cancel</Button>
				<Button
					type="submit"
					tone="critical"
					variant="primary"
					onClick={onConfirm}
				>
					Confirm
				</Button>
			</Group>
		</Stack>
	);
}

export default DeleteModal;
