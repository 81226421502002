import type {
	IApiListResponse,
	IUseListQueryHookArgs,
	IWorkspace,
} from '../../types';
import { useBaseModelList } from '../base';
import { WORKSPACE_NAMESPACE, workspaceQueryKeyFactory } from './constants';

export function useWorkspaceList<T = IApiListResponse<IWorkspace>>({
	page,
	filters,
	options = {},
}: IUseListQueryHookArgs<IWorkspace, T>) {
	return useBaseModelList<IWorkspace, T>({
		queryKey: workspaceQueryKeyFactory.list(page, filters),
		namespace: WORKSPACE_NAMESPACE,
		page,
		filters,
		options,
	});
}
