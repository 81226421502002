import { Menu } from '@mantine/core';
import { Icon, IconButton } from '@repo/foundations';
import { useBackgroundJob2 } from '../../BackgroundJobProgress/BackgroundJob2.hooks';
import { lineageStore } from '../store';

export function DownloadMenuButton() {
	const [startJob] = useBackgroundJob2(
		undefined,
		'Export completed successfully',
		true
	);

	const handleExportToCSV = async () => {
		const backgroundJob = await lineageStore.exportToCSV();
		if (backgroundJob) {
			startJob(backgroundJob.id, 'impact-analysis.csv');
		}
	};

	return (
		<Menu>
			<Menu.Target>
				<IconButton variant="tertiary" iconName="dots" />
			</Menu.Target>
			<Menu.Dropdown>
				<Menu.Item
					icon={<Icon name="download" />}
					onClick={lineageStore.exportToPNG}
				>
					Export PNG image
				</Menu.Item>
				<Menu.Item icon={<Icon name="download" />} onClick={handleExportToCSV}>
					Export CSV file
				</Menu.Item>
			</Menu.Dropdown>
		</Menu>
	);
}
