import { size } from 'lodash-es';
import { useMemo } from 'react';
import type { IBaseModel, ISecodaEntity } from '../../api';
import type { ExtendedDataTableColumn } from '../../components/TableV2/types';

import { EntityType } from '@repo/common/enums/entityType';
import { FilterOptionType } from '../../components/Filter';
import { propagateMetadataModalStore } from '../../components/PropagateMetadata';
import { closeSpotlight } from '../../components/Spotlight/events';
import {
	CapitalizeTextRender,
	IntegrationRender,
	LastUpdatedRender,
	ParentRenderer,
	PopularityRender,
} from '../../components/TableV2/render';
import {
	useGenericActions,
	useGenericColumns,
} from '../../components/TableV2/SecodaEntity.hooks';

export const useColumns = (): ExtendedDataTableColumn<ISecodaEntity>[] => {
	const genericColumns = useGenericColumns({});

	const columns: ExtendedDataTableColumn<ISecodaEntity>[] = useMemo(() => {
		const titleColumn = genericColumns.find(
			(column) => column.accessor === 'title'
		)!;
		const descriptionColumn = genericColumns.find(
			(column) => column.accessor === 'description'
		)!;

		const otherColumns = genericColumns.filter(
			(column) => !['title', 'description'].includes(column.accessor)
		);
		return [
			titleColumn,
			{
				accessor: 'type',
				esAccessor: 'native_type',
				title: 'Type',
				width: 150,
				filterOptionType: FilterOptionType.NATIVE_TYPE,
				render: (record) => (
					<CapitalizeTextRender
						record={record}
						field={'native_type'}
						field2="entity_type"
					/>
				),
			},
			{
				accessor: 'integration',
				esAccessor: 'integration_id',
				title: 'Integration',
				render: (record) => <IntegrationRender record={record} />,
				width: 150,
				filterOptionType: FilterOptionType.INTEGRATION,
			},
			{
				accessor: 'parent',
				esAccessor: 'parent_id',
				title: 'Parent',
				render: (record) => <ParentRenderer record={record} />,
				width: 150,
				filterOptionType: FilterOptionType.PARENT_ID,
			},
			descriptionColumn,
			{
				accessor: 'external_usage',
				title: 'Popularity',
				render: (record) => <PopularityRender record={record} />,
				width: 150,
			},
			...otherColumns,
			{
				accessor: 'external_updated_at',
				title: 'External last updated',
				render: (record) => (
					<LastUpdatedRender record={record} field={'external_updated_at'} />
				),
				width: 200,
				filterOptionType: FilterOptionType.EXTERNALLY_UPDATED_TIME,
			},
		];
	}, [genericColumns]);

	return columns;
};

export const useActions = () => {
	const genericActions = useGenericActions();

	const actions = useMemo(
		() => [
			{
				id: 'actions::propagate',
				title: 'Propagate',
				name: 'Propagate',
				iconName: 'arrowsSplit' as const,
				hotkey: '/sp',
				type: EntityType.all,
				team: undefined,
				category: 'actions',
				show: (selected: IBaseModel[]) => size(selected) === 1,
				onClick: async (selected: IBaseModel[], clearSelected) => {
					if (size(selected) === 1) {
						closeSpotlight('bulkActions');
						propagateMetadataModalStore.openModal(selected[0].id);
						clearSelected();
					}
				},
			},
			...genericActions,
		],
		[genericActions]
	);

	return actions;
};
