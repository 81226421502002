import { createContext, useContext, useMemo } from 'react';
import type { Automation } from '../../../../api';
import { AutomationTriggerCardStore } from './store';

const AutomationTriggerCardStoreContext =
	createContext<AutomationTriggerCardStore | null>(null);

export interface AutomationTriggerCardStoreProviderProps {
	automation: Automation;
	children: React.ReactNode;
}

export function AutomationTriggerCardStoreProvider({
	automation,
	children,
}: AutomationTriggerCardStoreProviderProps) {
	const store = useMemo(
		() => new AutomationTriggerCardStore(automation),
		[automation]
	);

	return (
		<AutomationTriggerCardStoreContext.Provider value={store}>
			{children}
		</AutomationTriggerCardStoreContext.Provider>
	);
}

export function useAutomationTriggerCardStore() {
	const ctx = useContext(AutomationTriggerCardStoreContext);
	if (ctx === null) {
		throw new Error(
			'useFormContext was called outside of FormProvider context for AutomationTriggerCardStore'
		);
	}

	return ctx;
}
