import type {
	SuggestAnalyticsMonitorOut,
	SuggestDirectMonitorOut,
} from '@repo/api-codegen';
import type { IconNames } from '@repo/foundations';
import type { ColorNames } from '@repo/theme/utils';

export type MonitorType = SuggestAnalyticsMonitorOut | SuggestDirectMonitorOut;

export const isAnalyticsMonitor = (
	type: MonitorType
): type is SuggestAnalyticsMonitorOut => type.metric_type === 'analytics';

export function equalMonitorTypes(a: MonitorType, b: MonitorType) {
	if (a.metric_type !== b.metric_type) return false;
	if (isAnalyticsMonitor(a) !== isAnalyticsMonitor(b)) return false;
	if (isAnalyticsMonitor(a) && isAnalyticsMonitor(b))
		return a.integration_metric === b.integration_metric;
	return true;
}

export type MonitorSpec = {
	iconName: IconNames;
	iconColor: ColorNames;
	backgroundColor: ColorNames;

	title: string;
	description: string;
	group: 'Table' | 'Column' | 'Custom' | 'Warehouse' | 'Tests' | 'Jobs';
	integrationType?: string; // Only exist on analytics monitor
	type: MonitorType;
};
