import type { MantineThemeOverride } from '@mantine/core';

export const InputWrapper: MantineThemeOverride['components'] = {
	InputWrapper: {
		styles: (theme) => ({
			label: {
				marginBottom: `calc(${theme.spacing.xs} / 2)`,
			},
			description: {
				fontSize: theme.other.typography.text.sm,
				lineHeight: theme.other.typography.lineHeight.text.sm,
				color: theme.other.getColor('text/secondary/default'),
			},
			error: {
				marginTop: `calc(${theme.spacing.xs} / 2)`,
				fontSize: theme.fontSizes.sm,
			},
		}),
	},
};
