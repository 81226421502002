import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { capitalize } from 'lodash-es';

dayjs.extend(isSameOrAfter);

export enum CaseType {
	LOWER_CASE = 'lowerCase',
	SENTENCE_CASE = 'sentenceCase',
	UPPER_CASE = 'upperCase',
	TITLE_CASE = 'titleCase',
}

export function titleFromIdentifier(k?: string): string {
	// @ts-expect-error TS(2532): Object is possibly 'undefined'.
	return k.includes('_') ? capitalize(k?.replaceAll('_', ' ')) : capitalize(k);
}

export function gracefulBase64Decode(str: string): string {
	try {
		const decoded = atob(str);
		return decoded;
	} catch (error) {
		return str;
	}
}

export function removeWorkspaceId(str: string) {
	if (str.includes('~')) {
		return str.split('~')[1];
	}
	return str;
}

export function getCookie(name: string): string {
	let cookie = '';
	document.cookie.split(';').forEach((el) => {
		const [k, v] = el.split('=');
		if (k.trim() === name) {
			cookie = v;
		}
	});
	return cookie;
}

export function getUserIdCookie(): string {
	return getCookie('id');
}

export function getAPIEndpoint(): string {
	return window.localStorage.getItem('secoda::api_endpoint') ?? '';
}

export function getHash(s: string): number {
	return Math.abs(
		s.split('').reduce((a, b) => {
			// eslint-disable-next-line no-param-reassign, no-bitwise
			a = (a << 5) - a + b.charCodeAt(0);
			// eslint-disable-next-line no-bitwise
			return a & a;
		}, 0)
	);
}

export const uuidRegex =
	/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/i;

export const entityIdFromCurrentPath = () => {
	// Do not match UUIDs in `/teams/` or `/ai/` paths
	if (window.location.pathname.includes('teams')) {
		return null;
	}

	if (window.location.pathname.includes('ai')) {
		return null;
	}

	const match = window.location.pathname.match(uuidRegex);
	return match ? match[0] : null;
};

export const entityBasePathFromCurrentPath = () => {
	const match = window.location.pathname.match(/\/([^/]+)\/[^/]+$/);
	return match ? match[1] : null;
};

export function getResourceKeyFromURL(): string | null {
	return entityIdFromCurrentPath();
}

export const snakeCaseToTitleCase = (str: string) =>
	str.replace(/^_*(.)|_+(.)/g, (s, c, d) =>
		c ? c.toUpperCase() : ` ${d.toUpperCase()}`
	);

export const withParanthesis = (count?: number) => (count ? `(${count})` : '');

/**
 * Sanitizes the markdown string generated by RichEditor to remove the escape characters for empty lines.
 * @param str markdown string
 * @returns sanitized string
 */
export function sanitizeMarkdownString(str: string | null | undefined): string {
	return (str ?? '')
		.replaceAll('\\', '')
		.split('\n')
		.map((line) => {
			const regex = /( {0,}#{1,}.*) \{#\}/;
			const match = regex.exec(line);
			return match ? match[1] : line;
		})
		.join('\n');
}
