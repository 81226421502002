import { showNotification } from '@mantine/notifications';
import { useMutation } from '@tanstack/react-query';
import { USERS_NAMESPACE } from '.';
import { createMockableHook } from '../../../utils/createMockableHook';
import { apiClient, getEndpoints } from '../../common';
import queryClient from '../../queryClient';
import { thisUserQueryKey } from './useThisUser';

interface RequestData {
	file: File;
}

export function useUserProfileImageInternal() {
	const mutationFn = async ({ file }: RequestData) => {
		const url = getEndpoints(USERS_NAMESPACE).byPath([
			'profile_picture',
			'upload',
		]);
		const formData = new FormData();
		formData.append('file', file);
		const { data } = await apiClient.post<string>(url, formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});
		return data;
	};

	return useMutation<string, Error, RequestData>({
		mutationFn,
		onSuccess() {
			queryClient.invalidateQueries(thisUserQueryKey);
		},
		onError() {
			showNotification({
				autoClose: false,
				title: 'Error',
				message: 'An error has ocurred when uploading the image.',
				color: 'red',
			});
		},
	});
}

export const [useUserProfileImage, MockUseUserProfileImage] =
	createMockableHook(useUserProfileImageInternal);
