import { useDebounceEffect } from 'ahooks';

import { isNil } from 'lodash-es';
import { useState } from 'react';
import { useIntegrationPrefetched } from '../../../api';
import DescriptionEditor from '../../DescriptionEditor';
import DescriptionEditorTooltip from '../../DescriptionEditor/DescriptionEditorTooltip';

export interface IEntityPageDescriptionProps {
	entityId?: string;
	description: string;
	readOnly?: boolean;
	onChange: (title: string) => void;
	integrationId?: string;
	placeholder?: string;
	withAi?: boolean;
	dataTestId?: string;
}

function EntityPageDescription({
	entityId,
	description,
	readOnly = false,
	onChange,
	integrationId,
	placeholder,
	withAi = true,
	dataTestId,
}: IEntityPageDescriptionProps) {
	const [inputValue, setInputValue] = useState<string>(description);

	const { data: integration } = useIntegrationPrefetched({
		id: integrationId || '',
		options: {
			enabled: !isNil(integrationId),
		},
	});

	const persistDescription = integration
		? !!integration.credentials?.use_native_descriptions
		: undefined;

	// Debounce the callback.
	useDebounceEffect(
		() => {
			// Prevent the callback from being called on first load.
			if (description !== inputValue) {
				onChange(inputValue);
			}
		},
		[inputValue],
		{ wait: 250 }
	);

	if (integrationId && persistDescription) {
		return (
			<DescriptionEditorTooltip
				integrationId={integrationId}
				initialValue={inputValue}
				persistDescription={persistDescription}
			/>
		);
	}

	return (
		<DescriptionEditor
			entityId={entityId}
			readOnly={readOnly || persistDescription}
			value={inputValue}
			onChange={setInputValue}
			placeholder={placeholder}
			withAi={withAi}
			dataTestId={dataTestId}
		/>
	);
}

export default EntityPageDescription;
