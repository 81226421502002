import { useParams } from 'react-router';
import { uuidRegex } from '../shared.utils';

export const useParamsIdSuffixUuid = () => {
	const params = useParams();
	const titleAndUUID = params.id ?? '';
	const match = titleAndUUID.match(uuidRegex);
	if (match) {
		return match[0];
	}

	return titleAndUUID;
};
