import type { Command } from 'prosemirror-state';
import Extension from '../lib/Extension';

export default class PreventTab extends Extension {
	get name() {
		return 'preventTab';
	}

	keys(): Record<string, Command> {
		return {
			Tab: () => true,
			'Shift-Tab': () => true,
		};
	}
}
