import {
	Alert,
	createStyles,
	Divider,
	Flex,
	Group,
	Select,
	Stack,
	useMantineTheme,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import type { SlackConverstationOut } from '@repo/api-codegen';
import {
	apiQueryKey,
	useDeleteSlackChannel,
	useGetSlackConversations,
} from '@repo/api-codegen';
import { integrationList } from '@repo/common/constants/integration/integrations.ts';
import { EntityType } from '@repo/common/enums/entityType';
import { Button, Icon, Text } from '@repo/foundations';
import { useMutation } from '@tanstack/react-query';
import { isNil, size } from 'lodash-es';
import type React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
	queryClient,
	useIntegration,
	useUpdateIntegration,
} from '../../../api';
import type { FetchModelListHook } from '../../../api/factories/types.ts';
import type { SlackIntegrationSpec } from '../../../interfaces/IntegrationSpec';
import type { ISecodaSlackChannel } from '../../../lib/models';
import { Slack } from '../../../lib/models';
import { useFeatureFlags } from '../../../utils/featureFlags';
import { Setting } from '../../Settings/Setting.tsx';
import { closeSpotlight } from '../../Spotlight';
import { TableV2 } from '../../TableV2';
import { SlackOAuthButton } from '../IntegrationForm';
import { useSecodaChannelList } from './SecodaChannelHooks.hooks.tsx';
import { useColumns } from './SecodaChannelHooks.tsx';
import { SlackChannelModal } from './SlackChannelModal.tsx';

interface IIntegrationSlackChannelsProps {
	integrationId: string;
}

const useStyles = createStyles(() => ({
	testMessageSelect: {
		flexGrow: 1,
	},
	testMessageWrapper: {
		width: 500,
	},
}));

const QUICK_ACTIONS = [
	'actions::test_channel',
	'actions::edit',
	'actions::delete',
] as const;

/**
 * IntegrationSlackChannels Component.
 * It fetches integration data, manages its Slack channels, and updates the changes.
 *
 * @param {IIntegrationSlackChannelsProps} props - Component properties.
 * @param {string} props.integrationId - ID of the integration.
 *
 * @returns {JSX.Element} The rendered component.
 */
function IntegrationSlackChannels({
	integrationId,
}: IIntegrationSlackChannelsProps): JSX.Element {
	// Fetch the integration data by its id
	const { data: integration } = useIntegration({ id: integrationId });
	const { slackV3 } = useFeatureFlags();

	const secodaChannelList = useSecodaChannelList();
	const { data: secodaChannels } = secodaChannelList({});

	const columns = useColumns();

	const theme = useMantineTheme();

	// Initialize the state variables for webhook and data request channels
	const [webHookChannel, setWebHookChannel] = useState<string>('');
	const [incidentsChannel, setIncidentsChannel] = useState<string>('');
	const [dataRequestChannel, setDataRequestChannel] = useState<string>('');
	const [forceRefresh, setForceRefresh] = useState<boolean>(false);
	const [editChannel, setEditChannel] = useState<
		ISecodaSlackChannel | undefined
	>(undefined);

	const [
		channelModalOpened,
		{ close: closeChannelModal, open: openChannelModal },
	] = useDisclosure(false);

	// Initialize the mutation function for updating the integration
	const { mutate, isLoading } = useUpdateIntegration({
		disableOptimisticUpdate: true,
		disableInvalidation: true,
	});

	const { data: slackChannels = [], status } = useGetSlackConversations({
		queryParams: {
			refresh: forceRefresh,
		},
	});
	const { mutateAsync: deleteChannel } = useDeleteSlackChannel({
		onSuccess: () => {
			queryClient.invalidateQueries(apiQueryKey('integration/slack/channel'));
		},
	});

	const channels = useMemo(
		() =>
			slackChannels.map((channel: SlackConverstationOut) => ({
				label: channel.name,
				value: channel.id,
			})) as { label: string; value: string }[],
		[forceRefresh, status]
	);

	const { classes } = useStyles();
	const {
		reset: resetTestMessage,
		isLoading: testMessageLoading,
		isError: testMessageError,
		isSuccess: testMessageSuccess,
		mutate: testMessageMutate,
	} = useMutation({
		async mutationFn(channel: string) {
			const response = await Slack.channelTest(channel);
			return response.data;
		},
		onSuccess: (channel: string) => {
			if (slackV3) {
				resetTestMessage();
				showNotification({
					title: 'Test message successful',
					message: 'A message has been sent to the channel ' + channel,
					color: 'green',
				});
			}
		},
		onError: (channel: string) => {
			if (slackV3) {
				resetTestMessage();
				showNotification({
					title: 'Access request failed to send',
					message: 'Test message failed for channel ' + channel,
					color: 'red',
				});
			}
		},
	});
	const [testChannel, setTestChannel] = useState<string>('');
	const sendTestMessage = () => {
		if (testChannel !== '') {
			testMessageMutate(testChannel);
		}
	};
	const testChannelSelected = testChannel !== '';

	// Define a callback to update the state of the webhook and data request channels
	const updateHookChannelCallback = useCallback(() => {
		if (isNil(integration)) {
			return;
		}

		setWebHookChannel(
			(integration.credentials?.webhook_channel_id ?? '') as string
		);
		setIncidentsChannel(
			(integration.credentials?.incidents_channel_id ?? '') as string
		);
		setDataRequestChannel(
			(integration.credentials?.data_request_channel_id ?? '') as string
		);
	}, [integration]);
	const handleNewChannelOpen = () => {
		setEditChannel(undefined);
		openChannelModal();
	};

	// Use the callback to update the state whenever the integration data changes
	useEffect(() => {
		updateHookChannelCallback();
	}, [updateHookChannelCallback]);

	/**
	 * Handles form submission event.
	 * Prevents default form submission behavior and calls the mutation to update the integration.
	 *
	 * @param {React.FormEvent} e - Form submission event.
	 */
	const handleSubmit = (e: React.FormEvent) => {
		e.preventDefault();
		mutate({
			data: { id: integrationId, credentials: { ...integration?.credentials } },
		});
	};

	/**
	 * Handles change event for the webhook channel.
	 * Updates the webhook channel state and modifies the integration data accordingly.
	 *
	 * @param {string} value - Channel id.
	 */
	const handleWebhookChannelChange = (value: string) => {
		setWebHookChannel(value);
		if (integration) {
			const channel = channels.find((c) => c.value === value);
			integration.credentials.webhook_channel_id = value;
			integration.credentials.webhook_channel_name = channel?.label ?? '';
		}
	};

	/**
	 * Handles change event for the incidents channel.
	 * Updates the incident channel state and modifies the integration data accordingly.
	 *
	 * @param {string} value - Channel id.
	 */
	const handleIncidentsChannelChange = (value: string) => {
		setIncidentsChannel(value);
		if (integration) {
			const channel = channels.find((c) => c.value === value);
			integration.credentials.incidents_channel_id = value;
			integration.credentials.incidents_channel_name = channel?.label ?? '';
		}
	};

	/**
	 * Handles change event for the data request channel.
	 * Updates the data request channel state and modifies the integration data accordingly.
	 *
	 * @param {string} value - Channel id.
	 */
	const handleDataRequestChannelChange = (value: string) => {
		setDataRequestChannel(value);
		if (integration) {
			const channel = channels.find((c) => c.value === value);
			integration.credentials.data_request_channel_id = value;
			integration.credentials.data_request_channel_name = channel?.label ?? '';
		}
	};

	if (slackV3) {
		return (
			<Stack>
				<Divider my={theme.spacing['3xs']} />
				<TableV2
					usePaginationList={
						secodaChannelList as FetchModelListHook<ISecodaSlackChannel>
					}
					columns={columns}
					height={secodaChannels?.count === 0 ? 228 : undefined}
					withCheckbox
					pluralTypeString="channels"
					withSearch
					withFilters={false}
					withAdditionalButtons={
						<Button onClick={handleNewChannelOpen}>Add channel</Button>
					}
					// FIXME: move to useActions similar to columns
					withActions={[
						{
							id: 'actions::test_channel',
							title: 'Test channel',
							name: 'Test channel',
							iconName: 'send' as const,
							hotkey: '/tc',
							type: EntityType.all,
							team: undefined,
							category: 'actions',
							show: true,
							onClick: (selected) => {
								selected.map((channel) => {
									resetTestMessage();
									setTestChannel(channel.channel_id);
									sendTestMessage();
								});
							},
						},
						{
							id: 'actions::edit',
							title: 'Edit channel',
							name: 'Edit channel',
							iconName: 'edit' as const,
							hotkey: '/ec',
							type: EntityType.all,
							team: undefined,
							category: 'actions',
							show: (selected: ISecodaSlackChannel[]) => size(selected) === 1,
							onClick: (selected, clearSelected) => {
								setEditChannel(selected[0]);
								openChannelModal();
								clearSelected();
								closeSpotlight('bulkActions');
							},
						},
						{
							id: 'actions::delete',
							title: 'Delete',
							name: 'Delete',
							iconName: 'trash' as const,
							hotkey: '/sp',
							type: EntityType.all,
							team: undefined,
							category: 'actions',
							show: true,
							onClick: async (
								selected: ISecodaSlackChannel[],
								clearSelected
							) => {
								selected.map((channel) => {
									deleteChannel({
										pathParams: {
											channelId: channel.id,
										},
									});
									clearSelected();
									closeSpotlight('bulkActions');
								});
							},
						},
					]}
					withQuickActions={QUICK_ACTIONS}
				/>
				<Divider my={theme.spacing['3xs']} />
				<Setting
					title="Authorization"
					description="Slack can be re-authorized by clicking the button"
				>
					<SlackOAuthButton
						spec={
							integrationList.find(
								(i) => i.type === 'slack'
							) as SlackIntegrationSpec
						}
						integration={integration}
					/>
				</Setting>
				<SlackChannelModal
					opened={channelModalOpened}
					onClose={closeChannelModal}
					existingChannel={editChannel}
				/>
			</Stack>
		);
	}

	// Render the component
	return (
		<Stack>
			<form onSubmit={handleSubmit}>
				<Select
					mb={24}
					data={channels}
					searchable
					label="Notifications Channel"
					value={webHookChannel}
					onChange={handleWebhookChannelChange}
				/>

				<Select
					mb={24}
					data={channels}
					searchable
					label="Monitoring Incidents Channel"
					value={incidentsChannel}
					onChange={handleIncidentsChannelChange}
				/>

				<Select
					data={channels}
					searchable
					label="Data Requests Channel"
					value={dataRequestChannel}
					onChange={handleDataRequestChannelChange}
				/>

				<Group spacing="xs">
					<Button
						loading={isLoading}
						mt={24}
						size="md"
						type="submit"
						variant="primary"
						disabled={isLoading}
					>
						Save Changes
					</Button>

					<Button
						loading={isLoading}
						mt={24}
						leftIconName="refresh"
						disabled={forceRefresh}
						onClick={() => setForceRefresh(true)} // Only callable once per render to reduce calls
					>
						Refresh
					</Button>
				</Group>
			</form>

			<Text size="lg" fw={600} mt={12}>
				Send a test message
			</Text>
			<Flex className={classes.testMessageWrapper} align="flex-end">
				<Select
					className={classes.testMessageSelect}
					data={channels}
					searchable
					label="Select Channel"
					value={testChannel}
					onChange={(value) => setTestChannel(value || '')}
				/>
				<Button
					ml={10}
					mb={3}
					size="md"
					disabled={!testChannelSelected}
					loading={testMessageLoading}
					variant="default"
					onClick={sendTestMessage}
				>
					Send Test Message
				</Button>
			</Flex>

			{testMessageSuccess && (
				<Alert
					mt={0}
					variant="light"
					color="green"
					icon={<Icon name="check" />}
					withCloseButton
					onClose={resetTestMessage}
				>
					<Text>Test message successfully sent on Slack!</Text>
				</Alert>
			)}
			{testMessageError && (
				<Alert
					variant="light"
					color="red"
					mt={0}
					icon={<Icon name="infoCircle" />}
					withCloseButton
					onClose={resetTestMessage}
				>
					<Text fw={600}>
						There was an issue sending the test message on Slack
					</Text>
					<Text>Please check your slack configuration or contact support</Text>
				</Alert>
			)}
			<Text size="lg" fw={600} mt={12}>
				Authorization
			</Text>
			<Flex mb={theme.spacing.lg}>
				<SlackOAuthButton
					spec={
						integrationList.find(
							(i) => i.type === 'slack'
						) as SlackIntegrationSpec
					}
					integration={integration}
				/>
			</Flex>
		</Stack>
	);
}

export default IntegrationSlackChannels;
