import { makeAutoObservable } from 'mobx';
import { createRef } from 'react';
import type { RichMarkdownEditor } from '../../../components/Editor/outline/src';
import removeCommentMark from '../../../components/Editor/outline/src/commands/removeCommentMark';
import { COMMENT_PLACEHOLDER_ID } from '../../../components/Editor/outline/src/marks/Comment';
import { getSpanTopOffset, handleConflicts } from './utils';

export class CommentStore {
	proseMirrorRef: React.RefObject<RichMarkdownEditor> | null;

	isLoading: boolean = false;

	focusedCommentID: string | undefined;

	commentHeightMap: { [commentID: string]: number };

	commentTopOffsetMap: { [commentID: string]: number } = {
		COMMENT_PLACEHOLDER_ID: 0,
	};

	commentIds: string[] = [];

	sortedCommentIds: string[] = [];

	placeholderVisible: boolean = false;

	padding: number = 20;

	globalTopOffset: number = -20;

	selectedText: string = '';

	// Stores the markdown editor ID of the comment being created. This helps smooth loading animation.
	uploadingEditorID: string = '';

	constructor() {
		this.proseMirrorRef = createRef<RichMarkdownEditor>();

		this.commentHeightMap = {};

		makeAutoObservable(this);
	}

	setUploadingEditorID = (editorID: string) => {
		this.uploadingEditorID = editorID;
	};

	setCommentIds = (commentIds: string[]) => {
		if (this.commentIds === commentIds) {
			return;
		}
		this.commentIds = commentIds;
	};

	setFocusedCommentId = (commentID?: string) => {
		this.focusedCommentID = commentID;
	};

	setPlaceholderVisible = (on: boolean) => {
		this.placeholderVisible = on;
		if (on) {
			this.setFocusedCommentId(COMMENT_PLACEHOLDER_ID);
		}
	};

	setSelectedText = (text: string) => {
		this.selectedText = text;
	};

	// === ProseMirror commands ===
	handleProseMirrorReplacePlaceholder = (newCommentID: string) => {
		if (!this.proseMirrorRef?.current?.view) {
			return;
		}

		const { state, dispatch } = this.proseMirrorRef.current.view;
		removeCommentMark(state, dispatch, COMMENT_PLACEHOLDER_ID, newCommentID);
		// The new comment is the root of the thread
		this.setFocusedCommentId(newCommentID);
	};

	handleProseMirrorRemoveComment = (commentID: string) => {
		if (!this.proseMirrorRef?.current?.view) {
			return;
		}
		const { state, dispatch } = this.proseMirrorRef.current.view;

		removeCommentMark(state, dispatch, commentID);

		if (commentID === this.focusedCommentID) {
			this.focusedCommentID = undefined;
		}
	};

	// ============================
	setCommentDOMHeight = (commentID: string, height: number) => {
		if (this.commentHeightMap[commentID] === height) {
			return;
		}
		this.commentHeightMap[commentID] = height;
		this.reorderComments();
	};

	/**
	 * Compute the top offset of each floating comment.
	 *
	 * NOTE: This logic can be potentially expensive and jittery if it's called from other actions
	 * or turned into an observable.
	 *
	 * It's best to call this logic explicitly from React hooks or handlers. That way it's easier to debug
	 * and it's only called at the end of a change, creating a smoother animation.
	 */
	reorderComments = () => {
		// Get top  offset of each span in the document and build a map
		const spans = document.querySelectorAll('span[commentID]');
		const naturalOffset: { [id: string]: number } = {};

		spans.forEach((span) => {
			const spanID = span.getAttribute('commentID');
			if (!(span instanceof HTMLElement) || !spanID) {
				return;
			}

			const top = getSpanTopOffset(span);

			if (!(spanID in naturalOffset)) {
				naturalOffset[spanID] = top;
			} else if (naturalOffset[spanID] > top) {
				naturalOffset[spanID] = top;
			}
		});
		// Sort the sidebar comments based on the corresponding span position in doc
		const commentIds = this.placeholderVisible
			? [...this.commentIds, COMMENT_PLACEHOLDER_ID]
			: this.commentIds;

		// Handle conflict between comments
		this.commentTopOffsetMap = handleConflicts(
			commentIds,
			naturalOffset,
			this.commentHeightMap,
			this.padding,
			this.focusedCommentID
		);

		// Set sorted comment Ids. Comments not visible on the floating sidebar are left last
		const sortedIds = commentIds
			.filter((commentID) => commentID in naturalOffset)
			.sort((a, b) => {
				const offsetA = naturalOffset[a];
				const offsetB = naturalOffset[b];

				return offsetA - offsetB;
			});

		this.sortedCommentIds = [
			...sortedIds,
			...commentIds.filter((commentID) => !(commentID in naturalOffset)),
		];
	};
	// ==============================
}
