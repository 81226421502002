import {
	Box,
	// eslint-disable-next-line no-restricted-imports
	Button,
	Center,
	createStyles,
	getStylesRef,
	Group,
	Loader,
	Menu,
	Stack,
	Tooltip,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { EmptyState } from '@repo/common/components';
import { Icon, IconButton, Text } from '@repo/foundations';
import { useCallback, useState } from 'react';
import type { IMetric, MetricNumericFormat } from '../../../../../../api';
import { useMetricDownload } from '../../../../../../api/hooks/metric/useMetricDownload';
import { useMetricExecution } from '../../../../../../api/hooks/metric/useMetricExecution';
import { formatShortTimeDifference } from '../../../../../../utils/time';
import { captureError } from '../../../../../../web-tracing';
import type { CreateGraphModalResult } from './CreateGraphModal';
import { CreateGraphModal } from './CreateGraphModal';
import { MetricChart } from './MetricChart';

const useStyles = createStyles((theme) => ({
	wrapper: {
		borderRadius: theme.radius.md,
		border: `solid ${theme.other.borderWidth.sm}px ${theme.other.getColor('border/secondary/default')}`,

		[`&:hover .${getStylesRef('hideIfNotHovered')}, &:focus-within .${getStylesRef('hideIfNotHovered')}`]:
			{
				opacity: 1,
			},
	},
	hideIfNotHovered: {
		ref: getStylesRef('hideIfNotHovered'),
		opacity: 0,
	},
}));

export interface ChartBlockProps {
	metric: IMetric;
	xAxis?: string;
	yAxis?: string;
	dimension?: string;
	numericFormat?: MetricNumericFormat;
	onDuplicate: () => void;
	onCopyLink: () => void;
	onDelete: () => void;
	onSave: (result: CreateGraphModalResult) => Promise<void>;
	readOnly?: boolean;
}

enum MetricState {
	Empty = 'empty',
	Loading = 'loading',
	Success = 'success',
	Error = 'error',
}

export function ChartBlock({
	metric,
	xAxis,
	yAxis,
	dimension,
	numericFormat,
	onDuplicate,
	onCopyLink,
	onDelete,
	onSave,
	readOnly,
}: ChartBlockProps) {
	const { classes } = useStyles();

	const [isDownloading, setIsDownloading] = useState(false);
	const { download } = useMetricDownload(metric);
	const [isEditModalOpen, { toggle: toggleEditModal, close: closeEditModal }] =
		useDisclosure(false);

	const {
		execute: refresh,
		executionStatus,
		isExecuting,
	} = useMetricExecution(metric);

	const handleDownload = useCallback(async () => {
		setIsDownloading(true);
		try {
			await download();
		} catch (e) {
			captureError(e);
		} finally {
			setIsDownloading(false);
		}
	}, [download]);

	const handleEditGraph = useCallback(
		async (result: CreateGraphModalResult) => {
			await onSave(result);
			closeEditModal();
		},
		[onSave, closeEditModal]
	);

	let state = MetricState.Empty;

	if (isExecuting) {
		state = MetricState.Loading;
	} else if (metric.results.length > 0) {
		state = MetricState.Success;
	} else if (executionStatus?.status === 'failed') {
		state = MetricState.Error;
	}

	return (
		<Stack
			className={classes.wrapper}
			spacing={0}
			id={`metric-chart-${metric.id}`}
		>
			<Group spacing="sm" p="sm" noWrap position="apart">
				<Text size="sm" weight="bold" style={{ flex: 1 }} py="3xs" pl="3xs">
					{metric.title}
				</Text>
				<Group spacing="3xs" className={classes.hideIfNotHovered}>
					<Menu>
						<Menu.Target>
							<Tooltip label="More actions">
								<IconButton iconName="dots" variant="tertiary" size="md" />
							</Tooltip>
						</Menu.Target>
						<Menu.Dropdown>
							{!readOnly && (
								<>
									<Menu.Item
										onClick={toggleEditModal}
										icon={<Icon name="pencil" />}
									>
										Edit...
									</Menu.Item>
									<Menu.Item onClick={onDuplicate} icon={<Icon name="copy" />}>
										Duplicate
									</Menu.Item>
									<Menu.Divider />
								</>
							)}
							<Menu.Item onClick={onCopyLink} icon={<Icon name="link" />}>
								Copy link
							</Menu.Item>
							<Menu.Item
								onClick={handleDownload}
								icon={<Icon name="download" />}
								disabled={isDownloading}
								rightSection={isDownloading ? <Loader size="xs" /> : undefined}
							>
								Export as CSV file
							</Menu.Item>
							{!readOnly && (
								<>
									<Menu.Divider />
									<Menu.Item
										onClick={refresh}
										icon={<Icon name="refresh" />}
										disabled={isExecuting}
									>
										<Text size="sm">
											{metric.last_run ? 'Refresh data' : 'Run query'}
										</Text>
										{metric.last_run && (
											<Text size="xs" color="text/secondary/default">
												Data from{' '}
												{formatShortTimeDifference(
													metric.last_run,
													true
												).toLocaleLowerCase()}
											</Text>
										)}
									</Menu.Item>
									<Menu.Divider />
									<Menu.Item
										onClick={onDelete}
										icon={<Icon name="trash" color="text/critical/default" />}
									>
										<Text size="sm" color="text/critical/default">
											Delete
										</Text>
									</Menu.Item>
								</>
							)}
						</Menu.Dropdown>
					</Menu>
				</Group>
			</Group>
			<Box p="md">
				{state === MetricState.Success && (
					<MetricChart
						results={metric.results}
						xAxis={xAxis}
						yAxis={yAxis}
						dimension={dimension}
						numericFormat={numericFormat}
					/>
				)}
				{state === MetricState.Empty && (
					<EmptyState
						iconName="alertCircle"
						title="No results returned from query"
						description={`Check that the ${metric.title} query is correct or try running it again.`}
						includeGoBack={false}
						withActions={<Button onClick={refresh}>Run query</Button>}
						size="sm"
					/>
				)}
				{state === MetricState.Loading && (
					<Center h={360}>
						<Loader />
					</Center>
				)}
				{state === MetricState.Error && (
					<EmptyState
						iconName="alertCircle"
						title="Query failed"
						description={`Check that the ${metric.title} query is correct or try running it again.`}
						includeGoBack={false}
						withActions={<Button onClick={refresh}>Run query</Button>}
						size="sm"
					/>
				)}
			</Box>
			{isEditModalOpen && (
				<CreateGraphModal
					initialMetricId={metric.id}
					onClose={closeEditModal}
					onSave={handleEditGraph}
					initialXAxis={xAxis}
					initialYAxis={yAxis}
					initialDimension={dimension}
					initialNumericFormat={numericFormat}
				/>
			)}
		</Stack>
	);
}
