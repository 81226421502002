import type { MantineTheme } from '@mantine/core';
import {
	ActionIcon,
	Card,
	createStyles,
	Group,
	Modal,
	Stack,
	TextInput,
} from '@mantine/core';
import { useFocusTrap } from '@mantine/hooks';
import { Icon, Text } from '@repo/foundations';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { type ChangeEvent, type KeyboardEventHandler } from 'react';
import { useAIConversation } from '../../../../api';
import {
	AIAssistantProvider,
	useAIAssistantContext,
} from '../../../../components/AIAssistant/context';
import { AIAssistantMode } from '../../../../components/AIAssistant/types';
import { closeSpotlight } from '../../../../components/Spotlight';
import AISearchResult from '../../AISearchResult/AISearchResult';
import { searchPageStore } from '../../store';

const useStyles = createStyles(
	(
		theme: MantineTheme,
		{ showSearchResults }: { showSearchResults: boolean }
	) => ({
		modal: {
			backgroundColor: 'transparent',
			boxShadow: 'none',
			top: showSearchResults ? 0 : 120,
		},
		searchCardRoot: {
			backgroundColor: 'transparent',
		},
		searchInput: {
			paddingRight: 62,
			'&:focus': {
				borderColor: theme.colors.gray[1],
				boxShadow: 'none',
			},
		},
		enterText: {
			color: theme.colors.gray[5],
			fontSize: theme.fontSizes.sm,
			fontWeight: 300,
		},
		aiIcon: {
			color: theme.colors.brand[0],
		},
	})
);

const AskAIModalInternal = observer(() => {
	const store = useAIAssistantContext();

	const { isWaitingAI, messages, submitPrompt } = useAIConversation({
		id: store.id,
	});

	const focusTrapRef = useFocusTrap();

	const showSearchResults = store.id !== 'new';

	const { classes, theme } = useStyles({
		showSearchResults,
	});

	const handleAIModalClose = () => {
		searchPageStore.toggleAIModal();
		closeSpotlight('search');
	};

	const handleSearchTermChange = (event: ChangeEvent<HTMLInputElement>) => {
		runInAction(() => {
			searchPageStore.aiSearchTerm = event.target.value;
		});
	};

	const handleEnterKeyPress: KeyboardEventHandler<HTMLInputElement> = (
		event
	) => {
		if (event.key === 'Enter') {
			event.stopPropagation();
			if (searchPageStore.aiSearchTerm !== messages?.[0]?.prompt) {
				submitPrompt(searchPageStore.aiSearchTerm);
			}
		}
	};

	return (
		<>
			<Modal
				classNames={{
					body: classes.modal,
				}}
				opened={searchPageStore.showAIModal}
				padding={0}
				size={720}
				overlayProps={{
					color: theme.colors.gray[9],
					opacity: 0.5,
					blur: 3,
				}}
				onClose={handleAIModalClose}
				withCloseButton={false}
			>
				<Stack spacing={24}>
					<Card
						ref={focusTrapRef}
						className={classes.searchCardRoot}
						py={14}
						px={16}
						shadow="lg"
					>
						<TextInput
							data-autofocus
							classNames={{
								input: classes.searchInput,
							}}
							variant="unstyled"
							autoFocus
							placeholder="Ask Secoda AI about your data"
							icon={<Icon name="sparkles" size="md" />}
							rightSection={
								!!searchPageStore.aiSearchTerm && (
									<Group spacing={4}>
										<Text className={classes.enterText}>Enter</Text>
										<Icon name="arrowBack" color="icon/secondary/default" />
									</Group>
								)
							}
							rightSectionWidth="fit-content"
							size="md"
							value={searchPageStore.aiSearchTerm}
							onChange={handleSearchTermChange}
							onKeyDown={handleEnterKeyPress}
						/>
					</Card>
					{showSearchResults && (
						<AISearchResult
							isLoading={isWaitingAI}
							result={messages?.[0]}
							onNavigate={handleAIModalClose}
						/>
					)}
				</Stack>
			</Modal>
			<ActionIcon variant="subtle" onClick={searchPageStore.toggleAIModal}>
				<Icon name="sparkles" color="fill/ai/default" />
			</ActionIcon>
		</>
	);
});

export default function AskAIModal() {
	return (
		<AIAssistantProvider mode={AIAssistantMode.MODAL} id="new">
			<AskAIModalInternal />
		</AIAssistantProvider>
	);
}
