import { ToastType } from '@repo/secoda-editor';
import type { MarkdownSerializerState } from '@repo/secoda-editor/lib/markdown/serializer';
import copy from 'copy-to-clipboard';
import { uuidv4 } from 'lib0/random';
import type Token from 'markdown-it/lib/token';
import { textblockTypeInputRule } from 'prosemirror-inputrules';
import type {
	Attrs,
	NodeSpec,
	ParseRule,
	Node as ProsemirrorNode,
} from 'prosemirror-model';
import type { Command, Transaction } from 'prosemirror-state';
import { Plugin, Selection } from 'prosemirror-state';
import { findParentNode } from 'prosemirror-utils';
import { Decoration, DecorationSet } from 'prosemirror-view';
import backspaceToParagraph from '../commands/backspaceToParagraph';
import splitHeading from '../commands/splitHeading';
import toggleBlockType from '../commands/toggleBlockType';
import { getHeadings } from '../lib/getHeadings';
import { headingToPersistenceKey } from '../lib/headingToSlug';
import type { NodeOptions } from './Node';
import Node from './Node';

const getNewHeadingId = () => `h-${uuidv4().substring(0, 8)}`;

export default class Heading extends Node {
	get name() {
		return 'heading';
	}

	get defaultOptions() {
		return {
			levels: [1, 2, 3, 4],
			collapsed: undefined,
		};
	}

	get schema(): NodeSpec {
		return {
			attrs: {
				level: {
					default: 1,
				},
				collapsed: {
					default: undefined,
				},
				id: {
					default: '',
				},
			},
			content: 'inline*',
			group: 'block',
			defining: true,
			draggable: false,
			parseDOM: this.options.levels.map(
				(level: number) =>
					({
						tag: `h${level}`,
						contentElement: (dom: HTMLElement) => {
							const content = dom.querySelector('.heading-content');
							return content ?? dom;
						},
						getAttrs: (dom: HTMLElement | string) =>
							typeof dom === 'string'
								? null
								: {
										level,
										id: dom.getAttribute('id') ?? getNewHeadingId(),
									},
					}) as ParseRule
			),
			toDOM: (node: ProsemirrorNode) => {
				const anchorId = node.attrs.id;

				const anchor = document.createElement('button');
				anchor.innerText = '#';
				anchor.type = 'button';
				anchor.className = 'heading-anchor';
				anchor.addEventListener('click', () => this.handleCopyLink(anchorId));

				const fold = document.createElement('button');
				fold.innerText = '';
				fold.innerHTML =
					'<svg fill="currentColor" width="12" height="24" viewBox="6 0 12 24" xmlns="http://www.w3.org/2000/svg"><path d="M8.23823905,10.6097108 L11.207376,14.4695888 L11.207376,14.4695888 C11.54411,14.907343 12.1719566,14.989236 12.6097108,14.652502 C12.6783439,14.5997073 12.7398293,14.538222 12.792624,14.4695888 L15.761761,10.6097108 L15.761761,10.6097108 C16.0984949,10.1719566 16.0166019,9.54410997 15.5788477,9.20737601 C15.4040391,9.07290785 15.1896811,9 14.969137,9 L9.03086304,9 L9.03086304,9 C8.47857829,9 8.03086304,9.44771525 8.03086304,10 C8.03086304,10.2205442 8.10377089,10.4349022 8.23823905,10.6097108 Z" /></svg>';
				fold.type = 'button';
				fold.className = `heading-fold ${
					node.attrs.collapsed ? 'collapsed' : ''
				}`;
				fold.addEventListener('mousedown', (event) =>
					this.handleFoldContent(event)
				);

				return [
					`h${node.attrs.level + (this.options.offset || 0)}`,
					{
						id: anchorId,
					},
					[
						'span',
						{
							contentEditable: false,
							class: `heading-actions ${
								node.attrs.collapsed ? 'collapsed' : ''
							}`,
						},
						anchor,
						fold,
					],
					[
						'span',
						{
							class: 'heading-content',
						},
						0,
					],
				];
			},
		};
	}

	toMarkdown(state: MarkdownSerializerState, node: ProsemirrorNode) {
		const anchorId = node.attrs.id ? node.attrs.id : getNewHeadingId();

		state.write(`${state.repeat('#', node.attrs.level)} `);
		state.renderInline(node);
		state.write(` {#${anchorId}}`); // format from https://www.npmjs.com/package/markdown-it-attrs#usage
		state.closeBlock(node);
	}

	parseMarkdown() {
		return {
			block: 'heading',
			getAttrs: (token: Token, tokens: Token[], i: number) => {
				let parsedAttrs = (token.attrs ?? []).reduce(
					(acc, item: [string, string]) => {
						if (item?.length !== 2) {
							return acc;
						}

						return {
							...acc,
							[item[0]]: item[1],
						};
					},
					{} as Record<string, string>
				);

				// Markdown-it-attrs might not add the ID to the correct block level, fallback to parsing the inline content
				// heading_open
				// inline { content: "text {#h-id}"}
				// heading_close
				if (!parsedAttrs.id && i + 1 < tokens.length) {
					const nextToken = tokens[i + 1];
					if (nextToken.type === 'inline') {
						const match = nextToken.content.match(/{#(h-[0-9a-z]{8})}/);
						if (match) {
							parsedAttrs = {
								...parsedAttrs,
								id: match[1],
							};
						}
					}
				}

				return {
					level: +token.tag.slice(1),
					id: parsedAttrs.id ? parsedAttrs.id : getNewHeadingId(),
				};
			},
		};
	}

	commands({ type, schema }: NodeOptions) {
		return (attrs?: Attrs) =>
			toggleBlockType(type, schema.nodes.paragraph, {
				...attrs,
				id: getNewHeadingId(),
			});
	}

	handleFoldContent = (event: MouseEvent) => {
		event.preventDefault();

		const { view, id } = this.editorState;
		const hadFocus = view.hasFocus();
		const { tr } = view.state;
		const { top, left } = (event.target as HTMLElement).getBoundingClientRect();
		const result = view.posAtCoords({ top, left });

		if (result) {
			const node = view.state.doc.nodeAt(result.inside);

			if (node) {
				const endOfHeadingPos = result.inside + node.nodeSize;
				const $pos = view.state.doc.resolve(endOfHeadingPos);
				const collapsed = !node.attrs.collapsed;

				if (collapsed && view.state.selection.to > endOfHeadingPos) {
					// Move selection to the end of the collapsed heading
					tr.setSelection(Selection.near($pos, -1));
				}

				const transaction = tr.setNodeMarkup(result.inside, undefined, {
					...node.attrs,
					collapsed,
				});

				const persistKey = headingToPersistenceKey(node, id);

				if (collapsed) {
					localStorage?.setItem(persistKey, 'collapsed');
				} else {
					localStorage?.removeItem(persistKey);
				}

				this.editor.props?.onTrackEvent?.('editor/heading-fold', {
					collapsed: String(collapsed),
				});

				view.dispatch(transaction);

				if (hadFocus) {
					view.focus();
				}
			}
		}
	};

	handleCopyLink = (anchorId: string) => {
		const hash = `#${anchorId}`;

		this.editor.props?.onTrackEvent?.('editor/heading-copy-link');

		// The existing url might contain a hash already, lets make sure to remove
		// that rather than appending another one.
		const urlWithoutHash = window.location.href.split('#')[0];
		copy(urlWithoutHash + hash);
		this.options.onShowToast(
			this.options.dictionary.linkCopied,
			ToastType.Info
		);
	};

	keys({ type, schema }: NodeOptions) {
		const options = this.options.levels.reduce(
			(items: Record<string, Command>, level: number) => ({
				...items,
				...{
					[`Shift-Ctrl-${level}`]: toggleBlockType(
						type,
						schema.nodes.paragraph,
						{
							level,
						}
					),
				},
			}),
			{}
		);

		return {
			...options,
			Backspace: backspaceToParagraph(type),
			Enter: splitHeading(type),
		};
	}

	get plugins() {
		const getAnchors = (doc: ProsemirrorNode) => {
			const decorations = getHeadings(doc).map(({ legacyId, pos }) =>
				Decoration.widget(
					pos,
					() => {
						const anchor = document.createElement('a');
						anchor.id = legacyId;
						anchor.className = 'heading-name';
						return anchor;
					},
					{
						side: -1,
						key: legacyId,
					}
				)
			);

			return DecorationSet.create(doc, decorations);
		};

		// we need to keep this plugin because some users are using these anchors to link to headings (checked on FullStory)
		const pluginLegacyAnchors: Plugin = new Plugin({
			state: {
				init: (config, state) => getAnchors(state.doc),
				apply: (tr, oldState) =>
					tr.docChanged ? getAnchors(tr.doc) : oldState,
			},
			props: {
				decorations: (state) => pluginLegacyAnchors.getState(state),
			},
		});

		// we update the URL hash when users focus/unfocus a heading node so they can quickly copy the link to the heading
		const pluginUrlHashState = new Plugin({
			filterTransaction: (tr: Transaction) => {
				// this is a hack!
				// we run the logic below inside the filterTransaction callback because view.update won't give us the selectionSet information
				if (!tr.selectionSet) {
					return true;
				}

				const headingNode = findParentNode(
					(node) => node.type.name === this.name
				)(tr.selection);

				if (headingNode?.node?.attrs?.id) {
					const anchorId = headingNode.node.attrs.id;

					window.history.replaceState(null, '', `#${anchorId}`);
				} else {
					// clear the hash if user is not focusing a heading
					window.history.replaceState(
						null,
						'',
						window.location.pathname + window.location.search
					);
				}

				return true;
			},
		});

		const removeDuplicateAnchors = new Plugin({
			view(view) {
				const allHeadings = getHeadings(view.state.doc);

				const duplicatesMap: { [key: string]: boolean } = {};

				allHeadings.forEach((heading) => {
					if (heading.id in duplicatesMap) {
						// duplicated - replace the id by a new one
						view.dispatch(
							view.state.tr.setNodeAttribute(
								heading.pos,
								'id',
								getNewHeadingId()
							)
						);
						return;
					}

					duplicatesMap[heading.id] = true;
				});

				return {};
			},
		});

		return [pluginLegacyAnchors, pluginUrlHashState, removeDuplicateAnchors];
	}

	inputRules({ type }: NodeOptions) {
		return this.options.levels.map((level: number) =>
			textblockTypeInputRule(new RegExp(`^(#{1,${level}})\\s$`), type, () => ({
				level,
				id: getNewHeadingId(),
			}))
		);
	}
}
