import { useState } from 'react';
import { useParams } from 'react-router';

import { useAuthUser, useSecodaEntity, useUpdateSecodaEntity } from '../../api';
import { getEntityPageSidebarProps } from '../../components/EntityDrawer/utils';
import EntityLogo from '../../components/EntityLogo/EntityLogo';
import EntityPageLayout from '../../components/EntityPageLayout';

import { EntityType } from '@repo/common/enums/entityType';
import { trackEvent } from '../../utils/analytics';
import { useParamsIdSuffixUuid } from '../../utils/hook/utils';
import type { DjangoValueType } from '../TemplatePage/types';
import ChartEntityTabs from './ChartEntityTabs';
import { ChartEntityTabsStore } from './ChartEntityTabs/ChartEntityTabsStore';

export interface IChartPageProps {
	id?: string;
}

function ChartPage({ id: propsId }: IChartPageProps) {
	const { tab } = useParams();
	const paramsId = useParamsIdSuffixUuid();
	const { user, workspace } = useAuthUser();

	const [chartEntityTabsStore] = useState(new ChartEntityTabsStore());

	const id = propsId || paramsId;

	const { data: chart } = useSecodaEntity({
		id,
		options: {
			useErrorBoundary: true,
			onSettled: () => {
				trackEvent('chart_entity/open', {}, user, workspace);
			},
		},
	});

	const { mutateAsync } = useUpdateSecodaEntity({});

	const updateChart = async (
		key: string,
		value: DjangoValueType,
		saveRemotely = true
	) => {
		if (saveRemotely) {
			mutateAsync({
				data: {
					id: chart!.id,
					[key]: value,
				},
			});
			trackEvent('chart/properties/update', {}, user, workspace!);
		}
	};

	if (!chart) {
		return null;
	}

	return (
		<EntityPageLayout
			key={chart.id}
			name="Chart"
			isReadOnlyTitle
			entity={chart}
			icon={<EntityLogo size={32} entity={chart} />}
			updateEntity={updateChart}
			withDescription
			{...getEntityPageSidebarProps(EntityType.chart)}
		>
			<ChartEntityTabs
				key={chart.id}
				chartEntityTabsStore={chartEntityTabsStore}
				chart={chart}
				tab={tab}
			/>
		</EntityPageLayout>
	);
}

export default ChartPage;
