import { Group, Menu } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { EntityType } from '@repo/common/enums/entityType';
import { Icon, SplitButton, Text } from '@repo/foundations';
import type { Incident } from '../../../../api';
import IntegrationLogo from '../../../../components/IntegrationLogo';
import { JiraIssueCreateModal } from './JiraIssueCreateModal';
import { JiraIssueLinkModal } from './JiraIssueLinkModal';
import { LinkedJiraIssue } from './LinkedJiraIssue';

export function IncidentJiraIssue({ incident }: { incident: Incident }) {
	const [
		isCreateModalOpened,
		{ open: openCreateModal, close: closeCreateModal },
	] = useDisclosure(false);
	const [isLinkModalOpened, { open: openLinkModal, close: closeLinkModal }] =
		useDisclosure(false);

	return (
		<>
			{incident.jira_issue?.issue_name ? (
				<LinkedJiraIssue
					issueName={incident.jira_issue.issue_name}
					issueUrl={incident.jira_issue.issue_url || ''}
					entityId={incident.id}
				/>
			) : (
				<SplitButton
					label={
						<Group spacing="xs" noWrap>
							<IntegrationLogo
								integrationType="jira"
								size={16}
								entityType={EntityType.integration}
							/>
							<Text size="xs">Create issue</Text>
						</Group>
					}
					onClick={openCreateModal}
				>
					<Menu>
						<Menu.Target>
							<Icon name="chevronDown" />
						</Menu.Target>
						<Menu.Dropdown>
							<Menu.Item icon={<Icon name="link" />} onClick={openLinkModal}>
								Link issue
							</Menu.Item>
						</Menu.Dropdown>
					</Menu>
				</SplitButton>
			)}
			<JiraIssueCreateModal
				opened={isCreateModalOpened}
				handleClose={closeCreateModal}
				entityId={incident.id}
			/>
			<JiraIssueLinkModal
				opened={isLinkModalOpened}
				handleClose={closeLinkModal}
				entityId={incident.id}
			/>
		</>
	);
}
