import { Flex } from '@mantine/core';
import { Icon } from '@repo/foundations';
import { filter, map } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useUnreadNotificationCount } from '../../../api/hooks/notification';
import { useAiEnabled } from '../../../hooks/useAIEnabled';
import { useOnboarding } from '../../../hooks/useOnboarding';
import { ensurePositive } from '../../../utils/utils';
import { isActive } from '../utils';
import type { Link } from './SideBarLink';
import SideBarLink from './SideBarLink';
import { sideBarStore } from './store';

function SideBarHeader() {
	const { showOnboarding, stepsTotal, stepsCompleted } = useOnboarding();
	const { data: unreadNotificationCount } = useUnreadNotificationCount();

	const { enableAi } = useAiEnabled();

	const location = useLocation();

	const headerLinks: Array<Link> = useMemo(
		() => [
			{
				id: 'nav::home',
				label: 'Home',
				icon: <Icon name="home" color="icon/primary/default" />,
				href: ['/', '/home'],
				matchExact: true,
				show: true,
				showCount: showOnboarding,
				count: ensurePositive(stepsTotal - stepsCompleted),
			},
			{
				id: 'nav::inbox',
				label: 'Inbox',
				icon: <Icon name="inbox" color="icon/primary/default" />,
				href: ['/inbox'],
				show: true,
				showCount: true,
				count: ensurePositive(unreadNotificationCount),
			},
			{
				label: 'Secoda AI',
				id: 'nav::suggestions',
				href: ['/ai/new'],
				target: '',
				icon: <Icon name="sparkles" color="icon/primary/default" />,
				show: enableAi,
			},
		],
		[
			enableAi,
			showOnboarding,
			stepsCompleted,
			stepsTotal,
			unreadNotificationCount,
		]
	);

	return (
		<Flex
			direction="column"
			pr={sideBarStore.collapsed ? 'none' : 'xs'}
			align={sideBarStore.collapsed ? 'center' : 'flex-start'}
		>
			{map(filter(headerLinks, 'show'), (link) => (
				<SideBarLink
					key={link.id}
					link={link}
					selected={isActive(link.href, location, Boolean(link.matchExact))}
				/>
			))}
		</Flex>
	);
}

export default observer(SideBarHeader);
