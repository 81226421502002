import { Title } from '@repo/foundations';
import { observer } from 'mobx-react-lite';
import {
	NavBarBreadcrumbWrapper,
	NavBarRightSideWrapper,
	NavBarWrapper,
} from '.';
import { useFeatureAccess } from '../../../api/hooks/workspace/useFeatureAccess';
import SelectReportTemplateMenu from '../../../pages/AnalyticsPage/components/Buttons/SelectTemplateMenu';

function AnalyticsListPageNavBar() {
	const { analyticsAccess } = useFeatureAccess();

	return (
		<NavBarWrapper>
			<NavBarBreadcrumbWrapper>
				<Title order={1} size="sm" data-testid="analytics-page-nav-bar-title">
					Analytics
				</Title>
			</NavBarBreadcrumbWrapper>
			<NavBarRightSideWrapper>
				{analyticsAccess && <SelectReportTemplateMenu />}
			</NavBarRightSideWrapper>
		</NavBarWrapper>
	);
}

export default observer(AnalyticsListPageNavBar);
