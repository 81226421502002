import { Box, createStyles, getStylesRef, Tooltip } from '@mantine/core';
import { Text } from '@repo/foundations';
import { useCallback } from 'react';
import { DeleteGlossarySynonym } from './DeleteGlossarySynonym';

const useStyles = createStyles((theme) => ({
	item: {
		background: theme.other.getColor('fill/transparent-secondary/default'),
		borderRadius: theme.radius.sm,
		padding: `0 ${theme.spacing['2xs']}`,
		position: 'relative',
		maxWidth: '260px',

		[`&:hover .${getStylesRef('deleteButton')}, &:focus .${getStylesRef('deleteButton')}, &:active .${getStylesRef('deleteButton')}`]:
			{
				opacity: 1,
			},
	},
	text: {
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
	},
	deleteButton: {
		ref: getStylesRef('deleteButton'),
		opacity: 0,
		position: 'absolute',
		right: 0,
		top: 0,
		width: 20,
		height: 20,
		padding: theme.spacing['4xs'],
		backgroundColor: theme.other.getColor('fill/secondary/default'),
		borderTopLeftRadius: 0,
		borderBottomLeftRadius: 0,

		[`&:hover, &:focus`]: {
			opacity: 1,
			backgroundColor: theme.other.getColor('fill/secondary/hover'),
		},
	},
}));

interface GlossarySynonymPillProps {
	synonym: string;
	onRemove?: (synonym: string) => Promise<void>;
}

export function GlossarySynonymPill({
	synonym,
	onRemove,
}: GlossarySynonymPillProps) {
	const { classes } = useStyles();

	const handleOnRemove = useCallback(
		() => Promise.resolve(onRemove?.(synonym)),
		[onRemove, synonym]
	);

	return (
		<Box className={classes.item} tabIndex={0}>
			<Tooltip label={synonym}>
				<Text size="sm" className={classes.text}>
					{synonym}
				</Text>
			</Tooltip>
			{onRemove && (
				<DeleteGlossarySynonym
					onRemove={handleOnRemove}
					className={classes.deleteButton}
				/>
			)}
		</Box>
	);
}
