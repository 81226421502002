import { createContext, useContext, useMemo } from 'react';
import { AutomationActionCardEditStore } from './store';

const AutomationActionCardEditStoreContext =
	createContext<AutomationActionCardEditStore | null>(null);

export interface AutomationActionCardEditStoreProviderProps {
	children: React.ReactNode;
}

export function AutomationActionCardEditStoreProvider({
	children,
}: AutomationActionCardEditStoreProviderProps) {
	const store = useMemo(() => new AutomationActionCardEditStore(), []);

	return (
		<AutomationActionCardEditStoreContext.Provider value={store}>
			{children}
		</AutomationActionCardEditStoreContext.Provider>
	);
}

export function useAutomationActionCardEditStore() {
	const ctx = useContext(AutomationActionCardEditStoreContext);
	if (ctx === null) {
		throw new Error(
			'useFormContext was called outside of FormProvider context for AutomationActionCardEditStore'
		);
	}

	return ctx;
}
