import { useMemo } from 'react';
import {
	fetchAutomationHistoryList,
	useAutomationHistoryList,
} from '../../api/hooks/automationHistory';
import { TableV2 } from '../TableV2';
import { useHistoryTableColumns } from './hooks';

interface AutomationHistoryTableProps {
	automationJobId: string;
}

const COLUMN_VISIBILITY = {
	catalogType: 'automation_history',
	catalogServerType: 'automation_history',
} as const;

export function AutomationHistoryDetailsTable({
	automationJobId,
}: AutomationHistoryTableProps) {
	const columns = useHistoryTableColumns();

	const defaultSearchParams = useMemo(
		() => ({
			automation_job_id: automationJobId,
		}),
		[automationJobId]
	);

	return (
		<TableV2
			columns={columns}
			usePaginationList={useAutomationHistoryList}
			fetchPaginationList={fetchAutomationHistoryList}
			defaultRequiredSearchParams={defaultSearchParams}
			columnVisibility={COLUMN_VISIBILITY}
			withInteractiveHeader={false}
			withFilters={false}
			withSearch
			withInfiniteScroll={false}
		/>
	);
}
