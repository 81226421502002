import { useTimeout } from '@mantine/hooks';
import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';

export function DelayedRender({
	children,
	timeout = 300,
}: {
	children: ReactNode;
	timeout?: number;
}): JSX.Element | null {
	const [canShow, setCanShow] = useState(false);
	const { clear } = useTimeout(() => setCanShow(true), timeout, {
		autoInvoke: true,
	});

	useEffect(() => clear, [clear]);

	// eslint-disable-next-line react/jsx-no-useless-fragment
	return canShow ? <>{children}</> : null;
}
