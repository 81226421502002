import { Group, Menu, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { Icon, IconButton, Text } from '@repo/foundations';
import { useCallback } from 'react';
import type { IMetric } from '../../../../../../api';
import { useFeatureFlags } from '../../../../../../utils/featureFlags';
import PopoverMenuItem from '../../../../../EntityNotificationHook/PopoverMenuItem';
import { SCHEDULE_OPTIONS } from './constants';
import { SchedulingDropdown } from './SchedulingDropdown';

interface MoreActionsMenuProps {
	readOnly?: boolean;
	metric: IMetric;
	handleDuplicate: () => void;
	toggleRenameModal: () => void;
	onChangeSchedule: (value: number | null) => void;
	onCopyLink?: () => void;
	handleCopyCode: () => void;
	onDelete?: () => void;
}

export function MoreActionsMenu({
	readOnly,
	metric,
	handleDuplicate,
	toggleRenameModal,
	onChangeSchedule,
	onCopyLink,
	handleCopyCode,
	onDelete,
}: MoreActionsMenuProps) {
	const { queryBlockScheduling } = useFeatureFlags();
	const [opened, { toggle, open, close }] = useDisclosure(false);

	const handleChangeSchedule = useCallback(
		(value: number | null) => {
			onChangeSchedule(value);
			close();
		},
		[onChangeSchedule, close]
	);

	const schedulingLabel = SCHEDULE_OPTIONS.find(
		(option) => option.value === metric.scheduled_delta
	)?.label;

	return (
		<Menu
			opened={opened}
			onChange={(shouldOpen) => (shouldOpen ? open() : close())}
		>
			<Menu.Target>
				<Tooltip label="More actions">
					<IconButton
						iconName="dots"
						variant="tertiary"
						size="md"
						onClick={toggle}
					/>
				</Tooltip>
			</Menu.Target>
			<Menu.Dropdown>
				{!readOnly && (
					<>
						<Menu.Item onClick={handleDuplicate} icon={<Icon name="copy" />}>
							Duplicate
						</Menu.Item>
						<Menu.Item
							onClick={toggleRenameModal}
							icon={<Icon name="pencil" />}
						>
							Rename
						</Menu.Item>
						<Menu.Divider />
						{queryBlockScheduling && (
							<>
								<PopoverMenuItem
									target={
										<Menu.Item
											icon={<Icon name="clock" />}
											rightSection={
												<Group spacing="4xs">
													<Text size="sm" color="text/secondary/default">
														{schedulingLabel ?? 'None'}
													</Text>
													<Icon
														name="selector"
														color="icon/secondary/default"
													/>
												</Group>
											}
											closeMenuOnClick={false}
										>
											Schedule
										</Menu.Item>
									}
								>
									<SchedulingDropdown
										value={metric.scheduled_delta ?? null}
										onChange={handleChangeSchedule}
									/>
								</PopoverMenuItem>
								<Menu.Divider />
							</>
						)}
					</>
				)}
				<Menu.Item onClick={onCopyLink} icon={<Icon name="link" />}>
					Copy link
				</Menu.Item>
				<Menu.Item onClick={handleCopyCode} icon={<Icon name="clipboard" />}>
					Copy code
				</Menu.Item>
				{!readOnly && (
					<>
						<Menu.Divider />
						<Menu.Item
							onClick={onDelete}
							icon={<Icon name="trash" color="text/critical/default" />}
						>
							<Text size="sm" color="text/critical/default">
								Delete
							</Text>
						</Menu.Item>
					</>
				)}
			</Menu.Dropdown>
		</Menu>
	);
}
