import { Group, createStyles } from '@mantine/core';
import { colors } from '@repo/theme/primitives';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { AutomationTriggerType, type Automation } from '../../../../api';
import { useAutomationStore } from '../../../../pages/AutomationPage/context';
import { isValidEnumValue } from '../../../../utils/enumUtils';
import AutomationCardButton from '../AutomationCardButton';
import { AUTOMATION_TRIGGER_OPTIONS } from './constants';
import { useAutomationTriggerCardStore } from './context';
import AutomationTriggerCardSchemaChangedHeaderText from './SchemaChange/AutomationTriggerCardSchemaChangedHeaderText';
import AutomationTriggerCardTableDropHeaderText from './TableDrop/AutomationTriggerCardTableDropHeaderText';

const useStyles = createStyles((theme) => ({
	group: {
		display: 'flex',
		alignSelf: 'stretch',
		background: colors.gray[1],
		width: '100%',
		gap: theme.spacing['2xs'],
	},
}));

interface AutomationTriggerCardHeaderTextProps {
	searchKey?: string;
	handleAutomationUpdate: (automation: Partial<Automation>) => Promise<void>;
}

function AutomationTriggerCardHeaderText({
	searchKey,
	handleAutomationUpdate,
}: AutomationTriggerCardHeaderTextProps) {
	const { classes } = useStyles();
	const store = useAutomationTriggerCardStore();
	const automationStore = useAutomationStore();
	const { automation } = automationStore;
	const defaultLabel = store.getTriggerLabel();
	const [label, setLabel] = useState<string>(defaultLabel);

	const handleOperatorOnClick = async (
		option: string | boolean
	): Promise<void> => {
		if (
			typeof option === 'string' &&
			isValidEnumValue(AutomationTriggerType, option)
		) {
			await handleAutomationUpdate({
				trigger_type: option,

				// Reset all other filter and action fields
				action_update_entities: [],
				filter_config: null,
				action_send_announcement: null,
				action_send_email: null,
				action_send_slack_message: null,
			});

			store.setSelectedTrigger(option);
			const triggerLabel = store.getTriggerLabel();
			setLabel(triggerLabel);
			automationStore.resetCards();

			return;
		}

		throw new Error('Invalid automation operator has been provided');
	};

	if (automation?.trigger_type === AutomationTriggerType.TABLE_DROP) {
		return (
			<AutomationTriggerCardTableDropHeaderText
				triggerLabel={label}
				triggerChangeOperator={handleOperatorOnClick}
				filterSetSearchKey={searchKey}
				handleAutomationUpdate={handleAutomationUpdate}
			/>
		);
	}

	if (automation?.trigger_type === AutomationTriggerType.SCHEMA_CHANGE) {
		return (
			<AutomationTriggerCardSchemaChangedHeaderText
				triggerLabel={label}
				triggerChangeOperator={handleOperatorOnClick}
				filterSetSearchKey={searchKey}
				handleAutomationUpdate={handleAutomationUpdate}
			/>
		);
	}

	return (
		<Group className={classes.group} noWrap>
			When
			<AutomationCardButton
				buttonLabel={label}
				buttonOptions={AUTOMATION_TRIGGER_OPTIONS}
				onClick={handleOperatorOnClick}
			/>
		</Group>
	);
}

export default observer(AutomationTriggerCardHeaderText);
