import { Box, Flex } from '@mantine/core';
import { Icon, IconSkeleton, Text } from '@repo/foundations';
import { TREE_INDENT } from '../constants';
import { useTreeNodeStyle } from './TreeNode.styles';

interface LoadMoreProps {
	level: number;
	onClick: () => void;
	isLoading: boolean;
}

export function LoadMore({ isLoading, level, onClick }: LoadMoreProps) {
	const { classes, theme } = useTreeNodeStyle({});

	return (
		<Box
			role="button"
			className={classes.loadMoreButton}
			pl={theme.other.space[7] + (level + 1) * TREE_INDENT}
			onClick={onClick}
		>
			<Flex align="center">
				<Box mr={theme.other.space[1]}>
					{isLoading ? <IconSkeleton /> : <Icon name="dots" />}
				</Box>
				<Text size="sm" weight="semibold" color="text/primary/default">
					Load more
				</Text>
			</Flex>
		</Box>
	);
}
