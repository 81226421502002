import type { TooltipProps } from '@mantine/core';
import { Tooltip } from '@mantine/core';
import type { PropsWithChildren, ReactNode } from 'react';

interface WithConditionalTooltipProps extends Omit<TooltipProps, 'label'> {
	tooltipLabel?: ReactNode;
}

export function WithConditionalTooltip({
	tooltipLabel,
	children,
	...rest
}: PropsWithChildren<WithConditionalTooltipProps>) {
	return tooltipLabel ? (
		<Tooltip variant="primary" label={tooltipLabel} {...rest}>
			{children}
		</Tooltip>
	) : (
		// eslint-disable-next-line react/jsx-no-useless-fragment
		<>{children}</>
	);
}
