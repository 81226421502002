import { createStyles } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { ListBox } from '@repo/foundations';
import { useFilterDropdownDate } from '../../../../../apps/frontend/src/components/Filter/FilterDropdown/useFilterDropdownDate';
import type { FilterValue } from '../types';

const useStyles = createStyles((theme) => ({
	calendar: {
		margin: `${theme.spacing['2xs']} ${theme.spacing['2xs']} 0 ${theme.spacing['2xs']}`,
	},
}));

interface FilterDropdownDateProps {
	value: FilterValue;
	onChange: (value: FilterValue, close: boolean) => void;
}

export function FilterDropdownDate({
	value,
	onChange,
}: FilterDropdownDateProps) {
	const { classes } = useStyles();

	const { dateValue, handleOnDateChange } = useFilterDropdownDate({
		value,
		onChange,
	});

	return (
		<ListBox.Dropdown>
			<DatePicker
				classNames={{ calendar: classes.calendar }}
				type="range"
				allowSingleDateInRange
				defaultDate={dateValue?.[0] ?? new Date()}
				value={dateValue}
				onChange={handleOnDateChange}
			/>
		</ListBox.Dropdown>
	);
}
