import { Tooltip } from '@mantine/core';
import { Text } from '@repo/foundations';
import { truncate } from 'lodash-es';

interface ILineageListItemTitleProps {
	title: string;
	length?: number;
}

function LineageListItemTitle({
	title,
	length = 20,
}: ILineageListItemTitleProps) {
	if (title?.length <= length) {
		return <Text size="sm">{title}</Text>;
	}

	return (
		<Tooltip label={title} position="top-start" withinPortal>
			<Text size="sm">{truncate(title, { length })}</Text>
		</Tooltip>
	);
}

export default LineageListItemTitle;
