import type {
	UseFloatingReturn,
	UseInteractionsReturn,
} from '@floating-ui/react';
import type { MutableRefObject } from 'react';
import { createContext, useContext } from 'react';

interface FloatingToolbarContextType {
	context: UseFloatingReturn['context'];
	refs: UseFloatingReturn['refs'];
	getReferenceProps: UseInteractionsReturn['getReferenceProps'];
	getItemProps: UseInteractionsReturn['getItemProps'];
	activeIndex: number | null;
	elementsRef: MutableRefObject<Array<HTMLButtonElement>>;
	reset: () => void;
}

export const FloatingToolbarContext =
	createContext<FloatingToolbarContextType | null>(null);

export function useFloatingToolbarContext() {
	const context = useContext(FloatingToolbarContext);

	if (!context) {
		throw new Error(
			'useFloatingToolbarContext must be used within a FloatingToolbarContext'
		);
	}

	return context;
}
