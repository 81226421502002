import { useMergedRef } from '@mantine/hooks';
import type { ForwardedRef } from 'react';
import { forwardRef, useEffect, useRef } from 'react';

interface IWrapperProps<T> {
	vectorEl: T;
}

export const Wrapper = forwardRef(
	<T extends SVGSVGElement | HTMLElement>(
		{ vectorEl }: IWrapperProps<T>,
		forwardedRef: ForwardedRef<HTMLDivElement>
	) => {
		const svgRef = useRef<HTMLDivElement>(null);

		useEffect(() => {
			const { current } = svgRef;
			if (current && vectorEl) {
				current.appendChild(vectorEl);
			}
			return () => {
				if (current && vectorEl) {
					current.removeChild(vectorEl);
				}
			};
		}, [svgRef, vectorEl]);

		const ref = useMergedRef(forwardedRef, svgRef);

		return <div ref={ref} />;
	}
);
Wrapper.displayName = 'Wrapper';
