import type { DefaultProps } from '@mantine/core';
import { createStyles, Group, Menu } from '@mantine/core';
import type { MenuProps } from '@mantine/core/lib/Menu/Menu';
import { Icon } from '@repo/foundations';
import type { ReactNode } from 'react';

type ICustomSelectorProps<T extends { value: string; disabled?: boolean }> = {
	items: T[];
	renderItem: (x: T) => ReactNode;
	value: string;
	onChange: (x: string) => void;
	menuProps?: MenuProps;
	targetProps?: DefaultProps;
	withoutBorder?: boolean;
	disabled?: boolean;
};

interface CustomSelectorStyleProps {
	withoutBorder?: boolean;
	disabled?: boolean;
}

const useStyles = createStyles(
	(
		theme,
		{ withoutBorder = false, disabled = false }: CustomSelectorStyleProps
	) => ({
		targetButton: {
			cursor: 'pointer',
			backgroundColor: disabled
				? theme.other.getColor('surface/primary/disabled')
				: theme.other.getColor('surface/input/default'),
			borderWidth: theme.other.borderWidth.xs,
			borderStyle: withoutBorder || disabled ? 'none' : 'solid',
			borderColor: theme.other.getColor('border/input/default'),
			borderRadius: theme.radius.sm,
			justifyContent: 'space-between',
			flexWrap: 'nowrap',
			paddingRight: theme.other.space[2],
			gap: '0',
			'&:hover': {
				borderColor: theme.other.getColor('border/input/hover'),
			},
			'&:focus, &:active': {
				borderColor: theme.other.getColor('border/input/active'),
				boxShadow: disabled
					? 'unset'
					: `0px 0px 0px 1px white, 0px 0px 0px 3px ${theme.other.getColor('border/emphasis/default')}`,
			},
		},
	})
);

export function CustomSelector<T extends { value: string; disabled?: boolean }>(
	props: ICustomSelectorProps<T>
) {
	const { items, value, disabled, renderItem, withoutBorder, onChange } = props;
	const { classes, theme } = useStyles({ withoutBorder, disabled });

	const currentItem = items.find((item) => item.value === value)!;
	// eslint-disable-next-line react/destructuring-assignment
	const menuProps = props.menuProps || {};
	// eslint-disable-next-line react/destructuring-assignment
	const targetProps = props.targetProps || {};

	return (
		<Menu {...menuProps} disabled={disabled} withinPortal>
			<Menu.Target>
				<Group role="button" {...targetProps} className={classes.targetButton}>
					{currentItem && renderItem(currentItem)}
					{!disabled && <Icon name="chevronDown" />}
				</Group>
			</Menu.Target>
			<Menu.Dropdown>
				{items.map((item) => (
					<Menu.Item
						disabled={item.disabled}
						key={item.value}
						role="button"
						onClick={(e) => {
							e.stopPropagation();
							onChange(item.value);
						}}
						p={0}
					>
						<Group position="apart" {...targetProps} pr={theme.other.space[1]}>
							{renderItem(item)}
							{item.value === value && <Icon name="check" />}
						</Group>
					</Menu.Item>
				))}
			</Menu.Dropdown>
		</Menu>
	);
}
