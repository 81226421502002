import { Stack } from '@mantine/core';
import { Button } from '@repo/foundations';
import type { QueryStatus } from '@tanstack/react-query';
import { includes, isEmpty, map } from 'lodash-es';
import type { ILineage } from '../../../../api';
import type { ButtonDetails } from '../../../EmptyState';
import { EmptyState } from '../../../EmptyState';
import { ImpactAnalysisCardWrapper } from './ImpactAnalysisCardWrapper';
import { impactAnalysisStore } from './store';

interface IImpactedAnalysisContentProps {
	status: QueryStatus;
	entities: ILineage[];
	hasNextPage?: boolean;
	fetchNextPage: () => void;
}

export function ImpactAnalysisContent({
	status,
	entities,
	hasNextPage,
	fetchNextPage,
}: IImpactedAnalysisContentProps) {
	if (isEmpty(entities) && includes(['success', 'error'], status)) {
		const buttons: ButtonDetails[] = [
			{
				name: 'Close',
				action: () => impactAnalysisStore.setOpened(false),
				isPrimary: true,
				size: 'md',
			},
		];
		return (
			<EmptyState
				iconName="chartArcs"
				title="No impacted resources found"
				description="Try viewing another resource's downstream impact."
				buttons={buttons}
				includeGoBack={false}
				size="sm"
			/>
		);
	}

	return (
		<Stack spacing="sm">
			{map(entities, (entity) => (
				<ImpactAnalysisCardWrapper entity={entity} />
			))}
			{hasNextPage && (
				<Button variant="tertiary" size="md" onClick={fetchNextPage}>
					Load more
				</Button>
			)}
		</Stack>
	);
}
