import { Anchor, createStyles, Divider, useMantineTheme } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { useWorkspaceAiFeedback } from '@repo/api-codegen';
import MarkdownRenderer from '@repo/common/components/MarkdownRenderer';
import { Text, TextArea, Title } from '@repo/foundations';
import { useDebounceFn } from 'ahooks';
import { useCallback, useState } from 'react';
import {
	useAgentTools,
	useAIPrivacySettings,
	useAuthUser,
	useDisableAgentTool,
	useEnableAgentTool,
	useGetAgentInstructions,
	useGetCustomDescriptionStyle,
	useToggleAIPrivacySetting,
	useUpdateAgentInstructions,
	useUpdateCustomDescriptionStyle,
} from '../../api';
import { useFeatureAccess } from '../../api/hooks/workspace/useFeatureAccess';
import { useUpdateWorkspace } from '../../api/hooks/workspace/useUpdateWorkspace';
import { useFeatureFlags } from '../../utils/featureFlags';
import { snakeCaseToTitleCase } from '../../utils/shared.utils';
import { EmptyState } from '../EmptyState';
import { PreferenceSection } from '../Integration/IntegrationPage/TabPanels/PreferencesPanel/PreferenceSection';
import { AIPersonas } from './AIPersonas/AIPersonas';
import { AIGovernanceFilters } from './GovernanceFilters/AIGovernanceFilters';
import { UpgradeButton } from './UpgradeButton';
import { SettingsSwitch } from './WorkspaceSettings';

export function AIToolsSettings() {
	const { workspace } = useAuthUser();
	const { aiPersonas } = useFeatureFlags();
	const { data: tools } = useAgentTools();
	const { data: privacySettings } = useAIPrivacySettings();
	const { mutateAsync: enableAgentTool } = useEnableAgentTool();
	const { mutateAsync: disableAgentTool } = useDisableAgentTool();
	const { mutateAsync: toggleAIPrivacySetting } = useToggleAIPrivacySetting();
	const { mutateAsync: updateWorkspace } = useUpdateWorkspace(workspace.id);
	const { aiAccess } = useFeatureAccess();
	const { data: aiFeedback } = useWorkspaceAiFeedback({});

	const theme = useMantineTheme();

	const { data: remoteInstructions } = useGetAgentInstructions();
	const { mutateAsync: updateAgentInstructions } = useUpdateAgentInstructions();

	const { data: remoteDescriptionStyle } = useGetCustomDescriptionStyle();
	const { mutateAsync: updateCustomDescriptionStyle } =
		useUpdateCustomDescriptionStyle();

	const [instructions, setInstructions] = useState<string | undefined>(
		undefined
	);
	const [descriptionStyle, setDescriptionStyle] = useState<string | undefined>(
		undefined
	);

	const useStyles = createStyles((theme) => ({
		textBox: {
			'& textarea': {
				resize: 'vertical',
			},
		},
	}));

	const { classes } = useStyles();

	const notifySuccessOnSave = useCallback((type: string) => {
		showNotification({
			title: `${type} saved`,
			message: '',
			autoClose: 1500,
		});
	}, []);

	const notifyErrorOnSave = useCallback(() => {
		showNotification({
			title: 'Something went wrong',
			message: 'Please enter the instructions again',
			autoClose: 1500,
		});
	}, []);

	const { run: debouncedUpdateAgentInstructions } = useDebounceFn(
		(value: string) => {
			updateAgentInstructions({ instructions: value });
			notifySuccessOnSave('Secoda AI custom instructions');
		},
		{
			wait: 1500,
		}
	);

	const { run: debouncedSetCustomDescriptionStyle } = useDebounceFn(
		(value: string) => {
			updateCustomDescriptionStyle({ instructions: value });
			notifySuccessOnSave('Secoda AI custom description instructions');
		},
		{
			wait: 1500,
		}
	);

	const saveInstructions = (value: string) => {
		setInstructions(value);
		try {
			debouncedUpdateAgentInstructions(value);
		} catch {
			notifyErrorOnSave();
		}
	};

	const saveDescriptionStyle = (value: string) => {
		setDescriptionStyle(value);
		try {
			debouncedSetCustomDescriptionStyle(value);
		} catch {
			notifyErrorOnSave();
		}
	};

	if (!aiAccess) {
		return (
			<EmptyState
				title="Upgrade to access Secoda AI"
				description="Use Secoda AI to easily access all your company data and find answers to common questions."
				illustrationName="upgrade"
				includeGoBack={false}
				stateHeight="50vh"
				size="lg"
				withActions={<UpgradeButton feature="Secoda AI" size="md" />}
			/>
		);
	}

	return (
		<div>
			<SettingsSwitch
				onChange={(e) =>
					updateWorkspace({
						data: { id: workspace.id, ai_search: e.target.checked },
					})
				}
				checked={workspace?.ai_search ?? false}
				title="Enable AI"
				description={
					<>
						Use Secoda&apos;s Secoda AI and Ask AI. By enabling Secoda AI you
						agree to our{' '}
						<Anchor
							href="https://docs.secoda.co/policies/privacy-policy"
							target="_blank"
						>
							Privacy Policy
						</Anchor>{' '}
						and our usage of{' '}
						<Anchor
							href="https://docs.secoda.co/features/ai-assistant/security"
							target="_blank"
						>
							Open AI
						</Anchor>
						.
					</>
				}
			/>

			{workspace?.ai_search && (
				<>
					<Divider my={theme.spacing.lg} />
					<PreferenceSection
						header="Access"
						subheader="Create rules to exclude resources from Secoda AI. These settings applied to all users."
					>
						<AIGovernanceFilters />
					</PreferenceSection>
					<Divider my={theme.spacing.lg} />
					<PreferenceSection
						header={'Privacy'}
						subheader={'Manage what Secoda AI can and cannot see.'}
					>
						<SettingsSwitch
							my={theme.spacing.md}
							disabled={false}
							key={'ai_mask_pii'}
							title={'Mask PII During Response Generation'}
							checked={
								privacySettings?.mask_pii_on_response_generation || false
							}
							description={
								'When querying resources, try to mask data marked as PII before being processed by Secoda AI'
							}
							onChange={() => {
								const val = !privacySettings?.mask_pii_on_response_generation;
								toggleAIPrivacySetting({
									key: 'mask_pii_on_response_generation',
									value: val,
								});
							}}
						/>
					</PreferenceSection>
					<Divider my={theme.spacing.lg} />
					<PreferenceSection
						header="Tools"
						subheader="Enable or disable AI functionality for your workspace. These settings are applied to all users."
					>
						{tools?.tools.map((tool) => (
							<SettingsSwitch
								my={theme.spacing.md}
								disabled={false}
								key={tool.name}
								title={snakeCaseToTitleCase(tool.name)}
								checked={tool.enabled}
								description={tool.frontend_description}
								onChange={() => {
									if (!tool.enabled) {
										enableAgentTool({ tool });
									} else {
										disableAgentTool({ tool });
									}
								}}
							/>
						))}
					</PreferenceSection>
					<Divider my={theme.spacing.lg} />
					{aiPersonas && (
						<>
							<PreferenceSection
								header="Agents"
								subheader="Create versions of Secoda AI for specific purposes."
							>
								<AIPersonas />
							</PreferenceSection>
							<Divider my={theme.spacing.lg} />
						</>
					)}
					<PreferenceSection
						header="Customize"
						subheader="Add custom instructions for chat and descriptions. These settings are applied to all users."
					>
						<Title size="sm">Custom chat instructions</Title>
						<Text size="sm" mb={theme.spacing.sm}>
							Provide custom instructions to Secoda AI. These instructions will
							be injected into the prompt.
						</Text>
						<TextArea
							minRows={3}
							className={classes.textBox}
							value={instructions ?? remoteInstructions}
							onChange={(e) => saveInstructions(e.target.value)}
							placeholder=""
						/>
						<Divider mb={theme.spacing.lg} />
						<Title size="sm">Custom description instructions</Title>
						<Text size="sm" mb={theme.spacing.sm}>
							Custom instructions for Secoda AI when generating descriptions for
							your resources.
						</Text>
						<TextArea
							minRows={3}
							className={classes.textBox}
							value={descriptionStyle ?? remoteDescriptionStyle}
							onChange={(e) => saveDescriptionStyle(e.target.value)}
							placeholder=""
						/>
					</PreferenceSection>

					{aiFeedback && (
						<>
							<Divider my={theme.spacing.lg} />
							<PreferenceSection
								header="AI Tips"
								subheader="Automated tips to improve your workspace's AI readiness."
							>
								<MarkdownRenderer>
									{aiFeedback?.documentation_suggestions || ''}
								</MarkdownRenderer>
								<MarkdownRenderer>
									{aiFeedback?.blind_spots || ''}
								</MarkdownRenderer>
							</PreferenceSection>
						</>
					)}
				</>
			)}
		</div>
	);
}
