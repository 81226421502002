import type { SecodaEditorComponentProps } from '@repo/secoda-editor';
import { isEqual } from 'lodash-es';
import { action, computed, makeObservable, observable } from 'mobx';
import type { FunctionComponent } from 'react';
import { createPortal } from 'react-dom';

export class NodeViewRenderer<T extends SecodaEditorComponentProps> {
	public props: T;

	public constructor(
		public container: HTMLElement,
		private Component: FunctionComponent<T>,
		props: T
	) {
		makeObservable(this, {
			content: computed,
			updateProps: action,
			props: observable,
		});

		this.props = props;
	}

	public get content() {
		return createPortal(<this.Component {...this.props} />, this.container);
	}

	public updateProps(props: T) {
		if (!isEqual(props, this.props)) {
			this.props = props;
		}
	}
}
