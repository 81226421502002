import { Box, Group } from '@mantine/core';
import { Icon, Text } from '@repo/foundations';
import { isEmpty, isNil } from 'lodash-es';
import { useStyles } from '../styles';

interface IDescriptionProps {
	description?: string;
	isNew?: boolean;
}

function Description({ description, isNew = false }: IDescriptionProps) {
	const { classes } = useStyles();

	const AddedOrRemovedIcon = isNew
		? () => <Icon name="plus" color="icon/success/default" />
		: () => <Icon name="minus" />;

	const isDescriptionEmpty = isNil(description) || isEmpty(description);

	return (
		<Group className={classes.rightCol} spacing="xs" noWrap>
			{isDescriptionEmpty ? (
				<Text className={classes.emptyLabel}>Empty</Text>
			) : (
				<Group
					className={classes.colorGroup}
					spacing={6}
					bg={isNew ? 'green.0' : 'gray.1'}
					noWrap
				>
					<Box w={18}>
						<AddedOrRemovedIcon />
					</Box>
					<Text
						className={classes.soleLabel}
						data-testid={`inbox-view-change-request-${
							isNew ? 'new' : 'old'
						}-description-26cceac93`}
					>
						{description}
					</Text>
				</Group>
			)}
		</Group>
	);
}

export default Description;
