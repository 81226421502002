import type { IMarketplaceIntegrationSpecVersion } from '@repo/common/models/marketplace';
import type { SecodaThemeShades } from '@repo/theme/types';

export const publishStatusBadgeColor = (
	publishStatus: IMarketplaceIntegrationSpecVersion['publish_status']
): SecodaThemeShades => {
	if (publishStatus === 'APPROVED') {
		return 'text/success/default';
	}
	if (publishStatus === 'SUBMITTED') {
		return 'text/warning/default';
	}
	if (publishStatus === 'REJECTED') {
		return 'text/critical/default';
	}
	return 'text/primary/default';
};

export const publishStatusBadgeBackgroundColor = (
	publishStatus: IMarketplaceIntegrationSpecVersion['publish_status']
): SecodaThemeShades => {
	if (publishStatus === 'APPROVED') {
		return 'fill/success-secondary/default';
	}
	if (publishStatus === 'SUBMITTED') {
		return 'fill/warning-secondary/default';
	}
	if (publishStatus === 'REJECTED') {
		return 'fill/critical-secondary/default';
	}
	return 'fill/secondary/default';
};

export const publishStatusBadgeLabel = (
	publishStatus: IMarketplaceIntegrationSpecVersion['publish_status']
) => {
	if (publishStatus === 'APPROVED') {
		return 'Approved';
	}
	if (publishStatus === 'SUBMITTED') {
		return 'In review';
	}
	if (publishStatus === 'REJECTED') {
		return 'Rejected';
	}

	return 'Draft';
};
