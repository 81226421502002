import type MarkdownIt from 'markdown-it';
import customFence from 'markdown-it-container';

export default function notice(md: MarkdownIt): void {
	return customFence(md, 'notice', {
		marker: ':',
		validate: () => true,
		render(tokens, idx) {
			const { info } = tokens[idx];

			if (tokens[idx].nesting === 1) {
				// Opening tag
				return `<div class="notice notice-${md.utils.escapeHtml(info)}">\n`;
			}
			// Closing tag
			return '</div>\n';
		},
	});
}
