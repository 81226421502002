type Props = {
	alt: string;
	src: string;
	title?: string;
	width?: number;
	height?: number;
};

// TODO: Update if necessary.
// @ts-expect-error TS(7006): Parameter 'path' implicitly has an 'any' type.
const cdnPath = (path) => `/images/editor${path}`;

export default function Image({ src, alt, ...rest }: Props) {
	return <img src={cdnPath(src)} alt={alt} {...rest} />;
}
