// NOTE ideally we use `Anchor` and `@repo/foundations/Button` instead of `Button` here.
// eslint-disable-next-line no-restricted-imports
import { Button, Card, Checkbox, Group, Stack, TextInput } from '@mantine/core';
import type { UseFormReturnType } from '@mantine/form';
import { randomId } from '@mantine/hooks';
import { Icon, Text, Title } from '@repo/foundations';
import { useSpecVersionFormStyles } from './MarketplaceIntegrationSpecVersionForm.helpers';
import type { MarketplaceIntegrationSpecVersionFormValues } from './MarketplaceIntegrationSpecVersionForm.hook';

export function FieldsSection({
	disabled,
	form,
}: {
	form: UseFormReturnType<MarketplaceIntegrationSpecVersionFormValues>;
	disabled: boolean;
}) {
	const { classes, theme } = useSpecVersionFormStyles();
	return (
		<>
			<Stack spacing="xs">
				<Title size="lg">Add the required user input fields</Title>
				<Text size="sm" color="text/secondary/default">
					{`These will be shown to users in this integrations connection form.
					You can access these fields in the dictionary parameter provided to your extract function.`}
				</Text>
			</Stack>
			<Stack spacing="xl">
				{form.values.form_fields.map((field, index) => (
					<Card withBorder key={field.key} p={0}>
						<Stack spacing="sm">
							<Group
								position="apart"
								p="sm"
								sx={{
									backgroundColor: theme.other.getColor(
										'surface/secondary/default'
									),
								}}
							>
								<Text size="sm" weight="semibold">
									New input
								</Text>
								<Button
									variant="subtle"
									disabled={disabled}
									sx={{ height: 36, width: 36 }}
									onClick={() => form.removeListItem('form_fields', index)}
								>
									<Icon name="trash" />
								</Button>
							</Group>
							<Group noWrap align="start" px="sm">
								<Stack spacing="3xs" sx={{ flex: 1, flexGrow: 1 }}>
									<Text weight="semibold" size="sm">
										Name
									</Text>
									<TextInput
										disabled={disabled}
										classNames={{
											input: classes.input,
											root: classes.inputRoot,
										}}
										name={`form_fields.${index}.name`}
										{...form.getInputProps(`form_fields.${index}.name`)}
									/>
								</Stack>
								<Stack spacing="3xs" sx={{ flex: 1, flexGrow: 1 }}>
									<Group spacing="xs">
										<Text weight="semibold" size="sm" inline>
											Example value
										</Text>
										<Text size="sm" color="text/secondary/default" inline>
											(Optional)
										</Text>
									</Group>
									<TextInput
										disabled={disabled}
										classNames={{
											input: classes.input,
											root: classes.inputRoot,
										}}
										name={`form_fields.${index}.placeholder`}
										{...form.getInputProps(`form_fields.${index}.placeholder`)}
									/>
								</Stack>
							</Group>
							<Stack px="sm" pb="md" w="100%">
								<Checkbox
									classNames={{
										body: classes.checkboxBody,
									}}
									disabled={disabled}
									label={
										<Text size="sm" weight="semibold" inline>
											Mark as required
										</Text>
									}
									radius="xs"
									color="gray"
									{...form.getInputProps(`form_fields.${index}.required`, {
										type: 'checkbox',
									})}
								/>
								<Checkbox
									classNames={{
										body: classes.checkboxBody,
									}}
									disabled={disabled}
									label={
										<Text size="sm" weight="semibold">
											Mark as sensitive
										</Text>
									}
									radius="xs"
									color="gray"
									{...form.getInputProps(`form_fields.${index}.sensitive`, {
										type: 'checkbox',
									})}
								/>
							</Stack>
						</Stack>
					</Card>
				))}
				{!disabled && (
					<Group>
						<Button
							p="xs"
							pl={0}
							variant="anchor"
							color="blue"
							leftIcon={<Icon name="plus" color="text/emphasis/default" />}
							onClick={() => {
								form.insertListItem('form_fields', {
									key: randomId(),
									name: '',
									placeholder: '',
									required: true,
									sensitive: false,
								});
							}}
						>
							<Text size="sm" color="text/emphasis/default" weight="bold">
								Add field
							</Text>
						</Button>
					</Group>
				)}
			</Stack>
		</>
	);
}
