import { useMantineTheme } from '@mantine/core';
import type { ChangeEvent } from 'react';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { Text } from '../Text';
import type { TextInputProps } from '../TextInput/TextInput';
import { TextInput } from '../TextInput/TextInput';

type NumberInputProps = {
	unit?: string;
	precision?: number;
	decimalSeparator?: string;
	thousandsSeparator?: string;
	// onChange subscribe to all valid changes
	onChange?: (value: number | undefined) => void;
	// onFinish subscribe only to `Enter` or input getting blur
	onFinish?: (value: number | undefined) => void;
	value?: number;
	defaultValue?: number;
} & Omit<TextInputProps, 'rightSection' | 'onChange'>;

const NumberInput = forwardRef<HTMLInputElement, NumberInputProps>(
	(
		{
			id,
			unit,
			precision,
			decimalSeparator = '.',
			thousandsSeparator = ',',
			onChange,
			onFinish,
			optional = false,
			value,
			defaultValue,
			...others
		},
		ref
	) => {
		const theme = useMantineTheme();

		const initialValue = value ?? defaultValue;
		const [internalValue, setInternalValue] = useState<number | undefined>(
			initialValue !== undefined ? Number(initialValue) : undefined
		);
		const [displayValue, setDisplayValue] = useState<string>(
			initialValue?.toString() ?? ''
		);

		const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
			if (event.key === 'Enter') {
				onFinish?.(internalValue);
			}
		};

		const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
			onFinish?.(internalValue);
		};

		const handleValidChange = (value: number | undefined) => {
			onChange?.(value);
			setInternalValue(value);
		};

		const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
			if (event.currentTarget.value === '') {
				handleValidChange(undefined);
				setDisplayValue('');
			} else if (event.currentTarget.value === '.') {
				handleValidChange(0);
				setDisplayValue('0.');
			} else if (/^[0-9]+\.?[0-9]*$/.test(event.currentTarget.value)) {
				const parsedValue = parseFloat(event.currentTarget.value);
				handleValidChange(parsedValue);
				setDisplayValue(event.currentTarget.value);
			}
		};

		const unitRef = useRef<HTMLDivElement>(null);
		const [unitWidth, setUnitWidth] = useState<number>();

		useEffect(() => {
			if (unitRef.current) {
				setUnitWidth(unitRef.current.getBoundingClientRect().width);
			}
		}, [unit]);

		const unitComponent = unit && (
			<Text
				ref={unitRef}
				color="text/secondary/default"
				size="sm"
				lineClamp={1}
				style={{
					flexShrink: 0,
					paddingLeft: theme.spacing.sm,
					paddingRight: theme.spacing.sm,
				}}
			>
				{unit}
			</Text>
		);

		return (
			<TextInput
				{...others}
				ref={ref}
				value={displayValue}
				onKeyDown={handleKeyDown}
				onChange={handleChange}
				onBlur={handleBlur}
				rightSection={unitComponent}
				rightSectionWidth={unitWidth}
			/>
		);
	}
);

NumberInput.displayName = 'NumberInput';

export { NumberInput };
