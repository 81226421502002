import type { MantineStyleSystemProps } from '@mantine/styles';

let textWidthCanvas: HTMLCanvasElement | null = null;

function getCssStyle(element: HTMLElement, prop: string) {
	return window.getComputedStyle(element, null).getPropertyValue(prop);
}

export function getTextWidth(
	text: string,
	textProps: {
		fontFamily?: MantineStyleSystemProps['ff'];
		fontSize?: MantineStyleSystemProps['fs'];
		fontWeight?: MantineStyleSystemProps['fw'];
	}
): number | null {
	if (!textWidthCanvas) {
		textWidthCanvas = document.createElement('canvas');
	}

	const context = textWidthCanvas.getContext('2d');
	if (!context) {
		return null;
	}

	let { fontFamily } = textProps;
	if (!fontFamily) {
		fontFamily = getCssStyle(document.body, 'font-family');
	}

	let { fontSize } = textProps;
	if (!fontSize) {
		fontSize = getCssStyle(document.body, 'font-size');
	}

	let { fontWeight } = textProps;
	if (!fontWeight) {
		fontWeight = getCssStyle(document.body, 'font-weight');
	}

	context.font = `${fontWeight} ${fontSize} ${fontFamily}`;

	const metrics = context.measureText(text);

	return metrics.width;
}
