import type { QueryKey, UseMutationOptions } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import { forEach } from 'lodash-es';
import { apiClient, getBaseUrl } from '../../common';
import queryClient from '../../queryClient';
import type { IEmbeddedPrompt, Namespace } from '../../types';
import { aiEmbeddedQueryKeyFactory } from './constants';

interface IEmbeddedRequest {
	prompt: string;
	parent?: string;
	user_generated?: boolean;
	persona_id?: string;
}

interface IUseEmbeddedAIArgs {
	invalidationKeys?: QueryKey[];
	options?: UseMutationOptions<IEmbeddedPrompt, unknown, RequestData, unknown>;
}

interface RequestData {
	data: IEmbeddedRequest;
	signal?: AbortSignal;
}

async function createAIResult(
	namespace: Namespace,
	data: IEmbeddedRequest,
	signal?: AbortSignal
) {
	const baseUrl = getBaseUrl();
	const path = namespace.join('/');
	const url = `${baseUrl}${path}/`;

	const response = await apiClient.post<IEmbeddedPrompt>(url, data, { signal });

	return response.data;
}

export function useCreateAIEmbedded({
	invalidationKeys = [],
	options = {},
}: IUseEmbeddedAIArgs = {}) {
	const mutationFn = ({ data, signal }: RequestData) =>
		createAIResult(aiEmbeddedQueryKeyFactory.namespace, data, signal);

	return useMutation({
		mutationFn,
		...options,
		onSettled: (data) => {
			// If the mutation failed, don't invalidate any queries.
			if (data === undefined) {
				return;
			}

			forEach(invalidationKeys, (key) => {
				queryClient.invalidateQueries(key);
			});
		},
	});
}
