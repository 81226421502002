import type { SelectablePropertyItem } from '@repo/common/components/SingleSelector/types';
import type { IconNames } from '@repo/foundations';

export type createAutomationType =
	| 'SCRATCH'
	| 'PII_TO_CHILD'
	| 'SCHEMA_TO_COLLECTION'
	| 'OWNERSHIP_ASSIGNMENT'
	| 'ADD_SUBSCRIBERS'
	| 'VERIFY_COMPLETE_RESOURCES'
	| 'ADD_SCHEMA_TO_TEAM'
	| 'PROPAGATE_METADATA_TO_UPSTREAM';

export enum AutomationActionCardType {
	FILTER_RESOURCES = 'FILTER_RESOURCES',
	FILTER_COUNT = 'FILTER_COUNT',
	EDIT_RESOURCES = 'EDIT_RESOURCES',
	SEND_ANNOUNCEMENT = 'SEND_ANNOUNCEMENT',
	SEND_EMAIL = 'SEND_EMAIL',
	SEND_SLACK_MESSAGE = 'SEND_SLACK_MESSAGE',
	PROPAGATE_METADATA = 'PROPAGATE_METADATA',
}

export enum AutomationActionMenuValue {
	FILTER_RESOURCES = 'FILTER_RESOURCES',
	EDIT_RESOURCES = 'EDIT_RESOURCES',
	PROPAGATE_METADATA = 'PROPAGATE_METADATA',
	SEND_ANNOUNCEMENT = 'SEND_ANNOUNCEMENT',
	SEND_EMAIL = 'SEND_EMAIL',
	SEND_SLACK_MESSAGE = 'SEND_SLACK_MESSAGE',
}

export interface AutomationActionCardOptions {
	type: AutomationActionCardType;
	iconName: IconNames;
}

export const automationActionMenuOptions: SelectablePropertyItem[] = [
	{
		label: 'Filter resources',
		value: AutomationActionMenuValue.FILTER_RESOURCES,
		iconName: 'filter',
	},
	{
		label: 'Edit resources',
		value: AutomationActionMenuValue.EDIT_RESOURCES,
		iconName: 'pencil',
	},
	{
		label: 'Send announcement',
		value: AutomationActionMenuValue.SEND_ANNOUNCEMENT,
		iconName: 'speakerphone',
	},
	{
		label: 'Send email',
		value: AutomationActionMenuValue.SEND_EMAIL,
		iconName: 'mail',
	},
	{
		label: 'Send Slack message',
		value: AutomationActionMenuValue.SEND_SLACK_MESSAGE,
		iconName: 'brandSlack',
	},
	{
		label: 'Propagate',
		value: AutomationActionMenuValue.PROPAGATE_METADATA,
		iconName: 'arrowsSplit',
	},
];

/**
 * This is the list of cards that can be added to an automation
 * The difference between this and the action menu is that the FILTER_AND_EDIT_RESOURCES
 * will add both the filter and the edit resource cards into the automation as they are
 * coupled together.
 */
export const automationActionCardOptions: AutomationActionCardOptions[] = [
	{
		type: AutomationActionCardType.FILTER_RESOURCES,
		iconName: 'filter',
	},
	{
		type: AutomationActionCardType.FILTER_COUNT,
		iconName: 'infoCircle',
	},
	{
		type: AutomationActionCardType.EDIT_RESOURCES,
		iconName: 'pencil',
	},
	{
		type: AutomationActionCardType.SEND_ANNOUNCEMENT,
		iconName: 'speakerphone',
	},
	{
		type: AutomationActionCardType.SEND_EMAIL,
		iconName: 'mail',
	},
	{
		type: AutomationActionCardType.SEND_SLACK_MESSAGE,
		iconName: 'brandSlack',
	},
	{
		type: AutomationActionCardType.PROPAGATE_METADATA,
		iconName: 'arrowsSplit',
	},
];
