import { Container, createStyles, Stack, Tabs } from '@mantine/core';
import { Dropzone } from '@mantine/dropzone';
import { showNotification } from '@mantine/notifications';
import { Button, Text } from '@repo/foundations';
import { useCallback, useState } from 'react';
import { uploadImagePublic } from '../../ImageUpload/ImageUpload.helpers';
import type { IDefaultColorPickerPanelProps } from './DefaultColorPickerPanel';

type IUploadPanelProps = IDefaultColorPickerPanelProps;

const useStyles = createStyles((theme) => ({
	dropzone: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100%',
	},
}));

function UploadPanel({ onBackgroundImageChange }: IUploadPanelProps) {
	const [fileAdded, setFileAdded] = useState(false);
	const { classes } = useStyles();
	const onUpload = useCallback(
		async (file: File) => {
			const result = await uploadImagePublic(file);
			onBackgroundImageChange(result as string, '#FFFFFF');

			showNotification({
				message: 'Image added',
				color: 'green',
			});
		},
		[onBackgroundImageChange]
	);

	return (
		<Tabs.Panel value="upload">
			<Container size="sm" w={568} h={150} pt={12} p={8}>
				<Dropzone
					accept={['image/jpg', 'image/jpeg', 'image/png']}
					multiple={false}
					className={classes.dropzone}
					onDrop={(files) => {
						setFileAdded(true);
						onUpload(files[0]);
					}}
				>
					<Stack align="center" spacing={4}>
						<Button>Add image</Button>
						<Text size="xs" color="text/secondary/default" align="center">
							Accepts .jpg, .jpeg, and .png.
							<br />
							Images wider than 1600px work best.
						</Text>
						{fileAdded && (
							<Text size="xs" color="text/secondary/default" align="center">
								Waiting for image to upload...
							</Text>
						)}
					</Stack>
				</Dropzone>
			</Container>
		</Tabs.Panel>
	);
}

export default UploadPanel;
