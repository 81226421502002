import type { MantineThemeOverride } from '@mantine/core';

export const ThemeIcon: MantineThemeOverride['components'] = {
	ThemeIcon: {
		variants: {
			transparent: () => ({
				root: {
					border: 'none',
				},
			}),
		},
	},
};
