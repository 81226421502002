import { useCallback } from 'react';
import {
	useAIEmbeddedTerminate,
	useAuthUser,
	useBulkGenerateAiMetadata,
	useStreamingAIPrompt,
} from '../../api';
import { useStreamingContent } from '../../hooks/useStreamingContent';
import { trackEvent } from '../../utils/analytics';
import { createMockableHook } from '../../utils/createMockableHook';

interface UseDescriptionEditorAiProps {
	entityId?: string;
	onKeep: (value: string) => void;
	refreshEditor: () => void;
}

function useDescriptionEditorAiInternal({
	entityId,
	onKeep,
	refreshEditor,
}: UseDescriptionEditorAiProps) {
	const { user, workspace } = useAuthUser();

	const { mutateAsync: generateAIDescription } = useBulkGenerateAiMetadata();
	const { mutateAsync: terminateAIEmbedded } = useAIEmbeddedTerminate();

	const {
		isStreaming,
		hasAIGeneratedText,
		pollingId,
		setPollingId,
		activePrompt,
		setActivePrompt,
	} = useStreamingAIPrompt();

	const aiGeneratedDescription = activePrompt?.response?.content ?? '';

	const generateDescription = useCallback(async () => {
		if (!entityId) {
			return;
		}

		const { jobId } = await generateAIDescription({
			data: {
				entity_ids: [entityId],
				field: 'description',
			},
		});

		setPollingId(jobId);
		setActivePrompt(null);
		refreshEditor();
	}, [
		entityId,
		generateAIDescription,
		refreshEditor,
		setActivePrompt,
		setPollingId,
	]);

	const stopAi = useCallback(async () => {
		if (!pollingId) {
			return;
		}

		await terminateAIEmbedded({
			id: pollingId,
		});

		setPollingId(null);
		setActivePrompt(null);
		refreshEditor();
	}, [
		pollingId,
		refreshEditor,
		setActivePrompt,
		setPollingId,
		terminateAIEmbedded,
	]);

	const keepDescription = useCallback(() => {
		if (!entityId) {
			return;
		}

		trackEvent(
			'ai/description/keep',
			{
				entityId,
			},
			user,
			workspace
		);

		onKeep(aiGeneratedDescription);
		setPollingId(null);
		setActivePrompt(null);
	}, [
		aiGeneratedDescription,
		entityId,
		onKeep,
		setActivePrompt,
		setPollingId,
		user,
		workspace,
	]);

	const rejectDescription = useCallback(() => {
		if (!entityId) {
			return;
		}

		trackEvent(
			'ai/description/reject',
			{
				entityId,
			},
			user,
			workspace
		);

		setPollingId(null);
		setActivePrompt(null);
		refreshEditor();
	}, [entityId, refreshEditor, setActivePrompt, setPollingId, user, workspace]);

	const aiGeneratedDescriptionWithStreaming = useStreamingContent(
		isStreaming,
		aiGeneratedDescription,
		refreshEditor
	);

	return {
		isStreaming,
		hasAIGeneratedText,
		aiGeneratedDescription: aiGeneratedDescriptionWithStreaming,
		generateDescription,
		rejectDescription,
		keepDescription,
		stopAi,
	};
}

export const [useDescriptionEditorAi, MockUseDescriptionEditorAi] =
	createMockableHook(useDescriptionEditorAiInternal);
