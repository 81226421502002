import { createContext, useContext, useMemo } from 'react';
import { AutomationPageStore } from './store';

const AutomationPageStoreContext = createContext<AutomationPageStore | null>(
	null
);

export interface AutomationPageStoreProviderProps {
	children: React.ReactNode;
}

export function AutomationPageStoreProvider({
	children,
}: AutomationPageStoreProviderProps) {
	const store = useMemo(() => new AutomationPageStore(), []);

	return (
		<AutomationPageStoreContext.Provider value={store}>
			{children}
		</AutomationPageStoreContext.Provider>
	);
}

export function useAutomationStore() {
	const ctx = useContext(AutomationPageStoreContext);
	if (ctx === null) {
		throw new Error(
			'useFormContext was called outside of FormProvider context for AutomationPageStore'
		);
	}

	return ctx;
}
