import type { FunctionComponent, PropsWithChildren } from 'react';
import { createContext, useContext } from 'react';

type HookType<P extends unknown[], R> = (...props: P) => R;
type ProviderProps<R> = PropsWithChildren<{ mockValue: R }>;

export function createMockableHook<P extends unknown[], R>(
	hook: HookType<P, R>
): [HookType<P, R>, FunctionComponent<ProviderProps<R>>] {
	const MockableContext = createContext<R | undefined>(undefined);

	function MockableProvider({ children, mockValue }: ProviderProps<R>) {
		return (
			<MockableContext.Provider value={mockValue}>
				{children}
			</MockableContext.Provider>
		);
	}

	function useMockableHook(...props: P) {
		const context = useContext(MockableContext);

		if (context === undefined) {
			// Fallback to the original hook if no mocked Context was set (no mock provided)
			return hook(...props);
		}

		return context;
	}

	return [useMockableHook, MockableProvider];
}
