/* eslint-disable no-nested-ternary */
import type { MenuProps } from '@mantine/core';
import { UnstyledButton } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import type { FilterOperatorConfig } from '@repo/common/components/Filter/constants';
import {
	IS_NOT_SET_FILTER_ITEM,
	IS_SET_FILTER_ITEM,
} from '@repo/common/components/Filter/constants';
import { FilterTargetDetailed } from '@repo/common/components/Filter/FilterTarget/FilterTargetDetailed';
import { FilterTargetSimple } from '@repo/common/components/Filter/FilterTarget/FilterTargetSimple';
import type { OperatorMenuProps } from '@repo/common/components/Filter/OperatorMenu';
import type {
	FilterOption,
	FilterValue,
} from '@repo/common/components/Filter/types';
import { FilterOperator } from '@repo/common/components/Filter/types';
import { ListBox } from '@repo/foundations';
import { isNil } from 'lodash-es';
import { memo, useCallback, useMemo } from 'react';
import { FilterDropdown } from './FilterDropdown/FilterDropdown';
import { useFilter } from './useFilter';
import { useFilterLabel } from './useFilterLabel';
import { getValueAsArray } from './utils';

export interface FilterProps extends Omit<MenuProps, 'opened' | 'onChange'> {
	value: FilterValue;
	showDetailedLabel?: boolean;
	onClear?: () => void;
	onChange: (value: Partial<FilterValue>) => void;
	initialOpened?: boolean;
	operatorConfig?: FilterOperatorConfig;
	filterOption: FilterOption;
	children?: React.ReactNode;
}

function FilterInternal({
	value,
	showDetailedLabel = false,
	onClear,
	onChange,
	initialOpened = false,
	operatorConfig,
	filterOption,
	children,
	...menuProps
}: FilterProps) {
	const [opened, { close, toggle, open }] = useDisclosure(initialOpened);

	const handleOnOpenChange = useCallback(
		(newOpen: boolean) => {
			if (newOpen) {
				open();
			} else {
				close();
			}
		},
		[close, open]
	);

	const valueLabel = useFilterLabel({ value });

	const { handleOnChange } = useFilter({
		value,
		onChange,
		close,
	});

	const operatorMenuProps = useMemo(() => {
		const operators = operatorConfig?.getOperators(value) ?? [];
		if (filterOption.filterDropdownConfig.hasIsNotSetOption) {
			// if option supports isNotSet, we add it to the list of operators
			operators.push(FilterOperator.isNotSet);
		}
		if (filterOption.filterDropdownConfig.hasIsSetOption) {
			// if option supports isSet, we add it to the list of operators
			operators.push(FilterOperator.isSet);
		}

		let selectedOperator = value.operator;
		// if option supports isNotSet, isNotSet is applied, and value is empty, we should set isNotSet as the operator for display purposes
		if (
			filterOption.filterDropdownConfig.hasIsNotSetOption &&
			value.isNotSetApplied &&
			(!filterOption.filterDropdownConfig.hasIsSetOption ||
				!value.isSetApplied) &&
			isNil(value.value)
		) {
			selectedOperator = FilterOperator.isNotSet;
		}

		// if option supports isSet, isSet is applied, and value is empty, we should set isSet as the operator for display purposes
		if (
			filterOption.filterDropdownConfig.hasIsSetOption &&
			value.isSetApplied &&
			(!filterOption.filterDropdownConfig.hasIsNotSetOption ||
				!value.isNotSetApplied) &&
			isNil(value.value)
		) {
			selectedOperator = FilterOperator.isSet;
		}

		return {
			operators: operators.map((item) => ({
				value: item,
				label: operatorConfig?.getLabel(item, value.value) ?? item.toString(),
			})),
			selected: selectedOperator,
			onChange: (operator) => {
				const valueAsArray = getValueAsArray(value);
				const newValue: Partial<FilterValue> = {
					operator,
					isNotSetApplied:
						operator === FilterOperator.isNotSet ||
						valueAsArray.includes(IS_NOT_SET_FILTER_ITEM.value),
					isSetApplied:
						operator === FilterOperator.isSet ||
						valueAsArray.includes(IS_SET_FILTER_ITEM.value),
				};

				handleOnChange(newValue, true);

				// if we are switching to other operator than isNotSet and value is empty, we should open dropdown for value selection
				if (
					!newValue.isNotSetApplied &&
					!newValue.isSetApplied &&
					isNil(value.value) &&
					!opened
				) {
					toggle();
				}
			},
			label:
				operatorConfig?.getLabel(selectedOperator, value.value) ??
				selectedOperator.toString(),
		} as OperatorMenuProps;
	}, [filterOption, handleOnChange, opened, operatorConfig, toggle, value]);

	const hasValue =
		!isNil(value.value) || value.isNotSetApplied || value.isSetApplied;
	const canShowDetailedLabel =
		showDetailedLabel && !!operatorConfig && hasValue;

	const memoizedButton = useMemo(
		() =>
			children ? (
				<UnstyledButton onClick={toggle}>{children}</UnstyledButton>
			) : null,
		[children, toggle]
	);

	return (
		<ListBox
			closeOnClickOutside
			{...menuProps}
			opened={opened}
			onOpenChange={handleOnOpenChange}
			autoAdjustPosition={false}
		>
			<ListBox.Target>
				{memoizedButton ? (
					memoizedButton
				) : canShowDetailedLabel ? (
					<FilterTargetDetailed
						label={filterOption.label}
						valueLabel={valueLabel}
						onClear={onClear}
						isMenuOpen={opened}
						operatorMenuProps={operatorMenuProps}
						onToggle={toggle}
					/>
				) : (
					<FilterTargetSimple
						label={valueLabel ?? filterOption.label}
						onClear={onClear}
						isMenuOpen={opened}
						onToggle={toggle}
						hasValue={hasValue}
					/>
				)}
			</ListBox.Target>
			{opened && (
				<FilterDropdown
					filterOption={filterOption}
					onChange={handleOnChange}
					value={value}
				/>
			)}
		</ListBox>
	);
}

export const Filter = memo(FilterInternal);
