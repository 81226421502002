export const COVER_IMAGE_MAPPING = {
	'cover-0.webp':
		'https://images.unsplash.com/photo-1651147538420-06f5e0d3f1d9?auto=format&crop=focal&fit=crop&w=2560&h=400&q=80',
	'cover-1.webp':
		'https://images.unsplash.com/photo-1554034483-04fda0d3507b?auto=format&crop=focal&fit=crop&w=2560&h=400&q=80',
	'cover-2.webp':
		'https://images.unsplash.com/photo-1541450805268-4822a3a774ca?auto=format&crop=focal&fit=crop&w=2560&h=400&q=80',
	'cover-3.webp':
		'https://images.unsplash.com/photo-1642680571569-a7676d19c215?auto=format&crop=focal&fit=crop&w=2560&h=400&q=80',
	'cover-4.webp':
		'https://images.unsplash.com/photo-1554034483-04fda0d3507b?auto=format&crop=focal&fit=crop&w=2560&h=400&q=80',
	'cover-5.webp':
		'https://images.unsplash.com/photo-1669465716237-9cb999b47773?auto=format&crop=focal&fit=crop&w=2560&h=400&q=80',
	'cover-6.webp':
		'https://images.unsplash.com/photo-1507692984170-ff22288b21cf?auto=format&crop=focal&fit=crop&w=2560&h=400&q=80',
	'cover-7.webp':
		'https://images.unsplash.com/photo-1491147334573-44cbb4602074?auto=format&crop=focal&fit=crop&w=2560&h=400&q=80',
	'cover-9.webp':
		'https://images.unsplash.com/photo-1596486935250-3ca1fb5c45b8?auto=format&crop=focal&fit=crop&w=2560&h=400&q=80',
	'cover-10.webp':
		'https://images.unsplash.com/photo-1503891617560-5b8c2e28cbf6?auto=format&crop=focal&fit=crop&w=2560&h=400&q=80',
	'cover-11.webp':
		'https://images.unsplash.com/photo-1538370965046-79c0d6907d47?auto=format&crop=focal&fit=crop&w=2560&h=400&q=80',
	'cover-12.webp':
		'https://images.unsplash.com/photo-1489914099268-1dad649f76bf?auto=format&crop=focal&fit=crop&w=2560&h=400&q=80',
};

export const COVER_COLORS = [
	'red',
	'pink',
	'grape',
	'violet',
	'indigo',
	'blue',
	'cyan',
	'teal',
	'green',
	'lime',
	'yellow',
	'orange',
] as const;

export const COVER_COLORS_FONT_COLOR_MAPPING: Record<string, string> = {
	blue: '#FFFFFF',
	cyan: '#232221',
	grape: '#FFFFFF',
	green: '#232221',
	indigo: '#FFFFFF',
	lime: '#232221',
	orange: '#232221',
	pink: '#FFFFFF',
	red: '#FFFFFF',
	teal: '#232221',
	violet: '#FFFFFF',
	yellow: '#232221',
};

export const COVER_IMAGE_FONT_COLOR_MAPPING: Record<string, string> = {
	'cover-0.webp': '#FFFFFF',
	'cover-1.webp': '#232221',
	'cover-2.webp': '#232221',
	'cover-3.webp': '#FFFFFF',
	'cover-4.webp': '#232221',
	'cover-5.webp': '#FFFFFF',
	'cover-6.webp': '#FFFFFF',
	'cover-7.webp': '#FFFFFF',
	'cover-9.webp': '#232221',
	'cover-10.webp': '#FFFFFF',
	'cover-11.webp': '#FFFFFF',
	'cover-12.webp': '#FFFFFF',
};
