import { findIndex, isEmpty, isNil, size } from 'lodash-es';
import type { IApiListResponse } from '../../../api';
import { notificationQueryKeyFactory } from '../../../api/hooks/notification/constants';
import { getFilters } from '../../../api/hooks/notification/utils';
import queryClient from '../../../api/queryClient';
import type { INotification } from '../../../api/types/models/notification';

export const moveNotification = (id: string, direction: 'up' | 'down') => {
	const { page, filters } = getFilters();

	const queryData: IApiListResponse<INotification> | undefined =
		queryClient.getQueryData(notificationQueryKeyFactory.list(page, filters));

	if (!isNil(queryData) && !isEmpty(queryData.results)) {
		const index = findIndex(queryData.results, { id });

		if (
			(direction === 'up' && index <= 0) ||
			(direction === 'down' && index === size(queryData.results) - 1)
		) {
			return null;
		}

		return direction === 'up'
			? queryData.results[index - 1]
			: queryData.results[index + 1];
	}
};
