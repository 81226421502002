import { useCallback, useContext, useEffect, useState } from 'react';
import type {
	ILineage,
	ILineageEntityChildren,
	ILineageTableTest,
} from '../../api';
import { useSecodaEntity, useSecodaQueryList } from '../../api';
import { SearchFilterV2StoreContext } from '../../components/Filter';
import type { LineageGraphData } from '../../components/LineageGraph/types';
import { LineageDirectionEnum } from '../../components/LineageGraph/types';
import { isDashboard, isTable } from '../../lib/utils/entity';
import { LINEAGE_QUERY_OPTIONS } from './constants';
import { getEntityChildren, getLineage, getTableTests } from './utils';

async function promiseAllObject<T extends { [key: string]: Promise<any> }>(
	promises: T
): Promise<{ [K in keyof T]: Awaited<T[K]> }> {
	const keys = Object.keys(promises) as Array<keyof T>;
	const values = keys.map((key) => promises[key]);

	let results = await Promise.all(values);
	const res = {} as { [K in keyof T]: Awaited<T[K]> };
	keys.forEach((key, index) => {
		res[key] = results[index];
	});
	return res;
}

export function useLineageGraph(id: string) {
	const [data, setData] = useState<LineageGraphData | undefined>();

	const { data: entity } = useSecodaEntity({
		id,
		options: { suspense: false },
	});
	const { data: creationQueries } = useSecodaQueryList({
		filters: {
			creates_databuilder_id: entity?.databuilder_id || '',
			order_by: '-updated_at',
		},
		options: { enabled: !!entity, ...LINEAGE_QUERY_OPTIONS },
	});
	const store = useContext(SearchFilterV2StoreContext);

	const fetchData = useCallback(async () => {
		if (!entity) {
			return;
		}

		const promises = {
			upstream: getLineage(
				entity.id,
				entity.entity_type,
				LineageDirectionEnum.UPSTREAM,
				store.catalogFilter
			),
			downstream: getLineage(
				entity.id,
				entity.entity_type,
				LineageDirectionEnum.DOWNSTREAM,
				store.catalogFilter
			),
		} as {
			upstream: Promise<ILineage[]>;
			downstream: Promise<ILineage[]>;
			children?: Promise<ILineageEntityChildren[]>;
			tests?: Promise<ILineageTableTest[]>;
		};

		if (isTable(entity) || isDashboard(entity)) {
			promises.children = getEntityChildren([entity.id]);
		}

		if (isTable(entity)) {
			promises.tests = getTableTests([entity.id]);
		}

		const dataResult = await promiseAllObject(promises);

		setData({
			entity,
			upstream: dataResult.upstream,
			downstream: dataResult.downstream,
			children: dataResult.children,
			tests: dataResult.tests,
			creationQuery: creationQueries?.results.length
				? {
						id: creationQueries.results[0].id,
						created_at: creationQueries.results[0].created_at,
						updated_at: creationQueries.results[0].updated_at,
						sql: creationQueries.results[0].sql,
						dialect: creationQueries.results[0].dialect,
						table_id: entity.id,
					}
				: undefined,
		});
	}, [entity, creationQueries?.results, store.catalogFilter]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	return { data };
}
