import { Stack } from '@mantine/core';
import { Button, Text } from '@repo/foundations';

interface MessageAIErrorProps {
	onRetry?: () => void;
}

export function MessageAIError({ onRetry }: MessageAIErrorProps) {
	return (
		<Stack spacing="xs" align="flex-start">
			<Text size="md" color="text/secondary/default">
				This message failed to load.
			</Text>
			{onRetry && <Button onClick={onRetry}>Try again</Button>}
		</Stack>
	);
}
