import produce from 'immer';
import type { Catalog } from '../../../api';

export const reorder = (
	properties: Catalog['properties'],
	startIndex: number,
	endIndex: number
) =>
	produce(properties, (draft) => {
		const [removed] = draft.splice(startIndex, 1);
		draft.splice(endIndex, 0, removed);

		// Update order of all properties
		draft.forEach((draftProperty, index) => {
			draftProperty.order = index + 1;
		});
	});

export const renameCatalogProperty = (
	properties: Catalog['properties'],
	existingColumnName: string,
	newColumnName: string
) =>
	produce(properties, (draft) => {
		const index = draft.findIndex(({ value }) => value === existingColumnName);
		draft[index].value = newColumnName;
	});

export const updateCatalogPropertyVisibility = (
	properties: Catalog['properties'],
	columnName: string,
	isVisible: boolean
) =>
	produce(properties, (draft) => {
		const index = draft.findIndex(({ value }) => value === columnName);
		draft[index].hidden = !isVisible;
	});
