import axios from 'axios';
import { cloneDeep } from 'lodash-es';
import { SecodaEntity } from './entity';

export class Collection extends SecodaEntity {
	static CLASS_NAME = 'Collection';

	// @ts-expect-error TS(2564): Property 'id' has no initializer and is not defini... Remove this comment to see the full error message
	id: string;

	// @ts-expect-error TS(2564): Property 'title' has no initializer and is not def... Remove this comment to see the full error message
	title: string;

	// @ts-expect-error TS(2564): Property 'pinned' has no initializer and is not de... Remove this comment to see the full error message
	pinned: boolean;

	// @ts-expect-error TS(2564): Property 'resource_count' has no initializer and ... Remove this comment to see the full error message
	resource_count: number;

	constructor(obj: Partial<Collection>) {
		// @ts-expect-error TS(2345): Argument of type 'Partial<Collection>' is not assi... Remove this comment to see the full error message
		super(obj);
		Object.keys(obj).forEach((key) => {
			(this as any)[key] = cloneDeep((obj as any)[key]);
		});
	}

	static get namespace() {
		return ['collection', 'collections'];
	}

	static get fields() {
		return SecodaEntity.fields.concat(['pinned', 'resource_count']);
	}

	async entities_count() {
		const { data } = await axios.get<Record<string, number>>(
			`${this.apiPath}entities_count/`
		);
		return data;
	}
}
