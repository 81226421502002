import { Group, Stack } from '@mantine/core';
import { Text } from '@repo/foundations';
import type { IUser } from '../../../../api';
import { UserAvatar } from '../../../../components/UserAvatar';
import { snakeCaseToTitleCase } from '../../../../utils/shared.utils';

interface UserCellProps {
	user?: IUser;
}

export function UserCell({ user }: UserCellProps) {
	if (!user) return null;

	return (
		<Group h={52} noWrap>
			<UserAvatar user={user} size="lg" />
			<Stack spacing={0}>
				<Text
					color="text/primary/default"
					size="sm"
					weight="semibold"
					lineClamp={1}
				>
					{user.display_name}
				</Text>
				<Text color="text/secondary/default" size="xs">
					{snakeCaseToTitleCase(user.role)}
				</Text>
			</Stack>
		</Group>
	);
}
