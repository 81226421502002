import { Space } from '@mantine/core';
import type { LiteUserOut } from '@repo/api-codegen';
import CollapsableStack from '@repo/common/components/CollapsableStack';
import type { SelectablePropertyItem } from '@repo/common/components/SingleSelector/types';
import { EntityType } from '@repo/common/enums/entityType';
import { SegmentedControl } from '@repo/foundations';
import { map, startCase, uniqBy } from 'lodash-es';
import { useCallback, useState } from 'react';
import type { IIntegration } from '../../../../api';
import { useAuthUser } from '../../../../api';
import { useExtendedUserList } from '../../../../api/hooks/user/useExtendedUserList';
import { formatEntityDateTime } from '../../../../utils/time';
import { formatBytes, nFormatter } from '../../../SearchListItem/helpers';
import { externalUsers } from '../../../UserAvatar/helpers';
import type { EntitySidebarContentProps } from '../../EntitySidebarContent';
import IntegrationMetadataStack from './IntegrationMetadataStack';
import SecodaMetadataStack from './SecodaMetadataStack';

interface MetadataStackProps
	extends Pick<
		EntitySidebarContentProps,
		| 'entity'
		| 'withFrequentUsers'
		| 'withEntityByteSize'
		| 'withEntityRowCount'
		| 'withEntityPopularity'
		| 'withAIInclusionStatus'
	> {
	integration?: IIntegration;
	visibleUsers: LiteUserOut[];
}

function MetadataStack({
	integration,
	entity,
	withFrequentUsers = false,
	withEntityPopularity = false,
	withEntityRowCount = false,
	withEntityByteSize = false,
	withAIInclusionStatus = false,
	visibleUsers,
}: MetadataStackProps) {
	const [activeTab, setActiveTab] = useState<string>(
		integration ? integration.name : 'Secoda'
	);
	const { isGuestUser } = useAuthUser();

	const { activeUsers: users, serviceAccountsActive: serviceAccounts } =
		useExtendedUserList();

	const calculateFrequentUsers = useCallback(
		(userList: LiteUserOut[]): SelectablePropertyItem[] => {
			const uniqueUsers = uniqBy([...userList], 'id');

			const filteredUsers = uniqueUsers.filter((uniqueUser) =>
				visibleUsers.some((visibleUser) => uniqueUser.id === visibleUser.id)
			);

			const displayableUsers = isGuestUser ? filteredUsers : uniqueUsers;
			return map(displayableUsers, (u) => ({
				label: u.display_name,
				value: String(u.id),
				icon: u.profile_picture,
			}));
		},
		[isGuestUser, visibleUsers]
	);

	const internal = users?.filter((user) =>
		entity.internal_users?.includes(user.id)
	);
	const external = externalUsers(users ?? [], serviceAccounts ?? [], entity);

	const frequentUsersSecoda = calculateFrequentUsers(internal ?? []);
	const frequentUsersSource = calculateFrequentUsers(external as LiteUserOut[]);

	const createdAtLabelSecoda = formatEntityDateTime(entity.created_at);
	const updatedAtLabelSecoda = formatEntityDateTime(entity.updated_at);

	const updatedAtLabelSource = entity.external_updated_at
		? formatEntityDateTime(entity.external_updated_at)
		: null;

	const viewsValue = nFormatter(entity.internal_usage, 1);

	const popularityValue = `${nFormatter(entity.external_usage, 1)} ${
		entity.entity_type === 'table' ? 'queries' : 'views'
	}`;
	const rowCountValue = nFormatter(parseInt(entity.row_count || '0'), 1);
	const byteSizeValue = formatBytes(parseInt(entity.byte_size || '0'), 2);

	const hasRowCount = !!entity.row_count && parseInt(entity.row_count) > 0;
	const hasByteSize = !!entity.byte_size && parseInt(entity.byte_size) > 0;
	const hasPopularity = !!entity.external_usage && entity.external_usage > 0;

	// resource is from secoda if entity has no native type
	const resourceTypeSecoda = !entity.native_type
		? startCase(entity.entity_type)
		: null;
	const resourceTypeSource = startCase(entity.native_type);

	if (entity.entity_type === EntityType.metric || !integration) {
		return (
			<CollapsableStack groupName="Metadata">
				<SecodaMetadataStack
					createdAtLabelSecoda={createdAtLabelSecoda}
					updatedAtLabelSecoda={updatedAtLabelSecoda}
					resourceTypeSecoda={resourceTypeSecoda}
					withFrequentUsers={withFrequentUsers}
					frequentUsersSecoda={frequentUsersSecoda}
					viewsValue={viewsValue}
					withAIInclusionStatus={withAIInclusionStatus}
					entityId={entity.id}
				/>
			</CollapsableStack>
		);
	}

	return (
		<CollapsableStack groupName="Metadata">
			<SegmentedControl
				size="sm"
				w="100%"
				value={activeTab === 'Secoda' ? 'Secoda' : integration.name}
				onChange={setActiveTab}
				data={[integration.name, 'Secoda']}
			/>
			<Space h="xs" />
			{activeTab !== 'Secoda' ? (
				<IntegrationMetadataStack
					updatedAtLabelSource={updatedAtLabelSource}
					resourceTypeSource={resourceTypeSource}
					withFrequentUsers={withFrequentUsers}
					frequentUsersSource={frequentUsersSource}
					withEntityRowCount={withEntityRowCount}
					rowCountValue={rowCountValue}
					hasRowCount={hasRowCount}
					withEntityByteSize={withEntityByteSize}
					byteSizeValue={byteSizeValue}
					hasByteSize={hasByteSize}
					withEntityPopularity={withEntityPopularity}
					popularityValue={popularityValue}
					hasPopularity={hasPopularity}
					entityId={entity.id}
					entityType={entity.entity_type}
				/>
			) : (
				<SecodaMetadataStack
					createdAtLabelSecoda={createdAtLabelSecoda}
					updatedAtLabelSecoda={updatedAtLabelSecoda}
					resourceTypeSecoda={resourceTypeSecoda}
					withFrequentUsers={withFrequentUsers}
					frequentUsersSecoda={frequentUsersSecoda}
					viewsValue={viewsValue}
					withAIInclusionStatus={withAIInclusionStatus}
					entityId={entity.id}
				/>
			)}
		</CollapsableStack>
	);
}

export default MetadataStack;
