import { Global } from '@mantine/core';
import * as Input from '@observablehq/inputs';
import { colors, typography } from '@repo/theme/primitives';
import { arrayToObjects } from '../../utils/metrics';
import { Wrapper } from './components/Wrapper';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const svgTable = (results: any[][]) => {
	const data = results;

	const formattedData = arrayToObjects(data);

	return Input.table(formattedData, {
		multiple: false,
		fixedWidth: true,
	});
};

interface DataDisplayTableProps {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	results: any[][];
	height?: string;
	width?: string;
}

export function DataDisplayTable({
	height,
	width = '100%',
	results,
}: DataDisplayTableProps) {
	return (
		<>
			<Global
				styles={() => ({
					form: {
						width,
						maxHeight: height ? `${height} !important` : undefined,
						display: 'block',
						overflowY: 'auto',
					},
					'form table thead tr th:first-child': {
						display: 'none',
					},
					'form table thead tr th': {
						fontWeight: typography.weight.bold,
						textAlign: 'left',
						fontSize: 14,
						borderBottom: `solid 1px ${colors.gray[2]}`,
						textOverflow: 'ellipsis',
						overflow: 'hidden',
						padding: '3px 6.5px 3px 0',
						textWrap: 'nowrap',
					},
					'form table tr td:first-child': {
						display: 'none',
					},
					'form table tr td': {
						fontWeight: typography.weight.regular,
						fontSize: 14,
						borderBottom: `solid 1px ${colors.gray[2]}`,
						textOverflow: 'ellipsis',
						overflow: 'hidden',
						padding: '3px 6.5px 3px 0',
					},
					'form table': {
						width: '100%',
					},
				})}
			/>
			<Wrapper svgElement={svgTable(results)} />
		</>
	);
}
