import {
	ActionIcon,
	createStyles,
	Group,
	Loader,
	Popover,
	rem,
	Stack,
	TextInput,
} from '@mantine/core';
import { useInputState } from '@mantine/hooks';
import { Icon } from '@repo/foundations';
import { space } from '@repo/theme/primitives';
import { isEmpty } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import type React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { useAiEnabled } from '../../hooks/useAIEnabled';
import AskAIModal from '../../pages/SearchPage/FilterCarousel/AskAIModal/AskAIModal';
import SearchActions from '../Spotlight/components/SearchPalette/components/SearchActions';

interface IStyleParams {
	popoverOpened: boolean;
	width: number;
}

interface ISearchProps {
	width?: number;
	className?: string;
	classNames?: {
		wrapper?: string;
		inner?: string;
		input?: string;
	};
}

const useStyles = createStyles(
	(theme, { width = 500, popoverOpened }: IStyleParams) => {
		const border = {
			borderRadius: popoverOpened
				? `${theme.radius.lg} ${theme.radius.lg} 0 0`
				: theme.radius.xl,
		};

		return {
			stack: {
				width,
			},
			menuDropdown: {
				padding: '0',
				borderRadius: '0 0 1.3rem 1.3rem',
			},
			input: {
				...border,
				transitionTimingFunction: 'ease-in-out',
				transitionDuration: '100ms',
				textIndent: theme.spacing.xs,
			},
			rightSection: {
				// 2 * width icon + padding
				width: `calc(2 * ${rem(theme.other.space[8])} + ${rem(
					theme.other.space[4]
				)})`,
			},
			icon: {
				paddingLeft: theme.spacing.sm,
				color: theme.other.getColor('icon/primary/default'),
			},
		};
	}
);

function SearchComponent({
	className,
	classNames,
	width = space[160],
}: ISearchProps) {
	const navigate = useNavigate();

	const [searchTerm, setSearchTerm] = useInputState('');
	const [popoverOpened, setPopoverOpened] = useState(false);
	const [loading, setLoading] = useState(false);

	const { theme, classes, cx } = useStyles({ width, popoverOpened });

	const icon = loading ? (
		<Loader size={theme.other.iconSize.md} />
	) : (
		<Icon name="search" color="icon/brand/default" />
	);

	// === Event handlers
	const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Escape') {
			setPopoverOpened(false);
		}
	};

	const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
		if (!popoverOpened) setPopoverOpened(true);
		setSearchTerm(e.target.value);
	};

	const handleNavigateToSearch = () => {
		let path = '/search';
		if (!isEmpty(searchTerm)) {
			path = `/search?term=${searchTerm}`;
		}
		navigate(path);
	};

	const { enableAi } = useAiEnabled();

	return (
		<Stack className={cx(classes.stack, classNames?.inner, className)}>
			<Popover
				classNames={{ dropdown: classes.menuDropdown }}
				opened={popoverOpened}
				width={width}
				withinPortal
				offset={0}
			>
				<Popover.Target>
					<TextInput
						classNames={{
							input: classes.input,
							icon: classes.icon,
							rightSection: classes.rightSection,
						}}
						icon={icon}
						variant="default"
						size="md"
						placeholder="Search company data"
						value={searchTerm}
						onKeyDown={handleKeyDown}
						onChange={handleChange}
						rightSection={
							enableAi && (
								<Group spacing={0} noWrap>
									<AskAIModal />
									<ActionIcon variant="subtle" onClick={handleNavigateToSearch}>
										<Icon name="arrowsDiagonal" />
									</ActionIcon>
								</Group>
							)
						}
						data-testid="home-page-search-input"
						onFocusCapture={() => setPopoverOpened(true)}
						onBlurCapture={() => setPopoverOpened(false)}
					/>
				</Popover.Target>
				<Popover.Dropdown>
					<SearchActions
						searchTerm={searchTerm}
						showFilters={false}
						onLoad={setLoading}
						eventSource="home_page"
					/>
				</Popover.Dropdown>
			</Popover>
		</Stack>
	);
}

export const Search = observer(SearchComponent);
