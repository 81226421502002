import { Group, Stack } from '@mantine/core';
import { DataQualityClassification } from '@repo/common/enums/dataQualityClassification';
import { Text, Title } from '@repo/foundations';
import { DataQualityBadge } from '../DataQualityBadge';

export function DataQualityBenchmarkTooltip() {
	return (
		<Stack spacing="sm" p="sm" w={240}>
			<Title size="sm">Quality benchmark</Title>
			<Stack spacing="xs">
				<Group position="apart" spacing={0}>
					<DataQualityBadge classification={DataQualityClassification.GOOD} />
					<Text size="sm">67 - 100%</Text>
				</Group>
				<Group position="apart" spacing={0}>
					<DataQualityBadge
						classification={DataQualityClassification.MODERATE}
					/>
					<Text size="sm">34 - 66%</Text>
				</Group>
				<Group position="apart" spacing={0}>
					<DataQualityBadge classification={DataQualityClassification.POOR} />
					<Text size="sm">0 - 33%</Text>
				</Group>
			</Stack>
			<Text size="xs" color="text/secondary/default">
				Reflects the accuracy, reliability, stewardship, and usability of this
				resource, helping you assess its overall quality.
			</Text>
		</Stack>
	);
}
