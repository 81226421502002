import type { MantineTheme } from '@mantine/core';
import { createStyles } from '@mantine/core';

export const ACTION_ICON_GROUP_VARIANTS = ['primary'] as const;
export type ActionIconGroupVariant =
	(typeof ACTION_ICON_GROUP_VARIANTS)[number];

const getVariantStyles = (
	theme: MantineTheme,
	orientation: 'horizontal' | 'vertical',
	variant: ActionIconGroupVariant
) => {
	const isHorizontal = orientation === 'horizontal';

	switch (variant) {
		case 'primary':
			return {
				actionIcon: {
					backgroundColor: theme.other.getColor('fill/primary/default'),
					'&:hover': {
						backgroundColor: theme.other.getColor('fill/primary/hover'),
					},
					'&:active': {
						backgroundColor: theme.other.getColor('fill/primary/active'),
					},
					'&:focus': {
						backgroundColor: theme.other.getColor('fill/primary/active'),
					},
					color: theme.other.getColor('icon/primary/default'),
					borderColor: theme.other.getColor('border/secondary/default'),
					borderRadius: 0,
					borderWidth: 1,
					'&:first-of-type': {
						borderTopLeftRadius: theme.radius.sm,
						borderTopRightRadius: isHorizontal ? 0 : theme.radius.sm,
						borderBottomLeftRadius: isHorizontal ? theme.radius.sm : 0,
						borderBottomRightRadius: 0,
						height: 'calc(100% + 1px)',
					},
					'&:last-of-type': {
						borderTopLeftRadius: 0,
						borderTopRightRadius: isHorizontal ? theme.radius.sm : 0,
						borderBottomLeftRadius: isHorizontal ? 0 : theme.radius.sm,
						borderBottomRightRadius: theme.radius.sm,
					},
					'&:not(:last-of-type)': {
						borderBottomWidth: 0,
					},
				},
			};
		default:
			return {};
	}
};

export interface IActionIconGroupStyleProps {
	orientation: 'horizontal' | 'vertical';
	variant: ActionIconGroupVariant;
}

export default createStyles(
	(
		theme: MantineTheme,
		{ orientation, variant }: IActionIconGroupStyleProps
	) => {
		const variantStyles = getVariantStyles(theme, orientation, variant);
		return {
			container: {
				gap: 0,
			},
			actionIcon: {
				cursor: 'pointer',
				...variantStyles.actionIcon,
			},
		};
	}
);
