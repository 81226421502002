export const ARCHIVED_TABLE_DATA = {
	restoreMessage: {
		title: 'Resources restored',
		message:
			'The resources have been unarchived, and are available in your workspace',
	},
	restoreData: { archived: false, archived_at: null },
	queryFilters: { archived: true },
};

export const STALE_TABLE_DATA = {
	restoreMessage: {
		title: 'Resources restored',
		message:
			'The resources have been unstaled, and are available in your workspace',
	},
	restoreData: {
		stale: false,
		forced_unstale_by_user: true,
		stale_at: null,
	},
	queryFilters: { stale: true },
};
