import { space } from '@repo/theme/primitives';
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import HorizontalResizeHandle from '../HorizontalResizeHandle';
import {
	RIGHT_SIDEBAR_MAX_WIDTH,
	RIGHT_SIDEBAR_MIN_WIDTH,
	SIDEBAR_COLOR,
} from '../SecodaAppShell/constants';
import { rightSidebarStore } from './store';

export const ResizeRightSidebar = observer(() => {
	const handleResize = useCallback((updatedWidth: number) => {
		const { collapsed } = rightSidebarStore;

		if (
			!collapsed &&
			updatedWidth >= RIGHT_SIDEBAR_MIN_WIDTH &&
			updatedWidth <= RIGHT_SIDEBAR_MAX_WIDTH
		) {
			rightSidebarStore.setWidth(updatedWidth);
		}
	}, []);

	return (
		<HorizontalResizeHandle
			side="left"
			handleSize={space[3]}
			minWidth={RIGHT_SIDEBAR_MIN_WIDTH}
			maxWidth={RIGHT_SIDEBAR_MAX_WIDTH}
			backgroundColor={SIDEBAR_COLOR}
			onResize={handleResize}
		/>
	);
});
