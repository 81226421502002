import { getBaseUrl } from './axios';

export const getEndpoints = (namespace: string[]) => ({
	root: () => {
		const baseUrl = getBaseUrl();
		const path = namespace.join('/');
		const url = `${baseUrl}${path}/`;

		return url;
	},
	byId: (id: string) => {
		const baseUrl = getBaseUrl();
		const path = namespace.join('/');
		const url = `${baseUrl}${path}/${id}/`;

		return url;
	},
	byPath: (path: string[]) => {
		const baseUrl = getBaseUrl();
		const subPath = [...namespace, ...path].join('/');
		return `${baseUrl}${subPath}/`;
	},
});
