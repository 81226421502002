import { Card, Group, Stack } from '@mantine/core';
import { Button, Text, Title } from '@repo/foundations';
import { startCase } from 'lodash-es';
import { useStyles } from './IntegrationsStatusPage.styles';

interface IStatusCountCardProps {
	label: string;
	count: number;
	onClick: () => void;
	timeDesc?: string;
}

export function StatusCountCard({
	label,
	count,
	onClick,
}: IStatusCountCardProps) {
	const { classes, theme } = useStyles();
	return (
		<Card
			className={classes.card}
			bg={theme.other.getColor('surface/primary/default')}
		>
			<Stack spacing="xs">
				<Stack spacing={0}>
					<Title size="md" color="text/primary/default">
						{`${startCase(label)}`}
					</Title>
					<Text size="sm" color="text/primary/default">
						in the last 24 hours
					</Text>
				</Stack>
				<Group align="center" spacing="xs">
					<Title size="xxl" color="text/primary/default">
						{count}
					</Title>
				</Group>
				<Button rightIconName="arrowRight" w="fit-content" onClick={onClick}>
					{`View ${label}`}
				</Button>
			</Stack>
		</Card>
	);
}
