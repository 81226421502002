import type { IReport } from '../../types';
import { useUpdateBaseModel } from '../base';
import { reportsQueryKeyFactory } from './constants';

export function useUpdateHomepageReport(id = 'homepage') {
	return useUpdateBaseModel<IReport>({
		queryKeyFactory: reportsQueryKeyFactory,
		namespace: reportsQueryKeyFactory.namespace,

		invalidationKeys: [reportsQueryKeyFactory.byId(id)],
	});
}
