import { Breadcrumbs, Button } from '@repo/foundations';
import type { Incident, IncidentStatus, Monitor } from '../../../api';
import { useIncidentStatusUpdate } from '../../../api';
import { AIAssistantSidebarToggle } from '../../../components/AIAssistant';
import { EntityPageSidebarToggle } from '../../../components/EntityPageSidebarToggle';
import {
	NavBarBreadcrumbWrapper,
	NavBarRightSideWrapper,
	NavBarSidesheetControlsWrapper,
	NavBarWrapper,
} from '../../../components/SecodaAppShell/SecodaNavBar';
import { isAcknowledgeEnabled, isResolveEnabled } from '../utils';

interface V2IncidentPageNavbarProps {
	incident?: Incident;
	monitor?: Monitor;
}

function V2IncidentPageNavbar({
	incident,
	monitor,
}: V2IncidentPageNavbarProps) {
	const { mutateAsync: updateIncident } = useIncidentStatusUpdate();

	const showAcknowledgeButton =
		isAcknowledgeEnabled(incident?.status) && incident?.user_can_resolve;
	const showResolveButton =
		isResolveEnabled(incident?.status) && incident?.user_can_resolve;

	const handleIncidentStatusUpdate = (status: IncidentStatus) => {
		if (!incident?.id) {
			return;
		}

		if (status === 'active' || status === 'expired') {
			return;
		}

		updateIncident({
			data: {
				id: incident.id,
				monitorId: incident.monitor,
				status,
			},
		});
	};

	return (
		<NavBarWrapper>
			<NavBarBreadcrumbWrapper>
				<Breadcrumbs
					crumbs={[
						{ title: 'Monitors', href: '/monitors' },
						{ title: monitor?.name || '', href: `/monitor/${monitor?.id}` },
						{
							title: incident?.summary || '',
							href: `/incident/${incident?.id}`,
						},
					]}
				/>
			</NavBarBreadcrumbWrapper>
			<NavBarRightSideWrapper>
				{showResolveButton && (
					<Button
						variant={showAcknowledgeButton ? 'default' : 'primary'}
						onClick={() => handleIncidentStatusUpdate('resolved')}
					>
						Resolve
					</Button>
				)}
				{showAcknowledgeButton && (
					<Button
						variant={'primary'}
						onClick={() => handleIncidentStatusUpdate('acknowledged')}
					>
						Acknowledge
					</Button>
				)}
			</NavBarRightSideWrapper>
			<NavBarSidesheetControlsWrapper>
				<AIAssistantSidebarToggle />
				<EntityPageSidebarToggle />
			</NavBarSidesheetControlsWrapper>
		</NavBarWrapper>
	);
}

export default V2IncidentPageNavbar;
