import { Group } from '@mantine/core';
import { Text } from '@repo/foundations';
import React from 'react';
import { useSchema, useSecodaEntity } from '../../../api';
import { LoadingSpinner } from '../../LoadingSpinner';
import type { DataQualityBreakdownSubItem } from '../types';
import { AddOwnerAction } from './AddOwnerAction';
import { AddTagAction } from './AddTagAction';
import { AnswerQuestionAction } from './AnswerQuestionAction';
import { CreateMonitorAction } from './CreateMonitorAction';
import { FixMonitorAction } from './FixMonitorAction';
import { SetDescriptionAction } from './SetDescriptionAction';

interface DataQualitySuggestionActionsProps {
	item: DataQualityBreakdownSubItem;
}

export function DataQualitySuggestionActions({
	item,
}: DataQualitySuggestionActionsProps) {
	const hasActions = item.action?.length > 0;
	const isSchema = item.entity.entity_type === 'schema';

	const {
		data: entity,
		isLoading,
		isError,
	} = useSecodaEntity({
		id: item.entity.id,
		options: {
			enabled: hasActions && !isSchema,
		},
	});

	const {
		data: schema,
		isLoading: isSchemaLoading,
		isError: isSchemaError,
	} = useSchema({
		id: item.entity.id,
		options: {
			enabled: hasActions && isSchema,
		},
	});

	if (!hasActions) {
		return null;
	}

	if ((isSchema && isSchemaLoading) || (!isSchema && isLoading)) {
		return <LoadingSpinner />;
	}

	if ((isSchema && isSchemaError) || (!isSchema && isError)) {
		return <Text size="sm">Failed to load actions</Text>;
	}

	const entityData = entity ?? schema;

	if (!entityData) {
		return null;
	}

	return (
		<Group spacing="sm">
			{item.action.map((action) => (
				<React.Fragment key={action}>
					{action === 'CREATE_NULLNESS_MONITOR' && (
						<CreateMonitorAction
							entity={entityData}
							metricType="null_percentage"
						/>
					)}
					{action === 'CREATE_UNIQUENESS_MONITOR' && (
						<CreateMonitorAction
							entity={entityData}
							metricType="unique_percentage"
						/>
					)}
					{action === 'ADD_TAGS' && <AddTagAction entity={entityData} />}
					{action === 'ADD_OWNERS' && <AddOwnerAction entity={entityData} />}
					{action === 'ADD_DESC' && (
						<SetDescriptionAction entity={entityData} />
					)}
					{action === 'ADD_COLUMN_DESC' && (
						<SetDescriptionAction entity={entityData} />
					)}
					{action === 'ADD_SCHEMA_DESC' && (
						<SetDescriptionAction entity={entityData} />
					)}
					{action === 'ANSWER_QUESTIONS' && (
						<AnswerQuestionAction entity={entityData} />
					)}
					{action === 'SET_SLA' && (
						<CreateMonitorAction entity={entityData} metricType="freshness" />
					)}
					{[
						'FIX_SLA',
						'FIX_NULLNESS_MONITOR',
						'FIX_UNIQUENESS_MONITOR',
						'FIX_OTHER_TESTS',
					].includes(action) && <FixMonitorAction suggestion={item} />}
				</React.Fragment>
			))}
		</Group>
	);
}
