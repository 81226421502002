import { Stack } from '@mantine/core';
import { EntityType } from '@repo/common/enums/entityType';
import { isEmpty } from 'lib0/object';
import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useAuthUser, useIntegrationList } from '../../api';
import { useTeamEmptyState } from '../../api/hooks/team/emptyStates';
import { type ButtonDetails, EmptyState } from '../../components/EmptyState';
import { FilterOptionType } from '../../components/Filter';
import { useEntityBreadcrumbTeam } from '../../hooks/useEntityBreadcrumbs';
import { CatalogTable } from './CatalogTable';
import { useStyles } from './TeamCatalogPage.styles';

const COLUMN_VISIBILITY = {
	catalogType: EntityType.all,
	catalogServerType: 'catalog',
} as const;

const EXCLUDED_ITEMS = {
	[FilterOptionType.NATIVE_TYPE]: [
		EntityType.document,
		EntityType.dictionary_term,
		EntityType.collection,
		EntityType.question,
		EntityType.metric,
		EntityType.user,
	],
} as { readonly [key in FilterOptionType]?: string[] };

const ADDITIONAL_FILTERS = [
	'integration_id',
	'schema',
	'database',
	'sources',
	'schema',
	'related',
	'slack_channels__contains',
] as const;

const PAGINATION_LIST_OPTIONS = {
	suspense: true,
};

function TeamCatalogPage() {
	const params = useParams();
	const teamId = params.teamId as string;

	const { classes } = useStyles();

	const navigate = useNavigate();

	const { isAdminUser } = useAuthUser();

	const { setTeamBreadcrumb } = useEntityBreadcrumbTeam();

	const { data: integrations } = useIntegrationList({});

	const defaultFilters: Record<string, unknown> = {};

	if (teamId) {
		defaultFilters.teams = teamId;
	}

	useEffect(() => {
		// If the user opens a entity page from this context we want the team
		// breadcrumb to match the team of this page.
		if (teamId) {
			setTeamBreadcrumb(teamId);
		}
	}, [setTeamBreadcrumb, teamId]);

	const navigateToIntegrationsBrowse = useCallback(() => {
		navigate(`/integrations/browse?teamId=${teamId}`);
	}, [navigate, teamId]);

	const emptyState = useTeamEmptyState(teamId, 'catalog', {
		enabled: isAdminUser,
	});

	const defaultSearchParams = useMemo(
		() => ({
			team_id: teamId,
		}),
		[teamId]
	);

	// If the empty state is loaded and indeed empty, then show the empty state.
	if (emptyState?.is_empty || isEmpty(integrations?.results)) {
		const buttons: ButtonDetails[] = isAdminUser
			? [
					{
						name: 'Learn more',
						action: () => {
							window.open('https://docs.secoda.co/features/catalog', '_blank');
						},
						isPrimary: false,
						size: 'md',
						leftIconName: 'externalLink',
					},
					{
						name: 'Add integration',
						action: navigateToIntegrationsBrowse,
						isPrimary: true,
						size: 'md',
					},
				]
			: [];

		return (
			<EmptyState
				illustrationName="catalog"
				title="Welcome to Catalog"
				description="Organize all your data assets in one central hub. Connect your integrations to start documenting and organizing your data."
				includeGoBack={false}
				buttons={buttons}
				stateHeight="80vh"
				size="lg"
			/>
		);
	}

	return (
		<Stack className={classes.wrapper} spacing={0}>
			<CatalogTable
				excludeItems={EXCLUDED_ITEMS}
				withCustomProperties={EntityType.all}
				columnVisibility={COLUMN_VISIBILITY}
				defaultRequiredSearchParams={defaultSearchParams}
				additionalFilters={ADDITIONAL_FILTERS}
				usePaginationListOptions={PAGINATION_LIST_OPTIONS}
			/>
		</Stack>
	);
}

export default TeamCatalogPage;
