import useUrlState from '@ahooksjs/use-url-state';
import { Stack, Tabs } from '@mantine/core';
import { Breadcrumbs, Text, Title } from '@repo/foundations';
import { observer } from 'mobx-react-lite';
import { useAuthUser } from '../../api';
import {
	NavBarBreadcrumbWrapper,
	NavBarWrapper,
} from '../../components/SecodaAppShell/SecodaNavBar';
import { IS_LOCAL_OR_DEVELOPMENT } from '../../utils/envs';
import { BrowseTab } from './BrowseTab';

import { CreateTab } from './CreateTab';

function IntegrationsBrowsePage() {
	const [state, setState] = useUrlState<{ tab: string }>({ tab: 'browse' });

	const { workspace } = useAuthUser();
	const marketplaceEnabled =
		IS_LOCAL_OR_DEVELOPMENT || Boolean(workspace.marketplace_enabled);

	return (
		<Stack spacing={0}>
			<NavBarWrapper>
				<NavBarBreadcrumbWrapper>
					<Breadcrumbs
						crumbs={[{ title: 'Integrations', href: '/integrations' }]}
					/>
				</NavBarBreadcrumbWrapper>
			</NavBarWrapper>
			<Stack px="2xl">
				<Title size="3xl">Find an integration</Title>
				<Text size="md">
					{marketplaceEnabled
						? 'Browse and connect integrations or create your own integration for the community.'
						: 'Connect your metadata in just a few minutes, with no code or engineering required.'}
				</Text>
				{marketplaceEnabled ? (
					<>
						<Tabs
							color="gray.9"
							value={state.tab}
							onTabChange={(value) => setState({ tab: value })}
						>
							<Tabs.List>
								<Tabs.Tab value="browse">Browse</Tabs.Tab>
								<Tabs.Tab value="create">Create</Tabs.Tab>
							</Tabs.List>
						</Tabs>
						{state.tab === 'browse' && <BrowseTab />}
						{state.tab === 'create' && <CreateTab />}
					</>
				) : (
					<BrowseTab />
				)}
			</Stack>
		</Stack>
	);
}

export default observer(IntegrationsBrowsePage);
