import { Box, createStyles } from '@mantine/core';
import { Text, Title } from '@repo/foundations';
import { space } from '@repo/theme/primitives';
import { kebabCase } from 'lodash-es';

interface ISettingProps {
	title: string;
	description: string;
	children?: React.ReactNode;
	callToAction?: React.ReactNode;
}

const useStyles = createStyles(() => ({
	wrapper: {
		minWidth: space[160],
		marginTop: space[6],
		marginBottom: space[6],
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
}));

export function Setting({
	title,
	description,
	children,
	callToAction,
}: ISettingProps) {
	const { classes } = useStyles();

	return (
		<Box className={classes.wrapper}>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					gap: '10px',
				}}
			>
				<Title id={`${kebabCase(title)}-section`} order={2} size="md">
					{title}
				</Title>
				<Text size="sm">
					{description} {callToAction}
				</Text>
			</Box>
			{children}
		</Box>
	);
}
