import type { ISecodaEntity } from '../../api';
import { useResourceActivity } from '../../api/hooks/notification/useResourceActivity';
import type { SecodaEntity } from '../../lib/models';
import Timeline from './Timeline';

interface IActivityLogProps {
	entity?: SecodaEntity | ISecodaEntity;
}

function ActivityLog({ entity }: IActivityLogProps) {
	const { data: notifications } = useResourceActivity(entity?.id);

	if (!notifications) {
		return null;
	}

	return <Timeline notifications={notifications} entity={entity} />;
}

export default ActivityLog;
