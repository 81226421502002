import { useMemo } from 'react';
import type { AutomationHistory } from '../../api/types/models/automation/automationHistory';
import type { AutomationJob } from '../../api/types/models/automation/automationJob';
import { LastUpdatedRender, TitleRender } from '../TableV2/render';
import type { ExtendedDataTableColumn } from '../TableV2/types';
import { AutomationStatusColumn } from './AutomationStatusColumn.tsx';

export const useColumns = (): ExtendedDataTableColumn<AutomationJob>[] =>
	useMemo(
		() => [
			{
				title: 'Run time',
				accessor: 'started_at',
				render: (record) => (
					<LastUpdatedRender record={record} field={'started_at'} />
				),
			},
			{
				title: 'Action',
				accessor: 'action',
				textAlignment: 'left',
				render: (job: AutomationJob) => (job.is_rollback ? 'Revert' : 'Run'),
			},
			{
				title: 'Resources updated',
				accessor: 'affected_records_count',
				textAlignment: 'right',
				render: (job: AutomationJob) => job.affected_records_count,
			},
			{
				title: 'Status',
				accessor: 'status',
				textAlignment: 'left',
				render: (record) => <AutomationStatusColumn job={record} />,
			},
		],
		[]
	);

export const useHistoryTableColumns =
	(): ExtendedDataTableColumn<AutomationHistory>[] => {
		const columns: ExtendedDataTableColumn<AutomationHistory>[] = useMemo(
			() => [
				{
					title: 'Resource',
					accessor: 'title',
					render: (record) => <TitleRender record={record} />,
					width: '150px',
				},
				{
					title: 'Changes',
					accessor: 'Changes',
					render: (record: AutomationHistory) => {
						const {
							changed_owners,
							changed_tags,
							changed_descriptions,
							changed_definitions,
							changed_pii,
							changed_verified,
							changed_published,
							changed_teams,
							changed_collections,
							changed_subscribers,
						} = record;

						const changes: Array<string> = [];

						if (changed_owners) {
							changes.push(`Owners ${changed_owners}`);
						}
						if (changed_tags) {
							changes.push(`Tags ${changed_tags}`);
						}
						if (changed_descriptions) {
							changes.push(`Description ${changed_descriptions}`);
						}
						if (changed_definitions) {
							changes.push(`Documentation ${changed_definitions}`);
						}
						if (changed_pii) {
							changes.push(`PII ${changed_pii}`);
						}
						if (changed_verified) {
							changes.push(`Verified ${changed_verified}`);
						}
						if (changed_published) {
							changes.push(`Published ${changed_published}`);
						}
						if (changed_teams) {
							changes.push(`Teams ${changed_teams}`);
						}
						if (changed_collections) {
							changes.push(`Collections ${changed_collections}`);
						}
						if (changed_subscribers) {
							changes.push(`Subscribers ${changed_subscribers}`);
						}

						if (changes.length > 0) {
							return changes.join(', ');
						}

						return 'No changes';
					},
				},
			],
			[]
		);

		return columns;
	};
