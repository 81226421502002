import { Group, Tooltip } from '@mantine/core';
import {
	apiQueryKey,
	useApiEntityHasCustomPermissions,
	useApiResetEntityCustomPermissions,
} from '@repo/api-codegen';
import { Button } from '@repo/foundations';
import type { ISecodaEntity } from '../../api';
import { queryClient } from '../../api';
import type { SecodaEntity } from '../../lib/models';
import CopyLinkButton from '../../pages/TablePage/CopyLinkButton';

type IFooterProps = { model: SecodaEntity | ISecodaEntity; readOnly: boolean };

export function Footer({ model, readOnly }: IFooterProps) {
	const { data: hasCustom } = useApiEntityHasCustomPermissions({
		pathParams: {
			entityId: model.id,
		},
	});

	const {
		mutateAsync: apiResetCustomPermission,
		isLoading: isLoadingResetCustomPermission,
	} = useApiResetEntityCustomPermissions({});

	const resetCustomPermission = async () => {
		await apiResetCustomPermission({
			pathParams: {
				entityId: model.id,
			},
		});
		await queryClient.invalidateQueries(
			apiQueryKey('resource/all/{entity_id}/permissions_v2', {
				entity_id: model.id,
			})
		);
	};

	const showResetPermissions = !readOnly && hasCustom;

	return (
		<Group position={showResetPermissions ? 'apart' : 'right'} px="lg" py="md">
			{showResetPermissions && (
				<Tooltip
					label="This resource is currently set to have independent permissions and no longer inherits permissions from its parent teams or resources. Click here to revert and allow it to inherit permissions from its parent again"
					multiline
					w={400}
				>
					<Button
						size="md"
						variant="default"
						tone="critical"
						leftIconName="arrowBackUp"
						loading={isLoadingResetCustomPermission}
						onClick={resetCustomPermission}
					>
						Reset permissions
					</Button>
				</Tooltip>
			)}
			<CopyLinkButton />
		</Group>
	);
}
