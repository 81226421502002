/* eslint-disable react-refresh/only-export-components */
import { observer } from 'mobx-react-lite';

import { useState } from 'react';
import type { ISecodaEntity, ITest } from '../../../api';
import { TableV2 } from '../../../components/TableV2';

import { useTestList } from '../../../api/hooks/test';
import type { SecodaEntity } from '../../../lib/models';
import type { ColumnEntityTabsStore } from '../../ColumnEntityPage/ColumnEntityTabs/ColumnEntityTabsStore';
import type { TableEntityTabsStore } from '../../TableEntityPage/TableEntityTabs/TableEntityTabsStore';
import TestDrawer from './TestDrawer';
import { useColumns } from './hooks';

function Tests(props: {
	entity: SecodaEntity | ISecodaEntity;
	store: TableEntityTabsStore | ColumnEntityTabsStore;
}) {
	const { entity, store } = props;
	const columns = useColumns();

	const [testDetailsShown, setTestDetailsShown] = useState(false);
	const [currentTest, setCurrentTest] = useState<ITest | null>(null);

	return (
		<>
			<TableV2
				onRowClick={(test) => {
					setTestDetailsShown(true);
					setCurrentTest(test);
				}}
				columns={columns}
				onTotalRowCountChange={store.setTestCount}
				usePaginationList={useTestList}
				defaultSort={null}
				defaultRequiredSearchParams={{
					entity_id:
						entity.entity_type === 'table'
							? entity.id
							: (entity.parent as string),
				}}
				listFilterFunction={
					entity.entity_type === 'column'
						? (test) =>
								test.columns?.includes(entity.title || 'Untitled') ?? false
						: undefined
				}
			/>
			{currentTest && (
				<TestDrawer
					test={currentTest}
					opened={testDetailsShown}
					onClose={() => {
						setTestDetailsShown(false);
						setCurrentTest(null);
					}}
				/>
			)}
		</>
	);
}

export default observer(Tests);
