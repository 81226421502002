import { Group, Stack } from '@mantine/core';
import { useNavigate } from '@repo/common/hooks/useNavigate';
import { Icon, Text } from '@repo/foundations';
import dayjs from 'dayjs';
import { startCase } from 'lodash-es';
import type { INotification } from '../../../../api/types/models/notification';
import { SecodaEntityIcon } from '../../../../components/SecodaEntity';
import { UserAvatar } from '../../../../components/UserAvatar';
import { buildResourceUrl } from '../../../../utils/navigationUtils';
import { useStyles } from './styles';

interface IResourcePublishedContentProps {
	selected: INotification;
}

function ResourcePublishedContent({
	selected,
}: IResourcePublishedContentProps) {
	const { classes, cx } = useStyles();

	const navigate = useNavigate();

	const handleNavigateToUser = () => {
		navigate(`/user/${selected.sender.id}`);
	};

	const handleNavigateToResource = () => {
		navigate(
			buildResourceUrl(
				selected.entity || {
					id: selected.resource_id,
					entity_type: selected.resource_type,
				}
			)
		);
	};

	return (
		<Stack spacing="lg">
			<Group spacing="xs" align="flex-start">
				<Group className={classes.leftCol} spacing="xs">
					<Icon name="user" />
					User
				</Group>
				<Group
					className={cx(classes.rightCol, classes.cursorPointer)}
					spacing="xs"
					onClick={handleNavigateToUser}
				>
					<UserAvatar user={selected.sender} size="lg" />
					<Stack spacing={0}>
						<Text className={classes.label}>
							{selected.sender.display_name}
						</Text>
						<Text className={classes.subLabel}>{`${startCase(
							selected.sender.role
						)} • ${selected.sender.email}`}</Text>
					</Stack>
				</Group>
			</Group>
			<Group spacing="xs" align="flex-start">
				<Group className={classes.leftCol} spacing="xs">
					<Icon name="file" />
					Question
				</Group>
				<Group
					className={cx(classes.rightCol, classes.cursorPointer)}
					spacing="xs"
					onClick={handleNavigateToResource}
				>
					<SecodaEntityIcon entity={selected.entity} />
					<Stack spacing={0}>
						<Text className={classes.label}>
							{selected.entity?.title || selected.resource_title}
						</Text>
					</Stack>
				</Group>
			</Group>
			<Group spacing="xs" align="flex-start">
				<Group className={classes.leftCol} spacing="xs">
					<Icon name="clock" />
					Updated time
				</Group>
				<Group className={classes.rightCol} spacing="xs" noWrap>
					<Stack spacing={0}>
						<Text className={classes.soleLabel}>
							{dayjs(selected.created_at).format('MMMM D, YYYY h:mm A')}
						</Text>
					</Stack>
				</Group>
			</Group>
		</Stack>
	);
}

export default ResourcePublishedContent;
