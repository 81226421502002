import { useQuery } from '@tanstack/react-query';
import { find } from 'lodash-es';
import { apiClient, getEndpoints } from '../../common';
import type { Measurement } from '../../types';
import { measurementsQueryKeyFactory } from './constants';

export function useIncidentMeasurement(incidentId?: string) {
	return useQuery(
		[...measurementsQueryKeyFactory.all(), incidentId],
		async ({ signal }) => {
			const url = getEndpoints([
				...measurementsQueryKeyFactory.namespace,
				'incident_measurement',
			]).root();

			const params = { incident: incidentId };

			const { data } = await apiClient.get<Measurement[]>(url, {
				params,
				signal,
			});

			// FIXME: This is a hack to get the latest measurement
			const measurement = find(
				data,
				({ incident_id: incident }: any) => incident === incidentId
			);

			if (!measurement) {
				throw new Error('No measurement found');
			}

			return measurement;
		},
		{
			enabled: !!incidentId,
		}
	);
}
