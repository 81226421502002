import { Box, Group, Timeline } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { Button, Text } from '@repo/foundations';
import moment from 'moment';
import { useCallback, useState } from 'react';
import type { ISecodaEntity } from '../../../api';
import { useThisUser, useUpdateSecodaEntity } from '../../../api';
import type { NewSnapshotFormat } from '../../../api/hooks/secodaEntity/useDefinitionSnapshots';
import {
	useLocalSnapshots,
	useRemoteSnapshots,
} from '../../../api/hooks/secodaEntity/useDefinitionSnapshots';
import { useExtendedUserList } from '../../../api/hooks/user/useExtendedUserList';
import { MIN_LENGTH_FOR_SNAPSHOT } from '../../Documentation/utils';

import type { SecodaEntity } from '../../../lib/models/entity';
import { isImpersonating } from '../../../utils/cache/utils';
import { EmptyState } from '../../EmptyState';
import { MarkdownDiff } from '../../MarkdownDiff/MarkdownDiff';
import { openModal } from '../../ModalManager/events';
import { RichEditor } from '../../RichEditor';
import { workspaceSelectorStore } from '../../SecodaAppShell/SideBar/WorkspaceSelector/store';
import { UserAvatar } from '../../UserAvatar';
import { useStyles } from './DefinitionSnapshots.styles';

// Props interface for the component
export interface IDefinitionSnapshotsProps {
	entity: SecodaEntity | ISecodaEntity;
}

/**
 * DefinitionSnapshots component displays a documentation editor along with a sidebar containing a list of documentation snapshots.
 * It fetches data from both remote and local sources to populate the snapshots and allows the user to select and view different snapshots.
 *
 * @component
 * @param {Object} props - Component props
 * @param {string} props.entity - The entity for which the documentation snapshots are to be displayed.
 *
 * @example
 * import React from 'react';
 * import { DefinitionSnapshots } from './DefinitionSnapshots';
 *
 * function MyComponent() {
 *   const entityId = '123456'; // Replace '123456' with the actual entity ID
 *   return <DefinitionSnapshots entityId={entityId} />;
 * }
 */

export function DefinitionSnapshots({ entity }: IDefinitionSnapshotsProps) {
	// Retrieve the classes object for applying styles.
	const { classes, theme } = useStyles();

	// State to keep track of the selected snapshot's timestamp.
	const [selectedSnapshotTimestamp, setSelectedSnapshotTimestamp] = useState<
		string | null
	>(null);

	const { mutateAsync: updateSecodaEntity } = useUpdateSecodaEntity({});

	const { data: user } = useThisUser();
	const users = useExtendedUserList();

	// Fetch remote and local data for documentation snapshots.
	const { data: remoteData, isLoading } = useRemoteSnapshots({ id: entity.id });
	const { data: localData } = useLocalSnapshots({ id: entity.id });

	// Extract remote and local definition snapshots from the data.
	const remoteSnapshots = remoteData?.definition_snapshots ?? {};
	const localSnapshots = localData ?? {};

	const convertLegacySnapshot = useCallback(
		(value: string | NewSnapshotFormat, defaultCurrentUser = false) => {
			if (typeof value === 'string') {
				return {
					definition: value,
					user: defaultCurrentUser && user ? user.id : null,
				};
			}
			return value;
		},
		[user]
	);

	const migrateRecordValues = useCallback(
		(
			record: Record<string, string | NewSnapshotFormat>,
			defaultCurrentUser: boolean
		) => {
			const updatedRecord: Record<string, NewSnapshotFormat> = {};
			Object.keys(record).forEach((key) => {
				updatedRecord[key] = convertLegacySnapshot(
					record[key],
					defaultCurrentUser
				);
			});
			return updatedRecord;
		},
		[convertLegacySnapshot]
	);

	// Combine remote and local snapshots into a single snapshots object.
	const snapshots: Record<string, NewSnapshotFormat> = {
		...migrateRecordValues(remoteSnapshots, false),
		...migrateRecordValues(localSnapshots, true),
	};

	// Function to generate a label for each snapshot based on its timestamp.
	const label = (id: string) =>
		`${moment(moment.utc(id).toDate()).format('MMMM D YYYY, h:mm a')} ${
			Object.keys(localSnapshots).includes(id) ? '*' : ''
		}`;

	// Sort the timestamps of snapshots in descending order and filter out
	// snapshots below the minimum length.
	const timestamps = Object.keys(snapshots)
		.sort((a, b) => moment(b).diff(moment(a)))
		.filter(
			(value) => snapshots?.[value].definition.length >= MIN_LENGTH_FOR_SNAPSHOT
		);

	// Get the content of the selected snapshot or use the latest snapshot as the default.
	const snapshot = selectedSnapshotTimestamp ?? timestamps?.[0] ?? '';
	const definition = snapshots?.[snapshot]?.definition ?? '';

	const handleRestoreSnapshot = useCallback(async () => {
		await updateSecodaEntity({
			data: {
				id: entity.id,
				definition,
			},
		});
		showNotification({
			title: 'Snapshot restoring',
			message: 'The page will reload',
		});
		setTimeout(() => {
			window.location.reload();
		}, 1000);
	}, [definition, entity, updateSecodaEntity]);

	const openDiff = useCallback(() => {
		openModal({
			fullScreen: true,
			title: 'Snapshot diff viewer',
			children: (
				<MarkdownDiff
					oldValue={entity.definition ?? ''}
					newValue={definition}
				/>
			),
		});
	}, [definition, entity]);

	if (isLoading) {
		return null;
	}

	// If no snapshots are available or all snapshots are empty, display an empty
	// state.
	if (
		Object.keys(snapshots).length === 0 ||
		Object.keys(snapshots).every(
			(key) => snapshots[key].definition.length < MIN_LENGTH_FOR_SNAPSHOT
		)
	) {
		return (
			<Box m={24}>
				<EmptyState
					iconName="clockPlay"
					title="No snapshots found"
					description="Content snapshots are automatically created periodically"
					includeGoBack={false}
					size="sm"
				/>
			</Box>
		);
	}

	return (
		<Group data-testid="definition-snapshots" className={classes.container}>
			{/* Main Markdown Editor */}
			<Box className={classes.markdown}>
				<Group w="100%" position="right" spacing="sm" px={theme.spacing.sm}>
					{(workspaceSelectorStore?.account?.is_superuser ||
						isImpersonating()) && (
						<Button onClick={openDiff}>Toggle diff</Button>
					)}
					{snapshot && (
						<Button onClick={handleRestoreSnapshot} variant="default">
							Restore snapshot
						</Button>
					)}
				</Group>
				<RichEditor value={definition} readOnly />
			</Box>

			{/* Sidebar */}
			<Box className={classes.sidebar}>
				<Timeline bulletSize={24}>
					{timestamps.map((snapshotId) => {
						const selected = snapshot === snapshotId;
						const snapshotUser = users.activeUsers?.find(
							(userParam) => userParam.id === snapshots?.[snapshotId].user
						);
						return (
							<Timeline.Item
								key={snapshotId}
								className={classes.navlink}
								onClick={() => setSelectedSnapshotTimestamp(snapshotId)}
								active={selected}
								title={
									<Text size="sm" td={selected ? 'underline' : undefined}>
										{snapshotUser?.display_name ?? 'Legacy Snapshot'}
									</Text>
								}
								bullet={
									snapshotUser ? (
										<UserAvatar size="sm" user={snapshotUser} />
									) : null
								}
							>
								<Text variant="secondary" size="sm">
									{label(snapshotId)}
								</Text>
							</Timeline.Item>
						);
					})}
				</Timeline>
			</Box>
		</Group>
	);
}
