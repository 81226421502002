import type { MantineTheme } from '@mantine/core';
import { Center, createStyles, Loader } from '@mantine/core';

import { showNotification } from '@mantine/notifications';
import { EntityType } from '@repo/common/enums/entityType';
import { IconButton, ListBox } from '@repo/foundations';
import type { IconButtonProps } from '@repo/foundations/components/Buttons/IconButton';
import { size } from 'lodash-es';
import { useCallback, useMemo, useState } from 'react';
import {
	useMention,
	type ISecodaEntity,
	type SearchResult,
} from '../../../../api';
import {
	invalidateResourceRelationList,
	useCreateResourceRelation,
} from '../../../../api/hooks/relations';
import type { SecodaEntity } from '../../../../lib/models';
import { useFeatureFlags } from '../../../../utils/featureFlags';
import type { ButtonDetails } from '../../../EmptyState';
import { EmptyState } from '../../../EmptyState';
import { RelatedResourceAIChatItem } from './RelatedResourceAIChatItem';
import RelatedResourceItem from './RelatedResourceItem';

interface RelatedResourceDropdownProps extends Partial<IconButtonProps> {
	entity: SecodaEntity | ISecodaEntity;
	relatedEntityIds: string[];
}

const useStyles = createStyles((theme: MantineTheme) => ({
	emptyState: {
		padding: theme.spacing['2xs'],
	},
}));

const extractUUID = (text: string) => {
	const uuidRegex =
		/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/i;
	const match = text.match(uuidRegex);
	return match ? match[0] : null;
};

const checkLinkToAI = (text: string) => {
	const aiLinkRegex =
		/ai\/([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})$/i;
	return aiLinkRegex.test(text);
};

export function RelatedResourceCreateMenu({
	entity,
	relatedEntityIds,
	...iconButtonProps
}: RelatedResourceDropdownProps) {
	const { aiToQuestionsAnswers } = useFeatureFlags();

	const { mutateAsync: createResourceRelation } = useCreateResourceRelation({
		options: {
			onSuccess: () => {
				showNotification({
					message: 'Related resource added.',
					color: 'green',
				});
				invalidateResourceRelationList(entity.id);
				setOpened(false);
			},
			onError: () => {
				showNotification({
					message: 'Failed to add a related resource',
					color: 'red',
				});
			},
		},
	});

	const handleCreateResourceRelation = useCallback(
		(selected: SearchResult) => {
			createResourceRelation({
				data: {
					from_entity: entity.id,
					to_entity: selected.id,
				},
			});
		},
		[createResourceRelation, entity.id]
	);

	const [opened, setOpened] = useState<boolean>(false);

	const [searchTerm, setSearchTerm] = useState('');
	const [AILinkProvided, setAILinkProvided] = useState(false);

	const { classes, theme } = useStyles();

	const handleSearchChange = (value: string) => {
		setSearchTerm(value);
		setAILinkProvided(checkLinkToAI(value.trim()));
	};
	const { isFetching, data } = useMention({
		searchTerm: searchTerm,
		filters: {
			operator: 'and',
			operands: [
				{
					field: 'entity_type',
					operator: 'in',
					value: Object.values(EntityType).filter(
						(el) =>
							![
								EntityType.user,
								EntityType.user_group,
								EntityType.question_reply,
								EntityType.event_property,
								EntityType.collection,
							].includes(el)
					),
					operands: [],
				},
				{
					operator: 'not',
					operands: [
						{
							operands: [],
							operator: 'in',
							field: 'id',
							value: [...relatedEntityIds, entity.id],
						},
					],
				},
			],
		},
		options: {
			select: (data) => data.results,
		},
	});

	const emptyButtons: ButtonDetails[] = useMemo(
		() => [
			{
				name: 'Clear search',
				action: () => setSearchTerm(''),
				isPrimary: false,
				size: 'sm',
			},
		],
		[setSearchTerm]
	);

	const height =
		size(data) > 6 ? theme.other.space[60] : size(data) * theme.other.space[12];

	const handleOpenChange = useCallback(
		(newOpened: boolean) => {
			setSearchTerm('');
			setOpened(newOpened);
		},
		[setSearchTerm]
	);

	const items: (SearchResult | { id: 'ai' })[] | undefined = useMemo(() => {
		if (
			AILinkProvided &&
			entity.entity_type === EntityType.question &&
			aiToQuestionsAnswers
		) {
			return [
				{
					id: 'ai',
				},
				...(data || []),
			];
		}
		return data;
	}, [AILinkProvided, entity.entity_type, aiToQuestionsAnswers, data]);

	return (
		<ListBox opened={opened} onOpenChange={handleOpenChange} closeOnEscape>
			<ListBox.Target>
				<IconButton
					iconName="plus"
					variant="tertiary"
					tooltip="Add related resource"
					{...iconButtonProps}
				/>
			</ListBox.Target>
			<ListBox.ItemsDropdown
				usePortal
				search={{
					onChange: handleSearchChange,
					value: searchTerm,
					placeholder: 'Search or add link',
				}}
				items={items}
				renderItem={(item, getProps) => {
					if (item.id === 'ai') {
						return (
							<RelatedResourceAIChatItem
								promptId={extractUUID(searchTerm) || ''}
								entityId={entity.id}
							/>
						);
					} else {
						return (
							<RelatedResourceItem
								item={item as SearchResult}
								onClick={handleCreateResourceRelation}
								isViewerUser={false}
								key={item.id}
								getProps={getProps}
							/>
						);
					}
				}}
			>
				{isFetching ? (
					<Center h={height} w={theme.other.space[60]}>
						<Loader size="sm" />
					</Center>
				) : (
					size(data) === 0 && (
						<EmptyState
							iconName="search"
							title="No results found"
							description="No resources or invalid search"
							buttons={emptyButtons}
							includeGoBack={false}
							size="sm"
							width={theme.other.space[60]}
							className={classes.emptyState}
						/>
					)
				)}
			</ListBox.ItemsDropdown>
		</ListBox>
	);
}
