import { useQuestion } from '.';
import type { IQuestion, IQuestionReply } from '../../types';
import { useAuthUser } from '../authUser';

export function useQuestionPermissions(
	entity: IQuestion | IQuestionReply,
	type: 'question' | 'reply' = 'reply'
) {
	const { user, isAdminUser } = useAuthUser();

	const { data: questionEntity } = useQuestion({
		id: type === 'reply' ? entity.parent! : entity.id,
	});

	const isOwner = entity?.owners.includes(user.id);
	const isQuestionOwner = questionEntity?.owners.includes(user.id);
	const isAssignedTo = entity?.assigned_to === user.id;

	return {
		canDelete: isAdminUser || isOwner,
		canEdit: isOwner,
		canReact: true,
		canReply: true,
		canAccept: isAdminUser || isAssignedTo || isQuestionOwner,
	};
}
