import { useMergedRef } from '@mantine/hooks';
import { cloneElement } from 'react';
import { useListBoxContext } from './context';

interface ListBoxTargetProps {
	children: React.ReactElement;
}

export function ListBoxTarget({ children }: ListBoxTargetProps) {
	const { getReferenceProps, refs, opened, targetId, dropdownId } =
		useListBoxContext();

	const targetRef = useMergedRef(refs.setReference, (children as any).ref);

	return cloneElement(children, {
		'aria-haspopup': 'listbox',
		'aria-expanded': opened ? true : undefined,
		'aria-controls': dropdownId,
		ref: targetRef,
		id: targetId,
		...getReferenceProps(),
	});
}
