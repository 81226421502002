import {
	Center,
	Container,
	createStyles,
	Flex,
	Group,
	ScrollArea,
	Stack,
	Tooltip,
	UnstyledButton,
} from '@mantine/core';
import { useNavigate } from '@repo/common/hooks/useNavigate';
import { Icon, type IconNames, Title } from '@repo/foundations';
import { useMemo } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router';
import { useIsSuperAdmin } from '../../api/hooks/internalQueryActions';
import { FullWidthLoadingSpinner } from '../../components/LoadingSpinner';
import { SecodaMinimalLogo } from '../../components/SecodaMinimalLogo';
import { handleLogout } from '../../utils/cache/utils';

const useStyles = createStyles((theme) => ({
	navbar: {
		width: theme.other.space[16],
		height: '100vh',
		padding: theme.spacing.md,
		borderRight: `1px solid ${theme.other.getColor('border/primary/default')}`,
	},
	navbarMain: {
		flex: 1,
		marginTop: theme.other.space[8],
	},
	link: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: theme.other.space[8],
		height: theme.other.space[8],
		borderRadius: theme.radius.sm,
		'&:hover': {
			backgroundColor: theme.other.getColor('fill/secondary/hover'),
		},
		'&[data-active]': {
			backgroundColor: theme.other.getColor('fill/secondary/active'),
			'&:hover': {
				backgroundColor: theme.other.getColor('fill/secondary/active'),
			},
		},
	},
	container: {
		flexGrow: 1,
		gap: 0,
		width: '100%',
		height: '100vh',
	},
	header: {
		justifyContent: 'space-between',
		padding: `${theme.spacing.md} ${theme.spacing.xl}`,
	},
	outletWrapper: {
		width: '100%',
		maxWidth: 'unset',
		padding: `0 ${theme.spacing.xl} ${theme.spacing.md} ${theme.spacing.xl}`,
	},
}));

const Links: {
	label: string;
	path: string;
	icon: IconNames;
}[] = [
	{
		label: 'Integrations Status',
		path: 'integrations',
		icon: 'plugConnected',
	},
	{
		label: 'Query Actions',
		path: 'query-actions',
		icon: 'calculator',
	},
	{
		label: 'Inspect Integration',
		path: 'inspect-integration',
		icon: 'code',
	},
	{
		label: 'Background jobs',
		path: 'background-job',
		icon: 'briefcase',
	},
	{
		label: 'Inspect SQL Lineage',
		path: 'inspect-sql-lineage',
		icon: 'wreckingBall',
	},
];

interface INavbarLinkProps {
	label: string;
	icon: IconNames;
	active?: boolean;

	onClick?(): void;
}

function NavbarLink({ label, icon, active, onClick }: INavbarLinkProps) {
	const { classes } = useStyles();

	return (
		<Tooltip label={label} position="right" transitionProps={{ duration: 0 }}>
			<UnstyledButton
				onClick={onClick}
				className={classes.link}
				data-active={active || undefined}
			>
				<Icon name={icon} size="md" />
			</UnstyledButton>
		</Tooltip>
	);
}

function InternalPage() {
	const location = useLocation();

	const active = location.pathname.split('/')[2] ?? '';

	const { classes, theme } = useStyles();

	const navigate = useNavigate();

	const { data: isSuperAdmin, isLoading: isSuperAdminLoading } =
		useIsSuperAdmin();

	const pageTitle = useMemo(
		() => Links.find((link) => link.path === active)?.label,
		[location.pathname]
	);

	if (isSuperAdminLoading) {
		return <FullWidthLoadingSpinner />;
	}

	if (!isSuperAdmin) {
		return <Navigate to="/" />;
	}

	const links = Links.map((link) => (
		<NavbarLink
			{...link}
			key={link.path}
			active={link.path === active}
			onClick={() => {
				navigate(link.path);
			}}
		/>
	));

	return (
		<Group align="flex-start" spacing={0} noWrap>
			<Stack className={classes.navbar}>
				<Center>
					<SecodaMinimalLogo
						width={theme.other.space[8]}
						height={theme.other.space[8]}
					/>
				</Center>
				<Flex className={classes.navbarMain}>
					<Stack spacing="xs">{links}</Stack>
				</Flex>
				<Stack justify="center" spacing="xs">
					<NavbarLink
						icon="switchHorizontal"
						label="Switch to Secoda"
						onClick={() => {
							navigate('/');
						}}
					/>
					<NavbarLink
						icon="doorExit"
						label="Logout"
						onClick={() => {
							handleLogout();
						}}
					/>
				</Stack>
			</Stack>
			{/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
			{/* @ts-ignore poorly typed component by Mantine */}
			<ScrollArea.Autosize className={classes.container}>
				<Group className={classes.header}>
					<Title size="lg">{pageTitle}</Title>
				</Group>
				<Container className={classes.outletWrapper}>
					<Outlet />
				</Container>
			</ScrollArea.Autosize>
		</Group>
	);
}

export default InternalPage;
