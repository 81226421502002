import { Flex, useMantineTheme } from '@mantine/core';
import { Icon, Text } from '@repo/foundations';
import { filter } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { useLocation } from 'react-router';
import { useAuthUser } from '../../../api';
import { isActive } from '../utils';
import type { Link } from './SideBarLink';
import SideBarLink from './SideBarLink';
import { sideBarStore } from './store';

function SideBarWorkspaceLinks() {
	const theme = useMantineTheme();
	const location = useLocation();

	const { isEditorUser, isAdminUser, workspace } = useAuthUser();

	const footerLinks: Array<Link> = useMemo(
		() => [
			{
				id: 'nav::integrations',
				label: 'Integrations',
				icon: <Icon name="plugConnected" color="icon/primary/default" />,
				href: ['/integrations'],
				show: isAdminUser,
			},
			{
				id: 'nav::monitors',
				label: 'Monitors',
				icon: <Icon name="activity" color="icon/primary/default" />,
				href: ['/monitors'],
				show: isAdminUser || isEditorUser,
			},
			{
				id: 'nav::automations',
				label: 'Automations',
				icon: <Icon name="bolt" color="icon/primary/default" />,
				href: ['/automations'],
				show: isAdminUser || isEditorUser,
			},
			{
				id: 'nav::forms',
				label: 'Forms',
				icon: <Icon name="writing" color="icon/primary/default" />,
				href: ['/forms'],
				show: isAdminUser && !!workspace?.forms_enabled,
			},
			{
				id: 'nav::analytics',
				label: 'Analytics',
				icon: <Icon name="chartBar" color="icon/primary/default" />,
				href: ['/analytics'],
				show: isAdminUser || isEditorUser,
			},
		],
		[isAdminUser, isEditorUser, workspace?.forms_enabled]
	);

	if (!isEditorUser && !isAdminUser) {
		// We don't want to show "just the title 'Workspace'" to guests and viewers.
		return null;
	}

	return (
		<Flex
			direction="column"
			align={sideBarStore.collapsed ? 'center' : 'flex-start'}
		>
			{!sideBarStore.collapsed && (
				<Flex
					w="100%"
					h={theme.other.space[7]}
					pl={theme.other.space[1]}
					align="center"
					justify="space-between"
				>
					<Text size="xs" color="text/secondary/default" weight="bold">
						Workspace
					</Text>
				</Flex>
			)}
			{filter(footerLinks, 'show').map((link) => (
				<SideBarLink
					key={link.id}
					link={link}
					selected={isActive(link.href, location)}
				/>
			))}
		</Flex>
	);
}

export default observer(SideBarWorkspaceLinks);
