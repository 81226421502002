import { Group } from '@mantine/core';
import type { ISecodaEntity } from '@repo/common/models/secodaEntity';
import { forwardRef } from 'react';
import { SecodaEntityIcon } from '../../SecodaEntity';
import NodeMenuItem from './NodeMenuItem';

interface ISelectItemComponentProps {
	label: string;
	value: string;
	entity: Pick<
		ISecodaEntity,
		| 'entity_type'
		| 'native_type'
		| 'icon'
		| 'integration'
		| 'id'
		| 'title'
		| 'title_cased'
		| 'search_metadata'
	>;
}

const SelectItemComponent = forwardRef<
	HTMLDivElement,
	ISelectItemComponentProps
>(({ entity, ...others }: ISelectItemComponentProps, ref) => (
	<div ref={ref} {...others}>
		<Group noWrap>
			<SecodaEntityIcon entity={entity} />
			<NodeMenuItem entity={entity} />
		</Group>
	</div>
));

SelectItemComponent.displayName = 'SelectItemComponent'; // Add this line

export default SelectItemComponent;
