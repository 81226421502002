import type { Placement } from '@floating-ui/react';
import {
	autoUpdate,
	flip,
	FloatingFocusManager,
	FloatingPortal,
	limitShift,
	offset,
	shift,
	size,
	useDismiss,
	useFloating,
	useInteractions,
	useListNavigation,
	useRole,
} from '@floating-ui/react';
import { Box, createStyles, getDefaultZIndex } from '@mantine/core';
import type { EditorView } from 'prosemirror-view';
import type { HTMLProps, PropsWithChildren } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FloatingToolbarContext } from './context';
import { getSelectionRect } from './utils';

const useStyles = createStyles((theme) => ({
	wrapper: {
		background: theme.other.getColor('surface/primary/default'),
		border: `${theme.other.borderWidth.xs}px solid ${theme.other.getColor('border/secondary/default')}`,
		boxShadow: theme.shadows.md,
		borderRadius: theme.radius.md,
		display: 'flex',
		flexDirection: 'column',
		zIndex: getDefaultZIndex('popover'),
		width: 'max-content',
	},
}));

interface FloatingToolbarProps extends HTMLProps<HTMLDivElement> {
	view: EditorView;
	placement?: Placement;
	onClose: () => void;
}

export function FloatingToolbar({
	view,
	placement = 'top',
	onClose,
	children,
	...props
}: PropsWithChildren<FloatingToolbarProps>) {
	const { classes } = useStyles();

	const { refs, floatingStyles, context } = useFloating({
		whileElementsMounted: autoUpdate,
		open: true,
		onOpenChange: (open: boolean) => open || onClose(),
		placement,
		middleware: [
			offset(8),
			shift({ limiter: limitShift() }),
			flip({ padding: 5 }),
			size({
				padding: 5,
				apply({ availableHeight }) {
					const styles = refs.floating.current?.style ?? {};

					Object.assign(styles, {
						maxHeight: `${availableHeight}px`,
					});
				},
			}),
		],
	});

	const [activeIndex, setActiveIndex] = useState<number | null>(null);
	const virtualItemRef = useRef<HTMLButtonElement | null>(null);

	const elementsRef = useRef<Array<HTMLButtonElement>>([]);

	const listNavigation = useListNavigation(context, {
		listRef: elementsRef,
		activeIndex,
		onNavigate: setActiveIndex,
		loop: true,
		virtual: true,
		virtualItemRef,
	});

	const role = useRole(context, { role: 'listbox' });
	const dismiss = useDismiss(context);

	const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
		[role, dismiss, listNavigation]
	);

	useEffect(() => {
		const clientRect = getSelectionRect(view);
		refs.setPositionReference({
			getBoundingClientRect: () => clientRect,
		});
	}, [refs, view]);

	useEffect(() => {
		const { onKeyDown } = getReferenceProps();

		function onKeyDownEditor(event: KeyboardEvent) {
			if (event.key === 'Enter') {
				event.preventDefault();
				event.stopPropagation();

				virtualItemRef.current?.click();
			}

			if (typeof onKeyDown === 'function') {
				onKeyDown?.(event);
			}
		}

		view.dom.addEventListener('keydown', onKeyDownEditor);

		return () => {
			view.dom.removeEventListener('keydown', onKeyDownEditor);
		};
		// need to update this effect whenever the selection changes
	}, [getReferenceProps, view, view.state.selection]);

	const reset = useCallback(() => {
		setActiveIndex(0);
	}, []);

	const contextValue = useMemo(
		() => ({
			context,
			refs,
			getReferenceProps,
			getItemProps,
			activeIndex,
			elementsRef,
			reset,
		}),
		[activeIndex, context, getItemProps, getReferenceProps, refs, reset]
	);

	return (
		<FloatingToolbarContext.Provider value={contextValue}>
			<FloatingPortal>
				<FloatingFocusManager context={context} modal={false} initialFocus={-1}>
					<Box
						{...props}
						ref={refs.setFloating}
						style={floatingStyles}
						className={classes.wrapper}
						{...getFloatingProps()}
					>
						{children}
					</Box>
				</FloatingFocusManager>
			</FloatingPortal>
		</FloatingToolbarContext.Provider>
	);
}
