import { ActionIcon, Box, Divider, Tooltip } from '@mantine/core';
import { Icon, Text } from '@repo/foundations';
import { observer } from 'mobx-react-lite';
import type { ISecodaEntity } from '../../api';
import { useAuthUser } from '../../api';
import { useSlackChannels } from '../../api/hooks/workspace/useSlackChannels';
import { useSlackHookList } from '../../api/hooks/workspace/useSlackHooks';
import type { SecodaEntity } from '../../lib/models';
import { User } from '../../lib/models';
import { trackEvent } from '../../utils/analytics';
import EntityNotificationHookEntry from './EntityNotificationHookEntry';
import EntityNotificationHookEntryInsert from './EntityNotificationHookEntryInsert';
import PopoverMenuItem from './PopoverMenuItem';

interface IEntityNotificationHookButtonProps {
	entity: SecodaEntity | ISecodaEntity;
	target?: React.ReactNode;
}

function EntityNotificationHookButton({
	entity,
	target,
}: IEntityNotificationHookButtonProps) {
	const { user, workspace } = useAuthUser();

	const { data: hooks } = useSlackHookList({
		filters: {
			entity: entity.id,
		},
	});

	const { data: channels } = useSlackChannels({});

	const openPopover = () => {
		open();
		trackEvent(`${entity.entity_type}/slack/click`, {}, user, workspace!);
	};

	if (!channels) {
		return null;
	}

	return (
		<PopoverMenuItem
			target={
				target || (
					<Tooltip
						label={
							<Text size="xs" color="text/inverse/default">
								Slack channels
							</Text>
						}
						position="top"
					>
						<ActionIcon onClick={openPopover} size="md">
							<Icon name="brandSlack" />
						</ActionIcon>
					</Tooltip>
				)
			}
		>
			<Box>
				{new User(user).is_admin_or_editor && (
					<EntityNotificationHookEntryInsert
						channels={channels}
						entity={entity}
					/>
				)}
				{(hooks?.count ?? 0) > 0 && (
					<Divider my="xs" label="Connected channels" labelPosition="center" />
				)}
				{hooks?.results?.map((hook) => (
					<EntityNotificationHookEntry key={hook.id} hook={hook} />
				))}
			</Box>
		</PopoverMenuItem>
	);
}

export default observer(EntityNotificationHookButton);
