import { createStyles, Divider, Flex, Group } from '@mantine/core';
import { Button, IconButton, Text } from '@repo/foundations';
import { useNavigate } from 'react-router-dom';
import type { Incident, IncidentStatus } from '../../../../api';
import { useIncidentStatusUpdate } from '../../../../api';
import {
	isAcknowledgeEnabled,
	isResolveEnabled,
} from '../../../IncidentPage/utils';
import { useIncidentDrawer } from '../IncidentDrawerContext';

interface IncidentActionBarProps {
	incident: Incident;
}

const useStyles = createStyles((theme) => ({
	buttons: {
		gap: theme.spacing.xs,
	},
	grow: {
		flexGrow: 1,
	},
	wrapper: {
		width: '100%',
		alignItems: 'center',
		paddingRight: theme.spacing.lg,
	},
}));

function IncidentActionBar({ incident }: IncidentActionBarProps) {
	const { classes } = useStyles();
	const navigate = useNavigate();
	const to = `/incident/${incident.id}`;
	const { close } = useIncidentDrawer();
	const { mutateAsync: updateIncident } = useIncidentStatusUpdate();

	const handleIncidentStatusUpdate = (status: IncidentStatus) => {
		if (!incident?.id) {
			return;
		}

		if (status === 'active' || status === 'expired') {
			return;
		}

		updateIncident({
			data: {
				id: incident.id,
				monitorId: incident.monitor,
				status,
			},
		});
	};

	const showAcknowledgeButton =
		isAcknowledgeEnabled(incident?.status) && incident?.user_can_resolve;
	const showResolveButton =
		isResolveEnabled(incident?.status) && incident?.user_can_resolve;

	const handleOpenInFullPage = () => {
		close();
		navigate(to);
	};

	return (
		<Flex className={classes.wrapper}>
			<Text size="lg" fw={600} className={classes.grow}>
				{incident.summary}
			</Text>
			<Flex className={classes.buttons} mr="sm">
				{showResolveButton && (
					<Button
						variant={showAcknowledgeButton ? 'default' : 'primary'}
						onClick={() => handleIncidentStatusUpdate('resolved')}
					>
						Resolve
					</Button>
				)}
				{showAcknowledgeButton && (
					<Button
						variant={'primary'}
						onClick={() => handleIncidentStatusUpdate('acknowledged')}
					>
						Acknowledge
					</Button>
				)}
			</Flex>
			{(showAcknowledgeButton || showResolveButton) && (
				<Divider orientation="vertical" />
			)}
			<Group spacing={4} ml="sm">
				<IconButton
					variant="tertiary"
					iconName="arrowsDiagonal"
					size="md"
					tooltip="Open in full page"
					onClick={handleOpenInFullPage}
				/>
				<IconButton
					variant="tertiary"
					iconName="x"
					size="md"
					tooltip="Close"
					onClick={close}
				/>
			</Group>
		</Flex>
	);
}

export default IncidentActionBar;
