import { Box, Group } from '@mantine/core';
import { Dropzone } from '@mantine/dropzone';
import { Button, Icon, Text } from '@repo/foundations';
import { openModal } from '../ModalManager';

export interface IModalUploadProps {
	handleDrop: (files: File[]) => void;
	title: string;
	instructions: string;
}

function ModalUpload({
	title,
	instructions,
	handleDrop,
	...props
}: IModalUploadProps) {
	const openUploadModal = () =>
		openModal({
			withCloseButton: false,
			title: 'Import data',
			centered: true,
			children: (
				<Box>
					<Dropzone onDrop={handleDrop} maxSize={16 * 1024 ** 2} {...props}>
						<Group
							position="center"
							spacing="md"
							style={{
								marginTop: 20,
								marginBottom: 20,
								minHeight: 140,
								pointerEvents: 'none',
							}}
						>
							<Dropzone.Accept>
								<Icon name="upload" size="lg" />
							</Dropzone.Accept>
							<Dropzone.Reject>
								<Icon name="x" size="lg" color="icon/critical/default" />
							</Dropzone.Reject>
							<Dropzone.Idle>
								<Icon name="file" color="icon/emphasis/default" size="lg" />
							</Dropzone.Idle>
							<Box>
								<Text
									align="center"
									color="text/secondary/default"
									inline
									mt={7}
								>
									{title}
								</Text>
							</Box>
						</Group>
					</Dropzone>
					<Text mt={20} mb={10} size="xs" color="text/secondary/default">
						{instructions}
					</Text>
				</Box>
			),
		});

	return (
		<Button variant="default" size="md" onClick={openUploadModal}>
			Select documents
		</Button>
	);
}

export default ModalUpload;
