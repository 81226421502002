import type { RequiredPick } from '../../../lib/typescript';
import type { IWidget } from '../../types';
import { useCreateBaseModel } from '../base';
import { WIDGETS_NAMESPACE } from './constants';

export interface ICreateWidgetParams {
	data: RequiredPick<IWidget, 'report' | 'type'> & Partial<IWidget>;
}

export function useCreateWidget() {
	return useCreateBaseModel<IWidget, ICreateWidgetParams>({
		namespace: WIDGETS_NAMESPACE,
	});
}
