import { Stack } from '@mantine/core';
import { CollapsableStackSkeleton } from '@repo/common/components/CollapsableStack';

export function EntityPageSidebarSkeleton() {
	return (
		<Stack spacing={0}>
			<CollapsableStackSkeleton numLines={8} />
			<CollapsableStackSkeleton numLines={3} />
			<CollapsableStackSkeleton numLines={3} />
		</Stack>
	);
}
