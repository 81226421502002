import { Paper, useMantineTheme } from '@mantine/core';

interface AuthCardProps {
	children: React.ReactNode;
}

export function AuthCard({ children }: AuthCardProps) {
	const theme = useMantineTheme();

	return (
		<Paper
			style={{
				display: 'flex',
				flexDirection: 'column',
				padding: theme.spacing['2xl'],
				borderRadius: theme.spacing.md,
				boxShadow: '0px 6px 16px 0px rgba(0, 0, 0, 0.12)',
				width: `min(100vw, ${theme.other.space[120]}px)`,
				minWidth: theme.other.space[80],
			}}
		>
			{children}
		</Paper>
	);
}
