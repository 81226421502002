import { createStyles, Group, Stack } from '@mantine/core';
import { singularize } from '@repo/common/utils';
import { Text } from '@repo/foundations';
import { map, round, size, startCase } from 'lodash-es';
import { useAdditionalPlanResourceCost } from '../../api/hooks/workspace/useLimits';

interface IPricingDetailsProps {
	type: 'viewers' | 'editors' | 'integrations';
	nominalCurrentCount: number;
	nominalChange: number;
	hidden: boolean;
}

const useStyles = createStyles((theme) => ({
	wrapper: {
		backgroundColor: theme.other.getColor('surface/secondary/default'),
		borderRadius: theme.radius.md,
		padding: theme.spacing.xl,
	},
}));

export function PricingDetails({
	type,
	nominalCurrentCount,
	nominalChange,
	hidden,
}: IPricingDetailsProps) {
	const { classes } = useStyles();

	const { additionalCosts, isIncludedInPlan, includedInPlanCount, interval } =
		useAdditionalPlanResourceCost({
			type,
			nominalCurrentCount,
			nominalNewCount: nominalCurrentCount + nominalChange,
		});

	if (hidden || size(additionalCosts) === 0) {
		return null;
	}

	const priceIncrease = round(
		additionalCosts.reduce(
			(accumulator, currentCost) => accumulator + currentCost.additionalAmount,
			0
		) / 100,
		2
	);

	const skipFirstCost =
		isIncludedInPlan &&
		size(additionalCosts) > 0 &&
		additionalCosts[0].additionalAmount === 0;

	const displayType = type === 'editors' ? 'editors/admins' : type;

	return (
		<Stack className={classes.wrapper} spacing="xl">
			<Stack spacing="3xs">
				{isIncludedInPlan && (
					<Group position="apart" noWrap>
						<Text size="sm">{`${startCase(displayType)} included in your plan`}</Text>
						<Text size="sm" weight="semibold">
							{includedInPlanCount}
						</Text>
					</Group>
				)}
				{map(
					skipFirstCost ? additionalCosts.slice(1) : additionalCosts,
					(cost) => {
						let label = `Price per each additional ${singularize(displayType)}`;
						const value = `$${cost.tierUnitAmount / 100}/${interval}`;
						if (cost.to === Infinity) {
							label += ` (${cost.from}+)`;
						} else {
							label += ` (${cost.from}-${cost.to})`;
						}

						return (
							<Group position="apart" noWrap>
								<Text size="sm">{label}</Text>
								<Text size="sm" weight="semibold">
									{value}
								</Text>
							</Group>
						);
					}
				)}
			</Stack>
			<Stack spacing="3xs">
				<Group position="apart" noWrap>
					<Text size="sm">{`${startCase(
						displayType
					)} you're using at the moment`}</Text>
					<Text size="sm" weight="semibold">
						{nominalCurrentCount}
					</Text>
				</Group>
				<Group position="apart" noWrap>
					<Text size="sm">{`${startCase(
						displayType
					)} you're adding to the plan`}</Text>
					<Text size="sm" weight="semibold">
						{nominalChange}
					</Text>
				</Group>
				<Group position="apart" noWrap>
					<Text size="sm">Price increase</Text>
					<Text data-testid="price-increase" size="sm" weight="semibold">
						{`$${priceIncrease}/${interval}`}
					</Text>
				</Group>
			</Stack>
		</Stack>
	);
}
