import {
	createStyles,
	Group,
	ScrollArea,
	Stack,
	UnstyledButton,
} from '@mantine/core';
import type { LiteUserOut } from '@repo/api-codegen';
import { IconWrapper } from '@repo/common/components/IconWrapper';
import { Text } from '@repo/foundations';
import { IconChevronRight } from '@tabler/icons-react';
import { useUnmount } from 'ahooks';
import { Link } from 'react-router-dom';
import { useAuthUser } from '../../api';
import { getDisplayName } from '../../utils/userUtils';
import { closeModal } from '../ModalManager';
import { toTitleCase } from '../SearchListItem/helpers';
import { UserAvatar } from '../UserAvatar';
import { TEAM_MEMBERS_LIST_MODAL_ID } from './constants';

interface ITeamMembersListModal {
	teamMembers: LiteUserOut[];
}

const useStyles = createStyles((theme) => ({
	userRow: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		gap: theme.spacing.md,
		':hover': {
			backgroundColor: theme.other.getColor('surface/primary/hover'),
		},
		cursor: 'pointer',
		borderBottom: `1px solid ${theme.colors.gray[3]}`,
		'&:last-of-type': {
			borderBottom: 'none',
		},
	},
}));

export function TeamMembersList({ teamMembers }: ITeamMembersListModal) {
	const { classes, theme } = useStyles();
	const { isAdminUser } = useAuthUser();

	const handleClick = () => {
		closeModal(TEAM_MEMBERS_LIST_MODAL_ID);
	};

	useUnmount(() => {
		closeModal(TEAM_MEMBERS_LIST_MODAL_ID);
	});

	return (
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore poorly typed component by Mantine
		<ScrollArea.Autosize mah={400} offsetScrollbars>
			<Stack spacing={0} pb={12}>
				{teamMembers.map((user) => (
					<UnstyledButton
						key={user.id}
						component={Link}
						to={`/user/${user.id}`}
						py={8}
						className={classes.userRow}
						onClick={handleClick}
					>
						<Group noWrap key={user.id} w={240}>
							<UserAvatar user={user} size="md" enableLink />
							<Stack spacing={0}>
								<Text size="sm" fw={500} lineClamp={1}>
									{getDisplayName(user)}
								</Text>
								<Text size="xs" color="text/secondary/default" lineClamp={1}>
									{user.email}
								</Text>
							</Stack>
						</Group>
						{isAdminUser && <Text size="sm">{toTitleCase(user.role)}</Text>}
						<IconWrapper>
							<IconChevronRight size={16} color={theme.colors.gray[4]} />
						</IconWrapper>
					</UnstyledButton>
				))}
			</Stack>
		</ScrollArea.Autosize>
	);
}
