import type { MantineThemeOverride } from '@mantine/core';

export const LoadingOverlay: MantineThemeOverride['components'] = {
	LoadingOverlay: {
		defaultProps: (theme) => ({
			loaderProps: {
				color: theme.other.getColor('icon/brand/default'),
			},
		}),
	},
};
