import type {
	IApiListResponse,
	ISecodaEntity,
	IUseListQueryHookArgs,
} from '../../types';
import { useBaseModelList } from '../base';
import {
	SECODA_ENTITIES_NAMESPACE,
	secodaEntitiesQueryKeyFactory,
} from './constants';

/**
 * A react-query query hook that can be used to fetch a list of Secoda entities.
 * By default, it will return the entities' data with the viewer's delta applied
 * based on the current user's role.
 *
 * @param params Params for the underlying useBaseModelList hook
 * - page => Page number for API pagination
 * - filters => Filters to apply to the API request (must match Django's model queries)
 * @returns Returns a react-query query hook that can be used to fetch a list of Secoda entities
 */
function useSecodaEntityList<
	TApiResponseData extends ISecodaEntity,
	TData = IApiListResponse<TApiResponseData>,
	TError = unknown,
>({
	queryKey,
	queryFn,
	namespace = SECODA_ENTITIES_NAMESPACE,
	page,
	filters,
	options = {},
}: IUseListQueryHookArgs<TApiResponseData, TData, TError>) {
	const entityQueryKey =
		queryKey ?? secodaEntitiesQueryKeyFactory.list(page, filters);

	return useBaseModelList<TApiResponseData, TData, TError>({
		queryKey: entityQueryKey,
		queryFn,
		namespace,
		page,
		filters,
		options,
	});
}

export default useSecodaEntityList;
