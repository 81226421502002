import type { TablerIconsProps } from '@tabler/icons-react';
import {
	IconActivity,
	IconBolt,
	IconChartBar,
	IconCloudNetwork,
	IconCode,
	IconDatabase,
	IconFile,
	IconHeadset,
	IconHistory,
	IconKey,
	IconLine,
	IconListDetails,
	IconLoader2,
	IconMail,
	IconMessages,
	IconPlugConnected,
	IconShieldLock,
	IconSparkles,
	IconUserEdit,
	IconUsers,
	IconUserScan,
	IconUsersGroup,
} from '@tabler/icons-react';

export const PLANS = ['starter', 'growth', 'business', 'enterprise'];
export const PLAN_FEATURE_CATEGORIES = [
	'workspace',
	'features',
	'security',
	'analytics',
	'support',
];
export type PLAN_FEATURE_ITEM = {
	label: string;
	icon: (props: TablerIconsProps) => JSX.Element;
};
export const PLAN_FEATURES: {
	[key: (typeof PLANS)[number]]: {
		[key: (typeof PLAN_FEATURE_CATEGORIES)[number]]: PLAN_FEATURE_ITEM[];
	};
} = {
	basic: {
		workspace: [
			{
				label: 'Custom editors',
				icon: IconUserEdit,
			},
			{
				label: 'Unlimited viewers',
				icon: IconUsers,
			},
			{
				label: 'Custom integrations',
				icon: IconPlugConnected,
			},
		],
		features: [
			{
				label: 'Catalog',
				icon: IconListDetails,
			},
			{
				label: 'Lineage',
				icon: IconLine,
			},
			{
				label: 'Monitoring',
				icon: IconActivity,
			},
			{
				label: 'Secoda AI',
				icon: IconSparkles,
			},
		],
		integrations: [
			{
				label: 'Data integrations',
				icon: IconDatabase,
			},
			{
				label: 'Communication integrations',
				icon: IconMail,
			},
		],
		security: [
			{
				label: 'Google & Microsoft SSO',
				icon: IconKey,
			},
		],
		analytics: [],
		support: [
			{
				label: 'Email support',
				icon: IconMail,
			},
		],
	},
	core: {
		workspace: [
			{
				label: 'Custom editors',
				icon: IconUserEdit,
			},
			{
				label: 'Unlimited viewers',
				icon: IconUsersGroup,
			},
			{
				label: 'Custom integrations',
				icon: IconPlugConnected,
			},
		],
		features: [
			{
				label: 'Catalog',
				icon: IconListDetails,
			},
			{
				label: 'Lineage',
				icon: IconLine,
			},
			{
				label: 'Monitoring',
				icon: IconActivity,
			},
			{
				label: 'Secoda AI',
				icon: IconSparkles,
			},
			{
				label: 'API access',
				icon: IconCode,
			},
			{
				label: 'Automations',
				icon: IconBolt,
			},
		],
		integrations: [
			{
				label: 'Data integrations',
				icon: IconDatabase,
			},
			{
				label: 'Communication integrations',
				icon: IconMail,
			},
			{
				label: 'Productivity integrations',
				icon: IconFile,
			},
		],
		security: [
			{
				label: 'Google & Microsoft SSO + SAML',
				icon: IconKey,
			},
			{
				label: 'SSH tunnels',
				icon: IconCloudNetwork,
			},
		],
		analytics: [
			{
				label: 'Workspace analytics',
				icon: IconChartBar,
			},
		],
		support: [
			{
				label: 'Live chat support',
				icon: IconMessages,
			},
		],
	},
	premium: {
		workspace: [
			{
				label: 'Custom editors',
				icon: IconUserEdit,
			},
			{
				label: 'Unlimited viewers',
				icon: IconUsersGroup,
			},
			{
				label: 'Custom integrations',
				icon: IconPlugConnected,
			},
		],
		features: [
			{
				label: 'Catalog',
				icon: IconListDetails,
			},
			{
				label: 'Lineage',
				icon: IconLine,
			},
			{
				label: 'Monitoring',
				icon: IconActivity,
			},
			{
				label: 'Secoda AI',
				icon: IconSparkles,
			},
			{
				label: 'API access',
				icon: IconCode,
			},
			{
				label: 'Automations',
				icon: IconBolt,
			},
			{
				label: 'Data Quality Score',
				icon: IconLoader2,
			},
		],
		integrations: [
			{
				label: 'Data integrations',
				icon: IconDatabase,
			},
			{
				label: 'Communication integrations',
				icon: IconMail,
			},
			{
				label: 'Productivity integrations',
				icon: IconFile,
			},
		],
		security: [
			{
				label: 'Google & Microsoft SSO + SAML',
				icon: IconKey,
			},
			{
				label: 'SSH tunnels',
				icon: IconCloudNetwork,
			},
			{
				label: 'Single tenant',
				icon: IconShieldLock,
			},
			{
				label: 'SCIM',
				icon: IconUserScan,
			},
		],
		analytics: [
			{
				label: 'Workspace analytics',
				icon: IconChartBar,
			},
		],
		support: [
			{
				label: 'Live chat support',
				icon: IconMessages,
			},
		],
	},
	enterprise: {
		workspace: [
			{
				label: 'Custom editors',
				icon: IconUserEdit,
			},
			{
				label: 'Unlimited viewers',
				icon: IconUsersGroup,
			},
			{
				label: 'Unlimited integrations',
				icon: IconPlugConnected,
			},
		],
		features: [
			{
				label: 'Catalog',
				icon: IconListDetails,
			},
			{
				label: 'Lineage',
				icon: IconLine,
			},
			{
				label: 'Monitoring',
				icon: IconActivity,
			},
			{
				label: 'Secoda AI',
				icon: IconSparkles,
			},
			{
				label: 'API access',
				icon: IconCode,
			},
			{
				label: 'Automations',
				icon: IconBolt,
			},
			{
				label: 'Data Quality Score',
				icon: IconLoader2,
			},
			{
				label: 'Role-based access control',
				icon: IconShieldLock,
			},
		],
		integrations: [
			{
				label: 'Data integrations',
				icon: IconDatabase,
			},
			{
				label: 'Communication integrations',
				icon: IconMail,
			},
			{
				label: 'Productivity integrations',
				icon: IconFile,
			},
			{
				label: 'Custom integrations',
				icon: IconPlugConnected,
			},
		],
		security: [
			{
				label: 'Google & Microsoft SSO + SAML',
				icon: IconKey,
			},
			{
				label: 'SSH tunnels',
				icon: IconCloudNetwork,
			},
			{
				label: 'Single tenant or self-hosted',
				icon: IconShieldLock,
			},
			{
				label: 'SCIM',
				icon: IconUserScan,
			},
		],
		analytics: [
			{
				label: 'Workspace analytics',
				icon: IconChartBar,
			},
			{
				label: 'Audit log',
				icon: IconHistory,
			},
		],
		support: [
			{
				label: 'Priority live chat support',
				icon: IconMessages,
			},
			{
				label: 'Account manager',
				icon: IconHeadset,
			},
		],
	},
};
