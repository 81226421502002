import { createStyles, Stack } from '@mantine/core';
import { useElementSize } from '@mantine/hooks';
import type { ILineage } from '../../../../api';
import ImpactAnalysisCard from './ImpactAnalysisCard';

interface IImpactedAnalysisContentProps {
	entity: ILineage;
}

const useStyles = createStyles((theme, { height }: { height: number }) => {
	let hiddenStyling = {};

	// Workaround to avoid taking vertical space when nothing is being shown
	if (height === 0) {
		hiddenStyling = {
			border: 'none',
			position: 'absolute',
			visibility: 'hidden',
		};
	}

	return {
		cardWrapper: {
			borderColor: theme.other.getColor('border/primary/default'),
			borderRadius: theme.radius.md,
			borderStyle: 'solid',
			borderWidth: 1,
			...hiddenStyling,
		},
	};
});

export function ImpactAnalysisCardWrapper({
	entity,
}: IImpactedAnalysisContentProps) {
	const { ref, height } = useElementSize();

	const { classes } = useStyles({ height });

	return (
		<Stack ref={ref} className={classes.cardWrapper}>
			<ImpactAnalysisCard key={entity.id} entity={entity} />
		</Stack>
	);
}
