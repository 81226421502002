import { ActionIcon, createStyles, Group, Menu, Tooltip } from '@mantine/core';
import { useHover } from '@mantine/hooks';
import type { ItemIconType } from '@repo/common/components/ItemIcon';
import SelectorItemCheckbox from '@repo/common/components/MultiSelector/SelectorItemCheckbox';
import SelectorItemContent from '@repo/common/components/MultiSelector/SelectorItemContent';
import RichTooltip from '@repo/common/components/RichTooltip/RichTooltip';
import type {
	IntegrationSelectablePropertyItem,
	SelectablePropertyItem,
} from '@repo/common/components/SingleSelector/types';
import { useNavigate } from '@repo/common/hooks/useNavigate';
import { pickColor, singularize } from '@repo/common/utils';
import { Icon, Text } from '@repo/foundations';
import { isNil } from 'lodash-es';
import { useMemo } from 'react';
import { useAuthUser, useIntegrationPrefetched } from '../../api';
import { sentenceCase } from '../../utils/stringUtils';

import { isResourceDecoratingTag } from './utils';
export interface IIntegrationTooltipSelectorItemProps {
	iconType: ItemIconType;
	item: IntegrationSelectablePropertyItem;
	isMenuItemBadge: boolean;
	isViewerUser: boolean;
	permittedId: string;
	onClick: (item: SelectablePropertyItem) => void;
	checked?: boolean;
	disabled?: boolean;
	disabledTooltip?: string;
}

const useStyles = createStyles((theme) => ({
	actionIcon: {
		backgroundColor: theme.other.getColor('fill/transparent/default'),
		width: 20,
		height: 20,
		padding: 0,
		'&:hover': {
			backgroundColor: theme.other.getColor('fill/transparent/hover'),
		},
		'&:active': {
			backgroundColor: theme.other.getColor('fill/transparent/active'),
		},
	},
}));

function IntegrationTooltipSelectorItem({
	iconType,
	item,
	isMenuItemBadge,
	isViewerUser,
	permittedId,
	onClick,
	checked = false,
	disabled,
	disabledTooltip,
}: IIntegrationTooltipSelectorItemProps) {
	const { hovered, ref } = useHover<HTMLButtonElement>();

	const { isAdminUser } = useAuthUser();

	const { data: integration } = useIntegrationPrefetched({
		id: item.entityIntegrationId!,
		options: { enabled: !isNil(item.entityIntegrationId) },
	});

	const decoratorTag = isResourceDecoratingTag(item.tagIntegration);
	const disableItem = item.maintainProp || decoratorTag;

	const { theme, classes } = useStyles();

	const navigate = useNavigate();

	let color: string;

	if (iconType === 'avatar') {
		color = pickColor(item.label);
	} else if (iconType === 'tag') {
		color = theme.other.getColor('icon/brand/default');
	} else {
		color =
			(item.color as string) || theme.other.getColor('icon/brand/default');
	}

	const RightSection = useMemo(
		() =>
			(!isViewerUser || permittedId === item.value) &&
			!isNil(item.navigateTo) &&
			hovered ? (
				<ActionIcon
					className={classes.actionIcon}
					size={20}
					onClick={(event) => {
						event.stopPropagation();
						navigate(item.navigateTo as string);
					}}
				>
					<Icon name="chevronRight" color="icon/primary/default" />
				</ActionIcon>
			) : null,
		[
			isViewerUser,
			item.value,
			item.navigateTo,
			hovered,
			classes.actionIcon,
			permittedId,
			navigate,
		]
	);

	const handleOnClick = () => {
		if (isViewerUser && !isNil(item.navigateTo) && permittedId !== item.value) {
			navigate(item.navigateTo);
		} else if (!disabled) {
			onClick(item);
		}
	};

	let name = sentenceCase(item.itemTypeName as string);
	name = singularize(name);

	return item.entityIntegrationId && disableItem ? (
		<RichTooltip
			title={`${name} comes from ${decoratorTag && item.tagIntegration ? item.tagIntegration.name : integration?.name}`}
			body={
				isAdminUser &&
				(!decoratorTag ? (
					<Text size="sm" color="text/secondary/default">
						To maintain in Secoda, go to integration settings.
					</Text>
				) : (
					<Text size="sm" color="text/secondary/default">
						To make changes, remove integration.
					</Text>
				))
			}
			linkLabel={
				isAdminUser && !decoratorTag
					? `${integration?.name} settings`
					: undefined
			}
			linkUrl={
				isAdminUser && !decoratorTag
					? `/integrations/${integration?.id}/preferences`
					: undefined
			}
			position="left"
		>
			<Menu.Item
				ref={ref}
				icon={
					<SelectorItemCheckbox
						checked={checked}
						disabled
						disabledTooltip={disabledTooltip}
						item={item}
						isViewerUser={isViewerUser}
						permittedId={permittedId}
					/>
				}
				rightSection={RightSection}
				onClick={handleOnClick}
				disabled
			>
				<Group spacing={4}>
					{item.showDescriptionInTooltip && item.description ? (
						<Tooltip label={item.description} withinPortal openDelay={500}>
							{
								<SelectorItemContent
									isMenuItemBadge={isMenuItemBadge}
									iconType={iconType}
									item={item}
									color={color}
									disabled
								/>
							}
						</Tooltip>
					) : (
						<SelectorItemContent
							isMenuItemBadge={isMenuItemBadge}
							iconType={iconType}
							item={item}
							color={color}
							disabled
						/>
					)}
				</Group>
			</Menu.Item>
		</RichTooltip>
	) : (
		<Menu.Item
			ref={ref}
			icon={
				<SelectorItemCheckbox
					checked={checked}
					disabled={!!disabled && disableItem}
					disabledTooltip={disabledTooltip}
					item={item}
					isViewerUser={isViewerUser}
					permittedId={permittedId}
				/>
			}
			rightSection={RightSection}
			onClick={handleOnClick}
		>
			<Group spacing={4}>
				{item.showDescriptionInTooltip && item.description ? (
					<Tooltip label={item.description} withinPortal openDelay={500}>
						{
							<SelectorItemContent
								isMenuItemBadge={isMenuItemBadge}
								iconType={iconType}
								item={item}
								color={color}
								disabled={disableItem}
							/>
						}
					</Tooltip>
				) : (
					<SelectorItemContent
						isMenuItemBadge={isMenuItemBadge}
						iconType={iconType}
						item={item}
						color={color}
						disabled={disableItem}
					/>
				)}
			</Group>
		</Menu.Item>
	);
}

export default IntegrationTooltipSelectorItem;
