import type { Navigator, Path, To } from 'react-router';
import { parsePath, resolvePath } from 'react-router';

// Utility functions from react-router
// https://github.com/remix-run/react-router/blob/main/packages/react-router/lib/router.ts

export const joinPaths = (paths: string[]): string =>
	paths.join('/').replace(/\/\/+/g, '/');

export function getToPathname(to: To): string | undefined {
	return to === '' || (to as Path).pathname === ''
		? '/'
		: typeof to === 'string'
			? parsePath(to).pathname
			: to.pathname;
}

export const getHref = (basename: string, navigator: Navigator, to: To) => {
	const { hash, pathname, search } = resolvePath(to);

	let joinedPathname = pathname;
	if (basename !== '/') {
		const toPathname = getToPathname(to);
		const endsWithSlash =
			toPathname !== null &&
			toPathname !== undefined &&
			toPathname.endsWith('/');
		joinedPathname =
			pathname === '/'
				? basename + (endsWithSlash ? '/' : '')
				: joinPaths([basename, pathname]);
	}

	return navigator.createHref({ pathname: joinedPathname, search, hash });
};
