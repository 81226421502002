import type { EntityOut, ExtensionEntityOut } from '@repo/api-codegen';
import { UserRole } from '../enums/UserRole';
import type { ISecodaEntity } from '../models/secodaEntity';
import type { IUser } from '../models/user';

export const getUserInitialsFromName = (name?: string) => {
	const names = name?.split(' ');
	let initials = names?.[0].substring(0, 1).toUpperCase();

	if (names && (names?.length ?? 0) > 1) {
		initials += names[names.length - 1].substring(0, 1).toUpperCase();
	}
	return initials;
};

export const isViewer = (user: IUser) => {
	if (!user) return false;
	// TODO: Refactor this when user.role is a UserRole
	// instead of a string.
	if (UserRole.VIEWER.toString() === user.role.toLowerCase()) return true;
	if (UserRole.GUEST.toString() === user.role.toLowerCase()) return true;
	return false;
};

const isSecodaEntity = (
	entity: ISecodaEntity | EntityOut | ExtensionEntityOut
): entity is ISecodaEntity => 'current_user_permissions' in entity;

export const isViewerOfEntity = (
	user: IUser,
	entity: ISecodaEntity | EntityOut | ExtensionEntityOut
) => {
	if (isSecodaEntity(entity) && entity.current_user_permissions) {
		return !entity.current_user_permissions.write;
	}

	return isViewer(user);
};
