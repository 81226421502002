// eslint-disable-next-line no-restricted-imports
import { Stack, Text } from '@mantine/core';
import { useApiGetPopularityUserQueries } from '@repo/api-codegen';
import RichTooltip from '@repo/common/components/RichTooltip/RichTooltip';
import type { SelectablePropertyItem } from '@repo/common/components/SingleSelector/types';
import { useLocation } from 'react-router';
import { theme } from '../../../CatalogView/TableView/theme';
import { StaticProperty } from '../../EntityPropertySidebar';

export interface IntegrationMetadataStackProps {
	updatedAtLabelSource: string | null;
	resourceTypeSource: string;
	withFrequentUsers: boolean;
	frequentUsersSource: SelectablePropertyItem[];
	withEntityRowCount: boolean;
	rowCountValue: string;
	hasRowCount: boolean;
	withEntityByteSize: boolean;
	byteSizeValue: string;
	hasByteSize: boolean;
	withEntityPopularity: boolean;
	popularityValue: string;
	hasPopularity: boolean;
	entityId: string;
	entityType: string;
}

function IntegrationMetadataStack({
	updatedAtLabelSource,
	resourceTypeSource,
	withFrequentUsers,
	frequentUsersSource,
	withEntityRowCount,
	rowCountValue,
	hasRowCount,
	withEntityByteSize,
	byteSizeValue,
	hasByteSize,
	withEntityPopularity,
	popularityValue,
	hasPopularity,
	entityId,
	entityType,
}: IntegrationMetadataStackProps) {
	const { data: popularityBreakdown } = useApiGetPopularityUserQueries(
		{
			pathParams: {
				entityId: entityId,
			},
		},
		{
			enabled: withEntityPopularity && hasPopularity,
		}
	);

	const location = useLocation();

	const index = location.pathname.indexOf(entityId);
	const resultUrl = location.pathname.substring(0, index + entityId.length);

	return (
		<Stack spacing="xs">
			{updatedAtLabelSource && (
				<StaticProperty
					type="text"
					label="Updated"
					value={updatedAtLabelSource}
				/>
			)}
			{resourceTypeSource && (
				<StaticProperty type="text" label="Type" value={resourceTypeSource} />
			)}
			{withFrequentUsers && frequentUsersSource.length > 0 && (
				<StaticProperty
					type="users"
					label="Frequent Users"
					value={frequentUsersSource as SelectablePropertyItem[]}
				/>
			)}
			{withEntityRowCount && hasRowCount && (
				<StaticProperty type="text" label="Row count" value={rowCountValue} />
			)}
			{withEntityByteSize && hasByteSize && (
				<StaticProperty type="text" label="Table size" value={byteSizeValue} />
			)}
			{withEntityPopularity && hasPopularity && (
				<StaticProperty
					type="text"
					label="Popularity"
					value={popularityValue}
					custom={
						entityType === 'table' && (
							<RichTooltip
								title={''}
								body={
									<Stack spacing={theme.other?.space[1]}>
										{'User summary by query count'}
										{popularityBreakdown?.map((item) => (
											<Text key={item.user_display_name}>
												{item.user_display_name}: {item.query_count}
											</Text>
										))}
									</Stack>
								}
								children={
									<Text ml={0} size="sm" truncate>
										{popularityValue}
									</Text>
								}
								linkLabel={'See all queries'}
								linkUrl={resultUrl + '/queries'}
								position="top"
							/>
						)
					}
				/>
			)}
		</Stack>
	);
}

export default IntegrationMetadataStack;
