import turndownService from './turndown';
import { arrayBufferToString } from './utils';

export function htmlToMarkdown(content: ArrayBuffer | string) {
	if (content instanceof ArrayBuffer) {
		content = arrayBufferToString(content);
	}

	return turndownService.turndown(content);
}
