import { Box, createStyles, Transition } from '@mantine/core';
import type { IComment } from '../../../api';
import { COMMENT_PLACEHOLDER_ID } from '../../../components/Editor/outline/src/marks/Comment';
import type { WithOnlyIdRequired } from '../../../lib/typescript';
import FloatingCommentThread, {
	FLOATING_COMMENT_WIDTH,
} from './FloatingCommentThread';

const useStyles = createStyles((theme) => ({
	sidebar: {
		width: `calc(${FLOATING_COMMENT_WIDTH}px + (2 * ${theme.spacing.md}))`,
	},
}));

export interface IFloatingCommentSidebarProps {
	visible: boolean;
	commentThreads: { [rootID: string]: IComment[] };
	handleCreateComment: (
		data: Partial<Omit<IComment, 'id'>>
	) => Promise<IComment>;
	handleUpdateComment: (data: WithOnlyIdRequired<IComment>) => Promise<void>;
	handleDeleteComment: (commentID: string) => Promise<void>;
}

export function FloatingCommentSidebar({
	visible,
	commentThreads,
	handleCreateComment,
	handleUpdateComment,
	handleDeleteComment,
}: IFloatingCommentSidebarProps) {
	const { classes } = useStyles();

	return (
		<Transition
			mounted={visible}
			transition="scale-x"
			duration={300}
			timingFunction="ease"
		>
			{(styles) => (
				<Box style={{ ...styles }} className={classes.sidebar}>
					{Object.entries(commentThreads).map(([rootID, commentsInThread]) => (
						<FloatingCommentThread
							key={rootID}
							rootCommentID={rootID}
							comments={commentsInThread}
							onCreateComment={handleCreateComment}
							onUpdateComment={handleUpdateComment}
							onDeleteComment={handleDeleteComment}
						/>
					))}
					<FloatingCommentThread
						key={COMMENT_PLACEHOLDER_ID}
						rootCommentID={COMMENT_PLACEHOLDER_ID}
						comments={[]}
						onCreateComment={handleCreateComment}
						onUpdateComment={handleUpdateComment}
						onDeleteComment={handleDeleteComment}
					/>
				</Box>
			)}
		</Transition>
	);
}
