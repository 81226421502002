import { Group } from '@mantine/core';
import { memo } from 'react';
import { useStyles } from './NavBarBreadcrumbWrapper.styles';

interface NavBarBreadcrumbWrapperProps {
	children: React.ReactNode;
}

// eslint-disable-next-line react/display-name
export const NavBarBreadcrumbWrapper = memo(
	({ children }: NavBarBreadcrumbWrapperProps) => {
		const { classes } = useStyles();

		return <Group className={classes.breadcrumbWrapper}>{children}</Group>;
	}
);
