import {
	Box,
	createStyles,
	Group,
	Stack,
	Table,
	useMantineTheme,
} from '@mantine/core';
import { useApiListTeams } from '@repo/api-codegen';
import { Text } from '@repo/foundations';
import { observer } from 'mobx-react-lite';
import { FullWidthLoadingSpinner } from '../../components/LoadingSpinner';
import { appShellStore } from '../../components/SecodaAppShell/store';
import { TeamRow } from './TeamRow';

const useStyles = createStyles((theme) => ({
	borderBottom: {
		borderBottom: `1px solid ${theme.colors.gray[2]}`,
	},
}));

function TeamsPage() {
	const theme = useMantineTheme();
	const { classes } = useStyles();

	const {
		navBarUI: {
			teamsPage: { includeArchive },
		},
	} = appShellStore;

	const { data: teams, isLoading } = useApiListTeams(
		{
			queryParams: {
				include_archived: includeArchive,
			},
		},
		{
			suspense: false,
		}
	);

	if (!teams || isLoading) {
		return <FullWidthLoadingSpinner />;
	}

	return (
		<Stack px="xl" py="md">
			<Group position="center">
				<Table maw={864} w="100%">
					<thead className={classes.borderBottom}>
						<tr>
							<th>
								<Box pl={0} py={10}>
									<Text align="left" size="xs" color="text/secondary/default">
										Team name
									</Text>
								</Box>
							</th>
							<th>
								<Box pl={0}>
									<Text align="left" size="xs" color="text/secondary/default">
										Member
									</Text>
								</Box>
							</th>
							<th>
								<Box pl={0}>
									<Text align="left" size="xs" color="text/secondary/default">
										Role
									</Text>
								</Box>
							</th>
							<th>
								<Box pl={0} />
							</th>
						</tr>
					</thead>
					<tbody>
						{teams.results.map((team) => (
							<TeamRow key={team.id} team={team} />
						))}
					</tbody>
				</Table>
			</Group>
		</Stack>
	);
}

export default observer(TeamsPage);
