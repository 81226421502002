import { useState } from 'react';
import { useParams } from 'react-router';

import { useAuthUser, useSecodaEntity, useUpdateSecodaEntity } from '../../api';
import { getEntityPageSidebarProps } from '../../components/EntityDrawer/utils';
import EntityLogo from '../../components/EntityLogo/EntityLogo';
import EntityPageLayout from '../../components/EntityPageLayout';

import { EntityType } from '@repo/common/enums/entityType';
import { trackEvent } from '../../utils/analytics';
import { useParamsIdSuffixUuid } from '../../utils/hook/utils';
import type { DjangoValueType } from '../TemplatePage/types';
import DashboardEntityTabs from './DashboardEntityTabs';
import { DashboardEntityTabsStore } from './DashboardEntityTabs/DashboardEntityTabsStore';

export interface IDashboardPageProps {
	id?: string;
}

function DashboardPage({ id: propsId }: IDashboardPageProps) {
	const { tab } = useParams();
	const paramsId = useParamsIdSuffixUuid();
	const [dashboardEntityTabsStore] = useState(new DashboardEntityTabsStore());
	const { user, workspace } = useAuthUser();

	const id = propsId || paramsId;

	const { data: dashboard } = useSecodaEntity({
		id,
		options: {
			useErrorBoundary: true,
			onSettled: () => {
				trackEvent('dashboard_entity/open', {}, user, workspace);
			},
		},
	});

	const { mutateAsync } = useUpdateSecodaEntity({});

	const updateDashboard = async (
		key: string,
		value: DjangoValueType,
		saveRemotely = true
	) => {
		if (saveRemotely) {
			mutateAsync({
				data: {
					id: dashboard!.id,
					[key]: value,
				},
			});
			trackEvent('dashboard/properties/update', {}, user, workspace!);
		}
	};

	if (!dashboard) {
		return null;
	}

	return (
		<EntityPageLayout
			key={dashboard.id}
			name="Dashboard"
			isReadOnlyTitle
			entity={dashboard}
			icon={<EntityLogo entity={dashboard} size={32} />}
			updateEntity={updateDashboard}
			withDescription
			{...getEntityPageSidebarProps(EntityType.dashboard)}
		>
			<DashboardEntityTabs
				key={dashboard.id}
				dashboardEntityTabsStore={dashboardEntityTabsStore}
				dashboard={dashboard}
				tab={tab}
			/>
		</EntityPageLayout>
	);
}

export default DashboardPage;
