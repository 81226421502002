export enum SentimentType {
	Positive = 'positive',
	Negative = 'negative',
}

export const CONFIG: {
	[key: string]: { question: string; answerOptions: string[] };
} = {
	[SentimentType.Negative]: {
		question: "What don't you like about this answer?",
		answerOptions: [
			'Innacurate',
			'Out of date',
			'Too long',
			'Too short',
			'Harmful or offensive',
			'Not helpful',
		],
	},
	[SentimentType.Positive]: {
		question: 'What do you like about this answer?',
		answerOptions: [
			'Accurate',
			'Adds relevant and helpful details',
			'Clear and easy to read',
		],
	},
};

export interface AIFeedbackValues {
	sentiment: SentimentType;
	answerOptions: string[];
	details: string;
}
