import type { BoxProps } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import type { ListBoxProps } from '@repo/foundations';
import { ListBox } from '@repo/foundations';
import type { ReactElement } from 'react';
import { useCallback } from 'react';
import type { SelectorDropdownProps } from './SelectorDropdown';
import { SelectorDropdown } from './SelectorDropdown';
import type { SelectorValue } from './types';

export type SelectorProps = Pick<
	SelectorDropdownProps,
	| 'getItems'
	| 'initialValue'
	| 'searchPlaceholder'
	| 'selectType'
	| 'renderItem'
	| 'withDivider'
	| 'disableSearch'
> &
	Pick<ListBoxProps, 'placement'> &
	Omit<BoxProps, 'children'> & {
		initialOpen?: boolean;
		onChange: (value: SelectorValue[]) => void;
		children: ReactElement;
		disabled?: boolean;
	};

export function Selector({
	getItems,
	onChange,
	initialValue = [],
	searchPlaceholder,
	selectType,
	renderItem,
	children,
	initialOpen = false,
	withDivider,
	disabled = false,
	disableSearch,
	placement,
	...boxProps
}: SelectorProps) {
	const [opened, { close, open }] = useDisclosure(initialOpen);

	const handleOnOpenChange = useCallback(
		(newOpen: boolean) => {
			if (newOpen) {
				open();
			} else {
				close();
			}
		},
		[close, open]
	);

	const handleOnChange = useCallback(
		(value: SelectorValue[], shouldClose: boolean) => {
			onChange(value);
			if (shouldClose) {
				close();
			}
		},
		[onChange, close]
	);

	return (
		<ListBox
			opened={opened}
			onOpenChange={handleOnOpenChange}
			disabled={disabled}
			placement={placement}
		>
			<ListBox.Target>{children}</ListBox.Target>

			{opened && (
				<SelectorDropdown
					{...boxProps}
					getItems={getItems}
					onChange={handleOnChange}
					searchPlaceholder={searchPlaceholder}
					selectType={selectType}
					renderItem={renderItem}
					initialValue={initialValue}
					withDivider={withDivider}
					disableSearch={disableSearch}
				/>
			)}
		</ListBox>
	);
}
