import type { MantineThemeOverride } from '@mantine/core';
// @ts-ignore no types
import vsDark from 'prism-react-renderer/themes/vsDark';
// @ts-ignore no types
import vsLight from 'prism-react-renderer/themes/vsLight';

export const Prism: MantineThemeOverride['components'] = {
	Prism: {
		defaultProps: () => ({
			getPrismTheme: (_theme: unknown, colorScheme: string) =>
				colorScheme === 'light' ? vsLight : vsDark,
		}),
	},
};
