import type { MantineNumberSize } from '@mantine/core';
import { Box, useMantineTheme } from '@mantine/core';
import * as Plot from '@observablehq/plot';
import type {
	DataQualityScore,
	DataQualityTimeseries,
} from '@repo/api-codegen';
import dayjs from 'dayjs';
import { svg } from 'htl';
import { isNil, uniq } from 'lodash-es';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { pointer } from '../../../utils/charts/TooltipMark/pointer';
import {
	cleanUpTooltips,
	tooltip,
} from '../../../utils/charts/TooltipMark/tooltipMark';
import { DATA_QUALITY_MAX_SCORE } from '../constants';
import type { QualityFilterKey } from '../types';
import { TimeseriesTooltip } from './TimeseriesTooltip';

export interface DataQualityTimeseriesChartProps {
	timeseries: DataQualityTimeseries;
	width?: MantineNumberSize;
	height?: MantineNumberSize;
	liveValue?: DataQualityScore;
	dataQualityKey?: QualityFilterKey;
}

export const DataQualityTimeseriesChart = memo(
	({
		timeseries,
		width,
		height,
		liveValue,
		dataQualityKey = 'total',
	}: DataQualityTimeseriesChartProps) => {
		const theme = useMantineTheme();
		const containerRef = useRef<HTMLDivElement>(null);
		const [chartWidth, setChartWidth] = useState<number | undefined>();

		useEffect(() => {
			if (containerRef.current) {
				setChartWidth(containerRef.current.clientWidth);
			}
		}, [containerRef.current?.clientWidth]);

		const points = useMemo(() => {
			const p = timeseries.datapoints.map((datapoint) => ({
				date: dayjs(datapoint.timestamp).toDate(),
				...datapoint,
			}));

			if (liveValue) {
				p.push({
					date: dayjs().toDate(),
					timestamp: dayjs().toString(),
					...liveValue,
				});
			}
			return p;
		}, [timeseries.datapoints, liveValue]);

		const isCrossingYears =
			uniq(points.map(({ date }) => dayjs(date).year())).length > 1;
		const dateFormat = isCrossingYears ? 'MMM D, YYYY' : 'MMM D';

		const convertToPercentage = useCallback(
			(value: DataQualityScore) =>
				(value[dataQualityKey] / DATA_QUALITY_MAX_SCORE[dataQualityKey]) * 100,
			[dataQualityKey]
		);

		const renderTooltip = useCallback(
			({
				x,
				y,
				dataIndex,
			}: {
				x: number;
				y: number;
				dataIndex: number | null;
			}) => {
				if (isNil(dataIndex) || !points[dataIndex]) {
					return null;
				}

				return (
					<TimeseriesTooltip
						data={points[dataIndex]}
						dataQualityKey={dataQualityKey}
						x={x}
						y={y}
					/>
				);
			},
			[dataQualityKey, points]
		);

		useEffect(() => {
			const plot = Plot.plot({
				width: chartWidth,
				height: typeof height === 'number' ? height : undefined,
				marginTop: 10,
				marginBottom: 30,
				marginLeft: 50,
				marginRight: 20,
				x: {
					nice: true,
				},
				y: {
					domain: [0, 100],
				},
				color: {
					domain: [0, 100],
					type: 'linear',
				},
				marks: [
					Plot.gridY({
						ticks: [0, 25, 50, 75, 100],
						stroke: theme.other.getColor('border/secondary/default'),
						strokeOpacity: 1,
					}),
					Plot.axisX({
						anchor: 'bottom',
						tickFormat: (t: Date) => dayjs.utc(t).format(dateFormat),
						label: null,
						tickSize: 0,
						fontSize: 12,
						color: theme.other.getColor('text/secondary/default'),
						tickPadding: 16,
						textAnchor: 'middle',
					}),
					Plot.axisY({
						tickFormat: (t: number) => `${t}%`,
						label: null,
						ticks: [0, 25, 50, 75, 100],
						tickSize: 0,
						fontSize: 12,
						color: theme.other.getColor('text/secondary/default'),
						tickPadding: 16,
					}),
					Plot.line(points, {
						x: 'date',
						y: convertToPercentage,
						stroke: 'url(#gradient)',
					}),
					function GradientPlot(_index, { y }) {
						return svg`<defs>
						<linearGradient id="gradient" gradientUnits="userSpaceOnUse"
							x1=0 x2=0 y1=${y?.(0)} y2=${y?.(100)}>
								<stop offset=0% stop-color=${theme.other.getColor('fill/critical/default')} />
								<stop offset=50% stop-color=${theme.other.getColor('fill/warning/default')} />
								<stop offset=100% stop-color=${theme.other.getColor('fill/info/default')} />`;
					},
					tooltip(
						points,
						pointer({
							x: 'date',
							y: convertToPercentage,
							tooltipRenderer: renderTooltip,
						})
					),
				],
			});
			containerRef.current?.appendChild(plot);
			return () => {
				plot.remove();
				cleanUpTooltips();
			};
		}, [
			chartWidth,
			dateFormat,
			height,
			points,
			renderTooltip,
			theme,
			dataQualityKey,
			convertToPercentage,
		]);

		return <Box w={width} h={height} ref={containerRef} />;
	}
);
DataQualityTimeseriesChart.displayName = 'DataQualityTimeseriesChart';
