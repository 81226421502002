import { Checkbox, createStyles, getStylesRef, Group } from '@mantine/core';
import { Icon, ListBox, Text } from '@repo/foundations';
import type { ReactNode } from 'react';
import type { SelectorItemType } from './types';
import { SelectType } from './types';

const useStyles = createStyles(
	(theme, { selected }: { selected: boolean }) => ({
		menuItem: {
			[`&[data-hovered] .${getStylesRef('checkbox')}`]: {
				visibility: 'visible',
			},
		},
		checkbox: {
			ref: getStylesRef('checkbox'),

			visibility: selected ? 'visible' : 'hidden',
		},
	})
);

export interface SelectorItemProps {
	selectType: SelectType;
	item: SelectorItemType;
	renderItem?: (item: SelectorItemType) => ReactNode;
	onClick: (close: boolean) => void;
	selected?: boolean;
	getProps: (
		userProps: Omit<React.HTMLProps<HTMLElement>, 'ref'>
	) => Omit<React.HTMLProps<HTMLElement>, 'ref'>;
}

export function SelectorItem({
	item,
	getProps,
	onClick,
	selected = false,
	selectType,
	renderItem,
}: SelectorItemProps) {
	const { classes } = useStyles({ selected });

	return (
		<ListBox.Item
			{...getProps({
				onClick: () => onClick(true),
				className: classes.menuItem,
			})}
		>
			<Group
				noWrap
				data-testid={`multi-select-item-${item.label.replace(/\s/g, '-').toLowerCase()}`}
				spacing="2xs"
			>
				{selectType === SelectType.Multiple && (
					<Checkbox
						checked={selected}
						onClick={(event) => event.stopPropagation()}
						onChange={() => onClick(false)}
						className={classes.checkbox}
					/>
				)}
				{item.icon}
				{renderItem ? (
					renderItem(item)
				) : (
					<Text size="sm" style={{ flex: 1 }}>
						{item.label}
					</Text>
				)}
				{selected && selectType === SelectType.Single && (
					<Icon name="check" color="icon/primary/default" />
				)}
			</Group>
		</ListBox.Item>
	);
}
