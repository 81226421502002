import {
	Anchor,
	Box,
	createStyles,
	Divider,
	Flex,
	Group,
	Tabs,
} from '@mantine/core';
import { UserRole } from '@repo/common/enums/UserRole';
import { useNavigate } from '@repo/common/hooks/useNavigate';
import { Icon, Text, Title } from '@repo/foundations';
import { space } from '@repo/theme/primitives';
import { capitalize } from 'lodash-es';
import { useMemo, type CSSProperties } from 'react';
import { useParams } from 'react-router-dom';
import { useAuthUser } from '../../api';
import { usePlan } from '../../hooks/usePlans';
import type { Plan } from '../../interfaces/Plan';
import { CreateTab } from '../../pages/IntegrationsBrowsePage/CreateTab';
import type { FeatureFlags } from '../../utils/featureFlags';
import { useFeatureFlags } from '../../utils/featureFlags';
import WorkspaceActivityLog from '../ActivityLog/WorkspaceActivityLog';
import { AIToolsSettings } from './AIToolsSettings';
import { APISettings } from './APISettings/APISettings';
import AppearanceSettings from './AppearanceSettings';
import { BillingSettings } from './BillingSettings';
import CatalogDefaultsSettings from './CatalogDefaultsSettings';
import { ChromeExtensionSettings } from './ChromeExtensionSettings/ChromeExtensionSettings';
import { DataSettings } from './DataSettings';
import { ImportSettings } from './ImportSettings';
import { MemberSettings } from './MemberSettings/MemberSettings';
import { NotificationSettings } from './NotificationSettings';
import { ProfileSettings } from './ProfileSettings';
import { PublishSettings } from './PublishSettings';
import { QualityScoreSettings } from './QualityScoreSettings';
import { SecuritySettings } from './SecuritySettings';
import { TagSettings } from './TagSettings/TagSettings';
import { UpgradeButton } from './UpgradeButton';
import { WorkspaceSettings } from './WorkspaceSettings';

interface TabType {
	name: string;
	id: string;
	title?: string;
	sidebarTitle?: string;
	description?: string;
	upgradeRequired?: (plan: Plan) => boolean;
	documentationLink?: string;
	allowedRoles?: UserRole[];
	featureFlag?: keyof FeatureFlags;
	component: React.JSXElementConstructor<unknown>;
}

const PROFILE_TABS: Array<TabType> = [
	{
		name: 'Profile',
		id: 'profile',
		description: 'Manage your Secoda profile.',
		component: ProfileSettings,
	},
	{
		name: 'Notifications',
		id: 'notifications',
		description: 'Select which notifications you would like to receive.',
		component: NotificationSettings,
	},
	{
		name: 'Chrome extension',
		id: 'chrome-extension',
		description: 'Manage your Secoda Chrome extension.',
		component: ChromeExtensionSettings,
	},
];

const WORKSPACE_TABS: Array<TabType> = [
	{
		name: 'Workspace',
		id: 'workspace',
		sidebarTitle: 'General',
		title: 'Workspace Settings',
		description: "Manage your team's workspace settings.",
		allowedRoles: [UserRole.ADMIN],
		component: WorkspaceSettings,
	},
	{
		name: 'Properties',
		id: 'catalog',
		description: 'Set catalog properties.',
		allowedRoles: [UserRole.ADMIN],
		component: CatalogDefaultsSettings,
	},
	{
		name: 'Security',
		id: 'security',
		title: 'Security',
		sidebarTitle: 'Security',
		description:
			'Security settings for your workspace. SAML is only available on the Enterprise plan.',
		documentationLink: 'https://docs.secoda.co/saml',
		allowedRoles: [UserRole.ADMIN],
		component: SecuritySettings,
	},
	{
		name: 'Appearance',
		id: 'appearance',
		description: 'Customize the appearance of your workspace.',
		allowedRoles: [UserRole.ADMIN],
		component: AppearanceSettings,
	},
	{
		name: 'Members',
		id: 'members',
		description: 'Manage your workspace members and groups.',
		allowedRoles: [UserRole.ADMIN, UserRole.EDITOR],
		component: MemberSettings,
	},
	{
		name: 'Data',
		id: 'data',
		description: 'Manage your workspace data.',
		allowedRoles: [UserRole.ADMIN],
		component: DataSettings,
	},
	{
		name: 'AI',
		id: 'ai',
		title: 'AI',
		description: 'Manage Secoda AI preferences for your workspace.',
		documentationLink: 'https://docs.secoda.co/features/ai-assistant',
		allowedRoles: [UserRole.ADMIN],
		component: AIToolsSettings,
	},
	{
		name: 'Quality score',
		id: 'quality',
		title: 'Quality score',
		description: 'Understand and improve your data quality.',
		documentationLink: 'https://docs.secoda.co/features/data-quality-score',
		allowedRoles: [UserRole.ADMIN],
		component: QualityScoreSettings,
	},
	{
		name: 'Import',
		id: 'import',
		title: 'Import and export',
		sidebarTitle: 'Import and export',
		description: 'Bulk import and export your workspace data.',
		documentationLink:
			'https://docs.secoda.co/features/data-management/import-and-export-data',
		allowedRoles: [UserRole.ADMIN],
		component: ImportSettings,
	},
	{
		name: 'Publishing',
		id: 'publish',
		description: 'Publish resources from your workspace',
		allowedRoles: [UserRole.ADMIN],
		component: PublishSettings,
	},
	{
		name: 'Billing',
		id: 'billing',
		description:
			'Workspace billing history and additional details will show up here.',
		allowedRoles: [UserRole.ADMIN],
		component: BillingSettings,
	},
	{
		name: 'API',
		id: 'api',
		title: 'API access',
		description:
			'Use the API to interact with Secoda services programmatically.',
		upgradeRequired: (plan: Plan) => !plan?.api_support,
		documentationLink: 'https://docs.secoda.co/secoda-api',
		allowedRoles: [UserRole.ADMIN, UserRole.EDITOR],
		component: APISettings,
	},
	{
		name: 'Tags',
		id: 'tags',
		description: 'Use tags to help organize your resources.',
		allowedRoles: [UserRole.ADMIN, UserRole.EDITOR],
		component: TagSettings,
	},
	{
		name: 'Audit log',
		id: 'audit-log',
		description: 'View the recent activity in your workspace.',
		allowedRoles: [UserRole.ADMIN],
		upgradeRequired: (plan: Plan) => !plan?.audit_log_support,
		component: WorkspaceActivityLog,
	},
	{
		name: 'Marketplace',
		id: 'marketplace-integrations',
		description:
			"Build your own integration with Secoda's SDK and list it on the marktplace",
		documentationLink:
			'https://docs.secoda.co/integrations/custom-integrations-and-marketplace/secoda-sdk-custom-integration',
		allowedRoles: [UserRole.ADMIN],
		component: CreateTab,
	},
];

const useStyles = createStyles((theme) => ({
	settingsPanel: {
		maxWidth: theme.other.space[160],
		margin: '0 auto',
	},
}));

function TabGroupTitle(props: {
	title: string;
	icon: JSX.Element;
	style?: CSSProperties;
}) {
	const { title, style, icon } = props;
	return (
		<Flex style={style ?? {}} mb="10px" align="center" gap="5px">
			{icon}
			<Text size="sm" weight="semibold">
				{title}
			</Text>
		</Flex>
	);
}

export default function SettingsPage() {
	const navigate = useNavigate();
	const { classes } = useStyles();
	const { tab: browserPath } = useParams();
	const { isEditorOrAdminUser } = useAuthUser();
	const { plan } = usePlan();
	const { user } = useAuthUser();
	const featureFlags = useFeatureFlags();

	const filteredTabs = useMemo(() => {
		function filterTabs(tabs: TabType[]) {
			return tabs.filter((tab) => {
				if (tab.allowedRoles) {
					return user ? tab.allowedRoles.includes(user.role) : false;
				}
				if (tab.featureFlag) {
					return featureFlags[tab.featureFlag];
				}
				return true;
			});
		}
		return {
			profileTabs: filterTabs(PROFILE_TABS),
			workspaceTabs: filterTabs(WORKSPACE_TABS),
		};
	}, [featureFlags, user]);

	const tabs = [...filteredTabs.profileTabs, ...filteredTabs.workspaceTabs];
	const currentTab = browserPath
		? tabs.find((tab) => tab.id === browserPath)
		: filteredTabs.profileTabs[0];

	if (!currentTab) {
		navigate('/403');
		return null;
	}

	return (
		<Box p="lg">
			<Tabs
				value={currentTab.id}
				onTabChange={(tabId) => navigate(`/settings/${tabId}`)}
				orientation="vertical"
				variant="pills"
			>
				<Tabs.List mr={space[4]} px={space[2]} w={space[60]}>
					<TabGroupTitle icon={<Icon name="userCircle" />} title="Account" />
					{filteredTabs.profileTabs.map((tab) => (
						<Tabs.Tab key={tab.id} value={tab.id}>
							<Text size="sm" weight="semibold">
								{tab.sidebarTitle ?? tab.name}
							</Text>
						</Tabs.Tab>
					))}
					{isEditorOrAdminUser && (
						<>
							<TabGroupTitle
								icon={<Icon name="building" />}
								style={{ marginTop: '30px' }}
								title="Workspace"
							/>
							{filteredTabs.workspaceTabs.map((tab) => (
								<Tabs.Tab key={tab.id} id={tab.id} value={tab.id}>
									<Text size="sm" weight="semibold">
										{tab.sidebarTitle ?? tab.name}
									</Text>
								</Tabs.Tab>
							))}
						</>
					)}
				</Tabs.List>

				{tabs.map((tab) => {
					const title = tab.title ?? capitalize(tab.name);
					const TabElement = tab.component;

					return (
						<Tabs.Panel
							key={tab.id}
							id={`panel-${tab.name}`}
							value={tab.id}
							className={classes.settingsPanel}
						>
							<Box>
								<Title size="xl" mb="15px">
									{title}
								</Title>
								<Text mb="35px" size="sm">
									{tab.description}
									{tab.documentationLink && (
										<Anchor
											target="_blank"
											href={tab.documentationLink}
											align="center"
										>
											{' '}
											<Group spacing="xxs" noWrap>
												Go to documentation
												<Icon
													name="arrowRight"
													style={{
														display: 'inline-block',
														verticalAlign: 'text-top',
													}}
												/>
											</Group>
										</Anchor>
									)}
								</Text>
								{plan && tab.upgradeRequired?.(plan) && (
									<UpgradeButton feature={title} />
								)}
								<Divider size="sm" my="2rem" />
							</Box>
							<TabElement />
						</Tabs.Panel>
					);
				})}
			</Tabs>
		</Box>
	);
}
