import type { IconNames } from '@repo/foundations';
import type { CustomPropertyType } from './CustomProperty.types';

type CustomPropertyOptionProps = {
	type: CustomPropertyType;
	iconName: IconNames;
};

export const CUSTOM_PROPERTY_OPTIONS: CustomPropertyOptionProps[] = [
	{ type: 'text', iconName: 'letterCase' },
	{ type: 'checkbox', iconName: 'squareCheck' },
	{ type: 'user', iconName: 'user' },
	{ type: 'tag', iconName: 'tag' },
	{ type: 'resource', iconName: 'listDetails' },
	{ type: 'date', iconName: 'calendar' },
	{ type: 'number', iconName: 'numbers' },
	{ type: 'select', iconName: 'selector' },
	{ type: 'multiselect', iconName: 'listCheck' },
];
