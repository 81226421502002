import { createStyles } from '@mantine/core';

interface IIFrameProps {
	url: string;
	title: string;
}

const useStyles = createStyles(() => ({
	iframe: {
		width: '100%',
		border: 'none !important',
		height: '100% !important',
		minHeight: '500px',
	},
}));

export function IFrame({ url, title }: IIFrameProps) {
	const { classes } = useStyles();

	return <iframe title={title} className={classes.iframe} src={url} />;
}
