import type { IWorkspace } from '../../types';
import { useUpdateBaseModel } from '../base';
import { thisUserQueryKey } from '../user/useThisUser';
import { workspaceQueryKeyFactory } from './constants';

export function useUpdateWorkspace(id: string) {
	return useUpdateBaseModel<IWorkspace>({
		queryKeyFactory: workspaceQueryKeyFactory,
		namespace: workspaceQueryKeyFactory.namespace,

		invalidationKeys: [
			// Invalidate the workspace by id, this applies to useListWorkspace.
			workspaceQueryKeyFactory.byId(id),
			// Invalidate 'this' workspace, this applies to useWorkspace.
			thisUserQueryKey,
		],
	});
}
