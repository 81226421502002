import type { TeamOut } from '@repo/api-codegen';

export const getTeamIcon = (
	teamName: string,
	teamsList: TeamOut[] | undefined
) => {
	if (!teamsList) {
		return 'users';
	}
	const findTeam = teamsList.find((obj) => obj.name === teamName);
	return findTeam?.icon || 'users';
};
