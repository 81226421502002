import { ActionIcon, Group, Popover } from '@mantine/core';
import { IconCircle } from '@tabler/icons-react';
import { useState } from 'react';
import { ColorButton } from './ColorButton';
import { DEFAULT_TAG_COLORS } from './constants';

interface IColorSelectorProps {
	initialColor?: string;
	onChange: (color: string) => void;
}

export function ColorSelector({
	initialColor = DEFAULT_TAG_COLORS[0],
	onChange,
}: IColorSelectorProps) {
	const [selectedColor, setSelectedColor] = useState(initialColor);

	return (
		<Popover withinPortal>
			<Popover.Target>
				<ActionIcon variant="outline" size="lg">
					<IconCircle color={selectedColor} fill={selectedColor} size={12} />
				</ActionIcon>
			</Popover.Target>
			<Popover.Dropdown>
				<Group>
					{DEFAULT_TAG_COLORS.map((c) => (
						<ColorButton
							key={c}
							color={c}
							onClick={() => {
								setSelectedColor(c);
								onChange(c);
							}}
							active={c === selectedColor}
						/>
					))}
				</Group>
			</Popover.Dropdown>
		</Popover>
	);
}
