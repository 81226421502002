import { useLocalStorage } from '@mantine/hooks';
import type { FeatureAccess } from '@repo/api-codegen';
import { useGetWorkspaceFeatureAccess } from '@repo/api-codegen';
import { useWorkspace } from '../workspace';

const FEATURE_ACCESS_KEY = 'feature_access';

export function useFeatureAccess() {
	const { workspace } = useWorkspace();
	const [local, setLocal] = useLocalStorage<FeatureAccess>({
		key: FEATURE_ACCESS_KEY,
		defaultValue: {
			monitoring_access: false,
			automations_access: false,
			ai_access: false,
			api_access: false,
			analytics_access: false,
			saml_access: false,
			tunnel_access: false,
			group_access: false,
			dqs_access: false,
		},
	});

	const { data, isLoading } = useGetWorkspaceFeatureAccess(
		{},
		{
			suspense: false,
			enabled: !!workspace.id,
			placeholderData: () => local,
			onSuccess: (_data) => {
				setLocal(_data);
			},
		}
	);

	return {
		monitoringAccess: data?.monitoring_access,
		automationsAccess: data?.automations_access,
		aiAccess: data?.ai_access,
		apiAccess: data?.api_access,
		analyticsAccess: data?.analytics_access,
		samlAccess: data?.saml_access,
		tunnelAccess: data?.tunnel_access,
		groupAccess: data?.group_access,
		dqsAccess: data?.dqs_access,
		isLoading,
	};
}
