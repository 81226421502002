import { Box, createStyles } from '@mantine/core';
import type React from 'react';
import { useCallback, useEffect, useState } from 'react';

export interface HorizontalResizeHandleProps {
	side?: 'right' | 'left';
	handleSize?: number;
	minWidth: number;
	maxWidth: number;
	backgroundColor?: string;
	onResize: (updatedWidth: number) => void;
	onActiveChange?: (active: boolean) => void;
	onHoverChange?: (hover: boolean) => void;
}

const useStyles = createStyles(
	(
		theme,
		{
			side,
			width,
			backgroundColor,
		}: { side: 'left' | 'right'; width: number; backgroundColor: string }
	) => ({
		wrapper: {
			position: 'absolute',
			zIndex: 20,
			cursor: 'col-resize',
			backgroundColor,
			width,
			height: '100vh',
			marginTop: `-${theme.spacing.sm}`,
			marginRight: `-${theme.spacing.xs}`,
			left: side === 'left' ? 0 : 'auto',
			right: side === 'right' ? 0 : 'auto',
		},
	})
);

function HorizontalResizeHandle({
	side = 'right',
	handleSize = 8,
	minWidth,
	maxWidth,
	backgroundColor = 'transparent',
	onResize,
	onActiveChange,
	onHoverChange,
}: HorizontalResizeHandleProps) {
	const { classes } = useStyles({
		side,
		width: handleSize,
		backgroundColor,
	});

	const [clickXPosition, setClickXPosition] = useState(Infinity);

	useEffect(() => {
		if (clickXPosition === Infinity) {
			return;
		}

		const handleMouseMove = (e: MouseEvent) => {
			e.preventDefault();

			let offset = e.clientX;
			// It's more difficult to calculate the width when
			// the handle is at the right side of the window.
			if (side === 'left') {
				offset = window.innerWidth - e.clientX;
			}

			if (offset < 0 || offset < minWidth || offset > maxWidth) {
				return;
			}

			onResize(offset);
		};

		const handleMouseUp = (e: MouseEvent) => {
			e.preventDefault();
			setClickXPosition(Infinity);
			document.body.style.cursor = 'auto';
			if (onActiveChange) {
				onActiveChange(false);
			}
		};

		document.addEventListener('mousemove', handleMouseMove);
		document.addEventListener('mouseup', handleMouseUp);

		return () => {
			document.removeEventListener('mousemove', handleMouseMove);
			document.removeEventListener('mouseup', handleMouseUp);
		};
	}, [clickXPosition]);

	const handleMouseDown = useCallback((e: React.MouseEvent) => {
		e.preventDefault();
		setClickXPosition(e.clientX);
		document.body.style.cursor = 'col-resize';
		if (onActiveChange) {
			onActiveChange(true);
		}
	}, []);

	const handleMouseEnter = useCallback(() => {
		if (onHoverChange) {
			onHoverChange(true);
		}
	}, []);

	const handleMouseLeave = useCallback((e: React.MouseEvent) => {
		// mouse is held down but goes past collapse point - handle should remain activated
		if (e.buttons === 1 && onActiveChange) {
			onActiveChange(true);
		}
		if (onHoverChange) {
			onHoverChange(false);
		}
	}, []);

	return (
		<Box
			className={classes.wrapper}
			onMouseDown={handleMouseDown}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		/>
	);
}

export default HorizontalResizeHandle;
