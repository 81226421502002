import { createStyles, Stack } from '@mantine/core';
import { useCallback, useEffect } from 'react';
import {
	queryClient,
	questionsQueryKeyFactory,
	useAuthUser,
	useQuestion,
	useUpdateQuestion,
} from '../../api';

import { Helmet } from 'react-helmet';
import { AIAssistantSidebar } from '../../components/AIAssistant/AIAssistantSidebar/AIAssistantSidebar.tsx';
import { DOCUMENTATION_WIDTH } from '../../components/Documentation/constants.ts';
import EntityPageActions from '../../components/EntityPageLayout/EntityPageActions.tsx';
import EntityPageNavBar from '../../components/EntityPageLayout/EntityPageNavBar.tsx';
import EntityPageSidebar from '../../components/EntityPageLayout/EntityPageSidebar.tsx';
import EntityPageTitle from '../../components/EntityPageLayout/EntityPageTitle/EntityPageTitle.tsx';
import EntityPageToggles from '../../components/EntityPageLayout/EntityPageToggles.tsx';
import {
	PageLayoutContent,
	PageLayoutContentWrapper,
	PageLayoutOuterWrapper,
	PageLayoutWrapper,
} from '../../components/PageLayout/index.tsx';
import { RightSidebarWrapper } from '../../components/RightSidebar/RightSidebarWrapper.tsx';
import {
	RightSidebarMode,
	rightSidebarStore,
} from '../../components/RightSidebar/store.ts';
import { useFullWidthToggle } from '../../hooks/useFullWidthToggle.ts';
import { trackEvent } from '../../utils/analytics';
import { isViewerOfEntity } from '../../utils/authorization/roles';
import { useParamsIdSuffixUuid } from '../../utils/hook/utils.ts';
import type { DjangoValueType } from '../TemplatePage/types';
import { Question } from './components/Question';

interface IStyleParams {
	shouldSetFullWidth: boolean;
}

const useStyles = createStyles<string, IStyleParams>(
	(theme, { shouldSetFullWidth }) => ({
		contentWrapper: {
			display: 'flex',
			alignItems: 'center',
			width: '100%',
		},
		content: {
			maxWidth: shouldSetFullWidth ? '100%' : `${DOCUMENTATION_WIDTH}px`,
			padding: 0,
			paddingTop: theme.spacing['3xl'],
		},
	})
);

function QuestionPage() {
	const id = useParamsIdSuffixUuid();
	const { user, workspace } = useAuthUser();
	const { shouldSetFullWidth } = useFullWidthToggle(id);
	const { classes } = useStyles({ shouldSetFullWidth });

	useEffect(() => {
		if (id) {
			trackEvent(
				'question/open',
				{
					id,
				},
				user,
				workspace
			);
		}
	});

	const { mutateAsync: updateQuestion } = useUpdateQuestion({
		options: {
			onSuccess: () => {
				// Refresh the question list page after updating the question.
				queryClient.invalidateQueries(questionsQueryKeyFactory.list());
			},
		},
	});

	const { data: question } = useQuestion({
		id,
		options: {
			useErrorBoundary: true,
			suspense: true,
		},
	});

	const handleUpdate = useCallback(
		async (
			key: string,
			value: DjangoValueType,
			saveRemotely: boolean = true
		) => {
			if (!question) return;
			if (!saveRemotely) {
				queryClient.setQueryData(questionsQueryKeyFactory.byId(id), {
					...question,
					[key]: value,
				});
				return;
			}
			updateQuestion({ data: { id: question.id, [key]: value } });
			trackEvent(
				'question/update',
				{
					id: question.id,
				},
				user,
				workspace
			);
		},
		[id, question, updateQuestion, user, workspace]
	);

	if (!question) {
		return null;
	}

	const isViewerUser = isViewerOfEntity(user, question);

	let source;
	// TODO: This should be moved to the integration property.
	if (question.native_type === 'Jira_question') {
		source = 'Jira';
	} else if (question.native_type === 'slack_thread') {
		source = 'Slack';
	} else {
		source = 'Secoda';
	}

	if (!question) {
		return null;
	}

	return (
		<PageLayoutOuterWrapper>
			<Helmet>
				<title>{question.title_cased ?? question.title ?? name}</title>
			</Helmet>
			<PageLayoutWrapper name="entity-page" key={question.id}>
				<PageLayoutContentWrapper name="entity-page">
					<EntityPageNavBar
						entity={question}
						actions={<EntityPageActions entity={question} />}
						toggles={<EntityPageToggles entity={question} />}
					/>
					<PageLayoutContent className={classes.content}>
						<Stack spacing="xs">
							<EntityPageTitle
								placeholder={`Untitled question`}
								entity={question}
								isReadOnly={isViewerUser}
								onChange={(value) => handleUpdate('title', value)}
								wrapTitle
							/>
						</Stack>
						<Question question={question} />
					</PageLayoutContent>
				</PageLayoutContentWrapper>

				<RightSidebarWrapper>
					{rightSidebarStore.mode === RightSidebarMode.INFO && (
						<EntityPageSidebar
							entity={question}
							updateEntity={handleUpdate}
							withOwnerSelector={false}
							withCollectionSelector
							withTagSelector
							withCustomPropertyEditors
							withRelatedResourceSelector
							withTeamSelector
							withVerifiedSelector
							// Question specific properties.
							withAssignedToSelector
							withStatusSelector
							withPrioritySelector
							withSource={source}
						/>
					)}
					{rightSidebarStore.mode === RightSidebarMode.AI && (
						<AIAssistantSidebar />
					)}
				</RightSidebarWrapper>
			</PageLayoutWrapper>
		</PageLayoutOuterWrapper>
	);
}

export default QuestionPage;
