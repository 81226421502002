// Our frontend runs statically, so we can't use NODE_ENV to check which environment we are running the website.
// Vite statically replaces env variables at build time whenever import.meta.env.<ENV_VAR> is used. That's the preferred way to check for environment variables in Vite.
// Having NODE_ENV=production will cause Vite to set import.meta.env.PROD to true.
// Having NODE_ENV=development will cause Vite to set import.meta.env.DEV to true.
// more: https://vitejs.dev/guide/env-and-mode#html-env-replacement

/**
 * Checks if the current environment is local or development.secoda.co.
 * NOTE: development.secoda.co will also return IS_PRODUCTION as `true`!
 * @returns {boolean} - Returns `true` if the environment is local or development.secoda.co, `false` otherwise.
 */
export const IS_LOCAL_OR_DEVELOPMENT =
	// We can't use import.meta.env.DEV here because the `us-development` deployment is also considered production (NODE_ENV=production)
	import.meta.env.MODE === 'local-development' ||
	import.meta.env.MODE === 'us-development';

export const IS_PRODUCTION = import.meta.env.PROD ?? true;
