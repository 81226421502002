// NOTE we should avoid `@mantine/core/Button` in favour of `@repo/foundations/Button`
// eslint-disable-next-line no-restricted-imports
import { Button, Card, createStyles, Group, Stack } from '@mantine/core';
import { Icon, Text } from '@repo/foundations';
import type { ReactNode } from 'react';
import { Link } from 'react-router-dom';

interface IStatusCardProps {
	label: string;
	icon?: ReactNode;
	content?: ReactNode;
	variant?: 'button' | 'text';
	url?: string;
}

const useStyles = createStyles((theme) => ({
	label: {
		color: theme.other.getColor('text/secondary/default'),
	},
	content: {
		color: theme.other.getColor('text/primary/default'),
		fontSize: theme.fontSizes.xl,
		fontWeight: theme.other.typography.weight.bold,
		'&:first-letter': {
			textTransform: 'capitalize',
		},
	},
	wrapper: {
		alignItems: 'flex-end',
	},
}));

function StatusCard({
	label,
	variant = 'text',
	url = '#',
	icon,
	content,
}: IStatusCardProps) {
	const { classes, theme } = useStyles();

	let item = (
		<>
			{icon}
			<Text className={classes.content}>{content}</Text>
		</>
	);

	if (variant === 'button') {
		item = (
			<Button
				ml={-theme.other.space[2]}
				variant="subtle"
				component={Link}
				classNames={{
					root: classes.wrapper,
					label: classes.content,
				}}
				leftIcon={icon}
				to={url}
				rightIcon={<Icon name="chevronRight" color="icon/secondary/default" />}
			>
				{content}
			</Button>
		);
	}

	return (
		<Card withBorder px="xl" py="md">
			<Stack spacing="xs">
				<Text size="xs" className={classes.label}>
					{label}
				</Text>
				<Group spacing="3xs" align="center">
					{item}
				</Group>
			</Stack>
		</Card>
	);
}

export default StatusCard;
