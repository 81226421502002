import { Divider, Group, SimpleGrid, Stack } from '@mantine/core';
import { Prism } from '@mantine/prism';
import dayjs from 'dayjs';

import { useGetCommitInfo, useGetLatestGitInfo } from '@repo/api-codegen';
import { Button, Text, Title } from '@repo/foundations';
import {
	useRemoveReservedJob,
	useRetryJob,
} from '../../../api/hooks/internalIntegrationStatus';
import type { IInternalDatabuilderJobStatus } from '../../../api/types/models/internalIntegrationStatus';

interface IJobMetadataDrawerProps {
	jobMetadata?: IInternalDatabuilderJobStatus;
}

export function JobMetadataDrawer({ jobMetadata }: IJobMetadataDrawerProps) {
	const { mutate: removeReservedJob } = useRemoveReservedJob();
	const { mutate: retryJob } = useRetryJob();

	const { data: latestGitInfo } = useGetLatestGitInfo({});
	const { data: commitInfo } = useGetCommitInfo({
		pathParams: {
			databuilderJobId: jobMetadata?.databuilder_job_id ?? 'undefined',
		},
	});

	const commitTag =
		jobMetadata?.git_tag?.split('-')[2]?.substring(1) ?? 'undefined';
	const latestCommitTag =
		latestGitInfo?.version.split('-')[2]?.substring(1) ?? 'undefined';

	if (!jobMetadata) {
		return null;
	}

	return (
		<Stack spacing="xs">
			<SimpleGrid cols={2}>
				<Stack spacing={0}>
					<Title size="md">Job ID</Title>
					<Text size="sm">{jobMetadata.databuilder_job_id}</Text>
				</Stack>
				<Stack spacing={0}>
					<Title size="md">Status</Title>
					<Text size="sm">{jobMetadata.status}</Text>
				</Stack>
				{jobMetadata.aws_batch_job_id && (
					<Stack spacing={0}>
						<Title size="md">AWS Batch Job ID</Title>
						<Text size="sm">{jobMetadata.aws_batch_job_id}</Text>
					</Stack>
				)}
			</SimpleGrid>
			<Divider />
			<SimpleGrid cols={2}>
				<Stack spacing={0}>
					<Title size="md">Created at</Title>
					<Text size="sm">
						{dayjs(jobMetadata.created_at).format('MMM-DD-YYYY  HH:mm:ss')}
					</Text>
				</Stack>
				<Stack spacing={0}>
					<Title size="md">Started at</Title>
					<Text size="sm">
						{dayjs(jobMetadata.started_at).format('MMM-DD-YYYY  HH:mm:ss')}
					</Text>
				</Stack>
				<Stack spacing={0}>
					<Title size="md">Total run time</Title>
					<Text size="sm">{`${jobMetadata.run_time_secs} seconds`}</Text>
				</Stack>
			</SimpleGrid>
			<Divider />
			<SimpleGrid cols={2}>
				<Stack spacing={0}>
					<Title size="md">Git version tag</Title>
					<Text size="sm">
						<a
							target="_blank"
							rel="noopener noreferrer"
							href={`https://github.com/secoda/secoda/commit/${commitTag}`}
						>
							{commitInfo?.version}
						</a>
					</Text>
				</Stack>
				<Stack spacing={0}>
					<Title size="md">Git commit date</Title>
					<Text size="sm">{commitInfo?.date ?? 'N/A'}</Text>
				</Stack>
				<Stack spacing={0}>
					<Title size="md">Git latest deployment tag</Title>
					<Text size="sm">
						<a
							target="_blank"
							rel="noopener noreferrer"
							href={`https://github.com/secoda/secoda/commit/${latestCommitTag}`}
						>
							{latestGitInfo?.version}
						</a>
					</Text>
				</Stack>
				<Stack spacing={0}>
					<Title size="md">Git latest deployment commit date</Title>
					<Text size="sm">{latestGitInfo?.date ?? 'N/A'}</Text>
				</Stack>
			</SimpleGrid>
			<>
				<Divider />
				<Stack spacing="xs">
					<Title size="md">Actions</Title>
					<Group>
						{jobMetadata.status === 'reserved' && (
							<Button
								onClick={() =>
									removeReservedJob({
										databuilderJobID: jobMetadata?.databuilder_job_id,
									})
								}
							>
								Remove reserved job
							</Button>
						)}
						{jobMetadata.status !== 'running' && (
							<Button
								onClick={() => {
									retryJob({
										databuilderJobID: jobMetadata?.databuilder_job_id,
									});
								}}
							>
								Retry job
							</Button>
						)}
					</Group>
				</Stack>
			</>
			<Divider />
			<Stack spacing="xs">
				<Title size="md">Summary</Title>
				<Prism language="json" colorScheme="dark">
					{JSON.stringify(jobMetadata.summary, null, 2)}
				</Prism>
			</Stack>
			{jobMetadata.exception_log && (
				<Stack spacing="xs">
					<Title size="md">Error log</Title>
					<Prism language="python" colorScheme="dark">
						{jobMetadata.exception_log}
					</Prism>
				</Stack>
			)}
		</Stack>
	);
}
