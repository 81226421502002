import Dagre from '@dagrejs/dagre';
import type { Filter, LineageJobMetadata } from '@repo/api-codegen';
import { EntityType } from '@repo/common/enums/entityType';
import { colors, iconSize } from '@repo/theme/primitives';
import { filter, includes, size, some } from 'lodash-es';
import type { Edge, Node } from 'reactflow';
import { MarkerType } from 'reactflow';
import type {
	IApiListResponse,
	ILineage,
	ILineageTableQuery,
	ILineageTableTest,
	ImpactedIds,
	LineageEntityMetadata,
} from '../../api';
import { queryClient } from '../../api';
import { apiClient, getEndpoints } from '../../api/common';
import { getDefaultListQueryFn } from '../../api/hooks/base/useBaseModelList';
import {
	LINEAGE_IMPACTED_NAMESPACE,
	LINEAGE_NAMESPACE,
	lineageImpactedQueryKeyFactory,
	lineageQueryKeyFactory,
} from '../../api/hooks/lineage';
import { LINEAGE_QUERY_OPTIONS } from '../../hooks/useLineage/constants';
import type { BackgroundJob } from '../../lib/models';
import {
	isChart,
	isColumn,
	isDashboard,
	isJob,
	isTable,
} from '../../lib/utils/entity';
import {
	DAGRE_EDGE_SEP,
	DAGRE_NODE_SEP,
	DAGRE_RANK_SEP,
	DEFAULT_POSITION,
	MAX_NUM_OF_CHILDREN,
	NODE_GAP,
	NODE_HEADER_HEIGHT,
	NODE_PADDING,
	NODE_ROW_HEIGHT,
	NODE_WIDTH,
} from './constants';
import type {
	EdgeData,
	EntityNodeChildren,
	EntityNodeData,
	LineageBoolean,
	LineageCount,
	LineageGraphData,
	LineageIntegration,
	TemporaryNodeData,
} from './types';
import { EdgeHandle, EdgeType, LineageDirectionEnum, NodeType } from './types';

const getImpactedIds = (id: string) =>
	apiClient.get<ImpactedIds>(
		getEndpoints(LINEAGE_IMPACTED_NAMESPACE).byPath([id, 'ids']),
		{
			params: { id },
		}
	);

export const fetchImpactedIds = async (id: string) =>
	queryClient
		.fetchQuery({
			queryKey: lineageImpactedQueryKeyFactory.byArgs('ids', {
				id,
			}),
			queryFn: () => getImpactedIds(id),
			retry: false,
		})
		.then((response) => response.data)
		.catch(() => null);

export const fetchLineage = async (
	id: string,
	direction: LineageDirectionEnum,
	page: number,
	filter?: Filter
) =>
	queryClient.fetchQuery<IApiListResponse<ILineage>>({
		queryKey: lineageQueryKeyFactory.list(page, {
			id,
			direction,
			filter,
		}),
		queryFn: (context) =>
			getDefaultListQueryFn<ILineage>(
				{
					page,
					filters: {
						id,
						direction,
						filter,
					},
				},
				lineageQueryKeyFactory.namespace
			)(context),
		...LINEAGE_QUERY_OPTIONS,
	});

export const fetchAllLineage = async (
	id: string,
	direction: LineageDirectionEnum,
	filter?: Filter
): Promise<ILineage[]> => {
	const lineages = [];

	const { results, total_pages: totalPages } = await fetchLineage(
		id,
		direction,
		1,
		filter
	);
	lineages.push(...results);

	if (totalPages > 1) {
		for (let page = 2; page <= totalPages; page += 1) {
			// eslint-disable-next-line no-await-in-loop
			const pageResponse = await fetchLineage(id, direction, page, filter);
			lineages.push(...pageResponse.results);
		}
	}

	return lineages;
};

export const initializeEntityNodeData = ({
	id,
	title,
	databuilderId,
	lineageId,
	integration,
	entityType,
	nativeType,
	published,
	metadata = {},
	numOfChildren,
	creationQuery,
	tests,
	direction,
	isRoot,
	isManual,
}: {
	id: string;
	title: string;
	databuilderId?: string;
	lineageId?: string;
	integration: LineageIntegration;
	entityType: EntityType;
	nativeType?: string;
	published: boolean;
	metadata?: LineageEntityMetadata;
	creationQuery?: ILineageTableQuery;
	numOfChildren: number;
	tests?: ILineageTableTest[];
	direction?: LineageDirectionEnum;
	isRoot: boolean;
	isManual: boolean;
}): EntityNodeData => ({
	id,
	title,
	ids: {
		databuilder: databuilderId,
		lineage: lineageId,
	},
	types: {
		entity: entityType,
		native: nativeType,
	},
	published,
	integration,
	metadata,
	numOfChildren,
	creationQuery,
	tests,
	direction,
	count: {
		upstream: 0,
		downstream: 0,
	},
	fetched: {
		upstream: false,
		downstream: false,
	},
	connectable: {
		source: false,
		target: false,
	},
	collapsed: {
		upstream: false,
		downstream: false,
	},
	height: NODE_HEADER_HEIGHT,
	isChildrenOpen: false,
	isFetching: false,
	isHighlighted: false,
	isManual,
	isRoot,
});

export const createRootEntityNode = (
	data: LineageGraphData
): Node<EntityNodeData> => {
	const { entity, upstream, downstream, children, tests, creationQuery } = data;

	const nodeData = initializeEntityNodeData({
		id: entity.id,
		title: entity.title || 'Untitled',
		databuilderId: entity.databuilder_id,
		integration: {
			id: entity.integration,
		},
		entityType: entity.entity_type,
		nativeType: entity.native_type,
		published: entity.published,
		isRoot: true,
		isManual: false,
		creationQuery,
		numOfChildren: size(children),
		tests,
	});

	nodeData.count = {
		upstream: size(upstream),
		downstream: size(downstream),
	};
	nodeData.fetched = {
		upstream: true,
		downstream: true,
	};

	if (isTable(entity)) {
		nodeData.metadata = {
			cluster: entity.search_metadata?.cluster,
			database: entity.search_metadata?.database,
			schema: entity.search_metadata?.schema,
		};
	} else if (isColumn(entity)) {
		nodeData.metadata = {
			cluster: entity.search_metadata?.cluster,
			database: entity.search_metadata?.database,
			schema: entity.search_metadata?.schema,
			table: entity.search_metadata?.table,
		};
	} else if (isDashboard(entity)) {
		nodeData.metadata = {
			group: entity.search_metadata?.group,
			product: entity.search_metadata?.product,
		};
	} else if (isChart(entity)) {
		nodeData.metadata = {
			product: entity.search_metadata?.product,
		};
	} else if (isJob(entity)) {
		nodeData.metadata = {
			product: entity.search_metadata?.product,
		};
	}

	return {
		id: entity.id,
		type: NodeType.ENTITY,
		data: nodeData,
		position: DEFAULT_POSITION,
		deletable: false,
	};
};

export const createEntityNodeFromLineage = (
	lineage: ILineage,
	direction: LineageDirectionEnum,
	nodeIdSet: Set<string>,
	numOfChildren: number,
	tests?: ILineageTableTest[],
	creationQuery?: ILineageTableQuery
): Node<EntityNodeData> => {
	let upstreamCount = size(lineage.upstream_entities);
	let downstreamCount = size(lineage.downstream_entities);

	if (nodeIdSet) {
		lineage.upstream_entities.forEach((id) => {
			if (nodeIdSet.has(id)) {
				upstreamCount -= 1;
			}
		});

		lineage.downstream_entities.forEach((id) => {
			if (nodeIdSet.has(id)) {
				downstreamCount -= 1;
			}
		});
	}

	const nodeData = initializeEntityNodeData({
		id: lineage.id,
		title: lineage.title,
		databuilderId: lineage.databuilder_id,
		lineageId: lineage.lineage_id,
		integration: {
			id: lineage.integration_id,
			type: lineage.integration_type,
		},
		entityType: lineage.entity_type,
		nativeType: lineage.native_type,
		published: lineage.published,
		metadata: lineage.metadata,
		creationQuery,
		numOfChildren,
		tests,
		direction,
		isRoot: false,
		isManual: lineage.is_manual,
	});

	nodeData.count = {
		upstream: upstreamCount,
		downstream: downstreamCount,
	};

	return {
		id: lineage.id,
		type: NodeType.ENTITY,
		data: nodeData,
		position: DEFAULT_POSITION,
	};
};

const hasConnectionToJob = (
	sourceId: string,
	targetId: string,
	lineageMetadata?: LineageJobMetadata
) => {
	if (!lineageMetadata) return false;
	const id = lineageMetadata.jobs[0]?.id;
	return Boolean(
		id && typeof id === 'string' && [sourceId, targetId].includes(id)
	);
};

export const createEdge = (
	sourceId: string,
	targetId: string,
	lineageMetadata?: LineageJobMetadata
): Edge<EdgeData> => ({
	id: `${sourceId}--${targetId}`,
	type: EdgeType.ENTITY,
	source: sourceId,
	sourceHandle: EdgeHandle.MAIN_SOURCE,
	target: targetId,
	targetHandle: EdgeHandle.MAIN_TARGET,
	animated: false,
	deletable: false,
	updatable: false,
	markerEnd: {
		type: MarkerType.ArrowClosed,
		width: iconSize.lg,
		height: iconSize.lg,
	},
	data: {
		isHovered: false,
		hasConnectionToJob: hasConnectionToJob(sourceId, targetId, lineageMetadata),
		metadata: lineageMetadata,
	},
});

const createChildEdge = (
	sourceId: string,
	targetId: string,
	sourceChildId: string,
	targetChildId: string,
	sourceHandle: string,
	targetHandle: string
): Edge<EdgeData> => ({
	id: `${sourceChildId}--${targetChildId}`,
	type: EdgeType.CHILD,
	source: sourceId,
	sourceHandle,
	target: targetId,
	targetHandle,
	animated: true,
	deletable: false,
	markerEnd: {
		type: MarkerType.ArrowClosed,
		width: iconSize.lg,
		height: iconSize.lg,
		color: colors.blue[5],
	},
});

export const createChildLineageEdges = (
	impactedChildren: ImpactedIds,
	nodeIdSet: Set<string>
): Edge<EdgeData>[] => {
	const upstreamEdges = impactedChildren.upstream.map((uc) => {
		const isNode = nodeIdSet.has(uc.to_entity.id);

		const sourceId = isNode ? uc.to_entity.id : uc.to_entity.parent;
		const targetId = uc.from_entity.parent;
		const sourceChildId = uc.to_entity.id;
		const targetChildId = uc.from_entity.id;
		const sourceHandle = isNode
			? EdgeHandle.MAIN_SOURCE
			: `${EdgeHandle.CHILD_SOURCE}--${sourceChildId}`;
		const targetHandle = `${EdgeHandle.CHILD_TARGET}--${targetChildId}`;

		return createChildEdge(
			sourceId,
			targetId,
			sourceChildId,
			targetChildId,
			sourceHandle,
			targetHandle
		);
	});

	const downstreamEdges = impactedChildren.downstream.map((dc) => {
		const isNode = nodeIdSet.has(dc.to_entity.id);

		const sourceId = dc.from_entity.parent;
		const targetId = isNode ? dc.to_entity.id : dc.to_entity.parent;
		const sourceChildId = dc.from_entity.id;
		const targetChildId = dc.to_entity.id;
		const targetHandleType = isNode
			? EdgeHandle.MAIN_TARGET
			: EdgeHandle.CHILD_TARGET;

		return createChildEdge(
			sourceId,
			targetId,
			sourceChildId,
			targetChildId,
			`${EdgeHandle.CHILD_SOURCE}--${sourceChildId}`,
			`${targetHandleType}--${targetChildId}`
		);
	});

	return [...upstreamEdges, ...downstreamEdges];
};

export const getEntityNodeHeight = (
	data: EntityNodeData,
	overrideBehavior: boolean | null = null
) => {
	const { types, numOfChildren, isChildrenOpen } = data;

	let height = NODE_HEADER_HEIGHT;

	const considerChildrenOpen =
		overrideBehavior !== null ? overrideBehavior : isChildrenOpen;

	if (
		[EntityType.table, EntityType.dashboard].includes(types.entity) &&
		numOfChildren > 0
	) {
		height += NODE_ROW_HEIGHT + 2 * NODE_PADDING;
		if (considerChildrenOpen) {
			height +=
				NODE_ROW_HEIGHT +
				2 * NODE_GAP +
				Math.min(numOfChildren, MAX_NUM_OF_CHILDREN) * NODE_ROW_HEIGHT;
		}
	}

	return height;
};

const isValidEdge = (edge: Edge<EdgeData>) =>
	edge.source !== edge.target &&
	includes(edge.sourceHandle, EdgeHandle.MAIN_SOURCE) &&
	includes(edge.targetHandle, EdgeHandle.MAIN_TARGET);

export const getNodeEdges = (edges: Edge<EdgeData>[]) =>
	edges.filter(isValidEdge);

const layoutGraph = (
	nodes: Node<EntityNodeData>[],
	edges: Edge<EdgeData>[]
) => {
	const graph = new Dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}));
	graph.setGraph({
		rankdir: 'LR',
		nodesep: DAGRE_NODE_SEP,
		edgesep: DAGRE_EDGE_SEP,
		ranksep: DAGRE_RANK_SEP,
	});

	nodes.forEach((node) => {
		const height = getEntityNodeHeight(node.data);
		graph.setNode(node.id, { width: NODE_WIDTH, height });
	});

	edges.forEach((edge) => {
		if (isValidEdge(edge)) {
			graph.setEdge(edge.source, edge.target);
		}
	});

	Dagre.layout(graph);

	return nodes.map((node) => {
		const { x, y } = graph.node(node.id);
		return {
			...node,
			position: {
				x: x - NODE_WIDTH / 2,
				y: y - node.data.height / 2,
			},
		};
	});
};

export const getLayoutedNodes = (
	nodes: Node<EntityNodeData>[],
	edges: Edge<EdgeData>[]
): Promise<Node<EntityNodeData>[]> =>
	Promise.resolve(layoutGraph(nodes, edges));

export const setNewNodePositions = async (
	rootNode: Node<EntityNodeData>,
	concatNodes: Node<EntityNodeData>[],
	concatEdges: Edge<EdgeData>[]
): Promise<{
	updatedPosition: { x: number; y: number };
	updatedNodes: Node<EntityNodeData>[];
	updatedEdges: Edge<EdgeData>[];
}> => {
	const layoutedNodes = layoutGraph(concatNodes, concatEdges);
	let updatedPosition: { x: number; y: number } = rootNode.position;

	const updatedNodes = layoutedNodes.map((node) => {
		if (node.id === rootNode.id) {
			updatedPosition = node.position;
			return {
				...node,
				data: {
					...node.data,
					fetched: {
						upstream: rootNode.data.direction === LineageDirectionEnum.UPSTREAM,
						downstream:
							rootNode.data.direction === LineageDirectionEnum.DOWNSTREAM,
					},
				},
			};
		}
		return node;
	});

	return {
		updatedPosition,
		updatedNodes,
		updatedEdges: concatEdges,
	};
};

export const getIndicatorMetadata = (
	count: LineageCount,
	fetched: LineageBoolean,
	collapsed: LineageBoolean,
	direction?: LineageDirectionEnum
) => {
	const isUpstream = direction === LineageDirectionEnum.UPSTREAM;
	const indicatorCount = isUpstream ? count.upstream : count.downstream;
	const indicatorFetched = isUpstream ? fetched.upstream : fetched.downstream;
	const isCollapsed = isUpstream ? collapsed.upstream : collapsed.downstream;

	const isIndicatorShown =
		(indicatorCount > 0 && !indicatorFetched) ||
		(indicatorCount > 0 && isCollapsed);

	return {
		indicatorCount,
		indicatorFetched,
		isIndicatorShown,
		isCollapsed,
	};
};

export const resetEntityNodeData = (
	nodes: Node<EntityNodeData>[],
	edges: Edge<EdgeData>[]
): Promise<Node<EntityNodeData>[]> => {
	const updatedNodes = nodes.map((node) => ({
		...node,
		data: {
			...node.data,
			height: getEntityNodeHeight(node.data, false),
			isChildrenOpen: false,
		},
	}));

	return getLayoutedNodes(updatedNodes, getNodeEdges(edges));
};

export const closeEntityNodeChildren = (
	nodes: Node<EntityNodeData>[]
): Node<EntityNodeData>[] =>
	nodes.map((node) => ({
		...node,
		data: {
			...node.data,
			height: getEntityNodeHeight(node.data, false),
			isChildrenOpen: false,
		},
	}));

export const getNodesWithHiglightedChildren = async (
	childId: string,
	nodes: Node<EntityNodeData>[],
	edges: Edge<EdgeData>[],
	impactedIds: Set<string>,
	nodeChildrenMap: Map<string, EntityNodeChildren[]>
): Promise<Node<EntityNodeData>[]> => {
	impactedIds.add(childId);

	const updateChildren = (node: Node<EntityNodeData>) => {
		const children = nodeChildrenMap.get(node.id);

		const hasHighlightedChildren = some(children, (child) =>
			impactedIds.has(child.id)
		);

		return {
			...node,
			data: {
				...node.data,
				height: getEntityNodeHeight(node.data, hasHighlightedChildren),
				isChildrenOpen: hasHighlightedChildren || node.data.isChildrenOpen,
			},
		};
	};

	const updatedNodes = nodes.map(updateChildren);

	return getLayoutedNodes(updatedNodes, getNodeEdges(edges));
};

export const getEntityNodes = (
	nodes: Node<EntityNodeData | TemporaryNodeData>[]
): Node<EntityNodeData>[] =>
	filter(
		nodes,
		(node) => node.type === NodeType.ENTITY
	) as Node<EntityNodeData>[];

export function delay(duration: number): Promise<void> {
	return new Promise((resolve) => {
		setTimeout(resolve, duration);
	});
}

export const exportImpactedCsv = async (id: string) =>
	apiClient.get<BackgroundJob>(
		getEndpoints(LINEAGE_NAMESPACE).byPath(['impacted', id, 'csv'])
	);
