import { Group, Stack } from '@mantine/core';
import { useNavigate } from '@repo/common/hooks/useNavigate';
import { Icon, Text } from '@repo/foundations';
import dayjs from 'dayjs';
import { find, isNil, startCase } from 'lodash-es';
import { useMemo } from 'react';
import { useIntegrationList } from '../../../../../api';
import type { INotification } from '../../../../../api/types/models/notification';
import { SecodaEntityIcon } from '../../../../../components/SecodaEntity';
import { UserAvatar } from '../../../../../components/UserAvatar';
import { buildResourceUrl } from '../../../../../utils/navigationUtils';
import { useStyles } from '../styles';
import ChangeRequestContent from './ChangeRequestContent';
import SchemaTablesDroppedContent from './SchemaTablesDroppedContent';
import TableSchemaChangeContent from './TableSchemaChangeContent';

interface IResourceBasicContentProps {
	selected: INotification;
}

function ResourceBasicContent({ selected }: IResourceBasicContentProps) {
	const { classes, cx } = useStyles();

	const navigate = useNavigate();

	const { data: integrations } = useIntegrationList({});

	const integration = useMemo(
		() =>
			selected.entity
				? find(integrations?.results, {
						id: selected.entity?.integration,
					})
				: null,
		[integrations?.results, selected.entity]
	);

	const handleNavigateToResource = () => {
		navigate(
			buildResourceUrl(
				selected.entity || {
					id: selected.resource_id,
					entity_type: selected.resource_type,
				}
			)
		);
	};

	return (
		<Stack spacing="lg">
			<Group spacing="xs" align="flex-start">
				<Group className={classes.leftCol} spacing="xs">
					<Icon name="user" />
					User
				</Group>
				<Group
					className={cx(classes.rightCol, classes.cursorPointer)}
					spacing="xs"
				>
					<UserAvatar user={selected.sender} size="lg" />
					<Stack spacing={0}>
						<Text className={classes.label}>
							{selected.sender.display_name}
						</Text>
						<Text className={classes.subLabel}>{`${startCase(
							selected.sender.role
						)} • ${selected.sender.email}`}</Text>
					</Stack>
				</Group>
			</Group>
			<Group spacing="xs" align="flex-start">
				<Group className={classes.leftCol} spacing="xs">
					<Icon name="file" />
					Resource
				</Group>
				<Group
					className={cx(classes.rightCol, classes.cursorPointer)}
					spacing="xs"
					onClick={handleNavigateToResource}
				>
					<SecodaEntityIcon entity={selected.entity} />
					<Stack spacing={0}>
						<Text className={classes.label}>
							{selected.entity?.title || selected.resource_title}
						</Text>
						{!isNil(integration) && (
							<Text className={classes.subLabel}>{`${startCase(
								integration.name
							)} / ... / ${
								selected.entity?.title || selected.resource_title
							}`}</Text>
						)}
					</Stack>
				</Group>
			</Group>
			<Group spacing="xs" align="flex-start">
				<Group className={classes.leftCol} spacing="xs">
					<Icon name="layoutGrid" />
					Resource type
				</Group>
				<Group className={classes.rightCol} spacing="xs" noWrap>
					<Stack spacing={0}>
						<Text className={classes.soleLabel}>
							{startCase(
								selected.entity?.native_type ||
									selected.entity?.entity_type ||
									selected.resource_type
							)}
						</Text>
					</Stack>
				</Group>
			</Group>
			<Group spacing="xs" align="flex-start">
				<Group className={classes.leftCol} spacing="xs">
					<Icon name="clock" />
					Updated time
				</Group>
				<Group className={classes.rightCol} spacing="xs" noWrap>
					<Stack spacing={0}>
						<Text className={classes.soleLabel}>
							{dayjs(selected.created_at).format('MMMM D, YYYY h:mm A')}
						</Text>
					</Stack>
				</Group>
			</Group>
			{selected.event === 'change_request' && (
				<ChangeRequestContent selected={selected} />
			)}
			{selected.event === 'table_schema_changed' && (
				<TableSchemaChangeContent selected={selected} />
			)}
			{selected.event === 'tables_dropped' && (
				<SchemaTablesDroppedContent selected={selected} />
			)}
		</Stack>
	);
}

export default ResourceBasicContent;
