import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
	dialog: {
		minHeight: theme.other.space[120],
		maxWidth: theme.other.space[200],
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	logs: {
		flexGrow: 1,
		fontSize: theme.fontSizes.xs,
		fontFamily: theme.fontFamilyMonospace,
		backgroundColor: theme.colors.gray[1],
		padding: theme.spacing.sm,
		borderRadius: theme.radius.xs,
	},
	logBox: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		fontSize: 'xs',
		color: 'black',
	},
	logItem: {
		whiteSpace: 'pre-wrap',
		marginRight: theme.other.space[1],
	},
}));
