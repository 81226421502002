import { Anchor, Stack } from '@mantine/core';
import { Text, Title } from '@repo/foundations';
import { usePlan } from '../../hooks/usePlans';

function PlansPageHeader() {
	const { daysLeftInTrial, trial } = usePlan();

	let title = 'Choose a plan for your team';

	if (trial) {
		title = `Your trial is ending in ${daysLeftInTrial} days`;
	}

	if (!trial) {
		title = 'Your trial has ended';
	}

	const handleBookDemoClick = () => {
		window.open(
			'https://secoda.co/contact-sales',
			'_blank',
			'noopener,noreferrer'
		);
	};

	return (
		<Stack spacing="sm">
			<Title order={1} size="3xl" align="center">
				{title}
			</Title>
			<Text align="center">
				Choose a plan to continue using Secoda after your trial ends. <br />
				If you have any questions,{' '}
				<Anchor onClick={handleBookDemoClick}>book a demo with us</Anchor>.
			</Text>
		</Stack>
	);
}

export default PlansPageHeader;
