import type { IIntegration } from '@repo/common/models/integration';
import type { IBaseModel } from './baseModel';

export interface ITag extends IBaseModel {
	name: string;
	custom_property_id?: string;
	description: string;
	color: string;
	workspace_id: string;
	integration: IIntegration;
	tag_group_id?: string;
	tag_group_name?: string;
	visible: boolean;
}
export interface ITagGroup extends IBaseModel {
	name: string;
	workspace_id: string;
	tags?: ITag[];
}

export const isTagGroup = (obj: ITagGroup | ITag): obj is ITagGroup =>
	!Object.hasOwn(obj, 'tag_group_id');

export const isTag = (obj: ITagGroup | ITag): obj is ITag =>
	Object.hasOwn(obj, 'tag_group_id');
