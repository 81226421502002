import { IconButton } from '@repo/foundations';
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { RightSidebarMode, rightSidebarStore } from '../RightSidebar';

export const EntityPageSidebarToggle = observer(() => {
	const { collapsed, mode, setCollapsed, setMode } = rightSidebarStore;

	const toggleEntitySideBar = useCallback(() => {
		if (collapsed) {
			setCollapsed(false);
		} else if (mode === RightSidebarMode.INFO) {
			// If the mode is already set to this we instead want to close sidebar.
			setCollapsed(true);
		}
		setMode('info');
	}, [collapsed, mode, setMode, setCollapsed]);

	const isOpen = !collapsed && mode === RightSidebarMode.INFO;

	return (
		<IconButton
			tooltip={`${isOpen ? 'Hide' : 'Show'} details`}
			onClick={toggleEntitySideBar}
			highlight={isOpen}
			iconName={isOpen ? 'infoCircleFilled' : 'infoCircle'}
			variant="tertiary"
		/>
	);
});
