import { Divider, Group, Stack } from '@mantine/core';
import MarkdownRenderer from '@repo/common/components/MarkdownRenderer';
import { useNavigate } from '@repo/common/hooks/useNavigate';
import { Text, Title } from '@repo/foundations';
import { isEmpty, isNil, isString, startCase } from 'lodash-es';
import { useUser } from '../../../../api';
import type { INotification } from '../../../../api/types/models/notification';
import { UserAvatar } from '../../../../components/UserAvatar';
import { useStyles } from './styles';

interface IResourceAnnouncementContentProps {
	selected: INotification;
}

function ResourceAnnouncementContent({
	selected,
}: IResourceAnnouncementContentProps) {
	const { classes } = useStyles();

	const navigate = useNavigate();

	const hasTitle = !isNil(selected.content) && !isEmpty(selected.content);

	const hasDefinition =
		!isNil(selected.metadata.definition) &&
		!isEmpty(selected.metadata.definition);

	const handleNavigateToUser = () => {
		navigate(`/user/${selected.sender.id}`);
	};

	const { data: user } = useUser({
		id: selected.sender?.id,
		options: {
			enabled: !isNil(selected.sender?.id),
		},
	});

	return (
		<Stack spacing="lg">
			{hasTitle && <Title size="lg">{selected.content}</Title>}
			{hasDefinition && (
				<>
					<MarkdownRenderer>
						{isString(selected.metadata.definition)
							? selected.metadata.definition
							: ''}
					</MarkdownRenderer>
					<Divider />
				</>
			)}
			<Group spacing="xs">
				{user ? <UserAvatar user={user} size="xxs" /> : null}
				<Stack
					className={classes.cursorPointer}
					spacing={0}
					onClick={handleNavigateToUser}
				>
					<Text className={classes.label}>{selected.sender.display_name}</Text>
					<Text className={classes.subLabel}>{`${startCase(
						selected.sender.role
					)} • ${selected.sender.email}`}</Text>
				</Stack>
			</Group>
		</Stack>
	);
}

export default ResourceAnnouncementContent;
