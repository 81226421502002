import { createStyles, Table } from '@mantine/core';
import type { IIntegration } from '../../../../../api';
import { ExtractionDetail } from '../../../IntegrationExtraction/ExtractionDetail';
import useIntegrationExtraction from '../../../IntegrationSelectionPage/useIntegrationExtraction';
import { SyncTableRow } from './SyncTableRow';

interface SyncTableProps {
	integration: IIntegration;
	type: 'PULL' | 'PUSH';
}

const useStyles = createStyles((theme) => ({
	table: {
		borderCollapse: 'separate',
		border: `1px solid ${theme.other.getColor('border/primary/default')}`,
		borderRadius: theme.radius.md,
		overflow: 'hidden',
		'thead tr th': {
			padding: theme.spacing.xs,
			fontSize: theme.other.typography.text.sm,
			fontWeight: theme.other.typography.weight.semibold,
			color: theme.other.getColor('text/secondary/default'),
			backgroundColor: theme.other.getColor('surface/secondary/default'),
			borderBottomColor: theme.other.getColor('border/secondary/default'),
		},
		'thead tr th:first-of-type': {
			paddingLeft: theme.spacing.lg,
		},
		'tbody tr td': {
			padding: theme.spacing.xs,
			fontSize: theme.other.typography.text.xs,
			borderTopColor: theme.other.getColor('border/secondary/default'),
		},
		'tbody tr td:first-of-type': {
			paddingLeft: theme.spacing.lg,
			fontWeight: theme.other.typography.weight.bold,
		},
		'tbody tr': {
			cursor: 'pointer',
		},
	},
}));

export function SyncTable({ integration, type }: SyncTableProps) {
	const { classes } = useStyles();

	const {
		extractionJobs,
		handleSetActiveExtraction,
		setExtractionPolling,
		extractionDetailId,
		showExtractionDetail,
		setShowExtractionDetail,
	} = useIntegrationExtraction({
		id: integration.id,
		type,
	});

	const handleCloseExtractionDetail = () => {
		setShowExtractionDetail(false);
	};

	return (
		<>
			<Table className={classes.table} cellSpacing={0} highlightOnHover>
				<thead>
					<tr>
						<th>Sync type</th>
						<th>Sync started</th>
						<th>Time</th>
						<th>Sync status</th>
						<th aria-label="Job menu" />
					</tr>
				</thead>
				<tbody>
					{extractionJobs?.map((job) => (
						<SyncTableRow
							key={job.id}
							job={job}
							setActiveExtraction={handleSetActiveExtraction}
							setExtractionPolling={setExtractionPolling}
						/>
					))}
				</tbody>
			</Table>
			<ExtractionDetail
				integration={integration}
				extractionJobs={extractionJobs ?? []}
				extractionDetailId={extractionDetailId}
				isOpen={showExtractionDetail}
				onClose={handleCloseExtractionDetail}
			/>
		</>
	);
}
