import { EntityType } from '@repo/common/enums/entityType';
import type { Attrs } from 'prosemirror-model';
import { invalidateResourceRelationList } from '../../../../../api/hooks/relations';
import { ResourceRelation } from '../../../../../lib/models';
import { entityIdFromCurrentPath } from '../../../../../utils/shared.utils';

export function ensureEntityRelation(enabled: boolean, attrs?: Attrs) {
	if (!enabled || !attrs) {
		return;
	}

	const fromEntityId = entityIdFromCurrentPath();

	// TODO: Fix this hack. The path should not be involved
	// in figuring out the entity type.
	let fromEntityType = window.location.pathname.split('/')[1] as EntityType;
	if ((fromEntityType as string) === 'docs') {
		// NOTE: This is a hack because the path when
		// editing documents is /docs and not /document.
		fromEntityType = EntityType.document;
	} else if ((fromEntityType as string) === 'questions') {
		// NOTE: This is a hack because the path when
		// browsing questions is /questions and not /question.
		fromEntityType = EntityType.question;
	} else if ((fromEntityType as string) === 'ai') {
		// Ignore it and don't throw an error - ai is not a valid entity type
		return;
	} else if ((fromEntityType as string) === 'dictionary') {
		fromEntityType = EntityType.dictionary_term;
	} else if ((fromEntityType as string) === 'chart') {
		fromEntityType = EntityType.chart;
	} else if ((fromEntityType as string) in ['user', 'user_group']) {
		return;
	} else if ((fromEntityType as string) === 'teams') {
		return;
	} else if (!Object.values(EntityType).includes(fromEntityType)) {
		throw Error(
			`Could not make valid EntityType from path (${fromEntityType})`
		);
	}

	if (fromEntityType && fromEntityId) {
		ResourceRelation.create({
			from_entity: fromEntityId,
			to_entity: attrs.to_entity,
		}).then(() => {
			invalidateResourceRelationList(fromEntityId);
			invalidateResourceRelationList(attrs.to_entity as string);
		});
	}
}
