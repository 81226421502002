import { createStyles } from '@mantine/core';
import type { TreeNode } from '../lib/headingsToTree';

const useStyles = createStyles(
	(theme, { isSelected }: { isSelected: boolean }) => ({
		ul: {
			listStyle: 'none',
			padding: '0 !important',
			margin: '0 !important',
			border: `2px solid ${isSelected ? '#0366d6' : 'transparent'}`,

			a: {
				color: `${theme.other.getColor('text/secondary/default')} !important`,
				fontWeight: theme.other.typography.weight.semibold,
				cursor: 'pointer !important',
			},

			'& ul': {
				marginLeft: `${theme.spacing.md} !important`,
			},
		},
	})
);

export interface TableOfContentsTreeProps {
	items: TreeNode[];
	isSelected: boolean;
	scrollToAnchor: (id: string) => void;
}

export function TableOfContentsTree({
	items,
	isSelected,
	scrollToAnchor,
}: TableOfContentsTreeProps) {
	const { classes } = useStyles({ isSelected });

	if (!items || items.length === 0) {
		return null;
	}

	const handleOnClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
		e.preventDefault();
		e.stopPropagation();
		const id = e.currentTarget.getAttribute('href');
		scrollToAnchor(id!);
	};

	return (
		<ul className={classes.ul} data-testid="editor-table-of-contents">
			{items.map((item) => (
				<li key={item.heading.id}>
					<a onClick={handleOnClick} href={`#${item.heading.id}`}>
						{item.heading.title}
					</a>
					{item.children && (
						<TableOfContentsTree
							items={item.children}
							isSelected={false}
							scrollToAnchor={scrollToAnchor}
						/>
					)}
				</li>
			))}
		</ul>
	);
}
