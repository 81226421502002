import { makeAutoObservable } from 'mobx';
import type { BackgroundJob } from '../../lib/models';

class GlobalBackgroundJobStore {
	activeJob: BackgroundJob | undefined = undefined;

	onComplete: () => void = () => {};

	setActiveJob = (value: BackgroundJob | undefined) => {
		this.activeJob = value;
	};

	dismissActiveJob = () => {
		this.setActiveJob(undefined);
	};

	setOnComplete = (value: () => void) => {
		this.onComplete = value;
	};

	constructor() {
		makeAutoObservable(this);
	}
}
const globalBackgroundJobStore = new GlobalBackgroundJobStore();
export default globalBackgroundJobStore;
