import { createStyles, Group, SimpleGrid, Stack } from '@mantine/core';
import { useNavigateHandler } from '@repo/common/hooks/useNavigate';
import { Text, Title } from '@repo/foundations';
import { capitalize, chunk, isEmpty, map, size, slice } from 'lodash-es';
import type { MouseEvent } from 'react';
import { useMemo } from 'react';
import type { ISecodaEntity } from '../../../../../../../api';
import { useCollectionList } from '../../../../../../../api';
import { SecodaEntityIcon } from '../../../../../../../components/SecodaEntity';
import { WidgetType } from '../../../../../../../interfaces';
import { buildResourceUrl } from '../../../../../../../utils/navigationUtils';
import { useHomepageTeam } from '../../../../../hooks/useHomepageTeam';
import type { CollectionWidgetTypes, IWidgetItemProps } from '../../../types';
import EmptyCollectionsWidget from './EmptyCollectionsWidget';

export type ICollectionsWidgetProps = IWidgetItemProps & {
	onWidgetTypeChange: (widgetType: WidgetType) => void;
};

const useStyles = createStyles((theme) => ({
	resource: {
		cursor: 'pointer',
		borderRadius: theme.radius.xs,
		'&:hover': {
			backgroundColor: theme.other.getColor('fill/transparent/hover'),
		},
		borderBottom: `1px solid ${theme.other.getColor('border/primary/default')}`,
		'&:last-child': {
			borderBottom: 'none',
		},
	},
	markdown: {
		color: theme.other.getColor('text/secondary/default'),
		fontSize: theme.fontSizes.sm,
	},
}));

function CollectionsWidget({
	widget,
	onWidgetTypeChange,
}: ICollectionsWidgetProps) {
	const { classes } = useStyles();
	const { isTeamsPage, team } = useHomepageTeam();
	const navigateHandler = useNavigateHandler();

	const widgetType = widget.type as CollectionWidgetTypes;

	const filters = useMemo(() => {
		const updatedFilters: Record<string, string> = {};

		if (isTeamsPage) {
			updatedFilters.teams = team.id;
		}

		if (widget.type === WidgetType.PINNED_COLLECTIONS) {
			updatedFilters.pinned = 'true';
		}
		return updatedFilters;
	}, [isTeamsPage, team?.id, widget.type]);

	const { data: entities } = useCollectionList({
		filters,
		options: {
			select: ({ results }) => results,
			suspense: false,
		},
	});

	const shouldChunkData = size(entities) > 5 && widget.size === 'full';

	const chunkCount = shouldChunkData ? 5 : 10;
	const cols = shouldChunkData ? 2 : 1;

	const resources: ISecodaEntity[][] = chunk(
		slice(entities, 0, 10),
		chunkCount
	);

	if (isEmpty(resources)) {
		return (
			<EmptyCollectionsWidget
				widgetType={widgetType}
				onWidgetTypeChange={onWidgetTypeChange}
			/>
		);
	}

	const handleNavigateToCollection =
		(resource: ISecodaEntity) => (event: MouseEvent) => {
			const url = buildResourceUrl(resource);
			navigateHandler(event)(url);
		};

	return (
		<SimpleGrid cols={cols} spacing={0}>
			{map(resources, (resourceChunk, chunkIndex) => (
				<Stack key={chunkIndex} spacing={0}>
					{map(resourceChunk, (resource) => (
						<Stack
							key={resource.id}
							px="sm"
							py={9}
							spacing="xs"
							className={classes.resource}
							onClick={handleNavigateToCollection(resource)}
						>
							<Group noWrap>
								<SecodaEntityIcon size={32} entity={resource} />
								<Stack spacing={2}>
									<Title weight="bold" order={6} lineClamp={1}>
										{capitalize(resource.title || 'Untitled')}
									</Title>
									<Text color="text/secondary/default" size="xs" lineClamp={2}>
										{capitalize(resource.entity_type)}
									</Text>
								</Stack>
							</Group>
						</Stack>
					))}
				</Stack>
			))}
		</SimpleGrid>
	);
}

export default CollectionsWidget;
