import { EntityType } from '@repo/common/enums/entityType';
import { includes, some } from 'lodash-es';
import type { SearchResult } from '../../../api';

export function shouldHideMetadata(entity: SearchResult) {
	let rowHidden = true;
	if (includes([EntityType.table, EntityType.column], entity.entity_type)) {
		rowHidden = !some([
			entity.search_metadata?.database,
			entity.search_metadata?.schema,
			entity.search_metadata?.table,
		]);
	} else if (
		includes([EntityType.dashboard, EntityType.job], entity.entity_type)
	) {
		rowHidden = !entity.search_metadata?.group;
	}
	return rowHidden;
}
