import { createQueryKeys } from '../../factories';

export const LINEAGE_NAMESPACE = ['lineage'];
export const lineageQueryKeyFactory = createQueryKeys(LINEAGE_NAMESPACE);

export const LINEAGE_JOB_NAMESPACE = ['lineage', 'job'];
export const lineageJobQueryKeyFactory = createQueryKeys(LINEAGE_JOB_NAMESPACE);

export const LINEAGE_IMPACTED_NAMESPACE = ['lineage', 'impacted'];
export const lineageImpactedQueryKeyFactory = createQueryKeys(
	LINEAGE_IMPACTED_NAMESPACE
);
