import { Text } from '@repo/foundations';

interface IReadOnlyIndicatorProps {
	isReadOnly: boolean;
}

export function ReadOnlyIndicator({ isReadOnly }: IReadOnlyIndicatorProps) {
	if (!isReadOnly) {
		return null;
	}

	return (
		<Text size="sm" color="text/secondary/default">
			Read only
		</Text>
	);
}
