import { useListState } from '@mantine/hooks';
import type { QueryKey } from '@tanstack/react-query';
import { useQueryClient } from '@tanstack/react-query';
import { curry, fromPairs, isArray, isString, map } from 'lodash-es';

import { useEffect, useMemo } from 'react';
import type { ICreateWidgetParams, IWidget, IWidgetSample } from '../../../api';
import {
	reportsQueryKeyFactory,
	useCreateWidget,
	useDeleteWidget,
	useReport,
	useUpdateHomepageReport,
	useUpdateWidget,
} from '../../../api';
import { usePopulateReportWithTemplate } from '../../../api/hooks/widget/usePopulateReportWithTemplate';
import { useReorderWidgets } from '../../../api/hooks/widget/useReorderWidgets';
import type {
	UpdatableWidgetKey,
	UpdatableWidgetKeys,
	UpdatableWidgetValue,
	WidgetUpdateFn,
} from '../types';

type IReportManagementArgs =
	| {
			type: 'teams';
			teamId: string;
	  }
	| {
			type: 'homepage';
	  }
	| {
			type: 'analytics';
			id: string;
	  };

export function useReportManagement(
	args: IReportManagementArgs = { type: 'homepage' }
) {
	const queryClient = useQueryClient();
	const { type } = args;
	const teamId = args.type === 'teams' ? args?.teamId : undefined;

	let queryKey: QueryKey = reportsQueryKeyFactory.byId(type);

	if (type === 'teams') {
		queryKey = reportsQueryKeyFactory.byArgs(type, teamId as string);
	}

	const invalidate = () => {
		queryClient.invalidateQueries(queryKey);
	};

	let reportId = args.type === 'analytics' ? args.id : undefined;
	if (type === 'analytics') {
		queryKey = reportsQueryKeyFactory.byArgs(type, reportId as string);
	}

	const { data: report, isFetching } = useReport({
		type,
		teamId,
		reportId,
		options: {
			refetchOnMount: 'always',
			refetchOnWindowFocus: 'always',
		},
	});

	const { mutate: updateReport } = useUpdateHomepageReport();

	const [widgets, widgetsHandler] = useListState<IWidget>([]);

	const { mutateAsync: updateWidget } = useUpdateWidget();
	const { mutateAsync: deleteWidget } = useDeleteWidget();
	const { mutateAsync: createWidget } = useCreateWidget();
	const { mutateAsync: reorderWidgets } = useReorderWidgets();
	const { mutateAsync: populateReportWithTemplate } =
		usePopulateReportWithTemplate();

	useEffect(() => {
		widgetsHandler.setState(report?.widgets ?? []);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [report?.widgets]);

	reportId = useMemo(() => report?.id, [report?.id]);

	const handleBackgroundImageChange = (image = '', fontColor = '#000000') => {
		if (!reportId) {
			return;
		}

		updateReport(
			{
				data: {
					id: reportId,
					background_image: image,
					header_font_color: fontColor,
				},
			},
			{
				onSettled: invalidate,
			}
		);
	};

	const addWidget = async (widgetSample: IWidgetSample) => {
		if (!reportId) {
			return;
		}

		const data: ICreateWidgetParams['data'] = {
			report: reportId,
			...widgetSample,
		};

		await createWidget(
			{
				data,
			},
			{
				onSettled: invalidate,
			}
		);
	};

	const handleUpdateWidget: WidgetUpdateFn = curry(
		async (
			id: string,
			key: UpdatableWidgetKeys,
			value: UpdatableWidgetValue | UpdatableWidgetValue[],
			disableInvalidate: boolean = false
		) => {
			let properties = {};

			if (isArray(key) && isArray(value)) {
				properties = fromPairs<IWidget[UpdatableWidgetKey]>(
					key.map((k, i) => [k, value?.[i]])
				);
			}

			if (isString(key)) {
				properties = {
					[key]: value,
				};
			}

			// Optimistically update widgets state
			const arrayId = widgets.findIndex((widget) => widget.id === id);
			if (arrayId >= 0) {
				widgetsHandler.setItem(arrayId, {
					...widgets[arrayId],
					...properties,
				});
			}

			await updateWidget(
				{
					data: {
						id,
						...properties,
					},
				},
				{
					onSettled: () => {
						if (disableInvalidate) {
							return;
						}

						invalidate();
					},
				}
			);
		}
	);

	const handleRemoveWidget = (id: string) => async () => {
		await deleteWidget(
			{
				id,
			},
			{
				onSettled: invalidate,
			}
		);
	};

	const handleReorderWidgets = async ({
		from,
		to,
	}: {
		from: number;
		to: number;
	}) => {
		if (!reportId) {
			return;
		}

		const widgetIds = map(widgets, 'id');

		// Optimistically update widgets state
		widgetsHandler.reorder({ from, to });

		try {
			await reorderWidgets(
				{
					data: {
						widgetIds,
						from,
						to,
					},
					reportId,
				},
				{
					onSettled: invalidate,
				}
			);

			localStorage.setItem(`reorder_${reportId}`, 'true');
		} catch (e) {
			// Revert changes if request failed
			widgetsHandler.reorder({ from: to, to: from });
		}
	};

	const handlePopulateReportWithTemplate = async (template: string) => {
		if (!reportId) {
			return;
		}

		await populateReportWithTemplate(
			{
				reportId,
				template,
			},
			{
				onSettled: invalidate,
			}
		);
	};

	return {
		isFetching,
		report,
		widgets,
		updateBackgroundImage: handleBackgroundImageChange,
		addWidget,
		reorderWidgets: handleReorderWidgets,
		updateWidget: handleUpdateWidget,
		removeWidget: handleRemoveWidget,
		populateReportWithTemplate: handlePopulateReportWithTemplate,
	};
}
