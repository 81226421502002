import type { SkeletonProps } from '@mantine/core';
import { Skeleton, useMantineTheme } from '@mantine/core';
import { typography } from '@repo/theme/primitives';
import type { TitleProps } from '../Title';
import { TITLE_ORDER_TO_SIZE_MAP } from '../Title/constants';
import { getTextWidth } from './util';

type TitleSkeletonProps = SkeletonProps &
	Pick<TitleProps, 'size' | 'order'> & {
		text?: string;
	};

export function TitleSkeleton({
	text,
	order = 1,
	size,
	...rest
}: TitleSkeletonProps) {
	const theme = useMantineTheme();
	const isInline = !!text;

	const height = size
		? typography.lineHeight.title[size]
		: typography.lineHeight.title[TITLE_ORDER_TO_SIZE_MAP[order as any]];

	let width = rest.width ?? '100%';

	if (isInline) {
		width = `${getTextWidth(text, {
			fontFamily: rest.ff ?? theme.fontFamily,
			fontWeight: rest.fw ?? 'normal',
			fontSize: size
				? typography.title[size]
				: typography.title[TITLE_ORDER_TO_SIZE_MAP[order as any]],
		})}px`;
	}

	return (
		<Skeleton style={{ width, maxWidth: '100%' }} height={height} {...rest} />
	);
}
