import produce from 'immer';
import { forEach, isEmpty, isNil, isPlainObject } from 'lodash-es';
import { matchPath } from 'react-router';
import urlcat from 'urlcat';

const IGNORED_PARAM_VALUES = ['null', 'undefined', '""', '{}', '[]'];

export function clearAllParamsFromUrl() {
	const url = new URL(window.location.href);
	url.search = '';
	const clearedURL = url.toString();
	window.history.pushState({}, document.title, clearedURL);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function setParamsInUrl(key: string, value: any) {
	const url = new URL(window.location.href);

	const params: string = isPlainObject(value) ? JSON.stringify(value) : value;

	const currentParams = url.searchParams.get(key);
	if (currentParams !== null && currentParams === params) {
		return;
	}

	if (IGNORED_PARAM_VALUES.includes(params)) {
		url.searchParams.delete(key);
		window.history.replaceState({}, '', url);
		return;
	}
	url.searchParams.set(key, params);
	window.history.pushState({}, '', url);
}

export function deleteParamsFromUrl(key: string) {
	const url = new URL(window.location.href);
	url.searchParams.delete(key);
	window.history.pushState({}, '', url);
}

export function getParamsFromUrl() {
	const queryString = window.location.search.slice(1);

	return new URLSearchParams(queryString);
}

export const getNavigationUrl = (
	path: string,
	params: Record<string, unknown> = {}
) => {
	const queryParams = produce(params, (draft) => {
		if (isEmpty(draft?.filters)) {
			delete draft.filters;
			return;
		}

		draft.filters = JSON.stringify(draft.filters);
	});

	return urlcat(path, queryParams);
};

export function isHash(href: string) {
	if (href[0] === '#') {
		return true;
	}

	try {
		const outline = new URL(window.location.href);
		const parsed = new URL(href);

		if (
			outline.hostname === parsed.hostname &&
			outline.pathname === parsed.pathname &&
			!!parsed.hash
		) {
			return true;
		}
	} catch (e) {
		// Failed to parse as url
	}

	return false;
}

// Utility function to create new path based on pattern and params
function createNewPath(
	pattern: string,
	params: Record<string, string | number>
) {
	let newPath = pattern;
	forEach(params, (value, key) => {
		newPath = newPath.replace(`:${key}`, String(value));
	});

	return newPath;
}

export const setTabSuffixPath = (
	tab: string,
	pagePattern = '/:pagePrefix/:id'
) => {
	const pagePatternWithTab = `${pagePattern}/:tab`;
	const currentPath = window.location.pathname;
	const match =
		matchPath(pagePattern, currentPath) ||
		matchPath(pagePatternWithTab, currentPath);

	if (match) {
		const newPath = createNewPath(pagePatternWithTab, {
			...match.params,
			tab,
		});
		window.history.replaceState({}, '', newPath);
	}
};

/**
 * Extracts the domain from an email address.
 * @param email - The email address from which to extract the domain.
 * @returns The domain part of the email address, or null if the domain is not found.
 */
export function extractDomainFromEmail(
	email: string | undefined
): string | null {
	if (isNil(email)) {
		return null;
	}

	// Regular expression to match the domain part of the email
	const emailRegex = /@([a-zA-Z0-9.-]+)$/;

	// Match the email against the regular expression
	const match = email.match(emailRegex);

	// If the regular expression matches, return the domain part
	if (match && match.length > 1) {
		return match[1];
	}

	// If the regular expression doesn't match or the domain part is not found, return null
	return null;
}
