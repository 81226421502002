import type { SVGProps } from 'react';

export function SecodaMinimalLogo(
	props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>
) {
	return (
		<svg
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<g clipPath="url(#clip0_489_30754)">
				<rect width="16" height="16" fill="#F56551" />
				<path
					d="M6.57124 6.68294L9.02617 4.623C9.73935 4.02457 10.8026 4.1176 11.401 4.83078C11.8117 5.37007 11.8931 6.01269 11.6477 6.56923C11.5627 6.76196 11.5632 6.99185 11.6967 7.1548C11.8732 7.37022 12.2019 7.38443 12.3526 7.15027C12.898 6.30293 12.9337 5.17004 12.1334 4.21625C11.1956 3.0986 9.5293 2.95282 8.41165 3.89064L6.71659 5.31296C6.5319 5.06324 6.23528 4.90132 5.90083 4.90132C5.34083 4.90132 4.88686 5.35529 4.88686 5.91529C4.88686 6.47529 5.34083 6.92926 5.90083 6.92926C6.13253 6.92926 6.34607 6.85154 6.51682 6.72076C6.53575 6.71003 6.55397 6.69742 6.57124 6.68294Z"
					fill="url(#paint0_linear_489_30754)"
				/>
				<path
					d="M9.33471 6.57121L11.3946 9.02615C11.9931 9.73933 11.9 10.8026 11.1869 11.401C10.6476 11.8117 10.005 11.8931 9.44841 11.6476C9.25568 11.5626 9.02579 11.5632 8.86284 11.6967C8.64742 11.8731 8.63321 12.2019 8.86737 12.3526C9.71471 12.8979 10.8476 12.9337 11.8014 12.1334C12.919 11.1956 13.0648 9.52927 12.127 8.41162L10.7047 6.71657C10.9544 6.53188 11.1163 6.23525 11.1163 5.9008C11.1163 5.34081 10.6624 4.88684 10.1024 4.88684C9.54235 4.88684 9.08838 5.34081 9.08838 5.9008C9.08838 6.1325 9.1661 6.34605 9.29688 6.5168C9.30762 6.53573 9.32022 6.55395 9.33471 6.57121Z"
					fill="url(#paint1_linear_489_30754)"
				/>
				<path
					d="M9.42876 9.33468L6.97383 11.3946C6.26065 11.993 5.19738 11.9 4.59895 11.1868C4.18826 10.6475 4.10688 10.0049 4.35234 9.44838C4.43735 9.25565 4.43678 9.02576 4.3033 8.86281C4.12684 8.64739 3.79806 8.63318 3.64736 8.86734C3.10203 9.71469 3.06627 10.8476 3.86659 11.8014C4.80441 12.919 6.4707 13.0648 7.58835 12.127L9.28341 10.7047C9.4681 10.9544 9.76472 11.1163 10.0992 11.1163C10.6592 11.1163 11.1131 10.6623 11.1131 10.1023C11.1131 9.54233 10.6592 9.08836 10.0992 9.08836C9.86747 9.08836 9.65393 9.16607 9.48318 9.29685C9.46425 9.30759 9.44603 9.32019 9.42876 9.33468Z"
					fill="url(#paint2_linear_489_30754)"
				/>
				<path
					d="M6.66043 9.42876L4.60049 6.97383C4.00207 6.26065 4.09509 5.19738 4.80827 4.59895C5.34757 4.18826 5.99018 4.10688 6.54672 4.35234C6.73945 4.43734 6.96935 4.43678 7.1323 4.3033C7.34771 4.12684 7.36192 3.79806 7.12776 3.64736C6.28042 3.10203 5.14753 3.06627 4.19374 3.86659C3.0761 4.80441 2.93031 6.4707 3.86813 7.58835L5.29045 9.28341C5.04073 9.4681 4.87881 9.76472 4.87881 10.0992C4.87881 10.6592 5.33278 11.1131 5.89278 11.1131C6.45278 11.1131 6.90675 10.6592 6.90675 10.0992C6.90675 9.86747 6.82904 9.65393 6.69826 9.48318C6.68752 9.46425 6.67492 9.44603 6.66043 9.42876Z"
					fill="url(#paint3_linear_489_30754)"
				/>
			</g>
			<defs>
				<linearGradient
					id="paint0_linear_489_30754"
					x1="3.25"
					y1="3.25"
					x2="14.7045"
					y2="8.41786"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="white" />
					<stop offset="1" stopColor="white" stopOpacity="0.9" />
				</linearGradient>
				<linearGradient
					id="paint1_linear_489_30754"
					x1="3.25"
					y1="3.25"
					x2="14.7045"
					y2="8.41786"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="white" />
					<stop offset="1" stopColor="white" stopOpacity="0.9" />
				</linearGradient>
				<linearGradient
					id="paint2_linear_489_30754"
					x1="3.25"
					y1="3.25"
					x2="14.7045"
					y2="8.41786"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="white" />
					<stop offset="1" stopColor="white" stopOpacity="0.9" />
				</linearGradient>
				<linearGradient
					id="paint3_linear_489_30754"
					x1="3.25"
					y1="3.25"
					x2="14.7045"
					y2="8.41786"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="white" />
					<stop offset="1" stopColor="white" stopOpacity="0.9" />
				</linearGradient>
				<clipPath id="clip0_489_30754">
					<path
						d="M0 4C0 1.79086 1.79086 0 4 0H12C14.2091 0 16 1.79086 16 4V12C16 14.2091 14.2091 16 12 16H4C1.79086 16 0 14.2091 0 12V4Z"
						fill="white"
					/>
				</clipPath>
			</defs>
		</svg>
	);
}
