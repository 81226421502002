import axios from 'axios';
import { cloneDeep } from 'lodash-es';
import type { IBaseModel } from '../../api';
import { authHeaders } from '../../api/common';
import { BaseModel } from './base';
import { SecodaEntity } from './entity';
import type { WorkspaceNotificationPreference } from './workspace.ts';

export interface ISlackHook extends BaseModel {
	entity: string;
	slack_conversation_id: string;
	slack_conversation_name: string;
}

export interface ISlackChannel {
	id: string;
	channel: string;
	name: string;
}

export interface ISecodaSlackChannel extends IBaseModel {
	title: string;
	teams: string[];
	channel_id: string;
	channel_type: 'notification' | 'question' | 'incident';
	notification_settings?: WorkspaceNotificationPreference;
	create_param?: 'all' | 'reaction' | 'mention' | 'never';
	respond_param?: 'all' | 'mention' | 'never';
	thread_respond_param?: 'all' | 'mention' | 'never';
	persona_id?: string | null | undefined;
}

export class Slack extends BaseModel {
	constructor(obj: Slack) {
		super(obj);
		Object.keys(obj).forEach((key) => {
			(this as any)[key] = cloneDeep((obj as any)[key]);
		});
	}

	static get namespace() {
		return ['integration', 'slack'];
	}

	static get fields() {
		return SecodaEntity.fields.concat([
			'workspace_id',
			'team_id',
			'team_name',
			'webhook_channel',
			'webhook_channel_id',
			'incidents_channel',
			'incidents_channel_id',
			'webhook_configuration_url',
			'webhook_url',
		]);
	}

	static async get_conversations(refresh = false) {
		try {
			const res = await axios.get(`${Slack.apiRootPath}conversations/`, {
				...authHeaders(),
				params: {
					refresh,
				},
			});
			return res.data.results;
		} catch (e) {
			return [];
		}
	}

	static async channelName() {
		return axios.get(`${Slack.apiRootPath}channel_name/`, authHeaders());
	}

	static async channelTest(channel: string) {
		return axios.get(`${Slack.apiRootPath}channel_test/`, {
			...authHeaders(),
			params: {
				channel,
			},
		});
	}

	static async getChannels() {
		return axios.get(`${Slack.apiRootPath}channel/`, authHeaders());
	}

	static async createChannel(channel: Partial<ISecodaSlackChannel>) {
		return axios.post(`${Slack.apiRootPath}channel/`, channel, authHeaders());
	}

	static async updateChannel(channel: Partial<ISecodaSlackChannel>) {
		return axios.put(
			`${Slack.apiRootPath}channel/${channel.id}/`,
			channel,
			authHeaders()
		);
	}

	static async deleteChannel(channel_id: string) {
		return axios.post(
			`${Slack.apiRootPath}channel/delete/${channel_id}/`,
			authHeaders()
		);
	}
}
