import { Tooltip } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { Button } from '@repo/foundations';

function CopyLinkButton() {
	const copyLink = async () => {
		await navigator.clipboard.writeText(window.location.href);
		showNotification({
			title: 'Link copied',
			message: 'Link copied to clipboard',
			color: 'green',
		});
	};

	return (
		<Tooltip label="Copy resource URL">
			<Button
				variant="default"
				leftIconName="link"
				size="md"
				onClick={(e) => {
					e.preventDefault();
					e.stopPropagation();
					copyLink();
				}}
			>
				Copy link
			</Button>
		</Tooltip>
	);
}

export default CopyLinkButton;
