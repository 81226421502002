import type { SelectablePropertyItem } from '@repo/common/components/SingleSelector/types';
import { AutomationTriggerType } from '../../../../api';
import type { ScheduleConfig } from '../../../../api/types/models/schedule';
import { ScheduleCadence } from '../../../../api/types/models/schedule';

export const AUTOMATION_TRIGGER_OPTIONS: SelectablePropertyItem[] = [
	{
		label: 'Scheduled',
		value: AutomationTriggerType.SCHEDULE,
	},
	{
		label: 'Schemas are changed',
		value: AutomationTriggerType.SCHEMA_CHANGE,
	},
];

export enum CadenceSelectOption {
	HOURLY = 'hourly',
	HOURLY_3 = 'hourly_3',
	HOURLY_6 = 'hourly_6',
	HOURLY_12 = 'hourly_12',
	DAILY = 'daily',
	WEEKLY = 'weekly',
}

export const convertCadenceOptionToSchedule = (
	cadence: CadenceSelectOption
): Pick<ScheduleConfig, 'cadence' | 'frequency'> => {
	switch (cadence) {
		case CadenceSelectOption.HOURLY:
			return {
				cadence: ScheduleCadence.HOURLY,
				frequency: 1,
			};
		case CadenceSelectOption.HOURLY_3:
			return {
				cadence: ScheduleCadence.HOURLY,
				frequency: 3,
			};
		case CadenceSelectOption.HOURLY_6:
			return {
				cadence: ScheduleCadence.HOURLY,
				frequency: 6,
			};
		case CadenceSelectOption.HOURLY_12:
			return {
				cadence: ScheduleCadence.HOURLY,
				frequency: 12,
			};
		case CadenceSelectOption.DAILY:
			return {
				cadence: ScheduleCadence.DAILY,
				frequency: 1,
			};
		case CadenceSelectOption.WEEKLY:
			return {
				cadence: ScheduleCadence.WEEKLY,
				frequency: 1,
			};
		default:
			return {
				cadence: ScheduleCadence.HOURLY,
				frequency: 1,
			};
	}
};

export const convertScheduleToCadenceOption = (
	schedule: Pick<ScheduleConfig, 'cadence' | 'frequency'>
): CadenceSelectOption => {
	switch (schedule.cadence) {
		case ScheduleCadence.HOURLY:
			if (schedule.frequency === 1) {
				return CadenceSelectOption.HOURLY;
			} else {
				if (schedule.frequency === 3) {
					return CadenceSelectOption.HOURLY_3;
				}
				if (schedule.frequency === 6) {
					return CadenceSelectOption.HOURLY_6;
				}
				if (schedule.frequency === 12) {
					return CadenceSelectOption.HOURLY_12;
				}
			}
			break;
		case ScheduleCadence.DAILY:
			return CadenceSelectOption.DAILY;
		case ScheduleCadence.WEEKLY:
			return CadenceSelectOption.WEEKLY;
		default:
			return CadenceSelectOption.HOURLY;
	}
	return CadenceSelectOption.HOURLY;
};

export const getCadenceDisplayLabel = (
	schedule: Pick<ScheduleConfig, 'cadence' | 'frequency'>
): string => {
	const frequency = schedule?.frequency || 1;
	switch (schedule.cadence) {
		case ScheduleCadence.HOURLY:
			if (frequency === 1) {
				return 'Hourly';
			}
			return `Every ${frequency} hours`;
		case ScheduleCadence.DAILY:
			return 'Daily';
		case ScheduleCadence.WEEKLY:
			return 'Weekly';
		default:
			return '';
	}
};

export const AUTOMATION_CADENCE_OPTIONS: SelectablePropertyItem[] = [
	{
		label: 'Every hour',
		value: CadenceSelectOption.HOURLY,
		iconName: 'repeat',
	},
	{
		label: 'Every 3 hours',
		value: CadenceSelectOption.HOURLY_3,
		iconName: 'repeat',
	},
	{
		label: 'Every 6 hours',
		value: CadenceSelectOption.HOURLY_6,
		iconName: 'repeat',
	},
	{
		label: 'Every 12 hours',
		value: CadenceSelectOption.HOURLY_12,
		iconName: 'repeat',
	},
	{
		label: 'Daily',
		value: CadenceSelectOption.DAILY,
		iconName: 'repeat',
	},
	{
		label: 'Weekly',
		value: CadenceSelectOption.WEEKLY,
		iconName: 'repeat',
	},
];

export const AUTOMATION_TIME_OPTIONS = [
	{ label: '00:00 UTC', value: '0', iconName: 'clock' },
	{ label: '01:00 UTC', value: '1', iconName: 'clock' },
	{ label: '02:00 UTC', value: '2', iconName: 'clock' },
	{ label: '03:00 UTC', value: '3', iconName: 'clock' },
	{ label: '04:00 UTC', value: '4', iconName: 'clock' },
	{ label: '05:00 UTC', value: '5', iconName: 'clock' },
	{ label: '06:00 UTC', value: '6', iconName: 'clock' },
	{ label: '07:00 UTC', value: '7', iconName: 'clock' },
	{ label: '08:00 UTC', value: '8', iconName: 'clock' },
	{ label: '09:00 UTC', value: '9', iconName: 'clock' },
	{ label: '10:00 UTC', value: '10', iconName: 'clock' },
	{ label: '11:00 UTC', value: '11', iconName: 'clock' },
	{ label: '12:00 UTC', value: '12', iconName: 'clock' },
	{ label: '13:00 UTC', value: '13', iconName: 'clock' },
	{ label: '14:00 UTC', value: '14', iconName: 'clock' },
	{ label: '15:00 UTC', value: '15', iconName: 'clock' },
	{ label: '16:00 UTC', value: '16', iconName: 'clock' },
	{ label: '17:00 UTC', value: '17', iconName: 'clock' },
	{ label: '18:00 UTC', value: '18', iconName: 'clock' },
	{ label: '19:00 UTC', value: '19', iconName: 'clock' },
	{ label: '20:00 UTC', value: '20', iconName: 'clock' },
	{ label: '21:00 UTC', value: '21', iconName: 'clock' },
	{ label: '22:00 UTC', value: '22', iconName: 'clock' },
	{ label: '23:00 UTC', value: '23', iconName: 'clock' },
] as SelectablePropertyItem[];

export const AUTOMATION_DAY_OPTIONS = [
	{ label: 'Monday', value: '0', iconName: 'calendar' },
	{ label: 'Tuesday', value: '1', iconName: 'calendar' },
	{ label: 'Wednesday', value: '2', iconName: 'calendar' },
	{ label: 'Thursday', value: '3', iconName: 'calendar' },
	{ label: 'Friday', value: '4', iconName: 'calendar' },
	{ label: 'Saturday', value: '5', iconName: 'calendar' },
	{ label: 'Sunday', value: '6', iconName: 'calendar' },
] as SelectablePropertyItem[];
