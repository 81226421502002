import { Group, Stack } from '@mantine/core';
import type { BackgroundJobType } from '@repo/api-codegen';
import { useApiGetBackgroundJob } from '@repo/api-codegen';
import { Button, ListBox, Text } from '@repo/foundations';
import { useState } from 'react';
import BackgroundJobCard from './BackgroundJobCard';
import BackgroundJobCreate from './BackgroundJobCreate';

function BackgroundJobPage() {
	const [type, setType] = useState<BackgroundJobType>('indexing');

	const [opened, setOpened] = useState(false);
	const { data: backgroundJobs, refetch } = useApiGetBackgroundJob({
		queryParams: { type: type },
	});

	return (
		<Stack
			p="sm"
			w="100%"
			mih="100vh"
			sx={{
				overflowX: 'scroll',
			}}
		>
			<BackgroundJobCreate />
			<Group>
				<Text>Filter by job type</Text>
				<ListBox
					opened={opened}
					onOpenChange={(newOpened) => setOpened(newOpened)}
					closeOnEscape
				>
					<ListBox.Target>
						<Button onClick={() => setOpened(!opened)}>{type}</Button>
					</ListBox.Target>
					<ListBox.ItemsDropdown
						items={['indexing', 'bg_admin'] as BackgroundJobType[]}
						renderItem={(item, getProps, idx) => (
							<ListBox.Item
								onClick={() => {
									setType(item);
									setOpened(false);
								}}
							>
								{item}
							</ListBox.Item>
						)}
					/>
				</ListBox>
			</Group>
			{backgroundJobs?.map((job) => (
				<BackgroundJobCard key={job.id} job={job} onRefresh={() => refetch()} />
			))}
		</Stack>
	);
}

export default BackgroundJobPage;
