import { Divider, Group, Stack } from '@mantine/core';
import { Icon } from '@repo/foundations';
import { isNil } from 'lodash-es';
import { useMemo } from 'react';
import type { INotification } from '../../../../../api/types/models/notification';
import { useStyles } from '../styles';

import Columns from './Columns';

interface ITableSchemaChangeContentProps {
	selected: INotification;
}

function TableSchemaChangeContent({
	selected,
}: ITableSchemaChangeContentProps) {
	const { classes } = useStyles();

	const schemaChange = useMemo(
		() =>
			(selected.metadata as Record<string, Record<string, string | string[]>>)
				.schema_change,
		[selected.metadata.schema_change]
	);

	const shouldRenderDeletedColumns = useMemo(
		() => !isNil(schemaChange.deleted_columns),
		[schemaChange.deleted_columns]
	);

	const shouldRenderNewColumns = useMemo(
		() => !isNil(schemaChange.new_columns),
		[schemaChange.new_columns]
	);

	if (isNil(selected.metadata.schema_change)) {
		return null;
	}

	return (
		<Stack spacing="lg" data-testid="inbox-view-schema-change-80f11312e">
			<Divider />
			{shouldRenderDeletedColumns && (
				<Group spacing="xs" align="flex-start">
					<Group className={classes.leftCol} spacing="xs">
						<Icon name="columns" />
						Removed Columns
					</Group>
					<Columns columns={schemaChange.deleted_columns as string[]} />
				</Group>
			)}
			{shouldRenderNewColumns && (
				<Group spacing="xs" align="flex-start">
					<Group className={classes.leftCol} spacing="xs">
						<Icon name="columns" />
						Added Columns
					</Group>
					<Columns columns={schemaChange.new_columns as string[]} isNew />
				</Group>
			)}
		</Stack>
	);
}

export default TableSchemaChangeContent;
