import type { SelectablePropertyItem } from '@repo/common/components/SingleSelector/types';
import {
	AutomationPropagateMetadataField,
	AutomationPropagateMetadataRelationship,
} from '../../../../../api/types/models/automation/automationPropagateMetadata';

export const AUTOMATION_PROPAGATE_METADATA_RELATIONSHIP: SelectablePropertyItem[] =
	[
		{
			label: 'Child resources',
			value: AutomationPropagateMetadataRelationship.CHILDREN,
		},
		{
			label: 'Parent resources',
			value: AutomationPropagateMetadataRelationship.PARENT,
		},
		{
			label: 'Resources with the same title',
			value: AutomationPropagateMetadataRelationship.EXACT_NAME,
		},
		{
			label: 'Upstream resources',
			value: AutomationPropagateMetadataRelationship.LINEAGE_UP,
		},
		{
			label: 'Downstream resources',
			value: AutomationPropagateMetadataRelationship.LINEAGE_DOWN,
		},
	];

export const AUTOMATION_PROPAGATE_METADATA_LEVELS: SelectablePropertyItem[] = [
	{
		label: '1 level',
		value: '1',
	},
	{
		label: '2 levels',
		value: '2',
	},
	{
		label: '3 levels',
		value: '3',
	},
	{
		label: '4 levels',
		value: '4',
	},
];

export const AUTOMATION_PROPAGATE_METADATA_PROPERTIES: SelectablePropertyItem[] =
	[
		{
			label: 'Description',
			value: AutomationPropagateMetadataField.DESCRIPTION,
		},
		{
			label: 'Documentation',
			value: AutomationPropagateMetadataField.DOCUMENTATION,
		},
		{
			label: 'Verification',
			value: AutomationPropagateMetadataField.VERIFICATION,
		},
		{
			label: 'Governance',
			value: AutomationPropagateMetadataField.GOVERNANCE,
		},
		{
			label: 'Tags',
			value: AutomationPropagateMetadataField.TAGS,
		},
		{
			label: 'Collections',
			value: AutomationPropagateMetadataField.COLLECTIONS,
		},
		{
			label: 'Owners',
			value: AutomationPropagateMetadataField.OWNERS,
		},
	];
