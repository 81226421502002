import { Checkbox, Dialog, Group, createStyles } from '@mantine/core';
import { Button, Text } from '@repo/foundations';
import { noop } from 'lodash-es';
import { type ReactNode } from 'react';

const useStyles = createStyles((theme) => ({
	root: {
		padding: 0,
		position: 'fixed',
		bottom: '1.5rem',
		left: '50%',
		minHeight: 'auto',
		borderRadius: theme.radius.md,
		transform: 'translateX(-50%) scale(1) !important',
		minWidth: 'max-content',
		width: 'unset',
	},
}));

interface TableV2DialogProps {
	count: number;
	totalCount: number;
	onClose: () => void;
	onClick: () => void;
	showMoreActionsButton: boolean;
	withQuickActions: ReactNode;
}

export function TableV2Dialog({
	count,
	totalCount,
	onClose,
	onClick,
	showMoreActionsButton,
	withQuickActions = null,
}: TableV2DialogProps) {
	const { classes, theme } = useStyles();

	return (
		<Dialog
			p={theme.spacing.xs}
			px={theme.spacing.sm}
			className={classes.root}
			opened={count > 0}
			onClose={noop}
		>
			<Group
				sx={{
					flexWrap: 'nowrap',
					alignItems: 'center',
					width: '100%',
				}}
			>
				<Group spacing={0}>
					<Checkbox
						defaultChecked={count <= totalCount}
						indeterminate={count < totalCount}
						onClick={onClose}
					/>
				</Group>
				<Text size="sm" weight="semibold">
					{count} selected
				</Text>
				<Group spacing="xs">
					{withQuickActions}
					{showMoreActionsButton && (
						<Button onClick={onClick}>More actions</Button>
					)}
				</Group>
			</Group>
		</Dialog>
	);
}
