import type { FILTER_OPTIONS_DIVIDER } from '@repo/common/components/Filter/constants';
import type { FilterOptionType } from '@repo/common/components/Filter/types';
import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { deleteParamsFromUrl } from '../../utils/url';
import { captureError } from '../../web-tracing';
import { getSearchParamFilters } from './utils';

export function useFilterQueryString(
	urlParam: string | undefined,
	filterOptions: (FilterOptionType | typeof FILTER_OPTIONS_DIVIDER)[]
) {
	const [searchParams] = useSearchParams();

	return useCallback(() => {
		if (!urlParam) {
			return [];
		}

		try {
			const filtersV1Params = getSearchParamFilters(
				searchParams,
				filterOptions
			);

			if (filtersV1Params?.length > 0) {
				// we have filters v1 params in URL
				return filtersV1Params;
			} else {
				// filters v2
				const filters = searchParams.get(urlParam);

				if (filters) {
					return JSON.parse(filters);
				}
			}
		} catch (e) {
			// eslint-disable-next-line no-console
			console.error('[FILTERS]', 'failed to parse filters param', e);
			captureError(e);
			deleteParamsFromUrl(urlParam);
		}

		return [];
	}, [searchParams, urlParam, filterOptions]);
}
